import React from 'react';
import {
  Flex,
  Skeleton,
  ProviderConsumer as FluentUIThemeConsumer
} from '@fluentui/react-northstar';

export const EditTicketFormSkeleton = () => (
  <FluentUIThemeConsumer
    render={globalTheme => (
      <div
        className={`p-2.5`}
        style={{
          backgroundColor:
            globalTheme.siteVariables.colorScheme.default.background5
        }}
      >
        <div className={`mt-2`}>
          <Skeleton animation="wave">
            <Flex gap="gap.medium" column>
              <Flex gap="gap.medium" vAlign="center">
                <Skeleton.Avatar size="smaller" />
                <Skeleton.Text size="smaller" />
              </Flex>
              <Flex gap="gap.medium">
                <Skeleton.Text size="smaller" />
              </Flex>
              <Flex gap="gap.medium">
                <Skeleton.Text size="smaller" />
              </Flex>
            </Flex>
          </Skeleton>
        </div>
      </div>
    )}
  />
);


export const EditTicketRequestFormSkeleton = () => (
  <FluentUIThemeConsumer
    render={globalTheme => (
      <div
        className={`p-2.5`}
        style={{
          backgroundColor:
            globalTheme.siteVariables.colorScheme.default.background5
        }}
      >
        <div className={`mt-2`}>
          <Skeleton animation="wave">
            <Flex gap="gap.medium" column>
              <Flex gap="gap.medium" vAlign="center">
                <Skeleton.Text size="smaller" />
              </Flex>
              <Flex gap="gap.medium">
                <Skeleton.Avatar size="smaller" />
                <Skeleton.Avatar size="smaller" />
                <Skeleton.Avatar size="smaller" />
                <Skeleton.Avatar size="smaller" />
                <Skeleton.Avatar size="smaller" />
              </Flex>
              <Flex gap="gap.medium" className={`w-20`}>
                <Skeleton.Text size="smaller" />
              </Flex>
            </Flex>
          </Skeleton>
        </div>
      </div>
    )}
  />
);