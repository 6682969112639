import { Flex } from '@fluentui/react-northstar';
import React, { useState } from 'react';
import { EmptyData } from '../EmptyData';
import EmptySVG from '../../../../svg/empty.svg';
import { TicketApprovalCard } from './TicketApprovalCard';
import {ADD_APPROVALS_NOTE, ADMINISTRATORS, ANALYSTS, TicketApprovalState} from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import { ApprovalsDialog } from './ApprovalsDialog';
import { PlatformUser } from '../../interfaces/platformuser.interface';
import { appState } from '../../../AppState';

interface Props {
  ticketApprovals: TicketApprovals[];
  currentUserId: number;
  updateApprovalStatus?: (ticketApprovalData: Approval, approverId: number, status: TicketApprovalState) => void;
  isTicketClosed: boolean;
  isLifecycleEnabled?: boolean;
  isEndUser?: boolean;
  disableButonOnUpdate?: boolean;
  addTicketApprovals?: (ticketApprovalsData: Approval) => void;
  updateTicketApproval?: (ticketApprovalsData: Approval) => void;
  setApproversList?: (value: PlatformUser[]) => void;
  approversList?: PlatformUser[];
}


export const TicketApprovalsList = ({
    ticketApprovals, 
    currentUserId, 
    updateApprovalStatus, 
    isTicketClosed, 
    isLifecycleEnabled = false, 
    disableButonOnUpdate = false, 
    isEndUser = false, 
    addTicketApprovals, 
    updateTicketApproval, 
    setApproversList, 
    approversList}: Props) => {

  const {t} = useTranslation();  

  const [openApprovalDialog, setOpenApprovalDialog] = useState<boolean>(false);
  const [selectedApprovalData, setSelectedApprovalData] = useState<any>();
  
  const currentState = appState();
  
  const onApprovalClick = (approvalDetail: Approval) => {
    setSelectedApprovalData({...approvalDetail});
    setOpenApprovalDialog(true);
  };

  const isEditable = (approvalState: any) =>{
    const hasAccess = currentState.userRoles.roles.includes(ADMINISTRATORS) || currentState.userRoles.roles.includes(ANALYSTS) 
    return hasAccess && approvalState == TicketApprovalState.Pending && !isTicketClosed;
  }

  return (
    <div className='mt-2'>
      {ticketApprovals?.length > 0 ?
        (ticketApprovals.map((approval: TicketApprovals) => (
          <div key={approval.Approval.Id} className={`mt-4 ${((isEditable(approval.Approval.ApprovalState)))  ? 'cursor-pointer' : ''}`} 
                    onClick={(e) => { 
                      if((isEditable(approval.Approval.ApprovalState)))
                        onApprovalClick(approval.Approval) 
                      else
                        e.stopPropagation();
                    }}>
            <TicketApprovalCard 
              key={approval.Approval.Id}
              approval={approval.Approval}
              currentUserId={currentUserId}
              updateApprovalStatus={updateApprovalStatus}
              isTicketClosed={isTicketClosed}
              isLifecycleEnabled={isLifecycleEnabled}
              disableButonOnUpdate={disableButonOnUpdate}
            />
          </div>
        ))) : 
        <Flex className='mt-5' >
          <Flex.Item grow className="pt-0 overflow-auto"></Flex.Item>
          <EmptyData
            subheaderText={isEndUser ? '' : ADD_APPROVALS_NOTE}
            headerText={t('ticket-details.edit-ticket-form.approval.empty-approval-list')}
            SVGIcon={<EmptySVG width={75} height={75} />}
          />
        </Flex>
      }
      <ApprovalsDialog
        open={openApprovalDialog}
        onCancel={() => setOpenApprovalDialog(false)}
        approversList={approversList}
        setApproversList={setApproversList}
        addTicketApprovals={addTicketApprovals}
        existingTicketApprovalData = {selectedApprovalData}
        isUpdateButton = {true}
        updateTicketApproval = {updateTicketApproval}
      />
    </div>
  );
};
