import React from 'react';
import { Loader } from '@fluentui/react-northstar';
import PageLoadingSVG from '../../../../svg/page-loading.svg';
import { useTranslation } from 'react-i18next';

export const InitialPageLoader = () => {
  const {t}= useTranslation();
  return (
    <div className='h-screen flex items-center justify-center flex-col'>
      <h1 className='text-lg mt-16 mb-0.5 font-semibold'>{t('common.loading')}</h1>
      <div className='mt-6'>
        <Loader size='smallest' />
      </div>
    </div>
  );
};
