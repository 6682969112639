import React, { useState } from 'react';

import { AddIcon } from '@fluentui/react-icons-northstar';
import { Button, Flex, ProviderConsumer, ThemePrepared } from '@fluentui/react-northstar';
import { SwitchButton } from '../../../../shared/components/SwitchButton/SwitchButton';
import { FilterFields } from '../FilterFields/FilterFields';
import { useTranslation } from 'react-i18next';

interface Props {
  propertiesList: DropdownDataModel[];
  ruleGroup: SlaTicketCriteria;
  updateCriteriaMetaData: (data: CriteriaMetaData[]) => void;
  updateRuleGroupType: (type: GroupType) => void;
  addNewFilter: () => void;
  removeFilter: (filterIndex: number) => void;
}

export const FilterGroups = ({
  propertiesList,
  ruleGroup,
  updateCriteriaMetaData,
  addNewFilter,
  removeFilter,
  updateRuleGroupType
}: Props) => {
  const [filtersData, setFiltersData] = useState<CriteriaMetaData[]>(
    ruleGroup.criteriaMetaData
  );
  const { t } = useTranslation();

  React.useEffect(() => {
    setFiltersData(ruleGroup.criteriaMetaData);
  }, [ruleGroup]);

  const updateFilterData = (filterIndex: number, data: CriteriaMetaData) => {
    const filtersDataClone = [...filtersData];
    filtersDataClone.splice(filterIndex, 1, { ...data });
    setFiltersData(filtersDataClone);

    updateCriteriaMetaData(filtersDataClone);
  };

  return (
    <ProviderConsumer render={(globalTheme: ThemePrepared<any>) => (
    <>
      <Flex space="between" styles={{ margin: '1.5rem 0' }}>
        <SwitchButton
          type={ruleGroup.logicOperator}
          changeGroupType={newGroupType => {
            updateRuleGroupType(newGroupType);
          }}
          globalTheme={globalTheme}
        />
        <Flex vAlign="center">
          <Button
            icon={<AddIcon style={{color: globalTheme.siteVariables.colorScheme.brand.foreground}} />}
            text
            primary
            content={<span style={{color: globalTheme.siteVariables.colorScheme.brand.foreground}}>{t('sla.add-filter')}</span>}
            onClick={() => {
              addNewFilter();
            }}
          />
        </Flex>
      </Flex>

      {React.Children.toArray(
        filtersData.map((filterData: CriteriaMetaData, index: number) => (
          <div key={index}>
            <FilterFields
              propertiesList={propertiesList}
              filterData={filterData}
              filterIndex={index}
              updateFilterData={updateFilterData}
              removeFilter={removeFilter}
              globalTheme={globalTheme}
            />
          </div>
        ))
      )}
    </>
    )}/>
  );
};
