import React, { useCallback, useState } from 'react';
import { Button, Loader, MenuButton, MoreIcon, Dialog } from '@fluentui/react-northstar';
import { platformService } from '../shared/services/platform.service';
import { Communication } from '@fluentui/react-teams';
import { Providers } from '@microsoft/mgt-element/dist/es6/providers/Providers';
import { Breadcrumbs } from '../shared/components/BreadcrumbNavigation';
import {
  BUTTONS,
  CONTACT_ADMIN,
  DEFAULT_API_STATUS,
  DELETE,
  DONE,
  ERROR,
  LOADING,
  REFRESH_SESSION,
  SETTINGS,
  SETTINGS_PAGES,
  SOMETHING_WENT_WRONG,
  TAGS,
  TAG_HEADERS,
  UNAUTHORIZED_ACCESS,
  AGENTS,
  LICENSE_FEATURE,
  ADMINISTRATORS,
  ANALYSTS,
  KNOWLEDGEAGENT
} from '../shared/utils/constants';
import { toTitleCase } from '../shared/utils/helper';
import { KBListWrapper } from '../kb/KBListWrapper';
import { Tags } from './Tags';
import { FontIcon } from '@fluentui/react';
import { CheckLogin } from '../shared/components/CheckLogin';
import { Helmet } from 'react-helmet';
import { MobileSettingsView, transformModalContent } from '../automation/AutomationTable';
import useCurrentPage from '../kb/useCurrentPage';
import { appState, useSetState } from '../AppState';
import { CheckLicense } from '../shared/components/License/CheckLicense';
import { getCachedFeature } from '../shared/cache/FeatureCache';
import { UpgradeNeeded } from '../shared/components/UpgradeNeeded';
import { t } from 'i18next';

export const TagsTable = () => {
  const api = new platformService();
  const [data, setData] = useState<RowsData[]>([]);
  const [newOrEditDialog, setNewOrEditDialog] = useState(false);
  const [tagId, setTagId] = useState(0);
  const [apiStatus, setApiStatus] = useState<APIStatus>(DEFAULT_API_STATUS as APIStatus);
  const [recordToDelete, setRecordToDelete] = useState<Partial<TagsData>>({ Id: 0, Name: ''});
  const [showDialog, setShowDialog] = useState(false);
  const {currentPage, setCurrentPage, onDeleteRowSetCurrentPage} = useCurrentPage();

  const setAppState = useSetState();
  const appContextState = appState();

  const fetchData = useCallback(async () => {
    setApiStatus({ ...apiStatus, msg: TAGS.GETTING_ALL_TAGS });

    try {
      const enabledTicketTags = await getCachedFeature(LICENSE_FEATURE.TicketTags);
      if(enabledTicketTags) {
        let value = await api.getTags(`?$count=true&v=${new Date().getTime()}`);

        setAppState(pre => ({ ...pre, tags: value }));

        const hasEditAccess = appContextState.userRoles.roles.includes(ADMINISTRATORS) 
                          || appContextState.userRoles.roles.includes(ANALYSTS)
                          || appContextState.userRoles.roles.includes(KNOWLEDGEAGENT);

        const rowData: RowsData[] = value.map((tag: TagsData) => ({
          id: tag['Id'],
          [t('ticket.add-ticket.tags.name')]: tag['Name'],
          "": (
            <>
            <CheckLicense disable>
              {hasEditAccess &&
                <MenuButton
                  trigger={
                      <Button icon={<MoreIcon />} text iconOnly title="Actions" onClick={(e) => {
                        e.stopPropagation();
                      }} />
                  }
                  menu={{
                    items: [
                      {
                        content: "Edit",
                        icon: <FontIcon iconName={"Edit"} className={`text-lg`} style={{ lineHeight: "unset" }} />,
                        onClick: (e) => {
                          e.stopPropagation();
                          setTagId(tag['Id']);
                          setNewOrEditDialog(true);
                        }
                      },
                      {
                        content: toTitleCase(DELETE),
                        icon: <FontIcon iconName={"Delete"} className={`text-lg`} style={{ lineHeight: "unset" }} />,
                        onClick: (e) => {
                          e.stopPropagation();
                          setRecordToDelete({
                            Id: tag['Id'],
                            Name: tag['Name']
                          });
                          setShowDialog(true);
                        }
                      }
                    ],
                  }}
                />
              }
              </CheckLicense>
            </>
          )
        }));

        setData(rowData);
        setApiStatus({ ...apiStatus, status: DONE });
      } else {
        setApiStatus({ ...apiStatus, status: ERROR, errCode: 402 });
      }
    } catch (err) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: err['response']?.status
      });
    }
  }, []);

  const loadPage = () => {
    const hasAccess = appContextState.userRoles.roles.includes(ADMINISTRATORS) || appContextState.userRoles.roles.includes(ANALYSTS) || appContextState.userRoles.roles.includes(AGENTS);
    if(!hasAccess) { 
      setApiStatus({ ...apiStatus, status: ERROR, errCode: 401 }) }
     else{
      fetchData();
    }
  };

  const navs: Breadcrumbs = {
    breadcrumbs: [
      {
        title: toTitleCase(SETTINGS),
        link: '/settings'
      },
      {
        title: toTitleCase(TAGS.TAGS),
        link: ''
      }
    ]
  };

  const deleteTag = async () => {
    let status = 0;
    setApiStatus({
      status: LOADING,
      msg: TAGS.DELETING_TAGS,
      errCode: 0
    });
    try {
      ({ status } = await api.deleteTag(recordToDelete['Id']));

      onDeleteRowSetCurrentPage(data.length)
    } catch (err: any) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: err['response']?.status
      });
    } finally {
      setShowDialog(false);
      setApiStatus({ ...apiStatus, status: DONE });
    }

    if (status === 204) fetchData();
  };

  const errConfig = {
    fields: {
      title: apiStatus.errCode === 401 ? UNAUTHORIZED_ACCESS : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  };

  return (
    <CheckLogin onSignedIn={loadPage}>
      <Helmet>
        <title>{t('ticket.add-ticket.tags.title-page')}</title>
      </Helmet>
      <div className="hidden md:block">
        {
          apiStatus.status === LOADING ? (
            <Loader label={apiStatus.msg} styles={{ height: '100vh' }} />
          ) : apiStatus.status === ERROR ? (
            <>
              {apiStatus.errCode !== 402 && <Communication {...errConfig} />}
              {apiStatus.errCode === 401 && (
                <Button
                  content={REFRESH_SESSION}
                  primary
                  onClick={e => {
                    Providers.globalProvider.login();
                  }}
                />
              )}
              {apiStatus.errCode === 402 && (
                <UpgradeNeeded
                  toolbarText={`${toTitleCase(SETTINGS)} > ${toTitleCase(
                    TAGS.TAGS
                  )}`}
                  breadcrumbs={navs}
                  headerText={t('ticket.tags.no-access')}
                  subheaderText={t('ticket.tags.upgrade-plan')}
                />
              )}
            </>
          ) : (
            <>
              <KBListWrapper
                title={toTitleCase(TAGS.TAGS)}
                routeName={SETTINGS_PAGES.TAGS}
                headers={TAG_HEADERS}
                rows={data}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                breadcrumbs={navs}
                ifEmpty={{
                  header: t('ticket.add-ticket.tags.there-are-no-tags'),
                  subHeader: t('ticket.add-ticket.tags.get-started-by-clicking')
                }}
                onRowClickHandler={(id: number) => {
                  setTagId(id);
                  setNewOrEditDialog(true);
                }}
                onAddHandler={() => {
                  setTagId(0);
                  setNewOrEditDialog(true);
                }}
              />
              <Tags
                id={tagId.toString()}
                open={newOrEditDialog}
                onCancel={() => { setTagId(0); setNewOrEditDialog(false) }}
                onSuccessFullCall={fetchData}
              />
              <Dialog
                open={showDialog}
                onCancel={() => setShowDialog(false)}
                cancelButton={toTitleCase(BUTTONS.CANCEL)}
                confirmButton={toTitleCase(DELETE)}
                onConfirm={() => {
                  deleteTag();
                }}
                content={transformModalContent`${t('ticket.add-ticket.tags.delete-tag', {
                  recordToDelete: recordToDelete['Name']
                })}`}
                header={`${t('ticket.add-ticket.tags.header-delete-tag',{
                  recordToDelete: recordToDelete['Name']
                })}`}
                style={{ maxWidth: '500px' }}
              />
            </>
          )}

      </div>
      <MobileSettingsView />
    </CheckLogin>
  )
};
