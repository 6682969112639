import React, { useEffect, useState } from 'react';
import Styles from './AutomationFilter.module.css';

import { Button, ProviderConsumer } from '@fluentui/react-northstar';
import DeleteIcon from '../../../../../svg/delete.svg';
import { AutomationEvaluationTypes, AutomationOperators, ENTITY_TYPE } from '../../../../shared/utils/constants';
import { DatePicker } from '../../../../shared/components/DatePicker/DatePicker';
import { OperatorField } from './_/OperatorField/OperatorField';
import { PropertyField } from '../../../../shared/components/PropertyField/PropertyField';
import { ValueField } from '../../../../shared/components/ValueField/ValueField';
import { stubFalse } from 'lodash';
import { useTranslation } from 'react-i18next';
import { DatepickerLocalizations } from '../../../../App';
import { EventEvaluationType } from '../../../../shared/components/EventActionType/EventEvaluationType';

interface Props {
  propertiesList: DropdownDataModel[];
  filterData?: AutomationFilter;
  propertyData?: AutomationDoTicketInfo;
  filterIndex: number;
  groupIndex?: number;
  updateFilterData?: (
    groupIndex: number,
    filterIndex: number,
    data: AutomationFilter
  ) => void;
  updatePropertyData?: (
    filterIndex: number,
    data: AutomationDoTicketInfo
  ) => void;
  removeFilter?: (groupIndex: number, filterIndex: number) => void;
  whenData: AutomationWhenData;
  section: 'if' | 'do';
}

export const AutomationFilter = ({
  propertiesList,
  filterData,
  propertyData,
  filterIndex,
  groupIndex,
  updateFilterData,
  updatePropertyData,
  removeFilter,
  whenData,
  section
}: Props) => {
  enum FilterGroup {
    Value,
    Value2
  }
  
  const {t} = useTranslation();
  const [evaluationType, setEvaluationType] = useState('');
  const [property, setProperty] = useState('');
  const [operator, setOperator] = useState('');
  const [operator2, setOperator2] = useState('');
  const [lastOperator, setLastOperator] = useState('');
  const [lastOperator2, setLastOperator2] = useState('');
  const [value, setValue] = useState('');
  const [value2, setValue2] = useState('');
  const [operatorLabel, setOperatorLabel] = useState('');


  const [showTextField, setShowTextField] = useState(false);
  const [showTextField2, setShowTextField2] = useState(false);
  const [showDateField, setShowDateField] = useState(false);
  const [disableValueField, setDisableValueField] = useState(true);
  const [disableValueField2, setDisableValueField2] = useState(true);
  const [showValueField, setShowValueField] = useState(true);
  const [showValueField2, setShowValueField2] = useState(false);
  const [operatorDisabled, setOperatorDisabled] = useState(false);


  React.useEffect(() => {
    setProperty(
      section === 'if' ? filterData?.property : propertyData?.Property
    );

    setEvaluationType( filterData?.evaluationType=='' ? AutomationEvaluationTypes.None : filterData?.evaluationType);
    setOperator(filterData?.operator ?? '');
    setOperator2(filterData?.operator2 ?? '');
    setValue(section === 'if' ? filterData?.value : propertyData?.Value);
    setValue2(section === 'if' ? filterData?.value2 : '');

    if (lastOperator=="") setLastOperator(filterData?.operator ?? '');
    if (lastOperator2=="") setLastOperator2(filterData?.operator2 ?? '');
  }, [filterData, propertyData]);

  // to set showTextField value
  useEffect(() => {
    setShowTextField(showTextFieldRule(operator, lastOperator, FilterGroup.Value));
    setShowTextField2(showTextFieldRule(operator2, lastOperator2, FilterGroup.Value2));
  }, [property, operator, operator2]);

  // to disable value field
  useEffect(() => {
    disabledValueField(operator, setValue, setDisableValueField);
    disabledValueField(operator2, setValue2 ,setDisableValueField2);
  }, [operator,operator2]);

  useEffect(()=>{
    disabledValueField(operator, setValue, setDisableValueField);
    disabledValueField(operator2, setValue2 ,setDisableValueField2);

    switch (evaluationType){
      case AutomationEvaluationTypes.Changed:
        setShowValueField2(false);
        
        setOperatorLabel('Operator');

        if (filterData.property=="template"){
          setOperatorDisabled(evaluationType==AutomationEvaluationTypes.Changed);
        }
        else{
          resetOperatorAndValueFields();
          setOperatorDisabled(true);
        }
        break;
      case AutomationEvaluationTypes.ChangedFromTo:
        setShowValueField2(true);
        setOperatorLabel('From Operator');
        break;
      default:
        setOperatorLabel('Operator');
        setShowValueField2(false);
        setOperatorDisabled(false);
        break;
    }


  },[evaluationType]);

  // to show data field
  useEffect(() => {
    if (property.toLowerCase().includes('date')) {
      setShowDateField(true);
      setDisableValueField(false);
      setDisableValueField2(false);
    } else {
      setShowDateField(false);
    }
  }, [property]);

  useEffect(()=>{
    if (whenData!=undefined){
      if (whenData.onSchedule){
        setEvaluationType(AutomationEvaluationTypes.None);  
      }
      
    }

  },[whenData]);

  const showTextFieldRule = (_operator:string, _lastOperator:string, filterGroup:FilterGroup) =>{
    let operatorRequest = [
      AutomationOperators.Equal.toLowerCase(),
      AutomationOperators.NotEqual.toLowerCase(),
      AutomationOperators.Contains.toLowerCase(),
      AutomationOperators.NotContains.toLowerCase(),
      AutomationOperators.EndsWith.toLowerCase(),
      AutomationOperators.NotEndsWith.toLowerCase(),
      AutomationOperators.StartsWith.toLowerCase(),
      AutomationOperators.NotStartsWith.toLowerCase()
    ];


    switch(property.toLowerCase()){
      case "template":
        let templateOpt = [
          AutomationOperators.Equal.toString(),
          AutomationOperators.NotEqual.toString()
        ];

        let isOperatorOk = templateOpt.includes(_operator);
        let isLastOperatorOk = templateOpt.includes(_lastOperator);

         if (isOperatorOk != isLastOperatorOk && _lastOperator != _operator){
           switch (filterGroup)
           {
            case FilterGroup.Value:
              onValueChange("");
              break;
            case FilterGroup.Value2:
              onValue2Change("");
              break;
           }
         }

        

        return !isOperatorOk;
      case "affecteduserscount":
      case "collaboratorscount":
        return ([
          AutomationOperators.Equal.toString(),
          AutomationOperators.NotEqual.toString(),
          AutomationOperators.GreaterThan.toString(),
          AutomationOperators.GreaterThanEqualTo.toString(),
          AutomationOperators.LessThan.toString(),
          AutomationOperators.LessThanEqualTo.toString()
        ].includes(_operator));
      default:
        let stringProperty = ['Request','title','additionaldetails','ticketlifecyclephase.name','phases.name','activephase'];
        if ((property=="title" && (whenData!=undefined && whenData.entityName==ENTITY_TYPE.Lifecycle))) return false;
        else if ((property === 'comments' && _operator==AutomationOperators.IsPrivate)) return false;
        else return ((property.toLowerCase() === 'comments' || property === 'tags') &&
        section === 'if' &&
        [
          AutomationOperators.Contains.toLowerCase(),
          AutomationOperators.NotContains.toLowerCase()
        ].includes(_operator.toLowerCase())) ||
        (stringProperty.includes(property) &&
          (section === 'do' ||
            (section === 'if' &&
            operatorRequest.includes(_operator.toLowerCase())))) ||
          (property.toLowerCase().includes('comment') &&
          (section === 'do' || section === 'if'));
    }
  }
      

  const disabledValueField = (_operator:string, setValueField, setDisabled) =>{
    const disableValueFieldRule = [
      AutomationOperators.IsNull.toLowerCase(),
      AutomationOperators.IsNotNull.toLowerCase(),
      AutomationOperators.IsEmpty.toLowerCase(),
      AutomationOperators.IsNotEmpty.toLowerCase(),
      AutomationOperators.AddedByRequester.toLowerCase(),
      AutomationOperators.AddedByAssignee.toLowerCase(),
      AutomationOperators.Added.toLowerCase(),
      AutomationOperators.ByAnyone.toLowerCase(),
      AutomationOperators.ByAssignee.toLowerCase(),
      AutomationOperators.ByAutomation.toLowerCase(),
      AutomationOperators.ByRequestor.toLowerCase(),
      AutomationOperators.RelateTicket.toLowerCase(),
      AutomationOperators.MergeTicket.toLowerCase(),
      AutomationOperators.Removed.toLocaleLowerCase(),
      AutomationOperators.AddedbyAgent.toLocaleLowerCase(),
      AutomationOperators.AddedbyAffectedUser.toLocaleLowerCase(),
      AutomationOperators.AddedbyCollaborator.toLocaleLowerCase(),
      AutomationOperators.IsMentioned.toLocaleLowerCase()
    ].includes(_operator.toLowerCase());

    let disabled = false;
    if (evaluationType==AutomationEvaluationTypes.Changed || disableValueFieldRule){
      disabled=true;
      setValueField("");
    }

    setDisabled(disabled);
  }

  const resetOperatorAndValueFields = () => {
    setOperator('');
    setOperator2('');
    setValue('');
    setValue2('');
    setDisableValueField(section === 'if');
    setDisableValueField2(section === 'if');
  };

  const onPropertyChange = (property: string) => {
    setProperty(property);
    section === 'if'
      ? updateFilterData(groupIndex, filterIndex, {
          ...filterData,
          property,
          operator: '',
          operator2: '',
          value: '',
          value2: ''
        })
      : updatePropertyData(filterIndex, {
          ...propertyData,
          Property: property,
          Value: ''
        });
    resetOperatorAndValueFields();
  };

  const onevaluationTypeChange = (evaluationType: string) => {
    setEvaluationType(evaluationType)

      updateFilterData(groupIndex, filterIndex, {
          ...filterData,
          evaluationType,
          property,
          operator,
          operator2,
          value,
          value2
        });
  };

  const onOperatorChange = (_operator: string) => {
    setLastOperator(operator)

    //Need to separate it to different method because for the updateFilterData(...) to work since I renamed the parameter from operator to _operator
    setFirstOperator(_operator);
  };

  const setFirstOperator = (operator: string) =>{
    setOperator(operator);
    updateFilterData(groupIndex, filterIndex, { ...filterData, operator });
    setValue('');
  }

  const onOperator2Change = (_operator: string) => {
    setLastOperator2(operator2);

    //Need to separate it to different method because for the updateFilterData(...) to work since I renamed the parameter from operator to _operator
    setSecondOperator(_operator);
  };

  const setSecondOperator = (operator2: string) => {
    setOperator2(operator2);
    updateFilterData(groupIndex, filterIndex, { ...filterData, operator2 });
    setValue2('');
  };



  const onValueChange = (value: string) => {
    setValue(value);
    section === 'if'
      ? updateFilterData(groupIndex, filterIndex, { ...filterData, value })
      : updatePropertyData(filterIndex, {
          ...propertyData,
          Value: value
        });
  };

  const onValue2Change = (value2: string) => {
    setValue2(value2);
    section === 'if'
      ? updateFilterData(groupIndex, filterIndex, { ...filterData, value2 })
      : updatePropertyData(filterIndex, {
          ...propertyData
        });
  };

  const showevaluationType = () => section === 'if' && whenData.onEvent;

  return (
    <ProviderConsumer
      render={globalTheme => (
        <>
          <div className={`${Styles.GroupInputsContainer} ${Styles.Line}`}>
          
            {/* Property */}
            <PropertyField
              propertiesList={propertiesList}
              property={property}
              updateProperty={onPropertyChange}
              whenData={whenData}
            />

            {showevaluationType() && (
              <EventEvaluationType
              evaluationType={evaluationType}
              property={property}
              entityName={whenData?.entityName}
              updateEvaluationType={onevaluationTypeChange}
              />
            )}

            {/* Operator */}
            {section === 'if' && (
              <OperatorField
                propertyValue={property}
                operatorValue={operator}
                updateOperator={onOperatorChange}
                whenData={whenData}
                disabled={operatorDisabled}
                label={operatorLabel}
                evaluationType={evaluationType}
              />
            )}

            {/* Value */}
            {showDateField ? (
              <>
                <DatePicker
                  dateValue={value}
                  disableValueField={disableValueField}
                  onChangeHandler={value => {
                    onValueChange(value);
                  }}
                />

                {showValueField2 && 
                  <>
                    <OperatorField
                      propertyValue={property}
                      operatorValue={operator2}
                      updateOperator={onOperator2Change}
                      whenData={whenData}
                      disabled={operatorDisabled}
                      label="To Operator"
                      evaluationType={evaluationType}
                    />
                    <DatePicker
                      dateValue={value2}
                      disableValueField={disableValueField2}
                      onChangeHandler={value => {
                        onValue2Change(value);
                      }}
                    />
                  </>
                  
                }
              </>
              
            ) : (
              <>
                {showValueField && 
                  <ValueField
                    showTextField={showTextField}
                    propertyValue={property}
                    automationValue={value}
                    operatorValue={operator}
                    disableValueField={disableValueField}
                    fieldOperatorShow={section === 'if'}
                    updateValue={onValueChange}
                    whenData={whenData}
                  />
                }
                {showValueField2 && 
                  <>
                    <OperatorField
                      propertyValue={property}
                      operatorValue={operator2}
                      updateOperator={onOperator2Change}
                      whenData={whenData}
                      disabled={operatorDisabled}
                      label="To Operator"
                      evaluationType={evaluationType}
                    />
                    <ValueField
                    showTextField={showTextField2}
                    propertyValue={property}
                    automationValue={value2}
                    operatorValue={operator2}
                    disableValueField={disableValueField2}
                    fieldOperatorShow={section === 'if'}
                    updateValue={onValue2Change}
                    whenData={whenData}
                  />
                  </>
                  
                }
              </>
              
              
            )}
          </div>
          <div className={Styles.CloseIcon}>
            <Button
              icon={<DeleteIcon height={14} width={14} stroke={`${globalTheme.siteVariables.colorScheme.brand.foreground}`} />}
              text
              primary
              content={
                <span style={{ color: globalTheme.siteVariables.colorScheme.brand.foreground, fontSize: '12px' }}>
                  {t('automation.automation-filter.remove')}
                </span>
              }
              onClick={() => {
                removeFilter(groupIndex, filterIndex);
              }}
            />
          </div>
        </>
      )}
    />
  );
};
