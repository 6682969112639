import { Box, Text, Flex, Divider, ThemePrepared } from "@fluentui/react-northstar";
import React from "react";
import {  OwnedDevice } from "../shared/interfaces/platformuser.interface";
import { DateTime } from "luxon";
import { getDeviceIcon, GetOperatingSystem } from "./DeviceCard";
import { useTranslation } from "react-i18next";

export const OwnedDeviceCard = (props: { device: OwnedDevice, userId: string, globalTheme: ThemePrepared<any> }) => {
    const { t } = useTranslation();
    return (
        <div className={`px-3 pt-3 pb-2 mt-5 md:ml-5 filter drop-shadow-md rounded w-full md:w-80`} style={{
            background: props.globalTheme.siteVariables.colorScheme.default.background
        }}>
            <Flex className={`py-1 flex-col md:flex-row md:content-center`} space="between">
                <Box>
                    {getDeviceIcon(props.device.operatingSystem)}
                    <Text content={props.device.displayName} weight="semibold" className={`p-1`} />
                </Box>
                <Box>
                    <Text content={t('users.device-form.version')} size="small" />
                    <Text content={props.device.operatingSystemVersion} weight="semibold" size="small" />
                </Box>
            </Flex>
            <Divider />
            <div>
                <Flex className={`py-1 flex-col md:flex-row md:content-center`} space="between">
                    <Box>
                        <Flex vAlign="center">
                            <Text size="small" content={t('users.device-form.registered-on')} />
                            <Text style={{
                                maxWidth: "80px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                paddingLeft: "5px"
                            }} size="small" content={DateTime.fromISO(props.device.registrationDateTime).toFormat("MM.dd.yy")} weight="semibold" />
                        </Flex>
                    </Box>
                    <Box className={`pt-1 md:pt-0`}>
                        <Flex vAlign="center">
                            <Text size="small" content={t('users.device-form.join-type')} />
                            <Text size="small" content={props.device.trustType} weight="semibold" style={{
                                maxWidth: "80px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                paddingLeft: "5px"
                            }} />
                        </Flex>
                    </Box>
                </Flex>
                <Flex className={`py-1 flex-col md:flex-row md:content-center`} space="between">
                    <GetOperatingSystem operatingSystem={props.device.operatingSystem} />
                </Flex>
            </div>
        </div>
    )
}