import React, { useContext, useEffect, useState } from 'react';
import {
  Dialog,
  Alert,
  ThemePrepared,
  Button,
} from '@fluentui/react-northstar';
import { Toast } from '../toast/Toast';
import { Communication } from '@fluentui/react-teams';
import { Providers } from '@microsoft/mgt-element/dist/es6/providers/Providers';
import { Ticket } from '../../interfaces/ticket.interface';
import { BulkEditTicketForm } from './BulkEditTicketForm';
import { platformService } from '../../services/platform.service';
import {
  BULK_EDIT_VALUES,
  CONTACT_ADMIN,
  DEFAULT_API_STATUS,
  DONE,
  ERROR,
  STATUS_GUID,
  LIFECYCLE_STATUS_GUID,
  REFRESH_SESSION,
  SOMETHING_WENT_WRONG,
  UNAUTHORIZED_ACCESS,
} from '../../utils/constants';
import {
  ListContext,
  ListContextType,
} from '../../../tikit/List/ListContextProvider';
import { CustomViewContext } from '../../../tikit/toolbar/CustomViewContextProvider';
import { AppStateContext } from '../../../AppState';
import { getStatusIdByGuid } from '../../../tikit/ticketHelper';
import { ListFeatureStates } from '../../../tikit/List/List';
import { useTranslation } from 'react-i18next';

interface Props {
  globalTheme: ThemePrepared<any>;
  open: boolean;
  onClose: (value: boolean) => void;
  tickets: Ticket[];
  onSuccessfullyUpdate: (listContext: ListContextType, customViewStates: CustomViewStatesType) => void;
  listFeatureStates: ListFeatureStates;
}

const api = new platformService();

export const BulkEditTicketDialog = ({
  open,
  onClose,
  tickets,
  onSuccessfullyUpdate,
  listFeatureStates
}: Props) => {
  const {
    ticketStatus
  } = useContext(AppStateContext);
  const {t} = useTranslation();
  const [showEditTicketsDialog, setShowEditTicketsDialog] = useState(false);
  const { listStates, setListStates } = useContext(ListContext);
  const { customViewStates } = useContext(CustomViewContext);

  const [ticketData, setTicketData] =
    useState<BulkTicketEntity>(BULK_EDIT_VALUES);
  const [apiStatus, setAPIStatus] = useState<APIStatus>(
    DEFAULT_API_STATUS as APIStatus
  );
  const [hasError, setHasError] = useState({
    error: false,
    msg: '',
  });

  const [alert, setAlert] = useState({
    title: '',
    show: false,
    attributes: {},
  });

  useEffect(() => {
    setShowEditTicketsDialog(open);
  }, [open]);

  const onOpen = () => {
    setAlert({ title: '', show: false, attributes: {} });
    setTicketData(null);
  };

  const updateAPIStatus = (newState: Partial<APIStatus>) => {
    setAPIStatus({ ...apiStatus, ...newState });
  };

  const skeletonLoading = (Id: number) => {
    setListStates((prev: any) => {
      // change current reference to heap to make sure state was changed.
      const _tickets: Ticket[] = prev.tickets.map((ticket: Ticket) => {
        if (ticket.Id === Id) {
          return {
            ...ticket,
            Title: 'temp_ticket',
          };
        }
        return ticket;
      });

      return {
        ...prev,
        tickets: _tickets,
      };
    });
  };

  const submit = async () => {
    let ticket = Object.keys(ticketData).reduce((acc, key) => {
      if (ticketData[key].required) {
        acc[key] = ticketData[key].value;
      }
      return acc;
    }, {} as BulkTicketEntity);

    const hasTicketValue = Object.keys(ticket).length > 0;

    if (!hasTicketValue) {
      return setHasError({
        error: true,
        msg: t('ticket-details.edit-ticket-view.please-enter-at-least-one-field-to-update'),
      });
    }

    const isValid = Object.keys(ticket).every((key) => ticket[key] !== null);

    if (!isValid) {
      return setHasError({
        error: true,
        msg: t('ticket-details.edit-ticket-view.please-enter-values-to-required-fields-to-update'),
      });
    }
    onClose(false);

    const lifecycleTicketsId = [];
    const ticketsId = [];
    const closeStatusId = getStatusIdByGuid(ticketStatus, STATUS_GUID.CLOSED);
    const resolveStatusId = getStatusIdByGuid(ticketStatus, STATUS_GUID.RESOLVED);
    const ticketStatusId = (ticket["StatusId"]) ? Number(ticket["StatusId"]) : 0;
    tickets.forEach(item => {
      skeletonLoading(item.Id);
      const lifecycleIsActive = (item.TicketLifecycle?.Status ?? false) && [LIFECYCLE_STATUS_GUID.NOT_STARTED, LIFECYCLE_STATUS_GUID.IN_PROGRESS].indexOf(item.TicketLifecycle.Status.Guid) >= 0;
      if(listFeatureStates.useTicketLifecycle && lifecycleIsActive && (ticketStatusId == closeStatusId || ticketStatusId== resolveStatusId )){
        lifecycleTicketsId.push(item.Id);
      }else
        ticketsId.push(item.Id);
    });


    if (ticketData.RequesterId.required) {
      const { Id } = await api.getOrCreatePlatformUser(ticketData.RequesterId.value);
      ticket = {
        ...ticket,
        RequesterId: Id,
      };
    }

    doBulkEdit(ticketsId, lifecycleTicketsId, ticket);
  };

  const doBulkEdit = async (ticketsId: number[], lifecycleTicketsId: number[], ticket: BulkTicketEntity) => {
    
    const updatedTickets = ticketsId.map((Id) => ({
      Id: Id,
      ...ticket,
    }));

    updatedTickets.forEach((ticketItem) => {
      if (ticketItem.TeamId && ticketItem.TeamId.toString() === '-1')
        ticketItem.TeamId = null;
      if (ticketItem.SupportGroupId && ticketItem.SupportGroupId.toString() === '-1') 
        ticketItem.SupportGroupId = null;
      if (ticketItem.AssigneeId && ticketItem.AssigneeId.toString() === '-1') 
        ticketItem.AssigneeId = null;
    });

    try {

      if (ticketsId.length > 0) {
        await api.bulkEditTickets(ticketsId, JSON.stringify(updatedTickets[0]));

        setShowEditTicketsDialog(false);
        updatedTickets.forEach((item) => { Toast.success(t('ticket-details.edit-ticket-view.ticket-updated-successfully', { ticketId: item.Id })); });
        setAPIStatus({ ...apiStatus, status: DONE });
        setHasError({ error: false, msg: '', });
      }

      lifecycleTicketsId.forEach((id) => {
        Toast.error(`Ticket #${id} has an active lifecycle and cannot be moved to resolved or closed.`);
      });

      setTicketData(BULK_EDIT_VALUES);
      onSuccessfullyUpdate({ listStates, setListStates }, customViewStates);
    } catch (error: any) {
      setHasError({ error: false,  msg: '', });
      setTicketData(BULK_EDIT_VALUES);
      onSuccessfullyUpdate({ listStates, setListStates }, customViewStates);
      updateAPIStatus({ status: ERROR, errCode: error.response?.status ?? 500, });
      Toast.error((error.response) ? t('ticket-details.edit-ticket-view.failed-to-updated-the-tickets') : t('ticket-details.edit-ticket-view.update-request-timed-out'));
    }
  };

  const errConfig = {
    fields: { 
      title: apiStatus.errCode === 401 ? UNAUTHORIZED_ACCESS : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN,
    },
  };

  return (
    <Dialog
      className={`responsive-dialog`}
      style={{ width: 600 }}
      closeOnOutsideClick={false}
      cancelButton={{ content: t('ticket-details.edit-ticket-view.cancel') }}
      confirmButton={{
        content: t('ticket-details.edit-ticket-view.update'),
        primary: true,
      }}
      onConfirm={submit}
      onCancel={() => {
        setShowEditTicketsDialog(false);
        setTicketData(BULK_EDIT_VALUES);
        setHasError({
          error: false,
          msg: '',
        });
        onClose(false);
      }}
      open={showEditTicketsDialog}
      onOpen={onOpen}
      content={
        <div className='relative'>
          {apiStatus.status === ERROR ? (
            <>
              <div style={{ height: '100%' }}>
                <Communication {...errConfig} />
                {apiStatus.errCode === 401 && (
                  <Button
                    content={REFRESH_SESSION}
                    primary
                    onClick={(_e) => {
                      Providers.globalProvider.login();
                    }}
                  />
                )}
              </div>
            </>
          ) : (
            <>
              {hasError.error && (
                <Alert content={hasError.msg} danger visible />
              )}
              <BulkEditTicketForm
                ticketData={ticketData}
                setTicketData={setTicketData}
              />
            </>
          )}
        </div>
      }
      header={{
        content: (
          <div style={{ width: '100%' }}>
            <h3 style={{ marginTop: 0 }}>{t('ticket-details.edit-ticket-view.update-tickets')}</h3>
            {alert.show && (
              <Alert
                fitted
                {...alert.attributes}
                content={alert.title}
                style={{
                  fontSize: 12,
                  width: '100%',
                }}
              />
            )}
          </div>
        ),
        style: { width: '100%' },
      }}
    />
  );
};
