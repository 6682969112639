import React from 'react';
import ReactDOM from 'react-dom';
import './i18n/config.ts';
import App from './components/App';
import reportWebVitals from './report-web-vitals';
import { BrowserRouter } from 'react-router-dom';
import * as microsoftTeams from "@microsoft/teams-js";
import { TeamsSSOProvider, TeamsSSOConfig, HttpMethod } from './components/shared/providers/TeamsSSOProvider';
import { Providers, ProviderState } from '@microsoft/mgt-element';
import { Msal2Provider } from './components/shared/providers/Msal2Provider';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import * as jwt from 'jsonwebtoken';

initializeIcons();

import './responsive.css';
import './styles/main.css';
import AppStateProvider from './components/AppState';
import { ConsentRequired } from './components/config/ConsentConfig';
import CustomViewContextProvider from './components/tikit/toolbar/CustomViewContextProvider';

const race = Promise.race([
  new Promise((resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      reject('Timed out in 1000 ms.');
    }, 1000);
  }),
  new Promise(resolve => { 
    microsoftTeams.app.initialize().then(() => { resolve(''); });
  })
]);

race
  .then(() => {
    localStorage.setItem('isInTeams', 'true');

    TeamsSSOProvider.microsoftTeamsLib = microsoftTeams;
    const scopes = ConsentRequired.Scopes;
    const config: TeamsSSOConfig = {
      clientId: window.__runtimeConfig.msalClientId,
      authPopupUrl: window.location.origin + '/auth.html', // see below for creating the popup page
      scopes: scopes,
      ssoUrl: `${window.__runtimeConfig.platformurl}api/GetSsoToken`,
      autoConsent: false,
      httpMethod: HttpMethod.POST
    };

    const provider = new TeamsSSOProvider(config);
    Providers.globalProvider = provider;
  })
  .catch(() => {
    let user = undefined;
    let isExpired = false;
    let token = localStorage.getItem("token") || localStorage.getItem(`msal.${window.__runtimeConfig.msalClientId}.idtoken`);
    if (token) {
        const decoded: { [key: string]: any; } = jwt.decode(token);
        user = decoded.preferred_username ?? decoded.unique_name;

        isExpired = new Date() >= new Date(decoded.exp * 1000);
    }

    const provider = new Msal2Provider({
      clientId: window.__runtimeConfig.msalClientId,
      scopes: ConsentRequired.Scopes,
      redirectUri: '/',
      loginHint: isExpired ? user : undefined
    });
    Providers.globalProvider = provider;

    if (isExpired || !token) {
      provider.trySilentSignIn().finally(() => {
        if (provider.state === ProviderState.SignedOut) provider.login();
      });
    }

    localStorage.setItem('isInTeams', 'false');
  })
  .finally(() => {
    ReactDOM.render(
      <BrowserRouter>
        <AppStateProvider>
          <CustomViewContextProvider>
            <App />
          </CustomViewContextProvider>
        </AppStateProvider>
      </BrowserRouter>,

      document.getElementById('root')
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
