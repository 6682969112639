import React from 'react';
import { RelateTicket } from '../RelateTicketDialog';
import { RelationType } from '../RelateTicketHelper';
import { MergeConfirmation } from './ConfirmationPages/MergeConfirmation';
import { RelateConfirmation } from './ConfirmationPages/RelateConfirmation';

export interface Props {
  relatedTicketData: RelateTicket;
  oldRelatedTicketIds: number[];
}

export const RelateTicketsConfirmation = ({ relatedTicketData, oldRelatedTicketIds }: Props) => {

  const renderPage = () => {
    switch (relatedTicketData.relationShipType) {
      case RelationType.MergeTickets:
        return <MergeConfirmation relatedTicketData={relatedTicketData} />;
      case RelationType.RelateTickets:
        return <RelateConfirmation oldRelatedTicketIds={oldRelatedTicketIds} relatedTicketData={relatedTicketData} />;
      default:
        return <></>;
    }
  }

  return renderPage();
};
