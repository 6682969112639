import React from 'react';
import CriteriaStyle from './TicketCriteria.module.css';
import { FormSubLabel } from '../../../shared/components/FormSubLabel/FormSubLabel';
import {
  CRITERIA_META_DATA_FILTER,
  DEFAULT_SLA_TICKET_CRITERIA,
  SLA
} from '../../../shared/utils/constants';
import { FilterGroups } from './FilterGroups/FilterGroups';
import { cloneDeep } from 'lodash';
import { Arrow } from '../../../automation/Sections/Do/_/Arrow/Arrow';
import { AddIcon, Button, ProviderConsumer } from '@fluentui/react-northstar';
import { ticketCriteriaValidation } from '../../utils/slaHelper';
import { themeNames } from '@fluentui/react-teams';
import { useTranslation } from 'react-i18next';

interface Props {
  propertiesList: DropdownDataModel[];
  ruleGroup: SlaTicketCriteria;
  updateRuleGroup: (ruleGroup: SlaTicketCriteria) => void;
}

export const ARROW_HEIGHT = 100;

export const TicketCriteria = ({
  propertiesList,
  ruleGroup,
  updateRuleGroup
}: Props) => {
  const {t} = useTranslation();
  const updateCriteriaMetaDataHandler = (data: CriteriaMetaData[]) => {
    const _ruleGroup = cloneDeep(ruleGroup);
    _ruleGroup.criteriaMetaData = [...data];
    updateRuleGroup(_ruleGroup);
  };

  const addNewFilter = (data: CriteriaMetaData = CRITERIA_META_DATA_FILTER) => {
    let _ruleGroup = cloneDeep(ruleGroup);
    _ruleGroup = {
      ..._ruleGroup,
      arrowHeight:
        _ruleGroup.arrowHeight <= 0 ? 50 : _ruleGroup.arrowHeight + ARROW_HEIGHT
    };
    _ruleGroup.criteriaMetaData.push({
      ...data
    });
    updateRuleGroup(_ruleGroup);
  };

  const removeCriteriaHandler = (filterIndex: number) => {
    let _ruleGroup = cloneDeep(ruleGroup);
    if (_ruleGroup.criteriaMetaData.length === 1) {
      return updateRuleGroup(null);
    }
    _ruleGroup = {
      ..._ruleGroup,
      arrowHeight: _ruleGroup.arrowHeight - ARROW_HEIGHT
    };
    _ruleGroup.criteriaMetaData.splice(filterIndex, 1);
    updateRuleGroup(_ruleGroup);
  };

  const updateRuleGroupTypeHandler = (type: GroupType) => {
    const _ruleGroup = cloneDeep(ruleGroup);
    _ruleGroup.logicOperator = type;
    updateRuleGroup(_ruleGroup);
  };

  return (
    <ProviderConsumer
      render={globalTheme => (
        <div className={CriteriaStyle.CriteriaSection}>
          <h2 className={`text-base mb-2 font-bold`}>
            {t('sla.sla-ticket-criteria.ticket-criteria')}{' '}
            <span className="text-lg text-purple-300 font-light">({t('sla.sla-ticket-criteria.optional')})</span>
          </h2>
          <FormSubLabel
            content={t('sla.sla-ticket-criteria.ticket-filter')}
          />
              {!ruleGroup && (
                <div className="my-3">
                  <div className="text-right mb-1">
                    <Button
                      icon={<AddIcon styles={{ color: globalTheme.siteVariables.colorScheme.brand.foreground }} />}
                      text
                      content={<span style={{ color: globalTheme.siteVariables.colorScheme.brand.foreground }}>{t('sla.add-filter')}</span>}
                      onClick={() => {
                        updateRuleGroup(DEFAULT_SLA_TICKET_CRITERIA);
                      }}
                    />
                  </div>
                  <p
                    className={`py-1.5 px-2.5 text-center text-base ${CriteriaStyle.OptionalPara}`}
                    style={{
                      color: `${globalTheme.siteVariables.theme === themeNames.Dark ? 'white' : 'black'}`,
                      background: `${globalTheme.siteVariables.theme === themeNames.Dark ? 'rgb(51, 51, 51)' : '#e5f0ff'}`
                    }}
                  >
                    {t('sla.sla-ticket-criteria.you-can-optionally')}
                    <span
                      className="font-bold cursor-pointer mx-2"
                      style={{ color: globalTheme.siteVariables.colorScheme.brand.foreground }}
                      onClick={() => {
                        updateRuleGroup(DEFAULT_SLA_TICKET_CRITERIA);
                      }}
                    >
                      {t('sla.sla-ticket-criteria.add-filters')}
                    </span>
                    {t('sla.sla-ticket-criteria.filter-detail')}
                  </p>
                </div>
              )}

              {ruleGroup && (
                <>
                  <div className={`relative ${CriteriaStyle.Groups}`}>
                    {ruleGroup.arrowHeight > 0 && (
                      <div className={CriteriaStyle.TicketCriteriaArrowContainer}>
                        <Arrow height={ruleGroup.arrowHeight} />
                      </div>
                    )}
                    <FilterGroups
                      propertiesList={propertiesList}
                      ruleGroup={ruleGroup}
                      updateCriteriaMetaData={updateCriteriaMetaDataHandler}
                      addNewFilter={addNewFilter}
                      removeFilter={removeCriteriaHandler}
                      updateRuleGroupType={updateRuleGroupTypeHandler}
                    />
                  </div>
                  {!ticketCriteriaValidation(ruleGroup.criteriaMetaData) &&
                    ruleGroup.criteriaMetaData.every(
                      criteria => criteria.property
                    ) && (
                      <p
                        className={`text-center my-2.5 py-1.5 ${CriteriaStyle.SelectValueError}`}
                      >
                        {SLA.SLA_REQUIRED_FIELDS}
                      </p>
                    )}
                </>
              )}
        </div>
      )}
    />
  );
};
