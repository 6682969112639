import _ from 'lodash';
import React from 'react';
import { BoardTheme } from '@fluentui/react-teams/lib/esm/components/Board/BoardTheme';
import {
  Box,
  SiteVariablesPrepared,
  ProviderConsumer as FluentUIThemeConsumer,
} from '@fluentui/react-northstar';
import MyWorkBoardView from './BoardView';

interface WorkProps {
  [key: string]: any;
}

export interface IMyWorkBoardView {
  siteVariables: SiteVariablesPrepared;
}
export interface IMyWorkBoardViewTickets extends IMyWorkBoardView {
  tickets?: Ticket[];
}

export interface Element {
  title: string;
  template: JSX.Element | JSX.Element[];
  hidden?: boolean;
}

const MyWorkBoard = () => <MyWorkBoardTheme />;

export default MyWorkBoard;

const MyWorkBoardTheme: React.FC<WorkProps> = () => (
  <FluentUIThemeConsumer
    render={(globalTheme) => {
      return (
        <BoardTheme globalTheme={globalTheme} style={{ height: '100%' }}>
          <Box
            variables={({ colorScheme }: SiteVariablesPrepared) => ({
              backgroundColor: colorScheme.default.background2,
            })}
            className={`h-full`}
            style={{ padding: '0 10px 0 10px', marginTop: '-5px' }}
          >
            <MyWorkBoardView
              key={'myworklist'}
              siteVariables={globalTheme.siteVariables}
            />
          </Box>
        </BoardTheme>
      );
    }}
  />
);
