import React, { useContext, useEffect, useReducer, useState } from 'react';
import { AppStateContext } from '../../AppState';
import { Layout, Loader } from '@fluentui/react-northstar';
import { Ticket } from '../../shared/interfaces/ticket.interface';
import {
  getCategory,
  getGroup,
  getPriority,
  getStatusValue,
  getTicketType,
  getUserData,
  getTeam,
} from '../ticketHelper';
import { customClassNames } from '../../shared/utils/constants';
import { TicketCardSkeleton } from '../../shared/components/TicketCard/TicketCardSkeleton';
import { TicketDetailCard } from '../EditTicket/TicketDetailCard';
import { useHistory } from 'react-router-dom';
import { EmptyData } from '../../shared/components/EmptyData';
import FlashlightSVG from './../../../svg/flashlight.svg';
import { useTranslation } from 'react-i18next';
import ListFunctions from '../List/ListFunctions';
import useCustomViewsTickets from '../List/useCustomViewsTickets';
import { ListContext } from '../List/ListContextProvider';

interface Props {
  className: string;
  idColorPurple: boolean;
  isTicketsFetching: boolean;
  setLoading: (data: boolean) => void;
  defaultFilters: FilterPropsItems[]
}

const ACTIONS = {
  SET_LIST: 'set-list',
  SET_CDM_USERS: 'set-cdm-users',
  LOADING: 'loading',
  SUCCESS: 'success'
};

const initialState = {
  list: [],
  users: [],
  loading: true
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_LIST:
      return {
        ...state,
        list: action.data
      };

    case ACTIONS.SET_CDM_USERS:
      return {
        ...state,
        users: action.data
      };

    case ACTIONS.SUCCESS:
      return {
        ...state,
        loading: false
      };

    default:
      throw new Error('Unexpected action');
  }
};

export const MobileList = ({ className, idColorPurple, setLoading, isTicketsFetching, defaultFilters }: Props) => {
  const [listState, listDispatch] = useReducer(reducer, initialState);
  const state = useContext(AppStateContext);
  const history = useHistory();
  const { t } = useTranslation();
  const [ticketList, setTicketList] = useState([]);
  const [page, setPage] = useState(1);
  const { listStates } = useContext(ListContext);
  const [ticketCount, setTicketCount] = useState<number>(0);
  const [isAppending, setIsAppending] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { getpaginatedTickets } = ListFunctions();

  useCustomViewsTickets({ defaultFilters }); // Get tickets filtered by custom views

  const getTicketProperties = (ticket: Ticket) => {
    return {
      Assignee: ticket.AssigneeId == null ? 'Unassigned' : getUserData(ticket.AssigneeId, state),
      Status: ticket.StatusId == null ? 'No Status' : { Value: getStatusValue(ticket.StatusId, state.ticketStatus) },
      Team: ticket.TeamId == null ? '' : { FriendlyName: getTeam(ticket.TeamId, state)?.FriendlyName },
      SupportGroup: ticket.SupportGroupId == null ? '' : { Name: getGroup(ticket.SupportGroupId, state)?.Name },
      Requester: ticket.RequesterId == null ? 'Unassigned' : getUserData(ticket.RequesterId, state),
      Category: ticket.CategoryId == null ? 'No Category' : getCategory(ticket.CategoryId, state.categories),
      Priority: ticket.PriorityId == null ? 'No Priority' : getPriority(ticket.PriorityId, state.priority),
      TicketType: ticket.TicketTypeId == null ? 'No Type' : getTicketType(ticket.TicketTypeId, state.ticketTypes)
    };
  };

  useEffect(() => {
    if (!isTicketsFetching) {
      fetchTicket();
    }
  }, [state.listFilter, state.filterDropdown.queryValue, isTicketsFetching])

  useEffect(() => {
    setPage(1);
    setTicketList([]);
    setTicketCount(0);
    listDispatch({ type: ACTIONS.SET_LIST, data: [] });
  }, [state.listFilter, state.filterDropdown.queryValue])

  useEffect(() => {
    setIsFetching(true);
  }, [state.filterDropdown.queryValue])

  useEffect(() => {
    if (page > 1) {
      fetchTicket();
    }
  }, [page]);

  useEffect(() => {
    const newList = [];
    ticketList.forEach(ticket => {
      const listElement = ticket.Title === 'temp' ? (<TicketCardSkeleton />) : (
        <Layout
          className={`m-2`}
          renderMainArea={() => (
            <div onClick={() => { history.push(`/tickets/${ticket.Id}`) }} style={{ marginBottom: '0.2rem' }}>
              <TicketDetailCard
                isBoardMobileView={true}
                ticketData={ticket}
                idColorPurple={idColorPurple}
              />
            </div>
          )}
        />
      );
      newList.push(listElement);
    });

    listDispatch({ type: ACTIONS.SET_LIST, data: newList });
    setLoading(false);
  }, [ticketList]);

  useEffect(() => {
    const _tickets = listStates.tickets.map(ticket => ({ ...ticket, ...getTicketProperties(ticket) }))
    if (page !== 1) {
      setTicketList([...ticketList, ..._tickets]);
    } else {
      setTicketList(_tickets);
    }
    setTicketCount(listStates.countTickets);
    setIsAppending(false);
    setIsFetching(listStates.isTicketsFetching);
  }, [listStates])

  const fetchTicket = async () => {
    let _page = page;

    if (_page == 1) {
      setLoading(true);
    }

    const tickets = getpaginatedTickets({ page: page });
    tickets.finally(() => {
      setIsAppending(false);
    })
  }

  const onScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    const isNearBottom = scrollTop + clientHeight >= scrollHeight;
    if (isNearBottom && !isAppending && ticketCount > ticketList.length) {
      setIsAppending(true);
      setPage(page + 1);
    }
  };

  return (
    <div className={`${customClassNames.tikitBoardBody} md:hidden`} onScroll={onScroll}>
      <div className={`${className} p-3 mt-3`}>

        {isFetching &&
          <Loader />
        }
        {!isFetching &&
          <>
            {listState?.list.length > 0 ? (listState.list) : (
              <EmptyData
                SVGIcon={<FlashlightSVG width={200} height={200} className={'pb-2'} />}
                headerText={t('ticket.mobile-view.empty.header')}
                subheaderText={t('ticket.mobile-view.empty.sub-header')}
              />
            )}
          </>
        }
        {isAppending &&
          <Loader />
        }
      </div>
    </div>

  );
};
