import React from 'react';
import Styles from './BoardTooltip.module.css';
import { Box, ProviderConsumer } from '@fluentui/react-northstar';
import { threedigitized } from '../../../../components/tikit/ticketHelper';
import { ThemeColorScheme } from '../../../../components/shared/common/TeamsTheme';

interface Props {
  children: any;
  sourceTicketId: number;
}

export const BoardTooltip = ({ children, sourceTicketId }: Props) => {
  return (
    <ProviderConsumer
      render={globalTheme => (
        <div
          className={`${Styles.tooltip}`}
          style={{
            position: 'relative',
            ...ThemeColorScheme(globalTheme.siteVariables)
          }}
        >
          <Box className={`ml-1 cursor-pointer`}>{children}</Box>
          {sourceTicketId > 0 && (
            <div className={`${Styles.tooltipcontent}`}>
              <div>
                <div className={`font-sans font-medium p-2`}>
                    Merged with Ticket <b>#{threedigitized(sourceTicketId)}</b>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    />
  );
};
