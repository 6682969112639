import React, { useContext, useEffect, useState } from 'react';
import ToolbarCSS from '../../../Toolbar.module.css';
import Tree, {
  moveItemOnTree,
  ItemId,
  TreeSourcePosition,
  TreeDestinationPosition,
  TreeItem,
} from '@atlaskit/tree';
import { ColumnDragItem } from './ColumnDragItem/ColumnDragItem';
import FlashlightSVG from './../../../../../../svg/flashlight.svg';
import { EmptyData } from '../../../../../shared/components/EmptyData';
import { CustomViewContext } from '../../../CustomViewContextProvider';
import { ListFeatureStates } from '../../../../List/List';
import { useTranslation } from 'react-i18next';

type Props = {
  columns: FEViewColumn[];
  isDarkMode: boolean;
  onDragEndHandler: (items: ItemId[]) => void;
  filterColumnsByFeature: (item: FEViewColumn) => void;
};

interface TreeData {
  rootId: ItemId;
  items: Record<ItemId, TreeItem>;
}

const TREE_INITIAL = {
  rootId: '0',
  items: {
    '0': {
      id: '0',
      children: [],
      hasChildren: true,
      isExpanded: false,
      isChildrenLoading: false,
      data: {
        title: 'root',
      },
    },
  },
};

export const ColumnTreeWrapper = ({ columns, isDarkMode, onDragEndHandler, filterColumnsByFeature }: Props) => {
  const [columnsList, setColumnsList] = useState<TreeData>(TREE_INITIAL);
  const { customViewStates, setCustomViewStates } = useContext(CustomViewContext);
  const {t} = useTranslation();

  const makeTreeColumnItems = (_items: FEViewColumn[]) => {
    _items = _items.filter((item: FEViewColumn) => {
      return filterColumnsByFeature(item);
    });
    const { columns: { columnsKeyOrder } } = customViewStates
    const _itemsId = _items.map((item) => item.id);

    const _itemsOrder = [
      ...columnsKeyOrder.filter((_preCol) => _itemsId.includes(Number(_preCol))),
      ..._itemsId.filter((col) => !columnsKeyOrder.includes(col)),
    ];
    const _treeItems = _items.reduce(
      (_acc, _item) => ({
        ..._acc,
        [_item.id.toString()]: {
          id: _item.id.toString(),
          children: [],
          hasChildren: false,
          isExpanded: false,
          isChildrenLoading: false,
          data: {
            ..._item,
          },
        },
      }),
      {}
    );
    setColumnsList({
      ...TREE_INITIAL,
      items: {
        '0': {
          id: '0',
          children: _itemsOrder,
          hasChildren: true,
          isExpanded: false,
          isChildrenLoading: false,
          data: {
            title: 'root',
          },
        },
        ..._treeItems,
      },
    });

    setCustomViewStates((prev): CustomViewStatesType => {
      return {
        ...prev,
        columns: { ...prev.columns, columnsKeyOrder: _itemsOrder }
      }
    });
  };

  useEffect(() => {
    makeTreeColumnItems(columns);
  }, [columns]);

  useEffect(() => {
    if (columnsList.items[0].children.length > 0)
      onDragEndHandler(columnsList.items[0].children);
  }, [columnsList]);

  const onDragEnd = (source: TreeSourcePosition, destination: TreeDestinationPosition) => {
    const tree = { ...columnsList };
    if (!destination) return;
    const newTree = moveItemOnTree(tree, source, destination);

    setCustomViewStates((prev): CustomViewStatesType => ({
      ...prev,
      columns: { ...prev.columns, columnsKeyOrder: newTree.items[destination.parentId].children }
    }));
    setColumnsList(newTree);
  };

  return (
    <div className={`h-80 overflow-auto pt-2.5 pl-2.5 pb-2.5 pr-2.5 rounded-sm ${ToolbarCSS.ColumnContainer}`}>
      {columns.length > 0 ? (
        <div>
          <h2 className='mb-3 font-semibold'>{t('ticket.ticket-list.configure-column.rearrange-columns')}</h2>
          <Tree
            tree={columnsList}
            renderItem={ColumnDragItem({ customViewStates, setCustomViewStates })}
            onDragEnd={onDragEnd}
            offsetPerLevel={8 * 2}
            isDragEnabled
          />
        </div>
      ) : (
        <EmptyData
          SVGIcon={<FlashlightSVG width={110} height={110} className={'mb-4'} />}
          headerText={t('ticket.ticket-list.configure-column.no-columns-selected-yet')}
        />
      )}
    </div>
  );
};
