import React, { useLayoutEffect, useState, useRef, useEffect } from "react";
import omit from "lodash/omit";
import debounce from "lodash/debounce";
import { useHistory, useParams } from "react-router-dom";
import { UserAvatar } from "../shared/components/UserAvatar";

import styles from "../kb.module.css";

import {
  Button,
  Checkbox,
  Popup,
  Menu,
  Dialog,
  MenuButton,
  PropsOfElement,
  ProviderConsumer as FluentUIThemeConsumer,
  ShorthandCollection,
  Table as FluentUITable,
  TableCellProps,
  TableRowProps,
  dialogBehavior,
  gridCellWithFocusableElementBehavior,
  MenuItem,
  Text
} from "@fluentui/react-northstar";

import {
  ArrowDownIcon,
  ArrowUpIcon,
  AcceptIcon,
  ChevronDownIcon,
  MoreIcon,
  OpenOutsideIcon,
  TrashCanIcon
} from "@fluentui/react-icons-northstar";

import { SiteVariablesPrepared } from "@fluentui/styles";

import Icon from "@fluentui/react-teams/lib/esm/lib/Icon";

import { KBTableTheme } from "./KBTableTheme";

import getBreakpoints, {
  IColumn,
  accessoryWidth,
  columnMinWidth,
  TSortable
} from "@fluentui/react-teams/lib/esm/components/Table/tableBreakpoints";

import { TActions, actionKey } from "@fluentui/react-teams/lib/esm";
import { TeamsTheme } from "@fluentui/react-teams/lib/esm/themes";
import {
  getText,
  TTranslations
} from "@fluentui/react-teams/lib/esm/translations";
import { RowDialog, RowDialogAction } from "./KBRowDialog";
import { KBForm } from "./KBFormDialog";
import { kbDataService, KBStateService } from "../shared/services/kb.service";
import { Person, People } from "@microsoft/mgt-react";
import { AppStateContext, useSetState } from "../AppState";
import { AppKBStateContext, useSetKBState } from "../AppKBState";

export type columnKey = string;
export type rowKey = string;
type sortOrder = [columnKey | "__rowKey__", "asc" | "desc"];

export type TSelected = Set<rowKey>;

export interface IRow {
  [columnKey: string]: string | TActions | undefined;
  actions?: TActions;
}

export type TTableInteraction = {
  event: "click";
  target: "table";
  subject: rowKey | rowKey[];
  action: actionKey;
};

export interface ITableProps extends PropsOfElement<"div"> {
  title?: string;
  columns: { [columnKey: string]: IColumn };
  rows: { [rowKey: string]: IRow };
  truncate?: boolean;
  selectable?: boolean;
  onSelectedChange?: (selected: TSelected) => TSelected;
  findQuery?: string;
  filterBy?: (row: IRow) => boolean;
  onInteraction?: (interaction: TTableInteraction) => void;
}

interface ISortOrderIndicatorProps {
  columnKey: columnKey;
  sortOrder: sortOrder;
}

const SortOrderIndicator = ({
  columnKey,
  sortOrder
}: ISortOrderIndicatorProps) => {
  const [sortOrderKey, sortOrderDirection] = sortOrder;
  if (columnKey === sortOrderKey) {
    if (sortOrderDirection === "asc")
      return (
        <>
          <ArrowUpIcon
            outline
            styles={{ marginRight: ".25rem", marginLeft: ".5rem" }}
          />
          <ChevronDownIcon outline size="small" />
        </>
      );
    else
      return (
        <>
          <ArrowDownIcon
            outline
            styles={{ marginRight: ".25rem", marginLeft: ".5rem" }}
          />
          <ChevronDownIcon outline size="small" />
        </>
      );
  } else return <ChevronDownIcon outline size="small" />;
};

const ariaSort = ({
  columnKey,
  sortOrder
}: ISortOrderIndicatorProps): {
  "aria-sort": "ascending" | "descending" | undefined;
} => {
  const [sortOrderKey, sortOrderDirection] = sortOrder;
  if (columnKey === sortOrderKey) {
    if (sortOrderDirection === "asc") return { "aria-sort": "ascending" };
    else return { "aria-sort": "descending" };
  } else return { "aria-sort": undefined };
};

const defaultSortOrder: sortOrder = ["__rowKey__", "desc"];

const passThrough = (arg: any) => arg;

export const KBTable = (props: ITableProps) => {
  const history = useHistory();
  const tableTitle = props.title;
  const rowKeys = Object.keys(props.rows);
  const columnKeys = Object.keys(props.columns);

  const [sortOrder, setSortOrder] = useState<sortOrder>(defaultSortOrder);

  const [selected, setSelected] = useState<TSelected>(new Set());
  const propagateSetSelected = (selected: TSelected) =>
    setSelected((props.onSelectedChange || passThrough)(selected));

  const selectedIndeterminate =
    selected.size > 0 && selected.size < rowKeys.length;

  const hasActions =
    rowKeys.findIndex(rowKey => props.rows[rowKey].hasOwnProperty("actions")) >=
    0;

  const breakpoints = getBreakpoints(
    props.columns,
    hasActions,
    !!props.selectable
  );

  const $tableWrapper = useRef<HTMLDivElement | null>(null);

  const [inFlowColumns, setInFlowColumns] = useState<Set<columnKey>>(
    // start by displaying all columns (in case of SSR)
    breakpoints.get(Infinity)!
  );

  const onResize = () => {
    if ($tableWrapper.current !== null) {
      const widths = Array.from(breakpoints.keys()).sort(
        (a: number, b: number) => a - b
      );
      const firstBreak = widths.findIndex(
        width => width > $tableWrapper.current!.clientWidth
      );
      // use the last width to not be greater than the client width, or zero if they all were
      const nextInFlowColumns = breakpoints.get(
        widths[Math.max(0, firstBreak - 1)]
      )!;
      setInFlowColumns(nextInFlowColumns);
    }
  };

  useLayoutEffect(() => {
    const debouncedResize = debounce(onResize, 100);
    window.addEventListener("resize", debouncedResize);
    onResize();
    return () => window.removeEventListener("resize", debouncedResize);
  }, []);

  const rowWidthStyles = (truncate: boolean) => {
    const minWidth = Array.from(inFlowColumns).reduce(
      (acc, columnKey) => acc + columnMinWidth(columnKey, props.columns),
      0
    );
    return {
      padding: "0 1.25rem",
      minWidth: `${minWidth}px`
    };
  };

  const columnWidthStyles = (columnKey: string, truncate: boolean) => {
    const minWidth = columnMinWidth(columnKey, props.columns);
    return {
      flexGrow: minWidth,
      minWidth: `${minWidth}px`
    };
  };

  const accessoryStyles = {
    flexShrink: 0,
    flexGrow: 0,
    width: "`${accessoryWidth}px`",
    minWidth: `${accessoryWidth}px`,
    display: "flex",
    justifyContent: "center",
    height: "calc(3rem - 2px)"
  };

  const columnOrder = [...columnKeys];

  const rowOrder =
    sortOrder[0] === defaultSortOrder[0]
      ? rowKeys
      : rowKeys.sort((rowKeyA, rowKeyB) => {
        let rowKeyI = rowKeyA,
          rowKeyJ = rowKeyB;
        if (sortOrder[1] === "asc") {
          rowKeyI = rowKeyB;
          rowKeyJ = rowKeyA;
        }
        return (props.rows[rowKeyI][sortOrder[0]] as string).localeCompare(
          props.rows[rowKeyJ]![sortOrder[0]] as string
        );
      });

  const hiddenColumns = new Set(
    columnKeys.filter(columnKey => !inFlowColumns.has(columnKey))
  );

  const setRowSelected = (rowSelected: boolean, rowKey: rowKey) => {
    if (rowSelected) propagateSetSelected(new Set(selected.add(rowKey)));
    else {
      selected.delete(rowKey);
      propagateSetSelected(new Set(selected));
    }
  };

  const includeRow = (row: IRow) =>
    props.filterBy ? props.filterBy(row) : true;

  const sortableLabelDesc = (t: TTranslations, sortable: TSortable): string =>
    t[`sort-order ${sortable} descending`];
  const sortableLabelAsc = (t: TTranslations, sortable: TSortable): string =>
    t[`sort-order ${sortable} ascending`];

  const setState = useSetKBState();
  
  return (
    <AppKBStateContext.Consumer>
      {(state) => {
        return (
          <FluentUIThemeConsumer
            render={globalTheme => (
              <KBTableTheme globalTheme={globalTheme}>
                <div
                  ref={$tableWrapper}
                  style={{
                    width: "100%",
                    height: "inherit",
                    backgroundColor:
                      globalTheme.siteVariables.colorScheme.default.background2,
                    paddingBottom: "5rem"
                  }}
                >
                  <FluentUITable
                    compact
                    header={{
                      key: "header",
                      compact: true,
                      variables: { compactRow: true },
                      styles: {
                        ...rowWidthStyles(!!props.truncate),
                        backgroundColor:
                          globalTheme.siteVariables.colorScheme.default.background2
                      },
                      items: columnOrder.reduce(
                        (acc: ShorthandCollection<TableCellProps>, columnKey) => {
                          const column = props.columns[columnKey];
                          if (inFlowColumns.has(columnKey))
                            switch (columnKey) {
                              case "overflow":
                                acc.push({
                                  key: "header__overflow",
                                  content: "",
                                  styles: accessoryStyles,
                                  "data-is-focusable": false
                                });
                                break;
                              case "selection":
                                acc.push({
                                  key: "header__selection",
                                  accessibility: gridCellWithFocusableElementBehavior,
                                  content: (
                                    <Checkbox
                                      aria-label="Select all"
                                      checked={selected.size > 0}
                                      variables={{
                                        margin: 0,
                                        indeterminate: selectedIndeterminate
                                      }}
                                      onChange={(_e, props) => {
                                        if (props?.checked)
                                          propagateSetSelected(new Set(rowKeys));
                                        else if (selectedIndeterminate)
                                          propagateSetSelected(new Set(rowKeys));
                                        else propagateSetSelected(new Set());
                                      }}
                                      styles={{
                                        gridTemplateColumns: "1fr"
                                      }}
                                    />
                                  ),
                                  styles: {
                                    ...accessoryStyles,
                                    height: "calc(2.5rem - 2px)"
                                  }
                                });
                                break;
                              default:
                                acc.push({
                                  key: `header__${columnKey}`,
                                  content: column.sortable ? (
                                    <MenuButton
                                      menu={[
                                        {
                                          content: sortableLabelDesc(
                                            globalTheme.siteVariables.t,
                                            column.sortable
                                          ),
                                          onClick: () => {
                                            setSortOrder(
                                              sortOrder[0] === columnKey &&
                                                sortOrder[1] === "desc"
                                                ? defaultSortOrder
                                                : [columnKey, "desc"]
                                            );
                                          },
                                          ...(sortOrder[0] === columnKey && {
                                            icon: (
                                              <AcceptIcon
                                                outline
                                                styles={{
                                                  visibility:
                                                    sortOrder[1] === "desc"
                                                      ? "visible"
                                                      : "hidden"
                                                }}
                                              />
                                            )
                                          })
                                        },
                                        {
                                          content: sortableLabelAsc(
                                            globalTheme.siteVariables.t,
                                            column.sortable
                                          ),
                                          onClick: () => {
                                            setSortOrder(
                                              sortOrder[0] === columnKey &&
                                                sortOrder[1] === "asc"
                                                ? defaultSortOrder
                                                : [columnKey, "asc"]
                                            );
                                          },
                                          ...(sortOrder[0] === columnKey && {
                                            icon: (
                                              <AcceptIcon
                                                outline
                                                styles={{
                                                  visibility:
                                                    sortOrder[1] === "asc"
                                                      ? "visible"
                                                      : "hidden"
                                                }}
                                              />
                                            )
                                          })
                                        }
                                      ]}
                                      trigger={
                                        <Button
                                          content={column.title}
                                          icon={
                                            <SortOrderIndicator
                                              {...{ sortOrder, columnKey }}
                                            />
                                          }
                                          iconPosition="after"
                                          text
                                          fluid
                                          styles={{
                                            padding: 0,
                                            height: "100%",
                                            justifyContent: "flex-start"
                                          }}
                                        />
                                      }
                                      styles={{ display: "block", height: "100%" }}
                                    />
                                  ) : (
                                    column.title
                                  ),
                                  styles: columnWidthStyles(
                                    columnKey,
                                    !!props.truncate
                                  ),
                                  variables: { flush: !!column.sortable },
                                  ...(column.sortable
                                    ? {
                                      accessibility:
                                        gridCellWithFocusableElementBehavior,
                                      ...ariaSort({ sortOrder, columnKey })
                                    }
                                    : {})
                                });
                                break;
                            }
                          return acc;
                        },
                        []
                      )
                    }}
                    rows={rowOrder.reduce(
                      (acc: ShorthandCollection<TableRowProps>, rowKey: rowKey) => {
                        const row = props.rows[rowKey];
                        const source = row.source;
                        const rowActionKeys = (
                          hiddenColumns.size ? ["__details__"] : []
                        ).concat(row.actions ? Object.keys(row.actions!) : []);
                        if (includeRow(row)) {
                          var rowStyle = rowWidthStyles(!!props.truncate);
                          if (source != "Editorial" && rowStyle) {
                            rowStyle[
                              "backgroundColor"
                            ] = `${globalTheme.siteVariables.colorScheme.default.background} !important`;
                          }
                          acc.push({
                            key: rowKey,
                            styles: rowStyle,
                            className: `${source}`,
                            variables: ({
                              colorScheme,
                              theme
                            }: SiteVariablesPrepared) =>
                              selected.has(rowKey) &&
                                theme !== TeamsTheme.HighContrast
                                ? {
                                  backgroundColor: colorScheme.grey.backgroundFocus,
                                  color: colorScheme.grey.foregroundFocus
                                }
                                : {
                                  backgroundColor: colorScheme.default.background2
                                },
                            onClick: ({ type }) => {
                              if (type === "click") {
                                if (tableTitle == "Users") {
                                  var userId = rowKey.replace("row-", "");
                                  history.push(`/settings/users?id=${userId}`);
                                } else if (tableTitle == "Groups") {
                                  var groupId = rowKey.replace("row-", "");
                                  history.push(`/settings/groups?id=${groupId}`);
                                } else if (tableTitle === "Tags") {
                                  const tagId = rowKey.replace("row-", "");
                                  history.push(`/settings/tags?id=${tagId}`);
                                } else return;
                              }
                              // respond only to keyboard space & enter for selection
                              props.selectable &&
                                setRowSelected(!selected.has(rowKey), rowKey);
                            },
                            items: columnOrder.reduce(
                              (
                                acc: ShorthandCollection<TableCellProps>,
                                columnKey
                              ) => {
                                if (inFlowColumns.has(columnKey))
                                  switch (columnKey) {
                                    case "overflow":
                                      acc.push({
                                        key: `${rowKey}__overflow`,
                                        content: (
                                          <Button
                                            icon={<TrashCanIcon />}
                                            text
                                            iconOnly
                                            title="Delete"
                                          />
                                        ),
                                        styles: accessoryStyles,
                                        accessibility:
                                          gridCellWithFocusableElementBehavior,
                                        ...{
                                          onClick: () => {
                                            // kbDataService.deleteOneRow(row["id"]);
                                              let newList = KBStateService.deleteOneRow(parseInt(row["id"].toString()), state.knowledgeBase);
                                              
                                          }
                                        }
                                      });
                                      break;
                                    case "overflow-old":
                                      acc.push({
                                        key: `${rowKey}__overflow`,
                                        content: rowActionKeys.length ? (
                                          <Popup
                                            trigger={
                                              <Button
                                                icon={<MoreIcon outline />}
                                                text
                                                aria-label="More actions"
                                                styles={{ color: "currentColor" }}
                                              />
                                            }
                                            content={
                                              <Menu
                                                items={rowActionKeys.map(
                                                  rowActionKey => {
                                                    switch (rowActionKey) {
                                                      case "__details__":
                                                        return {
                                                          key: `${rowKey}__details__`,
                                                          icon: (
                                                            <OpenOutsideIcon
                                                              outline
                                                            />
                                                          ),
                                                          content: "Details",
                                                          ...(props.onInteraction && {
                                                            onClick: () =>
                                                              props.onInteraction!({
                                                                event: "click",
                                                                target: "table",
                                                                subject: rowKey,
                                                                action:
                                                                  rowActionKey + "11"
                                                              })
                                                          })
                                                        };
                                                      case "delete":
                                                        var rowAction =
                                                          row.actions![rowActionKey];
                                                        return {
                                                          key: `${rowKey}__${rowActionKey}`,
                                                          content: (
                                                            <Dialog
                                                              trigger={
                                                                <MenuItem
                                                                  icon={
                                                                    <Icon
                                                                      icon={
                                                                        rowAction.icon
                                                                      }
                                                                    />
                                                                  }
                                                                  content={
                                                                    rowAction.title
                                                                  }
                                                                />
                                                              }
                                                              content={getText(
                                                                "en-US",
                                                                "confirm delete",
                                                                {
                                                                  title: getText(
                                                                    "en-US",
                                                                    row.actions![
                                                                      rowActionKey
                                                                    ].title
                                                                  )
                                                                }
                                                              )}
                                                              confirmButton={{
                                                                content: "delete"
                                                              }}
                                                              cancelButton={{
                                                                content: "cancel"
                                                              }}
                                                              onConfirm={() => { }}
                                                            />
                                                          )
                                                        };
                                                      case "manage":
                                                        rowAction =
                                                          row.actions![rowActionKey];
                                                        return {
                                                          key: `${rowKey}__${rowActionKey}`,
                                                          content: (
                                                            <RowDialog
                                                              action={
                                                                RowDialogAction.Edit
                                                              }
                                                              trigger={
                                                                <MenuItem
                                                                  icon={
                                                                    <Icon
                                                                      icon={
                                                                        rowAction.icon
                                                                      }
                                                                    />
                                                                  }
                                                                  content={
                                                                    rowAction.title
                                                                  }
                                                                />
                                                              }
                                                              initialState={row}
                                                              {...{
                                                                rowKey
                                                              }}
                                                            />
                                                          )
                                                        };
                                                      default:
                                                        return {
                                                          key: `${rowKey}__${rowActionKey}`,
                                                          icon: (
                                                            <Icon
                                                              icon={
                                                                row.actions![
                                                                  rowActionKey
                                                                ].icon
                                                              }
                                                            />
                                                          ),
                                                          content:
                                                            row.actions![rowActionKey]
                                                              .title,
                                                          ...(props.onInteraction && {
                                                            onClick: () =>
                                                              props.onInteraction!({
                                                                event: "click",
                                                                target: "table",
                                                                subject: rowKey,
                                                                action:
                                                                  rowActionKey + "1"
                                                              })
                                                          })
                                                        };
                                                    }
                                                  }
                                                )}
                                                vertical
                                              />
                                            }
                                            offset={[-4, 4]}
                                            position="below"
                                            accessibility={dialogBehavior}
                                            autoFocus={true}
                                          />
                                        ) : (
                                          ""
                                        ),
                                        styles: accessoryStyles,
                                        accessibility:
                                          gridCellWithFocusableElementBehavior
                                      });
                                      break;
                                    case "selection":
                                      acc.push({
                                        key: `${rowKey}__selection`,
                                        content: (
                                          <Checkbox
                                            aria-label="Select"
                                            variables={{ margin: 0 }}
                                            checked={selected.has(rowKey)}
                                            onChange={(_e, props) => {
                                              setRowSelected(
                                                !!props?.checked,
                                                rowKey
                                              );
                                            }}
                                            styles={{
                                              gridTemplateColumns: "1fr"
                                            }}
                                          />
                                        ),
                                        accessibility:
                                          gridCellWithFocusableElementBehavior,
                                        styles: accessoryStyles
                                      });
                                      break;
                                    default:
                                      if (tableTitle == "KB") {
                                          if (columnKey == "options") {acc.push({
                                            key: `${rowKey}__overflow`,
                                            content: (
                                              <Button
                                                text
                                                content={"Edit"}
                                                iconOnly
                                                title="Edit"
                                              />
                                            ),
                                            styles: {
                                              ...accessoryStyles,
                                              height: "unset"
                                            },
                                            accessibility:
                                              gridCellWithFocusableElementBehavior,
                                            ...{
                                              onClick: () => {
                                                // kbDataService.deleteOneRow(row["id"]);
                                                history.push(`/settings/knowledge?id=${row["id"]}` )
                                              }
                                            }
                                          });
                                          acc.push({
                                            key: `${rowKey}__overflow`,
                                            content: (
                                              <Button
                                                icon={<TrashCanIcon />}
                                                text
                                                iconOnly
                                                title="Delete"
                                              />
                                            ),
                                            styles: {
                                              ...accessoryStyles,
                                              height: "unset"
                                            },
                                            accessibility:
                                              gridCellWithFocusableElementBehavior,
                                            ...{
                                              onClick: () => {
                                                // kbDataService.deleteOneRow(row["id"]);
                                                let newList = KBStateService.deleteOneRow(parseInt(row["id"].toString()), state.knowledgeBase);
                                                setState((state) => {
                                                  return {
                                                    ...state,
                                                    deletedKB: [...state.deletedKB, parseInt(row["id"].toString())],
                                                    knowledgeBase: newList,
                                                    tab: 0
                                                  }
                                                })
                                              }
                                            }
                                          });
                                        } else {
                                          const cell = row[columnKey];
                                          const dataId = row["id"];
                                          const arrayCell = String(
                                            row[columnKey]
                                          ).split("|");
                                          const sections = [
                                            {
                                              inputGroups: []
                                            }
                                          ];
                                          let i = 0;
                                          arrayCell.map(value => {
                                            sections[0].inputGroups.push({
                                              type: "text-inputs",
                                              fields: [
                                                {
                                                  type:
                                                    columnKey == "questions"
                                                      ? "kbquestion"
                                                      : "kbanswer",
                                                  title: "",
                                                  source: source,
                                                  inputId: (rowKey +
                                                    "-" +
                                                    columnKey +
                                                    "-" +
                                                    i) as string,
                                                  dataId: dataId,
                                                  dataField: columnKey,
                                                  dataIndex: i,
                                                  optional: true,
                                                  initialValue: value
                                                }
                                              ]
                                            } as never);

                                            i++;
                                          });
                                          if (columnKey == "questions") {
                                            sections[0].inputGroups.push({
                                              type: "text-inputs",
                                              fields: [
                                                {
                                                  type: "kbaddquestion",
                                                  source: source,
                                                  title: "",
                                                  inputId: (rowKey +
                                                    "-" +
                                                    columnKey +
                                                    "-new") as string,
                                                  dataId: dataId,
                                                  dataField: columnKey,
                                                  dataIndex: i,
                                                  optional: true,
                                                  initialValue: ""
                                                }
                                              ]
                                            } as never);
                                          }
                                          acc.push({
                                            key: `${rowKey}__${columnKey}`,
                                            content: (
                                              <KBForm
                                                sections={sections}
                                                submit={""}
                                                cancel={""}
                                              />
                                            ),
                                            truncateContent: !!props.truncate,
                                            styles: columnWidthStyles(
                                              columnKey,
                                              !!props.truncate
                                            )
                                          });
                                        }
                                      } else if (tableTitle != "KB") {
                                        const cell = row[columnKey];
                                        acc.push({
                                          key: `${rowKey}__${columnKey}`,
                                          content:
                                            columnKey == "name" &&
                                              tableTitle == "Users" ? (
                                              <>
                                                <UserAvatar {...{
                                                  idOrUpn: (row["email"].toString().length > 0) ? row["email"].toString() : row["aadObjectId"].toString(),
                                                  avatarProps: { name: row["name"].toString(), size: "smaller" }
                                                }}></UserAvatar>
                                                <Text weight="semibold"
                                                  style={{ marginLeft: ".4rem" }}
                                                  className={`table-cell-color--${globalTheme.siteVariables
                                                      .theme == "teamsTheme"
                                                      ? "default"
                                                      : "dark"
                                                    }`}>{row["name"]}</Text>
                                              </>
                                            ) : columnKey == "members" &&
                                              tableTitle == "Groups" ? (
                                              <div>
                                                <Text
                                                  content={`Members`}
                                                  className={`md:hidden`}
                                                  size="small"
                                                  weight="semibold"
                                                />
                                                <People
                                                  peopleQueries={String(
                                                    row[columnKey]
                                                  ).split(",")}
                                                ></People>
                                              </div>
                                            ) : (
                                              <div>
                                                <Text
                                                  content={columnKey}
                                                  className={`md:hidden capitalize`}
                                                  weight={"semibold"}
                                                  size="small"
                                                />
                                                <div>
                                                  <Text
                                                    content={cell}
                                                    className={`table-cell-color--${globalTheme.siteVariables
                                                        .theme == "teamsTheme"
                                                        ? "default"
                                                        : "dark"
                                                      }`}
                                                  />
                                                </div>
                                              </div>
                                            ),
                                          truncateContent: !!props.truncate,
                                          styles: {
                                            cursor: "pointer",
                                            ...columnWidthStyles(
                                              columnKey,
                                              !!props.truncate
                                            )
                                          }
                                        });
                                      }
                                      break;
                                  }
                                return acc;
                              },
                              []
                            )
                          });
                        }
                        return acc;
                      },
                      []
                    )}
                    className={`responsive-table`}
                    variables={({ theme, colorScheme }: SiteVariablesPrepared) => {
                      return {
                        rowBorderColor: colorScheme.default.backgroundActive,
                        // box model
                        compactRowHeight: "2.5rem",
                        defaultRowMinHeight: "3rem",
                        defaultRowVerticalPadding: ".8125rem",
                        ...(props.truncate
                          ? {
                            defaultRowHeight: "3rem"
                          }
                          : {
                            defaultRowHeight: "auto",
                            cellVerticalAlignment: "flex-start"
                          }),
                        // colors
                        backgroundColor:
                          theme === TeamsTheme.HighContrast
                            ? colorScheme.grey.background
                            : colorScheme.grey.background2,
                        ...(theme === TeamsTheme.HighContrast
                          ? {
                            rowBorderColor: colorScheme.grey.foreground,
                            rowBorderHoverColor: colorScheme.grey.foreground
                          }
                          : {})
                      };
                    }}
                    styles={{
                      width: "auto"
                    }}
                    {...omit(props, [
                      "columns",
                      "rows",
                      "truncate",
                      "selectable",
                      "onSelectedChange",
                      "findQuery",
                      "filterBy"
                    ])}
                  />
                </div>
              </KBTableTheme>
            )}
          />
        )
      }}
    </AppKBStateContext.Consumer>
  );
};
