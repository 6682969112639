import React, { useCallback, useEffect, useState } from 'react';
import { Button, Text, MenuButton, MoreIcon, Dialog } from '@fluentui/react-northstar';
import { platformService } from '../shared/services/platform.service';
import { Communication } from '@fluentui/react-teams';
import { Providers } from '@microsoft/mgt-element/dist/es6/providers/Providers';
import { Breadcrumbs } from '../shared/components/BreadcrumbNavigation';
import {
  DEFAULT_API_STATUS,
  DELETE,
  DONE,
  ERROR,
  LOADING,
  SETTINGS,
  ACCESS_TOKEN,
  BUTTONS,
  REFRESH_SESSION,
  UNAUTHORIZED_ACCESS,
  SOMETHING_WENT_WRONG,
  CONTACT_ADMIN,
  SETTINGS_PAGES,
  ADMINISTRATORS,
  ANALYSTS
} from '../shared/utils/constants';
import { toTitleCase } from '../shared/utils/helper';
import { KBListWrapper } from '../kb/KBListWrapper';
import { FontIcon } from '@fluentui/react';
import { Tokens } from './Token';
import { ConvertDateToLocale } from '../shared/common/ConvertDate';
import { LoadingScreen } from '../shared/components/LoadingScreen';
import { CheckLogin } from '../shared/components/CheckLogin';
import { Helmet } from 'react-helmet';
import { MobileSettingsView, transformModalContent } from '../automation/AutomationTable';
import { UpgradeNeeded } from '../shared/components/UpgradeNeeded';
import { CheckLicense } from '../shared/components/License/CheckLicense';
import useCurrentPage from '../kb/useCurrentPage';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { appState  } from '../AppState';

export const TokensTable = () => {
  const appContextState = appState();
  const {t} = useTranslation();
  const api = new platformService();
  const [data, setData] = useState<RowsData[]>([]);
  const [newOrEditDialog, setNewOrEditDialog] = useState(false);
  const [tokenId, settokenId] = useState(0);
  const [apiStatus, setApiStatus] = useState<APIStatus>(
    DEFAULT_API_STATUS as APIStatus
  );
  const [myRoles, setMyRoles] = useState([]);
  const [tokenRoles, setTokenRoles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [recordToDelete, setRecordToDelete] = useState<Partial<TokenData>>(
    { Name: '' }
  );
  const [showDialog, setShowDialog] = useState(false);
  const {currentPage, setCurrentPage, onDeleteRowSetCurrentPage} = useCurrentPage();

  const fetchData = useCallback(async () => {
    setApiStatus({
      ...apiStatus,
      msg: ACCESS_TOKEN.GETTING_ALL_ACCESST_TOKENS
    });

    try {
      const {
        data: { value }
      } = await api.getAccessTokens();
      setTokenRoles(
        value.map((r, i) => {
          return { id: r.Id, roles: r.ApplicationRoles };
        })
      );

      const rowData: RowsData[] = value.map((token: TokenData) => ({
        id: token['Id'],
        [t('access-token.headers.name')]: token['Name'],
        [t('access-token.headers.owner')]: token['Owner']['Email'],
        [t('access-token.headers.expiration')]: ConvertDateToLocale(token['ExpirationDate'], DateTime.DATETIME_MED),
        [t('access-token.headers.actions')]: (
          <>
            <CheckLicense disable>
            <MenuButton
              trigger={
                  <Button
                    icon={<MoreIcon />}
                    text
                    iconOnly
                    title={t('access-token.actions')}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                  />
              }
              menu={{
                items: [
                  {
                    content: toTitleCase(DELETE),
                    icon: (
                      <FontIcon
                        iconName={'Delete'}
                        className={`text-lg`}
                        style={{ lineHeight: 'unset' }}
                      />
                    ),
                    onClick: e => {
                      e.stopPropagation();
                
                      setRecordToDelete({
                        Name: token['Name']
                      });
                      setShowDialog(true);
                    }
                  }
                ]
              }}
            />
          </CheckLicense>
          </>
        )
      }));

      setData(rowData);
      setApiStatus({ ...apiStatus, status: DONE });
    } catch (err: any) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: err.response.status
      });
    }
  }, []);

  const loadPage = () => {
    const hasAccess = appContextState.userRoles.roles.includes(ADMINISTRATORS) || appContextState.userRoles.roles.includes(ANALYSTS);
    if(!hasAccess) { 
      setApiStatus({ ...apiStatus, status: ERROR, errCode: 401 }) }
    else{
        fetchData();
        (async () => { setMyRoles(await api.getMyApplicationRoleNames()); })();
    }

   
  }

  useEffect(() => {
    const userRoles = tokenId > 0 ? getTokenRoles(tokenId) : myRoles;
    setRoles(userRoles);
  }, [tokenId, myRoles]);

  const getTokenRoles = id => {
    return tokenRoles.filter(f => f.id == id)[0].roles;
  };

  const navs: Breadcrumbs = {
    breadcrumbs: [
      {
        title: toTitleCase(SETTINGS),
        link: '/settings'
      },
      {
        title: toTitleCase(ACCESS_TOKEN.TOKEN),
        link: ''
      }
    ]
  };

  const deleteToken = async () => {
    setApiStatus({
      status: LOADING,
      msg: 'Delete',
      errCode: 0
    });
    try {
      await api.deleteToken({ Name: recordToDelete['Name']});
      
      onDeleteRowSetCurrentPage(data.length)
    } catch (err: any) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: err.response.status
      });
    } finally {
      setShowDialog(false);
      setApiStatus({ ...apiStatus, status: DONE });
      fetchData();
    }
  };

  const errConfig = {
    fields: {
      title:
        apiStatus.errCode === 401
          ? UNAUTHORIZED_ACCESS
          : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  };

  return (
    <CheckLogin onSignedIn={loadPage}>
      <Helmet>
        <title>{toTitleCase(ACCESS_TOKEN.TOKEN)} - Tikit</title>
      </Helmet>
      <div className="hidden md:block">
        {apiStatus.status === LOADING ? (<LoadingScreen message={apiStatus.msg} />) :
          apiStatus.status === ERROR ? (
            <>
              {apiStatus.errCode !== 402 && (<Communication {...errConfig} />)}
              {apiStatus.errCode === 401 && (<Button content={REFRESH_SESSION} primary onClick={() => { Providers.globalProvider.login(); }} />)}
              {apiStatus.errCode === 402 && (<UpgradeNeeded toolbarText={`${toTitleCase(SETTINGS)} > ${toTitleCase(ACCESS_TOKEN.TOKEN)}`} breadcrumbs={navs}
                headerText={t('access-token.header-text')} subheaderText={t('access-token.subheader-text')} />)}
            </>
          ) : (
            <>
              <KBListWrapper
                title={toTitleCase(ACCESS_TOKEN.TOKEN)}
                routeName={SETTINGS_PAGES.ACCESS_TOKEN}
                headers={[t('access-token.headers.name'), t('access-token.headers.owner'), t('access-token.headers.expiration'), t('access-token.headers.actions')]}
                rows={data}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                breadcrumbs={navs}
                ifEmpty={{
                  header: t('access-token.there-are-no-access-tokens'),
                  subHeader:
                  t('access-token.get-started-by-clicking')
                }}
                onRowClickHandler={(id: number) => {
                  settokenId(id);
                  setNewOrEditDialog(true);
                }}
                onAddHandler={() => {
                  settokenId(0);
                  setNewOrEditDialog(true);
                }}
              />
              <Tokens
                id={tokenId.toString()}
                open={newOrEditDialog}
                isView={tokenId > 0}
                onCancel={() => setNewOrEditDialog(false)}
                onSuccessFullCall={fetchData}
                myRoles={roles}
              />
              <Dialog
                open={showDialog}
                onCancel={() => setShowDialog(false)}
                cancelButton={toTitleCase(BUTTONS.CANCEL)}
                confirmButton={toTitleCase(DELETE)}
                onConfirm={() => {
                  deleteToken();
                }}
                content={transformModalContent`${t('access-token.delete-token',{recordToDelete: recordToDelete['Name']})}`}
                header={`${t('access-token.header-delete-token', {recordToDelete: recordToDelete['Name']})}`}
                style={{ maxWidth: '500px' }}
              />
            </>
          )}
      </div>
      <MobileSettingsView />
    </CheckLogin>
  );
};
