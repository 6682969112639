import React from 'react';
import { Box, Flex, Skeleton, ProviderConsumer as FluentUIThemeConsumer } from '@fluentui/react-northstar';
import { Col, Row } from 'react-grid-system';
import { ThemeColorScheme } from '../../common/TeamsTheme';

export const TopBarSkeleton = () => (
  <FluentUIThemeConsumer
    render={globalTheme => (
      <Flex column className={`relative flex flex-col`}>
        <div
          className={`px-5 pt-5 mb-5`}
          style={{
            minHeight: '60px',
            backgroundColor: 'var(--mgt-theme-background)',
            ...ThemeColorScheme(globalTheme.siteVariables)
          }}
        >
          <Box aria-roledescription="card with avatar, image and action buttons">
            <Row>
              <Col style={{ marginBottom: 15 }}>
                <Flex gap="gap.small" style={{ marginBottom: 15 }}>
                  <Skeleton animation="wave">
                    <Skeleton.Shape width="100px" height="20px" />
                  </Skeleton>

                  <div style={{ float: 'right' }}>
                    <Skeleton animation="wave">
                      <Skeleton.Line width="50px" height="20px" />
                    </Skeleton>
                  </div>
                </Flex>

                <Flex gap="gap.small" className={`mb-4`}>
                  <Skeleton animation="wave">
                    <Skeleton.Shape width="100px" height="20px" />
                    <Skeleton.Shape width="300px" height="20px" />
                  </Skeleton>
                </Flex>

                <Skeleton animation="wave">
                  <Flex gap="gap.large">
                    <Flex gap="gap.small" vAlign="center">
                      <Skeleton.Shape round width="20px" height="20px" />
                      <Skeleton.Shape width="100px" height="20px" />
                    </Flex>
                    <Skeleton.Shape width="100px" height="20px" />
                  </Flex>
                </Skeleton>

                <Skeleton animation="wave" className={`mt-3 mb-4`}>
                  <Skeleton.Shape width="300px" height="20px" />
                </Skeleton>

                <Flex gap="gap.small">
                  <Skeleton animation="wave">
                    <Skeleton.Shape width="200px" height="20px" />
                  </Skeleton>

                  <div style={{ float: 'right' }}>
                    <Skeleton animation="wave">
                      <Skeleton.Line width="100px" height="20px" />
                    </Skeleton>
                  </div>
                </Flex>
              </Col>
            </Row>
          </Box>
        </div>
      </Flex>
    )}
  />
);
