import React, { useEffect } from 'react';
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
  IColumn
} from '@fluentui/react/lib/DetailsList';
import { MarqueeSelection } from '@fluentui/react/lib/MarqueeSelection';
import { SkeletonCell, TicketProp } from './TicketListBoard';
import { ConvertDateToLocale } from '../../common/ConvertDate';
import { AppStateValue, useSetState } from '../../../AppState';
import { UserAvatar } from '../UserAvatar';
import { isCloseFilter, getUserData, formatAffectedUserLikeCollaborators } from '../../../tikit/ticketHelper';
import { Link, RouteComponentProps, useHistory, useLocation, withRouter } from 'react-router-dom';
import { SpeakerPersonIcon, ProviderConsumer as FluentUIThemeConsumer } from '@fluentui/react-northstar';
import _ from 'lodash';
import { PlatformUser } from '../../interfaces/platformuser.interface';
import { ThemeColorScheme } from '../../common/TeamsTheme';
import { PRODUCT_LICENSE, ListColumnKey, MyWorkListViewType } from '../../utils/constants';
import { getCachedFeature } from '../../cache/FeatureCache';
import { EnumSortOrderType } from '../../../tikit/toolbar/CustomViewContextProvider';
import { GroupUsersIcon } from '../../../tikit/Request/EditRequestDetails/GroupUsersCard/GroupUsersIcon';
import { TicketLifecyclePhaseElement } from '../TicketLifecycleTab/TicketLifecyclePhaseRibbon';
import { SlaPills } from '../SlaPills/SlaPills';
import { Route } from '../../../../route-constants';
import {  i18n } from '../../../../components/shared/utils/helper';
import { DateTime } from 'luxon';
const { t } = i18n();

export interface State {
  columns: IColumn[];
  items: Ticket[];
  selectedItems: Ticket[];
  analystAccess: boolean;
}

type PropsType = RouteComponentProps<{}> & {
  tickets: Ticket[];
  onTicketsSelected: (tickets: Ticket[]) => void;
  currentState: AppStateValue;
  useTicketType: boolean;
  useTeam: boolean;
  useTicketLifecycle: boolean;
  useSLA: boolean;
  setListStates: React.Dispatch<React.SetStateAction<ListStatesType>>;
  selectedOrderedColumns: null | (number | string)[];
  sortOrderApplied: {
    orderBy: ISortOrderBy;
    orderType: ISortOrderType;
  },
  clickColumnCallback: (x: IColumn) => void
};

class ClassDetailsList extends React.Component<PropsType, State> {
  private _selection: Selection;

  constructor(props: PropsType) {
    super(props);

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectedItems: this._getSelectionDetails(),
        });
      },
    });

    this.props.setListStates((prev) => ({
      ...prev,
      rowsSelection: this._selection,
    }));

    let cols = this._columns.slice();
    cols = this.filterColumnsByFeature(cols, this.props)
    this.state = {
      items: this.props.tickets,
      columns: cols,
      selectedItems: [],
      analystAccess: false
    };
  }

  public render() {
    const { columns, items } = this.state;

    return (
      <FluentUIThemeConsumer
        render={(globalTheme) => (
          <div
            className={`py-3.5 px-2.5 min-h-32 main-ticket-list`}
            style={{
              backgroundColor: 'var(--mgt-theme-background2)',
              ...ThemeColorScheme(globalTheme.siteVariables)
            }}
          >
            <div>
              <MarqueeSelection selection={this._selection}>
                <DetailsList
                  items={items}
                  compact={false}
                  columns={columns}
                  selectionMode={isCloseFilter(this.props.currentState.listFilter) || !this.state.analystAccess ? SelectionMode.none : SelectionMode.multiple}
                  getKey={this._getKey}
                  setKey='multiple'
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  selection={this._selection}
                  selectionPreservedOnEmptyClick={true}
                  enterModalSelectionOnTouch={true}
                  ariaLabelForSelectionColumn='Toggle selection'
                  ariaLabelForSelectAllCheckbox='Toggle selection for all items'
                  checkButtonAriaLabel='select row'
                  className="details-list-table"
                />
              </MarqueeSelection>
            </div>
          </div>
        )}
      />
    );
  }


  public componentDidMount() {
    (async () => {
      let isAnalystAccess = await getCachedFeature(PRODUCT_LICENSE.AnalystAccess);
      this.setState({ items: this.props.tickets, analystAccess: isAnalystAccess });
    })();
  }

  public filterColumnsByFeature(cols: IColumn[], props: PropsType) {
    let excludeCols = [];
    if (!props.useTicketType)
      excludeCols.push("TicketType");
    if (!props.useTeam)
      excludeCols.push("TeamName");
    if (!props.useTicketLifecycle){
      excludeCols.push("TicketLifecycleName");
      excludeCols.push("TicketLifecyclePhaseName");
    }
    if (!props.useSLA)
      excludeCols.push("SLA")

    if(excludeCols.length > 0)
      cols = cols.filter(x => !excludeCols.includes(x.fieldName));

    return cols;
  }

  public getOrderedColumns(cols: IColumn[]) {
    const selectedOrderedColumns = [...this.props.selectedOrderedColumns]
    if (selectedOrderedColumns?.length > 0) {
      const filteredCols = cols.filter((col) => selectedOrderedColumns.includes(Number(col.key)));

      const orderedColumns = selectedOrderedColumns.reduce(
        (acc, value) => {
          const matchedColumn = filteredCols.find((column) => Number(column.key) === value);
          return [...acc, matchedColumn];
        },
        [] as IColumn[]
      );
      return orderedColumns.filter(item => item);
    }

    return cols
  }

  public componentDidUpdate(previousProps: PropsType, previousState: State) {
    if (previousProps.tickets.length !== this.props.tickets.length || _.differenceBy(previousProps.tickets, this.props.tickets, 'Title').length > 0) {
      this.setState({ items: [...this.props.tickets] });
    } 
    
    if (previousProps.tickets.every(x => !x.TicketSlas) && this.props.tickets.some(x => x.TicketSlas?.length > 0)) {
      this.setState({ items: [...this.props.tickets]});
    }

    if (JSON.stringify(previousProps.selectedOrderedColumns) !== JSON.stringify(this.props.selectedOrderedColumns)) {
      let cols = this._columns.slice();
      cols = this.filterColumnsByFeature(cols, this.props);

      const columns = this.getOrderedColumns(cols);
      this.setState({ columns });
    }
  }

  private _getKey(item: any, index?: number): string {
    return item.key;
  }

  private _getSelectionDetails(): Ticket[] {
    this.props.onTicketsSelected(this._selection.getSelection() as Ticket[]);
    return this._selection.getSelection() as Ticket[];
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    this.props.clickColumnCallback(column);
  };

  private _requestLength = (localStorage.getItem('TikitRequestLength') || 'abbreviated') == 'full';

  private _cols: _ColsType = {
    Id: [ListColumnKey.Id, t('ticket.ticket-list.id'), 'Id', 30, 30, null, true, renderRecordValue('Id', this)],
    Type: [ListColumnKey.Type, t('ticket.ticket-list.type'), 'TicketType', 75, 125, null, null, renderRecordValue('Type', this)],
    Request: [ListColumnKey.Title, t('ticket.ticket-list.request'), 'Title', (this._requestLength ? 100 : 200), (this._requestLength ? 200 : 400), (this._requestLength ? true : null), true, renderRecordValue('Request', this)],
    Status: [ListColumnKey.Status, t('ticket.ticket-list.status'), 'Status', 100, 200, null, null, renderRecordValue('Status', this)],
    Requester: [ListColumnKey.Requester, t('ticket.ticket-list.requester'), 'RequesterName', 100, 200, null, null, renderRecordValue('Requester', this)],
    Category: [ListColumnKey.Category, t('ticket.ticket-list.category'), 'Category', 100, 200, null, null, renderRecordValue('Category', this)],
    Priority: [ListColumnKey.Priority, t('ticket.ticket-list.priority'), 'Priority', 100, 200, null, null, renderRecordValue('Priority', this)],
    SLAs: [ListColumnKey.SLA, t('ticket.ticket-list.sla'), 'SLA', 100, 220, null, null, renderRecordValue('SLAs', this)],
    Team: [ListColumnKey.Team, t('ticket.ticket-list.team'), 'TeamName', 100, 200, null, null, renderRecordValue('Team', this)],
    Group: [ListColumnKey.Group, t('ticket.ticket-list.group'), 'GroupName', 100, 200, null, null, renderRecordValue('Group', this)],
    Assignee: [ListColumnKey.Assignee, t('ticket.ticket-list.assignee'), 'AssigneeName', 100, 200, null, true, renderRecordValue('Assignee', this)],
    Lifecycle: [ListColumnKey.Lifecycle, t('ticket.ticket-list.lifecycle'), 'TicketLifecycleName', 100, 200, null, null, renderRecordValue('Lifecycle', this)],
    Phase: [ListColumnKey.Phase, t('ticket.ticket-list.phase'), 'TicketLifecyclePhaseName', 100, 200, null, null, renderRecordValue('Phase', this)],
    'Last Modified Date': [ListColumnKey.LastModifiedDate, t('ticket.ticket-list.last-modified-date'), 'ModifiedDate', 100, 200, null, null, renderRecordValue('Last Modified Date', this)],
    'Created Date': [ListColumnKey.CreatedDate, t('ticket.ticket-list.created-date'), 'CreatedDate', 100, 200, null, null, renderRecordValue('Created Date', this)],
    'Due Date': [ListColumnKey.DueDate, t('ticket.ticket-list.due-date'), 'DueDate', 100, 200, null, null, renderRecordValue('Due Date', this)],
    'Resolution Date': [ListColumnKey.ResolutionDate, t('ticket.ticket-list.resolution-date'), 'ResolutionDate', 100, 200, null, null, renderRecordValue('Resolution Date', this)]
  };

  private _columns: IColumn[] = Object.values(this._cols).map((value) => {
    const orderBy = this.props.sortOrderApplied.orderBy;
    const orderType = this.props.sortOrderApplied.orderType;

    return {
      key: value[0] + '',
      name: value[1],
      fieldName: value[2],
      minWidth: value[3],
      maxWidth: value[4],
      isMultiline: value[5],
      isPadded: value[6],
      onRender: value[7],
      isSorted: value[0] === orderBy ? true : null,
      isSortedDescending: value[0] === orderBy && orderType === EnumSortOrderType.desc ? true : false,
      onColumnClick: this._onColumnClick,
      isResizable: true,
      isCollapsible: true,
    }
  });
}

export default withRouter(ClassDetailsList);

type ColValuesType = [ISortOrderBy, string, string, number, number, boolean | null,
  boolean | null, (item: TicketProp) => JSX.Element];
type _ColsType = {
  [x in TicketColumnNameType]: ColValuesType
}

interface ILinkRecord {
  ticketId: number;
  itemId?: number;
  value: number | string;
  title: string;
  type?: number;
}
export const LinkRecord = ({ ticketId: ticId, itemId, value, title, type }: ILinkRecord) => {
  const location = useLocation();
  const ticketId = ticId.toString();
  const setAppState = useSetState();

  const isTaskOrApproval = [MyWorkListViewType.Approvals, MyWorkListViewType.Tasks].includes(type);
  const onClickLink = () => {
    if (isTaskOrApproval) {
      setAppState((prev) => ({
        ...prev,
        isRouteFromMyWork: true,
        selectedTaskOrApproval: { type, id: itemId },
      }));
    }
  };
  
  const isMyGroupWorkListView = location.pathname === Route.myGroupWork.list;
  const isMyWorkListView = location.pathname === Route.myWork.list;
  let path = '';
  let from = '';

  if(isMyGroupWorkListView || isMyWorkListView){
    const route = isMyGroupWorkListView ? Route.myGroupWork.editTicket : Route.myWork.editTicket;
    path = `${route}/${ticketId}`;
    from = isMyGroupWorkListView ? 'mygroupwork' : 'mywork';
  } else {
    path = `/tickets/${ticketId}`;
    from = '';
  }

  return (
    <FluentUIThemeConsumer
      render={(globalTheme) => (
        <span
          style={{
            cursor: 'pointer',
            color: globalTheme.siteVariables.colorScheme.brand.foregroundActive,
          }}
          title={title}
        >
          <Link to={{pathname: path, state: { from }}} onClick={onClickLink}>{value}</Link>
        </span>
      )}
    />
  );
};

export const SLAPills = (ticketSlas) =>{
  return (
    <FluentUIThemeConsumer
      render={(globalTheme) => (
        <SlaPills ticketSlas={ticketSlas.ticketSlas} svgGap={false} viewType='List'/>
      )}
    />
  );
}

const _getRequester = (item: TicketProp, thisContext: any) => {
  const user = item['Requester'] ? (item['Requester'] as PlatformUser) : getUserData(item.RequesterId, thisContext.props.currentState);
  const affectedUsers = item['AffectedUsers'] ? (item['AffectedUsers'] as PlatformUser[]) : [] as PlatformUser[];

  if (affectedUsers.length > 0) {
    const users = user ? [{ PlatformUserId: user.Id }, ...affectedUsers] : affectedUsers;
    return _getUsers(users);
  }

  return _getUser(user?.AadObjectId ? user?.AadObjectId : user?.UserName, user?.FullName);
};

const _getAssignee = (item: TicketProp, thisContext: any) => {
  const user = item['Assignee'] ? (item['Assignee'] as PlatformUser) : getUserData(item.AssigneeId, thisContext.props.currentState);
  const collaborators  = item['TicketCollaborators'] ? (item['TicketCollaborators'] as PlatformUser[]) : [] as PlatformUser[];

  if (collaborators.length > 0) {
    const users = user ? [{ PlatformUserId: user.Id }, ...collaborators] : collaborators;
    return _getUsers(users);
  }

  return _getUser(user?.AadObjectId ? user?.AadObjectId : user?.UserName, user?.FullName);
};




const renderRecord = (
  item: TicketProp,
  thisContext: any
): { [x in TicketColumnNameType]: React.ReactElement } => ({
  Id: (<LinkRecord ticketId={item.Id} value={item.Id} title={item.Title} />),
  Request: (<LinkRecord ticketId={item.Id} value={item.Title} title={item.Title} />),
  Assignee: _getAssignee(item, thisContext),
  Status: (
    <div className={`w-full truncate mr-4`} dir='rtl'>
      <bdi>{item.Status}</bdi>
    </div>
  ),
  Requester: _getRequester(item, thisContext),
  Lifecycle: (
    <div className={`w-full truncate mr-4`} >
      <bdi>{item.TicketLifecycleName}</bdi>
    </div>
  ),
  Phase: (
    <>
      {item.TicketLifecyclePhaseName.length > 0 && (
        <TicketLifecyclePhaseElement 
          status={item['TicketLifecycle']["Status"]} 
          phaseName={item.TicketLifecyclePhaseName}/>
      )}
    </>
  ),
  Team: <span>{item.TeamName}</span>,
  Group: <span>{item.GroupName}</span>,
  Type: (
    <div className={`w-full truncate mr-4`} dir='rtl'>
      <bdi>{item.TicketType}</bdi>
    </div>
  ),
  Category: (
    <div className={`w-full truncate mr-4`} dir='rtl'>
      <bdi>{item.Category}</bdi>
    </div>
  ),
  Priority: (
    <div className={`w-full truncate mr-4`} dir='rtl'>
      <bdi>{item.Priority}</bdi>
    </div>
  ),
  SLAs: item.TicketSlas?.length > 0 ? <SLAPills ticketSlas={item.TicketSlas} /> : <>-</>,
  'Last Modified Date': (
    <span>{ConvertDateToLocale(item.ModifiedDate, DateTime.DATE_SHORT)}</span>
  ),
  'Created Date': <span>{ConvertDateToLocale(item.CreatedDate, DateTime.DATE_SHORT)}</span>,
  'Due Date': <span>{ConvertDateToLocale(item.DueDate ? item.DueDate : '', DateTime.DATE_SHORT)}</span>,
  'Resolution Date': <span>{ConvertDateToLocale(item.ResolutionDate ? item.ResolutionDate : '', DateTime.DATE_SHORT)}</span>,
});

const renderRecordValue =
  (colName: TicketColumnNameType, thisContext: any) => (item: TicketProp) => {
    return item.Title === 'temp_ticket' ? (
      <SkeletonCell />
    ) : (
      renderRecord(item, thisContext)[colName]
    );
  };

export const _getUser = (email: string, name: string) => (
  <div>
    {_getUserAvatar(email, name)}
    &nbsp;
    {!email ? t('ticket.ticket-board.ticket-card.unassigned') : name}
  </div>
);

export const _getUsers = (users: any[]) => (users.length > 0 ? <span className="list-users-cell"><GroupUsersIcon maxLength={3} usersList={users} isListing={true} /></span> : <span>-</span>);

export const _getUserAvatar = (email: string, name: string) => {
  return email ? (
    <UserAvatar
      {...{
        idOrUpn: email,
        avatarProps: { name: name, size: 'smaller', className: 'cui-ml-2' },
      }}
    />
  ) : (
    <SpeakerPersonIcon
      size='large'
      style={{ color: '#8f90a6' }}
      variables={{ largeSize: '24px' }}
    />
  )
}