import React from 'react';
import { ITemplateFormProperties } from './TicketTemplates';
import {
  CardBody,
  Text,
  SpeakerPersonIcon,
  LightningIcon,
  UserFriendsIcon,
  Flex,
  CalendarIcon
} from '@fluentui/react-northstar';
import { Row, Col } from 'react-grid-system';
import {  getUserByUserId, getEmailByUserId} from '../../fluent-ui/components/Board/BoardItem';
import { UserAvatar } from '../shared/components/UserAvatar';
import { TicketTemplate } from '../shared/interfaces/ticket.interface';
import { TEMPLATE, LICENSE_FEATURE, LIFECYCLE, AUTOMATION_RELATIVE_DATE } from '../shared/utils/constants';
import {
  getCategory,
  getPriority,
  getStatus,
  getSupportGroupName,
  getTicketType,
  getTeamName,
  customDateFormatter
} from '../tikit/ticketHelper';
import TicketTypeIcon from '../../svg/ticket-type-filled.svg';
import PriorityIcon from '../../svg/priority-icon.svg';
import CategoryIcon from '../../svg/category.svg';
import LifecycleIcon from '../../svg/lifecycle.svg';
import { showParentChild } from '../shared/components/TicketDetailInfo/TicketDetailInfo';
import { Icon } from '@fluentui/react/lib/Icon';
import { CheckFeature } from '../shared/components/CheckFeature';
import { useTranslation } from 'react-i18next';
import { showNumberFieldHandler } from '../shared/components/DatePicker/DatePicker';

const Title = (prop) => {
  return <div
  style={{ color: '#8f90a6' }}
  className={`ml-1`}
>
  {prop.title}:{' '}
</div>
}

const TemplateFieldsCardBody: React.FC<ITemplateFormProperties> = props => {
  const {t} = useTranslation();
  const { formData, isSelectionView } = props;
  const templateData: TicketTemplate = props.templateData;
  const assignee = templateData['AssigneeName'] || (templateData.AssigneeId && templateData.AssigneeId > 0) ? getUserByUserId(templateData.AssigneeId, formData.platformusers) : null;
  templateData.AssigneeId = templateData.AssigneeId || null;
  const status =
    templateData.StatusId && templateData.StatusId > 0
      ? showParentChild(
          getStatus(templateData.StatusId, formData.ticketStatus),
          formData.ticketStatus
        ).join(' > ')
      : null;
  templateData.StatusId = status ? templateData.StatusId : null;
  const group =
    templateData['SupportGroupName'] ||
    (templateData.SupportGroupId && templateData.SupportGroupId > 0)
      ? getSupportGroupName(templateData.SupportGroupId, formData.supportgroups)
      : null;
  templateData.SupportGroupId = group ? templateData.SupportGroupId : null;
  const team =
    templateData['TeamName'] ||
    (templateData.TeamId && templateData.TeamId > 0)
      ? getTeamName(templateData.TeamId, formData.teams)
      : null;
  templateData.TeamId = team ? templateData.TeamId : null;
  const ticketType =
    templateData.TicketTypeId && templateData.TicketTypeId > 0
      ? showParentChild(
          getTicketType(templateData.TicketTypeId, formData.ticketTypes),
          formData.ticketTypes
        ).join(' > ')
      : null;
  templateData.TicketTypeId = ticketType ? templateData.TicketTypeId : null;

  const category =
    templateData.CategoryId && templateData.CategoryId > 0
      ? showParentChild(
          getCategory(templateData.CategoryId, formData.categories),
          formData.categories
        ).join(' > ')
      : null;
  templateData.CategoryId = category ? templateData.CategoryId : null;
  const priority =
    templateData.PriorityId && templateData.PriorityId > 0
      ? showParentChild(
          getPriority(templateData.PriorityId, formData.priority),
          formData.priority
        ).join(' > ')
      : null;
  templateData.PriorityId = priority ? templateData.PriorityId : null;

  const lifecycle = templateData.Lifecycle?.Title ?? null;
  templateData.LifecycleId = lifecycle ? templateData.LifecycleId : null;

  const displayProperties = [];
  if (templateData.StatusId) displayProperties.push('status');
  if (templateData.UnsetSupportGroup || templateData.SupportGroupId)
    displayProperties.push('group');
  if (templateData.UnsetTeam || templateData.TeamId)
    displayProperties.push('team');
  if (templateData.PriorityId) displayProperties.push('priority');
  if (templateData.UnsetCategory || templateData.CategoryId)
    displayProperties.push('category');
  if (templateData.TicketTypeId) displayProperties.push('Type');
  if (templateData.LifecycleId) displayProperties.push('Lifecycle');
  if (templateData.DueDate || templateData.RelativeDueDate) displayProperties.push('DueDate');
  if (templateData.Tags && templateData.Tags.length>0) displayProperties.push('Tags');

const renderStatus = (isShowIcon: boolean) => {
  return <Col className="flex">
    <Flex vAlign="center">
      {!isShowIcon && <LightningIcon size="small" style={{ color: '#8f90a6' }} />}
      
      <Text size="small" className="flex">
        <Title title={t("ticket-details.apply-template.template-field-card-body.status")} />
        <div
          className={`ml-1 truncate mr-4 w-full lg:w-1/2 xl:w-3/4 2xl:w-full`}
          dir="rtl"
        >
          <bdi>
          {!templateData.StatusId && <>{TEMPLATE.UNSET_VALUE}</>}
          {templateData.StatusId ? <>{status}</> : null}
          </bdi>
        </div>
      </Text>
  </Flex>
</Col>
}

const renderGroup = (isShowIcon: boolean) => {
  return <Col className="truncate flex">
    <Flex vAlign="center">
      {
        !isShowIcon && <UserFriendsIcon size="small" style={{ color: '#8f90a6' }} />
      }
      
      <Text size="small" className="flex">
        <Title title={t("ticket-details.apply-template.template-field-card-body.group")} />
        <div
          className={`ml-1`}
        >
          {templateData.SupportGroupId === null && (
            <>{TEMPLATE.UNSET_VALUE}</>
        )}
          {templateData.SupportGroupId !== null && <>{group}</>}
        </div>
      </Text>
    </Flex>
  </Col>
}

const renderDueDate = (isShowIcon: boolean) => {
  const [relative, number] = (templateData.RelativeDueDate?.length > 0 ? templateData.RelativeDueDate : "").split('-');
  const daysCount = +number;
  const value = `${relative}-`;
  const _showNumberField = typeof value !== 'undefined' && showNumberFieldHandler(value);

  let relativeDateValue = "";
  if(_showNumberField)
    relativeDateValue = `${daysCount} ${AUTOMATION_RELATIVE_DATE[value]}`;
  else
    relativeDateValue = `${AUTOMATION_RELATIVE_DATE[`${value}0`]}`;
  
  return <Col className="truncate flex">
    <Flex vAlign="center">
      {
        !isShowIcon && <CalendarIcon style={{ color: '#8f90a6' }} />
      }
      
      <Text size="small" className="flex">
        <Title title="Due Date" />
        <div
          className={`ml-1`}
        >
          {templateData.DueDate !== null && <>{customDateFormatter(new Date(templateData.DueDate))}</>}
          {templateData.RelativeDueDate?.length > 0 && <>{relativeDateValue}</>}
        </div>
      </Text>
    </Flex>
  </Col>
}

const renderTags = (isShowIcon: boolean) => {

  let tagNameList = [];
  templateData.Tags.forEach(t=>{
    tagNameList.push(t.Name);
  });

  return <Col className="truncate flex">
    <Flex vAlign="center">
      {
        !isShowIcon && <Icon iconName="Tag" className={`text-lg`} style={{ color: '#8f90a6' }} />
      }
      
      <Text size="small" className="flex">
        <Title title="Tags" />
        <div
          className={`ml-1`}
        >
          {templateData.Tags !== null && <>{tagNameList.join(',')}</>}
        </div>
      </Text>
    </Flex>
  </Col>
}

const renderLifecycle = (isShowIcon: boolean) => {
  return <>
  <CheckFeature featureName={LICENSE_FEATURE.TicketLifecycle}>
    <Col className="truncate flex">
      <Flex vAlign="center">
        {
          !isShowIcon && <LifecycleIcon />
        }
        
        <Text size="small" className="flex">
          <Title title={LIFECYCLE.NAME} />
          <div
            className={`ml-1`}
          >
            {templateData.LifecycleId === null && (
              <>{TEMPLATE.UNSET_VALUE}</>
          )}
            {templateData.LifecycleId !== null && <>{lifecycle}</>}
          </div>
        </Text>
      </Flex>
    </Col>
  </CheckFeature>
  </>
}

const renderTeam = (isShowIcon: boolean) => {
  return <Col className="truncate flex">
    <Flex vAlign="center">
      {
        !isShowIcon && <UserFriendsIcon size="small" style={{ color: '#8f90a6' }} />
      }
      
      <Text size="small" className="flex">
        <Title title={t("ticket-details.apply-template.template-field-card-body.team")} />
        <div
          className={`ml-1`}
        >
          {templateData.TeamId === null && (
            <>{TEMPLATE.UNSET_VALUE}</>
        )}
          {templateData.TeamId !== null && <>{team}</>}
        </div>
      </Text>
    </Flex>
  </Col>
}

const renderPriority = (isShowIcon: boolean) => {
  return <Col className="flex">
    <Flex vAlign="center">
      {
        !isShowIcon && <PriorityIcon width={10} height={10} />
      }
      
      <Text size="small" className="flex">
        <Title title={t("ticket-details.apply-template.template-field-card-body.priority")} />
        <div
          className={`ml-1 w-full truncate mr-4 lg:w-1/2 xl:w-3/4 2xl:w-full`}
          dir="rtl"
        >
          <bdi>
          {templateData.PriorityId ? <>{priority}</> : <>{TEMPLATE.UNSET_VALUE}</>}
          </bdi>
        </div>
      </Text>
  </Flex>
</Col>
}

const renderCategory = (isShowIcon: boolean) => {
  return <Col className={`flex`}>
    <Flex vAlign="center">
      {!isShowIcon && <CategoryIcon width={12} height={12} stroke="#8f90a6" />}
      
      <Text size="small" className="flex">
        <Title title={t("ticket-details.apply-template.template-field-card-body.category")} />
        <div
          className={`ml-1 truncate mr-4 w-full lg:w-1/2 xl:w-3/4 2xl:w-full`}
          dir="rtl"
        >
          <bdi>
          {templateData.CategoryId ? <>{category}</> : <>{TEMPLATE.UNSET_VALUE}</>}
          </bdi>
        </div>
      </Text>
  </Flex>
</Col>
}

const renderType = (isShowIcon: boolean) => {
  return <Col className="flex">
  <Flex vAlign="center">
    {!isShowIcon && <TicketTypeIcon width={12} height={12} stroke="#8f90a6" />}
    
    <Text size="small" className="flex">
      <Title title={t("ticket-details.apply-template.template-field-card-body.type")} />
      <div
        className={`ml-1 truncate mr-4 w-full lg:w-1/2 xl:w-3/4 2xl:w-full`}
        dir="rtl"
      >
        <bdi>
        {templateData.TicketTypeId ? <>{ticketType}</> : <>{TEMPLATE.UNSET_VALUE}</>}
        </bdi>
      </div>
    </Text>
  </Flex>
</Col>
}


  const renderContent = (array, isShowIcon: boolean) => {
    return array.map(key => {
      switch (key) {
        case 'status':
          return (
            renderStatus(isShowIcon)
          );
        case 'group':
          return (
            renderGroup(isShowIcon)
          );
        case 'team':
          return (
            renderTeam(isShowIcon)
          );
        case 'priority':
          return (
            renderPriority(isShowIcon)
          );
        case 'category':
          return (
            renderCategory(isShowIcon)
          );
        case 'Type':
          return (
            renderType(isShowIcon)
          );
        case 'DueDate':
        case 'RelativeDueDate':
          return (
            renderDueDate(isShowIcon)
          );
        case 'Tags':
            return (
              renderTags(isShowIcon)
            );
        case 'Lifecycle':
          return (
            renderLifecycle(isShowIcon)
          );

        default:
          return <></>;
      }
    });
  };
  return (
    <CardBody fitted>
      <>
        {!props.isSelectionView && (
          <Row>
            <Col>
              {templateData.Description && (
                <Text content={templateData.Description} />
              )}
              {!templateData.Description && <Text>&nbsp;</Text>}
            </Col>
          </Row>
        )}

        <Row style={{ marginTop: '16px' }}>
          <Col
            className={`truncate`}
            style={{ height: '1.75rem', display: 'flex', alignItems: 'center' }}
          >
            {(templateData.UnsetAssignee || templateData.AssigneeId != null) && (
              <>
                {templateData.AssigneeId != null && templateData.AssigneeId > 0 && (
                  <>
                    {!isSelectionView && (
                      <UserAvatar
                        {...{
                          idOrUpn: getEmailByUserId(templateData.AssigneeId, formData.platformusers),
                          avatarProps: { name: assignee, size: 'small' }
                        }}
                      />
                    )}

                    {isSelectionView && (
                      <Text>
                        <span
                          style={{ color: '#8f90a6' }}
                          className={`ml-1`}
                        >
                          {t("ticket-details.apply-template.template-field-card-body.assignee")}:{' '}
                        </span>
                      </Text>
                    )}

                    <Text size="small" className={`ml-1`}>
                      {assignee}
                    </Text>
                  </>
                )}
                {(!templateData.AssigneeId || templateData.AssigneeId == 0) && (
                  <>
                    <SpeakerPersonIcon
                      size="small"
                      style={{ color: '#8f90a6' }}
                    />
                    <Text size="small" className={`ml-1`}>
                      <>{TEMPLATE.UNASSIGNED}</>
                    </Text>
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
        <Row className={`mt-1`}>
          {renderContent(displayProperties.slice(0, 1), isSelectionView)}
        </Row>
        <Row className={`mt-1`}>
          {renderContent(displayProperties.slice(1, 2), isSelectionView)}
        </Row>
        <Row className={`mt-1`}>
          {renderContent(displayProperties.slice(2, 3),isSelectionView)}
        </Row>
        <Row className={`mt-1`}>
          {renderContent(displayProperties.slice(3, 4), isSelectionView)}
        </Row>
        <Row className={`mt-1`}>
          {renderContent(displayProperties.slice(4, 5), isSelectionView)}
        </Row>
        <Row className={`mt-1`}>
          {renderContent(displayProperties.slice(5, 6), isSelectionView)}
        </Row>
        <Row className={`mt-1`}>
          {renderContent(displayProperties.slice(6, 7), isSelectionView)}
        </Row>
      </>
    </CardBody>
  );
};

export default TemplateFieldsCardBody;
