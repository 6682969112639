import React from 'react';
import { AppStateContext } from '../AppState';
import ChartBoard from './tabs/Charts/ChartBoard';
import { actions } from '@storybook/addon-actions';

const eventsFromNames = actions('onInteraction');

export const Report: React.FC = () => {
  return (
    <AppStateContext.Consumer>
      {state => {
        return <ChartBoard FilterDate={state.listChartFilterDate} />;
      }}
    </AppStateContext.Consumer>
  );
};
