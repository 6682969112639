import React, { useEffect, useState } from "react";
import { TextArea, Text, ProviderConsumer as FluentUIThemeConsumer, Flex } from "@fluentui/react-northstar";
import { PeoplePicker } from '@microsoft/mgt-react';
import { UserType, PersonType } from '@microsoft/mgt-components';
import { Toggle } from "@fluentui/react";
import { themeNames } from '@fluentui/react-teams';
import { LifecycleApproval } from "../utils/typings";
import { PlatformUser } from "../../shared/interfaces/platformuser.interface";
import { ThemeColorScheme } from "../../shared/common/TeamsTheme";
import { LIFECYCLE } from './../../shared/utils/constants';
import { appState } from "../../AppState";
import { platformService } from "../../shared/services/platform.service";

interface Props{
    approvalData: LifecycleApproval,
    setApprovalData: (approvalData: LifecycleApproval) => void;
}
export const LabelElement = (props: any) => {
  return (
    <div>
      <Flex>
        <Text content={props.label} style={{ display: 'block' }} />
        {props.required && (
          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
        )}
      </Flex>
      <div>{props.children}</div>
    </div>
  );
};
export const PhaseApprovalForm = ({approvalData,setApprovalData}:Props) => {
    const api = new platformService();
    const [switchRecipents, setswitchRecipents] = useState<boolean>(approvalData.IsRequiredByAll);
    const [isPickerLoaded, setIsPickerLoaded] = useState(false);
    const currentState = appState();

    useEffect(()=>{
      setApprovalData({
        ...approvalData,
        IsRequiredByAll: switchRecipents
      });
    },[switchRecipents]);

    useEffect(()=>{
      if(!isPickerLoaded){
        const picker: any = document.querySelector('mgt-people-picker');
        if(picker){
          picker.selectedPeople = approvalData.Approvers.map(approver => {
            return {
              displayName: approver.FullName,
              userPrincipalName: (approver.Email ?? approver.UserName),
              id: approver.AadObjectId ?? ''
            }
          });
          setIsPickerLoaded(true);
        }
      }
    },[isPickerLoaded]);

    const setCdmUser = async (el: any): Promise<PlatformUser> => 
      currentState.platformusers.find(x => x.UserName == el.userPrincipalName || x.AadObjectId == el.id) ??
      await api.getOrCreatePlatformUser({ Email: el.mail ?? el.userPrincipalName, GivenName: el.givenName, FamilyName: el.familyName, UserName: el.userPrincipalName, AadObjectId: el.id }) as PlatformUser;
        
    const setApprovers = async event => {
      let users: PlatformUser[] = [];
      if (event.detail.length > 0) {
        for (const i of event.detail) 
          if (!users.some(u => u.AadObjectId == i.id || u.UserPrincipalName == i.userPrincipalName))
            users.push(await setCdmUser(i));
      }
      setApprovalData({ ...approvalData, Approvers: users });
    }

    return (
      <FluentUIThemeConsumer
      render={globalTheme => (
        
        <div
          className="min-h-32"
          style={{
            color: 'var(--mgt-theme-default-foreground)',
            ...ThemeColorScheme(globalTheme.siteVariables)
          }}
        >
            <div >
                <LabelElement label={LIFECYCLE.TITLE_WITH_MAX_LENGTH} required={true}>
                    <TextArea
                        maxLength={500}
                        required
                        fluid
                        value={approvalData.Name}
                        onChange={(_e, p) => {
                          setApprovalData({
                            ...approvalData,
                            Name: p.value
                          });
                      }}
                    />
                </LabelElement>
            </div>
            <div>
                <LabelElement label={LIFECYCLE.APPROVERS} >
                    <PeoplePicker
                      className={`width-med height-small`}
                      userType={UserType.user}
                      type={PersonType.person}
                      selectionChanged={setApprovers}
                    />
                </LabelElement>
                <Toggle
                    className="pt-2"
                    styles={{ label: { color:`${globalTheme.siteVariables.theme === themeNames.Dark ? 'white' : 'black'}` }}}
                    label={LIFECYCLE.REQUIRED_APPROVER_RESPONSE}
                    {...(switchRecipents && {
                        defaultChecked: true,
                        style: {
                            background: '#6264a7',
                            opacity: 1
                        }
                    })}
                    inlineLabel
                    onChange={(
                        _ev: React.MouseEvent<HTMLElement>,
                        checked?: boolean
                      ) => {
                       setswitchRecipents(checked);
                      }}
                />
                <div className="pt-1">               
                <LabelElement label={LIFECYCLE.ADDITIONAL_DETAILS} >
                    <TextArea
                        style={{ height: 100 }}
                        fluid
                        value={approvalData.AdditionalDetails}
                        onChange={(_e, p) => {
                          setApprovalData({
                            ...approvalData,
                            AdditionalDetails: p.value
                          });
                        }}
                    />
                </LabelElement>
                </div>
            </div>
            </div>)} />
    ) 
};