import { Button, Flex, Loader, TextArea, ThemePrepared } from '@fluentui/react-northstar';
import { Input } from 'adaptivecards';
import _ from 'lodash';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { appState, useSetState } from '../../AppState';
import { platformService } from '../../shared/services/platform.service';
import { LICENSE_FEATURE, markdownComponents, STATUS_GUID } from '../../shared/utils/constants';
import { AdaptiveCardElement } from '../../templates/AdaptiveCardElement';
import { LabelElement } from '../../token/TokenModal';
import { useTranslation } from 'react-i18next';
import { getPortalSource, getUserFromToken } from '../ticketHelper';
import { DropdownSupportGroup } from '../../shared/components/TicketForm';
import { CheckFeature } from '../../shared/components/CheckFeature';
import rehypeRaw from 'rehype-raw';  // IMPORTANT: DO NOT USE WITHOUT DOM-PURIFY. This could lead to script injection.
import DOMPurify from 'dompurify';
import remarkBreaks from 'remark-breaks';

interface Props {
  globalTheme: ThemePrepared<any>;
  onCreateTicket?: () => void;
  onCreateComplete?: () => void;
  teamsConfig?: any;
}

export const NewRequest = ({
  globalTheme,
  onCreateTicket,
  onCreateComplete,
  teamsConfig
}: Props) => {
  const api = new platformService();
  const history = useHistory();
  const setAppState = useSetState();
  const currentState = appState();
  const { t } = useTranslation();

  const [request, setRequest] = useState("");
  const [state, setState] = useState(0);
  const [answer, setAnswer] = useState("");
  const [templateId, setTemplate] = useState<number | null>(null);
  const [templateResponse, setTemplateResponse] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState(t('requests.dialog.asking'));
  const [defaultTeam, setDefaulTeam] = useState(currentState?.defaultTeam?.Id ?? null);
  const [searchTeam, setSearchTeam] = useState("");

  const onChangeTeamQuery = useCallback((searchQuery: string) => {
    setSearchTeam(searchQuery);
  }, [searchTeam]);

  const askQuestion = async () => {
    try {
      setLoading(true);
      const result = await api.getKnowledgeAnswer(request);
      const answer = result.data;
      if (answer.AnswerType === "AddTicket") {
          setState(5);
          setShowMessage(true);
          setLoading(false);
        
        return;
      } else if (answer.AnswerType === "ApplyTemplate") {
        setState(2);
      } else if (answer.AnswerType === "Text") {
        setState(1);
      }

      setAnswer(answer.Answer);
      setTemplate(answer.AnswerId);
    } catch {
      setState(3);
    }
    setShowMessage(false);
    setLoading(false);
  };

  const deflectTicket = async () => {
    setState(4);
    try {
      const result = await submitTicket(true);
      await api.addCommentToTicket(result.data.Id, { Body: `Deflected answer: ${answer}`, IsPublic: true, ModifiedById: 0 });
    } catch {
      // Ticket was deflected, we can ignore the error.
    }
  };

  const createTicket = async () => {
    if (onCreateTicket) onCreateTicket();

    setLoading(true);
    setLoadingMsg(t('requests.dialog.creating'));
    
    try {
      const ticket = await submitTicket(false);
      const requester = currentState.platformusers?.find(o => o.Id == ticket.data.RequesterId);
      
      const result = await api.GetRequestsAndAffectedTickets(Number(requester?.Id));

      const apiData = {
        allTickets: JSON.parse(result?.data?.value),
        allStatus: currentState.ticketStatus
      };

      const _tickets = apiData.allTickets.map((ticket) => ({ ...ticket, Status: apiData.allStatus.find((status) => status.Id === ticket.StatusId) }));
      const [closed, active] = _.partition(_tickets, ({ Status }) => Status && (Status.Guid === STATUS_GUID.CLOSED || Status.Guid === STATUS_GUID.DEFLECTED || Status.Guid === STATUS_GUID.RESOLVED));

      setAppState(prevState => {
        prevState.tickets = active;
        prevState.closedTickets = closed;
        return prevState;
      });
      
      history.push(`/requests/${ticket.data.Id}`);
    } catch {
      setState(3);
    }

    if (onCreateComplete) onCreateComplete();
    setLoading(false);
  };

  const submitTicket = async (isDeflected: boolean) => {
    const requester = await getUserFromToken();
    let source = await getPortalSource();

    const ticket: any = {
      Title: request,
      RequesterId: requester.Id,
      Requester: {
        EmailAddress: (requester.Email ?? requester.UserName),
        AadObjectId: requester.AadObjectId,
        UserPrincipalName: requester.UserName
      },
      TemplateId: templateId,
      SourceId: source.Id,
      TeamId: templateId ? null : defaultTeam
    };
    if (templateId) {
      ticket.CardAnswerJson = JSON.stringify(templateResponse)
    }

    if (isDeflected) {
      const deflectedStatus = currentState.ticketStatus.find((status) => status.Guid === STATUS_GUID.DEFLECTED);
      
      if (deflectedStatus !=null) {
        ticket.StatusId = deflectedStatus.Id;
        ticket.ResolutionDate = new Date();
      } else {
        throw new Error('Unable to get deflected status');
      }
    }
    return await api.addRequest(ticket);
  };

  return (
    <>
      {isLoading && (<Loader className='m-4' label={loadingMsg} />)}
      {!isLoading && (<>
        {state === 0 && (
          <LabelElement label={t('requests.dialog.title')} required={true}>
            <TextArea maxLength={1000} required style={{ height: 60 }} fluid onChange={(e, p) => { setRequest(p.value); }} />
          </LabelElement>
        )}
        {state === 1 && (
          <>
            <Markdown remarkPlugins={[remarkGfm, remarkBreaks]} rehypePlugins={[rehypeRaw]} components={markdownComponents}>
              {DOMPurify.sanitize(answer)}
            </Markdown>
            <div className='mt-4'>{t('requests.dialog.was-helpful')}</div>
          </>)}
        {state === 2 && (<AdaptiveCardElement jsonString={answer} cardProps={{ styles: { height: 'fit-content' } }} onInputValueChanged={(input: Input) => {
          setTemplateResponse(cardResponse => {
            cardResponse[input.id] = input.value;
            return cardResponse;
          });
        }} />)}
        <div hidden={state !== 3}>{t('requests.dialog.oops')}</div>
        <div hidden={state !== 4}>{t('requests.dialog.glad-we-helped')}</div>
        <div hidden={state !== 5} style={{ overflow: "visible !important" }}>
          {showMessage && <div style={{ marginBottom: '10px' }}>{t('requests.dialog.no-info')}</div>}
          <CheckFeature featureName={LICENSE_FEATURE.MultiDepartment}>
            {teamsConfig?.EnableMultiDepartmentPicker && (<LabelElement label={teamsConfig?.MultiDepartmentPickerMessage && teamsConfig?.MultiDepartmentPickerMessage.length > 0 ? teamsConfig.MultiDepartmentPickerMessage : t('bot-configuration.enduser-select-team-default')} 
              required={true} styles={{ marginBottom: '10px' }}>
              <DropdownSupportGroup items={currentState.teams} handler={(value: any) => setDefaulTeam(value.Id)} properties={{ defaultValue: undefined }} value={defaultTeam} header={'FriendlyName'} setSearchTeam={setSearchTeam} 
                searchQuery={searchTeam} onSearchQueryChange={onChangeTeamQuery}  style={{ marginBottom: '10px' }} />
            </LabelElement>)}
          </CheckFeature>
          <LabelElement label={t('requests.dialog.title')} required={true}>
            <TextArea maxLength={1000} required style={{ height: 60 }} value={request} fluid onChange={(e, p) => { setRequest(p.value); }} />
          </LabelElement>
        </div>

        <Flex gap='gap.small' className={'mt-4'} hAlign='end'>
          <Button primary content={t('requests.dialog.ask')} onClick={askQuestion} style={{ marginRight: 0 }} hidden={state !== 0} disabled={request.trim() === "" || (teamsConfig?.EnableMultiDepartmentPicker && !defaultTeam)} />
          <Button primary content={t('common.buttons.yes')} onClick={deflectTicket} hidden={state !== 1} />
          <Button primary content={t('requests.dialog.no-create')} onClick={() => { setState(5); }} hidden={state !== 1} />
          <Button primary content={t('common.buttons.submit')} onClick={createTicket} style={{ ...(state === 5 ? { marginRight: 0 } : {}) }} hidden={state !== 2 && state !== 5} />
          <Button primary content={t('common.buttons.something-else')} onClick={() => { setState(0); }} style={{ marginRight: 0 }} hidden={state !== 1 && state !== 2} />
        </Flex>
      </>)}
    </>);
}