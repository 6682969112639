import { DateTime } from 'luxon';
import i18n from 'i18next';

export const ConvertDateToLocale = (date: string, format: Intl.DateTimeFormatOptions) => {
  if(!date || date === "") 
    return i18n.t('ticket.ticket-board.ticket-card.none');
  
  const locale = navigator.language ?? i18n.language;
  return DateTime.fromJSDate(new Date(date)).setLocale(locale).toLocaleString(format);
}

export const ConvertISODateToLocal = (date: string, format: string) => {
  return DateTime.fromISO(date, { zone: 'utc' }).toLocal().toFormat(format, { locale: i18n.language });
};

export const IsDateThisWeek = (date: string) => {
  const queryDate = DateTime.fromJSDate(new Date(date));

  const dt = DateTime.utc();
  const currentYear = dt.year;
  const nextWeek = dt.weekNumber;
  const nextWeekDt = DateTime.fromObject({
    weekYear: currentYear,
    weekNumber: nextWeek
  });

  let isNextWeek = false;

  if (
    nextWeekDt.startOf('week').toISO() === queryDate.toISO() ||
    nextWeekDt.startOf('week').plus({ day: 1 }).toISO() === queryDate.toISO() ||
    nextWeekDt.startOf('week').plus({ day: 2 }).toISO() === queryDate.toISO() ||
    nextWeekDt.startOf('week').plus({ day: 3 }).toISO() === queryDate.toISO() ||
    nextWeekDt.startOf('week').plus({ day: 4 }).toISO() === queryDate.toISO() ||
    nextWeekDt.startOf('week').plus({ day: 5 }).toISO() === queryDate.toISO() ||
    nextWeekDt.startOf('week').minus({ day: 1 }).toISO() === queryDate.toISO()
  ) {
    isNextWeek = true;
  }

  return isNextWeek;
};

export const IsDateNextWeek = (date: string) => {
  const queryDate = DateTime.fromJSDate(new Date(date));

  const dt = DateTime.utc();
  const currentYear = dt.year;
  const nextWeek = dt.weekNumber + 1;
  const nextWeekDt = DateTime.fromObject({
    weekYear: currentYear,
    weekNumber: nextWeek
  });

  let isNextWeek = false;

  if (
    nextWeekDt.startOf('week').toISO() === queryDate.toISO() ||
    nextWeekDt.startOf('week').plus({ day: 1 }).toISO() === queryDate.toISO() ||
    nextWeekDt.startOf('week').plus({ day: 2 }).toISO() === queryDate.toISO() ||
    nextWeekDt.startOf('week').plus({ day: 3 }).toISO() === queryDate.toISO() ||
    nextWeekDt.startOf('week').plus({ day: 4 }).toISO() === queryDate.toISO() ||
    nextWeekDt.startOf('week').plus({ day: 5 }).toISO() === queryDate.toISO() ||
    nextWeekDt.startOf('week').minus({ day: 1 }).toISO() === queryDate.toISO()
  ) {
    isNextWeek = true;
  }

  return isNextWeek;
};

export const IsDateFuture = (date: string) => {
  const queryDate = DateTime.fromJSDate(new Date(date));

  const dt = DateTime.utc();
  const currentYear = dt.year;
  const nextWeek = dt.weekNumber + 1;
  const nextWeekDt = DateTime.fromObject({
    weekYear: currentYear,
    weekNumber: nextWeek
  });

  let isFuture = false;

  if (nextWeekDt.startOf('week').plus({ day: 6 }).toISO() < queryDate.toISO()) {
    isFuture = true;
  }

  return isFuture;
};

export const IsDateLate = (date: string | null) => {
  const queryDate = DateTime.fromJSDate(new Date(date));
  if (date === null) {
    return false;
  }
  return DateTime.fromJSDate(new Date()).toISO() > queryDate.toISO();
};

export const IsDateToday = (date: string) => {
  const queryDate = DateTime.fromJSDate(new Date(date));
  return queryDate.hasSame(DateTime.local(), 'day');
};

export const IsDateTomorrow = (date: string) => {
  const queryDateMinus1 = DateTime.fromJSDate(new Date(date)).minus({ day: 1 });
  return queryDateMinus1.hasSame(DateTime.local(), 'day');
};
