import React, {useState} from 'react';
import { useRouteMatch } from 'react-router-dom';
import { BrowserAppTemplate } from '../browser-pages/BrowserAppTemplate';
import { Tickets } from './TicketBoard';
import { UpgradeNeeded} from '../shared/components/UpgradeNeeded';
import { getCachedFeature } from '../shared/cache/FeatureCache';
import { LICENSE_FEATURE } from '../shared/utils/constants';
import { useTranslation } from 'react-i18next';

const pagesMapper = {
  '/': 'table',
  list: 'table',
  board: 'board',
  chart: 'chart'
};

export const TicketPage = () => {
  const { url } = useRouteMatch();
  const lastPage = localStorage.getItem('lastTicketPage');
  const {t} = useTranslation();

  let page = lastPage || 'table' ;
  if (url !== '/') page = url.split('/').pop();

  const [isFeatureEnabled, setIsFeatureEnabled] = useState(true);
  (async () => setIsFeatureEnabled((await getCachedFeature(LICENSE_FEATURE.TicketPortal))))();

  const activePage = isFeatureEnabled ? <Tickets page={pagesMapper[page]} /> : 
  <UpgradeNeeded
    toolbarText={t('ticket.toolbar.tickets')}
    headerText={t('ticket-portal.no-access-current-plan')}  
    subheaderText={t('ticket-portal.no-access-upgrade-plan')} />
   
  return (<BrowserAppTemplate page={activePage} active={'tickets'} hideSettingsNav={true} />);
};
