import React, { useEffect, useState, useCallback } from 'react';
import {
  ProviderConsumer,
  Flex,
  Input,
  SearchIcon,
  Card,
  Text,
  CardHeader,
  Checkbox,
  Dialog,
  CloseIcon,
  Button,
  CardBody
} from '@fluentui/react-northstar';
import { LIFECYCLE, DEFAULT_API_STATUS, ENDPOINT_SECURITY_MAPPINGS, SETTINGS_PAGES, ERROR, DONE, LOADING, CSS_CLASS, UNAUTHORIZED_ACCESS, SOMETHING_WENT_WRONG, CONTACT_ADMIN, BUTTONS } from '../shared/utils/constants';
import { EmptyData } from '../shared/components/EmptyData';
import EmptySVG from '../../svg/empty.svg';
import { platformService } from '../shared/services/platform.service';
import { Communication } from '@fluentui/react-teams';
import { LoadingScreen } from '../shared/components/LoadingScreen';


export interface LifecycleSelectionProperties {
  ticketData: Ticket;
  dispatchEvent?: any;
  openApplyLifecycle: boolean;
  closeApplyLifecycle: (savedState?: any) => void;
  applyLifecycleToTickets: (lifecycleId: number, ticketId:number ) => {};
}

export const LifecycleSelection = (
  props: LifecycleSelectionProperties
) => {
  const { ticketData, openApplyLifecycle, closeApplyLifecycle, applyLifecycleToTickets } = props;
  const [lifecycles, setLifecycles] = useState<TicketLifecycle[]>([] as TicketLifecycle[]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedLifecycleId, setSelectedLifecycleId] = useState<number>(0);
  const [reqStatus, setReqStatus] = useState<APIStatus>(
    DEFAULT_API_STATUS as APIStatus
  );
  const api = new platformService();

  const fetchData = useCallback(async function(){
    setReqStatus({ ...reqStatus, msg: LIFECYCLE.GETTING_ALL_LIFECYCLE });

    const check = ENDPOINT_SECURITY_MAPPINGS.find(x => x.key == SETTINGS_PAGES.LIFECYCLE);
    if (check && JSON.parse(sessionStorage.getItem('my_application_roles') || '[]').filter(x => x.EndpointPath == check.path && x.Permissions.includes(check.method)).length <= 0) {
        setReqStatus({
        ...reqStatus,
        status: ERROR,
        errCode: 403
      });
      return;
    }
    try {
      const {
        data: { value }
      } = await api.getLifecycles("?$expand=Phases($orderby=Order)")

      setLifecycles(currentItems => {
        return value.map(function(v) {
          v['hidden'] = false;
          return v;
        });
      });
      setTimeout(function(){
        setReqStatus({ ...reqStatus, status: DONE });
      }, 1000);
      setIsDataLoaded(true);
    } catch (err) {
      setReqStatus({
        ...reqStatus,
        status: ERROR,
        errCode: err["response"]["status"]
      });
    }
  }, []);

  useEffect(() => {
      if(!isDataLoaded)
        fetchData();
  }, [isDataLoaded]);

  const errConfig = {
    fields: {
      title:
        reqStatus.errCode === 401 || reqStatus.errCode === 403
          ? UNAUTHORIZED_ACCESS
          : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  };

  function handleSearch(event: any) {
    const target = event.target.value.toString() || '';
    setLifecycles(currentItems => {
      if (target.length > 0) {
        let re = new RegExp(target, 'i');
        const matches = lifecycles.map(json => {
          const match = JSON.stringify(json).match(re);
          json['hidden'] = !(match && match.length > 0);
          return json;
        });
        return matches;
      } else
        return lifecycles.map(json => {
          json['hidden'] = false;
          return json;
        });
    });
  };

  const onSelectedCard = function(checked: boolean, item: TicketLifecycle) {
    setSelectedLifecycleId(checked ? item.Id : 0);
  };

  const confirmApplyLifecycleToTicket = async function() {
    setReqStatus({ ...reqStatus, status: LOADING, msg: LIFECYCLE.APPLYING_LIFECYCLE });
    
    const success = await applyLifecycleToTickets(selectedLifecycleId, ticketData.Id);
    if(success){
      setReqStatus({ ...reqStatus, status: DONE });
      closeApplyLifecycle({IsSuccess: true});
    } else {
        setReqStatus({
            ...reqStatus,
            status: ERROR,
            errCode: 500
            });
        closeApplyLifecycle({IsSuccess: false});
    }
  }

  const renderItems = lifecycles.filter(x => !x["hidden"]).map((item: TicketLifecycle, index) => {
    return (
      <Card
        key={item.Id}
        fluid
        className={`mt-4`}
        elevated
        // {...(template.Id && template.Id == item.Id && { selected: true })}
        // hidden={item['hidden']}
        // onClick={() => {
        //   onSelectedCard(selectedLifecycleId != item.Id, item);
        // }}
      >
        <Card.TopControls style={{ zIndex: 1 }}>
          <Checkbox
            // checked={selectedLifecycleId == item.Id}
            onChange={function(e, data) {
              onSelectedCard(data.checked, item);
            }}
          />
        </Card.TopControls>
        <CardHeader>
          <Flex gap="gap.small">
            <Flex column styles={{ maxWidth: '90%' }}>
              <Text content={item.Title} weight="semibold" />
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody fitted>
        <>
        <Flex className={'cui-pt-4 cui-pv-4'}>
        {item["Phases"]
          .sort(function(a: TicketLifecyclePhase, b: TicketLifecyclePhase){return a.Order - b.Order})
          .map(function(phase: TicketLifecyclePhase){
          return (
            <div key={phase.Id}
              className={`${phase.IsCurrent ? CSS_CLASS.STATUS_PILL_OTHERS : CSS_CLASS.STATUS_PILL_CLOSED} mr-2`}
            >
              <Text weight="bold" size="smaller">
                {phase.Name}
              </Text>
            </div>
          )
        })
        }
        </Flex>
        </>
      </CardBody>
        {/* <TemplateFieldsCardBody templateData={item} formData={formData} /> */}
      </Card>
    );
  });

  return (
    <ProviderConsumer
      render={globalTheme => {
        return (
            <Dialog
              content={
                (
                <>
                    {
                    reqStatus.status === LOADING ? (
                        <Flex
                            column
                            style={{ flex: 1, maxHeight: '40vh' }}
                            gap="gap.large"
                        >
                        <LoadingScreen message={reqStatus.msg} />
                        </Flex>
                    ) : 
                    (
                    <>
                    {reqStatus.status == ERROR ? (
                        <Flex
                        column
                        style={{ flex: 1, maxHeight: '40vh' }}
                        gap="gap.large"
                        >
                        <Communication {...errConfig} />
                        </Flex>
                    ) : (
                        <Flex
                            column
                            style={{ flex: 1, minHeight: '40vh', maxHeight: '70vh', overflow: 'auto' }}
                            gap="gap.large"
                        >
                            <Flex.Item>
                            <Flex column>
                                <Text
                                content={LIFECYCLE.SELECT_LIFECYCLE}
                                weight="bold"
                                size="large"
                                />
                                <Text content={LIFECYCLE.SELECT_LIFECYCLE_DESCRIPTION} />
                            </Flex>
                            </Flex.Item>
                            <Flex.Item>
                            <Flex vAlign="center">
                                <Text content={LIFECYCLE.EXISTING_LIFECYCLE} weight="bold" />
                                <Flex.Item push>
                                <Input
                                    icon={<SearchIcon />}
                                    placeholder={LIFECYCLE.SEARCH}
                                    onChange={handleSearch}
                                />
                                </Flex.Item>
                            </Flex>
                            </Flex.Item>
                            <Flex.Item>
                            <div className={`grid grid-cols-1 gap-4 ${ (renderItems && renderItems.length > 0) ? "lg:grid-cols-2" : ""}`}>
                                {renderItems?.length > 0 ? (
                                renderItems
                                ) : (
                                <EmptyData
                                    headerText={LIFECYCLE.NO_LIFECYCLE_SHOW}
                                    subheaderText={''}
                                    SVGIcon={<EmptySVG width={130} height={130} />}
                                />
                                )}
                            </div>
                            </Flex.Item>
                        </Flex>
                    )}
                    </>
                    )}
                </>
                )
              }
              open={openApplyLifecycle}
              headerAction={(reqStatus.msg === LIFECYCLE.APPLYING_LIFECYCLE) ? {} : {
                icon: <CloseIcon />,
                title: LIFECYCLE.CLOSE,
                onClick: closeApplyLifecycle
              }}
              footer={reqStatus.status === DONE ?
                <>
                <Flex gap="gap.small">
                  <Flex.Item push>
                    <Button
                      content={BUTTONS.CANCEL}
                      onClick={closeApplyLifecycle}
                    />
                  </Flex.Item>
                  <Flex.Item>
                    <Button
                      content={BUTTONS.CONFIRM}
                      primary
                      disabled={selectedLifecycleId == 0}
                      onClick={confirmApplyLifecycleToTicket}
                    />
                  </Flex.Item>
                </Flex>
                </>
                : <></>
              }
            />
        )}
    }
    />
  );
};
