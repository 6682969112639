import React, { useReducer, useState } from 'react';
import TicketForm, {
  ActionTypes,
  entityTicketModel,
  entityTicketReducer,
  TicketActions,
  TicketEntityProps,
  UnitOfWork,
} from '../shared/components/TicketForm';
import { platformService } from '../shared/services/platform.service';
import { appState, AppStateContext, useSetState } from '../AppState';
import { SkeletonForm } from '../shared/components/Skeleton';
import {
  Alert,
  Button,
  Card,
  Flex,
  FlexItem,
  Loader
} from '@fluentui/react-northstar';
import * as microsoftTeams from "@microsoft/teams-js";
import { CheckLogin } from '../shared/components/CheckLogin';
import { graphService } from '../shared/services/graph.service';
import { loadFilters } from './ticketHelper';
import { useTranslation } from 'react-i18next';
import {
  ADMINISTRATORS,
  ANALYSTS,
  AGENTS,
  UNAUTHORIZED_ACCESS,
  SOMETHING_WENT_WRONG,
  CONTACT_ADMIN
} from '../shared/utils/constants';
import { Communication } from '@fluentui/react-teams';

const AddTicket: React.FC = () => {
  const {t} = useTranslation();
  const api = new platformService();
  const graphAPI = new graphService();
  const currentState = appState();
  const defaultData = entityTicketModel();
  const [ticketData, dispatch] = useReducer(entityTicketReducer, entityTicketModel());
  const setState = useSetState();
  const [alert, setAlert] = useState<any>({
    title: '',
    show: false,
    attributes: {},
  });
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const hasAccess = currentState.userRoles.roles.includes(ADMINISTRATORS) || currentState.userRoles.roles.includes(ANALYSTS) || currentState.userRoles.roles.includes(AGENTS);

  const loadPage = async () => {
    await loadFilters(api, graphAPI, currentState, setState);
    let newstate = {
      tickets: [],
      closedTickets: [],
      platformusers: [],
      menu: null,
      boardFilter: null,
      activeFilter: null,
      listFilter: null,
      comments: [],
      isLoading: false,
      isError: false,
      listChartFilterDate: null,
    };
    setState(state => ({ ...state, ...newstate }));
    dispatch({action:TicketActions.ClearData});

    const params = new URLSearchParams(window.location.search);
    const requestorId = params.get('rid') || null;
    const question =params.get('q') || null;
    if (requestorId !== null) {
      let users = await api.getUsers(`?$filter=aadObjectId eq '${requestorId}'`);
      const user = users.data.value[0];

      dispatch({ action: TicketActions.Requester, isEntity: false, data: user });
    }
    
    if (question !== null) {
      dispatch({ action: "Title", isEntity: true, data: question });
    }   
    
    //setTicketData(defaultData);
  };

  const CloseTaskModlue = () => {
    microsoftTeams.dialog.url.submit();
  };

  const createTikit = () => {
    if (validateRequired()) return;
    setIsSubmit(true);
    UnitOfWork.AddTicketFromTaskModule(ticketData, onCloseTaskModule, t);
  };

  const validateRequired = () => {
    setAlert({ title: '', show: false, attributes: {} });
    if (!ticketData.Entity.Title || !ticketData.Requester || !ticketData.Entity.TeamId) {
      setAlert({ title: t('ticket-details.edit-ticket-view.required-field-must-be-filled-out'), show: true, attributes: { danger: true } });
      return true;
    }
    return false;
  };

  const onCloseTaskModule = () => {
    CloseTaskModlue();
  };

  const errConfig = {
    fields: {
      title:
        !hasAccess
          ? UNAUTHORIZED_ACCESS
          : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  };

  return (
    <AppStateContext.Consumer>
      {(state) => {
        return (
          <div className={`h-screen`}>
            <CheckLogin onSignedIn={loadPage}>
            {(!hasAccess) ? <Communication {...errConfig} />:
              (state.isLoading) ? <SkeletonForm /> : <AddTicketForm {...{ isSubmit, alert, ticketData, CloseTaskModlue, createTikit, dispatch }} />}
            </CheckLogin>
          </div>
        );
      }}
    </AppStateContext.Consumer>
  );
};

export default AddTicket;

const AddTicketForm = ({ isSubmit, alert, ticketData, CloseTaskModlue, createTikit, dispatch }) => {
  return (
    <>
      {isSubmit ? (
        <Loading />
      ) : (
        <Card style={{ width: '100%', overflowY: 'scroll' }}>
          <>
            <Card.Body>
              {alert.show && (
                <Alert
                  fitted
                  {...alert.attributes}
                  content={alert.title}
                  style={{ fontSize: 12, width: '100%' }}
                />
              )}
              <TicketForm type={ActionTypes.Create} data={ticketData} dispatch={dispatch}/>
            </Card.Body>
            <Card.Footer>
              <Flex gap='gap.small'>
                <FlexItem push>
                  <Button content={'Cancel'} onClick={CloseTaskModlue}></Button>
                </FlexItem>
                <Button
                  primary
                  content={'Create'}
                  onClick={createTikit}
                ></Button>
              </Flex>
            </Card.Footer>
          </>
        </Card>
      )}
    </>
  );
};

const Loading = () => {
  return <Loader label={'Creating a ticket...'} className={`absolute w-full`} style={{top: 'calc(50% - 36px)'}}/>;
};