import axios, { AxiosInstance } from 'axios';
import { Providers } from '@microsoft/mgt-element';
import { WipeData } from '../../user/UserForm';
import { ITeam } from '../interfaces/supportGroup.interface';
import * as microsoftTeams from '@microsoft/teams-js';

export class graphService  {
    api_token: string | null;
    client: AxiosInstance | null;
    api_url: string | undefined;

    constructor() {
      this.api_url = "https://graph.microsoft.com/v1.0";
      this.client = null;
    }

    init = (customHeaders?, customConfig?) => {
        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken)
            Providers.globalProvider.login();

        let headers = {
            "Accept": "application/json",
            "Authorization": `Bearer ${accessToken}`,
            "Access-Control-Allow-Origin":  "*"
        };
        headers = Object.assign(headers, customHeaders);
        let config = {
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers
        };
        config = Object.assign(config, customConfig);
        this.client = axios.create(config);

        this.client.interceptors.response.use(
            async response => {
              if (response.data && response.data['@odata.nextLink']) {
                const url = new URL(response.data['@odata.nextLink']);
                const req = {
                  ...response.config,
                  url: url.toString()
                };
                const rsp = await this.client.request(req);
                response.data.value = response.data.value.concat(rsp.data.value);
              }
              return response;
            },
            error => Promise.reject(error)
          );

        return this.client;
    };
    
    getManagedDevices = (usersId: string) => {
        return this.init().get(`/users/${usersId}/managedDevices`).then((response) => {
            return response;
        });
    }

    getOwnedDevices = (usersId: string) => {
        return this.init().get(`/users/${usersId}/ownedDevices`).then((response) => {
            return response;
        })
    }

    getMeetingDetails = (context: microsoftTeams.app.Context) => {
        return this.init().get(`/me/events`).then((response) => {
            var events = response.data.value;
            var currentEvent = events.filter((x) => {
                if(x.isOnlineMeeting){
                    const joinUrl = x.onlineMeeting.joinUrl || "";
                    const chatId = new URLSearchParams(context.chat.id).toString().toLowerCase();
                    return joinUrl.toLowerCase().indexOf(chatId) > -1;
                }
        
                return false;
            });
            return currentEvent[0];
        });
    }

    updateMeetingDetails = (ticketId: number, event: {}) => {

        if(!event["isOrganizer"]){
            return new Promise(resolve => {
                return false;
            });
        }
        
        var eventId = event["id"] || 0;
        var eventBody = event["body"]["content"] || "";
        var endOfBodyIndex = eventBody.indexOf("<body>");
        var insertTicketId = eventBody.slice(0, endOfBodyIndex) + `<div>---- Do not remove this line: [{​​​"TicketId":${ticketId} }] ----</div>` + eventBody.slice(endOfBodyIndex);
        var data = {
            body: {
                "content": insertTicketId,
                "contentType": "html"
            }
        }

        return this.init().patch(`/me/events/${eventId}`, data);
    }

    getUserList = () => {
        return this.init().get(`/users`);
    }

    getUser = (userId: string, params?: string) => {
        return this.init().get(`/users/${userId}${params ? (params) : ''} `);
    }

    getMembersOfGroup = (groupId: string) => {
        return this.init().get(`/groups/${groupId}/members`);
    }
    
    getUserApps = (userId: string) => {
        return this.init().get(`/users/${userId}/teamwork/installedApps?$expand=teamsApp,teamsAppDefinition&$filter=teamsApp/externalId in ('${window.__runtimeConfig.tikitClientId}','${window.__runtimeConfig.helpbotClientId}','${window.__runtimeConfig.tvaClientId}') or teamsApp/id in ('${window.__runtimeConfig.tikitClientId}','${window.__runtimeConfig.helpbotClientId}', '${window.__runtimeConfig.tvaClientId}')`);
    }

    getTeamApps = (teamId: string) => {
        return this.init().get(`/teams/${teamId}/installedApps?$expand=teamsApp,teamsAppDefinition&$filter=teamsApp/externalId eq '${window.__runtimeConfig.tikitClientId}' or teamsApp/id eq '${window.__runtimeConfig.tikitClientId}'`);
    }

    getCatalogApps = (userId: string) => {
        return this.init().get(`/appCatalogs/teamsApps?$expand=appDefinitions&$filter=externalId in ('${window.__runtimeConfig.tikitClientId}','${window.__runtimeConfig.helpbotClientId}','${window.__runtimeConfig.tvaClientId}') or id in ('${window.__runtimeConfig.tikitClientId}','${window.__runtimeConfig.helpbotClientId}', '${window.__runtimeConfig.tvaClientId}')`);
    }

    installUserApp = (userId: string, teamsAppId: string) => {
        var data = {
            "teamsApp@odata.bind":`https://graph.microsoft.com/v1.0/appCatalogs/teamsApps/${teamsAppId}`
        }
        return this.init().post(`/users/${userId}/teamwork/installedApps`, data);
    }

    uninstallUserApp = (userId: string, appInstallId: string) => {
        return this.init().delete(`/users/${userId}/teamwork/installedApps/${appInstallId}`);
    }

    updateUserApp = (userId: string, appInstallId: string) => {
        return this.init().post(`/users/${userId}/teamwork/installedApps/${appInstallId}/upgrade`);
    }

    getUserPhoto = (idOrUpn: string) => {
         return this.init({"Content-Type": "image/jpg"}, {responseType: "arraybuffer"}).get(`/users/${idOrUpn}/photo/$value`).then((image) => {
            const base64 = Buffer.from(image.data, 'binary').toString('base64');
            return `data:image/jpeg;base64, ${base64}`;
        }).catch(() => {
            return "";
        });
    }

    getUserPresence = (id: string) => {
        return this.init().get(`/users/${id}/presence`).then((result) => {
           return result.data;
        }).catch(() => {
            return { availability: "PresenceUnknown", activity: "Unknown"};
        });
    }

    getMePeople = () => {
        return this.init().get(`/me/people?$filter=personType/class eq 'Person' and personType/subclass eq 'OrganizationUser'`);
    }

    downloadFile = (groupId: string, itemId: string) => {
        return this.init().get(`/groups/${groupId}/drive/items/${itemId}`).then((response) => {
            var element = document.createElement('a');
            element.setAttribute('href', response.data["@microsoft.graph.downloadUrl"]);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
            return true;
        }).catch(() => { 
            return false;
        });
    }

    // managed device

    
    retireDevice = (managedDeviceId: string) => {
        return this.init().post(`/deviceManagement/managedDevices('${managedDeviceId}')/retire`).then((response) => {
            return response;
        });
    }

    wipeDevice = (managedDeviceId: string, data: WipeData) => {
        return this.init().post(`/deviceManagement/managedDevices('${managedDeviceId}')/wipe`, data).then((response) => {
            return response;
        });
    }
    
    remoteLockDevice = (managedDeviceId: string) => {
        return this.init().post(`/deviceManagement/managedDevices('${managedDeviceId}')/remoteLock`).then((response) => {
            return response;
        });
    }
    
    rebootNowDevice = (managedDeviceId: string) => {
        return this.init().post(`/deviceManagement/managedDevices('${managedDeviceId}')/rebootNow`).then((response) => {
            return response;
        });
    }

    me = (params?: string) => {
        return this.init().get(`/me/memberOf${params ? params : ''}`).then((response) => {
            return response;
        })
    }

    getEffectivePermissions = () => {
        return this.init().get(`/deviceManagement/getEffectivePermissions(scope='*')`).then((response) => {
            return response;
        })
    }

    installTeamApp = (teamId: string, teamsAppId: string) => {
        const data = {
            "teamsApp@odata.bind":`https://graph.microsoft.com/v1.0/appCatalogs/teamsApps/${teamsAppId}`
        }
        return this.init().post(`/teams/${teamId}/installedApps`, data);
    }

    getTeamData = (teamId) => {
        return this.init().get(`/teams/${teamId}`);
    }

    getJoinedTeams = (params?: string) => {
        return this.init().get(`/me/joinedTeams${params ? params : ''}`);
    }

    getGroups = (params?: string) => {
        return this.init().get(`/groups${params ? params : ''}`);
    }

    getTeamMembers = (teamId: string) => this.init().get(`/groups/${teamId}/members?$select=id,displayName,mail,userPrincipalName`);

    getChannelData = async (data: { teamId: string, channelId: string }[]) => {
        try {
            // Get distinct
            const requests = data.reduce((r, c) => {
                if (!r.find(x => x.id === c.channelId))
                    r.push({ url: `/teams/${c.teamId}/channels/${c.channelId}`, method: "GET", id: c.channelId });
                
                return r;
             }, []);
            const results = await this.init().post('/$batch', { requests: requests });
            
            return data.map(c => ({
                ...c,
                ...results.data.responses.find(x => x.id === c.channelId).body
            }));
        } catch {
            return data;
        }
    }
}