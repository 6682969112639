import {
  BoldIcon,
  BulletsIcon,
  Button,
  CodeSnippetIcon,
  Divider,
  Dropdown,
  Header,
  Input,
  ItalicIcon,
  LinkIcon,
  NumberListIcon,
  Popup,
} from "@fluentui/react-northstar";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Editor,
  Transforms,
  Range,
  Element as SlateElement,
  BaseRange,
  Descendant,
  Node as SlateNode,
} from "slate";
import { ReactEditor, useSlate, useSlateStatic } from "slate-react";
import { isValidURL } from "../../../utils/helper";
interface ToolbarProps {
  editor: any;
  noImage: boolean;
  value: Descendant[];
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      text={!isMarkActive(editor, format)}
      primary
      icon={icon}
      iconOnly
    ></Button>
  );
};

const BlockButton = ({ format, icon, editor, onClick }) => {
  return (
    <Button
      onClick={(event) => {
        event.preventDefault();
        onClick();
      }}
      text={!isBlockActive(editor, format)}
      primary
      icon={icon}
      iconOnly
    ></Button>
  );
};

const inputItems = ["Heading 1", "Heading 2", "Heading 3", "Default"];

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

const isBlockActive = (editor, format, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties: any;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    if (isActive) {
      newProperties = {
        type: "paragraph",
      };
    } else {
      newProperties = {
        type: isList ? "list-item" : format,
      };
    }
  }
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const createParagraphNode = (children = [{ text: "" }]) => ({
  type: "paragraph",
  children,
});

const SlateToolbar: React.FC<ToolbarProps> = (props: ToolbarProps) => {
  const { t } = useTranslation();
  const editor = useSlateStatic();
  const [lastSelection, setLastSelection] = useState<BaseRange>();
  const [linkPopup, setLinkPopup] = useState<boolean>(false);
  const [textToDisplayLink, setTextToDisplayLink] = useState<string>("");
  const [linkHref, setLinkHref] = useState<string>("");

  const fontStyles = ["h1", "h2", "h3", "paragraph"];
  const fontChange = (_e, p) => {
    toggleBlock(props.editor, fontStyles[p.highlightedIndex]);
    ReactEditor.focus(editor);
  };

  const handleLinkButtonChange = (e, data) => {
    setLastSelection(editor.selection);
    const selectedText = Editor.string(props.editor, props.editor.selection);
    setTextToDisplayLink(selectedText);
    setLinkPopup(!linkPopup);
  };

  const insertLink = (url, textToDisplay) => {
    if (lastSelection && Range.isCollapsed(lastSelection)) {
      const [start] = Range.edges(lastSelection);
      const link: any = {
        type: "link",
        url,
        children: [{ text: textToDisplay }],
      };
      Transforms.insertText(editor, " ", { at: start }); // Insert a space after the link
      Transforms.insertNodes(editor, link, { at: start });
      Transforms.insertText(editor, " ", { at: start }); // Insert a space after the link
      Transforms.collapse(editor, { edge: "end" });
    } else {
      const link: any = {
        type: "link",
        url,
        children: [{ text: textToDisplay }],
      };

      Transforms.wrapNodes(editor, link, { split: true, at: lastSelection });
      Transforms.collapse(editor, { edge: "end" });
    }
    setLinkPopup(false);

    ReactEditor.focus(editor);
  };

  const getSelectedFont = () => {
    return (
      inputItems.find((_item, index) =>
        isBlockActive(editor, fontStyles[index])
      ) ?? inputItems[3]
    );
  };

  const handleBlockButton = (format: "bulleted-list" | "numbered-list") => {
    toggleBlock(editor, format);
    ReactEditor.focus(editor);
  };

  const isPreviousListItemEmpty = (editor: Editor) => {
    if (editor.selection) {
      // Get the previous node relative to the current selection
      const previousNodeEntry = Editor.previous(editor, {
        match: (n) => SlateElement.isElement(n) && n.type === "list-item",
      });

      if (previousNodeEntry) {
        const [previousNode] = previousNodeEntry;

        // Check if the previous list item is empty by checking if its text content is empty
        const isEmpty = SlateNode.string(previousNode) === "";

        return isEmpty;
      }
    }

    return false;
  };

  const removePreviousListItem = (editor: Editor) => {
    if (editor.selection) {
      // Get the previous list-item node relative to the current selection
      const previousNodeEntry = Editor.previous(editor, {
        match: (n) => SlateElement.isElement(n) && n.type === "list-item",
      });

      if (previousNodeEntry) {
        const [, previousNodePath] = previousNodeEntry;

        // Remove the previous list-item node
        Transforms.removeNodes(editor, {
          at: previousNodePath,
        });
      }
    }
  };

  React.useEffect(() => {
    if (isPreviousListItemEmpty(editor)) {
      removePreviousListItem(editor);
      if (isBlockActive(editor, "bulleted-list")) {
        handleBlockButton("bulleted-list");
      } else if (isBlockActive(editor, "numbered-list")) {
        handleBlockButton("numbered-list");
      }
    }
  }, [props.value]);

  return (
    <div className="flex py-1 items-center transform scale-90 md:scale-100 -ml-4 md:-ml-0" style={{ height: "32px" }}>
      <MarkButton format="bold" icon={<BoldIcon />} />
      <MarkButton format="italic" icon={<ItalicIcon />} />
      <Divider vertical className="h-1/2-important" />
      <MarkButton format="code" icon={<CodeSnippetIcon />} />

      <BlockButton
        format={"bulleted-list"}
        icon={<BulletsIcon />}
        editor={props.editor}
        onClick={() => handleBlockButton("bulleted-list")}
      />
      <BlockButton
        format={"numbered-list"}
        icon={<NumberListIcon />}
        editor={props.editor}
        onClick={() => handleBlockButton("numbered-list")}
      />
      <div style={{ width: "150px" }}>
        <Dropdown
          items={inputItems}
          value={getSelectedFont()}
          checkable
          fluid
          onChange={fontChange}
        />
      </div>
      <Divider vertical className="h-1/2-important" />

      <Popup
        trigger={
          <Button
            icon={<LinkIcon />}
            text
            primary
            iconOnly
            onClick={handleLinkButtonChange}
          />
        }
        onOpenChange={() => {
          setLinkPopup(false);
        }}
        open={linkPopup}
        content={
          <div className="w-80">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (
                  linkHref !== "" &&
                  textToDisplayLink !== "" &&
                  isValidURL(linkHref)
                ) {
                  insertLink(linkHref, textToDisplayLink);
                  setTextToDisplayLink("");
                  setLinkHref("");
                }
              }}
            >
              <Header as="h3" className="font-bold mb-1">
                {t(
                  "ticket-details.ticket-conversation.editor-toolbar.insert-link"
                )}
              </Header>
              <Header as="h4">
                {t(
                  "ticket-details.ticket-conversation.editor-toolbar.text-to-display"
                )}
                *
              </Header>
              <Input
                fluid
                placeholder={t(
                  "ticket-details.ticket-conversation.editor-toolbar.text-to-display"
                )}
                value={textToDisplayLink}
                onChange={(_e, p) => {
                  setTextToDisplayLink(p.value);
                }}
                className="mb-2"
              />
              <Header as="h4">
                <div className="flex items-center justify-between">
                  <span>
                    {t(
                      "ticket-details.ticket-conversation.editor-toolbar.address"
                    )}
                    *
                  </span>
                  <span className="text-red-500">
                    {isValidURL(linkHref)
                      ? ""
                      : t(
                          "ticket-details.ticket-conversation.editor-toolbar.invalid-url"
                        )}
                  </span>
                </div>
              </Header>
              <Input
                fluid
                placeholder={t(
                  "ticket-details.ticket-conversation.editor-toolbar.address-placeholder"
                )}
                value={linkHref}
                onChange={(_e, p) => {
                  setLinkHref(p.value);
                }}
              />

              <div className="flex flex-row-reverse w-full mt-2">
                <div className="ml-2">
                  <Button
                    disabled={
                      linkHref === "" ||
                      textToDisplayLink === "" ||
                      !isValidURL(linkHref)
                    }
                    primary
                    type="submit"
                  >
                    {t(
                      "ticket-details.ticket-conversation.editor-toolbar.insert"
                    )}
                  </Button>
                </div>
                <>
                  <Button
                    className={"mr-2"}
                    type="button"
                    onClick={() => {
                      setLinkPopup(false);
                    }}
                  >
                    {t("ticket.ticket-list.configure-column.cancel")}
                  </Button>
                </>
              </div>
            </form>
          </div>
        }
      />
    </div>
  );
};

export default SlateToolbar;
