import React from 'react';
import { PlatformUser } from "../shared/interfaces/platformuser.interface";
import { ProviderConsumer, Text } from '@fluentui/react-northstar';
import { UserAvatar } from '../shared/components/UserAvatar';

interface Props{
    user: PlatformUser;
}
  
export const NameUserAvatar = ({user} : Props ) => {
    return (
        <ProviderConsumer
          render={globalTheme => (
            <>
              <UserAvatar
                {...{
                  idOrUpn: user.AadObjectId || user.UserName,
                  avatarProps: { name: user.FullName.toString(), size: 'smaller' }
                }}
              ></UserAvatar>
              <Text
                weight="semibold"
                style={{ marginLeft: '.4rem' }}
                className={`table-cell-color--${
                  globalTheme.siteVariables.theme == 'teamsTheme'
                    ? 'default'
                    : 'dark'
                }`}
              >
                {user.FullName}
              </Text>
            </>
          )}
        />
      )
}