import React, { useEffect } from 'react';
import Chart from 'chart.js';
import { SiteVariablesPrepared } from '@fluentui/react-northstar';
import { TeamsTheme } from '@fluentui/react-teams/lib/esm/themes';
import { IChartData } from '../ChartTypes';
import {
  tooltipTrigger,
  chartConfig,
  axesConfig,
  setTooltipColorScheme,
  usNumberFormat,
  createChartId
} from '../ChartUtils';
import { ChartContainer } from './ChartContainer';
import { buildPattern, chartBarDataPointPatterns } from '../ChartPatterns';
import { getText } from '../translations/';

export const PieChart = ({
  title,
  data,
  siteVariables,
  cutoutPercentage
}: {
  title: string;
  data: IChartData;
  siteVariables: SiteVariablesPrepared;
  cutoutPercentage?: number;
}) => {
  if (data && data.datasets && data.datasets[0].data.length > 6) {
    data.datasets[0].data = data.datasets[0].data.slice(0, 6);
  }
  const { colorScheme, theme, t } = siteVariables;
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const chartRef = React.useRef<Chart | undefined>();
  const chartId = React.useMemo(createChartId, []);
  const chartDataPointColors = React.useMemo(
    () => [
      colorScheme.brand.backgroundFocus2,
      colorScheme.brand.foreground3,
      colorScheme.brand.background,
      colorScheme.default.borderHover,
      colorScheme.default.foreground2,
      colorScheme.default.foreground
    ],
    [theme]
  );

  const pieChartPatterns = Array.from({ length: 6 }, (v, i) =>
    buildPattern({
      ...chartBarDataPointPatterns(colorScheme)[i],
      backgroundColor: colorScheme.default.background,
      patternColor: colorScheme.brand.background
    })
  );

  const pieChartHoverPatterns = Array.from({ length: 6 }, (v, i) =>
    buildPattern({
      ...chartBarDataPointPatterns(colorScheme)[i],
      backgroundColor: colorScheme.default.background,
      patternColor: colorScheme.default.borderHover
    })
  );

  const createDataPoints = (): Chart.ChartDataSets[] => {
    let dataPointConfig = {
      label: getText(t.locale, data.datasets[0].label),
      data: data.datasets[0].data,
      borderWidth: 2,
      borderColor: colorScheme.default.background,
      hoverBorderColor: colorScheme.default.background,
      backgroundColor: chartDataPointColors,
      hoverBackgroundColor: chartDataPointColors
    };
    if (theme === TeamsTheme.HighContrast) {
      dataPointConfig = {
        ...dataPointConfig,
        borderWidth: 3,
        hoverBorderColor: colorScheme.default.borderHover,
        borderColor: colorScheme.brand.background,
        backgroundColor: pieChartPatterns,
        hoverBackgroundColor: pieChartHoverPatterns
      };
    }
    return [dataPointConfig] as any;
  };

  useEffect(() => {
    let selectedIndex = -1;
    let selectedDataSet = 0;

    if (canvasRef.current === null || canvasRef.current === undefined) return;
    const ctx = canvasRef.current.getContext('2d');
    if (ctx === null || ctx === undefined) return;
    const config: any = chartConfig({ type: 'pie' });
    config.options.hover.mode = 'point';

    config.options.layout.padding.top = 32;
    config.options.layout.padding.left = -16;
    config.options.layout.padding.right = 32;
    config.options.layout.padding.bottom = 32;

    config.options.scales.xAxes[0].ticks.display = false;
    config.options.scales.xAxes[0].gridLines.display = false;

    config.options.scales.yAxes[0].ticks.display = false;
    config.options.scales.yAxes[0].gridLines.display = false;

    config.options.cutoutPercentage = cutoutPercentage ?? 0;

    config.options.tooltips.callbacks.label = (tooltipItem: any, d: any) =>
      getText(t.locale, d.labels[tooltipItem.index]);
    config.options.tooltips.callbacks.labelColor = (tooltipItem: any) => ({
      backgroundColor: chartDataPointColors[tooltipItem.index]
    });

    config.options.tooltips.callbacks.title = (tooltipItems: any) => {
      return `${parseFloat(
        (
          (Number(data.datasets[0].data[tooltipItems[0].index]) /
            (data.datasets[0].data as number[]).reduce((a, b) => a + b)) *
          100
        ).toFixed(2)
      )}% (${usNumberFormat(
        Number(data.datasets[0].data[tooltipItems[0].index])
      )})`;
    };

    chartRef.current = new Chart(ctx, {
      ...config,
      data: {
        labels: Array.isArray(data.labels)
          ? data.labels.map(label => getText(t.locale, label))
          : getText(t.locale, data.labels),
        datasets: []
      }
    });
    const chart: any = chartRef.current;

    function meta() {
      return chart.getDatasetMeta(selectedDataSet);
    }

    function removeFocusStyleOnClick() {
      canvasRef.current.style.boxShadow = 'none';
    }

    function removeDataPointsHoverStates() {
      meta().controller.removeHoverStyle(
        meta().data[selectedIndex > -1 ? selectedIndex : 0],
        0,
        selectedIndex
      );
    }

    function hoverDataPoint(pointID: number) {
      meta().controller.setHoverStyle(
        meta().data[pointID],
        selectedDataSet,
        pointID
      );
    }

    function showFocusedDataPoint() {
      hoverDataPoint(selectedIndex);
      tooltipTrigger({
        chart: chartRef.current,
        data,
        set: selectedDataSet,
        index: selectedIndex,
        siteVariables
      });
      document
        .getElementById(
          `${chartId}-tooltip-${selectedDataSet}-${selectedIndex}`
        )
        ?.focus();
    }

    const chartStyle = () => {
      if (siteVariables.theme === TeamsTheme.HighContrast) {
        chartRef.current.data.datasets.map((dataset: any, i: number) => {
          dataset.borderColor = siteVariables.colorScheme.default.border;
          dataset.borderWidth = 2;
          dataset.backgroundColor = buildPattern({
            ...chartBarDataPointPatterns(colorScheme)[i],
            backgroundColor: colorScheme.default.background,
            patternColor: colorScheme.brand.background
          });
        });
        chart.update();
      }
    };

    function resetChartStates() {
      removeDataPointsHoverStates();
      const activeElements = chart.tooltip._active;
      const requestedElem =
        chart.getDatasetMeta(selectedDataSet).data[selectedIndex];
      activeElements.find((v: any, i: number) => {
        if (requestedElem._index === v._index) {
          activeElements.splice(i, 1);
          return true;
        }
      });

      for (let i = 0; i < activeElements.length; i++) {
        if (requestedElem._index === activeElements[i]._index) {
          activeElements.splice(i, 1);
          break;
        }
      }
      chartStyle();
      chart.tooltip._active = activeElements;
      chart.tooltip.update(true);
      chart.draw();
    }

    function changeFocus(e: KeyboardEvent) {
      removeDataPointsHoverStates();
      switch (e.key) {
        case 'ArrowRight':
        case 'ArrowUp':
          e.preventDefault();
          selectedIndex = (selectedIndex + 1) % meta().data.length;
          break;
        case 'ArrowLeft':
        case 'ArrowDown':
          e.preventDefault();
          selectedIndex = (selectedIndex || meta().data.length) - 1;
          break;
      }

      showFocusedDataPoint();
    }

    canvasRef.current.addEventListener('click', removeFocusStyleOnClick);
    canvasRef.current.addEventListener('keydown', changeFocus);
    canvasRef.current.addEventListener('focusout', resetChartStates);
    return () => {
      if (canvasRef.current) {
        canvasRef.current.removeEventListener('click', removeFocusStyleOnClick);
        canvasRef.current.removeEventListener('keydown', changeFocus);
        canvasRef.current.removeEventListener('focusout', resetChartStates);
      }
      chartRef.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (!chartRef.current) return;
    if (!canvasRef.current) return;
    const ctx = canvasRef.current.getContext('2d');
    if (!ctx) return;
    chartRef.current.data.datasets = createDataPoints();

    setTooltipColorScheme({
      chart: chartRef.current,
      siteVariables,
      chartDataPointColors,
      patterns: chartBarDataPointPatterns,
      verticalDataAlignment: true
    });

    axesConfig({ chart: chartRef.current, ctx, colorScheme });

    chartRef.current.update();
  }, [theme]);

  function onLegendClick(datasetIndex: number) {
    if (!chartRef.current) return;

    chartRef.current.update();
  }

  return (
    <ChartContainer
      siteVariables={siteVariables}
      data={data}
      chartDataPointColors={chartDataPointColors}
      patterns={chartBarDataPointPatterns}
      onLegendClick={onLegendClick}
      verticalDataAlignment
    >
      <canvas
        id={chartId}
        ref={canvasRef}
        tabIndex={0}
        style={{
          userSelect: 'none'
        }}
        aria-label={title}
      >
        {data.datasets.map((set, setKey) =>
          (set.data as number[]).forEach((item: number, itemKey: number) => (
            <div key={itemKey} id={`${chartId}-tooltip-${setKey}-${itemKey}`}>
              <p>{item}</p>
            </div>
          ))
        )}
      </canvas>
    </ChartContainer>
  );
};
