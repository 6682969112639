import React, { useEffect, useState } from 'react';
import { graphService } from '../../shared/services/graph.service';
import * as microsoftTeams from "@microsoft/teams-js";
import { MeetingTicket } from './MeetingTicket';
import { Communication } from '@fluentui/react-teams';
import { LoadingScreen } from '../../shared/components/LoadingScreen';

export const MeetingDisplay: React.FC = () => {
  const graphapi = new graphService();
  const [ticketId, setTicketId] = useState(0);
  const [event, setEvent] = useState({ isOrganizer: false });
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState(
    "Signing you in... please wait.  Please ensure popups are not blocked if you're using the web app."
  );

  useEffect(() => {
    loadPage();
  }, []);

  function getText(html) {
    var divContainer = document.createElement('div');
    divContainer.innerHTML = html;
    var result = divContainer.textContent || divContainer.innerText || '';
    return result.replace(/\u200B/g, '');
  }

  const loadPage = () => {
    setLoadingMessage('Getting Data...');
    microsoftTeams.app.getContext().then((context: microsoftTeams.app.Context) => {
      graphapi.getMeetingDetails(context).then(data => {
        if (data) {
          setEvent(prevState => {
            return Object.assign({}, data);
          });
          var reg = /([^[]+(?=]))/g;
          var content = getText(data['body']['content']);
          const matches = content.matchAll(reg);
          for (const match of matches) {
            for (let item of match) {
              try {
                var json = JSON.parse(item);
                if (json.hasOwnProperty('TicketId'))
                  setTicketId(json['TicketId']);
              } catch { }
            }
          }
          setIsLoading(false);
        } else setIsLoading(false);
      });
    });
  };

  const config = {
    fields: {
      title: 'No Ticket Reference',
      desc: "Your organizer hasn't selected a ticket as a topic for this meeting yet."
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen message={loadingMessage} />
      ) : !event['isOrganizer'] && ticketId == 0 ? (
        <Communication {...config} />
      ) : (
        <MeetingTicket {...{ ticketId: ticketId, event: event }} />
      )}
    </>
  );
};
