import {
  Box,
  Flex,
  FlexItem,
  SiteVariablesPrepared,
  Skeleton,
  ProviderConsumer as FluentUIThemeConsumer,
  Divider,
  Card,
  List,
  Layout,
  Table
} from '@fluentui/react-northstar';
import { BoardTheme } from '@fluentui/react-teams/lib/esm/components/Board/BoardTheme';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { separatorStyles } from '../../../fluent-ui/components/Board/BoardLane';
import MgtCss from '../../mgt.module.css';
import { ThemeColorScheme } from '../common/TeamsTheme';
import TicketSVG from '../../../svg/ticket.svg';

const headerCss = {
  marginLeft: '10px',
  marginRight: '10px',
  alignItems: 'center',
  display: 'inline-flex',
  width: 120
};

export const SkeletonBoardHeader = () => {
  return (
    <Flex vAlign="center" style={{ padding: 4 }}>
      <Flex.Item grow>
        <Box>
          <>
            <Flex>
              <Skeleton animation="wave" styles={{ ...headerCss, marginLeft: '.75rem', marginRight: '.5rem' }}>
                <Skeleton.Line width="82px" height="30px"></Skeleton.Line>
              </Skeleton>
              <Divider vertical color="gray" style={{ height: '40px', marginTop: '4px' }} />
              <Skeleton animation="wave" styles={headerCss}>
                <Skeleton.Line width="100px" height="30px"></Skeleton.Line>
              </Skeleton>
            </Flex>
          </>
        </Box>
      </Flex.Item>
      <Flex.Item>
        <div className={`hidden md:block`}>
          <Flex>
            <Skeleton animation="wave" styles={headerCss}>
              <Skeleton.Line width="115px" height="30px"></Skeleton.Line>
            </Skeleton>
            <Divider
              vertical
              color="gray"
              style={{ height: '40px', marginTop: '4px' }}
            />
            <Skeleton animation="wave" styles={headerCss}>
              <Skeleton.Line width="115px" height="30px"></Skeleton.Line>
            </Skeleton>
            <Divider
              vertical
              color="gray"
              style={{ height: '40px', marginTop: '4px' }}
            />
            <Skeleton animation="wave" styles={headerCss}>
              <Flex gap="gap.medium" column>
                <Flex gap="gap.medium">
                  <Skeleton.Line width="50px" height="30px"></Skeleton.Line>
                  <Skeleton.Line width="50px" height="30px"></Skeleton.Line>
                  <Skeleton.Line width="50px" height="30px"></Skeleton.Line>
                </Flex>
              </Flex>
            </Skeleton>
          </Flex>
        </div>
      </Flex.Item>
    </Flex>
  );
};

export const SkeletonMyyWorkHeader = () => {
  return (
    <Flex vAlign="center" style={{ padding: 4 }}>
      <Flex.Item grow>
        <Box>
          <>
            <Flex>
              <Skeleton
                animation="wave"
                styles={{
                  ...headerCss,
                  marginLeft: '16px',
                  marginRight: '4px'
                }}
              >
                <Skeleton.Line width="82px" height="30px"></Skeleton.Line>
              </Skeleton>
              <Divider
                vertical
                color="gray"
                style={{ height: '40px', marginTop: '4px' }}
              />
              <Skeleton animation="wave" styles={headerCss}>
                <Skeleton.Line width="50px" height="30px"></Skeleton.Line>
              </Skeleton>
            </Flex>
          </>
        </Box>
      </Flex.Item>
    </Flex>
  );
};

export const SkeletonBoard = () => {
  const headers: any[] = [];
  const elements: any[] = [];
  for (let i = 1; i < 5; i++) {
    headers.push(
      <Skeleton animation="wave">
        <Skeleton.Line height="20px" width="100px"></Skeleton.Line>
      </Skeleton>
    );
    elements.push(
      <div
        style={{
          position: 'relative',
          margin: `0 1.25rem .5rem 1.25rem`,
          width: 'auto',
          height: 'auto',
          marginLeft: '12px',
          marginRight: '12px',
          borderRadius: '4px',
          overflow: 'hidden'
        }}
        className={MgtCss.skeletonBoxStyle}
      >
        <SkeletonBoardCard />
      </div>
    );
  }

  return (
    <FluentUIThemeConsumer
      render={globalTheme => {
        return (
          <BoardTheme globalTheme={globalTheme} style={{ height: '100%' }}>
            <Box
              variables={({ colorScheme }: SiteVariablesPrepared) => ({
                backgroundColor: colorScheme.default.background2
              })}
              className={`h-full`}
              style={{ minWidth: 1600, overflow: 'hidden', padding: "0 10px 0 10px", marginTop: "-5px"}}
            >
              <Container
                fluid={true}
                style={{
                  overflow: 'hidden',
                  height: '100%',
                  paddingLeft: 0,
                  paddingRight: 0,
                  marginLeft: -3
                }}
              >
                <Row style={{ marginLeft: '-10px' }}>
                  {headers.map((header: any, index: number) => (
                    <Col key={`header_${index}`} width={400}>
                      <div
                        style={{
                          fontWeight: 700,
                          fontSize: 'inherit',
                          paddingLeft: 5,
                          marginTop: 15,
                          marginBottom: 15
                        }}
                      >
                        {header}
                      </div>
                    </Col>
                  ))}
                </Row>
                <Row style={{ overflow: 'hidden', height: '100%' }}>
                  {elements.map((elem: any, index: number) => (
                    <Col
                      className={MgtCss.colMyWork}
                      key={`data_${index}`}
                      width={400}
                      style={{ padding: '0 30px' }}
                    >
                      <Box
                        variables={({
                          colorScheme
                        }: SiteVariablesPrepared) => ({
                          backgroundColor: 'colorScheme.default.background2',
                          separatorColor: colorScheme.default.border2
                        })}
                        styles={{
                          height: '100% !important',
                          flex: '0 0 auto',
                          width: '400px !important',
                          padding: '.60rem .60rem .75rem .60rem',
                          ...(index === elements.length - 1
                            ? {}
                            : separatorStyles),
                          ...ThemeColorScheme(globalTheme.siteVariables)
                        }}
                      >
                        {elem}
                      </Box>
                    </Col>
                  ))}
                </Row>
              </Container>
            </Box>
          </BoardTheme>
        );
      }}
    />
  );
};

export const SkeletonBoardCard = () => {
  return (
    <Box aria-roledescription="card with avatar, image and action buttons">
      <Row
        style={{
          paddingTop: '16px',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}
      >
        <Col style={{ marginBottom: 15 }}>
          {/* ticket id */}
          <Flex gap="gap.small" style={{ marginBottom: 15 }}>
            <Skeleton animation="wave">
              <Skeleton.Shape width="70px" height="20px" />
            </Skeleton>
            {/* status */}
            <div style={{ float: 'right' }}>
              <Skeleton animation="wave">
                <Skeleton.Line width="50px" height="20px" />
              </Skeleton>
            </div>
          </Flex>
          {/* requester info */}
          <Skeleton animation="wave">
            <Flex gap="gap.small">
              <Skeleton.Shape round width="24px" height="24px" />
              <div>
                <Skeleton.Line width="150px" height="16px" />
                <Skeleton.Line width="100px" height="12px" />
              </div>
            </Flex>
          </Skeleton>
        </Col>
        <Col style={{ marginBottom: 15 }}>
          {/* ticket title */}
          <Skeleton animation="wave">
            <Skeleton.Shape width="150" height="20px" />
          </Skeleton>
          <div style={{ marginBottom: 15 }}></div>
          <Flex>
            {/* assignee */}
            <Skeleton animation="wave">
              <Flex gap="gap.small">
                <Skeleton.Shape round width="24px" height="24px" />
                <div>
                  <Skeleton.Line width="110px" />
                </div>
              </Flex>
            </Skeleton>
            {/* due date */}
            <FlexItem push>
              <Skeleton animation="wave">
                <Skeleton.Line width="130px" />
              </Skeleton>
            </FlexItem>
          </Flex>
          <Flex>
            {/* priority */}
            <Skeleton animation="wave">
              <Skeleton.Line width="145px" />
            </Skeleton>
            {/* category */}
            <Flex.Item push>
              <Skeleton animation="wave">
                <Skeleton.Line width="130px" />
              </Skeleton>
            </Flex.Item>
          </Flex>
        </Col>
        {/* footer */}
        <Col style={{ marginBottom: 15 }}>
          <Skeleton animation="wave">
            <Skeleton.Line height="1px" width="100%" />
          </Skeleton>
          <div style={{ marginTop: 15 }}></div>
          <Skeleton animation="wave">
            <Skeleton.Shape width="200px" height="20px" />
          </Skeleton>
        </Col>
      </Row>
    </Box>
  );
};

export const SkeletonForm = () => {
  const styleCss = { width: '100%', marginBottom: '1em' };
  const SkeletonField = (props: any): JSX.Element => {
    const { width, height } = props;
    return (
      <div style={styleCss}>
        <Skeleton animation="wave">
          <Skeleton.Shape height="20px" width="100px" />
        </Skeleton>
        <Skeleton animation="wave">
          <Skeleton.Shape
            height={height}
            width={width === undefined ? '100%' : width}
          />
        </Skeleton>
      </div>
    );
  };

  return (
    <Card style={{ width: '100%' }}>
      <Card.Body>
        <SkeletonField height={'50px'} />
        <SkeletonField height={'30px'} />
        <SkeletonField height={'30px'} />
        <SkeletonField height={'30px'} />
        <SkeletonField height={'30px'} />
        <SkeletonField height={'30px'} />
        <SkeletonField height={'30px'} />
        <SkeletonField height={'30px'} width={'200px'} />
        <SkeletonField height={'30px'} width={'200px'} />
      </Card.Body>
      <Card.Footer>
        <div style={styleCss}>
          <div style={{ float: 'right' }}>
            <Skeleton animation="wave">
              <Skeleton.Shape width="100px" height="30px" />
            </Skeleton>
          </div>
        </div>
      </Card.Footer>
    </Card>
  );
};

export const SkeletonTable = () => {
  return (
    <Skeleton animation="wave" className={`p-5`}>
      <Flex gap="gap.medium" className={`mb-5`}>
        <Skeleton.Line width="8%" />
        <Skeleton.Line width="22%" />
        <Skeleton.Line width="14%" />
        <Skeleton.Line width="14%" />
        <Skeleton.Line width="14%" />
        <Skeleton.Line width="14%" />
        <Skeleton.Line width="14%" />
      </Flex>
      <Flex gap="gap.medium" className={`mb-5`}>
        <Skeleton.Line width="8%" />
        <Skeleton.Line width="22%" />
        <Skeleton.Line width="14%" />
        <Skeleton.Line width="14%" />
        <Skeleton.Line width="14%" />
        <Skeleton.Line width="14%" />
        <Skeleton.Line width="14%" />
      </Flex>
      <Flex gap="gap.medium" className={`mb-5`}>
        <Skeleton.Line width="8%" />
        <Skeleton.Line width="22%" />
        <Skeleton.Line width="14%" />
        <Skeleton.Line width="14%" />
        <Skeleton.Line width="14%" />
        <Skeleton.Line width="14%" />
        <Skeleton.Line width="14%" />
      </Flex>
      <Flex gap="gap.medium" className={`mb-5`}>
        <Skeleton.Line width="8%" />
        <Skeleton.Line width="22%" />
        <Skeleton.Line width="14%" />
        <Skeleton.Line width="14%" />
        <Skeleton.Line width="14%" />
        <Skeleton.Line width="14%" />
        <Skeleton.Line width="14%" />
      </Flex>
      <Flex gap="gap.medium" className={`mb-5`}>
        <Skeleton.Line width="8%" />
        <Skeleton.Line width="22%" />
        <Skeleton.Line width="14%" />
        <Skeleton.Line width="14%" />
        <Skeleton.Line width="14%" />
        <Skeleton.Line width="14%" />
        <Skeleton.Line width="14%" />
      </Flex>
    </Skeleton>
  );
};

export const SkeletonMobileList = () => {
  return (
    <div className={`md:hidden`}>
      <Layout
        className={`m-2`}
        renderMainArea={() => (
          <Card fluid elevated>
            <Flex vAlign="center">
              <Flex.Item>
                <div style={{ width: '36px' }}>
                  <TicketSVG width={24} className={`mr-3`} />
                </div>
              </Flex.Item>
              <Flex.Item grow>
                <Flex column>
                  <Skeleton animation="wave">
                    <Skeleton.Line width="100%" />
                    <Skeleton.Line width="100%" />
                  </Skeleton>
                </Flex>
              </Flex.Item>
            </Flex>
          </Card>
        )}
      />
      <Layout
        className={`m-2`}
        renderMainArea={() => (
          <Card fluid elevated>
            <Flex vAlign="center">
              <Flex.Item>
                <div style={{ width: '36px' }}>
                  <TicketSVG width={24} className={`mr-3`} />
                </div>
              </Flex.Item>
              <Flex.Item grow>
                <Flex column>
                  <Skeleton animation="wave">
                    <Skeleton.Line width="100%" />
                    <Skeleton.Line width="100%" />
                  </Skeleton>
                </Flex>
              </Flex.Item>
            </Flex>
          </Card>
        )}
      />
      <Layout
        className={`m-2`}
        renderMainArea={() => (
          <Card fluid elevated>
            <Flex vAlign="center">
              <Flex.Item>
                <div style={{ width: '36px' }}>
                  <TicketSVG width={24} className={`mr-3`} />
                </div>
              </Flex.Item>
              <Flex.Item grow>
                <Flex column>
                  <Skeleton animation="wave">
                    <Skeleton.Line width="100%" />
                    <Skeleton.Line width="100%" />
                  </Skeleton>
                </Flex>
              </Flex.Item>
            </Flex>
          </Card>
        )}
      />
    </div>
  );
};
