import React, { useEffect, useState } from "react";
import { TextArea, Header, Text, Grid, Input, Slider, Flex, ProviderConsumer, Button, Alert, Dropdown } from '@fluentui/react-northstar';
import { platformService } from '../shared/services/platform.service';
import { Communication, TToolbarInteraction } from '@fluentui/react-teams';
import { TeamsChannelPicker } from '@microsoft/mgt-react';
import { MgtTeamsChannelPicker } from '@microsoft/mgt-components';
import { Providers } from '@microsoft/mgt-element';
import { Toolbar } from '../shared/components/Toolbar';
import ConfigCSS from './config.module.css';
import { Breadcrumbs } from "../shared/components/BreadcrumbNavigation";
import { CheckLogin } from "../shared/components/CheckLogin";
import { Helmet } from 'react-helmet';
import { LoadingScreen } from "../shared/components/LoadingScreen";
import { MobileSettingsView } from "../automation/AutomationTable";
import { ThemeColorScheme } from '../shared/common/TeamsTheme';
import MgtCss from './../mgt.module.css';
import { getCachedFeature } from "../shared/cache/FeatureCache";
import { CONTACT_ADMIN, LICENSE_FEATURE, REFRESH_SESSION, SAVE, SOMETHING_WENT_WRONG, UNAUTHORIZED_ACCESS, ADMINISTRATORS, PRIMARY_COLOR_STYLE, toolbarOptions, CustomHelpCardConstants } from "../shared/utils/constants";
import { ITeam } from "../shared/interfaces/supportGroup.interface";
import { useTranslation } from "react-i18next";
import { appState  } from '../AppState';
import { Toggle } from "@fluentui/react";
import { HelpCardEditorComponent, rearrangeCustomButtons } from "../shared/components/Wysiwyg/HelpCardEditor";
import { clone } from "lodash";

export interface ITeamsConfig {
  TriageChannel: string,
  QnaThreshold: number,
  ServiceUrl: string,
  TenantId: string,
  TeamsTabUri: string,
  TeamsProfilePictureUri: string,
  TeamsConversationUri: string,
  ViceroyUri: string,
  TeamsChatUri: string,
  WelcomeMessage: string,
  HelpBotWelcomeMessage: string,
  MultiDepartmentPickerMessage: string,
  DefaultLocale: string,
  HasConsent: boolean,
  EnableMultiDepartmentPicker: boolean,
  EndUserHelpCardHeader: string,
  EndUserHelpCardBody: string
  ResponseCount: number,
  EnableMostConfidentResponse: boolean,
  MostConfidentResponsePercentage: number,
  MultipleResponseHeader: string,
  DefaultCreateMessage: string
}

export function MsTeamsConfig() {
  const appContextState = appState();
  const {t} = useTranslation();
  const api = new platformService();
  MgtTeamsChannelPicker.config.useTeamsBasedScopes = true;

  const [data, setData] = useState<ITeamsConfig>({
    WelcomeMessage: "",
    QnaThreshold: 50,
    TriageChannel: "",
    HelpBotWelcomeMessage: "",
    MultiDepartmentPickerMessage: "",
    ServiceUrl: "",
    TenantId: "",
    TeamsTabUri: "",
    TeamsChatUri: "",
    TeamsProfilePictureUri: "",
    TeamsConversationUri: "",
    ViceroyUri: "",
    DefaultLocale: "en-US",
    HasConsent: false,
    EnableMultiDepartmentPicker: false,
    EndUserHelpCardHeader: "",
    EndUserHelpCardBody: "",
    ResponseCount: 5,
    EnableMostConfidentResponse: true,
    MostConfidentResponsePercentage: 95,
    MultipleResponseHeader: "",
    DefaultCreateMessage: ""
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errCode, setErrCode] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [installState, setInstallState] = useState("");
  const [isChannelLoading, setIsChannelLoadingState] = useState(true);
  const [useTVA, setUseTVA] = useState(false);
  const [enabledMultiDepartment, setEnabledMultiDepartment] = useState(false);
  const [defaultTeamAadObjectId, setDefaultTeamAadObjectId] = useState("");
  const [isCheckedIn, setIsCheckedIn] = useState<boolean>(false);
  const [cultures, setCultures] = useState([]);
  const [enabledTicketDeflection, setEnabledTicketDeflection] = useState(false);
  const isAdmin = appContextState.userRoles.roles.includes(ADMINISTRATORS);
  const [originalData, setOriginalData] = useState<ITeamsConfig>(data);
  const [isDisable, setIsDisable] = useState(true);
  const [mentionList] = useState({
    separator: ' ',
    trigger: '{',
    suggestions: []
  });

  
  const fetchData = async () => {
    setLoadingMessage(t('bot-configuration.loading-your-teams-configuration'));

    const thisEnabledMultiDepartment = await getCachedFeature(LICENSE_FEATURE.MultiDepartment);
    setEnabledMultiDepartment(thisEnabledMultiDepartment);

    const thisEnabledTicketDeflection = await getCachedFeature(LICENSE_FEATURE.TicketDeflection);
    setEnabledTicketDeflection(thisEnabledTicketDeflection);

    const result = await Promise.allSettled([
      api.getSystemSetting('ServiceDesk.Core.Integrations.Teams.Configuration.TeamsBotConfiguration'),
      api.hasFeature('HelpBot'),
      api.getDefaultTeamUnrestricted(),
      api.getCultures()
    ]);

    if (result[2].status == "fulfilled") {
      const team: ITeam = JSON.parse(result[2].value.data.value);
      if (team && team.TeamsAadObjectId)
        setDefaultTeamAadObjectId(team.TeamsAadObjectId);
    }

    setFetchedData(result[0]);
    if (result[3].status === "fulfilled") {
      const available = result[3].value.data.value.map(l => {
        const label = new Intl.DisplayNames(l.Code, { type: 'language', languageDisplay: 'dialect' }).of(l.Code);
        return {
          header: label.charAt(0).toUpperCase() + label.slice(1),
          key: l.Code
        } 
      });
      available.push({ header: new Intl.DisplayNames('en', { type: 'language', languageDisplay: 'dialect' }).of('en'), key: 'en-US'  });
      available.sort((a, b) => a.header.localeCompare(b.header));
      setCultures(available);
    }

    if (window.location.search.indexOf('admin_consent') >= 0) {
      installHelpBot();
    }
  };

  const setFetchedData = (result: any) => {
    if (result.status === "rejected") {
      setIsError(true);
      setErrCode(result.reason.response ? result.reason.response.status : 500);
      setIsLoading(false);
    } else {
      setIsError(false);
      const tc: ITeamsConfig = {
        WelcomeMessage: result.value.data.WelcomeMessage || "",
        QnaThreshold: result.value.data.QnaThreshold || 50,
        TriageChannel: result.value.data.TriageChannel || undefined,
        HelpBotWelcomeMessage: result.value.data.HelpBotWelcomeMessage || "",
        MultiDepartmentPickerMessage: result.value.data.MultiDepartmentPickerMessage || t('bot-configuration.enduser-select-team-default'),
        ServiceUrl: result.value.data.ServiceUrl || "",
        TenantId: result.value.data.TenantId || "",
        TeamsChatUri: result.value.data.TeamsChatUri || "",
        TeamsProfilePictureUri: result.value.data.TeamsProfilePictureUri || "",
        TeamsConversationUri: result.value.data.TeamsConversationUri || "",
        TeamsTabUri: result.value.data.TeamsTabUri || "",
        ViceroyUri: result.value.data.ViceroyUri || "",
        DefaultLocale: result.value.data.DefaultLocale || "en-US",
        HasConsent: result.value.data.HasConsent || false,
        EnableMultiDepartmentPicker: result.value.data.EnableMultiDepartmentPicker || false,
        EndUserHelpCardHeader: result.value.data.EndUserHelpCardHeader || CustomHelpCardConstants.DefaultEndUserCardHeader,
        EndUserHelpCardBody: result.value.data.EndUserHelpCardBody || CustomHelpCardConstants.DefaultEndUserCardBody,
        ResponseCount: result.value.data.ResponseCount || 1,
        EnableMostConfidentResponse: result.value.data.EnableMostConfidentResponse || false,
        MostConfidentResponsePercentage: result.value.data.MostConfidentResponsePercentage || 95,
        MultipleResponseHeader: result.value.data.MultipleResponseHeader || "",
        DefaultCreateMessage: result.value.data.DefaultCreateMessage || ""
      };

      //No feature restriction for TVA
      setUseTVA(true);

      if (tc) {
        setData(tc);
        setOriginalData(tc);
      }

      setIsLoading(false);

    }
  }

  const loadPage = () => {
    if(!isAdmin){
      setIsError(true);
      setErrCode(401);
      setIsLoading(false);
    }

    setIsCheckedIn(true);
  }

  useEffect(() => {

    setLoadingMessage(t('bot-configuration.loading-your-teams-configuration'));
    if (isCheckedIn)
      fetchData();
  }, [isCheckedIn]);

  useEffect(() => {
    let count = 0;
    const setPicker = async () => {
      const picker: any = document.querySelector('mgt-teams-channel-picker');
      await picker.selectChannelById(data.TriageChannel);
      if (picker.isLoadingState)
        setTimeout(setPicker, 500);
      else if ((picker.selectedItem && picker.selectedItem.channel.id === data.TriageChannel) || (data.TriageChannel || '') === '')
        setIsChannelLoadingState(false);

      if (picker?.shadowRoot && picker?.items) {
        const pickerSelector = picker.shadowRoot;
        const pickerList = pickerSelector.querySelectorAll('.list-team');
        const indexes = [];
        picker?.items.forEach((data, index) => {
          if (defaultTeamAadObjectId != data.item.id)
            pickerList[index].style.display = "none";
        });
      }

      count++;
    }

    if (isCheckedIn && !isLoading && !isError)
      setPicker();
  }, [isCheckedIn, isLoading])

  const maxQnAThreshold = 100;
  const maxQnaResponseCount = 10;
  const maxQnaConfidentPercentage = 100;

  const handleTikitWelcome = event => setData({ ...data, WelcomeMessage: event.target.value });
  const handleHelpbotWelcome = event => setData({ ...data, HelpBotWelcomeMessage: event.target.value });
  const handleTeamMessage = event => setData({ ...data, MultiDepartmentPickerMessage: event.target.value });
  const handleQnaThreshold = event => setData({ ...data, QnaThreshold: (event.target.value > maxQnAThreshold) ? maxQnAThreshold : event.target.value });
  const handleResponseCount = event => setData({ ...data, ResponseCount: (event.target.value > maxQnaResponseCount) ? maxQnaResponseCount : event.target.value });
  const handleMostConfidentPercentage = event => setData({ ...data, MostConfidentResponsePercentage: (event.target.value > maxQnaConfidentPercentage) ? maxQnaConfidentPercentage : event.target.value });
  const handleResponseMessage = event => setData({ ...data, MultipleResponseHeader: event.target.value });
  const handleCreateMessage = event => setData({ ...data, DefaultCreateMessage: event.target.value });
  const handleTriage = event => {
    if (event.detail.length > 0 && event.detail[0].channel.id !== data.TriageChannel)
      setData({ ...data, TriageChannel: event.detail[0].channel.id });
    else if (!isChannelLoading)
      setData({ ...data, TriageChannel: undefined })
  };
  const handleLocale = (e, p) => setData({ ...data, DefaultLocale: p.value.key });
  const handleEndUserHelpCardHeader = event => setData({ ...data, EndUserHelpCardHeader: event.target.value });
  const handleEndUserHelpCardBody = editorData => setData({ ...data, EndUserHelpCardBody: editorData });

  const saveData = () => {
    setLoadingMessage(t('bot-configuration.updating-your-microsoft-teams-settings'));
    setIsLoading(true);
    setIsChannelLoadingState(true);

    if(data.EndUserHelpCardBody.length > 0){
      let outputHTML = rearrangeCustomButtons(data.EndUserHelpCardBody);
      let main = document.createElement('div');
      main.innerHTML = outputHTML;
      data.EndUserHelpCardBody =  main.innerText.trim().length > 0 ? outputHTML : "";
      setData({ ...data});
      setOriginalData({ ...data});
    }
    api.setSystemSetting('ServiceDesk.Core.Integrations.Teams.Configuration.TeamsBotConfiguration', data).finally(() => {
      setIsLoading(false);
    });
  };
  const installHelpBot = () => {
    setLoadingMessage(t('bot-configuration.installing-tva'));
    setIsLoading(true);

    const redirectUri = `${window.location.origin}/settings/botconfiguration`;
    const provider = Providers.globalProvider;
    provider.getAccessTokenForScopes('AppCatalog.Submit').then(token => {
      api.installHelpBot({ RedirectUri: redirectUri, AccessToken: token, IsTVA: true }).then(rsp => {
        const result = rsp.data.value.split('-');
        onInstallSuccess(result[0]);
      }).catch(() => {
        setInstallState("Error");
      }).finally(() => {
        setIsLoading(false);
      });
    }).catch(() => {
      installHelpBotWhenException(redirectUri);
    })
  };

  const installHelpBotWhenException = (redirectUri: string) => {
    api.installHelpBot({ RedirectUri: redirectUri, IsTVA: true }).then(rsp => {
      if (rsp.data.value.startsWith('https://login.microsoftonline.com/'))
        window.location.href = rsp.data.value;
      else {
        const result = rsp.data.value.split('-');
        onInstallSuccess(result[0]);
      }
    }).catch(() => {
      setInstallState("Error");
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const onInstallSuccess = (result: any) => {
    if (result === "Success") setInstallState(data[1] === "False" ? "Success" : "Review");
    else if (result === "Error") setInstallState("Error");
    else setInstallState("Denied");
  }

  const errConfig = {
    fields: {
      title: (errCode == 401 || errCode == 403) ? UNAUTHORIZED_ACCESS : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  };

  const navs: Breadcrumbs = {
    breadcrumbs: [
      {
        title: t('common.settings'),
        link: '/settings'
      },
      {
        title: t('bot-configuration.bot-configuration'),
        link: ""
      }
    ]
  };

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const onToolbarInteraction = (interaction: TToolbarInteraction) => {
    if (interaction.action === "toggle-menu") {
      setMenuOpen(!menuOpen);
    }
  }

  useEffect(() => {
    let oData = clone(originalData);
    oData.EndUserHelpCardBody = oData.EndUserHelpCardBody.replaceAll("data-mention=\"\"", "data-mention");
    let nData = clone(data);
    nData.EndUserHelpCardBody = nData.EndUserHelpCardBody.replace(/\r?\n|\r/g, "").replaceAll("data-mention=\"\"", "data-mention");
    const isDirty = JSON.stringify(oData) !== JSON.stringify(nData) ;
    const isMissingRequired = (!data.TriageChannel || !data.EndUserHelpCardHeader || !data.EndUserHelpCardBody);
    setIsDisable((!isDirty || isMissingRequired));
  }, [data, originalData]);

  return (
    <CheckLogin onSignedIn={loadPage}>
      <Helmet>
        <title>{t('bot-configuration.title-page')}</title>
      </Helmet>
      <div className="hidden md:block">
        {
          (isLoading) ? <LoadingScreen message={loadingMessage} /> :
            (isError) ? (
              <ProviderConsumer render={(globalTheme) => (
                <>
                  <Toolbar globalTheme={globalTheme} title="Settings > Bot Configuration" hideSave={true} breadcrumbs={navs} />
                  <Flex className={ConfigCSS.configBody}>
                    <Flex.Item grow>
                      <>
                        <Communication {...errConfig} />
                        {errCode === 401 && (<Button content={REFRESH_SESSION} primary onClick={e => { Providers.globalProvider.login(); }} />)}
                      </>
                    </Flex.Item>
                  </Flex>
                </>)} />) :
              (
                <ProviderConsumer render={(globalTheme) => (
                  <div style={{
                    display: 'block',
                    ...ThemeColorScheme(globalTheme.siteVariables)
                  }}>
                    <Toolbar onInteraction={onToolbarInteraction} globalTheme={globalTheme} title="Settings > Bot Configuration" saveText={SAVE} saveCommand={saveData} breadcrumbs={navs} disableSave={isDisable} />
                    <Flex className={ConfigCSS.configBody}>
                      <Flex.Item grow styles={{ height: "calc(100vh - 50px)" }}>
                        <Grid columns="auto"
                          styles={{
                            padding: '20px',
                            paddingTop: 0,
                            justifyContent: 'flex-start',
                            rowGap: '10px',
                            overflow: 'scroll'
                          }}>

                          <Header content={t('bot-configuration.tikit')} />
                          
                          <Text content={t('bot-configuration.tikit-welcome-message')}  />
                          <TextArea value={data.WelcomeMessage} resize="both" inverted placeholder={t('bot-configuration.welcome-message')} variables={{ height: "150px" }} onChange={handleTikitWelcome} className={'minify-when-mobile--100'} />
                          <em style={{ paddingBottom: '20px' }} className={'minify-when-mobile--100'}>{t('bot-configuration.plain-text-supported')} <a href="https://docs.microsoft.com/en-us/adaptive-cards/authoring-cards/text-features" target="_blank" rel="noopener noreferrer" style={{ color: globalTheme.siteVariables.colorScheme.brand.foregroundActive }}>{t('bot-configuration.available-options')}</a></em>
                          
                          <div>
                            <Text content={t('bot-configuration.default-triage-channel')} className={'minify-w hen-mobile--100'} />
                            <span style={{ color: 'red', marginLeft: 2}}>*</span>
                          </div>
                          
                          {isChannelLoading && (<Text content={data.TriageChannel} />)}
                          <div className={(enabledMultiDepartment || defaultTeamAadObjectId.length == 0) ? MgtCss.disabledContainer : ""}>
                            {(<TeamsChannelPicker selectionChanged={handleTriage} defaultValue={data.TriageChannel} className={`no-border${(isChannelLoading ? " hidden" : "")} minify-when-mobile--100`} />)}
                          </div>
                          
                          {!enabledMultiDepartment && defaultTeamAadObjectId.length == 0 && (<em style={{ paddingBottom: '20px' }} className={'minify-when-mobile--100'}>{t('bot-configuration.install-tikit-to-one-of-your-teams')}</em>)}
                          {enabledMultiDepartment && (<em style={{ paddingBottom: '20px' }} className={'minify-when-mobile--100'}>{t('bot-configuration.the-default-triage-channel')} <a href={`${window.location.origin}/settings/teams`} target="_blank" rel="noopener noreferrer" style={{ color: globalTheme.siteVariables.colorScheme.brand.foregroundActive }}>{t('bot-configuration.here')}</a></em>)}
                          <Text content={t('bot-configuration.default-locale')} />
                          <Dropdown items={cultures} onChange={handleLocale} value={cultures.find(c => c.key === data.DefaultLocale)?.header} inverted fluid style={{ background: globalTheme.siteVariables.colorScheme.default.background }} />
                          <em style={{ paddingBottom: '20px' }}>{t('bot-configuration.default-locale-note')}</em>
                          
                          {useTVA && (<hr/>)}
                          {useTVA && (<Header content={t('bot-configuration.virtual-agent')} />)}
                          {useTVA && installState === "Success" && (<Alert success visible content={<Text>{t('bot-configuration.tva-has-successfully-been-installed')} <a href="https://admin.teams.microsoft.com/" target="_blank">{t('bot-configuration.microsoft-teams-admin-center')}</a></Text>} />)}
                          {useTVA && installState === "Review" && (<Alert warning visible content={<Text>{t('bot-configuration.tva-has-been-installed')} <a href="https://admin.teams.microsoft.com/policies/manage-apps/" target="_blank">{t('bot-configuration.microsoft-teams-admin-center')}</a> {t('bot-configuration.to-do-so')}</Text>} />)}
                          {useTVA && installState === "Denied" && (<Alert content={t('bot-configuration.you-do-not-have-appropriate-access-rights')} danger visible />)}
                          {useTVA && installState === "Error" && (<Alert content={t('bot-configuration.an-error-occurred')} danger visible />)}
                          {useTVA && (<Text content={t('bot-configuration.virtual-agent-welcome-message')} />)}
                          {useTVA && (<TextArea value={data.HelpBotWelcomeMessage} resize="both" inverted placeholder={t('bot-configuration.welcome-message')} variables={{ height: "150px" }} onChange={handleHelpbotWelcome} className={`minify-when-mobile--100`} />)}
                          {useTVA && (<em style={{ paddingBottom: '30px' }}>{t('bot-configuration.plain-text-supported')} <a href="https://docs.microsoft.com/en-us/adaptive-cards/authoring-cards/text-features" target="_blank" rel="noopener noreferrer" style={{ color: globalTheme.siteVariables.colorScheme.brand.foregroundActive }}>{t('bot-configuration.available-options')}</a></em>)}
                          {useTVA && (
                            <>
                            <Text content={t('bot-configuration.help-card.section_label')} />
                            <div>
                              <Text content={t('bot-configuration.help-card.section_card_header')} className={'minify-when-mobile--100'} />
                              <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                            </div>
                            <Input value={data.EndUserHelpCardHeader} inverted fluid onChange={handleEndUserHelpCardHeader} className={`minify-when-mobile--100`} />
                          
                          <div>
                            <Text content={t('bot-configuration.help-card.section_card_body')} className={'minify-when-mobile--100'} />
                            <span style={{ color: 'red', marginLeft: 2 }}>*</span>
                          </div>
                          <HelpCardEditorComponent 
                            mention={mentionList}
                            toolbarOptions={toolbarOptions.options}
                            toolbarInline={toolbarOptions.inlineOption}
                            htmlString={data.EndUserHelpCardBody}
                            onChange={handleEndUserHelpCardBody}
                            wrapperClassName="htmleditor-wrapper helpcard-editor-wrapper"
                            editorClassName="editor--with-mention helpcard-editor"
                            spellCheck
                          />
                          <em style={{ paddingBottom: '30px' }}>{t('bot-configuration.help-card.actionSetHint')}</em>
                          </>
                          )}
                          {enabledMultiDepartment && (
                            <>
                              <Text style={{ paddingBottom: '20px' }} content={t('bot-configuration.multi-department-header')}  size="large" weight="bold"/>
                              <div style={{ paddingBottom: '20px' }}>
                              <Flex>
                                <Toggle
                                  {...(data.EnableMultiDepartmentPicker && {
                                    defaultChecked: true,
                                    style: {
                                      background: PRIMARY_COLOR_STYLE.color,
                                      opacity: 1
                                    }
                                  })}
                                  onChange={(_ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    setData({ ...data, EnableMultiDepartmentPicker: checked })
                                  }}
                                />
                                <Text style={{ paddingLeft: '5px' }} content={t('bot-configuration.enduser-enable-multideptpicker') } className="mr-2"/>
                              </Flex>
                              <em dangerouslySetInnerHTML={{ __html: t('bot-configuration.enduser-enable-multideptpicker-subcopy', { color: globalTheme.siteVariables.colorScheme.brand.foregroundActive })}} />
                              </div>
                              
                              {data.EnableMultiDepartmentPicker && (
                              <div style={{ paddingBottom: '20px' }}>
                                <Text content={t('bot-configuration.enduser-select-team-message')} />
                                <Input value={data.MultiDepartmentPickerMessage} inverted fluid placeholder={t('bot-configuration.enduser-select-team-placeholder')} onChange={handleTeamMessage} className={`minify-when-mobile--100 mt-2.5`} /> 
                              </div>
                              )}
                            </>
                          )}

                          <Text style={{ paddingBottom: '20px' }} content={t('bot-configuration.kb-responses-header')} size="large" weight="bold"/>
                          
                          {enabledTicketDeflection && <Text content={t('bot-configuration.ticket-deflection-score-threshold')}  />}
                          {enabledTicketDeflection && <em>{t('bot-configuration.kb-threshold-subtext')} <a href='https://help.tikit.ai/articles/bot-configuration/#How_to_update_ticket_deflection_score_threshold' target="_blank" style={{ color: globalTheme.siteVariables.colorScheme.brand.foregroundActive }}>{t('bot-configuration.knowledge-article')}.</a></em>}
                          <Flex style={{ paddingBottom: '10px' }} inline hAlign="start" vAlign="center" gap="gap.smaller"  className={`minify-when-mobile--100`}>
                            {enabledTicketDeflection && <div style={{width:'500px'}}><Slider fluid value={data.QnaThreshold} min="0" max="100" step="1" onChange={handleQnaThreshold} className={`minify-when-mobile--100`} /></div>}
                            {enabledTicketDeflection && <Input inverted type="number" min="0" max="100" step="1" value={data.QnaThreshold} onChange={handleQnaThreshold} />} 
                            {enabledTicketDeflection && <p> % </p>} 
                          </Flex>
                          
                          {enabledTicketDeflection && <Text content={t('bot-configuration.kb-response-to-return')} />}
                          {enabledTicketDeflection && <em>{t('bot-configuration.kb-response-subtext')} </em>}
                          <Flex style={{ paddingBottom: '10px' }} inline hAlign="start" vAlign="center" gap="gap.smaller" className={`minify-when-mobile--100`}>
                            {enabledTicketDeflection && <div style={{width:'500px'}}><Slider fluid value={data.ResponseCount} min="1" max="10" step="1" onChange={handleResponseCount} className={`minify-when-mobile--100`} /></div>}
                            {enabledTicketDeflection && <Input inverted type="number" min="1" max="10" step="1" value={data.ResponseCount} onChange={handleResponseCount} />}
                          </Flex>
                          <div>
                          {enabledTicketDeflection && <Flex>
                            <Toggle
                                  {...(data.EnableMostConfidentResponse && {
                                    defaultChecked: true,
                                    style: {
                                      background: PRIMARY_COLOR_STYLE.color,
                                      opacity: 1
                                    }
                                  })}
                                  onChange={(_ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                                    setData({ ...data, EnableMostConfidentResponse: checked })
                                  }}/>
                                  <Text style={{ paddingLeft: '5px' }} content={t('bot-configuration.most-confident-message')} className="mr-2"  />
                          </Flex>}
                          </div>
                          {enabledTicketDeflection && <Flex inline hAlign="start" vAlign="center" gap="gap.smaller" className={`minify-when-mobile--100`}>
                            {data.EnableMostConfidentResponse && <div style={{width:'500px'}}><Slider fluid value={data.MostConfidentResponsePercentage} min="1" max="100" step="1" onChange={handleMostConfidentPercentage} className={`minify-when-mobile--100`} /></div>}
                            {data.EnableMostConfidentResponse &&  <Input inverted type="number" min="1" max="100" step="1" value={data.MostConfidentResponsePercentage} onChange={handleMostConfidentPercentage} />}
                            {data.EnableMostConfidentResponse &&  <p>%</p> } 
                          </Flex>}

                          {enabledTicketDeflection && <Text content={t('bot-configuration.multiple-response-message')}  />}
                          {enabledTicketDeflection && <TextArea value={data.MultipleResponseHeader} resize="both" inverted placeholder={t('bot-configuration.multiple-response-message')} variables={{ height: "75px" }} onChange={handleResponseMessage} className={'minify-when-mobile--100'} />}
                          {enabledTicketDeflection && <em style={{ paddingBottom: '10px' }} className={'minify-when-mobile--100'}>{t('bot-configuration.plain-text-supported')} <a href="https://docs.microsoft.com/en-us/adaptive-cards/authoring-cards/text-features" target="_blank" rel="noopener noreferrer" style={{ color: globalTheme.siteVariables.colorScheme.brand.foregroundActive }}>{t('bot-configuration.available-options')}</a></em>}
                          {enabledTicketDeflection && <Text content={t('bot-configuration.create-ticket-message')}   />}
                          {enabledTicketDeflection && <TextArea  value={data.DefaultCreateMessage} resize="both" inverted placeholder={t('bot-configuration.create-ticket-message')} variables={{ height: "75px" }} onChange={handleCreateMessage} className={'minify-when-mobile--100'} />}
                          {enabledTicketDeflection && <em style={{ paddingBottom: '50px' }} className={'minify-when-mobile--100'}>{t('bot-configuration.plain-text-supported')} <a href="https://docs.microsoft.com/en-us/adaptive-cards/authoring-cards/text-features" target="_blank" rel="noopener noreferrer" style={{ color: globalTheme.siteVariables.colorScheme.brand.foregroundActive }}>{t('bot-configuration.available-options')}</a></em>}
                     
                        </Grid>
                      </Flex.Item>
                    </Flex>
                  </div>)} />
              )
        }
      </div>
      <MobileSettingsView />
    </CheckLogin>
  )
}