
import React, { useEffect, useState } from 'react';
import { appState, useSetState } from "../../../AppState";
import { Flex, Loader, ProviderConsumer as FluentUIThemeConsumer, } from "@fluentui/react-northstar";
import { platformService } from '../../services/platform.service';
import { EmptyData } from '../EmptyData';

import EmptySVG from '../../../../svg/empty.svg';
import { TicketLifecyclePhaseContainer } from './TicketLifecyclePhaseContainer';
import { useTranslation } from 'react-i18next';
import { updateApprovalList } from '../../../tikit/ticketHelper';
import { ADMINISTRATORS, ANALYSTS, LIFECYCLE_STATUS_GUID, STATUS_GUID, TicketApprovalState } from '../../utils/constants';
import { TicketApprovalStateString } from '../../../lifecycle/utils/constants';

interface TicketLifecycleSectionProps {
  ticketData: any;
  ticketLifecycle: TicketLifecycle;
  data: any;
  onReloadPhases: () => void;
}

export const TicketLifecycleSection = (Props: TicketLifecycleSectionProps) => {
    const currentState = appState();
    const setAppState = useSetState();
    const api = new platformService();
    const {ticketData, ticketLifecycle, data, onReloadPhases} = Props;
    const [loading, setLoading] = useState(false);
    const [currentOpenedPhase, setCurrentOpenedPhase] = useState(false);
    const [phases, setPhases] = useState<TicketLifecyclePhase[]>([]);

    const { t } = useTranslation();
    
    const refreshApprovalsList = (approval: Approval, approverId: number, status: TicketApprovalState, UpdatedStatus: TicketApprovalState) => {
      const oldApprovals = phases.find(o => o.IsCurrent).Approvals;
      const updatedApprovals = updateApprovalList(oldApprovals, approverId, approval.Id, status, UpdatedStatus);
      const hasPendingApprovals: boolean = updatedApprovals.some((item: any) => item.Approval.ApprovalState == TicketApprovalStateString.Pending);

      setAppState(prevState => ({
        ...prevState,
        lifecycleApprovals: updatedApprovals,
        hasPendingApprovals: hasPendingApprovals
      }));
      setPhases(pre => {
        pre.forEach(phase => { if (phase.IsCurrent) phase.Approvals = [...updatedApprovals] });
        return pre;
      });
    }

    const loadData = async(isReload: boolean = false) => {
      try {
        const ticketLifecyclePhases = await api.getTicketLifecyclePhases(`?$filter=TicketLifecycleId eq ${ticketLifecycle.Id}`);
        const results = await Promise.all([
          api.getTicketApprovals(ticketData.Id),
          api.getTicketTasks(`?$filter=TicketId eq ${ticketData.Id}&$expand=Status,Assignee,Team,SupportGroup,TaskCollaborators`)
        ]);
        
        const allApprovals: TicketApprovals[] = results[0];
        allApprovals.forEach(approval => {
          approval.Approval.TicketLifecyclePhase = ticketLifecyclePhases.find(x => x.Id == approval.Approval.TicketLifecyclePhaseId);
          return approval;
        });

        const allTasks:TicketTask[] = results[1];
        allTasks.forEach(task => {
          task.TicketLifecyclePhase = ticketLifecyclePhases.find(x => x.Id == task.TicketLifecyclePhaseId);
          return task;
        });

        const arrangedPhases = ticketLifecyclePhases.sort((a, b) => a.Order - b.Order);
        arrangedPhases.forEach((phase:TicketLifecyclePhase) => {
          phase.Tasks = allTasks.filter(x => x.TicketLifecyclePhaseId == phase.Id && !x.IsPowerAutomateTask);
          phase.Approvals = allApprovals.filter(x => x.Approval.TicketLifecyclePhaseId == phase.Id);
          phase.PowerAutomateTasks = allTasks.filter(x => x.TicketLifecyclePhaseId == phase.Id && x.IsPowerAutomateTask);
        });
        
        setPhases(arrangedPhases);

        if (isReload) {
          const currentPhaseOrder = arrangedPhases?.find((o: any) => o.IsCurrent)?.Order;
          setAppState(prevState => ({ ...prevState, lifecyclePhaseMenu: (currentPhaseOrder ?? prevState.lifecyclePhaseMenu) }));
          onReloadPhases();
        }

      } catch (e) {
        console.error(e);
      }

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }

    const reloadPhases = async () => {
      await loadData(true);
    }

    const isAddAllowed = (phaseData : any) =>{
        const hasAccess = currentState.userRoles.roles.includes(ADMINISTRATORS) || currentState.userRoles.roles.includes(ANALYSTS);
        const isPhaseEditable = phaseData.LastCompletionDate == null;
        const isTicketClosed = ticketData.Status.Guid == STATUS_GUID.CLOSED;
        
        return hasAccess && isPhaseEditable && !isTicketClosed;
    }

    useEffect(() => {
      if (ticketLifecycle.Id){
        setLoading(true);
        loadData();
      }
    }, [ticketLifecycle.Id]);

    useEffect(() => {
      if (currentState.lifecyclePhaseMenu != undefined){
        setCurrentOpenedPhase(!currentOpenedPhase);
      }
    }, [currentState.lifecyclePhaseMenu]);

    return (
    <FluentUIThemeConsumer
    render={globalTheme => (
      <>
        {(loading) && (
          <Flex.Item>
            <div className={`pt-5 mt-5`}>
              <Loader />
            </div>
          </Flex.Item>
        )}
        {!loading && phases.length == 0 && (
          <Flex.Item>
            <EmptyData
              headerText={t("ticket-details.ticket-conversation.empty-result")}
              subheaderText=""
              SVGIcon={<EmptySVG width={100} height={100} className="mb-2" />}
            />
          </Flex.Item>
        )}
        <Flex.Item styles={{ flexGrow: 1, height: "50%" }}>
          <div className={`overflow-auto h-sm:m-h-1/4 h-md:m-h-2/5`}>
          {!loading && (
            <div key={`openPhase-${currentOpenedPhase}`}>
              {phases.map((phaseData, index) => {
                return (
                <div key={phaseData.Id} id={`phase-container-${phaseData.Order}`} className={index == (phases.length - 1) ? 'mb-7' : ''}>
                <TicketLifecyclePhaseContainer 
                  statuses={currentState.taskStatus}
                  analysts={currentState.usersInRole}
                  lifecycleStatus={ticketLifecycle.Status}
                  phaseData={phaseData}
                  ticketData={ticketData}
                  phaseIndex={index}
                  activePhaseIndex={phases.find(x => x.IsCurrent)?.Order ?? 0}
                  openPhaseDefault={currentState.lifecycleMenuOpen ? (currentState.lifecyclePhaseMenu == phaseData.Order) : false}
                  globalTheme={globalTheme}
                  refreshApprovalsList={refreshApprovalsList}
                  reloadPhases={reloadPhases}
                  enableTaskEdit = {(ticketLifecycle.Status.Guid==LIFECYCLE_STATUS_GUID.FAILED || ticketLifecycle.Status.Guid==LIFECYCLE_STATUS_GUID.COMPLETED)?false:true}
                  enableAppovalEdit = {(currentState.userRoles.roles.includes(ADMINISTRATORS) || currentState.userRoles.roles.includes(ANALYSTS))}
                  setLoading = {setLoading}
                  enableAddApprovalButton = {isAddAllowed(phaseData)}
                  enableAddTaskButton = {isAddAllowed(phaseData)}
                /></div>)
                })
              }
            </div>
          )}
          </div>
        </Flex.Item>
      </>
    )} />
  );
};