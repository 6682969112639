import { Subject } from 'rxjs';
import { platformService } from './platform.service';

let data = [];
let initData = [];
let initCategoryData = [];
let catData = [];
let initStatusData = [];
let initStatusCreatedDeflectedData = [];
let statData = [];
let statCreatedDelfectedData =[];
let assigneeData = [];
let initAssigneeData = [];
let initLastUpdate = [];
let initLastUpdateTicketAssignee = [];
let initAverageResolutionTime =[];
let initAverageResolutionTimePerAnalyst = [];
let averageResolutionTime= "";
let averageResolutionTimePerAnalyst= "";
let lastUpDate="";
let lastUpdateTicketAssignee = "";
let ticketSurveyData = [];
const subject = new Subject();

export class ChartService extends platformService  {
    constructor() {
      super();
    }

    initData = (givenData) =>{
        initData = givenData;
        data = givenData;
    };

    initCategoryData = (givenData) => { 
        initCategoryData = givenData;
        catData = givenData;
    };
    initStatusData= (givenData) => { 
        initStatusData = givenData;
        statData = givenData;
    };

    initStatusCreatedDeflectedData = (givenData) =>{
        initStatusCreatedDeflectedData = givenData;
        statCreatedDelfectedData = givenData;
    };

    initTicketSurvey = (givenData) => {
        ticketSurveyData = givenData;
    };

    initAssigneeData = (givenData) => { 
        initAssigneeData = givenData;
        assigneeData = givenData;
    };

    initLastUpdate = (d) =>{
        initLastUpdate = d;
        lastUpDate = d;
    };
    initLastUpdateTicketAssignee = (d) => {
        initLastUpdateTicketAssignee = d;
        lastUpdateTicketAssignee = d;
    };

    initAverageResolutionTime =  (d) => {
        initAverageResolutionTime = d;
        averageResolutionTime = d;
    };
    initAverageResolutionTimePerAnalyst = (d) => {
        initAverageResolutionTimePerAnalyst = d;
        averageResolutionTimePerAnalyst = d;
    };

    getAverageResolutionTimePerAnalyst= () =>{
        return averageResolutionTimePerAnalyst;
    };

    getAverageResolutionTime = () =>{
        return averageResolutionTime;
    };

    getLastUpdateTicketAssignee = () =>{
        return lastUpdateTicketAssignee;
    };

    getLastUpdate = () =>{
        return lastUpDate;
    };

    listen = () => subject.asObservable();

    getData = () => { 
        return data;
    };
    getCatData = () => {return catData;};

    getStatData = () => { return statData;};

    getStatCreatedDeflectedData = () => {return statCreatedDelfectedData;};

    getTicketSurveyData = () => {
        return ticketSurveyData;
    };
    
    getAssigneeData = () => {return assigneeData;};

    setData = (givenData) => { 
       data = givenData;
       subject.next({data: data});
    };

    getReport = (timeframe) => {

        return this.init().post("GetReports",{ TimeFrame: timeframe});
    }
}