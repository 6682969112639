import React from 'react';
import {
  Flex,
  ProviderConsumer as FluentUIThemeConsumer
} from '@fluentui/react-northstar';
import { EditTicketRequestFormSkeleton } from '../../../shared/components/skeletonloaders/EditTicketFormSkeleton';
import { RequestDetailCardSkeleton } from './RequestDetailCardSkeleton';

export const EditRequestDetailsSkeleton = () => {
  return (
    <FluentUIThemeConsumer
      render={globalTheme => (
        <>
          <Flex.Item grow className="h-full">
            <Flex column className={`pt-5 px-5 relative`}>
              <Flex.Item>
                <RequestDetailCardSkeleton globalTheme={globalTheme} />
              </Flex.Item>
            </Flex>
          </Flex.Item>
          <Flex.Item
            className={`h-full hidden md:block`}
            styles={{
              width: '25%',
              backgroundColor: globalTheme.siteVariables.colorScheme.default.background
            }}
          >
            <div className={`p-5 m-0 overflow-auto`}>
              <div >
                <EditTicketRequestFormSkeleton />
              </div>
              <div className={`mt-2`}>
                <EditTicketRequestFormSkeleton />
              </div>
            </div>
          </Flex.Item>
        </>
      )}
    />
  );
};
