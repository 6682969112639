import React from 'react';
import { FormGroup } from '../../../shared/components/FormGroup/FormGroup';
import { FormSubLabel } from '../../../shared/components/FormSubLabel/FormSubLabel';
import { Dropdown, DropdownProps, Input, ProviderConsumer } from '@fluentui/react-northstar';
import { SLA, UnitOfTimeDropdownModel } from '../../../shared/utils/constants';
import { useTranslation } from 'react-i18next';

interface Props {
  slaBreachedModel: SlaBreachMeta;
  updateSlaBreachModel: (slaBreachedModel: SlaBreachMeta) => void;
}

export const Breached = ({ slaBreachedModel, updateSlaBreachModel }: Props) => {
  const {t} = useTranslation();
  return (
    <ProviderConsumer render={globalTheme =>
      <FormGroup>
        <div className={`flex items-center text-base mb-2 font-bold`}>
          <h2 >{t('sla.sla-breached')}</h2>
          <span className="text-red-500 ml-1">*</span>
        </div>
        <FormSubLabel content={`${SLA.SLA_STATE}:`} />
        <div className="flex items-center gap-3">
          <Input
            type="number"
            disabled={!slaBreachedModel.timeUnit}
            inverted
            fluid
            placeholder="0"
            styles={{ flex: '0 1 65%' }}
            value={slaBreachedModel.timeDigitPart}
            onChange={(_e, p) => {
              const _value = +p.value;
              if (_value >= 0)
                updateSlaBreachModel({
                  ...slaBreachedModel,
                  timeDigitPart: +p.value
                });
            }}
          />
          <Dropdown
            styles={{ flex: '1' }}
            inverted
            fluid
            placeholder={SLA.UNIT_OF_TIME}
            items={UnitOfTimeDropdownModel}
            value={t(`sla.time.${slaBreachedModel.timeUnit.toLowerCase()}`)}
            style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
            onChange={(_e, p: DropdownProps) => {
              const _data = p.value as DropdownDataModel;
              updateSlaBreachModel({
                ...slaBreachedModel,
                timeUnit: _data.value
              });
            }}
          />
        </div>
      </FormGroup>
    } />
  );
};
