import {
  UnitOfTime,
  RuleGroupType,
  RuleCriteriaOperator,
  AutomationOperators
} from './../../shared/utils/constants';

const PropertyMapper = {
  Request: 'Title',
  'New Comment': 'Comments'
};

export const prepareSlaCreatePayload = (
  slaInfo: SlaInfo,
  slaSlidingBreachModel: SlaBreachMeta,
  slaBreachedModel: SlaBreachMeta,
  ruleGroup: SlaTicketCriteria,
  isCreate: boolean
): SlaCreate => ({
  Model: {
    Id: !isCreate ? slaInfo.id : 0,
    Name: slaInfo.name,
    IsActive: slaInfo.isActive,
    StatusList: slaInfo.statusList,
    SlaSlidingBreachModel: {
      TimeDigitPart: slaSlidingBreachModel.timeDigitPart,
      TimeUnit: UnitOfTime[slaSlidingBreachModel.timeUnit] + ''
    },
    SlaBreachedModel: {
      TimeDigitPart: slaBreachedModel.timeDigitPart,
      TimeUnit: UnitOfTime[slaBreachedModel.timeUnit] + ''
    },
    RuleGroup: ruleGroup
      ? {
          Id: !isCreate ? ruleGroup.Id : 0,
          LogicOperator: RuleGroupType[ruleGroup.logicOperator] + '',
          CriteriaMetaData: ruleGroup.criteriaMetaData.map(criteria => ({
            Id: !isCreate ? criteria.id : 0,
            Property: PropertyMapper[criteria.property]
              ? PropertyMapper[criteria.property]
              : criteria.property.replaceAll(' ', ''),
            Operator: RuleCriteriaOperator[criteria.operator] + '',
            Value: criteria.value
          }))
        }
      : null
  }
});

export const ticketCriteriaValidation = (criteriaData: CriteriaMetaData[]) =>
  criteriaData.every(criteria => {
    if (
      (criteria.property &&
        criteria.operator &&
        [
          AutomationOperators.IsEmpty.toLowerCase(),
          AutomationOperators.IsNotEmpty.toLowerCase(),
          AutomationOperators.IsNotNull.toLowerCase(),
          AutomationOperators.IsNull.toLowerCase()
        ].includes(criteria.operator.toLowerCase()) &&
        !criteria.value) ||
      (criteria.property && criteria.operator && criteria.value)
    ) {
      return true;
    }
    return false;
  });
