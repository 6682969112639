import React, { useEffect, useState } from "react";
import { Button, CalendarIcon, Dialog, Flex, ProviderConsumer as FluentUIThemeConsumer, List, ListItem, Skeleton, Text } from '@fluentui/react-northstar';
import { useParams } from "react-router-dom";
import { AppStateContext, appState } from "../../../../AppState";
import { getFileIcon, getTruncatedFilename } from "./NewAttachmentFlowContent";
import { checkInTeams } from "../../../../App";
import { FileProps, bytesToSize } from "../EditTicketAttachments";
import { Icon, Link, mergeStyleSets } from "@fluentui/react";
import { graphService } from "../../../services/graph.service";
import { useTranslation } from "react-i18next";
import { platformService } from "../../../services/platform.service";
import * as microsoftTeams from "@microsoft/teams-js";
import { DateTime } from "luxon";
import { PlatformUser } from "../../../interfaces/platformuser.interface";
import { UserAvatar } from "../../UserAvatar";
import { ITeamsConfig } from "../../../../config/TeamsConfig";

export const NewAttachmentList: React.FC = () => {

  const { t, i18n } = useTranslation();
  const currentState = appState();
  const api = new platformService();
  const graphApi = new graphService();
  const { ticketId, isEndUserPage } = useParams<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [files, setFiles] = useState<FileProps[]>([]);
  const [platformUsers, setPlatformUSers] = useState<PlatformUser[]>([]);
  const [teamsConfig, setTeamsConfig] = useState<ITeamsConfig>(currentState.teamsConfig);
  let isInTeams = checkInTeams();
  const isEndUser = (isEndUserPage?.toLowerCase() ?? "false") === "true";

  const classNames = mergeStyleSets({
    fileLink: {
      //   fontWeight: 600,
      color: 'var(--mgt-theme-brand-foreground1)',
      ':hover, :active, :focus': {
        color: 'var(--mgt-theme-brand-foreground1) !important'
      }
    },
    alertLink: {
      color: 'inherit',
      fontWeight: 600,
      ':hover, :active, :focus': {
        color: 'inherit'
      }
    },
    fileIcon: {
      color: 'var(--mgt-theme-default-foreground2)',
      marginRight: '.5rem'
    }
  });

  useEffect(() => {
    (async () => {
      const results = await Promise.all([
        api.getTicketFiles(ticketId),
        api.getUserList(),
        (currentState?.teamsConfig?.TenantId) ? currentState?.teamsConfig : (await api.getTeamsBotConfiguration()).data
      ]);
      const items = results[0];
      const users = results[1];
      const filteredAttachments = isEndUser ? items.filter(a => a["IsPublic"]) : items;
      setFiles(filteredAttachments);
      setPlatformUSers(users);
      setTeamsConfig(results[2] as ITeamsConfig);
      setIsLoading(false);
    })()
  }, []);

  const deleteFileInList = async (id: any) => {
    try {
      const deleteFile = await api.deleteFile(id);
      if (deleteFile?.status == 204) {
        setFiles((currentItems: FileProps[]) => {
          return currentItems.filter(item => item.Id != id);
        });
      }
    } catch (error) {
      console.error(`There was an error deleting File`);
    }
  };

  const renderFiles = (globalTheme: any) => {
    return files.map((file, index) => (
      <ListItem
        {...{
        index: index,
        media: (
            getFileIcon(file.FileExtension, file, globalTheme)
        ),
        header: (
            <Link
              disabled={file.isUploading || file.isUploadFailed || file.MoveItemFailed}
              target="_blank"
              href={file.DownladUrl}
              className={classNames.fileLink}
            >
              {`${getTruncatedFilename(file.FileName, 50)} (${bytesToSize(file.FileSize)})`}
            </Link>
          ),
          headerMedia: (
            ''
          ),
          endMedia: (
            <Flex>
              {!isEndUser && (
                <Dialog
                  cancelButton={t('ticket-details.toolbar.action.delete-dialog.button.cancel')}
                  confirmButton={t('ticket-details.toolbar.action.delete-dialog.button.confirm')}
                  onConfirm={() => {
                    deleteFileInList(file.Id);
                  }}
                  header={t('ticket-details.edit-ticket-form.attachments.delete-dialog.header')}
                  content={getTruncatedFilename(file.FileName, 50)}
                  trigger={
                    <Button
                      style={{ color: globalTheme.siteVariables.colorScheme.brand.foreground }}
                      icon={<Icon iconName="Delete" className={'text-sm'} />}
                      iconOnly
                      text
                      title={t('ticket-details.edit-ticket-form.attachments.button.delete')}
                    />
                  }
                />
              )}

              <Button
                style={{
                  color: globalTheme.siteVariables.colorScheme.brand.foreground
                }}
                icon={<Icon iconName="Download" className={'text-sm'} />}
                iconOnly
                text
                title={t('ticket-details.edit-ticket-form.attachments.button.download')}
                onClick={() =>
                  graphApi.downloadFile(file.DriveItemGroupId, file.DriveItemId)
                }
              />
            </Flex>

          ),
          content: (
            <Flex gap="gap.medium" className="mt-1">
              <div className="flex items-center gap-2">
                <UserAvatar
                  {...{
                    idOrUpn: file["CreatedBy"]?.AadObjectId ?? file["CreatedBy"]?.UserName,
                    avatarProps: { name: file["CreatedBy"]?.FullName, size: 'smaller' }
                  }}
                />
                <Text content={file["CreatedBy"]?.FullName} size="small" />
              </div>

              <div className="flex items-center gap-2">
                <Flex vAlign="center" gap="gap.smaller">
                  <CalendarIcon style={{ color: '#8f90a6' }} />
                </Flex>
                <Flex>
                  {DateTime.fromISO(file.ModifiedDate).setLocale(i18n.language).toLocaleString(DateTime.DATETIME_MED)}
                </Flex>
              </div>
            </Flex>
          ),
          style: {
            borderBottomWidth: 'thin',
            height: '4rem',
            display: file.isHidden ? 'none' : 'flex',
            cursor: 'auto'
          },
          hidden: file.isHidden
        }}
      />
    ))
  };

  const renderSkeleton = (num: number) => Array.from(Array(num)).map((r, index) => (
    <ListItem {...{
      index: index,
      media: <Skeleton animation="wave"><Skeleton.Shape width="32px" height="32px"></Skeleton.Shape></Skeleton>,
      content: <Skeleton animation="wave"><Skeleton.Line></Skeleton.Line></Skeleton>
    }} />
  ));

  const closeTaskModlue = () => {
    microsoftTeams.dialog.url.submit();
  };

  return (
    <AppStateContext.Consumer>
      {(state) => {
        return (
          <FluentUIThemeConsumer
            render={globalTheme => (
              <div className={`h-screen px-10 py-2`}>
                <>
                  <Flex space="between" style={{ margin: "1.25rem 0", alignItems: "center" }}>
                    <Flex.Item className="extended-toolbar__far-side">
                      <Text content={`${t('ticket-details.edit-ticket-form.attachments.all-attachments')} (${files.length})`} size="large" weight="semibold" />
                    </Flex.Item>
                    {files.length > 0 && (<Flex.Item>
                      <Flex vAlign="center">
                        <Button
                          primary
                          style={{
                            color: globalTheme.siteVariables.colorScheme.brand.foreground
                          }}
                          icon={<Icon iconName="Download" className={'text-sm'} />}
                          content={t('ticket-details.edit-ticket-form.attachments.button.download-all')}
                          text
                          title={t('ticket-details.edit-ticket-form.attachments.button.download')}
                          onClick={() =>
                            window.open(`${window.__runtimeConfig.platformurl}teams/downloadTicketFiles/${teamsConfig?.TenantId ?? ''}/${ticketId}`)
                          } />
                      </Flex>
                    </Flex.Item>)}
                  </Flex>
                  {(isLoading) && (<List>{renderSkeleton(10)}</List>)}
                  {!isLoading && files.length > 0 && (
                    <List truncateHeader variables={{ selectableFocusHoverBackgroundColor: globalTheme.siteVariables.colorScheme.default.background }} className="overflow-auto" style={{ height: "calc(100vh - 175px)" }}>
                      {renderFiles(globalTheme)}
                    </List>)}
                  {isInTeams && (
                    <Flex hAlign='end' className='mt-4'>
                      <Button
                        content={t('ticket-details.apply-template.dialog.button.close')}
                        onClick={closeTaskModlue}
                      />
                    </Flex>
                  )}
                </>
              </div>
            )} />
        );
      }}
    </AppStateContext.Consumer>)
}