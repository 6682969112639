import { TTextObject } from './translations/';

export enum ChartTypes {
  Line = 'line',
  LineStacked = 'lineStacked',
  LineArea = 'lineArea',
  Bar = 'bar',
  BarStacked = 'barStacked',
  BarHorizontal = 'barHorizontal',
  BarHorizontalStacked = 'barHorizontalStacked',
  Pie = 'pie',
  Doughnut = 'doughnut',
  Bubble = 'bubble'
}

export interface IChartData {
  labels: TTextObject | TTextObject[];
  datasets: IChartDataSet[];
}

export interface IBubbleChartData {
  x: number;
  y: number;
  r: number;
}
export interface IChartDataSet {
  label: TTextObject;

  data: number[] | IBubbleChartData[];

  hidden?: boolean;
}
export enum EShapes {
  Square = 'square',
  DiagonalRightLeft = 'diagonalRightLeft',
  Grid = 'grid',
  Diagonal = 'diagonal',
  VerticalLine = 'verticalLine',
  GridRightLeft = 'gridRightLeft'
}
export interface IDraw {
  shapeType: EShapes;
  size: number;
}
export type IChartPatterns = (colorScheme: any) => IDraw[];
export interface ILegendItem {
  key: number;
  kind: string;
  content: JSX.Element;
  fitted: string;
  onClick: (index: number) => void;
}
export interface ILineChartPatterns {
  lineBorderDash: number[];
  pointStyle: EPointStyles;
}

export enum EPointStyles {
  Circle = 'circle',
  Rectangle = 'rect',
  Triangle = 'triangle',
  RectangleRotated = 'rectRot'
}
