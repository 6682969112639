import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Flex, headerDescriptionClassName, ProviderConsumer } from '@fluentui/react-northstar';
import { platformService } from '../shared/services/platform.service';
import { Communication, themeNames } from '@fluentui/react-teams';
import { Providers } from '@microsoft/mgt-element/dist/es6/providers/Providers';
import { Breadcrumbs } from '../shared/components/BreadcrumbNavigation';
import {
  DEFAULT_API_STATUS,
  DONE,
  ERROR,
  LOADING,
  LICENSE_DISTRIBUTION,
  USER_LICENSE_HEADERS,
  GETTING_ALL_LICENSE,
  toastDefault,
  ADMINISTRATORS
} from '../shared/utils/constants';
import { CheckLogin } from '../shared/components/CheckLogin';
import { Helmet } from 'react-helmet';
import { LoadingScreen } from '../shared/components/LoadingScreen';
import { Toolbar } from '../shared/components/Toolbar';
import { TableWrapper } from '../shared/components/table/TableWrapper';
import { EmptyData } from '../shared/components/EmptyData';
import EmptySVG from './../../svg/empty.svg';
import { PeoplePicker } from '@microsoft/mgt-react';
import { UserType, PersonType } from '@microsoft/mgt-components';
import { NameUserAvatar } from './NameUserAvatar';
import useCurrentPage from '../kb/useCurrentPage';
import { toast } from 'react-toastify';
import { IUserProductLicense } from '../shared/interfaces/platformuser.interface';
import { useTranslation } from 'react-i18next';

interface ILicenseDistributionProperties {
  routeName: string;
  licenseName: string;
  updateLicensesDetail: () => void;
}

interface ILicensedAnalyst {
  isLicensed: boolean;
  errorMessage: string;
  isOverage: boolean;
}

interface ISeatCount {
  licensedSeatCount: number;
  seatsUsedCount: number;
}

export const LicenseDistribution = ({ routeName, licenseName, updateLicensesDetail }: ILicenseDistributionProperties) => {
  const { t } = useTranslation();
  const api = new platformService();
  const [data, setData] = useState<RowsData[]>([]);
  const [userData, setUserData] = useState([]);
  const [reloadData, setReloadData] = useState(false);
  const [reloadDataOnSearch, setReloadDataOnSearch] = useState(false);
  const [resetPicker, setResetPicker] = useState(false);
  const [product, setProduct] = useState<any>({});
  const [header, setHeader] = useState("");
  const [searchKey, setSearchKey] = useState('');
  const [apiStatus, setApiStatus] = useState<APIStatus>(DEFAULT_API_STATUS as APIStatus);
  const { currentPage, setCurrentPage } = useCurrentPage();
  const [isFormUpdated, setIsFormUpdated] = useState<boolean>(false);
  const [licensedUsers, setLicensedUsers] = useState<IUserProductLicense[]>([]);
  const [licensedAnalystData, setLicensedAnalystData] = useState<ILicensedAnalyst>()
  const [isAdmin, setIsAdmin] = useState<boolean>();
  const [seatsCount, setSeatsCount] = useState<ISeatCount>();
 
  useEffect(() => {
    const rowData: RowsData[] = userData.map((user: any, index: number) => {
      const obj = {
        id: user['Id'],
        hiddenName: user.FullName.toString()
      };
      obj[USER_LICENSE_HEADERS[0]] = (<NameUserAvatar user={user} />);
      obj[USER_LICENSE_HEADERS[1]] = (<Checkbox
        checked={userData[index]["IsUserLicensed"]}
        disabled={user.IsDisabled}
        onChange={(_e, data) => onChangeCheckbox(userData, data, index)}
      />);

      return obj;
    });
    setData(rowData);
    setReloadData(false);
    setReloadDataOnSearch(false);
    if (searchKey?.length > 0 && !reloadDataOnSearch) {
      setSearchKey(`${searchKey.substring(0, searchKey.length - 1)}`);
    }
    setIsFormUpdated(checkIfFormUpdated());
  }, [userData, reloadData]);

  const onChangeCheckbox = (users: any, data: any, index: number) => {
    let seatCount: number = product.SeatCount;
    let usedCount: number = users.filter((x: any) => x.IsUserLicensed).length;
    let licensedPlatformUserCount = 0;
    
    for (let licensedUser of licensedUsers)    {
      if (!users.find(x => x.Id == licensedUser.PlatformUserId))
        licensedPlatformUserCount++;
    }
    usedCount += licensedPlatformUserCount;
    setSeatsCount({
      licensedSeatCount: seatCount,
      seatsUsedCount: usedCount
    });
    updateUserData(index, data, seatCount);
    setHeaderText(data, seatCount);
    setReloadData(true);
    setReloadDataOnSearch(true);
  }

  const updateUserData = (index: number, data: any, seatCount: number) => {
    setUserData(prevState => {
      prevState[index]["IsUserLicensed"] = data.checked;
      if (data.checked && seatCount == seatsCount.seatsUsedCount + 1) prevState.forEach(x => x.IsDisabled = !x.IsUserLicensed);
      if (!data.checked && seatsCount.seatsUsedCount - 1 < seatCount) prevState.forEach(x => x.IsDisabled = false);
      return prevState;
    });
  }

  const setHeaderText = (data: any, seatCount: number) => {
    let availableCount = 0;
    let newSeatCount = seatsCount.seatsUsedCount
    if (data.checked) {
      newSeatCount = seatsCount.seatsUsedCount + 1;
      availableCount = seatCount - newSeatCount;
      availableCount = (availableCount < 0) ? 0 : availableCount;
      setHeader(t('billing.license.available', { available: availableCount, seatCount: seatCount }))
    } else {
      newSeatCount = seatsCount.seatsUsedCount - 1;
      availableCount = seatCount - newSeatCount;
      availableCount = (availableCount < 0) ? 0 : availableCount;
      setHeader(t('billing.license.available', { available: availableCount, seatCount: seatCount }))
    }

    setSeatsCount({
      licensedSeatCount: seatCount,
      seatsUsedCount: newSeatCount
    });
  }

  const fetchData = useCallback(async () => {

    setApiStatus({ ...apiStatus, msg: GETTING_ALL_LICENSE });

    try {
      const result = await Promise.all([
        api.getUsers("?$orderby=FullName"),
        api.getUserLicenses("?$expand=PlatformUser&$orderby=PlatformUser/UserName"),
        api.getTenantLicenses(),
        api.getLicensedAnalyst(),
        api.me()
      ]);

      const users = result[0].data.value
      const licenses = result[1].data.value;
      const products = result[2].data.value;
      const licensedAnalystData = result[3].data;
      const roles = result[4].data.Roles;
      setLicensedAnalystData(licensedAnalystData);
      setIsAdmin(roles.includes(ADMINISTRATORS));
      setLicensedUsers(licenses);
      setProduct(products[0]);

      let seatCount: number = products[0].SeatCount;
      let usedCount: number = products[0].UsedCount;
      setSeatsCount({
        licensedSeatCount: seatCount,
        seatsUsedCount: usedCount
      });

      let availableCount = seatCount - usedCount;
      availableCount = (availableCount < 0) ? 0 : availableCount;
      setHeader(t('billing.license.available', { available: availableCount, seatCount: seatCount }))

      let userData = users.map((user: any) => {
        const isUserLicensed = licenses.some(x => x.PlatformUserId === user.Id);
        const isDisabled = (seatCount == usedCount) && !isUserLicensed;
        return {
          ...user,
          IsUserLicensed: isUserLicensed,
          _IsUserLicensed: isUserLicensed,
          IsDisabled: isDisabled
        }
      });
      setUserData(userData);

    } catch (err) {
      setApiStatus({ ...apiStatus, status: ERROR, errCode: err['response']?.status });
    } finally {
      setApiStatus({ ...apiStatus, status: DONE });
    }
  }, []);

  const loadPage = () => {
    fetchData();
  };

  useEffect(() => {
    let count = 0;
    const setPicker = async () => {
      const picker: any = document.querySelector('mgt-people-picker');
      if ((!picker && !picker?.shadowRoot) && count <= 20)
        setTimeout(() => { setPicker(); }, 500);
      if (picker?.shadowRoot) {
        setTimeout(() => {
          const pickerSelector = picker.shadowRoot;
          const pickerInput = pickerSelector.querySelector('#people-picker-input');
          if (pickerInput)
            pickerInput.addEventListener('input', (e: React.SyntheticEvent<HTMLElement>) => {
              setSearchKey((e.target as HTMLInputElement).value);
            });
        }, 500);
      }
      count++;
    }
    setPicker();
  }, [resetPicker]);

  const checkIfFormUpdated = () => {
    return userData?.some(u => u["_IsUserLicensed"] != u["IsUserLicensed"]) ?? false;
  };

  const getNavigation = (): Breadcrumbs => {
    const items: Breadcrumbs = { breadcrumbs: [] };
    items.breadcrumbs.push({ title: licenseName, link: "/settings/billing" });
    if (header && header.length > 0)
      items.breadcrumbs.push({ title: header, link: "/settings/billing" });
    return items;
  };

  const errConfig = {
    fields: {
      title: apiStatus.errCode === 401 ? t('common.unauthorized-access') : t('common.something-went-wrong'),
      desc: t('common.please-contact-your-administrator')
    }
  };

  const ifEmpty = {
    header: t('billing.license.no-records'),
    subHeader: t('billing.license.please-add')
  };

  const searchChangeHandler = async (e: any) => {
    setResetPicker(!resetPicker);
    if (e.detail.length === 0) {
      setSearchKey("");
      return;
    }

    const { displayName, givenName, surname, userPrincipalName, scoredEmailAddresses, id } = e.detail[0];
    setSearchKey(displayName);

    if (!userData.some(x => x.UserName == userPrincipalName || x.AadObjectId == id)) {
      let seatCount: number = product.SeatCount;
      let usedCount: number = userData.filter((x: any) => x.IsUserLicensed).length;
      const payload = {
        Id: 0,
        FullName: displayName,
        GivenName: givenName,
        FamilyName: surname,
        Email: (scoredEmailAddresses && scoredEmailAddresses[0]?.Address) || userPrincipalName,
        UserName: userPrincipalName,
        AadObjectId: id,
        IsUserLicensed: false,
        _IsUserLicensed: false,
        IsDisabled: seatCount == usedCount
      };
      setUserData((prevState: any[]) => {
        prevState.push(payload);
        return prevState;
      });
      setReloadData(true);
    }
  };

  const saveData = async () => {
    setApiStatus({ status: LOADING, msg: t('billing.license.saving'), errCode: 0 });
    try {
      const dirtyData = userData.filter(x => x["_IsUserLicensed"] != x["IsUserLicensed"]);
      const toAdd = dirtyData.filter(x => x["IsUserLicensed"]);
      const toRemove = dirtyData.filter(x => !x["IsUserLicensed"]);
      const data = {
        AddToUsers: JSON.stringify(toAdd),
        RemoveFromUsers: JSON.stringify(toRemove)
      }
      await api.updateUsersLicenses(data);
      toast.success(t('billing.license.update-success'), toastDefault);
      onSaveSuccess();
    } catch (err) {
      setApiStatus({ ...apiStatus, status: ERROR, errCode: err['response']?.status });
    }
  };

  const onSaveSuccess = () => {
    setApiStatus({ ...apiStatus, status: DONE });
    setUserData(userData.map((user: any) => ({ ...user, _IsUserLicensed: user.IsUserLicensed })));
    updateLicensesDetail();
  }

  const saveDisabled = () => {
    if (!isFormUpdated)
      return true;

    return (seatsCount.seatsUsedCount > seatsCount.licensedSeatCount);
  }

  return (
    <CheckLogin onSignedIn={loadPage}>
      <Helmet>
        <title>{t('users.tabs.licenses')} - Tikit</title>
      </Helmet>
      {apiStatus.status === LOADING && (<LoadingScreen message={apiStatus.msg} />)}
      {apiStatus.status === ERROR && (
        <>
          <Communication {...errConfig} />
          {apiStatus.errCode === 401 && (
            <Button
              content="Refresh Session"
              primary
              onClick={_e => { Providers.globalProvider.login(); }}
            />
          )}
        </>
      )}
      {apiStatus.status === DONE && (
        <ProviderConsumer render={globalTheme => (
          <>
            <Toolbar
              globalTheme={globalTheme}
              title={LICENSE_DISTRIBUTION}
              breadcrumbs={getNavigation()}
              saveText={t('common.buttons.save')}
              saveCommand={saveData}
              disableSave={saveDisabled()}
              overrideLicenseCheck={(licensedAnalystData.isLicensed && licensedAnalystData.isOverage) || isAdmin}
            />

            {/* Main Section */}
            <Flex style={{ marginTop: '-1.25rem', height: 'calc(100vh - 110px)' }} column gap='gap.medium'>
              <Flex.Item>
                <Flex style={{ marginTop: '1rem' }}>
                  <Flex.Item size="size.half"><div></div></Flex.Item>
                  <Flex.Item size="size.half">
                    <div>
                      <PeoplePicker
                        userType={UserType.user}
                        type={PersonType.person}
                        selectionMode="single"
                        selectionChanged={searchChangeHandler}
                        showMax={3}
                      />
                    </div>
                  </Flex.Item>
                </Flex>
              </Flex.Item>
              <Flex.Item grow styles={{ paddingTop: '1.25rem', overflow: 'auto' }}>
                <div style={{ padding: 10 }}>
                  {ifEmpty && (
                    <>
                      {data.length === 0 && (
                        <EmptyData
                          headerText={ifEmpty.header}
                          subheaderText={ifEmpty.subHeader}
                          SVGIcon={<EmptySVG width={200} height={200} />}
                          style={{color:`${globalTheme.siteVariables.theme === themeNames.Dark ? 'white' : 'black'}`}}
                        />
                      )}
                      {data.length !== 0 && (
                      <TableWrapper
                        headers={USER_LICENSE_HEADERS}
                        headerNotTitleCase={false}
                        rows={data}
                        routeName={routeName}
                        hideSearch={true}
                        searchText={searchKey}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                      />
                    )}
                    </>
                  )}
                </div>
              </Flex.Item>
            </Flex>
          </>)}
        />
      )}
    </CheckLogin>
  );
};
