import React from 'react';
import {
  CalendarIcon,
  Divider,
  EyeIcon,
  Flex,
  ProviderConsumer as FluentUIThemeConsumer,
  Text
} from '@fluentui/react-northstar';
import { Link } from '@fluentui/react';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { ConvertDateToLocale } from '../../../shared/common/ConvertDate';
import AffectedUserIcon from '../../../../svg/Affected-user-request.svg';

interface Props {
  ticket: Ticket;
  view?: 'list' | 'edit';
  userId: number;
}

const isAffectedUser = (ticket,userId) => ticket.AffectedUsers?.some(x=>x.PlatformUserId == userId);

export const RequestCardFooter = ({ ticket, view = 'list', userId }: Props) => {
  const {t} = useTranslation();
  return (
  <FluentUIThemeConsumer
    render={globalTheme => (
      <>
        <Divider size={0} className={`mb-2`} />
        <Flex vAlign="center" space="between">
          <Flex vAlign="center">
            <span
              style={{ marginRight: '8px', marginBottom: '4px' }}
              className={`inline`}
            >
              <CalendarIcon style={{ color: '#8f90a6' }} />
            </span>
            <Text size="small" className={`text-gray-500 ml-2`}>
              <span className={`inline`}>{t('requests.last')} </span>
              {t('requests.modified')}:&nbsp;
            </Text>
            <Text
              size="small"
              style={{ marginLeft: '8px' }}
              content={ConvertDateToLocale(ticket.ModifiedDate, DateTime.DATETIME_MED)}
            />
          </Flex>
          {view === 'list' ? (
            <Flex>
              {isAffectedUser(ticket, userId) && (
                <Link to={`/requests/${ticket.Id}`} className={`hover:no-underline py-1 mr-2 cursor-pointer`} style={{ color: globalTheme.siteVariables.colorScheme.brand.foreground }}> 
                  <Text size="small" weight="semibold" content={
                    <Flex vAlign="center">
                      <AffectedUserIcon />
                      <span className={`ml-1`} style={{ color: '#8F90A6' }}>{t('requests.affected-user')}</span>
                    </Flex>} />
                </Link>
              )}
             <Link to={`/requests/${ticket.Id}`} className={`hover:no-underline py-1 cursor-pointer`} style={{ color: globalTheme.siteVariables.colorScheme.brand.foreground }}>
                <Text size="small" weight="semibold" content={
                  <Flex vAlign="center">
                    <EyeIcon size="small" />
                    <span className={`ml-1`}>{t('requests.view-ticket')}</span>
                  </Flex>} />
              </Link>
            </Flex>
          ) : (
            ''
          )}
        </Flex>
      </>
    )}
  />
)};
