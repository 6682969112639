import React, { useState } from 'react';
import { Dropdown } from '@fluentui/react-northstar';
import AppCSS from '../App.module.css';
import { listFilterDate } from './ticketHelper';
import { useSetState } from '../AppState';

export const ChartMenu = () => {
  const setState = useSetState();
  const [listChartFilterDate, setChartFilterDate] = useState(0);

  return (
    <Dropdown
      items={listFilterDate}
      triggerButton={<span>{listFilterDate[listChartFilterDate]}</span>}
      fluid
      className={`${AppCSS.dropdownItem} h-12 dropdown-btn`}
      onChange={(event, props) => {
        const filterDate =
          typeof props.highlightedIndex == 'undefined'
            ? 0
            : props.highlightedIndex;
        setChartFilterDate(filterDate);
        setState(s => {
          return {
            ...s,
            listChartFilterDate: props.highlightedIndex
          };
        });
      }}
    />
  );
};
