import React from 'react';
import { ProviderConsumer, Flex, Card, Text } from '@fluentui/react-northstar';
import MgtCss from '../mgt.module.css';
import { TEMPLATE } from '../shared/utils/constants';
import { TicketTemplate } from '../shared/interfaces/ticket.interface';
import { TicketTemplatesSelectionProperties } from './TicketTemplatesSelection';
import { AdaptiveCardElement } from './AdaptiveCardElement';
import TemplateFieldsCardBody from './TemplateFieldsCardBody';

export const TicketTemplatesSelectionView = (
  props: TicketTemplatesSelectionProperties
) => {
  const { formData, ticketData } = props;
  const templateData: TicketTemplate = ticketData['SelectedTemplate'];
  let noCustomForm = ticketData['SelectedTemplate']["NoCustomForm"];
  let countProps = 0;
  if (templateData.UnsetAssignee || templateData.AssigneeId) countProps++;
  if (templateData.UnsetCategory || templateData.CategoryId) countProps++;
  if (templateData.PriorityId) countProps++;
  if (templateData.StatusId) countProps++;
  if (templateData.TicketTypeId) countProps++;
  if (templateData.UnsetSupportGroup || templateData.SupportGroupId) countProps++;
  if (templateData.UnsetTeam || templateData.TeamId) countProps++;

  return (
    <ProviderConsumer
      render={globalTheme => (
        <>
          <Flex
            column
            style={{ flex: 1, maxHeight: '70vh', overflow: 'auto' }}
            gap="gap.large"
          >
            <Flex.Item>
              <Flex column>
                <Text
                  content={`${TEMPLATE.APPLY} ${ticketData['SelectedTemplate']['Name']}`}
                  weight="bold"
                  size="large"
                />
                <Text content={TEMPLATE.UPDATE_TICKET_DATA_DESCRIPTION} />
              </Flex>
            </Flex.Item>
            <Flex.Item>
              <Flex gap="gap.medium" className={MgtCss.cardSelectionContainer}>
                <Flex.Item size="size.half">
                  <Flex column style={{ maxWidth: '46%' }} gap="gap.medium">
                    <Flex column>
                      <Text content={TEMPLATE.TICKET_PROPERTIES} weight="bold" />
                      <Text
                        content={
                          countProps > 0
                            ? TEMPLATE.ASSIGN_PRESET_PROPERTY
                            : TEMPLATE.NO_PROPERTY_TO_CHANGE
                        }
                      />
                    </Flex>
                    <TemplateFieldsCardBody
                      templateData={ticketData['SelectedTemplate']}
                      formData={formData}
                      isSelectionView={true}
                    />
                  </Flex>
                </Flex.Item>
                {noCustomForm?null
                :
                <Flex.Item size="size.half">
                  <Flex column style={{ maxWidth: '46%' }} gap="gap.medium">
                    <Text content={TEMPLATE.CUSTOM_FORM} weight="bold" />
                    <Card
                      compact
                      ghost
                      styles={{ '&:hover': { background: 'transparent' } }}
                    >
                      <Card.Body fitted>
                        <AdaptiveCardElement
                          jsonString={
                            ticketData['SelectedTemplate']['CardResponseJson']
                          }
                          cardProps={{
                            styles: {
                              height: '350px',
                              overflow: 'hidden',
                              pointerEvents: 'none'
                            }
                          }}
                        />
                      </Card.Body>
                    </Card>
                  </Flex>
                </Flex.Item>
                }
              </Flex>
            </Flex.Item>
          </Flex>
        </>
      )}
    />
  );
};
