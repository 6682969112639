import React, { createContext, useState } from 'react';

export interface Props {
  children: React.ReactNode;
} 

export interface RequestContextType {
  requestStates: ListStatesType;
  setRequestStates: React.Dispatch<React.SetStateAction<ListStatesType>>;
  approvalStates: ApprovalStatesType;
  setApprovalStates: React.Dispatch<React.SetStateAction<ApprovalStatesType>>;
  showClosed: boolean;
  setShowClosed: React.Dispatch<React.SetStateAction<boolean>>;
  searchFilter: string;
  setSearchFilter: React.Dispatch<React.SetStateAction<string>>;
}

export const RequestListContext = createContext({} as RequestContextType);
const RequestListProvider = RequestListContext.Provider;

const RequestContextProvider = ({ children }: Props) => {
  const [requestStates, setRequestStates] = useState<ListStatesType>({ isTicketsFetching: true, countTickets: 0, tickets: [], currentPage: 1 });
  const [approvalStates, setApprovalStates] = useState<ApprovalStatesType>({ isApprovalsFetching: true, countApprovals: 0, approvals: [], currentPage: 1 });
  const [showClosed, setShowClosed] = useState<boolean>(false);
  const [searchFilter, setSearchFilter] = useState<string>('');

  return (
    <>
      <RequestListProvider value={{ requestStates, setRequestStates, approvalStates, setApprovalStates, showClosed, setShowClosed, searchFilter, setSearchFilter }}>
        {children}
      </RequestListProvider>
    </>
  );
};

export default RequestContextProvider;
