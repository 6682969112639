import React from 'react';
import { FormCheckbox } from '@fluentui/react-northstar';
import ToolbarCSS from '../../../Toolbar.module.css';

type ColumnItemProps = {
  globalTheme: any;
  checked: boolean;
  label: string;
  _onChange: () => void;
};

export const ColumnItem = ({
  globalTheme,
  checked,
  label,
  _onChange,
}: ColumnItemProps) => {
  return (
    <div
      onClick={() => _onChange()}
      style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background2 }}
      className={`p-1.5 flex items-center mb-2.5 rounded-sm cursor-pointer ${ToolbarCSS['ColumnItem']}`}
    >
      <FormCheckbox
        checked={checked}
        label={label}
      />
    </div>
  );
};
