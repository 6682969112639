import React, {  } from "react";
import uniqueId from "lodash/uniqueId";
import cloneDeep from "lodash/cloneDeep";

import { getText } from "@fluentui/react-teams/lib/esm/translations";

import { KBFormDialog } from "./KBFormDialog";
import { IRow } from "./KBTable";

export enum RowDialogAction {
  Create,
  Edit,
}

export interface RowDialogProps {
  action: RowDialogAction;
  trigger: JSX.Element;
  initialState: Partial<IRow>;
  rowKey: string;
}

export const RowDialog = ({
  action,
  trigger,
  initialState,
  rowKey
}: RowDialogProps) => {
  const sections = [
    {
      inputGroups: []
    }];
  let i = 1;
  String(initialState["c1"]).split("|").map((value) => {
  sections[0].inputGroups.push({
    type: "text-inputs",
    fields: [
      {
        type: "kbquestion",
        title: "question " + i as string + ":",
        inputId: "question" + i as string,
        optional: true,
        initialValue: value,
      },
    ],
  } as never)
  i++;
  });
  sections[0].inputGroups.push({
    type: "text-inputs",
    fields: [
      {
        type: "text",
        title: "answer:",
        inputId: "board-item__subtitle",
        optional: true,
        initialValue: getText("en-US", String(initialState["c2"])),
      },
    ],
  } as never);
  return (
    <KBFormDialog
      trigger={trigger}
      headerSection={{
        title: (function () {
          switch (action) {
            case RowDialogAction.Create:
              return "add item";
            case RowDialogAction.Edit:
              return "edit item";
          }
        })(),
      }}
      sections={sections}
      submit={"save"}
      cancel={(function () {
        switch (action) {
          case RowDialogAction.Create:
            return "discard";
          case RowDialogAction.Edit:
            return "cancel";
        }
      })()}
      onInteraction={({ formState }) => {
        if (!formState) return;
        const boardItem = Object.keys(formState).reduce(
          (
            boardItem: {
              [boardItemPropKey: string]: any;
            },
            inputId
          ) => {
            const [_prefix, boardItemProperty] = inputId.split("__");
            const value = formState[inputId];
            if (value) boardItem[boardItemProperty] = value;
            return boardItem;
          },
          (function () {
            switch (action) {
              case RowDialogAction.Create:
                return {
                  order: -1,
                  itemKey: uniqueId("nbi"),
                };
              case RowDialogAction.Edit:
                return cloneDeep(initialState);
            }
          })()
        );
        switch (action) {
          case RowDialogAction.Create:
            break;
          case RowDialogAction.Edit:
            break;
        }
      }}
    />
  );
};
