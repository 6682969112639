export const Route = {
  myWork: {
    pagePath: '/mywork',
    get list() { return `${this.pagePath}/list` },
    get board() { return `${this.pagePath}/board` },
    get editTicket() { return `${this.pagePath}/ticket` },
  },
  myGroupWork: {
    pagePath: '/mygroupwork',
    get list() { return `${this.pagePath}/list` },
    get board() { return `${this.pagePath}/board` },
    get editTicket() { return `${this.pagePath}/ticket` },
  },
  settings: {
    pagePath: '/settings',
    billing: {
      routeName: 'billing',
      pagePath: '/settings/billing',
      tikitPricing: 'https://tikit.ai/pricing',
    },
  },
};
