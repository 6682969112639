import { Text, Flex, ThemePrepared, ProviderConsumer as FluentUIThemeConsumer } from "@fluentui/react-northstar";
import React, { useState } from "react";
import { OwnedDevice, ManagedDevice } from "../shared/interfaces/platformuser.interface";
import { TablePagination } from "../shared/components/table/TablePagination";
import { ManagedDeviceCard } from "./DeviceCard";
import { OwnedDeviceCard } from "./OwnedDeviceCard";
import { FeatureConsentNeeded, UpgradeNeeded } from "../shared/components/UpgradeNeeded";
import { EmptyData } from "../shared/components/EmptyData";
import EmptySVG from '../../svg/empty.svg';
import { useTranslation } from 'react-i18next';

export const LinkedDevices = (props: {isUserLicensed: boolean, enableIntune: boolean, hasIntuneConsent: boolean, managedDevices: ManagedDevice[], ownedDevices: OwnedDevice[], userId: string, allowedPermissions: string[], globalTheme: ThemePrepared<any> }) => {
  const { t } = useTranslation();
  const unenrolledOwnedDevices = () => {
    let managedDevicesIds = []
    props.managedDevices.forEach(device => {
      managedDevicesIds.push(device.azureADDeviceId);
    })
    return props.ownedDevices.filter((device: OwnedDevice) => !managedDevicesIds.includes(device.deviceId));
  }

  let [currentPage, setCurrentPage] = useState<number>(1);
  let [paginatedData, setPaginatedData] = useState<ManagedDevice[]>(props.managedDevices.slice(0, 10));

  const moveToPage = (type: string) => {
    if (type === 'next') {
      const newData: any[] = props.managedDevices.slice(
        currentPage * 10,
        (currentPage + 1) * 10
      );
      setCurrentPage(prevPage => prevPage + 1);
      setPaginatedData(newData);
    } else {
      const newData: any[] = props.managedDevices.slice(
        (currentPage - 1) * 10 - 10,
        (currentPage - 1) * 10
      );
      setCurrentPage(prevPage => prevPage - 1);
      setPaginatedData(newData);
    }
  };

  return (
    <FluentUIThemeConsumer
      render={globalTheme => (
        <>
          {!props.enableIntune && (<UpgradeNeeded headerText={t('users.device-form.no-access-header')} subheaderText={t('users.device-form.no-access-subheader')} />)}
          {props.enableIntune && (
            <>
              {!props.hasIntuneConsent && (<FeatureConsentNeeded />)}
              {props.hasIntuneConsent && (<Flex wrap className="mb-5 mt-5">
                {paginatedData.length > 0 && (
                  <>
                    {paginatedData.map((device: ManagedDevice) => {
                      return <ManagedDeviceCard isUserLicensed={props.isUserLicensed} device={device} userId={props.userId} allowedPermission={props.allowedPermissions} globalTheme={props.globalTheme} />
                    })}
                  </>
                )}
                {paginatedData.length === 0 && unenrolledOwnedDevices().length === 0 && (
                  <div className="mt-10 flex justify-center w-full h-96">
                    <EmptyData
                      headerText={t('users.device-form.no-records')}
                      subheaderText=""
                      SVGIcon={<EmptySVG width={200} height={200} className="mb-2" />}
                    />
                  </div>
                )}
                {paginatedData.length === 0 && unenrolledOwnedDevices().length !== 0 && (
                  <>
                    <Text content={t('users.device-form.enrolled')} className="ml-6" weight="semibold" />
                    <div className="mt-5 ml-4 flex h-56 pb-5" style={{ borderBottom: '1px solid #ddd', width: '100%' }}>
                      <div className={`filter drop-shadow-md rounded w-full md:w-80`} style={{
                        background: props.globalTheme.siteVariables.colorScheme.default.background
                      }}>
                        <EmptyData
                          headerText={t('users.device-form.no-records')}
                          subheaderText=""
                          SVGIcon={<EmptySVG width={80} height={80} className="mb-2" />}
                        />
                      </div>
                    </div>
                  </>
                )}
              </Flex>)}
              {props.hasIntuneConsent && (props.managedDevices.length > 10) && (
                <div className="mb-5 px-3">
                  <TablePagination
                    currentPage={currentPage}
                    totalCount={props.managedDevices.length}
                    moveToPage={moveToPage}
                  />
                </div>
              )}
              {props.hasIntuneConsent && (<UnenrolledDevices devices={unenrolledOwnedDevices()} userId={props.userId} globalTheme={props.globalTheme} />)}
            </>
          )}
        </>
      )}
    />
  );
};

export const UnenrolledDevices = (props: { devices: OwnedDevice[], userId: string, globalTheme: ThemePrepared<any> }) => {
  const { t } = useTranslation();
  if (props.devices.length > 0) {
    return (
      <>
        <Text content={t('users.device-form.unenrolled')} className="ml-6" weight="semibold" />
        <Flex wrap>
          {props.devices.map((device: OwnedDevice) => {
            return <OwnedDeviceCard device={device} userId={props.userId} globalTheme={props.globalTheme} />
          })}
        </Flex>
      </>
    );
  } else {
    return (
      <>
      </>
    )
  }
}