import React, { useState } from 'react';
import Styles from '../styles/Lifecycle.module.css';
import { Flex, Button, AddIcon, ThemePrepared, Grid } from '@fluentui/react-northstar';
import { cloneDeep } from 'lodash';
import { PhaseApproval } from './PhaseApproval';
import { KeyValuePair, LifecycleApproval, RelationshipData } from '../utils/typings';
import { PhaseApprovalDialog } from './PhaseApprovalDialog';
import { getNewGuid } from '../utils/helper';
import { LIFECYCLE } from './../../shared/utils/constants';

interface Props {
  data: LifecycleApproval[];
  updateData: (data: LifecycleApproval[]) => void;
  globalTheme: ThemePrepared<any>;
  phaseData: RelationshipData;
  showHeading?: boolean;
}

export const PhaseApprovalSection = ({
  data,
  updateData,
  globalTheme,
  phaseData,
  showHeading
}: Props) => {

  const [openDialog, setOpenDialog] = useState(false);

  const initialApprovalData: LifecycleApproval = {
    Id: 0,
    AdditionalDetails: '',
    Name: '',
    Guid: '',
    LifecyclePhaseId: phaseData.Id,
    IsRequiredByAll: false,
    Approvers: [],
    ApprovalState: 3
  }

  let dataClone = cloneDeep(data);

  const updateApprovalData = (editData: KeyValuePair[], index: number) => {
    editData.forEach(pair => {
      if(pair.key == "RemoveItem")
        dataClone.splice(index, 1);
      else
        dataClone[index][pair.key] = pair.value;
    });    
    updateData(dataClone);
  }

  const saveNewApproval = (newApproval: LifecycleApproval) => {
    newApproval.Guid = getNewGuid();
    data.push(newApproval);
    updateData(data);
  }


  return (
    <>
     <div className={`mt-2 ${showHeading ? `${Styles.taskAndapprovalBorder} mb-6`: ''}`}>
     <Flex space={`between`} vAlign={'center'}>
      <Flex.Item><h2 className={Styles.SubHeading2} style={{marginBottom: '0px'}}>{LIFECYCLE.APPORVALS}</h2></Flex.Item>
        <Flex.Item>
          <Button
            icon={<AddIcon 
              styles={{color: globalTheme.siteVariables.colorScheme.brand.foreground}} 
            />}
            text
            primary
            content={<span 
              style={{color: globalTheme.siteVariables.colorScheme.brand.foreground}}
            >{LIFECYCLE.ADD_APPROVAL}</span>}
            onClick={() => {
              setOpenDialog(true);
            }}
          />
        </Flex.Item>
      </Flex>
        <PhaseApprovalDialog
                open={openDialog}
                onCancel={() => setOpenDialog(false)}
                saveApproval={saveNewApproval}
                initData={initialApprovalData}
            />
        <div className={`${Styles.TicketsContainer}`}>
          <Grid columns={data.length === 1 ? 1 : 2}>
            {data.map((item, index) => {
    
                return (
                  <div key={item.Id}>
                    <PhaseApproval 
                    data={item}
                    dataIndex={index}
                    updateData={updateApprovalData}
                    globalTheme={globalTheme}
                  />
                </div>
              )
            })}
          </Grid>
        </div>        
    </div>
    </>
  );

};
