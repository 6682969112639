import i18next from '../../../i18n/config';
import {
  CONTACT_ADMIN,
  SOMETHING_WENT_WRONG,
  STATUS_GUID,
  UNAUTHORIZED_ACCESS
} from './constants';
import { TicketProps } from '../interfaces/ticketproperties.interface';

export const isValidURL = (url: string): boolean => {
  const pattern = /^(ftp|http|https):\/\/[^ "]+$/;
  if (url === "") {
    return true
  }
  return pattern.test(url);
}

export const getErrConfig = (errCode: number) => ({
  fields: {
    title: errCode === 401 ? UNAUTHORIZED_ACCESS : SOMETHING_WENT_WRONG,
    desc: CONTACT_ADMIN
  }
});

export const toTitleCase = (str: string) => {
  return str.replace(/(^|\s)\S/g, function (t) { return t.toUpperCase() });
};

export const toCapitalizeCase = (str: string) => {
  str = str.toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const stringRandomizer = (length: number) => {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() *
      charactersLength));
  }
  return result;
}
export const splitString = (str: string) =>
  str.match(/[A-Z][a-z]+|[0-9]+/g).join(' ');

export const getOrderedColumnsFromViewColumns = (ViewColumns: ViewColumn[]) => {
  const orderedColumns = [] as (number | string)[];
  ViewColumns.forEach((viewColumn) => {
    orderedColumns[viewColumn.Order - 1] = viewColumn.ColumnKey;
  });
  return orderedColumns;
};

//had to duplicate this code from ticket helper because this loads up before helper, and when at that time it cannot access 'FILTERS' before initialization
export const getStatusIdByGuidHelper = (statusList: TicketProps[], guidValue: string) => {
  let status = statusList.find((s: TicketProps) => s.Guid == guidValue);
  if (!status) status = statusList[statusList.length - 1];
  return status ? status.Id : 0;
};

const updateQuery = (_title, arrayVal, queryFromArray, _statusList) => {
  if (_title === "Assignee/FullName" && arrayVal === '\'No Assignee\'') {
    queryFromArray = 'Assignee eq null';
  }
  if (_title === "SupportGroup/Name" && arrayVal === '\'No Group\'') {
    queryFromArray = 'SupportGroup eq null';
  }
  if (_title == "Team/Name" && arrayVal === '\'No Team\'') {
    queryFromArray = 'Team eq null';
  }
  if (_title == "TicketLifecycle/Lifecycle/Title" && arrayVal === '\'No Lifecycle\'') {
    queryFromArray = 'TicketLifecycle eq null';
  }
  if (_title == "Resolved" && !arrayVal) {
    queryFromArray = `StatusId ne ${getStatusIdByGuidHelper(_statusList, STATUS_GUID.RESOLVED)}`;
  }
  return queryFromArray;
}

export const getTicketsFilterQuery = (_filterObj: FilterType, _statusList: any[]) => {
  // initially filterObj contains all values of dropdown filters as empty array
  let nonEmptyFilterQueryCount = 0; // if we donot select any custom filters then their values will be empty array
  return _filterObj &&
    Object.entries(_filterObj).reduce(
      (acc, [_title, selectedValuesArr]) => {
        if (_title === 'DueDate') return acc;

        acc =
          nonEmptyFilterQueryCount > 0 && selectedValuesArr.length > 0
            ? `${acc} and (`
            : acc;
        if (selectedValuesArr.length > 0) {
          // if dropdownfilter is not an empty array
          nonEmptyFilterQueryCount += 1;
        }

        for (let i = 0; i < selectedValuesArr.length; i++) {
          const arrayVal =
            typeof selectedValuesArr[i] === 'string'
              ? `'${selectedValuesArr[i]}'`
              : selectedValuesArr[i];
          let queryFromArray = `${_title} eq ${arrayVal}`;
          queryFromArray = updateQuery(_title, arrayVal, queryFromArray, _statusList);
          acc =
            acc && i === 0
              ? acc + queryFromArray
              : acc
                ? `${acc} or ${queryFromArray}`
                : `(${queryFromArray}`;

          acc = i === selectedValuesArr.length - 1 ? `${acc})` : `${acc}`;
        }
        return acc;
      },
      ''
    );
}


export const configureLanguage = () => {
  let lsLangs = JSON.parse(localStorage.getItem('TikitLanguages'));
  i18next.changeLanguage(lsLangs.filter(x => x.isDefault)[0].value);
}

export const i18n = () => {
  configureLanguage();

  return i18next
};

export const removeHtmlTags = (htmlText: string) => {
  if (htmlText == undefined) return htmlText;

  return htmlText.replace(/<[^>]+>/g, '');
}

export const removeWhiteSpaces = (text: string) => {
  if (text == undefined) return text;
  return text.replace(/\&nbsp;/g, '').replace(/\s/g, '');
}

export const mentionify = (htmlString: string, mentionsList: any[]) => { // converting {{<property>}} to a <a> to stylize in editor
  if (mentionsList.length > 0) {
    const entities = htmlString.split("{{");
    let mentionified = entities[0];
    for (let i = 1; i < entities.length; i++) {
      const endEntities = entities[i].split("}}");
      const mention = endEntities[0];
      let mentionDetail = mentionsList.find(x => x.url == `{${mention}}}`);
      mentionified += (mentionDetail ? getMentionTag(mention, mentionDetail?.text) : `{{${mention}}}`) + endEntities[1];
    }
    return mentionified;
  }
  else return htmlString;
}


export const dementionify = (htmlString: string, suggestions: any[]) => {// removing anchor tags to properties for saving purposes

  try {

    const patterns = [
      /<a\s+[^>]*class="wysiwyg-mention"[^>]*>.*?<\/a>/gi,
      /<a\s+[^>]*\bhref="[^"]*\/settings\/[^"]*"[^>]*>.*?<\/a>/gi
    ]

    let dementionified = htmlString;
    for (let pattern of patterns) {
      let mentions = RegExp.prototype[Symbol.match].call(new RegExp(pattern), dementionified)
      if (mentions) {
        for (let mention of mentions) {
          let xmlDoc = new DOMParser().parseFromString(mention.replace("data-mention", ""), "text/xml");
          let token = suggestions.find(x => `{${x.value}` == xmlDoc.getElementsByTagName("a")[0].innerHTML);

          if (token) dementionified = dementionified.replace(mention, `{${token.url}`);
        }
      }
    }

    suggestions.forEach(x => {
      dementionified = dementionified.replaceAll(`{${x.value}`, `{${x.url}`);
    });

    return dementionified;
  }
  catch {
    return ""; //return empty string if data is corrupted.
  }
}

export const getMentionTag = (entity: string, display: string) => {
  return `<a href="{${entity}}}" class="wysiwyg-mention" data-mention data-value="{${display}}}">{{${display}}}</a>`;
}

export const toFriendlyName = (str: string) => str.replace(/([A-Z])/g, ' $1').trim();