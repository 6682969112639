import React, { useEffect, useState } from 'react';
import Styles from './DatePicker.module.css';

import {
  Datepicker,
  Dropdown,
  DropdownProps,
  Flex,
  FormLabel,
  Input,
  ProviderConsumer as FluentUIThemeConsumer
} from '@fluentui/react-northstar';
import {
  AUTOMATION_RELATIVE_DATE,
  getDropdownDateModel
} from '../../utils/constants';
import { DatepickerLocalizations } from '../../../App';
import { t } from 'i18next';
import MgtCss from './../../../mgt.module.css';

export type DateInputType = 'value' | 'relative';
interface Props {
  dateValue: string;
  disableValueField: boolean;
  onChangeHandler: (value: string) => void;
  setRelativeDateType?: React.Dispatch<React.SetStateAction<DateInputType>>;
  isTemplateField?: boolean;
  isTemplateFieldError?: boolean;
}

interface DateFieldProps {
  content: string;
  type: boolean;
  onClick: () => void;
}

const DateFieldLabel = ({ content, type, onClick }: DateFieldProps) => (
  <FluentUIThemeConsumer
  render={globalTheme => (
  <FormLabel
    content={content}
    styles={{
      fontSize: '12px',
      color: !type && `${globalTheme.siteVariables.colorScheme.brand.foreground}`,
      marginRight: '5px',
      cursor: 'pointer'
    }}
    onClick={onClick}
  />
  )}></FluentUIThemeConsumer>
);

export const showNumberFieldHandler = (value: string) => ['minutesago-', 'hoursago-', 'daysago-', 'monthsago-', 'yearsago-', 'minutesfromnow-', 
    'hoursfromnow-', 'daysfromnow-', 'monthsfromnow-', 'yearsfromnow-'].includes(value);

export const DatePicker = ({
  dateValue,
  disableValueField,
  onChangeHandler,
  setRelativeDateType,
  isTemplateField,
  isTemplateFieldError
}: Props) => {
  const DROPDOWN_VALUES = isTemplateField ? getDropdownDateModel().filter(x => !['minutesago-', 'hoursago-', 'minutesfromnow-', 'hoursfromnow-'].includes(x.key) ) : getDropdownDateModel();
  const [dateType, setDateType] = useState<DateInputType>('value');
  const [selectedDate, setSelectedDate] = useState<MaybeNull<Date>>(null);
  const [showNumberField, setShowNumberField] = useState(false);
  const [dateDropdownValue, setDateDropdownValue] = useState('');
  const [numberFieldValue, setNumberFieldValue] = useState(0);

  useEffect(() => {
    if (dateValue) {
      const [relative, number] = dateValue.split('-');
      const daysCount = +number;
      const value = `${relative}-`;
      const _showNumberField = typeof value !== 'undefined' && showNumberFieldHandler(value);
      
      if (!_showNumberField) setNumberFieldValue(0);
      setShowNumberField(_showNumberField);

      if (typeof value !== 'undefined' && showNumberFieldHandler(value) && AUTOMATION_RELATIVE_DATE[`${relative}-`]) {
        setDateType('relative');
        setDateDropdownValue(`${relative}-`);
        setNumberFieldValue(daysCount);
      } else if (`${relative}-0` == dateValue) {
        setDateType('relative');
        setDateDropdownValue(`${relative}-0`);
      } else if (!isNaN(Date.parse(dateValue))) {
        setDateType('value');
        setSelectedDate(new Date(dateValue));
      }
    }
  }, [dateValue]);

  useEffect(() => {
    if(setRelativeDateType)
      setRelativeDateType(dateType);
  }, [dateType]);

  const onDropdownChange = (_e, p: DropdownProps) => {
    const data = p.value as DropdownDataModel;
    setDateDropdownValue(showNumberFieldHandler(data.key) ? `${data.key}${numberFieldValue}` : data.key);
    onChangeHandler(showNumberFieldHandler(data.key) ? `${data.key}${numberFieldValue}` : data.key);
  };

  const resetSelection = () => {
    setSelectedDate(null);
    setShowNumberField(false);
    setNumberFieldValue(0);
    setDateDropdownValue('');
    onChangeHandler('');
  }

  return (
    <FluentUIThemeConsumer
    render={globalTheme => (
    <div className={Styles.GroupInput}>
      <Flex className={disableValueField ? MgtCss.disabledContainer : ''}>
        <DateFieldLabel
          content={t('automation.value-field.value')}
          type={dateType === 'value'}
          onClick={() => {
            resetSelection();
            setDateType('value');
          }}
        />
        <DateFieldLabel
          content={t('automation.value-field.relative-date')}
          type={dateType === 'relative'}
          onClick={() => {
            resetSelection();
            setDateType('relative');
          }}
        />
      </Flex>
      {dateType === 'value' ? (
        <Datepicker
          {...DatepickerLocalizations}
          firstDayOfWeek={0}
          styles={{ display: 'flex' }}
          disabled={disableValueField}
          className={Styles.DatePicker}
          popup={{
            styles: {
              width: '100%'
            }
          }}
          input={{
            input: {
              styles: {
                backgroundColor: isTemplateField ? globalTheme.siteVariables.colorScheme.default.background2 : globalTheme.siteVariables.colorScheme.default.background,
                width: '100%'
              }
            },
            error: isTemplateFieldError
          }}
          selectedDate={selectedDate}
          onDateChange={(_e, { value }) => {
            setSelectedDate(value);
            onChangeHandler(value.toString());
          }}
        />
      ) : (
        <>
          <Flex>
            {showNumberField && (
              <Input
                type="number"
                inverted={!isTemplateField}
                value={numberFieldValue.toString()}
                onChange={(_e, data) => {
                  const value = +data.value;
                  if (value >= 0) {
                    setNumberFieldValue(value);
                    onChangeHandler(`${dateDropdownValue}${value}`);
                  }
                }}
                style={{ width: '60px' }}
                input={{ styles: { width: '100%' }, error: isTemplateFieldError }}
                defaultValue="0"
                disabled={disableValueField}
              />
            )}
            <Dropdown
              disabled={disableValueField}
              inverted={!isTemplateField}
              error={isTemplateFieldError}
              fluid
              items={DROPDOWN_VALUES}
              placeholder={t('automation.value-field.select-a-Value')}
              defaultValue={AUTOMATION_RELATIVE_DATE[dateDropdownValue]}
              onChange={onDropdownChange}
              styles={{ flex: '1', backgroundColor: isTemplateField ? globalTheme.siteVariables.colorScheme.default.background2 :'' }}
            />
          </Flex>
        </>
      )}
    </div>
    )}></FluentUIThemeConsumer>
  );
};
