import React from 'react';
import Styles from '../styles/Lifecycle.module.css';
import { FormGroup } from '../LifecycleForm';
import { LIFECYCLE } from '../../shared/utils/constants';
import { TextInputField } from '../../shared/common/TextInputField';
import { LifecycleData } from '../utils/typings';
import { Button, Flex, ProviderConsumer, TextArea } from '@fluentui/react-northstar';
import { LabelElement } from '../../shared/components/TicketForm';
import { FormCheckbox } from '@fluentui/react-northstar';
import { Title } from './../../shared/components/TicketDetailInfo/Title';

interface Props {
  infoData: LifecycleData;
  updateInfoData: (infoData: LifecycleData) => void;
}

export const Information = ({
  infoData,
  infoData: { Title, Description, SetStatusResolved },
  updateInfoData
}: Props) => (
  <ProviderConsumer 
    render={globalTheme => ( 
      <>
        <Flex space={'between'} vAlign={'center'}>
          <Flex.Item>
            <h2 className={Styles.SubHeading2}>{LIFECYCLE.INFORMATION}</h2>
          </Flex.Item>
        </Flex>
        <FormGroup marginClass='mb-3.5'>
          <TextInputField
            label= {LIFECYCLE.TITLE}
            value={Title}
            isRequired
            updateValue={(value: string) => {
              updateInfoData({
                ...infoData,
                Title: value
              });
            }}
          />
        </FormGroup>
        <FormGroup marginClass='mb-1'>
          <LabelElement label={LIFECYCLE.DESCRIPTION} required={false}>
            <TextArea
              styles={{ height: '60px', backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
              fluid
              placeholder={LIFECYCLE.ENTER_DESCRIPTION}
              maxLength={500}
              required
              value={Description}
              onChange={(_event, p) => {
                updateInfoData({
                  ...infoData,
                  Description: p.value
                });
              }}
            />
          </LabelElement>
        </FormGroup>
        <FormGroup marginClass='mb-5'>
          <FormCheckbox
            label={LIFECYCLE.AUTOMATIC_STATUS_CHANGE}
            checked={SetStatusResolved}
            onChange={(_, checked) => {
              updateInfoData({
                ...infoData,
                SetStatusResolved: checked.checked
              });
            }}
          />
        </FormGroup>
      </>
    )}
  />
);
