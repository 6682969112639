import {
  Box,
  Text,
  Button,
  ThemePrepared,
  Divider,
  Flex,
  ICSSInJSStyle,
  MenuIcon,
  Loader
} from '@fluentui/react-northstar';
import { TeamsTheme } from '@fluentui/react-teams/lib/esm/themes';
import { ToolbarTheme } from '@fluentui/react-teams/lib/esm/components/Toolbar/ToolbarTheme';
import { SiteVariablesPrepared } from '@fluentui/styles';
import React, { useState, useEffect }  from 'react';
import { BreadcrumbNavigation, Breadcrumbs } from './BreadcrumbNavigation';
import { TToolbarInteraction } from '@fluentui/react-teams';
import { checkInTeams } from '../../App';
import { getCachedFeature } from '../cache/FeatureCache';
import { PRODUCT_LICENSE } from '../utils/constants';
import { useTranslation } from 'react-i18next';

export interface IToolbarProperties {
  globalTheme: ThemePrepared<any>;
  title: string;
  saveText?: string;
  breadcrumbs?: Breadcrumbs;
  disableSave?: boolean;
  addNew?: boolean;
  addNewContent?: JSX.Element;
  saveCommand?: () => void;
  saveLoading?: boolean;
  saveLoadingText?: string;
  hideSave?: boolean;
  onInteraction?: (interaction: TToolbarInteraction) => void;
  isLifecycleForm?: boolean;
  viewLifecycleCommand?: () => void;
  cancelLifecycleCommand?: () => void;
  overrideLicenseCheck?: boolean;
  extraRightContainerContent?: JSX.Element;
}

export const Toolbar = (props: IToolbarProperties) => {
  const { t } = useTranslation();
  let buttonStyle: ICSSInJSStyle = {
    margin: '.625rem',
    visibility: props.hideSave ? 'hidden' : 'visible'
  };
  const [isUserLicensed, setIsUserLicensed] = useState(false);
  const onMenuToggle = () => {
    if (props.onInteraction) {
      props.onInteraction({
        event: 'click',
        target: 'toolbar',
        subject: 'menu',
        action: 'toggle-menu'
      });
    }
  };
  const fetchData = async () =>{
    setIsUserLicensed(await getCachedFeature(PRODUCT_LICENSE.AnalystAccess));
  }

  useEffect(()=>{
    fetchData();
  });

  return (
    <div style={{ paddingBottom: '-1.25rem', height: '100px' }}>
      <ToolbarTheme globalTheme={props.globalTheme}>
        <Box
          className="extended-toolbar"
          variables={({ colorScheme, theme }: SiteVariablesPrepared) => ({
            backgroundColor:
              theme === TeamsTheme.HighContrast
                ? colorScheme.grey.background
                : colorScheme.default.background2,
            elevation: colorScheme.elevations[16]
          })}
          styles={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0',
            alignItems: 'center'
          }}
        >
          <Box className="extended-toolbar__far-side">
            <Flex vAlign="center">
              {checkInTeams() && (
                <div className={`md:hidden`}>
                  <Button
                    onClick={onMenuToggle}
                    iconOnly
                    content={<MenuIcon size="large" color="brand" />}
                    title="menu"
                  />
                </div>
              )}
              {props.breadcrumbs && (
                <>
                  <div className={`hidden md:flex`}>
                    <BreadcrumbNavigation {...props.breadcrumbs} />
                  </div>
                  <Text
                    size="medium"
                    weight="semibold"
                    className={`md:hidden px-2`}
                    style={{ lineHeight: '52px' }}
                    content={
                      props.breadcrumbs.breadcrumbs[
                        props.breadcrumbs.breadcrumbs.length - 1
                      ].title
                    }
                  />
                </>
              )}
              {!props.breadcrumbs && (
                <Text
                  content={props.title}
                  weight="semibold"
                  styles={{
                    margin: '0 0.625rem',
                    alignItems: 'center',
                    display: 'inline-flex'
                  }}
                />
              )}
              {props.addNew && (
                <>
                  <Divider vertical style={{ height: '48px' }} />
                  {props.addNewContent && <>{props.addNewContent}</>}
                </>
              )}
            </Flex>
          </Box>
          <Box>
            <div
              className={`hidden md:flex items-center`}
              style={{
                margin: '.625rem',
                visibility: props.saveCommand ? 'visible' : 'hidden'
              }}
            >
            
              {!props.saveLoading && props.extraRightContainerContent && (
                <>
                  {props.extraRightContainerContent}
                </>
              )}
              {props.saveLoading && (
                <Loader
                  label={
                    props.saveLoadingText ? props.saveLoadingText : 'Saving'
                  }
                  labelPosition="end"
                  className={`mr-2`}
                  size="smallest"
                />
              )}
              <Flex vAlign={'center'} gap={'gap.smaller'}>
                {
                  props.isLifecycleForm && <>
                    {props.viewLifecycleCommand && (
                    <Flex.Item>
                      <span className={`cursor-pointer`} 
                      style={{color: props.globalTheme.siteVariables.colorScheme.brand.foreground}} 
                      title={`View Life Cycle`} onClick={props.viewLifecycleCommand}>View Life Cycle</span>
                    </Flex.Item>
                    )}
                    <Flex.Item>
                      <Button onClick={props.cancelLifecycleCommand}>{t('common.buttons.cancel')}</Button>
                    </Flex.Item>
                  </>
                }
                <Flex.Item>
                  <Button
                    content={props.saveText}
                    disabled={props.disableSave || props.saveLoading || (!isUserLicensed && !props.overrideLicenseCheck)}
                    primary
                    onClick={_e => {
                      props.saveCommand();
                    }}
                  />
                </Flex.Item>
              </Flex>

            </div>
          </Box>
        </Box>
      </ToolbarTheme>
    </div>
  );
};
