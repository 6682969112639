import React, { useEffect, useState } from "react";
import { TextArea, Text, ProviderConsumer as FluentUIThemeConsumer, Flex } from "@fluentui/react-northstar";
import { PeoplePicker, PersonType, UserType } from '@microsoft/mgt-react';
import { Toggle } from "@fluentui/react";
import { PlatformUser } from "../../interfaces/platformuser.interface";
import { ThemeColorScheme } from "../../common/TeamsTheme";
import { themeNames } from '@fluentui/react-teams';
import { useTranslation } from 'react-i18next';
import { appState } from "../../../AppState";
interface Props{
    ticketApprovalData: Approval,
    setTicketApprovalData: (ticketApprovalData: Approval) => void;
    setApproversList:(value: PlatformUser[]) => void;
}
export const LabelElement = (props: any) => {
  return (
    <div>
      <Flex>
        <Text content={props.label} style={{ display: 'block' }} />
        {props.required && (
          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
        )}
      </Flex>
      <div>{props.children}</div>
    </div>
  );
};
export const ApprovalsForm = ({ticketApprovalData,setTicketApprovalData,setApproversList}:Props) => {
  const {t} = useTranslation();
    const [switchRecipents, setswitchRecipents] = useState<boolean>(ticketApprovalData.IsRequiredByAll ?? false);
    const [isPickerLoaded, setIsPickerLoaded] = useState(false);
    const [title, setTitle] = useState<string>();
    const [additionalDetails, setAdditionalDetails] = useState<string>();
    const currentState = appState();

    useEffect(()=>{
      setTitle(ticketApprovalData.Title);
      setswitchRecipents(ticketApprovalData.IsRequiredByAll ?? false);
      setAdditionalDetails(ticketApprovalData.AdditionalDetails);
    },[]);

    useEffect(()=>{
      setTicketApprovalData({
        ...ticketApprovalData,
        Title: title,
        IsRequiredByAll: switchRecipents,
        AdditionalDetails: additionalDetails
      });
    },[switchRecipents, title, additionalDetails]);

    useEffect(()=>{
      if(!isPickerLoaded){

        const picker: any = document.querySelector('mgt-people-picker');
        if(picker){
          let users = ticketApprovalData.Approvers.map(o => currentState.platformusers.find(i => i.Id == o.Approver.Id)) 
          picker.selectedPeople = users.map(item => {
            return {
              displayName: item?.FullName,
              userPrincipalName: item?.UserName,
              id: item?.AadObjectId
            }
          });
          
          setApproversList(users);
          setIsPickerLoaded(true);
        }
      }
    },[isPickerLoaded]);

    const setPlatformUser = (user: any): PlatformUser => {
        return {
          FullName: user.displayName,
          GivenName: user.givenName,
          FamilyName: user.surname,
          Email: user.userPrincipalName,
          UserName: user.userPrincipalName,
          AadObjectId: user.id
        } as PlatformUser;
      };

    const setApprovers = async event => {
        if (event.detail.length > 0) {
          let users = event.detail.map(i =>{
            return setPlatformUser(i);
          })
          setApproversList(users);
        } else{
          setApproversList([])
        }
      }
    return (
      <FluentUIThemeConsumer
      render={globalTheme => (
        
        <div
          className="min-h-32"
          style={{
            color: 'var(--mgt-theme-default-foreground)',
            ...ThemeColorScheme(globalTheme.siteVariables)
          }}
        >
            <div >
                <LabelElement label={t('ticket-details.edit-ticket-form.approval.dialog.form.title')} required={true}>
                    <TextArea
                        maxLength={500}
                        required
                        fluid
                      onChange={(_e, p) => {
                        setTitle(p.value)
                      }}
                      value = {title}
                    />
                </LabelElement>
            </div>
            <div>
                <LabelElement label={t('ticket-details.edit-ticket-form.approval.dialog.form.approvers')} required={true} >
                    <PeoplePicker className={`width-med height-small`}
                    userType={UserType.user} 
                    type={PersonType.person}
                    selectionChanged={setApprovers}
                    />
                </LabelElement>
                <Toggle
                    className="pt-2"
                    styles={{ label: { color:`${globalTheme.siteVariables.theme === themeNames.Dark ? 'white' : 'black'}` }}}
                    label={t('ticket-details.edit-ticket-form.approval.dialog.form.response-requires')}
                    {...(switchRecipents && {
                        defaultChecked: true,
                        style: {
                            background: '#6264a7',
                            opacity: 1
                        }
                    })}
                    inlineLabel
                    onChange={(
                        _ev: React.MouseEvent<HTMLElement>,
                        checked?: boolean
                      ) => {
                       setswitchRecipents(checked);
                      }}
                />
                <div className="pt-1">               
                <LabelElement label={t('ticket-details.edit-ticket-form.approval.dialog.form.additional-details')} >
                    <TextArea
                        style={{ height: 100 }}
                        fluid
                        onChange={(_e, p) => {
                            setAdditionalDetails(p.value);
                          }}
                        value = {additionalDetails}
                    />
                </LabelElement>
                </div>
            </div>
            </div>)} />
    ) 
};