import React, { useCallback, useEffect, useState } from 'react';
import Styles from './Security.module.css';
import { Button, Dialog, Flex, Header, ProviderConsumer } from '@fluentui/react-northstar';
import { Breadcrumbs } from '../shared/components/BreadcrumbNavigation';
import { SettingsPageWrapper } from '../shared/components/SettingsPageWrapper';
import {
  BUTTONS,
  CONTACT_ADMIN,
  DEFAULT_API_STATUS,
  DONE,
  ERROR,
  LOADING,
  REFRESH_SESSION,
  SECURITY,
  SETTINGS,
  SOMETHING_WENT_WRONG,
  UNAUTHORIZED_ACCESS
} from '../shared/utils/constants';
import { toTitleCase } from '../shared/utils/helper';
import { MappedRolesItem } from './MappedRolesItem/MappedRolesItem';
import { SecurityModal } from './SecurityModal/SecurityModal';
import { SecurityLeftPanel } from './SecurityLeftPanel';
import { platformService } from '../shared/services/platform.service';
import { Communication } from '@fluentui/react-teams';
import { Providers } from '@microsoft/mgt-element/dist/es6/providers/Providers';
import { EmptyData } from '../shared/components/EmptyData';
import EmptySVG from '../../svg/empty.svg';
import { MobileSettingsView, transformModalContent } from '../automation/AutomationTable';
import { CheckLogin } from '../shared/components/CheckLogin';
import { Helmet } from 'react-helmet';
import { LoadingScreen } from '../shared/components/LoadingScreen';
import { Toolbar } from '../shared/components/Toolbar';
import ConfigCSS from '../config/config.module.css';
import { useTranslation } from 'react-i18next';
interface Props {
  securityKey: string;
}

export const applicationRolesList = ['administrators', 'analysts', 'agents', 'knowledgeagents'];

export const Security = ({ securityKey }: Props) => {
  const {t} = useTranslation();
  const api = new platformService();
  const [apiStatus, setAPIStatus] = useState<APIStatus>(
    DEFAULT_API_STATUS as APIStatus
  );

  const [dialog, setDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');

  const [currentRole, setCurrentRole] = useState('administrators');
  const [applicationRoles, setApplicationRoles] = useState<
    Record<string, string[]>
  >({});

  const fetchApplicationRoles = useCallback(async () => {
    setAPIStatus({
      status: LOADING,
      msg: SECURITY.FETCHING_ROLES,
      errCode: 0
    });
    try {
      const {
        data: { ApplicationRoleDetails }
      } = await api.getApplicationRoles();
      const applicationRolesData: Record<string, string[]> = {};
      ApplicationRoleDetails.forEach(item => {
        if (applicationRolesList.includes(item.RoleName.toLowerCase()) && item.IsSystem===true)
          applicationRolesData[item.RoleName.toLowerCase()] = [
            ...item.MappedProviderRoles
          ];
      });

      setApplicationRoles(applicationRolesData);
      setAPIStatus({ ...apiStatus, status: DONE });
    } catch (error: any) {
      setAPIStatus({
        ...apiStatus,
        status: ERROR,
        errCode: error.response.status
      });
    }
  }, []);

  const loadPage = () => {
    fetchApplicationRoles();
  }

  useEffect(() => {
    setCurrentRole(securityKey);
  }, [securityKey]);

  const navs: Breadcrumbs = {
    breadcrumbs: [
      {
        title: toTitleCase(SETTINGS),
        link: '/settings'
      },
      {
        title: `${toTitleCase(SECURITY.SECURITY)}`,
        link: '/settings/security'
      },
      {
        title: `${t(`security.roles.${securityKey}`)}`,
        link: ''
      }
    ]
  };

  const errConfig = {
    fields: {
      title:
        (apiStatus.errCode === 403 || apiStatus.errCode === 401)
          ? UNAUTHORIZED_ACCESS
          : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  };

  const deleteMappedRole = async (role: string) => {
    const payload: MappedRolePayload = {
      ProviderRole: role,
      ApplicationRole: toTitleCase(currentRole)
    };

    let status = 0;

    setAPIStatus({
      status: LOADING,
      msg: t('security.deleting-mapped-role'),
      errCode: 0
    });
    try {
      await api.deleteMappedRole(payload);
      ({ status } = await api.batchSetPermission());
    } catch (err: any) {
      setAPIStatus({
        ...apiStatus,
        status: ERROR,
        errCode: err.response.status
      });
    }

    if (status === 200) {
      setDeleteDialog(false);
      fetchApplicationRoles();
    }
  };

  const createMappedRole = async (data: MappedRolePayload[]) => {
    setAPIStatus({
      status: LOADING,
      msg: SECURITY.CREATING_ROLES,
      errCode: 0
    });
    let status = 0;
    ({ status } = await api.createMappedRole(data));

    if (status === 200) {
      fetchApplicationRoles();
      setDialog(false);
    }
  };

  return (
    <CheckLogin onSignedIn={loadPage}>
    <Helmet>
      <title>{toTitleCase(SECURITY.SECURITY)} - Tikit</title>
    </Helmet>
      <div className="hidden md:block">
        {
          apiStatus.status === LOADING ? (
            <LoadingScreen message={apiStatus.msg} />
          ) : apiStatus.status === 'error' ? (
            <ProviderConsumer render={(globalTheme) => (
              <>
                <Toolbar globalTheme={globalTheme} title="Settings > Security" hideSave={true} breadcrumbs={navs}/>
                <Flex className={ConfigCSS.configBody}>
                  <Flex.Item grow>
                    <>
                      <Communication {...errConfig} />
                      {apiStatus.errCode === 401 && (<Button content={REFRESH_SESSION} primary onClick={e => { Providers.globalProvider.login(); }} />)}
                    </>
                  </Flex.Item>
                </Flex>
              </>)} />) : 
              (<>
              <SettingsPageWrapper
                title={toTitleCase(SECURITY.MAPPED_ROLE)}
                routeName={SECURITY.SECURITY}
                breadcrumbs={navs}
                queryId={0}
                mode="list"
                saveData={() => {
                  setDialog(true);
                }}
                isDisabled
                NavbarTopPadding="0"
                newButtonHandler={() => {
                  setDialog(true);
                }}
              >
              <Helmet>
                <title>{t(`security.roles.${currentRole}`)} - Tikit</title>
              </Helmet>
                <div className="flex h-full">
                  {/* Left pane */}
                  <SecurityLeftPanel
                    activeRole={currentRole}
                    applicationRoles={applicationRoles}
                  />

                  {/* Listing */}
                  <div className={Styles.SecurityRightSide}>
                    <Header
                      as="h4"
                      content={SECURITY.MAPPED_ROLES}
                      className="pl-7 mb-3 capitalize font-normal"
                    />

                    {applicationRoles[securityKey].length > 0 ? (
                      applicationRoles[securityKey].map((mappedRole: string) => (
                        <MappedRolesItem
                          title={mappedRole}
                          deleteHandler={() => {
                            setDeleteDialog(true);
                            setSelectedItem(mappedRole);
                          }}
                          key={mappedRole}
                        />
                      ))
                    ) : (
                      <Flex
                        vAlign="center"
                        hAlign="center"
                        style={{ height: 'calc(100% - 10px)' }}
                      >
                        <EmptyData
                          headerText={t('security.there-are-no-security', {securityKey: t(`security.roles.${securityKey}`)})}
                          subheaderText={t('security.get-started-by-clicking')}
                          SVGIcon={<EmptySVG width={200} height={200} />}
                        />
                      </Flex>
                    )}
                  </div>
                </div>
              </SettingsPageWrapper>

              <SecurityModal
                apiState={apiStatus}
                applicationRoles={applicationRoles}
                open={dialog}
                closeHandler={() => {
                  setDialog(false);
                }}
                confirmationHandler={createMappedRole}
              />
              <Dialog
                open={deleteDialog}
                onCancel={() => setDeleteDialog(false)}
                cancelButton={toTitleCase(BUTTONS.CANCEL)}
                confirmButton={toTitleCase(BUTTONS.DELETE)}
                onConfirm={() => {
                  deleteMappedRole(selectedItem);
                }}
                content={transformModalContent`${t('security.delete-security', {selectedItem: selectedItem})}`}
                header={t('security.header', {
                  selectedItem: selectedItem,
                  currentRole: t(`security.roles.${currentRole}`) 
                })}
                style={{ maxWidth: '500px' }}
              />
            </>
          )
        }
      </div>
      <MobileSettingsView />
    </CheckLogin>
  )
};
