import React from 'react';
import {
  Box,
  CalendarIcon,
  Card,
  Flex,
  Text,
  SiteVariablesPrepared
} from '@fluentui/react-northstar';
import { Col, Row } from 'react-grid-system';
import TicketSVG from '../../../svg/ticket.svg';
import PriorityIcon from '../../../svg/priority-icon.svg';
import { ConvertDateToLocale } from '../common/ConvertDate';
import RelativeDate from './RelativeDate';
import { UserAvatar } from './UserAvatar';
import { BoardItemPreview } from '../../../fluent-ui/components/Board/BoardItem';
import { IBoardItemCardLayout } from '@fluentui/react-teams';
import { CSS_CLASS, TASK_STATUS_GUID, STATUS_GUID, POWERAUTOMATE_TASK_STATUS_GUID } from '../utils/constants';
import { getCategoryValue, getPriorityValue, getStatus } from '../../tikit/ticketHelper';
import { appState } from '../../AppState';
import { TicketProps } from '../interfaces/ticketproperties.interface';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import CategoryIcon from '../../../svg/category.svg';
import { GroupUsersIcon } from '../../tikit/Request/EditRequestDetails/GroupUsersCard/GroupUsersIcon';

interface TicketBodyProps {
  title?: string;
  assignee: any;
  collaborators: { PlatformUserId: number }[] | null;
  dueDate: string | null;
  priority: string;
  category: string;
  subtitle: string;
}
interface RequesterProps {
  email: string;
  username: string;
  createdDate: string;
  preview: string;
  boardItemCardLayout: IBoardItemCardLayout;
}

export const TicketStatus = ({ status, postion }: { status: TicketProps | StatusDetail; postion?: string }) => {
  const flexPosition = postion != 'start' ? 'end': 'start';
  return (
    <Flex hAlign={flexPosition} className={'cui-pt-4 cui-pv-4'}>
      <div
        className={`${!status || status.Guid === STATUS_GUID.ACTIVE ? CSS_CLASS.STATUS_PILL_ACTIVE : 
                      status.Guid === STATUS_GUID.RESOLVED          ? CSS_CLASS.STATUS_PILL_RESOLVED : 
                      status.Guid === STATUS_GUID.DEFLECTED         ? CSS_CLASS.STATUS_PILL_CLOSED : 
                      status.Guid === STATUS_GUID.CLOSED            ? CSS_CLASS.STATUS_PILL_CLOSED : 
                                                                      CSS_CLASS.STATUS_PILL_OTHERS
        }`}
      >
        <Text weight="bold" size="smaller">
          {status?.Value}
        </Text>
      </div>
    </Flex>
  );
};

export const TicketTaskStatus = ({ status, postion }: { status: TaskStatus; postion?: string }) => {
  
  const findPillClass = (Guid: string) => {
    switch (Guid) {
      case TASK_STATUS_GUID.IN_PROGRESS:
        return CSS_CLASS.STATUS_PILL_ACTIVE;
      case TASK_STATUS_GUID.COMPLETED:
      case POWERAUTOMATE_TASK_STATUS_GUID.SUCCEEDED:
        return CSS_CLASS.STATUS_PILL_CLOSED;
      case TASK_STATUS_GUID.FAILED:
      case POWERAUTOMATE_TASK_STATUS_GUID.FAILED:
        return CSS_CLASS.STATUS_PILL_ERROR;
      case TASK_STATUS_GUID.SKIPPED:
        return CSS_CLASS.STATUS_PILL_SKIPPED;
      default:
        return CSS_CLASS.STATUS_PILL_OTHERS;
    }
  }
  
  const flexPosition = postion != 'start' ? 'end': 'start';
  
  return (
    <Flex hAlign={flexPosition} className={'cui-pt-4 cui-pv-4'}>
      <div
        className={`${findPillClass(status.Guid)}`}
      >
        <Text weight="bold" size="smaller">
          {status.Value}
        </Text>
      </div>
    </Flex>
  );
};

const TicketFooter = ({ modifiedDate }: { modifiedDate: string }) => {
  const {t} = useTranslation();
  return (
    <div>
      <Flex>
        <Box
          styles={{
            flex: '1 0 auto',
            display: 'flex'
          }}
        >
          <div
            style={{
              marginTop: '8px',
              marginBottom: '8px',
              marginRight: '16px',
              marginLeft: '16px',
              width: '100%'
            }}
          >
            <Row>
              <Col sm={11}>
                <Flex vAlign="center">
                  <CalendarIcon style={{ color: '#8f90a6' }} />
                  <Text
                    size="small"
                    style={{ color: '#8f90a6', marginLeft: '8px' }}
                  >
                    {t('my-work.my-ticket.ticket-template.card.card-footer.modified')}:&nbsp;
                  </Text>
                  <Text size="small" style={{ marginLeft: '8px' }}>
                    <RelativeDate date={modifiedDate} />
                  </Text>
                </Flex>
              </Col>
            </Row>
          </div>
        </Box>
      </Flex>
    </div>
  );
};

const TicketBody = ({
  title,
  assignee,
  collaborators,
  dueDate,
  priority,
  category,
  subtitle
}: TicketBodyProps) => {
  const { t } = useTranslation();
  const currentState = appState();
  const collabs = [];
  if (assignee)
    collabs.push({ PlatformUserId: assignee.Id });
  if (collaborators && collaborators.length > 0) 
    collabs.push(...collaborators);

  if (!assignee && collabs.length > 0)
    assignee = currentState.platformusers.find(u => u.Id == collabs[0].PlatformUserId);

  return (
    <>
      <Row>
        <Col>
          <Text weight="semibold" size="medium">{title}</Text>
          {subtitle && (<Text size="small" weight="bold" variables={({ colorScheme }: SiteVariablesPrepared) => ({ color: colorScheme.foreground1 })}>{subtitle}</Text>)}
        </Col>
      </Row>
      <Row style={{ marginTop: '16px' }}>
        <Col>
          {collabs.length === 1 && (
            <>
              <UserAvatar {...{ idOrUpn: assignee.AadObjectId || assignee.UserName, avatarProps: { name: assignee.FullName, size: 'small' } } } />
              <Text size="small" className={`ml-1`}>{assignee.FullName}</Text>
            </>
          )}
          {collabs.length > 1 && (<GroupUsersIcon usersList={collabs} isListing={true} maxLength={3} />)}
        </Col>
        <Col>
          <CalendarIcon size="small" style={{ color: '#8f90a6' }} />
          <Text size="small">
            <span style={{ color: '#8f90a6' }} className={`ml-1`}>
              {t('my-work.my-ticket.ticket-template.card.card-body.due-date')}:{' '}
            </span>
            <span className={`ml-1`}>
              {dueDate === null && <>{t('my-work.my-ticket.ticket-template.card.card-body.none')}</>}
              {dueDate !== null && <>{ConvertDateToLocale(dueDate, DateTime.DATE_SHORT)}</>}
            </span>
          </Text>
        </Col>
      </Row>
      <Row style={{ paddingBottom: '8px' }}>
        <Col className={`flex truncate`}>
          <Flex vAlign='center'>
            <PriorityIcon width={10} height={10} />
            <Text size="small" className={`flex`}>
              <span style={{ color: '#8f90a6' }} className={`ml-1`}>
                {t('my-work.my-ticket.ticket-template.card.card-body.priority')}:{' '}
              </span>
              <span className={`ml-1`}>{priority}</span>
            </Text>
          </Flex>
        </Col>
        <Col>
          <Flex vAlign='center'>
            <CategoryIcon width={12} height={12} stroke={'#8f90a6'} />
            <Text size="small">
              <span style={{ color: '#8f90a6' }} className={`ml-1`}>
                {t('my-work.my-ticket.ticket-template.card.card-body.category')}:{' '}
              </span>
              <span className={`ml-1`}>{category}</span>
            </Text>
          </Flex>
        </Col>
      </Row>
    </>
  );
};

const TicketRequester = ({
  email,
  username,
  createdDate,
  preview,
  boardItemCardLayout
}: RequesterProps) => {
  return (
    <Col>
      {email && username && (
        <Flex vAlign="center">
          <Box>
            <UserAvatar
              {...{
                idOrUpn: email,
                avatarProps: {
                  name: username,
                  size: 'small',
                }
              }}
            />
          </Box>
          <Box className={`ml-2`}>
            <Text weight="semibold" size="small">
              {username}
            </Text>
            <Text size="smaller">
              <RelativeDate date={createdDate} />
            </Text>
          </Box>
        </Flex>
      )}
      {preview && boardItemCardLayout.previewPosition === 'top' && (
        <BoardItemPreview preview={preview} />
      )}
    </Col>
  );
};

export const TicketHeaderNumber = ({ id }: { id: number }) => {
  return (
    <Flex vAlign="center">
      <TicketSVG width={24} />
      <Text weight="semibold" className={`ml-1`}>
        #{id < 10 && <>0</>}
        {id < 100 && <>0</>}
        {id}
      </Text>
    </Flex>
  );
};

const TicketCardTemplate = ({
  ticket,
  boardItemCardLayout,
  from,
}: TicketCardTemplate) => {
  const currentState = appState();

  const {
    Id,
    StatusId,
    ModifiedDate,
    DueDate,
    PriorityId,
    CategoryId,
    Title,
    AssigneeId,
    RequesterId,
    CreatedDate,
    preview,
    subtitle,
    AffectedUsers,
    TicketCollaborators
  } = ticket;
  const {
    platformusers,
    ticketStatus,
    priority,
    categories
  } = currentState;

  const Assignee = platformusers.find(u => u.Id === AssigneeId);
  const Requester = platformusers.find(u => u.Id === RequesterId);

  return (
    <>
    <a {...(from !== 'mywork' && {href: `/tickets/${Id}`})}>
      <Row
        style={{
          padding: '16px 16px 0 16px'
        }}
      >
        <Col>
          <TicketHeaderNumber id={Id} />
        </Col>
        <Col>
          <TicketStatus status={getStatus(StatusId, ticketStatus)} />
        </Col>
      </Row>
      <Row style={{ paddingLeft: '16px', paddingRight: '16px', paddingTop: '8px' }}>
        
      {AffectedUsers?.length > 0 && (
        <Col>
          <Flex vAlign="center" column>
            <div>
              <GroupUsersIcon usersList={[{ PlatformUserId: RequesterId }, ...AffectedUsers]} maxLength={11} isListing={true} /> 
            </div>
            <Text size="smaller" weight="regular">
              <RelativeDate date={CreatedDate} />
            </Text>
            {preview && boardItemCardLayout.previewPosition === 'top' && (<BoardItemPreview preview={preview} />)}
          </Flex>
        </Col>
      )}
      {(!AffectedUsers || AffectedUsers.length === 0) && (
        <TicketRequester email={Requester?.AadObjectId ?? Requester.UserName} username={`${Requester?.FullName}`} createdDate={CreatedDate} preview={preview} boardItemCardLayout={boardItemCardLayout} />
      )}


        
      </Row>
      <Card.Body
        {...(!preview || boardItemCardLayout?.previewPosition !== 'top'
          ? { styles: { marginTop: '1.25rem' } }
          : {})}
      >
        <TicketBody
          title={Title}
          subtitle={subtitle || ''}
          assignee={Assignee}
          collaborators={TicketCollaborators}
          dueDate={DueDate}
          priority={getPriorityValue(PriorityId, priority)}
          category={getCategoryValue(CategoryId, categories)}
        />
      </Card.Body>
      <Card.Footer style={{ margin: '0px', padding: '0px 0px 8px 0px' }}>
        <TicketFooter modifiedDate={ModifiedDate} />
      </Card.Footer>
      </a>
    </>
  );
};

export default TicketCardTemplate;
