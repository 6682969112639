import { Alert, Button, Card, Flex, FlexItem, Loader } from "@fluentui/react-northstar";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { PlatformUser } from "../../interfaces/platformuser.interface";
import { platformService } from "../../services/platform.service";
import * as microsoftTeams from "@microsoft/teams-js";
import { ApprovalsForm } from "./ApprovalsForm";
import { TicketApprovalState ,LICENSE_FEATURE} from "../../utils/constants";
import { CheckFeature } from "../CheckFeature";
import { useTranslation } from "react-i18next";

export const AddApproval = () => {
  const api = new platformService();
  const { t } = useTranslation();
  const { ticketId } = useParams<{
    ticketId: string
  }>() || {};
  const initialApprovalsData: Approval = {
    Id: 0,
    AdditionalDetails: '',
    Title: '',
    IsRequiredByAll: null,
    Approvers: [],
    TicketApprovals: [],
    ApprovalState: TicketApprovalState.Pending
  }
  const CloseTaskModule = () => {
    microsoftTeams.dialog.url.submit();
  };

  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const Loading = () => {
    return <Loader label={t('ticket-details.edit-ticket-form.approval.saving-approval')} className="top-1/2 absolute w-full" />;
  };
  const [ticketApprovalData, setTicketApprovalData] = useState<Approval>(initialApprovalsData);
  const [approversList, setApproversList] = useState<PlatformUser[]>([]);
  const [alert, setAlertOnRequiredFields] = useState({
    title: '',
    show: false,
    attributes: {}
  });

  const validateRequired = () => {
    setAlertOnRequiredFields({ title: '', show: false, attributes: {} });
    if (ticketApprovalData.Title === '' || approversList.length == 0) {
      setAlertOnRequiredFields({
        title: t('ticket-details.edit-ticket-view.required-field-must-be-filled-out'),
        show: true,
        attributes: { danger: true }
      });
      return true;
    }
    return false;
  };

  const primaryClick = async () => {
    if (validateRequired()) return;
    try {
      setIsSubmit(true);
      if (approversList != null) {
        const results = await Promise.all(approversList.map(async user =>
          api.getOrCreatePlatformUser(user))
        )
        ticketApprovalData.ApprovalState = TicketApprovalState.Pending;
        results.forEach(result => {
          ticketApprovalData.Approvers.push({
            ApprovalId: ticketApprovalData.Id,
            ApproverId: result.Id,
            HasApproved: 3,
            Id: 0
          });
        });
      }

      ticketApprovalData.TicketApprovals.push({
        Id: 0,
        TicketId: parseInt(ticketId),
        ApprovalId: ticketApprovalData.Id
      })
      await api.addTicketApproval(ticketApprovalData);
      setApproversList([]);
      ticketApprovalData.Approvers = [];
      ticketApprovalData.TicketApprovals = [];
      CloseTaskModule();
    }
    catch (e) {
      console.error(e);
      setApproversList([]);
      CloseTaskModule();
    }
  }

  return (
    <CheckFeature featureName={LICENSE_FEATURE.Approvals}>
    <div className="h-screen" >
      <Card fluid className="h-screen">
        {isSubmit ? (
          <Loading />
        ) : (
          <>
            <Card.Body>
              {alert.show && (
                <Alert
                  fitted
                  {...alert.attributes}
                  content={alert.title}
                  className="w-full text-xs"
                />
              )}
              <ApprovalsForm
                ticketApprovalData={ticketApprovalData}
                setTicketApprovalData={setTicketApprovalData}
                setApproversList={setApproversList} />
            </Card.Body>
            <Card.Footer>
              <Flex gap="gap.small">
                <FlexItem push>
                  <Button content={t('common.buttons.cancel')} onClick={CloseTaskModule}></Button>
                </FlexItem>
                <Button
                  primary
                  content={t('common.add')}
                  onClick={primaryClick}
                ></Button>
              </Flex>
            </Card.Footer>
          </>
        )}
      </Card>
    </div>
    </CheckFeature>
  );
}

function setAlert(_arg0: { title: string; show: boolean; attributes: {}; }) {
  throw new Error("Function not implemented.");
}
