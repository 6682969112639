import React, { useCallback, useEffect, useState } from "react";
import { Flex, Dialog, CloseIcon, Button, Loader } from "@fluentui/react-northstar";
import { RelationTypeSelection } from "./DialogPages/RelationTypeSelection";
import { TicketsSelection } from "./DialogPages/TicketsSelection";
import { RelateTicketsConfirmation } from "./DialogPages/RelateTicketsConfirmation";
import { IRelateTickets, RelationType } from "./RelateTicketHelper";
import { appState } from '../../../AppState';
import { useTranslation } from 'react-i18next';
import { platformService } from "../../services/platform.service";

interface Props {
  oldRelatedTicketIds: number[];
  sourceTicketId: number;
  open: boolean;
  onCancel: () => void;
  relateTickets: (relateTicketData: IRelateTickets) => void;
}

export interface RelateTicket {
  sourceTicketId: number,
  relatedTicketIds: number[],
  relationShipType: number,
}

const initalRelatedTicketData: RelateTicket = {
  sourceTicketId: 0,
  relatedTicketIds: [],
  relationShipType: 0,
}

export const RelateTicketDialog = ({ oldRelatedTicketIds, sourceTicketId, open, onCancel, relateTickets }: Props) => {
  const {t} = useTranslation();
  const currentState = appState();
  const [menu, setMenu] = useState<number>(0);
  const [relatedTicketData, setRelatedTicketData] = useState<RelateTicket>(initalRelatedTicketData);
  const [filteredTickets, setFilteredTickets] = useState<Ticket[]>([]);
  const [allTickets, setAllTickets] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const RELATION_SELECTION = "relation-selection";
  const TICKETS_SELECTION = "tickets-selection";
  const CONFIRMATION = "confirmation";
  const pages = [RELATION_SELECTION, TICKETS_SELECTION, CONFIRMATION];
  const api = new platformService();

  useEffect(() => {
    const fetchData = async () => {
      const {
        data: { value }
      } = await api.getTicketList();
      setAllTickets(value);
      setRelatedTicketData({ ...initalRelatedTicketData, sourceTicketId });
      setIsLoaded(true);
    }

    if (open) {
      setIsLoaded(false);
      fetchData();
    }
  }, [open]);

  const filterTickets = (relationShipType: number) => {
    let _allTickets = allTickets.sort((a, b) => (b.Id - a.Id));
    let filteredTicketsList: Ticket[] = [];
    switch (relationShipType) {
      case RelationType.MergeTickets: {
        _allTickets.forEach(item => item.ticketApprovals = currentState.ticketApprovals?.find(x => x.TicketId == item.Id) || null);
        filteredTicketsList = _allTickets.filter((ticket: Ticket) => !ticket.Closed && (ticket.ticketApprovals?.length ?? 0) === 0 && (ticket.TicketTasks?.length ?? 0) === 0);
        break;
      }
      case RelationType.RelateTickets: {
        const checkedTickets = _allTickets.filter(t => oldRelatedTicketIds.includes(t.Id));
        const unCheckedTickets = _allTickets.filter(t => !oldRelatedTicketIds.includes(t.Id));
        filteredTicketsList = [...checkedTickets, ...unCheckedTickets];
        break;
      }
      default: break;
    }
    setFilteredTickets(filteredTicketsList.filter((ticket: Ticket) => ticket.Id !== sourceTicketId));
  }

  const closeDialog = () => {
    setMenu(pages.indexOf(RELATION_SELECTION));
    onCancel();
    setRelatedTicketData(initalRelatedTicketData);
  }

  const onRelationTypeSelection = useCallback((relationShipType: number) => {
    filterTickets(relationShipType);
    let relatedTicketIds = relatedTicketData.relatedTicketIds;
    if (relationShipType === RelationType.MergeTickets) relatedTicketIds = [];
    if (relationShipType === RelationType.RelateTickets) relatedTicketIds = oldRelatedTicketIds;
    setRelatedTicketData({ ...relatedTicketData, relationShipType, relatedTicketIds });
    setMenu(pages.indexOf(TICKETS_SELECTION));
  }, [relatedTicketData, menu, filteredTickets]);

  const primaryClick = useCallback(async () => {
    let nextIndex = menu + 1;
    if (nextIndex < pages.length) {
      setMenu(nextIndex);
    }
    else {
      saveData();
      closeDialog();
    }
  }, [menu, relatedTicketData]);

  const secondaryClick = useCallback(() => {
    let nextIndex = menu - 1;
    if (nextIndex >= 0) setMenu(nextIndex);
    else closeDialog();
  }, [open, menu, relatedTicketData]);

  const saveData = async () => {
    relateTickets({
      SourceTicketId: relatedTicketData.sourceTicketId,
      RelatedTicketIds: relatedTicketData.relatedTicketIds,
      RelationshipType: relatedTicketData.relationShipType
    });
  }

  const isNoChangeInSelection = () => {
    return menu === pages.indexOf(TICKETS_SELECTION) &&
    ((relatedTicketData.relationShipType === RelationType.MergeTickets && relatedTicketData.relatedTicketIds.length === 0) ||
    (relatedTicketData.relationShipType === RelationType.RelateTickets && getIdsString(oldRelatedTicketIds) === getIdsString(relatedTicketData.relatedTicketIds)));
  }

  const getIdsString = (ticketIds: number[]) => [...ticketIds].sort((a, b) => (a - b)).join('~');

  const renderPages = () => {
    switch (pages[menu]) {
      case RELATION_SELECTION:
        return <RelationTypeSelection onRelationTypeSelection={onRelationTypeSelection} />
      case TICKETS_SELECTION:
        return <TicketsSelection relatedTicketData={relatedTicketData} setRelatedTicketData={setRelatedTicketData} tickets={filteredTickets} />
      case CONFIRMATION:
        return <RelateTicketsConfirmation relatedTicketData={relatedTicketData} oldRelatedTicketIds={oldRelatedTicketIds} />
      default:
        return (<></>)
    }
  }

  return (
    <Dialog className="relate-ticket-dialog"
      content={
        <>
          {isLoaded && renderPages()}
          {isLoaded && relatedTicketData.relationShipType === RelationType.MergeTickets && menu === 1 &&
              <Flex.Item>
                <div style={{ marginLeft: 'unset', marginTop: '5px' }}>
                  <span className='font-bold'>{t('ticket-details.edit-ticket-form.relate-ticket.dialog.footer.note')}: </span>
                  <span>{t('ticket-details.edit-ticket-form.relate-ticket.dialog.footer.ticket-approval')}</span>
                </div>
              </Flex.Item>
            }
          {!isLoaded && <div className={`pt-5 mt-5`}><Loader /></div>}
        </>
      }
      open={open}
      headerAction={{
        icon: <CloseIcon />,
        title: t('ticket-details.edit-ticket-form.relate-ticket.dialog.button.close'),
        onClick: closeDialog
      }}
      footer={
        <Flex gap="gap.small">
          {menu > 0 && <>
            <Flex.Item push>
              <Button content={t('ticket-details.edit-ticket-form.relate-ticket.dialog.button.back')} onClick={secondaryClick} />
            </Flex.Item>
            <Button className="dialog-btn"
              disabled={isNoChangeInSelection()}
              content={menu + 1 < pages.length ? t('ticket-details.edit-ticket-form.relate-ticket.dialog.button.continue') : t('ticket-details.edit-ticket-form.relate-ticket.dialog.button.confirm')}
              primary onClick={primaryClick}
            />
          </>}
        </Flex>
      }
    />
  )
}