import React, { useState, useEffect } from 'react';
import Styles from '../styles/Lifecycle.module.css';
import { ThemePrepared } from '@fluentui/react-northstar';
import { PhaseTransitionSection } from './PhaseTransitionSection';
import { KeyValuePair, LifecycleTransition, LifecyclePhase, LifecycleTask, LifecycleApproval, RelationshipData, LifecyclePowerAutomateTask } from '../utils/typings';
import { PlatformUser } from '../../shared/interfaces/platformuser.interface';
import { createDropdownDataModel } from '../utils/constants';
import { PhaseTaskSection } from './PhaseTaskSection';
import { PhaseApprovalSection } from './PhaseApprovalSection';
import { LifecycleAccordion } from './shared/Accordion/LifecycleAccordion';
import { PhaseDetails } from './PhaseDetails';
import { ThemeColorScheme } from '../../shared/common/TeamsTheme';
import { useTranslation } from 'react-i18next';
import { LICENSE_FEATURE, LIFECYCLE } from '../../../components/shared/utils/constants';
import { PhasePowerAutomateTaskSection } from './PhasePowerAutomateTaskSection'
import { CheckFeature } from '../../shared/components/CheckFeature';

interface Props {
  phaseData: LifecyclePhase;
  phaseIndex: number;
  updatePhaseData: (editData: KeyValuePair[], index: number) => void;
  globalTheme: ThemePrepared<any>;
  phasesOptions: DropdownDataModel[];
  userList: PlatformUser[];
  taskStatusList: TaskStatus[];
  clickOnExpand: (index: number, isExpand: boolean) => void;
  allPhasesData?: LifecyclePhase[];
  handleScroll?: (movePhaseGuid: any) => void;
}

export const Phase = ({
  phaseData,
  phaseData: { Id, Guid, Name, Tasks, Approvals, Transitions, PowerAutomateTasks },
  phaseIndex,
  updatePhaseData,
  globalTheme,
  phasesOptions,
  userList,
  taskStatusList,
  clickOnExpand,
  allPhasesData,
  handleScroll
}: Props) => {

  const { t } = useTranslation();
  
  const [tasksOptions, setTasksOptions] = useState<DropdownDataModel[]>([]);
  const [approvalsOptions, setApprovalsOptions] = useState<DropdownDataModel[]>([]);
  const [powerautomateOptions, setPowerAutomateOptions] = useState<DropdownDataModel[]>([]);

  const [reloadOptions, setReloadOptions] = useState(true);
  
  useEffect(() => {
    if(reloadOptions){
      setApprovalsOptions(createDropdownDataModel(Approvals));
      setTasksOptions(createDropdownDataModel(Tasks));
      setPowerAutomateOptions(createDropdownDataModel(PowerAutomateTasks));
      setReloadOptions(false);
    }
  }, [reloadOptions]);


  const updateTasksData = (item: LifecycleTask[]) => {
    const pair: KeyValuePair = {
        key: "Tasks",
        value: [...item]
    }
    setTasksOptions([...createDropdownDataModel(item)]);
    updatePhaseData([pair], phaseIndex);
  }

  const updateApprovalsData = (item: LifecycleApproval[]) => {
    const pair: KeyValuePair = {
        key: "Approvals",
        value: [...item]
    }
    setApprovalsOptions([...createDropdownDataModel(item)]);
    updatePhaseData([pair], phaseIndex);
  }

  const updatePowerAutomateTasksData = (item: LifecyclePowerAutomateTask[]) => {
    const pair: KeyValuePair = {
        key: "PowerAutomateTasks",
        value: [...item]
    }
    setPowerAutomateOptions([...createDropdownDataModel(item)]);
    updatePhaseData([pair], phaseIndex);
  }

  const updateTransitionsData = (item: LifecycleTransition[]) => {
    const pair: KeyValuePair = {
        key: "Transitions",
        value: [...item]
    }

    updatePhaseData([pair], phaseIndex);
  }

  const transitionDataUpdateOrRemove = (editData: KeyValuePair[], index: number) => {
    editData.forEach(pair => {
      if(pair.key == "RemoveItem")
        Transitions.splice(index, 1);
      else
        Transitions[index][pair.key] = pair.value;
    });    
    updateTransitionsData([...Transitions]);
  }

  const removeItem = () => {
    const pair: KeyValuePair = {
      key: "RemoveItem",
      value: phaseIndex
    } 
    updatePhaseData([pair], phaseIndex);
  }

  const onChangeTextField = (value: string) => {
    const pair: KeyValuePair = {
        key: "Name",
        value: value
    }
    updatePhaseData([pair], phaseIndex);
  }

  const onBlurTextField = (value: string) => {
    if(value.length == 0){
      const pair: KeyValuePair = {
        key: "Name",
        value: `${t('lifecycle.new-phase')} ${(phaseIndex + 1)}`
      }
      updatePhaseData([pair], phaseIndex);
    }
  }

  return (
    <>
      <LifecycleAccordion
        isAccordionOpen={phaseData.IsExpand}
        globalTheme={globalTheme}
        displayName={Name}
        deleteTitle={LIFECYCLE.DELETE_PHASE}
        deleteMessage={t('lifecycle.delete-phase-message', { phaseName: Name })}
        removeItem={removeItem}
        onChangeTextField={onChangeTextField}
        onBlurTextField={onBlurTextField}
        clickOnExpand={clickOnExpand}
        index={phaseIndex}
        phaseId={phaseData.Id}
        deleteWarningMessage={t('lifecycle.delete-phase-message', { phaseName: Name })}
      />
      {phaseData.IsExpand && (
          <div className={`${Styles['phaseForm']}`} style={{margin: '5px', ...ThemeColorScheme(globalTheme.siteVariables)}}>
              <div className={`w-full`}>
                <PhaseTaskSection 
                  data={Tasks}
                  updateData={updateTasksData}
                  globalTheme={globalTheme}
                  phaseData={{Id: Id, Guid: Guid} as RelationshipData}
                  userList={userList}
                  showHeading={Tasks.length > 0 || Approvals.length > 0 || PowerAutomateTasks.length > 0}
                />
              </div>
              <div className={`w-full`}>
                <PhaseApprovalSection 
                  data={Approvals}
                  updateData={updateApprovalsData}
                  globalTheme={globalTheme}
                  phaseData={{Id: Id, Guid: Guid} as RelationshipData}
                  showHeading={Tasks.length > 0 || Approvals.length > 0 || PowerAutomateTasks.length > 0}
                />
              </div>
              
              <CheckFeature featureName={LICENSE_FEATURE.PowerAutomateTask}>
              <div className={`w-full`}>
                <PhasePowerAutomateTaskSection 
                  data={PowerAutomateTasks}
                  updateData={updatePowerAutomateTasksData}
                  globalTheme={globalTheme}
                  phaseData={{Id: Id, Guid: Guid} as RelationshipData}
                  userList={userList}
                  showHeading={Tasks.length > 0 || Approvals.length > 0 || PowerAutomateTasks.length > 0}
                />
              </div>
              </CheckFeature>

            <PhaseTransitionSection data={Transitions} updateData={updateTransitionsData} globalTheme={globalTheme} 
              phasesOptions={phasesOptions} 
              tasksOptions={tasksOptions}
              approvalsOptions={approvalsOptions}
              phaseData={phaseData}
              taskStatusList={taskStatusList}
              phaseIndex={phaseIndex}
              updateTransitionDataOrRemove={transitionDataUpdateOrRemove}
              powerautomateOptions = {powerautomateOptions}
              />
          </div>
      )}
      {!phaseData.IsExpand && Transitions.length > 0 && (
        <>
          <PhaseDetails phaseData={phaseData} allPhasesData={allPhasesData} phaseIndex={phaseIndex}
            clickOnPhaseName={clickOnExpand} handleScroll={handleScroll}
          />
        </>
      )}
    </>
  );

  
};
