import React, { useEffect, useState } from 'react';
import Styles from './AutomationGroup.module.css';

import { AddIcon } from '@fluentui/react-icons-northstar';
import { Button, Flex, ProviderConsumer } from '@fluentui/react-northstar';
import DeleteIcon from '../../../../../svg/delete.svg';
import { SwitchButton } from '../../../../shared/components/SwitchButton/SwitchButton';
import { AutomationFilter } from '../AutomationFilter/AutomationFilter';
import { useTranslation } from 'react-i18next';

interface Props {
  propertiesList: DropdownDataModel[];
  groupData: AutomationGroup | AutomationChildrenGroup;
  groupIndex?: number;
  updateGroupFilters: (groupIndex: number, data: AutomationFilter[]) => void;
  updateGroupType: (groupIndex: number, type: GroupType) => void;
  deleteChildGroup?: (index: number) => void;
  addNewFilter: (groupIndex: number) => void;
  removeFilter: (groupIndex: number, filterIndex: number) => void;
  whenData: AutomationWhenData;
  section: 'if' | 'do';
}

export const AutomationGroup = ({
  propertiesList,
  groupData,
  groupIndex,
  updateGroupFilters,
  updateGroupType,
  deleteChildGroup,
  addNewFilter,
  removeFilter,
  whenData,
  section
}: Props) => {
  const {t} = useTranslation();
  const [filtersData, setFiltersData] = useState<AutomationFilter[]>(
    groupData.filters
  );

  React.useEffect(() => {
    setFiltersData(groupData.filters);
  }, [groupData]);

  const updateFilterData = (
    groupIndex: number,
    filterIndex: number,
    data: AutomationFilter
  ) => {
    const filtersDataClone = [...filtersData];
    filtersDataClone.splice(filterIndex, 1, { ...data });
    setFiltersData(filtersDataClone);

    updateGroupFilters(groupIndex, filtersDataClone);
  };

  return (
    <ProviderConsumer
      render={globalTheme => (
        <>
          {groupIndex !== undefined && (
            <h2 className={Styles.SubHeading2} style={{ fontWeight: 'bold' }}>
              {t('automation.automation-group.and')}
            </h2>
          )}

          <Flex space="between" styles={{ margin: '1.5rem 0' }}>
            <SwitchButton
              type={groupData.type}
              changeGroupType={newGroupType => {
                updateGroupType(groupIndex, newGroupType);
              }}
              globalTheme={globalTheme}
            />
            <Flex vAlign="center">
              <Button
                icon={
                  <AddIcon
                    styles={{
                      color:
                        globalTheme.siteVariables.colorScheme.brand.foreground
                    }}
                  />
                }
                text
                content={
                  <span
                    style={{
                      color:
                        globalTheme.siteVariables.colorScheme.brand.foreground
                    }}
                  >
                    {t('automation.automation-group.add-filter')}
                  </span>
                }
                onClick={() => {
                  addNewFilter(groupIndex);
                }}
              />
              {groupIndex !== undefined && (
                <span
                  className={Styles.DeleteBtnContainer}
                  onClick={() => {
                    deleteChildGroup(groupIndex);
                  }}
                >
                  <DeleteIcon stroke={`${globalTheme.siteVariables.colorScheme.brand.foreground}`}  />
                </span>
              )}
            </Flex>
          </Flex>

          {React.Children.toArray(
            filtersData.map((filterData: AutomationFilter, index: number) => (
              <div className={Styles.Groups}>
                <AutomationFilter
                  propertiesList={propertiesList}
                  filterData={filterData}
                  filterIndex={index}
                  groupIndex={groupIndex}
                  updateFilterData={updateFilterData}
                  removeFilter={removeFilter}
                  section={section}
                  whenData={whenData}
                />
              </div>
            ))
          )}
        </>
      )}
    />
  );
};
