import React from 'react';
import { Text } from '@fluentui/react-northstar';
interface Props {
  headerText: string;
  subheaderText?: string;
  SVGIcon: JSX.Element;
  style?: React.CSSProperties;
  Extras?: JSX.Element;
}

export const EmptyData = (props: Props) => {
  return (
    <div
      className={'flex flex-col justify-around h-full w-full'}
      style={props.style}
    >
      <div className="align-center flex justify-around">
        <div className="flex flex-col items-center">
          {props.SVGIcon}
          <Text size="large" weight="bold" content={props.headerText} />
          <Text size="medium" weight="regular" content={props.subheaderText} />
          {props.Extras && (
            props.Extras
          )}
        </div>
      </div>
    </div>
  );
};
