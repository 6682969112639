import React, { useEffect, useRef } from "react";
import * as AdaptiveCards from "adaptivecards";
import * as markdownit from "markdown-it";
import { Card, Flex, ProviderConsumer as FluentUIThemeConsumer, CardProps, } from "@fluentui/react-northstar";
import { blankPayloadData } from "./AdaptiveCardDesigner";
import { Input, Action } from "adaptivecards";
import { mergeStyleSets } from "@fluentui/react";
import { themeNames } from "@fluentui/react-teams";
import 'github-markdown-css';

const classNames = mergeStyleSets({
    rootContainer: {
        '.ac-pushButton': {
            marginTop: "1rem",
            background: 'var(--mgt-theme-brand-background)',
            color: 'white',
            fontWeight: 400,
            borderColor: "transparent",
            borderRadius: "4px",
            ':hover, :active, :focus': {
              background: 'var(--mgt-theme-background-brand-hover) !important'
            }
        },
        '.ac-pushButton-white': {
            background: 'var(--mgt-theme-background)',
            color: 'var(--mgt-theme-brand-foreground)',
            fontWeight: 400,
            borderColor: "var(--mgt-theme-border-color)",
            borderRadius: "4px",
            ':hover, :active, :focus': {
                background: 'var(--mgt-theme-background-hover) !important'
            }
        },
        '.ac-input.ac-textInput, .ac-input.ac-numberInput, .ac-input.ac-dateInput, .ac-input.ac-timeInput, .ac-input.ac-multichoiceInput': {
            ':focus, :focus-visible': {
                outlineColor: "#d1d1d1 !important",
            },
        },
        '.ac-richTextBlock > span': {
            color: 'var(--mgt-theme-foreground) !important'
        },
        '.ac-textBlock': {
            color: "var(--mgt-theme-foreground) !important"
        },
    }
});

const dropDownClassesDefault = mergeStyleSets({
  rootContainer: {
    '.ac-input.ac-multichoiceInput.ac-choiceSetInput-compact': {
      background: 'white !important',
      color: 'black !important'
    }
  }
});

const dropDownClassesDark = mergeStyleSets({
  rootContainer: {
    '.ac-input.ac-multichoiceInput.ac-choiceSetInput-compact, .ac-input.ac-choiceSetInput-expanded, .ac-input.ac-choiceSetInput-multiSelect': {
      background: 'rgb(35, 35, 35) !important',
      color: 'white !important'
    },
    '.ac-input.ac-textInput, .ac-input.ac-numberInput, .ac-input.ac-dateInput, .ac-input.ac-timeInput, .ac-input.ac-multichoiceInput': {
      background: 'var(--mgt-theme-background) !important',
      color: 'var(--mgt-theme-foreground) !important'
  },
  }
});

interface Props{
    jsonString: string, 
    cardProps?: CardProps,
    containerClassName?: string,
    clear?:boolean,
    onLoad?:()=>void;
    onInputValueChanged?: (input: Input) => void;
    onExecuteAction?: (action: Action) => void;
}

export const AdaptiveCardElement = (props: Props) => {
    
    const { jsonString, cardProps, containerClassName, onLoad, onInputValueChanged, onExecuteAction, clear } = props;
    const ref = useRef<HTMLDivElement>(null);
    const getAdaptiveCard = (cardJson: any) => {
        AdaptiveCards.AdaptiveCard.onProcessMarkdown = (text, result) => {
            result.outputHtml = new markdownit().render(text);
            result.didProcess = true;
        }

        // Create an AdaptiveCard instance
        var adaptiveCard = new AdaptiveCards.AdaptiveCard();

        // Set its hostConfig property unless you want to use the default Host Config
        // Host Config defines the style and behavior of a card
        adaptiveCard.hostConfig = new AdaptiveCards.HostConfig({
            fontFamily: "Segoe UI, Helvetica Neue, sans-serif"
            // More host config options
        });

        // Parse the card payload
        adaptiveCard.parse(cardJson);

        adaptiveCard.onInputValueChanged = (input: Input) => {
            onInputValueChanged && onInputValueChanged(input);
        }

        adaptiveCard.onExecuteAction = (action: Action) => {
            onExecuteAction && onExecuteAction(action);
        }

        // Render the card to an HTML element:
        var renderedCard = adaptiveCard.render();

        return renderedCard;
    }

    useEffect(() => {
        if (ref.current) {
            const cardJson = (jsonString?.length > 0 ?? false) ? JSON.parse(jsonString) : blankPayloadData;
            
            if (clear!=undefined && clear) ref.current.innerHTML="";
            ref.current.appendChild(getAdaptiveCard(cardJson));

            if (onLoad) onLoad();
        }
    }, [ref,jsonString]);

    return (
        <FluentUIThemeConsumer
        render={(globalTheme) => {
        return (
          <>
            <Card
              fluid
              elevated
              style={{
                padding: 0,
                borderTop: `2px solid ${globalTheme.siteVariables.colorScheme.brand.foreground}`
              }}
              {...cardProps}
            >
              <Card.Body fitted>
                <Flex column gap="gap.small">
                  <div
                    ref={ref}
                    className={`${containerClassName} ${
                      classNames.rootContainer
                    } ${
                      globalTheme.siteVariables.theme === themeNames.Dark
                        ? dropDownClassesDark.rootContainer
                        : dropDownClassesDefault.rootContainer
                    }`}
                  ></div>
                </Flex>
              </Card.Body>
            </Card>
          </>
        );}}
        />
    )
}