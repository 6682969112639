import Autolinker from "autolinker";
import React from "react";
import { mergeStyleSets } from "@fluentui/react";

export const TransformToLink = ({ text }: { text: string }) => {

    const classNames = mergeStyleSets({
        userLink: {
          color: 'var(--mgt-theme-brand-foreground1)',
          ':hover, :active, :focus': {
            color: 'var(--mgt-theme-brand-foreground1) !important'
          }
        }
      });
    
    const link = text

    return (
        <span dangerouslySetInnerHTML={{ __html: link }} ></span>
    )
}
  