import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ToolbarTheme } from '@fluentui/react-teams/lib/esm/components/Toolbar/ToolbarTheme';
import {
  AddIcon,
  Box,
  Button,
  Checkbox,
  CloseIcon,
  Divider,
  Flex,
  Input,
  SearchIcon,
  Text
} from '@fluentui/react-northstar';
import { Toggle, mergeStyleSets, FontIcon } from '@fluentui/react';
import AppCSS from '../Tikit.module.css';
import { themeNames } from '@fluentui/react-teams';
import ColumnsSVG from './../../../svg/columns.svg';
import { useTranslation } from 'react-i18next';

const classNames = mergeStyleSets({
  msToggleBtn: {
    marginBottom: '0 !important'
  }
});

interface Props {
  globalTheme: any;
  initialFilterValue: boolean;
  changeFilter: (checked?: boolean) => void;
  searchTickets: (searchKey: string) => void;
  onClickRefresh: () => void;
  onClickRequest: () => void;
  onChangeColumn: (config: number) => void;
  hasApprovals: boolean;
  column: number;
}

export const RequestToolbar = ({
  globalTheme,
  initialFilterValue,
  changeFilter,
  searchTickets,
  onClickRefresh,
  onClickRequest,
  onChangeColumn,
  hasApprovals,
  column
}: Props) => {

  const ref = useRef<any>(null);
  const {t} = useTranslation();
  const [searchText, setSearchText] = useState('');
  const [showClosed, setShowClosed] = useState(initialFilterValue);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [columnState, dispatch] = React.useReducer(
    (state, action) => {
      return { ...state, ...action }
    },
    {
      approvals: true,
      requests: true,
    },
  );

  const isSelectAll =
    Object.values(columnState).every(Boolean) ||
    (Object.values(columnState).some(Boolean) ? 'mixed' : false)
  const colorScheme = globalTheme.siteVariables.colorScheme;

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth > 768) {
        setIsSearchOpen(false);
      }
    });
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu

      if (isMenuOpen && ref.current && !ref.current?.contains(e.target)) {
        setIsMenuOpen(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isMenuOpen])


  useEffect(useCallback(() => {
    dispatch({
      requests: column === 0 || column === 2,
      approvals: column === 0 || column === 1,
    });
    console.log(column)
    console.log("requests:",column === 0 || column === 2 )
    console.log("approvals:",column === 0 || column === 1 )
    onChangeColumn(column);
  }, [column, onChangeColumn]), [column])

  const changeSearchHandler = (e: React.SyntheticEvent<HTMLElement, Event>) => {
    const searchKey = (e.target as HTMLInputElement).value ?? '';
    setSearchText(searchKey);
    searchTickets(searchKey);
  };

  return (
    <ToolbarTheme globalTheme={globalTheme}>
      <div className={`md:block`}>
        <Flex vAlign="center" styles={{ height: '48px' }}>
          {isSearchOpen ? (
            <div className="block md:hidden w-full h-full">
              <Input
                clearable
                fluid
                autoFocus
                className={`${AppCSS.searchInput} w-full flex`}
                value={searchText}
                onChange={(e: React.SyntheticEvent<HTMLElement, Event>) => {
                  changeSearchHandler(e);
                }}
                icon={
                  <CloseIcon
                    onClick={() => {
                      setIsSearchOpen(false);
                    }}
                  />
                }
                inverted
                placeholder={t('requests.filter-by-ticket-title')}
              />
            </div>
          ) : (
            <>
              <Flex.Item grow>
                <Box>
                  <Flex vAlign="center">
                    <Text
                      content={t('requests.tickets')}
                      weight="bold"
                      styles={{
                        marginLeft: '.75rem',
                        marginRight: '.5rem',
                        alignItems: 'center',
                        display: 'inline-flex',
                        height: '48px'
                      }}
                    />
                    <Divider
                      vertical
                      color="gray"
                      style={{ height: '40px', marginTop: '4px' }}
                    /> 
                    <div className={`hidden md:flex`}>
                      <Button primary text icon={<AddIcon size="small" />} title={t('requests.add-request')} content={t('requests.add-request')} onClick={() => { onClickRequest() }} style={{ color: colorScheme.brand.foreground }} />
                      <Button primary text icon={<FontIcon iconName={'Refresh'} />} title={t('requests.refresh')} content={t('requests.refresh')} onClick={() => { onClickRefresh() }} style={{ color: colorScheme.brand.foreground }} />
                    </div>
                    <div className={`flex md:hidden`}>
                      <Button icon={<AddIcon size="small" />} text iconOnly  title={t('requests.add-request')} onClick={() => { onClickRequest() }} style={{ color: colorScheme.brand.foreground }}/>
                      <Button icon={<FontIcon iconName={'Refresh'} />} text iconOnly title={t('requests.refresh')} onClick={() => { onClickRefresh() }} style={{ color: colorScheme.brand.foreground }} />
                    </div>
                    <Flex.Item className={`md:hidden`} grow>
                      <div>&nbsp;</div>
                    </Flex.Item>
                  </Flex>
                </Box>
              </Flex.Item>
              <Flex.Item>
                <Flex vAlign="center" gap="gap.medium" space="between">
                  {hasApprovals }
                  { hasApprovals ? (
                    <Flex.Item>
                      <div ref={ref} className='hidden lg:flex'>
                        <Flex vAlign="center" gap="gap.small" className={`relative`}>
                          <a onClick={() => setIsMenuOpen(true)} className="flex items-center cursor-pointer">
                            <div className={'mr-2'} >
                              <ColumnsSVG />
                            </div>
                            <Text content={`Column Configuration`} weight={'semibold'} />
                          </a>

                          <div className={`absolute rounded-md filter drop-shadow-md ${isMenuOpen ? '' : 'hidden'}`} style={{ width: "100%", backgroundColor: "white", "top": "100%", "left": "0px" }}>

                            <div className={'block w-full p-1'}>
                              <Checkbox
                                checked={isSelectAll}
                                aria-controls="select all columns"
                                onChange={(e, { checked }) => {
                                  dispatch({
                                    requests: true,
                                    approvals: true,
                                  });
                                  onChangeColumn(0);
                                }}
                                label="Select All"
                                id="all"
                              />
                            </div>
                            <div className={'block w-full p-1 border-t'}>
                              <Checkbox
                                checked={columnState.approvals}
                                aria-controls="select approvals"
                                onChange={(e, { checked }) => {
                                  dispatch({
                                    approvals: checked,
                                  });
                                  if (columnState.approvals) {
                                    if (columnState.requests) {
                                      onChangeColumn(2);
                                    }
                                  } else {
                                    if (columnState.requests) {
                                      onChangeColumn(0);
                                    } else {
                                      onChangeColumn(1);
                                    }
                                  }
                                }}
                                label="Approvals"
                                id="all"
                              />
                            </div>
                            <div className={'block w-full p-1 border-t'}>
                              <Checkbox
                                checked={columnState.requests}
                                aria-controls="select requests"
                                onChange={(e, { checked }) => {
                                  dispatch({
                                    requests: checked,
                                  });
                                  if (columnState.requests) {
                                    if (columnState.approvals) {
                                      onChangeColumn(1);
                                    }
                                  } else {
                                    if (columnState.approvals) {
                                      onChangeColumn(0);
                                    } else {
                                      onChangeColumn(2);
                                    }
                                  }
                                }}
                                label="Requests"
                                id="all"
                              />
                            </div>
                          </div>
                        </Flex>
                      </div>
                    </Flex.Item>
                  ) : ''}
                  <Flex.Item>
                    <Flex vAlign="center" gap="gap.small">
                      <Toggle
                        label={t('requests.show-closed')}
                        styles={{
                          label: {
                            color: `${globalTheme.siteVariables.theme ===
                              themeNames.Dark
                              ? 'white'
                              : 'black'
                              }`
                          }
                        }}
                        inlineLabel
                        onChange={(
                          _ev: React.MouseEvent<HTMLElement>,
                          checked?: boolean
                        ) => {
                          setShowClosed(checked);
                          changeFilter(checked);
                        }}
                       
                        {...(showClosed && {
                          defaultChecked: true,
                          style: { background: colorScheme.brand.foreground1, opacity: 1
                          }
                        })}
                        className={`${classNames.msToggleBtn}`}
                      />
                    </Flex>
                  </Flex.Item>
                  <Divider
                    vertical
                    color="gray"
                    style={{ height: '40px', marginTop: '4px' }}
                  />
                  <Flex.Item>
                    <Box>
                      <div className={`hidden md:block`}>
                        <Input
                          inverted
                          clearable
                          placeholder={t('requests.search')}
                          className={'mr-5'}
                          value={searchText}
                          onChange={(
                            e: React.SyntheticEvent<HTMLElement, Event>
                          ) => {
                            changeSearchHandler(e);
                          }}
                          icon={<SearchIcon />}
                        />
                      </div>

                      <div className={`pr-4 md:hidden`}>
                        <SearchIcon
                          size="large"
                          title={t('requests.search-tickets')}
                          styles={{ color: '#424242' }}
                          onClick={() => {
                            setIsSearchOpen(true);
                          }}
                        />
                      </div>
                    </Box>
                  </Flex.Item>
                </Flex>
              </Flex.Item>
            </>
          )}
        </Flex>
      </div>
    </ToolbarTheme>
  );
};
