import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Button, Dialog } from '@fluentui/react-northstar';
import { platformService } from '../shared/services/platform.service';
import { Communication } from '@fluentui/react-teams';
import { Providers } from '@microsoft/mgt-element/dist/es6/providers/Providers';
import { Breadcrumbs } from '../shared/components/BreadcrumbNavigation';
import {
  ARCHIVE_CONFIRMATION_SUBTITLE,
  ARCHIVE_MODAL_HEADER,
  BUTTONS,
  TICKET_TYPE,
  DEFAULT_API_STATUS,
  DONE,
  ERROR,
  LOADING,
  SETTINGS,
  TREE_LISTING_CONSTANTS,
  UNARCHIVE_MODAL_HEADER,
  PRODUCT_LICENSE,
  UNAUTHORIZED_ACCESS,
  SOMETHING_WENT_WRONG,
  CONTACT_ADMIN,
  REFRESH_SESSION
} from '../shared/utils/constants';
import { toTitleCase } from '../shared/utils/helper';
import { Flex } from '@fluentui/react-northstar';
import {
  CTreeItem,
  TreeData,
  TreeWrapper
} from '../shared/components/tree/Tree';
import { useHistory } from 'react-router-dom';
import { ItemId, TreeDestinationPosition } from '@atlaskit/tree';
import { PickersPageWrapper } from '../kb/PickersPageWrapper';
import { TreeListingHeader } from '../shared/components/tree/TreeListingHeader/TreeListingHeader';
import { TicketType } from './TicketType';
import { Helmet } from 'react-helmet';
import { LoadingScreen } from '../shared/components/LoadingScreen';
import EmptySVG from './../../svg/empty.svg';
import { EmptyData } from '../shared/components/EmptyData';
import { UpgradeNeeded } from '../shared/components/UpgradeNeeded';
import { getBodyHeight } from '../shared/common/CommonHelper';
import { checkInTeams } from '../App';
import { MobileSettingsView } from '../automation/AutomationTable';
import { getCachedFeature } from '../shared/cache/FeatureCache';
import { useTranslation } from 'react-i18next';

export const TicketTypeList = () => {
  const {t} = useTranslation();
  const api = new platformService();
  const history = useHistory();
  const [dialog, setDialog] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [newOrEditDialog, setNewOrEditDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [archiveItem, setArchiveItem] =
    useState<MaybeNull<TicketTypeDetail>>(null);
  const [ticketTypeList, setTicketTypeList] = useState<TreeData>({
    rootId: '0',
    items: {
      '0': {
        id: '0',
        children: [],
        hasChildren: true,
        container: true,
        isExpanded: true,
        isChildrenLoading: false,
        data: {
          title: 'root'
        }
      }
    }
  });
  const [apiStatus, setAPIStatus] = useState<APIStatus>(
    DEFAULT_API_STATUS as APIStatus
  );
  
  const [isLicensedUser, setIsLicensedUser] = useState(false);
  const [isAddNewEnabled, setAddNewEnabled] = useState(false);
  const [isDragEnabled, setDragEnabled] = useState(false);
  const [isEditEnabled, setEditEnabled] = useState(false);

  const updateAPIStatus = (newState: Partial<APIStatus>) => {
    setAPIStatus({ ...apiStatus, ...newState });
  };

  const sortTreeItems = (items: CTreeItem[]) =>
    Object.values(items).sort((a, b) => a.data.Position - b.data.Position);

  const checkSortedTreeExistItems = (
    items: CTreeItem[],
    rootChild: string[]
  ) => {
    const sortedArray: string[] = [];
    items.forEach(child => {
      if (rootChild.includes(child.id as string)) {
        sortedArray.push(child.id as string);
      }
    });
    return sortedArray;
  };

  const fetchTicketTypeList = useCallback(async () => {
    updateAPIStatus({ msg: TICKET_TYPE.GETTING_ALL_TICKET_TYPES });
    setArchiveItem(null);

    try {
      const permissionToEdit = JSON.parse(
        sessionStorage.getItem('my_application_roles')
      );
      const permissionToEditFiltered = permissionToEdit.filter(
        roles =>
          roles['TypeName'] ==
          'ServiceDesk.Core.Models.Pickers.Ticket.TicketType'
      );

      permissionToEditFiltered.find(p => {
        if (p.Permissions.includes('POST') || p.Permissions.includes('PUT')) {
          setAddNewEnabled(true);
          setDragEnabled(true);
          setEditEnabled(true);
          return true;
        }
      });

      const {
        data: { value }
      } = await api.getTicketTypes(`?v=${new Date().getTime()}`);
      const treeItems: CTreeItem[] = getTreeItems(value);
      const sortedArr = sortTreeItems(treeItems);
      const rootChilds = getRootChilds(treeItems);
      const sortedRootChilds = checkSortedTreeExistItems(sortedArr, rootChilds);

      setTicketTypeList({
        ...ticketTypeList,
        // @ts-ignore
        items: {
          ...ticketTypeList.items,
          '0': {
            id: '0',
            children: [...sortedRootChilds],
            hasChildren: true,
            container: true,
            isExpanded: true,
            isChildrenLoading: false,
            data: {
              title: 'root'
            }
          },
          ...treeItems
        }
      });
      setIsLicensedUser(await getCachedFeature(PRODUCT_LICENSE.AnalystAccess));
      updateAPIStatus({ status: DONE });
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    }
  }, []);

  useEffect(() => {
    fetchTicketTypeList();
  }, []);

  const navs: Breadcrumbs = {
    breadcrumbs: [
      {
        title: toTitleCase(SETTINGS),
        link: '/settings'
      },
      {
        title: toTitleCase(TICKET_TYPE.TICKET_TYPES),
        link: ''
      }
    ]
  };

  const getTreeItems = useCallback(array => {
    const items = array.reduce((acc, item) => {
      const child = getChildsOfTree(array, item.Id);
      const sortedChild = child
        .sort((a, b) => a.position - b.position)
        .map(item => item.id.toString());
      return {
        ...acc,
        [item.Id.toString()]: {
          id: item.Id.toString(),
          children: [...sortedChild],
          hasChildren: child.length > 0,
          container: true,
          isExpanded: child.length > 0,
          isChildrenLoading: false,
          data: {
            title: item.Value,
            ...item
          }
        }
      };
    }, {} as CTreeItem);
    return items;
  }, []);

  const getChildsOfTree: (
    items: CTreeItem[],
    id: number | string
  ) => { id: number; position: number }[] = useCallback(
    (items: CTreeItem[], id: number | string) => {
      const child = [];
      for (let i = 0; i < items.length; i++) {
        const element: CTreeItem['data'] = items[i + 1];
        if (element?.ParentId === id) {
          child.push({ id: element.Id.toString(), position: element.Position });
        }
      }
      return child;
    },
    []
  );

  const getRootChilds: (items: CTreeItem[]) => string[] = useCallback(
    (items: CTreeItem[]) => {
      const getChildOfRoot: string[] = [];

      Object.keys(items).forEach((key: string) => {
        getChildOfRoot.push(...items[key].children);
      });

      return Object.keys(items).filter(
        (val: string) => !getChildOfRoot.includes(val)
      );
    },
    []
  );

  const onDragHandler = async (
    source: ItemId,
    destination: TreeDestinationPosition,
    rootChildren
  ) => {
    let data: UpdateOnDrag = {
      Id: '',
      ParentId: '',
      Position: 0
    };

    const positionOfItem =
      ticketTypeList.items[destination.parentId.toString()].data.Position;
    let updatedRootChildren: UpdatePickerPositionPayload[] = [];
    updatedRootChildren = rootChildren.map((child, index) => ({
      '@odata.type': '#ServiceDesk.Core.Models.Pickers.Ticket.TicketType',
      Id: child,
      Position: index + 1
    }));

    if (destination.parentId === '0') {
      data = {
        ...data,
        Id: source,
        ParentId: null
      };
    } else {
      data = {
        ...data,
        Id: source,
        ParentId: destination?.parentId,
        Position: positionOfItem
      };
    }
    try {
      await api.updateTicketType(data);
      await api.updatePickerPositions('TicketType', updatedRootChildren);
    } catch (error: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: error.response.status
      });
    }
  };

  const onConfirmArchiveHandler = async () => {
    try {
      setDialog(false);
      updateAPIStatus({
        msg: TICKET_TYPE.UPDATING_TICKET_TYPE,
        status: LOADING
      });
      await api.updateTicketType({
        IsArchived: !archiveItem.IsArchived,
        IsDefault: false,
        Id: archiveItem.Id.toString()
      });
      fetchTicketTypeList();
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    }
  };

  const errConfig = {
    fields: {
      title:
        apiStatus.errCode === 401
          ? UNAUTHORIZED_ACCESS
          : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  };

  const renderContent = (_apiStatus: APIStatus) => {
    if (_apiStatus.status === LOADING) {
      return <LoadingScreen message={_apiStatus.msg} />;
    }

    return _apiStatus.status === ERROR ? (
      <>
        {_apiStatus.errCode !== 402 && <Communication {...errConfig} />}
        {_apiStatus.errCode === 401 && (
          <Button
            content={REFRESH_SESSION}
            primary
            onClick={() => {
              Providers.globalProvider.login();
            }}
          />
        )}
        {_apiStatus.errCode === 402 && (
          <UpgradeNeeded
            toolbarText={`${toTitleCase(SETTINGS)} > ${toTitleCase(
              TICKET_TYPE.TICKET_TYPE
            )}`}
            breadcrumbs={navs}
            headerText={t('ticket-types.you-do-not-have-access')}
            subheaderText={t('ticket-types.please-upgrade-your-plan')}
          />
        )}
      </>
    ) : (
      <>
        <PickersPageWrapper
          title={toTitleCase(TICKET_TYPE.TICKET_TYPE)}
          breadcrumbs={navs}
          items={Object.entries(ticketTypeList.items).length}
          ifEmpty={{
            header: t('ticket-types.there-are-no-Ticket-types'),
            subHeader:
            t('ticket-types.get-started-by-clicking')
          }}
          isAddNewEnabled={isAddNewEnabled}
          onAddClickHandler={() => {
            setNewOrEditDialog(true);
            setArchiveItem(null);
          }}
        >
          <div>
            {Object.entries(ticketTypeList.items).length > 1 ? (
              <div>
                <TreeListingHeader
                  content={toTitleCase(TICKET_TYPE.TICKET_TYPES)}
                />
                <TreeWrapper
                  depth={Object.entries(ticketTypeList.items).length}
                  data={ticketTypeList}
                  isEditEnabled={isEditEnabled}
                  onEditHandler={item => {
                    setArchiveItem(item.data);
                    setNewOrEditDialog(true);
                  }}
                  onArchiveHandler={item => {
                    setArchiveItem(item.data);
                    if (item.data.IsArchived) {
                      setModalHeader(
                        `${UNARCHIVE_MODAL_HEADER} ‘${item.data.title}’?`
                      );
                      setDialogContent(
                        `${ARCHIVE_CONFIRMATION_SUBTITLE} ${item.data.title} ${TICKET_TYPE.TICKET_TYPE}?`
                      );
                    } else {
                      setModalHeader(
                        `${ARCHIVE_MODAL_HEADER} ‘${item.data.title}’?`
                      );
                      setDialogContent(
                        `${ARCHIVE_CONFIRMATION_SUBTITLE} ${item.data.title} ${TICKET_TYPE.TICKET_TYPE}?`
                      );
                    }
                    setDialog(true);
                  }}
                  onDragEndHandler={onDragHandler}
                  isDragEnabled={isDragEnabled}
                  isLicensedUser={isLicensedUser}
                />
              </div>
            ) : (
              <Flex
                style={{
                  marginTop: '-1.25rem',
                  height: getBodyHeight(checkInTeams())
                }}
              >
                <Flex.Item
                  grow
                  styles={{ paddingTop: '1.25rem', overflow: 'auto' }}
                ></Flex.Item>
                <EmptyData
                  headerText={t('ticket-types.there-are-no-Ticket-types')}
                  subheaderText={
                    t('ticket-types.get-started-by-clicking')
                  }
                  SVGIcon={<EmptySVG width={200} height={200} />}
                />
              </Flex>
            )}
          </div>
        </PickersPageWrapper>
        <Dialog
          open={dialog}
          onCancel={() => setDialog(false)}
          cancelButton={toTitleCase(BUTTONS.CANCEL)}
          confirmButton={
            archiveItem?.IsArchived
              ? TREE_LISTING_CONSTANTS.unArchive
              : TREE_LISTING_CONSTANTS.archive
          }
          onConfirm={onConfirmArchiveHandler}
          content={dialogContent}
          header={modalHeader}
          style={{ maxWidth: '500px' }}
        />
        <TicketType
          id={archiveItem?.Id.toString()}
          open={newOrEditDialog}
          onCancel={() => setNewOrEditDialog(false)}
          onSuccessFullCall={fetchTicketTypeList}
        />
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>{t('ticket-types.title')}</title>
      </Helmet>
      <div className="hidden md:block">{renderContent(apiStatus)}</div>
      <MobileSettingsView />
    </>
  );
};
