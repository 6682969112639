import React, { useCallback, useEffect, useState } from 'react';
import { platformService } from '../shared/services/platform.service';
import { SignedInState } from '../shared/services/signInState.service';
import '../AppGlobals';

import { useHistory } from 'react-router-dom';
import {
  DEFAULT_API_STATUS,
  DONE,
  ERROR,
  LOADING,
  STATUS
} from '../shared/utils/constants';
import { Modal } from '../shared/components/Modal/Modal';
import { CheckLogin } from '../shared/components/CheckLogin';

interface Props {
  id: string;
  open: boolean;
  onCancel: () => void;
  onSuccessFullCall: () => void;
  statusData: StatusDetail[];
}

export const Status: React.FC<Props> = ({
  id,
  open,
  onCancel,
  onSuccessFullCall,
  statusData
}) => {
  const api = new platformService();
  const queryId = parseInt(id);

  const history = useHistory();

  const [statusDetails, setStatusDetails] = useState<StatusDetail>();
  const [statusName, setStatusName] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [apiStatus, setAPIStatus] = useState<APIStatus>(
    DEFAULT_API_STATUS as APIStatus
  );

  const updateAPIStatus = (newState: Partial<APIStatus>) => {
    setAPIStatus({ ...apiStatus, ...newState });
  };

  const fetchStatusDetails = useCallback(async () => {
    updateAPIStatus({
      msg: STATUS.FETCHING_STATUS_DETAILS,
      status: LOADING
    });

    try {
      const { data } = await api.getStatus(`(${queryId})`);
      setStatusDetails(data);
      setStatusName(data.Value);
      setIsDefault(data.IsDefault);
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    } finally {
      updateAPIStatus({ status: DONE });
    }
  }, [queryId]);

  const fetchStatusDefaultInstance = useCallback(async () => {
    updateAPIStatus({
      msg: STATUS.FETCHING_STATUS_DEFAULT_INSTANCE,
      status: LOADING
    });

    try {
      const {
        data: { value }
      } = await api.getDefaultInstance(`?v=${new Date().getTime()}`);
      const parsedValue = JSON.parse(value);
      const requiredProps: StatusDetail = (({
        Value,
        Position,
        IsArchived,
        IsDefault,
        IsSystem,
        ParentId,
        Id,
        ModifiedDate,
        ModifiedById,
        CreatedDate,
        CreatedById,
        Guid,
        IsDeleted
      }) => ({
        Value,
        Position,
        IsArchived,
        IsDefault,
        IsSystem,
        ParentId,
        Id,
        ModifiedDate,
        ModifiedById,
        CreatedDate,
        CreatedById,
        Guid,
        IsDeleted
      }))(parsedValue);

      setStatusDetails(requiredProps);
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    } finally {
      updateAPIStatus({ status: DONE });
    }
  }, []);

  const [isCheckedIn, setIsCheckedIn] = useState<boolean>(false);

  const loadPage = () => {
    setIsCheckedIn(true);
  };

  useEffect(() => {
    if (isCheckedIn) {
      if (queryId > 0 && open) fetchStatusDetails();
      else fetchStatusDefaultInstance();
    }
  }, [isCheckedIn, queryId, open]);

  const checkAndUndefaultOther = async (currentDefaultId: number) => {
    try {
      const {
        data: { value }
      } = await api.getStatus(`?v=${new Date().getTime()}`);

      const defaultIdList: number[] = value
        .filter(
          (val: StatusDetail) =>
            val.IsDefault && val.IsDefault && val.Id !== currentDefaultId
        )
        .map((value: StatusDetail) => value.Id);

      if (defaultIdList.length > 0) {
        for (const id of defaultIdList) {
          const payload: UpdateStatusPayload = {
            Id: id.toString(),
            IsDefault: false
          };

          await api.updateStatus(payload);
        }
      }
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    }
    // no finally block to avoid the flicker effect because finally sets the status to done
  };

  const updatePositionAfterCreationOfStatus = async (id: number) => {
    let statusIds = statusData
      .sort((a, b) => a.Position - b.Position)
      .map((status: StatusDetail) => status.Id);
    statusIds.unshift(id);
    const allStatusData = statusIds.map((child, index) => ({
      '@odata.type': '#ServiceDesk.Core.Models.Pickers.Ticket.Status',
      Id: child,
      Position: index + 1
    }));
    return api.updatePickerPositions('Status', allStatusData);
  };

  const createStatus = async (value: string, defaultCheck: boolean) => {
    const payload: StatusDetail = {
      ...statusDetails,
      Value: value,
      IsDefault: defaultCheck
    };

    updateAPIStatus({
      msg: STATUS.CREATING_NEW_STATUS,
      status: LOADING
    });

    try {
      const {
        data: { Id }
      } = await api.createStatus(payload);
      await updatePositionAfterCreationOfStatus(Id);

      if (defaultCheck) {
        await checkAndUndefaultOther(Id);
      }

      onCancel();
      onSuccessFullCall();
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    }
    // no finally block to avoid the flicker effect because finally sets the status to done
  };

  const updateStatus = async (value: string, defaultCheck: boolean) => {
    const payload: UpdateStatusPayload = {
      Value: value,
      Id: statusDetails.Id.toString(),
      IsDefault: defaultCheck
    };
    updateAPIStatus({
      msg: STATUS.UPDATING_STATUS,
      status: LOADING
    });

    try {
      await api.updateStatus(payload);

      if (defaultCheck) {
        await checkAndUndefaultOther(statusDetails.Id);
      }

      onCancel();
      onSuccessFullCall();
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    }
    // no finally block to avoid the flicker effect because finally sets the status to done
  };

  const saveData = async (value: string, defaultCheck: boolean) => {
    if (queryId > 0) updateStatus(value, defaultCheck);
    else createStatus(value, defaultCheck);
  };

  const closeModal = () => {
    setStatusDetails(null);
    setStatusName('');
    setIsDefault(false);

    onCancel();
  };

  return (
    <CheckLogin onSignedIn={loadPage}>
      <Modal
        apiStatus={apiStatus}
        name={STATUS.STATUS}
        open={open}
        isUpdate={queryId > 0}
        inputValue={statusName}
        checkBoxValue={isDefault}
        onCancelHandler={closeModal}
        onConfirmationHandler={(inputValue, checkBoxValue) => {
          setIsDefault(checkBoxValue);
          setStatusName(inputValue);
          saveData(inputValue, checkBoxValue);
        }}
      />
    </CheckLogin>
  );
};
