import React from 'react';
import { Flex, SpeakerPersonIcon } from '@fluentui/react-northstar';
import { UserAvatar } from '../../shared/components/UserAvatar';

interface Props {
  id: number;
  name?: string;
  email: string;
}

export const UserPic = ({ id, name, email }: Props) => {
  return (
    <>
      {id !== null ? (
        <Flex gap="gap.smaller" vAlign="center">
          <UserAvatar
            {...{
              idOrUpn: email,
              avatarProps: {
                name,
                size: 'smaller',
              }
            }}
          />
          
        </Flex>
      ) : (
        <Flex gap="gap.smaller" vAlign="center">
          <SpeakerPersonIcon
            size="large"
            style={{ color: '#8f90a6' }}
            variables={{ largeSize: '24px' }}
          />
          {name && <Flex>{name}</Flex>}
        </Flex>
      )}
    </>
  );
};
