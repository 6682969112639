import React, { useEffect, useState } from "react";
import { PlatformUser } from "../interfaces/platformuser.interface";
import { platformService } from "../services/platform.service";
import { Avatar, Skeleton } from "@fluentui/react-northstar";
import { UserAvatar } from "./UserAvatar";

interface PlatformUserAvatarProps {
  id: number;
  showPresence?: boolean;
}

const loadUserData = async (api: platformService, userId: number): Promise<PlatformUser | null> => {
  try {
    if (userId == null) return { Id: 0, AadObjectId: '', Email: '' } as PlatformUser;

    const getPlatformUser = await api.getPlatformUserByFilter(`Id eq ${userId}`);
    const platfromUserData = await getPlatformUser.data.value[0];
    return platfromUserData;
  } catch (err) {
    return null;
  }
}

export const PlatformUserAvatar = (props: PlatformUserAvatarProps) => {
  const api = new platformService();
  const [platformUser, setPlatformUser] = useState<PlatformUser | null>(null);

  const loadPage = async () => {
    try {
      const userData = await loadUserData(api, props.id);
      setPlatformUser(userData);
    } catch {
      // Ignore
    }
  };

  useEffect(() => { loadPage(); }, []);

  return (
    <>
      {platformUser && platformUser.Id !== 0 &&
        <UserAvatar showPresence={typeof props.showPresence !== "undefined" ? props.showPresence : true} {...{ idOrUpn: platformUser.AadObjectId || platformUser.Email, avatarProps: { name: platformUser.FullName, size: "smaller" } }}></UserAvatar>}
      {platformUser && platformUser.Id === 0 &&
        <Avatar image={`${window.__runtimeConfig.platformurl}Image/workflow.png`} size="smaller" />}
      {(!platformUser) && (
        <Skeleton animation="wave">
          <Skeleton.Avatar size="smaller" />
        </Skeleton>
      )}
    </>
  )
}