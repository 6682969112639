import React, { useEffect, useState } from 'react';
import {
  ProviderConsumer,
  Flex,
  Input,
  SearchIcon,
  Card,
  Text,
  CardHeader,
  RadioGroup,
  Checkbox
} from '@fluentui/react-northstar';
import { ITemplateFormProperties } from './TicketTemplates';
import MgtCss from '../mgt.module.css';
import { TEMPLATE } from '../shared/utils/constants';
import defaultTemplates from '../../data/defaultTemplates.json';
import { TicketTemplate } from '../shared/interfaces/ticket.interface';
import { getUserByUserId } from '../../fluent-ui/components/Board/BoardItem';
import TemplateFieldsCardBody from './TemplateFieldsCardBody';
import {
  getCategoryValue,
  getStatusValue,
  getSupportGroupName,
  getTeamName
} from '../tikit/ticketHelper';
import { useTranslation } from 'react-i18next';

export const mappingTemplateProps = (templateData, formData) => {
  const assignee =
    templateData.AssigneeId && templateData.AssigneeId > 0
      ? getUserByUserId(
          templateData.AssigneeId.toString(),
          formData.platformusers
        )
      : null;
  templateData.AssigneeId = templateData.AssigneeId || null;
  templateData['AssigneeName'] = assignee;
  const status =
    templateData.StatusId && templateData.StatusId > 0
      ? getStatusValue(templateData.StatusId, formData.ticketStatus)
      : null;
  templateData.StatusId = status ? templateData.StatusId : null;
  templateData['StatusName'] = status;
  const group =
    templateData.SupportGroupId && templateData.SupportGroupId > 0
      ? getSupportGroupName(templateData.SupportGroupId, formData.supportgroups)
      : null;
  templateData.SupportGroupId = group ? templateData.SupportGroupId : null;
  templateData['SupportGroupName'] = group;
  const team =
    templateData.TeamId && templateData.TeamId > 0
      ? getTeamName(templateData.TeamId, formData.teams)
      : null;
  templateData.TeamId = team ? templateData.TeamId : null;
  templateData['TeamName'] = team;
  const category =
    templateData.CategoryId && templateData.CategoryId > 0
      ? getCategoryValue(templateData.CategoryId, formData.categories)
      : null;
  templateData.CategoryId = category ? templateData.CategoryId : null;
  templateData['CategoryName'] = category;
  const priority =
    templateData.PriorityId && templateData.PriorityId > 0
      ? formData.priority.find(c => {
          if (templateData.PriorityId == null) {
            return c.Id == 12;
          } else {
            return c.Id == templateData.PriorityId;
          }
        })?.Value
      : null;
  templateData.PriorityId = priority ? templateData.PriorityId : null;
  templateData['PriorityName'] = assignee;

  return templateData;
};

export const SetDefault_TemplateForm = (props: ITemplateFormProperties) => {
  const {t} = useTranslation();
  const { templateData, dispatchData, headerContent, formData } = props;
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [selectedOption, setSelectedOption] = useState('scratch');
  const defaultData = {
    Name: '',
    Description: '',
    AssigneeId: null,
    SupportGroupId: null,
    TeamId: null,
    CategoryId: null,
    PriorityId: null,
    StatusId: null
  } as TicketTemplate;
  const [templates, setTemplates] = useState(
    Object.assign([], defaultTemplates)
  );

  useEffect(() => {
    mapTemplateData(defaultData);
    setTemplates(currentItems => {
      return currentItems.map((templateData, index) => {
        templateData['hidden'] = false;
        return mappingTemplateProps(templateData, formData);
      });
    });
  }, []);

  const handleSearch = (event: any) => {
    const target = event.target.value.toString() || '';
    setTemplates(currentItems => {
      if (target.length > 0) {
        let re = new RegExp(target, 'i');
        const matches = templates.map(json => {
          const match = JSON.stringify(json).match(re);
          json['hidden'] = !(match && match.length > 0);
          return json;
        });
        return matches;
      } else
        return templates.map(json => {
          json['hidden'] = false;
          return json;
        });
    });
  };

  const mapTemplateData = data => {
    for (let [key, value] of Object.entries(data)) {
      templateData[key] = value;
    }
  };

  const renderItems = templates.map((item: TicketTemplate, index) => {
    return (
      <Card
        fluid
        style={{ maxWidth: '46%', height: 'auto', marginTop: '1rem' }}
        elevated
        {...(selectedCardIndex == index && { selected: true })}
        hidden={item['hidden']}
      >
        <Card.TopControls style={{ zIndex: 1 }}>
          <Checkbox
            checked={selectedCardIndex == index}
            onChange={(e, data) => {
              if (!data.checked) {
                setSelectedCardIndex(null);
                mapTemplateData(defaultData);
              } else {
                setSelectedCardIndex(index);
                mapTemplateData(item);
              }
            }}
          />
        </Card.TopControls>
        <CardHeader>
          <Flex gap="gap.small">
            <Flex column>
              <Text content={item.Name} weight="semibold" />
            </Flex>
          </Flex>
        </CardHeader>
        <TemplateFieldsCardBody templateData={item} formData={formData} />
      </Card>
    );
  });

  const chooseItems = [
    {
      name: 'templateType',
      key: 'Scratch',
      label: TEMPLATE.CREATE_FROM_SCRATCH,
      value: 'scratch'
    },
    {
      name: 'templateType',
      key: 'Default',
      label: TEMPLATE.CREATE_FROM_DEFAULT,
      value: 'default'
    }
  ];
  return (
    <ProviderConsumer
      render={globalTheme => (
        <>
          <Flex
            column
            style={{ flex: 1, maxHeight: '70vh', overflow: 'auto' }}
            gap="gap.large"
          >
            <Flex.Item>
              <Flex column>
                <Text
                  content={TEMPLATE.CREATE_NEW_TEMPLATE}
                  weight="bold"
                  size="large"
                />
                <Text content={TEMPLATE.CREATE_NEW_TEMPLATE_DESCRIPTION} />
              </Flex>
            </Flex.Item>
            <Flex.Item>
              <Flex column gap="gap.small">
                <Text content={TEMPLATE.CHOOSE_TEMPLATE} weight="bold" />
                <RadioGroup
                  vertical
                  items={chooseItems}
                  defaultCheckedValue="scratch"
                  onCheckedValueChange={(e, data) => {
                    setSelectedOption(data['value'].toString());
                  }}
                />
              </Flex>
            </Flex.Item>
            <Flex.Item>
              <Flex
                vAlign="center"
                {...(selectedOption != 'default' && {
                  className: MgtCss.disabledContainer
                })}
              >
                <Text content={TEMPLATE.DEFAULT_TEMPLATES} weight="bold" />
                <Flex.Item push>
                  <Input
                    icon={<SearchIcon />}
                    placeholder={t('template.search')}
                    onChange={handleSearch}
                  />
                </Flex.Item>
              </Flex>
            </Flex.Item>
            <Flex.Item>
              <Flex
                gap="gap.medium"
                className={MgtCss.cardSelectionContainer}
                {...(selectedOption != 'default' && {
                  className: MgtCss.disabledContainer
                })}
              >
                {renderItems}
              </Flex>
            </Flex.Item>
          </Flex>
        </>
      )}
    />
  );
};
