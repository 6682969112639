import 'react'

declare global {
    interface String {
        normalize(): string;
        hyphenize(): string;
        toObject<T>(): T;
        removeWhiteSpaces(): string;
        addWhiteSpaces(): string;
    }
}

String.prototype.normalize = function () {
    return this.toString().match(/[A-Z][a-z]+/g).join(' ').toLowerCase();
};

String.prototype.hyphenize = function () {
    return this.toString().match(/[A-Z][a-z]+/g).join('-').toLowerCase();
};

String.prototype.removeWhiteSpaces = function () {
    return this.replace(/\s/g, "");
};

String.prototype.addWhiteSpaces = function () {
    return this.replace(/([A-Z])/g, ' $1').trim();
};
