import React, { useEffect, useState } from 'react';
import { PRODUCT_LICENSE } from '../../utils/constants';
import { getCachedFeature } from '../../cache/FeatureCache';
import { mergeStyleSets } from '@fluentui/react';

const classNames = mergeStyleSets({
  disabledButton: { 
    color: 'var(--mgt-theme-foreground-disabled) !important'
  }
});

interface Props {
  children: any;
  disable: boolean;
}

const FIELDS = {
  BUTTON: 'Button',
  TEXT: 'Text'
}

export const CheckLicense: React.FC<Props> = ({ children, disable }) => {
  const [isLicensed, setIsLicensed] = useState<boolean>(false);
  const [childComponent, setChildComponent] = useState<any>(<></>);

  const addDisabledProp = (childrenProp: any, isUserLicensed: boolean) => {
    if (disable && !isUserLicensed) {
      const childrenArray =  React.Children.map(childrenProp, child => child);
      for (let child of childrenArray) {
        if (child?.props?.children) child.props.children = addDisabledProp(child.props.children, isUserLicensed);
        if (child?.props?.trigger) child.props.trigger = addDisabledProp(child.props.trigger, isUserLicensed)[0];
      }
      return React.Children.map(childrenArray, child => {
        if (child?.type?.displayName == FIELDS.BUTTON && child?.props?.icon) {
          child.props.className += (' ' + classNames.disabledButton);
        }
        if (child?.props?.onClick) child.props.onClick = "";
        if (child?.props?.clearable) child.props.clearable = false;
        const newProps = child?.type?.displayName == FIELDS.TEXT ? {} : { disabled: true };
        return React.cloneElement(child, { ...newProps });
      });
    }
  }

  useEffect(() => {
    (async () => {
      const isUserLicensed = await getCachedFeature(PRODUCT_LICENSE.AnalystAccess);
      if (!isUserLicensed) setChildComponent(addDisabledProp(children, isUserLicensed));
      setIsLicensed(isUserLicensed);
    })();
  }, []);

  return <>
    {(disable && !isLicensed) && (
      <div className='pointer-events-none'>
        {childComponent}
      </div>
    )}
    {isLicensed && children}
  </>;
};
