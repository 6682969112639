import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { appState } from '../AppState';
import { ADMINISTRATORS, ANALYSTS, AGENTS, KNOWLEDGEAGENT } from '../shared/utils/constants';
import { BrowserAppTemplate } from '../browser-pages/BrowserAppTemplate';
import { SkeletonBoardHeader } from '../shared/components/Skeleton';
import { ToolbarTheme } from '@fluentui/react-teams/lib/esm/components/Toolbar/ToolbarTheme';
import { ProviderConsumer as FluentUIThemeConsumer } from '@fluentui/react-northstar';
import { SignedInConsentState, ConsentState } from '../../components/shared/services/signInState.service';

export const SkeletonPage = ({ children }) => {
  return (
    <FluentUIThemeConsumer
      render={globalTheme => (
        <>
          <ToolbarTheme globalTheme={globalTheme}>
            <SkeletonBoardHeader />
          </ToolbarTheme>
          {children}
        </>
      )} />
  )
}


export const ChangeSkeletonVersion = ({ children }) => {
  return (
    <BrowserAppTemplate page={
      <div style={{ height: "calc(100vh - 50px)" }}>
        {children}
      </div>}
      active={'ticket'}
    />
  );
}
export const AuthorizedAdminOrAnalystsRoute = ({
  component: Component,
  ...rest
}) => {
  const { roles } = appState().userRoles;
  const [signedIn] = SignedInConsentState.useIsSignedIn();

  return (
    <Route {...rest} render={props => 
      (signedIn !== ConsentState.SignedIn || 
        roles.includes(ADMINISTRATORS) || 
        roles.includes(ANALYSTS) || 
        roles.includes(AGENTS) || 
        roles.includes(KNOWLEDGEAGENT)) ? <Component {...props} /> : <Redirect to={{ pathname: '/requests', state: { from: props.location } }} />
    } />
  );
};
