import React, { useMemo } from 'react';
import {
  Text,
  ProviderConsumer as FluentUIThemeConsumer,
} from '@fluentui/react-northstar';
import { GroupUsersIcon } from './GroupUsersIcon';
import { GroupUsersList } from './GroupUsersList';
import Styles from './GroupUsersCard.module.css';
import { PlatformUser } from '../../../../shared/interfaces/platformuser.interface';
import { useTranslation } from 'react-i18next';

export interface GroupUsersCardProps {
  title: string;
  usersList: PlatformUser[];
  clickOnSeeMoreBtn: () => void;
  seeMore: boolean;
}

export const GroupUsersCard = ({
  title,
  usersList,
  clickOnSeeMoreBtn,
  seeMore
}: GroupUsersCardProps) => {
  const { t } = useTranslation();
  
  const showGroupuserIcon = useMemo(() => {
    return <GroupUsersIcon usersList={usersList.map(u => ({ PlatformUserId: u.Id }))} maxLength={5} />;
  }, [usersList]);
  
  const showGroupUserList = useMemo(() => {
    return <GroupUsersList usersList={usersList} />;
  }, [usersList]);

  return (
    <FluentUIThemeConsumer
      render={globalTheme => (
        <div
          className={`py-3.5 min-h-32`}
          style={{
            backgroundColor:
              globalTheme.siteVariables.colorScheme.default.background
          }}
        >
          <div className={`px-2.5`}>
            <Text content={title} size="medium" weight="semibold" />
            <div className={`my-2`}>
              {!seeMore ? showGroupuserIcon : showGroupUserList}
            </div>
          </div>

          <div className={`border-t-2 pt-1`}>
            <span
              className={`ml-2.5 text-xs cursor-pointer ${Styles['linkColor']}`}
              title={!seeMore ? t('requests.see-more') : t('requests.see-less')}
              onClick={clickOnSeeMoreBtn}
            >
              {!seeMore ? t('requests.see-more') : t('requests.see-less')}
            </span>
          </div>
        </div>
      )}
    />
  );
};
