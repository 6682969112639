import React, { useEffect, useState } from 'react';
import Styles from './Sla.module.css';

import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { Communication } from '@fluentui/react-teams';
import { Providers } from '@microsoft/mgt-element';
import { LoadingScreen } from '../shared/components/LoadingScreen';
import {
  AUTOMATION_PROPERTIES,
  BUTTONS,
  CONTACT_ADMIN,
  DEFAULT_SLA_INFO,
  DEFAULT_SLA_SLIDING_METADTA,
  DONE,
  ERROR,
  FILTERS_STRINGS,
  LOADING,
  REFRESH_SESSION,
  SAVE,
  SETTINGS,
  SLA,
  SOMETHING_WENT_WRONG,
  STATUS_GUID,
  UNAUTHORIZED_ACCESS
} from '../shared/utils/constants';
import { Toolbar } from '../shared/components/Toolbar';
import { Button, Dialog, ProviderConsumer } from '@fluentui/react-northstar';
import { Breadcrumbs } from '../shared/components/BreadcrumbNavigation';
import { Breached } from './Sections/Breached/Breached';
import { ApproachingBreached } from './Sections/ApproachingBreached/ApproachingBreached';
import { platformService } from '../shared/services/platform.service';
import { TicketCriteria } from './Sections/TicketCriteria/TicketCriteria';
import {
  prepareSlaCreatePayload,
  ticketCriteriaValidation
} from './utils/slaHelper';
import { ARROW_HEIGHT } from '../automation/Sections/Do/Do';
import { Info } from './Sections/Info/Info';
import { toTitleCase } from '../shared/utils/helper';
import { UpgradeNeeded } from '../shared/components/UpgradeNeeded';
import { useTranslation } from 'react-i18next';

interface Props {
  id: number;
}

const api = new platformService();

export const ServiceLevelAgreement = ({ id }: Props) => {
  const {t} = useTranslation();
  const history = useHistory();
  const [showDialog, setShowDialog] = useState(false);
  const [statuses, setStatuses] = useState<DropdownDataModel[]>([]);
  const [propertiesList, setPropertiesList] = useState<DropdownDataModel[]>([]);
  const [apiStatus, setApiStatus] = useState<APIStatus>({
    status: DONE,
    msg: '',
    errCode: 0
  });
  const [apiStatusState, setApiStatusState] = useState<APIStatus>({
    status: DONE,
    msg: '',
    errCode: 0
  });
  const [slaInfo, setSlaInfo] = useState<SlaInfo>(DEFAULT_SLA_INFO);
  const [slaSlidingBreachModel, setSlaSlidingBreachModel] =
    useState<SlaBreachMeta>(DEFAULT_SLA_SLIDING_METADTA);
  const [slaBreachedModel, setSlaBreachedModel] = useState<SlaBreachMeta>(
    DEFAULT_SLA_SLIDING_METADTA
  );
  const [ruleGroup, setRuleGroup] =
    useState<MaybeNull<SlaTicketCriteria>>(null);

  const getStatuses = async () => {
    setApiStatusState({
      status: LOADING,
      msg: t('sla.fetching-sla-status'),
      errCode: 0
    });
    try {
      const {
        data: { value }
      } = await api.getStatus(FILTERS_STRINGS.FILTERS_URL_PARAMS);
      const _value = value.filter(
        item =>
          item.Guid !== STATUS_GUID.CLOSED &&
          item.Guid !== STATUS_GUID.DEFLECTED &&
          item.Guid !== STATUS_GUID.RESOLVED
      );
      const _statuses: DropdownDataModel[] = _value.map(({ Id, Value }) => ({
        key: Value,
        label: Value,
        header: Value,
        Id: Id
      }));
      setStatuses(_statuses);
      setApiStatusState({
        status: DONE,
        msg: '',
        errCode: 0
      });
    } catch (error: any) {
      console.error(error);
      setApiStatusState({
        ...apiStatus,
        status: ERROR,
        errCode: error.response.status
      });
    }
  };

  const fetchAutomationProperties = async () => {
    try {
      const {
        data: { value }
      } = await api.getSlaProperties();

      const automationProperties: DropdownDataModel[] = value
        .filter((val: string) => !val.includes('id'))
        .map((val: string) => ({
          key: val,
          label: AUTOMATION_PROPERTIES[val],
          header: AUTOMATION_PROPERTIES[val]
        }));

      setPropertiesList(automationProperties);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchSlaDataForEdit = async (queryId: number) => {
    setApiStatus({
      status: LOADING,
      msg: t('sla.fetching-sla-data'),
      errCode: 0
    });

    try {
      const { data }: { data: SlaEditMetaData } = await api.getSla(
        queryId,
        '?$expand=SlaStatuses($select=Id,StatusId),SlaCriterias($select=Id,TimeDigitPart,TimeUnitPart,SlaActionTypeId;$expand=SlaActionType($select=Id,SlaActionName)),SlaRuleGroup($select=Id,Type;$expand=RuleCriteria($select=Id,Property,Operator,Value))&$select=Id,Name,IsActive'
      );
      setSlaInfo({
        id: data.Id,
        name: data.Name,
        isActive: data.IsActive,
        statusList: data.SlaStatuses.map(({ StatusId }) => StatusId)
      });

      data.SlaCriterias.forEach(
        ({
          Id,
          TimeDigitPart,
          TimeUnitPart,
          SlaActionType: { SlaActionName }
        }) => {
          if (SlaActionName === 'ApproachBreach') {
            setSlaSlidingBreachModel({
              id: Id,
              timeDigitPart: TimeDigitPart,
              timeUnit: TimeUnitPart
            });
          }

          if (SlaActionName === 'Breached') {
            setSlaBreachedModel({
              id: Id,
              timeDigitPart: TimeDigitPart,
              timeUnit: TimeUnitPart
            });
          }
        }
      );

      if (data.SlaRuleGroup)
        setRuleGroup({
          Id: data.SlaRuleGroup.Id,
          arrowHeight:
            data.SlaRuleGroup.RuleCriteria.length > 0
              ? 50 + ARROW_HEIGHT * (data.SlaRuleGroup.RuleCriteria.length - 1)
              : 0,
          logicOperator: data.SlaRuleGroup.Type,
          criteriaMetaData: data.SlaRuleGroup.RuleCriteria.map(
            ({ Id, Property, Operator, Value }) => ({
              id: Id,
              property: Property,
              operator: Operator,
              value: Value
            })
          )
        });

      setApiStatus({ ...apiStatus, status: DONE });
    } catch (err: any) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: err.response.status
      });
    }
  };

  useEffect(() => {
    getStatuses();
    fetchAutomationProperties();
    if (id > 0) {
      fetchSlaDataForEdit(id);
    }
  }, []);

  const createSla = async () => {
    const payload = prepareSlaCreatePayload(
      slaInfo,
      slaSlidingBreachModel,
      slaBreachedModel,
      ruleGroup,
      true
    );

    setApiStatus({
      status: LOADING,
      msg: t('sla.creating-sla'),
      errCode: 0
    });
    try {
      await api.createSla(payload);
      history.push('/settings/sla');
    } catch (error: any) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: error.response.status
      });
    }
  };

  const updateSla = async () => {
    setShowDialog(false);
    const payload = prepareSlaCreatePayload(
      slaInfo,
      slaSlidingBreachModel,
      slaBreachedModel,
      ruleGroup,
      false
    );

    setApiStatus({
      status: LOADING,
      msg: t('sla.updating-sla'),
      errCode: 0
    });

    try {
      await api.createSla(payload);
      history.push('/settings/sla');
    } catch (error: any) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: error.response.status
      });
    }
  };

  const submitHandler = () => {
    if (id > 0) {
      setShowDialog(true);
    } else {
      createSla();
    }
  };

  const errConfig = {
    fields: {
      title:
        apiStatus.errCode == 401
          ? UNAUTHORIZED_ACCESS
          : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  };

  const navs: Breadcrumbs = {
    breadcrumbs: [
      {
        title: t('common.settings'),
        link: '/settings'
      },
      {
        title: t('sla.service-level-agreement'),
        link: '/settings/sla'
      },
      {
        title: slaInfo.name.length > 0 ? slaInfo.name : t('knowledge.new-sla'),
        link: ''
      }
    ]
  };

  const renderContent = (_apiStatus: APIStatus, _apiStatusState: APIStatus) => {
    if (_apiStatus.status === LOADING || _apiStatusState.status === LOADING) {
      return <LoadingScreen message={_apiStatus.msg || _apiStatusState.msg} />;
    }

    return _apiStatus.status === ERROR ? (
      <div style={{ height: '100vh' }}>
        {_apiStatus.errCode !== 402 && <Communication {...errConfig} />}
        {_apiStatus.errCode === 401 && (
          <Button
            content={REFRESH_SESSION}
            primary
            onClick={() => {
              Providers.globalProvider.login();
            }}
          />
        )}
        {_apiStatus.errCode === 402 && (
          <UpgradeNeeded
            toolbarText={`${toTitleCase(SETTINGS)} > ${toTitleCase(SLA.SLA)}`}
            breadcrumbs={navs}
            headerText={t('sla.you-do-not-have-access')}
            subheaderText={t('sla.please-upgrade-your-plan')}
          />
        )}
      </div>
    ) : (
      <ProviderConsumer
        render={globalTheme => {
          return (
            <>
              <div className={Styles.SlaWrapper}>
                <Toolbar
                  globalTheme={globalTheme}
                  title={t('sla.title')}
                  saveText={SAVE}
                  saveCommand={submitHandler}
                  breadcrumbs={navs}
                  disableSave={
                    apiStatus.status === LOADING ||
                    !slaInfo.name ||
                    slaInfo.statusList.length === 0 ||
                    slaSlidingBreachModel.timeUnit === null ||
                    slaBreachedModel.timeUnit === null ||
                    slaSlidingBreachModel.timeDigitPart === 0 ||
                    slaBreachedModel.timeDigitPart === 0 ||
                    (ruleGroup != null &&
                      !ticketCriteriaValidation(ruleGroup.criteriaMetaData))
                  }
                />
                <div className={Styles.SlaContainer}>
                  <div className={Styles.Sla}>
                    <Info
                      slaInfo={slaInfo}
                      updateSlaInfo={setSlaInfo}
                      statuses={statuses}
                    />
                    <TicketCriteria
                      propertiesList={propertiesList}
                      ruleGroup={ruleGroup}
                      updateRuleGroup={setRuleGroup}
                    />
                    <ApproachingBreached
                      slaSlidingBreachModel={slaSlidingBreachModel}
                      updateSlaSlidingBreachModel={setSlaSlidingBreachModel}
                    />
                    <Breached
                      slaBreachedModel={slaBreachedModel}
                      updateSlaBreachModel={setSlaBreachedModel}
                    />
                  </div>
                </div>
              </div>
              <Dialog
                open={showDialog}
                onCancel={() => setShowDialog(false)}
                cancelButton={toTitleCase(BUTTONS.CANCEL)}
                confirmButton={toTitleCase(BUTTONS.CONFIRM)}
                onConfirm={() => {
                  updateSla();
                }}
                content={
                  <div className="my-2.5">
                    <h3
                      className="font-bold text-sm"
                      style={{
                        color: '#484644'
                      }}
                    >
                      {SLA.SLA_DOING_SO}
                    </h3>
                    <ul className="my-1 ml-3.5 list-disc text-sm">
                      <li>{SLA.SLA_RECALCULATE}</li>
                      <li>{SLA.SLA_POSSIBLE_TICKETS}</li>
                    </ul>
                  </div>
                }
                header={SLA.SLA_UPDATE_HEADER}
                style={{ maxWidth: '600px' }}
              />
            </>
          );
        }}
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>{t('sla.title-page')}</title>
      </Helmet>
      {renderContent(apiStatus, apiStatusState)}
    </>
  );
};
