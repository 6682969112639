import React, { Fragment, useContext, useState } from 'react';
import SaveSvg from '../../../svg/save.svg';
import MenuSvg from '../../../svg/menu-lines.svg';
import DownArrowSvg from '../../../svg/down-arrow.svg';
import DeleteSvg from '../../../svg/delete.svg';
import { CustomViewContext } from './CustomViewContextProvider';
import { appState, useSetState } from '../../AppState';
import { deleteCustomView, getChildFiltersCountFromStorage, getConfiguredColumnsKeyOrderFromStorage, getSortOrderFromStorage, isChildFilterSelectedExceptQueryValue, isSaveView, selectedColumnsFromConfigurableColumnsKeyOrder } from './CustomViewPopup/heplers/customPopupHelper';
import { Dialog, Loader, Text, ThemePrepared } from '@fluentui/react-northstar';
import ToolbarCSS from './Toolbar.module.css';
import useSelectedViewName from './CustomViewPopup/useSelectedViewName';
import { ListContext } from '../List/ListContextProvider';
import { ADMINISTRATORS, ANALYSTS } from '../../shared/utils/constants';
import AppCSS from '../../App.module.css';
import { ThemeColorScheme } from '../../shared/common/TeamsTheme';
import ListFunctions from '../List/ListFunctions';
import { useTranslation } from 'react-i18next';
import { platformService } from '../../shared/services/platform.service';
import SaveCsvIcon from '../../../svg/csv.svg';

interface PropsI {
  defaultFilters: FilterPropsItems[];
  globalTheme: ThemePrepared<any>;
  page: string | undefined;
  isCustomViewEnabled: boolean;
}

const TicketToolbarSecondary = ({ defaultFilters, globalTheme, page, isCustomViewEnabled }: PropsI) => {
  const setAppState = useSetState();
  const currentState = appState()
  const viewName = useSelectedViewName();
  const {t} = useTranslation();

  const { listStates } = useContext(ListContext);
  const { customViewStates, setCustomViewStates } = useContext(CustomViewContext);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const { isDefaultViewId } = ListFunctions();
  const { getFilterQuery } = ListFunctions();
  const [ exporting, setExporting ] = useState<boolean>(false);
  const api = new platformService();
  
  if (page === 'chart' || listStates.isTicketsFetching) {
    return <></>;
  }

  const actionOnCustomView = (view: ViewType) => {
    if (view === 'edit') {
      setCustomViewStates(
        (prev): CustomViewStatesType => ({
          ...prev,
          showCustomViewModal: true,
          view,
          viewName: prev.selectedCustomView?.Name,
          filters: JSON.parse(
            JSON.stringify(prev.selectedCustomView.FiltersDropdownValues)
          ),
          columns: {
            ...prev.selectedCustomView?.ColumnsSelectedValues,
            sortOrder: prev.selectedCustomView?.OrderBy ? {
              orderBy: prev.selectedCustomView?.OrderBy,
              orderType: prev.selectedCustomView?.OrderType,
            } : null,
          },
        })
      );
    }
    if (view === 'delete') {
      setShowDeleteConfirmationModal(true);
    }
    if (view === 'columns') {
      setCustomViewStates((prev): CustomViewStatesType => ({
        ...prev,
        showCustomViewModal: true,
        view,
        viewName: '',
        filters: JSON.parse(JSON.stringify(defaultFilters)),
        columns: {
          ...prev.columns,
          columnsKeyOrder: getConfiguredColumnsKeyOrderFromStorage(),
          selectedColumns: selectedColumnsFromConfigurableColumnsKeyOrder(getConfiguredColumnsKeyOrderFromStorage()),
          sortOrder: getSortOrderFromStorage()
        }
      }));
    }
    if (view === 'save') {
      setCustomViewStates((prev): CustomViewStatesType => ({
        ...prev,
        showCustomViewModal: true,
        view,
        ...(isChildFilterSelectedExceptQueryValue(
          getChildFiltersCountFromStorage(),
          currentState.filterDropdown?.queryValueApplied
        ) && {
          filters: JSON.parse(
            JSON.stringify(currentState.filterDropdown.selectedValues)
          ),
        }),
        columns: {
          ...prev.columns,
          sortOrder: prev.configureColumnsApplied.sortOrder
        }
      }));
    }
  };

  const isAdminUser = currentState?.userRoles?.roles?.includes(ADMINISTRATORS) ?? false;
  const isAnalystUser = currentState?.userRoles?.roles?.includes(ANALYSTS) ?? false;
  const isEditEnabled = (customViewStates.view === 'edit' || customViewStates.selectedCustomView);

  const onCancel = () => {
    setShowDeleteConfirmationModal(false);
  };

  const onSubmit = async () => {
    if (isCustomViewEnabled) {
      setSubmitClicked(true);
      await deleteCustomView({ customViewStates, setCustomViewStates, setAppState });
      setShowDeleteConfirmationModal(false);
      setSubmitClicked(false);
    }
  };

  const onExportClick = async () => {
    setExporting(true);
    let filter = getFilterQuery(customViewStates);
    await api.exportTicketsToCsv(`${filter}`);
    setExporting(false);   
  }

  const colorScheme = globalTheme.siteVariables.colorScheme;

  return (
    <Fragment>     
        <Dialog
          content={ <Loader label={t("ticket.toolbar.export-tickets")} size="large" labelPosition="below" /> }
          trapFocus={true}
          open={exporting}
          style={{maxWidth: "400px"}}
        />              
      <div
        className={`flex justify-between -mt-5 pl-4 pr-4 pt-2 pb-2 ${ToolbarCSS['toolbar-custom-views']}`}
        style={{ backgroundColor: colorScheme.default.background, ...ThemeColorScheme(globalTheme.siteVariables) }}
      >
        <div className='flex space-x-4'>
          <Text content={viewName} weight='semibold' />
          {isAdminUser && isCustomViewEnabled &&
            (isSaveView(
                isDefaultViewId,
                customViewStates.configurableColumnsKeyOrder,
                getChildFiltersCountFromStorage(),
                currentState.filterDropdown?.queryValueApplied
              )) && (
            <div
              className={`hidden md:flex font-semibold space-x-1 items-center cursor-pointer`}
              style={{ color: colorScheme.brand.foreground }}
              onClick={() => actionOnCustomView('save')}
            >
              <SaveSvg className={ToolbarCSS['brand-svg-icon']} />
              <span>{t('ticket.ticket-list.configure-column.save-view')}</span>
            </div>
          )}
          {isAdminUser && isEditEnabled && (
            <>
              <div
                className={`hidden md:flex font-semibold space-x-1 items-center cursor-pointer`}
                style={{ color: colorScheme.brand.foreground }}
                onClick={() => actionOnCustomView('edit')}
              >
                <SaveSvg className={ToolbarCSS['brand-svg-icon']} />
                <span>{t('ticket.ticket-list.configure-column.edit-view')}</span>
              </div>
              <div
                className={`hidden md:flex font-semibold space-x-1 items-center cursor-pointer`}
                style={{ color: colorScheme.brand.foreground }}
                onClick={() => actionOnCustomView('delete')}
              >
                <DeleteSvg className={ToolbarCSS['brand-svg-icon']} />
                <span>{t('ticket.ticket-board.delete')}</span>
              </div>
            </>
          )}
        </div>
        {(isAdminUser || isAnalystUser) && isCustomViewEnabled && page != 'board' && !customViewStates.selectedCustomView && (
          <>
          <div
            className={`hidden md:flex font-semibold space-x-1 items-center cursor-pointer`}
            style={{ color: colorScheme.brand.foreground, marginLeft: "auto", marginRight: "10px" }}
            onClick={() => actionOnCustomView('columns')}
          >
            <div className='flex' style={{marginBottom: "-2px"}}>
              <MenuSvg className={ToolbarCSS['brand-svg-icon']} />
              <DownArrowSvg className={ToolbarCSS['brand-svg-icon']} />
            </div>
            <span>{t('ticket.ticket-list.configure-column.configure-columns')}</span>
          </div>
          </>
        )}
         {(isAdminUser || isAnalystUser) && (
          <>
          <div
            className={`hidden md:flex font-semibold space-x-1 items-center cursor-pointer`}
            style={{ color: colorScheme.brand.foreground }}
            onClick={() => onExportClick()}
          >
            <div className='flex' style={{marginBottom: "-3px"}}>
             <SaveCsvIcon className={ToolbarCSS['brand-svg-icon']} />
            </div>
            <span>{t('ticket.toolbar.export-to-csv')}</span>
          </div>
          </>
         )}
      </div>
      <Dialog
        className={`${ToolbarCSS['deleteCustomViewDialog']} ${AppCSS['confirmDialog']}`}
        closeOnOutsideClick={false}
        cancelButton={{ content: t('common.buttons.cancel'), disabled: submitClicked }}
        onCancel={onCancel}
        confirmButton={{
          content: t('common.buttons.delete'),
          primary: true,
          disabled: submitClicked,
        }}
        onConfirm={onSubmit}
        open={showDeleteConfirmationModal}
        header={{
          content: (
            <div>
              <h3>{t('ticket.custom-view.delete-prompt-header', { viewName: customViewStates.selectedCustomView?.Name })}</h3>
              <div className='text-sm font-medium mt-2' style={{ color: colorScheme.default.foreground2 }}>
                {t('ticket.custom-view.delete-prompt')}
              </div>
            </div>
          ),
          style: { width: '100%' },
        }}
      ></Dialog>
    </Fragment>
  );
};

export default TicketToolbarSecondary;
