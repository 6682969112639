import React from 'react';
import Styles from './Arrow.module.css';

interface Props {
  height: number;
  bottom?: number
}

export const RightArrow = ({ height, bottom }: Props) => (
  <div className={`${Styles.ArrowRightLines} relative bottom-${bottom} ml-4`} style={{ height: height }}>
    <i className={`${Styles.Arrow} ${Styles.Right} ${Styles.ArrowRight}`}></i>
  </div>
);
