import React from 'react';
import { Loader, ProviderConsumer } from '@fluentui/react-northstar';

export const LoadingScreen = (props: { message: string, height?: string }) => {
  return (
    <ProviderConsumer render={({ siteVariables: { colorScheme } }) => (
      <div className={`${!props.height && 'h-screen'} flex items-center justify-center flex-col`} style={props.height ? { height: props.height }: {}}>
        <Loader style={{ color: colorScheme.default.foreground1 }} label={props.message} className={`mt-14`}/>
      </div>
    )} />
  );
};
