import {
  ProviderConsumer as FluentUIThemeConsumer,
  Flex,
  CheckboxProps,
  Checkbox,
  Text,
  ThemePrepared,
  InfoIcon,
  Dropdown
} from '@fluentui/react-northstar';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  DropdownSupportGroup,
  DropdownAssignee,
  createTag,
  getBackgroundColor
} from '../shared/components/TicketForm';
import { ThemeColorScheme } from '../shared/common/TeamsTheme';
import MgtCss from '../../components/mgt.module.css';
import { TicketTemplate } from '../shared/interfaces/ticket.interface';
import { ITemplateFormProperties } from './TicketTemplates';
import { LICENSE_FEATURE, TAGS, TEMPLATE, TEMPLATE_DROPDOWN_RETURN_VALUE } from '../shared/utils/constants';
import { CheckFeature } from '../shared/components/CheckFeature';
import { NestedDropdown } from '../shared/components/NestedDropdown/NestedDropdown';
import { platformService } from '../shared/services/platform.service';
import { TicketTagPicker } from '../shared/common/Controls/TagsPicker';
import { useTranslation } from 'react-i18next';
import { getCachedFeature } from '../shared/cache/FeatureCache';
import { PeoplePicker, PersonType, UserType } from '@microsoft/mgt-react';
import { filterPlatformUsersInRole } from '../tikit/ticketHelper';
import { DateInputType, DatePicker } from '../shared/components/DatePicker/DatePicker';
interface LooseObject {
  [key: string]: any;
}

export type TemplateFormStateProps = {
  ticketStatus: any[];
  categories: any[];
  ticketTypes: any[];
  priority: any[];
  usersInRole: any[];
  supportgroups: any[];
  teams: any[];
  platformusers: any[];
  lifecycles?: any[];
  templates?: any[];
  tags?: any[];
  globalTheme?: ThemePrepared<any>;
  defaultTeam?: any;
  defaultSupportGroup?: any;
};

const TemplateFieldsForm: React.FC<ITemplateFormProperties> = props => {
  const templateData: TicketTemplate = props.templateData;
  
  const unsetValue = {
    Value: TEMPLATE.UNSET_VALUE,
    Id: -1
  }; 

  if (templateData.UnsetAssignee) {
    templateData.AssigneeId = -1;
    templateData.UnsetAssignee = false;
  }
  if (templateData.UnsetCategory) {
    templateData.CategoryId = -1;
    templateData.UnsetCategory = false;
  }
  if (templateData.UnsetTicketType) {
    templateData.TicketTypeId = -1;
    templateData.TicketType = unsetValue;
    templateData.UnsetTicketType = false;
  }
  if (templateData.UnsetSupportGroup) {
    templateData.SupportGroupId = -1;
    templateData.UnsetSupportGroup = false;
  }
  if (templateData.UnsetTeam) {
    templateData.TeamId = -1;
    templateData.UnsetTeam = false;
  }
  props.templateData = templateData;
  return <TicketTemplateFluentTheme {...props} />;
};

type TemplateFieldPropsValue = {
  title: string | '';
  value: any;
  checked: boolean | false;
  properties?: LooseObject;
  isHidden: boolean | false;
  feature?: string;
};

type TemplateFieldProps = {
  StatusId: TemplateFieldPropsValue;
  CategoryId: TemplateFieldPropsValue;
  TicketTypeId: TemplateFieldPropsValue;
  PriorityId: TemplateFieldPropsValue;
  SupportGroupId: TemplateFieldPropsValue;
  TeamId: TemplateFieldPropsValue;
  AssigneeId: TemplateFieldPropsValue;
  Assignment: TemplateFieldPropsValue;
  LifecycleId: TemplateFieldPropsValue;
  DueDate: TemplateFieldPropsValue;
  Tags: TemplateFieldPropsValue;
  AffectedUsers: TemplateFieldPropsValue;
  Collaborators: TemplateFieldPropsValue;
};

const api = new platformService();
const DefaultSelectedObject = (id: number, itemsArray) =>
  itemsArray.find(e => e.Id == id);


const setUnsetValue = (key: string, templateData: TicketTemplate) => {
  if (key == 'AssigneeId') templateData.UnsetAssignee = false;
  if (key == 'TeamId') templateData.UnsetTeam = false;
  if (key == 'SupportGroupId') templateData.UnsetSupportGroup = false;
  if (key == 'CategoryId') templateData.UnsetCategory = false;
  if (key == 'TicketTypeId') templateData.UnsetTicketType = false;
}

const getSelectedObjectNameFromKey = (key: string) => {
  if (key == TEMPLATE_DROPDOWN_RETURN_VALUE.STATUS_ID)
    return TEMPLATE_DROPDOWN_RETURN_VALUE.STATUS;
  else if (key == TEMPLATE_DROPDOWN_RETURN_VALUE.CATEGORY_ID)
    return TEMPLATE_DROPDOWN_RETURN_VALUE.CATEGORY;
  else if (key == TEMPLATE_DROPDOWN_RETURN_VALUE.TICKET_TYPE_ID)
    return TEMPLATE_DROPDOWN_RETURN_VALUE.TICKET_TYPE;
  else if (key == TEMPLATE_DROPDOWN_RETURN_VALUE.PRIORITY_ID)
    return TEMPLATE_DROPDOWN_RETURN_VALUE.PRIORITY;
}

const TicketTemplateFluentTheme: React.FC<ITemplateFormProperties> = props => {
  const {t} = useTranslation();
  const { templateData, formData, dispatchData } = props;
  const {
    ticketStatus,
    categories,
    ticketTypes,
    priority,
    usersInRole,
    supportgroups,
    teams,
    lifecycles,
    tags,
    platformusers
  } = formData;

  const unsetValue = {
    Value: TEMPLATE.UNSET_VALUE,
    IsDefault: false,
    Id: -1,
    ParentId: null
  };

  let querySearch: string = "";
  let defaultData = {
    AffectedUsers: { title: t('template.fields.affected-users'), feature: LICENSE_FEATURE.AffectedUsers },
    StatusId: { title: t('template.fields.status') },
    TicketTypeId: { title: t('template.fields.ticket-type'), feature: LICENSE_FEATURE.TicketTypes },
    CategoryId: { title: t('template.fields.category') },
    PriorityId: { title: t('template.fields.priority') },
    TeamId: { title: t('template.fields.team'), isHidden: true, feature: LICENSE_FEATURE.MultiDepartment },
    SupportGroupId: { title: t('template.fields.group'), isHidden: true },
    AssigneeId: { title: t('template.fields.assignee'), isHidden: true },
    Assignment: { title: t('template.fields.assignment') },
    Collaborators: { title: t('template.fields.collaborators'), feature: LICENSE_FEATURE.Collaborators },
    DueDate: { title: t('template.fields.due-date') },
    Tags: {title: t('template.fields.tags'), feature: LICENSE_FEATURE.TicketTags },
    LifecycleId: { title: t('template.fields.lifecycle'), feature: LICENSE_FEATURE.TicketLifecycle },
  } as TemplateFieldProps;

  const defaultGroup = { Name: TEMPLATE.UNSET_VALUE, Id: -1 };
  const defaultAssignee = { FullName: TEMPLATE.UNASSIGNED, Id: -1 };

  if (!ticketTypes?.some(x => x.Id == -1)) ticketTypes.unshift(unsetValue);
  // removed unset for teams
  if (!supportgroups?.some(x => x.Id == -1)) supportgroups.unshift(defaultGroup);

  if (!usersInRole?.some(x => x.Id == -1)) usersInRole.unshift(defaultAssignee);

  const [fieldProps, dispatch] = React.useReducer((state: any, action: any) => {
    return { ...state, ...action };
  }, defaultData);

  const [filteredUsers, setFilteredUsers] = useState(usersInRole);
  const [filteredGroups, setFilteredGroups] = useState(supportgroups);
  const [assigneeFullName, setAssigneeFullName] = useState('');
  const [mappedLifecycles, setMappedLifecycles] = useState([]);
  const [searchTag, setSearchTag] = useState('');
  const [isCreatingTag, setIsCreatingTag] = useState(false);
  const [isTeamsEnabled, setIsTeamsEnabled] = useState(false);
  const [collaboratorList, setCollaboratorList] = useState(usersInRole);
  const tagSearch = useRef(null);
  const [dateType, setDateType] = useState<DateInputType>('value');
  
  const [searchTeam, setSearchTeam] = useState("");

  const onChangeTeamQuery = useCallback((searchQuery: string) => {
    setSearchTeam(searchQuery);
  }, [searchTeam]);
  
  useEffect(() => {
    (async () => {
      const result = await getCachedFeature(LICENSE_FEATURE.MultiDepartment)
      setIsTeamsEnabled(result);
    })();

    setGroupList(teams.find(g => g.Id == templateData['TeamId']));
    setAssigneeList(supportgroups.find(g => g.Id == templateData['SupportGroupId']));
    setAssigneeFullName(usersInRole.find(o => o.Id === templateData['AssigneeId'])?.FullName);
    setMappedLifecycles(lifecycles.map(x => ({'Id': x["Id"], 'Name': x["Title"]})));
    const collabs = usersInRole.map(c => ({ header: c.FullName, accessibilityitemprops: c.Id, selected: false })).filter((t: any) => templateData.AssigneeId != t.accessibilityitemprops && t.accessibilityitemprops != -1);
    for (let u of templateData?.Collaborators?.filter(x => x.Id != templateData.AssigneeId) ?? [])
      collabs.find(i => i.accessibilityitemprops == u.Id).selected = true;

    setCollaboratorList(collabs);
  }, []);

  const getExtraProps = (prop: TemplateFieldPropsValue) => {
    return { disabled: !prop.checked, inverted: !prop.checked };
  };

  Object.keys(defaultData).forEach((key: string) => {
    const tempData = defaultData[key];

    switch (key) {
      case 'AffectedUsers':
      case 'Collaborators':
      case 'Tags':
        tempData.checked = templateData[key] && templateData[key].length > 0 ? true : false;
        break;
      case 'DueDate':
        tempData.checked = templateData[key] || templateData["RelativeDueDate"];
        break;
      default:
        tempData.checked = templateData[key] ? true : false;
    }
     
    if (!tempData.properties) {
      let properties = { clearable: true, placeholder: `${t('common.select')} ${tempData.title}` };
      if (!templateData[key]) properties['defaultValue'] = undefined;
      tempData.properties = properties;
      Object.assign(tempData.properties, getExtraProps(tempData));
    }
    defaultData[key] = tempData;
  });

  const setFields = (key: string, checked: boolean) => {
    fieldProps[key].checked = checked;
    Object.assign(fieldProps[key].properties, getExtraProps(fieldProps[key]));
    if (checked) {
      templateData[key] = fieldProps[key].value;
      pushOnErrorFields(key);
    } else {
      const checkedObjName = getSelectedObjectNameFromKey(key);
      if (checkedObjName && checkedObjName.length > 0) 
        templateData[checkedObjName] = null;
      
      if (key == 'Tags') {
        tags.forEach(k => { k.selected = false });
        templateData[key] = [];
      } else if (key == 'Collaborators') {
        collaboratorList.forEach(k => { k.selected = false });
        setCollaboratorList(collaboratorList);
        templateData[key] = [];
      } else 
        templateData[key] = null;
      
      fieldProps[key].value = null;
      Object.assign(fieldProps[key].properties, { defaultValue: undefined });
      templateData.errorFieldsArray = templateData.errorFieldsArray.filter(x => x != key);
    }
    dispatch(fieldProps);
    dispatchData(templateData);
    
  }

  const onCheckboxChanged = (key: string, { checked }: Omit<CheckboxProps, 'checked'> & { checked: boolean; }) => {
    let keys: string[] = [key];
    if (key === 'Assignment') {
      keys = ['TeamId', 'SupportGroupId', 'AssigneeId'];
      keys.forEach(k => setFields(k, checked));
      if (!checked) {
        setSearchTeam('');
        setFilteredGroups([...supportgroups]);
        setFilteredUsers([...usersInRole]);
        setAssigneeFullName('');
      } else {        
        setSearchTeam(formData.defaultTeam.Name)
        dropdownValueChange('TeamId', formData.defaultTeam.Id);
        dropdownValueChange('SupportGroupId', null);
        dropdownValueChange('AssigneeId', null);
        setGroupList(formData.defaultTeam);
        const defaultSupportGroupId = supportgroups.find(sg => sg.TeamsAadObjectId === formData.defaultTeam["TeamsAadObjectId"] && sg.TeamsChannelId === formData.defaultTeam["TeamsChannelId"])?.Id;
        dropdownValueChange('SupportGroupId', defaultSupportGroupId ?? defaultSupportGroupId);
      }
    } else {      
      keys.forEach(k => setFields(k, checked));
    }
  };

  const buildTagModel = (tag) =>{
    return {
      Id: tag.Id,
      ModifiedDate: tag.ModifiedDate,
      ModifiedById: tag.ModifiedById,
      CreatedDate: tag.CreatedDate,
      CreatedById: tag.CreatedById,
      Guid: tag.Guid,
      IsDeleted: false,
      Name: tag.Name
    };
  }

  const setGroupList = (value: any) => {
    if (value && value.Id && value.Id != -1 && !value.TeamsAadObjectId) {
      setFilteredGroups([...supportgroups.filter(x => x.Id == -1)]);
      setFilteredUsers([defaultAssignee, ...[]]);
    }
    else {
      if (!value || !value.Id || value.Id === -1) {
        setFilteredGroups([...supportgroups]);
        setFilteredUsers([...usersInRole]);
      }
      else {
        const defaultSupportGroupId = supportgroups.find(sg => sg.TeamsAadObjectId === value["TeamsAadObjectId"] && sg.TeamsChannelId === value["TeamsChannelId"])?.Id;
        const thisUsers = filterPlatformUsersInRole(supportgroups.find(s => s.Id == defaultSupportGroupId)?.Members ?? teams.filter((x) => x.Id === value.Id)[0]?.Users ?? [], usersInRole);
        
        setFilteredGroups([{ ...defaultGroup }, ...supportgroups.filter(x => x.TeamsAadObjectId == value.TeamsAadObjectId)]);
        setFilteredUsers([{ ...defaultAssignee }, ...thisUsers]);
      }
    }
  }

  const setAssigneeList = (value: any) => {
    const teamId = templateData['TeamId'];
    if (!value || !value.Id || value.Id === -1) {
      if (!teamId || teamId === -1) {
        setFilteredUsers([...usersInRole]);
      } else {
        setFilteredUsers([{ ...defaultAssignee }, ...filterPlatformUsersInRole(teams.find(x => x.Id == teamId)?.Users || [], usersInRole)]);
      }
    } else {
      setFilteredUsers([defaultAssignee, ...filterPlatformUsersInRole(value.Members, usersInRole)]);
    }
  }

  const onTeamChange = (value: any, event: string) => {
    if (event == 'onChange') {
      dropdownValueChange('TeamId', value.Id);
      const defaultSupportGroupId = value.Id == null ? null : supportgroups.find(sg => sg.TeamsAadObjectId === value.TeamsAadObjectId && sg.TeamsChannelId === value.TeamsChannelId)?.Id;
      dropdownValueChange('SupportGroupId', defaultSupportGroupId ?? defaultSupportGroupId );
      dropdownValueChange('AssigneeId', null);
      setGroupList(value);
      setAssigneeFullName('');
      
    }
  }

  const onSupportGroupChange = (value: any, event: string) => {
    if (event == 'onChange') {
      dropdownValueChange('SupportGroupId', value.Id);
      dropdownValueChange('AssigneeId', null);
      setAssigneeList(value);
      setAssigneeFullName('');
    }
  }

  const onAssigneeChange = (value: any, event: string) => {
    if (event == 'onChange') {
      if (!value) setAssigneeFullName('');
      dropdownValueChange('AssigneeId', value);
    }
  }

  const onLifecycleChange = (value: any, event: string) => {
    if (event == 'onChange') {
      dropdownValueChange('LifecycleId', value.Id);
    }
  }

  const pushOnErrorFields = (key: string) => {
    if (isTeamsEnabled || (!isTeamsEnabled && key !== 'TeamId'))
        templateData.errorFieldsArray.push(key);
  }

  const dropdownValueChange = (key: string, value: any) => {
    fieldProps[key].value = value;
    templateData[key] = value;
    if (value && templateData.errorFieldsArray.indexOf(key) >= 0) {
      templateData.errorFieldsArray = templateData.errorFieldsArray.filter(x => x != key);
      dispatchData(templateData);
    }
    else if (value == undefined && templateData.errorFieldsArray && templateData.errorFieldsArray.indexOf(key) < 0) {
      setUnsetValue(key, templateData);
      pushOnErrorFields(key);
      dispatchData(templateData);
    }else if(key == 'LifecycleId')
      dispatchData(templateData);
  };

  const nestedDropdownValueChange = (key: string, value: any, event: string, itemsArray: any, objText: string) => {
    if (event == 'onChange') {
      fieldProps[key].value = value;
      templateData[key] = value;
      if (value) {
        if(templateData.errorFieldsArray.indexOf(key) >= 0)
          templateData.errorFieldsArray = templateData.errorFieldsArray.filter(x => x != key);
        let selectedObj = itemsArray.find((e: any) => e.Id == value);
        templateData[objText] = selectedObj;
        dispatchData(templateData);
      }
      else if (value == undefined) {
        setUnsetValue(key, templateData);
        templateData[objText] = null;
        templateData.errorFieldsArray = templateData.errorFieldsArray.filter(x => x != key);
        pushOnErrorFields(key);
        dispatchData(templateData);
      }
    }
  };

  const setDateField = (key:string, newDate:Date) => {
    let selectedDate = newDate ?? newDate.toISOString();

    if (selectedDate) {
      let inx = templateData.errorFieldsArray.indexOf(key);
      templateData.errorFieldsArray.splice(inx, 1);
    } else {
      pushOnErrorFields(key);
    }
    templateData[key]=selectedDate;
    templateData["RelativeDueDate"]="";

    dispatch(fieldProps);
    dispatchData(templateData);
  }

  const setRelativeDateField = (key:string, newDate:string) => {
    let selectedDate = newDate ?? newDate;

    if (selectedDate) {
      let inx = templateData.errorFieldsArray.indexOf(key);
      templateData.errorFieldsArray.splice(inx, 1);
    } else {
      pushOnErrorFields(key);
    }
    templateData[key]=null;
    templateData["RelativeDueDate"]=selectedDate;

    dispatch(fieldProps);
    dispatchData(templateData);
  }

  const convertItemsArray = (itemsArray: any[]) =>
    itemsArray.map(item => ({
      id: item.Id,
      parentId: item.ParentId,
      position: item.Position,
      value: item.Value,
      ...item
    }))

    const addTag = () => {
      setIsCreatingTag(true);
      createTag({
        Name: searchTag
      }).then(response => {
        tagSearch.current.value = '';
        delete response["@odata.context"];
        tags.push(response);
        response.header = response.Name;
        response.accessibilityitemprops = response.Id;
        response.selected = false;

        setIsCreatingTag(false);
        
      });
    }

    const selectTag = (e, p, key) => {
      //update the list of tags
      let updatedTagList = [];
      p.items.forEach((t:any)=>{t.selected=false});
      
      let values : any = p.value;
      values.forEach((t:any) => {
        t.selected = true;
        updatedTagList.push(buildTagModel(t));
        
      });

      templateData[key]=updatedTagList;
      if (updatedTagList.length>0){
        let inx = templateData.errorFieldsArray.indexOf(key);
        templateData.errorFieldsArray.splice(inx,1);
      }
      else{
        pushOnErrorFields(key);
      }

      dispatch(fieldProps);
      dispatchData(templateData);
    }

  const onSearchQueryChange = (e, p) => {
    setSearchTag(p.searchQuery);
  }

  const removeHiddenOverflow = () => {
    var dropdowns = document.getElementsByClassName('ps');
    if(dropdowns?.length > 0){
      for (let i = 0; i < dropdowns.length; i++) {
        dropdowns[i]['style'] = 'overflow: unset !important';
      }
    }
  }

  const addressLifecycleStatusConflict = (key: string) => {
      delete templateData["Status"];
      delete templateData[key];
      let inx = templateData.errorFieldsArray.indexOf("LifecycleStatusConflict");
      templateData.errorFieldsArray.splice(inx,1);
      fieldProps[key].checked = false;
      fieldProps[key].warning = "Ticket Status is determined by the chosen Lifecycle.";
      Object.assign(fieldProps[key].properties, {disabled: true});
      const divElement = document.getElementById(`template-field-${key}`);
      if(divElement)
        divElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }

  const renderFields = (globalTheme:ThemePrepared<any>) =>{
    removeHiddenOverflow();
    return Object.keys(fieldProps).map((key: string) => {
      const newprops = fieldProps[key];
      let isFieldChecked = false;
      if (!newprops.isHidden) {
        if (key == 'Assignment') {
          isFieldChecked = fieldProps['SupportGroupId'].checked || fieldProps['AssigneeId'].checked || fieldProps['TeamId'].checked;
          Object.assign(fieldProps['TeamId'].properties, { error: isFieldChecked && templateData && !templateData['TeamId'] && templateData.validateFields});
          Object.assign(fieldProps['SupportGroupId'].properties, { error: isFieldChecked && templateData && !templateData['SupportGroupId'] && templateData.validateFields});
          Object.assign(fieldProps['AssigneeId'].properties, { error: isFieldChecked && templateData && !templateData['AssigneeId'] && templateData.validateFields });
        }
        else if (key == 'DueDate') {
          isFieldChecked = newprops.checked;
          Object.assign(fieldProps[key].properties, {
            error: isFieldChecked && templateData && templateData.validateFields && !templateData[dateType == "value" ? key : "RelativeDueDate"]
          });
        }else {
          if(templateData.errorFieldsArray?.includes("LifecycleStatusConflict") && key == "StatusId")
            addressLifecycleStatusConflict(key);
          else
            isFieldChecked = newprops.checked;
          Object.assign(fieldProps[key].properties, {
            error: isFieldChecked && templateData && templateData.validateFields && !templateData[key]
          });
        }

        const label = (
          <Checkbox
            id={key + '_fieldCheckbox'}
            label={newprops.title}
            defaultChecked={isFieldChecked}
            checked={isFieldChecked}
            onChange={(_e, isChecked) => onCheckboxChanged(key, isChecked)}
            className={MgtCss.fieldCheckbox}
          />
        );
        let field = <></>;
        switch (key) {
          case 'AffectedUsers': 
            field = (<PeoplePicker 
              userType={UserType.user} 
              type={PersonType.person} 
              className={`width-med height-small`} 
              disabled={newprops.properties?.disabled} 
              selectedPeople={templateData.AffectedUsers?.map((item: any) => ({ displayName: item.FullName, userPrincipalName: item.UserName, id: item.AadObjectId })) ?? []}
              selectionChanged={async (e: any) => {
                const list = e.detail.map((item: any) => ({ 
                  FullName: item.displayName,
                  GivenName: item.givenName,
                  FamilyName: item.surname,
                  Email: item.scoredEmailAddresses && item.scoredEmailAddresses[0]?.address,
                  UserName: item.userPrincipalName, 
                  AadObjectId: item.id
                }));
            
                const selectedUsers = [];
                for (let u of list)
                  selectedUsers.push(platformusers.find(i => (i.AadObjectId && u.AadObjectId && i.AadObjectId == u.AadObjectId) || i.UserName == u.UserName) ?? (await api.getOrCreatePlatformUser(u)));

                templateData.AffectedUsers = selectedUsers;
                if (selectedUsers.length > 0) {
                  let inx = templateData.errorFieldsArray.indexOf('AffectedUsers');
                  templateData.errorFieldsArray.splice(inx, 1);
                } else {
                  pushOnErrorFields('AffectedUsers');
                }
                
                dispatchData(templateData);
              }}
            />);
            break;
          case 'Collaborators': 
            field = (<Dropdown
              disabled={newprops.properties?.disabled}
              multiple
              fluid
              style={{ backgroundColor: getBackgroundColor(globalTheme, newprops.properties?.disabled) }}
              items={collaboratorList}
              value={collaboratorList.filter(i => i.selected)}
              onChange={(_, p: any) => { 
                const collabs = p.value.map(i => platformusers.find(u => u.Id == i.accessibilityitemprops));
                templateData.Collaborators = collabs;
                                
                collaboratorList.forEach(x => {
                  x.selected = (p.value.some(i => x.accessibilityitemprops == i.accessibilityitemprops));
                });
                setCollaboratorList(collaboratorList);

                if (collabs.length > 0) {
                  let inx = templateData.errorFieldsArray.indexOf('Collaborators');
                  templateData.errorFieldsArray.splice(inx, 1);
                } else {
                  pushOnErrorFields('Collaborators');
                }

                dispatchData(templateData);
                
              }}
            />);
            break;
          case 'StatusId':
            field = (
              <NestedDropdown
                items={convertItemsArray(ticketStatus)}
                dataItem={{
                  id: templateData.Status?.Id,
                  parentId: templateData.Status?.ParentId,
                  position: templateData.Status?.Position,
                  value: templateData.Status?.Value,
                  ...templateData.Status
                }}
                defaultValue={newprops.properties?.defaultValue}
                onChange={(value: any, event: string = 'onChange') => {
                  nestedDropdownValueChange(
                    key,
                    value?.Id,
                    event,
                    ticketStatus,
                    'Status'
                  );
                }}
                disabled={newprops.properties?.disabled}
                placeholder={newprops.properties?.placeholder}
                showClearBtn={true}
                error={fieldProps[key]?.properties?.error}
              />
            );
            break;
          case 'CategoryId':
            field = (
              <NestedDropdown
                items={convertItemsArray(categories)}
                dataItem={{
                  id: templateData.Category?.Id,
                  parentId: templateData.Category?.ParentId,
                  position: templateData.Category?.Position,
                  value: templateData.Category?.Value,
                  ...templateData.Category
                }}
                defaultValue={newprops.properties?.default}
                onChange={(value: any, event: string = 'onChange') => {
                  nestedDropdownValueChange(
                    key,
                    value?.Id,
                    event,
                    categories,
                    'Category'
                  );
                }}
                disabled={newprops.properties?.disabled}
                showClearBtn={true}
                placeholder={newprops.properties?.placeholder}
                error={fieldProps[key]?.properties?.error}
              />
            );
            break;
          case 'PriorityId':
            field = (
              <NestedDropdown
                items={(convertItemsArray(priority))}
                dataItem={{
                  id: templateData.Priority?.Id,
                  parentId: templateData.Priority?.ParentId,
                  position: templateData.Priority?.Position,
                  value: templateData.Priority?.Value,
                  ...templateData.Priority
                }}
                defaultValue={newprops.properties?.default}
                onChange={(value: any, event: string = 'onChange') => {
                  nestedDropdownValueChange(
                    key,
                    value?.Id,
                    event,
                    priority,
                    'Priority'
                  );
                }}
                disabled={newprops.properties?.disabled}
                showClearBtn={true}
                placeholder={newprops.properties?.placeholder}
                error={fieldProps[key]?.properties?.error}
              />
            );
            break;
          case 'Assignment':
            field = (
              <div>
                <CheckFeature featureName={LICENSE_FEATURE.MultiDepartment}>
                  <div>
                    <Text content={t('template.fields.team')}></Text>
                    <DropdownSupportGroup
                      properties={fieldProps['TeamId'].properties}
                      items={teams}
                      handler={(value: any, event?: string) => onTeamChange(value, event)}
                      value={templateData['TeamId']}
                      header={'FriendlyName'}
                      setSearchTeam={setSearchTeam}
                      searchQuery={searchTeam}
                      onSearchQueryChange={onChangeTeamQuery}
                    />
                  </div>
                </CheckFeature>
                <div className={`pt-2`}>
                  <Text content={t('template.fields.group')}></Text>
                  <DropdownSupportGroup
                    properties={fieldProps['SupportGroupId'].properties}
                    items={filteredGroups}
                    handler={(value: any, event?: string) => onSupportGroupChange(value, event)}
                    value={templateData['SupportGroupId']}
                  />
                </div>
                <div className={`pt-2`}>
                  <Text content={t('template.fields.assignee')}></Text>
                  <DropdownAssignee
                    setAssigneeFullName={setAssigneeFullName}
                    searchQuery={assigneeFullName}
                    onSearchQueryChange={(searchQuery: string) => setAssigneeFullName(searchQuery)}
                    properties={fieldProps['AssigneeId'].properties}
                    assignees={filteredUsers}
                    handler={(value: any, event?: string) => onAssigneeChange(value, event)}
                    value={templateData['AssigneeId']}
                  />
                </div>
              </div>
            );
            break;
          case 'DueDate':
            let selectedDate = templateData[key] == null ? null : new Date(templateData[key]);
            let selectedRelativeDate = templateData["RelativeDueDate"] ?? templateData["RelativeDueDate"];
            let selectedValueDate = ((selectedRelativeDate?.length ?? 0) > 0) ? selectedRelativeDate : selectedDate?.toString() ?? null;
            field = <DatePicker
              dateValue={selectedValueDate}
              disableValueField={newprops.properties?.disabled}
              onChangeHandler={value => {
                if (value) {
                  let inx = templateData.errorFieldsArray.indexOf(key);
                  templateData.errorFieldsArray.splice(inx, 1);
                } else {
                  pushOnErrorFields(key);
                }
                if(dateType == "value"){
                  const dateValue = new Date(value);
                  if(!isNaN(dateValue.getDate()))
                    setDateField(key, dateValue);
                }else
                  setRelativeDateField(key, value);
              }}
              setRelativeDateType={setDateType}
              isTemplateField
              isTemplateFieldError={fieldProps[key]?.properties?.error}
            />
            break;
          case 'Tags':
            field = TicketTagPicker(globalTheme, key,tagSearch,  newprops.properties?.disabled, isCreatingTag, tags, TAGS.SELECT_TAGS,searchTag,addTag,selectTag,onSearchQueryChange);
            break;
          case 'LifecycleId':
            field = (
              <div>
                <CheckFeature featureName={LICENSE_FEATURE.TicketLifecycle}>
                  <div>
                    <DropdownSupportGroup
                      properties={fieldProps['LifecycleId'].properties}
                      items={mappedLifecycles}
                      handler={(value: any, event?: string) => onLifecycleChange(value, event)}
                      value={templateData['LifecycleId']}
                    />
                  </div>
                </CheckFeature>
              </div>
            );
            break;
          case 'TicketTypeId':
            field = (
              <NestedDropdown
                items={convertItemsArray(ticketTypes)}
                dataItem={{
                  id: templateData.TicketType?.Id,
                  parentId: templateData.TicketType?.ParentId,
                  position: templateData.TicketType?.Position,
                  value: templateData.TicketType?.Value,
                  ...templateData.TicketType
                }}
                defaultValue={newprops.properties?.default}
                onChange={(value: any, event: string = 'onChange') => {
                  nestedDropdownValueChange(
                    key,
                    value?.Id,
                    event,
                    ticketTypes,
                    'TicketType'
                  );
                }}
                disabled={newprops.properties?.disabled}
                showClearBtn={true}
                placeholder={newprops.properties?.placeholder}
                error={fieldProps[key]?.properties?.error}
              />
            );
            break;
          default:
            break;
        }
        return (
          <div key={ `template-field-${key}`} id={ `template-field-${key}`}>
          <CheckFeature featureName={newprops.feature ?? LICENSE_FEATURE.ValidSubscription}>
            <Flex>
              {label}
              {isFieldChecked && (
                <span style={{ color: '#c4314b', marginLeft: 2 }}>*</span>
              )}
            </Flex>
            {field}
            {newprops.warning && (
              <Flex gap={"gap.small"}>
                <InfoIcon outline style={{color: '#ff7a00', marginRight: 5, alignSelf: 'center'}}/>
                <Text content={(<div>{newprops.warning}</div>)} style={{color: '#ff7a00'}}/>
              </Flex>
            )}
          </CheckFeature>
          </div>
        );
      }
    });
  }

  
  return (
    <FluentUIThemeConsumer
      render={globalTheme => {

        return (
          <div
            className={`${(templateData.Id > 0) ? '' : 'overflow-auto'} pb-5`}
            style={{
              height: (templateData.Id > 0) ? 'inherit' : '50vh',
              ...ThemeColorScheme(globalTheme.siteVariables)
            }}
          >
            <div className={MgtCss.ticketForm}>{renderFields(globalTheme)}</div>
          </div>
        );
      }}
    />
  );
};

export default TemplateFieldsForm;
