import React, { useContext, useState, useEffect } from 'react';
import { Dropdown, Divider } from '@fluentui/react-northstar';
import AppCSS from '../App.module.css';
import { inputItems } from './ticketHelper';
import { AppStateContext, useSetState } from '../AppState';
import { getCachedFeature } from '../shared/cache/FeatureCache';
import { LICENSE_FEATURE } from '../shared/utils/constants';
import { useTranslation } from 'react-i18next';

export const BoardMenu = () => {
  const setState = useSetState();
  const state = useContext(AppStateContext);
  const [laneFilter, setLaneFilter] = useState(0);
  const [groupByItems, setGroupByItems] = useState(getInputItemValues(inputItems));
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const enabledMultiDepartment = await getCachedFeature(LICENSE_FEATURE.MultiDepartment);
      if(!enabledMultiDepartment){
        const clonedInputItems = {...inputItems};
        delete clonedInputItems.team;
        setGroupByItems(getInputItemValues(clonedInputItems));
      }
    })();
  }, []);

  return (
    <>
      <Divider
        vertical
        color="gray"
        style={{ height: '40px', marginTop: '4px' }}
      />
      <Dropdown
        items={groupByItems}
        triggerButton={<span>{t('ticket.ticket-board.group-by-filter.title', {status: groupByItems[laneFilter]})}</span>}
        fluid
        className={`${AppCSS.dropdownItem} h-12 dropdown-btn`}
        defaultValue={state.boardFilter}
        onChange={(event, props) => {
          var active =
            typeof props.highlightedIndex == 'undefined'
              ? 0
              : props.highlightedIndex;
          setLaneFilter(active);
          setState(s => {
            return {
              ...s,
              boardFilter: props.highlightedIndex
            };
          });
        }}
      />
      <Divider
        vertical
        color="gray"
        style={{ height: '40px', marginTop: '4px' }}
      />
    </>
  );
};

export function getInputItemValues(inputItems: IInputItems): string[] {
  return Object.values(inputItems)
}