import React from 'react';
import {
  Flex,
  Text,
  CalendarIcon,
  PaperclipIcon,
  ProviderConsumer as FluentUIThemeConsumer
} from '@fluentui/react-northstar';
import TicketSVG from '../../../../../svg/ticket.svg';
import CommentSVG from '../../../../../svg/comments.svg';
import CollaboratorIcon from '../../../../../svg/collaboratorIcon.svg';
import AffectedUserIcon from '../../../../../svg/affected-user.svg';
import { DateTime } from 'luxon';
import { formatAffectedUserLikeCollaborators, threedigitized } from '../../../ticketHelper';
import { TicketStatus } from '../../../../shared/components/TicketCardTemplate';
import { UsersTooltip } from '../../../../shared/components/TicketDetailInfo/UsersTooltip';
import { CheckFeature } from '../../../../shared/components/CheckFeature';
import { LICENSE_FEATURE } from '../../../../shared/utils/constants';
import { DisplayLifecycleWithPhases } from '../../../../shared/components/TicketDetailInfo/displayLifecyclePhases/DisplayLifecycleWithPhases';
import { ConvertDateToLocale } from '../../../../shared/common/ConvertDate';
import { useTranslation } from 'react-i18next';
import { appState } from '../../../../AppState';

interface Props {
  ticketData: Ticket;
  attachmentsCount: number;
  commentsCount: number;
}

export const RequestDetailCard = ({ ticketData, commentsCount, attachmentsCount }: Props) => {
  const { t } = useTranslation();
  const currentState = appState();
  return (
    <FluentUIThemeConsumer
      render={globalTheme => (
        <Flex
          column
          gap="gap.small"
          style={{
            backgroundColor: globalTheme.siteVariables.colorScheme.default.background,
            padding: '1.56rem',
            marginBottom: '1rem',
            boxShadow: '0px 0.3px 0.9px rgba(0, 0, 0, 0.07), 0px 1.6px 3.6px rgba(0, 0, 0, 0.11)',
            borderRadius: '4px'
          }}
        >
          <Flex space="between" vAlign="center">
            <Flex vAlign="center">
              <TicketSVG width={24} className={`mr-3`} />#
              {threedigitized(ticketData['Id'])}
            </Flex>
            <TicketStatus status={ticketData.Status} />
          </Flex>

          <Text weight="semibold" className="break-all">{ticketData.Title}</Text>

          {ticketData?.TicketLifecycle?.Id && (
            <div>
              <DisplayLifecycleWithPhases ticketData={ticketData} isAnalyst={false} />
            </div>
          )}
          <Flex
            vAlign="center"
            space="between"
            style={{ paddingTop: '16px', borderTop: '1px solid lightgrey' }}
          >
            <Flex vAlign="center" gap="gap.small">
              <Flex vAlign="center" gap="gap.smaller">
                <span className={`hidden md:inline`}>
                  <CalendarIcon className={`text-gray-500`} />
                </span>
                <Text size="small" className={`text-gray-500 ml-2`}>
                  {t('requests.modified')}:
                </Text>
              </Flex>
              <Flex>
                {ConvertDateToLocale(ticketData.ModifiedDate, DateTime.DATETIME_MED)}
              </Flex>
            </Flex>

            <Flex vAlign="center" gap="gap.smaller">

              <CheckFeature featureName={LICENSE_FEATURE.Collaborators}>
                <Flex vAlign="center">
                  <CollaboratorIcon width={12} height={12} style={{ margin: '0 auto', color: '#8f90a6' }} />
                  <UsersTooltip ticketCollaborator={ticketData?.TicketCollaborators.map(y => currentState.platformusers.find(p => p.Id == y.PlatformUserId))} title={t('ticket.ticket-list.collaborators')} />
                </Flex>
              </CheckFeature>

              <CheckFeature featureName={LICENSE_FEATURE.AffectedUsers}>
                <Flex vAlign="center">
                  <AffectedUserIcon width={12} height={12} style={{ margin: '0 auto', color: '#8f90a6' }}/>
                  <UsersTooltip ticketCollaborator={ticketData?.AffectedUsers.map(y => currentState.platformusers.find(p => p.Id == y.PlatformUserId))} title={t('ticket.ticket-list.affected-users')} />
                </Flex>
              </CheckFeature>
              <Flex vAlign="center">
                <PaperclipIcon size="smaller" style={{ color: '#8f90a6' }} />
                <Text size="small" style={{ color: '#8f90a6', marginLeft: '4px' }}>
                  {attachmentsCount ?? 0}
                </Text>
              </Flex>

              <Flex vAlign="center">
                <CommentSVG
                  width={12}
                  height={12}
                  style={{ margin: '0 auto', color: '#8f90a6' }}
                />
                <Text size="small" style={{ color: '#8f90a6', marginLeft: '4px' }}>
                  {commentsCount ?? 0}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
    />
  );
};
