import React, { useState, useEffect } from 'react';
import { TToolbarInteraction } from '@fluentui/react-teams/lib/esm/components/Toolbar/Toolbar';
import {
  ProviderConsumer,
  Flex,
  Button,
  AddIcon
} from '@fluentui/react-northstar';
import { SettingsMenu } from './Menu';
import { Toolbar } from './Toolbar';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs } from './BreadcrumbNavigation';
import ConfigCSS from '../../config/config.module.css';
import { BUTTONS, PRODUCT_LICENSE, SECURITY, SETTINGS } from '../utils/constants';
import { toTitleCase } from '../utils/helper';
import { checkInTeams } from '../../App';
import { getCachedFeature } from '../cache/FeatureCache';
import { toolbarButtonColor } from '../../tikit/ticketHelper';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  routeName: string;
  breadcrumbs: Breadcrumbs;
  children?: React.ReactNode;
  mode: 'list' | 'edit';
  queryId?: number;
  saveData?: () => void;
  isDisabled?: boolean;
  NavbarTopPadding?: string;
  newButtonHandler?: () => void;
}

export const SettingsPageWrapper: React.FC<Props> = ({
  title,
  routeName,
  breadcrumbs,
  children,
  mode,
  queryId,
  saveData,
  isDisabled,
  NavbarTopPadding,
  newButtonHandler
}) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [isUserLicensed, setIsUserLicensed] = useState<boolean>(false);
  const history = useHistory();
  const { t } = useTranslation();

  const onToolbarInteraction = (interaction: TToolbarInteraction) => {
    if (interaction.action === 'toggle-menu') {
      setMenuOpen(!menuOpen);
    }
  };

  useEffect(() => {
    (async () => setIsUserLicensed((await getCachedFeature(PRODUCT_LICENSE.AnalystAccess))))();
  }, []);

  return (
    <ProviderConsumer
      render={globalTheme => (
        <>
          {mode === 'list' ? (
            <Toolbar
              globalTheme={globalTheme}
              title={`${toTitleCase(SETTINGS)} > ${title}`}
              breadcrumbs={breadcrumbs}
              addNew={true}
              onInteraction={onToolbarInteraction}
              addNewContent={
                <Button
                  icon={<AddIcon />}
                  text
                  primary
                  disabled={!isUserLicensed}
                  content={`${routeName === SECURITY.SECURITY ? t('common.add') : t('common.new')} ${title}`}
                  onClick={() => {
                    if (newButtonHandler) {
                      newButtonHandler();
                    } else {
                      const path = `/settings/${routeName}?id=0`;
                      history.push(path);
                    }
                  }}
                  style={{
                    color: toolbarButtonColor(isUserLicensed, globalTheme.siteVariables.colorScheme),
                    height: '52px'
                  }}
                />
              }
            />
          ) : (
            <Toolbar
              onInteraction={onToolbarInteraction}
              globalTheme={globalTheme}
              title={`${toTitleCase(SETTINGS)} > ${title}`}
              saveText={
                queryId !== 0
                  ? toTitleCase(BUTTONS.UPDATE)
                  : toTitleCase(BUTTONS.SAVE)
              }
              saveCommand={saveData}
              breadcrumbs={breadcrumbs}
              disableSave={!isDisabled}
            />
          )}
          <Flex className={ConfigCSS.configBody}>
            <Flex.Item
              grow
              styles={{
                paddingTop: NavbarTopPadding ? NavbarTopPadding : '1.25rem',
                overflow: 'auto'
              }}
            >
              <div>{children}</div>
            </Flex.Item>
          </Flex>
        </>
      )}
    />
  );
};
