import { Flex, Text } from '@fluentui/react-northstar';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const AccessDenied = () => {
    const {t} = useTranslation();
    return (
        <Flex hAlign="center" vAlign="center" column className={`h-screen`}>
            <Flex hAlign="center" vAlign="center">
                <Text className={`m-0`} size="largest">{t('common.access-denied')}</Text>
            </Flex>
        </Flex>
    )
}