import React, { useCallback, useEffect, useState } from 'react';

import { platformService } from '../shared/services/platform.service';
import { SignedInState } from '../shared/services/signInState.service';
import '../AppGlobals';
import {
  DEFAULT_API_STATUS,
  DONE,
  ERROR,
  LOADING,
  PRIORITY
} from '../shared/utils/constants';
import { Modal } from '../shared/components/Modal/Modal';
import { CheckLogin } from '../shared/components/CheckLogin';

interface Props {
  id: string;
  open: boolean;
  onCancel: () => void;
  onSuccessFullCall: () => void;
}

export const Priority: React.FC<Props> = ({
  id,
  open,
  onCancel,
  onSuccessFullCall
}) => {
  const api = new platformService();
  const queryId = parseInt(id);

  const [priorityDetails, setPriorityDetails] = useState<PriorityDetail>();
  const [priorityName, setPriorityName] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [apiStatus, setAPIStatus] = useState<APIStatus>(
    DEFAULT_API_STATUS as APIStatus
  );

  const updateAPIStatus = (newState: Partial<APIStatus>) => {
    setAPIStatus({ ...apiStatus, ...newState });
  };

  const fetchPriorityDetails = useCallback(async () => {
    updateAPIStatus({
      msg: PRIORITY.FETCHING_PRIORITY_DETAILS,
      status: LOADING
    });

    try {
      const { data } = await api.getPriority(`(${queryId})`);
      setPriorityDetails(data);
      setPriorityName(data.Value);
      setIsDefault(data.IsDefault);
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    } finally {
      updateAPIStatus({ status: DONE });
    }
  }, [queryId]);

  const fetchPriorityDefaultInstance = useCallback(async () => {
    updateAPIStatus({
      msg: PRIORITY.FETCHING_PRIORITY_DEFAULT_INSTANCE,
      status: LOADING
    });

    try {
      const {
        data: { value }
      } = await api.getDefaultInstance(`?v=${new Date().getTime()}`);
      const parsedValue = JSON.parse(value);
      const requiredProps: PriorityDetail = (({
        Value,
        Position,
        IsArchived,
        IsDefault,
        IsSystem,
        ParentId,
        Id,
        ModifiedDate,
        ModifiedById,
        CreatedDate,
        CreatedById,
        Guid,
        IsDeleted
      }) => ({
        Value,
        Position,
        IsArchived,
        IsDefault,
        IsSystem,
        ParentId,
        Id,
        ModifiedDate,
        ModifiedById,
        CreatedDate,
        CreatedById,
        Guid,
        IsDeleted
      }))(parsedValue);

      setPriorityDetails(requiredProps);
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    } finally {
      updateAPIStatus({ status: DONE });
    }
  }, []);

  const [isCheckedIn, setIsCheckedIn] = useState<boolean>(false);

  const loadPage = () => {
    setIsCheckedIn(true);
  }

  useEffect(() => {
    if (isCheckedIn) {
      if (queryId > 0 && open) fetchPriorityDetails();
      else fetchPriorityDefaultInstance();
    }
  }, [isCheckedIn, queryId, open]);

  const checkAndUndefaultOther = async (currentDefaultId: number) => {
    try {
      const {
        data: { value }
      } = await api.getPriority(`?v=${new Date().getTime()}`);

      const defaultIdList: number[] = value
        .filter(
          (val: PriorityDetail) =>
            val.IsDefault && val.IsDefault && val.Id !== currentDefaultId
        )
        .map((value: PriorityDetail) => value.Id);

      if (defaultIdList.length > 0) {
        for (const id of defaultIdList) {
          const payload: UpdatePriorityPayload = {
            Id: id.toString(),
            IsDefault: false
          };

          await api.updatePriority(payload);
        }
      }
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    }
    // no finally block to avoid the flicker effect because finally sets the status to done
  };

  const createPriority = async (value: string, defaultCheck: boolean) => {
    const payload: PriorityDetail = {
      ...priorityDetails,
      Value: value,
      IsDefault: defaultCheck
    };
    updateAPIStatus({
      msg: PRIORITY.CREATING_NEW_PRIORITY,
      status: LOADING
    });

    try {
      const {
        data: { Id }
      } = await api.createPriority(payload);

      if (defaultCheck) {
        await checkAndUndefaultOther(Id);
      }

      onCancel();
      onSuccessFullCall();
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    }
    // no finally block to avoid the flicker effect because finally sets the priority to done
  };

  const updatePriority = async (value: string, defaultCheck: boolean) => {
    const payload: UpdatePriorityPayload = {
      Value: value,
      Id: priorityDetails.Id.toString(),
      IsDefault: defaultCheck
    };
    updateAPIStatus({
      msg: PRIORITY.UPDATING_PRIORITY,
      status: LOADING
    });

    try {
      await api.updatePriority(payload);

      if (defaultCheck) {
        await checkAndUndefaultOther(priorityDetails.Id);
      }

      onCancel();
      onSuccessFullCall();
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    }
    // no finally block to avoid the flicker effect because finally sets the priority to done
  };

  const saveData = async (value: string, defaultCheck: boolean) => {
    if (queryId > 0) updatePriority(value, defaultCheck);
    else createPriority(value, defaultCheck);
  };

  const closeModal = () => {
    setPriorityDetails(null);
    setPriorityName('');
    setIsDefault(false);

    onCancel();
  };

  return (
    <CheckLogin onSignedIn={loadPage}>
      <Modal
        apiStatus={apiStatus}
        name={PRIORITY.PRIORITY}
        open={open}
        isUpdate={queryId > 0}
        inputValue={priorityName}
        checkBoxValue={isDefault}
        onCancelHandler={closeModal}
        onConfirmationHandler={(inputValue, checkBoxValue) => {
          saveData(inputValue, checkBoxValue);
        }}
      />
    </CheckLogin>
  );
};
