import { Button, CloseIcon, ProviderConsumer as FluentUIThemeConsumer, Loader, } from '@fluentui/react-northstar';
import React from 'react';
import { getFileIcon, getTruncatedFilename } from '../../EditTicketAttachments/NewAttachmentFlow/NewAttachmentFlowContent';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

interface Props {
    onRemoveFile: (index: number) => void;
    file: File;
    id: number;
    isRemoveDisabled: boolean;
    isUploading: boolean;
    percentComplete: number;
    isInProgress: boolean;
}

const FileAttachments: React.FC<Props> = ({ isUploading, file, onRemoveFile, isRemoveDisabled, id, percentComplete, isInProgress }: Props) => {

    const removeFile = (index: number) => {
        onRemoveFile(index);
    }
    const extension = file.name.split(".").pop();

    return (
        <FluentUIThemeConsumer
            render={globalTheme => (
                <>
                    <div className={`flex justify-between items-center my-1 py-2 pl-2 border border-tneutral-200 mr-2 mb-2 shadow-sm rounded bg-tneutral-100`} key={id}>
                        {isUploading && 
                            (isInProgress ? <div style={{width: '1.5rem', height: '1.5rem'}}><CircularProgressbar value={percentComplete} strokeWidth={10} styles={buildStyles({ pathColor: globalTheme.siteVariables.colorScheme.brand.foreground, trailColor: "rgba(0,0,0,0)" })} /></div> :  
                            <Loader size='smaller' />)}
                        {!isUploading && getFileIcon(extension, file, globalTheme)}
                        <span className='px-2 whitespace-nowrap'>{getTruncatedFilename(file.name)}</span>
                        {!isUploading && <Button disabled={isRemoveDisabled} iconOnly text icon={<CloseIcon size='small' />} onClick={() => removeFile(id)} />}
                    </div>
                </>
            )} />
    )
}

export default FileAttachments;