import {
  Box,
  Breadcrumb,
  Button,
  ChevronEndMediumIcon,
  Flex,
  Divider,
  ProviderConsumer as FluentUIThemeConsumer,
  Skeleton,
  ArrowLeftIcon
} from '@fluentui/react-northstar';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { EditTicketActions } from './EditTicketActions';
import { threedigitized } from '../../tikit/ticketHelper';
import { useTranslation } from 'react-i18next';

export interface BreadCrumbItemProps {
  title: string;
  link: string;
}

interface ETTProps {
  onInteraction?: (interaction: any) => void;
  ticketData: Ticket;
  ticketId: number;
  currentUserId?: number;
  disabledSave: boolean;
  breadcrumbItems: BreadCrumbItemProps[];
  edittingState: boolean;
  isItemSelected?: boolean;
  isUserLicensed: boolean;
  mergedTicketIds: number[];
}

export const EditTicketToolbar = (props: ETTProps) => {
  const {t} = useTranslation();
  const history = useHistory();

  const onSave = () => {
    if (props.onInteraction) props.onInteraction({ event: 'saveTicket' });
  };

  const actions = action => {
    props.onInteraction({ event: action.event, data: action.data });
  };

  return (
    <FluentUIThemeConsumer
      render={globalTheme => (
        <Flex
          vAlign="center"
          style={{ height: '48px' }}
          className={`py-2`}
        >
          <Flex.Item grow>
            <Flex>
              <Box>
                <div className={`hidden md:flex`}>
                  <Breadcrumb
                    aria-label="breadcrumb"
                    style={{ height: '48px', marginRight: '.5rem', marginLeft: '.75rem' }}
                  >
                    {props.breadcrumbItems.map((v, _i) => {
                      let clickLink = e => {
                        e.preventDefault();
                        history.push(v.link);
                      };

                      return (
                        <Breadcrumb.Item>
                          <Breadcrumb.Link
                            {...(v.link !== null && {
                              ...{
                                onClick: clickLink,
                                href: '/',
                                style: { cursor: 'pointer' }
                              }
                            })}
                          >
                            {v.title}
                          </Breadcrumb.Link>
                        </Breadcrumb.Item>
                      );
                    })}
                    <Breadcrumb.Divider>
                      <ChevronEndMediumIcon />
                    </Breadcrumb.Divider>
                    <Breadcrumb.Item aria-current="page">
                      <strong>
                        {t('ticket-details.toolbar.edit-ticket')} #{threedigitized(props.ticketId)}
                      </strong>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
                <div className={`md:hidden action-button pt-2`}>
                  <Flex vAlign="center">
                    <Button
                      onClick={() => {
                        history.goBack();
                      }}
                      className={`mb-1`}
                      content={<ArrowLeftIcon size="large" />}
                      text
                      iconOnly
                      style={{
                        color:
                          globalTheme.siteVariables.colorScheme.brand
                            .foregroundActive
                      }}
                    />
                  </Flex>
                </div>
              </Box>
              <Box>
                <Divider vertical />
              </Box>
              {props.currentUserId != null && !props.ticketData.Closed && (
                <>
                  {!props.isItemSelected ? (
                    <Box className={`flex-grow`}>
                      <EditTicketActions
                        ticketData={props.ticketData}
                        ticketId={props.ticketId}
                        edittingState={props.edittingState}
                        currentUserId={props.currentUserId}
                        onInteraction={actions}
                        mergedTicketIds={props.mergedTicketIds}
                        isUserLicensed={props.isUserLicensed}
                      />
                    </Box>
                  ) : (
                    <Box className={`flex-grow`}>
                      <div className={`mt-3 ml-2`}>
                        <Skeleton animation="wave">
                          <Skeleton.Line width="20%" height="24px" />
                        </Skeleton>
                      </div>
                    </Box>
                  )}
                </>
              )}
            </Flex>
          </Flex.Item>
        </Flex>
      )}
    />
  );
};
