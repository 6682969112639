import React, { useCallback, useEffect, useState } from 'react';
import Styles from '../../../../Automation.module.css';
import { Button, Dropdown, FormLabel, ProviderConsumer as FluentUIThemeConsumer } from '@fluentui/react-northstar';
import { platformService } from '../../../../../shared/services/platform.service';
import {
  DEFAULT_API_STATUS,
  DONE,
  ERROR
} from '../../../../../shared/utils/constants';
import { splitString, toFriendlyName } from '../../../../../shared/utils/helper';
import { useTranslation } from 'react-i18next';

interface Props {
  copyGlobalAction: () => void;
  globalAction: string;
  updateGlobalAction: (value: string) => void;
}

export const GlobalActions = ({
  copyGlobalAction,
  globalAction,
  updateGlobalAction
}: Props) => {
  const {t} = useTranslation();
  const api = new platformService();
  const [apiStatus, setAPIStatus] = useState<APIStatus>(
    DEFAULT_API_STATUS as APIStatus
  );

  const [globalActions, setGlobalActions] = useState<DropdownDataModel[]>([]);

  const fetchGlobalActions = useCallback(async () => {
    try {
      const {
        data: { value }
      } = await api.getGlobalActions();

      const globalActions: DropdownDataModel[] = value.map((val: any) => ({
        key: val['Title'],
        label: splitString(val['Title']),
        header: splitString(val['Title'])
      }));

      setGlobalActions(globalActions);
    } catch (err: any) {
      setAPIStatus({
        ...apiStatus,
        status: ERROR,
        errCode: err.response.status
      });
    } finally {
      setAPIStatus({ ...apiStatus, status: DONE });
    }
  }, []);
  
  useEffect(() => {
    fetchGlobalActions();
  }, []);

  return (
    <FluentUIThemeConsumer
      render={globalTheme => (
        <>
        <FormLabel content={t('automation.global-actions')} styles={{ fontSize: '12px' }} />
      <div className={Styles.GlobalRules}>
        <div className={Styles.GlobalRules1}>
          <Dropdown
            inverted
            fluid
            items={globalActions}
            placeholder={t('automation.global-action')}
            value={globalAction == '' ? globalAction : toFriendlyName(globalAction)}
            style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
            onChange={(e, p) => {
              const data = p.value as DropdownDataModel;
              updateGlobalAction(data.key);
            }}
          />
        </div>
        <div className={Styles.GlobalRules2}>
          <Button
            content={
              <span style={{ color: globalAction === '' ? '#e5e5e5' :  `${globalTheme.siteVariables.colorScheme.brand.foreground}` }}>
                {t('automation.copy')}
              </span>
            }
            text
            primary
            disabled={globalAction === ''}
            onClick={() => {
              copyGlobalAction();
            }}
          />
        </div>
      </div>
        </>
        )}
        ></FluentUIThemeConsumer>
  );
};
