import { Header } from '@fluentui/react-northstar';
import React from 'react';
import { SECURITY } from '../shared/utils/constants';
import { RoleItem } from './RoleItem/RoleItem';
import { applicationRolesList } from './Security';
import Styles from './Security.module.css';
import i18next from 'i18next';

interface Props {
  activeRole: string;
  applicationRoles: Record<string, string[]>;
}

export const SecurityLeftPanel = ({ activeRole, applicationRoles }: Props) => (
  <div className={Styles.SecurityLeftSide}>
    <Header
      as="h2"
      content={SECURITY.APPLICATION_ROLES}
      className="font-normal pl-3 pb-2 capitalize"
    />
    {applicationRolesList.map((role: string) => (
      <RoleItem
        title={role === "analysts" ? "Super Agents" : (role === "knowledgeagents" ? "Knowledge Agents" : role)}
        role={role}
        mappedRolesCount={applicationRoles[role].length}
        active={activeRole === role}
      />
    ))}
  </div>
);
