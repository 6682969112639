import {
    Datepicker,
    pxToRem,
    Flex,
    Dropdown,
    Text,
    TextArea,
    ProviderConsumer as FluentUIThemeConsumer,
    Button,
    Loader,
    Dialog,
    CloseIcon,
    ThemePrepared,
    ComponentEventHandler,
    DatepickerProps,
    ShorthandValue,
    InputProps,
    ShorthandCollection,
    DropdownItemProps
  } from '@fluentui/react-northstar';
  import React, { useRef, useState } from 'react';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { customDateFormatter } from '../../../tikit/ticketHelper';
import { useTranslation } from 'react-i18next';



export const TicketTagPicker = (globalTheme: ThemePrepared<any>, key:string, tagSearch:React.MutableRefObject<any>, isDisabled:boolean, isCreatingTag:boolean, tagList: any[], placeholder:string,
  searchTag:string, addTagFunction, selectTagFunction, onSearchQueryChange) =>{

    const isTagNameEmpty = () => 
            searchTag == null ||
            searchTag == '' ||
            searchTag.trim() == '';

    const { t } = useTranslation();
                              
    return (<Dropdown
    disabled={isDisabled}
    items={tagList}
    fluid
    placeholder={placeholder}
    multiple
    search
    noResultsMessage={
      
      <div style={{ textAlign: 'center' }}>
        {(searchTag.charAt(0) == ' ' || searchTag.charAt(searchTag.length - 1) == ' ') && (
          <Text error content={t('ticket.add-ticket.tags.tags-error-message')} />
        )}
        {searchTag.charAt(0) != ' ' && searchTag.charAt(searchTag.length - 1) != ' ' && (
            <>
              {isCreatingTag && (
                <Loader label={t('ticket.add-ticket.tags.save-tag')} />
              )}
              {!isCreatingTag && (
                <>
                  <div>
                    {!isTagNameEmpty() && (<Text content={`${t('ticket.add-ticket.tags.new-tag')} : '${searchTag}'`} />)}
                  </div>
                  <Button
                    disabled={isTagNameEmpty()}
                    content={'Add Tag?'}
                    primary
                    className={`mt-3`}
                    onClick={addTagFunction}
                  />
                </>
              )}
            </>
          )}
      </div>
      
      
    }
    a11ySelectedItemsMessage={t('ticket.add-ticket.tags.selected-items-message')}
    onChange={(e, p) => {selectTagFunction(e,p,key)}}
    onSearchQueryChange={onSearchQueryChange}
    value={tagList.filter(t => t.selected)}
    searchInput={{
      inline: true,
      inputRef: tagSearch,
      styles: { paddingLeft: '8px', height: '32px' }
    }}
    style={{backgroundColor: globalTheme.siteVariables.colorScheme.default.background2 }}
  />);

}