import { toast, ToastContent, ToastOptions } from 'react-toastify';

toast.configure({newestOnTop: true});
const defaultOptions = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
} as ToastOptions

const Toast = {
    success: (content:ToastContent) => toast.success(content, defaultOptions),
    error: (content:ToastContent) => toast.error(content, defaultOptions),
    info: (content:ToastContent) => toast.info(content, defaultOptions),
    dark: (content:ToastContent) => toast.dark(content, defaultOptions),
    warn: (content:ToastContent) => toast.warn(content, defaultOptions),
}

export { Toast }