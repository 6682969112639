import { AppStateValue } from "../../AppState";
import { platformService } from "../services/platform.service";

const api = new platformService();

const checkData = (id: number, cachedData: any[]) => cachedData?.find(x => x.Id === id);

export const retrieveStatus = async (id: number, cachedData: any[]) => checkData(id, cachedData) ?? (await api.getStatus(`(${id})`))?.data;
export const retrieveCategory = async (id: number, cachedData: any[]) => checkData(id, cachedData) ?? (await api.getCategories(`(${id})`))?.data;
export const retrievePriority = async (id: number, cachedData: any[]) => checkData(id, cachedData) ?? (await api.getPriority(`(${id})`))?.data;
export const retrieveUser = async (id: number, cachedData: any[]) => checkData(id, cachedData) ?? (await api.getPlatformUser(id))?.data;
export const retrieveTeam = async (id: number, cachedData: any[]) => checkData(id, cachedData) ?? await api.getTeamSafe(id);
export const retrieveSupportGroup = async (id: number, cachedData: any[]) => checkData(id, cachedData) ?? (await api.getSupportGroups(`(${id})`))?.data;
export const retrieveTicketType = async (id: number, cachedData: any[]) => checkData(id, cachedData) ?? await api.getTicketTypeSafe(id);
export const retrieveSource = async (id: number, cachedData: any[]) => checkData(id, cachedData) ?? (await api.getSource(`(${id})`))?.data;

export const retrieveTicketInformation = async (data: any, currentState: AppStateValue) => {
  if (data.StatusId)
    data.Status = await retrieveStatus(data.StatusId, currentState.ticketStatus);
  if (data.CategoryId)
    data.Category = await retrieveCategory(data.CategoryId, currentState.categories);
  if (data.PriorityId)
    data.Priority = await retrievePriority(data.PriorityId, currentState.priority);
  if (data.AssigneeId)
    data.Assignee = await retrieveUser(data.AssigneeId, currentState.platformusers);
  if (data.RequesterId)
    data.Requester = await retrieveUser(data.RequesterId, currentState.platformusers);
  if (data.SourceId)
    data.Source = await retrieveSource(data.SourceId, currentState.source);
  if (data.TeamId)
    data.Team = await retrieveTeam(data.TeamId, currentState.teams);
  if (data.SupportGroupId)
    data.SupportGroup = await retrieveSupportGroup(data.SupportGroupId, currentState.supportgroups);
  if (data.TicketTypeId)
    data.TicketType = await retrieveTicketType(data.TicketTypeId, currentState.ticketTypes);

  return data;
};