import React, { useState } from 'react';
import { Flex, Dialog, CloseIcon, Button, TextArea, Text } from '@fluentui/react-northstar';
import { CheckLogin } from '../CheckLogin';
import { LIFECYCLE } from '../../utils/constants';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onModalClose: () => void;
  onExecutePhase: (transitionMessage: string) => void;
}

export const TicketLifecyclePhaseTranstionDialog = ({
  open,
  onModalClose,
  onExecutePhase
}: Props) => {
  const { t } = useTranslation(); 
  const [transitionMessage, setTransitionMessage] = useState("");

  const modalClose = () => {
    onModalClose();
  }

  const executePhase = () => {
    onExecutePhase(transitionMessage);
    onModalClose();
  }

  return (
    <CheckLogin>
      <Dialog
        className={`taskDialog`}
        content={(<div className={`mt-2`} style={{ marginBottom: '-0.25rem' }}>
            <Text content={LIFECYCLE.TRANSITION_REASON}/>
            <TextArea
                fluid
                maxLength={500}
                required
                style={{ height: 60 }}
                onChange={(_e, data) => {
                  setTransitionMessage(data.value);
                }}
              />
          </div>)}
        open={open}
        headerAction={{
          icon: <CloseIcon />,
          title: t('common.buttons.close'),
          onClick: modalClose
        }}
        footer={
          <Flex gap="gap.small" className={``}>
            <Flex.Item push>
              <Button
                content={t('common.buttons.cancel')}
                onClick={() => {
                  modalClose();
                }}
              />
            </Flex.Item>
              <Button
                className="dialog-btn"
                content={t('common.buttons.ok')}
                primary
                onClick={() => {
                  executePhase();
                }}
              />
          </Flex>
        }
      />
    </CheckLogin>
  );
};
