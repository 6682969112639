import axios from "axios";
const DEFAULT_CHUNK_SIZE = 320 * 1024 * 3; // This has to be in Increments of 320KiB, do not change the 320 * 1024

export class uploadService {
  uploadFile = async (uploadUrl: string, file: any, progressCallback: (uploaded: number, totalBytes: number) => void) => {
    const item = (file.FileSize) ? file.file : file;

    let minRange = 0;
    let maxRange = DEFAULT_CHUNK_SIZE - 1;
    if (maxRange > item.size)
      maxRange = item.size - 1;

    while (true) {
      const headers = {
        "Content-Range": `bytes ${minRange}-${maxRange}/${item.size}`,
      };
      const client = axios.create({ baseURL: uploadUrl, headers: headers });
      const response = await client.put('', item.slice(minRange, maxRange + 1));

      // Upon completion of upload process incase of onedrive, driveItem is returned, which contains id
      if (response.data.id !== undefined) {
        return response;
      } else {
        const firstRange = response.data.nextExpectedRanges[0].split("-");
        minRange = parseInt(firstRange[0], 10);
        maxRange = minRange + DEFAULT_CHUNK_SIZE - 1;
        if (maxRange > item.size)
          maxRange = item.size - 1;

        if (progressCallback) progressCallback(minRange, item.size);
      }
    }
  }
}