import React, { useEffect, useState } from "react";
import { Prompt } from "react-router-dom";
import {  i18n } from '../../../components/shared/utils/helper';
const { t } = i18n();

const useUnsavedChangesWarning = (message = t('common.unsaved')) => {

    const [isDirty, setDirty] = useState<boolean>(false);

    useEffect(() => {
        window.onbeforeunload = isDirty && (() => message);
        return () => window.onbeforeunload = null;
    }, [isDirty]);

    const routerPrompt = <Prompt when = {isDirty} message = {message} />

    return [routerPrompt, setDirty];
}

export default useUnsavedChangesWarning;