import React from 'react';
import { Alert, Button, Flex, InfoIcon, ProviderConsumer, Text } from '@fluentui/react-northstar';
import UpgradeSVG from './../../../svg/upgrade.svg';
import { Link, useHistory } from 'react-router-dom';
import { Toolbar } from './Toolbar';
import { Breadcrumbs } from './BreadcrumbNavigation';
import { useTranslation } from 'react-i18next';

interface Props {
  toolbarText?: string;
  breadcrumbs?: Breadcrumbs;
  headerText: string;
  subheaderText?: string;
  style?: React.CSSProperties;
  svgSize?: number;
}

export const UpgradeNeeded = (props: Props) => {
  const {t} = useTranslation();
  const useStyle = (props.toolbarText && props.toolbarText !== '') ? { marginTop: '-1.25rem', height: 'calc(100vh - 110px)' } : { marginTop: '-1.25rem' };

  return (
    <ProviderConsumer render={globalTheme => (
      <>
        {props.toolbarText && props.toolbarText !== '' && (<Toolbar globalTheme={globalTheme} title={`${props.toolbarText}`} breadcrumbs={props.breadcrumbs} />)}
        {/* Main Section */}
        <Flex style={useStyle} >
          <Flex.Item grow styles={{ paddingTop: '1.25rem', overflow: 'auto' }}>
            <div style={{ padding: 10 }}>
              <div className={'flex flex-col justify-around h-full w-full'} style={props.style}>
                <div className="align-center flex justify-around">
                  <div className="flex flex-col items-center">
                    <UpgradeSVG height={props.svgSize ?? 200} width={props.svgSize ?? 200} />
                    <Text size="large" weight="bold" content={props.headerText} />
                    <Text size="medium" weight="regular" content={props.subheaderText} />

                    <Link to="/settings/billing">
                      <a href="/settings/billing" className={`cursor-pointer px-4 py-2 block`}>
                        <Button text primary content={t('common.upgrade-needed.go-to-billing')} style={{ color: globalTheme.siteVariables.colorScheme.brand.foreground, height: '52px' }} />
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Flex.Item>
        </Flex>
      </>)}
    />);
};

export const FeatureConsentNeeded = () => {
  const {t} = useTranslation();
  const history = useHistory();
  return (
    <Alert warning visible dismissible className="mb-3"
    actions={[{
      content: t('common.upgrade-needed.grant-consent'),
      key: 'privacy',
      onClick: () => { history.push('/settings/feature-config'); }
    }]}
    icon={<InfoIcon outline />}
    content={<Text>{t('common.upgrade-needed.administrator-enable')}</Text>} />
  );
}
