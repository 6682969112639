import React from 'react';
import {
    Flex,
    ProviderConsumer as FluentUIThemeConsumer,
} from '@fluentui/react-northstar';
import { Nav } from '../browser-pages/browser-components/Nav';
import ExpiredSVG from '../../svg/expired.svg';
import { useTranslation } from 'react-i18next';

export const SubscriptionExpired = () => {
    const { t } = useTranslation();

    return (
        <FluentUIThemeConsumer
            render={globalTheme => (
                <>
                    <Nav />
                    <div className={`h-screen`}>
                        <Flex

                            style={{
                                height: 'calc(100vh - 50px)',
                                overflow: 'auto',
                                paddingBottom: '20px'
                            }}
                        >
                            <Flex className='justify-center' style={{ margin: 'auto' }}>
                                <div className='text-center'>
                                    <ExpiredSVG width={150} height={150} style={{margin: "auto"}}/>
                                    <h1 className="font-bold text-2xl mb-3 mt-16">{t('billing.subscription-expired')}</h1>
                                </div>
                            </Flex>
                        </Flex>
                    </div>
                </>
            )}
        />
    );
};
