import React, { useEffect, useState, useCallback } from 'react';
import {
  ProviderConsumer,
  Loader,
  Button,
  Flex,
  Menu,
  Text,
  ThemePrepared
} from '@fluentui/react-northstar';
import { Communication, TToolbarInteraction } from '@fluentui/react-teams';
import ConfigCSS from '../config/config.module.css';
import { Providers } from '@microsoft/mgt-element/dist/es6/providers/Providers';
import { Breadcrumbs } from '../shared/components/BreadcrumbNavigation';
import { Toolbar } from '../shared/components/Toolbar';
import {
  TEMPLATES,
  NEW_TEMPLATE,
  SETTINGS,
  DEFAULT_API_STATUS,
  GETTING_TEMPLATE_DATA,
  ERROR,
  DONE,
  LOADING,
  SAVING_TEMPLATE_DATA,
  TEMPLATE,
  FILTERS_STRINGS,
  STATUS_GUID,
  LICENSE_FEATURE,
  UNAUTHORIZED_ACCESS,
  SOMETHING_WENT_WRONG,
  CONTACT_ADMIN,
  REFRESH_SESSION,
  SAVE
} from '../shared/utils/constants';
import { toTitleCase } from '../shared/utils/helper';
import { Step_DefineFields } from './Step_DefineFields';
import { TicketTemplate } from '../shared/interfaces/ticket.interface';
import { platformService } from '../shared/services/platform.service';
import { useHistory } from 'react-router-dom';
import { AdaptiveCardDesigner } from './AdaptiveCardDesigner';
import { TemplateFormStateProps } from './TemplateFieldsForm';
import { CheckLogin } from '../shared/components/CheckLogin';
import { Helmet } from 'react-helmet';
import { MobileSettingsView } from '../automation/AutomationTable';
import { loadFilters } from '../tikit/ticketHelper';
import { appState, useSetState } from '../AppState';
import { graphService } from '../shared/services/graph.service';
import { getCachedFeature } from '../shared/cache/FeatureCache';
import _, { cloneDeep } from 'lodash';
interface ITicketTemplateProperties {
  id: string;
  menu?: string;
}

export interface ITemplateFormProperties {
  headerContent?: JSX.Element;
  templateData: TicketTemplate;
  dispatchData?: any;
  formData?: TemplateFormStateProps;
  isSelectionView?: boolean;
  defaultValues?: any;
}

export const getUpdatedData = (apiData: TicketTemplate) => {
  if (apiData.AssigneeId == -1) {
    delete apiData.AssigneeId;
    apiData.UnsetAssignee = true;
  }
  if (apiData.SupportGroupId == -1) {
    delete apiData.SupportGroupId;
    apiData.UnsetSupportGroup = true;
  }
  if (apiData.TeamId == -1) {
    delete apiData.TeamId;
    apiData.UnsetTeam = true;
  }
  if (apiData.CategoryId == -1) {
    delete apiData.CategoryId;
    apiData.UnsetCategory = true;
  }
  if (apiData.TicketTypeId == -1) {
    delete apiData.TicketTypeId;
    apiData.UnsetTicketType = true;
  }
  const removeProps = [
    'isCardValid',
    'AssigneeName',
    'CategoryName',
    'TicketTypeName',
    'PriorityName',
    'SupportGroupName',
    'TeamName',
    'StatusName',
    'hidden',
    'errorFieldsArray',
    'errorFormsArray',
    'validateFields',
    'validateForms',
    'Category',
    'Status',
    'TicketType',
    'Priority'
  ];
  removeProps.forEach(prop => {
    delete apiData[prop];
  });

  return apiData;
};

export const TicketTemplates = (props: ITicketTemplateProperties) => {
  const api = new platformService();
  const currentState = appState();
  const history = useHistory();
  const setState = useSetState();
  const graphAPI = new graphService();
  const [errCode, setErrCode] = useState(0);
  const [isValidating, setIsValidating] = useState(false);
  const [apiStatus, setApiStatus] = useState<APIStatus>(DEFAULT_API_STATUS as APIStatus);
  const defaultData = { errorFieldsArray: [] } as TicketTemplate;
  const [data, dispatchData] = React.useReducer(
    (state: TicketTemplate, newState) => {
      state = newState;
      return { ...state, ...newState };
    },
    defaultData
  );
  const [originalData, setOriginalData] = useState(defaultData);
  const [menu, setMenu] = useState<number>(
    props.menu && props.menu.length > 0 ? parseInt(props.menu) : 0
  );
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [isCustomFormFeatureEnabled, setIsCustomFormFeatureEnabled] = useState<boolean>(false);
  const [templateProps, setTemplateProps] = useState<TemplateFormStateProps>(
    {} as TemplateFormStateProps
  );

  const onToolbarInteraction = (interaction: TToolbarInteraction) => {
    if (interaction.action === 'toggle-menu') {
      setMenuOpen(!menuOpen);
    }
  };

  const fetchData = useCallback(async () => {
    setApiStatus({ ...apiStatus, msg: GETTING_TEMPLATE_DATA });

    try {
      const {data: result} = await api.getTicketTemplates( `(${props.id})?$expand=Status,Category,Priority,TicketType,Tags,AffectedUsers,Collaborators`);
      //result.Tags

      let tags = JSON.parse(JSON.stringify(result.Tags));
      result.isCardValid = true;
      result.errorFieldsArray = [];
       if (result['TeamId'] || result['SupportGroupId'] || result['AssigneeId'] ||
           result.UnsetAssignee || result.UnsetTeam || result.UnsetSupportGroup) {
          if (!result['TeamId']) result.UnsetTeam = true;
          if (!result['SupportGroupId']) result.UnsetSupportGroup = true;
          if (!result['AssigneeId']) result.UnsetAssignee = true;
      }

      dispatchData(result);
      setOriginalData(result);
      const results = await Promise.all([
        api.getLifecyclesSafe(`${FILTERS_STRINGS.FILTERS_URL_PARAMS}&$select=Id,Title`),
        (currentState?.ticketStatus?.length > 0) ? Promise.resolve(currentState) : loadFilters(api, graphAPI, currentState, setState),
        getCachedFeature(LICENSE_FEATURE.CustomForms)
      ]);
      let filterResults = results[1];
      const lifecycles = results[0];
      filterResults["lifecycles"] = lifecycles;

      filterResults.tags.forEach((t:any) => {
        t.selected = tags.some(x=>x.Id==t.Id);
      });
      setIsCustomFormFeatureEnabled(results[2]);
      setTemplateProps(filterResults as TemplateFormStateProps);
    } catch (err) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: err['response']?.status
      });
    } finally {
      setApiStatus({ ...apiStatus, status: DONE });
    }
  }, []);

  const loadPage = () => {
    fetchData();
  };

  const errConfig = {
    fields: {
      title: errCode == 401 ? UNAUTHORIZED_ACCESS : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  };

  const saveDataAsync = async () => {
    setApiStatus({ ...apiStatus, msg: SAVING_TEMPLATE_DATA, status: LOADING });

    try {
      const apiData = getUpdatedData(data);
      if (props.id && props.id.length > 0 && parseInt(props.id) > 0)
        await api.updateTicketTemplate(apiData);
      else 
        await api.createTicketTemplate(apiData);
    } catch (err: any) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: err.response.status
      });
    } finally {
      setApiStatus({ ...apiStatus, status: DONE });
      history.push(`/settings/templates`);
    }
  };

  const isFieldsValid = menu => {
    
    if (data.NoCustomForm) menu = 0;

    if (menuItems[menu].key == 'fields') {
      if (!(data && data.Name))
        data.errorFieldsArray.push('Name');
      if(data && data.LifecycleId && data.Status){
        if(data.Status.Guid === STATUS_GUID.RESOLVED || data.Status.Guid === STATUS_GUID.CLOSED)
          data.errorFieldsArray.push("LifecycleStatusConflict");
      }
      if (data && data.errorFieldsArray.length > 0) return false;
    }

    return true;
  };

  const saveData = async () => {
    setIsValidating(true);
    if (!isFieldsValid(menu)) {
      data.validateFields = true;
      dispatchData(data);
      setIsValidating(false);
    } else {
      const { data: { value } } = await api.getTicketTemplates(`?$filter=Name eq '${data.Name.replace(/'/ig, "''")}'`);

      const result = value || [];
      if (result?.some(x => x.Id != props.id)) {
        data.validateFields = true;
        data.errorFieldsArray.push('DupeName');
        dispatchData(data);
        setIsValidating(false);
      } else {
        setIsValidating(false);
        saveDataAsync();
      }
    }
  };

  const countInput = (items, cnt) => {
    for (let element of items) {
      if (element.type.includes("Input"))
        cnt++;
      if (element.type == "Container" || element.type == "Column"){
        if(element.items)
          cnt = countInput(element.items, cnt);
      }
      if (element.type == "ColumnSet"){
        if(element.columns)
          cnt = countInput(element.columns, cnt);
      }
    }
    return cnt;
  }

  const disableSave = () => {  
    if (!(data && data.isCardValid))
      return true;
      
    if (data.NoCustomForm)
      return false;

    if (data.CardResponseJson?.length < 1)
      return true;
    
    let customFormData = JSON.parse(data.CardResponseJson);
    if (!customFormData || !customFormData.body)
      return true;

    if(customFormData.body.length > 0){
      let inputCount = 0;
      inputCount = countInput(customFormData.body, inputCount);
      if(inputCount == 0)
        return true;
    }

    const currentData = getUpdatedData(cloneDeep(data));
    const initialData = getUpdatedData(originalData);

    return _.isEqual(currentData, initialData);
  };

  const navs: Breadcrumbs = {
    breadcrumbs: [
      {
        title: toTitleCase(SETTINGS),
        link: `/settings`
      },
      {
        title: toTitleCase(TEMPLATES),
        link: `/settings/templates`
      },
      {
        title:
          data['Name'] && data['Name'].length > 0
            ? data['Name']
            : toTitleCase(NEW_TEMPLATE),
        link: ''
      }
    ]
  };

  let menuItems = [
    {
      key: 'fields',
      content: TEMPLATE.TEMPLATE_HEADER,
      description: ''
    }
  ];

  if(isCustomFormFeatureEnabled && !data.NoCustomForm)
    menuItems.push({
      key: 'forms',
      content: TEMPLATE.CUSTOM_FORM,
      description: TEMPLATE.CREATE_CUSTOM_FORM
    });

  const HeaderElement = (props: any) => {
    return (
      <div>
        <Flex styles={{ paddingTop: '1.75rem' }}>
          <Text content={props.title} weight="bold" size="large" />
        </Flex>
        {props.description && (
          <Flex styles={{ padding: '0.5rem 0' }}>
            <Text content={props.description} />
          </Flex>
        )}
      </div>
    );
  };

  const menuIndexChange = (e, props: any) => {
    if (!isFieldsValid(menu)) {
      data.validateFields = true;
      dispatchData(data);
    } else {
      setMenu(props.activeIndex);
    }
  };

  const renderMenu = () => {
    let selectedMenu = menu;
    if (data.NoCustomForm && selectedMenu != 0){
      selectedMenu = 0;
      menuItems.pop();
    }

    const menuItem = menuItems[selectedMenu];
    const headerContent = (
      <HeaderElement
        title={menuItem.content}
        description={menuItem.description}
      />
    );
    switch (menuItem.key) {
      case 'fields':
        return (
          <Step_DefineFields
            headerContent={headerContent}
            templateData={data}
            formData={templateProps}
            dispatchData={dispatchData}
          />
        );
      case 'forms':
        return (
          <AdaptiveCardDesigner
            templateData={data}
            dispatchData={dispatchData}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <CheckLogin onSignedIn={loadPage}>
      <Helmet>
        <title>{toTitleCase(TEMPLATES)} - Tikit</title>
      </Helmet>
      <div className="hidden md:block">
        {apiStatus.status === LOADING ? (
          <Loader label={apiStatus.msg} styles={{ height: '100vh' }} />
        ) : apiStatus.status === ERROR ? (
          <>
            <Communication {...errConfig} />
            {apiStatus.errCode === 401 && (
              <Button
                content={REFRESH_SESSION}
                primary
                onClick={e => {
                  Providers.globalProvider.login();
                }}
              />
            )}
          </>
        ) : (
          <ProviderConsumer
            render={globalTheme => (
              <>
                <Toolbar
                  onInteraction={onToolbarInteraction}
                  globalTheme={globalTheme}
                  title=""
                  breadcrumbs={navs}
                  saveText={SAVE}
                  saveCommand={saveData}
                  saveLoading={isValidating}
                  saveLoadingText={'Validating'}
                  disableSave={disableSave()}
                />

                <Flex className={ConfigCSS.configTemplateBody}>
                  <Flex.Item>
                    <Menu
                      activeIndex={menu}
                      items={menuItems}
                      vertical
                      pointing
                      onActiveIndexChange={menuIndexChange}
                      styles={({ theme: { siteVariables } }) => ({
                        height: '100%',
                        paddingTop: '1.5rem',
                        backgroundColor:
                          siteVariables.colorScheme.default.background2,
                        borderRight: `solid 1px ${siteVariables.colorScheme.default.border}`
                      })}
                    />
                  </Flex.Item>
                  <Flex.Item>
                    <Flex
                      fill
                      className={`pr-3`}
                      styles={({ theme: { siteVariables } }) => ({
                        backgroundColor:
                          siteVariables.colorScheme.default.background,
                        overflow: 'auto',
                        padding: '0 1.25rem'
                      })}
                    >
                      {renderMenu()}
                    </Flex>
                  </Flex.Item>
                </Flex>
              </>
            )}
          />
        )}
      </div>
      <MobileSettingsView />
    </CheckLogin>
  );
};
