import {
  ActionType,
  RuleCriteriaOperator,
  RuleGroupType,
  TriggerState,
  ScheduleFrequency,
  RuleCriteriaEventActionType,
  AUTOMATION_PROPERTIES,
  ENTITY_TYPE,
  AUTOMATION_EMAIL
} from '../../shared/utils/constants';


const PropertyMapper = {
  Request: 'Title',
  'New Comment': 'Comments',
  'Group': 'SupportGroup'
};

export const getGroupFilters = (
  groupFilters: AutomationFilter[],
  isCreate: boolean = true
): RuleCriteria[] =>
  groupFilters.map(
    (filter: AutomationFilter) =>
      ({
        EvaluationType: RuleCriteriaEventActionType[filter.evaluationType],
        Property:
          PropertyMapper[filter.property] ??
          filter.property.replaceAll(' ', ''),
        Operator: RuleCriteriaOperator[filter.operator],
        Operator2: RuleCriteriaOperator[filter.operator2],
        Value: filter.value,
        Value2: filter.value2,
        GroupId: isCreate ? 0 : filter.GroupId,
        Id: isCreate ? 0 : filter.Id
      } as RuleCriteria)
  );

export const getChildGroupData = (
  childGroupData: AutomationChildrenGroup[],
  ifGroupData: AutomationGroup,
  isCreate: boolean = true
): ChildrenGroup[] =>
  childGroupData.map((childGroup: AutomationChildrenGroup) => ({
    Type: RuleGroupType[childGroup.type],
    RuleEntitySectionId: (isCreate) ? null : ifGroupData.RuleEntitySectionId,
    ParentGroupId: null,
    Id: isCreate ? 0 : +childGroup.id,
    RuleCriteria: getGroupFilters(childGroup.filters, isCreate)
  } as any));

export const getActionProperties = (
  actionProperties: AutomationDoTicketInfo[],
  isCreate: boolean = true
): PropertyValuePair[] =>
  actionProperties.map((property: AutomationDoTicketInfo) => ({
    Property:
      PropertyMapper[property.Property] ??
      property.Property.replaceAll(' ', ''),
    Value: property.Value,
    ActionId: isCreate ? 0 : property.ActionId,
    Id: isCreate ? 0 : +property.Id
  }));

export const prepareCreatePayload = (
  infoData: AutomationInfoData,
  whenData: AutomationWhenData,
  ifGroupData: AutomationGroup,
  ifChildGroupData: AutomationChildrenGroup[],
  doData: AutomationDoTickets
): AutomationCreatePayload => ({
  Title: infoData.title,
  Description: infoData.description,
  TriggerId: 0,
  Enabled: infoData.enabled,
  Id: 0,
  EntityName: whenData.entityName,
  Trigger: {
    Id: 0,
    OnEvent: whenData.onEvent,
    TriggerType: TriggerState[whenData.triggerType],
    OnSchedule: whenData.onSchedule,
    RepeatFrequency: ScheduleFrequency[whenData.scheduleFrequency]
  },
  Steps: [
    {
      Id: 0,
      Order: 1,
      StepId: 0,
      Step: {
        '@odata.type': '#ServiceDesk.Core.Models.AnalysisEngine.Rule',
        Title: ifGroupData.ruleName,
        Id: 0,
        EntitySections: [
          {
            Id: 0,
            Disabled:ifGroupData.disabled,
            Groups: [
              {
                Type: RuleGroupType[ifGroupData.type],
                RuleEntitySectionId: 0,
                ParentGroupId: null,
                Id: 0,
                RuleCriteria: getGroupFilters(ifGroupData.filters)
              } as any as RuleGroup
            ].concat(getChildGroupData(ifChildGroupData, ifGroupData) as any as RuleGroup) as RuleGroup[]
          }
        ]
      }
    },
    {
      Id: 0,
      Order: 2,
      StepId: 0,
      Step: {
        '@odata.type': '#ServiceDesk.Core.Models.AnalysisEngine.Action',
        Title: doData.actionName,
        Id: 0,
        ActionType: ActionType[doData.actionType],
        PropertyValuePair: getActionProperties(doData.items),
        ActionNotification: doData.actionNotification,
        ActionNotificationId: doData.actionNotificationId,
        ActionApplyTemplate: doData.actionApplyTemplate,
        ActionApplyTemplateId: doData.actionApplyTemplate.Id ?? 0
      }
    }
  ]
});

export const getTranslateAutomationProperties =(propertyName: string, entityName: string) => 
  (([ENTITY_TYPE.Lifecycle.toString(),ENTITY_TYPE.Approval.toString(),ENTITY_TYPE.Task.toString()].includes(entityName)) && propertyName === "title") ? AUTOMATION_PROPERTIES.approvaltitle : AUTOMATION_PROPERTIES[propertyName]

export const setActionNotification = (action:AutomationDoNotificationInfo) => {
  action.NotifyTikitMessageHeader = action.NotifyTikitMessageHeader ?? AUTOMATION_EMAIL.DEFAULT_SUBJECT;
  action.NotifyTikitMessage = action.NotifyTikitMessage ?? AUTOMATION_EMAIL.DEFAULT_BODY;

  action.NotifyTVAMessageHeader = action.NotifyTVAMessageHeader ?? AUTOMATION_EMAIL.DEFAULT_SUBJECT;
  action.NotifyTVAMessage = action.NotifyTVAMessage ?? AUTOMATION_EMAIL.DEFAULT_BODY;
  return action;
}

export const prepareUpdatePayload = (
  phaseEditData: AutomationEditData,
  infoData: AutomationInfoData,
  whenData: AutomationWhenData,
  ifGroupData: AutomationGroup,
  ifChildGroupData: AutomationChildrenGroup[],
  doData: AutomationDoTickets
): AutomationCreatePayload => ({
  Title: infoData.title,
  Description: infoData.description,
  TriggerId: phaseEditData.TriggerId,
  Enabled: infoData.enabled,
  Id: phaseEditData.Id,
  EntityName: whenData.entityName,
  Trigger: {
    Id: phaseEditData.TriggerId,
    OnEvent: whenData.onEvent,
    TriggerType: TriggerState[whenData.triggerType],
    OnSchedule: whenData.onSchedule,
    RepeatFrequency: ScheduleFrequency[whenData.scheduleFrequency]
  },
  Steps: [
    {
      Id: phaseEditData.OrderOneStep.Id,
      Order: 1,
      StepId: phaseEditData.OrderOneStep.StepId,
      Step: {
        '@odata.type': '#ServiceDesk.Core.Models.AnalysisEngine.Rule',
        Title: ifGroupData.ruleName,
        Id: phaseEditData.OrderOneStep.StepId,
        EntitySections: [
          {
            Id: +ifGroupData.id,
            Disabled:ifGroupData.disabled,
            Groups: [
              {
                Type: RuleGroupType[ifGroupData.type],
                RuleEntitySectionId: ifGroupData.RuleEntitySectionId,
                ParentGroupId: null,
                Id: +ifGroupData.id,
                ChildrenGroups: [],
                RuleCriteria: getGroupFilters(ifGroupData.filters, false)
              } as any as RuleGroup
            ].concat(getChildGroupData(ifChildGroupData, ifGroupData, false) as any[]) as RuleGroup[]
          }
        ]
      }
    },
    {
      Id: phaseEditData.OrderTwoStep.Id,
      Order: 2,
      StepId: phaseEditData.OrderTwoStep.StepId,
      Step: {
        '@odata.type': '#ServiceDesk.Core.Models.AnalysisEngine.Action',
        Title: doData.actionName,
        Id: phaseEditData.OrderTwoStep.StepId,
        ActionType: ActionType[doData.actionType],
        PropertyValuePair: getActionProperties(doData.items, false),
        ActionNotification: setActionNotification(doData.actionNotification),
        ActionNotificationId: doData.actionNotificationId ?? doData.actionNotification?.Id,
        ActionApplyTemplate: doData.actionApplyTemplate,
        ActionApplyTemplateId: doData.actionApplyTemplate.Id ?? 0
      }
    }
  ]
});
