import React from "react";
import { TextArea, Text, ProviderConsumer as FluentUIThemeConsumer, Flex } from "@fluentui/react-northstar";
import { LifecyclePowerAutomateTask } from "../utils/typings";
import { PlatformUser } from "../../shared/interfaces/platformuser.interface";
import { ThemeColorScheme } from "../../shared/common/TeamsTheme";
import '../styles/Styles.css';
import { LIFECYCLE } from '../../../components/shared/utils/constants'

interface Props{
    taskData: LifecyclePowerAutomateTask,
    setTaskData: (data: LifecyclePowerAutomateTask) => void;
    userList: PlatformUser[];
}

export const LabelElement = (props: any) => {
  return (
    <div>
      <Flex>
        <Text content={props.label} style={{ display: 'block' }} />
        {props.required && (
          <span style={{ color: 'red', marginLeft: 2 }}> * </span>
        )}
      </Flex>
      <div>{props.children}</div>
    </div>
  );
};

export const PhasePowerAutomateTaskForm = ({taskData,setTaskData, userList}:Props) => {
    return (
      <FluentUIThemeConsumer
      render={globalTheme => (
          <div
            className="min-h-32"
            style={{
              color: 'var(--mgt-theme-default-foreground)',
              ...ThemeColorScheme(globalTheme.siteVariables)
            }}
          >
          <div >
            <LabelElement label={LIFECYCLE.TITLE} required={true}>
                <TextArea maxLength={500} required fluid value={taskData.Name} onChange={(_e, p) => {setTaskData({...taskData, Name: p.value});}}/>
                <Text>{LIFECYCLE.POWERAUTOMATE_TASK_TITLE} <a style={{ color: 'rgb(98, 100, 167)'}} href="https://make.powerautomate.com/" target="_blank" rel="noopener noreferrer">{LIFECYCLE.POWERAUTOMATE_PORTAL}. </a></Text>
            </LabelElement>
          </div>
        </div>)} />
    ) 
};