import { Button, CloseIcon } from '@fluentui/react-northstar';
import React from 'react';

interface Props {
    show: boolean;
    message: string;
}

export const FileAlert: React.FC<Props> = (props: Props) => {
    const [isShown, setIsShown] = React.useState<boolean>(props.show);
    React.useEffect(() => {
        setIsShown(props.show);
    }, [props.show])
    return (
        <div className={`${isShown ? '': 'hidden'} bg-red-100 border-red-600 text-red-600 border flex items-center py-1 px-2 justify-between`}>
            <span>
                {props.message}
            </span>
            <Button iconOnly text size='small' icon={<CloseIcon size='small' className='text-red-600' />} />
        </div>
    )
}