import React, { useEffect } from 'react';
import Chart from 'chart.js';
import { SiteVariablesPrepared } from '@fluentui/react-northstar';
import { IChartData } from '../ChartTypes';
import {
  tooltipTrigger,
  tooltipAxisYLine,
  chartConfig,
  axesConfig,
  setTooltipColorScheme,
  hexToRgb,
  createChartId
} from '../ChartUtils';
import { TeamsTheme } from '@fluentui/react-teams/lib/esm/themes';
import { ChartContainer } from './ChartContainer';
import { lineChartPatterns } from '../ChartPatterns';
import { getText } from '../translations/';

export const LineChart = ({
  title,
  data,
  siteVariables,
  gradients
}: {
  title: string;
  data: IChartData;
  siteVariables: SiteVariablesPrepared;
  gradients?: boolean;
}) => {
  const { colorScheme, theme, t } = siteVariables;
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const chartRef = React.useRef<Chart | undefined>();
  const chartId = React.useMemo(createChartId, []);
  const chartDataPointColors = React.useMemo(
    () => [
      colorScheme.brand.background,
      colorScheme.default.borderHover,
      colorScheme.brand.borderHover,
      colorScheme.default.foreground2,
      colorScheme.brand.background4,
      colorScheme.default.foreground
    ],
    [theme]
  );

  const createDataPoints = (): Chart.ChartDataSets[] =>
    Array.from(data.datasets, (set, i) => {
      let dataPointConfig = {
        label: getText(t.locale, set.label),
        data: set.data,
        borderColor: chartDataPointColors[i],
        hoverBorderColor: chartDataPointColors[i],
        hoverBorderWidth: 2,
        backgroundColor: 'transparent',
        hoverBackgroundColor: 'transparent',
        borderWidth: 2,
        pointBorderColor: chartDataPointColors[i],
        pointBackgroundColor: chartDataPointColors[i],
        pointHoverBackgroundColor: chartDataPointColors[i],
        pointHoverBorderColor: chartDataPointColors[i],
        pointHoverBorderWidth: 0,
        borderCapStyle: 'round',
        borderJoinStyle: 'round',
        pointBorderWidth: 0,
        pointRadius: 2,
        pointHoverRadius: 2,
        pointStyle: 'circle',
        borderDash: []
      };
      if (theme === TeamsTheme.HighContrast) {
        dataPointConfig = {
          ...dataPointConfig,
          borderColor: colorScheme.brand.background,
          hoverBorderColor: colorScheme.default.borderHover,
          pointBorderColor: colorScheme.brand.background,
          pointBackgroundColor: colorScheme.brand.background,
          pointHoverBackgroundColor: colorScheme.brand.background,
          pointHoverBorderColor: colorScheme.brand.background,
          hoverBorderWidth: 4,
          pointRadius: 4,
          pointHoverRadius: 4,
          pointStyle: lineChartPatterns[i].pointStyle,
          borderDash: lineChartPatterns[i].lineBorderDash
        } as any;
      }
      return dataPointConfig as Chart.ChartDataSets;
    });

  const createAreaChartDataPoints = (
    ctx: CanvasRenderingContext2D
  ): Chart.ChartDataSets[] =>
    Array.from(data.datasets, (set, i) => {
      const gradientStroke = ctx.createLinearGradient(
        0,
        0,
        0,
        ctx.canvas.clientHeight * 0.8
      );
      const hoverGradientStroke = ctx.createLinearGradient(
        0,
        0,
        0,
        ctx.canvas.clientHeight * 0.8
      );
      if (theme === TeamsTheme.HighContrast) {
        const colorRGB = hexToRgb(colorScheme.brand.background);
        const hoverColorRGB = hexToRgb(colorScheme.default.borderHover);
        gradientStroke.addColorStop(0, `rgba(${colorRGB}, .2)`);
        gradientStroke.addColorStop(1, `rgba(${colorRGB}, .0)`);
        hoverGradientStroke.addColorStop(0, `rgba(${hoverColorRGB}, .4)`);
        hoverGradientStroke.addColorStop(1, `rgba(${hoverColorRGB}, .0)`);
      } else {
        const colorRGB = hexToRgb(chartDataPointColors[i]);
        gradientStroke.addColorStop(0, `rgba(${colorRGB}, .4)`);
        gradientStroke.addColorStop(1, `rgba(${colorRGB}, .0)`);
        hoverGradientStroke.addColorStop(0, `rgba(${colorRGB}, .6)`);
        hoverGradientStroke.addColorStop(1, `rgba(${colorRGB}, .0)`);
      }

      let dataPointConfig = {
        label: getText(t.locale, set.label),
        data: set.data,
        borderColor: chartDataPointColors[i],
        hoverBorderColor: chartDataPointColors[i],
        hoverBorderWidth: 2,
        backgroundColor: gradientStroke as any,
        hoverBackgroundColor: hoverGradientStroke as any,
        borderWidth: 2,
        pointBorderColor: chartDataPointColors[i],
        pointBackgroundColor: chartDataPointColors[i],
        pointHoverBackgroundColor: chartDataPointColors[i],
        pointHoverBorderColor: chartDataPointColors[i],
        pointHoverBorderWidth: 0,
        borderCapStyle: 'round',
        borderJoinStyle: 'round',
        pointBorderWidth: 0,
        pointRadius: 2,
        pointHoverRadius: 2,
        pointStyle: 'circle',
        borderDash: []
      };
      if (theme === TeamsTheme.HighContrast) {
        dataPointConfig = {
          ...dataPointConfig,
          borderColor: colorScheme.brand.background,
          hoverBorderColor: colorScheme.default.borderHover,
          pointBorderColor: colorScheme.brand.background,
          pointBackgroundColor: colorScheme.brand.background,
          pointHoverBackgroundColor: colorScheme.brand.background,
          pointHoverBorderColor: colorScheme.brand.background,
          hoverBorderWidth: 4,
          pointRadius: 4,
          pointHoverRadius: 4,
          pointStyle: lineChartPatterns[i].pointStyle,
          borderDash: lineChartPatterns[i].lineBorderDash
        } as any;
      }
      return dataPointConfig as Chart.ChartDataSets;
    });

  useEffect(() => {
    let selectedIndex = -1;
    let selectedDataSet = 0;

    if (canvasRef.current === null || canvasRef.current === undefined) return;
    const ctxCurrent = canvasRef.current.getContext('2d');
    if (ctxCurrent === null || ctxCurrent === undefined) return;
    chartRef.current = new Chart(ctxCurrent, {
      ...(chartConfig({ type: 'line' }) as any),
      data: {
        labels: Array.isArray(data.labels)
          ? data.labels.map(label => getText(t.locale, label))
          : getText(t.locale, data.labels),
        datasets: []
      },
      plugins: [
        {
          afterDatasetsDraw: ({ ctx, tooltip, chart }: any) => {
            tooltipAxisYLine({
              chart,
              ctx,
              tooltip
            });
          }
        }
      ]
    });

    const chartCurrent: any = chartRef.current;

    /**
     * Keyboard manipulations
     */
    function meta() {
      return chartCurrent.getDatasetMeta(selectedDataSet);
    }

    function removeFocusStyleOnClick() {
      canvasRef.current.style.boxShadow = 'none';
    }

    function removeDataPointsHoverStates() {
      meta().controller.removeHoverStyle(
        meta().data[selectedIndex > -1 ? selectedIndex : 0],
        0,
        selectedIndex
      );
    }

    function hoverDataPoint(pointID: number) {
      meta().controller.setHoverStyle(
        meta().data[pointID],
        selectedDataSet,
        pointID
      );
    }

    function showFocusedDataPoint() {
      hoverDataPoint(selectedIndex);
      tooltipTrigger({
        chart: chartRef.current,
        data,
        set: selectedDataSet,
        index: selectedIndex,
        siteVariables
      });
      document
        .getElementById(
          `${chartId}-tooltip-${selectedDataSet}-${selectedIndex}`
        )
        ?.focus();
    }

    function resetChartStates() {
      removeDataPointsHoverStates();
      const activeElements = chartCurrent.tooltip._active;
      const requestedElem =
        chartCurrent.getDatasetMeta(selectedDataSet).data[selectedIndex];

      const checkActiveElement = (i: number, compare: any): boolean => {
        if (requestedElem._index === compare) {
          activeElements.splice(i, 1);
          return true;
        }
        return false;
      };

      activeElements.find((v: any, i: number) => {
        return checkActiveElement(i, v._index);
      });

      for (let i = 0; i < activeElements.length; i++) {
        if (checkActiveElement(i, activeElements[i]._index)) break;
      }

      if (theme === TeamsTheme.HighContrast) {
        chartCurrent.data.datasets.map((dataset: any) => {
          dataset.borderColor = colorScheme.default.border;
          dataset.borderWidth = 2;
        });
        chartCurrent.update();
      }
      chartCurrent.tooltip._active = activeElements;
      chartCurrent.tooltip.update(true);
      chartCurrent.draw();
    }

    const setSelectedDataSet = e => {
      if (data.datasets.length > 1) {
        // Get all values for the current data point
        const values = data.datasets.map(
          dataset => dataset.data[selectedIndex]
        );
        // Sort an array to define next available number
        const sorted = [...Array.from(new Set(values))].sort(
          (a, b) => Number(a) - Number(b)
        );
        let arrowUp = e.key === 'ArrowUp' ? 1 : -1;
        let nextValue =
          sorted[
            sorted.findIndex(v => v === values[selectedDataSet]) + arrowUp
          ];

        // Find dataset ID by the next higher number after current
        let nextDataSet = values.findIndex(v => v === nextValue);

        // If there is no next number that could selected, get number from oposite side
        let sortIndex = e.key === 'ArrowUp' ? 0 : data.datasets.length - 1;
        nextDataSet =
          nextDataSet < 0
            ? values.findIndex(v => v === sorted[sortIndex])
            : nextDataSet;

        selectedDataSet = nextDataSet;
        selectedIndex = selectedIndex % meta().data.length;
      }
    };

    function changeFocus(e: KeyboardEvent) {
      removeDataPointsHoverStates();
      switch (e.key) {
        case 'ArrowRight':
          e.preventDefault();
          selectedIndex = (selectedIndex + 1) % meta().data.length;
          break;
        case 'ArrowLeft':
          e.preventDefault();
          selectedIndex = (selectedIndex || meta().data.length) - 1;
          break;
        case 'ArrowUp':
        case 'ArrowDown':
          e.preventDefault();
          setSelectedDataSet(e);
          break;
      }

      showFocusedDataPoint();
    }

    canvasRef.current.addEventListener('click', removeFocusStyleOnClick);
    canvasRef.current.addEventListener('keydown', changeFocus);
    canvasRef.current.addEventListener('focusout', resetChartStates);
    return () => {
      if (canvasRef.current) {
        canvasRef.current.removeEventListener('click', removeFocusStyleOnClick);
        canvasRef.current.removeEventListener('keydown', changeFocus);
        canvasRef.current.removeEventListener('focusout', resetChartStates);
      }
      chartRef.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (!chartRef.current) return;
    if (!canvasRef.current) return;
    const ctx = canvasRef.current.getContext('2d');
    if (!ctx) return;
    // Apply new colors scheme for data points
    chartRef.current.data.datasets = gradients
      ? createAreaChartDataPoints(ctx)
      : createDataPoints();
    // Update tooltip colors scheme
    setTooltipColorScheme({
      chart: chartRef.current,
      siteVariables,
      chartDataPointColors
    });
    // Update axeses
    axesConfig({ chart: chartRef.current, ctx, colorScheme });
    // Show style changes
    chartRef.current.update();
  }, [theme]);

  function onLegendClick(datasetIndex: number) {
    if (!chartRef.current) return;
    chartRef.current.data.datasets![datasetIndex].hidden =
      !chartRef.current.data.datasets![datasetIndex].hidden;
    chartRef.current.update();
  }

  return (
    <ChartContainer
      siteVariables={siteVariables}
      data={data}
      chartDataPointColors={chartDataPointColors}
      onLegendClick={onLegendClick}
    >
      <canvas
        id={chartId}
        ref={canvasRef}
        tabIndex={0}
        style={{
          userSelect: 'none'
        }}
        aria-label={title}
      >
        {data.datasets.map((set, setKey) =>
          (set.data as number[]).forEach((item: number, itemKey: number) => (
            <div key={itemKey} id={`${chartId}-tooltip-${setKey}-${itemKey}`}>
              <p>{item}</p>
              <span>
                {getText(t.locale, set.label)}: {set.data[itemKey]}
              </span>
            </div>
          ))
        )}
      </canvas>
    </ChartContainer>
  );
};
