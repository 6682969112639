import React, { useContext, useEffect, useState } from 'react';
import { AppStateContext } from '../../AppState';
import { SkeletonTable } from '../../shared/components/Skeleton';
import { TablePagination } from '../../shared/components/table/TablePagination';
import { TicketListBoard } from '../../shared/components/table/TicketListBoard';
import { Ticket } from '../../shared/interfaces/ticket.interface';
import { TICKET_PAGINATION, LICENSE_FEATURE } from '../../shared/utils/constants';
import { ListContext } from './ListContextProvider';
import ListFunctions from './ListFunctions';
import useCustomViewsTickets from './useCustomViewsTickets';
import { getCachedFeature } from '../../shared/cache/FeatureCache';

interface Props {
  onTicketsSelected: (tickets: Ticket[]) => void;
  defaultFilters: FilterPropsItems[];
}

export interface ListFeatureStates {
  useTicketType: boolean;
  useTeam: boolean;
  useTicketLifecycle: boolean;
  useSLA: boolean;
};

export const List = ({ onTicketsSelected, defaultFilters }: Props) => {
  const state = useContext(AppStateContext);
  const { listStates } = useContext(ListContext);
  const { moveToPage } = ListFunctions();
  const [listFeatureStates, setListFeatureStates] = useState<ListFeatureStates>();

  useCustomViewsTickets({ defaultFilters }); // Get tickets filtered by custom views

  const loadFeatureVars = async () =>{
    const featureResult = await Promise.all([
      getCachedFeature(LICENSE_FEATURE.TicketTypes),
      getCachedFeature(LICENSE_FEATURE.MultiDepartment),
      getCachedFeature(LICENSE_FEATURE.TicketLifecycle),
      getCachedFeature(LICENSE_FEATURE.ServiceLevelAgreements)
    ]);
    const enabled = featureResult[0];
    const enabledMultiDepartment = featureResult[1];
    const enabledTicketLifecycle = featureResult[2];
    const enabledSLA = featureResult[3];

    setListFeatureStates(prev => ({
      ...prev,
      useTicketType: enabled,
      useTeam: enabledMultiDepartment,
      useTicketLifecycle: enabledTicketLifecycle,
      useSLA: enabledSLA
    }));
  }

  useEffect(() => {
    if(listStates.isTicketsFetching)
      loadFeatureVars();
  }, [listStates])
  return (
    <>
      {listStates.isTicketsFetching && <SkeletonTable />}
      {!listStates.isTicketsFetching && (
        <>
          <div style={{ height: 'calc(100% - 37px)', overflow: 'auto' }}>
            <TicketListBoard
              appStateValue={state}
              filterDropdownCount={state.filterDropdownCount}
              onTicketsSelected={onTicketsSelected}
              isFilterApplied={state.isFilterApplied}
              listFeatureStates={listFeatureStates}
            />
          </div>
          <div style={{ height: '37px' }}>
            <TablePagination
              currentPage={listStates.currentPage}
              totalCount={listStates.countTickets}
              moveToPage={moveToPage}
              itemPerPage={TICKET_PAGINATION.ITEM_PER_PAGE}
            />
          </div>
        </>
      )}
    </>
  );
};
