import React, { useCallback, useEffect, useState } from "react";
import { Header, Text, Grid, Input, Flex, ProviderConsumer, Button, EyeSlashIcon, EyeIcon, Checkbox } from '@fluentui/react-northstar';
import { platformService } from '../shared/services/platform.service';
import { Communication, TToolbarInteraction } from '@fluentui/react-teams';
import { Providers } from '@microsoft/mgt-element';
import { Toolbar } from '../shared/components/Toolbar';
import ConfigCSS from './config.module.css';
import { Breadcrumbs } from "../shared/components/BreadcrumbNavigation";
import { CheckLogin } from "../shared/components/CheckLogin";
import { Helmet } from 'react-helmet';
import { LoadingScreen } from "../shared/components/LoadingScreen";
import { MobileSettingsView } from "../automation/AutomationTable";
import { ThemeColorScheme } from '../shared/common/TeamsTheme';
import { SAVE, REFRESH_SESSION, ADMINISTRATORS } from "../shared/utils/constants";
import { useTranslation } from "react-i18next";
import { getErrConfig } from "../shared/utils/helper";
import { appState  } from '../AppState';

interface IAiConfig {
    AiUri: string,
    AiDeployment: string,
    AiApiKey: string,
    SearchUri: string,
    SearchKey: string,
    SearchIndex: string,
    BlobConnectionString: string,
    AllowFileDownloads: boolean
}

export function AiConfig() {
    const { t } = useTranslation();
    const appContextState = appState();
    const api = new platformService();

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [errCode, setErrCode] = useState(0);
    const [isCheckedIn, setIsCheckedIn] = useState<boolean>(false);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [passwordInputTypes, setPasswordInputTypes] = useState<{[key: string]: string}>({
        api: 'password',
        search: 'password',
        blob: 'password'
    });
    const [showPasswords, setShowPasswords] = useState<{[key: string]: boolean}>({
        api: false,
        search: false,
        blob: false
    });

    const isAdmin = appContextState.userRoles.roles.includes(ADMINISTRATORS);
    const configurationSetting = 'ServiceDesk.Core.Integrations.Cognitive.AiConfiguration';

    const updateDeployment = event => setData({ ...data, AiDeployment: event.target.value });
    const updateApiUri = event => setData({ ...data, AiUri: event.target.value });
    const updateApiKey = event => setData({ ...data, AiApiKey: event.target.value });
    const updateSearchUri = event => setData({ ...data, SearchUri: event.target.value });
    const updateSearchKey = event => setData({ ...data, SearchKey: event.target.value });
    const updateSearchIndex = event => setData({ ...data, SearchIndex: event.target.value });
    const updateBlobConnectionSTring = event => setData({ ...data, BlobConnectionString: event.target.value });
    const updateAllowFileDownlads = (checked: boolean) => {
        setData({...data, AllowFileDownloads: checked});
    } 

    const [data, setData] = useState<IAiConfig>({
        AiUri: "",
        AiDeployment: "",
        AiApiKey: "",
        SearchIndex: "",
        SearchKey: "",
        SearchUri: "",
        BlobConnectionString: "",
        AllowFileDownloads: false
      });

    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const onToolbarInteraction = (interaction: TToolbarInteraction) => {
        if (interaction.action === "toggle-menu") {
            setMenuOpen(!menuOpen);
        }
    }

    useEffect(() => {
        setLoadingMessage(t('ai-configuration.loading-ai-configuration'));
        if (isCheckedIn)
            fetchSystemSetting();
    }, [isCheckedIn]);

    const fetchSystemSetting = useCallback(async () => {
        setLoadingMessage(t('ai-configuration.loading-ai-configuration'));

        const fetchResults = await Promise.all([
            api.getSystemSetting(configurationSetting)
        ]);
        setFetchedData(fetchResults[0]);
    }, []);

    const setFetchedData = (result: any) => {
        if (result.status === "rejected") {
            setIsError(true);
            setErrCode(result.reason.response ? result.reason.response.status : 500);
            setIsLoading(false);
        } else {
            setIsError(false);
            const config: IAiConfig = {
                AiUri: result.data.AiUri || "",
                AiDeployment: result.data.AiDeployment || "",
                AiApiKey: result.data.AiApiKey || "",
                SearchUri: result.data.SearchUri || "",
                SearchKey: result.data.SearchKey || "",
                SearchIndex: result.data.SearchIndex || "",
                BlobConnectionString: result.data.BlobConnectionString || "",
                AllowFileDownloads: result.data.AllowFileDownloads || false
          }

          if (config)
            setData(config);
          
          setIsLoading(false);
        }
    };

    const saveData = () => {
        setLoadingMessage(t('ai-configuration.updating-your-ai-settings'));
        setIsLoading(true);
        api.setSystemSetting(configurationSetting, data).finally(() => {
           setIsLoading(false);
        });
    };

    const navs: Breadcrumbs = {
        breadcrumbs: [
          {
            title: t('common.settings'),
            link: '/settings'
          },
          {
            title: t('ai-configuration.ai-configuration'),
            link: ""
          }
        ]
    };

    const loadPage = () => {
        if(!isAdmin){
          setIsError(true);
          setErrCode(401);
          setIsLoading(false);
        }
    
        setIsCheckedIn(true);
    };

    const toggleFieldIndicator = (field: string) => {
        setPasswordInputTypes(prevInputTypes => ({
            ...prevInputTypes,
            [field]: showPasswords[field] ? 'password' : 'text'
        }));
        setShowPasswords(prevShowPasswords => ({
            ...prevShowPasswords,
            [field]: !prevShowPasswords[field]
        }));
    };

    return (
        <CheckLogin onSignedIn={loadPage}>
            <Helmet>
                <title>{t('ai-configuration.title-page')}</title>
            </Helmet>
            <div className="hidden md:block">
            {
            (isLoading) ? <LoadingScreen message={loadingMessage} /> :
            (isError) ? (
                <ProviderConsumer render={(globalTheme) => (
                    <>
                    <Toolbar globalTheme={globalTheme} title={t('ai-configuration')} hideSave={true} breadcrumbs={navs} />
                    <Flex className={ConfigCSS.configBody}>
                        <Flex.Item grow>
                        <>
                            <Communication {...getErrConfig(errCode)} />
                            {errCode === 401 && (<Button content={REFRESH_SESSION} primary onClick={e => { Providers.globalProvider.login(); }} />)}
                        </>
                        </Flex.Item>
                    </Flex>
                </>)} />) :
                (
                    <ProviderConsumer render={(globalTheme) => (
                    <div style={{
                        display: 'block',
                        ...ThemeColorScheme(globalTheme.siteVariables)
                    }}>
                    <Toolbar onInteraction={onToolbarInteraction} globalTheme={globalTheme} title={t('ai-configuration-breadcrumb')} saveText={SAVE} saveCommand={saveData} breadcrumbs={navs} disableSave={false} />
                    <Flex className={ConfigCSS.configBody}>
                    <Flex.Item grow styles={{ height: "calc(90vh - 50px)" }}>
                    <Grid columns="auto"
                          styles={{
                            padding: '20px',
                            paddingTop: 0,
                            justifyContent: 'flex-start',
                            rowGap: '20px',
                            overflow: 'scroll'
                          }}>
                            <Header content={t('ai-configuration.open-ai')} />
                            <Header as="h2" className="pb-2 defaultStyle" content={t('ai-configuration.open-ai-deployment')} />
                            <div className="teams-input-med" style={{width: '500px'}}>
                                <Text content={t('ai-configuration.ai-api-uri-title')} className="mr-2" />
                                <Input
                                    clearable
                                    inverted
                                    placeholder={t('ai-configuration.enter-api-uri')}
                                    value={data.AiUri}
                                    onChange={updateApiUri}
                                    style={{marginTop: "5px"}}
                                    fluid />
                                <em>{t('ai-configuration.api-uri-em')}</em>
                            </div>
                            <div className="teams-input-med" style={{width: '500px'}}>
                                <Text content={t('ai-configuration.ai-api-deployment-title')} className="mr-2" />
                                <Input
                                    clearable
                                    inverted
                                    placeholder={t('ai-configuration.enter-api-deployment')}
                                    value={data.AiDeployment}
                                    onChange={updateDeployment}
                                    style={{marginTop: "5px"}}
                                    fluid />
                                <em>{t('ai-configuration.api-deployment-name-em')}</em>
                            </div>
                            <div className="teams-input-med" style={{width: '500px'}}>
                                <Text content={t('ai-configuration.ai-api-key-title')} className="mr-2" />
                                <Input
                                    inverted
                                    placeholder={t('ai-configuration.enter-api-key')}
                                    value={data.AiApiKey}
                                    onChange={updateApiKey}
                                    fluid
                                    style={{marginTop: "5px"}}
                                    type={passwordInputTypes.api} 
                                    icon={showPasswords.api ? <EyeSlashIcon onClick={() => toggleFieldIndicator('api')} /> : <EyeIcon onClick={() => toggleFieldIndicator('api')} />} />
                                <em>{t('ai-configuration.api-key-em')}</em>
                            </div>
                            <Header as="h2" className="pb-2 defaultStyle" content={t('ai-configuration.document-repository-deployment')} />
                            <div className="teams-input-med" style={{width: '500px'}}>
                             <Text content={t('ai-configuration.ai-search-uri-title')} className="mr-2" />
                                <Input
                                    clearable
                                    inverted
                                    placeholder={t('ai-configuration.enter-search-uri')}
                                    value={data.SearchUri}
                                    onChange={updateSearchUri}
                                    style={{marginTop: "5px"}}
                                    fluid />
                                <em>{t('ai-configuration.search-name-em')}</em>
                            </div>
                            <div className="teams-input-med" style={{width: '500px'}}>
                            <Text content={t('ai-configuration.ai-search-deployment-title')} className="mr-2" />
                                <Input
                                    clearable
                                    inverted
                                    placeholder={t('ai-configuration.enter-search-deployment')}
                                    value={data.SearchIndex}
                                    onChange={updateSearchIndex}
                                    style={{marginTop: "5px"}}
                                    fluid />
                                <em>{t('ai-configuration.search-index-em')}</em>
                            </div>
                            <div className="teams-input-med" style={{width: '500px'}}>
                                <Text content={t('ai-configuration.ai-search-key-title')} className="mr-2" />
                                <Input
                                    inverted
                                    placeholder={t('ai-configuration.enter-search-key')}
                                    value={data.SearchKey}
                                    onChange={updateSearchKey}
                                    fluid
                                    style={{marginTop: "5px"}}
                                    type={passwordInputTypes.search} 
                                    icon={showPasswords.search ? <EyeSlashIcon onClick={() => toggleFieldIndicator('search')} /> : <EyeIcon onClick={() => toggleFieldIndicator('search')} />} />
                                <em>{t('ai-configuration.search-key-em')}</em>
                            </div>
                            <div className="teams-input-med" style={{width: '500px'}}>
                                <Text content={t('ai-configuration.blob-connectionstring')} className="mr-2" />
                                <Input
                                    inverted
                                    placeholder={t('ai-configuration.enter-blob-connectionstring')}
                                    value={data.BlobConnectionString}
                                    onChange={updateBlobConnectionSTring}
                                    fluid
                                    style={{marginTop: "5px"}}
                                    type={passwordInputTypes.blob} 
                                    icon={showPasswords.blob ? <EyeSlashIcon onClick={() => toggleFieldIndicator('blob')} /> : <EyeIcon onClick={() => toggleFieldIndicator('blob')} />} />
                                <em>{t('ai-configuration.blob-connection-string-em')}</em>
                            </div>
                            <div className="teams-input-med" style={{width: '500px'}}>
                                <Header as="h2" className="pb-2 defaultStyle" content={t('ai-configuration.allow-filedownloads')} />
                                <div style={{paddingTop: '15px'}}>
                                    <Checkbox
                                        label={t('ai-configuration.allow-filedownload-label')}
                                        onChange={function(e, data) {
                                            updateAllowFileDownlads(data.checked);
                                        }}
                                        checked={data.AllowFileDownloads}
                                    />
                                </div>
                                <em>{t('ai-configuration.allow-download-file-em')}</em>
                            </div>
                        </Grid>
                    </Flex.Item>
                    </Flex>
                  </div>)} />
                )
            }
            </div>
        <MobileSettingsView />
        </CheckLogin>
    )
}