import { Box, Flex, SpeakerPersonIcon, Text } from '@fluentui/react-northstar';
import _ from 'lodash';
import React from 'react';
import {
  BoardItemPreview,
  getEmailByUserId,
  getUserByUserId,
  IBoardItemCardLayout,
  IPreparedBoardItem
} from '../../../../fluent-ui/components/Board/BoardItem';
import { appState } from '../../../AppState';
import {
  getStatusIdByGuid,
  getStatusNameByGuid,
  getStatusValue,
  getUserData
} from '../../../tikit/ticketHelper';
import RelativeDate from '../RelativeDate';
import { UserAvatar } from '../UserAvatar';
import TicketSVG from '../../../../svg/ticket.svg';
import { CSS_CLASS, STATUS_GUID } from '../../utils/constants';
import MergedIcon from '../../../../svg/merged.svg';
import { useTranslation } from 'react-i18next';
import { GroupUsersIcon } from '../../../tikit/Request/EditRequestDetails/GroupUsersCard/GroupUsersIcon';

interface RequesterSeassionProps {
  item: IPreparedBoardItem;
  boardItemCardLayout?: IBoardItemCardLayout;
}

interface CategorySeassionProps {
  item: IPreparedBoardItem;
  state?: any;
}

export const RequesterSection = React.memo((props: RequesterSeassionProps) => {
  const { item, boardItemCardLayout } = props;
  const currentstate = appState();
  const name = !_.isEmpty(item['Requester']) ? item['Requester']['FullName'] : getUserByUserId(item.users[0], currentstate.platformusers);
  const email = !_.isEmpty(item['Requester']) ? item['Requester']['UserName'] : getEmailByUserId(item.users[0], currentstate.platformusers);
  const user = currentstate.platformusers.find(x => x.Id == item.users[0]);

  return (
    <>
      {typeof item.users != 'undefined' && item.users.length === 1 && (
        <Flex vAlign="center">
          <Box>
            <UserAvatar {...{ idOrUpn: email, avatarProps: { name, size: 'small' } }} />
          </Box>
          <Box className={`ml-2`}>
            <Text weight="semibold" size="small">
              {name}
            </Text>
            <Text size="smaller" weight="regular">
              <RelativeDate date={item.CreatedDate} />
            </Text>
          </Box>
        </Flex>
      )}
      {typeof item.users != 'undefined' && item.users.length > 1 && (
        <Flex vAlign="center" column>
          <div>
            <GroupUsersIcon usersList={[{ PlatformUserId: user.Id }, ...item.AffectedUsersCount]} maxLength={11} isListing={true} /> 
          </div>
          <Text size="smaller" weight="regular">
            <RelativeDate date={item.CreatedDate} />
          </Text>
        </Flex>
      )}
      {item.preview && boardItemCardLayout.previewPosition === 'top' && (
        <BoardItemPreview preview={item.preview} />
      )}
    </>
  );
});

export const AssigneeSection = React.memo((props: RequesterSeassionProps) => {
  const { item } = props;
  const currentstate = appState();
  const { t } = useTranslation();
  const name = !_.isEmpty(item['Assignee']) ? item['Assignee']['FullName'] : getUserData(item.AssigneeId[0], currentstate)?.FullName;
  const email = !_.isEmpty(item['Assignee']) ? item['Assignee']['UserName'] : getEmailByUserId(item.AssigneeId[0], currentstate.platformusers);
  const user = currentstate.platformusers.find(x => x.Id == item.AssigneeId[0]);

  return (
    <>
      {typeof item.AssigneeId != 'undefined' && item.AssigneeId.length === 1 && (
        <>
          <UserAvatar {...{ idOrUpn: email, avatarProps: { name, size: 'small', } }} />
          <Text size="small" weight="regular" className={`ml-1`}>{name}</Text>
        </>
      )}
      {typeof item.AssigneeId != 'undefined' && item.AssigneeId.length > 1 && (<GroupUsersIcon usersList={[{ PlatformUserId: user?.Id }, ...item.CollaboratorsCount]} maxLength={3} isListing={true} />)}
      {item.AssigneeId.length == 0 && (
        <>
          <SpeakerPersonIcon size="small" style={{ color: '#8f90a6' }} />
          <Text size="small" weight="regular" className={`ml-2`}>
            {t('ticket.ticket-board.ticket-card.unassigned')}
          </Text>
        </>
      )}
    </>
  );
});

export const StatusSection = (props: RequesterSeassionProps) => {
  const { item } = props;
  return (
    <>
      <Flex vAlign="center" className={'cui-pt-4 cui-pv-4'}>
        {
          item?.IsMerged ? <MergedIcon />: <TicketSVG width={24} />
        }
        <Text
          weight="semibold"
          color="brand"
          className={`ml-1 cursor-pointer`}
        >
          #{item.Id < 10 && <>0</>}
          {item.Id < 100 && <>0</>}
          {item.Id}
        </Text>
      </Flex>
    </>
  );
};

export const CategorySection = (props: CategorySeassionProps) => {
  const { state, item } = props;

  return (
    <Flex hAlign="end" className={'cui-pt-4 cui-pv-4'}>
      {item.StatusId == null && (
        <Text weight="bold" size="smaller" className={`${CSS_CLASS.STATUS_PILL_ACTIVE} leading-none`}>
          {getStatusNameByGuid(state.ticketStatus, STATUS_GUID.ACTIVE)}
        </Text>
      )}
      {item.StatusId ==
        getStatusIdByGuid(state.ticketStatus, STATUS_GUID.ACTIVE) && (
        <Text weight="bold" size="smaller" className={`${CSS_CLASS.STATUS_PILL_ACTIVE} leading-none`}>
          {getStatusNameByGuid(state.ticketStatus, STATUS_GUID.ACTIVE)}
        </Text>
      )}
      {item.StatusId ==
        getStatusIdByGuid(state.ticketStatus, STATUS_GUID.RESOLVED) && (
        <Text weight="bold" size="smaller" className={`${CSS_CLASS.STATUS_PILL_RESOLVED} leading-none`}>
          {getStatusNameByGuid(state.ticketStatus, STATUS_GUID.RESOLVED)}
        </Text>
      )}
      {item.StatusId ==
        getStatusIdByGuid(state.ticketStatus, STATUS_GUID.DEFLECTED) && (
        <Text weight="bold" size="smaller" className={`${CSS_CLASS.STATUS_PILL_CLOSED} leading-none`}>
          {getStatusNameByGuid(state.ticketStatus, STATUS_GUID.DEFLECTED)}
        </Text>
      )}
      {item.StatusId ==
        getStatusIdByGuid(state.ticketStatus, STATUS_GUID.CLOSED) && (
        <Text weight="bold" size="smaller" className={`${CSS_CLASS.STATUS_PILL_CLOSED} leading-none`}>
          {getStatusNameByGuid(state.ticketStatus, STATUS_GUID.CLOSED)}
        </Text>
      )}
      {item.StatusId != null &&
        item.StatusId !=
          getStatusIdByGuid(state.ticketStatus, STATUS_GUID.CLOSED) &&
        item.StatusId !=
          getStatusIdByGuid(state.ticketStatus, STATUS_GUID.DEFLECTED) &&
        item.StatusId !=
          getStatusIdByGuid(state.ticketStatus, STATUS_GUID.RESOLVED) &&
        item.StatusId !=
          getStatusIdByGuid(state.ticketStatus, STATUS_GUID.ACTIVE) && (
          <Text weight="bold" size="smaller"  className={`${CSS_CLASS.STATUS_PILL_OTHERS} leading-none`}>
            {getStatusValue(item.StatusId, state.ticketStatus)}
          </Text>
        )}
    </Flex>
  );
};
