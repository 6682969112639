import React, { useEffect, useState, useCallback } from "react";
import { Helmet } from 'react-helmet';
import { platformService } from '../shared/services/platform.service';
import { ADMINISTRATORS, CONTACT_ADMIN, DEFAULT_API_STATUS, DONE, ERROR, LICENSE_FEATURE, LOADING, SAVE, SETTINGS, SETTINGS_PAGES, SOMETHING_WENT_WRONG, SURVEY, UNAUTHORIZED_ACCESS } from "../shared/utils/constants";
import { getCachedFeature } from "../shared/cache/FeatureCache";
import { useBoolean } from "@fluentui/react-hooks";
import { UpgradeNeeded } from "../shared/components/UpgradeNeeded";
import { Checkbox, Flex, Header, ProviderConsumer } from "@fluentui/react-northstar";
import { Communication } from '@fluentui/react-teams';
import { Toolbar } from '../shared/components/Toolbar';
import { toTitleCase } from "../shared/utils/helper";
import { Breadcrumbs } from "../shared/components/BreadcrumbNavigation";
import { checkInTeams } from "../App";
import { getBodyHeight } from '../shared/common/CommonHelper';
import { LoadingScreen } from "../shared/components/LoadingScreen";
import { CheckLicense } from "../shared/components/License/CheckLicense";
import { useTranslation } from "react-i18next";
import { appState  } from '../AppState';

export function SurveyConfig() {
  const { t } = useTranslation();
  const api = new platformService();

  const [apiStatus, setApiStatus] = useState<APIStatus>(DEFAULT_API_STATUS as APIStatus);
  const [isEnabled, setIsEnabled] = useState(true);
  const [surveysEnabled, { toggle: toggleSurveys, setTrue: setTrueSurveys, setFalse: setFalseSurveys }] = useBoolean(false);

  const appContextState = appState();

  useEffect(() => { 
    fetchSystemSetting(); 
  }, []);

  const fetchSystemSetting = useCallback(async () => {
    setApiStatus({ ...apiStatus, msg: t('surveys.fetching-system-setting') });
    try {
      const fetchResults = await Promise.all([
        api.getSystemSetting('ServiceDesk.Core.Configuration.SurveyConfiguration'),
        getCachedFeature(LICENSE_FEATURE.Surveys)
      ]);

      const { data } = fetchResults[0];
      setIsEnabled(fetchResults[1]);

      data.IsEnabled ? setTrueSurveys() : setFalseSurveys();
    } catch (err: any) {
      setApiStatus({ ...apiStatus, status: ERROR, errCode: err.response.status });
    } finally {
      const isAdmin = appContextState.userRoles.roles.includes(ADMINISTRATORS);
      (!isAdmin) ? setApiStatus({ ...apiStatus, status: ERROR, errCode: 401 }) : setApiStatus({ ...apiStatus, status: DONE });
    }
  }, []);

  const saveData = async () => {
    setApiStatus({ status: LOADING, msg: t('surveys.saving-settings'), errCode: 0 });
    try {
      const payload = { IsEnabled: surveysEnabled };
      const res = await api.setSystemSetting('ServiceDesk.Core.Configuration.SurveyConfiguration', payload);
      setApiStatus({ ...apiStatus, status: DONE });
    } catch (err: any) {
      setApiStatus({ ...apiStatus, status: ERROR, errCode: err.response.status });
    }
  };

  const navs: Breadcrumbs = {
    breadcrumbs: [
      {
        title: toTitleCase(SETTINGS),
        link: '/settings'
      },
      {
        title: toTitleCase(SURVEY),
        link: ''
      }
    ]
  };

  const errConfig = {
    fields: { title: apiStatus.errCode === 401 ? UNAUTHORIZED_ACCESS : SOMETHING_WENT_WRONG, desc: CONTACT_ADMIN }
  };
console.log(errConfig)
  return (
    <>
      <Helmet>
        <title>{t('surveys.surveys-tikit')}</title>
      </Helmet>
      {apiStatus.status === LOADING ? (<LoadingScreen message={apiStatus.msg} />) :
       apiStatus.status === ERROR ? (<Communication {...errConfig} />) :
        <>
          {!isEnabled && (<UpgradeNeeded toolbarText={`${toTitleCase(SETTINGS)} > ${toTitleCase(SETTINGS_PAGES.SURVEYS)}`} breadcrumbs={navs}
            headerText={t('surveys.you-do-not-have-access')} subheaderText={t('surveys.please-upgrade-your-plan')} />)}
          {isEnabled && (
            <ProviderConsumer render={globalTheme => (
              <>
                <Toolbar globalTheme={globalTheme} title={`Settings > Surveys`} breadcrumbs={navs} saveText={SAVE} saveCommand={saveData} />
                <Flex style={{ marginTop: '-1.25rem', height: getBodyHeight(checkInTeams()) }}>
                  <Flex column style={{ padding: '32px', maxWidth: '1400px' }}>
                    <Header as="h2" className="pb-2 defaultStyle" content={t('surveys.survey-configuration')} />
                      <CheckLicense disable>
                        <div><Checkbox label={t('surveys.enable-surveys')} toggle onChange={toggleSurveys} checked={surveysEnabled} /></div>
                      </CheckLicense>
                  </Flex>
                </Flex>
              </>
            )} />
          )}
        </>
      }
    </>
  );
}

