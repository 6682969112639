import React, { useState, useEffect, useRef } from 'react';
import { Text, ThemePrepared, ChevronDownIcon, Input, Datepicker } from '@fluentui/react-northstar';
import ToolbarCSS from '../../Toolbar.module.css';
import MgtCss from '../../../../mgt.module.css'
import { NestedFilter } from '../../../../shared/components/NestedFilter/NestedFilter';
import { getDropdownBoxShadow } from '../../../ticketHelper';
import { FILTERS } from '../../../../shared/utils/constants';
import { ConvertDateToLocale } from '../../../../shared/common/ConvertDate';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { DatepickerLocalizations } from '../../../../App';

interface CustomViewFilterProps {
  filter: FilterPropsItems;
  globalTheme: ThemePrepared<any>;
  assigneeValue: any
  onClick: (item: any, date?: any) => void;
  getFilterCount: () => any;
  onChangeSearchDropdown: (value: any) => void;
  onChangeCollaboratorSearchDropdown: (value: any) => void;
  onChangeDate: (value: any) => void;
}

export const CustomViewFilter = ({ filter, globalTheme, assigneeValue, onClick, getFilterCount, onChangeSearchDropdown, onChangeCollaboratorSearchDropdown, onChangeDate }: CustomViewFilterProps) => {

  const wrapperRef = useRef<HTMLDivElement>(null);
  const isAssigneeFilter = [FILTERS.ASSIGNEE.title].includes(filter.title);
  const isCollaboratorsFilter = [FILTERS.TicketCollaborators.title].includes(filter.title);
  const [isShow, setIsShow] = useState<boolean>(false);
  const {t} = useTranslation();

  const isModifiedDate = [FILTERS.MODIFIED_DATE.title].includes(filter.title);
  let selectedModifiedDate = isModifiedDate ? filter.values.find(x=>x.selected)?.date: null;
  const [selectedModifiedDateValue, setSelectedModifiedDateValue] = useState(selectedModifiedDate ? new Date(selectedModifiedDate): null);

  const isCreatedDate = [FILTERS.CREATED_DATE.title].includes(filter.title);
  let selectedCreatedDate = isCreatedDate ? filter.values.find(x=>x.selected)?.date: null;
  const [selectedCreatedDateValue, setSelectedCreatedDateValue] = useState(selectedCreatedDate ? new Date(selectedCreatedDate): null);

  const dropdownClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setIsShow((prev) => { return !prev });
  }

  useEffect(() => {
    function handleClickOutside(event: any) {
      let calenderRef = document.querySelector('.ui-popup__content');
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && isShow && (calenderRef == null || !calenderRef.contains(event.target))) {
        setIsShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [wrapperRef, isShow]);
 
  const customFormatter = date => {
    return ConvertDateToLocale(date, DateTime.DATE_SHORT);
  };
  
  return (
    <>
      <div ref={wrapperRef} className={`${ToolbarCSS.filterItem} relative`} key={filter.id}>
        <div
          onClick={(e) => dropdownClick(e)}
          style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background2 }}
          className={`flex justify-between px-3 py-2 cursor-pointer rounded-sm ${ToolbarCSS['custom-view-dropdown']}`}
        >
          <>
            <Text
              style={{ color: globalTheme.siteVariables.colorScheme.default.foreground }}
              content={<span>{`${filter.title} ${getFilterCount()[filter.id] > 0 ? '(' + getFilterCount()[filter.id] + ')' : ''}`}</span>}
            />
            <div className='relative'>
              <div className='absolute top-0 right-0 h-full p-2 z-10'></div>
              <ChevronDownIcon
                style={{ color: globalTheme.siteVariables.colorScheme.default.foreground }}
                className={`${ToolbarCSS.filterOptionArrow} ${isShow && ToolbarCSS.filterOptionHidden}`}
              />
            </div>
          </>
        </div>

        {isShow && (
          <div
            style={{ boxShadow: `${getDropdownBoxShadow(globalTheme)}`, backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
            className={`origin-top-right min-w-full absolute left-0 rounded-md focus:outline-none max-h-72 overflow-y-scroll z-10 my-2`}
            role='menu'
            aria-orientation='vertical'
            aria-labelledby='menu-button'
            tabIndex={-1}
          >
            {isAssigneeFilter &&
              <Input
                fluid
                className={ToolbarCSS.filterSearch}
                style={{
                  backgroundColor: globalTheme.siteVariables.colorScheme.default.background1,
                  padding: '2px 0px', marginTop: "2px",
                }}
                value={assigneeValue}
                onChange={onChangeSearchDropdown}
                inverted
                placeholder={t('ticket.ticket-list.configure-column.filter-by-assignee')}
              />
            }

            {isCollaboratorsFilter &&
              <Input
                fluid
                className={ToolbarCSS.filterSearch}
                style={{
                  backgroundColor: globalTheme.siteVariables.colorScheme.default.background1,
                  padding: '2px 0px', marginTop: "2px",
                }}
                value={assigneeValue}
                onChange={onChangeCollaboratorSearchDropdown}
                inverted
                placeholder={t('ticket.ticket-list.configure-column.filter-by-collaborator')}
              />
            }
            <> 
            <NestedFilter filterItems={filter.values} onClick={(item: any) => onClick(item, selectedCreatedDateValue)} />
            {isModifiedDate && (<Datepicker
                  {...DatepickerLocalizations}
                  disabled={false}
                  firstDayOfWeek={0}
                  formatMonthDayYear={customFormatter}
                  className={`${MgtCss.fluidDate} ${MgtCss.inverted}`}
                  onDateChange={(e, p)=>{
                    let selectedDate = p.value === null ? null : p.value.toISOString();
                    onChangeDate(selectedDate);
                    setSelectedModifiedDateValue(new Date(selectedDate));
                  }}
                  selectedDate={selectedModifiedDateValue}
                 
                />)}
              {isCreatedDate && (<Datepicker
                  {...DatepickerLocalizations}
                  disabled={false}
                  firstDayOfWeek={0}
                  formatMonthDayYear={customFormatter}
                  className={`${MgtCss.fluidDate} ${MgtCss.inverted}`}
                  onDateChange={(e, p)=>{
                    let selectedDate = p.value === null ? null : p.value.toISOString();
                    onChangeDate(selectedDate);
                    setSelectedCreatedDateValue(new Date(selectedDate));
                  }}
                  selectedDate={selectedCreatedDateValue}
                />)} 
              </>
          </div>
        )}
      </div>
    </>
  );
};