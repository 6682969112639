import React from 'react';

import { Flex, Skeleton, ProviderConsumer as FluentUIThemeConsumer } from '@fluentui/react-northstar';
import { ThemeColorScheme } from '../../common/TeamsTheme';

export const TicketApprovalsSkeleton = () => {
  const TicketApprovalCardSkeleton = () => {
    return (
      <FluentUIThemeConsumer render={globalTheme => (
        <div className="p-4" style={{ backgroundColor: 'var(--mgt-theme-background)', ...ThemeColorScheme(globalTheme.siteVariables) }}>
          <Flex>
            <Flex.Item>
              <Skeleton animation="wave">
                <div className="flex items-center justify-between">
                  <Skeleton.Line width="75px" />
                  <Skeleton.Line width="45px" />
                </div>
                <div className="my-4">
                  <Flex gap="gap.small">
                    <Skeleton.Shape round width="34px" height="34px" />
                    <div>
                      <Skeleton.Line width="200px" />
                      <Skeleton.Line width="150px" />
                    </div>
                  </Flex>
                </div>
                <Skeleton.Line width="100%" />
                <Skeleton.Line width="100%" />
              </Skeleton>
            </Flex.Item>
          </Flex>
        </div>
      )}
      />
    );
  }
  return (
    <Skeleton animation="wave">
      <Flex gap="gap.small" column>
        <TicketApprovalCardSkeleton />
        <TicketApprovalCardSkeleton />
        <TicketApprovalCardSkeleton />
      </Flex>
    </Skeleton>
  );
};