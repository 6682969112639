import { CacheService, CacheStore, CacheSchema, CacheItem, Providers } from "@microsoft/mgt-react";
import { ConsentFeatures } from "../../config/ConsentConfig";
import { Msal2Provider } from "../providers/Msal2Provider";
import { platformService } from '../services/platform.service';

const cacheSchema: CacheSchema = {
  name: 'features',
  stores: {
    names: ''
  },
  version: 1
};

interface CacheFeature extends CacheItem {
  enabled: boolean;
}

// retrieves invalidation time from cache config
const getFeatureInvalidationTime = (): number =>
  CacheService.config.response.invalidationPeriod ||
  CacheService.config.defaultInvalidationPeriod;

// checks for if cache is enabled
const featureCacheEnabled = (): boolean =>
  CacheService.config.response.isEnabled && CacheService.config.isEnabled;

// declare the desired cache store
let cache: CacheStore<CacheFeature>;
if (featureCacheEnabled())
  cache = CacheService.getCache<CacheFeature>(cacheSchema, 'names');

const platformAPI = new platformService();

const getNewPhoto = async (featureName: string): Promise<boolean> => {
  // store graph result into the cache if cache is enabled
  if (featureCacheEnabled()) {
    const src = await platformAPI.hasFeature(featureName);
    cache.putValue(featureName, { enabled: src.data.value });
    return src.data.value;
  }
};

export const setCachedPhoto = (featureName: string, value: boolean) => {
  if (featureCacheEnabled()) cache.putValue(featureName, { enabled: value });
};

export const getCachedFeature = async (
  featureName: string
): Promise<boolean> => {
  // check if the cache is enabled
  if (featureCacheEnabled()) {
    const feature = await cache.getValue(featureName);
    // check if an item is retrieved, and if it's not expired
    if (feature && getFeatureInvalidationTime() > Date.now() - feature.timeCached)
      return feature.enabled;
    else return getNewPhoto(featureName);
  }
};

export const hasConsentFeature = async (consentName: string): Promise<boolean> => {
  const provider = Providers.globalProvider;
  if (ConsentFeatures[consentName].ConsentUrl && ConsentFeatures[consentName].ConsentUrl !== "") {
    const api = new platformService();
    try {
      const result = await api.checkConsent(ConsentFeatures[consentName].ConsentUrl);
      return result.data.value;
    }
    catch { }
  } else if (provider instanceof Msal2Provider) {
    try {
      await provider.testAccessToken(...ConsentFeatures[consentName].Scopes);
      return true;
    } catch { }
  }
  return false;
};
