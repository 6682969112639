import React from 'react';
import {
  Flex,
  Skeleton,
  ProviderConsumer as FluentUIThemeConsumer
} from '@fluentui/react-northstar';
import { ToolbarTheme } from '@fluentui/react-teams/lib/esm/components/Toolbar/ToolbarTheme';
import { EditTicketFormSkeleton } from './EditTicketFormSkeleton';
import { TopBarSkeleton } from './TopBarSkeleton';
import { TicketCardSkeleton } from '../TicketCard/TicketCardSkeleton';
import Styles from './EditTicketSkeleton.module.css';
import { mergeStyleSets } from '@fluentui/react';

const classNames = mergeStyleSets({
  tikitBoardBody: {
    height: 'calc(100% - 60px) !important',
    top: '-18px'
  }
});

export const EditTicketSkeleton = () => {
  return (
    <FluentUIThemeConsumer
      render={globalTheme => (
        <>
          <ToolbarTheme globalTheme={globalTheme}>
            <Skeleton animation="wave">
              <Flex style={{ height: '48px' }}>
                <Skeleton.Text
                  size="smaller"
                  style={{
                    width: '30%',
                    margin: 'auto',
                    marginLeft: '16px',
                    marginRight: '16px'
                  }}
                />
                <Skeleton.Text
                  size="smaller"
                  style={{
                    width: '30%',
                    margin: 'auto',
                    marginLeft: '16px',
                    marginRight: '16px'
                  }}
                />
                <Skeleton.Text
                  size="smaller"
                  style={{
                    width: '30%',
                    margin: 'auto',
                    marginLeft: '16px',
                    marginRight: '16px'
                  }}
                />
              </Flex>
            </Skeleton>
          </ToolbarTheme>
          <Flex className={`h-full ${classNames.tikitBoardBody} overflow-scroll relative`}>
            <Flex.Item>
              <div className={`${Styles.EditSkeleton} p-5 hidden md:block`}>
                <div
                  className="p-2"
                  style={{
                    backgroundColor:
                      globalTheme.siteVariables.colorScheme.default.background
                  }}
                >
                  <Skeleton animation="wave">
                    <Skeleton.Line width="100%" />
                  </Skeleton>
                </div>

                <div className="mt-8">
                  <TicketCardSkeleton />
                  <TicketCardSkeleton />
                  <TicketCardSkeleton />
                  <TicketCardSkeleton />
                </div>
              </div>
            </Flex.Item>
            <Flex.Item grow styles={{ height: 'calc(100vh - 50px)' }}>
              <Flex column className={`pt-5 px-5 relative flex flex-col`}>
                <Flex.Item>
                  <TopBarSkeleton />
                </Flex.Item>
              </Flex>
            </Flex.Item>
            <Flex.Item
              className={`hidden md:block`}
              styles={{
                width: '25%',
                backgroundColor:
                  globalTheme.siteVariables.colorScheme.default.background
              }}
            >
              <div className={`p-5 m-0 overflow-auto`}>
                <EditTicketFormSkeleton />
              </div>
            </Flex.Item>
          </Flex>
        </>
      )}
    />
  );
};
