import React, { useEffect, useState } from 'react';
import Styles from './PropertyField.module.css';

import { Dropdown, DropdownProps, FormLabel, ProviderConsumer } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import { AUTOMATION_PROPERTIES } from '../../utils/constants';
import { getTranslateAutomationProperties } from '../../../automation/utils/automationHelper';

interface Props {
  propertiesList: DropdownDataModel[];
  property: string;
  updateProperty: (property: string) => void;
  whenData?: AutomationWhenData;
}

export const PropertyField = ({
  propertiesList,
  property,
  updateProperty,
  whenData
}: Props) => {
  const [automationProperty, setAutomationProperty] = useState(property);
  const {t} = useTranslation();
  useEffect(() => {
    setAutomationProperty(property);
  }, [property]);

  const automationPropertyChange = (_e, p: DropdownProps) => {
    let data = p.searchQuery;
    if (data === null) return;

    const result = propertiesList.find(x => x.header === p.searchQuery);
    if (result) 
      data = result.key;
    
    setAutomationProperty(data);
    updateProperty(data);
  };

  const removeSpaces = (text: string) => {
    return text?.replace(/\s/g, '');
  };
  propertiesList = propertiesList.filter(x => x.header && x.label)

  return (
    <ProviderConsumer render={globalTheme =>
      <div className={Styles.GroupInput}>
        <FormLabel content={t('automation.property-field.property')} styles={{ fontSize: '12px' }} />
        <Dropdown
          search
          clearable
          {...(automationProperty && automationProperty.length > 0 && {
            searchQuery: getTranslateAutomationProperties(removeSpaces(automationProperty)?.toLowerCase(),whenData?.entityName),
            value: propertiesList.find(x => x.key.toLowerCase() == automationProperty.toLowerCase())
          })}
          noResultsMessage={t('automation.property-field.we-couldnt-find-any-matches')}
          inverted
          fluid
          items={propertiesList}
          style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
          placeholder={t('automation.property-field.start-typing-a-property')}
          onSearchQueryChange={automationPropertyChange}
          onChange={(e, p) => {
            let data = p.value ? p.value["key"] : '';
            setAutomationProperty(data);
            updateProperty(data);
          }}
        />
      </div>
    } />
  );
};
