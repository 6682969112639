import React, { useCallback, useEffect, useState } from 'react';

import { platformService } from '../shared/services/platform.service';
import { SignedInState } from '../shared/services/signInState.service';
import '../AppGlobals';
import { Modal } from '../shared/components/Modal/Modal';
import {
  DEFAULT_API_STATUS,
  DONE,
  ERROR,
  LOADING,
  STATUS,
  TAGS
} from '../shared/utils/constants';
import { CheckLogin } from '../shared/components/CheckLogin';

export interface TagFormProperties {
  id: string;
  open: boolean;
  onCancel: () => void;
  onSuccessFullCall: () => void;
}

export const Tags = (props: TagFormProperties) => {
  const { id, open, onCancel, onSuccessFullCall } = props;
  const queryId: number = parseInt(id);
  const api = new platformService();

  const [apiStatus, setAPIStatus] = useState<APIStatus>(
    DEFAULT_API_STATUS as APIStatus
  );

  const [tagId, setTagId] = useState<MaybeNull<number>>(null);
  const [tagName, setTagName] = useState('');

  const updateAPIStatus = (newState: Partial<APIStatus>) => {
    setAPIStatus({ ...apiStatus, ...newState });
  };

  const fetchCategoryDefaultInstance = async () => {
    updateAPIStatus({
      status: DONE
    });
    setTagName('');
    setTagId(null);
  };

  const fetchCategoryDetails = useCallback(async () => {
    updateAPIStatus({
      msg: TAGS.FETCHING_TAG_DETAILS,
      status: LOADING
    });

    try {
      const { data } = await api.getTagPromise(`(${props.id})`);
      setTagName(data.Name);
      setTagId(data.Id);
    } catch (error: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: error.response.status
      });
    } finally {
      updateAPIStatus({ status: DONE });
    }
  }, [queryId]);

  const [isCheckedIn, setIsCheckedIn] = useState<boolean>(false);

  const loadPage = () => {
    setIsCheckedIn(true);
  }

  useEffect(() => {
    if (isCheckedIn) {
      if (queryId > 0) fetchCategoryDetails();
      else fetchCategoryDefaultInstance();
    }
  }, [isCheckedIn, queryId]);

  const updateTags = async (value: string) => {
    const payload: UpdateTagsPayload = {
      Name: value
    };

    updateAPIStatus({
      msg: TAGS.UPDATE_TAGS,
      status: LOADING
    });

    try {
      await api.updateTag(tagId, payload);

      onCancel();
      onSuccessFullCall();
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    }
  };

  const createTags = async (value: string) => {
    const payload: UpdateTagsPayload = {
      Name: value
    };

    updateAPIStatus({
      msg: TAGS.CREATING_NEW_TAGS,
      status: LOADING
    });

    try {
      await api.addTag(payload);

      onCancel();
      onSuccessFullCall();
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    }
  };

  const saveData = async (value: string) => {
    if (queryId > 0) updateTags(value);
    else createTags(value);
  };

  return (
    <CheckLogin onSignedIn={loadPage}>
      <Modal
        apiStatus={apiStatus}
        name={TAGS.TAGS}
        open={open}
        isUpdate={queryId > 0}
        inputValue={tagName}
        showCheckBox={false}
        onCancelHandler={onCancel}
        onConfirmationHandler={(inputValue, checkBoxValue) => {
          setTagName(inputValue);
          saveData(inputValue);
        }}
      />
    </CheckLogin>
  );
};
