import React from 'react';
import Styles from '../styles/TransitionRuleGroup.module.css';
import {
  AddIcon,
  Button,
  Flex,
  ProviderConsumer
} from '@fluentui/react-northstar';
import { LogicOperatorTypeString } from '../utils/constants';
import { KeyValuePair, TransitionRuleCriteria, TransitionRuleGroup } from '../utils/typings';
import { SwitchButton } from '../../shared/components/SwitchButton/SwitchButton';
import { PhaseTransitionRuleCriteria } from './PhaseTransitionRuleCriteria';
import { cloneDeep } from 'lodash';
import { DeleteIcon } from '@fluentui/react-icons-mdl2';
import { LIFECYCLE } from '../../shared/utils/constants';

interface Props {
  data: TransitionRuleGroup;
  dataIndex: number;
  updateData: (editData: KeyValuePair[], index: number) => void;
  tasksOptions: DropdownDataModel[];
  approvalsOptions: DropdownDataModel[];
  taskStatusList: TaskStatus[];
  powerautomateOptions: DropdownDataModel[];
}

export const PhaseTransitionRuleGroups
 = ({
  data,
  dataIndex,
  data: { TransitionRuleCriterias, LogicOperatorType },
  updateData,
  tasksOptions,
  approvalsOptions,
  taskStatusList,
  powerautomateOptions
}: Props) => {
  
  const initialData: TransitionRuleCriteria = {
    Id: 0,
    TransitionItem: null,
    TransitionItemId: 0,
    StatusId: null,
    TransitionRuleGroupId: data.Id
  }
  
  const updateRuleCriteriaData = (editData: KeyValuePair[], index: number) => {
    let isRemoved = false;
    editData.forEach(pair => {
      if(pair.key != "RemoveTransitionRuleCriteria")
        TransitionRuleCriterias[index][pair.key] = pair.value;
      else{
        TransitionRuleCriterias.splice(index, 1);
        isRemoved = true;
      }
    });    
    const pair: KeyValuePair = {
      key: !isRemoved ? "TransitionRuleCriterias" : "RemoveTransitionRuleCriterias",
      value: [...TransitionRuleCriterias]
    }

    updateData([pair], dataIndex);
  }

  const addNewCriteria = () => {
    const newCriteria= cloneDeep(initialData);
    TransitionRuleCriterias.push(newCriteria);
    const pair: KeyValuePair = {
      key: "AddTransitionRuleCriterias",
      value: [...TransitionRuleCriterias]
    }
    updateData([pair], dataIndex);
  }
  
  const updateLogicType = (item: LogicOperatorTypeString) => {
    const pair: KeyValuePair = {
      key: "LogicOperatorType",
      value: item
    }
    updateData([pair], dataIndex);
  }

  const removeGroup = () => {
    const pair: KeyValuePair = {
      key: "RemoveTransitionRuleGroup",
      value: dataIndex
  } 
    updateData([pair], dataIndex);
  }

  return (
    <ProviderConsumer
      render={globalTheme => (
        <>
          {dataIndex !== undefined && (
            <h2 className={Styles.SubHeading2} style={{ fontWeight: 'bold'}}>
              { dataIndex > 0 ? LIFECYCLE.AND  : LIFECYCLE.RULES }
            </h2>
          )}

          <Flex space="between" className='my-4 mr-0 ml-0'>
            <SwitchButton
              type={data.LogicOperatorType}
              changeGroupType={newGroupType => {
                updateLogicType(LogicOperatorTypeString[newGroupType]);
              }}
              globalTheme={globalTheme}
            />
            <Flex vAlign="center">
              <Button
                icon={
                  <AddIcon
                    styles={{
                      color:
                        globalTheme.siteVariables.colorScheme.brand.foreground
                    }}
                  />
                }
                text
                content={
                  <span
                    style={{
                      color:
                        globalTheme.siteVariables.colorScheme.brand.foreground
                    }}
                  >
                   {LIFECYCLE.ADD_FILTER}
                  </span>
                }
                onClick={() => {
                  addNewCriteria();
                }}
              />
              {(dataIndex !== undefined && dataIndex > 0) && (
                <span
                  className={Styles.DeleteBtnContainer}
                  onClick={() => {
                    removeGroup();
                  }}
                >
                  <DeleteIcon  />
                </span>
              )}
            </Flex>
          </Flex>

          {React.Children.toArray(
            TransitionRuleCriterias.map((filterData: TransitionRuleCriteria, index: number) => (
              <div key={filterData.Id}>
                <PhaseTransitionRuleCriteria
                  data={filterData} 
                  dataIndex={index}
                  updateData={updateRuleCriteriaData} 
                  tasksOptions={tasksOptions} 
                  approvalsOptions={approvalsOptions}
                  taskStatusList={taskStatusList}
                  powerautomateOptions={powerautomateOptions}
                />
              </div>
            ))
          )}
        </>
      )}
    />
  );
};
