import React, { useState } from 'react';
import { TToolbarInteraction } from '@fluentui/react-teams/lib/esm/components/Toolbar/Toolbar';
import { ProviderConsumer, Flex } from '@fluentui/react-northstar';
import { SettingsMenu } from '../shared/components/Menu';
import { Toolbar } from '../shared/components/Toolbar';
import { Breadcrumbs } from '../shared/components/BreadcrumbNavigation';
import { checkInTeams } from '../App';
import { getBodyHeight } from '../shared/common/CommonHelper';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  breadcrumbs: Breadcrumbs;
  children: React.ReactNode;
  disableSave: boolean;
  saveData: () => void;
}

export const EmailPageWrapper = ({ title, breadcrumbs, children, disableSave, saveData }: Props) => {
  const {t} = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);

  const onToolbarInteraction = (interaction: TToolbarInteraction) => {
    if (interaction.action === 'toggle-menu') {
      setMenuOpen(!menuOpen);
    }
  };
  return (
    <ProviderConsumer
      render={globalTheme => (
        <>
          <Toolbar
            globalTheme={globalTheme}
            title={`Settings > ${title}`}
            disableSave={disableSave}
            breadcrumbs={breadcrumbs}
            addNew
            onInteraction={onToolbarInteraction}
            saveText={t('notification-center.save')}
            saveCommand={saveData}
          />

          {/* Main Section */}
          <Flex style={{ marginTop: '-1.25rem', height: getBodyHeight(checkInTeams()) }}>
            <Flex.Item
              grow
              styles={{ paddingTop: '1.25rem', overflow: 'auto' }}
            >
              <div>{children}</div>
            </Flex.Item>
          </Flex>
        </>
      )}
    />
  );
};
