import React, { useEffect, useRef, useState } from 'react';
import Styles from './TicketCard.module.css';
import { Flex, SpeakerPersonIcon, Text, ProviderConsumer as FluentUIThemeConsumer } from '@fluentui/react-northstar';
import { UserAvatar } from '../UserAvatar';
import RelativeDate from '../RelativeDate';
import { TicketHeaderNumber, TicketStatus } from '../TicketCardTemplate';
import { Link } from 'react-router-dom';
import { getUserData, getStatus } from '../../../tikit/ticketHelper';
import { appState } from '../../../AppState';
import { ThemeColorScheme } from '../../common/TeamsTheme';
import { useTranslation } from 'react-i18next';
import { PlatformUser } from '../../interfaces/platformuser.interface';

interface Props {
  ticket: Ticket;
  onClick: (id: number) => void;
  selectedTicketId: number;
}

export const TicketCard = ({ ticket, onClick, selectedTicketId }: Props) => {
  const {t} = useTranslation();
  const activeTicketRef = useRef(null);
  const currentState = appState();
  const [platformUser, setPlatformUser] = useState({} as PlatformUser);

  useEffect(() => {
    scrollToCardIfSelected({ cardId: ticket.Id, selectedCardId: selectedTicketId, activeCardRef: activeTicketRef })

    setPlatformUser(getUserData(ticket.RequesterId, currentState));
  }, [ticket]);

  return (
    <FluentUIThemeConsumer
      render={globalTheme => (
        <Link to={`/tickets/${ticket.Id.toString()}`}>
          <div
            className={`p-4 bg-white cursor-pointer ${Styles.Card} overflow-hidden ${ticket.Id === +selectedTicketId ? Styles.CardActive : ''} mb-3`}
            onClick={() => onClick(ticket.Id)}
            ref={activeTicketRef}
            style={{ backgroundColor: 'var(--mgt-theme-background)', ...ThemeColorScheme(globalTheme.siteVariables) }}
          >
            <div className="flex items-center justify-between leading-none">
              <Flex vAlign="center">
                <TicketHeaderNumber id={ticket.Id} />
              </Flex>
              {ticket.StatusId !== null && (
                <TicketStatus status={getStatus(ticket.StatusId, currentState.ticketStatus)} />
              )}
            </div>
            <div className="my-4">
              <div className="flex items-center gap-1">
                {platformUser ? (
                  <UserAvatar
                    {...{
                      idOrUpn: platformUser.AadObjectId || platformUser.Email ||  platformUser.UserName,
                      avatarProps: { name: platformUser.FullName, size: 'medium' }
                    }}
                  />
                ) : (
                  <SpeakerPersonIcon
                    size="large"
                    style={{ color: '#8f90a6' }}
                    variables={{ largeSize: '30px' }}
                  />
                )}

                <div>
                  <Text weight="semibold" size="medium">
                    {platformUser?.FullName || t('ticket-details.edit-ticket-view.ticket-card.no-requester')}
                  </Text>
                  <RelativeDate date={ticket.CreatedDate} className="text-xs" />
                </div>
              </div>
            </div>
            <Text weight="semibold" size="medium" className="block">
              {ticket?.Title}
            </Text>
          </div>
        </Link>
      )}
    />
  );
};

export const scrollToCardIfSelected = ({ cardId, selectedCardId, activeCardRef }) => {
  if (cardId === +selectedCardId) {
    if (!Element.prototype['scrollIntoViewIfNeeded']) {
      let target = activeCardRef.current,
        parent = target.parentNode,
        parentComputedStyle = window.getComputedStyle(parent, null),
        parentBorderTopWidth = parseInt(parentComputedStyle.getPropertyValue('border-top-width')),
        parentBorderLeftWidth = parseInt(parentComputedStyle.getPropertyValue('border-left-width')),
        overTop = target.offsetTop - parent.offsetTop < parent.scrollTop,
        overBottom = (target.offsetTop - parent.offsetTop + target.clientHeight - parentBorderTopWidth) > (parent.scrollTop + parent.clientHeight),
        overLeft = target.offsetLeft - parent.offsetLeft < parent.scrollLeft,
        overRight = (target.offsetLeft - parent.offsetLeft + target.clientWidth - parentBorderLeftWidth) > (parent.scrollLeft + parent.clientWidth)

      if (overTop || overBottom)
        parent.scrollTop = target.offsetTop - parent.offsetTop - parent.clientHeight / 2 - parentBorderTopWidth + target.clientHeight / 2;

      if (overLeft || overRight)
        parent.scrollLeft = target.offsetLeft - parent.offsetLeft - parent.clientWidth / 2 - parentBorderLeftWidth + target.clientWidth / 2;
    } else {
      activeCardRef.current.scrollIntoViewIfNeeded(true);
    }
  }
}