import React from 'react';
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { markdownToDraft, draftToMarkdown } from 'markdown-draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { ProviderConsumer } from '@fluentui/react-northstar';
import './Editor.css';
import { themeNames } from '@fluentui/react-teams';
import { ThemeColorScheme } from '../../common/TeamsTheme';
import { AppStateContext } from '../../../AppState';

interface Mention {
  separator?: string;
  trigger: string;
  suggestions: {
    text: string;
    value: string;
    url?: string;
  }[];
}

interface Props {
  isFocus?: boolean;
  markdownString: string;
  onChange?: (interaction: any) => void;
  maxLength?: number;
  position?: 'bottom' | 'top';
  noImage?: boolean;
  readOnly?: boolean;
  useBorder?: boolean;
  height?: string;
}

export interface HtmlProps {
  htmlString: string;
  onChange?: (interaction: any) => void;
  toolbarOptions?: string[];
  toolbarInline?: any;
  mention?: Mention;
  wrapperClassName?: string;
  editorClassName?: string;
  oneLine?: boolean;
  readOnly?: boolean;
  handlePastedText?: (text: string, html: string, edstate: any) => void;
  spellCheck?: boolean;
}

interface State {
  editorState: any;
}

const ImageRegexp = /^!\[([^\]]*)]\s*\(([^)"]+)( "([^)"]+)")?\)/;
const LinkRegexp = /(?![!])\[([^\]]*)]\s*\(([^)"]+)( "([^)"]+)")?\)/g;
const imageBlock = remarkable => {
  remarkable.block.ruler.before(
    'paragraph',
    'image',
    (state, startLine, endLine, silent) => {
      const pos = state.bMarks[startLine] + state.tShift[startLine];
      const max = state.eMarks[startLine];
      if (pos >= max) {
        return false;
      }
      if (!state.src) {
        return false;
      }
      if (state.src[pos] !== '!') {
        return false;
      }

      var match = ImageRegexp.exec(state.src.slice(pos));
      if (!match) {
        return false;
      }

      // in silent mode it shouldn't output any tokens or modify pending
      if (!silent) {
        state.tokens.push({
          type: 'image_open',
          src: match[2],
          alt: match[1],
          lines: [startLine, state.line],
          level: state.level,
          text: match[0]
        });

        state.tokens.push({
          text: match[0],
          type: 'inline',
          content: match[4] || '',
          level: state.level + 1,
          lines: [startLine, state.line],
          children: []
        });

        state.tokens.push({
          text: match[0],
          type: 'image_close',
          level: state.level
        });
      }

      state.line = startLine + 1;

      return true;
    }
  );
};

// export const convertMdToDraftST = md => mdToDraftjs()

export const convertMdToDraft = md =>
  markdownToDraft(md, {
    remarkablePlugins: [imageBlock],
    blockTypes: {
      image_open: item => {
        return {
          type: 'atomic',
          data: {
            src: item.src,
            alt: item.alt
          }
        };
      }
    }
  });

interface StyleRange {
  offset: number;
  length: number;
  style: string;
}

interface EntityRange {
  key?: string | number;
  length?: number;
  offset?: number;
}

interface Block {
  data?: {
    src?: string;
  };
  depth: number;
  entityRanges: EntityRange[];
  inlineStyleRanges: StyleRange[];
  key?: string;
  text: string;
  type: string;
}

interface RawData {
  blocks: Block[];
  entityMap: {};
}

export const insertString = (
  currentString: string,
  index: number,
  toInsert: string
) => {
  if (index > 0) {
    return (
      currentString.substring(0, index) + toInsert + currentString.substr(index)
    );
  }

  return toInsert + currentString;
};

export const RawDataToMarkdown = (rawdata: RawData) => {
  let md = '';
  rawdata.blocks.forEach((block: Block) => {
    let textblock = block.text;
    let addedChar = 0;
    block.inlineStyleRanges.forEach((styleRange: StyleRange) => {
      if (styleRange.style === 'BOLD') {
        textblock = insertString(
          textblock,
          styleRange.length + styleRange.offset,
          '**'
        );
        textblock = insertString(textblock, styleRange.offset, '**');
      }
    });
    md = textblock + '\n';
  });

  return md;
};

export class EditorComponent extends React.Component<Props, State> {
  maxLength: number;
  
  constructor(props) {
    super(props);
    const markdownString = this.props.markdownString;
    this.maxLength = this.props.maxLength || 25000;
    
    let match = [];
    let matchCount = 0;

    while ((match[matchCount] = LinkRegexp.exec(markdownString)) != null) {
      matchCount++;
    }
    const fromDBRawData = convertMdToDraft(markdownString);

    let newRawData = { blocks: [], entityMap: {} };
    let current_key = 0;
    let current_link = 0;
    let newEntityMap = {};
    let imagesIndex = [];
    newRawData.blocks = fromDBRawData.blocks.map((block: Block, index) => {
      let isBlockChanged = false;
      let newBlockFormat = {};
      if (block.entityRanges.length > 0) {
        let newEntityRanges = [];
        block.entityRanges.forEach(er => {
          if (match[current_link]) {
            newEntityMap[current_key.toString()] = {
              type: 'LINK',
              mutability: 'MUTABLE',
              data: {
                url: match[current_link][2],
                targetOption: '_self'
              }
            };
            newEntityRanges.push({
              offset: er.offset,
              length: er.length,
              key: current_key
            });
            current_link++;
            current_key++;
          }
        });
        isBlockChanged = true;
        newBlockFormat = { ...block, entityRanges: newEntityRanges };
      }
      if (block.data && block.type === 'atomic') {
        if (block.data.src) {
          block.text = ' ';
          block.entityRanges = [
            {
              key: current_key,
              length: 1,
              offset: 0
            }
          ];
          newEntityMap[current_key.toString()] = {
            type: 'IMAGE',
            mutability: 'IMMUTABLE',
            data: {
              url: block.data.src,
              src: block.data.src,
              fileName: ''
            }
          };
          imagesIndex.push(index);
          delete block.data;
          current_key++;
        }
      } else if (block.type === 'code-block') block.type = 'code';

      return isBlockChanged ? newBlockFormat : block;
    });
    newRawData.entityMap = newEntityMap;
    let i = 0;
    if(imagesIndex[0] === 0) {
      newRawData.blocks.unshift( {
        "depth": 0,
        "type": "unstyled",
        "text": "",
        "entityRanges": [],
        "inlineStyleRanges": []
      });
      i++;
    }
    imagesIndex.forEach(index => {
      i++;
      newRawData.blocks.splice(index + i, 0, {
        "depth": 0,
        "type": "unstyled",
        "text": "",
        "entityRanges": [],
        "inlineStyleRanges": []
      });
    })

    const contentState = convertFromRaw(newRawData);
    const newEditorState = EditorState.createWithContent(contentState);
    this.state = {
      editorState: newEditorState
    };
  }


  onEditorStateChange: Function = editorState => {
    const rawdata = convertToRaw(editorState.getCurrentContent());
    const blocks = rawdata.blocks.map((r, idx) => {
      if (r.type === 'code') r.type = 'code-block';
      else if (r.type === 'unstyled' && idx !== rawdata.blocks.length - 1)
        r.text += '\n';
      return r;
    });

    rawdata.blocks = blocks.filter(r => !(r.type === 'unstyled' && r.entityRanges.length > 0 && r.entityRanges.some(x => rawdata.entityMap[x.key].type === 'IMAGE')));
    
    const draftOptions = {
      entityItems: {
        IMAGE: {
          open: entity => ' ',
          close: entity =>
            `![${entity['data'].alt || ''}](${entity['data'].src})`
        }
      },
      preserveNewlines: false
    };

    if (rawdata.blocks.length > 0 && (rawdata.blocks[0].text === "\n" || rawdata.blocks[0].text === "") && rawdata.blocks[0].type === "unstyled") {
      rawdata.blocks.shift();
    }
    let md = draftToMarkdown(rawdata, draftOptions);

    if (this.props.onChange) this.props.onChange(md);

    this.setState({ editorState });
  };
  onHandleBeforeInput: Function = val => {
    const textLength = this.state.editorState.getCurrentContent().getPlainText().length;
    return (val && textLength >= this.maxLength) ? 'handled' : 'not-handled';
  };

  setEditorReference: Function = ref => {
    if (this.props.isFocus) {
      ref?.focus();
    }
  };

  render() {
    const { editorState } = this.state;
    let positionClass = this.props.position === 'bottom' ? `b-no-space` : '';
    let hasImageClass = this.props.noImage ? `no-image` : '';
    const border = this.props.useBorder ? { border: '1px solid var(--mgt-theme-background3)' } : {};
    const editorStyle = (this.props.height) ? { height: this.props.height } : {};

    return (
      <AppStateContext.Consumer>
        {(state) => {
          return (
            <ProviderConsumer render={(globalTheme) => (
              <div className={`${positionClass} ${hasImageClass} ${state.theme === themeNames.Dark && 'editor--darkmode'}`}
                style={{ backgroundColor: 'var(--mgt-theme-background)', ...border, ...ThemeColorScheme(globalTheme.siteVariables) }}
              >
                <Editor
                  readOnly={this.props.readOnly}
                  editorRef={this.setEditorReference}
                  editorState={editorState}
                  spellCheck={true}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor editor-scroll-to-bottom"
                  editorStyle={editorStyle} 
                  onEditorStateChange={this.onEditorStateChange}
                  handleBeforeInput={this.onHandleBeforeInput}
                  handlePastedText={() => false}
                  blockStyleFn={() => 'defaultStyle'}
                  toolbar={{
                    options: [
                      'inline',
                      'blockType',
                      'list',
                      'link',
                      'emoji',
                      'image',
                      'remove',
                      'history'
                    ],
                    inline: {
                      inDropdown: false,
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                      options: ['bold', 'italic', 'strikethrough']
                    }
                  }}
                />
              </div>
            )} />
          )
        }}
      </AppStateContext.Consumer>
    );
  }
}

export class HtmlEditorComponent extends React.Component<HtmlProps, State> {
  toolbarOptions: string[];
  toolbarInline: any;
  constructor(props: any) {
    super(props);
    const htmlString = this.props.htmlString;
    const blocksFromHtml = htmlToDraft(htmlString);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const newEditorState = EditorState.createWithContent(contentState);
    this.state = { editorState: newEditorState };
    if (this.props.toolbarOptions)
      this.toolbarOptions = this.props.toolbarOptions;
    if (this.props.toolbarInline)
      this.toolbarInline = this.props.toolbarInline;
  }

  onEditorStateChange: Function = (editorState: any) => {
    const rawdata = convertToRaw(editorState.getCurrentContent());
    const html = draftToHtml(rawdata);
    if (this.props.onChange) this.props.onChange(html);
    this.setState({ editorState });
  };

  handlePastedText: Function = (text) => {
    //This will highlight the token after pasting
    setTimeout(()=>{
      const regex = /\{{1,2}([^{}]+?)\}{1,2}/;
      let pastedTokens = document.getElementsByClassName("rdw-link-decorator-wrapper");
      for(const tokenContainer of pastedTokens){
        if (regex.test(tokenContainer.outerHTML)){
          let link = tokenContainer.getElementsByTagName("a");
          let token = link[0];
          token.className="rdw-mention-link";
        }
      }
    },100);
    
    return false;
  }

  render() {
    const { editorState } = this.state;
    return (
      <ProviderConsumer
        render={globalTheme => (
          <div className={`${globalTheme.siteVariables.theme !== themeNames.Default && 'dark-mood-custom'} mt-2`}
            style={{ position: 'relative', color: this.props.readOnly ? 'var(--mgt-theme-foreground-disabled)' : 'var(--mgt-theme-foreground)', backgroundColor: this.props.readOnly ? 'var(--mgt-theme-background-disabled)' : 'var(--mgt-theme-background)', ...ThemeColorScheme(globalTheme.siteVariables) }}
          >
            <Editor
              editorState={editorState}
              wrapperClassName={`${this.props.wrapperClassName ?? 'editor-wrapper'} ${this.props.readOnly ? ' editor-wrapper--disabled' : ''}`}
              editorClassName={this.props.editorClassName ?? 'editor-default'}
              toolbarClassName={`editor-toolbar`}
              onEditorStateChange={this.onEditorStateChange}
              blockStyleFn={() => 'defaultStyle'}
              mention={this.props.mention}
              handleReturn={() => {
                return this.props.oneLine;
              }}
              handlePastedText={this.handlePastedText}
              toolbar={{
                options: this.toolbarOptions ?? [
                  'inline',
                  'blockType',
                  'list',
                  'link',
                  'emoji',
                  'image',
                  'remove',
                  'history'
                ],
                inline: this.toolbarInline ?? {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: ['bold', 'italic', 'strikethrough']
                }
              }}
              readOnly={this.props.readOnly}
              spellCheck={this.props.spellCheck}
            />
          </div>
        )}
      />
    );
  }
}