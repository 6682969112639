import React from "react";

import { VisibilityContext } from "react-horizontal-scrolling-menu";
import { ChevronEndIcon } from "@fluentui/react-icons-northstar/dist/es/components/ChevronEndIcon";
import { ChevronStartIcon } from "@fluentui/react-icons-northstar/dist/es/components/ChevronStartIcon";
import { ProviderConsumer } from "@fluentui/react-northstar";

function Arrow({
  children,
  disabled,
  onClick
}: {
  children: React.ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      style={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        right: "1%",
        opacity: disabled ? "0" : "1",
        userSelect: "none",
        // backgroundColor: "rgb(224, 224, 224)",
        // height: "100%"
      }}
    >
      {children}
    </button>
  );
}

export function LeftArrow() {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleElements,
    initComplete
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollPrev()}>
      <ChevronStartIcon/>
    </Arrow>
  );
}

export function RightArrow() {
  const { isLastItemVisible, scrollNext, visibleElements } = React.useContext(
    VisibilityContext
  );

  const [disabled, setDisabled] = React.useState(
    !visibleElements.length && isLastItemVisible
  );
  React.useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollNext()}>
      <ChevronEndIcon />
    </Arrow>
  );
}

function FormArrow({
  children,
  disabled,
  onClick
}: {
  children: React.ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
}) {
  return (
    <ProviderConsumer render={globalTheme => (
      <button
        disabled={disabled}
        onClick={onClick}
        style={{
          cursor: "pointer",
          height: '2.3rem',
          width: '1.5rem',
          display: `${disabled ? 'none': 'flex'}`,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: 'center',
          right: "1%",
          opacity: disabled ? "0" : "1",
          userSelect: "none",
          backgroundColor: globalTheme.siteVariables.colorScheme.default.background5,
        }}
      >
        {children}
      </button>
    )} />
  );
}


export function FormLeftArrow() {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleElements,
    initComplete
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return (
    <FormArrow disabled={disabled} onClick={() => scrollPrev()}>
      <ChevronStartIcon />
    </FormArrow>
  );
}

export function FormRightArrow() {
  const { isLastItemVisible, scrollNext, visibleElements } = React.useContext(
    VisibilityContext
  );

  const [disabled, setDisabled] = React.useState(
    !visibleElements.length && isLastItemVisible
  );
  React.useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (
    <FormArrow disabled={disabled} onClick={() => scrollNext()}>
      <ChevronEndIcon />
    </FormArrow>
  );
}
