import React from 'react';
import { Flex, Text } from '@fluentui/react-northstar';
import CSS from '../TemplateCard.module.css';
import TicketSVG from '../../../../../svg/ticket.svg';
import { threedigitized } from '../../../ticketHelper';
import { TicketTasksDetails } from '../../../../shared/components/TicketTasks/TicketTasksList/TicketTaskCard/TicketTasksDetails';

interface Props {
  ticketTask: TicketTask;
}

export const TicketTaskCard = ({ ticketTask }: Props) => {
  return (
    <>
      <div className={`${CSS.ticketTaskCard} p-4`}>
        <Flex vAlign="center">
          <TicketSVG width={24} className={`mr-1`} />
          <Text weight="semibold">
            #{threedigitized(ticketTask.Ticket.Id)}
          </Text>
        </Flex>
        <div>
          {ticketTask.Ticket.Title}
        </div>
        <TicketTasksDetails isWorkBoard={true} ticketTask={ticketTask} />
      </div>
    </>
  );
};
