import React, { useState } from 'react';
import Styles from '../Automation.module.css';
import { FormCheckbox } from '@fluentui/react-northstar';
import { FormGroup } from '../Automation';
import { AUTOMATION } from '../../shared/utils/constants';
import { TextInputField } from '../../shared/common/TextInputField';
import { t } from 'i18next';

interface Props {
  infoData: AutomationInfoData;
  updateInfoData: (infoData: AutomationInfoData) => void;
}

export const Information = ({
  infoData,
  infoData: { title, description, enabled },
  updateInfoData
}: Props) => (
  <>
    <h2 className={Styles.SubHeading2}>{AUTOMATION.INFORMATION}</h2>
    <FormGroup>
      <TextInputField
        label={t('automation.information-inputs.title')}
        value={title}
        isRequired
        updateValue={(value: string) => {
          updateInfoData({
            ...infoData,
            title: value
          });
        }}
      />
    </FormGroup>
    <FormGroup>
      <TextInputField
        label={t('automation.information-inputs.description')}
        value={description}
        updateValue={(value: string) => {
          updateInfoData({
            ...infoData,
            description: value
          });
        }}
      />
    </FormGroup>
    <FormGroup>
      <FormCheckbox
        label={t('automation.information-inputs.enabled')}
        checked={enabled}
        onChange={(_, checked) => {
          updateInfoData({
            ...infoData,
            enabled: checked.checked
          });
        }}
      />
    </FormGroup>
  </>
);
