import { ProviderConsumer } from '@fluentui/react-northstar';
import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import Styles from './RoleItem.module.css';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  role: string;
  mappedRolesCount: number;
  active: boolean;
}

export const RoleItem = ({ title, role, mappedRolesCount, active }: Props) => {
  const { url } = useRouteMatch();
  const { t } = useTranslation();
  const splittedUrl = url.split('/');
  splittedUrl.splice(splittedUrl.length - 1, 1, role);
 
  //if analysts role is chose, set url to superagents
  if(role=='analysts'){splittedUrl[splittedUrl.length - 1]='superagents'}
  
  return (
    <ProviderConsumer
      render={globalTheme => (
        <Link to={splittedUrl.join('/')}>
          <ul
            className={`${Styles.RoleItem}`}
            style={{background: `${active ? globalTheme.siteVariables.colorScheme.default.background: ''}`}}
          >
            <li className={Styles.RoleItemTitle} style={{color: globalTheme.siteVariables.colorScheme.default.foreground}}>{title}</li>
            <li className={Styles.RoleItemSubTitle} style={{color: globalTheme.siteVariables.colorScheme.default.foreground}}>
              {mappedRolesCount} {t('security.mapped-roles')}
            </li>
          </ul>
        </Link>
      )}
    />
  );
};
