import React, { useEffect, useState } from 'react';
import { Avatar, SizeValue, AvatarStatusProps, AvatarProps, ShorthandValue } from '@fluentui/react-northstar';
import { graphService } from '../services/graph.service';
import { getCachedPhoto } from '../cache/UserPhoto';
import { getUserKeyValue } from '../cache/UserKeyValue';

interface UserPhotoProperties {
  idOrUpn: string;
  showPresence?: boolean;
  avatarProps?: AvatarProps;
}

export const UserAvatar = (props: UserPhotoProperties) => {
  const [userImage, setUserImage] = useState('');
  const [userPresence, setUserPresence] =
    useState<ShorthandValue<AvatarStatusProps>>();

  useEffect(() => {
    (async () => {
      if (props.idOrUpn) {
        setUserImage(await getCachedPhoto(props.idOrUpn));
        if (props.showPresence)
          setUserPresence(
            await getUserPresence(
              (await getUserKeyValue(props.idOrUpn)) || props.idOrUpn,
              props.avatarProps.size || 'smaller'
            )
          );
      }
    })();
  }, [props.idOrUpn]);
  let avatarProps = props.avatarProps || {};
  avatarProps = Object.assign(
    {
      styles: {
        background: 'transparent',
        fontWeight: 'bold'
      },
      variables: {
        statusBorderColor: 'white'
      },
      ...(userImage?.length > 0 && {
        image: userImage
      }),
      ...(props.showPresence && {
        status: userPresence
      })
    },
    avatarProps
  );
  return <Avatar {...avatarProps} />;
};

const graphAPI = new graphService();
const stateOptions = {
  success: 'success',
  warning: 'warning',
  error: 'error',
  unknown: 'unknown'
};

const getAvatarState = (availability: string) => {
  let state = stateOptions.success;
  switch (availability) {
    case 'Away':
    case 'BeRightBack':
      state = stateOptions.warning;
      break;
    case 'Busy':
    case 'DoNotDisturb':
      state = stateOptions.error;
      break;
    case 'Offline':
    case 'PresenceUnknown':
      state = stateOptions.unknown;
      break;
    default:
      break;
  }
  return state;
};

const getUserPresence = async (id: string, size: SizeValue) => {
  const presence = await graphAPI.getUserPresence(id);
  const avatarStatus: ShorthandValue<AvatarStatusProps> = {
    title: presence.activity,
    state: stateOptions[getAvatarState(presence.availability)],
    size: size
  };
  return avatarStatus;
};
