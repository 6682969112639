import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Card, Flex, Loader, ProviderConsumer, TextArea, Text } from '@fluentui/react-northstar';
import { Input } from 'adaptivecards';
import { AdaptiveCardElement } from '../../templates/AdaptiveCardElement';
import { platformService } from '../../shared/services/platform.service';
import { FILTERS_STRINGS, LICENSE_FEATURE, TicketApprovalState } from '../../shared/utils/constants';
import { PlatformUser } from '../../shared/interfaces/platformuser.interface';
import { useTranslation } from 'react-i18next';
import { CheckFeature } from '../../shared/components/CheckFeature';
import { DropdownSupportGroup, LabelElement } from '../../shared/components/TicketForm';
import { ITeam } from '../../shared/interfaces/supportGroup.interface';
import { getCachedFeature } from '../../shared/cache/FeatureCache';

const EmailRequest: React.FC = () => {
  const { search } = useLocation();
  const { t } = useTranslation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const api = new platformService();
  const [state, setState] = useState(0);
  const [answer, setAnswer] = useState("");
  const [approvalTitle, setApprovalTitle] = useState("");
  const [templateId, setTemplateId] = useState(0);
  const [templateResponse, setTemplateResponse] = useState({});
  const [emailConfig, setEmailConfig] = useState<any>({});
  const [defaultTeam, setDefaultTeam] = useState();
  const [teams, setTeams] = useState<ITeam[]>();
  const [request, setRequest] = useState("");
  const [searchTeam, setSearchTeam] = useState("");

  const onChangeTeamQuery = useCallback((searchQuery: string) => {
    setSearchTeam(searchQuery);
  }, [searchTeam]);

  const newrequest = async (data: any) => {
    try {
      const results = await Promise.all([
        api.getTeamsSafe(FILTERS_STRINGS.TICKET_TEAMS),
        api.getTeamsSafe(FILTERS_STRINGS.IS_DEFAULT),
        api.getEmailConnectorConfiguration(),
        api.getTeamsSafe(`?$filter=Id eq ${data.teamId ?? 0}`)
      ]);
      setTeams(results[0]);
      const tc = results[2].data || {};
      setDefaultTeam(data.teamId ?? results[1][0]?.Id ?? null);
      const thisEnabledMultiDepartment = await getCachedFeature(LICENSE_FEATURE.MultiDepartment);
      if (thisEnabledMultiDepartment) {
          tc.EnableMultiDepartmentPicker = results[3][0]?.EnableMultiDepartmentPicker ?? tc.EnableMultiDepartmentPicker;
          tc.MultiDepartmentPickerMessage = results[3][0]?.MultiDepartmentPickerMessage ?? tc.MultiDepartmentPickerMessage;
      } 
        
      setEmailConfig(tc);
      setRequest(data.responseSubject);
      if (tc.EnableMultiDepartmentPicker && thisEnabledMultiDepartment)
        setState(9)
      else {
        await api.addRequestEmail(data);
        setState(2);
      }
    } catch { 
      setState(4);
    }
  };

  const submitNewRequest = async () => {
    try {
      const data = JSON.parse(atob(searchParams.get('data')));
      data.responseSubject = request;
      data.responseTeam = defaultTeam;

      await api.addRequestEmail(data);
      setState(2);
    } catch { 
      setState(4);
    }
  };

  const deflectTicket = async (data: any) => {
    try {
      if (data.isDeflected) {
        await api.addRequestEmail(data);
        setState(3);
      } else
        newrequest(data);
    } catch { 
      setState(4);
    }
  };

  const ticketApproval = async (data: any) => {
    try {
      const approvals = await api.getApprovalApproversTicketLifecyclePhase(data['approvalId']);
      const cdmUser = await api.getPlatformUserByFilter(`Email eq '${data['email']}'`);
      const user = cdmUser.data.value[0] as PlatformUser;
      const approvers = approvals[0].Approvers;
      let currentApprover = null;
      approvers.map((item)=> { 
        if(item.ApproverId == user.Id) {
          currentApprover = item;
        }
      });
      if (currentApprover != null && currentApprover.HasApproved != TicketApprovalState.Pending) {
        setState(5);
        return;
      }
      var approvalState = CheckApprovalStateChange(approvers, approvals[0].IsRequiredByAll);
      if (approvalState != 'pending') {
        setState(5);
        return;
      }

      if (!(approvals[0]?.TicketLifecyclePhase?.IsCurrent ?? true)) {
        setApprovalTitle(approvals[0]?.Title);
        setState(8);
        return;
      }

      await api.addRequestEmail(data);
      if (data["isApprovalAccepted"]) {
        setState(6);
      } else {
        setState(7);
      }
    } catch { 
      setState(4);
    }
  };

  const CheckApprovalStateChange = (approvers: any[], isRequiredByAll: boolean) =>
        {
            var isRejected = approvers.some(x => x.HasApproved == TicketApprovalState.Rejected);
            if (isRejected)
            {
                return "rejected";
            }
            if (isRequiredByAll)
            {
                var isApproved = approvers.every(x => x.HasApproved == TicketApprovalState.Approved);
                if (isApproved)
                {
                    return "approved";
                }
                return "pending";
            }
            else
            {
                var isApproved = approvers.some(x => x.HasApproved == TicketApprovalState.Approved);
                if (isApproved)
                {
                    return "approved";
                }
                return "pending";
            }
        }
  const getTemplate = async (templateId) => {
    try {
      const result = await api.getTicketTemplates(`?$filter=Id eq ${templateId}`);
      if (result && result.data && result.data.value.length > 0) {
        setAnswer(result.data.value[0].CardResponseJson);
        setTemplateId(result.data.value[0].Id);
      }
      setState(1);
    } catch { 
      setState(4);
    }
  };
  const submitTemplate = async () => {
    try {
      const data = JSON.parse(atob(searchParams.get('data')));
      data['response'] = templateResponse;
      data['templateBody'] = answer;
      data['templateId'] = templateId;
      await api.addRequestEmail(data);
      setState(2);
    } catch { 
      setState(4);
    }
  };

  useEffect(() => {
    const data = JSON.parse(atob(searchParams.get('data')));
    if (data['template']) 
      getTemplate(data['template'])
    else if (data['approvalId'])
      ticketApproval(data);
    else
      deflectTicket(data);
  }, []);

  return (<ProviderConsumer render={globalTheme => (
    <div className={`h-screen`}>
      <Card style={{ width: '100%', overflowY: 'scroll' }}>
        <>
          {state === 0 && (<Loader className='m-4' label={t('requests.dialog.loading')} />)}
          {state === 1 && (
            <div className='email-request-card'>
              <Card.Body>
                <AdaptiveCardElement jsonString={answer} cardProps={{ styles: { height: 'fit-content' } }} onInputValueChanged={(input: Input) => {
                  setTemplateResponse(cardResponse => {
                    cardResponse[input.id] = input.value;
                    return cardResponse;
                  });
                }} />
              </Card.Body>
              <Card.Footer>
                <Flex gap='gap.small' className={'mt-4'} hAlign='end'>
                  <Button primary content={t('common.buttons.submit')} onClick={submitTemplate} style={{ marginRight: 0 }} />
                </Flex>
              </Card.Footer>
            </div>
          )}
          {state === 2 && (<div className='text-center'>{t('requests.dialog.received')}  {t('requests.dialog.close-window')}</div>)}
          {state === 3 && (<div className='text-center'>{t('requests.dialog.glad-we-helped')} {t('requests.dialog.close-window')}</div>)}
          {state === 4 && (<div className='text-center'>{t('requests.dialog.oops')}</div>)}
          {state === 5 && (<div className='text-center'>{t('requests.dialog.responded')}</div>)}
          {state === 6 && (<div className='text-center'>{t('requests.dialog.approved')}  {t('requests.dialog.close-window')}</div>)}
          {state === 7 && (<div className='text-center'>{t('requests.dialog.declined')}  {t('requests.dialog.close-window')}</div>)}
          {state === 8 && (<div className='text-center'><strong>{t('requests.dialog.error')}"{approvalTitle}"</strong>. {t('requests.dialog.not-current-phase')}</div>)}
          {state === 9 && (
          <div className='email-request-card'>
            <CheckFeature featureName={LICENSE_FEATURE.MultiDepartment}>
              <div className='mb-4'>
                <LabelElement label={emailConfig?.MultiDepartmentPickerMessage && emailConfig?.MultiDepartmentPickerMessage.length > 0 ? emailConfig.MultiDepartmentPickerMessage : t('bot-configuration.enduser-select-team-default')} required={true}>
                <DropdownSupportGroup
                  items={teams}
                  handler={(value: any) => setDefaultTeam(value.Id)}
                  properties={{ defaultValue: undefined }}
                  value={defaultTeam}
                  header={'FriendlyName'}
                  setSearchTeam={setSearchTeam}
                  searchQuery={searchTeam}
                  onSearchQueryChange={onChangeTeamQuery}
                />
                </LabelElement>
              </div>
            </CheckFeature>
            <div className='mb-4'>
            <LabelElement label={t('requests.dialog.title')} required={true}>
              <TextArea maxLength={500} required style={{ height: 60 }} fluid onChange={(e, p) => { setRequest(p.value); }} value={request}/>
            </LabelElement>
            </div>
            <Button
              disabled={request.length == 0 || (emailConfig?.EnableMultiDepartmentPicker && !defaultTeam)}
              primary
              type="submit"
              content={t('requests.dialog.ask')}
              styles={{float: 'right'}}
              onClick={() => {
                submitNewRequest();
              }}
            />
          </div>
          )}
        </>
      </Card>
    </div>
  )} />);
};

export default EmailRequest;