import React, { useEffect, useState } from 'react';
import { Input, Flex, ProviderConsumer, Button, Text, FormCheckbox, Dropdown, Tooltip, Header, Checkbox } from '@fluentui/react-northstar';
import { platformService } from '../shared/services/platform.service';
import { Communication, TToolbarInteraction } from '@fluentui/react-teams';
import { PeoplePicker, PersonType, TeamsChannelPicker } from '@microsoft/mgt-react';
import { MgtTeamsChannelPicker } from '@microsoft/mgt-components';
import { Providers } from '@microsoft/mgt-element';
import { Toolbar } from '../shared/components/Toolbar';
import ConfigCSS from '../config/config.module.css';
import { PlatformUser } from '../shared/interfaces/platformuser.interface';
import { graphService } from '../shared/services/graph.service';
import '../AppGlobals';
import { Breadcrumbs } from "../shared/components/BreadcrumbNavigation"
import { useHistory } from "react-router-dom";
import MgtCss from './../mgt.module.css';
import { CheckLogin } from '../shared/components/CheckLogin';
import { Helmet } from 'react-helmet';
import { LoadingScreen } from '../shared/components/LoadingScreen';
import { ThemeColorScheme } from '../shared/common/TeamsTheme';
import { MobileSettingsView } from '../automation/AutomationTable';
import { ITeam } from '../shared/interfaces/supportGroup.interface';
import { useTranslation } from 'react-i18next';
import { ADMINISTRATORS, CONTACT_ADMIN, EMAIL, LICENSE_FEATURE, REFRESH_SESSION, SOMETHING_WENT_WRONG, UNAUTHORIZED_ACCESS } from '../shared/utils/constants';
import { toTitleCase } from '../shared/utils/helper';
import './Teams.css';
import { FeatureConsentNeeded, UpgradeNeeded } from '../shared/components/UpgradeNeeded';
import { getCachedFeature, hasConsentFeature } from '../shared/cache/FeatureCache';
import { checkInTeams } from '../App';
import { appState } from '../AppState';

class Team implements ITeam {
  constructor(id, name, friendlyName, description, teamsId, teamsAadObjectId, teamsChannelId, teamsChannelName, users, isDefault, email, userId, subId, loc, deptPick, deptMsg, deflect) {
    this.Id = id;
    this.Name = name;
    this.FriendlyName = friendlyName;
    this.Description = description;
    this.TeamsId = teamsId;
    this.TeamsAadObjectId = teamsAadObjectId;
    this.TeamsChannelId = teamsChannelId;
    this.TeamsChannelName = teamsChannelName;
    this.Users = users;
    this.IsDefault = isDefault;
    this.EmailAddress = email;
    this.UserId = userId;
    this.SubscriptionId = subId;
    this.DefaultLocale = loc;
    this.EnableMultiDepartmentPicker = deptPick;
    this.MultiDepartmentPickerMessage = deptMsg;
    this.UseTicketDeflection = deflect;
  }
  Id: 0;
  Name: "";
  FriendlyName: "";
  Description: "";
  TeamsId: "";
  TeamsAadObjectId: "";
  TeamsChannelId: "";
  TeamsChannelName: "";
  Users: PlatformUser[];
  IsDefault: false;
  EmailAddress: "";
  UserId: "";
  SubscriptionId: "";
  DefaultLocale: "en-US";
  EnableMultiDepartmentPicker: false;
  UseTicketDeflection: true;
  MultiDepartmentPickerMessage: "";
}

export interface ITeamsFormProperties {
  id: string,
}

export const Teams = (props: ITeamsFormProperties) => {
  const { t } = useTranslation();
  const history = useHistory();
  const api = new platformService();
  const graphAPI = new graphService();
  MgtTeamsChannelPicker.config.useTeamsBasedScopes = true;

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errCode, setErrCode] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [resetPicker, setResetPicker] = useState(false);
  const [team, setTeam] = useState<ITeam>(new Team(0, "", "", "", "", "", "", "", [], false, "", "", "", "en-US", false, "", true));
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [hasTikit, setHasTikit] = useState(true);
  const [teamSelectionList, setTeamSelectionList] = useState<DropdownDataModel[]>([]);
  const [allTeamsAadObjectId, setAllTeamsAadObjectId] = useState([]);
  const [isDefaultTeam, setIsDefaultTeam] = useState(false);
  const [multiEmailInbox, setMultiEmailInbox] = useState(false);
  const [connectedEmail, setConnectedEmail] = useState({ email: "", id: "" });
  const [hasEmailConsent, setHasEmailConsent] = useState(true);
  const [cultures, setCultures] = useState([]);
  const [fetchDataComplete, setFetchDataComplete] = useState(false);
  const currentState = appState();

  const onToolbarInteraction = (interaction: TToolbarInteraction) => {
    if (interaction.action === "toggle-menu") {
      setMenuOpen(!menuOpen);
    }
  }

  const fetchData = async () => {
    setLoadingMessage(t('teams.loading-your-team'));

    const isMultiInbox = await getCachedFeature(LICENSE_FEATURE.MultiEmailInbox);
    setMultiEmailInbox(isMultiInbox);

    if (props.id && parseInt(props.id) > 0) {
      const result: Team = await api.getTeams(`(${props.id})`).then((val) => {
        let value = val.data;
        setIsError(false);
        return value;
      }).catch((e: any) => {
        setIsError(true);
        setErrCode(e.response.status);
      });

      if ((result.MultiDepartmentPickerMessage?.trim().length ?? 0) == 0)
        result.MultiDepartmentPickerMessage = t('bot-configuration.enduser-select-team-default');

      let sg = new Team(result.Id, result.Name, result.FriendlyName, result.Description, result.TeamsId, result.TeamsAadObjectId, result.TeamsChannelId, result.TeamsChannelName, result.Users, result.IsDefault, result.EmailAddress, result.UserId, 
        result.SubscriptionId, result.DefaultLocale ?? "en-US", result.EnableMultiDepartmentPicker, result.MultiDepartmentPickerMessage, result.UseTicketDeflection);
      initTeamData(sg);
      const teamsList: DropdownDataModel[] = [
        {
          key: result.TeamsAadObjectId,
          label: result.Name,
          header: result.Name
        }
      ];
      setIsDefaultTeam(sg.IsDefault);
      setTeamSelectionList(teamsList);
      setConnectedEmail({ email: sg.EmailAddress ?? "", id: sg.UserId ?? "" });

      if (isMultiInbox) {
        const result = await api.getCultures();
        const available = result.data.value.map(l => {
          const label = new Intl.DisplayNames(l.Code, { type: 'language', languageDisplay: 'dialect' }).of(l.Code);
          return {
            header: label.charAt(0).toUpperCase() + label.slice(1),
            key: l.Code
          } 
        });
        available.push({ header: new Intl.DisplayNames('en', { type: 'language', languageDisplay: 'dialect' }).of('en'), key: 'en-US' });
        available.sort((a, b) => a.header.localeCompare(b.header));
        setCultures(available);
      }

      setIsLoading(false);
    } else {
      try {
        const results = await Promise.all([
          api.getTeams('?$select=Name,TeamsAadObjectId'),
          graphAPI.getJoinedTeams('?$select=displayName,id')
        ]);

        let existingTeams = results[0].data.value;
        existingTeams = existingTeams.map(a => a.TeamsAadObjectId);
        setAllTeamsAadObjectId(existingTeams);

        const joinedTeams = results[1].data.value;
        const thisTeamsList: DropdownDataModel[] = joinedTeams.filter(x => existingTeams.indexOf(x.id) == -1)
          .map(x => {
            return {
              key: x.id,
              label: x.displayName,
              header: x.displayName
            }
          });
        setTeamSelectionList(thisTeamsList);

        setIsError(false);
        setIsLoading(false);
      } catch (e: any) {
        setIsError(true);
        setErrCode(e.response.status);
      }

    }
  }

  const loadPage = async () => {
    fetchData();
    setHasEmailConsent(await hasConsentFeature('EMAIL_CONNECTOR'));
  }

  const errConfig = {
    fields: {
      title: (errCode == 401) ? UNAUTHORIZED_ACCESS : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  };

  const savingData = async (thisTeam: ITeam) => (thisTeam.Id == 0) ? api.createTeam(thisTeam) : api.updateTeam(thisTeam);

  const saveData = async () => {
    setLoadingMessage(t('teams.updating-your-teams-with-the-changes-you-made'));
    setIsLoading(true);

    if (team.Id == 0) {
      let teamData = await graphAPI.getTeamData(team.TeamsAadObjectId).then((val) => {
        return val.data;
      });
      team.TeamsId = teamData["internalId"] ?? "";
    }

    savingData(team)
      .then(async (result) => {
        if (team.IsDefault && currentState.userRoles.roles.includes(ADMINISTRATORS)) {
          const { data } = await api.getEmailConnectorConfiguration();
          delete data['@odata.context'];

          data.UseTicketDeflection = team.UseTicketDeflection;
          data.DefaultLocale = team.DefaultLocale;
          data.EnableMultiDepartmentPicker = team.EnableMultiDepartmentPicker;
          data.MultiDepartmentPickerMessage = team.MultiDepartmentPickerMessage;

          await api.setSystemSetting('ServiceDesk.Common.Integrations.Email.Configuration.EmailConnectorConfiguration', data);
        }

        let roleName = result.data.Name;
        if (result.data == '') {
          roleName = JSON.parse(result.config.data).Name;
        }

        let payload: MappedRolePayload[] = [{
          ProviderRole: result.data.Name,
          EntraId: result.data.TeamsAadObjectId,
          ApplicationRole: toTitleCase(globalThis.sdApp.RoleNameAgents)
        }];
        await api.createMappedRole(payload);

        if (team.Id == 0 && !hasTikit) {
          let tikitApp = await graphAPI.getCatalogApps('').then((val) => {
            let apps = val.data.value || [];
            return apps.filter(x => x["externalId"] == window.__runtimeConfig.tikitClientId || x["id"] == window.__runtimeConfig.tikitClientId);
          });
          if (tikitApp.length > 0)
            graphAPI.installTeamApp(team.TeamsAadObjectId, tikitApp[0].id);
        }
        if (team.Id == 0 && result.data.Id)
          team.Id = result.data.Id;
        if (team.IsDefault)
          await checkAndUndefaultOther(team.Id);
      })
      .catch((e: any) => {
        setIsError(true);
        setErrCode(e.response.status);
      })
      .finally(() => {
        setTeam({
          ...team,
          TeamsChannelId: "",
          TeamsChannelName: ""
        });
        history.push(`/settings/teams`)        
      });
  };

  const checkAndUndefaultOther = async (currentDefaultId: number) => {
    try {
      const {
        data: { value }
      } = await api.getTeams(`?v=${new Date().getTime()}`);

      const defaultList: ITeam[] = value
        .filter(
          (val: ITeam) =>
            val.IsDefault && val.Id !== currentDefaultId
        )

      if (defaultList.length > 0) {
        for (const team1 of defaultList) {
          team1.IsDefault = false;
          await savingData(team1);
        }
      }
    } catch (e: any) {
      setIsError(true);
      setErrCode(e.response.status);
    }
    // no finally block to avoid the flicker effect because finally sets the status to done
  };

  const setGroup = async (event) => {
    if (event.detail.length > 0 && event.detail[0].channel.id.length > 0) {

      if (team.Id == 0) {
        team.Name = event.detail[0].team.displayName;

        let tikitApp = await graphAPI.getTeamApps(event.detail[0].team.id).then((val) => {
          let apps = val.data.value || [];
          return apps.filter(x => x["teamsApp"]["externalId"] == window.__runtimeConfig.tikitClientId || x["teamsApp"]["id"] == window.__runtimeConfig.tikitClientId);
        });

        if (tikitApp.length > 0) {
          setHasTikit(true);
        } else
          setHasTikit(false);
      }

      setTeam({
        ...team,
        TeamsChannelId: event.detail[0].channel.id,
        TeamsChannelName: event.detail[0].channel.displayName
      });
    } else {
      const picker: any = document.querySelector('mgt-people-picker');
      if (picker) picker.clearState();
      setTeam({
        ...team,
        TeamsChannelId: "",
        TeamsChannelName: ""
      });
    }
  };

  const initTeamData = (sg: ITeam) => {
    setTeam(sg);
  }

  const onChangeMailbox = async (e: any) => 
    (e.detail.length === 0) ? setConnectedEmail({ email: "", id: "" }) : 
                              setConnectedEmail({ email: e.detail[0].scoredEmailAddresses && e.detail[0].scoredEmailAddresses.length > 0 ? e.detail[0].scoredEmailAddresses[0].address : e.detail[0].userPrincipalName, id: e.detail[0].id });
  
  const connectInbox = async () => {
    setIsLoading(true);
    setLoadingMessage(t('notification-center.connecting-mailbox'));

    try {
      await api.subscribeToEmailTeam(team.Id, connectedEmail.id, connectedEmail.email);
      fetchData();
    } catch (err: any) {
      setIsLoading(false);
      setIsError(true);
      setErrCode(err['response']?.status);
    }
  };
  const disconnectInbox = async () => {
    setIsLoading(true);
    setLoadingMessage(t('notification-center.disconnecting-mailbox'));

    try {
      await api.unsubscribeToEmailTeam(team.Id);
      fetchData();
    } catch (err: any) {
      setIsLoading(false);
      setIsError(true);
      setErrCode(err['response']?.status);
    }
  };

  useEffect(() => {
    if (isLoading) return;

    let count = 0;
    const setPicker = async () => {
      const picker: any = document.querySelector('mgt-teams-channel-picker');

      if (picker?.isLoadingState ?? true)
        setTimeout(setPicker, 500);
      else {
        if (team.TeamsChannelId)
          await picker.selectChannelById(team.TeamsChannelId);

        setFetchDataComplete(true);

        const pickerSelector = picker.shadowRoot;
        const pickerList = pickerSelector.querySelectorAll('.list-team');

        if (team.TeamsChannelId && !picker.selectedItem)
          setTeam({
            ...team,
            TeamsChannelId: "",
            TeamsChannelName: ""
          });

        if (team.Id != 0 && picker.selectedItem) {
          const itemIndex = picker.items.findIndex((data) => {
            return data.item.id == picker.selectedItem.team.id;
          });

          pickerList.forEach((element, index) => {
            if (itemIndex != index)
              element.style.display = "none";
          });
        } else {
          picker.items.forEach((data, index) => {
            if ((team.TeamsAadObjectId && team.TeamsAadObjectId != data.item.id) || allTeamsAadObjectId.indexOf(data.item.id) > -1)
              pickerList[index].style.display = "none";
          });
        }
        setDisabled(false);
      }

      count++;
    }
    setPicker();

  }, [team.TeamsChannelId, team.TeamsAadObjectId, isLoading]);

  const navs: Breadcrumbs = {
    breadcrumbs: [
      {
        title: t('common.settings'),
        link: '/settings'
      },
      {
        title: toTitleCase(t('teams.teams')),
        link: '/settings/teams'
      },
      {
        title: (team.Name.length > 0) ? team.Name : t('teams.new-team'),
        link: ""
      }
    ]
  };

  const LabelElement = (props1: any) => {
    return (
      <div>
        <Flex className={props1.className ?? "pt-6"}>
          <Text content={props1.label} style={{ display: 'block' }} />
          {props1.required && <span style={{ color: 'red', marginLeft: 2 }}>*</span>}
        </Flex>
        <div>{props1.children}</div>
      </div>
    )
  }

  return (
    <CheckLogin onSignedIn={loadPage}>
      <Helmet>
        <title>{(team.Name.length > 0) ? team.Name : t('teams.new-team')} - Tikit</title>
      </Helmet>
      <div className="hidden md:block">
        {
          <>
            {isLoading && (<LoadingScreen message={loadingMessage} />)}
            {(!isLoading && isError) && (<>
              <Communication {...errConfig} />
              {errCode === 401 && (<Button content={REFRESH_SESSION} primary onClick={_e => { Providers.globalProvider.login(); }} />)}
            </>)}
            {(!isLoading && !isError) && (
              <ProviderConsumer render={(globalTheme) => (
                <div style={{
                  display: 'block',
                  ...{ '--input-background-color': 'var(--mgt-theme-background)' },
                  ...ThemeColorScheme(globalTheme.siteVariables)
                }}>
                  <Toolbar onInteraction={onToolbarInteraction} globalTheme={globalTheme} title="Settings > Teams" saveText={t('common.buttons.save')} saveCommand={saveData} breadcrumbs={navs}
                    disableSave={!team.Name || !team.FriendlyName || !team.TeamsChannelId || !fetchDataComplete} />
                  <Flex className={ConfigCSS.configBody}>
                    <Flex.Item grow styles={{ maxHeight: checkInTeams() ? "calc(100vh - 50px)" : "calc(100vh - 100px)" }}>
                      <Flex column className={`px-4 lg:w-1/2 w-full`} styles={{ overflow: "auto" }}>
                        <Header content={t('teams.header')} className="mb-2" />
                        <LabelElement label={t('teams.teams-columns.team')} required={true} className="pt-0" />
                        <div className="teams-input-med lg:w-1/2">
                          <Dropdown
                            search={team.Id == 0 ? true : false}
                            clearable={team.Id == 0 ? true : false}
                            fluid
                            items={teamSelectionList}
                            placeholder={t('teams.select-a-team')}
                            value={teamSelectionList.filter(x => x.key == team.TeamsAadObjectId)}
                            styles={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
                            inverted
                            onChange={(_e, p) => {
                              const data = p.value as DropdownDataModel;
                              setTeam({
                                ...team,
                                Name: data ? data.label : "",
                                TeamsAadObjectId: data ? data.key : "",
                                TeamsChannelId: "",
                                TeamsChannelName: "",
                                FriendlyName: data ? data.label : ""
                              });
                              setResetPicker(!resetPicker);
                            }}

                          />
                        </div>
                        <LabelElement label={toTitleCase(t('teams.teams-columns.friendly-name'))} required={true}></LabelElement>
                        <div className="teams-input-med lg:w-1/2">
                          <Input clearable inverted placeholder={t('teams.enter-team-friendlyname')} value={team.FriendlyName} onChange={(_e, p) => { setTeam({ ...team, FriendlyName: p.value }); }} fluid />
                        </div>
                        <LabelElement label={t('teams.triage-channel')} required={true} />
                        <div className={`${(!team.TeamsAadObjectId || disabled) ? MgtCss.disabledContainer : ''} lg:w-1/2`}>
                          <>
                          <TeamsChannelPicker selectionChanged={setGroup} className={`width-med ${MgtCss.noBorder}`} />
                            {(!hasTikit) && (
                              <Text color={'brand'} content={t('teams.tikit-app-will-be-installed-for-this-team')}></Text>
                            )}
                          </>
                        </div>
                        <LabelElement label={t('teams.description')} />
                        <div className="teams-input-med lg:w-1/2">
                          <Input clearable inverted placeholder={t('teams.enter-team-description')} value={team.Description} onChange={(_e, p) => { setTeam({ ...team, Description: p.value }); }} fluid />
                        </div>
                        {team.IsDefault ? (
                          <Tooltip position={"after"}
                            content={t('teams.the-default-triage-channel')} trigger={
                              <Flex className="teams-input-med lg:w-1/2  mt-4" styles={{ width: "fit-content" }}>
                                <FormCheckbox
                                  label={t('teams.default')}
                                  disabled={isDefaultTeam}
                                  checked={team.IsDefault}
                                  onChange={(_, checked) => {
                                    setTeam({
                                      ...team,
                                      IsDefault: checked.checked
                                    });
                                  }}
                                />
                              </Flex>
                            } />
                        ) : (
                          <Flex
                            className="teams-input-med lg:w-1/2  mt-4"
                            styles={{ width: 'fit-content', }}
                          >
                            <FormCheckbox
                              label="Default"
                              disabled={isDefaultTeam}
                              checked={team.IsDefault}
                              onChange={(_, checked) => {
                                setTeam({
                                  ...team,
                                  IsDefault: checked.checked
                                });
                              }}
                            />
                          </Flex>
                        )}
                        {team.Id !== 0 && currentState.userRoles.roles.includes(ADMINISTRATORS) && (<div className="teams-input-med lg:w-1/2 mt-4">
                          <Header className="mb-4" content={t('notification-center.connect-to-an-Office')} />
                          {multiEmailInbox ? (<>
                            {!hasEmailConsent && (<FeatureConsentNeeded />)}
                            <Flex style={{ fontSize: '14px', lineHeight: '20px', color: '#616161', paddingBottom: '27.5px' }}>{t('teams.email-connector')}</Flex>
                            <Flex column gap="gap.medium">
                              {!team.EmailAddress && !team.SubscriptionId && !team.UserId && <Flex gap="gap.medium" vAlign="center">
                                <Flex style={{ fontWeight: 600, fontSize: '16px', lineHeight: '22px', color: globalTheme.siteVariables.colorScheme.default.foreground }}>
                                  {t('notification-center.select-an-account')}
                                </Flex>
                              </Flex>}
                              <Flex column gap="gap.medium">
                                {!team.EmailAddress && !team.SubscriptionId && !team.UserId ? (
                                  <div className="teams-input-med">
                                    <PeoplePicker type={PersonType.any} selectionMode="single" className={`width-med height-small ${MgtCss.groupsPicker}`} selectionChanged={onChangeMailbox} />
                                  </div>
                                ) : (
                                  <Flex vAlign="center" gap="gap.small"> 
                                    <Text content={`${toTitleCase(EMAIL)}:`} style={{ color: '#686868' }} />
                                    <div className="teams-input-med">{team.EmailAddress}</div>
                                  </Flex>)}
                                <Flex>{!team.EmailAddress && !team.SubscriptionId && !team.UserId ? (<Button className="p-0" content={t('notification-center.connect-m365-mailbox')} primary disabled={connectedEmail.email === '' && connectedEmail.id === ''} onClick={connectInbox} />) : 
                                                                                                    (<Button className="p-0" content={t('notification-center.disconnect-m365-mailbox')} primary onClick={disconnectInbox} />)}</Flex>
                              </Flex>
                            </Flex>
                            {team.EmailAddress && team.SubscriptionId && team.UserId && (<Flex column>
                              <Header as="h3" content={<>{t('notification-center.default-locale')}<span style={{ color: 'red', marginLeft: 2, fontWeight: "bold" }}>*</span></>} className="pt-8 pb-2 defaultStyle" />
                              <Dropdown items={cultures} onChange={(e, p) => setTeam({ ...team, DefaultLocale: p.value['key'] })} value={cultures.find(c => c.key === team.DefaultLocale)?.header} inverted fluid style={{ background: globalTheme.siteVariables.colorScheme.default.background }} />
                              <Header as="h3" className="pt-8 pb-2 defaultStyle" content={t('notification-center.ticket-deflection-optional')} />
                              <Checkbox label={t('notification-center.enable-ticket-deflection')} toggle onChange={(_, d) => setTeam({ ...team, UseTicketDeflection: d.checked })} checked={team.UseTicketDeflection} />
                              <>
                                <Header as="h3" className="pt-8 pb-2 defaultStyle" content={t('notification-center.multideptpicker-optional')} />
                                <p className="text-sm pb-4">{t('notification-center.multideptpicker-subcopy')}</p>
                                <div className='mb-4'>
                                  <Checkbox label={t('bot-configuration.enduser-enable-multideptpicker')} toggle onChange={(_, d) => setTeam({ ...team, EnableMultiDepartmentPicker: d.checked })} checked={team.EnableMultiDepartmentPicker} />
                                  {team.EnableMultiDepartmentPicker && (<div className="pt-4">
                                    <Text content={t('bot-configuration.enduser-select-team-message')} weight="bold"/>
                                    <Input value={team.MultiDepartmentPickerMessage} inverted fluid placeholder={t('bot-configuration.enduser-select-team-placeholder')} onChange={(event: any) => setTeam({ ...team, MultiDepartmentPickerMessage: event.target.value })} className="py-2"/>
                                  </div>)}
                                </div>
                              </>
                            </Flex>)}
                          </>) : (
                            <UpgradeNeeded headerText={t('teams.inbox-disabled.header')} subheaderText={t('teams.inbox-disabled.sub-header')} svgSize={125} />
                          )}
                        </div>)}
                      </Flex>
                    </Flex.Item>
                  </Flex>
                </div>)} />
            )}
          </>
        }
      </div>
      <MobileSettingsView />
    </CheckLogin>
  )
}