import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { ProviderConsumer as FluentUIThemeConsumer } from '@fluentui/react-northstar';
import TicketCardTemplate from '../../../shared/components/TicketCardTemplate';
import { CardTemplate } from './CardTemplate';
import { STATUS_GUID } from '../../../shared/utils/constants';
import { Route } from '../../../../route-constants';
import { getUser } from '../../../AuthConfig';
import { MyWorkContext } from '../../MyWork/MyWorkContextProvider';
import { getShowClosedFromStorage } from '../../MyWork/MyWorkList/myWorkListHelper';
import { platformService } from '../../../shared/services/platform.service';
import { TicketCardSkeleton } from '../../../shared/components/TicketCard/TicketCardSkeleton';
import { EmptyData } from '../../../shared/components/EmptyData';
import EmptySVG from '../../../../svg/empty.svg';
import { appState } from '../../../AppState';
import { useTranslation } from 'react-i18next';
import { getStatusIdByGuid } from '../../ticketHelper';

const api = new platformService();

export const TicketTemplate = ({ boardItemCardLayout }: Omit<TicketCardTemplate, 'ticket'>) => {
  const history = useHistory();
  const { myWorkStates } = useContext(MyWorkContext);
  const [tickets, setTickets] = useState(undefined as Ticket[] | undefined);
  const location = useLocation();
  const currentState = appState();
  const { t } = useTranslation();
  
  const isMyGroupWork = location.pathname.includes(Route.myGroupWork.pagePath);
  const isClosed = getShowClosedFromStorage(isMyGroupWork) || myWorkStates.showClosed;

  let groupWorkFilter = '';
  if (isMyGroupWork) {
    const groupIds = currentState?.supportgroups?.filter(o => o.Members.some(m => m.Id == currentState.currentUserId))?.map(o => o.Id) ?? [];
    if (groupIds.length > 0) 
      groupWorkFilter = `or (SupportGroupId ne null and SupportGroupId in (${groupIds.join(',')}))`;
  }

  const resolvedId = getStatusIdByGuid(currentState.ticketStatus, STATUS_GUID.RESOLVED);
  const closedId = getStatusIdByGuid(currentState.ticketStatus, STATUS_GUID.CLOSED);
  const deflectedId = getStatusIdByGuid(currentState.ticketStatus, STATUS_GUID.DEFLECTED);


  const assigneeFilter = `AssigneeId eq ${currentState.currentUserId} or TicketCollaborators/any(c: c/PlatformUserId eq ${currentState.currentUserId})`;
  let ticketsFilter = `StatusId in (${resolvedId},${closedId},${deflectedId})`;
  if (!isClosed)
    ticketsFilter = `not(${ticketsFilter})`;

  const ticketListQuery = `?$expand=AffectedUsers($select=PlatformUserId),TicketCollaborators($select=PlatformUserId)&$filter=(${assigneeFilter} ${groupWorkFilter}) and ${ticketsFilter}&$orderby=CreatedDate desc`;

  useEffect(() => {
    (async () => {
      setTickets(undefined);
      const result = await api.getTicketList(ticketListQuery);
      const tickets = result.data.value as Ticket[];
      setTickets(tickets);
    })();
  }, [isClosed]);

  const routeChange = (ticketId: number) => {
    const route = isMyGroupWork ? Route.myGroupWork.editTicket : Route.myWork.editTicket
    let path = `${route}/${ticketId}`;
    const from = isMyGroupWork ? 'mygroupwork' : 'mywork';
    history.push(path, { from });
  };

  return (
    <FluentUIThemeConsumer render={globalTheme => (
      <>
        {!tickets ? (
          <>
            <TicketCardSkeleton />
            <TicketCardSkeleton />
            <TicketCardSkeleton />
          </>
        ) : tickets?.length === 0 ? (
          <EmptyData
            headerText={t('my-work.my-ticket.no-ticket')}
            subheaderText=''
            SVGIcon={<EmptySVG width={75} height={75} />}
          />
        ) : (
          tickets.map((ticket: Ticket) => {
            const item = { ...ticket, preview: '' };
            return (
              <div
                onClick={() => { routeChange(item.Id) }}
                style={{ cursor: 'pointer' }}
              >
                <CardTemplate
                  boxStyle={{ opacity: `${ticket.Status?.Guid === STATUS_GUID.CLOSED ? '0.6' : '1'}` }}
                  {...({ styles: { backgroundColor: globalTheme.siteVariables.colorScheme.default.background } })}
                >
                  <TicketCardTemplate
                    ticket={item}
                    boardItemCardLayout={boardItemCardLayout}
                    from='mywork'
                  />
                </CardTemplate>
              </div>
            )
          })
        )}
      </>
    )} />
  );
};
