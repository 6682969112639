import React, { useState } from 'react';
import { ThemePrepared, Flex, ChevronEndIcon, ChevronDownIcon, Button, TrashCanIcon, Dialog, FormInput } from '@fluentui/react-northstar';
import { toTitleCase } from '../../../../shared/utils/helper';
import { BUTTONS, DELETE } from '../../../../shared/utils/constants';
import { transformModalContent } from '../../../../automation/AutomationTable';
import { platformService } from '../../../../shared/services/platform.service';

interface Props {
    isAccordionOpen: boolean;
    globalTheme: ThemePrepared<any>;
    displayName: string;
    deleteTitle: string;
    deleteMessage: string;
    removeItem: () => void;
    onChangeTextField: (value: string) => void,
    onBlurTextField: (value: string) => void,
    clickOnExpand?: (index: number, isExpand: boolean) => void,
    index?: number,
    phaseId?: number,
    deleteWarningMessage?: string
}

export const LifecycleAccordion = ({
    isAccordionOpen,
    globalTheme,
    displayName,
    deleteTitle,
    deleteMessage,
    removeItem,
    onChangeTextField,
    onBlurTextField,
    clickOnExpand,
    index,
    phaseId,
    deleteWarningMessage
}: Props) => {
  
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openWarningDeleteDialog, setOpenWarningDeleteDialog] = useState(false);
  const [transitions, setTransitions] = useState([]);
  const api = new platformService();

  const peformDeleteCheck = async()=>{
    if (phaseId) {
      const transitionList = await api.getTransitionsByTransitionAction(phaseId.toString());
      setTransitions(transitionList);
      (transitionList.length > 0) ? setOpenWarningDeleteDialog(true) : setOpenDeleteDialog(true);
    } else {
      setOpenDeleteDialog(true);
    }
  }
  
  return (
    <>
      <Flex vAlign="center" space="between"
        style={{ borderBottom: isAccordionOpen ? `1px solid ${globalTheme.siteVariables.colorScheme.brand.foreground1}` : 'none'}}
      >
        <Flex.Item styles={{width: '100%'}}>
          {
            isAccordionOpen ? <Flex gap="gap.small" vAlign="center">
              <Flex.Item>
                <span onClick={() => clickOnExpand(index, !isAccordionOpen)} className={`cursor-pointer`} title={`Collapse`}><ChevronEndIcon /></span>
              </Flex.Item>
              <Flex.Item styles={{width: '85%'}}>
                <FormInput
                  inverted
                  fluid
                  value={displayName}
                  onChange={e => {
                    onChangeTextField((e.target as HTMLInputElement).value);
                  }}
                  onBlur={e => {
                    onBlurTextField((e.target as HTMLInputElement).value);
                  }}
                />
              </Flex.Item>
            </Flex> : <Flex gap="gap.small" vAlign="center" title={`Expand`} onClick={() => clickOnExpand(index, !isAccordionOpen)} className={`cursor-pointer`}>
              <Flex.Item>
                <span><ChevronDownIcon /></span>
              </Flex.Item>
              <Flex.Item styles={{width: '85%'}}>
                <div>
                  {displayName}
                </div>
              </Flex.Item>
            </Flex>
          }
        </Flex.Item>
        <Flex.Item>
          <Flex gap={`gap.small`} vAlign={`center`}>
            <Flex.Item>
              <div>
                <Button
                  icon={<TrashCanIcon />}
                  text
                  iconOnly
                  size="small"
                  style={{ color: globalTheme.siteVariables.colorScheme.brand.foregroundActive }}
                  title={deleteTitle}
                  onClick={async e => {
                    e.stopPropagation();
                    await peformDeleteCheck();
                  }}
                />
              </div>
            </Flex.Item>
          </Flex>
        </Flex.Item>
      </Flex>
      <Dialog
        open={openDeleteDialog}
        onCancel={() => setOpenDeleteDialog(false)}
        cancelButton={toTitleCase(BUTTONS.CANCEL)}
        confirmButton={toTitleCase(DELETE)}
        onConfirm={() => {
          removeItem();
          setOpenDeleteDialog(false);
        }}
        content={transformModalContent`${deleteMessage}`}
        header={deleteTitle}
        style={{ maxWidth: '500px' }}
      /> 
      <Dialog
            open={openWarningDeleteDialog}
            onConfirm={() => setOpenWarningDeleteDialog(false)}
            confirmButton={toTitleCase(BUTTONS.OK)}
            content={<>
              <div>{deleteWarningMessage}</div>
              <br/>
              {transitions && transitions.map(transition =>
                <p>{transition.Title}</p>
              )}
            </>}
            header={deleteTitle}
            style={{ maxWidth: '500px' }}
          />
    </>
  );

};
