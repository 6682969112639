import React from 'react';
import {
  Text,
} from '@fluentui/react-northstar';
import { ApprovalCard } from './TicketRequestCard';
import { PlatformUser } from '../../../shared/interfaces/platformuser.interface';
import { TablePagination } from '../../../shared/components/table/TablePagination';
import { useHistory } from 'react-router-dom';
import { MyWorkListViewType, TicketApprovalState } from '../../../shared/utils/constants';
import { useTranslation } from 'react-i18next';
import { useSetState } from '../../../AppState';

interface Props {
  paginatedApprovals: any[],
  column: number,
  users: PlatformUser[],
  userId: number,
  pagination: {
    currentPage: number,
    totalCount: number,
    moveToPage: (type: string) => void,
    itemPerPage: number
  }
  onUpdate: (approvalId: number, approverId: number, status: TicketApprovalState, approvalState: TicketApprovalState) => void
}

export const ApprovalColumn = ({ paginatedApprovals, column, users, userId, pagination, onUpdate }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const setAppState = useSetState();

  return (
    <>
      <div className="overflow-auto" style={{ height: 'calc(100vh - 165px - 3.25rem)' }}>
        {paginatedApprovals.map((ticketApproval: any) => (
          <div onClick={() => {
            setAppState(prev => ({ ...prev, selectedTaskOrApproval: { type: MyWorkListViewType.Approvals, id: ticketApproval.TicketId } }));
            history.push(`/requests/${ticketApproval.TicketId}`)
          }} className='cursor-pointer'>
            <ApprovalCard key={ticketApproval.Id} ticket={ticketApproval.Ticket} view='list' approvalProps={{ approval: ticketApproval, users: users, userId: userId }} onUpdate={onUpdate} />
          </div>
        ))}
      </div>
      <TablePagination currentPage={pagination.currentPage} totalCount={pagination.totalCount} moveToPage={pagination.moveToPage} itemPerPage={pagination.itemPerPage} />
    </>
  )
}