import React, { createRef, useEffect, useState } from "react";
import {
  Flex,
  Loader,
  List,
  Button,
  ListItem,
  Text,
  Skeleton,
  Alert,
  ThemePrepared,
  Grid,
  gridHorizontalBehavior,
  ChevronEndIcon,
  ChevronDownIcon,
  Dialog,
  CloseIcon,
} from "@fluentui/react-northstar";
import { ExclamationTriangleIcon } from "@fluentui/react-icons-northstar";
import { platformService } from "../../services/platform.service";
import { graphService } from "../../services/graph.service";
import RelativeDate from "../RelativeDate";
import Dropzone, { DropzoneRef, FileRejection } from "react-dropzone";
import { Icon, Link, mergeStyleSets } from "@fluentui/react";
import { EmptyData } from "../EmptyData";
import EmptySVG from "../../../../svg/empty.svg";
import { debounce } from "lodash";
import CSS from "./EditTicketAttachments.module.css";
import { EditTicketEvent } from "../../../tikit/ticketHelper";
import { i18n } from "../../utils/helper";
import { useTranslation } from "react-i18next";
import {
  NewAttachmentFlowContent,
  getFileIcon,
  getTruncatedFilename,
} from "./NewAttachmentFlow/NewAttachmentFlowContent";
import { ITeamsConfig } from "../../../config/TeamsConfig";
import { appState, useSetState } from "../../../AppState";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

interface Props {
  isOpen: boolean;
  onCLose: (value: boolean) => void;
  primaryButtonColor: string;
  isSaving: boolean;
  onInteraction?: (interaction: any) => void;
  onToolbarInteraction?: (interaction: any) => void;
  globalTheme: ThemePrepared<any>;
  isTicketClosed: boolean;
  ticketId: number;
  fileTriggerChannel: string;
  isEndUserPage: boolean;
  fileAttachments: FileProps[];
  signedInUser: {
    FullName: string;
    Id: number;
    Email: string;
  };
  teamsConfig: ITeamsConfig;
}

export interface FileProps {
  Id?: number;
  FileName: string;
  DownladUrl?: string;
  FileExtension: string;
  FileSize?: number;
  ModifiedById?: number;
  ModifiedDate?: string;
  DriveItemGroupId?: string;
  DriveItemId?: string;
  MoveItemFailed: boolean | false;
  isHidden: boolean | false;
  isUploading: boolean | false;
  isUploadFailed: boolean | false;
  isInProgress: boolean | false;
  progressPercent: number;
  uploadFailedMessage: string;
  isPublic?: boolean | true;
  iconElement?: JSX.Element | null;
  ThumbnailUrl?: string;
  Comment?: { Id: number };
}

const uploadFailedMessage = i18n().t(
  "ticket-details.edit-ticket-form.attachments.upload-failed"
);

export const bytesToSize = (bytes: number) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = 2;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const EditTicketAttachments = ({
  isOpen,
  onCLose,
  primaryButtonColor,
  isSaving,
  onInteraction,
  globalTheme,
  isTicketClosed,
  ticketId,
  fileTriggerChannel,
  isEndUserPage,
  fileAttachments,
  teamsConfig,
}: Props) => {
  const { t } = useTranslation();
  const api = new platformService();
  const graphApi = new graphService();
  const [uploadFiles, setUploadFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAttachmentListDisabled, setIsAttachmentListDisabled] =
    useState(false);

  const showColumns = isEndUserPage || isTicketClosed ? "1" : "2";
  const channelNotSet =
    fileTriggerChannel == null || fileTriggerChannel?.length < 1;
  const uploadDisabled = isTicketClosed || channelNotSet;
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const setAppState = useSetState();
  const currentState = appState();

  const classNames = mergeStyleSets({
    fileLink: {
      fontWeight: 600,
      color: globalTheme.siteVariables.colorScheme.brand.foreground1,
      ":hover, :active, :focus": {
        color: "var(--mgt-theme-brand-foreground1) !important",
      },
    },
    alertLink: {
      color: "inherit",
      fontWeight: 600,
      ":hover, :active, :focus": {
        color: "inherit",
      },
    },
    fileIcon: {
      color: globalTheme.siteVariables.colorScheme.default.foreground2,
      marginRight: ".5rem",
    },
  });

  const getFileAttachments = debounce(async () => {
    const filteredAttachments = isEndUserPage
      ? fileAttachments.filter((a) => a["IsPublic"])
      : fileAttachments;

    setAppState((state) => ({
      ...state,
      uploadingState: {
        ...state.uploadingState,
        uploadedFiles: filteredAttachments,
      },
    }));
  }, 300);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      await getFileAttachments();
      setIsLoading(false);
    })();
  }, [ticketId, fileAttachments]);

  const dropzoneRef = createRef<DropzoneRef>();
  const onDropAccepted = (items: File[]) => {
    onCLose(true);
    setUploadFiles(items);
    setOpenUploadDialog(true);
  };

  const onDropRejected = (fileRejections: FileRejection[]) => {
    setAppState((state) => {
      let newItems: FileProps[] = fileRejections.map((rejected) => {
        let item = rejected.file;
        return Object.assign(item, {
          FileName: item.name,
          isUploadFailed: true,
          uploadFailedMessage: `${uploadFailedMessage} ${rejected.errors
            .map((x) => x.message)
            .join(" ")}`,
        } as FileProps);
      });
      return {
        ...state,
        uploadingState: {
          ...state.uploadingState,
          uploadedFiles: newItems.concat(state.uploadingState.uploadedFiles),
        },
      };
    });
  };

  const removeFileInList = (event: any) => {
    const target = event.target as HTMLElement;
    target.closest("li").remove();
  };

  const deleteFileInList = async (id: any) => {
    try {
      setIsAttachmentListDisabled(true);
      const deleteFile = await api.deleteFile(id);
      if (deleteFile?.status == 204) {
        setIsAttachmentListDisabled(false);
        setAppState((state) => ({
          ...state,
          uploadingState: {
            ...state.uploadingState,
            uploadedFiles: state.uploadingState.uploadedFiles.filter(
              (item) => item.Id != id
            ),
          },
        }));

        onInteraction({ event: EditTicketEvent.DeleteSuccess });
      }
    } catch (error) {
      console.error(`There was an error deleting File`);
    }
  };

  const renderSkeleton = (num: number) =>
    Array.from(Array(num)).map((_r, index) => (
      <ListItem
        {...{
          index: index,
          media: (
            <Skeleton animation="wave">
              <Skeleton.Shape width="32px" height="32px"></Skeleton.Shape>
            </Skeleton>
          ),
          content: (
            <Skeleton animation="wave">
              <Skeleton.Line></Skeleton.Line>
            </Skeleton>
          ),
        }}
      />
    ));

  const renderFiles = currentState.uploadingState.uploadedFiles.map(
    (file, index) => {
      let iconType;

      if (file.isUploadFailed) {
        iconType = "failed";
      } else {
        iconType = file.FileExtension;
      }
      return (
        <ListItem
          {...{
            index: index,
            media: file.isUploading ? (
              file.isInProgress ? 
                <div style={{width: '1.5rem', height: '1.5rem'}}><CircularProgressbar value={file.progressPercent} strokeWidth={10} styles={buildStyles({ pathColor: globalTheme.siteVariables.colorScheme.brand.foreground, trailColor: "rgba(0,0,0,0)" })} /></div> : 
                <Loader size='smallest' inline /> 
            ) : (
              getFileIcon(iconType, file, globalTheme)
            ),
            header: (
              <Link
                disabled={
                  file.isUploading || file.isUploadFailed || file.MoveItemFailed
                }
                target="_blank"
                href={file.DownladUrl}
                className={classNames.fileLink}
              >
                {getTruncatedFilename(file.FileName)}
              </Link>
            ),
            headerMedia: file.isUploading ? (
              ""
            ) : file.isUploadFailed ? (
              <Text error content="Failed" />
            ) : (
              // bytesToSize(file.FileSize)
              ""
            ),
            endMedia:
              file.isUploading || file.MoveItemFailed ? (
                ""
              ) : file.isUploadFailed ? (
                <Button
                  disabled={isTicketClosed}
                  icon={<Icon iconName="Delete" className={"text-lg"} />}
                  iconOnly
                  text
                  title={t(
                    "ticket-details.edit-ticket-form.attachments.button.remove"
                  )}
                  onClick={removeFileInList}
                />
              ) : (
                <Grid
                  accessibility={gridHorizontalBehavior}
                  columns={showColumns}
                >
                  {!isEndUserPage && !isTicketClosed && (
                    <Dialog
                      style={{ maxWidth: "600px" }}
                      cancelButton={t(
                        "ticket-details.toolbar.action.delete-dialog.button.cancel"
                      )}
                      confirmButton={t(
                        "ticket-details.toolbar.action.delete-dialog.button.confirm"
                      )}
                      onConfirm={() => {
                        deleteFileInList(file.Id);
                      }}
                      header={t(
                        "ticket-details.edit-ticket-form.attachments.delete-dialog.header"
                      )}
                      content={getTruncatedFilename(file.FileName, 50)}
                      trigger={
                        <Button
                          disabled={isTicketClosed}
                          icon={
                            <Icon iconName="Delete" className={"text-sm"} />
                          }
                          iconOnly
                          text
                          title={t(
                            "ticket-details.edit-ticket-form.attachments.button.delete"
                          )}
                        />
                      }
                    />
                  )}

                  <Button
                    icon={<Icon iconName="Download" className={"text-sm"} />}
                    iconOnly
                    text
                    title={t(
                      "ticket-details.edit-ticket-form.attachments.button.download"
                    )}
                    onClick={() =>
                      graphApi.downloadFile(
                        file.DriveItemGroupId,
                        file.DriveItemId
                      )
                    }
                  />
                </Grid>
              ),
            content: file.isUploading ? (
              t(
                "ticket-details.edit-ticket-form.attachments.upload-in-progress"
              )
            ) : file.isUploadFailed || file.MoveItemFailed ? (
              <Text
                error={file.isUploadFailed}
                content={
                  file.MoveItemFailed
                    ? t(
                        "ticket-details.edit-ticket-form.attachments.move-to-different-team"
                      )
                    : file.uploadFailedMessage
                }
              />
            ) : (
              <span style={{ fontSize: "9px", lineHeight: "12px" }}>
                <RelativeDate
                  date={file.ModifiedDate}
                  text={t(
                    "ticket-details.edit-ticket-form.attachments.button.updated"
                  )}
                  fileSize={bytesToSize(file.FileSize)}
                />
              </span>
            ),
            style: {
              borderBottomWidth: "thin",
              height: "4rem",
              display: file.isHidden ? "none" : "flex",
              cursor: "auto",
            },
            hidden: file.isHidden,
          }}
        />
      );
    }
  );

  return (
    <Dropzone
      ref={dropzoneRef}
      noClick
      noKeyboard
      onDropAccepted={onDropAccepted}
      onDropRejected={onDropRejected}
      disabled={uploadDisabled}
    >
      {({ getRootProps, getInputProps, isDragActive }) => {
        return (
          <div
            {...getRootProps({
              className: "dropzone overflow-auto relative",
            })}
            style={
              isAttachmentListDisabled
                ? { pointerEvents: "none", opacity: "0.7" }
                : {}
            }
          >
            <input {...getInputProps()} />
            {isDragActive && !isTicketClosed && (
              <>
                <Flex
                  style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor:
                      globalTheme.siteVariables.colorScheme.default.background,
                    opacity: "0.7",
                    zIndex: 2,
                    cursor: "pointer",
                    position: "absolute",
                    justifyContent: "center",
                  }}
                />
                <Flex
                  style={{
                    height: "100%",
                    width: "100%",
                    zIndex: 5,
                    cursor: "pointer",
                    position: "absolute",
                    justifyContent: "center",
                    border: `2px solid ${globalTheme.siteVariables.colorScheme.brand.foregroundActive}`,
                  }}
                >
                  {currentState.uploadingState.uploadedFiles.length > 0 && (
                    <Text
                      content={t("ticket-details.drop-file-text")}
                      className="self-center"
                      style={{
                        color:
                          globalTheme.siteVariables.colorScheme.brand
                            .foregroundActive,
                      }}
                    />
                  )}
                </Flex>
              </>
            )}
            <div className="bg-red">
              <Flex
                vAlign="center"
                space="between"
                className={`mt-7 pb-4 cursor-pointer`}
                style={{
                  borderBottom: isOpen
                    ? `1px solid ${primaryButtonColor}`
                    : "none",
                }}
                onClick={() => {
                  onCLose(!isOpen);
                }}
              >
                <Flex gap="gap.small" vAlign="center">
                  {isOpen ? <ChevronEndIcon /> : <ChevronDownIcon />}
                  <Flex
                    style={{
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    <span>{t("ticket-details.edit-ticket-form.attachments.attachments")} ({currentState.uploadingState.uploadedFiles.length})</span>
                  </Flex>
                </Flex>
                {isOpen && (
                  <div className={`md:flex items-center`}>
                    {isSaving && (
                      <Loader
                        label={t(
                          "ticket-details.edit-ticket-form.attachments.saving"
                        )}
                        labelPosition="end"
                        className={`mr-2`}
                        size="smallest"
                      />
                    )}
                    <Button
                      icon={<Icon iconName="Upload" className={"text-sm"} />}
                      content={t(
                        "ticket-details.edit-ticket-form.attachments.button.upload"
                      )}
                      className={
                        uploadDisabled
                          ? CSS["upload-btn-disabled"]
                          : CSS["upload-btn-active"]
                      }
                      text
                      disabled={uploadDisabled}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (dropzoneRef.current) dropzoneRef.current.open();
                      }}
                    />
                  </div>
                )}
              </Flex>
              {isOpen && (
                <>
                  {isLoading && (
                    <>
                      <Skeleton animation="wave">
                        <Flex
                          space="between"
                          style={{ margin: "1.25rem 0", alignItems: "center" }}
                        >
                          <Flex.Item className="extended-toolbar__far-side">
                            <Flex vAlign="center">
                              <Skeleton.Button />
                            </Flex>
                          </Flex.Item>
                          <Flex.Item>
                            <Skeleton.Input />
                          </Flex.Item>
                        </Flex>
                      </Skeleton>
                      <List>{renderSkeleton(10)}</List>
                    </>
                  )}
                  {!isLoading && (
                    <>
                      {isTicketClosed ? (
                        <>
                          <Flex.Item>
                            <Alert
                              danger
                              icon={<ExclamationTriangleIcon />}
                              content={
                                <Text
                                  content={t(
                                    "ticket-details.edit-ticket-form.attachments.closed-ticket-message"
                                  )}
                                />
                              }
                            />
                          </Flex.Item>
                        </>
                      ) : (
                        <>
                          {channelNotSet && (
                            <>
                              {!isEndUserPage && (
                                <Flex.Item>
                                  <Alert
                                    danger
                                    icon={<ExclamationTriangleIcon />}
                                    content={
                                      <>
                                        <Text
                                          content={t(
                                            "ticket-details.edit-ticket-form.attachments.upload-disabled-message"
                                          )}
                                        />
                                        <Link
                                          target="_blank"
                                          href="https://help.tikit.ai/faq/how-do-i-add-tikit-to-a-team/"
                                          className={classNames.alertLink}
                                        >
                                          {t(
                                            "ticket-details.edit-ticket-form.attachments.learn-more"
                                          )}
                                        </Link>
                                      </>
                                    }
                                  />
                                </Flex.Item>
                              )}
                              {isEndUserPage && (
                                <Flex.Item>
                                  <Alert
                                    danger
                                    icon={<ExclamationTriangleIcon />}
                                    content={
                                      <Text
                                        content={t(
                                          "ticket-details.edit-ticket-form.attachments.upload-disabled"
                                        )}
                                      />
                                    }
                                  />
                                </Flex.Item>
                              )}
                            </>
                          )}
                        </>
                      )}
                      <Flex.Item grow>
                        <div
                          style={{
                            maxHeight: "calc(80vh - 320px)",
                            overflow: isDragActive ? "hidden" : "auto",
                            display: "grid",
                          }}
                          className="relative"
                        >
                          {currentState.uploadingState.uploadedFiles.length >
                            0 && (
                            <List
                              // navigable
                              truncateHeader
                              variables={{
                                selectableFocusHoverBackgroundColor:
                                  globalTheme.siteVariables.colorScheme.default
                                    .background,
                              }}
                            >
                              {renderFiles}
                            </List>
                          )}
                          {currentState.uploadingState.uploadedFiles.length ==
                            0 && (
                            <div className={`py-10`}>
                              <EmptyData
                                headerText={t(
                                  "ticket-details.edit-ticket-form.attachments.empty.header-text"
                                )}
                                subheaderText={
                                  !uploadDisabled &&
                                  t(
                                    "ticket-details.edit-ticket-form.attachments.empty.sub-header-text"
                                  )
                                }
                                SVGIcon={<EmptySVG height={70} width={70} />}
                              />
                            </div>
                          )}
                        </div>
                      </Flex.Item>
                    </>
                  )}
                </>
              )}
              <Dialog
                closeOnOutsideClick={false}
                open={openUploadDialog}
                content={
                  <NewAttachmentFlowContent
                    globalTheme={globalTheme}
                    ticketId={ticketId}
                    filesToUpload={uploadFiles}
                    onInteraction={onInteraction}
                    teamsConfig={teamsConfig}
                    isEndUserPage={isEndUserPage}
                    onClose={() => setOpenUploadDialog(false)}
                  />
                }
                header={`${t(
                  "ticket-details.edit-ticket-form.attachments.add-attachment-to-ticket"
                )} #${ticketId}`}
                headerAction={{
                  icon: <CloseIcon />,
                  title: t("common.buttons.close"),
                  onClick: () => setOpenUploadDialog(false),
                }}
                style={{ minWidth: "650px", maxWidth: "700px" }}
              />
            </div>
          </div>
        );
      }}
    </Dropzone>
  );
};
