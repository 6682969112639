import { AppStateValue } from "../../../AppState";
import { getStatusIdByGuid, threedigitized } from "../../../tikit/ticketHelper";
import { STATUS_GUID } from "../../utils/constants";

export interface IRelateTickets {
  SourceTicketId: number,
  RelatedTicketIds: number[],
  RelationshipType: number,
  CurrentUserEmail?:string
}

export interface IRelatedTicketsCard {
  Id: number,
  Title: string,
  RelationshipTypeId: number,
  StatusId: number,
  CreatedById: number,
  CreatedDate: string
}

export interface IRelatedTickets {
  CreatedById: number;
  CreatedDate: string;
  Guid: string;
  Id: number;
  IsDeleted: boolean;
  ModifiedById: number;
  ModifiedDate: string;
  RelatedTicketId: number;
  RelationshipTypeId: number;
  SourceTicketId: number;
}

export enum RelationType {
  MergeTickets = 1,
  RelateTickets = 2,
  ParentChild = 3,
  BlockedBy = 4
}

export interface IRelationType {
  key: number,
  name: string,
  icon: any
}

export const getCommaSeparatedIds = (ticketIds: number[]) => [...ticketIds].sort((a, b) => (a - b)).map(id => threedigitized(id)).join(', ');

export const getTicketStatusClass = (ticketStatusId: number, state: AppStateValue) => {
  switch (ticketStatusId) {
    case getStatusIdByGuid(state.ticketStatus, STATUS_GUID.ACTIVE):
      return "status-icon-active";
    case getStatusIdByGuid(state.ticketStatus, STATUS_GUID.RESOLVED):
      return "status-icon-resolved";
    case getStatusIdByGuid(state.ticketStatus, STATUS_GUID.PENDING):
      return "status-icon-pending";
    case getStatusIdByGuid(state.ticketStatus, STATUS_GUID.CLOSED):
      return "status-icon-closed";
    case getStatusIdByGuid(state.ticketStatus, STATUS_GUID.DEFLECTED):
      return "status-icon-deflected";
    default:
      return "status-icon-others";
  }
}

export const getAllMergedTicketIds = (ticketId: number, relatedTickets: IRelatedTickets[]) => {
  const mergedIds: number[] = [];
  const currentTicketId: number = parseInt(ticketId.toString());
  populateMergedTicketIds(mergedIds, [currentTicketId], relatedTickets);
  return mergedIds.sort((a, b) => a - b);
}

export const populateMergedTicketIds = (finalMergedIds: number[], ticketIds: number[], relatedTickets: IRelatedTickets[]) => {
  let mergedIds: number[] = relatedTickets.filter(t => ticketIds.includes(t.SourceTicketId)).map(t => t.RelatedTicketId);
  if (mergedIds && mergedIds.length > 0) {
    mergedIds.forEach(id => finalMergedIds.push(id));
    populateMergedTicketIds(finalMergedIds, mergedIds, relatedTickets);
  }
}