import React, { useEffect, useState } from 'react';
import { Communication } from '@fluentui/react-teams';
import { Ticket } from '../../shared/interfaces/ticket.interface';
import { Col, Row } from 'react-grid-system';
import { platformService } from '../../shared/services/platform.service';
import { graphService } from '../../shared/services/graph.service';
import * as microsoftTeams from "@microsoft/teams-js";
import {
  Flex,
  Text,
  Loader,
  Card,
  Box,
  Avatar,
  Button,
  Header,
  Dropdown
} from '@fluentui/react-northstar';
import RelativeDate from '../../shared/components/RelativeDate';
import { LoadingScreen } from '../../shared/components/LoadingScreen';
import { CONTACT_ADMIN, SOMETHING_WENT_WRONG, UNAUTHORIZED_ACCESS } from '../../shared/utils/constants';
import { t } from 'i18next';

interface Props {
  ticketId: number;
  event: {};
}

export const MeetingTicket: React.FC<Props> = props => {
  const api = new platformService();
  const graphapi = new graphService();
  let defaultTicket: Ticket = {
    RequesterId: 0,
    Title: '',
    Closed: false,
    DueDate: '',
    ResolutionDate: '',
    Description: '',
    AssigneeId: 0,
    SupportGroupId: 0,
    EscalationId: 0,
    CategoryId: 0,
    ImpactId: 0,
    PriorityId: 0,
    SourceId: 0,
    StatusId: 0,
    UrgencyId: 0,
    Message: '',
    TriageConversationId: 0,
    TriageActivityId: 0,
    Id: 0,
    ModifiedDate: '',
    ModifiedById: 0,
    CreatedDate: '',
    CreatedById: 0,
    Guid: '',
    IsDeleted: false,
    Comments: []
  };
  const [ticket, setTicket] = useState<Ticket>(defaultTicket);
  const [frame, setFrame] = useState('');
  const [card, setCard] = useState('');
  const [refreshCard, setRefreshCard] = useState(true);
  const [isCardLoading, setIsCardLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [ticketList, setTicketList] = useState([]);
  const [selectedId, setSelectedId] = useState(0);
  const [ticketId, setTicketId] = useState(props.ticketId);
  const [tenant, setTenantId] = useState('');
  const [isError, setIsError] = useState(false);
  const [errCode, setErrCode] = useState(0);

  microsoftTeams.app.getContext().then((context: microsoftTeams.app.Context) => {
    setFrame(context.page.frameContext);
    setTenantId(context.user.tenant.id);
  });

  interface LooseObject {
    [key: string]: any;
  }

  const getTicketDropOptions = (tickets: any[]) => {
    var items: any = [];
    tickets.forEach(ticket1 => {
      let item: LooseObject = {};
      item['header'] = `Ticket #${ticket1['Id']}: ${ticket1['Title']}`;
      item['content'] = ticket1['Id'];
      item['selected'] = false;
      items.push(item);
    });
    return items;
  };

  useEffect(() => {
    (async () => {
      initFunction(ticketId);
    })();
  }, []);

  async function initFunction(id: number) {
    setIsPageLoading(true);

    if (id > 0) {
      
      try {
        const results = await Promise.all([api.getTicket(id, `?$expand=Status,Requester`), getCardPayloadAsync(id)]);
        const ticketdetails: Ticket = results[0].data;
        const card = results[1];

        setTicket(prevState => ticketdetails);
        setCard(prevState => card['data']['value']);
      } catch (e) {
        setIsError(true);
        if (e && e['response'] && e['response']['status'])
          setErrCode(e['response']['status']);
      }

      setIsCardLoading(false);
      setIsPageLoading(false);
    } else {
      api.getTicketList().then(result => {
        setTicketList(result.data.value);
        setIsPageLoading(false);
      });
    }
  }

  async function getCardPayloadAsync(id: number) {
    let result = {};
    if (refreshCard) {
      setIsCardLoading(true);
      result = await api.getCardPayload(id, '');
      setRefreshCard(false);
    } else {
      result = {
        data: {
          value: card
        }
      };
    }
    return result;
  }

  const errConfig = {
    fields: {
      title: (errCode === 401 || errCode === 404) ? UNAUTHORIZED_ACCESS : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  };
  
  return (<>
    <div className={`h-screen`}>
      {isPageLoading && (
        <LoadingScreen message={t('ticket.meeting-ticket.getting-data')} />
      )}
      {!isPageLoading && isError && (<Communication {...errConfig} />)}
      {!isPageLoading && ticketId == 0 && (
        <Flex
          vAlign="center"
          style={{ height: frame !== 'sidePanel' ? 'inherit' : 'unset' }}
        >
          <Card styles={{ margin: 'auto', height: 'auto' }}>
            <Row>
              <Col sm={12}>
                <Header as="h3">
                  Set a ticket as topic for this meeting{' '}
                </Header>
                <Dropdown
                  search
                  items={getTicketDropOptions(ticketList)}
                  placeholder={t('ticket.meeting-ticket.search-for_ticket')}
                  noResultsMessage={t('ticket.meeting-ticket.we-couldnt-find-any-matches')}
                  renderItem={(Item, props1) => (
                    <Item
                      {...props1}
                      header={`${props1.header}`}
                      content=""
                    />
                  )}
                  onChange={(e, { value }) => {
                    setSelectedId(value['content']);
                  }}
                  fluid={true}
                />
              </Col>
            </Row>
            <Row className={`mt-4`}>
              <Col sm={12}>
                <Flex gap="gap.small" vAlign="start" hAlign="center">
                  <Button
                    content="Set"
                    primary
                    onClick={e => {
                      graphapi
                        .updateMeetingDetails(selectedId, props.event)
                        .then(() => {
                          setTicketId(selectedId);
                          initFunction(selectedId);
                        });
                    }}
                  />
                </Flex>
              </Col>
            </Row>
          </Card>
        </Flex>
      )}
      {!isPageLoading && !isError && ticketId > 0 && (
        <Flex
          vAlign="center"
          style={{ height: frame !== 'sidePanel' ? 'inherit' : 'unset' }}
        >
          <Card styles={{ margin: 'auto', height: 'auto' }}>
            {ticket['Status'] && (
              <Row>
                <Col sm={12}>
                  <Flex
                    gap="gap.medium"
                    style={{ alignItems: 'center', marginBottom: '0.625rem' }}
                    className={`mb-4`}
                  >
                    <Text
                      size="larger"
                      weight="semibold"
                      content={`[${ticket['Status']['Value']}]: Ticket #${ticket.Id}`}
                    />
                  </Flex>
                </Col>
              </Row>
            )}
            <Row>
              <Col sm={12}>
                {ticket['Requester'] && (
                  <Flex vAlign="center">
                    <Box>
                      <Avatar
                        size="medium"
                        name={ticket['Requester']['Name']}
                        image={`${window.__runtimeConfig.platformurl
                          }teams/photo/${tenant}/${ticket['Requester']['AadObjectId']
                          }?initials=${ticket['Requester']['GivenName']
                            .toString()
                            .substring(0, 1) || ''
                          }${ticket['Requester']['FamilyName']
                            .toString()
                            .substring(0, 1) || ''
                          }`}
                      />
                    </Box>
                    <Box className={`ml-2`}>
                      <Text weight="semibold" size="medium">
                        {ticket['Requester']['Name']}
                      </Text>
                      {typeof ticket.CreatedDate != 'undefined' && (
                        <div>
                          <Text size="small">
                            <RelativeDate date={ticket.CreatedDate} />
                          </Text>
                        </div>
                      )}
                    </Box>
                  </Flex>
                )}
              </Col>
            </Row>
            <Row className={`mt-4`}>
              <Col sm={12}>
                <Flex gap="gap.small" vAlign="start">
                  <Text content={ticket.Title} size="large" />
                </Flex>
              </Col>
            </Row>
            <Row className={`mt-4`}>
              <Col sm={12}>
                <Flex gap="gap.small" vAlign="start" hAlign="center">
                  {isCardLoading && <Loader inline size="smallest" />}
                  {!isCardLoading && (
                    <Text
                      content={t('ticket.meeting-ticket.see-full-details')}
                      color={'brand'}
                      onClick={e => {
                        getCardPayloadAsync(ticketId).then(result => {
                          setRefreshCard(true);
                          setIsCardLoading(false);

                          const cardPayload = {
                            contentType:
                              'application/vnd.microsoft.card.adaptive',
                            content: JSON.parse(result['data']['value'])
                          };

                          let taskInfo: any = {
                            title: t('ticket.meeting-ticket.ticket-information'),
                            height: 600,
                            width: 600,
                            url: null,
                            card: cardPayload,
                            fallbackUrl: null,
                            completionBotId:
                              window.__runtimeConfig.msalClientId
                          };

                          const submitHandler = res => { initFunction(ticketId); };
                          microsoftTeams.app.getContext().then(
                            (context: microsoftTeams.app.Context) => {
                              microsoftTeams.dialog.url.open(taskInfo, submitHandler);
                            }
                          );
                        });
                      }}
                      style={{
                        cursor: 'pointer'
                      }}
                    />
                  )}
                </Flex>
              </Col>
            </Row>
          </Card>
        </Flex>
      )}
    </div>
  </>);
};
