import React from 'react';
import { Flex, Loader, Button, ChevronEndIcon, ChevronDownIcon } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';

interface Props {
  isPropertiesAccordionOpen: boolean;
  setIsPropertiesAccordionOpen: (value: boolean) => void;
  primaryButtonColor: string;
  isSaving: boolean;
  onToolbarInteraction?: (interaction: any) => void;
  onSaveSubmit?: () => void;
  isFormUpdated: boolean;
  isMergedOrDeflected: boolean;
  isUserLicensed: boolean;
  isRequestFieldEmpty: boolean;
}

export const PropertiesAccordion = ({
  isPropertiesAccordionOpen,
  setIsPropertiesAccordionOpen,
  primaryButtonColor,
  isSaving,
  onSaveSubmit,
  isFormUpdated,
  isMergedOrDeflected,
  isUserLicensed,
  isRequestFieldEmpty
}: Props) => {
  const {t} = useTranslation();
  const isButtonDisabled: boolean = isMergedOrDeflected || isSaving || !isFormUpdated || isRequestFieldEmpty || !isUserLicensed;

  return (
    <Flex
      vAlign="center"
      space="between"
      className={`mt-7 pb-4 cursor-pointer`}
      style={{
        borderBottom: isPropertiesAccordionOpen
          ? `1px solid ${primaryButtonColor}`
          : 'none'
      }}
      onClick={() => {
        setIsPropertiesAccordionOpen(!isPropertiesAccordionOpen);
      }}
    >
      <Flex gap="gap.small" vAlign="center">
        {isPropertiesAccordionOpen ? <ChevronEndIcon /> : <ChevronDownIcon />}
        <Flex
          style={{
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px'
          }}
        >
          {t('ticket-details.edit-ticket-form.properties.title')}
        </Flex>
      </Flex>
      {isPropertiesAccordionOpen && (
        <div className={`md:flex items-center`}>
          {isSaving && (
            <Loader
              label={t('ticket-details.edit-ticket-form.properties.saveing-label')}
              labelPosition="end"
              className={`mr-2`}
              size="smallest"
            />
          )}
          <Button
            content={t('ticket-details.edit-ticket-form.properties.save-btn')}
            primary
            disabled={isButtonDisabled}
            onClick={e => {
              e.stopPropagation();
              onSaveSubmit();
            }}
          />
        </div>
      )}
    </Flex>
  );
};
