import React, { useState, useEffect } from 'react';
import Styles from './Modal.module.css';

import {
  Button,
  Checkbox,
  Dialog,
  Flex,
  Input,
  Loader,
  Text,
  TextArea
} from '@fluentui/react-northstar';
import { Communication } from '@fluentui/react-teams';
import { Providers } from '@microsoft/mgt-element/dist/es6/providers/Providers';
import {
  ADD,
  BUTTONS,
  CONTACT_ADMIN,
  DEFAULT,
  ERROR,
  getModalHeading,
  getModalSubheading,
  LOADING,
  NAME,
  REFRESH_SESSION,
  SOMETHING_WENT_WRONG,
  UNAUTHORIZED_ACCESS
} from '../../utils/constants';
import { toTitleCase } from '../../utils/helper';
import { CheckLicense } from '../License/CheckLicense';

interface PopupProps {
  open: boolean;
  isUpdate: boolean;
  inputValue: string;
  descriptionValue?: string;
  showDescription?: boolean;
  checkBoxValue?: boolean;
  showCheckBox?: boolean;
  name: string;
  apiStatus: APIStatus;
  onCancelHandler: () => void;
  onConfirmationHandler?: (value: string, isDefault: boolean, desc?: string) => void;
}

export const LabelElement = (props: any) => {
  return (
    <div className={props.className}>
      <Flex>
        <Text content={props.label} weight={props.weight} style={{ display: 'block' }} />
        {props.required && (
          <span style={{ color: 'red', marginLeft: 2, fontWeight: props.weight}}>*</span>
        )}
      </Flex>
      <div>{props.children}</div>
    </div>
  );
};

export const Modal = ({
  open,
  isUpdate,
  name,
  apiStatus,
  inputValue = '',
  descriptionValue = '',
  showDescription = false,
  checkBoxValue = false,
  showCheckBox = true,
  onCancelHandler,
  onConfirmationHandler
}: PopupProps) => {
  const errConfig = {
    fields: {
      title:
        apiStatus.errCode === 401
          ? UNAUTHORIZED_ACCESS
          : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  };
  const type = isUpdate ? toTitleCase(BUTTONS.EDIT) : toTitleCase(ADD);

  const [input, setInput] = useState(inputValue);
  const [desc, setDesc] = useState(descriptionValue);
  const [checkbox, setCheckbox] = useState(checkBoxValue);

  useEffect(() => {
    setInput(inputValue);
    setDesc(descriptionValue);
    setCheckbox(checkBoxValue);
  }, [inputValue, checkBoxValue, descriptionValue]);

  useEffect(() => {
    if (!open) {
      setInput('');
      setDesc('');
      setCheckbox(false);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      closeOnOutsideClick={false}
      footer={
        <Flex hAlign="end" gap="gap.smaller">
          <Button
            content={toTitleCase(BUTTONS.CANCEL)}
            onClick={onCancelHandler}
            secondary
            disabled={apiStatus.status === LOADING}
          />
          <CheckLicense disable>
            <Button
              content={
                isUpdate ? toTitleCase(BUTTONS.UPDATE) : toTitleCase(BUTTONS.SAVE)
              }
              onClick={() => {
                onConfirmationHandler && onConfirmationHandler(input, checkbox);
              }}
              primary
              disabled={input === '' || apiStatus.status === LOADING}
            />
          </CheckLicense>
        </Flex>
      }
      content={
        <div className={Styles.Container}>
          <h6 className={Styles.SubHeading}>
            {getModalSubheading(isUpdate, name)}
          </h6>
          {apiStatus.status === LOADING ? (
            <Loader label={apiStatus.msg} styles={{ height: '100%' }} />
          ) : apiStatus.status === ERROR ? (
            <>
              <div style={{ height: '100%' }}>
                <Communication {...errConfig} />
                {apiStatus.errCode === 401 && (
                  <Button
                    content={REFRESH_SESSION}
                    primary
                    onClick={e => {
                      Providers.globalProvider.login();
                    }}
                  />
                )}
              </div>
            </>
          ) : (
            <Flex column gap="gap.small">
              <LabelElement label={toTitleCase(NAME)} required />
              <div className="teams-input-med">
                    <CheckLicense disable>
                      <Input
                        clearable
                        value={input}
                        onChange={e => {
                          setInput((e.target as HTMLInputElement).value);
                        }}
                        fluid
                      />
                    </CheckLicense>
              </div>
              {showDescription && (
                <>
                <LabelElement label={"Description"} />
                <div className="teams-input-med">
                  <TextArea
                    variables={{ height: "150px" }}
                    value={desc}
                    onChange={function(_e, p){
                      setDesc(p.value);
                    }}
                    fluid
                  />
                </div>
                </>
              )}
              {showCheckBox && (
                <Checkbox
                  checked={checkbox}
                  label={toTitleCase(DEFAULT)}
                  onChange={() => {
                    setCheckbox(prevState => !prevState);
                  }}
                />
              )}
            </Flex>
          )}
        </div>
      }
      header={getModalHeading(isUpdate, name)}
      style={{ maxWidth: '550px' }}
    />
  );
};
