import React from 'react';
import { threedigitized } from '../../../../../tikit/ticketHelper';
import { useTranslation } from 'react-i18next';
import { RelateTicket } from '../../RelateTicketDialog';
import { getCommaSeparatedIds } from '../../RelateTicketHelper';

export interface Props {
  relatedTicketData: RelateTicket;
}

export const MergeConfirmation = ({ relatedTicketData }: Props) => {
  const {t} = useTranslation();
  const sourceTicketId = threedigitized(relatedTicketData.sourceTicketId);

  return (
    <>
      <div className="dialog-header font-bold">
        {t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.merge-confirmation.header-confirmation')}
      </div>
      <div className="dialog-body pt-3 confirm-parent overflow-auto">
        {t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.merge-confirmation.merging-result')}
        <ul className="confirmation-list">
          <li>
            {t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.merge-confirmation.ticket')}{' '}
            <span className="font-bold">
              {getCommaSeparatedIds(relatedTicketData.relatedTicketIds)}
            </span>{' '}
              {t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.merge-confirmation.will-merged')}{' '}
            <span className="font-bold">{sourceTicketId}</span>
          </li>
          <li>
            {t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.merge-confirmation.all')} <span className="font-bold">{t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.merge-confirmation.comments')}</span> {t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.merge-confirmation.visible-on-ticket')}{' '}
            <span className="font-bold">{sourceTicketId}</span>
          </li>
          <li>
            {t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.merge-confirmation.all')} <span className="font-bold">{t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.merge-confirmation.file-attachments')}</span> {t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.merge-confirmation.available-on-ticket')}{' '}
            <span className="font-bold">{sourceTicketId}</span>
          </li>
          <li><span dangerouslySetInnerHTML={{ __html: t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.merge-confirmation.affected-users', { relatedTickets: getCommaSeparatedIds(relatedTicketData.relatedTicketIds), sourceTicket: sourceTicketId })}} /></li>
          <li><span dangerouslySetInnerHTML={{ __html: t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.merge-confirmation.collaborators', { relatedTickets: getCommaSeparatedIds(relatedTicketData.relatedTicketIds), sourceTicket: sourceTicketId })}} /></li>
          <li>
            {t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.merge-confirmation.ticket')}{' '}
            <span className="font-bold">
              {getCommaSeparatedIds(relatedTicketData.relatedTicketIds)}
            </span>{' '}
            {t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.merge-confirmation.will-closed')}
          </li>
        </ul>
        <div className="pt-4 confirm-child">
          {t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.merge-confirmation.note-you-will')} <span className="font-bold color-red">{t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.merge-confirmation.not')}</span> {t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.merge-confirmation.revert-back')}
        </div>
      </div>
    </>
  );
};
