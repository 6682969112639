import React from 'react';

import { FormGroup } from '../../../shared/components/FormGroup/FormGroup';
import { FormSubLabel } from '../../../shared/components/FormSubLabel/FormSubLabel';
import { Dropdown, DropdownProps, Input, ProviderConsumer } from '@fluentui/react-northstar';
import { SLA, UnitOfTimeDropdownModel } from '../../../shared/utils/constants';
import { useTranslation } from 'react-i18next';

interface Props {
  slaSlidingBreachModel: SlaBreachMeta;
  updateSlaSlidingBreachModel: (approachingBreach: SlaBreachMeta) => void;
}

export const ApproachingBreached = ({
  slaSlidingBreachModel,
  updateSlaSlidingBreachModel
}: Props) => {
  const {t} = useTranslation();
  return (
    <ProviderConsumer render={(globalTheme) => (
      <>
        <FormGroup>
          <div className={`flex items-center text-base mb-2 font-bold`}>
            <h2 >{t('sla.sla-approaching-breach')}</h2>
            <span className="text-red-500 ml-1">*</span>
          </div>
          <FormSubLabel content={`${SLA.SLA_STATE}:`} />
          <div className="flex items-center gap-3">
            <Input
              inverted
              fluid
              placeholder="0"
              disabled={!slaSlidingBreachModel.timeUnit}
              type="number"
              styles={{ flex: '0 1 65%' }}
              value={slaSlidingBreachModel.timeDigitPart}
              onChange={(_e, p) => {
                const _value = +p.value;
                if (_value >= 0)
                  updateSlaSlidingBreachModel({
                    ...slaSlidingBreachModel,
                    timeDigitPart: +p.value
                  });
              }}
            />
            <Dropdown
              styles={{ flex: '1' }}
              inverted
              fluid
              placeholder={SLA.UNIT_OF_TIME}
              items={UnitOfTimeDropdownModel}
              value={t(`sla.time.${slaSlidingBreachModel.timeUnit.toLowerCase()}`)}
              style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
              onChange={(_e, p: DropdownProps) => {
                const _data = p.value as DropdownDataModel;
                updateSlaSlidingBreachModel({
                  ...slaSlidingBreachModel,
                  timeUnit: _data.value
                });
              }}
            />
          </div>
        </FormGroup>
      </>
    )} />
  );
};
