import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Text } from '@fluentui/react-northstar';
import { platformService } from '../shared/services/platform.service';
import { Communication } from '@fluentui/react-teams';
import { Providers } from '@microsoft/mgt-element/dist/es6/providers/Providers';
import { Breadcrumbs } from '../shared/components/BreadcrumbNavigation';
import { KBListWrapper } from '../kb/KBListWrapper';
import {
  CONTACT_ADMIN,
  DEFAULT_API_STATUS,
  DONE,
  ERROR,
  GETTING_ALL_USERS,
  LOADING,
  SETTINGS_PAGES,
  SOMETHING_WENT_WRONG,
  SYNC_USERS,
  UNAUTHORIZED_ACCESS,
  USERS,
  USERS_HEADERS,
  ADMINISTRATORS,
  ANALYSTS,
  AGENTS
} from '../shared/utils/constants';
import { toTitleCase } from '../shared/utils/helper';
import { CheckLogin } from '../shared/components/CheckLogin';
import { Helmet } from 'react-helmet';
import { LoadingScreen } from '../shared/components/LoadingScreen';
import { NameUserAvatar } from './NameUserAvatar';
import useCurrentPage from '../kb/useCurrentPage';
import { useTranslation } from 'react-i18next';

import { appState  } from '../AppState';

export const UserTable = () => {
  
  const appContextState = appState();
  const {t} = useTranslation();
  const api = new platformService();
  const [data, setData] = useState<RowsData[]>([]);
  const [apiStatus, setApiStatus] = useState<APIStatus>(
    DEFAULT_API_STATUS as APIStatus
  );
  const history = useHistory();
  const location = useLocation();

  const {currentPage, setCurrentPage} = useCurrentPage()

  const fetchData = useCallback(async () => {
    setApiStatus({ ...apiStatus, msg: GETTING_ALL_USERS });

    try {

      const result = await Promise.all([
        api.getUsers("?$orderby=FullName"),
        api.getUserLicenses("?$expand=PlatformUser&$orderby=PlatformUser/UserName"),
        api.getTenantLicenses()
      ]);

      const platformUsers = result[0].data.value;
      const licenses = result[1].data.value;
      const products = result[2].data.value;

      const rowData: RowsData[] = platformUsers.map((user: any) => {
        const userLicenses = licenses.filter(x => x.PlatformUserId === user.Id);
        let productNames = [];
        products.forEach(product => {
          if (userLicenses.some(x => x.ProductLicenseId === product.ProductLicenseId)) {
            productNames.push(product.ProductName);
          }
        });

        return {
          id: user['Id'],
          firstName: user['GivenName'],
          lastName: user['FamilyName'],
          [t('users.name')]: (<NameUserAvatar user={user} />),
          [t('users.email')]: user['Email'],
          [t('licenses')]: productNames.join()
        }
      });

      setData(rowData);

    } catch (err: any) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: err.response.status
      });
    } finally {
      setApiStatus({ ...apiStatus, status: DONE });
    }
  }, []);

  const loadPage = () => {
    const hasAccess = appContextState.userRoles.roles.includes(ADMINISTRATORS) || appContextState.userRoles.roles.includes(ANALYSTS) || appContextState.userRoles.roles.includes(AGENTS);
    if(!hasAccess) { 
      setApiStatus({ ...apiStatus, status: ERROR, errCode: 401 }) }
    else{
        fetchData();
    }
  }

  const getNavigation = (): Breadcrumbs => {
    const items: Breadcrumbs = { 
      breadcrumbs: []
    };

    if (location.pathname.indexOf('/settings/') >= 0)
      items.breadcrumbs.push({ title: t('users.breadcrumb-settings'), link: '/settings' })

    items.breadcrumbs.push({ title: t('users.breadcrumb-user'), link: '' });
    return items;
  };

  const syncAllUsers = async () => {
    setApiStatus({ ...apiStatus, status: LOADING, msg: SYNC_USERS });

    try {
      await api.syncAllUsers();
      await fetchData();
    } catch (err: any) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: err.response.status
      });
    } finally {
      setApiStatus({ ...apiStatus, status: DONE });
    }
  };

  const errConfig = {
    fields: {
      title:
        apiStatus.errCode === 401
          ? UNAUTHORIZED_ACCESS
          : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  };

  return (
    <CheckLogin onSignedIn={loadPage}>
      <Helmet>
        <title>{toTitleCase(USERS)} - Tikit</title>
      </Helmet>
      {apiStatus.status === LOADING ? (
        <LoadingScreen message={apiStatus.msg} />
      ) : apiStatus.status === ERROR ? (
        <>
          <Communication {...errConfig} />
          {apiStatus.errCode === 401 && (
            <Button
              content={t('common.refresh-session')}
              primary
              onClick={e => {
                Providers.globalProvider.login();
              }}
            />
          )}
        </>
      ) : (
        <KBListWrapper
          title={toTitleCase(USERS)}
          routeName={SETTINGS_PAGES.USERS}
          headers={[t('users.name'), t('users.email'), t('licenses')]}
          rows={data}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          breadcrumbs={getNavigation()}
          syncAllUsers={syncAllUsers}
          onRowClickHandler={(id: number) => history.push((location.pathname.indexOf('/settings/') != -1) ? `/settings/${SETTINGS_PAGES.USERS}/${id}` : `/${SETTINGS_PAGES.USERS}/${id}`)}
          ifEmpty={{
            header: t('common.no-users-in-the-system'),
            subHeader: ''
          }}
        />
      )}
    </CheckLogin>
  );
};
