import React, { useState } from 'react';
import { Text, Flex, ThemePrepared, ProviderConsumer, TrashCanIcon, Dialog, SvgIconSizeValue, TeamCreateIcon } from '@fluentui/react-northstar';
import { LifecycleTask, KeyValuePair } from '../utils/typings';
import { PeopleIcon, GroupIcon } from '@fluentui/react-icons-mdl2';
import { toTitleCase } from '../../shared/utils/helper';
import { TICKET_DETAIL, BUTTONS, DELETE, LIFECYCLE, LICENSE_FEATURE } from '../../shared/utils/constants';
import { ThemeColorScheme } from '../../shared/common/TeamsTheme';
import { themeNames } from '@fluentui/react-teams/lib/esm';
import { PhaseTaskDialog } from './PhaseTaskDialog';
import { PlatformUser } from '../../shared/interfaces/platformuser.interface';
import { transformModalContent } from '../../automation/AutomationTable';
import TaskStyle from '../styles/PhaseTask.module.css';
import LifecycleTaskIcon from "../../../svg/lifecycle-task-filled-icon.svg";
import { threedigitized } from '../../tikit/ticketHelper';
import { useTranslation } from 'react-i18next';
import { platformService } from '../../shared/services/platform.service';
import { CheckFeature } from '../../shared/components/CheckFeature';
import { UsersTooltip } from '../../shared/components/TicketDetailInfo/UsersTooltip';
import CollaboratorIcon from '../../../svg/collaboratorIcon.svg';
import { appState } from '../../AppState';

const iconStyle = {
  size: 'small' as SvgIconSizeValue,
  style: { color: '#8f90a6' }
};

const ellipsis = {
  width: '80px',
  overflow:'hidden',
  whiteSpace:'nowrap',
  textOverflow: 'ellipsis',
  marginLeft: '8px'
}  as React.CSSProperties;

interface Props {
  data: LifecycleTask;
  dataIndex: number;
  updateData: (editData: KeyValuePair[], index: number) => void;
  globalTheme: ThemePrepared<any>;
  userList: PlatformUser[];
}

export const PhaseTask = ({
  data,
  data: { Id, Name, Assignee, Team, SupportGroup, TaskCollaborators },
  dataIndex,
  updateData,
  userList
}: Props) => {
 
  const currentState = appState();
  const { t } = useTranslation();
  
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openWarningDeleteDialog, setOpenWarningDeleteDialog] = useState(false);
  const [transitions, setTransitions] = useState([]);
  const api = new platformService();

  const updateTask = (task: LifecycleTask) => {
    let kv = [];
    Object.keys(task).forEach(key => {
      kv.push({
        key: key,
        value: task[key]
      } as KeyValuePair)
    });    
    updateData(kv, dataIndex);
  }

  const removeItem = () => {
    const pair: KeyValuePair = {
      key: "RemoveItem",
      value: dataIndex
    } 
    updateData([pair], dataIndex);
  }

  const peformDeleteCheck = async()=>{
    const transitionList = await api.getTransitionsByCriteria(data.Id.toString());
    setTransitions(transitionList);
    (transitionList.length > 0)?setOpenWarningDeleteDialog(true):setOpenDeleteDialog(true);
  }

  const removeItemMessageText = t('lifecycle.delete-task-message', { taskName: Name })
  const removeTaskUsedMessageText = t('lifecycle.delete-task-used-message', { taskName: Name })

  return (
    <ProviderConsumer
      render={globalTheme => (
        <>
        <div
          onClick={() => {setOpenDialog(true);}}
          className={`min-h-32 cursor-pointer mx-2 ${TaskStyle['phaseTaskCard']}`}
          style={{
            color: 'var(--mgt-theme-default-foreground)',
            ...ThemeColorScheme(globalTheme.siteVariables)
          }}
        >
        <div className="mb-2 text-xs mt-1" style={{marginTop: '3px'}}>
            <Flex
              column
              gap="gap.small"
              style={{
                background: `${globalTheme.siteVariables.theme === themeNames.Dark ? 'rgb(51, 51, 51)' : '#fafafa'}`,
                padding: '0.75rem',
                boxShadow: 'var(--mgt-theme-border-color) 0px 1px 2px',
                borderRadius: '3px'
              }}
            >
            <div className="flex space-x-1 pb-2 ticketTaskTitle" >
              <div>
                <LifecycleTaskIcon
                    width={14}
                    height={14}
                    className="inline-block"
                  />
              </div>
              <div>
                <div>
                <Text weight="semibold" className="break-normal" size="small">
                  {Name}
                </Text>
              </div>
            </div>
          </div>
          <div className={`mt-2 flex justify-between`}>
            <Flex vAlign={`center`} gap="gap.small">
               <CheckFeature featureName={LICENSE_FEATURE.MultiDepartment}>
                  <Flex.Item>
                    <Flex vAlign='center'>
                      <Flex.Item>
                        <Flex vAlign='center'>
                          <TeamCreateIcon {...iconStyle} />
                        </Flex>
                      </Flex.Item>
                      <Flex.Item>
                        { (Team?.Name) && <span style={ellipsis} >{Team?.Name}</span>}
                      </Flex.Item>
                    </Flex>
                  </Flex.Item>
              </CheckFeature>
              <Flex.Item>
                <Flex vAlign='center'>
                  <Flex.Item>
                    <Flex vAlign='center'>
                      <GroupIcon {...iconStyle} />
                    </Flex>
                  </Flex.Item>
                  <Flex.Item>
                  { (SupportGroup?.Name) && <span style={ellipsis} >{SupportGroup?.Name}</span>}
                  </Flex.Item>
                </Flex>
              </Flex.Item>
              <Flex.Item>
                <Flex vAlign='center'>
                  <Flex.Item>
                    <Flex vAlign='center'>
                      <PeopleIcon {...iconStyle} />
                    </Flex>
                  </Flex.Item>
                  <Flex.Item>
                    { (Assignee?.FullName) && <span style={ellipsis} >{Assignee?.FullName}</span>}
                  </Flex.Item>
                </Flex>
              </Flex.Item>
              <Flex.Item>
                  <CheckFeature featureName={LICENSE_FEATURE.Collaborators}>
                    <Flex vAlign="center" gap="gap.smaller">
                      <CollaboratorIcon width={12} height={12} style={{ margin: '0 auto', color: globalTheme.siteVariables.colorScheme.default.foreground }} />
                      {TaskCollaborators?.length>0 && <UsersTooltip ticketCollaborator={TaskCollaborators?.map(c => currentState.platformusers.find(p => p.Id == c.Id))} title={t('ticket.ticket-list.collaborators')} />}
                    </Flex>
                  </CheckFeature>
              </Flex.Item>
              <Flex.Item>
                <Flex vAlign='center' className={`${TaskStyle['phaseTaskCardDelete']}`}
                    onClick={async e => {
                      e.stopPropagation();
                      await peformDeleteCheck();
                    }}
                    title={`Remove Task`}
                  >
                    <Flex.Item>
                      <TrashCanIcon size={`small`} />
                    </Flex.Item>
                    <Flex.Item>
                      <span className='ml-1'>
                        {LIFECYCLE.DELETE_TASK}
                      </span>
                    </Flex.Item>
                  </Flex>
              </Flex.Item>
            </Flex>
          </div>
        </Flex>
        </div>
      </div>
      <PhaseTaskDialog
          open={openDialog}
          onCancel={() => setOpenDialog(false)}
          saveTask={updateTask}
          initData={data}
          userList={userList}
      />
      <Dialog
        open={openDeleteDialog}
        onCancel={() => setOpenDeleteDialog(false)}
        cancelButton={toTitleCase(BUTTONS.CANCEL)}
        confirmButton={toTitleCase(DELETE)}
        onConfirm={() => {
          removeItem();
          setOpenDeleteDialog(false);
        }}
        content={removeItemMessageText}
        header={LIFECYCLE.DELETE_TASK}
        style={{ maxWidth: '500px' }}
      />   
      <Dialog
            open={openWarningDeleteDialog}
            onConfirm={() => setOpenWarningDeleteDialog(false)}
            confirmButton={toTitleCase(BUTTONS.OK)}
            content={<>
              <div>{removeTaskUsedMessageText}</div>
              <br/>
              {transitions && transitions.map(transition =>
                <p>{transition.Title}</p>
              )}
            </>}
            header={LIFECYCLE.DELETE_APPROVAL}
            style={{ maxWidth: '500px' }}
          /> 
      </>
    )}
    />
  );

};
