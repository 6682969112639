import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Button, Text, Dialog } from '@fluentui/react-northstar';
import { platformService } from '../shared/services/platform.service';
import { Communication } from '@fluentui/react-teams';
import { SignedInState } from '../shared/services/signInState.service';
import { Providers } from '@microsoft/mgt-element/dist/es6/providers/Providers';
import { Breadcrumbs } from '../shared/components/BreadcrumbNavigation';
import {
  ARCHIVE_CONFIRMATION_SUBTITLE,
  ARCHIVE_MODAL_HEADER,
  BUTTONS,
  CONTACT_ADMIN,
  DEFAULT_API_STATUS,
  DONE,
  ERROR,
  LOADING,
  PRIORITY,
  PRODUCT_LICENSE,
  REFRESH_SESSION,
  SETTINGS,
  SOMETHING_WENT_WRONG,
  TREE_LISTING_CONSTANTS,
  UNARCHIVE_CONFIRMATION_SUBTITLE,
  UNARCHIVE_MODAL_HEADER,
  UNAUTHORIZED_ACCESS
} from '../shared/utils/constants';
import { toTitleCase } from '../shared/utils/helper';
import {
  CTreeItem,
  TreeData,
  TreeWrapper
} from '../shared/components/tree/Tree';
import { useHistory } from 'react-router-dom';
import { ItemId, TreeDestinationPosition } from '@atlaskit/tree';
import { PickersPageWrapper } from '../kb/PickersPageWrapper';
import { TreeListingHeader } from '../shared/components/tree/TreeListingHeader/TreeListingHeader';
import { Priority } from './Priority';
import { CheckLogin } from '../shared/components/CheckLogin';
import { Helmet } from 'react-helmet';
import { LoadingScreen } from '../shared/components/LoadingScreen';
import { MobileSettingsView } from '../automation/AutomationTable';
import { toast } from 'react-toastify';
import { getCachedFeature } from '../shared/cache/FeatureCache';
import { useTranslation } from 'react-i18next';

export const PriorityList = () => {
  const {t} = useTranslation();
  const api = new platformService();
  const history = useHistory();
  const [dialog, setDialog] = useState(false);
  const [newOrEditDialog, setNewOrEditDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [modalHeader, setModalHeader] = useState('');
  const [archiveItem, setArchiveItem] =
    useState<MaybeNull<PriorityDetail>>(null);
  const [priorityList, setPriorityList] = useState<TreeData>({
    rootId: '0',
    items: {
      '0': {
        id: '0',
        children: [],
        hasChildren: true,
        container: true,
        isExpanded: true,
        isChildrenLoading: false,
        data: {
          title: 'root'
        }
      }
    }
  });
  
  const [isLicensedUser, setIsLicensedUser] = useState(false);
  const [apiStatus, setAPIStatus] = useState<APIStatus>(
    DEFAULT_API_STATUS as APIStatus
  );
  const [isAddNewEnabled, setAddNewEnabled] = useState(false);
  const [isDragEnabled, setDragEnabled] = useState(false);
  const [isEditEnabled, setEditEnabled] = useState(false);

  const updateAPIStatus = (newState: Partial<APIStatus>) => {
    setAPIStatus({ ...apiStatus, ...newState });
  };

  const sortTreeItems = (items: CTreeItem[]) =>
    Object.values(items).sort((a, b) => a.data.Position - b.data.Position);

  const checkSortedTreeExistItems = (
    items: CTreeItem[],
    rootChild: string[]
  ) => {
    const sortedArray: string[] = [];
    items.forEach(child => {
      if (rootChild.includes(child.id as string)) {
        sortedArray.push(child.id as string);
      }
    });
    return sortedArray;
  };

  const fetchPriorityList = useCallback(async () => {
    updateAPIStatus({ msg: PRIORITY.GETTING_ALL_PRIORITIES });
    setArchiveItem(null);

    try {
      const permissionToEdit = JSON.parse(
        sessionStorage.getItem('my_application_roles')
      );
      const permissionToEditFiltered = permissionToEdit.filter(
        roles =>
          roles['TypeName'] == 'ServiceDesk.Core.Models.Pickers.Ticket.Priority'
      );

      permissionToEditFiltered.find(p => {
        if (p.Permissions.includes('POST') || p.Permissions.includes('PUT')) {
          setAddNewEnabled(true);
          setDragEnabled(true);
          setEditEnabled(true);
          return true;
        }
      });

      const {
        data: { value }
      } = await api.getPriority(`?v=${new Date().getTime()}`);

      const treeItems: CTreeItem[] = getTreeItems(value);
      const sortedArr = sortTreeItems(treeItems);
      const rootChilds = getRootChilds(treeItems);
      const sortedRootChilds = checkSortedTreeExistItems(sortedArr, rootChilds);

      setPriorityList({
        ...priorityList,
        // @ts-ignore
        items: {
          ...priorityList.items,
          '0': {
            id: '0',
            children: [...sortedRootChilds],
            hasChildren: true,
            container: true,
            isExpanded: true,
            isChildrenLoading: false,
            data: {
              title: 'root'
            }
          },
          ...treeItems
        }
      });
      setIsLicensedUser(await getCachedFeature(PRODUCT_LICENSE.AnalystAccess));
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    } finally {
      updateAPIStatus({ status: DONE });
    }
  }, []);

  const loadPage = () => {
    fetchPriorityList();
  };

  const navs: Breadcrumbs = {
    breadcrumbs: [
      {
        title: toTitleCase(SETTINGS),
        link: '/settings'
      },
      {
        title: toTitleCase(PRIORITY.PRIORITY),
        link: ''
      }
    ]
  };

  const getTreeItems = useCallback(array => {
    const items = array.reduce((acc, item) => {
      const child = getChildsOfTree(array, item.Id);
      const sortedChild = child
        .sort((a, b) => a.position - b.position)
        .map(item => item.id.toString());
      return {
        ...acc,
        [item.Id.toString()]: {
          id: item.Id.toString(),
          children: [...sortedChild],
          hasChildren: child.length > 0,
          container: true,
          isExpanded: child.length > 0,
          isChildrenLoading: false,
          data: {
            title: item.Value,
            ...item
          }
        }
      };
    }, {} as CTreeItem);
    return items;
  }, []);

  const getChildsOfTree: (
    items: CTreeItem[],
    id: number | string
  ) => { id: number; position: number }[] = useCallback(
    (items: CTreeItem[], id: number | string) => {
      const child = [];
      for (let i = 0; i < items.length; i++) {
        const element: CTreeItem['data'] = items[i + 1];
        if (element?.ParentId === id) {
          child.push({ id: element.Id.toString(), position: element.Position });
        }
      }
      return child;
    },
    []
  );

  const getRootChilds: (items: CTreeItem[]) => string[] = useCallback(
    (items: CTreeItem[]) => {
      const getChildOfRoot: string[] = [];

      Object.keys(items).forEach((key: string) => {
        getChildOfRoot.push(...items[key].children);
      });

      return Object.keys(items).filter(
        (val: string) => !getChildOfRoot.includes(val)
      );
    },
    []
  );

  const onDragHandler = async (
    source: ItemId,
    destination: TreeDestinationPosition,
    rootChildren: ItemId[]
  ) => {
    let data: UpdateOnDrag = {
      Id: '',
      ParentId: '',
      Position: 0
    };
    const positionOfItem =
      priorityList.items[destination.parentId.toString()].data.Position;
    let updatedRootChildren: UpdatePickerPositionPayload[] = [];
    updatedRootChildren = rootChildren.map((child, index) => ({
      '@odata.type': '#ServiceDesk.Core.Models.Pickers.Ticket.Priority',
      Id: child,
      Position: index + 1
    }));

    if (destination.parentId === '0') {
      data = {
        ...data,
        Id: source,
        ParentId: null
      };
    } else {
      data = {
        ...data,
        Id: source,
        ParentId: destination?.parentId,
        Position: positionOfItem
      };
    }
    try {
      await api.updatePriority(data);
      await api.updatePickerPositions('Priority', updatedRootChildren);
      toast.success(t('priority.changes-are-saved'));
    } catch (error: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: error.response.status
      });
    }
  };

  const onConfirmArchiveHandler = async () => {
    try {
      setDialog(false);
      updateAPIStatus({ msg: PRIORITY.UPDATING_PRIORITY, status: LOADING });
      await api.updatePriority({
        IsArchived: !archiveItem.IsArchived,
        IsDefault: false,
        Id: archiveItem.Id.toString()
      });
      fetchPriorityList();
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    }
  };

  const errConfig = {
    fields: {
      title:
        apiStatus.errCode === 401
          ? UNAUTHORIZED_ACCESS
          : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  };

  return (
    <CheckLogin onSignedIn={loadPage}>
      <Helmet>
        <title>{t('priority.title')}</title>
      </Helmet>
      <div className="hidden md:block">
        {apiStatus.status === LOADING ? (
          <LoadingScreen message={apiStatus.msg} />
        ) : apiStatus.status === ERROR ? (
          <>
            <Communication {...errConfig} />
            {apiStatus.errCode === 401 && (
              <Button
                content={REFRESH_SESSION}
                primary
                onClick={e => {
                  Providers.globalProvider.login();
                }}
              />
            )}
          </>
        ) : (
          <>
            <PickersPageWrapper
              title={toTitleCase(PRIORITY.PRIORITY)}
              breadcrumbs={navs}
              items={Object.entries(priorityList.items).length}
              ifEmpty={{
                header: t('priority.there-are-no-priorities'),
                subHeader:
                t('get-started-by-clicking')
              }}
              isAddNewEnabled={isAddNewEnabled}
              onAddClickHandler={() => {
                setNewOrEditDialog(true);
                setArchiveItem(null);
              }}
            >
              <div>
                <TreeListingHeader content={toTitleCase(PRIORITY.PRIORITY)} />
                <TreeWrapper
                  depth={Object.entries(priorityList.items).length}
                  data={priorityList}
                  isEditEnabled={isEditEnabled}
                  onEditHandler={item => {
                    setArchiveItem(item.data);
                    setNewOrEditDialog(true);
                  }}
                  onArchiveHandler={item => {
                    setArchiveItem(item.data);
                    if (item.data.IsArchived) {
                      setModalHeader(
                        `${UNARCHIVE_MODAL_HEADER} ‘${item.data.title}’?`
                      );
                      setDialogContent(
                        `${ARCHIVE_CONFIRMATION_SUBTITLE} ${item.data.title} ${PRIORITY.PRIORITY}?`
                      );
                    } else {
                      setModalHeader(
                        `${ARCHIVE_MODAL_HEADER} ‘${item.data.title}’?`
                      );
                      setDialogContent(
                        `${ARCHIVE_CONFIRMATION_SUBTITLE} ${item.data.title} ${PRIORITY.PRIORITY}?`
                      );
                    }
                    setDialog(true);
                  }}
                  onDragEndHandler={onDragHandler}
                  isDragEnabled={isDragEnabled}
                  isLicensedUser = { isLicensedUser }
                />
              </div>
            </PickersPageWrapper>
            <Dialog
              open={dialog}
              onCancel={() => setDialog(false)}
              cancelButton={toTitleCase(BUTTONS.CANCEL)}
              confirmButton={
                archiveItem?.IsArchived
                  ? TREE_LISTING_CONSTANTS.unArchive
                  : TREE_LISTING_CONSTANTS.archive
              }
              onConfirm={onConfirmArchiveHandler}
              content={dialogContent}
              header={modalHeader}
              style={{ maxWidth: '500px' }}
            />
            <Priority
              id={archiveItem?.Id.toString()}
              open={newOrEditDialog}
              onCancel={() => setNewOrEditDialog(false)}
              onSuccessFullCall={fetchPriorityList}
            />
          </>
        )}
      </div>
      <MobileSettingsView />
    </CheckLogin>
  );
};
