import {
  AddIcon, Alert, Button, Dialog, EditIcon, gridCellWithFocusableElementBehavior, gridNestedBehavior, Input, MenuButton, MoreIcon, Pill,
  PillGroup, ProviderConsumer, Table, Text,
  TrashCanIcon
} from '@fluentui/react-northstar';
import update from 'immutability-helper';
import pick from 'lodash/pick';
import React, { useEffect, useState } from 'react';
import AppCSS from '../App.module.css';
import { AppKBStateContext, useSetKBState } from '../AppKBState';
import { SynonymData } from '../AppState';
import KBCSS from '../kb.module.css';
import { getCachedFeature } from '../shared/cache/FeatureCache';
import { ObjectSize } from '../shared/common/Helpers';
import { EmptyData } from '../shared/components/EmptyData';
import { kbDataService } from '../shared/services/kb.service';
import { PRODUCT_LICENSE } from '../shared/utils/constants';
import EmptySVG from './../../svg/empty.svg';
import { useTranslation } from 'react-i18next';
interface SynonymDialogProps {
  alterations: string[];
  id: number;
  open?: boolean;
  onInteraction?: (interaction: any) => void;
  isUserLicensed: boolean;
}

export const SynonymTable = (props) => {
  const [isUserLicensed, setIsUserLicensed] = useState<boolean>(false);
  const tableProps = pick(props, [
    'title',
    'columns',
    'rows',
    'selectable',
    'truncate',
    'onInteraction'
  ]);

  useEffect(() => {
    (async () => setIsUserLicensed((await getCachedFeature(PRODUCT_LICENSE.AnalystAccess))))();
  }, []);

  return (
    <AppKBStateContext.Consumer>
      {state => {
        return (
          <ProviderConsumer
            render={globalTheme => (
              <>
                {ObjectSize(tableProps.rows) === 0 && (
                  <>
                    <div className={`flex justify-start`}>
                      <div className={`ml-4 mb-2`}>
                        <SynonymDialog id={-1} alterations={[]} isUserLicensed={isUserLicensed} />
                      </div>
                    </div>
                    <EmptyData headerText={props.ifEmpty.header} subheaderText={props.ifEmpty.subHeader} SVGIcon={<EmptySVG width={200} height={200} />} style={{height: "calc(100% - 20em)"}} />
                  </>
                )}
                {ObjectSize(tableProps.rows) > 0 && (
                  <>
                    <div className={`flex justify-start`}>
                      <div className={`ml-4 mb-2`}>
                        <SynonymDialog id={-1} alterations={[]} isUserLicensed={isUserLicensed} />
                      </div>
                    </div>
                    <div className={`mb-5`} style={{height: 'calc(100vh - 212px)', overflow:'auto'}}>
                      <SynonymList rows={state.synonyms} isUserLicensed={isUserLicensed} />
                    </div>
                  </>)}
              </>
            )} />
        );
      }}
    </AppKBStateContext.Consumer>
  );
}

const synonymFormatter = (alterations: string[], isUserLicensed: boolean) => alterations.map(s => (<Pill appearance="outline" style={{ minWidth: '1px' }}>{s}</Pill>));

const SynonymOptions = (props: {
  idx: number;
  onInteraction?: (interaction: any) => void;
  isUserLicensed: boolean;
}) => {
  const {t} = useTranslation();
  const setState = useSetKBState();
  const [open, setOpen] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  return (
    <ProviderConsumer render={globalTheme => (
    <AppKBStateContext.Consumer>
      {appstate => {
        return (<>
          <Dialog
            className={AppCSS.confirmDialog}
            cancelButton="Cancel"
            confirmButton="Yes"
            open={openDialog}
            onConfirm={async () => {
              setState(state => {
                return {
                  ...state,
                  loadingKB: {
                    message: t('knowledge.loading-knowledge-message'),
                    isLoading: true
                  }
                };
              });

              // Update Synonyms here
              try {
                const newData = update(appstate.synonyms, { $splice: [[props.idx, 1]] });
                const job = await kbDataService.saveSynonyms(newData);
                if (job.status === "succeeded") {
                  setState(state => { return { ...state, synonyms: newData, tab: 1 }; });
                }
              } catch (e) {
                console.error(e);
              }

              setState(state => {
                return {
                  ...state,
                  loadingKB: { message: '', isLoading: false }
                };
              });
            }}
            onCancel={() => {
              setOpenDialog(false);
            }}
            header={t('knowledge.are-you-sure-you-want-to-delete-this-synonym-group')}
            content={''}
          />
          <MenuButton
            open={open}
            onOpenChange={(e, { open }) => {
              props.isUserLicensed && setOpen(open);
            }}
            onMenuItemClick={(e, p) => {
              if (props.isUserLicensed) {
                if (p.index === 0) {
                  // delete
                  setOpenDialog(true);
                } else if (p.index === 1) {
                  props.onInteraction({ event: 'openDialog', id: props.idx });
                }
              }
            }}
            trigger={
                <Button
                  style={props.isUserLicensed ? {} : {color: globalTheme.siteVariables.colorScheme.default.foregroundDisabled}}
                  disabled={!props.isUserLicensed}
                  tabIndex={-1}
                  icon={<MoreIcon />}
                  circular
                  text
                  iconOnly
                  title={t('knowledge.more-ooptions')}
                />
            }
            menu={[
              <span>
                <TrashCanIcon outline /> &nbsp;Delete
              </span>,
              <span>
                <EditIcon outline /> &nbsp;Edit
              </span>
            ]}
          />
        </>);
      }}
    </AppKBStateContext.Consumer>
    )}/>
  );
};
const SynonymList = (props: {
  rows: SynonymData[],
  isUserLicensed: boolean
}) => {
  const setState = useSetKBState();
  const {t} = useTranslation();
  const [rowState, setRowState] = useState<{ id: number; isOpen: boolean }[]>([]);
  const header = {
    key: 'header',
    items: [
      { content: t('knowledge.synonyms'), key: 'synonyms' },
      { key: 'more options', 'aria-label': 'options', className: KBCSS.kbTableOption }
    ]
  };

  const optionInteraction = (p: { event: string; id: number }) => {
    if (p.event === 'openDialog') {
      toggleEditDialog(p.id, true);
    }
  };

  const moreOptionCell = (idx: number) => {
    return {
      content: <SynonymOptions idx={idx} onInteraction={optionInteraction} isUserLicensed={props.isUserLicensed} />,
      truncateContent: true,
      accessibility: gridCellWithFocusableElementBehavior
    };
  };

  const toggleEditDialog = (idx: number, state: boolean) => {
    let updateValues = update(rowState[idx], { isOpen: { $set: state } });
    let newRowState = update(rowState, { $splice: [[idx, 1, updateValues]] });
    setRowState(newRowState);
  };

  const rowFormatter = (rows: SynonymData[], theme?: 'dark' | 'light') => {
    let tablerows = [];
    rows.forEach((r, idx) => {
      let findRowState = rowState.find(rs => rs.id === idx);
      if (!findRowState) {
        let newRowState = rowState;
        newRowState.push({ id: idx, isOpen: false });
        setRowState(newRowState);
      }
      tablerows.push({
        key: idx,
        items: [
          {
            key: `${idx}-1`,
            content: (
              <>
                <div onClick={() => {if (props.isUserLicensed) toggleEditDialog(idx, true); } } 
                  className={`${props.isUserLicensed ? 'cursor-pointer' : 'cursor-default'}`}>
                  {synonymFormatter(r.alterations, props.isUserLicensed)}
                </div>
                <SynonymDialog
                  alterations={r.alterations}
                  id={idx}
                  onInteraction={e => {
                    if (e.event === 'close')
                      toggleEditDialog(idx, false);
                  }}
                  open={rowState.find(rs => rs.id === idx).isOpen}
                  isUserLicensed={props.isUserLicensed}
                />
              </>
            )
          },
          {
            key: `${idx}-2`,
            ...moreOptionCell(idx),
            className: KBCSS.kbTableOption
          }
        ]
      });
    });
    return tablerows;
  };

  return (
    <ProviderConsumer
      render={globalTheme => (
        <Table
          variables={{ cellContentOverflow: 'none' }}
          header={header}
          rows={rowFormatter(props.rows, globalTheme.siteVariables.colorScheme.default.background === '#fff' ? 'light' : 'dark')}
          aria-label="Nested navigation"
          accessibility={gridNestedBehavior}
          className={KBCSS.kbTable} />
      )}
    />
  );
};
const SynonymDialog = (props: SynonymDialogProps) => {
  const {t} = useTranslation();
  const [alterations, setAlterations] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(-1);
  const [inputVal, setInputVal] = useState("");
  const setState = useSetKBState();

  const [hasError, setHasError] = useState(false);
  const [invalidInput, setInvalidInput] = useState(false);

  useEffect(() => {
    setAlterations(props.alterations);
    setIndex(props.id);
    if (props.open) setOpen(props.open);
  }, [props.open]);

  const onClose = () => {
    if (props.onInteraction) {
      props.onInteraction({ event: 'close' });
    }
  };

  const isAlphanumeric = (str) => {
    return /^[a-zA-Z0-9\s]+$/.test(str);
  }

  const addAlteration = () => {
    const list = [...alterations];

    setHasError(false);
    setInvalidInput(false);

    if (inputVal.trim().length == 0) {
      if(list.length < 2)
        setHasError(true);
      return;
    }

    if(!isAlphanumeric(inputVal)){
      setInvalidInput(true);
      return;
    }

    if (list.filter(x => x === inputVal).length === 0) {
      list.push(inputVal);
      setAlterations(list);
      setInputVal("");
    }
  }
  const removeAlteration = (val) => {
    const list = alterations.filter(x => x !== val);
    setAlterations(list);
  }

  return (
    <AppKBStateContext.Consumer>
      {appstate => {
        return (
          <ProviderConsumer
            render={globalTheme => (
              <>
                <Dialog
                  cancelButton="Cancel"
                  confirmButton="Save"
                  closeOnOutsideClick={false}
                  open={open}
                  className={`${KBCSS.EditQnADialog}`}
                  onConfirm={async () => {
                    setHasError(false);
                    setInvalidInput(false);
                    if (alterations.filter(x => x && x.trim() != '').length <= 1) {
                      setHasError(true);
                      return;
                    }

                    setState(state => {
                      return { ...state, loadingKB: { message: t('knowledge.loading-knowledge-message'), isLoading: true } };
                    });

                    let newData: SynonymData[];
                    if (index === -1) {
                      newData = update(appstate.synonyms, { $push: [{ alterations: alterations }] });
                    } else {
                      const updatedKB = update(appstate.synonyms[index], { alterations: { $set: alterations } });
                      newData = update(appstate.synonyms, { $splice: [[index, 1, updatedKB]] });
                    }

                    try {
                      const job = await kbDataService.saveSynonyms(newData);
                      if (job.status === "succeeded") {
                        setState(state => { return { ...state, synonyms: newData, tab: 1 }; });
                      }
                    } catch (err) {
                      console.error(err);
                    }

                    setState(state => { return { ...state, loadingKB: { message: '', isLoading: false } }; });
                    setOpen(false);
                  }}
                  onCancel={() => {
                    setAlterations([]);
                    setInputVal("");
                    setHasError(false);
                    setInvalidInput(false);
                    setOpen(false);
                    onClose();
                  }}
                  content={
                    <div>
                      {hasError && (<Alert content={t('knowledge.please-enter-at-least-two-phrases')} danger visible />)}
                      {invalidInput && (<Alert content={t('knowledge.invalid-synonym')} danger visible />)}
                      <PillGroup>
                        {alterations.map(q => (<Pill appearance="outline" actionable onDismiss={() => { removeAlteration(q); }} style={{ minWidth: '1px' }}>{q}</Pill>))}
                      </PillGroup>
                      <Input value={inputVal} fluid placeholder={t('knowledge.enter-text-and-press-enter')} onChange={(ev: any) => { setInputVal(ev.target.value); }} onKeyUp={(ev) => { if (ev.key === "Enter") { addAlteration(); } }} />
                    </div>
                  }
                  header={`${(index === -1 ? 'Add' : 'Edit')} Synonyms`}
                  trigger={(index === -1 ? (<div>
                    <div className={`md:hidden`}>
                      <Button disabled={!props.isUserLicensed} icon={<AddIcon />} primary size="small" content={<Text size="smallest" content={t('knowledge.add-synonym')} onClick={() => setOpen(true)} />} />
                    </div>
                    <div className={`hidden md:block`}>
                      <Button disabled={!props.isUserLicensed} icon={<AddIcon />} primary content={t('knowledge.add-synonym')} onClick={() => setOpen(true)} />
                    </div>
                  </div>) : (<></>))}
                />
              </>
            )}
          />
        );
      }}
    </AppKBStateContext.Consumer>
  );
};