import React from 'react';
import { Flex } from '@fluentui/react-northstar';
import { RequestCardSkeleton } from './RequestCardSkeleton';

export const RequestPageSkeleton = () => (
  <div className={`flex w-full`}>
    <div className={`p-5`}>
      <Flex column className="mt-3">
        <RequestCardSkeleton />
        <RequestCardSkeleton />
        <RequestCardSkeleton />
      </Flex>
    </div>
  </div>
);
