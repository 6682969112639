import React, { useEffect, useState, useCallback } from "react";
import { Flex, Dialog, CloseIcon, Button, Text, Loader } from "@fluentui/react-northstar";
import { SetDefault_TemplateForm } from "./SetDefault_TemplateForm";
import { TicketTemplate } from "../shared/interfaces/ticket.interface";
import { Step_DefineFields } from "./Step_DefineFields";
import { SetDefault_CustomForm } from "./SetDefault_CustomForm";
import { TemplateFormStateProps } from "./TemplateFieldsForm";
import { platformService } from "../shared/services/platform.service";
import { SignedInState } from "../shared/services/signInState.service";
import { Communication } from "@fluentui/react-teams/lib/esm";
import { Providers } from '@microsoft/mgt-element';
import { useHistory } from "react-router-dom";
import { getUpdatedData } from "./TicketTemplates";
import { CheckLogin } from "../shared/components/CheckLogin";
import { appState, useSetState } from "../AppState";
import { graphService } from "../shared/services/graph.service";
import { loadFilters } from "../tikit/ticketHelper";
import { FILTERS_STRINGS, STATUS_GUID, LICENSE_FEATURE } from "../shared/utils/constants";
import { getCachedFeature } from "../shared/cache/FeatureCache";
import { ITeam } from "../shared/interfaces/supportGroup.interface";
import { useTranslation } from "react-i18next";

interface ITicketTemplateDialogProperties {
    open: boolean;
    onCancel: () => void;
  }
export const TicketTemplatesDialog = (props: ITicketTemplateDialogProperties) => {
    const {t} = useTranslation();
    const { open, onCancel } = props;
    const currentState = appState();
    const history = useHistory();
    const setState = useSetState();
    const graphAPI = new graphService();
    const api = new platformService();
    const [templateProps, setTemplateProps] = useState<TemplateFormStateProps>({} as TemplateFormStateProps);
    const [isLoading, setIsLoading] = useState(true);
    const [loadingMessage, setLoadingMessage] = useState(t('template.fetching-template-data'));
    const defaultData = {errorFieldsArray: [], errorFormsArray: []} as TicketTemplate;
    const [menu, setMenu] = useState<number>(0);
    const [templateList, setTemplateList] = useState([]);
    const [pages, setPages] = useState<string[]>(["fields"]);
    const [isCustomFormFeatureEnabled, setIsCustomFormFeatureEnabled] = useState<boolean>(false);

    const [ticketData, setTicketData] = React.useReducer(
        (state: TicketTemplate, newState: TicketTemplate) => {
            state = newState;
          return { ...state, ...newState }
        },
        defaultData
      );

      const loadPage = async () => {
        const results = await Promise.all([
            api.getTicketTemplates('?$select=Name'),
            api.getLifecyclesSafe(`${FILTERS_STRINGS.FILTERS_URL_PARAMS}&$select=Id,Title`),
            (currentState?.ticketStatus?.length > 0) ? Promise.resolve(currentState) : loadFilters(api, graphAPI, currentState, setState),
            getCachedFeature(LICENSE_FEATURE.CustomForms),
            api.getTeamsSafe(FILTERS_STRINGS.IS_DEFAULT)
        ]);
        let filterResults = results[2];
        const lifecycles = results[1];
        filterResults["lifecycles"] = lifecycles;
        filterResults.tags.forEach((t:any) => { t.selected = false });
        setTemplateProps(filterResults as TemplateFormStateProps);
        setTemplateList(results[0].data.value);
        if(results[3])
            setPages((prev: string[]) => {
                prev.push("form")
                return prev;
            });
        setIsCustomFormFeatureEnabled(results[3]);
        setIsLoading(false);
    }
        

    const saveDataAsync = async () => {
        setLoadingMessage(t('template.creating-new-template'));
        setIsLoading(true);
        try {
            const apiData = getUpdatedData(ticketData);
            if(!isCustomFormFeatureEnabled)
                apiData.NoCustomForm = true;

            const { data } = await api.createTicketTemplate(apiData);
            const tagIdList: number[] = apiData?.Tags?.map(tag => tag.Id) ?? [];
            if (tagIdList.length > 0)
                await attachTagsToTicketTemplate(data.Id, tagIdList)
            
            const au = apiData?.AffectedUsers?.map(u => u.Id) ?? [];
            const col = apiData?.Collaborators?.map(u => u.Id) ?? [];
            if (au.length > 0 || col.length > 0)
                await api.attachTemplateUsers(data.Id, au, col);

            setIsLoading(false);
            closeDialog();
            const redirectMenu = (apiData.NoCustomForm || !isCustomFormFeatureEnabled) ? 0 : 1
            history.push(`/settings/templates?id=${data["Id"]}&menu=${redirectMenu}`);
        } catch (err) {
            console.log(err);
        }
    };
    
    const attachTagsToTicketTemplate = async (
        ticketTemplateId: number,
        tags: number[]
      ): Promise<any> => {
        let result = await api.attachTagsOnTicket(ticketTemplateId, tags, true);
        if (result.status === 201) {
          return {
            message: 'tags attached'
          };
        }
      
        return null;
      };

    const isFieldsValid = (menu) => {
        if(pages[menu] == "form" && ticketData.errorFormsArray.includes("NoSelectedCardIndex")){
            return false;
        }
        if(pages[menu] == "fields"){
            if(!(ticketData && ticketData.Name))
                ticketData.errorFieldsArray.push("Name");
            if(ticketData && ticketData.LifecycleId && ticketData.Status){
                if(ticketData.Status.Guid === STATUS_GUID.RESOLVED || ticketData.Status.Guid === STATUS_GUID.CLOSED)
                    ticketData.errorFieldsArray.push("LifecycleStatusConflict");
            }
            if(ticketData && ticketData.errorFieldsArray.length > 0)
                return false;
        }

        return true;
    }

    const primaryClick = async() => {
        if(!isFieldsValid(menu)){
            if(pages[menu] == "form")
                ticketData.validateForms = true;
            if(pages[menu] == "fields")
                ticketData.validateFields = true;
            setTicketData(ticketData);
        }else{
            let result = [];
            if (templateList.length > 0 && templateList.some(x=>x.Name==ticketData.Name))
            {
                result = templateList;
            }

            if(result[0]){
                ticketData.validateFields = true;
                ticketData.errorFieldsArray.push("DupeName");
                setTicketData(ticketData);
            }else{
                let nextIndex = menu+1;
                if(nextIndex < pages.length)
                    setMenu(nextIndex)
                else
                saveDataAsync();
            }
        }
    }

    const secondaryClick = () => {
        let nextIndex = menu-1;
        if(nextIndex >= 0)
            setMenu(nextIndex);
        else
            closeDialog();
    }

    const closeDialog = () => {
        setTicketData(defaultData);
        setMenu(0);
        onCancel();
    }


    return (
    <CheckLogin onSignedIn={loadPage}>
        <Dialog
            content={(isLoading) ? <Loader label={loadingMessage} styles={{ height: "50vh" }} /> : (<div style={{ height: "70vh" }}>{
                <>
                {pages[menu] == "template" && (
                    <SetDefault_TemplateForm templateData={ticketData} dispatchData={setTicketData} formData={templateProps}/>
                )}
                {pages[menu] == "fields" && (
                    <Step_DefineFields  templateData={ticketData} formData={templateProps} dispatchData={setTicketData}/>
                )}
                {pages[menu] == "form" && (
                    <SetDefault_CustomForm templateProps={{templateData: ticketData, dispatchData: setTicketData}} />
                )}
                </>
            }</div>)}
            open={open}
            headerAction={{
                icon: <CloseIcon />,
                title: 'Close',
                onClick: closeDialog
            }}
            footer={(isLoading) ? <></> :
                <Flex gap="gap.small" className="mt-5">
                    <Flex.Item push>
                    <Button content={menu == 0 ? t('template.cancel') : t('template.back')} onClick={secondaryClick}/>
                    </Flex.Item>
                    <Button content={menu+1 < pages.length ? t('template.next') :t('template.create')} primary onClick={primaryClick} />
                </Flex>
            }
            // onCancel={closeDialog}
        />
    </CheckLogin>
    )
}