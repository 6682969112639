import React, { createContext, useState } from 'react';

export enum EnumSortOrderType {
  none,
  asc,
  desc,
}
export const DEFAULT_SORT_ORDER: ISortOrder = null;

interface PropTypes {
  children: React.ReactNode;
}

export interface CustomViewContextType {
  customViewStates: CustomViewStatesType;
  setCustomViewStates: React.Dispatch< React.SetStateAction<CustomViewStatesType>>;
}

export const CustomViewContext = createContext({} as CustomViewContextType);
const CustomViewProvider = CustomViewContext.Provider;

const CustomViewContextProvider = ({ children }: PropTypes) => {
  const [customViewStates, setCustomViewStates] =
    useState<CustomViewStatesType>({
      showCustomViewModal: false,
      view: 'none',
      viewName: '',
      filters: [],
      columns: {
        columnsKeyOrder: [],
        selectedColumns: [],
        sortOrder: DEFAULT_SORT_ORDER,
      },
      configurableColumnsKeyOrder: [],
      configureColumnsApplied: {
        prevSortOrder: DEFAULT_SORT_ORDER,
        sortOrder: DEFAULT_SORT_ORDER,
      },
      customViews: [],
      selectedCustomView: null,
    });

  return (
    <>
      <CustomViewProvider value={{ customViewStates, setCustomViewStates }}>
        <>{children}</>
      </CustomViewProvider>
    </>
  );
};

export default CustomViewContextProvider;
