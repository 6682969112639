import React, { useState } from 'react';
import Styles from '../styles/Lifecycle.module.css';
import { Text, Flex, ThemePrepared, TextArea } from '@fluentui/react-northstar';
import { KeyValuePair, LifecyclePhase, LifecycleTransition, TransitionAction, TransitionRule } from '../utils/typings';
import { PhaseTransitionAction } from './PhaseTransitionAction';
import { PhaseTransitionRule } from './PhaseTransitionRule';
import { Toggle } from '@fluentui/react';
import MgtCss from '../../mgt.module.css';
import { LifecycleAccordion } from './shared/Accordion/LifecycleAccordion';
import { LIFECYCLE } from '../../shared/utils/constants';
import { useTranslation } from 'react-i18next';

interface Props {
  data: LifecycleTransition;
  dataIndex: number;
  updateData: (editData: KeyValuePair[], index: number) => void;
  globalTheme: ThemePrepared<any>;
  phasesOptions: DropdownDataModel[];
  tasksOptions: DropdownDataModel[];
  approvalsOptions: DropdownDataModel[];
  taskStatusList: TaskStatus[];
  clickOnExpand: (index: number) => void;
  phaseData: LifecyclePhase;
  powerautomateOptions: DropdownDataModel[]
}

export const PhaseTransition = ({
  data: { Title, TransitionAction, TransitionRule, UseDefaultLogic, Message, IsExpand },
  dataIndex,
  updateData,
  globalTheme,
  phasesOptions,
  tasksOptions,
  approvalsOptions,
  taskStatusList,
  clickOnExpand,
  powerautomateOptions
}: Props) => {

  const { t } = useTranslation();
  const [isDefaultLogic, setIsDefaultLogic] = useState<boolean>(UseDefaultLogic);
  const [isLogicBuilder, setIsLogicBuilder] = useState<boolean>(!UseDefaultLogic);

  const updateTransitionType = (type: string, checked: boolean) => {
    setIsDefaultLogic((type == "default") ? checked : !checked);
    setIsLogicBuilder((type == "builder") ? checked : !checked);

    const pair: KeyValuePair = {
      key: "UseDefaultLogic",
      value: (type == "default") ? checked : !checked
    }
    updateData([pair], dataIndex);
  }

  const updateTransitionActionData = (item: TransitionAction) => {
    const pair: KeyValuePair = {
      key: "TransitionAction",
      value: item
    }
    updateData([pair], dataIndex);
  }

  const updateTransitionRulesData = (item: TransitionRule) => {
    const pair: KeyValuePair = {
      key: "TransitionRule",
      value: item
    }
    updateData([pair], dataIndex);
  }

  const removeItem = () => {
    const pair: KeyValuePair = {
      key: "RemoveItem",
      value: dataIndex
    } 
    updateData([pair], dataIndex);
  }

  const onChangeTextField = (value: string) => {
    const pair: KeyValuePair = {
        key: "Title",
        value: value
    }
    updateData([pair], dataIndex);
  }

  const onBlurTextField = (value: string) => {
    if(value.length == 0){
      const pair: KeyValuePair = {
        key: "Title",
        value: `${t('lifecycle.new-transition')} ${(dataIndex + 1)}`
      }
      updateData([pair], dataIndex);
    }
  }

  const removeTransitionMessageText = t('lifecycle.delete-transition-message', { transitionName: Title })

  return (
    <>
      <LifecycleAccordion
        isAccordionOpen={IsExpand}
        globalTheme={globalTheme}
        displayName={Title}
        deleteTitle={LIFECYCLE.DELETE_TRANSITION}
        deleteMessage={removeTransitionMessageText}
        removeItem={removeItem}
        onChangeTextField={onChangeTextField}
        onBlurTextField={onBlurTextField}
        clickOnExpand={clickOnExpand}
        index={dataIndex}
      />
      {IsExpand && (
          <>
            <h2 className={Styles.SubHeading2}></h2>
            <div className={Styles.marginY}>
                <div>
                  <Flex>
                    {isDefaultLogic && (
                      <Toggle
                        defaultChecked={true}
                        style={{
                          background: globalTheme.siteVariables.colorScheme.brand.foreground1,
                          opacity: 1
                        }}
                        onChange={(_ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                          updateTransitionType("default", checked);
                        }}
                      />
                    )}
                    {!isDefaultLogic && (
                      <Toggle
                        onChange={(_ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                          updateTransitionType("default", checked);
                        }}
                      />
                    )}
                    <Text content={LIFECYCLE.USE_DEFAULT_LOGIC} className={`ml-2 ${(!isDefaultLogic) && MgtCss.disabledContainer}`} color="brand" />
              </Flex>
              </div>
            </div>

            <div className={`mb-4 -mt-2 ml-10 ${(!isDefaultLogic) && MgtCss.disabledContainer}`}>
              <div>
                <Text content={LIFECYCLE.ALL_COMPLETED_MESSAGE} className="mr-2" weight="semibold"/>
              </div>
            </div>

            <div className={Styles.marginY }>
                <div>
                  <Flex>
                    {isLogicBuilder && (
                      <Toggle
                        defaultChecked={true}
                        style={{
                          background: globalTheme.siteVariables.colorScheme.brand.foreground1,
                          opacity: 1
                        }}
                        onChange={(_ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                          updateTransitionType("builder", checked);
                        }}
                      />
                    )}
                    {!isLogicBuilder && (
                      <Toggle
                        onChange={(_ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                          updateTransitionType("builder", checked);
                        }}
                      />
                    )}
                     <Text content={LIFECYCLE.USE_LOGIC_BUILDER} className={`ml-2 ${(!isLogicBuilder) && MgtCss.disabledContainer}`} color="brand" />
              </Flex>
              </div>
            </div>

            <div className={(!isLogicBuilder) && MgtCss.disabledContainer}>
              <div className={`ml-10`}>
                <Text content={LIFECYCLE.DEFINE_TRANSITION_CONDITION} className="mr-2"/>
              </div>
              {isLogicBuilder && <>
                <PhaseTransitionRule data={TransitionRule} updateData={updateTransitionRulesData} 
                  tasksOptions={tasksOptions} 
                  approvalsOptions={approvalsOptions} 
                  taskStatusList={taskStatusList}
                  powerautomateOptions = {powerautomateOptions}
                />

                  <PhaseTransitionAction data={TransitionAction} updateData={updateTransitionActionData} phasesOptions={phasesOptions} />
                  
                  <div className={`${Styles.marginY} ml-10`}>
                    <Text content={LIFECYCLE.REASON} className="mr-2"/>
                    <TextArea
                        styles={{
                          height: '80px'
                        }}
                        inverted
                        fluid
                        resize="vertical"
                        defaultValue={Message}
                        placeholder={LIFECYCLE.ENTER_A_MESSAGE}
                        onChange={(event, data) => {
                          const pair: KeyValuePair = {
                            key: "Message",
                            value:  data.value
                          };
                          updateData([pair], dataIndex);
                        }}
                      />
                  </div>
              </>}
            </div>
          </>
      )}
    </>
  );

};
