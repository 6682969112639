import { GroupIcon, PeopleIcon } from '@fluentui/react-icons-mdl2';
import { Button, Flex, ProviderConsumer, SvgIconSizeValue, TeamCreateIcon, Text, TrashCanIcon } from '@fluentui/react-northstar';
import React, { useEffect, useRef } from 'react';
import { LICENSE_FEATURE, MyWorkListViewType } from '../../../../utils/constants';
import { TicketTaskStatus } from '../../../TicketCardTemplate';
import CSS from '../../../../../tikit/WorkBoard/Templates/TemplateCard.module.css'
import './TicketTasksDetails.css';
import { getTemplateCardBgColor } from '../../../../../tikit/ticketHelper';
import { scrollToCardIfSelected } from '../../../TicketCard/TicketCard';
import { appState, useSetState } from '../../../../../AppState';
import LifecycleTaskFilledIcon from "../../../../../../svg/lifecycle-task-filled-icon.svg";
import LifecyclePowerAutomateTaskFilledIcon from "../../../../../../svg/lifecycle-powerautomate-filled-icon.svg";
import CollaboratorIcon from '../../../../../../svg/collaboratorIcon.svg';
import { useTranslation } from 'react-i18next';
import { CheckFeature } from '../../../CheckFeature';
import { UsersTooltip } from '../../../TicketDetailInfo/UsersTooltip';

interface Props {
  isWorkBoard?: boolean;
  ticketTask: TicketTask;
  deleteTask?: (taskId: number, taskTitle: string) => void;
  isFormDisabled?: boolean
}

export const TicketTasksDetails = ({ ticketTask, deleteTask, isFormDisabled = false, isWorkBoard = false }: Props) => {

  const { t } = useTranslation();

  const activeTicketTaskRef = useRef(null);
  const { selectedTaskOrApproval  } = appState();
  const setAppState = useSetState();
  const currentstate = appState();
  const iconStyle = {
    size: 'small' as SvgIconSizeValue,
    style: { color: '#8f90a6' }
  };
  const ellipsis = {
    width: '70px',
    overflow:'hidden',
    whiteSpace:'nowrap',
    textOverflow: 'ellipsis'
  }  as React.CSSProperties;

  useEffect(() => {
    if(!selectedTaskOrApproval || selectedTaskOrApproval.type !== MyWorkListViewType.Tasks) return;

    const { id } = selectedTaskOrApproval; 
    scrollToCardIfSelected({ cardId: ticketTask.Id, selectedCardId: id, activeCardRef: activeTicketTaskRef });

    setAppState((prev) => ({ ...prev, isRouteFromMyWork: false, selectedTaskOrApproval: undefined }));
  }, [ticketTask]);

  return (
    <ProviderConsumer
      render={globalTheme => (
        <>
          <div 
            className={`lifecycleItem ${CSS.ticketTaskCardDetail} p-2 mt-3`}
            style={{ background: getTemplateCardBgColor(globalTheme.siteVariables.theme) }}
            ref={activeTicketTaskRef}
          >
            <div className={`pb-2 ticketTaskTitle`}>
              <Flex space="between" className={`mb-2`}>
                <Flex.Item>
                  <div>
                    <Flex>
                      <Flex.Item>
                        <span className={`mr-2`}>
                          {(ticketTask.IsPowerAutomateTask) ? 
                          <LifecyclePowerAutomateTaskFilledIcon
                            width={14}
                            height={14}
                            className="inline-block"
                          /> :
                          <LifecycleTaskFilledIcon
                            width={14}
                            height={14}
                            className="inline-block"
                          />}
                        </span>
                      </Flex.Item>
                      <Flex.Item>
                        <span style={{marginTop: '1px'}}>
                          <Text size="small" className="break-normal" weight="semibold">
                            {ticketTask.Title}
                          </Text>
                        </span>
                      </Flex.Item>
                    </Flex>
                  </div>
                </Flex.Item>
                <Flex.Item>
                  <span className={`ml-2`} style={{whiteSpace: 'nowrap'}}>
                    <TicketTaskStatus status={ticketTask?.Status} postion={'end'} />
                  </span>
                </Flex.Item>
              </Flex>
            </div>
            <div className={`mt-2 flex justify-between`}>
              {(!ticketTask.IsPowerAutomateTask) && (<Flex vAlign="center" gap="gap.small">
                
                <CheckFeature featureName={LICENSE_FEATURE.MultiDepartment}>
                  <Flex vAlign="center" gap="gap.smaller">
                    <TeamCreateIcon {...iconStyle}/>
                  </Flex>
                  {(ticketTask?.Team?.Name) && <Flex>
                    <Text size="small" style={ellipsis} className='break-all'>
                    {ticketTask?.Team?.Name}
                    </Text>
                  </Flex>}
                </CheckFeature>

                <Flex vAlign="center" gap="gap.smaller">
                  <GroupIcon />
                </Flex>
                {(ticketTask?.SupportGroup?.Name) && <Flex>
                  <Text size="small" style={ellipsis} className='break-all'>
                  {ticketTask?.SupportGroup?.Name}
                  </Text>
                </Flex>}

                <Flex vAlign="center" gap="gap.smaller">
                  <PeopleIcon />
                </Flex>
                {(ticketTask?.Assignee?.FullName) && <Flex>
                  <Text size="small" style={ellipsis} className='break-all'>
                  {ticketTask?.Assignee?.FullName}
                  </Text>
                </Flex>}

                <CheckFeature featureName={LICENSE_FEATURE.Collaborators}>
                    <Flex vAlign="center" gap="gap.smaller">
                      <CollaboratorIcon width={12} height={12} style={{ margin: '0 auto', color: globalTheme.siteVariables.colorScheme.default.foreground }} />
                      {ticketTask?.TaskCollaborators?.length > 0 && <Flex style={{ marginLeft: '5px'}} ><UsersTooltip ticketCollaborator={ticketTask?.TaskCollaborators?.map(c => currentstate.platformusers.find(p => p.Id == c.PlatformUserId))} title={t('ticket.ticket-list.collaborators')} /></Flex>}
                    </Flex>
                </CheckFeature>
              </Flex>)}
              {(!isWorkBoard && !ticketTask.TicketLifecyclePhase) && (
                  <Flex.Item>
                    <div>
                      <Button
                        icon={<TrashCanIcon />}
                        disabled={isFormDisabled}
                        text
                        iconOnly
                        size="small"
                        style={{ color: isFormDisabled ? globalTheme.siteVariables.colorScheme.default.foregroundDisabled : 'red' }}
                        title={t('ticket-details.edit-ticket-form.task.delete-task')}
                        onClick={e => {
                          e.stopPropagation();
                          deleteTask(ticketTask.Id, ticketTask.Title);
                        }}
                      />
                    </div>
                  </Flex.Item>
                )}
            </div>
          </div>
        </>
      )}
    />
  );
};
