import React from 'react';
import { Checkbox, CheckboxProps, Flex } from '@fluentui/react-northstar';
import MgtCss from '../../../mgt.module.css';

interface Props {
  label: string;
  checked: boolean;
  onChange: (
    checked: Omit<CheckboxProps, 'checked'> & {
      checked: boolean;
    }
  ) => void;
  required?: boolean;
  children?: any;
}

export const LabelWithCheckbox = ({
  label,
  checked,
  onChange,
  required,
  children
}: Props) => {
  return (
    <div className="mb-2.5">
      <Flex vAlign="center">
        <Checkbox
          id={label + '_fieldCheckbox'}
          label={label}
          defaultChecked={checked}
          checked={checked}
          onChange={(_e, isChecked) => {
            onChange(isChecked);
          }}
          className={`${MgtCss.fieldCheckbox} px-0 py-0.5`}
        />
        {required && <span style={{ color: '#c4314b' }}>*</span>}
      </Flex>
      {children}
    </div>
  );
};
