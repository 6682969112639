import React from 'react';
import { RecursiveTree } from './RecursiveTree';
import { useTranslation } from 'react-i18next';

interface NestedFilterProps {
  filterItems: FilterAccordionValues[];
  onClick: (item: any) => void;
}

export interface INestedFilterItem {
  key: number;
  title: string;
  selected: boolean;
  position?: number | null;
  parentId?: number | null;
  children?: any;
}

export const NestedFilter = ({ filterItems, onClick }: NestedFilterProps) => {
  const {t} = useTranslation();
  const toTree = (items: FilterAccordionValues[], parentId: number = null) => {
    return [...items].sort((a, b) => (a?.position ?? 0) - (b?.position ?? 0)).reduce((treeItems: INestedFilterItem[], item) => {
      if (item.parentId == parentId) {
        const treeItem: INestedFilterItem = { ...item };
        const children = toTree(items, item.key);
        if (children && children.length > 0) treeItem.children = children;
        treeItems.push(treeItem);
      }
      return treeItems;
    }, []);
  };

  const dropdownItems: INestedFilterItem[] = toTree(filterItems);

  return (
    <>
      {dropdownItems.length <= 0 ?
        <div className="h-full flex flex-col items-center justify-center py-2">
          <span>{t('common.no-data-found')}</span>
        </div> :
        <RecursiveTree
          items={dropdownItems}
          onSelectCallback={onClick}
        />
      }
    </>
  );
};