export const ThemeColorScheme = (props: any) => {
    let color = props.colorScheme;
    return {
        '--mgt-theme-background': color.default.background,
        '--mgt-theme-background1': color.default.background1,
        '--mgt-theme-background2': color.default.background2,
        '--mgt-theme-background3': color.default.background3,
        '--mgt-theme-background5': color.default.background5,
        '--mgt-theme-foreground-disabled': color.default.foregroundDisabled,
        '--mgt-theme-background-disabled':color.default.backgroundDisabled,
        '--mgt-theme-foreground': color.default.foreground,
        '--mgt-theme-foregroundFocus': color.default.foregroundFocus,
        '--mgt-theme-brand-background': color.brand.background,
        '--mgt-theme-brand-background1': color.brand.background1,
        '--mgt-theme-brand-background2': color.brand.background2,
        '--mgt-theme-background-hover': color.default.backgroundHover,
        '--mgt-theme-background-hover1': color.default.backgroundHover1,
        '--mgt-theme-background-hover2': color.default.backgroundHover2,
        '--mgt-theme-background-active': color.default.backgroundActive,
        '--mgt-theme-background-brand-hover': color.brand.backgroundHover,
        '--mgt-theme-background-brand-hover1': color.brand.backgroundHover1,
        '--mgt-theme-background-brand-hover2': color.brand.backgroundHover2,
        '--mgt-theme-border-color': color.default.border,
        '--mgt-theme-border-color1': color.default.border1,
        '--mgt-theme-border-color2': color.default.border2,
        '--mgt-theme-border-color3': color.default.border3,
        '--mgt-theme-border-hover': color.default.borderHover,
        '--mgt-theme-border-disabled': color.default.borderDisabled,
        '--mgt-theme-border-active': color.default.borderActive,
        '--mgt-theme-button-border': color.brand.border1,
        '--mgt-theme-brand-foreground': color.brand.foreground,
        '--mgt-theme-brand-foreground1': color.brand.foreground1,
        '--mgt-theme-brand-foreground2': color.brand.foreground2,
        '--mgt-theme-default-foreground': color.default.foreground,
        '--mgt-theme-default-foreground1': color.default.foreground1,
        '--mgt-theme-default-foreground2': color.default.foreground2,
        '--mgt-theme-default-foreground6': color.default.foreground6,
        '--mgt-theme-button-background': color.default.background
    }
}