export {}

var sdApp: sdConstants;
type sdConstants = {
    RoleNameAnalyst: string;
    RoleNameUsers: string;
    RoleNameAgents: string;
};

globalThis.sdApp = {
    RoleNameAnalyst: "Analysts",
    RoleNameUsers: "Users",
    RoleNameAgents: "Agents"
};

Object.freeze(globalThis.sdApp);