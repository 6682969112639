import React, { useState, useContext } from 'react';
import { Communication } from '@fluentui/react-teams';
import { MobileList } from '../mobile/MobileList';
import { AppStateContext } from '../../AppState';
import { TicketCardSkeleton } from '../../shared/components/TicketCard/TicketCardSkeleton';
import { platformService } from '../../shared/services/platform.service';
const api = new platformService();

interface Props {
  isTicketsFetching: boolean;
  defaultFilters: FilterPropsItems[]
}

export const TicketBoardMobileView = ({ isTicketsFetching, defaultFilters }: Props) => {
  const state = useContext(AppStateContext);
  const [loading, setLoading] = useState(false);
  
  return (
    <>
      {(isTicketsFetching || loading) ? (
        <div className="p-3">
          <TicketCardSkeleton />
          <TicketCardSkeleton />
          <TicketCardSkeleton />
        </div>
      ) : !isTicketsFetching && state.isError ? (
        <Communication {...state.errorConfig} />
      ) : (
        <></>
      )}
      <MobileList className="md:hidden" defaultFilters={defaultFilters} idColorPurple setLoading={setLoading} isTicketsFetching={isTicketsFetching}/>
    </>
  );
};
