import React, { useEffect, useState, useCallback } from 'react';
import { platformService } from '../shared/services/platform.service';
import { Communication } from '@fluentui/react-teams';
import { Providers } from '@microsoft/mgt-element/dist/es6/providers/Providers';
import { Breadcrumbs } from '../shared/components/BreadcrumbNavigation';
import {
  BUTTONS,
  DEFAULT_API_STATUS,
  DELETE,
  DONE,
  ERROR,
  LOADING,
  SETTINGS,
  SLA,
  SLA_HEADERS,
  TicketSlaState,
  ENDPOINT_SECURITY_MAPPINGS,
  SETTINGS_PAGES,
  UNAUTHORIZED_ACCESS,
  SOMETHING_WENT_WRONG,
  CONTACT_ADMIN,
  REFRESH_SESSION
} from '../shared/utils/constants';
import {
  Button,
  Checkbox,
  Dialog,
  TrashCanIcon,
  ProviderConsumer,
  Flex
} from '@fluentui/react-northstar';
import { Helmet } from 'react-helmet';
import { LoadingScreen } from '../shared/components/LoadingScreen';
import { KBListWrapper } from '../kb/KBListWrapper';
import { toTitleCase } from '../shared/utils/helper';
import { ConvertDateToLocale } from '../shared/common/ConvertDate';
import { transformModalContent } from '../automation/AutomationTable';
import { UpgradeNeeded } from '../shared/components/UpgradeNeeded';
import { CheckLogin } from '../shared/components/CheckLogin';
import { Toolbar } from '../shared/components/Toolbar';
import ConfigCSS from '../config/config.module.css';
import { CheckLicense } from '../shared/components/License/CheckLicense';
import useCurrentPage from '../kb/useCurrentPage';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

export const SlaTable = () => {
  const {t} = useTranslation();
  const api = new platformService();
  const [data, setData] = useState<RowsData[]>([]);
  const [showDialog, setShowDialog] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState({
    Id: 0,
    Title: ''
  });
  const [apiStatus, setApiStatus] = useState<APIStatus>(
    DEFAULT_API_STATUS as APIStatus
  );
  const {currentPage, setCurrentPage, onDeleteRowSetCurrentPage} = useCurrentPage()

  const toggleSla = async (id: number, currentToggleStatus: boolean) => {
    let status = 0;
    setApiStatus({
      status: LOADING,
      msg: SLA.TOGGLING_SLA,
      errCode: 0
    });
    try {
      ({ status } = await api.toggleSla(id, !currentToggleStatus));
      setApiStatus({ ...apiStatus, status: DONE });
    } catch (err: any) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: err.response.status
      });
    }

    if (status === 204) fetchData();
  };

  const fetchData = useCallback(async () => {
    setApiStatus({ ...apiStatus, msg: SLA.GETTING_ALL_SLA });

    const check = ENDPOINT_SECURITY_MAPPINGS.find(x => x.key == SETTINGS_PAGES.SLA);
    if (check && JSON.parse(sessionStorage.getItem('my_application_roles') || '[]').filter(x => x.EndpointPath == check.path && x.Permissions.includes(check.method)).length <= 0) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: 403
      });
      return;
    }
    try {
      const result = await Promise.all([ 
        api.getSlaList('?$select=Id,Name,IsActive,CreatedDate&$filter=IsDeleted eq false&$orderby=Id desc'),
        api.getTicketSlaCountBySla()
      ]);

      const value = result[0].data.value;
      const counts = result[1];
      
      const rowData: RowsData[] = value.map((sla: SlaData) => ({
        id: sla['Id'],
        [t('sla.headers.name')]: sla['Name'],
        [t('sla.headers.ticket-related-to-sla')]: counts.find(x => x.SlaId == sla.Id)?.TicketCount ?? 0,
        [t('sla.headers.ticket-breached-sla')]: counts.find(x => x.SlaId == sla.Id)?.BreachedCount ?? 0,
        [t('sla.headers.created-date')]: ConvertDateToLocale(sla['CreatedDate'], DateTime.DATE_SHORT),
        [t('sla.headers.action')]: (
          <>
            <CheckLicense disable>
              <Checkbox
                toggle
                checked={sla['IsActive']}
                onClick={e => {
                  e.stopPropagation();
                  toggleSla(sla['Id'], sla['IsActive']);
                }}
              />
              <Button
                icon={<TrashCanIcon />}
                text
                iconOnly
                title={toTitleCase(DELETE)}
                onClick={e => {
                  e.stopPropagation();
                  setRecordToDelete({
                    Id: sla['Id'],
                    Title: sla['Name']
                  });
                  setShowDialog(true);
                }}
              />
            </CheckLicense>
          </>
        )
      }));

      setData(rowData);
      setApiStatus({ ...apiStatus, status: DONE });
    } catch (err: any) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: err?.response?.status
      });
    }
  }, []);

  const loadPage = () => {
    fetchData();
  }

  const deleteSla = async () => {
    let status = 0;
    setShowDialog(false);
    setApiStatus({
      status: LOADING,
      msg: SLA.DELETING_SLA,
      errCode: 0
    });
    try {
      ({ status } = await api.deleteSla(recordToDelete['Id']));
      setApiStatus({ ...apiStatus, status: DONE });
    
      onDeleteRowSetCurrentPage(data.length)
    } catch (err: any) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: err.response.status
      });
    } finally {
      setShowDialog(false);
    }

    if (status === 204) fetchData();
  };

  const navs: Breadcrumbs = {
    breadcrumbs: [
      {
        title: 'Settings',
        link: '/settings'
      },
      {
        title: t('sla.service-level-agreement'),
        link: '/settings/sla'
      }
    ]
  };

  const errConfig = {
    fields: {
      title:
        apiStatus.errCode === 401 || apiStatus.errCode === 403
          ? UNAUTHORIZED_ACCESS
          : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  };

  return (
    <CheckLogin onSignedIn={loadPage}>
      <Helmet>
        <title>{t('sla.title-page')}</title>
      </Helmet>
      {
        apiStatus.status === LOADING ? (
          <LoadingScreen message={apiStatus.msg} />
        ) : apiStatus.status === ERROR ? (
        <>
          {apiStatus.errCode != 402 && (
            <ProviderConsumer render={(globalTheme) => (
              <>
                <Toolbar globalTheme={globalTheme} title={t('sla.title-link')} hideSave={true} breadcrumbs={navs}/>
                <Flex className={ConfigCSS.configBody}>
                  <Flex.Item grow>
                    <>
                      <Communication {...errConfig} />
                      {apiStatus.errCode === 401 && (<Button content={REFRESH_SESSION} primary onClick={e => { Providers.globalProvider.login(); }} />)}
                    </>
                  </Flex.Item>
                </Flex>
              </>)}
            />
          )}
          {apiStatus.errCode === 402 && (
            <UpgradeNeeded
              toolbarText={`${toTitleCase(SETTINGS)} > ${toTitleCase(SLA.SLA)}`}
              breadcrumbs={navs}
              headerText={t('sla.you-do-not-have-access')}
              subheaderText={t('sla.please-upgrade-your-plan')}
            />
          )}
        </>
      ) : (
        <>
          <KBListWrapper
            title={toTitleCase(SLA.SLA)}
            routeName={SETTINGS_PAGES.SLA}
            headers={SLA_HEADERS}
            headerNotTitleCase
            rows={data}
            currentPage={currentPage} 
            setCurrentPage={setCurrentPage}
            breadcrumbs={navs}
            ifEmpty={{
              header: t('sla.there-are-no-service-level-agreement'),
              subHeader:
                t('sla.get-started-by-clicking')
            }}
          />
        </>
      )}
      <Dialog
        open={showDialog}
        onCancel={() => setShowDialog(false)}
        cancelButton={toTitleCase(BUTTONS.CANCEL)}
        confirmButton={toTitleCase(DELETE)}
        onConfirm={() => {
          deleteSla();
        }}
        content={transformModalContent`${t('sla.delete-sla', {recordToDelete: recordToDelete['Title']})}`}
        header={`${t('sla.header-delete-sla', {recordToDelete: recordToDelete['Title']})}`}
        style={{ maxWidth: '500px' }}
      />
    </CheckLogin>
  );
};
