import React, { ReactNode } from 'react';
import { IChartProps, Chart } from './Chart';
import { Placeholder } from './Placeholder';
import {
  Flex,
  Card,
  Text,
  Box as BoxComponent,
  ThemePrepared,
  SiteVariablesPrepared,
  tabListBehavior,
  Menu
} from '@fluentui/react-northstar';

import { TChartBoardInteraction } from './ChartBoard';

export interface IChartWidgetContent {
  type: 'chart' | string;
  chart: IChartProps;
}

interface IPlaceholderWidgetContent {
  type: 'placeholder' | string;
  message: string;
}

export type TWidgetContent = IChartWidgetContent | IPlaceholderWidgetContent;

export enum ChartWidgetSize {
  Single = 'single',

  Double = 'double',

  Triple = 'triple',

  Box = 'box'
}

export interface IChartWidget {
  id: string;

  size: ChartWidgetSize;

  title: string;

  desc?: string;

  body?: IChartWidgetBodyContent[];

  link?: IWidgetLink;
}

export interface IChartWidgetBodyContent {
  id: string;

  title: string;

  content: TWidgetContent;
}

export interface IWidgetLink {
  href: string;
}

const EmptyState = ({ borderColor }: { borderColor: string }) => {
  return (
    <BoxComponent
      styles={{
        height: '100%',
        border: `1px dashed ${borderColor}`
      }}
    />
  );
};

export const Widget = ({
  children,
  size
}: {
  children: ReactNode;
  size: ChartWidgetSize;
}) => {
  const cardStyle = {
    gridColumnEnd: 'auto',
    gridRowEnd: 'auto',
    '@media (max-width: 842px)': {
      gridColumnEnd: 'span 3'
    }
  };
  if (size === ChartWidgetSize.Double) {
    cardStyle.gridColumnEnd = 'span 2';
  }
  if (size === ChartWidgetSize.Box) {
    cardStyle.gridColumnEnd = 'span 2';
    cardStyle.gridRowEnd = 'span 2';
  }
  if (size === ChartWidgetSize.Triple) {
    cardStyle.gridColumnEnd = 'span 3';
  }
  return (
    <Card styles={cardStyle} fluid>
      {children}
    </Card>
  );
};

export const WidgetTitle = ({
  widgetId,
  title,
  desc,
  globalTheme,
  onInteraction
}: {
  widgetId: string;
  title: string;
  desc?: string;
  globalTheme: ThemePrepared;
  onInteraction?: (interaction: TChartBoardInteraction) => void;
}) => {
  return (
    <Card.Header>
      <Flex gap="gap.small" space="between" style={{ minHeight: '2rem' }}>
        <Flex gap="gap.small" column>
          <Text content={title} style={{ margin: 0 }} weight="bold" />
          {desc && <Text content={desc} size="small" />}
        </Flex>
      </Flex>
    </Card.Header>
  );
};

export const WidgetBody = ({
  body,
  siteVariables
}: {
  body?: IChartWidgetBodyContent[];
  siteVariables: SiteVariablesPrepared;
}) => {
  const [activeTabId, setActiveTabId] = React.useState(0);
  return (
    <Card.Body
      style={{
        marginBottom: '0.75rem',
        height: '100%',
        overflow: 'hidden'
      }}
      fitted
    >
      {body ? (
        <>
          {body.length > 1 && (
            <Menu
              style={{
                border: 'none',
                background: 'none',
                marginBottom: '1.25rem'
              }}
              items={Array.from(body, ({ id, title }) =>
                Object.assign({ key: id, content: title })
              )}
              activeIndex={activeTabId}
              onItemClick={({ currentTarget }, props) =>
                setActiveTabId(props && props.index ? props.index : 0)
              }
              accessibility={tabListBehavior}
              underlined
              primary
            />
          )}
          {body.map(({ id, content }, i) => (
            <Flex
              key={id}
              styles={{
                height: '100%',
                display: activeTabId === i ? 'flex' : 'none'
              }}
              column
            >
              {(() => {
                switch (content.type) {
                  case 'chart':
                    return (
                      <Chart {...(content as IChartWidgetContent).chart} />
                    );
                  case 'placeholder':
                    return (
                      <Placeholder
                        message={(content as IPlaceholderWidgetContent).message}
                      />
                    );
                }
              })()}
            </Flex>
          ))}
        </>
      ) : (
        <EmptyState borderColor={siteVariables.colors.grey['300']} />
      )}
    </Card.Body>
  );
};

export const WidgetFooter = ({
  link,
  siteVariables
}: {
  link: IWidgetLink;
  siteVariables: SiteVariablesPrepared;
}) => (
  <Card.Footer fitted>
    <Flex space="between" vAlign="center">
      <Text
        as="a"
        href={link.href}
        target="_blank"
        size="small"
        color="brand"
        styles={{
          textDecoration: 'none',
          '&:focus': {
            outlineColor: siteVariables.colorScheme.default.foregroundActive
          }
        }}
      ></Text>
    </Flex>
  </Card.Footer>
);
