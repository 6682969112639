import React, { useEffect, useRef, useState } from 'react';
import { Alert, Flex, ProviderConsumer, Text, ThemePrepared } from '@fluentui/react-northstar';
import { LifecyclePhase, LifecycleTransition } from '../utils/typings';
import Style from '../styles/PhaseDetails.module.css'
import { cloneDeep } from 'lodash';
import { RightArrow } from './shared/Arrow/Arrow';
import InfoIcon from '../../../svg/info-icon.svg';
import LifecycleTaskIcon from "../../../svg/lifecycle-task-filled-icon.svg";
import LifecycleApprovalIcon from "../../../svg/lifecycle-filled-approvals.svg";
import LifecyclePowerAutomateIcon from "../../../svg/lifecycle-powerautomate-icon.svg";
import { TransitionActionTypeString } from '../utils/constants';
import { themeNames } from '@fluentui/react-teams';
import { ThemeColorScheme } from '../../shared/common/TeamsTheme';
import { LIFECYCLE, BUTTONS } from './../../shared/utils/constants';

interface Props {
    phaseData: LifecyclePhase;
    allPhasesData: LifecyclePhase[];
    phaseIndex?: number;
    clickOnPhaseName: (index: number, isExpand: boolean) => void;
    handleScroll?: (movePhaseGuid: any) => void;
}

interface TransitionPillsProps {
    transition: LifecycleTransition;
    globalTheme: ThemePrepared<any>;
}

interface ShowActivitiesProps {
    taskCount: number;
    approvalCount: number;
    powerAutomateCount: number
}

interface ShowMoveToPhaseActionProps {
    globalTheme: ThemePrepared<any>;
    transition: LifecycleTransition;
    useDefaultLogic: boolean;
    phaseIndex: number;
    allPhasesData: LifecyclePhase[];
    clickOnPhaseName: (index: number, isExpand: boolean) => void;
    handleScroll?: (movePhaseGuid: any) => void;
}

export const PhaseDetails = ({
    phaseData,
    allPhasesData,
    phaseIndex,
    clickOnPhaseName,
    handleScroll
}: Props) => {
    const [transitionsData, setTransitionsData] = useState([]);

    useEffect(() => {
        const gData= cloneDeep(phaseData.Transitions);
        let previousElement = null;
        const gProps = gData.map((g, index) => {
            let arrowHeight = 30;
            let bottom = 1;
            if(index > 0) {
                arrowHeight = 120;
                bottom = 24;
                if(!previousElement?.Message) {
                    arrowHeight = 72;
                    bottom = 12;
                }
            }
            previousElement = g;
            const toggleExpandMessage = g.Message?.length > 50 ? true : false;
            return {
            transition: g,
            arrowHeight: arrowHeight,
            bottom: bottom,
            toggleExpandMessage: toggleExpandMessage
            };
        });
        setTransitionsData(gProps);
    }, [phaseData])

    const updateTransitionMessageExpand = (index: number, toggle: boolean) => {
        transitionsData[index].toggleExpandMessage = toggle;
        setTransitionsData([...transitionsData]);
    }

    return(
        <ProviderConsumer 
            render={(globalTheme) => (
                <div style={{borderTop: `1px solid ${globalTheme.siteVariables.colorScheme.brand.foreground1}`, ...ThemeColorScheme(globalTheme.siteVariables)}}>
                    <div className={`${Style['phaseDetails']} pb-1.5 mb-3`} style={{backgroundColor: globalTheme.siteVariables.theme === themeNames.Dark ? 
                        globalTheme.siteVariables.colorScheme.default.background1 : globalTheme.siteVariables.colorScheme.default.background3 }}>
                        <ShowActivities taskCount={phaseData.Tasks.length} approvalCount={phaseData.Approvals.length} powerAutomateCount={phaseData.PowerAutomateTasks.length} />
                        <div>
                            {React.Children.toArray(
                                transitionsData.map((item, index) => (
                                    <>
                                        <Flex key={index}>
                                            {item.arrowHeight > 0 && (
                                                <div className={Style.IfChildGroupArrowContainer}>
                                                    <RightArrow height={item.arrowHeight} bottom={item.bottom} />
                                                </div>
                                            )}
                                            <div className={`relative flex flex-col mt-4 ml-3`}>
                                                <TransitionPills transition={item.transition} globalTheme={globalTheme} />
                                                <div className={`mt-2 ml-1`} style={{width: '500px'}}>
                                                    <ShowMoveToPhaseAction globalTheme={globalTheme} 
                                                        transition={item.transition.TransitionAction}
                                                        useDefaultLogic={item.transition.UseDefaultLogic}
                                                        allPhasesData={allPhasesData}
                                                        phaseIndex={phaseIndex}
                                                        clickOnPhaseName={clickOnPhaseName}
                                                        handleScroll={handleScroll}
                                                    />
                                                </div>
                                                <div className={`mt-2 ml-1`} style={{ width: '500px' }}>
                                                    {
                                                        !item.transition.UseDefaultLogic && item.transition.Message && <Alert className="mt-2" warning
                                                            header={<Flex styles={{height: '100%', alignItems: 'center'}}>
                                                                <InfoIcon
                                                                    width={16}
                                                                    height={16}
                                                                    className={`inline-block`}
                                                                /></Flex>}
                                                            content={
                                                                <>
                                                                    <Text weight="semibold" content={<>{LIFECYCLE.REASON}: </>} />
                                                                    <Text size='small' content={<>
                                                                        {item.toggleExpandMessage ? item.transition.Message.substring(0, 50) : item.transition.Message}
                                                                        {item.transition.Message?.length > 50 &&
                                                                        <>{item.toggleExpandMessage ? "..." : ""} <span className={`cursor-pointer`} style={{color: globalTheme.siteVariables.colorScheme.brand.foreground}}
                                                                        onClick={() => {updateTransitionMessageExpand(index, !item.toggleExpandMessage)}}
                                                                        >{item.toggleExpandMessage ? LIFECYCLE.SEE_MORE : LIFECYCLE.SEE_LESS}</span></>}
                                                                    </>} />
                                                                </>
                                                            }
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </Flex>
                                    </>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            )}
        />
    )
}

export const ShowActivities = ({taskCount, approvalCount, powerAutomateCount}: ShowActivitiesProps) => {
    return (
        <ProviderConsumer 
            render={(globalTheme) => (
                <Flex className={`p-4`} space='between' vAlign='center' styles={{
                    backgroundColor: globalTheme.siteVariables.colorScheme.default.background5,
                    color: globalTheme.siteVariables.colorScheme.default.foreground
                }}>
                    <Flex.Item>
                        <span className={`${Style['activitiesTitle']}`}>{LIFECYCLE.ACTIVITIES}</span>
                    </Flex.Item>
                    <Flex.Item>
                        <Flex>
                            {taskCount > 0 && <Flex.Item>
                                <div className={`${Style.taskTextStyle}`}>
                                    <LifecycleTaskIcon
                                        width={12}
                                        height={12}
                                        className="inline-block"
                                    />
                                    &nbsp;{taskCount} {(taskCount > 1) ? LIFECYCLE.TASKS : LIFECYCLE.TASK}
                                </div>
                                </Flex.Item>}
                            {approvalCount > 0 && <Flex.Item>
                                <div className={`ml-2 ${Style.taskTextStyle}`}>
                                    <LifecycleApprovalIcon
                                        width={12}
                                        height={12}
                                        className={`inline-block`}
                                    />
                                    &nbsp;{approvalCount} {(approvalCount > 1) ? LIFECYCLE.APPORVALS : LIFECYCLE.APPROVAL}
                                </div>
                                </Flex.Item>}
                            {powerAutomateCount > 0 && <Flex.Item>
                                <div className={`ml-2 ${Style.taskTextStyle}`}>
                                    <LifecyclePowerAutomateIcon
                                        width={12}
                                        height={12}
                                        className={`inline-block`}
                                    />
                                    &nbsp;{powerAutomateCount} {(powerAutomateCount > 1) ? LIFECYCLE.POWER_AUTOMATE_TASKS : LIFECYCLE.POWER_AUTOMATE_TASK}
                                </div>
                            </Flex.Item>}
                            
                        </Flex>
                    </Flex.Item>
                </Flex>
            )}
        />
    )
}

export const TransitionPills = ({transition, globalTheme}: TransitionPillsProps) => {
    return(
        <>
            <Flex vAlign='center'>
                <div className={`bg-gray-100 text-gray-600 inline-block capitalize rounded-full px-4 ${Style.transitionPill}`} style={{
                    backgroundColor: globalTheme.siteVariables.colorScheme.default.foregroundDisabled,
                    color: globalTheme.siteVariables.colorScheme.default.foreground
                }}>
                    <Text weight="bold" size="smaller">
                        {transition.Title}
                    </Text>
                </div>
            </Flex> 
        </>
    )
}

export const ShowMoveToPhaseAction = ({globalTheme, transition, useDefaultLogic, phaseIndex, allPhasesData, clickOnPhaseName, handleScroll}: ShowMoveToPhaseActionProps) => {
    const phaseSpanRef = useRef(null);

    const clickOnMovePhase = (guid) => {
        const index = allPhasesData.findIndex(item => item.Guid === guid);
        clickOnPhaseName(index, true);
        handleScroll(guid);
    }

    const moveToPhaseTitle = (phaseData) => {
        return <span className={`font-bold cursor-pointer`} ref={phaseSpanRef} onClick={() => {clickOnMovePhase(phaseData.Guid)}}>
        {phaseData?.Name ?? ''}</span>
    }
    
    const moveToNextPhase = (phasesData: LifecyclePhase[], phaseIndex: number) => {
        if(phaseIndex < phasesData.length - 1) {
            return moveToPhaseTitle(phasesData[phaseIndex + 1])
        } else {
            return <span className={`font-bold`}>{LIFECYCLE.COMPLETE}</span>;
        }
    }

    const moveToPreviousPhase = (phasesData: LifecyclePhase[], phaseIndex: number) => {
        if(phaseIndex > 0) {
            return moveToPhaseTitle(phasesData[phaseIndex - 1])
        } else {
            return <span>{LIFECYCLE.FAILED}</span>;
        }
    }

    const showPhaseTextToMove = (phaseIndex, transition, allPhasesData) => {
        if (useDefaultLogic)
            return moveToNextPhase(allPhasesData, phaseIndex);

        switch (transition.ActionType) {
            case TransitionActionTypeString.MoveToPreviousPhase:
                return moveToPreviousPhase(allPhasesData, phaseIndex);
            case TransitionActionTypeString.MoveToPhaseX:
                return moveToPhaseTitle(transition.TransitionItem); 
            case TransitionActionTypeString.FailLifecycle:
                return <span className={`font-bold`}>{LIFECYCLE.FAILED}</span>;
            default:
                // Default Move To Next phase
                return moveToNextPhase(allPhasesData, phaseIndex);
        }
    }

    return (
        <div className={`text-xs`}>
            <span className={`font-bold`} style={{color: globalTheme.siteVariables.colorScheme.default.foreground}}>{LIFECYCLE.ACTIONS}: </span> {LIFECYCLE.MOVE_TRANSITION} <span>{showPhaseTextToMove(phaseIndex, transition, allPhasesData)}</span>
        </div>
    )
}