import React, { useEffect, useState } from 'react';
import { threedigitized } from '../../../../../tikit/ticketHelper';
import { useTranslation } from 'react-i18next';
import { RelateTicket } from '../../RelateTicketDialog';
import { getCommaSeparatedIds } from '../../RelateTicketHelper';

export interface Props {
  oldRelatedTicketIds: number[];
  relatedTicketData: RelateTicket;
}

export const RelateConfirmation = ({
  oldRelatedTicketIds,
  relatedTicketData
}: Props) => {
  const {t} = useTranslation();
  const [ticketsToBeRelated, setTicketsToBeRelated] = useState<number[]>([]);
  const [ticketsToBeRemoved, setTicketsToBeRemoved] = useState<number[]>([]);

  const sourceTicketId = threedigitized(relatedTicketData.sourceTicketId);

  useEffect(() => {
    const toBeRelated = relatedTicketData.relatedTicketIds.filter(
      (id: number) => !oldRelatedTicketIds.includes(id)
    );
    const toBeRemoved = oldRelatedTicketIds.filter(
      (id: number) => !relatedTicketData.relatedTicketIds.includes(id)
    );
    setTicketsToBeRelated(toBeRelated);
    setTicketsToBeRemoved(toBeRemoved);
  }, []);

  return (
    <>
      <div className="dialog-header font-bold">
        {t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.relate-configuration.header-confirmation')}
      </div>
      <div className="dialog-body pt-3 confirm-parent overflow-auto">
        {t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.relate-configuration.relate-result')}
        <ul className="confirmation-list">
          {ticketsToBeRelated.length > 0 && (
            <li>
              {t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.relate-configuration.ticket')}{' '}
              <span className="font-bold">
                {getCommaSeparatedIds(ticketsToBeRelated)}
              </span>{' '}
              {t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.relate-configuration.related-ticket')}{' '}
              <span className="font-bold">{sourceTicketId}</span>
            </li>
          )}
          {ticketsToBeRemoved.length > 0 && (
            <li>
              {t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.relate-configuration.ticket')}{' '}
              <span className="font-bold">
                {getCommaSeparatedIds(ticketsToBeRemoved)}
              </span>{' '}
              {t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.relate-configuration.unrelated-ticket')}{' '}
              <span className="font-bold">{sourceTicketId}</span>
            </li>
          )}
        </ul>
        <div className="pt-4 confirm-child">
          {t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.relate-configuration.note')}
        </div>
      </div>
    </>
  );
};
