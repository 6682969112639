import React, { useEffect, useRef, useReducer, useState, useCallback } from 'react';
import {
  Button,
  Flex,
  Text,
  Loader,
  Chat,
  SendIcon,
  Input,
  SearchIcon,
  Dropdown,
  Skeleton,
  Alert,
  Datepicker,
  DropdownProps,
  Tooltip,
  Divider,
  CalendarIcon,
  ProviderConsumer as FluentUIThemeConsumer,
  SvgIconSizeValue,
} from '@fluentui/react-northstar';
import { platformService } from '../../services/platform.service';
import AppCSS from './TicketConversation.module.css';
import _, { debounce } from 'lodash';
import {
  ActivityItemProps,
  AttachementProps,
  chatActivityItem,
  dateStatusMapping,
  resolveCommentDate,
  skeletonLoader,
  useActivity
} from './TicketActivity';
import { UserAvatar } from '../UserAvatar';
import { Link, mergeStyleSets, Separator, Stack, Toggle } from '@fluentui/react';
import { DateTime } from 'luxon';
import { EmptyData } from '../EmptyData';
import EmptySVG from '../../../../svg/empty.svg';
import { getCachedFeature } from '../../cache/FeatureCache';
import { LICENSE_FEATURE, markdownComponents, ACTIVITY_FIELD, ACTIVITY_ACTION, TicketApprovalState, WARNING_NOTE, STATUS_GUID, LIFECYCLE_STATUS_GUID } from '../../utils/constants';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { IRelatedTickets, RelationType } from '../EditTicketRelateTickets/RelateTicketHelper';
import { getUserData, getDateWithDay, threedigitized, filterPlatformUsersInRole, getStatusIdByGuid } from '../../../tikit/ticketHelper';
import { appState, AppStateContext, AppStateValue, useSetState } from '../../../AppState';
import { CheckFeature } from '../CheckFeature';
import { PlatformUser } from '../../interfaces/platformuser.interface';
import { TicketProps } from '../../interfaces/ticketproperties.interface';
import { LightningBoltSolidIcon, LockSolidIcon, PeopleIcon, StatusCircleInnerIcon } from '@fluentui/react-icons-mdl2';
import { CommonHelper } from '../../common/CommonHelper';
import { ConvertDateToLocale } from '../../common/ConvertDate';
import { useTranslation } from 'react-i18next';
import { DatepickerLocalizations } from '../../../App';
import SlateEditor, { slateToMd } from '../Wysiwyg/SlateEditor';
import { useDropzone } from 'react-dropzone';
import FileAttachments from './components/FileAttachments';
import { FileAlert } from './components/Alerts';
import { FileProps } from '../EditTicketAttachments/EditTicketAttachments';
import { Descendant } from 'slate';
import LockSVG from '../../../../svg/lock.svg';
import { getUser } from '../../../AuthConfig';
import remarkBreaks from 'remark-breaks';
import { AsyncQueue } from '../../common/AsyncQueue';
import { uploadService } from '../../services/upload.service';

const classNames = mergeStyleSets({
  userLink: {
    color: 'var(--mgt-theme-brand-foreground1)',
    ':hover, :active, :focus': {
      color: 'var(--mgt-theme-brand-foreground1) !important'
    }
  },
  TicketDetailHeader: {
    color: 'var(--mgt-theme-default-foreground2)',
  },
  MergedTicketDetail: {
    backgroundColor: 'var(--mgt-theme-brand-background1)'
  },
  editor: {
    '.DraftEditor-editorContainer': {
      overflowY: 'auto',
      maxHeight: '100px !important',
      display: 'flex',
      flexDirection: 'column-reverse'
    }
  }
});
const api = new platformService();
const uploadHelper = new uploadService();
const ACTIONS = {
  TYPING: 'typing',
  CHECK: 'check',
  SENDING: 'sending',
  CALL_API: 'call-api',
  SUCCESS: 'success',
  ERROR: 'error',
  EMPTY_MESSAGE: 'empty-message',
  SET_CONVERSATION: 'set-conversation',
  SAVING_STATE: 'saving-state',
  SAVING_SUCCESS: 'saving-success',
  SET_TICKET: 'set-ticket',
  SET_SIGNEDIN_USER: 'set-signedin-user',
  SENT: 'sent',
  COMMENTING_STATE_ON: 'commenting-state-on',
  COMMENTING_STATE_OFF: 'commenting-state-off',
  SET_IS_EMPTY: 'set-is-empty',
  SET_NOT_EMPTY: 'set-not-empty'
};

const commentsReducer = (t: any) => (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_IS_EMPTY:
      return {
        ...state,
        isMessageEmpty: true
      }
    case ACTIONS.SET_NOT_EMPTY:
      return {
        ...state,
        isMessageEmpty: false
      }
    case ACTIONS.TYPING:
      return {
        ...state,
        body: action.payload.body
      };
    case ACTIONS.CHECK:
      return {
        ...state,
        isPublic: !state.isPublic
      };
    case ACTIONS.CALL_API: {
      return {
        ...state,
        loading: true
      };
    }
    case ACTIONS.SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case ACTIONS.SENDING: {
      return {
        ...state,
        isSending: true
      };
    }
    case ACTIONS.SENT: {
      return {
        ...state,
        isSending: false
      };
    }
    case ACTIONS.EMPTY_MESSAGE: {
      return {
        ...state,
        body: [{
          children: [{ text: '' }],
        }],
        isPublic: true
      };
    }
    case ACTIONS.SET_CONVERSATION: {
      return {
        ...state,
        conversation: action.data
      };
    }
    case ACTIONS.COMMENTING_STATE_ON: {
      return {
        ...state,
        isCommenting: true
      }
    }
    case ACTIONS.COMMENTING_STATE_OFF: {
      return {
        ...state,
        isCommenting: false
      }
    }
    default:
      throw new Error(t('ticket-details.ticket-conversation.unexpected-action'));
  }
};

const updateTicket = async (
  ticketId: number,
  data: {
    StatusId?: number;
    PriorityId?: number;
    Assignee?: number;
    DueDate?: string;
  }
) => {
  try {
    return await api.updateTicket(ticketId, data);
  } catch (err) {
    return err;
  }
}

const createComment = async (ticketId: number, data: { Body: string; IsPublic: boolean }) => {
  try {
    const createCommentRes = await api.addCommentToTicket(ticketId, data);
    return createCommentRes.data;
  } catch (err) {
    return err;
  }
};

const referenceComment = async (ticketId: number, commentId: number) => {
  try {
    const referenceComment = await api.referenceComment(ticketId, commentId);
    return referenceComment.data;
  } catch (err) {
    return err;
  }
};

interface ConversationProps {
  isTicketMerged: boolean;
  isUserLicensed?: boolean | null;
  ticketId: number;
  assigneeId: number;
  teamId?: number | null;
  groupId?: number | null;
  requester: {
    Email: string;
  };
  signedInUser: SignedInUser;
  isEndUserPage: boolean;
  priority: number;
  status: number;
  dueDate: string | null;
  isDropzoneDisabled?: boolean;
  onTicketUpdate?: (data: any, date: Date) => void;
  isTicketClosed: boolean;
  resetCommentsCount: () => void;
  onSendComment?: (date: Date) => void;
  ticketData?: any;
  setIsUploading?: (data: boolean) => void;
  setUploadedFiles?: (data: FileProps[]) => void;
  uploadedFiles?: FileProps[];
  reloadConversation: boolean | null;
  setReloadConversation: React.Dispatch<React.SetStateAction<boolean>>;
  globalTheme: any;
  droppedIndex: number;
}

interface ApproversProps {
  Id: number;
  HasApproved: number;
  Approval: string;
  ModifiedDate? :string;
}

interface Approval {
  Id: number;
  Title: string;
}

interface CommentStateProps {
  body: any;
  isPublic: boolean;
  conversation: [];
  loading: boolean;
  isSending: boolean;
  isCommenting: boolean;
  isMessageEmpty: boolean;
}

const commentInitialState: CommentStateProps = {
  body: [{
    children: [{ text: '' }],
  },
  ],
  isPublic: true,
  conversation: [],
  loading: true,
  isSending: false,
  isCommenting: false,
  isMessageEmpty: true,
};

export const TicketConversation = (Props: ConversationProps) => {
  const { t } = useTranslation();
  const currentState = appState();
  const setState = useSetState();
  const chatcontainer = useRef<HTMLDivElement>(null);
  const [commentState, dispatch] = useReducer(commentsReducer(t), commentInitialState);
  const [_activityState, getTicketActivitiesById] = useActivity();
  const [pullingActivity, setPullingActivity] = useState(true);
  const [noScroll, setNoScroll] = useState(false);
  const [switchComment, setSwitchComment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterProp, setFilterProp] = useState('All');
  const [searchText, setSearchText] = useState('');
  const [filterIndex, setFilterIndex] = useState<number>(0);
  const [emptySearch, setEmptySearch] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [properties, setProperties] = useState([{ key: 'All', header: t("ticket-details.ticket-conversation.all") }]);
  const [sourceTicket, setSourceTicket] = useState<IRelatedTickets>(null);
  const [mergedBy, setMergedBy] = useState<string>('');
  const [isAutoFocused, setIsAutoFocused] = useState<boolean>(false);
  const [isLifecycleEnabled, setIsLifecycleEnabled] = useState<boolean>(false);
  const [droppedFiles, setDroppedFiles] = useState<any[]>([]);
  const droppedRef = useRef<File[]>();
  droppedRef.current = droppedFiles;
  const [droppedIndex, setDroppedIndex] = useState(1);
  const droppedIndexRef = useRef<number>();
  droppedIndexRef.current = droppedIndex;

  const [toUpdate, setToUpdate] = useState<EditTicketProps>({
    StatusId: Props.status,
    AssigneeId: Props.assigneeId,
    DueDate: Props.dueDate,
    PriorityId: Props.priority,
  })

  const [currentTicketProps, setCurrentTicketProps] = useState<EditTicketProps>({
    StatusId: Props.status,
    AssigneeId: Props.assigneeId,
    DueDate: Props.dueDate,
    PriorityId: Props.priority
  })

  const updateFile = (index: number, progress: number) => {
    droppedFiles[index].isInProgress = (progress < 100);
    droppedFiles[index].progressPercent = progress;
    droppedFiles[index].isUploading = (progress < 100);

    setDroppedFiles((items) => {
      return items.map((item, idx) => {
        if (idx === index) {
          item.isInProgress = (progress < 100);
          item.progressPercent = progress;
          item.isUploading = (progress < 100);
        }
        return item;
      });
    });
  }
  const uploadFilesComment = async (message: string, files: File[], isPrivate: boolean) => {
    const jobs = [];
    const queue = AsyncQueue(2);

    files.forEach((file, idx) => jobs.push(queue.add(async () => {
      // Get the Upload URL
      const upload = await api.getUploadUrl(Props.ticketId, file.name, file.size);
      updateFile(idx, 0);
      
      // Upload the File
      const url = upload.data;
      const response = await uploadHelper.uploadFile(url.uploadUrl, file, (u, t) => updateFile(idx, (u*1.0/t) * 100));
      
      // Set File Complete
      updateFile(idx, 100);
      response.data.groupId = url.groupId;

      return response;
    })));
    
    const results = await Promise.allSettled(jobs);

    const filesToAttach = results
      .map(file => (file.status == "fulfilled" && file.value.data.id) ? { FileName: file.value.data.name, FileSize: file.value.data.size, DriveItemId: file.value.data.id, DriveItemGroupId: file.value.data.groupId, DownladUrl: file.value.data.webUrl } : null)
      .filter(x => x !== null);
    const uploadedFiles: FileProps[] = await api.attachTicketFiles(Props.ticketId, filesToAttach, message, isPrivate, Props.isEndUserPage, false);
    return uploadedFiles;
  }

  const sendComment = (isPrivate?: boolean) => {
    dispatch({ type: ACTIONS.SENDING });
    const mdfied = slateToMd(commentState.body).replace(/\n/gi, "&nbsp; \n");
    const currentdate = new Date();
    if (droppedFiles.length > 0) {
      uploadFilesComment(mdfied, droppedFiles, isPrivate).then((data) => {
        if (Props.setUploadedFiles)
          Props.setUploadedFiles(data);
        
        setState(s => ({
          ...s,
          uploadingState: {
            ...s.uploadingState,
            uploadedFiles: [...data, ...s.uploadingState.uploadedFiles]
          }
        }));
      }).then(() => {
        setDroppedFiles([]);
        updateTicketByComment(currentdate, commentState.conversation.length)
      }).then(() => {
        Props.setReloadConversation(true);
        dispatch({ type: ACTIONS.EMPTY_MESSAGE });
        dispatch({ type: ACTIONS.SET_IS_EMPTY });
        dispatch({ type: ACTIONS.COMMENTING_STATE_OFF });
        dispatch({ type: ACTIONS.SENT });
      })
    } else if (!commentState.isMessageEmpty) {
      createComment(Props.ticketId, { Body: mdfied, IsPublic: !isPrivate }).then(res => {
        dispatch({ type: ACTIONS.EMPTY_MESSAGE }); 
        let currentMessage = {
          contentPosition: 'end',
          gutter: (
            <UserAvatar
              {...{
                idOrUpn: Props.signedInUser.Email,
                avatarProps: { name: Props.signedInUser.FullName, size: 'smaller' }
              }}
            />
          ),
          message: {
            className: 'w-5/6',
            content: (<Flex className="w-full items-center">
              <Flex column className={`w-full`}>
                <Flex className='items-center justify-end'>
                  <Chat.Message
                    content={<Markdown remarkPlugins={[remarkGfm, remarkBreaks]} components={markdownComponents}>{mdfied}</Markdown>}
                    author={Props.signedInUser.FullName}
                    header={
                      <Text
                        size='small'
                        timestamp
                        color='grey'
                        content={
                          <div className='flex items-center'>
                            <span className='leading-5 pr-2'>
                              {resolveCommentDate(currentdate.toString())}
                            </span>
                            { isPrivate && 
                              <LockSolidIcon className='text-private'/>
                            }                        
                          </div>
                        }
                      />
                    }
                    mine
                    style={{ minWidth: '170px', marginLeft: '16px' }}
                  />
                </Flex>
              </Flex>
            </Flex>)
          },
          key: `message-id-${res.Id}`
        };
        return { message: currentMessage, id: res.Id };
      }).then((currentMessage) => {
        appendActivityAndConvo(currentMessage, currentdate);
      }).then(() => {
        Props.setReloadConversation(true)
        dispatch({ type: ACTIONS.EMPTY_MESSAGE });
        dispatch({ type: ACTIONS.SET_IS_EMPTY });
        dispatch({ type: ACTIONS.SENT });
        dispatch({ type: ACTIONS.COMMENTING_STATE_OFF });
      });
    }
  };

  const sendInternalNote = useCallback(() => sendComment(true), [sendComment]);
  const sendCommentCallback = useCallback(() => { sendComment(); }, [sendComment]);

  const appendActivityAndConvo = (
    currentMessage: {
      message: {
        contentPosition?: string;
        gutter?: JSX.Element;
        message: {
          className: string;
          content: JSX.Element;
        }
        key?: string;
      };
      id: any;
    },
    commentTime: Date
  ) => {
    if (toUpdate.AssigneeId === currentTicketProps.AssigneeId && toUpdate.DueDate == currentTicketProps.DueDate && toUpdate.PriorityId === currentTicketProps.PriorityId && toUpdate.StatusId === currentTicketProps.StatusId) {
      dispatch({ type: ACTIONS.SET_CONVERSATION, data: [...commentState.conversation, currentMessage.message] });
      dispatch({ type: ACTIONS.SENT });
      dispatch({ type: ACTIONS.COMMENTING_STATE_OFF });
      if (Props.onSendComment) Props.onSendComment(commentTime);
      if (Props.resetCommentsCount) Props.resetCommentsCount();
      chatcontainer.current.scrollTop = chatcontainer?.current?.scrollHeight;
    }
    else {
      updateTicketByComment(commentTime, parseInt(currentMessage.id) + 1, currentMessage.message)
    }
  }

  const updateTicketByComment = async (commentTime: Date, currentId: number, message?: any) => {
    let updatesActivity = [];

    updateTicket(Props.ticketId, toUpdate).then(async (res: any) => {
      if (res.status === 204) {
        if (Props.onTicketUpdate) Props.onTicketUpdate(toUpdate, new Date(Date.now()));
        const data = JSON.parse(res.config.data);
        if (toUpdate.DueDate != currentTicketProps.DueDate) {
          let activity = {
            Action: "changed",
            Field: "DueDate",
            Grouping: "Today",
            Id: currentId++,
            ModifiedById: Props.signedInUser.Id,
            ModifiedDate: (new Date()).toLocaleDateString(),
            ModifierAvatar: null,
            ModifierInfo: `{\"Name\":\"${Props.signedInUser.FullName}\",\"Id\":${Props.signedInUser.Id},\"Email\":\"${Props.signedInUser.Email}\"}`,
            TicketId: Props.ticketId,
            Value: (new Date(data.DueDate)).toLocaleDateString(),
          }
          updatesActivity.push(chatActivityItem(activity, "end", Props.signedInUser, Props.isEndUserPage, currentState.teamsConfig, Props.globalTheme));
        }
        if (toUpdate.AssigneeId !== currentTicketProps.AssigneeId) {
          let newAssignee = currentState.platformusers.find(pu => pu.Id === data.AssigneeId);

          let activity = {
            Action: "assigned",
            Field: "Assignee",
            Grouping: "Today",
            Id: currentId++,
            ModifiedById: Props.signedInUser.Id,
            ModifiedDate: (new Date()).toISOString(),
            ModifierAvatar: null,
            ModifierInfo: `{\"Name\":\"${Props.signedInUser.FullName}\",\"Id\":${Props.signedInUser.Id},\"Email\":\"${Props.signedInUser.Email}\"}`,
            TicketId: Props.ticketId,
            Value: JSON.stringify({
              Name: newAssignee?.FullName ?? 'Unassigned',
              Id: newAssignee?.Id ?? 0,
              Email: newAssignee?.Email
            }),
          };
          updatesActivity.push(chatActivityItem(activity, "end", Props.signedInUser, Props.isEndUserPage, currentState.teamsConfig, Props.globalTheme));
        }
        if (toUpdate.PriorityId !== currentTicketProps.PriorityId) {
          let activity = {
            Action: "changed",
            Field: "Priority",
            Grouping: "Today",
            Id: currentId++,
            ModifiedById: Props.signedInUser.Id,
            ModifiedDate: (new Date()).toISOString(),
            ModifierAvatar: null,
            ModifierInfo: `{\"Name\":\"${Props.signedInUser.FullName}\",\"Id\":${Props.signedInUser.Id},\"Email\":\"${Props.signedInUser.Email}\"}`,
            TicketId: Props.ticketId,
            Value: currentState.priority.find(priority => priority.Id === data.PriorityId).Value,
          }
          updatesActivity.push(chatActivityItem(activity, "end", Props.signedInUser, Props.isEndUserPage, currentState.teamsConfig, Props.globalTheme));
        }
        if (toUpdate.StatusId !== currentTicketProps.StatusId) {
          let activity = {
            Action: "changed",
            Field: "Status",
            Grouping: "Today",
            Id: currentId++,
            ModifiedById: Props.signedInUser.Id,
            ModifiedDate: (new Date()).toISOString(),
            ModifierAvatar: null,
            ModifierInfo: `{\"Name\":\"${Props.signedInUser.FullName}\",\"Id\":${Props.signedInUser.Id},\"Email\":\"${Props.signedInUser.Email}\"}`,
            TicketId: Props.ticketId,
            Value: currentState.ticketStatus.find(status => status.Id === data.StatusId).Value,
          }
          updatesActivity.push(chatActivityItem(activity, "end", Props.signedInUser, Props.isEndUserPage, currentState.teamsConfig, Props.globalTheme));
        }
        if (message) {
          dispatch({ type: ACTIONS.SET_CONVERSATION, data: [...commentState.conversation, message, ...updatesActivity] });
          dispatch({ type: ACTIONS.SENT });
        }
      } else {
        if (Props.onSendComment) Props.onSendComment(commentTime);
      }
      if (message) {
        dispatch({ type: ACTIONS.COMMENTING_STATE_OFF });
      }
      if (Props.resetCommentsCount) Props.resetCommentsCount();
      chatcontainer.current.scrollTop = chatcontainer?.current?.scrollHeight;
      
      setCurrentTicketProps(toUpdate)
    });
  }

  const onScrollLoadMoreActivity = () => {
    if (!pullingActivity || loading) {
      if (chatcontainer.current.scrollTop <= chatcontainer.current.scrollHeight * 0.01 && !noScroll) {
        chatcontainer.current.scrollTop = chatcontainer.current.scrollTop + 50;
      }
      return;
    }
    if (chatcontainer.current.scrollTop <= chatcontainer.current.scrollHeight * 0.15) {
      setPullingActivity(false);
      renderActivityData(filterCompose(), true);
    }
  };

  function onChangeHideHistory(_ev: React.MouseEvent<HTMLElement>, _checked?: boolean) {
    setSwitchComment(!switchComment);
    setLoading(true);
    setNoScroll(false);

    dispatch({ type: ACTIONS.SET_CONVERSATION, data: [] });
    dispatch({ type: ACTIONS.SUCCESS });
  }

  const onChangeHideHistoryCallbackWrapper = useCallback((_ev: React.MouseEvent<HTMLElement>, _checked?: boolean) => {
    onChangeHideHistory(_ev, _checked);
  }, [onChangeHideHistory]);

  const renderActivityData = debounce(async (filter: string = '', isTrigger: boolean = false) => {

    const isRelatedTicketEnabled = await getCachedFeature(LICENSE_FEATURE.RelatedTickets);
    const results = await Promise.all([
      getTicketActivitiesById(Props.ticketId, switchComment, filter, isTrigger),
      isRelatedTicketEnabled ? api.getRelatedTicketsIncludeDeleted(Props.ticketId) : []
    ]);

    let messages = [];
    let { activities, endOfList } = results[0];
    const relatedTickets: IRelatedTickets[] = results[1];

    setEmptySearch(false);

    const filteredActivitites = filterActivities(activities);
    const allActivitites = arrangeActivities(filteredActivitites, relatedTickets);
    const groupActivities = _.chain(allActivitites).groupBy('Grouping').value();

    _.map(groupActivities, (activityItems: ActivityItemProps[]): any => {

      if (activityItems[0].Grouping !== '') messages.push(getDateSeprator(activityItems[0]));

      activityItems.forEach(activity => {
        const activityValue = getActivityValue(activity);
        let position = Props.signedInUser.Id == activity.ModifiedById && activityValue.CreatedById != null ? 'end' : 'start';
        let attachment = CommonHelper.toObject<AttachementProps>(activity.Value);
        if (!(activity.Field == ACTIVITY_FIELD.FILE_ATTACHMENT && (attachment.hasOwnProperty("Comment") || attachment.HasComment)))
          messages.push(chatActivityItem(activity, position, Props.signedInUser, Props.isEndUserPage, currentState.teamsConfig, Props.globalTheme));
      });
    });

    if (allActivitites.length === 0) setEmptySearch(true);

    if (endOfList) {
      setNoScroll(true);
      setPullingActivity(false);
    }
    else {
      setPullingActivity(true);
      if (switchComment) {
        messages.unshift(skeletonLoader('600px').comment);
        messages.unshift(skeletonLoader('400px').comment);
      } else {
        messages.unshift(skeletonLoader().comment);
        messages.unshift(skeletonLoader().activity);
      }
    }
    dispatch({ type: ACTIONS.SET_CONVERSATION, data: messages });
    dispatch({ type: ACTIONS.SUCCESS });

    if (!isTrigger && chatcontainer.current) chatcontainer.current.scrollTop = chatcontainer.current.scrollHeight;

    setLoading(false);

  }, 500);

  const getActivityValue = (activity: ActivityItemProps) => {
    let activityValue: any;
    const fieldsWithNoCreatedById = [
      ACTIVITY_FIELD.RELATE_TICKET,
      ACTIVITY_FIELD.CARD_ANSWER,
      ACTIVITY_FIELD.ASSIGNEE,
      ACTIVITY_FIELD.REQUESTER,
      ACTIVITY_FIELD.RESOLUTATION_DATE,
      ACTIVITY_FIELD.DUE_DATE,
      ACTIVITY_FIELD.FILE_ATTACHMENT,
      ACTIVITY_FIELD.TICKET_COLLABORATORS,
      ACTIVITY_FIELD.AFFECTED_USERS,
      ACTIVITY_FIELD.TICKET_TASKS,
      ACTIVITY_FIELD.TICKET_APPROVAL
    ];
    try {
      activityValue = JSON.parse(activity.Value || '{}');
      if (fieldsWithNoCreatedById.includes(activity.Field)) activityValue.CreatedById = 0;
    } catch {
      activityValue = { CreatedById: 0 };
    }
    return activityValue;
  }

  const getDateSeprator = (activityItem: ActivityItemProps) => {
    const style = { backgroundColor: 'var(--mgt-theme-default-foreground2)', opacity: 0.1, height: 1 };
    const sepratorOptions = {
      root: { zIndex: 1, ...{ '::before': style }, ...{ '::after': style } },
      content: { backgroundColor: 'var(--mgt-theme-background2)', padding: '5px 5px 5px 0', fontWeight: '600' }
    };

    return {
      children: (
        <Stack style={{ width: '100%', zIndex: 1 }}>
          <Separator alignContent="center" styles={{ ...sepratorOptions }}>
            <span style={{ color: 'var(--mgt-theme-default-foreground)', padding: '0 15px', opacity: 0.8, fontSize: 12 }}>
              {activityItem.Grouping}
            </span>
          </Separator>
        </Stack>
      ),
      key: `group-id-${activityItem.Id}`
    }
  }

  const filterActivities = (activityItems: ActivityItemProps[]) => {
    if (!activityItems) return [];
    const hideActivityFromFields = [ACTIVITY_FIELD.CARD_TEMPLATE, ACTIVITY_FIELD.IS_MERGED];
    const endUserRequestActivityFromFields = [
      ACTIVITY_FIELD.INIT,
      ACTIVITY_FIELD.ASSIGNEE,
      ACTIVITY_FIELD.STATUS,
      ACTIVITY_FIELD.FILE_ATTACHMENT,
      ACTIVITY_FIELD.CARD_ANSWER,
      ACTIVITY_FIELD.RESOLUTATION_DATE,
      ACTIVITY_FIELD.COMMENTS,
      ACTIVITY_FIELD.TICKET_COLLABORATORS,
      ACTIVITY_FIELD.AFFECTED_USERS
    ];
    return activityItems.filter(activity => ((!Props.isEndUserPage && !hideActivityFromFields.includes(activity.Field)) || (Props.isEndUserPage && endUserRequestActivityFromFields.includes(activity.Field))) &&
      (activity.Field != ACTIVITY_FIELD.COMMENTS || !Props.isEndUserPage || (activity.Field === ACTIVITY_FIELD.COMMENTS && Props.isEndUserPage && JSON.parse(activity.Value || '{}').IsPublic)));
  }

  const arrangeActivities = (activityItems: ActivityItemProps[], relatedTickets: IRelatedTickets[]) => {
    const activities: ActivityItemProps[] = [];
    let relatedTicketKey = "";
    activityItems.forEach(activity => {
      activity.Grouping = dateStatusMapping(DateTime.fromJSDate(new Date(activity.ModifiedDate)));
      if (activity.Field === ACTIVITY_FIELD.RELATE_TICKET) {
        const filteredRelatedTickets = getFilteredRelatedTickets(relatedTickets, activity);
        if (filteredRelatedTickets.length > 0) {
          const relatedTicketIds = '#' + [...filteredRelatedTickets].sort((a, b) => (a.RelatedTicketId - b.RelatedTicketId)).map(t => threedigitized(t.RelatedTicketId)).join(', #');
          const relationType = getRelateAction(activity.Action, filteredRelatedTickets[0]?.RelationshipTypeId);
          const uniqueKey = relatedTicketIds + activity.ModifiedById + activity.Action + relationType;
          if (relatedTicketKey !== uniqueKey && checkFilter(relatedTicketIds, relationType)) {
            relatedTicketKey = uniqueKey;
            activity.Action = relationType;
            activity.RelatedTicketIds = relatedTicketIds;
            activities.push(activity);
          }
        }
      }
      else if ((activity.Field == ACTIVITY_FIELD.APPROVERS || activity.Field == ACTIVITY_FIELD.APPROVAL) && activity.Action == ACTIVITY_ACTION.CHANGED) {
        if(activity.Field == ACTIVITY_FIELD.APPROVERS){
          activity.Action = getApproverAction(activity.Value);
          activity.Value = getApprovalTitle(activity.Value);

          let isDuplicatedEntry = false;
          const prevActivity = activities[activities.length - 1];
          if (prevActivity) {
            if (activity.Action === ACTIVITY_ACTION.RESET_VOTES_FOR && prevActivity.Action === ACTIVITY_ACTION.RESET_VOTES_FOR) {
              let approvalData = CommonHelper.toObject<ApproversProps>(activity.Value);
              let prevApprovalData = CommonHelper.toObject<ApproversProps>(activities[activities.length - 1].Value);
              const thisApprovalTitle = CommonHelper.toObject<Approval>(approvalData.Approval);
              let prevApprovalTitle = CommonHelper.toObject<Approval>(prevApprovalData.Approval);
              if (thisApprovalTitle.Title === prevApprovalTitle.Title && getTimeDifference(approvalData.ModifiedDate, prevApprovalData.ModifiedDate) <= 2.0) {
                isDuplicatedEntry = true;
              }
            }
          }
          if (!isDuplicatedEntry) {
            activities.push(activity);
          }
        }
      }
      else {
        activities.push(activity);
      }
    });
    return activities;
  }

  const getRelateAction = (action: string, reationType: number) => {
    if (action === ACTIVITY_ACTION.CREATED && reationType === RelationType.MergeTickets)
      return ACTIVITY_ACTION.MERGED;
    else if (action === ACTIVITY_ACTION.CREATED && reationType === RelationType.RelateTickets)
      return ACTIVITY_ACTION.RELATED;
    else if (action === ACTIVITY_ACTION.CHANGED && reationType === RelationType.RelateTickets)
      return ACTIVITY_ACTION.UNRELATED;
    else return "";
  }

  const getApprovalTitle = (value: string) => {
    if (value != null || value != "") {
      let approverData = CommonHelper.toObject<ApproversProps>(value);
      let approvalData = CommonHelper.toObject<Approval>(approverData.Approval);
  
      if (approverData.HasApproved === TicketApprovalState.Pending) 
        return value;
      else
        return approvalData?.Title ?? "";
    }
    else {
      return value;
    }
  }

  const getApproverAction = (value: string) => {
    if (value != null || value != "") {
      let approverData = CommonHelper.toObject<ApproversProps>(value);
      if (approverData.HasApproved == TicketApprovalState.Approved) return "approved";
      else if (approverData.HasApproved == TicketApprovalState.Rejected) return "declined";
      else if (approverData.HasApproved == TicketApprovalState.Pending) return ACTIVITY_ACTION.RESET_VOTES_FOR;
      else return "";
    }
    else return value;
  }

  const getFilteredRelatedTickets = (relatedTickets: IRelatedTickets[], activity: ActivityItemProps) => {
    switch (activity.Action) {
      case ACTIVITY_ACTION.CHANGED:
        return relatedTickets.filter(t => t.IsDeleted && t.ModifiedById === activity.ModifiedById && getTimeDifference(activity.ModifiedDate, t.ModifiedDate) <= 2.0);
      case ACTIVITY_ACTION.CREATED:
        return relatedTickets.filter(t => t.CreatedById === activity.ModifiedById && getTimeDifference(activity.ModifiedDate, t.CreatedDate) <= 2.0);
      default: return [] as IRelatedTickets[];
    }
  }

  const commentsDisabled = () => commentState.isSending || (commentState.isMessageEmpty && droppedFiles.length === 0) || Props.isTicketClosed;

  const getUserName = (createdById: number) => getUserData(createdById, currentState)?.FullName;
  const getTimeDifference = (date1: string, date2: string) => Math.abs((new Date(date1).getTime() - new Date(date2).getTime()) / 1000);
  const checkFilter = (relatedTicketIds: string, relationType: string) =>
    filterProp === 'All' && (searchText === '' || relatedTicketIds.includes(searchText) || relationType.includes(searchText.toLowerCase()));

  const filterCompose = () => encodeURI(`Property eq ${filterProp.removeWhiteSpaces()},NewValue eq ${searchText}`);

  const searchActivityHandler = () => {
    setEmptySearch(false);
    renderActivityData(filterCompose());
    setLoading(true);

    dispatch({ type: ACTIONS.SET_CONVERSATION, data: [] });
    dispatch({ type: ACTIONS.SUCCESS });
  };

  useEffect(() => {
    setLoading(true);
    dispatch({ type: ACTIONS.CALL_API });
    dispatch({ type: ACTIONS.SET_CONVERSATION, data: [] });
    if (Props.ticketId && Props.signedInUser) renderActivityData();
  }, [Props.ticketId, Props.signedInUser.Id]);

  useEffect(() => {
    const filter = (!switchComment) ? filterCompose() : encodeURI("Property eq All,NewValue eq ");
    setTimeout(() => renderActivityData(filter), 300);
  }, [switchComment]);

  useEffect(() => {
    if (Props.reloadConversation) {
      const filter = (!switchComment) ? filterCompose() : encodeURI("Property eq All,NewValue eq ");
      setTimeout(() => renderActivityData(filter), 300);
      Props.setReloadConversation(oldValue => { return false; });
    }
  }, [Props.reloadConversation]);

  useEffect(() => { 
    setDroppedIndex(Props.droppedIndex) 
  }, []);

  useEffect(() => {
    setTimeout(() => searchActivityHandler(), 300);
  }, [filterProp, searchText]);

  useEffect(() => {
    (async () => {
      const featureResult = await Promise.all([
        getCachedFeature(LICENSE_FEATURE.TicketTypes),
        getCachedFeature(LICENSE_FEATURE.MultiDepartment),
        getCachedFeature(LICENSE_FEATURE.TicketLifecycle),
        getCachedFeature(LICENSE_FEATURE.TicketTasks),
        getCachedFeature(LICENSE_FEATURE.Approvals),
        getCachedFeature(LICENSE_FEATURE.TicketTags),
        getCachedFeature(LICENSE_FEATURE.AffectedUsers),
        getCachedFeature(LICENSE_FEATURE.Collaborators)
      ]);
      const enabled = featureResult[0];
      const enabledMultiDepartment = featureResult[1];
      const enabledTicketLifecycle = featureResult[2];
      setIsLifecycleEnabled(enabledTicketLifecycle);
      const enabledTicketTasks = featureResult[3];
      const enabledApprovals = featureResult[4];
      const enabledTicketTags = featureResult[5];
      const enabledUsers = featureResult[6];
      const enabledCollabs = featureResult[7];
      let searchers = [
        { key: 'Status', header: t('ticket-details.edit-ticket-view.filter.status') },
        { key: 'Ticket Type', header: t('ticket-details.edit-ticket-view.filter.type') },
        { key: 'Category', header: t('ticket-details.edit-ticket-view.filter.category') },
        { key: 'Priority', header: t('ticket-details.edit-ticket-view.filter.priority') },
        { key: 'Requester', header: t('ticket-details.edit-ticket-view.filter.requester') },
        { key: 'Assignee', header: t('ticket-details.edit-ticket-view.filter.assignee') },
        { key: 'DueDate', header: t('ticket-details.edit-ticket-view.filter.due-date') },
        { key: 'Team', header: t('ticket-details.edit-ticket-view.filter.team') },
        { key: 'Group', header: t('ticket-details.edit-ticket-view.filter.group') },
        { key: 'Tags', header: t('ticket-details.edit-ticket-view.filter.tags') },
        { key: 'Attachments', header: t('ticket-details.edit-ticket-view.filter.attachments') },
        { key: 'Tasks', header: t('ticket-details.edit-ticket-view.filter.tasks') },
        { key: 'Approval', header: t('ticket-details.edit-ticket-view.filter.approval') },
        { key: 'Lifecycle', header: t('ticket-details.edit-ticket-view.filter.lifecycle') },
        { key: 'AffectedUsers', header: t('ticket-details.edit-ticket-view.filter.affected-users') },
        { key: 'TicketCollaborators', header: t('ticket-details.edit-ticket-view.filter.collaborators') }
      ];
      let excludeCols = [];
      if (!enabled) excludeCols.push("Ticket Type");
      if (!enabledMultiDepartment) excludeCols.push("Team");
      if (!enabledTicketLifecycle) excludeCols.push("Lifecycle");
      if (!enabledTicketTasks) excludeCols.push("Tasks");
      if (!enabledApprovals) excludeCols.push("Approval");
      if (!enabledTicketTags) excludeCols.push("Tags");
      if (!enabledUsers) excludeCols.push("AffectedUsers");
      if (!enabledCollabs) excludeCols.push("TicketCollaborators");

      if (excludeCols.length > 0)
        searchers = searchers.filter(x => !excludeCols.includes(x.header));

      searchers.sort((a, b) => a.header.localeCompare(b.header));
      searchers.splice(0, 0, { key: 'All', header: t('ticket-details.edit-ticket-view.filter.all') });

      setProperties(searchers);
    })();
  }, []);

  const [isShowFileAlert, setIsShownFileAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const { getRootProps, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDropAccepted: (acceptedFiles: File[]) => {
      if(!commentState.isSending) {
        let filteredFiles: File[] = []
        if ((acceptedFiles.length + droppedFiles.length) <= 10) {
          acceptedFiles.forEach((acceptedFile: File) => {
            if (droppedFiles.some(f => f.name == acceptedFile.name && f.size == acceptedFile.size)) {
              setIsShownFileAlert(true)
              setAlertMessage(t("ticket-details.ticket-conversation.file-already-attached-error"))
              setTimeout(() => {
                setIsShownFileAlert(false)
                setAlertMessage('')
              }, 3000)
            } else {
              filteredFiles.push(acceptedFile)
            }
          });

          setDroppedFiles((prev) => ([
            ...prev, ...filteredFiles
          ]));
        } else {
          setIsShownFileAlert(true)
          setAlertMessage(t("ticket-details.ticket-conversation.number-of-files-on-upload-error"))
          setTimeout(() => {
            setIsShownFileAlert(false)
            setAlertMessage('')
          }, 3000)
        }
        dispatch({ type: ACTIONS.COMMENTING_STATE_ON });
      }
    },
    disabled: Props.isDropzoneDisabled
  });

  useEffect(() => {
    if (Props.isTicketMerged) setMergedSourceTicket();
  }, [Props.ticketId, Props.isTicketMerged]);

  useEffect(() => {
    if (Props.isTicketClosed) dispatch({ type: ACTIONS.COMMENTING_STATE_OFF });
  }, [Props.isTicketClosed]);

  const setMergedSourceTicket = async () => {
    setMergedBy('');
    setMergedByUser(null);
    const ticket: IRelatedTickets = await api.getMergedSourceTicket(Props.ticketId);
    if (ticket) {
      setSourceTicket(ticket);
      setMergedByUser(ticket.CreatedById);
    }
  }

  const setMergedByUser = async (createdById: number) => {
    if (currentState.platformusers.length > 0) {
      const userName = getUserName(createdById);
      setMergedBy(userName);
    }
    else {
      if (createdById) {
        const platformUser = await api.getPlatformUserByFilter(`Id eq ${createdById}`);
        setMergedBy(platformUser.data.value[0]?.FullName ?? '');
      }
    }
  }

  const searchTimeout=useRef(null);
  const onSearch = (e: any, v) => {
     if (e.currentTarget.value) setIsAutoFocused(true);
     setSearchQuery(v.value);
     //Used setTimout instead of debounce becuase if you keep on typing, debounce will be triggered when the delay you set is met.
     clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(async ()=>{
      setSearchText(v.value);
    },500);
  };

  const handleSearchClick = (_e: any) => setSearchText(searchQuery);

  const handleSearchClickCallbackWrapper = useCallback((_e: any) => {
    handleSearchClick(_e);
  }, [handleSearchClick]);

  const onChangefilterIndex = (_event, props) => {
    let active = typeof props.highlightedIndex == 'undefined' ? 0 : props.highlightedIndex;
    setFilterIndex(active);
    let header = (props.value).key;
    if (header == "Attachments") setFilterProp("FileAttachments");
    else if (header == "Groups") setFilterProp("SupportGroup");
    else setFilterProp(header);
  }

  const onChangefilterIndexCallbackWrapper = useCallback((_event, props) => {
    onChangefilterIndex(_event, props);
  }, [onChangefilterIndex]);

  const ToolbarActivity = () => {
    return (
      <FluentUIThemeConsumer
        render={globalTheme => (
          <>
            {!Props.isEndUserPage && (
              <div style={{ margin: '0.75rem 0' }} className="flex justify-between">
                <div className="hidden md:block">
                  <Flex.Item>
                    <Toggle
                      disabled={loading}
                      label={<Text content={t("ticket-details.ticket-conversation.only-comments")} weight={'regular'} style={{ color: globalTheme.siteVariables.colorScheme.default.foreground }} />}
                      {...(switchComment && {
                        defaultChecked: true,
                        style: {
                          background: 'var(--mgt-theme-brand-foreground1)',
                          opacity: loading ? 0.5 : 1
                        }
                      })}
                      inlineLabel
                      onChange={onChangeHideHistoryCallbackWrapper}
                    />
                  </Flex.Item>
                </div>
                <Flex.Item>
                  <Flex gap="gap.smaller" className="md:w-2/3 w-full" style={{ height: 20, marginTop:4 }}>
                    <div className="hidden md:block">
                      {!switchComment && (
                        <Dropdown
                          items={properties}
                          triggerButton={
                            <span>{properties[filterIndex].header}</span>
                          }
                          fluid
                          disabled={loading}
                          defaultValue={filterIndex}
                          onChange={onChangefilterIndexCallbackWrapper}
                          style={{
                            zIndex: 2,
                            marginTop: -5,
                            marginRight: 2,
                            backgroundColor: globalTheme.siteVariables.colorScheme.default.background
                          }}
                        />
                      )}
                    </div>
                    <Input
                      placeholder={t("ticket-details.ticket-conversation.search-placeholder")}
                      icon={
                        <Button
                          text
                          content={<SearchIcon color="brand" />}
                          style={{ minWidth: 0, padding: 0 }}
                          onClick={handleSearchClickCallbackWrapper}
                        />
                      }
                      autoFocus={isAutoFocused}
                      onBlur={()=>{
                        setIsAutoFocused(false);
                      }}
                      inverted
                      onChange={onSearch}
                      defaultValue={searchQuery}
                      fluid
                      style={{ marginTop: 1 }}
                      disabled={loading}
                    />
                  </Flex>
                </Flex.Item>
              </div>
            )}
            {Props.isEndUserPage && (
              <div style={{ margin: '0.75rem 0' }} className="flex justify-between">
                <div className="hidden md:block">
                  <Flex.Item>
                    <></>
                  </Flex.Item>
                </div>
                <Flex.Item>
                  <Flex gap="gap.smaller" className="md:w-1/2 w-full" style={{ height: 20 }}>
                    <Input
                      placeholder={t("ticket-details.ticket-conversation.search-placeholder")}
                      icon={
                        <Button
                          text
                          content={<SearchIcon color="brand" />}
                          style={{ minWidth: 0, padding: 0 }}
                          onClick={handleSearchClickCallbackWrapper}
                        />
                      }
                      autoFocus={isAutoFocused}
                      inverted
                      onChange={onSearch}
                      defaultValue={searchQuery}
                      fluid
                      style={{ marginTop: 1 }}
                      disabled={loading}
                    />
                  </Flex>
                </Flex.Item>
              </div>
            )}
          </>
        )} />
    );
  };

  const getMergedTicketDetails = () => {
    const redirectPage = Props.isEndUserPage ? "requests" : "tickets";
    return <>
      <CheckFeature featureName={LICENSE_FEATURE.RelatedTickets}>
        <div className={`my-2 py-4 px-5 rounded-md w-full h-85 ${classNames.MergedTicketDetail}`}>
          {sourceTicket && mergedBy ? <>
            <div className={`font-bold ${classNames.TicketDetailHeader}`}>{t("ticket-details.ticket-conversation.merge-with-ticket")}&nbsp;
              <Link className={`${classNames.userLink} font-bold`} target="_blank"
                href={`${window.location.origin}/${redirectPage}/${sourceTicket.SourceTicketId}`}>
                #{threedigitized(sourceTicket.SourceTicketId)}
              </Link>
            </div>
            <div className="text-xs">
              <span className={`text-gray-400`}>{t("ticket-details.ticket-conversation.merged-by")}&nbsp;</span>
              <span>{mergedBy}&nbsp;</span>
              <span className={`text-gray-400`}>{t("ticket-details.ticket-conversation.on")}&nbsp;</span>
              <span>{getDateWithDay(sourceTicket.CreatedDate)}</span>
              <div>{t("ticket-details.ticket-conversation.all-attachments-and-comments-moved")}&nbsp;
                <span className={`font-bold ${classNames.TicketDetailHeader}`}>
                  #{threedigitized(sourceTicket.SourceTicketId)}
                </span>
              </div>
            </div>
          </> : <>
            <Skeleton className='mt-2' animation="pulse">
              <Flex gap='gap.small' column>
                <Skeleton.Line width="25%" />
                <Skeleton.Line width="40%" />
              </Flex>
            </Skeleton>
          </>}
        </div>
      </CheckFeature>
    </>;
  }

  const getTextBoxClass = (isCommenting: boolean) => {
    return isCommenting ? 'hidden' : '';
  }

  const getCursorClass = (body: string) => {
    return body === "" ? 'cursor-not-allowed' : '';
  }

  const getCommentBackgroundColor = (colorScheme: any) => {
    return Props.isTicketClosed ? colorScheme.default.backgroundDisabled : colorScheme.default.background;
  }

  const ref = useRef(null);
  const fakeBox = useRef(null);

  function handleClickOutside(event) {
    //if its dropdown then we can ignore this so search filter dropdown can be clickable
    if (event.target.matches('button.ui-button') || event.target.tagName == 'svg') {
      return;
    }

    //if its searchbox then we can ignore this so search box can be clickable
    if (event.target.matches('input.ui-box')) {
      return;
    }

    if (fakeBox.current.contains(event.target)) {
      return;
    }
    if (event.target.matches('a.mention-selection')) {
      return;
    }
    if (event.target.matches('div.mention-selection')) {
      return;
    }
    if (event.target.matches('mgt-person')) {
      return;
    }
    let calenderRef = document.querySelector('.ui-popup__content');

    if (ref.current && !ref.current.contains(event.target) && (calenderRef == null || !calenderRef.contains(event.target))) {
      if (!commentState.isSending) {
        dispatch({ type: ACTIONS.COMMENTING_STATE_OFF })
      }
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  const onChangeShowEditor = (data: any) => {
    dispatch({ type: ACTIONS.TYPING, payload: { body: data } });
  };

  const onChangeShowEditorCallback = useCallback((data: any) => {
    onChangeShowEditor(data);
    try {
      let text = ""
      data.forEach((d) => {
        if (d.children.length > 1) {
          throw new Error('not-empty');
        } else {
          text += d.children[0]['text'] ?? ''
          if (typeof d.type != "undefined" && d.type == 'image') {
            text += 'image'
          }
          if (typeof d.type != "undefined" && d.type == 'mention') {
            text += 'mention'
          }
        }
      })
      if (text == "") {
        dispatch({ type: ACTIONS.SET_IS_EMPTY });
      } else {
        dispatch({ type: ACTIONS.SET_NOT_EMPTY });
      }
    } catch {
      dispatch({ type: ACTIONS.SET_NOT_EMPTY });
    }
  }, [onChangeShowEditor]);

  const onChangeTicketQuickEdit = (data) => {
    setToUpdate(data);
  };

  const onChangeTicketQuickEditCallback = useCallback((data) => { onChangeTicketQuickEdit(data) }, [onChangeTicketQuickEdit]);

  const removeFile = (index: number) => {
    setDroppedFiles(droppedFiles.filter((_df: File, i: number) => i !== index))
  }
  const pastedImageFile = (file: File) => {
    const result = new File([file], `image-${droppedIndexRef.current.toString().padStart(3, '0')}.png`, { type: file.type });
    setDroppedFiles((prev) => ([...prev, result]));
    setDroppedIndex((prevIndex) => prevIndex + 1);
  }

  return (
    <FluentUIThemeConsumer
      render={globalTheme => (
        <>
          <Flex.Item>
            <ToolbarActivity />
          </Flex.Item>
          {(commentState.loading || loading) && (
            <Flex.Item>
              <div className={`pt-5 mt-5`}>
                <Loader />
              </div>
            </Flex.Item>
          )}
          {emptySearch && (
            <Flex.Item>
              <>
              <EmptyData
                headerText={t("ticket-details.ticket-conversation.empty-result")}
                subheaderText=""
                SVGIcon={<EmptySVG width={100} height={100} className="mb-2" />}
              /></>
            </Flex.Item>
          )}
          <Flex.Item>
            <div className={`chathistory-detail overflow-auto md:h-full w-full md:w-auto md:relative pb-20 md:pb-10 ${Props.isEndUserPage ? 'chathistory-enduser' : 'chathistory-analyst'} ${AppCSS.ChatContainer}`} ref={chatcontainer} onScroll={onScrollLoadMoreActivity}>
              <Chat items={commentState.conversation} />
            </div>
          </Flex.Item>
          <Flex.Item>
            <>
              <FileAlert show={isShowFileAlert} message={alertMessage} />
              <div  {...getRootProps({ className: 'dropzone h-full' })} className={`md:mb-3 w-full bottom-0 left-0 z-10 fixed md:relative`}>
                {isDragActive && !commentState.isSending && (
                  <div className="w-full h-full absolute">
                    <div className='w-full h-full absolute left-0 top-0 z-0 opacity-50' style={{ zIndex: 2000, background: globalTheme.siteVariables.bodyBackground }}>
                    </div>
                    <Flex
                      style={{
                        border: `2px solid ${globalTheme.siteVariables.colorScheme.brand.foregroundActive}`,
                        zIndex: 2010
                      }}
                      className='left-0 top-0 absolute cursor-pointer w-full justify-center h-full'
                    >
                      <Text
                        content={t('ticket-details.drop-file-text')}
                        className="self-center"
                        style={{ color: globalTheme.siteVariables.colorScheme.brand.foregroundActive }}
                      />
                    </Flex>
                  </div>
                )}
                {Props.isTicketMerged ? getMergedTicketDetails() :
                  <FluentUIThemeConsumer render={({ siteVariables: { colorScheme } }) => (
                    <>
                      {commentState.isCommenting && Props.isEndUserPage && <Alert warning content={WARNING_NOTE} style={{ color: colorScheme.default.foreground, marginBottom: '1px', fontSize: '11.3px' }} />}
                      <Flex className={`w-full`}>
                        <Flex.Item className={getTextBoxClass(commentState.isCommenting || !commentState.isMessageEmpty || droppedFiles.length > 0)}>
                          <div className={`flex items-center justify-between p-3 cursor-text w-full ${Props.isTicketClosed ? 'cursor-not-allowed' : ''}`}
                            style={{ backgroundColor: getCommentBackgroundColor(colorScheme) }}
                            ref={fakeBox}
                            onClick={() => {
                              setSearchQuery('');
                              if (!Props.isTicketClosed) {
                                dispatch({ type: ACTIONS.COMMENTING_STATE_ON });
                              }
                            }}>
                            <div style={{ color: Props.isTicketClosed ? colorScheme.default.foregroundDisabled : colorScheme.default.foreground }}>
                              {t("ticket-details.ticket-conversation.type-new-message")}
                            </div>
                            <div>
                              <Text content={<SendIcon outline />} style={{ color: Props.isTicketClosed ? colorScheme.default.foregroundDisabled : colorScheme.brand.foreground1 }} />
                            </div>
                          </div>
                        </Flex.Item>
                        <Flex.Item className={getTextBoxClass(!commentState.isCommenting && commentState.isMessageEmpty && droppedFiles.length == 0)}>
                          <div className={`w-full`} style={{ backgroundColor: getCommentBackgroundColor(colorScheme) }} ref={ref}>
                            <ShowEditor
                              teamId={Props.teamId}
                              groupId={Props.groupId}
                              onChange={onChangeShowEditorCallback}
                              isEditorVisible={commentState.isCommenting || !commentState.isMessageEmpty || droppedFiles.length > 0}
                              disabled={commentState.isSending}
                              commentBody={commentState?.body}
                              onImagePaste={pastedImageFile} />
                            <div className={`flex items-center overflow-auto ${droppedFiles.length > 0 ? 'm-2' : ''}`}>
                              {droppedFiles.map((file: any, index: number) => {
                                return (
                                  <FileAttachments key={file.name} isUploading={file.isUploading} id={index} file={file} onRemoveFile={() => removeFile(index)} isRemoveDisabled={commentState?.isSending} isInProgress={file.isInProgress} percentComplete={file.progressPercent} />
                                )
                              })}
                            </div>
                            <Divider />
                            <div className='md:flex justify-between'>
                              {!commentState.isSending && (
                                <>
                                  {!Props.isEndUserPage && (<TicketQuickEdit
                                    StatusId={Props.status}
                                    DueDate={Props.dueDate}
                                    teamId={Props.teamId}
                                    groupId={Props.groupId}
                                    AssigneeId={Props.assigneeId}
                                    PriorityId={Props.priority}
                                    onChange={onChangeTicketQuickEditCallback}
                                    ticketData={Props.ticketData}
                                    isUserLicensed={Props.isUserLicensed}
                                    isLifecycleEnabled={isLifecycleEnabled}
                                  />)}
                                </>
                              )}
                              <Flex className='flex-grow justify-between md:justify-end items-center md:px-0 px-1'>
                                <Flex.Item>
                                  <div className="pb-1">
                                    {!commentState.isSending && !Props.isEndUserPage &&
                                      <Button title={t("ticket-details.ticket-conversation.add-internal-note")} onClick={sendInternalNote} disabled={commentsDisabled()} primary style={{ minWidth: "0px", padding: "0px", backgroundColor: "#E9EAF6", }}>
                                        <div className='px-2 flex items-center text-xs font-semibold' style={{ color: commentsDisabled() ? colorScheme.default.foregroundDisabled : colorScheme.brand.foreground1 }}>
                                          <LockSVG className='mr-2 text-private' />
                                          <span className='leading-6 whitespace-nowrap'>{t("ticket-details.ticket-conversation.add-internal-note")}</span>
                                        </div>
                                      </Button>
                                    }
                                  </div>
                                </Flex.Item>
                                <Flex.Item>
                                  <div className='px-2 pb-1'>
                                    <div className={getCursorClass(commentState.body)}>
                                      {commentState.isSending && <Text content={t("ticket-details.ticket-conversation.sending")} />}
                                      {!commentState.isSending && <Button title={t("ticket-details.ticket-conversation.send")} onClick={sendCommentCallback}
                                          disabled={commentsDisabled()} text={commentsDisabled()} primary style={{ minWidth: "0px", width: "72px", padding: "0px" }}><SendIcon className='mr-1' />{t('ticket-details.ticket-conversation.send')}</Button>
                                      }
                                    </div>
                                  </div>
                                </Flex.Item>
                              </Flex>
                            </div>
                          </div>
                        </Flex.Item>
                      </Flex>
                    </>
                  )} />
                }
              </div>
            </>
          </Flex.Item>
        </>
      )} />
  );
};


interface ShowEditorProps {
  isEditorVisible: boolean;
  onChange: (interaction: any) => void;
  teamId: number;
  groupId: number;
  commentBody?: Descendant[];
  disabled: boolean;
  onImagePaste?: (data: File) => void;
}

const iconStyle = {
  size: 'small' as SvgIconSizeValue,
  style: { color: '#8f90a6' }
};

const formatUsersToDropdownItems = (users: PlatformUser[]): DropdownDataModel[] => {
  const items: DropdownDataModel[] = users.map((u: PlatformUser) => (
    {
      key: u.Id.toString(),
      label: u.FullName,
      header: u.FullName,
      Id: u.Id,
      value: u.FullName
    } as DropdownDataModel
  ))
  return items;
}
const formatStatusToDropdownItems = (status: TicketProps[]): DropdownDataModel[] => {
  const items: DropdownDataModel[] = status.map((s: TicketProps) => (
    {
      key: s.Id.toString(),
      label: s.Value,
      header: s.Value,
      Id: s.Id,
      value: s.Value
    } as DropdownDataModel
  ))
  return items;
}

interface TicketQuickEditProps extends EditTicketProps {
  teamId?: number | null;
  groupId?: number | null;
  onChange: (data: any) => void;
  ticketData?: any;
  isUserLicensed: boolean;
  isLifecycleEnabled: boolean;
}

interface EditTicketProps {
  StatusId: number,
  AssigneeId: number,
  DueDate: string | null,
  PriorityId: number,
}

interface DropdownNewProps extends DropdownProps {
  value: {
    Id: number;
    header: string;
    key: string;
    label: string;
    value: string;
  }
}

const setAssigneeList = (groupId: number, teamId: number, currentState: AppStateValue, setAssignees) => {
  if (groupId) {
    const groupMembers = currentState.supportgroups?.find(supportGroup => supportGroup.Id === groupId)?.Members || [];
    const userInRoles = filterPlatformUsersInRole(groupMembers, currentState.usersInRole);
    setAssignees(userInRoles);
  } else if (teamId) {
    const teamMembers = currentState.teams?.find(x => x.Id === teamId)?.Users || [];
    const userInRoles = filterPlatformUsersInRole(teamMembers, currentState.usersInRole);
    setAssignees(userInRoles);
  } else setAssignees(currentState.usersInRole);
};

const TicketQuickEdit: React.FunctionComponent<TicketQuickEditProps> = ({
  StatusId,
  AssigneeId,
  DueDate,
  PriorityId,
  ticketData,
  isUserLicensed,
  teamId,
  groupId,
  onChange,
  isLifecycleEnabled
}) => {
  const { t } = useTranslation();
  const currentState = appState();
  const [assignees, setAssignees] = useState<PlatformUser[]>([]);
  const [assigneeName, setAssigneeName] = useState<string>('');
  const [data, setData] = useState<EditTicketProps>({
    StatusId: StatusId,
    AssigneeId: AssigneeId,
    DueDate: DueDate,
    PriorityId: PriorityId
  })

  useEffect(() => {
    setData({ StatusId, AssigneeId, DueDate, PriorityId });
    onChange({ StatusId, AssigneeId, DueDate, PriorityId });
  }, [StatusId, AssigneeId, DueDate, PriorityId]);

  useEffect(() => setAssigneeList(groupId, teamId, currentState, setAssignees), [teamId, groupId, currentState.usersInRole, currentState.supportgroups, currentState.teams]);
  useEffect(() => setAssigneeName(currentState.usersInRole?.find(o => o.Id == AssigneeId)?.FullName || ''), [AssigneeId]);

  const customDateFormatter = (date: any) => ConvertDateToLocale(date, DateTime.DATE_SHORT);

  const customDateFormatterCallback = useCallback((date: any) => customDateFormatter(date), [customDateFormatter]);

  const onChangeAssignee = (selectedItem: DropdownProps) => {
    const value: any = selectedItem.value;
    const assigneeId = value ? parseInt(value.key) : null;
    setData(d => ({ ...d, AssigneeId: assigneeId }));
    onChange(({ ...data, AssigneeId: assigneeId }));
    if (!value) setAssigneeName('');
  }

  const onChangeStatus = (_e, p) => {
    const newData: any = p.value;
    setData(d => ({ ...d, StatusId: parseInt(newData.key) }))
    onChange(({ ...data, StatusId: parseInt(newData.key) }));
  }

  const onChangeStatusCallback = useCallback((_e, p) => { onChangeStatus(_e, p); }, [onChangeStatus]);

  const onSearchQueryChangeAssignee = (_e, data) => setAssigneeName(data.searchQuery);

  const onSearchQueryChangeAssigneeCallback = useCallback((_e, data) => { onSearchQueryChangeAssignee(_e, data); }, [onSearchQueryChangeAssignee]);

  const onChangeAssigneeDropdown = (_e, p) => onChangeAssignee(p);

  const onChangeAssigneeDropdownCallback = useCallback((_e, p) => { onChangeAssigneeDropdown(_e, p); }, [onChangeAssigneeDropdown]);

  const onDateChange = (_e, p) => {
    setData(d => ({ ...d, DueDate: (new Date(p.value)).toISOString() }));
    onChange(({ ...data, DueDate: (new Date(p.value)).toISOString() }));
  };

  const onDateChangeCallback = useCallback((_e, p) => { onDateChange(_e, p); }, [onDateChange]);

  const onChangePriorityDropdown = (_e, p) => {
    const newData: any = p.value;
    setData(d => ({ ...d, PriorityId: parseInt(newData.key) }));
    onChange(({ ...data, PriorityId: parseInt(newData.key) }));
  };

  const onChangePriorityDropdownCallback = useCallback((_e, p) => { onChangePriorityDropdown(_e, p); }, [onChangePriorityDropdown]);
  return (
    <AppStateContext.Consumer>
      {(state) => {
        return (
          <React.Fragment>
            <div className='flex flex-wrap flex-grow px-2'>
              <div className={`${AppCSS.WMobileAdjust} flex items-center`}>
                <Tooltip
                  trigger={<LightningBoltSolidIcon {...iconStyle} />}
                  content={t("ticket-details.ticket-conversation.status")}
                  position='above'
                />
                <Dropdown className='mr-1'
                  disabled={!isUserLicensed}
                  items={formatStatusToDropdownItems(state.ticketStatus.filter((item: any) => {
                    const initialCondition = item.Id != getStatusIdByGuid(currentState.ticketStatus, STATUS_GUID.DEFLECTED);
                    const lifecycleIsActive = (ticketData?.TicketLifecycle?.Status ?? false) &&
                      [LIFECYCLE_STATUS_GUID.FAILED, LIFECYCLE_STATUS_GUID.COMPLETED].indexOf(ticketData?.TicketLifecycle.Status.Guid) < 0;
                    const lifecycleCondition = isLifecycleEnabled && lifecycleIsActive &&
                      (item.Id == getStatusIdByGuid(currentState.ticketStatus, STATUS_GUID.CLOSED) ||
                        item.Id == getStatusIdByGuid(currentState.ticketStatus, STATUS_GUID.RESOLVED));
                    return initialCondition && !lifecycleCondition;
                  }))}
                  value={formatStatusToDropdownItems(state.ticketStatus).find(u => u.Id === data.StatusId)?.value ?? ticketData?.Status?.Value}
                  placeholder={t("ticket-details.ticket-conversation.status-dropdown-placeholder")}
                  checkable
                  inverted
                  fluid
                  getA11ySelectionMessage={{
                    onAdd: item => `${item} has been selected.`,
                  }}
                  onChange={onChangeStatusCallback}
                  styles={{ width: 'calc(100% - 1em)' }}
                />
              </div>
              <div className={`${AppCSS.WMobileAdjust} flex items-center`}>
                <Tooltip
                  trigger={<PeopleIcon {...iconStyle} />}
                  content={t("ticket-details.ticket-conversation.assignee")}
                  position='above'
                />
                <Dropdown className='mr-1'
                  disabled={!isUserLicensed}
                  items={formatUsersToDropdownItems(assignees)}
                  value={formatUsersToDropdownItems(assignees)?.filter(u => u.Id === data.AssigneeId)}
                  searchQuery={assigneeName}
                  search
                  clearable={isUserLicensed}
                  checkable
                  inverted
                  fluid
                  getA11ySelectionMessage={{ onAdd: item => `${item} has been selected.` }}
                  onSearchQueryChange={onSearchQueryChangeAssigneeCallback}
                  onChange={onChangeAssigneeDropdownCallback}
                  noResultsMessage={
                    <div style={{ textAlign: 'center' }}>
                      <Text content={`User not found`} />
                    </div>
                  }
                />
              </div>
              <div className={`${AppCSS.WMobileAdjust} flex items-center`}>
                <Tooltip
                  trigger={<CalendarIcon {...iconStyle} />}
                  content={t("ticket-details.ticket-conversation.due-date")}
                  position='above'
                />
                <Datepicker
                  {...DatepickerLocalizations}
                  disabled={!isUserLicensed}
                  firstDayOfWeek={0}
                  className={`datepicker-transparent date-no-icon mr-1`}
                  formatMonthDayYear={customDateFormatterCallback}
                  onDateChange={onDateChangeCallback}
                  {...(data.DueDate && {
                    selectedDate: new Date(data.DueDate)
                  })}
                />
              </div>
              <div className={`${AppCSS.WMobileAdjust} flex items-center`}>
                <Tooltip
                  trigger={<StatusCircleInnerIcon {...iconStyle} />}
                  content={t("ticket-details.ticket-conversation.priority")}
                  position='above'
                />
                <Dropdown
                  disabled={!isUserLicensed}
                  items={formatStatusToDropdownItems(state.priority)}
                  value={formatStatusToDropdownItems(state.priority).find(u => u.Id === data.PriorityId)?.value ?? ticketData?.Priority?.Value}
                  placeholder={t("ticket-details.ticket-conversation.priority")}
                  checkable
                  inverted
                  fluid
                  getA11ySelectionMessage={{
                    onAdd: item => t('ticket-details.ticket-conversation.item-has-been-selected', { item: item }),
                  }}
                  onChange={onChangePriorityDropdownCallback}
                  styles={{ width: 'calc(100% - 1em)' }}
                />
              </div>
            </div>
          </React.Fragment>
        )
      }}
    </AppStateContext.Consumer>
  )
}

const ShowEditor: React.FC<ShowEditorProps> = ({ disabled, isEditorVisible, onChange, commentBody, groupId, teamId, onImagePaste }: ShowEditorProps) => {
  const onChangeEditorComponent = (data: any) => { onChange(data); };
  const onChangeEditorComponentCallback = useCallback((data: any) => { onChangeEditorComponent(data); }, [onChangeEditorComponent]);
  const [assignees, setAssignees] = useState<PlatformUser[]>([]);
  const currentState = appState();
  const currentUser = getUser();

  useEffect(() => setAssignees(currentState.usersInRole), [currentState.usersInRole]);

  return isEditorVisible ?
    <div className={`${classNames.editor}`}>
      <SlateEditor
        onChange={onChangeEditorComponentCallback}
        slateValue={commentBody ?? [{
          children: [{ text: '' }],
        },
        ]}
        readOnly={disabled}
        usersInMention={assignees.filter(a => a.UserName !== currentUser && a.Id !== -1)}
        isFocus={true}
        noImage={true}
        onImagePaste={onImagePaste}
        minHeight='4rem'
      />
    </div> : <></>
}