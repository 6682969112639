import { Flex, ProviderConsumer } from '@fluentui/react-northstar';
import React from 'react';
import { EmptyData } from '../../EmptyData';
import EmptySVG from '../../../../../svg/empty.svg';
import Styles from './TicketTasksList.module.css';
import { TicketTasksDetails } from './TicketTaskCard/TicketTasksDetails';
import {ADD_TASKS_NOTE} from '../../../utils/constants';
import { useTranslation } from 'react-i18next';

interface Props {
  onTaskClick: (taskDetails: TicketTask) => void;
  tasksList: TicketTask[];
  deleteTask: (taskId: number, taskTitle: string) => void;
  isFormDisabled?: boolean;
}

export const TicketTasksList = ({ onTaskClick, tasksList, deleteTask, isFormDisabled }: Props) => {

  const { t } = useTranslation();

  return (
    <ProviderConsumer
      render={globalTheme => (
        <div className={`${Styles['ticket-task-list']} mb-4`}>
          {tasksList.length > 0 ? (
            tasksList.map((ticketTask: TicketTask) => (
              <div key={ticketTask.Id} className={`mt-2 cursor-pointer ${isFormDisabled && 'pointer-events-none'}`} 
              onClick={(e) => { 
                onTaskClick(ticketTask);
              }}>
                <TicketTasksDetails ticketTask={ticketTask} deleteTask={deleteTask} isFormDisabled={isFormDisabled} />
              </div>
            ))
          ) : (
            <Flex className="mt-5">
              <Flex.Item grow styles={{ paddingTop: '0.25rem', overflow: 'auto' }}></Flex.Item>
              <EmptyData
                headerText={t('ticket-details.edit-ticket-form.task.empty-task-list')}
                subheaderText={ADD_TASKS_NOTE}
                SVGIcon={<EmptySVG width={75} height={75} />}
              />
            </Flex>
          )}
        </div>
      )}
    />
  );
};
