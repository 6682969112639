import React, { useEffect, useState } from "react";
import { Flex, Dialog, CloseIcon, Button, Alert, ProviderConsumer } from "@fluentui/react-northstar";
import { LifecycleApproval } from "../utils/typings";
import { PhaseApprovalForm } from "./PhaseApprovalForm";
import { useTranslation } from 'react-i18next';
import { LIFECYCLE, BUTTONS } from './../../shared/utils/constants';


interface Props {
  open: boolean;
  onCancel: () => void;
  saveApproval: (approvalData: LifecycleApproval) => void;
  initData: LifecycleApproval
}

export const PhaseApprovalDialog = ({ open, onCancel, saveApproval, initData }: Props) => {
  const [approvalData, setApprovalData] = useState<LifecycleApproval>(initData);
  
  const closeDialog = () => {
    setAlert({ title: '', show: false, attributes: {} });
    onCancel();
  }
  const [alert, setAlert] = useState({
    title: '',
    show: false,
    attributes: {}
  });
  const validateRequired = () => {
    setAlert({ title: '', show: false, attributes: {} });
    if (approvalData.Name  === '') {
      setAlert({
        title: LIFECYCLE.REQUIRED_FIELD_MESSAGE,
        show: true,
        attributes: { danger: true }
      });
      return true;
    }
    return false;
  };
  const primaryClick = async () => {
    if (validateRequired()) return;
    saveData();
    onCancel();
  }
  useEffect(() => {
    if (open) {
      setAlert({ title: '', show: false, attributes: {} });
      setApprovalData(initData);
    }
  }, [open]);
  
  const renderPages = () => {
    return <PhaseApprovalForm
      approvalData={approvalData}
      setApprovalData={setApprovalData}
    />
  }

  const saveData = () => {
    saveApproval(approvalData);
    setApprovalData(initData);
  }

  const { t } = useTranslation();
  
  return (
    <Dialog className="relate-ticket-dialog"
      content={renderPages()}
      open={open}
      headerAction={{
        icon: <CloseIcon />,
        title: LIFECYCLE.CLOSE,
        onClick: closeDialog
      }}
      header={{
        content: (
          <div className="w-full">
            <h3 className="mt-0">{(approvalData.Guid.length > 0) ? BUTTONS.EDIT : LIFECYCLE.ADD} {LIFECYCLE.APPROVAL}</h3>
            {alert.show && (
              <Alert
                fitted
                {...alert.attributes}
                content={alert.title}
                className="w-full text-xs"
              />
            )}
          </div>
        ),        
      }}
      footer={
        <ProviderConsumer 
          render={(_globalTheme) => (
            <Flex gap="gap.small">
              <>
                <Flex.Item push>
                  <Button content={BUTTONS.CANCEL} onClick={closeDialog} />
                </Flex.Item>
                <Button className="dialog-btn"
                  content={(approvalData.Guid.length > 0) ? BUTTONS.SAVE : LIFECYCLE.ADD}
                  primary onClick={primaryClick}
                />
              </>
            </Flex>
          )}
        />
      }
    />
  )
};