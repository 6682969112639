import {useTranslation} from 'react-i18next';
import {AppKBStateContext, AppStateKBValue} from '../AppKBState';
import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {
    Input,
    ProviderConsumer,
    Checkbox,
    Button,
    Slider,
    Text,
    TextArea,
    SendIcon,
    InfoIcon,
    TrashCanIcon,
    Flex,
    ThemePrepared,
} from '@fluentui/react-northstar';
import {kbDataService} from '../shared/services/kb.service';
import KBCSS from '../kb.module.css';
import DOMPurify from 'dompurify';

export const KbPlayground = () => {
    const {t} = useTranslation();
    const defaultText = `<div style="padding: 10px;">${t('knowledge.tqa-chat-start')}</div>`;
    const [query, setQuery] = useState('');
    const updateQuery = (event) => setQuery(event.target.value);
    const [answer, setAnswer] = useState(defaultText);
    const [qnaThreshold, setQnaThreshold] = useState(65);
    const [numberOfResponses, setNumberOfResponses] = useState(5);
    const [showConfidenceScores, setShowConfidenceScores] = useState(false);
    const [includeAgentOnly, setIncludeAgentOnly] = useState(false);
    const [includeOpenAi, setIncludeOpenAi] = useState(false);
    const [includeDraft, setIncludeDraft] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const appState = useContext(AppKBStateContext);
    const answerDivRef = useRef(null);

    useEffect(() => {
        if (appState.chatStudioData) {
            setAnswer(DOMPurify.sanitize(appState.chatStudioData));
            if (appState.chatStudioData !== t('knowledge.tqa-chat-start')) {
                setIsLoaded(true);
            }
        } else {
            setAnswer(defaultText);
        }
    }, [appState]);

    useLayoutEffect(() => {
        if (answerDivRef.current) {
            answerDivRef.current.scrollTop = answerDivRef.current.scrollHeight;
        }
    }, [answer]);

    useEffect(() => {
        setShowConfidenceScores(localStorage.getItem('showConfidenceScores') === 'true');
        setIncludeAgentOnly(localStorage.getItem('includeAgentOnly') === 'true');
        setIncludeOpenAi(localStorage.getItem('includeOpenAi') === 'true');
        setIncludeDraft(localStorage.getItem('includeDraft') === 'true');
        setQnaThreshold(localStorage.getItem('qnaThreshold') ? Number(localStorage.getItem('qnaThreshold')) : 65);
        setNumberOfResponses(
            localStorage.getItem('numberOfResponses') ? Number(localStorage.getItem('numberOfResponses')) : 5
        );
    }, []);

    const getKnowlegeQuery = async (appState: AppStateKBValue, theme: ThemePrepared<any>) => {
        setLoading(true);
        let newData = {
            agentOnly: includeAgentOnly,
            includeOpenAiResults: includeOpenAi,
            query: query,
            numberOfResponses: numberOfResponses,
            threshold: qnaThreshold,
            includeDraft: includeDraft,
        };
        const data = await kbDataService.getChatStudioAnswer(newData);

        let newAnswer = isLoaded ? answer : '';
        let styles = 'border-radius:5px; padding:10px; text-align:left; background-color:rgb(98, 100, 167); color:white;';
        if (query.length > 70) {
            styles += 'float: left;';
        }
        newAnswer += `<div style="padding:10px"><div style="width:100%; text-align:right; float:right; margin-top:20px; margin-bottom:5px; color:lightgray;"><span style="${styles}">${query}</span></div>`;

        for (let dataValue of data.value) {
            newAnswer += `<div class="${KBCSS.widthLg}" style="width:100%; text-align:left; float:left; margin-top:10px;"><div style="border-radius:5px; padding:10px; display:inline-block; width:auto; background-color: ${theme.siteVariables.colorScheme.default.background };">${dataValue.Answer}<br><p>`;
            let score = dataValue.Score;

            if (showConfidenceScores && score >= 0) {
                newAnswer += `<span style="font-weight:bold;">${t('knowledge.tqa-chat-confidence-score')}:</span> ${score}`;
            } else if (includeOpenAi && score === -1) {
                newAnswer += `<span style="font-weight:bold;">${t('knowledge.tqa-chat-open-ai-result')}</span>`;
            }
            newAnswer += `</p></div></div>`;
        }

        newAnswer += `</div>`;

        // Sanitize the HTML content before setting it
        const sanitizedAnswer = DOMPurify.sanitize(newAnswer);

        appState.chatStudioData = sanitizedAnswer;
        setAnswer(sanitizedAnswer);
        setIsLoaded(true);
        setLoading(false);
        setQuery('');
    };

    const clearResults = (appState: AppStateKBValue) => {
        setIsLoaded(false);
        appState.chatStudioData = defaultText;
        setAnswer(defaultText);
    };

    const handleKeyDown = (event, theme: ThemePrepared<any>) => {
        if (event.key === 'Enter' && query.length > 0) {
            getKnowlegeQuery(appState, theme);
            event.preventDefault();
        }
    };

    const updateQnaThreshold = (event) => {
        localStorage.setItem('qnaThreshold', event.target.value);
        setQnaThreshold(Number(event.target.value));
    };

    const updateNumberOfResponses = (event) => {
        localStorage.setItem('numberOfResponses', event.target.value);
        setNumberOfResponses(Number(event.target.value));
    };

    return (
        <AppKBStateContext.Consumer>
            {(appstate) => {
                return (
                    <ProviderConsumer
                        render={(globalTheme) => (
                            <>
                            	<Flex gap="gap.medium"  className={KBCSS.kbWidthLg} style={{ height: '100vh', padding: '5px'}}>
                                    <Flex column style={{ width: '75%', height: '100%' }}>
                                        <Flex className={KBCSS.kbBorderRounded} style={{ flexBasis: '70%', height: '70%', overflow: 'hidden', paddingBottom: '5px' }}>
                                            <div  className={KBCSS.kbWidthLg} style={{ overflowY: 'auto', height: '99%', marginTop: '0px' }} dangerouslySetInnerHTML={{ __html: answer }} ref={answerDivRef}/>
                                        </Flex>
                                        <Flex column className={KBCSS.kbPadTopMed} style={{ flexBasis: '30%', height: '30%' }}>
                                            <TextArea
                                                fluid
                                                maxLength={1000}
                                                onChange={updateQuery}
                                                onKeyDown={(event) =>{handleKeyDown(event, globalTheme)}}
                                                placeholder={t('knowledge.tqa-chat-input')}
                                                className={`${KBCSS.kbMarginTopSm} ${KBCSS.kbWidthLg}`}
                                                style={{ height: '65px', backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
                                                inverted
                                                value={query}
                                            />
                                            <Flex hAlign="end" gap="gap.small">
                                                <Button
                                                    icon={<TrashCanIcon />}
                                                    text
                                                    iconOnly
                                                    title={t('knowledge.tqa-chat-clear')}
                                                    onClick={() => clearResults(appState)}
                                                />
                                                <Button
                                                    icon={loading ? <InfoIcon /> : <SendIcon />}
                                                    text
                                                    iconOnly
                                                    title={t('knowledge.tqa-chat-submit')}
                                                    onClick={() => getKnowlegeQuery(appState, globalTheme)}
                                                    disabled={query.length === 0 || loading}
                                                    content={loading ? t('knowledge.tqa-chat-answering') : ''}
                                                />
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                    <Flex column className={KBCSS.kbBorderRounded} style={{ width: '25%', height: 'calc(100vh - 22%)'}}>
                                        <Flex column padding='padding.medium' className={KBCSS.kbWidthLg}>
                                            <p className={KBCSS.kbPadBottomSm}>
                                                <Checkbox label={t('knowledge.tqa-chat-include-draft')} checked={includeDraft} onChange={() => {
                                                        localStorage.setItem('includeDraft', (!includeDraft).toString());
                                                        setIncludeDraft(!includeDraft);
                                                    }}
                                                />
                                            </p>
                                            <p className={KBCSS.kbPadBottomSm}>
                                                <Checkbox label={t('knowledge.tqa-chat-include-agent-only')} checked={includeAgentOnly} onChange={() => {
                                                        localStorage.setItem('includeAgentOnly', (!includeAgentOnly).toString());
                                                        setIncludeAgentOnly(!includeAgentOnly);
                                                    }}
                                                />
                                            </p>
                                            <p className={KBCSS.kbPadBottomSm}>
                                                <Checkbox label={`${t('knowledge.tqa-chat-include-openai')} * `} checked={includeOpenAi} onChange={() => {
                                                        localStorage.setItem('includeOpenAi', (!includeOpenAi).toString());
                                                        setIncludeOpenAi(!includeOpenAi);
                                                    }}
                                                />
                                            </p>
                                            <p className={KBCSS.kbPadBottomSm}>
                                                <Checkbox label={t('knowledge.tqa-chat-show-confidence')} checked={showConfidenceScores} onChange={() => {
                                                        localStorage.setItem('showConfidenceScores', (!showConfidenceScores).toString());
                                                        setShowConfidenceScores(!showConfidenceScores);
                                                    }}
                                                />
                                            </p>
                                            <p className={KBCSS.kbPadBottomSm}>
                                                <Text content={t('knowledge.tqa-chat-qna-threshold')} />
                                                <Flex gap="gap.small" vAlign="center">
                                                    <Slider fluid value={qnaThreshold} min='1' max='100' step='1' onChange={updateQnaThreshold} className={KBCSS.kbWidth80}/>
                                                    <Input
                                                        inverted
                                                        type='number'
                                                        min='1'
                                                        max='100'
                                                        step='1'
                                                        value={qnaThreshold}
                                                        onChange={updateQnaThreshold}
                                                        style={{ width: '15%' }}
                                                    />
                                                    <Text content='%' style={{ width: '5%' }} />
                                                </Flex>
                                            </p>
                                             <p className={KBCSS.kbPadBottomSm}>
                                                <Text content={t('bot-configuration.kb-response-to-return')} />
                                                <Flex gap="gap.small" vAlign="center">
                                                    <Slider fluid value={numberOfResponses} min='1' max='10' step='1' onChange={updateNumberOfResponses} className={KBCSS.kbWidth80} />
                                                    <Input inverted type='number' min='1' max='10' step='1' value={numberOfResponses} onChange={updateNumberOfResponses} style={{ width: '18%' }}/>
                                                    <Text content='&nbsp;' style={{ width: '2%' }} />
                                                </Flex>
                                            </p>
                                            <p className={KBCSS.kbPadTopSm}>
                                                <em>
                                                    {t('knowledge.tqa-chat-no-save-disclaimer')}
                                                </em>
                                            </p>
                                            <p className={KBCSS.kbPadTopMed}>
                                                <em className={KBCSS.kbPadTopMed}>
                                                * {t('knowledge.tqa-chat-needs-bot-config')} <a href="/settings/aiconfiguration" style={{ color: 'rgb(98, 100, 167)'}}>Azure {t('ai-configuration.open-ai')}</a>. {t('knowledge.tqa-chat-openai-warning')}
                                                </em>
                                            </p>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </>
                        )}
                    />
                );
            }}
        </AppKBStateContext.Consumer>
    );
};
