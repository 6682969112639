import { DateTime } from 'luxon';
import React from 'react';
import i18n from 'i18next';
import { ConvertDateToLocale } from '../common/ConvertDate';

type RelativeDateProps = {
  date: string;
  isNotShowTime?: boolean;
  locale?: string;
  className?: string;
  text?: string;
  fileSize?: string;
};

const RelativeDate: React.FC<RelativeDateProps> = (props): JSX.Element => {
  let date = DateTime.fromJSDate(new Date(props.date));
  
  return (
    <div {...(props.className !== '' && { className: props.className })}>
      {props?.text ? <span>{props.text}&nbsp;</span> : ''}
      {toRelative(date)}
      {!props.isNotShowTime && <span> ({ConvertDateToLocale(props.date, DateTime.TIME_SIMPLE)})</span>}
      {props?.fileSize ? <div>{props.fileSize}</div> : ''}
    </div>
  );
};

const toRelative = (date: DateTime) => {
  if (date.diffNow().as('seconds') > -30) return i18n.t('common.just-now');
  return date.toRelative({ locale: i18n.language });
};

export default RelativeDate;
