import React, { useState, useEffect, useCallback } from 'react';
import Styles from './Automation.module.css';
import { Text, ProviderConsumer, Button } from '@fluentui/react-northstar';
import { Providers } from '@microsoft/mgt-element';
import { Toolbar } from '../shared/components/Toolbar';
import { Communication, TToolbarInteraction } from '@fluentui/react-teams';
import {
  AUTOMATION_PROPERTIES,
  DEFAULT_AUTOMATION_DO_DATA,
  DEFAULT_AUTOMATION_EDIT_DATA,
  DEFAULT_AUTOMATION_GROUP,
  DEFAULT_AUTOMATION_INFO_DATA,
  DEFAULT_AUTOMATION_WHEN_DATA,
  DONE,
  ERROR,
  LOADING,
  AUTOMATION_LABELS,
  UNAUTHORIZED_ACCESS,
  SOMETHING_WENT_WRONG,
  CONTACT_ADMIN,
  REFRESH_SESSION,
  SETTINGS,
  AUTOMATION_EMAIL,
  ENTITY_TYPE
} from '../shared/utils/constants';
import { Breadcrumbs } from '../shared/components/BreadcrumbNavigation';
import { When } from './Sections/When';
import { platformService } from '../shared/services/platform.service';
import { Information } from './Sections/Information';
import DesktopIcon from '../../svg/desktop.svg';
import { If, IF_ARROW_HEIGHT } from './Sections/If/If';
import { ARROW_HEIGHT, Do } from './Sections/Do/Do';
import { uniqueId, cloneDeep } from 'lodash';
import {
  getTranslateAutomationProperties,
  prepareCreatePayload,
  prepareUpdatePayload
} from './utils/automationHelper';
import { useHistory } from 'react-router-dom';
import { CheckLogin } from '../shared/components/CheckLogin';

import { Helmet } from 'react-helmet';
import { LoadingScreen } from '../shared/components/LoadingScreen';
import { MobileSettingsView } from './AutomationTable';
import { loadFilters } from '../tikit/ticketHelper';
import { appState, useSetState } from '../AppState';
import { graphService } from '../shared/services/graph.service';
import { useTranslation } from 'react-i18next';
import { SetStateAction } from 'react';
import { getDropdownDateModel } from './../shared/utils/constants';
import { Dispatch } from 'react';


interface Props {
  id: string;
}

export const FormGroup = ({
  children
}: {
  children: React.ReactElement | React.ReactElement[];
}) => <div style={{ marginBottom: '15px' }}>{children}</div>;

export const Automation = ({ id }: Props) => {
  const {t} = useTranslation();
  const api = new platformService();
  const setAppState = useSetState();
  const currentState = appState();
  const graphAPI = new graphService();
  const queryId = parseInt(id);
  const history = useHistory();
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [apiStatus, setAPIStatus] = useState<APIStatus>({
    status: id !== "0" ? LOADING : DONE,
    msg: '',
    errCode: 0
  });
  const [menuOpen, setMenuOpen] = useState(false);

  const [whenPropertiesList, setWhenPropertiesList] = useState<DropdownDataModel[]>([]);
  const [propertiesList, setPropertiesList] = useState<DropdownDataModel[]>([]);

  const [infoData, setInfoData] = useState<AutomationInfoData>(
    DEFAULT_AUTOMATION_INFO_DATA
  );
  const [whenData, setWhenData] = useState<AutomationWhenData>(
    DEFAULT_AUTOMATION_WHEN_DATA
  );
  const [ifGroupData, setIfGroupData] = useState<AutomationGroup>(
    DEFAULT_AUTOMATION_GROUP
  );

  const [ifChildGroupData, setIfChildGroupData] = useState<
    AutomationChildrenGroup[]
  >([]);

  const [doData, setDoData] = useState<any>({
    ...DEFAULT_AUTOMATION_DO_DATA,
    loading: false,
    entitySelected: ENTITY_TYPE.Ticket,
    Id: 0,
    height: 36,
    actionNotification: {
      Message: AUTOMATION_EMAIL.DEFAULT_BODY,
      MessageSubject: AUTOMATION_EMAIL.DEFAULT_SUBJECT,
      MessageSender: '',
      NotifyTikitMessageHeader: AUTOMATION_EMAIL.DEFAULT_SUBJECT,
      NotifyTikitMessage: AUTOMATION_EMAIL.DEFAULT_BODY,
      NotifyTVAMessageHeader: AUTOMATION_EMAIL.DEFAULT_SUBJECT,
      NotifyTVAMessage: AUTOMATION_EMAIL.DEFAULT_BODY
    },
    actionApplyTemplate: {
      Id: 0,
      SendCustomFormToRequester: false
    },
    items: [
      { key: uniqueId('id'), Id: 0, Property: '', Value: '', ActionId: 0 }
    ]
  });

  const [phaseEditData, setPhaseEditData] = useState<AutomationEditData>(
    DEFAULT_AUTOMATION_EDIT_DATA
  );

  const setError = err => {
    setAPIStatus({
      ...apiStatus,
      status: ERROR,
      errCode: err
    });
  };

  const fetchAutomationProperties = useCallback(async () => {
    try {
      let propList = await fetchPropertyList(ENTITY_TYPE.Ticket);
      setPropertiesList(propList);
      if ((currentState?.ticketStatus?.length ?? 0) <= 0) loadFilters(api, graphAPI, currentState, setAppState);
      
    } catch (err) {
      console.error(err);
    }
  }, []);
  
  useEffect(()=>{
    fetchPropertyList(whenData.entityName).then(data=>{
      if (whenData.onSchedule)
      {
        const excludedProperties = ["relatedtickets", "comments"];
        setWhenPropertiesList(data.filter(x=> !excludedProperties.includes(x.key)));
      }
      else
      {
        setWhenPropertiesList(data);
      }
      
    });
  },[whenData]);

  const fetchPropertyList = async (entityTypeName: string) => {
    
    const {
      data: { value }
    } = await api.getAutomationProperties(entityTypeName);

    const automationProperties: DropdownDataModel[] = value
      .filter((val: string) => !val.includes('id'))
      .map((val: string) => ({
        key: val,
        label: getTranslateAutomationProperties(val, entityTypeName),
        header: getTranslateAutomationProperties(val, entityTypeName)
      }))
      .sort(sortByLabel);
      return automationProperties;
  }

  const sortByLabel = (a: DropdownDataModel, b: DropdownDataModel) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0);

  const getGroupTypes = async (id: number) => {
    try {
      const {
        data: { value }
      } = await api.getRuleGroupChildrenGroups(id);

      return value.map(item => ({
        id: item.Id,
        type: item.Type,
        ParentGroupId: item.ParentGroupId,
        RuleEntitySectionId: item.RuleEntitySectionId
      }));
    } catch (err) {
      setError(err);
    }
  };

  const getGroupsFilters = async (id: number | string) => {
    try {
      const {
        data: { value }
      } = await api.getRuleGroupRuleCriteria(+id);

      return value.map(item => ({
        property: item.Property
          ? item.Property
          : '',
        evaluationType: item.EvaluationType ?? '',
        operator: item.Operator ?? '',
        operator2: item.Operator2 ?? '',
        value: item.Value ?? '',
        value2: item.Value2 ?? '',
        GroupId: item.GroupId ?? 0,
        Id: item.Id ?? 0
      }));
    } catch (err) {
      setError(err);
    }
  };

  const fetchFiltersAndSubgroups = async (id: number) => {
    try {
      const {
        data: { value }
      } = await api.getRuleEntitySectionGroups(id);

      /**
       * Returns "Group Type" and "Id" of the parent group
       */

      if (value.length > 0) {
        const groups: FetchedGroupsData[] = await Promise.all(
          value.map(async ({ Id, Type }) => {
            const parentRules = await getGroupsFilters(Id);
            const children = await getGroupTypes(Id);

            return {
              id: Id,
              type: Type,
              arrowHeight:
                parentRules.length > 0
                  ? ifGroupData.arrowHeight +
                  IF_ARROW_HEIGHT * (parentRules.length - 1)
                  : 0,
              filters: [...parentRules],
              childrenGroups: [...children]
            };
          })
        );

        //FluentUI does not have a notion of children groups, the design work as array of groups only
        //Get the first group and reassign all others as child groups
        let cloneGroups = cloneDeep(groups);
        cloneGroups.shift()
        let childCloneGroups: any[] = cloneGroups.map(x => {x["key"] = uniqueId('id'); return x ;});
        groups[0].childrenGroups = groups[0].childrenGroups.concat(childCloneGroups);

        // fetches filters for child groups
        const childGroups: AutomationChildrenGroup[][] = await Promise.all(
          groups.map(async (group: FetchedGroupsData) => {
            return Promise.all(
              group.childrenGroups.map(async child => {
                const rules = await getGroupsFilters(child.id);
                return {
                  key: uniqueId('id'),
                  id: child.id,
                  type: child.type,
                  ParentGroupId: child.ParentGroupId,
                  RuleEntitySectionId: child.RuleEntitySectionId,
                  arrowHeight:
                    rules.length > 0
                      ? 50 + IF_ARROW_HEIGHT * (rules.length - 1)
                      : 0,
                  filters: [...rules]
                };
              })
            );
          })
        );
        const { childrenGroups, ...rest } = groups[0];

        setIfGroupData(prevState => ({
          ...prevState,
          loading: false,
          ...rest
        }));
        
        setIfChildGroupData(childGroups[0]);
      } else {
        setIfGroupData(prevState => ({
          ...prevState,
          loading: false
        }));
      }
    } catch (err) {
      setError(err);
    }
  };

  const fetchAutomationGroups = async (id: number) => {
    try {
      const {
        data: { value }
      } = await api.getEntitySections(id);

      /**
       * Returns an "Id"
       * Returned "Id" will be used to fetch "Group Type" and "Id" of parent group
       */

      fetchFiltersAndSubgroups(value[value.length - 1].Id);
    } catch (err) {
      setError(err);
    }
  };

  const fetchPropertyValuePairs = useCallback(
    async (id: number) => {
      try {
        const {
          data: { value }
        } = await api.getPropertyValuePair(id);
        setDoData(prevState => ({
          ...prevState,
          loading: false,
          height:
            value.length > 0
              ? doData.height + ARROW_HEIGHT * (value.length - 1)
              : 0,
          items:
            value &&
            value.map(
              ({ Id, Property, Value, ActionId }: AutomationDoTicketInfo) => ({
                Id,
                Property: Property ? Property : '',
                Value: Value ?? '',
                ActionId: ActionId ?? 0
              })
            )
        }));
      } catch (err) {
        setError(err);
      }
    },
    [queryId]
  );

  const fetchActionNotification = useCallback(
    async (notifId: number) => {
      try {
        const {
          data
        } = await api.getActionNotification(notifId);

        data.NotifyUsers = data.NotifyUsers.map(x=>({
            ...x,
            displayName: x.FullName,
            userPrincipalName:  x.UserName,
            aadObjectId: x.AadObjectId
          })
        );

        setDoData(prevState => ({
          ...prevState,
          loading: false,
          actionNotification: data,
        }));
      } catch (err) {
        setError(err);
      }
    },
    [queryId]
  );

  const fetchActionApplyTemplate = useCallback(
    async (notifId: number) => {
      try {
        const {
          data
        } = await api.getActionApplyTemplate(notifId);
        setDoData(prevState => ({
          ...prevState,
          loading: false,
          actionApplyTemplate: data,
        }));
      } catch (err) {
        //Do net set the setError because editing existing automation will cause error since applytemplate is not yet existing.
        setDoData(prevState => ({
          ...prevState,
          loading: false
        }));
      }
    },
    [queryId]
  );

  const setInitialEditData = ({
    Id,
    Title,
    Description,
    Enabled,
    Trigger,
    Steps,
    TriggerId
  }) => {
    setPhaseEditData({
      Id,
      TriggerId,
      OrderOneStep: {
        Id: Steps[0].Id,
        StepId: Steps[0].StepId
      },
      OrderTwoStep: {
        Id: Steps[1].Id,
        StepId: Steps[1].StepId
      }
    });

    setInfoData({
      ...infoData,
      title: Title,
      description: Description,
      enabled: Enabled
    });

    setWhenData({
      ...whenData,
      entityName: Trigger?.ModelFullName, //(Trigger?.ModelFullName) ? Trigger?.ModelFullName.split('.').pop() : "Ticket",
      triggerType: Trigger?.TriggerType ?? "Added",
      onEvent: (Trigger?.TriggerType) ? true : false,
      onSchedule: (Trigger?.RepeatFrequency) ? true : false,
      scheduleFrequency: Trigger?.RepeatFrequency ?? "Hour"
    });

    setIfGroupData({
      ...ifGroupData,
      loading: true,
      disabled:Steps[0].Step.Disabled,
      ruleName:
        Steps[0].Step.Title === 'ruleTemp'
          ? `Rule (${Steps[0].Step.Id})`
          : Steps[0].Step.Title
    });

    setDoData({
      ...doData,
      loading: true,
      entitySelected: Trigger?.ModelFullName,
      actionName:
        Steps[1].Step.Title === 'actionTemp'
          ? `Action (${Steps[1].Step.Id})`
          : Steps[1].Step.Title,
      actionType: Steps[1]?.Step.ActionType
    });
  };

  const fetchAutomationDataForEdit = useCallback(async () => {
    setAPIStatus({
      status: LOADING,
      msg: t('automation.fetching-automation-data'),
      errCode: 0
    });

    try {
      const { data } = await api.getAutomation(queryId);
      setInitialEditData(data);


      fetchAutomationGroups(data.Steps[0].StepId);
      let actionStepId = data.Steps[1].StepId;
      switch(data.Steps[1].Step.ActionType){
        case AUTOMATION_LABELS.NOTIFICATION.toUpperCase():
          fetchActionNotification(actionStepId);
          break;
        case "APPLYTEMPLATE":
          fetchActionApplyTemplate(actionStepId);
          break;
        default:
          fetchPropertyValuePairs(actionStepId);
          break;
      }
      

      setAPIStatus({ ...apiStatus, status: DONE });
    } catch (err) {
      setError(err);
    }
  }, [queryId]);

  const [isCheckedIn, setIsCheckedIn] = useState<boolean>(false);

  const loadPage = async () => {
    setIsCheckedIn(true)
  }

  useEffect(() => {
    if (isCheckedIn) {
      if (queryId > 0) {
        fetchAutomationDataForEdit();
      }
      fetchAutomationProperties();
    }
  }, [queryId, isCheckedIn]);

  useEffect(() => {
    setDoData({...doData, entitySelected: whenData.entityName });
  }, [whenData.entityName])

  const onToolbarInteraction = (interaction: TToolbarInteraction) => {
    if (interaction.action === 'toggle-menu') {
      setMenuOpen(!menuOpen);
    }
  };

  const errConfig = {
    fields: {
      title:
        apiStatus.errCode == 401
          ? UNAUTHORIZED_ACCESS
          : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  };

  const navs: Breadcrumbs = {
    breadcrumbs: [
      {
        title: SETTINGS,
        link: '/settings'
      },
      {
        title: t('automation.automation'),
        link: '/settings/' + 'automation'
      },
      {
        title: infoData.title.length > 0 ? infoData.title : t('automation.new-automation'),
        link: '',
      }
    ]
  };

  const updateAutomation = async () => {
    const payload: AutomationCreatePayload = prepareUpdatePayload(
      phaseEditData,
      infoData,
      whenData,
      ifGroupData,
      ifChildGroupData,
      doData
    );
    setAPIStatus({
      status: LOADING,
      msg: t('automation.updating-automation'),
      errCode: 0
    });
    try {
      await api.createUpdateAutomation(payload);
      setAPIStatus({ ...apiStatus, status: DONE });
      history.push('/settings/automation');
    } catch (err) {
      setError(err);
    }
  };

  const createAutomation = async () => {
    const payload: AutomationCreatePayload = prepareCreatePayload(
      infoData,
      whenData,
      ifGroupData,
      ifChildGroupData,
      doData
    );
 
    setAPIStatus({
      status: LOADING,
      msg: t('automation.creating-automation'),
      errCode: 0
    });
    try {
      await api.createUpdateAutomation(payload);
      setAPIStatus({ ...apiStatus, status: DONE });
      history.push('/settings/automation');
    } catch (err) {
      setError(err);
    }
  };

  const createUpdateAutomation = () => {
    if (queryId > 0) updateAutomation();
    else createAutomation();
  };

  return (
    <CheckLogin onSignedIn={loadPage}>
      <Helmet>
        <title>{t('automation.title-main')}</title>
      </Helmet>
      <div className="hidden md:block">
        {
          apiStatus.status === LOADING ? (
            <LoadingScreen message={apiStatus.msg} />
          ) : apiStatus.status === ERROR ? (
            <div style={{ height: '100vh' }}>
              <Communication {...errConfig} />
              {apiStatus.errCode === 401 && (
                <Button
                  content={REFRESH_SESSION}
                  primary
                  onClick={e => {
                    Providers.globalProvider.login();
                  }}
                />
              )}
            </div>
          ) : (
            <ProviderConsumer
              render={globalTheme => {
                return (
                  <div className={Styles.AutomationWrapper}>
                    <Toolbar
                      onInteraction={onToolbarInteraction}
                      globalTheme={globalTheme}
                      title={t('automation.toolbar.title')}
                      saveText={t('automation.toolbar.save')}
                      saveCommand={createUpdateAutomation}
                      breadcrumbs={navs}
                      disableSave={
                        infoData.title === '' ||
                        ifGroupData.loading ||
                        doData.loading ||
                        !isEmailValid ||
                        (doData.actionType=="NOTIFICATION" && 
                        (!doData.actionNotification?.NotifyMessageValid ||
                        !doData.actionNotification?.NotifyTVAMessageValid ||
                        !doData.actionNotification?.EmailMessageValid))
                      }
                    />
                    <div className={Styles.AutomationContainer}>
                      <div className={Styles.Automation}>
                        <Helmet>
                          <title>
                          {t('automation.title', {infoDataTitle  : infoData.title})}
                          </title>
                        </Helmet>
                        <Information
                          infoData={infoData}
                          updateInfoData={setInfoData}
                        />

                        <When 
                          whenData={whenData} 
                          updateWhenData={setWhenData} 
                          updateIfGroupData={setIfGroupData}
                          updateIfChildGroupData={setIfChildGroupData}
                          doData={doData}
                          updateDoData={setDoData}
                        />

                        
                        <If
                          propertiesList={whenPropertiesList}
                          ifGroupData={ifGroupData}
                          updateIfGroupData={setIfGroupData}
                          ifChildGroupData={ifChildGroupData}
                          updateIfChildGroupData={setIfChildGroupData}
                          whenData={whenData}
                        />
                        
                        
                        <Do
                          propertiesList={propertiesList}
                          doData={doData}
                          updateDoData={setDoData}
                          setIsEmailValid={setIsEmailValid}
                        />
                      </div>
                    </div>
                  </div>
                );
              }}
            />
          )}
      </div>
      <MobileSettingsView />
    </CheckLogin>
  );
};
