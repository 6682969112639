import React, { useLayoutEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import omit from 'lodash/omit';
import {
  Box,
  ObjectShorthandCollection,
  Position,
  TreeItemProps,
  Button,
  Text,
  Divider,
  MenuIcon,
  ThemePrepared
} from '@fluentui/react-northstar';

import { SiteVariablesPrepared } from '@fluentui/styles';

import { TeamsTheme } from '@fluentui/react-teams/lib/esm/themes';

import { actionKey, TActions } from '@fluentui/react-teams/lib/esm';
import { ToolbarFilter } from '@fluentui/react-teams/lib/esm/components/Toolbar/ToolbarFilter';
import { ToolbarTheme } from '@fluentui/react-teams/lib/esm/components/Toolbar/ToolbarTheme';

import {
  Breadcrumbs,
  BreadcrumbNavigation
} from '../shared/components/BreadcrumbNavigation';
import { AppStateKBValue } from '../AppState';
import { CheckLogin } from '../shared/components/CheckLogin';
import { checkInTeams } from '../App';
import { useTranslation } from 'react-i18next';


export type TActionGroups = {
  [actionKey: string]: TActions;
};

export type TFilters = ObjectShorthandCollection<TreeItemProps, never>;

export type TToolbarInteraction = {
  event: 'click';
  target: 'toolbar';
  subject: string | string[] | null;
  action: actionKey;
};

export interface IToolbarProps {
  actionGroups: TActionGroups;
  filters?: TFilters;
  find?: boolean;
  filtersSingleSelect?: boolean;
  onSelectedFiltersChange?: (selectedFilters: string[]) => string[];
  onFindQueryChange?: (findQuery: string) => string;
  onInteraction?: (interaction: TToolbarInteraction) => void;
  state?: AppStateKBValue;
  globalTheme?: ThemePrepared;
}

export type TToolbarLayout = 'compact' | 'verbose';


const toolbarMenuProps = {
  offset: [0, 4] as [number, number],
  position: 'below' as Position
};

let toolbarButtonStyles = {
  padding: '.5rem',
  borderWidth: '1px',
  marginTop: 0,
  marginBottom: 0,
  height: '3rem',
  minWidth: 0,
  '&:focus:before': {
    top: 'calc(.5rem - 1px)',
    bottom: 'calc(.5rem - 1px)'
  },
  '&:focus:after': {
    top: 'calc(.5rem - 1px)',
    bottom: 'calc(.5rem - 1px)'
  }
};

const getNavigation = (location: any, t): Breadcrumbs => {
  const items: Breadcrumbs = {
    breadcrumbs: [
      {
        title: t('knowledge.title'),
        link: ''
      }
    ]
  };

  if (location.pathname.indexOf('/settings/') >= 0)
    items.breadcrumbs.splice(0, 0, { title: 'Settings', link: '/settings' });

  return items;
};

export const KBToolbar = (props: IToolbarProps) => {
  const {t} = useTranslation();
  const location = useLocation();
  const { filters, filtersSingleSelect, find } = props;
  const [filtersOpen, setFiltersOpen] = useState(false);

  const [layout, setLayout] = useState<TToolbarLayout>('compact');

  const layoutQuery = useRef<MediaQueryList | null>(null);

  const onChangeLayout = () => {
    setLayout(layoutQuery.current && layoutQuery.current.matches ? 'verbose' : 'compact');
  };

  const onMenuToggle = () => {
    if (props.onInteraction)
      props.onInteraction({
        event: 'click',
        target: 'toolbar',
        subject: 'menu',
        action: 'toggle-menu'
      });
  };

  
  useLayoutEffect(() => {
    layoutQuery.current = window.matchMedia('(min-width: 640px)');
    layoutQuery.current.addEventListener('change', onChangeLayout);
    onChangeLayout();
    return () =>
      layoutQuery.current?.removeEventListener('change', onChangeLayout);
  });

  const displayFindOnly = find && layout === 'compact';
  const addColor = (textColor: string) => {
    toolbarButtonStyles['color'] = textColor;
    return '';
  };

  const navs = getNavigation(location, t);
  return (
    <CheckLogin>
      {addColor(
        props.globalTheme.siteVariables.colorScheme.brand.foreground
      )}
      <ToolbarTheme globalTheme={props.globalTheme}>
        <Box
          className="extended-toolbar"
          variables={({
            colorScheme,
            theme
          }: SiteVariablesPrepared) => ({
            backgroundColor:
              theme === TeamsTheme.HighContrast
                ? colorScheme.grey.background
                : colorScheme.default.background2,
            elevation: colorScheme.elevations[16]
          })}
          styles={{
            padding: '0',
            lineHeight: '52px'
          }}
        >
          <div className={`width-100 flex justify-between`}>
            <Box
              className="extended-toolbar"
              styles={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%'
              }}
              {...omit(props, [
                'actionGroups',
                'filters',
                'find',
                'filtersSingleSelect',
                'onSelectedFiltersChange',
                'onFindQueryChange'
              ])}
            >
              <Box
                className="extended-toolbar__far-side align-center"
                styles={{
                  flex: displayFindOnly ? '1 1 100%' : '0 1 auto',
                  display: 'flex',
                  flexFlow: 'row nowrap',
                  overflow: 'hidden'
                }}
              >
                {!displayFindOnly && filters && (
                  <ToolbarFilter
                    layout={layout}
                    filters={filters}
                    singleSelect={!!filtersSingleSelect}
                    open={filtersOpen}
                    onOpenChange={(_e, props) => {
                      const open = !!props?.open;
                      setFiltersOpen(open);
                    }}
                    onSelectedFiltersChange={
                      props.onSelectedFiltersChange
                    }
                    toolbarMenuProps={toolbarMenuProps}
                    toolbarButtonStyles={toolbarButtonStyles}
                  />
                )}
                <div className={`hidden md:flex`}>
                  <BreadcrumbNavigation {...navs} />
                </div>

                {checkInTeams() && (
                  <div className={`md:hidden`}>
                    <Button
                      onClick={onMenuToggle}
                      iconOnly
                      content={
                        <MenuIcon size="large" color="brand" />
                      }
                      title="menu"
                      className={`mt-2`}
                    />
                  </div>
                )}
                {!displayFindOnly && (
                  <>
                    <Divider vertical />
                  </>
                )}
                <Text
                  size="medium"
                  weight="semibold"
                  className={`md:hidden px-2 pt-4`}
                  content={
                    navs.breadcrumbs[navs.breadcrumbs.length - 1]
                      .title
                  }
                />
              </Box>
            </Box>
          </div>
        </Box>
      </ToolbarTheme>
    </CheckLogin>
  );
};
