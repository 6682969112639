import React, { useState } from 'react';
import Styles from '../styles/Lifecycle.module.css';
import {
  AddIcon,
  Button,
  Flex,
  ProviderConsumer
} from '@fluentui/react-northstar';
import { LogicOperatorTypeString } from '../utils/constants';
import { KeyValuePair, TransitionRule, TransitionRuleGroup } from '../utils/typings';
import { PhaseTransitionRuleGroups } from './PhaseTransitionRuleGroups';
import { cloneDeep } from 'lodash';
import { LIFECYCLE } from '../../shared/utils/constants';

interface Props {
  data: TransitionRule;
  updateData: (data: TransitionRule) => void;
  tasksOptions: DropdownDataModel[];
  approvalsOptions: DropdownDataModel[];
  taskStatusList: TaskStatus[];
  powerautomateOptions: DropdownDataModel[];
}

interface RuleGroupProps {
  group: TransitionRuleGroup;
  arrowHeight: number;
}

export const RULE_ARROW_HEIGHT = 100;

export const PhaseTransitionRule = ({
  data,
  // data: { TransitionRuleGroups },
  updateData,
  tasksOptions,
  approvalsOptions,
  taskStatusList,
  powerautomateOptions
}: Props) => {

  const initialData: TransitionRuleGroup = {
    Id: 0,
    LogicOperatorType: LogicOperatorTypeString.And,
    TransitionRuleId: data.Id,
    TransitionRuleCriterias: [
      {
        Id: 0,
        TransitionItem: null,
        TransitionItemId: 0,
        TransitionRuleGroupId: 0
      }
    ]
  }

  const [groupsData, setGroupsData] = useState<RuleGroupProps[]>([]);

  React.useEffect(() => {
    if(groupsData.length == 0 && data.TransitionRuleGroups){
      const gData: TransitionRuleGroup[] = cloneDeep(data.TransitionRuleGroups);
      const gProps: RuleGroupProps[] = gData.map(g => {
        const arrowHeight = g.TransitionRuleCriterias.length > 0
        ? 50 + RULE_ARROW_HEIGHT * (g.TransitionRuleCriterias.length - 1)
        : 0;
        return {
          group: g,
          arrowHeight: arrowHeight 
        } as RuleGroupProps;
      });
      setGroupsData(gProps);
    }
  }, groupsData)

  const updateGroupData = (editData: KeyValuePair[], index: number) => {
    editData.forEach(pair => {
      
      if(pair.key == "AddTransitionRuleCriterias") {
        data.TransitionRuleGroups[index]["TransitionRuleCriterias"] = pair.value;
        const arrowHeight = data.TransitionRuleGroups[index].TransitionRuleCriterias.length > 0
          ? 50 + RULE_ARROW_HEIGHT * (data.TransitionRuleGroups[index].TransitionRuleCriterias.length - 1)
          : 0;
        groupsData[index].arrowHeight = arrowHeight;
        setGroupsData([...groupsData]);
      }else if(pair.key == "RemoveTransitionRuleCriterias") {
          data.TransitionRuleGroups[index]["TransitionRuleCriterias"] = pair.value;
          groupsData[index].arrowHeight = groupsData[index].arrowHeight - RULE_ARROW_HEIGHT;
          setGroupsData([...groupsData]);
      }else if(pair.key == "RemoveTransitionRuleGroup") {
        data.TransitionRuleGroups.splice(index, 1);
        groupsData.splice(index, 1);
        setGroupsData([...groupsData]);
      }else
        data.TransitionRuleGroups[index][pair.key] = pair.value;
      
    });    

    updateData(data);
    
  }

  const addNewGroup = () => {
    const newGroup= cloneDeep(initialData);
    data.TransitionRuleGroups.push(newGroup);
    updateData(data);
    const arrowHeight = newGroup.TransitionRuleCriterias.length > 0
        ? 50 + RULE_ARROW_HEIGHT * (newGroup.TransitionRuleCriterias.length - 1)
        : 0;
    groupsData.push({
      group: newGroup,
      arrowHeight: arrowHeight
    })
    setGroupsData([...groupsData]);
  }

  return (
    <ProviderConsumer
      render={(globalTheme) => (
        <div className={`ml-10 ${Styles.WhenSection}`} >
        
        {groupsData.length > 0 && (
        <>
          <div>
            {React.Children.toArray(
              groupsData.map(
                (groupData: RuleGroupProps, index: number) => (
                  <div className={`${Styles.Groups} pt-2 pb-4`} key={groupData.group.Id}>
      
                      <PhaseTransitionRuleGroups data={groupData.group} updateData={updateGroupData} 
                        dataIndex={index}
                        tasksOptions={tasksOptions} 
                        approvalsOptions={approvalsOptions} 
                        taskStatusList={taskStatusList}
                        powerautomateOptions = {powerautomateOptions}
                      />
                    </div>
                )
              )
            )}
          </div>
          </>
        )}
        <Flex className='mt-4 mb-2 ml-0 mr-0'>
            <Button
            icon={<AddIcon 
                styles={{color: globalTheme.siteVariables.colorScheme.brand.foreground}} 
                />}
            text
            primary
            content={<span 
                style={{color: globalTheme.siteVariables.colorScheme.brand.foreground}}
                >{LIFECYCLE.ADD_RULE_GROUP}</span>}
            onClick={addNewGroup}
            />
        </Flex>
      </div>
      )}
    />
  );
};
