import { platformService } from '../../../shared/services/platform.service';
import { APPROVAL_PAGINATION, STATUS_GUID, TICKET_PAGINATION } from '../../../shared/utils/constants';
import { useContext } from 'react';
import { AppStateContext } from '../../../AppState';
import { RequestListContext } from './RequestContextProvider';

const api = new platformService();

const RequestFunctions = () => {
  const state = useContext(AppStateContext);
  const { requestStates, setRequestStates, approvalStates, setApprovalStates, showClosed, searchFilter } = useContext(RequestListContext);

  const moveToRequestPage = (action: string) => {
    setRequestStates((prev) => ({ ...prev, isTicketsFetching: true }));

    if (action === 'next') {
      setRequestStates((prev) => ({ ...prev, isTicketsFetching: true, currentPage: prev.currentPage + 1 }));
      getPaginatedRequests(requestStates.currentPage + 1);
    } else {
      setRequestStates((prev) => ({ ...prev, isTicketsFetching: true, currentPage: prev.currentPage - 1 }));
      getPaginatedRequests(requestStates.currentPage - 1);
    }
  };

  const moveToApprovalPage = (action: string) => {
    setApprovalStates((prev) => ({ ...prev, isApprovalsFetching: true }));

    if (action === 'next') {
      setApprovalStates((prev) => ({ ...prev, isApprovalsFetching: true, currentPage: prev.currentPage + 1 }));
      getPaginatedApprovals(approvalStates.currentPage + 1);
    } else {
      setApprovalStates((prev) => ({ ...prev, isApprovalsFetching: true, currentPage: prev.currentPage - 1 }));
      getPaginatedApprovals(approvalStates.currentPage - 1);
    }
  };

  const getPaginatedRequests = async (page: number, closed?: boolean, search?: string) => {
    closed ??= showClosed;
    search ??= searchFilter;
    try {
      const expandQuery = "AffectedUsers($select=PlatformUserId),TicketCollaborators($select=PlatformUserId),FileAttachments($filter=IsPublic eq true;$top=1;$orderby=Id desc;),Comments($filter=IsPublic eq true;$top=1;$orderby=Id desc;$select=Id,Body,CreatedById,IsPublic),TicketLifecycle($expand=Status,Lifecycle($select=Title),Phases($filter=IsCurrent eq true))";
      let filterQuery = `Closed eq ${closed} and Status/Guid ne ${STATUS_GUID.DEFLECTED} and (RequesterId eq ${state.currentUserId} or AffectedUsers/any(a: a/PlatformUserId eq ${state.currentUserId}))`
      if (search)
        filterQuery += `and ${(/^\d+$/.test(search) ? `(contains(Title, '${search}') or Id eq ${search})` : `(contains(Title, '${search}'))`)}`;
        
      const data = (await api.getTicketList(`?$expand=${expandQuery}&$filter=${filterQuery}&$orderby=ModifiedDate desc&$top=${TICKET_PAGINATION.ITEM_PER_PAGE}&$count=true&$skip=${(page - 1) * TICKET_PAGINATION.ITEM_PER_PAGE}`))?.data;

      setRequestStates(prev => ({ 
        ...prev,
        isTicketsFetching: false,
        countTickets: data['@odata.count'],
        tickets: data.value
      }));
    } catch (error) {
      console.error('Error occurred fetching requests', error);
    }
  };

  const getPaginatedApprovals = async (page: number, closed?: boolean, search?: string) => {
    closed ??= showClosed;
    search ??= searchFilter ?? '';
    try {
      const data = await api.getApproversByUser(state.currentUserId, `?showClosed=${closed}&search=${search}&$skip=${(page - 1) * APPROVAL_PAGINATION.ITEM_PER_PAGE}&$top=${APPROVAL_PAGINATION.ITEM_PER_PAGE}`);

      var approvals = JSON.parse(data.approvals).map((a: any) => ({
        ...a, 
        Ticket: { 
          Id: a.TicketId, 
          Title: a.TicketTitle, 
          StatusId: a.StatusId, 
          Closed: a.Closed, 
          TicketLifecycle: (a.LifecycleName ? { Lifecycle: { Title: a.LifecycleName }, Status: { Guid: a.LifecycleStatus }, Phases: [{ Name: a.ActivePhaseName }] } : null), 
          Status: state.ticketStatus.find((status: any) => status.Id === a.StatusId) 
        }
      }));

      setApprovalStates(prev => ({
        ...prev,
        isApprovalsFetching: false,
        countApprovals: data.count,
        approvals: approvals
      }));
    } catch (error) {
      console.error('Error occurred fetching approvals', error);
    }
  };

  return {
    getPaginatedRequests,
    moveToRequestPage,
    getPaginatedApprovals,
    moveToApprovalPage
  };
};

export default RequestFunctions;