import React, { useState } from 'react';
import {
  ProviderConsumer as FluentUIThemeConsumer,
  Flex,
  Text,
  RadioGroup
} from '@fluentui/react-northstar';
import MgtCss from '../mgt.module.css';
import { TEMPLATE } from '../shared/utils/constants';
import { TicketTemplatesSelectionProperties } from './TicketTemplatesSelection';
import { AdaptiveCardElement } from './AdaptiveCardElement';
import { parseCard } from '../shared/components/TicketTemplateAnswer';
import { Input } from 'adaptivecards';
import { mergeStyleSets } from '@fluentui/react';

const classNames = mergeStyleSets({
  customFormDialog: {
    '.ac-textBlock': {
      padding: '5px 0 !important'
    }
  }
});

export const TicketTemplatesSelectionComplete = (
  props: TicketTemplatesSelectionProperties
) => {
  const { ticketData, dispatchEvent } = props;
  const [selectedOption, setSelectedOption] = useState('send');
  
  const answer = ticketData['SelectedTemplate']['CardAnswerJson'] || '{}';
  const [cardAnswerJson, setCardAnswerJson] = useState(JSON.parse(answer));
  const cardPayload =
    ticketData['SelectedTemplate'] &&
    ticketData['SelectedTemplate']['CardResponseJson']
      ? JSON.parse(ticketData['SelectedTemplate']['CardResponseJson'])
      : null;
  const answerJSON = JSON.parse(answer);
  if (cardPayload) parseCard(cardPayload.body, answerJSON);
  ticketData['SelectedTemplate']['CardAnswerJson'] = JSON.stringify(answerJSON);
  if (ticketData['SelectedTemplate']['ClearFormAnswer']===undefined) ticketData['SelectedTemplate']['ClearFormAnswer'] = true;

  const onInputValueChanged = (input: Input) => {
    cardAnswerJson[input.id] = input.value;
    setCardAnswerJson(cardAnswerJson);
    ticketData['SelectedTemplate']['CardAnswerJson'] =
      JSON.stringify(cardAnswerJson);
    ticketData['SelectedTemplate']['ClearFormAnswer'] = false;
  };

  const chooseItems = [
    {
      name: 'templateType',
      key: 'SendForm',
      label: TEMPLATE.SEND_FORM,
      value: 'send'
    },
    {
      name: 'templateType',
      key: 'AnswerForm',
      label: TEMPLATE.ANSWER_FORM,
      value: 'answer'
    }
  ];

  return (
    <FluentUIThemeConsumer
      render={globalTheme => {
        return (
          <>
            <Flex
              column
              style={{ flex: 1, maxHeight: '70vh', overflow: 'auto' }}
              gap="gap.large"
            >
              <Flex.Item>
                <Flex column gap="gap.small">
                  <Text content={TEMPLATE.CHOOSE_FINAL_ACTION} weight="bold" />
                  <RadioGroup
                    vertical
                    items={chooseItems}
                    defaultCheckedValue={selectedOption}
                    onCheckedValueChange={(e, data) => {
                      setSelectedOption(data['value'].toString());
                      if (data['value'].toString() === 'answer')
                        ticketData['SelectedTemplate']['ClearFormAnswer'] = false;
                      else
                        ticketData['SelectedTemplate']['ClearFormAnswer'] = true;

                      dispatchEvent({ type: 'set-handle-customform', data: ticketData });
                    }}
                  />
                </Flex>
              </Flex.Item>
              <Flex.Item>
                <Flex
                  gap="gap.medium"
                  className={MgtCss.cardSelectionContainer}
                  {...(selectedOption != 'answer' && {
                    className: MgtCss.disabledContainer
                  })}
                >
                  <AdaptiveCardElement
                    jsonString={cardPayload ? JSON.stringify(cardPayload) : ""}
                    onInputValueChanged={onInputValueChanged}
                    cardProps={{
                      styles: { width: '50%', height: 'fit-content' }
                    }}
                    containerClassName={classNames.customFormDialog}
                  />
                </Flex>
              </Flex.Item>
            </Flex>
          </>
        );
      }}
    />
  );
};
