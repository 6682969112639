import React from 'react';
import { Button } from '@fluentui/react-northstar';
import NotFoundSVG from '../../../svg/404.svg';
import { useHistory } from 'react-router-dom';

export const PageNotFound = () => {
  const history = useHistory();
  return (
    <div className="h-screen flex items-center justify-center flex-col">
      <NotFoundSVG width={150} height={150} />
      <h1 className="font-bold text-2xl mb-3 mt-12">Error 404!</h1>
      <p className="text-sm" style={{ color: '#555770' }}>
        We can’t seem to find the page you’re looking for.
      </p>
      <Button
        content="Go Back"
        primary
        className="mt-8"
        onClick={() => {
          history.push('/');
        }}
      />
    </div>
  );
};
