import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Alert,
  CalendarIcon,
  Divider,
  Flex,
  ProviderConsumer,
  SiteVariablesPrepared,
  Text
} from '@fluentui/react-northstar';
import { range } from 'lodash';
import TicketSVG from '../../../../svg/ticket.svg';
import TickIcon from '../../../../svg/tick-icon.svg';
import CrossIcon from '../../../../svg/cross-icon.svg';
import { mergedCollaboratorAndAffectedUsers, getApprovalStatus, getDateWithDay, getShortDate, threedigitized, updateApproval, getStatus } from '../../ticketHelper';
import { RequestCardFooter } from './RequestCardFooter';
import { LastConvo } from './LastConvo';
import { TicketStatus } from '../../../shared/components/TicketCardTemplate';
import { useHistory } from 'react-router-dom';
import { GroupUsersIcon } from '../EditRequestDetails/GroupUsersCard/GroupUsersIcon';
import { ThemeColorScheme } from '../../../shared/common/TeamsTheme';

import ApprovalIcon from '../../../../svg/ticket-approval-icon.svg';
import { UserAvatar } from '../../../shared/components/UserAvatar';
import { CSS_CLASS, TicketApprovalState, toastDefault, LICENSE_FEATURE } from '../../../shared/utils/constants';
import { PlatformUser } from '../../../shared/interfaces/platformuser.interface';
import AdditionalInfoIcon from '../../../../svg/additional-info-icon.svg';
import { platformService } from '../../../shared/services/platform.service';
import { toast } from 'react-toastify';

import LifecycleIcon from '../../../../svg/lifecycle.svg';
import LifecyclePhaseIcon from '../../../../svg/lifecycle-phase-icon.svg';
import { TicketLifecyclePhaseElement } from '../../../shared/components/TicketLifecycleTab/TicketLifecyclePhaseRibbon';
import { CheckFeature } from '../../../shared/components/CheckFeature';
import './TicketRequestCard.css';
import { useTranslation } from 'react-i18next';
import { appState } from '../../../AppState';

interface Props {
  ticket: Ticket;
  view: 'list' | 'edit';
  active?: boolean;
  userId?: number;
  approvalProps?: {
    approval: Approval;
    users: PlatformUser[];
    myEmail: string;
  }
}
export interface AffectedUsersGroupInterface {
  affectedUserList: any[];
}
export const AffectedUsersGroup = ({
  affectedUserList
}: AffectedUsersGroupInterface) => {
  // [v-wishow] Complete: replace with AvatarGroup compoment to be released in Fluent UI
  // spec in Figma: https://www.figma.com/file/p5tprlOerFyzQ9YH4aMQBl/Avatar-Group-Fluent-UI?node-id=3%3A123
  return (
    <>
      {range(0, Math.min(affectedUserList?.length, 4))
        .reverse()
        .map(i => {
          if (affectedUserList.length > 4 && i === 0) {
            return (
              <Avatar
                size="small"
                key={`BoardItemUserAvatar__overflow`}
                name={`+${affectedUserList.length - 3}`}
                getInitials={name => name}
                variables={({ colorScheme }: SiteVariablesPrepared) => ({
                  borderColor: colorScheme.default.background
                })}
                styles={{ marginLeft: '-.375rem', order: i }}
              />
            );
          } else {
            return (
              <Avatar
                size="small"
                key={`BoardItemUserAvatar__${i}`}
                name={affectedUserList[i].Name}
                variables={({ colorScheme }: SiteVariablesPrepared) => ({
                  borderColor: colorScheme.default.background
                })}
                {...(affectedUserList[i].image
                  ? { image: affectedUserList[i].image }
                  : {})}
                styles={{
                  order: i,
                  ...(i > 0 ? { marginLeft: '-.375rem' } : {})
                }}
              />
            );
          }
        })}
    </>
  );
};

interface ApprovalCardProps {
  ticket: Ticket;
  view: 'list' | 'edit';
  approvalProps: {
    approval: Approval;
    users: PlatformUser[];
    userId: number;
  }
  onUpdate: (approvalId: number, approverId: number, status: TicketApprovalState, approvalState: TicketApprovalState) => void;
  active?: boolean;
}

interface ApprovalListProps {
  approval: any;
  approver: any;
  approvalProps: {
    approval: Approval;
    users: PlatformUser[];
    userId: number;
  };
  ticket: Ticket;
  onUpdate: (approvalId: number, approverId: number, status: TicketApprovalState, approvalState: TicketApprovalState) => void;
}

interface TicketHeaderProps {
  ticket: any;
  isApproval?: boolean;
}
export const getApprovalStatusName = (status: TicketApprovalState,t :any) => {
  if (status === TicketApprovalState.Approved) {
    return t('requests.approvals-columns.approved');
  } else if (status === TicketApprovalState.Rejected) {
    return  t('requests.approvals-columns.rejected');
  } else {
    return  t('requests.approvals-columns.pending');
  }
}
export const TicketHeader = ({ ticket, isApproval }: TicketHeaderProps) => {
  const currentState = appState();

  let affectedUsers = [];
  if (ticket.AffectedUsers?.length > 0)
    affectedUsers = [{ PlatformUserId: ticket.RequesterId }, ...ticket.AffectedUsers];
  
  return (
    <ProviderConsumer
      render={globalTheme => (
        <>
          <Flex space="between" vAlign="center">
            <div>
              <Flex vAlign="center" className={'cui-pt-4 cui-pv-4'}>
                <TicketSVG width={24} />
                <Text weight="semibold" className={`${!isApproval ? 'ml-1 cursor-pointer' : 'ml-1'}`} style={{ color: `${!isApproval ? globalTheme.siteVariables.colorScheme.brand.foreground : ''}` }}>
                  #{threedigitized(ticket['Id'])}
                </Text>
              </Flex>
            </div>
            <div className={`leading-none`}>
              <TicketStatus status={getStatus(ticket.StatusId, currentState.ticketStatus)} />
            </div>
          </Flex>
          <Flex className={`mt-2 mb-1`}>
            <Text content={ticket.Title} weight={'semibold'} />
          </Flex>
          {(affectedUsers.length > 0) && (<div className={'my-2'}><GroupUsersIcon maxLength={11} usersList={affectedUsers} isListing={true} /></div>)}
          <CheckFeature featureName={LICENSE_FEATURE.TicketLifecycle}>
            <TicketLifecycleDisplay ticket={ticket}/>
          </CheckFeature>
        </>
      )} />
  );
}

export const ApprovalList = ({ approval, approver, ticket, approvalProps, onUpdate }: ApprovalListProps) => {
  const api = new platformService();
  const {t} = useTranslation();
  
  const [approvalState, setApprovalState] = useState<TicketApprovalState>(approver.HasApproved);
  const [modifiedDate, setModifiedDate] = useState<string>(approver.ModifiedDate);
  const [isUpdatingState, setUpdatingState] = useState<boolean>(false);

  const isDisabled = () => ticket.Closed || ticket.IsMerged || isUpdatingState;
  
  const changeApprovedStatus = async (event: any, status: TicketApprovalState, approverId: number, approvalId: number) => {
    if (!isDisabled()) {
      event.stopPropagation();
      setUpdatingState(true);

      const result = await updateApproval(api, approval, approverId, status, t);
      result.IsError ? toast.error(result.Message, toastDefault) : toast.success(result.Message, toastDefault);

      setApprovalState(result.UpdatedStatus);
      onUpdate(approvalId, approverId, status, result.UpdatedStatus);

      const currentDate = new Date();
      setModifiedDate(currentDate.toISOString());
    }
  }

  useEffect(() => {
    const approvalStatus = getApprovalStatus(approval);
    setApprovalState(approvalStatus);
  }, [])

  return (
    <ProviderConsumer render={(globalTheme) => (
    <div style={{ position: 'relative', ...ThemeColorScheme(globalTheme.siteVariables)}}>
      <Flex space="between" className="mb-2.5">
        <Flex.Item>
          <div className="flex items-center mb-2.5 space-x-1">
            <UserAvatar {...{ idOrUpn: approver.AadObjectId ?? approver.UserName, avatarProps: { name: approver.Name, size: 'small', className: 'cui-ml-2' } }} />
            <div>
              <Text size="small">
                {approver.ApproverId == approvalProps.userId ? t('requests.approvals-columns.you') : approver.Name}
              </Text>
              <Flex vAlign="center" gap="gap.smaller">
                {approver.HasApproved === TicketApprovalState.Approved && (<Text size="small">{t('requests.approvals-columns.approved-on')} {getDateWithDay(modifiedDate)}</Text>)}
                {approver.HasApproved === TicketApprovalState.Rejected && (<Text size="small">{t('requests.approvals-columns.rejected-on')} {getDateWithDay(modifiedDate)}</Text>)}
                {approver.HasApproved === TicketApprovalState.Pending && approvalProps.approval.ApprovalState === TicketApprovalState.Pending && (<Text size="small">{t('requests.approvals-columns.pending-approval')}</Text>)}
              </Flex>
            </div>
          </div>
        </Flex.Item>
        <Flex vAlign="center" gap="gap.smaller">
          {approver.ApproverId == approvalProps.userId && approver.HasApproved === TicketApprovalState.Pending && approvalState === TicketApprovalState.Pending ? (
            <>
              <div className={`approval-button ${!isDisabled() && 'approval-button-hover cursor-pointer'}`} onClick={(e) => { changeApprovedStatus(e, TicketApprovalState.Approved, approver.ApproverId, approvalProps.approval.Id) }}>
                <TickIcon width='20' height='16'  className={`${isDisabled() && 'approvals-disabled-icon'}`} />
              </div>
              <div className={`approval-button ${!isDisabled() && 'approval-button-hover cursor-pointer'}`} onClick={(e) => { changeApprovedStatus(e, TicketApprovalState.Rejected, approver.ApproverId, approvalProps.approval.Id) }}>
                <CrossIcon width='16' height='16' className={`${isDisabled() && 'approvals-disabled-icon'}`} />
              </div>
            </>
          ) : (<></>)}
        </Flex>
      </Flex>
    </div>
    )}/>
  )
}

export const ApprovalCard = ({ ticket, active, approvalProps, onUpdate }: ApprovalCardProps) => {
  const { t } = useTranslation();
  const [approval, setApproval] = useState(approvalProps?.approval);

  const updateCard = (approvalId: number, approverId: number, status: TicketApprovalState, approvalState: TicketApprovalState) => {
    const idx = approval.Approvers.findIndex(x => x.ApproverId == approverId);
    const approver = approval.Approvers[idx];
    approver.HasApproved = status;

    setApproval(prev => ({
      ...prev,
      ApprovalState: approvalState,
      Approvers: prev.Approvers.splice(idx, 1, approver)
    }));
    
    if (onUpdate) onUpdate(approvalId, approverId, status, approvalState);
  }

  useEffect(() => {
    const approvers = approvalProps?.approval.Approvers.map((approver) => {
      const user = approvalProps.users.find((user) => user.Id === approver.Id); 
      return { ...approver, Email: user?.Email, Name: user?.FullName, UserName: user?.UserName }
    });

    const approval = !approvalProps ? undefined : {
      ...approvalProps.approval,
      Approvers: approvers
    }

    const index = approval.Approvers.findIndex(e => (e.Id === approvalProps?.userId));
    if (index > -1) {
      approval.Approvers.unshift(approval.Approvers[index]);
      approval.Approvers.splice(index + 1, 1);
    }

    setApproval(approval);
  }, []);


  return (
    <ProviderConsumer
      render={globalTheme => (
        <>
          <div
            className={`px-4 pt-4 pb-2 mt-3 filter drop-shadow-md rounded-md`}
            style={{
              backgroundColor: globalTheme.siteVariables.colorScheme.default.background,
              border: active ? '1px solid rgb(98, 100, 167)' : ''
            }}
          >
            <TicketHeader ticket={ticket} isApproval={true} />
            <Divider size={0} className={`mb-2`} />
            <div className='flex items-center w-full mb-2'>
              <Flex space="between" className="mb-2 w-full">
                <div className='flex items-center'>
                  <ApprovalIcon />
                  <Text content={`${approval.Title}`} weight={'semibold'} className={`ml-1`} />
                </div>
                <div className={`${approval.ApprovalState === TicketApprovalState.Approved ? CSS_CLASS.STATUS_PILL_ACTIVE : 
                                   approval.ApprovalState === TicketApprovalState.Pending  ? CSS_CLASS.STATUS_PILL_OTHERS : 
                                   approval.ApprovalState === TicketApprovalState.Rejected ? CSS_CLASS.STATUS_PILL_RESOLVED : ''}`}>
                  <Text weight="bold" size="smaller" content={getApprovalStatusName(approval.ApprovalState, t)} />
                </div>
              </Flex>
            </div>
            <div className='mt-3'>
              {approval.Approvers.map(approver => (
                <ApprovalList key={approver.ApproverId} onUpdate={updateCard} ticket={ticket} approvalProps={approvalProps} approval={approval} approver={approver} />
              ))}
            </div>
            {approval.IsRequiredByAll && <div className="text-xs pt-1 mb-2">
              <Alert warning content={
                <>
                    <Text weight="semibold" content={t('ticket-details.edit-ticket-form.approval.required-note')} />
                    <Text className="ml-1" size='small' content={t('ticket-details.edit-ticket-form.approval.required-approvers-response')} />
                </>} />
            </div>}
            <div className={`flex items-center justify-between`}>
              <div className='flex items-center text-xs pt-1 mb-2'>
                <Text content={
                  <div className={`flex items-center`}>
                    <AdditionalInfoIcon />
                    <span className={'ml-1'}>{t('requests.approvals-columns.additional-details')}</span>
                  </div>
                } size="small" className={`mr-1`} style={{ color: "#8F90A6" }} />
                <Text content={approval.AdditionalDetails} size="small" />
              </div>
              <div className="text-xs mb-2 pt-1">
                <Flex vAlign="center" gap="gap.smaller">
                  <div className="flex space-x-1 items-center">
                    <div>
                      <CalendarIcon style={{ color: '#8f90a6' }} />
                    </div>
                    <div style={{ color: '#8f90a6' }}>
                      {t('requests.approvals-columns.requested-on')}
                    </div>
                    <span className="ml-px">{getShortDate(approval.CreatedDate)}</span>

                  </div>
                </Flex>
              </div>
            </div>
          </div>
        </>
      )}
    />
  );
}

export const TicketRequestCard = ({ ticket, view, active, userId }: Props) => {
  const history = useHistory();
  return (
    <ProviderConsumer
      render={globalTheme => (
        <>
          <div
            className={`px-4 pt-4 pb-2 mt-3 rounded-md cursor-pointer`}
            style={{
              backgroundColor: globalTheme.siteVariables.colorScheme.default.background,
              border: active ? '1px solid rgb(98, 100, 167)' : '',
              boxShadow: '0 4px 3px rgb(0 0 0 / 0.07)'
            }}
            onClick={() => {
              history.push(`/requests/${ticket['Id']}`);
            }}
          >
            <TicketHeader ticket={ticket} />
            {ticket['Comments']?.filter(x=>x.IsPublic)?.length > 0 ? (
              <Flex>
                <LastConvo ticket={ticket} view={view} />
              </Flex>
            ) : null}
            <RequestCardFooter ticket={ticket} view={view} userId={userId} />
          </div>
        </>
      )}
    />
  );
};

export const TicketLifecycleDisplay = ({ ticket }: TicketHeaderProps) => {
  if(ticket.TicketLifecycle)
    return (
      <Flex vAlign="center" gap="gap.small" className={`mt-2 mb-1`}>
          <div className={`truncate`}>
            <LifecycleIcon
              width={12}
              height={12}
              stroke="#8f90a6"
              className="inline-block"
            />
            <Text size="small" weight="regular">
              <span style={{ color: '#8f90a6' }} className={`ml-1`}>
                {ticket.TicketLifecycle?.Lifecycle?.Title ?? ''}
              </span>
            </Text>
          </div>
          <div>
            <Flex style={{alignItems: 'center'}}>
                <LifecyclePhaseIcon
                  width={12}
                  height={12}
                  stroke="#8f90a6"
                  className="inline-block mr-1"
                />
                <TicketLifecyclePhaseElement 
                  extraClassNames={"ml-3"}
                  status={ticket.TicketLifecycle?.Status} 
                  phaseName={ticket.TicketLifecycle?.Phases[0]?.Name ?? ''}/>
              </Flex>
          </div>
        </Flex>
    );
  else
    return (<></>)
};
