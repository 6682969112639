import React from 'react';
import { useHistory } from 'react-router-dom';
import { ToolbarTheme } from '@fluentui/react-teams/lib/esm/components/Toolbar/ToolbarTheme';
import {
  Box,
  Flex,
  Text,
  Button,
  Breadcrumb,
  ChevronEndMediumIcon,
  EditIcon,
  Divider
} from '@fluentui/react-northstar';
import { threedigitized } from '../ticketHelper';
import { useTranslation } from 'react-i18next';

interface Props {
  globalTheme: any;
  id: number;
  toggleEditingToolbar?: (value: boolean) => void;
  isEditing?: boolean;
}

export const EditRequestToolbar = ({
  globalTheme,
  id,
  toggleEditingToolbar,
  isEditing
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const onEdit = () => {
    toggleEditingToolbar(true);
  };
  return (
    <ToolbarTheme globalTheme={globalTheme}>
      <Flex vAlign="center" style={{ height: '48px', padding: '0 10px' }}>
        <Flex.Item grow>
          <Box>
            <Breadcrumb aria-label="breadcrumb">
              <Breadcrumb.Item>
                <Breadcrumb.Link
                  href="/requests"
                  onClick={e => {
                    e.preventDefault();
                    history.push('/requests');
                  }}
                >
                  <Text
                    content={'Tickets'}
                    weight="semibold"
                    styles={{
                      alignItems: 'center',
                      display: 'inline-flex'
                    }}
                  />
                </Breadcrumb.Link>
              </Breadcrumb.Item>

              <Breadcrumb.Divider>
                <ChevronEndMediumIcon />
              </Breadcrumb.Divider>
              <Breadcrumb.Item>
                <Text
                  content={`${t('requests.view-ticket')} #${threedigitized(id)}`}
                  weight="bold"
                />
              </Breadcrumb.Item>
            </Breadcrumb>
          </Box>
        </Flex.Item>
        {!isEditing && (
          <Flex.Item>
            <>
              <Divider vertical />
              <Button
                primary
                text
                icon={<EditIcon size="medium" />}
                onClick={onEdit}
                title="Edit"
                size="medium"
                iconOnly
                className={`ml-1 lg-hidden-important`}
                style={{
                  color: globalTheme.siteVariables.colorScheme.brand.foreground
                }}
              />
            </>
          </Flex.Item>
        )}
      </Flex>
    </ToolbarTheme>
  );
};
