import React, { useState } from 'react';
import { Flex, Skeleton, ThemePrepared, ChevronEndIcon, ChevronDownIcon } from '@fluentui/react-northstar';
import { TicketTemplateAnswer } from '../TicketTemplateAnswer';
import { Ticket } from '../../interfaces/ticket.interface';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  isDisabled: boolean;
  onCLose: (value: boolean) => void;
  primaryButtonColor: string;
  isSaving: boolean;
  globalTheme: ThemePrepared<any>;
  ticketId: number;
  parentData?: Ticket;
  isEndUserForm?: boolean;
}

export const EditTicketCustomFormAnswers = ({
  isOpen,
  isDisabled,
  onCLose,
  primaryButtonColor,
  isSaving,
  globalTheme,
  ticketId,
  parentData,
  isEndUserForm
}: Props) => {
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const getColor = () => {
    const theme = globalTheme.siteVariables.colorScheme;
    return (parentData?.TemplateId <= 0 || parentData["Template"]["NoCustomForm"]) ? theme.default.foregroundDisabled : theme.default.foreground
  }

  return (
    <>
      <Flex
        vAlign="center"
        space="between"
        className={`mt-7 pb-4 cursor-pointer`}
        onClick={() => {onCLose(!isOpen)}}
        style={{
          borderBottom: isOpen ? `1px solid ${primaryButtonColor}` : 'none',
          pointerEvents: parentData?.TemplateId <= 0 || parentData["Template"]["NoCustomForm"] ? 'none' : 'auto',
          color: `${getColor()}`
        }}
      >
        <Flex gap="gap.small" vAlign="center">
          {isOpen ? <ChevronEndIcon style={{ color:`${getColor()}`}} /> : <ChevronDownIcon style={{ color:`${getColor()}`}} /> }
          <Flex style={{ fontWeight: 400, fontSize: '16px', lineHeight: '24px' }} >
            {t('ticket-details.edit-ticket-form.custom-form-answer.title')}
          </Flex>
        </Flex>
      </Flex>

      {isOpen && <>
        {isLoading && (
          <>
            <Skeleton animation="wave">
              <TicketTemplateAnswer
                globalTheme={globalTheme}
                ticketId={ticketId}
                parentData={parentData}
                isSavingState={isSaving}
                isDisabled={isDisabled}
                width={100}
                isEndUserForm={isEndUserForm}
              />
            </Skeleton>
          </>
        )}
      </>}
    </>
  );
};
