import React, { useEffect, useState, useContext } from 'react';
import { platformService } from '../../../../shared/services/platform.service';
import { TicketApprovalCard } from '../../../../shared/components/EditTicketApprovals/TicketApprovalCard';
import { appState, useSetState } from '../../../../AppState';
import { TicketApprovalsSkeleton } from '../../../../shared/components/EditTicketApprovals/TicketApprovalsSkeleton';
import { getCachedFeature } from '../../../../shared/cache/FeatureCache';
import { FEATURE_NOT_AVAILABLE, LICENSE_FEATURE, MyWorkListViewType, STATUS_GUID, TicketApprovalState, toastDefault } from '../../../../shared/utils/constants';
import { getShowClosedFromStorage } from '../../../MyWork/MyWorkList/myWorkListHelper';
import { MyWorkContext } from '../../../MyWork/MyWorkContextProvider';
import { EmptyData } from '../../../../shared/components/EmptyData';
import EmptySVG from '../../../../../svg/empty.svg';
import { Route } from '../../../../../route-constants';
import { useHistory, useLocation } from 'react-router-dom';
import { UpgradeNeeded } from '../../../../shared/components/UpgradeNeeded';
import { Flex, Text } from '@fluentui/react-northstar';
import TicketSVG from '../../../../../svg/ticket.svg';
import { threedigitized, updateApproval, updateApprovalList } from '../../../ticketHelper';
import CSS from '../TemplateCard.module.css';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const api = new platformService();

const ApprovalsTemplate = () => {
  const currentState = appState();
  const setAppState = useSetState();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLifecycleEnabled, setIsLifecycleEnabled] = useState<boolean>(false);
  const [isApprovalsFeatureAvailable, setIsApprovalsFeatureAvailable] = useState<boolean>(false);
  const [ticketApprovals, setTicketApprovals] = useState<TicketApprovals[]>([]);
  const [currentUserId, setCurrentUserId] = useState<number>(null);
  const { myWorkStates } = useContext(MyWorkContext);
  const location = useLocation();
  const history = useHistory();
  
  const isMyGroupWork = location.pathname.includes(Route.myGroupWork.pagePath);
  const isClosed = getShowClosedFromStorage(isMyGroupWork) ?? myWorkStates.showClosed;

  const {t} = useTranslation();
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const me = (currentState?.me != null) ? currentState.me : await api.me();
        let userIdClaim = me.data.Claims.find((claim: any) => claim.startsWith('Cireson.Platform.UserId:'));
        let platformUserId = (userIdClaim != null) ? parseInt(userIdClaim.split('Cireson.Platform.UserId: ')[1], 10) : 0;
        setCurrentUserId(platformUserId);

        const [isapprovalsEnabled, isLifecyclesEnabled] = await Promise.all([
          getCachedFeature(LICENSE_FEATURE.Approvals),
          getCachedFeature(LICENSE_FEATURE.TicketLifecycle)
        ]);

        setIsLifecycleEnabled(isLifecyclesEnabled);
        setIsApprovalsFeatureAvailable(isapprovalsEnabled);

        const phaseFilter = `(Approval/TicketLifecyclePhaseId eq null ${isLifecyclesEnabled ? ' or Approval/TicketLifecyclePhase/IsCurrent eq true' : ''})`;
        const approvalFilter = isClosed ?
          `(Ticket/Closed eq true or Ticket/Status/Guid eq ${STATUS_GUID.RESOLVED} or Approval/ApprovalState ne ${TicketApprovalState.Pending})` :
          `(Ticket/Closed eq false and Ticket/Status/Guid ne ${STATUS_GUID.RESOLVED} and Approval/ApprovalState eq ${TicketApprovalState.Pending})`;
        const filter = `Approval/Approvers/any(ap: ap/Approver/Id eq ${platformUserId}) and ${approvalFilter} and ${phaseFilter}`;
        let approvals: TicketApprovals[] = isapprovalsEnabled ? await api.getApprovalsByEmail(filter) : [];
        setTicketApprovals(approvals);
        setIsLoading(false);

      } catch (error) {
        setIsLoading(false);
        console.log('Error: ', error);
      }
    })();
  }, [isClosed]);

  const onApprovalClick = (ticketId: number, approvalId: number) => {
    setAppState((prev) => ({
      ...prev,
      isRouteFromMyWork: true,
      selectedTaskOrApproval: { type: MyWorkListViewType.Approvals, id: approvalId },
    }));
    const route = isMyGroupWork ? Route.myGroupWork.editTicket : Route.myWork.editTicket;
    const path = `${route}/${ticketId}`;
    const from = isMyGroupWork ? 'mygroupwork' : 'mywork';
    history.push(path, { from });
  }

  const updateApprovalStatus = async (approval: Approval, approverId: number, status: TicketApprovalState) => {
    try {
      const result = await updateApproval(api, approval, approverId, status, t);
      result.IsError ? toast.error(result.Message, toastDefault) : toast.success(result.Message, toastDefault);
      refreshApprovalList(approval.Id, approverId, status, result.UpdatedStatus);
    } catch (e) {
      toast.error(t('ticket-details.edit-ticket-form.approval.error.update'), toastDefault);
      console.error(e);
    }
  }

  const refreshApprovalList = (approvalId: number, approverId: number, state: TicketApprovalState, updatedStatus: TicketApprovalState) => {
    const updatedApprovals = updateApprovalList(ticketApprovals, approverId, approvalId, state, updatedStatus);
    if (!isClosed && updatedStatus != TicketApprovalState.Pending) {
      const indexTobeRemoved = updatedApprovals.findIndex((o) => o.Approval.Id === approvalId);
      if (indexTobeRemoved > -1) updatedApprovals.splice(indexTobeRemoved, 1);
    }
    setTicketApprovals(updatedApprovals);
  }

  return (
    <>
      {isLoading ? <TicketApprovalsSkeleton /> : <>
        {isApprovalsFeatureAvailable && (ticketApprovals?.length > 0 ? (
          ticketApprovals.map((approval: TicketApprovals) => (
            <div onClick={() => onApprovalClick(approval.Ticket.Id, approval.Approval.Id)} className='mb-2 cursor-pointer'>
              <div className={`${CSS.ticketTaskCard} p-4`}>
                <Flex vAlign="center">
                  <TicketSVG width={24} className={`mr-1`} />
                  <Text weight="semibold">
                    #{threedigitized(approval.Ticket.Id)}
                  </Text>
                </Flex>
                <div>
                  {approval.Ticket.Title}
                </div>
                <TicketApprovalCard
                  key={approval.Approval.Id}
                  approval={approval.Approval}
                  currentUserId={currentUserId}
                  isTicketClosed={approval.Ticket.Closed}
                  updateApprovalStatus={updateApprovalStatus}
                  isLifecycleEnabled={isLifecycleEnabled}
                  disableButonOnUpdate={true}
                />
              </div>
            </div>
          ))
        ) : (
          <EmptyData
            headerText={t('my-work.my-ticket.no-approval')}
            SVGIcon={<EmptySVG width={75} height={75} />}
          />
        ))}
        {!isApprovalsFeatureAvailable && <div className='flex flex-col justify-around h-full w-full'>
          <UpgradeNeeded
            headerText={FEATURE_NOT_AVAILABLE.ApprovalHeader}
            subheaderText={FEATURE_NOT_AVAILABLE.ApprovalSubHeader}
          />
        </div>}
      </>}
    </>
  );
};

export default ApprovalsTemplate;
