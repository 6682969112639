import React from 'react';
import Styles from './Arrow.module.css';

interface Props {
  height: number;
}

export const Arrow = ({ height }: Props) => (
  <div className={Styles.Line} style={{ height: height }}>
    <i className={`${Styles.Arrow} ${Styles.Up}`}></i>
  </div>
);
