import React, { useCallback, useEffect, useState } from 'react';
import Styles from '../../../Automation.module.css';
import { Button, Dropdown, FormLabel,ProviderConsumer as FluentUIThemeConsumer } from '@fluentui/react-northstar';
import { FormGroup } from '../../../Automation';
import {
  DEFAULT_API_STATUS,
  DONE,
  ERROR
} from '../../../../shared/utils/constants';
import { platformService } from '../../../../shared/services/platform.service';
import { splitString, toFriendlyName } from '../../../../shared/utils/helper';
import { useTranslation } from 'react-i18next';

interface Props {
  copyGlobalRule: (value: string) => void;
  globalRule: string;
  updateGlobalRule: (value: string) => void;
}

export const GlobalRules = ({
  copyGlobalRule,
  globalRule,
  updateGlobalRule
}: Props) => {
  const {t} = useTranslation();
  const api = new platformService();
  const [apiStatus, setAPIStatus] = useState<APIStatus>(
    DEFAULT_API_STATUS as APIStatus
  );

  const [globalRules, setGlobalRules] = useState<DropdownDataModel[]>([]);
    const fetchGlobalRules = useCallback(async () => {
    try {
      const {
        data: { value }
      } = await api.getGlobalRules();

      const globalRules: DropdownDataModel[] = value.map((val: any) => ({
        key: val['Title'],
        label: splitString(val['Title']),
        header: splitString(val['Title'])
      }));

      setGlobalRules(globalRules);
      setAPIStatus({ ...apiStatus, status: DONE });
    } catch (err: any) {
      setAPIStatus({
        ...apiStatus,
        status: ERROR,
        errCode: err.response.status
      });
    }
  }, []);
  useEffect(() => {
    fetchGlobalRules();
  }, []);
  
  return (
    <FluentUIThemeConsumer
      render={globalTheme => (
        <FormGroup>
        <FormLabel content={t('automation.global-rules')} styles={{ fontSize: '12px' }} />
        <div className={Styles.GlobalRules}>
          <div className={Styles.GlobalRules1}>
            <Dropdown
              inverted
              fluid
              items={globalRules}
              placeholder={t('automation.global-rule')}
              value={globalRule == '' ? globalRule : toFriendlyName(globalRule)}
              style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
              onChange={(e, p) => {
                const data = p.value as DropdownDataModel;
                updateGlobalRule(data.key);
              }}
            />
          </div>
          <div className={Styles.GlobalRules2}>
            <Button
              content={
                <span style={{ color: globalRule === '' ? '#e5e5e5' : `${globalTheme.siteVariables.colorScheme.brand.foreground}` }}>
                  {t('automation.copy')}
                </span>
              }
              text
              primary
              disabled={globalRule === ''}
              onClick={() => {
                copyGlobalRule(globalRule);
              }}
            />
          </div>
        </div>
      </FormGroup>
  
      )}>

      </FluentUIThemeConsumer>
     );
};
