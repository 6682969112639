import React, { useState } from 'react';
import Styles from '../styles/TransitionRuleGroup.module.css';
import {
  Dropdown,
  Flex,
  FormLabel,
  ProviderConsumer
} from '@fluentui/react-northstar';
import { LIFECYCLE_ACTION_DROPDOWN, LIFECYCLE_ACTION_TYPE_LABELS, TransitionActionTypeString, LifecycleOdataTypes } from '../utils/constants';
import { TransitionAction, TransitionItem } from '../utils/typings';
import { LabelElement } from '../../shared/components/Modal/Modal';
import { LIFECYCLE } from '../../shared/utils/constants';

interface Props {
  data: TransitionAction;
  updateData: (data: TransitionAction) => void;
  phasesOptions: DropdownDataModel[];
}

export const PhaseTransitionAction = ({
  data,
  data: { ActionType },
  updateData,
  phasesOptions
}: Props) => {

  const [actionType, setActionType] = useState(ActionType);
  const [transitionItem, setTransitionItem] = useState<TransitionItem>(data.TransitionItem);

  return (
    <ProviderConsumer
      render={(globalTheme) => (
        <div className={`ml-10`} >
        <h2 className={`${Styles.SubHeading2} mb-4`} style={{ fontWeight: 'bold'}}>{LIFECYCLE.ACTION}</h2>
        
          <Flex gap="gap.small" >
          <Flex.Item size="size.half">
            <div className={`w-full`}>
              <FormLabel content={LIFECYCLE.MOVE} styles={{ fontSize: '12px' }} />
              <Dropdown
                inverted
                fluid
                items={LIFECYCLE_ACTION_DROPDOWN}
                placeholder={LIFECYCLE.SELECT_MOVE}
                value={LIFECYCLE_ACTION_TYPE_LABELS[actionType.toString()]}
                onChange={(e, p) => {
                  const item = p.value as DropdownDataModel;
                  setActionType(item.key);
                  setTransitionItem(null);
                  updateData({
                      ...data,
                      ActionType: item.key,
                      TransitionItem: null,
                      TransitionItemId: null
                  });
                }}
                style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
              />
            </div>
          </Flex.Item>

          <Flex.Item size="size.half">
            <div className={`w-full`}>
            {(ActionType.toString() == TransitionActionTypeString.MoveToPhaseX) && (
              <>
              <LabelElement label={LIFECYCLE.PHASE} required={true}></LabelElement>
              <Dropdown
                inverted
                fluid
                items={phasesOptions}
                placeholder={LIFECYCLE.SELECT_PHASE}
                value={transitionItem?.Name ?? ""}
                onChange={(e, p) => {
                  const item = p.value as DropdownDataModel;
                  const newItem:TransitionItem = {
                    Id: item.Id,
                    Guid: item.key,
                    Name: item.label,
                    "@odata.type": LifecycleOdataTypes.Phase
                  };
                  setTransitionItem(newItem);
                  updateData(
                    {
                      ...data,
                      TransitionItemId: newItem.Id,
                      TransitionItem: newItem
                    }
                  )
                }}
                style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
              />
              </>
            )}
            </div>
          </Flex.Item>
        </Flex>
      </div>
      )}
    />
  );
};
