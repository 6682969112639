import { Flex, Text } from '@fluentui/react-northstar';
import React from 'react';

const Placeholder = ({ message }: { message: string }) => {
  return (
    <Flex
      vAlign="center"
      hAlign="center"
      styles={{
        height: '100%',
        fontSize: '4rem',
        background: '#3D3E66',
        color: 'white'
      }}
    >
      <Text weight="bold">{message}</Text>
    </Flex>
  );
};

export { Placeholder };
