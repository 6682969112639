import React, { useState, useEffect } from 'react';
import Styles from '../shared/components/Modal/Modal.module.css';

import {
  Button,
  Checkbox,
  Datepicker,
  Dialog,
  Flex,
  Input,
  Loader,
  Text,
  ProviderConsumer as FluentUIThemeConsumer,
} from '@fluentui/react-northstar';
import { Communication, themeNames } from '@fluentui/react-teams';
import { Providers } from '@microsoft/mgt-element/dist/es6/providers/Providers';
import {
  BUTTONS,
  CONTACT_ADMIN,
  DEFAULT,
  ERROR,
  getModalHeading,
  getModalSubheading,
  AGENTS,
  KNOWLEDGEAGENT,
  LOADING,
  NAME,
  REFRESH_SESSION,
  SOMETHING_WENT_WRONG,
  UNAUTHORIZED_ACCESS
} from '../shared/utils/constants';
import { toTitleCase } from '../shared/utils/helper';
import { LoadingScreen } from '../shared/components/LoadingScreen';
import { useTranslation } from 'react-i18next';
import { DatepickerLocalizations } from '../App';

interface PopupProps {
  open: boolean;
  isView: boolean;
  inputValue: string;
  expiration: string;
  checkBoxValue?: boolean;
  showCheckBox?: boolean;
  name: string;
  apiStatus: APIStatus;
  onCancelHandler: () => void;
  onConfirmationHandler?: (value: any) => void;
  applicationRoles: any[]
}

export const LabelElement = (props: any) => {
  return (
    <div style={({ ...(props.styles ? props.styles : {}) })}>
      <Flex>
        <Text content={props.label} style={{ display: 'block' }} />
        {props.required && (
          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
        )}
      </Flex>
      <div>{props.children}</div>
    </div>
  );
};

export const TokenModal = ({
  open,
  isView,
  name,
  apiStatus,
  inputValue = '',
  expiration = '',
  checkBoxValue = false,
  showCheckBox = true,
  onCancelHandler,
  onConfirmationHandler,
  applicationRoles
}: PopupProps) => {
  const {t} = useTranslation();
  const errConfig = {
    fields: {
      title:
        apiStatus.errCode === 401
          ? UNAUTHORIZED_ACCESS
          : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  };

  const [input, setInput] = useState(inputValue);
  const [picker, setPicker] = useState('');
  const [checkbox, setCheckbox] = useState(checkBoxValue);
  const [toggleRoles, setToggleRoles] = useState(true);
  const [style, setStyle] = useState({ color: '#fff', background: '#5558af' });
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    setInput(inputValue);
    setCheckbox(checkBoxValue);
  }, [inputValue, checkBoxValue]);

  useEffect(() => {
    if (!open) {
      setInput('');
      setCheckbox(false);
    }

    setRoles(applicationRoles.map((r, i) => { return { id: i, name: r, checked: true } }));
  }, [open, isView, applicationRoles]);

  const allToggleRoles = () => {
    setToggleRoles(!toggleRoles);
    if (!toggleRoles) {
      setStyle({ color: '#fff', background: '#5558af' });
      setRoles(roles.map((r, i) => {
        r.checked = true;
        return r;
      }));
    } else {
      setStyle({ color: '#000', background: '#fff' });
      setRoles(roles.map((r, i) => {
        r.checked = false;
        return r;
      }));
    }
  }

  const ApplicationRolesElement = () => {
    const roleMappingToElement = roles.map((role, index) => {
      const [roleIndex] = useState(index);
      return (
        <FluentUIThemeConsumer
          render={globalTheme => (
            <div className={`${globalTheme.siteVariables.theme === themeNames.Dark ? Styles.RoleItemDark: Styles.RoleItem}`}>
              <Flex
                space="between"
                className={`${globalTheme.siteVariables.colorScheme.default.background} ${globalTheme.siteVariables.colorScheme.default.foreground}`}
              >
                <Flex.Item size="small">
                  <div style={{ paddingTop: '0.25rem', paddingLeft: '5px' }}>
                    {t(`security.roles.${role.name.toLowerCase()}`)}
                  </div>
                </Flex.Item>
                {!isView && (
                  <Flex.Item>
                    <Checkbox
                      id={'_role' + index.toString()}
                      checked={roles[roleIndex].checked}
                      onClick={() => {
                        roles[roleIndex] = {
                          ...roles[roleIndex],
                          checked: !roles[roleIndex].checked
                        };
                        let rolechecking = roles.every(
                          r => r.checked === roles[roleIndex].checked
                        );

                        if (rolechecking && roles[roleIndex].checked) {
                          setToggleRoles(true);
                          setStyle({ color: '#fff', background: '#5558af' });
                        } else {
                          setToggleRoles(false);
                          setStyle({ color: '#000', background: '#fff' });
                        }
                        setRoles(roles);
                      }}
                    />
                  </Flex.Item>
                )}
              </Flex>
            </div>
          )}
        />
      );
    });

    return (
      <FluentUIThemeConsumer
        render={globalTheme => (
          <>
            <Flex space="between">
              <Flex.Item size="small">
                <Text
                  content={t('access-token.application-roles')}
                  style={{ display: 'block', fontWeight: 600 }}
                />
              </Flex.Item>
              {!isView && (
                <Flex.Item>
                  <div style={{ width: 42 }}>
                    <div
                      style={{
                        padding: 5,
                        cursor: 'pointer',
                        height: 25,
                        fontSize: 12,
                        textAlign: 'center',
                        width: 35,
                        color: style.color,
                        background: style.background,
                        borderRadius: 4
                      }}
                      onClick={() => allToggleRoles()}
                    >
                      {t('access-token.role')}
                    </div>
                  </div>
                </Flex.Item>
              )}
            </Flex>
            <div>{roleMappingToElement}</div>
          </>
        )}
      />
    );
  };

  return (
    <Dialog
      open={open}
      closeOnOutsideClick={false}
      footer={
        <Flex hAlign="end" gap="gap.smaller">
          <Button
            content={toTitleCase(BUTTONS.CANCEL)}
            onClick={onCancelHandler}
            secondary
            disabled={apiStatus.status === LOADING}
          />
          {(!isView && <Button
            content={
              toTitleCase(BUTTONS.SAVE)
            }
            onClick={() => {
              onConfirmationHandler && onConfirmationHandler({ Name: input, Expiration: picker, RequestedRoles: ['Administrators', 'Analysts'] });
            }}
            primary
            disabled={input === '' || picker === '' || apiStatus.status === LOADING}
          />)}
        </Flex>
      }
      content={
        <div className={Styles.Container}>
          <h6 className={Styles.SubHeading}>
            {isView ? t('access-token.access-token-details') : getModalSubheading(false, name)}
          </h6>
          {apiStatus.status === LOADING ? (            
            <LoadingScreen message={apiStatus.msg} height="100%" />
          ) : apiStatus.status === ERROR ? (
            <>
              <div style={{ height: '100%' }}>
                <Communication {...errConfig} />
                {apiStatus.errCode === 401 && (
                  <Button
                    content={REFRESH_SESSION}
                    primary
                    onClick={e => {
                      Providers.globalProvider.login();
                    }}
                  />
                )}
              </div>
            </>
          ) : (
            <Flex column gap="gap.small">
              <LabelElement label={toTitleCase(NAME)} {...(!isView && {required: true})} />
              <div className="teams-input-med">
                <Input
                  {...(!isView && {clearable: true})}
                  value={input}
                  onChange={e => {
                    setInput((e.target as HTMLInputElement).value);
                  }}
                  disabled={isView}
                  fluid
                />
              </div>
              <LabelElement label={t('access-token.expiration-date')} {...(!isView && {required: true})} />
              <div className="teams-input-med">
                {isView ? <Input disabled={true} fluid value={expiration} /> 
                        : <Datepicker {...DatepickerLocalizations} firstDayOfWeek={0} onDateChange={(e, p) => { setPicker(p.value.toISOString()); }} />}
              </div>
              <div style={{ overflowY: 'scroll', overflowX: 'hidden', height: 120 }}>
                <ApplicationRolesElement />
              </div>
              {showCheckBox && (
                <Checkbox
                  checked={checkbox}
                  label={toTitleCase(DEFAULT)}
                  onChange={() => {
                    setCheckbox(prevState => !prevState);
                  }}
                />
              )}
            </Flex>
          )}
        </div>
      }
      header={isView ? t('access-token.access-token') : getModalHeading(false, name)}
      style={{ maxWidth: '550px' }}
    />
  );
};
