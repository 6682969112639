import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { platformService } from "../shared/services/platform.service";
import { CheckLogin } from "../shared/components/CheckLogin";
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";
import { AppStateContext } from "../AppState";
import { LoadingScreen } from "../shared/components/LoadingScreen";

export const GetAiFileFlow: React.FC = () => {
    const {container, fileName} = useParams<any>();
    const api = new platformService();
    const [isDownloaded, setIsDownloaded,] = useState<boolean>(false);
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);

    const loadPage = async () => {
        if (!isDownloaded) {
            await api.getAiFile(container, fileName);
            setIsDownloaded(true);
            setIsLoading(false);
        }
    };

    return (
        <AppStateContext.Consumer>
            {(state) => {
                return (
                    <CheckLogin onSignedIn={loadPage}>
                        <Helmet>
                            <title>{t('ai-configuration.downloadfile')}</title>
                        </Helmet>
                        <div className="hidden md:block">
                        {
                            (isLoading) ? 
                            <LoadingScreen message={`${t('retrieving')} ${fileName}`} />: <div></div>
                        }
                        </div>
                    </CheckLogin>
                )
            }}
        </AppStateContext.Consumer>
    );
}