import React from 'react';
import MgtCss from '../../../mgt.module.css';

import _ from 'lodash';
import { Box, Flex, Text } from '@fluentui/react-northstar';
import { Person } from '@microsoft/mgt-react';
import RelativeDate from '../../../shared/components/RelativeDate';
import { CardTemplate } from './CardTemplate';

export const EmailTemplate = ({ dataContext: email }: MgtTemplateProps) => {
  const personDetails = {
    displayName: email.sender.emailAddress.name,
    mail: email.sender.emailAddress.address
  };

  return (
    <CardTemplate styles={{ borderRadius: 4 }}>
      <div className={MgtCss.MgtCard}>
        <Flex vAlign="center" className={MgtCss.emailHeader}>
          <Box>
            <Person
              personDetails={personDetails}
              fetchImage
              avatarSize="small"
            ></Person>
          </Box>
          <Box style={{ marginLeft: 10, marginTop: -5 }}>
            <Text weight="semibold" size="medium">
              {email.sender.emailAddress.name}
            </Text>
            <RelativeDate date={email.sentDateTime} className={'caption'} />
          </Box>
        </Flex>
        <a
          className={MgtCss.emailLink}
          style={{ textDecoration: 'none' }}
          href={email.webLink}
          target={'_blank'}
        >
          <h3>{email.subject}</h3>
        </a>
        <div className="preview">
          {!_.isNil(email.bodyPreview)
            ? email.bodyPreview
            : 'email body is empty'}
        </div>
      </div>
    </CardTemplate>
  );
};
