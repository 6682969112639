import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { useParams } from 'react-router-dom';
import { useSetState } from '../AppState';
import { BrowserAppTemplate } from '../browser-pages/BrowserAppTemplate';
import { Security } from './Security';

interface SettingParam {
  settingsKey: string;
}

export const SecurityPage = () => {
  const setState = useSetState();
  const { settingsKey } = useParams<SettingParam>();
  const { url } = useRouteMatch();
  let securityKey = url.split('/').pop();
 
  //if superagents is selected, set the security key to analysts
  if(securityKey.toLowerCase()=='superagents'){securityKey='analysts'}
 
  useEffect(() => {
    setState(state => {
      var newstate = {
        ...state,
        isLoading: false
      };
      if(state.isSidenavChanged) {
        newstate = {
          ...newstate
        }
      } else {
        newstate = {
          ...newstate,
          isSidenavCollapsed: true,
          isSidenavChanged: true,
        }
      }
      return newstate;
    });
  }, []);

  return (
    <BrowserAppTemplate
      page={<Security securityKey={securityKey} />}
      active={settingsKey}
    />
  );
};
