import React, { useCallback, useState } from 'react';
import { Button, Text, MoreIcon, MenuButton, Flex, Dialog, LinkIcon } from '@fluentui/react-northstar';
import { platformService } from '../shared/services/platform.service';
import { Communication } from '@fluentui/react-teams';
import { Providers } from '@microsoft/mgt-element/dist/es6/providers/Providers';
import { Breadcrumbs } from '../shared/components/BreadcrumbNavigation';
import {
  DEFAULT_API_STATUS,
  DONE,
  ERROR,
  GETTING_ALL_TEMPLATES,
  TEMPLATES,
  TEMPLATE_HEADERS,
  LOADING,
  SETTINGS,
  NEW_TEMPLATE,
  DELETE,
  SAVING_TEMPLATE_DATA,
  toastDefault,
  TREE_LISTING_CONSTANTS,
  BUTTONS,
  UNARCHIVE_MODAL_HEADER,
  ARCHIVE_CONFIRMATION_SUBTITLE,
  TEMPLATE,
  UNARCHIVE_CONFIRMATION_SUBTITLE,
  ARCHIVE_MODAL_HEADER,
  SOMETHING_WENT_WRONG,
  UNAUTHORIZED_ACCESS,
  CONTACT_ADMIN,
  REFRESH_SESSION,
  SETTINGS_PAGES
} from '../shared/utils/constants';
import { toTitleCase } from '../shared/utils/helper';
import { KBListWrapper } from '../kb/KBListWrapper';
import { TicketTemplate } from '../shared/interfaces/ticket.interface';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { TicketTemplatesDialog } from './TicketTemplatesDialog';
import ArchiveIcon from '../../svg/archive-icon.svg';
import UnArchiveIcon from '../../svg/Un-archive.svg';
import { useHistory } from 'react-router-dom';
import { FontIcon } from '@fluentui/react';
import { CheckLogin } from '../shared/components/CheckLogin';
import { Helmet } from "react-helmet";
import { LoadingScreen } from '../shared/components/LoadingScreen';
import { MobileSettingsView } from '../automation/AutomationTable';
import Style from './template.module.css';
import { CheckLicense } from '../shared/components/License/CheckLicense';
import useCurrentPage from '../kb/useCurrentPage';
import { useTranslation } from 'react-i18next';
import {UpgradeNeeded} from '../shared/components/UpgradeNeeded';
import { LICENSE_FEATURE  } from '../shared/utils/constants';
import { getCachedFeature } from '../shared/cache/FeatureCache';

export const TicketTemplatesTable = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const api = new platformService();
  const [data, setData] = useState<RowsData[]>([]);
  const [counts, setCounts] = useState([]);
  const [apiStatus, setApiStatus] = useState<APIStatus>(
    DEFAULT_API_STATUS as APIStatus
  );
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<TicketTemplate>({} as TicketTemplate);
  const {currentPage, setCurrentPage} = useCurrentPage()
  const [isFeatureEnabled, setIsFeatureEnabled] = useState(true);
  

  const fetchData = useCallback(async () => {
    setApiStatus({ ...apiStatus, msg: GETTING_ALL_TEMPLATES });

    try {
      const result = await Promise.all([
        api.getTicketTemplates('?$orderby=CreatedDate desc'),
        api.getTemplateCountByTicket()
      ]);

      const templates = result[0].data.value;
      const ticketCount = result[1];
      const rowData: RowsData[] = templates.map((template: TicketTemplate) => (setTemplate(template, ticketCount)));
      setData(rowData);
      setCounts(ticketCount);
    } catch (err: any) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: (err.response) ? err.response.status : ""
      });
    } finally {
      setApiStatus({ ...apiStatus, status: DONE });
    }
  }, []);

  const loadPage = () => {
    fetchData();
    (async () => setIsFeatureEnabled((await getCachedFeature(LICENSE_FEATURE.TicketTemplates))))();
  }

  const getTemplateTicketCount = (id: number, ticketCount: any[]) => {
    const cnt = ticketCount.filter(x => x["TemplateId"] == id) || [];
    if (cnt.length > 0)
      return cnt[0]["Count"];
    return 0;
  }

  const getMenuItems = (template: TicketTemplate) => {
    const items = [
      {
        content: "Edit",
        icon: <FontIcon iconName={"Edit"} className={`text-lg`} style={{ lineHeight: "unset" }} />,
        onClick: (e) => {
          e.stopPropagation();
          history.push(`/settings/templates?id=${template.Id}`);
        }
      },
      {
        content: template.IsArchived ? TREE_LISTING_CONSTANTS.unArchive : TREE_LISTING_CONSTANTS.archive,
        icon: template.IsArchived ? <UnArchiveIcon className={`${Style.svgIcon}`} /> : <ArchiveIcon className={`${Style.svgIcon}`} />,
        onClick: (e) => {
          e.stopPropagation();
          setSelectedTemplate(template);
          setArchiveDialogOpen(true);
        }
      }
    ];
    if (!template.IsArchived) {
      items.push({
        content: t('common.direct-link'),
        icon: <LinkIcon outline />,
        onClick: (e) => {
          e.stopPropagation();
          (async () => {
            await navigator.clipboard.writeText(`https://${window.location.host}/knowledge/template/${template.Id}`);
            toast.success(t('common.link-copied'))
          })();
        }
      });
    }
    return items;
  }

  const setTemplate = (template: TicketTemplate, ticketCount: any[]) => {
    return {
      id: template.Id,
      nameSearch: template.Name,
      [t('template.template-headers.name')]: (
        <Flex gap="gap.medium" vAlign="center">
          <Text content={template.Name} />
          {template.IsArchived && (
            <Text content={TREE_LISTING_CONSTANTS.archived} size="smaller" weight="light" disabled />
          )}
        </Flex>
      ),
      [t('template.template-headers.description')]: <Text content={template.Description} />,
      [t('template.template-headers.ticket-count')]: getTemplateTicketCount(template.Id, ticketCount),
      [t('template.template-headers.created-date')]: DateTime.fromJSDate(new Date(template.CreatedDate)).toLocaleString(DateTime.DATETIME_SHORT),
      "": (
        <>
          <CheckLicense disable>
          <MenuButton
            trigger={
                <Button icon={<MoreIcon />} text iconOnly title="Actions" onClick={(e) => {
                  e.stopPropagation();
                }} />
            }
            menu={{
              items: getMenuItems(template)
            }}
          />
          </CheckLicense>
        </>
      )
    }
  };

  const deleteTemplate = async (temp: TicketTemplate) => {

    try {
      setData((currentRows: RowsData[]) => {
        const rows = currentRows.filter(x => x.id != temp.Id);
        return rows;
      });
      await api.deleteTicketTemplate(temp.Id);
    } catch (err) {
      setData((currentRows: RowsData[]) => {
        currentRows.push(setTemplate(temp, counts));
        return currentRows;
      });
      toast.error(`There was an error deleting Template "${temp.Name}"`, toastDefault);
    } finally {
      toast.success(`Template "${temp.Name}" is deleted.`, toastDefault);
    }
  };

  const archiveTemplate = async (temp: TicketTemplate) => {
    try {
      const apiData: TicketTemplate = {
        ...temp,
        IsArchived: !temp.IsArchived,
      };
      await api.updateTicketTemplate(apiData);
      fetchData();
    } catch (err: any) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: (err.response) ? err.response.status : ""
      });
    }
  };

  const navs: Breadcrumbs = {
    breadcrumbs: [
      {
        title: toTitleCase(SETTINGS),
        link: `/${SETTINGS}`
      },
      {
        title: toTitleCase(TEMPLATES),
        link: ''
      }
    ]
  };

  const errConfig = {
    fields: {
      title:
        apiStatus.errCode === 401
          ? UNAUTHORIZED_ACCESS
          : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  };

  return (
    <CheckLogin onSignedIn={loadPage}>
      <Helmet>
        <title>{toTitleCase(TEMPLATES)} - Tikit</title>
      </Helmet>
      <div className="hidden md:block">
        {
          apiStatus.status === LOADING ? (
            <LoadingScreen message={apiStatus.msg} />
          ) : apiStatus.status === ERROR ? (
            <>
              <Communication {...errConfig} />
              {apiStatus.errCode === 401 && (
                <Button
                  content={REFRESH_SESSION}
                  primary
                  onClick={e => {
                    Providers.globalProvider.login();
                  }}
                />
              )}


            </>
          ) : (
            <>
              <Dialog
                style={{ maxWidth: '500px' }}
                open={archiveDialogOpen}
                onCancel={() => setArchiveDialogOpen(false)}
                cancelButton={toTitleCase(BUTTONS.CANCEL)}
                confirmButton={
                  selectedTemplate?.IsArchived
                    ? TREE_LISTING_CONSTANTS.unArchive
                    : TREE_LISTING_CONSTANTS.archive
                }
                onConfirm={() => { setArchiveDialogOpen(false); archiveTemplate(selectedTemplate) }}
                content={`${(!selectedTemplate.IsArchived) ? ARCHIVE_CONFIRMATION_SUBTITLE : UNARCHIVE_CONFIRMATION_SUBTITLE} ‘${selectedTemplate?.Name}’ ${TEMPLATE.TEMPLATE}?`}
                header={`${(!selectedTemplate.IsArchived) ? ARCHIVE_MODAL_HEADER : UNARCHIVE_MODAL_HEADER} ‘${selectedTemplate?.Name}’?`}
              />
              {(!isFeatureEnabled) ? <UpgradeNeeded
                        toolbarText={`${toTitleCase(SETTINGS)} > ${toTitleCase(TEMPLATES)}`}
                        headerText={t('ticket.templates.no-access')}
                        subheaderText={t('ticket.templates.upgrade-plan')}/> :
              <KBListWrapper
                title={toTitleCase(TEMPLATES)}
                addNewTitle={toTitleCase(NEW_TEMPLATE)}
                routeName={SETTINGS_PAGES.TEMPLATES}
                headers={TEMPLATE_HEADERS}
                rows={data}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                breadcrumbs={navs}
                ifEmpty={{
                  header: t('template.there-are-no-templates-lets-create-one'),
                  subHeader:
                    t('template.get-started-by-clicking-the-new-template-button')
                }}
                onAddHandler={() => {
                  setOpenDialog(true);
                }}
              />}
              <TicketTemplatesDialog
                open={openDialog}
                onCancel={() => setOpenDialog(false)}
              />
            </>
          )
        }
      </div>
      <MobileSettingsView />
    </CheckLogin>

  );
};
