import React, { useState } from 'react';
import { ThemePrepared, Flex, ChevronEndIcon, ChevronDownIcon, Text, Button} from '@fluentui/react-northstar';
import LifecycleTaskIcon from "../../../../svg/lifecycle-task-icon.svg";
import LifecycleApprovalIcon from "../../../../svg/approvals-lifecycle.svg";
import LifecyclePowerAutomateTaskIcon from "../../../../svg/lifecycle-powerautomate-icon.svg";
import LifecyclePhaseIcon from "../../../../svg/lifecycle-phase-transition-icon.svg";
import { LIFECYCLE, LIFECYCLE_STATUS_GUID } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import { TicketLifecyclePhaseTranstionDialog } from './TicketLifecyclePhaseTranstionDialog';
import { platformService } from '../../services/platform.service';


interface Props {
    isAccordionOpen: boolean;
    setIsAccordionOpen: (data: boolean) => void;
    globalTheme: ThemePrepared<any>;
    phaseData: TicketLifecyclePhase;
    lifecycleStatus: LifecycleStatus;
    activePhaseIndex: number;
    reloadPhases: ()=>void;
    setLoading : (data: boolean) => void;
    enableTransitionButton: boolean,
    ticketData: Ticket;
}

export const TicketLifecyclePhaseAccordion = ({
    isAccordionOpen,
    setIsAccordionOpen,
    globalTheme,
    phaseData,
    lifecycleStatus,
    activePhaseIndex,
    reloadPhases,
    setLoading,
    enableTransitionButton,
    ticketData
}: Props) => {

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openTransitionDialog, setOpenTransitionDialog] = useState(false);
    const tasksCount = phaseData.Tasks.length;
    const approvalsCount = phaseData.Approvals.length;
    const powerAutomateTasksCount = phaseData.PowerAutomateTasks.length;
    const isLifecycleCompleted = lifecycleStatus.Guid == LIFECYCLE_STATUS_GUID.COMPLETED;
    const isLifecycleFailed = lifecycleStatus.Guid == LIFECYCLE_STATUS_GUID.FAILED;

    const { t } = useTranslation();
    const api = new platformService();

    const getExtraClass = () => {
        
        if(phaseData.IsCurrent){
            if(isLifecycleCompleted) return "completed-lifecyle-accordion lifecyle-accordion";
            else if(isLifecycleFailed) return "failed-lifecyle-accordion lifecyle-accordion";
            else return "inprogress-lifecyle-accordion lifecyle-accordion";
        }
    }

    const onModalClose =  async() => {
      setOpenTransitionDialog(false);
    };

    const onExecutePhase = async(transitionMessage: string) => {
      setOpenTransitionDialog(false);
      setLoading(true);

      await api.executeManualPhaseTransition(phaseData.Id, transitionMessage, activePhaseIndex);
      reloadPhases();
    };
  return (
    <>
      <Flex vAlign="center" space="between"
        className={`mt-7 p-4 cursor-pointer ${getExtraClass()} ${isAccordionOpen ? "lifecyle-accordion-open" : ""}`}
        style={{ 
            borderBottom: '1px solid',
            borderBottomColor: isAccordionOpen ? `${globalTheme.siteVariables.colorScheme.brand.foreground1}` : 'transparent',
            backgroundColor: `${phaseData.Order < activePhaseIndex ? 'var(--past-phase-bg)' : 'var(--notstarted-lifecyle-bg)'}`,
            color: 'black'
        }}
        onClick={() => setIsAccordionOpen(!isAccordionOpen)}
      >
        <Flex.Item>
          <Flex gap="gap.small" vAlign="center">
            {isAccordionOpen ? <ChevronEndIcon /> : <ChevronDownIcon />}
            <Flex style={{ fontWeight: 400, fontSize: '16px', lineHeight: '24px', alignItems: "center" }} gap="gap.small">
              <Text weight="semibold" content={phaseData.Name}/>
              {((activePhaseIndex > phaseData.Order && phaseData.LastCompletionDate && !phaseData.IsCurrent) || isLifecycleCompleted) && (
                 <Text weight="semibold" size="smaller" className={"text-green-600"} content={<div style={{marginTop: '3px'}}> {' '}&bull;{' '}<em>{LIFECYCLE.COMPLETED}</em></div>}/>
              )}
              {(isLifecycleFailed && phaseData.IsCurrent) && (
                <Text weight="semibold" size="smaller" className={"error-pill"} content={<div style={{marginTop: '3px'}}> {' '}&bull;{' '}<em>{LIFECYCLE.FAILED}</em></div>}/>
              )}
              {(phaseData.IsManuallyTransitionedFrom && !phaseData.IsManuallyTransitionedTo)&& (
                <Text weight="semibold" size="smaller"  content={<div style={{marginTop: '3px'}}> {' '}{' '}<em>{LIFECYCLE.MANUAL_TRANSITION_FROM}</em></div>}/>
              )}
              {(phaseData.IsManuallyTransitionedTo) && (
                <Text weight="semibold" size="smaller"  content={<div style={{marginTop: '3px'}}> {' '}{' '}<em>{LIFECYCLE.MANUAL_TRANSITION_TO}</em></div>}/>
              )}
              {(enableTransitionButton || isLifecycleCompleted || isLifecycleFailed) && (
                <Button
                icon={<LifecyclePhaseIcon stroke="#8f90a6" style={{color: globalTheme.siteVariables.colorScheme.brand.AddIcon}} />}
                text
                primary
                content={<span 
                    style={{color: globalTheme.siteVariables.colorScheme.brand.foreground}}>{LIFECYCLE.TRANSITION}</span>}
                  onClick={() => {
                    setOpenTransitionDialog(true)
                  }}
                /> 
              )}
            </Flex>
          </Flex>
        </Flex.Item>
        <Flex.Item>
          <div>
          <Flex>
            {tasksCount > 0 && (
              <Flex.Item>
                <Flex style={{alignItems: 'center'}}>
                  <LifecycleTaskIcon
                    width={14}
                    height={14}
                    className="inline-block"
                  />
                  <div>&nbsp;{tasksCount} {t('ticket-details.edit-ticket-form.task.task')}{tasksCount > 1 ? 's' : ''}</div>
                </Flex>
              </Flex.Item>
            )}
            {approvalsCount > 0 && (
              <Flex.Item>
                <Flex className={`ml-2`} style={{alignItems: 'center'}}>
                  <LifecycleApprovalIcon
                    width={14}
                    height={14}
                    className={`inline-block`}
                  />
                  <div>&nbsp;{approvalsCount} {t('ticket-details.edit-ticket-form.approval.approval')}{approvalsCount > 1 ? 's' : ''}</div>
                </Flex>
              </Flex.Item>
            )}
            {powerAutomateTasksCount > 0 && (
              <Flex.Item>
                <Flex className={`ml-2`} style={{alignItems: 'center'}}>
                  <LifecyclePowerAutomateTaskIcon
                    width={14}
                    height={14}
                    className="inline-block"
                  />
                  <div>&nbsp;{powerAutomateTasksCount} {LIFECYCLE.FLOW}{powerAutomateTasksCount > 1 ? 's' : ''}</div>
                </Flex>
              </Flex.Item>
            )}
          </Flex>
          </div>
        </Flex.Item>
      </Flex>

      <TicketLifecyclePhaseTranstionDialog
        open={openTransitionDialog}
        onModalClose={onModalClose}
        onExecutePhase ={onExecutePhase}
      />
    </>

    
  );

};
