import { ProviderConsumer } from '@fluentui/react-northstar';
import React from 'react';
import { SLA } from '../../shared/utils/constants';

export const SlaHeader = () => {
  return (
    <ProviderConsumer
      render={globalTheme => (
        <h2 className={`text-gray-800 text-lg font-bold mb-4`}
          style={{
            color: globalTheme.siteVariables.colorScheme.default.foreground
          }}>
          {SLA.CREATE_SLA}
        </h2>
      )} />
  );
};
