import React from 'react';
import { Button } from '@fluentui/react-northstar';
import NotFoundSVG from '../../svg/404.svg';
import { useHistory } from 'react-router-dom';

export const UnauthorizedAccessPage = () => {
  const history = useHistory();
  return (
    <div className="h-screen flex items-center justify-center flex-col">
      <NotFoundSVG width={150} height={150} />
      <h1 className="font-bold text-2xl mb-3 mt-12">Unauthorized Access!</h1>
      <p className="text-sm" style={{ color: '#555770' }}>
        You don't have access to this page.
      </p>
      <p className="text-sm" style={{ color: '#555770' }}>
        Please contact your administrator.
      </p>
     
    </div>
  );
};
