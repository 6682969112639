import React, { useState } from 'react';
import { ChevronEndIcon, ChevronDownIcon, Flex } from '@fluentui/react-northstar';
import { IRelateTickets } from './RelateTicketHelper';
import { RelateTicketDialog } from './RelateTicketDialog';
import RelateIcon from '../../../../svg/relate-icon.svg';
import './relate-ticket.css';
import { useTranslation } from 'react-i18next';

interface Props {
  relatedTicketsCount: number;
  oldRelatedTicketIds: number[];
  sourceTicket: Ticket;
  isRelateTicketsOpen: boolean;
  isFormDisabled: boolean;
  setIsRelateTicketsOpen: (value: boolean) => void;
  relateTickets: (relateTicketData: IRelateTickets) => void;
  isLoading: boolean;
  primaryButtonColor: string;
}

export const EditTicketRelateTickets = ({
  relatedTicketsCount,
  oldRelatedTicketIds,
  sourceTicket,
  isRelateTicketsOpen,
  isFormDisabled,
  setIsRelateTicketsOpen,
  relateTickets,
  isLoading,
  primaryButtonColor
}: Props) => {
  const {t} = useTranslation();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const isDisabled: boolean = isLoading || sourceTicket.Closed || isFormDisabled; 

  const onRelateClick = (e: any) => {
    if (!isDisabled) {
      e.stopPropagation();
      setOpenDialog(true);
    }
  }

  return (
    <>
      <Flex vAlign="center" space="between"
        className={`mt-7 pb-4 cursor-pointer`}
        style={{ borderBottom: isRelateTicketsOpen ? `1px solid ${primaryButtonColor}` : 'none' }}
        onClick={() => setIsRelateTicketsOpen(!isRelateTicketsOpen)}
      >
        <Flex gap="gap.small" vAlign="center">
          {isRelateTicketsOpen ? <ChevronEndIcon /> : <ChevronDownIcon />}
          <Flex style={{ fontWeight: 400, fontSize: '16px', lineHeight: '24px' }}>
            {t('ticket-details.edit-ticket-form.relate-ticket.title')} ({relatedTicketsCount ? relatedTicketsCount : 0})
          </Flex>
        </Flex>
        {isRelateTicketsOpen && (
          <div className={`hidden md:flex items-center 
          ${isDisabled ? 'relate-disabled' : 'relate-active'}`} 
          onClick={e => onRelateClick(e)}>
            <RelateIcon className='relate-icon' /> {t('ticket-details.edit-ticket-form.relate-ticket.relate')}
          </div>
        )}
      </Flex>
      <RelateTicketDialog
        oldRelatedTicketIds={oldRelatedTicketIds}
        sourceTicketId={sourceTicket.Id}
        open={openDialog}
        onCancel={() => setOpenDialog(false)}
        relateTickets={relateTickets}
      />
    </>
  );
};
