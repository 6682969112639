import React, { useCallback, useEffect, useRef } from 'react';

function useComponentVisible(
  setShow: React.Dispatch<React.SetStateAction<number | boolean>>,
  isMounted?: boolean,
  value?: number
) {
  const childRef = useRef(null);
  const parentRef = useRef(null);

  const handleClickOutside = useCallback((event: any) => {
   if (
      childRef.current &&
      !childRef.current?.contains(event.target) &&
      parentRef.current?.id !== event.target.id
    ) {
      setShow(value || false);
    }
  }, []);

  useEffect(() => {
    if (isMounted === false) {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    }
  }, []);

  return { parentRef, ref: childRef };
}

export default useComponentVisible;
