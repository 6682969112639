import { equals } from '@microsoft/mgt-element';
import React, { useState, useEffect } from 'react';
import { Link } from '@fluentui/react';
import { useHistory, useLocation } from 'react-router-dom';
import { appState, useSetState } from '../../AppState';
import { PlatformUser } from '../interfaces/platformuser.interface';
import { Ticket } from '../interfaces/ticket.interface';
import AppCSS from '../../App.module.css';
import { platformService } from '../services/platform.service';
import { FontIcon } from '@fluentui/react/lib/Icon';
import LifecyleIcon from '../../../svg/lifecycle.svg';
import { Route } from '../../../route-constants';

import {
  Box,
  Button,
  SpeakerPersonIcon,
  TrashCanIcon,
  ProviderConsumer as FluentUIThemeConsumer,
  Dialog,
  Loader,
  Text,
  EditIcon,
  Divider
} from '@fluentui/react-northstar';
import { toolbarButtonColor, threedigitized } from '../../tikit/ticketHelper';
import { useTranslation } from 'react-i18next';
import { FILTERS_STRINGS, TASK_STATUS_GUID, TicketApprovalState } from '../utils/constants';
import { retrieveTicketInformation } from '../utils/propertyHelper';
interface ActionProps {
  ticketData: Ticket;
  ticketId: number;
  edittingState: boolean;
  currentUserId: number;
  onInteraction?: (interaction: any) => void;
  mergedTicketIds: number[];
  isUserLicensed: boolean;
}

export const EditTicketActions = (props: ActionProps) => {
  const location = useLocation();
  const {t} = useTranslation();
  const api = new platformService();
  const history = useHistory();
  const setState = useSetState();
  const currentState = appState();
  const [isUserInRole, setIsUserInRole] = useState<boolean>(false);
  const [isAssigned, setIsAssigned] = useState<boolean>(null);
  const [isAbleToTakeTicket, setIsAbleToTakeTicket] = useState<boolean>(false);
  const [isAbleToDeleteTicket, setIsAbleToDeleteTicket] = useState<boolean>(false);
  const [isEditting, setIsEditting] = useState<boolean>(false);
  const [assigningLoading, setAssigningLoading] = useState<boolean>(false);
  const [isUserInTeamOrGroup, setIsUserInTeamOrGroup] = useState<boolean>(false);
  const [isAbleToApplyTemplate, setIsAbleToApplyTemplate] = useState<boolean>(false);
  const [isTemplatesLoading, setIsTemplatesLoading] = useState<boolean>(true);

  const deleteTicket = async () => {
    try {
      const deletedTicket = await api.deleteTicket(props.ticketId);
      return deletedTicket;
    } catch (err) {
      return err;
    }
  };

  const takeTicketRequest = async () => {
    try {
      const takeTicketResponse = await api.takeTicket(props.ticketId);
      return takeTicketResponse.data.value;
    } catch (err) {
      return err;
    }
  };

  const onTake = () => {
    setAssigningLoading(true);
    if (props.onInteraction) {
      takeTicketRequest().then(async isSaved => {
        if (isSaved) {
          const data = await getCurrentTicketCompleteInfo();
          data.TicketSlas = [];
          props.onInteraction({ event: 'takeTicket', data: data });
          setIsAssigned(true);
          setAssigningLoading(false);
          let result = await getCurrentTicketSLAs();
          props.onInteraction({ event: 'set-ticket-SLAs', data: result.TicketSlas });
        }
      });
    }
  };

  const unassignTicketRequest = async (): Promise<boolean> => {
    try {
      const unassignTicketResponse = await api.updateTicket(props.ticketId, { AssigneeId: null });
      return unassignTicketResponse.status === 204;
    } catch (err: any) {
      console.error(err)
      return err;
    }
  };

  const onUnAssign = () => {
    setAssigningLoading(true);
    unassignTicketRequest().then(async isSaved => {
      if (isSaved) {
        const data = await getCurrentTicketCompleteInfo();
        data.TicketSlas = [];
        props.onInteraction({ event: 'onUnassign', data: data });
        setIsAssigned(false);
        setAssigningLoading(false);
        let result = await getCurrentTicketSLAs();
        props.onInteraction({ event: 'set-ticket-SLAs', data: result.TicketSlas });
      }
    });
  };

  const getCurrentTicketCompleteInfo = async () => {    
      const { data } = await api.getTicket(props.ticketId, FILTERS_STRINGS.TICKET_DETAILS);
      return await retrieveTicketInformation(data, currentState);
  };

  const getCurrentTicketSLAs = async () => {
    const {data} = await api.getTicket(props.ticketId, '?$expand=TicketSlas($orderby=CreatedDate%20desc;$select=CreatedDate,ModifiedDate;$expand=SlaState,ServiceLevelAgreement($select=Name,IsActive,CreatedDate;$expand=SlaCriterias($select=Id,TimeDigitPart,TimeUnitPart,SlaActionTypeId,CreatedDate)))');
    return data;
  };
  
  const onApplyTemplate = () => {
    props.onInteraction({ event: 'onApplyTemplate' });
  };

  const onEdit = () => {
    props.onInteraction({ event: 'onEdit' });
    setIsEditting(true);
  };

  const onConfirm = () => { 
    let historyPath = '/';
    const currentPath = location.pathname.substring(0, location.pathname.lastIndexOf('/'));
    
    deleteTicket().then(ticket => {
      if (ticket) {
        setState(state => {
          const newTicketSet = state.tickets.filter(t => t.Id !== props.ticketId);
          let newstate = { ...state, tickets: newTicketSet };
          return newstate;
        });
        
        switch(currentPath){
          case Route.myGroupWork.editTicket:
            historyPath = Route.myGroupWork.list;
            break;
          case Route.myWork.editTicket:
            historyPath = Route.myWork.list;
            break;
          default:
            historyPath = '/'
            break;
        }

        history.push(historyPath);
      }
    });
  };

  useEffect(() => {
    const isCurrentUserInRole = currentState?.usersInRole?.some((user: PlatformUser) => props.currentUserId == user.Id) ?? false;
    if (!equals(isCurrentUserInRole, isUserInRole)) {
      setIsUserInRole(isCurrentUserInRole);
    }
  }, [props.edittingState, currentState.usersInRole]);
  
  useEffect(() => {

    const permissionToTakeTicket = JSON.parse(sessionStorage.getItem('my_application_roles'));
    const permissionToTakeTicketFiltered = permissionToTakeTicket.filter((roles: any) => roles['EndpointPath'] == '~/api/Ticket({id})/Action.TakeTicket');

    permissionToTakeTicketFiltered.map((p: any) => {
      if (p.Permissions.includes('POST')) {
        setIsAbleToTakeTicket(true);
      }
    });

    const permissionToUpdateTicket = JSON.parse(
      sessionStorage.getItem('my_application_roles')
    ).filter((roles: any) => roles['EndpointPath'] === '~/api/Ticket');

    permissionToUpdateTicket.map(p => {
      if (p.Permissions.includes('DELETE')) {
        setIsAbleToDeleteTicket(true);
      }
    });

    setIsEditting(props.edittingState);
  }, [props.edittingState]);

  useEffect(() => {
    let noAtionOnLifecycle = true;
    if(props.ticketData.TicketLifecycle){
      const phaseTasks = currentState.ticketTask?.filter(t => t.TicketLifecyclePhaseId != null) ?? [];
      const actedTasks = phaseTasks.filter(item => item.Status.Guid !== TASK_STATUS_GUID.NOT_STARTED);
      const phaseApprovals:TicketApproval[] = currentState.lifecycleApprovals?.filter(a => a.Approval.TicketLifecyclePhaseId != null) ?? [];
      const actedApprovals = phaseApprovals.filter(item => {
        if (item.Approval.Approvers.some(a => a.HasApproved !== TicketApprovalState.Pending)) {
          return item;
        }
      });

      noAtionOnLifecycle = actedTasks.length == 0 && actedApprovals.length == 0;
    }

    setIsAssigned(props.currentUserId === props.ticketData.AssigneeId);
    setIsAbleToApplyTemplate(noAtionOnLifecycle);
    checkIsUserInTeamOrGroup();
  }, [props.ticketData, currentState.ticketTask, currentState.lifecycleApprovals, currentState.supportgroups, currentState.teams]);

  
  useEffect(() => {
    if (currentState?.isLoadingTemplates != undefined) {
      setIsTemplatesLoading(currentState.isLoadingTemplates);
    }
  }, [currentState.isLoadingTemplates])

  const checkIsUserInTeamOrGroup = () => {
    let isAssigneeExist = true;
    if (props.ticketData.SupportGroupId) {
      const groupMembers = currentState.supportgroups?.find(supportGroup => supportGroup.Id === props.ticketData.SupportGroupId)?.Members || [];
      isAssigneeExist = groupMembers.some((assignee: any) => assignee.Id == props.currentUserId);
    }
    else if (props.ticketData.TeamId) {
      const teamMemebers = currentState.teams?.find(x => x.Id === props.ticketData.TeamId)?.Users || [];
      isAssigneeExist = teamMemebers.some(assignee => assignee.Id == props.currentUserId);
    }
    setIsUserInTeamOrGroup(isAssigneeExist);
  }
  
  const getContent = (linkColor: string) => {
    return <>
        <span>{t('ticket-details.edit-ticket-form.relate-ticket.merged-tickets')} </span>
        {props.mergedTicketIds.map(ticketId => (
          <>
            <Link key={ticketId} className='font-bold' style={{color: linkColor}} target="_blank"
              href={`${window.location.origin}/Tickets/${ticketId}`}>
              #{threedigitized(ticketId)}
            </Link>{', '}
          </>
        ))}
        <span>{t('ticket-details.edit-ticket-form.relate-ticket.will-aslo-deleted')}</span>
    </>;
  }

  return (
    <FluentUIThemeConsumer
      render={({ siteVariables: { colorScheme } }) => (
        <>
          {isUserInRole && (
            <Box>
              <div className={`flex items-center`} style={{ height: '48px' }}>
                <div className={`flex-grow flex flex-items-center`}>
                  {isAbleToDeleteTicket && (
                    <Dialog
                      className={`${AppCSS['confirmDialog']}`}
                      cancelButton={t('ticket-details.toolbar.action.delete-dialog.button.cancel')}
                      confirmButton={t('ticket-details.toolbar.action.delete-dialog.button.confirm')}
                      onConfirm={onConfirm}
                      header={t('ticket-details.toolbar.action.delete-dialog.header')}
                      content={props.mergedTicketIds.length > 0 && getContent(colorScheme.brand.foreground)}
                      trigger={
                        <Button
                          disabled={!props.isUserLicensed}
                          icon={<TrashCanIcon />}
                          text
                          content={t('ticket-details.toolbar.action.delete-dialog.button.delete')}
                          size="small"
                          style={{ color: toolbarButtonColor(props.isUserLicensed, colorScheme) }}
                        />
                      }
                    />
                  )}
                  {isAssigned !== null && (
                    <>
                      {!isAssigned && isAbleToTakeTicket && isUserInTeamOrGroup && (
                        <>
                          {assigningLoading && (
                            <Loader
                              label={<Text content={``} size="smallest" />}
                              labelPosition="end"
                              className={`mlr-2`}
                              size="smallest"
                            />
                          )}

                          {!assigningLoading && (
                            <Button
                              disabled={!props.isUserLicensed}
                              icon={<SpeakerPersonIcon />}
                              onClick={onTake}
                              text
                              primary
                              content={t('ticket-details.toolbar.action.take')}
                              size="small"
                              style={{ color: toolbarButtonColor(props.isUserLicensed, colorScheme) }}
                            />
                          )}
                        </>
                      )}
                      {isAssigned && isAbleToTakeTicket && (
                        <>
                          {assigningLoading && (
                            <Loader
                              label={<Text content={``} size="smallest" />}
                              labelPosition="end"
                              className={`ml-2`}
                              size="smallest"
                            />
                          )}
                          {!assigningLoading && (
                            <Button
                              disabled={!props.isUserLicensed}
                              icon={<SpeakerPersonIcon />}
                              text
                              onClick={onUnAssign}
                              primary
                              content={t('ticket-details.toolbar.action.unassign')}
                              size="small"
                              style={{ color: toolbarButtonColor(props.isUserLicensed, colorScheme) }}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                  {isAbleToApplyTemplate && (
                     <div className="md:block">
                      {isTemplatesLoading ? (
                        <Loader
                          label={<Text content={``} size="smallest" />}
                          labelPosition="end"
                          className={`mlr-2`}
                          size="smallest"
                        />
                      ) : (
                        <Button
                          disabled={!props.isUserLicensed}
                          icon={<FontIcon iconName="FileTemplate" />}
                          text
                          onClick={onApplyTemplate}
                          content={t('ticket-details.toolbar.action.apply-template')}
                          size="small"
                          style={{ color: toolbarButtonColor(props.isUserLicensed, colorScheme) }}
                        />)}
                    </div>
                  )}
                 
                </div>
                {!isEditting && (
                  <>
                    <Divider vertical />
                    <Button
                      disabled={!props.isUserLicensed}
                      text
                      icon={<EditIcon size="medium" />}
                      onClick={onEdit}
                      title={t('ticket-details.toolbar.action.edit')}
                      size="medium"
                      iconOnly
                      className={`${AppCSS.navActionButton} mr-2 ml-1 lg-hidden-important`}
                      style={{ color: toolbarButtonColor(props.isUserLicensed, colorScheme) }}
                    />
                  </>
                )}
              </div>
            </Box>
          )}
        </>
      )}
    />
  );
};
