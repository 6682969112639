import React, { Fragment } from 'react';
import { Text, AcceptIcon } from '@fluentui/react-northstar';
import FilterCSS from './NestedFilter.module.css';
import { INestedFilterItem } from "./NestedFilter";

interface ITreeItem {
  id: number;
  value: string;
  isSelected?: boolean;
  paddingLeft: string;
  onSelectCallback: () => void;
}

export const RecursiveTree = ({ items, onSelectCallback }) => {

  const createTree = (item: INestedFilterItem, padding: number) => {
    padding++;
    return item && <>
      <TreeItem
        id={item.key}
        key={item.key}
        onSelectCallback={() => { onSelectCallback(item) }}
        value={item.title}
        isSelected={item.selected}
        paddingLeft={`${padding * 0.75}rem`}
      />
      {item.children && item.children.map((child: any) => {
        return <Fragment key={child.key}>{createTree(child, padding)}</Fragment>;
      })}
    </>;
  }

  return (
    <div>
      {items.map((item: INestedFilterItem, i: any) => (
        <Fragment key={item.key}>{createTree(item, 0)}</Fragment>
      ))}
    </div>
  );
};

const TreeItem = ({ id, value, isSelected, paddingLeft, onSelectCallback }: ITreeItem) => {
  return (
    <div key={id} className={`${FilterCSS.filterOption} cursor-pointer`} onClick={() => { onSelectCallback() }}>
      <div style={{ paddingLeft: paddingLeft }} className={`flex justify-between pr-3 py-2`}>
        <Text content={value} weight="semibold" />
        {isSelected && (<Text color="brand" content={<AcceptIcon />} />)}
      </div>
    </div>
  );
};
