import React, { createContext, useState } from 'react';

export interface Props {
  children: React.ReactNode;
} 

export interface ListContextType {
  listStates: ListStatesType;
  setListStates: React.Dispatch<React.SetStateAction<ListStatesType>>;
}

export const ListContext = createContext({} as ListContextType);
const ListProvider = ListContext.Provider;

const ListContextProvider = ({ children }: Props) => {
  const [listStates, setListStates] = useState<ListStatesType>({
    isTicketsFetching: true,
    countTickets: 0,
    tickets: [],
    currentPage: 1,
  });

  return (
    <>
      <ListProvider value={{ listStates, setListStates }}>
        {children}
      </ListProvider>
    </>
  );
};

export default ListContextProvider;
