import React, { useEffect, useState } from "react";
import { Flex, Dialog, CloseIcon, Button, Alert } from "@fluentui/react-northstar";
import { ApprovalsForm } from "./ApprovalsForm";
import { PlatformUser } from "../../interfaces/platformuser.interface";
import { TicketApprovalState } from "../../utils/constants";
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { appState } from "../../../AppState";

interface Props {
  open: boolean;
  onCancel: () => void;
  addTicketApprovals: (ticketApprovalsData: Approval) => void;
  approversList: PlatformUser[];
  setApproversList: (value: PlatformUser[]) => void;
  existingTicketApprovalData?: Approval;
  isUpdateButton?: boolean;
  updateTicketApproval?: (ticketApprovalData: Approval) => void;
}

const getInitialApprovalValues = () => {
  return {
    Id: 0,
    AdditionalDetails: '',
    Title: '',
    IsRequiredByAll: null,
    Approvers: [],
    TicketApprovals: [],
    ApprovalState:TicketApprovalState.Pending
    };
  }

export const ApprovalsDialog = ({ open, onCancel, addTicketApprovals, approversList, setApproversList, isUpdateButton, updateTicketApproval, existingTicketApprovalData }: Props) => {
  const {t} = useTranslation();
  const [ticketApprovalData, setTicketApprovalData] = useState<Approval>(getInitialApprovalValues());
  const [isFormUpdated, setIsFormUpdated] = useState<boolean>(false);
  
  const currentState = appState();
  
  const closeDialog = () => {
    setAlert({ title: '', show: false, attributes: {} });
    setTicketApprovalData(getInitialApprovalValues());
    setApproversList([]);
    onCancel();
  }
  const [alert, setAlert] = useState({
    title: '',
    show: false,
    attributes: {}
  });


  const validateRequired = () => {
    setAlert({ title: '', show: false, attributes: {} });
    if (ticketApprovalData.Title  === '' || approversList.length==0) {
      setAlert({
        title: t('ticket-details.edit-ticket-form.approval.error.field-required'),
        show: true,
        attributes: { danger: true }
      });
      return true;
    }
    return false;
  };
  const primaryClick = async () => {
    if (validateRequired()) return;
    saveData();
    onCancel();
  }
  useEffect(() => {
    if (open) {
      setAlert({ title: '', show: false, attributes: {} });
    }

  }, [open]);

  useEffect(() => {
    setIsFormUpdated(checkIfApprovalFormUpdated());
  }, [ticketApprovalData, approversList]);
  
  const renderPages = () => {
    return <ApprovalsForm
      ticketApprovalData={isUpdateButton ? existingTicketApprovalData:ticketApprovalData}
      setTicketApprovalData={setTicketApprovalData}
      setApproversList={setApproversList}/>
  }

  const saveData = () => {
    addTicketApprovals(ticketApprovalData);
    setTicketApprovalData(getInitialApprovalValues());
    setApproversList([]);
  }

  const onUpdateApprovalClick = async()=>{
    if (validateRequired()) return;
    updateTicketApproval(ticketApprovalData);
    setTicketApprovalData(getInitialApprovalValues());
    onCancel();
  }

  const checkIfApprovalFormUpdated = () => {
    let isDirty = false;
    let propertiesList = Object.keys(getInitialApprovalValues());

    if(existingTicketApprovalData === undefined) return isDirty;
    
    for (let property of propertiesList) {
      let updatedPropertyValue: any = ticketApprovalData[property];
      let initialPropetyValue: any = existingTicketApprovalData[property];
      if (updatedPropertyValue?.toString() !== initialPropetyValue?.toString()) {
        isDirty = true;
        break;
      }
    }

    let currentApprovers = existingTicketApprovalData.Approvers.map(o => currentState.platformusers.find(i => i.Id == o.Approver.Id));
    if(!_.isEqual(approversList.map(u=>u.AadObjectId), currentApprovers.map(u=>u.AadObjectId))){
      isDirty = true;
    }
    
    return isDirty;
  };

  return (
    <Dialog className="relate-ticket-dialog"
      content={renderPages()}
      open={open}
      headerAction={{
        icon: <CloseIcon />,
        title: t('ticket-details.edit-ticket-form.approval.dialog.button.close'),
        onClick: closeDialog
      }}
      header={{
        content: (
          <div className="w-full">
            <h3 className="mt-0">{isUpdateButton?t('ticket-details.edit-ticket-form.approval.edit-approval') :t('ticket-details.edit-ticket-form.approval.add-approval')}</h3>
            {alert.show && (
              <Alert
                fitted
                {...alert.attributes}
                content={alert.title}
                className="w-full text-xs"
              />
            )}
          </div>
        ),        
      }}
      footer={
        <Flex gap="gap.small">
          <>
            <Flex.Item push>
              <Button content={t('ticket-details.edit-ticket-form.approval.dialog.button.cancel')} onClick={closeDialog} />
            </Flex.Item>
            {isUpdateButton ? (
              <Button
                className="dialog-btn"
                content={t('common.buttons.update')}
                primary
                onClick={onUpdateApprovalClick}
                disabled={!isFormUpdated}
              />
            ) : (
              <Button
                className="dialog-btn"
                disabled={false}
                content={t('ticket-details.edit-ticket-form.approval.dialog.button.add')}
                primary
                onClick={primaryClick}
              />
            )}
          </>
        </Flex>
      }
    />
  )
};