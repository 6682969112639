import React, { useCallback, useState } from 'react';
import { Button, MenuButton, MoreIcon, Flex, ProviderConsumer, Text } from '@fluentui/react-northstar';
import { platformService } from '../shared/services/platform.service';
import { Communication } from '@fluentui/react-teams';
import { Providers } from '@microsoft/mgt-element/dist/es6/providers/Providers';
import { Breadcrumbs } from '../shared/components/BreadcrumbNavigation';
import {
  DEFAULT_API_STATUS,
  DONE,
  ERROR,
  LOADING,
  SETTINGS,
  TREE_LISTING_CONSTANTS,
  SETTINGS_PAGES,
  ENDPOINT_SECURITY_MAPPINGS,
  GETTING_ALL_TEAMS,
  TEAMS,
  NEW_TEAM,
  TEAMS_HEADERS,
  UNAUTHORIZED_ACCESS,
  SOMETHING_WENT_WRONG,
  CONTACT_ADMIN,
  REFRESH_SESSION,
  LICENSE_FEATURE
} from '../shared/utils/constants';
import { toTitleCase } from '../shared/utils/helper';
import { KBListWrapper } from '../kb/KBListWrapper';
import { FontIcon } from '@fluentui/react';
import { useHistory } from 'react-router-dom';
import { CheckLogin } from '../shared/components/CheckLogin';
import { Helmet } from 'react-helmet';
import { LoadingScreen } from '../shared/components/LoadingScreen';
import { Toolbar } from '../shared/components/Toolbar';
import ConfigCSS from '../config/config.module.css';
import { ITeam } from '../shared/interfaces/supportGroup.interface';
import { UpgradeNeeded } from '../shared/components/UpgradeNeeded';
import { CheckLicense } from '../shared/components/License/CheckLicense';
import useCurrentPage from '../kb/useCurrentPage';
import { useTranslation } from 'react-i18next';
import { getCachedFeature } from '../shared/cache/FeatureCache';


export const TeamsTable = () => {
  const {t }= useTranslation();
  const history = useHistory();
  const api = new platformService();
  const [data, setData] = useState<RowsData[]>([]);
  const [apiStatus, setApiStatus] = useState<APIStatus>(DEFAULT_API_STATUS as APIStatus);
  const [multiEmailInbox, setMultiEmailInbox] = useState(false);
  const {currentPage, setCurrentPage} = useCurrentPage()

  const fetchData = useCallback(async () => {
    setApiStatus({ ...apiStatus, msg: GETTING_ALL_TEAMS });

    const check = ENDPOINT_SECURITY_MAPPINGS.find(x => x.key == SETTINGS_PAGES.TEAMS);
    if (check && JSON.parse(sessionStorage.getItem('my_application_roles') || '[]').filter(x => x.EndpointPath == check.path && x.Permissions.includes(check.method)).length <= 0) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: 403
      });
      return;
    }

    try {
      const isMultiInbox = await getCachedFeature(LICENSE_FEATURE.MultiEmailInbox);
      setMultiEmailInbox(isMultiInbox);

      const { data: { value } } = await api.getTeams('?$orderby=FriendlyName');
      const rowData: RowsData[] = value.map((team: ITeam) => ({
        id: team['Id'],
        [t('teams.teams-columns.name-search')]: team.FriendlyName,
        [t('teams.teams-columns.friendly-name')]: (<Flex gap="gap.medium" vAlign="center">
          <Text content={team.FriendlyName} />
          {team.IsDefault && (
            <Text content={TREE_LISTING_CONSTANTS.default} size="smaller" weight="light" disabled />
          )}
        </Flex>
        ),
        [t('teams.teams-columns.team')]: team.Name,
        [t('teams.teams-columns.triage-channel')]: team.TeamsChannelName,
        [t('teams.teams-columns.description')]: team.Description,
        [t('teams.teams-columns.email')]: team.EmailAddress,
        "": (
          <>
            <CheckLicense disable>
            <MenuButton
              trigger={
                <Button icon={<MoreIcon />} text iconOnly title="Actions" onClick={(e) => {
                  e.stopPropagation();
                }} />
              }
              menu={{
                items: [
                  {
                    content: t('teams.teams-columns.edit'),
                    icon: <FontIcon iconName={"Edit"} className={`text-lg`} style={{ lineHeight: "unset" }} />,
                    onClick: (e) => {
                      e.stopPropagation();
                      history.push(`/settings/teams?id=${team.Id}`);
                    }
                  },
                  // {
                  //   content: group.IsArchived ? TREE_LISTING_CONSTANTS.unArchive : TREE_LISTING_CONSTANTS.archive,
                  //   icon: group.IsArchived ? <UnArchiveIcon /> : <ArchiveIcon />,
                  //   onClick: (e) => {
                  //     e.stopPropagation();
                  //     setSelectedGroup(group);
                  //     setArchiveDialogOpen(true);
                  //   }
                  // }
                ],
              }}
            />
            </CheckLicense>
          </>
        )
      }));

      setData(rowData);
      setApiStatus({ ...apiStatus, status: DONE });
    } catch (err: any) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: err.response.status
      });
    }
  }, []);

  const loadPage = () => {
    fetchData();
  }

  const navs: Breadcrumbs = {
    breadcrumbs: [
      {
        title: toTitleCase(SETTINGS),
        link: `/${SETTINGS}`
      },
      {
        title: toTitleCase(TEAMS),
        link: ''
      }
    ]
  };

  const errConfig = {
    fields: {
      title:
        apiStatus.errCode === 401 || apiStatus.errCode === 403
          ? UNAUTHORIZED_ACCESS
          : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  };

  return (
    <CheckLogin onSignedIn={loadPage}>
      <Helmet>
        <title>{toTitleCase(TEAMS)} - Tikit</title>
      </Helmet>
      {
        apiStatus.status === LOADING ? (
          <LoadingScreen message={apiStatus.msg} />
        ) : 
        (<>
          {apiStatus.status === ERROR && (
          <>
            {apiStatus.errCode != 402 && (
              <ProviderConsumer render={(globalTheme) => (
              <>
                <Toolbar globalTheme={globalTheme} title="Settings > Teams" hideSave={true} breadcrumbs={navs}/>
                <Flex className={ConfigCSS.configBody}>
                  <Flex.Item grow>
                    <>
                      <Communication {...errConfig} />
                      {apiStatus.errCode === 401 && (<Button content={REFRESH_SESSION} primary onClick={_e => { Providers.globalProvider.login(); }} />)}
                    </>
                  </Flex.Item>
                </Flex>
              </>)} />
            )}
            {apiStatus.errCode === 402 && (
              <UpgradeNeeded
                toolbarText={`${toTitleCase(SETTINGS)} > ${toTitleCase(TEAMS)}`}
                breadcrumbs={navs}
                headerText={t('teams.you-do-not-have-access')}
                subheaderText={t('teams.please-upgrade-your-plan')}
              />
            )}
          </>
          )} 
          {apiStatus.status != ERROR && (
            <KBListWrapper
              title={toTitleCase(TEAMS)}
              addNewTitle={toTitleCase(NEW_TEAM)}
              routeName={SETTINGS_PAGES.TEAMS}
              headers={multiEmailInbox ? TEAMS_HEADERS : TEAMS_HEADERS.map((e, i) => i !== 3 ? e : undefined).filter(x => x !== undefined)}
              rows={data}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              breadcrumbs={navs}
              ifEmpty={{
                header: t('teams.there-are-no-teams'),
                subHeader:
                t('teams.get-started-by-clicking')
              }}
            />
          )}
        </>
        )
      }
    </CheckLogin>
  )
};
