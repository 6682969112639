import React from 'react';
import { Loader } from '@fluentui/react-northstar';

type Props = {
  message: string;
};

const FormLoading = ({ message }: Props) => {
  return (
    <div className={`absolute h-full w-full flex items-center justify-center flex-col z-10`}>
      <Loader label={message} className={`mt-14`} />
    </div>
  );
};

export default FormLoading;
