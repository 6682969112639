import React, { useState } from 'react';
import range from 'lodash/range';
import PriorityIcon from '../../../svg/priority-icon.svg';
import CategoryIcon from '../../../svg/category.svg';
import TicketTypeIcon from '../../../svg/ticket-type-filled.svg';
import CollaboratorIcon from '../../../svg/collaboratorIcon.svg';
import { DraggableProvidedDraggableProps, DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import TaskIcon from '../../../svg/tasks-icon.svg';
import LifecycleIcon from '../../../svg/lifecycle.svg';
import LifecyclePhaseIcon from '../../../svg/lifecycle-phase-icon.svg';

import {
  Avatar,
  Box,
  Button,
  Card,
  CardBehaviorProps,
  Flex,
  FocusZoneTabbableElements,
  Popup,
  Ref,
  SiteVariablesPrepared,
  Text,
  dialogBehavior,
  gridCellWithFocusableElementBehavior,
  CalendarIcon,
  UserFriendsIcon,
  MenuItemProps,
  MenuShorthandKinds,
  ShorthandCollection,
  SpeakerPersonIcon
} from '@fluentui/react-northstar';

import { getCode, keyboardKey } from '@fluentui/keyboard-key';
import { MoreIcon, PaperclipIcon } from '@fluentui/react-icons-northstar';
import { getText, TLocale, TTextObject, TTranslations } from '@fluentui/react-teams/lib/esm/translations';
import { TUsers } from '@fluentui/react-teams/lib/esm';
import setMultiple from '@fluentui/react-teams/lib/esm/lib/setMultiple';
import { useAccessibility } from '@fluentui/react-bindings';
import { AppStateContext, AppStateValue } from '../../../components/AppState';
import { Container, Row, Col } from 'react-grid-system';
import { TicketProps } from '../../../components/shared/interfaces/ticketproperties.interface';
import { Person } from '@microsoft/mgt-react';
import { PlatformUser } from '../../../components/shared/interfaces/platformuser.interface';

import RelativeDate from '../../../components/shared/components/RelativeDate';
import { ConvertDateToLocale } from '../../../components/shared/common/ConvertDate';
import { useHistory } from 'react-router-dom';
import { SkeletonBoardCard } from '../../../components/shared/components/Skeleton';
import { formatAffectedUserLikeCollaborators, getCategoryValue, getSupportGroupName, getTicketTypeValue } from '../../../components/tikit/ticketHelper';
import { STATUS_GUID, LICENSE_FEATURE } from '../../../components/shared/utils/constants';
import { AssigneeSection, CategorySection, RequesterSection, StatusSection } from '../../../components/shared/components/BoardItemComponents/RequesterSession';
import { SlaPills } from '../../../components/shared/components/SlaPills/SlaPills';
import { CheckFeature } from '../../../components/shared/components/CheckFeature';
import { BoardTooltip } from './BoardTooltip/BoardTooltip';
import { UsersTooltip } from '../../../components/shared/components/TicketDetailInfo/UsersTooltip';
import AffectedUsersIcon from '../../../svg/affected-user.svg';
import { TicketLifecyclePhaseElement } from '../../../components/shared/components/TicketLifecycleTab/TicketLifecyclePhaseRibbon';
import { TFunction } from 'i18next';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

const boardItemBehavior = (props: CardBehaviorProps) =>
  setMultiple(gridCellWithFocusableElementBehavior(), {
    'focusZone.props': {
      handleTabKey: FocusZoneTabbableElements.all,
      isCircularNavigation: true,
      shouldEnterInnerZone: (event: React.KeyboardEvent<HTMLElement>) =>
        getCode(event) === keyboardKey.Enter
    },
    'keyActions.root.focus.keyCombinations': [{ keyCode: keyboardKey.Escape }]
  });

export interface IBoardItemProps {
  isDragging: boolean;
  draggableProps: DraggableProvidedDraggableProps;
  dragHandleProps: DraggableProvidedDragHandleProps;
  editItemDialog?: JSX.Element;
  t: TFunction<"translation", undefined>;
  item: IPreparedBoardItem;
  boardItemCardLayout: IBoardItemCardLayout;
  users: TUsers;
  isSlaEnabled: boolean;
}

export interface IBoardItemBadges {
  attachments?: number;
}

export interface IBoardItem {
  lane: string;
  order: number;
  title: TTextObject;
  subtitle?: TTextObject;
  body?: TTextObject | TTextObject[];
  users?: number[];
  badges?: IBoardItemBadges;
  preview?: string;
  // modified
  AssigneeId?: number[];
  CategoryId?: number;
  TicketTypeId?: number;
  StatusId?: number;
  PriorityId?: number;
  SupportGroupId?: number;
  CreatedById?: number;
  CreatedDate: string;
  ModifiedDate: string;
  DueDate: string | null;
  Id: number;
  Comments: [];
  TicketSlas: TicketSla[];
  IsSlasFetching?: boolean;
  IsMerged?: boolean;
  Closed?: boolean;
  SourceTicketId?: number | null;
  CollaboratorsCount?: { PlatformUserId: number }[];
  AffectedUsersCount?: { PlatformUserId: number }[];
  TicketTasks: TicketTask[];
  TicketLifecycle?: TicketLifecycle;
}

export type TBoardItems = {
  [itemKey: string]: IBoardItem;
};

export interface IPreparedBoardItem extends IBoardItem {
  itemKey: string;
  order: number;
}

export interface IPreparedBoardItems {
  [laneKey: string]: IPreparedBoardItem[];
}

export interface IBoardItemCardLayout {
  previewPosition: 'top' | 'afterHeader';
  overflowPosition: 'preview' | 'header' | 'footer';
}

interface IBoardItemBadgesProps {
  badges: IBoardItemBadges;
  t: TTranslations;
}

const BoardItemBadges = ({ badges }: IBoardItemBadgesProps) => {
  return (
    <Box>
      {Object.keys(badges).map(badgeKey => {
        switch (badgeKey) {
          case 'attachments':
            return (
              <Flex
                styles={{
                  height: '1.75rem',
                  color: 'var(--content-color-secondary)'
                }}
                hAlign="center"
                vAlign="center"
                key={`BoardItem__Badge__${badgeKey}`}
              >
                <PaperclipIcon outline />
                <Text
                  size="small"
                  content={badges[badgeKey]}
                  styles={{ marginLeft: '.25rem' }}
                />
              </Flex>
            );
        }
      })}
    </Box>
  );
};

type SizeType = | 'small' | 'smallest' | 'smaller' | 'medium' | 'large' | 'larger' | 'largest';
export interface IBoardItemUsersProps {
  associatedUserKeys?: string[];
  users?: TUsers;
  locale?: TLocale;
  datecreated?: string;
  fontWeight?:
    | 'semibold'
    | 'light'
    | 'semilight'
    | 'regular'
    | 'bold';
  avatarSize?: SizeType;
  fontSize?: SizeType
}

const inputItems: ShorthandCollection<MenuItemProps, MenuShorthandKinds> = [
  {
    header: 'Bruce Wayne',
    image:
      'https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/small/matt',
    content: 'Software Engineer',
    email: 'sample@gmail.com'
  },
  {
    header: 'Natasha Romanoff',
    image:
      'https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/small/jenny.jpg',
    content: 'UX Designer 2'
  },
  {
    header: 'Steven Strange',
    image:
      'https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/small/joe.jpg',
    content: 'Principal Software Engineering Manager'
  },
  {
    header: 'Alfred Pennyworth',
    image:
      'https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/small/justen.jpg',
    content: 'Technology Consultant'
  },
  {
    header: `Scarlett O'Hara`,
    image:
      'https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/small/laura.jpg',
    content: 'Software Engineer 2'
  },
  {
    header: 'Imperator Furiosa',
    image:
      'https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/small/veronika.jpg',
    content: 'Boss'
  },
  {
    header: 'Bruce Banner',
    image:
      'https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/small/chris.jpg',
    content: 'Senior Computer Scientist'
  },
  {
    header: 'Peter Parker',
    image:
      'https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/small/daniel.jpg',
    content: 'Partner Software Engineer'
  },
  {
    header: 'Selina Kyle',
    image:
      'https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/small/ade.jpg',
    content: 'Graphic Designer'
  }
];

export const getUserByUserId = (userId: number, users: PlatformUser[]) => users.find(({ Id }: PlatformUser) => Id === userId)?.FullName ?? '';
export const getEmailByUserId = (userId: number, users: PlatformUser[]) => users.find(({ Id }: PlatformUser) => Id == userId)?.UserName ?? '';

export const getDisplayNameByUserEmail = (
  email: string,
  users: PlatformUser[]
) =>
  users.find(({ Email }: PlatformUser) => Email === email)?.FullName ?? '';

const getDefault = (propsId: number, ticketprops: TicketProps[]) => {
  var defaultProp = ticketprops.find((tprop: TicketProps) => {
    return tprop.IsDefault;
  });

  if (typeof defaultProp === 'undefined') {
    defaultProp = ticketprops[0];
  }

  return defaultProp.Id;
};

export const getClosed = (ticketprops: TicketProps[]) => {
  var defaultProp = ticketprops.find((tprop: TicketProps) => {
    return tprop.Guid === STATUS_GUID.CLOSED;
  });

  if (typeof defaultProp === 'undefined') {
    defaultProp = ticketprops[ticketprops.length - 1];
  }

  return defaultProp.Id;
};

export const BoardItemUsers = ({
  associatedUserKeys,
  users,
  locale,
  datecreated,
  fontWeight,
  avatarSize,
  fontSize
}: IBoardItemUsersProps) => {
  // [v-wishow] todo: replace with AvatarGroup compoment to be released in Fluent UI
  // spec in Figma: https://www.figma.com/file/p5tprlOerFyzQ9YH4aMQBl/Avatar-Group-Fluent-UI?node-id=3%3A123
  var currentfontSize:
    | 'small'
    | 'smallest'
    | 'smaller'
    | 'medium'
    | 'large'
    | 'larger'
    | 'largest' = typeof fontSize == 'undefined' ? 'medium' : fontSize;

  return (
    <>
      {range(0, Math.min(associatedUserKeys.length, 3))
        .reverse()
        .map(i => {
          const userKey = associatedUserKeys[i];
          const user = users[userKey];
          if (associatedUserKeys.length > 3 && i === 0) {
            return <div></div>;
          } else if (associatedUserKeys.length > 3 && i === 2) {
            return (
              <div>
                <Avatar
                  size="small"
                  key={`BoardItemUserAvatar__overflow`}
                  name={`+${associatedUserKeys.length - 2}`}
                  getInitials={name => name}
                  variables={({ colorScheme }: SiteVariablesPrepared) => ({
                    borderColor: colorScheme.default.background
                  })}
                  styles={{ marginLeft: '-.375rem', order: i }}
                />
              </div>
            );
          } else if (associatedUserKeys.length == 1) {
            if (typeof user == 'undefined') {
              return <span key={Math.random().toString()}></span>;
            } else {
              return (
                <div key={`BoardItemUserAvatar__${userKey}`}>
                  <Flex vAlign="center">
                    <Box>
                      <Avatar
                        size={avatarSize}
                        name={getText(locale, user.name)}
                        variables={({
                          colorScheme
                        }: SiteVariablesPrepared) => ({
                          borderColor: colorScheme.default.background
                        })}
                        {...(user.image ? { image: user.image } : {})}
                        styles={{
                          order: i,
                          ...(i > 0 ? { marginLeft: '-.375rem' } : {})
                        }}
                      />
                    </Box>
                    <Box>
                      <Text weight={fontWeight} size={currentfontSize}>
                        {user.name}
                      </Text>
                      {typeof datecreated != 'undefined' && (
                        <RelativeDate
                          date={datecreated}
                          className={'caption'}
                        />
                      )}
                    </Box>
                  </Flex>
                </div>
              );
            }
          } else {
            return (
              <Avatar
                size="small"
                key={`BoardItemUserAvatar__${userKey}`}
                name={getText(locale, user.name)}
                variables={({ colorScheme }: SiteVariablesPrepared) => ({
                  borderColor: colorScheme.default.background
                })}
                {...(user.image ? { image: user.image } : {})}
                styles={{
                  order: i,
                  ...(i > 0 ? { marginLeft: '-.375rem' } : {})
                }}
              />
            );
          }
        })}
    </>
  );
};
export interface AffectedUsersGroupInterface {
  affectedUsersGroupList: any[];
}

export interface CollaboratorsGroupInterface {
  collaboratorList: any[];
}


interface IBoardItemBody {
  locale: TLocale;
  textObject: TTextObject;
}

const BoardItemBody = ({ locale, textObject }: IBoardItemBody) => {
  return <Text>{getText(locale, textObject)}</Text>;
};

interface IBoardItemPreview {
  preview: string;
}
export interface LooseObject {
  [key: string]: any;
}

interface DropdownProps {
  content: string;
  active: boolean;
}

export const BoardItemPreview = ({ preview }: IBoardItemPreview) => {
  return (
    <Box
      styles={{
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        width: '100%',
        height: '6.625rem',
        marginBottom: '.75rem'
      }}
      style={{
        backgroundImage: `url(${preview})`
      }}
    />
  );
};

interface BoardModalProps {
  item: IPreparedBoardItem;
  users: TUsers;
}

interface IBoardModalProps {
  t: TTranslations;
  item: IPreparedBoardItem;
  users: TUsers;
  platformUsers: PlatformUser[];
}

// this (BoardModal) is currently not used but will be in the future.
const BoardModal = React.memo((props: IBoardModalProps) => {
  const { item, platformUsers } = props;
  const { t } = useTranslation();
  return (
    <div>
      <Container>
        <Row>
          <Col sm={12} style={{ marginBottom: '8px' }}>
            <Flex
              gap="gap.small"
              vAlign="start"
              style={{ marginBottom: '2px' }}
            >
              <Text content={item.title} size="large" />
            </Flex>
            <Text size="smaller">
              Last Changed <RelativeDate date={item.ModifiedDate} />
            </Text>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            {typeof item.users != 'undefined' && item.users.length > 0 && (
              <Flex
                vAlign="center"
                className={'m-2'}
                style={{ marginBottom: '24px' }}
              >
                <Box>
                  <Person
                    personQuery={getEmailByUserId(item.users[0], platformUsers)}
                    avatarSize="small"
                    className={'ml-2'}
                  ></Person>
                </Box>
                <Box className={`ml-2`}>
                  <Text weight="semibold" size="small">
                    {getUserByUserId(item.users[0], platformUsers)}
                  </Text>
                </Box>
              </Flex>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <SpeakerPersonIcon size="small" style={{ color: '#8f90a6' }} />
            <Text size="small">Assigned to</Text>

            {typeof item.AssigneeId != 'undefined' &&
              item.AssigneeId.length > 0 && (
                <>
                  <Person
                    personQuery={getEmailByUserId(item.AssigneeId[0], platformUsers)}
                    avatarSize="small"
                  ></Person>
                  <Text size="small" className={`ml-1`}>
                    {getUserByUserId(item.AssigneeId[0], platformUsers)}
                  </Text>
                </>
              )}
            {item.AssigneeId.length == 0 && (
              <>
                <Text size="small" className={`ml-1`}>
                  Unassigned
                </Text>
              </>
            )}
          </Col>
          <Col sm={6}>
            <div>
              <CalendarIcon size="small" style={{ color: '#8f90a6' }} />
              <Text
                size="small"
                style={{ color: '#8f90a6', marginLeft: '4px' }}
              >
                Due Date:
              </Text>
              <Text size="small" style={{ marginLeft: '4px' }}>
                <span className={`ml-1`}>
                  {item.DueDate == null && <>{t('ticket.ticket-board.ticket-card.none')}</>}
                  {item.DueDate != null && (
                    <>{ConvertDateToLocale(item.DueDate, DateTime.DATE_SHORT)}</>
                  )}
                </span>
              </Text>
            </div>
          </Col>
          <Col sm={6}>
            <Text size="smaller">Priority</Text>
          </Col>
          <Col sm={6}>
            <Text size="smaller">Category</Text>
          </Col>
        </Row>
      </Container>
    </div>
  );
});

type BoxProps = Partial<IBoardItemProps> & {
  state: AppStateValue;
  isSlaEnabled: boolean;
};

const BoxItem = ({
  item,
  boardItemCardLayout,
  state,
  t,
  isSlaEnabled
}: BoxProps) => {
  const cardStyle =
    !item.preview || boardItemCardLayout.previewPosition !== 'top'
      ? { styles: { marginTop: '1.25rem' } }
      : {};

  return (
    <Box
      styles={{
        borderRadius: '4px',
        overflow: 'hidden'
      }}
      style={{
        opacity: `${
          item.StatusId == getClosed(state.ticketStatus) ? '0.6' : '1'
        }`
      }}
    >
      <Row
        style={{
          paddingTop: '16px',
          paddingLeft: '16px',
          paddingRight: '16px'
        }}
      >
        <Col>
          <StatusSection item={item} />
        </Col>
        <Col>
          <CategorySection state={state} item={item} />
        </Col>
      </Row>
      <Row
        style={{
          paddingLeft: '16px',
          paddingRight: '16px',
          paddingTop: '8px'
        }}
      >
        <Col>
          <RequesterSection
            item={item}
            boardItemCardLayout={boardItemCardLayout}
          />
        </Col>
      </Row>
      <Card.Body {...cardStyle}>
        <Row>
          <Col className={`truncate`}>
            <Text weight="semibold" size="medium">
              {item.title}
            </Text>
            {item.subtitle && (
              <Text
                size="small"
                weight="bold"
                variables={({ colorScheme }: SiteVariablesPrepared) => ({
                  color: colorScheme.foreground1
                })}
              >
                {item.subtitle}
              </Text>
            )}
          </Col>
        </Row>
        <Row style={{ marginTop: '16px' }}>
          <Col className={`truncate`}>
            <AssigneeSection item={item} />
          </Col>
          <Col className={`truncate`}>
            <UserFriendsIcon size="small" style={{ color: '#8f90a6' }} />
            <Text size="small" weight="regular">
              <span style={{ color: '#8f90a6' }} className={`ml-1`}>
                {t('ticket.ticket-board.ticket-card.group')}:{' '}
              </span>
              <span className={`ml-1`}>
                {item.SupportGroupId === null && t('ticket.ticket-board.ticket-card.none')}
                {item.SupportGroupId !== null && (
                  <>
                    {getSupportGroupName(item.SupportGroupId, state.supportgroups)}
                  </>
                )}
              </span>
            </Text>
          </Col>
        </Row>
        <Row>
          <Col className={`truncate`}>
            <PriorityIcon width={10} height={10} className="inline-block" />
            <Text size="small" weight="regular">
              <span style={{ color: '#8f90a6' }} className={`ml-1`}>
              {t('ticket.ticket-board.ticket-card.priority')}:{' '}
              </span>
              <span className={`ml-1`}>
                {`${
                  state.priority.find(c => { 
                    if (item.PriorityId == null) {
                      return c.Id == 12;
                    } else {
                      return c.Id == item.PriorityId;
                    }
                  })?.Value
                }`}
              </span>
            </Text>
          </Col>
          <Col className={`truncate`}>
            <CategoryIcon width={12} height={12} stroke="#8f90a6" className="inline-block" />
            <Text size="small" weight="regular">
              <span style={{ color: '#8f90a6' }} className={`ml-1`}>
              {t('ticket.ticket-board.ticket-card.category')}:{' '}
              </span>
              <span className={`ml-1`}>
                {getCategoryValue(item.CategoryId, state.categories)}
              </span>
            </Text>
          </Col>
        </Row>
        <Row style={{ paddingBottom: '4px', paddingTop: item.AssigneeId.length == 0 ? '' : '3px' }}>
          <Col className={`truncate`}>
            <CalendarIcon size="small" style={{ color: '#8f90a6' }} />
            <Text size="small" weight="regular">
              <span style={{ color: '#8f90a6' }} className={`ml-1`}>
              {t('ticket.ticket-board.ticket-card.due-date')}:{' '}
              </span>
              <span className={`ml-1`}>
                {item.DueDate === null && t('ticket.ticket-board.ticket-card.none')}
                {item.DueDate !== null && (
                  <>{ConvertDateToLocale(item.DueDate, DateTime.DATE_SHORT)}</>
                )}
              </span>
            </Text>
          </Col>
          <CheckFeature featureName={LICENSE_FEATURE.TicketTypes}>
            <Col className={`truncate`}>
              <TicketTypeIcon width={13} height={13} stroke="#8f90a6" className="inline-block"/>
              <Text size="small" weight="regular">
                <span style={{ color: '#8f90a6' }} className={`ml-1`}>{t('ticket.ticket-board.ticket-card.type')}: </span>
                <span className={`ml-1`}>
                  {getTicketTypeValue(item.TicketTypeId, state.ticketTypes)}
                </span>
              </Text>
            </Col>
          </CheckFeature>
        </Row>

        <Row style={{ paddingBottom: '4px', paddingTop: item.AssigneeId.length == 0 ? '' : '3px' }}>
        <CheckFeature featureName={LICENSE_FEATURE.TicketTasks}>
            <Col className={`truncate`}>
              <TaskIcon
                width={12}
                height={12}
                stroke="#8f90a6"
                className="inline-block"
              />
              <Text size="small">
                <span style={{ color: '#8f90a6' }} className={`ml-1`}>
                  Tasks:{' '}
                </span>
                <span className={`ml-1`}>
                  {item.TicketTasks?.length ?? 0}
                </span>
              </Text>
            </Col>
          </CheckFeature>
        </Row>

        <CheckFeature featureName={LICENSE_FEATURE.TicketLifecycle}>
        {item.TicketLifecycle && (
          <Row style={{ paddingBottom: '4px', paddingTop: item.AssigneeId.length == 0 ? '' : '3px' }}>
            <Col className={`truncate`}>
              <LifecycleIcon
                width={12}
                height={12}
                stroke="#8f90a6"
                className="inline-block"
              />
              <Text size="small" weight="regular">
                <span style={{ color: '#8f90a6' }} className={`ml-1`}>
                  {item.TicketLifecycle?.Lifecycle?.Title ?? ''}
                </span>
              </Text>
            </Col>
            <Col className={`truncate`}>
              <Flex style={{alignItems: 'center'}}>
                <LifecyclePhaseIcon
                  width={12}
                  height={12}
                  stroke="#8f90a6"
                  className="inline-block mr-1"
                />
                <TicketLifecyclePhaseElement 
                  extraClassNames={"ml-3"}
                  status={item.TicketLifecycle?.Status} 
                  phaseName={item.TicketLifecycle?.Phases[0]?.Name ?? ''}/>
              </Flex>
            </Col>
          </Row>
        )}
        </CheckFeature>

        {isSlaEnabled && (
          <div>
            <SlaPills
              ticketSlas={item.TicketSlas}
              svgGap={false}
              isLoading={state.isLoadingSla || item.IsSlasFetching}
              viewType="Board"
            />
          </div>
        )}
      </Card.Body>
      <Card.Footer style={{ margin: '0px', padding: '0px 0px 8px 0px' }}>
        <div>
          <Flex>
            <Box
              styles={{
                flex: '1 0 auto',
                display: 'flex'
              }}
            >
              <div
                style={{
                  marginTop: '8px',
                  marginBottom: '8px',
                  marginRight: '16px',
                  marginLeft: '16px',
                  width: '100%'
                }}
              >
                <Row>
                  <Col sm={11}>
                    <Flex vAlign="center">
                      <CalendarIcon style={{ color: '#8f90a6' }} />
                      <Text
                        size="small"
                        weight="regular"
                        style={{
                          color: '#8f90a6',
                          marginLeft: '8px'
                        }}
                      >
                        {t('ticket.ticket-board.ticket-card.modified')}:&nbsp;
                      </Text>
                      <Text size="small" weight="regular" style={{ marginLeft: '8px' }}>
                        <RelativeDate date={item.ModifiedDate} />
                      </Text>
                    </Flex>
                  </Col>
                </Row>
              </div>
            </Box>
          </Flex>
        </div>
      </Card.Footer>
    </Box>
  );
};

export const BoardItem = React.memo((props: IBoardItemProps) => {
  const {
    isDragging,
    draggableProps,
    dragHandleProps,
    t,
    item,
    editItemDialog,
    boardItemCardLayout,
    isSlaEnabled
  } = props;

  const [itemNode, setItemNode] = useState<HTMLElement | null>(null);

  const getA11Props = useAccessibility(boardItemBehavior, {
    actionHandlers: {
      preventDefault: event => {
        // preventDefault only if event coming from inside the lane
        if (event.currentTarget !== event.target) {
          event.preventDefault();
        }
      },

      focus: event => {
        if (itemNode && event.target !== event.currentTarget) {
          itemNode.focus();
          event.stopPropagation();
        }
      }
    }
  });

  let history = useHistory();

  const routeChange = (ticketId: number) => {
    history.push(`/tickets/${ticketId}`, { from: 'board' });
  };

  return (
    <AppStateContext.Consumer>
      {state => {
        return (
          <Ref innerRef={setItemNode}>
            {getA11Props.unstable_wrapWithFocusZone(
              <Card
                onClick={e => {
                  e.preventDefault();
                  routeChange(item.Id);
                }}
                {...getA11Props('root', {
                  elevated: true,
                  variables: ({ colorScheme }: SiteVariablesPrepared) => ({
                    elevation: isDragging
                      ? colorScheme.elevations[8]
                      : colorScheme.elevations[4],
                    hoverElevation: colorScheme.elevations[8],
                    backgroundColor: colorScheme.default.background,
                    borderColor: isDragging
                      ? colorScheme.default.borderHover
                      : colorScheme.default.border
                  }),
                  styles: {
                    position: 'relative',
                    zIndex: 1,
                    margin: `0 1.25rem .5rem 1.25rem`,
                    width: 'auto',
                    height: 'auto'
                  },
                  ...{ ...(item.title !== 'temp' && { ...draggableProps }) },
                  ...{ ...(item.title !== 'temp' && { ...dragHandleProps }) },
                  'aria-label': `${t('ticket.ticket-board.board-item')}, ${item.title}`,
                  ...(isDragging ? { 'data-isdragging': true } : {}),
                  tabIndex: -1
                })}
              >
                <div style={{ display: 'none' }}>
                  <Popup
                    content={editItemDialog}
                    trigger={
                      <Button
                        text
                        iconOnly
                        data-is-focusable="true"
                        aria-label={t('ticket.ticket-board.board-item-options')}
                        icon={<MoreIcon size="small" outline />}
                        styles={{ minWidth: '1.25rem', height: '1.25rem' }}
                      />
                    }
                    position="below"
                    accessibility={dialogBehavior}
                    autoFocus={true}
                  />
                </div>
                {item.title === 'temp' ? (
                  <SkeletonBoardCard />
                ) : (
                  <a href={`/tickets/${item.Id}`}>
                    <BoardTooltip sourceTicketId={item.SourceTicketId}>
                      <BoxItem
                        isSlaEnabled={isSlaEnabled}
                        item={item}
                        state={state}
                        boardItemCardLayout={boardItemCardLayout}
                        t={t}
                      />
                    </BoardTooltip>
                  </a>
                )}
              </Card>
            )}
          </Ref>
        );
      }}
    </AppStateContext.Consumer>
  );
});
