import React from 'react';
import { Box, Flex, Skeleton, ProviderConsumer } from '@fluentui/react-northstar';
import { Col, Row } from 'react-grid-system';

export const RequestCardSkeleton = () => (
  <ProviderConsumer render={globalTheme => (
    <Flex column className={`relative flex flex-col`}>
      <div className={`px-5 pt-5 mb-5`} style={{ minHeight: '60px', backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}>
        <Box aria-roledescription="card with avatar, image and action buttons">
          <Row>
            <Col style={{ marginBottom: 15 }}>
              <Flex gap="gap.small" style={{ marginBottom: 15 }}>
                <Skeleton animation="wave">
                  <Skeleton.Shape width="100px" height="20px" />
                </Skeleton>

                <div style={{ float: 'right' }}>
                  <Skeleton animation="wave">
                    <Skeleton.Line width="80px" height="20px" />
                  </Skeleton>
                </div>
              </Flex>

              <Flex gap="gap.small" className={`mb-4`}>
                <Skeleton animation="wave">
                  <Skeleton.Shape width="100%" height="20px" />
                  <Skeleton.Shape width="50%" height="20px" />
                </Skeleton>
              </Flex>

              <Skeleton animation="wave">
                <Flex gap="gap.large">
                  <Flex gap="gap.small" vAlign="center">
                    <Skeleton.Shape round width="30px" height="30px" />
                    <Flex column>
                      <Skeleton.Shape width="100px" height="20px" />
                      <Skeleton.Shape width="250px" height="20px" />
                    </Flex>
                  </Flex>
                </Flex>
              </Skeleton>

              <Flex gap="gap.small" className={`mt-4`}>
                <Skeleton animation="wave">
                  <Skeleton.Shape width="200px" height="20px" />
                </Skeleton>

                <div style={{ float: 'right' }}>
                  <Skeleton animation="wave">
                    <Skeleton.Line width="100px" height="20px" />
                  </Skeleton>
                </div>
              </Flex>
            </Col>
          </Row>
        </Box>
      </div>
    </Flex>
  )} />
);
