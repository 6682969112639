import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import Styles from './BulkEditTicket.module.css';
import {
  Datepicker,
  Dropdown,
  Text,
  ProviderConsumer as FluentUIThemeConsumer,
  Loader,
  Button
} from '@fluentui/react-northstar';
import MgtCss from '../../../mgt.module.css';
import { ThemeColorScheme } from '../../common/TeamsTheme';
import { PeoplePicker } from '@microsoft/mgt-react';
import { NestedDropdown } from '../NestedDropdown/NestedDropdown';
import {
  createTag,
  DropdownAssignee,
  DropdownSupportGroup,
  getBackgroundColor,
  getTagsList,
  LabelElement
} from '../TicketForm';
import { appState, AppStateContext } from '../../../AppState';
import { ConvertDateToLocale } from '../../common/ConvertDate';
import { LICENSE_FEATURE, STATUS_GUID, TAGS } from '../../utils/constants';
import { LabelWithCheckbox } from '../LabelWithCheckbox/LabelWithCheckbox';
import { CheckFeature } from '../CheckFeature';
import { ISupportGroup, ITeam } from '../../interfaces/supportGroup.interface';
import { getCachedFeature } from '../../cache/FeatureCache';
import { UserType, PersonType } from '@microsoft/mgt-components';
import { getStatusIdByGuid } from '../../../tikit/ticketHelper';
import { DatepickerLocalizations } from '../../../App';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { platformService } from '../../services/platform.service';

interface Props {
  ticketData: BulkTicketEntity;
  setTicketData: (
    ticketData:
      | BulkTicketEntity
      | ((value: BulkTicketEntity) => BulkTicketEntity)
  ) => void;
}

export const BulkEditTicketForm = ({ ticketData, setTicketData }: Props) => {
  const {
    ticketStatus,
    categories,
    priority,
    ticketTypes,
    supportgroups,
    usersInRole,
    teams
  } = useContext(AppStateContext);
  const api = new platformService();

  const [supportGroupsBulkEdit, setSupportGroupsBulkEdit] = useState<ISupportGroup[]>([]);
  const [teamsBulkEdit, setTeamsBulkEdit] = useState<ITeam[]>([]);
  const tagSearch = useRef(null);
  const [tagList, setTagList] = useState([]);
  const [tagSearchQuery, setTagSearchQuery] = useState<string>('');
  const [isCreatingTag, setIsCreatingTag] = useState<boolean>(false);
  const [filteredUsers, setFilteredUsers] = useState(usersInRole);
  const [filteredGroups, setFilteredGroups] = useState(supportgroups);
  const [assigneeFullName, setAssigneeFullName] = useState('');
  const [enabledMultiDepartment, setEnabledMultiDepartment] = useState(false);

  const currentState = appState();
  const defaultAssigneeOption = { Id: -1, FullName: 'Unassigned' };
  const { t } = useTranslation();

  
  const [searchTeam, setSearchTeam] = useState("");
  const onChangeTeamQuery = useCallback((searchQuery: string) => {
    setSearchTeam(searchQuery);
  }, [searchTeam]);
  
  (async () => {
    const enabled = await getCachedFeature(LICENSE_FEATURE.MultiDepartment);
    setEnabledMultiDepartment(enabled);
  })();

  useEffect(() => {
    let _group = [
      ...filteredGroups
    ];

    if(_group.map(x => x.Id).indexOf(-1) == -1)
      _group.unshift(
        {
          Id: -1,
          Members: [],
          Name: t('ticket.ticket-list.bulk-edit.no-group'),
          Description: '',
          TeamsChannelId: '',
          TeamsAadObjectId: '',
          ApplicationRoleId: 0
        } as ISupportGroup);

    setSupportGroupsBulkEdit(_group);
  }, [filteredGroups]);

  useEffect(() => {
    const _team = [...teams];
    setTeamsBulkEdit(_team);
  }, [teams]);

  useEffect(() => {
    const tagsPromises = getTagsList();
    tagsPromises.then(taglist => {
      setTagList(taglist);
    });
  }, []);

  const customFormatter = date => ConvertDateToLocale(date, DateTime.DATE_SHORT);

  const requestChangeHandler = async (e: any) => {
    if (e.detail.length === 0) return;

    const { displayName, givenName, surname, userPrincipalName, id, scoredEmailAddresses } = e.detail[0];
    const payload = {
      FullName: displayName,
      GivenName: givenName,
      FamilyName: surname,
      Email: scoredEmailAddresses?.[0]?.address,
      UserName: userPrincipalName,
      AadObjectId: id
    };

    setTicketData(preState => ({
      ...preState,
      RequesterId: {
        ...preState.RequesterId,
        value: payload
      }
    }));
  };

  const affectedUserChangeHandler = async (e: any) => {
    const userList = [];
    if (e.detail.length > 0) {
      let users = e.detail.map((item: any) => {
        const { id, displayName, givenName, surname, userPrincipalName, scoredEmailAddresses } = item;
        return {
          Name: displayName,
          GivenName: givenName,
          FamilyName: surname,
          Email: scoredEmailAddresses?.[0]?.address,
          UserName: userPrincipalName ?? "",
          AadObjectId: id ?? null,
        };
      });
      
      for (let u of users) 
        userList.push(currentState.platformusers.find(o => (u.AadObjectId != null && o.AadObjectId == u.AadObjectId) || o.UserName == u.UserName)?.Id ?? (await api.getOrCreatePlatformUser(u)).Id);
    }

    setTicketData(preState => ({
      ...preState,
      AffectedUsers: {
        ...preState.AffectedUsers,
        value: userList
      }
    }));
  };
  const collaboratorChangeHandler = (_, p) => {
    const userList = [];
    p.value.forEach(i => userList.push(parseInt(i.accessibilityitemprops, 0)));
    
    setTicketData(preState => ({
      ...preState,
      TicketCollaborators: {
        ...preState.TicketCollaborators,
        value: userList
      }
    }));
  };

  const isTagNameEmpty = () => {
    return (
      tagSearchQuery === null ||
      tagSearchQuery === '' ||
      tagSearchQuery.trim() === ''
    );
  };

  const assignmentCheckbox = (multiDepartmentEnabled, dataTicket) => {
    return multiDepartmentEnabled ? dataTicket.TeamId.required : dataTicket.SupportGroupId.required
  }

  const assignmentRequired = (multiDepartmentEnabled, dataTicket) => {
    return (!multiDepartmentEnabled || (multiDepartmentEnabled && dataTicket.TeamId.required)) &&
    dataTicket.SupportGroupId.required &&
    dataTicket.AssigneeId.required
  }

  const ifTeamNotSelected = (value) => {
    defaultAssigneeOption.FullName = t('ticket.ticket-board.ticket-card.unassigned');
    if (value.Id === -1 || value.Id == null) {
      setFilteredGroups([...supportgroups]);
      setFilteredUsers([ { ...defaultAssigneeOption }, ...usersInRole ]);
    }
    else {
      setFilteredGroups([...supportgroups.filter(x => x.TeamsAadObjectId == value.TeamsAadObjectId)]);
      setFilteredUsers([ { ...defaultAssigneeOption }, ...(currentState.teams.filter(x => x.Id == value.Id)[0]?.Users || []) ]);
    }
  }

  const filterGroups = (value: any) => {
    defaultAssigneeOption.FullName = t('ticket.ticket-board.ticket-card.unassigned');
    if (value?.Id && value.Id != -1 && !value.TeamsAadObjectId) {
      setFilteredGroups([...supportGroupsBulkEdit.filter(x => x.Id == -1)]);
      setFilteredUsers([ { ...defaultAssigneeOption }, ...[] ]);
    }
    else {
      ifTeamNotSelected(value);
    }
  }

  const handlerTeam = (value: any, event: string) => {
    setTicketData(preState => ({ ...preState,
      TeamId: { ...preState.TeamId, value: value.Id },
      SupportGroupId: { ...preState.SupportGroupId, value: null },
      AssigneeId: { ...preState.AssigneeId, required: true, value: null }
    }));

    if (event == 'onChange') {
      filterGroups(value);
      setAssigneeFullName('');
    }
  }

  const ifGroupNotSelected = (value: any) => {
    defaultAssigneeOption.FullName = t('ticket.ticket-board.ticket-card.unassigned');
    if (!ticketData.TeamId.value || ticketData.TeamId.value === -1) {
      setFilteredUsers([ { ...defaultAssigneeOption }, ...usersInRole ]);
    } else {
      const filteredUsers = currentState.teams.filter(x => x.Id == ticketData.TeamId.value)[0]?.Users || [];
      setFilteredUsers([ { ...defaultAssigneeOption }, ...filteredUsers ]);
    }
  }

  const filterUsers = (value: any) => {
    defaultAssigneeOption.FullName = t('ticket.ticket-board.ticket-card.unassigned');
    if (value.Id === -1 || value.Id == null) {
      ifGroupNotSelected(value);
    } else {
      setFilteredUsers([ { ...defaultAssigneeOption }, ...value.Members ]);
    }
  }

  const groupHandler = (value: any, event: string) => {
    setTicketData(preState => ({ ...preState,
      SupportGroupId: { ...preState.SupportGroupId, value: value.Id },
      AssigneeId: { ...preState.AssigneeId, required: true, value: null }
    }));

    if (event == 'onChange') {
      filterUsers(value);
      setAssigneeFullName('');
    }
  }

  return (
    <FluentUIThemeConsumer
      render={globalTheme => {
        return (
          <div
            style={{
              height: '100%',
              ...ThemeColorScheme(globalTheme.siteVariables)
            }}
          >
            <div className={Styles.BulkEditTicketForm}>
              <LabelWithCheckbox
                checked={ticketData.RequesterId.required}
                label={t('ticket.add-ticket.requester')}
                onChange={() => {
                  setTicketData(preState => ({
                    ...preState,
                    RequesterId: {
                      ...preState.RequesterId,
                      required: !preState.RequesterId.required
                    }
                  }));
                }}
                required={ticketData.RequesterId.required}
              >
                <PeoplePicker
                  userType={UserType.user} 
                  type={PersonType.person}
                  selectionMode="single"
                  selectionChanged={requestChangeHandler}
                  disabled={!ticketData.RequesterId.required}
                />
              </LabelWithCheckbox>

              <CheckFeature featureName={LICENSE_FEATURE.AffectedUsers}>
                <LabelWithCheckbox checked={ticketData.AffectedUsers.required}
                  label={t('ticket.ticket-list.affected-users')}
                  onChange={() => {
                    setTicketData(preState => ({ ...preState, AffectedUsers: { ...preState.AffectedUsers, required: !preState.AffectedUsers.required } }));
                  }}>
                  <PeoplePicker
                    userType={UserType.user} 
                    type={PersonType.person}
                    className={`width-med height-small`}
                    disabled={!ticketData.AffectedUsers.required}
                    selectionChanged={affectedUserChangeHandler}
                  />
                </LabelWithCheckbox>
              </CheckFeature>

              <LabelWithCheckbox
                checked={ticketData.StatusId.required}
                label={t('ticket.add-ticket.status')}
                onChange={() => {
                  setTicketData(preState => ({
                    ...preState,
                    StatusId: {
                      ...preState.StatusId,
                      required: !preState.StatusId.required
                    }
                  }));
                }}
                required={ticketData.StatusId.required}
              >
                <NestedDropdown
                  disabled={!ticketData.StatusId.required}
                  items={ticketStatus.filter(item => item.Id != getStatusIdByGuid(currentState.ticketStatus, STATUS_GUID.DEFLECTED)).map(item => ({
                    id: item.Id,
                    parentId: item.ParentId,
                    position: item.Position,
                    value: item.Value,
                    ...item
                  }))}
                  defaultValue={null}
                  onChange={value => {
                    setTicketData(preState => ({
                      ...preState,
                      StatusId: {
                        ...preState.StatusId,
                        value: value.id
                      }
                    }));
                  }}
                />
              </LabelWithCheckbox>
              <CheckFeature featureName={LICENSE_FEATURE.TicketTypes}>
                <LabelWithCheckbox
                  checked={ticketData.TicketTypeId.required}
                  label={t('ticket.add-ticket.type')}
                  onChange={() => {
                    setTicketData(preState => ({
                      ...preState,
                      TicketTypeId: {
                        ...preState.TicketTypeId,
                        required: !preState.TicketTypeId.required
                      }
                    }));
                  }}
                  required={ticketData.TicketTypeId.required}
                >
                  <NestedDropdown
                    disabled={!ticketData.TicketTypeId.required}
                    defaultValue={null}
                    items={ticketTypes.map(item => ({
                      id: item.Id,
                      parentId: item.ParentId,
                      position: item.Position,
                      value: item.Value,
                      ...item
                    }))}
                    onChange={value => {
                      setTicketData(preState => ({
                        ...preState,
                        TicketTypeId: {
                          ...preState.TicketTypeId,
                          value: value.id
                        }
                      }));
                    }}
                  />
                </LabelWithCheckbox>
              </CheckFeature>
              <LabelWithCheckbox
                checked={ticketData.CategoryId.required}
                label={t('ticket.add-ticket.category')}
                onChange={() => {
                  setTicketData(preState => ({
                    ...preState,
                    CategoryId: {
                      ...preState.CategoryId,
                      required: !preState.CategoryId.required
                    }
                  }));
                }}
                required={ticketData.CategoryId.required}
              >
                <NestedDropdown
                  disabled={!ticketData.CategoryId.required}
                  defaultValue={null}
                  items={categories.map(item => ({
                    id: item.Id,
                    parentId: item.ParentId,
                    position: item.Position,
                    value: item.Value,
                    ...item
                  }))}
                  onChange={value => {
                    setTicketData(preState => ({
                      ...preState,
                      CategoryId: {
                        ...preState.CategoryId,
                        value: value.id
                      }
                    }));
                  }}
                />
              </LabelWithCheckbox>
              <LabelWithCheckbox
                checked={ticketData.PriorityId.required}
                label={t('ticket.add-ticket.priority')}
                onChange={() => {
                  setTicketData(preState => ({
                    ...preState,
                    PriorityId: {
                      ...preState.PriorityId,
                      required: !preState.PriorityId.required
                    }
                  }));
                }}
                required={ticketData.PriorityId.required}
              >
                <NestedDropdown
                  disabled={!ticketData.PriorityId.required}
                  defaultValue={null}
                  items={priority.map(item => ({
                    id: item.Id,
                    parentId: item.ParentId,
                    position: item.Position,
                    value: item.Value,
                    ...item
                  }))}
                  onChange={value => {
                    setTicketData(preState => ({
                      ...preState,
                      PriorityId: {
                        ...preState.PriorityId,
                        value: value.id
                      }
                    }));
                  }}
                />
              </LabelWithCheckbox>

              <LabelWithCheckbox
                checked={assignmentCheckbox(enabledMultiDepartment, ticketData)}
                label={t('ticket.add-ticket.assignment')}
                onChange={() => {
                  setTicketData(preState => ({
                    ...preState,
                    TeamId: {
                      ...preState.TeamId,
                      required: enabledMultiDepartment && !preState.TeamId.required
                    },
                    SupportGroupId: {
                      ...preState.SupportGroupId,
                      required: !preState.SupportGroupId.required
                    },
                    AssigneeId: {
                      ...preState.AssigneeId,
                      required: !preState.AssigneeId.required
                    }
                  }));
                }}
                required={assignmentRequired(enabledMultiDepartment, ticketData)}
              >
                <div>
                  <CheckFeature featureName={LICENSE_FEATURE.MultiDepartment}>
                    <LabelElement label={t('ticket.add-ticket.team')}>
                    <DropdownSupportGroup
                      disabled={!ticketData.TeamId.required}
                      items={teamsBulkEdit}
                      handler={(value: any, event?: string) => {handlerTeam(value, event)}}
                      properties={{ defaultValue: undefined }}
                      value={ticketData.TeamId.value}
                      header={'FriendlyName'}
                      setSearchTeam={setSearchTeam}
                      searchQuery={searchTeam}
                      onSearchQueryChange={onChangeTeamQuery}
                    />
                    </LabelElement>
                  </CheckFeature>
                  <div className={`pt-2`}>
                    <Text content={t('ticket.add-ticket.group')}></Text>
                    <DropdownSupportGroup
                      isClearable={false}
                      disabled={!ticketData.SupportGroupId.required}
                      items={supportGroupsBulkEdit}
                      handler={(value: any, event?: string) => {groupHandler(value, event)}}
                      properties={{ defaultValue: undefined }}
                      value={ticketData.SupportGroupId.value}
                    />
                  </div>
                  <div className={`pt-2`}>
                    <Text content={t('ticket.add-ticket.assignee')}></Text>
                    <DropdownAssignee
                      setAssigneeFullName={setAssigneeFullName}
                      searchQuery={assigneeFullName}
                      disabled={!ticketData.AssigneeId.required}
                      onSearchQueryChange={(searchQuery: string) => setAssigneeFullName(searchQuery)}
                      assignees={filteredUsers}
                      handler={(value: any, _event?: string) => {
                        if (_event == 'onChange') {
                          if (!value) setAssigneeFullName('');
                          setTicketData(preState => ({ ...preState,  AssigneeId: { ...preState.AssigneeId, value: value || 0 }}));
                        }
                      }}
                      value={ticketData.AssigneeId.value}
                      properties={{ defaultValue: undefined }}
                    />
                  </div>
                </div>
              </LabelWithCheckbox>

              <CheckFeature featureName={LICENSE_FEATURE.Collaborators}>
                <LabelWithCheckbox checked={ticketData.TicketCollaborators.required}
                  label={t('ticket.ticket-list.collaborators')}
                  onChange={() => {
                    setTicketData(preState => ({ ...preState, TicketCollaborators: { ...preState.TicketCollaborators, required: !preState.TicketCollaborators.required } }));
                  }}>
                  <Dropdown
                    disabled={!ticketData.TicketCollaborators.required}
                    multiple
                    fluid
                    items={filteredUsers.map(c => ({ header: c.FullName, accessibilityitemprops: c.Id, selected: false })).filter((t: any) => ticketData.AssigneeId.value != t.accessibilityitemprops)}
                    onChange={collaboratorChangeHandler}
                    searchInput={{ inline: true, inputRef: tagSearch, styles: { paddingLeft: '8px', height: '32px' } }}
                    noResultsMessage={<div>No User found</div>}
                    style={{ backgroundColor: getBackgroundColor(globalTheme) }}
                    className={'collaborators--dropdown'}
                  />
                </LabelWithCheckbox>
              </CheckFeature>

              <LabelWithCheckbox
                checked={ticketData.DueDate.required}
                label={t('ticket.add-ticket.due-date')}
                onChange={() => {
                  setTicketData(preState => ({
                    ...preState,
                    DueDate: {
                      ...preState.DueDate,
                      required: !preState.DueDate.required
                    }
                  }));
                }}
                required={ticketData.DueDate.required}
              >
                <Datepicker
                  {...DatepickerLocalizations}
                  firstDayOfWeek={0}
                  disabled={!ticketData.DueDate.required}
                  formatMonthDayYear={customFormatter}
                  className={`fluent-date ${MgtCss.inverted} flex`}
                  onDateChange={(_e, p) => {
                    setTicketData({
                      ...ticketData,
                      DueDate: {
                        ...ticketData.DueDate,
                        value: new Date(p.value)
                      }
                    });
                  }}
                  {...(ticketData.DueDate.value !== undefined && {
                    defaultSelectedDate: ticketData.DueDate.value
                  })}
                />
              </LabelWithCheckbox>

              <LabelWithCheckbox label={t('ticket.add-ticket.resolution-date')}
                checked={ticketData.ResolutionDate.required}
                required={ticketData.ResolutionDate.required}
                onChange={() => {
                  setTicketData(preState => ({
                    ...preState, ResolutionDate: { ...preState.ResolutionDate, required: !preState.ResolutionDate.required }
                  }));
                }}
              >
                <Datepicker
                  {...DatepickerLocalizations}
                  firstDayOfWeek={0}
                  className={`fluent-date ${MgtCss.inverted} flex`}
                  disabled={!ticketData.ResolutionDate.required}
                  formatMonthDayYear={customFormatter} 
                  onDateChange={(_e, p) => {
                    setTicketData({ ...ticketData, ResolutionDate: { ...ticketData.ResolutionDate, value: new Date(p.value) } });
                  }}
                  {...(ticketData.ResolutionDate.value !== undefined && { defaultSelectedDate: ticketData.ResolutionDate.value })}
                />
              </LabelWithCheckbox>
              <CheckFeature featureName={LICENSE_FEATURE.TicketTags}>
                <LabelWithCheckbox
                  checked={ticketData.Tags.required}
                  label={t('ticket.add-ticket.tag')}
                  onChange={() => {
                    setTicketData(preState => ({
                      ...preState,
                      Tags: {
                        ...preState.Tags,
                        required: !preState.Tags.required
                      }
                    }));
                  }}
                  required={ticketData.Tags.required}
                >
                  <Dropdown
                    className={`${MgtCss.invertedDropdown}`}
                    disabled={!ticketData.Tags.required}
                    items={tagList}
                    fluid
                    placeholder={TAGS.SELECT_TAGS}
                    multiple
                    search
                    noResultsMessage={
                      <div style={{ textAlign: 'center' }}>
                        {(tagSearchQuery.startsWith(' ') || tagSearchQuery.endsWith(' ') && (
                          <Text
                            error
                            content={t('ticket.add-ticket.tags.tags-error-message')}
                          />
                        ))}
                        {!tagSearchQuery.startsWith(' ') && !tagSearchQuery.endsWith(' ') && (
                            <>
                              {isCreatingTag && (<Loader label={t('ticket.add-ticket.tags.save-tag')} />)}
                              {!isCreatingTag && (
                                <>
                                  <div>
                                    {!isTagNameEmpty() && (
                                      <Text
                                      content={`${t('ticket.add-ticket.tags.new-tag')} '${tagSearchQuery}'`}
                                      />
                                    )}
                                  </div>
                                  <Button
                                    disabled={isTagNameEmpty()}
                                    content={t('ticket.add-ticket.tags.add-tag')}
                                    primary
                                    className={`mt-3`}
                                    onClick={() => {
                                      setIsCreatingTag(true);
                                      createTag({ Name: tagSearchQuery }).then(
                                        response => {
                                          tagSearch.current.value = '';
                                          const newTag = {
                                            header: tagSearchQuery,
                                            accessibilityitemprops: response.Id,
                                            selected: true
                                          };
                                          setTagList([...tagList, newTag]);
                                          setIsCreatingTag(false);
                                        }
                                      );
                                    }}
                                  />
                                </>
                              )}
                            </>
                          )}
                      </div>
                    }
                    a11ySelectedItemsMessage={t('ticket.add-ticket.tags.selected-items-message')}
                    onChange={(_e, p) => {
                      const stringifyTheValue = JSON.stringify(p.value);
                      const parsedValue: any[] = JSON.parse(stringifyTheValue);
                      let formattedNewTags = [];
                      parsedValue.forEach(item => {
                        const tagId = item['accessibilityitemprops'];
                        formattedNewTags.push(tagId);
                      });
                      setTicketData(preState => ({
                        ...preState,
                        Tags: {
                          ...preState.Tags,
                          value: formattedNewTags
                        }
                      }));
                    }}
                    onSearchQueryChange={(_e, p) => {
                      setTagSearchQuery(p.searchQuery);
                    }}
                    searchInput={{
                      inline: true,
                      inputRef: tagSearch,
                      styles: {
                        paddingLeft: '8px',
                        height: '32px'
                      }
                    }}
                  />
                </LabelWithCheckbox>
              </CheckFeature>
            </div>
          </div>
        );
      }}
    />
  );
};
