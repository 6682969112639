import React from "react";
import { AddIcon, Button, ProviderConsumer } from "@fluentui/react-northstar";
import { Toolbar } from "../shared/components/Toolbar";
import { SETTINGS } from "../shared/utils/constants";
import { toTitleCase } from "../shared/utils/helper";
import { useHistory } from "react-router-dom";
import { Breadcrumbs } from "../shared/components/BreadcrumbNavigation";
import { toolbarButtonColor } from "../tikit/ticketHelper";
import { useTranslation } from 'react-i18next';
import { LIFECYCLE } from '..//shared/utils/constants';

interface Props {
    title: string;
    addNewTitle?: string;
    routeName: string;
    breadcrumbs: Breadcrumbs;
    isUserLicensed: boolean;
  }

export const LifeCycleToolbar = ({breadcrumbs, addNewTitle, routeName, title, isUserLicensed}: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  
  return (
    <ProviderConsumer
      render={(globalTheme) => (
        <Toolbar
          globalTheme={globalTheme}
          title={`${LIFECYCLE.BREADCRUMB_SETTINGS} > ${title}`}
          breadcrumbs={breadcrumbs}
          addNew
          addNewContent={
            <Button
              icon={<AddIcon />}
              text
              primary
              content={addNewTitle}
              onClick={() => {
                if (isUserLicensed) {
                  const path = `/settings/${routeName}?id=0`;
                  history.push(path);
                }
              }}
              style={{
                cursor: isUserLicensed ? 'pointer' : 'auto',
                color:  toolbarButtonColor(isUserLicensed, globalTheme.siteVariables.colorScheme),
                height: "52px",
              }}
            />
          } 
        />
      )}
    />
  );
};
