import React, { useEffect, useContext } from 'react';
import DetailsListTable from './DetailsListTable';
import { TablePagination } from '../../../shared/components/table/TablePagination';
import { TICKET_PAGINATION } from '../../../shared/utils/constants';
import { SkeletonTable } from '../../../shared/components/Skeleton';
import useMyWorkListFunctions from './useMyWorkListFunctions';
import { MyWorkContext } from '../MyWorkContextProvider';
import { getSelectedTabFromStorage } from './myWorkListHelper';
import { Route } from '../../../../route-constants';
import { useLocation } from 'react-router-dom';

const MyWorkList = () => {
  const { moveToPage, getpaginatedMyWorkList, setMyWorkListInterval } = useMyWorkListFunctions();
  const { myWorkStates } = useContext(MyWorkContext);
  const location = useLocation();

  const isMyGroupWork = location.pathname.includes(Route.myGroupWork.pagePath);

  useEffect(() => {
    getpaginatedMyWorkList({});
    const timer = setMyWorkListInterval();
    return () => { clearInterval(timer); };
  }, []);

  return myWorkStates.myWorkList.isFetching ? (
    <SkeletonTable />
  ) : (
    <>
      <div style={{ height: 'calc(100% - 37px)', overflow: 'auto' }}>
        <DetailsListTable />
      </div>
      <div style={{ height: '37px' }}>
        <TablePagination
          currentPage={myWorkStates.myWorkList.currentPage}
          totalCount={myWorkStates.myWorkList.dataCount[getSelectedTabFromStorage(isMyGroupWork) ?? myWorkStates.myWorkList.selectedTabId]}
          moveToPage={moveToPage}
          itemPerPage={TICKET_PAGINATION.ITEM_PER_PAGE}
        />
      </div>
    </>
  );
};

export default MyWorkList;
