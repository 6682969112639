import { Box, Text, Button, Flex, Loader, Divider, Tooltip, Dialog, Checkbox, LockIcon, ThemePrepared } from "@fluentui/react-northstar";
import React, { useState } from "react";
import { graphService } from '../shared/services/graph.service';
import { CancelIcon, CellPhoneIcon, OpenInNewWindowIcon, PowerButtonIcon, TVMonitorIcon, UndoIcon } from '@fluentui/react-icons-mdl2';
import WindowsIcon from "../../svg/windows.svg";
import AppleIcon from "../../svg/apple.svg";
import AndroidIcon from "../../svg/android.svg";
import { Toast } from '../../components/shared/components/toast/Toast';
import { ProgressBar, WipeData } from "./UserForm";
import { ManagedDevice } from "../shared/interfaces/platformuser.interface";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

interface ManageDeviceCardProps {
    device?: ManagedDevice;
    userId: string;
    allowedPermission?: string[];
    globalTheme: ThemePrepared<any>;
    owned?: boolean;
    isUserLicensed: boolean;
}


export const getDeviceIcon = (operatingSystem: string) => {
    switch (operatingSystem) {
        case "Windows": {
            //statements; 
            return <TVMonitorIcon />
        }
        case "MacOS": {
            //statements; 
            return <TVMonitorIcon />
        }
        case "Android": {
            //statements; 
            return <CellPhoneIcon />
        }
        case "iOS": {
            //statements; 
            return <CellPhoneIcon />
        }
        default: {
            //statements; 
            return <TVMonitorIcon />
        }
    }
}

export const GetOperatingSystem = (props: { operatingSystem: string }) => {
    return (
        <Box>
            <Flex className="items-center">
                <Text size="small" content="OS: " />
                {(props.operatingSystem === "Windows") && (<WindowsIcon height={18} width={18} className="px-1" />)}
                {(props.operatingSystem === "iOS") && (<AppleIcon height={18} width={18} className="px-1" />)}
                {(props.operatingSystem === "Android") && (<AndroidIcon height={18} width={18} className="px-1" />)}
                <Text size="small" content={props.operatingSystem} weight="semibold" />
            </Flex>
        </Box>
    )
}

export const ManagedDeviceCard = (props: ManageDeviceCardProps) => {
    const { t } = useTranslation();
    const graphAPI = new graphService();
    const intuneDevicesURL = "https://endpoint.microsoft.com/#blade/Microsoft_Intune_Devices/DeviceSettingsMenuBlade/overview/mdmDeviceId/";
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [processRequest, setProcessRequest] = useState<string>("");
    const [rebootPendingIds, setRebootPendingIds] = useState<string[]>([]);
    const [remoteLockPendingIds, setRemoteLockPendingIds] = useState<string[]>([]);
    const [wipePendingIds, setWipePendingIds] = useState<string[]>([]);
    const [retirePendingIds, setRetirePendingIds] = useState<string[]>([]);
    const [isOpenRebootDialog, setIsOpenRebootDialog] = useState<boolean>(false);
    const [isOpenWipeDialog, setIsOpenWipeDialog] = useState<boolean>(false);
    const [isOpenRetireDialog, setIsOpenRetireDialog] = useState<boolean>(false);
    const [wipeOption, setWipeOption] = useState<number>(null);

    const colorScheme = props.globalTheme.siteVariables.colorScheme;

    const reboot = async (managedDevice: ManagedDevice) => {
        setProcessRequest(t('users.device-form.restart-start'));
        setIsProcessing(true);
        try {
            const device = await getManagedDevice(managedDevice.id);
            // checking if there's still pending reboot request to avoid request flooding
            const checkPendingReboot = device.deviceActionResults.filter(actionResults => (actionResults.actionName === "rebootNow" && actionResults.actionState === "pending"))
            if (checkPendingReboot.length === 0) {
                const re = await graphAPI.rebootNowDevice(managedDevice.id);
                setIsProcessing(false);
                if (re.status === 204) {
                    Toast.success(t('users.device-form.restart-init', { deviceName: managedDevice.deviceName }));
                }
                setRebootPendingIds([...rebootPendingIds, managedDevice.id])
            } else {
                setIsProcessing(false);
                Toast.success(t('users.device-form.restart-started', { deviceName: managedDevice.deviceName }));
                if (!rebootPendingIds.includes(managedDevice.id)) {
                    setRebootPendingIds([...rebootPendingIds, managedDevice.id])
                }
            }
        } catch (e) {
            console.error(e)
        }
    }
    const remoteLock = async (managedDevice: ManagedDevice) => {
        setProcessRequest(t('users.device-form.lock-start'));
        setIsProcessing(true);
        try {
            const device = await getManagedDevice(managedDevice.id);
            // checking if there's still pending remote lock request to avoid request flooding
            const checkPendingRemoteLock = device.deviceActionResults.filter(actionResults => (actionResults.actionName === "remoteLock" && actionResults.actionState === "pending"))
            if (checkPendingRemoteLock.length === 0) {
                const re = await graphAPI.remoteLockDevice(managedDevice.id);
                if (re.status === 204) {
                    Toast.success(t('users.device-form.lock-init', { deviceName: managedDevice.deviceName }));
                }
                setIsProcessing(false);
            } else {
                setIsProcessing(false);
                if (!remoteLockPendingIds.includes(managedDevice.id)) {
                    setRemoteLockPendingIds([...remoteLockPendingIds, managedDevice.id])
                }
            }
        } catch (e) {
            console.error(e)
        }
    }

    const wipe = async (managedDevice: ManagedDevice, data: WipeData) => {
        setIsProcessing(true);
        setProcessRequest(t('users.device-form.wipe-start'));
        try {
            const device = await getManagedDevice(managedDevice.id);
            // checking if there's still pending wipe request to avoid request flooding
            const checkPendingWipe = device.deviceActionResults.filter(actionResults => (actionResults.actionName === "wipe" && actionResults.actionState === "pending"))
            if (checkPendingWipe.length === 0) {
                const re = await graphAPI.wipeDevice(managedDevice.id, data);
                setIsProcessing(false);
                if (re.status === 204) {
                    Toast.success(t('users.device-form.wipe-init', { deviceName: managedDevice.deviceName }));
                }
            } else {
                setIsProcessing(false);
                if (!wipePendingIds.includes(managedDevice.id)) {
                    setWipePendingIds([...wipePendingIds, managedDevice.id])
                }
            }
        } catch (e) {
            console.error(e)
        }
    }

    const retire = async (managedDevice: ManagedDevice) => {
        setIsProcessing(true);
        setProcessRequest(t('users.device-form.retire-start'));
        try {
            const device = await getManagedDevice(managedDevice.id);
            // checking if there's still pending retire request to avoid request flooding
            const checkPendingRemoteLock = device.deviceActionResults.filter(actionResults => (actionResults.actionName === "retire" && actionResults.actionState === "pending"))

            if (checkPendingRemoteLock.length === 0) {
                const re = await graphAPI.retireDevice(managedDevice.id);
                setIsProcessing(false);
                if (re.status === 204) {
                    Toast.success(t('users.device-form.retire-init', { deviceName: managedDevice.deviceName }));
                }
            } else {
                setIsProcessing(false);
                if (!retirePendingIds.includes(managedDevice.id)) {
                    setRetirePendingIds([...retirePendingIds, managedDevice.id])
                }
            }
        } catch (e) {
            console.error(e)
        }
    }

    const getManagedDevice = async (managedDeviceId: string) => {
        try {
            const { data: device } = await graphAPI.getManagedDevices(managedDeviceId);
            return device;
        } catch (e) {
            console.error(e)
            return {
                deviceActionResults: []
            };
        }
    }
    const checkComplianceState = () => {
        if (props.device.complianceState === null)
            return 'N/A';
        else
            return props.device.complianceState;
    }

    const wipeCheckbox = (option: number, checked: boolean) => {
        if (checked) {
            setWipeOption(option);
        } else {
            setWipeOption(null);
        }
    }

    const buttonColor = () => {
        return props.isUserLicensed ? colorScheme.brand.foreground : colorScheme.default.foregroundDisabled;
    }

    return (
        (<div className={`px-3 pt-3 pb-2 mt-5 md:ml-5 filter drop-shadow-md rounded w-full md:w-80`} style={{ background: colorScheme.default.background }}>
            <Flex className={`py-1 flex-col md:flex-row md:content-center items-center`} space="between">
                <Box>
                    <Flex vAlign="center">
                        {getDeviceIcon(props.device.operatingSystem)}
                        <Text content={props.device.deviceName} weight="semibold" className={`p-1`} />
                    </Flex>
                </Box>
            </Flex>
            <Divider />
            <div className={"my-1"}>
                <Flex className={`py-1 flex-col md:flex-row md:content-center items-center`} space="between">
                    <Box>
                        <Flex vAlign="center">
                            <Text size="small" content={t('users.device-form.registered-on')} />
                            <Text style={{
                                maxWidth: "80px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                paddingLeft: "5px"
                            }} size="small" content={DateTime.fromISO(props.device.enrolledDateTime).toFormat("MM.dd.yy")} weight="semibold" />
                        </Flex>
                    </Box>
                    <Box className={`pt-1 md:pt-0`}>
                        <Text size="small" content={t('users.device-form.sync-date')} />
                        <Text size="small" content={DateTime.fromISO(props.device.lastSyncDateTime).toFormat("MM.dd.yy")} weight="semibold" />
                    </Box>
                </Flex>
                <Flex className={`py-1 flex-col md:flex-row md:content-center items-center`} space="between">
                    <Box className={`pt-1 md:pt-0`}>
                        <Text size="small" content={t('users.device-form.compliance')} />
                        <Text size="small" content={checkComplianceState()} weight="semibold" />
                    </Box>
                </Flex>
                <Flex className={`py-1 flex-col md:flex-row md:content-center`} space="between">
                    <Box>
                        <Flex vAlign="center">
                            <Text size="small" content={t('users.device-form.join-type')} />
                            <Text size="small" className="pl-1" content={props.device.deviceEnrollmentType.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())} weight="semibold"  />
                        </Flex>
                    </Box>
                </Flex>
                <Flex className={`py-1 pt-5 flex-col md:flex-row md:content-center`} space="between">
                    <Flex vAlign="center">
                        <Text size="small" content={t('users.device-form.manufacturer')} />
                        <Text size="small" className="pl-1" content={props.device.manufacturer} weight="semibold" />
                    </Flex>
                </Flex>
                <Flex className={`py-1 flex-col md:flex-row md:content-center`} space="between">
                    <Flex vAlign="center">
                        <Text size="small" content={t('users.device-form.model')} />
                        <Text size="small" className="pl-1" content={props.device.model} weight="semibold" />
                    </Flex>
                </Flex>
                <Flex className={`py-1 flex-col md:flex-row md:content-center`} space="between">
                    <Flex vAlign="center">
                        <Text size="small" content={t('users.device-form.serial')} />
                        <Text size="small" className="pl-1" content={props.device.serialNumber} weight="semibold" />
                    </Flex>
                </Flex>
                <Flex className={`py-1 flex-col md:flex-row md:content-center`} space="between">
                    <Flex vAlign="center">
                        <Text size="small" content={t('users.device-form.device-id')} />
                        <Text size="small" className="pl-1" content={props.device.azureADDeviceId} weight="semibold" />
                    </Flex>
                </Flex>
                <Flex className={`py-1 flex-col md:flex-row md:content-center`} space="between">
                    <Flex vAlign="center">
                        <Text size="small" content={t('users.device-form.mac-wire')} />
                        <Text size="small" className="pl-1" content={props.device.ethernetMacAddress ?? "--"} weight="semibold" />
                    </Flex>
                </Flex>
                <Flex className={`py-1 flex-col md:flex-row md:content-center`} space="between">
                    <Flex vAlign="center">
                        <Text size="small" content={t('users.device-form.mac-wifi')} />
                        <Text size="small" className="pl-1" content={props.device.wiFiMacAddress ?? "--"} weight="semibold" />
                    </Flex>
                </Flex>
                <Flex className={`py-1 pt-5 flex-col md:flex-row md:content-center items-center`} space="between">
                    <GetOperatingSystem operatingSystem={props.device.operatingSystem} />
                    <Box>
                        <Text content={t('users.device-form.version')} size="small" />
                        <Text content={props.device.osVersion} weight="semibold" size="small" />
                    </Box>
                </Flex>
                <Flex className={`py-1 flex-col md:flex-row md:content-center`} space="between">
                    <Box className={`mt-1 w-full`}>
                        <Flex vAlign="center">
                            <Text size="small" content={t('users.device-form.free-space')} />
                            <ProgressBar percentage={props.device.freeStorageSpaceInBytes} total={props.device.totalStorageSpaceInBytes} />
                        </Flex>
                    </Box>
                </Flex>
            </div>
            <Divider />
            <Flex>
                <Flex.Item push>
                    <div>
                        {(isProcessing) && (
                            <Flex vAlign="center">
                                <Loader size="smallest" className={"mr-2"} />
                                <Text content={processRequest} />
                            </Flex>
                        )}
                        {(!isProcessing) && (
                            <>
                                <Tooltip content={t('users.device-form.retire')} trigger={
                                    <Button text iconOnly size="small"
                                        disabled={!props.isUserLicensed} 
                                        hidden={!props.allowedPermission.includes("Microsoft.Intune_RemoteTasks_Retire") && !props.allowedPermission.includes("*")} 
                                        icon={<CancelIcon />} 
                                        onClick={() => { setIsOpenRetireDialog(true); }} 
                                        style={{ color: buttonColor() }}
                                    />} 
                                />
                                <Dialog
                                    className={`confirmation-dialog`}
                                    cancelButton={t('common.buttons.cancel')}
                                    confirmButton={t('common.buttons.yes')}
                                    open={isOpenRetireDialog}
                                    onConfirm={() => {
                                        retire(props.device);
                                        setIsOpenRetireDialog(false);
                                    }}
                                    onCancel={() => {
                                        setIsOpenRetireDialog(false);
                                    }}
                                    header={t('users.device-form.retire-prompt-header', { deviceName: props.device.deviceName })}
                                    content={<><div><Text content={t('users.device-form.retire-prompt-body')} /></div></>}
                                />
                                <Tooltip content={t('users.device-form.wipe')} trigger={
                                    <Button text iconOnly size="small" 
                                        disabled={!props.isUserLicensed}
                                        hidden={!props.allowedPermission.includes("Microsoft.Intune_RemoteTasks_Wipe") && !props.allowedPermission.includes("*")}
                                        icon={<UndoIcon />}
                                        onClick={() => { setIsOpenWipeDialog(true); }}
                                        style={{ color: buttonColor() }} 
                                    />}
                                />
                                <Dialog
                                    className={`confirmation-dialog`}
                                    cancelButton={t('common.buttons.cancel')}
                                    confirmButton={t('common.buttons.yes')}
                                    open={isOpenWipeDialog}
                                    onConfirm={() => {

                                        let data: WipeData = {
                                            keepUserData: wipeOption === 1,
                                            keepEnrollmentData: wipeOption === 1,
                                            useProtectedWipe: wipeOption !== 1
                                        }

                                        if (wipeOption === null) {
                                            data = {
                                                keepUserData: false,
                                                keepEnrollmentData: false,
                                                useProtectedWipe: false
                                            }
                                        }

                                        wipe(props.device, data);
                                        setIsOpenWipeDialog(false);
                                    }}
                                    onCancel={() => {
                                        setIsOpenWipeDialog(false);
                                    }}
                                    header={t('users.device-form.wipe-prompt-header', { deviceName: props.device.deviceName })}
                                    content={
                                        <>
                                            <div>
                                                <Text content={t('users.device-form.wipe-prompt-body')} />
                                            </div>
                                            <div>
                                                <Checkbox
                                                    disabled={wipeOption !== 1 && wipeOption !== null}
                                                    onChange={(e, p) => { wipeCheckbox(1, p.checked); }}
                                                    label={<Text content={t('users.device-form.wipe-prompt-enroll')} />}
                                                />
                                            </div>
                                            <div>
                                                <Checkbox
                                                    disabled={wipeOption !== 2 && wipeOption !== null}
                                                    onChange={(e, p) => { wipeCheckbox(2, p.checked); }}
                                                    label={<Text content={t('users.device-form.wipe-prompt-powerloss')} />}
                                                />
                                            </div>
                                        </>
                                    }
                                />
                                {(props.device.operatingSystem !== "Windows" && props.device.operatingSystem !== "MacOS") && (
                                    <Tooltip content={t('users.device-form.lock')} trigger={
                                        <Button text iconOnly size="small" 
                                            disabled={!props.isUserLicensed}
                                            hidden={!props.allowedPermission.includes("Microsoft.Intune_RemoteTasks_RemoteLock") && !props.allowedPermission.includes("*")} 
                                            icon={<LockIcon />} 
                                            onClick={() => { remoteLock(props.device); }}
                                            style={{ color: buttonColor() }}
                                        />
                                    } />
                                )}
                                <Tooltip content={t('users.device-form.restart')} trigger={
                                    <Button text iconOnly size="small"
                                        disabled={!props.isUserLicensed}
                                        hidden={!props.allowedPermission.includes("Microsoft.Intune_RemoteTasks_RebootNow") && !props.allowedPermission.includes("*")}
                                        icon={<PowerButtonIcon />}
                                        onClick={() => { setIsOpenRebootDialog(true); }}
                                        style={{ color: buttonColor() }}
                                    />} 
                                />
                                <Dialog
                                    className={`confirmation-dialog`}
                                    cancelButton={t('common.buttons.cancel')}
                                    confirmButton={t('common.buttons.yes')}
                                    open={isOpenRebootDialog}
                                    onConfirm={() => {
                                        reboot(props.device);
                                        setIsOpenRebootDialog(false);
                                    }}
                                    onCancel={() => {
                                        setIsOpenRebootDialog(false);
                                    }}
                                    header={t('users.device-form.restart-prompt-header', { deviceName: props.device.deviceName })}
                                    content={<div><Text content={t('users.device-form.restart-prompt-body')} /></div>}
                                />
                                <Tooltip content={t('users.device-form.open')} trigger={
                                    <Button text iconOnly size="small"
                                        disabled={!props.isUserLicensed}
                                        icon={<OpenInNewWindowIcon />} 
                                        onClick={(_e) => { window.open(`${intuneDevicesURL}${props.device.id}`, '_blank'); }}
                                        style={{ color: buttonColor() }} 
                                    />}
                                />
                            </>
                        )}
                    </div>
                </Flex.Item>
            </Flex>
        </div>)
    );
}