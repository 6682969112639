import React, { useState, useEffect } from 'react';
import { TToolbarInteraction } from '@fluentui/react-teams/lib/esm/components/Toolbar/Toolbar';
import {
  ProviderConsumer,
  Flex,
  Button,
  AddIcon
} from '@fluentui/react-northstar';
import { Toolbar } from '../shared/components/Toolbar';
import EmptySVG from './../../svg/empty.svg';
import { EmptyData } from '../shared/components/EmptyData';
import { Breadcrumbs } from '../shared/components/BreadcrumbNavigation';
import { checkInTeams } from '../App';
import { getBodyHeight } from '../shared/common/CommonHelper';
import { toolbarButtonColor } from '../tikit/ticketHelper';
import { getCachedFeature } from '../shared/cache/FeatureCache';
import { PRODUCT_LICENSE } from '../shared/utils/constants';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  items: number;
  breadcrumbs: Breadcrumbs;
  ifEmpty?: IfEmptyProps;
  children: React.ReactNode;
  isAddNewEnabled?: boolean;
  onAddClickHandler?: () => void;
}

export const PickersPageWrapper = ({
  title,
  items,
  breadcrumbs,
  ifEmpty,
  children,
  isAddNewEnabled,
  onAddClickHandler
}: Props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isUserLicensed, setIsUserLicensed] = useState<boolean>(false);
  const { t } = useTranslation();

  const onToolbarInteraction = (interaction: TToolbarInteraction) => {
    if (interaction.action === 'toggle-menu') {
      setMenuOpen(!menuOpen);
    }
  };

  useEffect(() => {
    (async () => setIsUserLicensed((await getCachedFeature(PRODUCT_LICENSE.AnalystAccess))))();
  }, []);

  return (
    <ProviderConsumer
      render={globalTheme => (
        <>
          <Toolbar
            globalTheme={globalTheme}
            title={`Settings > ${title}`}
            breadcrumbs={breadcrumbs}
            addNew={isAddNewEnabled}
            onInteraction={onToolbarInteraction}
            addNewContent={
              <Button
                icon={<AddIcon />}
                text
                primary
                content={`${t('common.new')} ${title}`}
                disabled={!isUserLicensed}
                onClick={() => { onAddClickHandler?.(); }}
                style={{
                  color: toolbarButtonColor(isUserLicensed, globalTheme.siteVariables.colorScheme),
                  height: '52px'
                }}
              />
            }
          />

          {/* Main Section */}
          <Flex style={{ marginTop: '-1.25rem', height: getBodyHeight(checkInTeams()) }}>
            <Flex.Item
              grow
              styles={{ paddingTop: '1.25rem', overflow: 'auto' }}
            >
              <div>
                {ifEmpty && (
                  <>
                    {items === 0 && (
                      <EmptyData
                        headerText={ifEmpty.header}
                        subheaderText={ifEmpty.subHeader}
                        SVGIcon={<EmptySVG width={200} height={200} />}
                      />
                    )}

                    {children}
                  </>
                )}
              </div>
            </Flex.Item>
          </Flex>
        </>
      )}
    />
  );
};
