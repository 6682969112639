import React, { useState } from 'react';
import Styles from '../styles/TransitionRuleCriteria.module.css';
import {
  Button,
  Dropdown,
  FormLabel,
  ProviderConsumer
} from '@fluentui/react-northstar';
import { TransitionRulePropertyTypeString, LifecycleOdataTypes, TicketApprovalStateString, TransitionRulePropertyTypeString_Label} from '../utils/constants';
import { KeyValuePair, TransitionRuleCriteria, TransitionItem } from '../utils/typings';
import { DeleteIcon } from '@fluentui/react-icons-mdl2';
import { LICENSE_FEATURE, TicketApprovalState } from '../../shared/utils/constants';
import { LabelElement } from '../../shared/components/Modal/Modal';
import { LIFECYCLE } from '../../shared/utils/constants';
import { toTitleCase } from '../../shared/utils/helper';
import { getCachedFeature } from '../../shared/cache/FeatureCache';

interface Props {
  data: TransitionRuleCriteria;
  dataIndex: number;
  updateData: (editData: KeyValuePair[], index: number) => void;
  tasksOptions: DropdownDataModel[];
  approvalsOptions: DropdownDataModel[];
  taskStatusList: TaskStatus[];
  powerautomateOptions: DropdownDataModel[];
}

export const PhaseTransitionRuleCriteria = ({
  data,
  dataIndex,
  updateData,
  tasksOptions,
  approvalsOptions,
  taskStatusList,
  powerautomateOptions
}: Props) => {
  const [propType, setPropType] = useState<TransitionRulePropertyTypeString>(data.PropertyType);
  const [propTypeValue, setPropTypeValue] = useState(toTitleCase(data.PropertyType ? TransitionRulePropertyTypeString_Label[data.PropertyType] : ""));
  const [statusLabel, setStatusLabel] = useState("");
  const [transitionItem, setTransitionItem] = useState<TransitionItem>(data.TransitionItem);
  const [itemOptions, setItemOptions] = useState<DropdownDataModel[]>(tasksOptions);
  const [itemOdataType, setItemOdataType] = useState<string>(LifecycleOdataTypes.Task);
  const [enablePAFeature, setEnablePAFeature] = useState(false);
  const rulePropertyTypesOptions:DropdownDataModel[] = [];
  const ruleValueTypesOptions:DropdownDataModel[] = [];
  const ruleApprovalValueTypesOptions:DropdownDataModel[] = [];
  const rulePowerAutomateStatusValueTypesOptions: DropdownDataModel[] = [];

  React.useEffect(() => {

    if(rulePropertyTypesOptions.length == 0){
      (async () => setEnablePAFeature((await getCachedFeature(LICENSE_FEATURE.PowerAutomateTask))))();
      Object.keys(TransitionRulePropertyTypeString).forEach((key) => {
        if(key == TransitionRulePropertyTypeString.PowerAutomateTask && !enablePAFeature){
          return;
        }
        rulePropertyTypesOptions.push({
          key: key,
          label: toTitleCase(TransitionRulePropertyTypeString_Label[key]),
          header: toTitleCase(TransitionRulePropertyTypeString_Label[key])
        } as DropdownDataModel);
      });
    }

    if(ruleValueTypesOptions.length == 0){
      taskStatusList.filter(x=>!x.IsPowerAutomateStatus).forEach((status) => {
        const isSelected = (propType == TransitionRulePropertyTypeString.Task 
          && data.StatusId && data.StatusId == status.Id) ? true : false;
        ruleValueTypesOptions.push({
          key: status.Id.toString(),
          label: status["Value"],
          header: status["Value"],
          selected: isSelected
        } as DropdownDataModel);
        if(isSelected && propType == TransitionRulePropertyTypeString.Task )
          setStatusLabel(status["Value"]);
      });
    }

    if(ruleApprovalValueTypesOptions.length == 0){
      Object.keys(TicketApprovalStateString).forEach((key) => {
          if(TicketApprovalState[key] != TicketApprovalStateString.Pending){
          const isSelected = (propType == TransitionRulePropertyTypeString.Approval 
            && data.StatusId && data.StatusId == parseInt(TicketApprovalState[key])) ? true : false;
          ruleApprovalValueTypesOptions.push({
            key: TicketApprovalState[key],
            label: key,
            header: key,
            selected: isSelected
          } as DropdownDataModel);
          if(isSelected && propType == TransitionRulePropertyTypeString.Approval )
            setStatusLabel(key);
        }
      });
    }

    if(rulePowerAutomateStatusValueTypesOptions.length == 0){
      taskStatusList.filter(x=>x.IsPowerAutomateStatus).forEach((status) => {
        const isSelected = (propType == TransitionRulePropertyTypeString.PowerAutomateTask && data.StatusId && data.StatusId == status.Id);
        
          rulePowerAutomateStatusValueTypesOptions.push({
          key: status.Id.toString(),
          label: status["Value"],
          header: status["Value"],
          selected: isSelected
        } as DropdownDataModel);
        
        if(isSelected && propType == TransitionRulePropertyTypeString.PowerAutomateTask )
          setStatusLabel(status["Value"]);
      });
    }


  }, [rulePropertyTypesOptions, ruleValueTypesOptions, ruleApprovalValueTypesOptions, rulePowerAutomateStatusValueTypesOptions]);

  React.useEffect(() => {
    if(propType == TransitionRulePropertyTypeString.Approval){
      setItemOptions([...approvalsOptions]);
      setItemOdataType(LifecycleOdataTypes.Approval);
    }else if(propType == TransitionRulePropertyTypeString.PowerAutomateTask){
      setItemOptions([...powerautomateOptions]);
      setItemOdataType(LifecycleOdataTypes.Task);
    }else{
      setItemOptions([...tasksOptions]);
      setItemOdataType(LifecycleOdataTypes.Task);
    }
    setPropTypeValue(toTitleCase(propType ? TransitionRulePropertyTypeString_Label[propType] : ""));
  }, [propType, tasksOptions, approvalsOptions, powerautomateOptions]);

  const updateCriteria = (rule: TransitionRuleCriteria) => {
    let kv = [];
    Object.keys(rule).forEach(key => {
      kv.push({
        key: key,
        value: rule[key]
      } as KeyValuePair)
    });    
    updateData(kv, dataIndex);
  }

  const removeCriteria = () => {
    const pair: KeyValuePair = {
      key: "RemoveTransitionRuleCriteria",
      value: dataIndex
    } 
    updateData([pair], dataIndex);
  }

  const getTransitionItems = (propType)=>{
    switch(propType){
      case TransitionRulePropertyTypeString.Approval: 
        return ruleApprovalValueTypesOptions;
      case TransitionRulePropertyTypeString.PowerAutomateTask:
        return rulePowerAutomateStatusValueTypesOptions;
      default:
        return ruleValueTypesOptions;
    }
  }

  return (
    <ProviderConsumer
      render={(globalTheme) => (
        <>
        <div className={`${Styles.GroupInputsContainer}`} >

        <div className={`w-full ${Styles.GroupInput}`}>
            <LabelElement label={LIFECYCLE.PROPERTY} required={true}></LabelElement>
            <Dropdown
              inverted
              fluid
              items={rulePropertyTypesOptions}
              placeholder={LIFECYCLE.SELECT_PROPERTY}
              style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
              value={propTypeValue}
              onChange={(e, p) => {
                const item = p.value as DropdownDataModel;
                setPropType(TransitionRulePropertyTypeString[item.key]);
                setTransitionItem(null);
                updateCriteria(
                  {
                    ...data,
                    PropertyType: item.key
                  }
                )
              }}
            />
          </div>

          <div className={`w-4/12 ${Styles.GroupInput}`}>
            <LabelElement label={LIFECYCLE.ITEM} required={data.PropertyType}></LabelElement>
            <Dropdown
                disabled={!propType}
                inverted
                fluid
                items={itemOptions}
                placeholder={LIFECYCLE.SELECT_ITEM}
                style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
                value={transitionItem?.Name ?? ""}
                onChange={(e, p) => {
                  const item = p.value as DropdownDataModel;
                  const newItem:TransitionItem = {
                    Id: item.Id,
                    Guid: item.key,
                    Name: item.label,
                    "@odata.type": itemOdataType
                  };
                  setTransitionItem(newItem);
                  updateCriteria(
                    {
                      ...data,
                      TransitionItemId: newItem.Id,
                      TransitionItem: newItem
                    }
                  )
                }}
              />
          </div>

          <div className={`w-full ${Styles.GroupInput}`}>
            <LabelElement label={LIFECYCLE.VALUE} required={data.TransitionItem}></LabelElement>
            <Dropdown
              disabled={!transitionItem}
              inverted
              fluid
              items={getTransitionItems(propType)}
              placeholder={LIFECYCLE.SELECT_VALUE}
              style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
              value={statusLabel}
              onChange={(e, p) => {
                const item = p.value as DropdownDataModel;
                setStatusLabel(item.label);
                updateCriteria(
                  {
                    ...data,
                    StatusId: parseInt(item.key)
                  }
                )
              }}
            />
          </div>
        
      </div>
      <div className={Styles.CloseIcon}>
            <Button
              icon={<DeleteIcon />}
              text
              primary
              content={
                <span style={{ color: globalTheme.siteVariables.colorScheme.brand.foreground, fontSize: '12px' }}>
                  {LIFECYCLE.REMOVE}
                </span>
              }
              onClick={() => {
                removeCriteria();
              }}
            />
          </div>
      </>
      )}
    />
  );
};
