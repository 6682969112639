import React, { useEffect, useState } from 'react';
import { Button, Dialog, ProviderConsumer } from '@fluentui/react-northstar';
import { BUTTONS, CONTACT_ADMIN, DEFAULT_API_STATUS, DONE, ERROR, LOADING, REFRESH_SESSION, SECURITY, SOMETHING_WENT_WRONG, UNAUTHORIZED_ACCESS } from '../../shared/utils/constants';
import { toTitleCase } from '../../shared/utils/helper';
import { Communication } from '@fluentui/react-teams';
import { Providers } from '@microsoft/mgt-element/dist/es6/providers/Providers';
import { LoadingScreen } from '../../shared/components/LoadingScreen';
import { PeoplePicker, PersonType, UserType } from '@microsoft/mgt-react';
import { ThemeColorScheme } from '../../shared/common/TeamsTheme';

interface Props {
  applicationRoles: Record<string, string[]>;
  open: boolean;
  closeHandler: () => void;
  confirmationHandler: (data: MappedRolePayload[]) => void;
  apiState: APIStatus;
}

interface MappedProviderRole {
  upn: string;
  id: string;
}

export const SecurityModal = ({
  applicationRoles,
  open,
  closeHandler,
  confirmationHandler,
  apiState
}: Props) => {
  const [apiStatus, setApiStatus] = useState<APIStatus>(DEFAULT_API_STATUS as APIStatus);
  const [mappedProviderRoles, setMappedProviderRoles] = useState<MappedProviderRole[]>([]);
  useEffect(() => {
    if (open) {
      setMappedProviderRoles([]);
      setApiStatus({ ...apiStatus, status: DONE });
    }
  }, [open]);

  const errConfig = {
    fields: {
      title: apiStatus.errCode === 401 ? UNAUTHORIZED_ACCESS : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  };

  const saveData = () => {
    const currentRole = window.location.pathname.split('/').pop();
    const data: MappedRolePayload[] = mappedProviderRoles.map(role => ({
      ProviderRole: role.upn,
      EntraId: role.id,
      ApplicationRole: (currentRole.toLowerCase()==="knowledgeagents") ? "KnowledgeAgents" : toTitleCase(currentRole)
    }));
    confirmationHandler(data);
  };

  const onSelectedUser = async (e: any) => {
    const selectedUsers = e.detail;
    
    const usersToMap: MappedProviderRole[] = [];
    selectedUsers.forEach(user => {
      usersToMap.push({ upn: user.userPrincipalName ?? user.displayName, id: user.id });
    });

    setMappedProviderRoles([...usersToMap]);
  };

 
  return (
    <Dialog
      open={open}
      cancelButton={{
        content: toTitleCase(BUTTONS.CANCEL),
        disabled: apiState.status === LOADING
      }}
      confirmButton={{
        content: toTitleCase(BUTTONS.SAVE),
        disabled: apiState.status === LOADING
      }}
      closeOnOutsideClick={false}
      onCancel={closeHandler}
      onConfirm={saveData}
      content={
        <ProviderConsumer
          render={(globalTheme) => (
            <>
              {apiStatus.status === LOADING ? (
                <LoadingScreen message={apiStatus.msg} height="20rem" />
              ) : apiStatus.status === ERROR ? (
                <div style={{ height: '100%' }}>
                  <Communication {...errConfig} />
                  {apiStatus.errCode === 401 && (
                    <Button
                      content={REFRESH_SESSION}
                      primary
                      onClick={e => {
                        Providers.globalProvider.login();
                      }}
                    />
                  )}
                </div>
              ) : (
                <div style={{
                  display: 'block',
                  ...{ '--input-background-color': 'var(--mgt-theme-background)' },
                  ...ThemeColorScheme(globalTheme.siteVariables)}}>
                  <div className="py-3">
                    <div className="mb-5">
                      <p>
                        {toTitleCase(SECURITY.LABEL_PLACEHOLDER)}
                      </p>
                      <div></div>
                      <PeoplePicker userType={UserType.any} type={PersonType.any} className={`height-small`} selectionChanged={onSelectedUser} />
                    
                    </div>
                  </div>
                </div>
              )}
            </>
          )} />
      }
      header={SECURITY.ADD_MAPPED_ROLE}
      styles={{ maxWidth: '550px' }}
    />
  );
};
