import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Flex, Input, SearchIcon } from '@fluentui/react-northstar';
import _ from 'lodash';
import { platformService } from '../../../shared/services/platform.service';
import { appState, useSetState } from '../../../AppState';
import { RequestPageSkeleton } from '../Skeletons/RequestPageSkeleton';
import { TicketRequestCard } from '../TicketRequestCard/TicketRequestCard';
import FlashlightSVG from './../../../../svg/flashlight.svg';
import { EmptyData } from '../../../shared/components/EmptyData';
import { Toggle } from '@fluentui/react';
import { TICKET_PAGINATION } from '../../../shared/utils/constants';
import { themeNames } from '@fluentui/react-teams';
import { useTranslation } from 'react-i18next';

interface Props {
  ticketId: number;
  globalTheme: any;
}

export const EditRequestsView = ({ ticketId, globalTheme }: Props) => {
  const { t } = useTranslation();
  const api = new platformService();
  const setState = useSetState();
  const currentState = appState();

  const observer = useRef<IntersectionObserver>();
  const [ticketsState, setTicketsState] = useState<SideViewTicketState>({ loading: false, hasMore: false, pageNumber: 1, tickets: [], totalTickets: 0, searchText: '' });

  const lastTicketElementRef = useCallback(
    node => {
      if (ticketsState.loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && ticketsState.hasMore) 
          setTicketsState(pre => ({ ...pre, pageNumber: pre.pageNumber + 1 })); 
      });
      
      if (node) observer.current.observe(node);
    },
    [ticketsState.loading, ticketsState.hasMore]
  );

  const loadSideViewTickets = async (page: number, search?: string, closed?: boolean) => {
    closed ??= currentState.request.isToggleClosed;
    search ??= ticketsState.searchText;

    setTicketsState(pre => ({ ...pre, loading: true }));
    try {      
      const expandQuery = "AffectedUsers($select=PlatformUserId),FileAttachments($filter=IsPublic eq true;$top=1;$orderby=Id desc;),Comments($filter=IsPublic eq true;$top=1;$orderby=Id desc;$select=Id,Body,CreatedById,IsPublic),TicketCollaborators($select=PlatformUserId),TicketLifecycle($expand=Status,Lifecycle($select=Title),Phases($filter=IsCurrent eq true))";
      let filterQuery = `Closed eq ${closed} and (RequesterId eq ${currentState.currentUserId} or AffectedUsers/any(a: a/PlatformUserId eq ${currentState.currentUserId}))&$expand=${expandQuery}`
      if (search)
        filterQuery += `and ${(/^\d+$/.test(search) ? `(contains(Title, '${search}') or Id eq ${search})` : `(contains(Title, '${search}'))`)}`;

      const { data } = await api.getTicketList(`?$filter=${filterQuery}&$orderby=CreatedDate desc&$top=${TICKET_PAGINATION.ITEM_PER_PAGE}&$count=true&$skip=${(page - 1) * TICKET_PAGINATION.ITEM_PER_PAGE}`);

      const selectedTicket = data.value.find(x => x.Id == ticketId) ?? ticketsState.tickets.find(x => x.Id == ticketId);

      if (!selectedTicket) {
        let currentTicket = await api.getTicket(ticketId, `?$expand=${expandQuery}`);
        if (currentTicket?.data)
          data.value.unshift(currentTicket.data);
      }
      
      setTicketsState(pre => ({
        ...pre,
        loading: false,
        hasMore: data['@odata.count'] > (pre.tickets.length + data.value.length),
        tickets: [...pre.tickets, ...data.value],
        totalTickets: data['@odata.count'],
      }));
    } catch (error) {
      console.error(error);
      setTicketsState(pre => ({ ...pre, loading: false }));
    }
  };

  const searchItems = useCallback(
    _.debounce((_searchText: string) => {
      setTicketsState(pre => ({ ...pre, pageNumber: 1, tickets: [], searchText: _searchText }));
    }, 2000), []
  );

  const changeFilter = (_ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
    setState(state => ({ ...state, request: { isToggleClosed: checked } }));
    setTicketsState(state => ({ ...state, tickets: [], pageNumber: 1 }));
  };

  useEffect(() => {
    loadSideViewTickets(ticketsState.pageNumber, ticketsState.searchText, currentState.request.isToggleClosed);
  }, [ticketsState.pageNumber, ticketsState.searchText, currentState.request.isToggleClosed]);

  return (
    <div className={`h-full p-4`} style={{ borderRight: '1px solid #E4E4EB' }}>
      <div style={{ height: '13%', zIndex: 9 }}>
        <Flex className="mb-2" hAlign="end">
          <Toggle label={t('requests.edit-request-view.show-closed-tickets')} styles={{ label: { color:`${globalTheme.siteVariables.theme === themeNames.Dark ?'white' : 'black'}` }}} inlineLabel
            onChange={changeFilter} {...(currentState.request.isToggleClosed && { defaultChecked: true, style: { background: 'var(--mgt-theme-brand-foreground1)', opacity: 1 } })} />
        </Flex>
        <Input placeholder={t('requests.edit-request-view.search-for-a-ticket')} icon={<SearchIcon />} iconPosition="end" clearable inverted fluid defaultValue={ticketsState.searchText} onChange={(_, event) => searchItems(event.value ?? '')} />
      </div>
      <div className="overflow-auto mt-2" style={{ height: '83%' }}>
          {ticketsState.tickets.length > 0 && ticketsState.tickets.map((ticket: Ticket, idx: number) => (
            (idx === ticketsState.tickets.length - 1) ? 
              <div key={ticket.Id} ref={lastTicketElementRef}><TicketRequestCard key={ticket.Id} ticket={ticket} active={ticketId === ticket.Id} view="edit" /></div> :
              <div key={ticket.Id}><TicketRequestCard key={ticket.Id} ticket={ticket} active={ticketId === ticket.Id} view="edit" /></div>
          ))}
          {!ticketsState.loading && ticketsState.tickets.length == 0 && (
            <EmptyData SVGIcon={<FlashlightSVG width={110} height={110} className={'mb-4'} />} headerText={t('requests.edit-request-view.there-arent-any-matches')} subheaderText={t('requests.edit-request-view.try-changing-or-removing-filters')} />
          )}
          {ticketsState.loading && (<div><RequestPageSkeleton /></div>)}
      </div>
    </div>
  );
};