import React, { useEffect } from 'react';
import Chart from 'chart.js';
import { SiteVariablesPrepared } from '@fluentui/react-northstar';
import { TeamsTheme } from '@fluentui/react-teams/lib/esm/themes';
import { IChartData, IChartPatterns } from '../ChartTypes';
import { tooltipTrigger, tooltipAxisXLine, tooltipAxisYLine, chartConfig, axesConfig, setTooltipColorScheme, usNumberFormat, createChartId } from '../ChartUtils';
import { ChartContainer } from './ChartContainer';
import { buildPattern, chartBarDataPointPatterns, chartLineStackedDataPointPatterns, lineChartPatterns } from '../ChartPatterns';
import { getText } from '../translations/';

export const BaseChart = ({ title, data, siteVariables, chartType, stacked }: {
  title: string;
  data: IChartData;
  siteVariables: SiteVariablesPrepared;
  chartType: "line" | "bar";
  stacked?: boolean;
}) => {
  const { colorScheme, theme, colors, t } = siteVariables;
  const chartPatterns: IChartPatterns = chartType === "bar" ? chartBarDataPointPatterns : chartLineStackedDataPointPatterns
                                        
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const chartRef = React.useRef<Chart | undefined>();
  const chartId = React.useMemo(createChartId, []);

  const chartDataPointColors = React.useMemo(
    () => [
      colors.brand['600'],
      colors.brand['200'],
      colors.brand['800'],
      colors.grey['400'],
      colors.grey['500'],
      colorScheme.default.borderHover
    ],
    [theme]
  );

  const createDataPoints = (): Chart.ChartDataSets[] => Array.from(data.datasets, (set, i) => {
    let dataPointConfig: Chart.ChartDataSets = {
      label: getText(t.locale, set.label),
      data: set.data,
      borderWidth: 1,
      borderColor: colorScheme.default.background,
      hoverBorderColor: chartDataPointColors[i],
      backgroundColor: chartDataPointColors[i],
      hoverBorderWidth: chartType === "bar" ? 0 : 2,
      hoverBackgroundColor: chartDataPointColors[i],
      pointBorderColor: colorScheme.default.background,
      pointBackgroundColor: colorScheme.default.foreground3,
      pointHoverBackgroundColor: colorScheme.default.foreground3,
      pointHoverBorderColor: chartDataPointColors[i],
      pointHoverBorderWidth: chartType === "bar" ? 0 : 2,
      borderCapStyle: 'round',
      borderJoinStyle: 'round',
      pointBorderWidth: 0,
      pointRadius: 0,
      pointHoverRadius: chartType === "bar" ? 0 : 3
    };

    if (chartType === "line") {
      dataPointConfig.pointStyle = 'circle';
      dataPointConfig.borderDash = [];
    };

    if (theme === TeamsTheme.HighContrast)  {
      dataPointConfig = {
        ...dataPointConfig, 
        borderWidth: chartType === "bar" ? 1 : 3,
        hoverBorderColor: colorScheme.default.borderHover,
        hoverBorderWidth: chartType === "bar" ? 3 : 4,
        pointBorderColor: colorScheme.default.border,
        pointHoverBorderColor: colorScheme.default.borderHover,
        pointHoverRadius: chartType === "bar" ? 0 : 5,
        borderColor: colorScheme.brand.background,
        backgroundColor: buildPattern({
          ...chartPatterns(colorScheme)[i],
          backgroundColor: colorScheme.default.background,
          patternColor: colorScheme.brand.background
        }),
        hoverBackgroundColor: buildPattern({
          ...chartPatterns(colorScheme)[i],
          backgroundColor: colorScheme.default.background,
          patternColor: colorScheme.default.borderHover
        })
      };

      if (chartType === "line") 
        dataPointConfig.pointStyle = lineChartPatterns[i].pointStyle;
    }
    return dataPointConfig;
  });

   /**
   * Theme updates
   */
   useEffect(() => {
    if (!chartRef.current) return;
    if (!canvasRef.current) return;
    const ctx = canvasRef.current.getContext('2d');
    if (!ctx) return;
    chartRef.current.data.datasets = createDataPoints();
    setTooltipColorScheme({
      chart: chartRef.current,
      siteVariables,
      chartDataPointColors,
      patterns: chartPatterns
    });
    // Update axeses
    axesConfig({ chart: chartRef.current, ctx, colorScheme });

    chartRef.current.update();
  }, [theme]);

  useEffect(() => {
    let selectedIndex = -1;
    let selectedDataSet = 0;

    if (!canvasRef.current) return;
    const ctxCurrent = canvasRef.current.getContext('2d');
    if (!ctxCurrent) return;
    const config: any = chartConfig({ type: chartType });

    if (chartType == "bar") {
      config.options.hover.mode = 'nearest';
      config.options.scales.xAxes[0].gridLines.offsetGridLines = data.datasets.length > 1 && !stacked;
      config.options.scales.xAxes[0].stacked = stacked;
    }

    config.options.scales.yAxes[0].stacked = stacked;

    if (stacked) {
      config.options.tooltips.callbacks.title = (tooltipItems: any) => {
        let total = 0;
        data.datasets.forEach(dataset => {
          const value = dataset.data[tooltipItems[0].index];
          return typeof value === 'number' ? (total += value) : null;
        });

        return `${parseFloat(((tooltipItems[0].yLabel / total) * 100).toFixed(2))}% (${usNumberFormat(tooltipItems[0].yLabel)})`;
      };
    }

    config.options.scales.yAxes[0].stacked = true;

    chartRef.current = new Chart(ctxCurrent, {
      ...config,
      data: {
        labels: Array.isArray(data.labels) ? data.labels.map(label => getText(t.locale, label)) : getText(t.locale, data.labels),
        datasets: createDataPoints()
      },
      plugins: [{ afterDatasetsDraw: ({ ctx, tooltip, chart }: any) => chartType === "bar" ? tooltipAxisXLine({chart, ctx, tooltip}) : tooltipAxisYLine({ chart, ctx, tooltip }) }]
    });
    const chartCurrent: any = chartRef.current;

    const meta = () => chartCurrent.getDatasetMeta(selectedDataSet);
    const removeFocusStyleOnClick = () => {
      if (canvasRef.current) {
        canvasRef.current.style.boxShadow = 'none';
      }
    };

    const removeDataPointsHoverStates = () => meta().controller.removeHoverStyle(meta().data[selectedIndex > -1 ? selectedIndex : 0], 0, selectedIndex);
    const hoverDataPoint = (pointID: number) => meta().controller.setHoverStyle(meta().data[pointID], selectedDataSet, pointID);

    const showFocusedDataPoint = () => {
      hoverDataPoint(selectedIndex);
      tooltipTrigger({ chart: chartRef.current, data, set: selectedDataSet, index: selectedIndex, siteVariables });
      document.getElementById(`${chartId}-tooltip-${selectedDataSet}-${selectedIndex}`)?.focus();
    }

    const resetChartStates = () => {
      removeDataPointsHoverStates();
      const activeElements = chartCurrent.tooltip._active;
      const requestedElem = chartCurrent.getDatasetMeta(selectedDataSet).data[selectedIndex];

      const checkActiveElement = (i: number, compare: any): boolean => {
        if (requestedElem._index === compare) {
          activeElements.splice(i, 1);
          return true;
        }
      };

      activeElements.find((v: any, i: number) => checkActiveElement(i, v._index));
      for (let i = 0; i < activeElements.length; i++) {
        if (checkActiveElement(i, activeElements[i]._index)) break;
      }

      if (siteVariables.theme === TeamsTheme.HighContrast) {
        chartRef.current.data.datasets.map((dataset: any, i: number) => {
          dataset.borderColor = siteVariables.colorScheme.default.border;
          dataset.borderWidth = 2;
          dataset.backgroundColor = buildPattern({ ...chartPatterns(colorScheme)[i], backgroundColor: colorScheme.default.background, patternColor: colorScheme.brand.background });
        });
        chartCurrent.update();
      }
      chartCurrent.tooltip._active = activeElements;
      chartCurrent.tooltip.update(true);
      chartCurrent.draw();
    };

    const changeFocus = (e: KeyboardEvent) => {
      removeDataPointsHoverStates();
      switch (e.key) {
        case 'ArrowRight':
          e.preventDefault();
          selectedIndex = (selectedIndex + 1) % meta().data.length;
          break;
        case 'ArrowLeft':
          e.preventDefault();
          selectedIndex = (selectedIndex || meta().data.length) - 1;
          break;
        case 'ArrowUp':
          e.preventDefault();
          if (data.datasets.length > 1) {
            selectedDataSet += 1;
            if (selectedDataSet === data.datasets.length)
              selectedDataSet = 0;
          }
          break;
        case 'ArrowDown':
          e.preventDefault();
          if (data.datasets.length > 1) {
            selectedDataSet -= 1;
            if (selectedDataSet < 0)
              selectedDataSet = data.datasets.length - 1;
          }
          break;
      }

      showFocusedDataPoint();
    };

    canvasRef.current.addEventListener('click', removeFocusStyleOnClick);
    canvasRef.current.addEventListener('keydown', changeFocus);
    canvasRef.current.addEventListener('focusout', resetChartStates);
    return () => {
      if (!chartRef.current) return;
      if (canvasRef.current) {
        canvasRef.current.removeEventListener('click', removeFocusStyleOnClick);
        canvasRef.current.removeEventListener('keydown', changeFocus);
        canvasRef.current.removeEventListener('focusout', resetChartStates);
      }
      chartRef.current.destroy();
    };
  }, []);

  const onLegendClick = (datasetIndex: number) => {
    if (!chartRef.current) return;
    chartRef.current.data.datasets![datasetIndex].hidden = !chartRef.current.data.datasets![datasetIndex].hidden;
    chartRef.current.update();
  }

  return <ChartContainer siteVariables={siteVariables} data={data} chartDataPointColors={chartDataPointColors} patterns={chartPatterns} onLegendClick={onLegendClick}>
    <canvas id={chartId} ref={canvasRef} tabIndex={0} style={{ userSelect: 'none' }} aria-label={title}>
      {data.datasets.map((set, setKey) =>
        (set.data as number[]).forEach((item: number, itemKey: number) => (
          // Generated tooltips for screen readers
          <div key={itemKey} id={`${chartId}-tooltip-${setKey}-${itemKey}`}>
            <p>{item}</p>
            <span>{getText(t.locale, set.label)}: {set.data[itemKey]}</span>
          </div>
        ))
      )}
    </canvas>
  </ChartContainer>;

};