import React, { useEffect, useState } from 'react';
import Styles from './Request.module.css';

import { useParams } from 'react-router-dom';
import {
  Flex,
  ProviderConsumer as FluentUIThemeConsumer,
} from '@fluentui/react-northstar';
import { EditRequestToolbar } from './EditRequestToolbar';
import { ThemeColorScheme } from '../../shared/common/TeamsTheme';
import { EditRequestsView } from './EditRequestsView/EditRequestsView';
import { platformService } from '../../shared/services/platform.service';
import { EditRequestDetails } from './EditRequestDetails/EditRequestDetails';
import { Nav } from '../../browser-pages/browser-components/Nav';
import { checkInTeams } from '../../App';
import { AccessDenied } from '../../shared/components/AccessDenied';
import { useTranslation } from 'react-i18next';
import { getCachedFeature } from '../../shared/cache/FeatureCache';
import { LICENSE_FEATURE } from '../../shared/utils/constants';
import { UpgradeNeeded } from '../../shared/components/UpgradeNeeded';
import { appState, useSetState } from '../../AppState';
import { EditRequestDetailsSkeleton } from './Skeletons/EditRequestDetailsSkeleton';
import { RequestPageSkeleton } from './Skeletons/RequestPageSkeleton';

export const EditRequest = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const state = appState();
  const setState = useSetState();

  const api = new platformService();
  const ticketId = +id;
  
  const [ticket, setTicket] = useState<Ticket | null>(null);
  const [isUserLicensed, setIsUserLicensed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  
  const [fileTriggerChannel, setFileTriggerChannel] = useState('');
  
  const [isEditingOpen, setIsEditingOpen] = useState<boolean>(false);
  const [isAuthorizedAccess, setIsAuthorizedAccess] = useState(true);

  const loadLists = async () => {
    try {
      const results = await Promise.all([
        (state?.ticketStatus?.length > 0) ? Promise.resolve({ data: { value: state.ticketStatus } }) : api.getStatus(),
        (state?.platformusers.length > 0) ? Promise.resolve({ data: { value: state.platformusers } }) : api.getUsers()
      ]);

      setState(prev => ({
        ...prev,
        platformusers: results[1].data.value,
        ticketStatus: results[0].data.value
      }));
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }

  const toggleEditingToolbar = (value: boolean) => {
    setIsEditingOpen(value);
  };

  const getFilesChannel = async () => {
    try {
      const { data, status } = await api.getTeamsBotConfiguration();
      if (status === 200) {
        setFileTriggerChannel(data?.TriageChannel);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Effects
  useEffect(() => {
    loadLists();
    if (fileTriggerChannel == null || fileTriggerChannel == '') getFilesChannel();
  }, [ticketId]);

  const getUserEmail = () =>{
    return state.currentUserId ? state.platformusers.find(x=>x.Id==state.currentUserId).Email : "";
  }

  const [isFeatureEnabled, setIsFeatureEnabled] = useState(true);
  (async () => setIsFeatureEnabled((await getCachedFeature(LICENSE_FEATURE.TicketPortal))))();

  return isFeatureEnabled ? (
    <FluentUIThemeConsumer render={globalTheme => (<>
      {!checkInTeams() && <Nav />}
      {isAuthorizedAccess && (
        <div  className='dropzone' style ={{ height: 'calc(height: 100% - 40px)' }}>
          <div className={`h-screen relative`}>
            <EditRequestToolbar globalTheme={globalTheme} id={ticketId} toggleEditingToolbar={toggleEditingToolbar} isEditing={isEditingOpen} />
            <Flex className={`p-5 md:p-0 ${Styles.RequestEditBody}`} style={{ position: 'relative', ...ThemeColorScheme(globalTheme.siteVariables) }}>
              <Flex.Item className={`collapsible-component m-0 h-full overflow-auto w-1/5`}>
                <div className={`m-0 overflow-auto mobile-editform px-3 py-5`}>
                  {isLoading && <RequestPageSkeleton />}
                  {!isLoading && <EditRequestsView ticketId={ticketId} globalTheme={globalTheme} />}
                </div>
              </Flex.Item>
              {isLoading && (<EditRequestDetailsSkeleton />)}
              {!isLoading && (<EditRequestDetails ticketId={ticketId} signInUser={{ Email: state.currentUserEmail, Id: state.currentUserId, FullName: '' }} ticket={ticket} isUserLicensed={isUserLicensed} setTicket={setTicket} setIsUserLicensed={setIsUserLicensed}
                toggleEditingToolbar={toggleEditingToolbar} isEditingOpen={isEditingOpen} fileTriggerChannel={fileTriggerChannel} setIsAuthorizedAccess={setIsAuthorizedAccess} />)}
            </Flex>
          </div>
        </div>
      )}
      {!isAuthorizedAccess && (<AccessDenied />)}
    </>
    )} />
  ) : (
    <UpgradeNeeded toolbarText={t('ticket.toolbar.tickets')} headerText={t('ticket-portal.no-access-current-plan')} subheaderText={t('ticket-portal.no-access-upgrade-plan')} />
  );
};
