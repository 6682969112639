import React from 'react';

import { Flex } from '@fluentui/react-northstar';
import { People } from '@microsoft/mgt-react';
import { ConvertISODateToLocal } from '../../../shared/common/ConvertDate';
import EventLocationSvg from '../../../../svg/event-location.svg';
import { CardTemplate } from './CardTemplate';
import MgtCss from '../../../mgt.module.css';

export const AgendaTemplate = (props: MgtTemplateProps) => {
  const { event } = props.dataContext;
  return (
    <CardTemplate styles={{ borderRadius: 4 }}>
      <div className={MgtCss.MgtCard}>
        <div className={MgtCss.eventTimeContainer}>
          <div>
            {ConvertISODateToLocal(event.start.dateTime, 't')} -{' '}
            {ConvertISODateToLocal(event.end.dateTime, 't')}
          </div>
        </div>
        <div className={MgtCss.eventDetailsContainer}>
          <div className={MgtCss.eventSubject}>{event.subject}</div>
          <Flex
            style={{ display: 'flex' }}
            className={MgtCss.eventLocationContainer}
          >
            <div>
              <EventLocationSvg />
            </div>
            <div className={MgtCss.eventLocation}>
              {event.locations.map((l: any) => l.displayName).join(' - ')}
            </div>
          </Flex>
          <div>
            <People
              peopleQueries={event.attendees.map(
                (a: any) => a.emailAddress.address
              )}
            ></People>
          </div>
        </div>
      </div>
    </CardTemplate>
  );
};
