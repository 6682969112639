import React, { useEffect, useRef, useState } from 'react';
import styles from './DraggableItem.module.css';

import { RenderItemParams } from '@atlaskit/tree/dist/types/components/TreeItem/TreeItem-types';
import { TreeItem } from '@atlaskit/tree/dist/types/types';
import Dots from '../../../../../svg/dots.svg';
import EditIcon from '../../../../../svg/edit-icon.svg';
import ArchiveIcon from '../../../../../svg/archive-icon.svg';
import UnArchiveIcon from '../../../../../svg/Un-archive.svg';
import ArrowDownIcon from '../../../../../svg/arrow-down.svg';
import ArrowRightIcon from '../../../../../svg/arrow-right.svg';
import { TREE_LISTING_CONSTANTS } from '../../../utils/constants';
import { Button, MoreIcon } from '@fluentui/react-northstar';

const getIcon = (
  item: TreeItem,
  onExpand: (itemId: string | number) => void,
  onCollapse: (itemId: string | number) => void
) => {
  if (item?.children?.length > 0) {
    return (
      <div
        style={{ cursor: 'pointer', marginLeft: '0.2rem' }}
        onClick={() =>
          item.isExpanded ? onCollapse(item.id) : onExpand(item.id)
        }
      >
        {item.isExpanded ? <ArrowDownIcon className={`${styles.arrowIcon}`} /> : <ArrowRightIcon className={`${styles.arrowIcon}`} />}
      </div>
    );
  }
};

type DraggableItemProps = RenderItemParams & {
  isEditEnabled?: boolean;
  onEditHandler?: (item: TreeItem) => void;
  onArchiveHandler?: (item: TreeItem) => void;
  isDragEnabled?: boolean;
  isLicensedUser?: boolean;
};

export const DraggableItem: React.FunctionComponent<DraggableItemProps> = ({
  item,
  provided,
  onExpand,
  onCollapse,
  snapshot,
  isEditEnabled,
  onEditHandler,
  onArchiveHandler,
  isDragEnabled,
  isLicensedUser
}) => {
  const wrapperRef = useRef<HTMLUListElement>(null);
  const [editDropdown, setEditDropdown] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        editDropdown
      ) {
        setEditDropdown(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, editDropdown]);

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      className={`${styles.DraggableItem} ${
        snapshot?.isDragging ? styles.Dragging : ''
      }`}
    >
      <div className={`${styles.TextContainer}`}>
        <span
          {...provided.dragHandleProps}
          className={`${styles.DotIconContainer} ${(!isDragEnabled || !isLicensedUser) && 'invisible'}`}
        >
          <Dots />
        </span>
        {getIcon(item, onExpand, onCollapse)} 
        <span
          className={`${styles.Title} ${(!isEditEnabled || !isLicensedUser) && styles.TitleEditDisabled}`}
          onClick={() => {
            (isEditEnabled && isLicensedUser) && onEditHandler && onEditHandler(item);
          }}
        >
          {item.data && item.data?.title}
        </span>
        {item?.data?.IsDefault && (
          <span className={styles.Default}>
            {TREE_LISTING_CONSTANTS.default}
          </span>
        )}
        {item?.data?.IsArchived ? (
          <span className={styles.Default}>
            {TREE_LISTING_CONSTANTS.archived}
          </span>
        ) : (
          ''
        )}
      </div>
      {isEditEnabled && (
        <div className={styles.EditIconsContainer}>
          <span className={`${styles.EditIcon} ${isLicensedUser && 'cursor-pointer'}`} onClick={() => isLicensedUser && setEditDropdown(true)}>
            <Button className={`${!isLicensedUser && styles.DisabledButton}`} disabled={!isLicensedUser} icon={<MoreIcon />} text iconOnly />
          </span>
        </div>
      )}
      {editDropdown && (
        <ul className={styles.EditDropdown} ref={wrapperRef}>
          <li
            className={styles.EditDropdownItem}
            onClick={() => {
              if (isLicensedUser) onEditHandler && onEditHandler(item);
              setEditDropdown(false);
            }}
          >
            <span className={styles.EditDropdownItemIcon}>
              <EditIcon className={`${styles.svgIcon}`} />
            </span>
            <span className={styles.EditDropdownItemName}>
              {TREE_LISTING_CONSTANTS.edit}
            </span>
          </li>

          {item?.data?.IsArchived && !item?.data?.IsSystem ? (
            <li
              className={styles.EditDropdownItem}
              onClick={() => {
                if (isLicensedUser) onArchiveHandler && onArchiveHandler(item);
                setEditDropdown(false);
              }}
            >
              <span className={styles.EditDropdownItemIcon}>
                <UnArchiveIcon className={`${styles.svgIcon}`} />
              </span>
              <span className={styles.EditDropdownItemName}>
                {TREE_LISTING_CONSTANTS.unArchive}
              </span>
            </li>
          ) : (
            <></>
          )}
          {!item?.data?.IsArchived && !item.data?.IsSystem ? (
            <li
              className={styles.EditDropdownItem}
              onClick={() => {
                if (isLicensedUser) onArchiveHandler && onArchiveHandler(item);
                setEditDropdown(false);
              }}
            >
              <span className={styles.EditDropdownItemIcon}>
                <ArchiveIcon className={`${styles.svgIcon}`} />
              </span>
              <span className={styles.EditDropdownItemName}>
                {TREE_LISTING_CONSTANTS.archive}
              </span>
            </li>
          ) : (
            <></>
          )}
        </ul>
      )}
    </div>
  );
};
