import React, { useCallback, useEffect, useState } from 'react';
import { Button, Loader, MenuButton, MoreIcon, Dialog, Flex, ProviderConsumer } from '@fluentui/react-northstar';
import { platformService } from '../shared/services/platform.service';
import { Communication, themeNames } from '@fluentui/react-teams';
import { SignedInState } from '../shared/services/signInState.service';
import { Providers } from '@microsoft/mgt-element/dist/es6/providers/Providers';
import { Breadcrumbs } from '../shared/components/BreadcrumbNavigation';
import {
  DEFAULT_API_STATUS,
  DONE,
  ERROR,
  GETTING_ALL_GROUPS,
  GROUPS,
  GROUPS_HEADERS,
  LOADING,
  SETTINGS,
  NEW_GROUP,
  TREE_LISTING_CONSTANTS,
  BUTTONS,
  ARCHIVE_CONFIRMATION_SUBTITLE,
  ARCHIVE_MODAL_HEADER,
  UNARCHIVE_MODAL_HEADER,
  UNARCHIVE_CONFIRMATION_SUBTITLE,
  SETTINGS_PAGES,
  ENDPOINT_SECURITY_MAPPINGS,
  UNAUTHORIZED_ACCESS,
  SOMETHING_WENT_WRONG,
  CONTACT_ADMIN,
  REFRESH_SESSION
} from '../shared/utils/constants';
import { toTitleCase } from '../shared/utils/helper';
import { MgtPersonCard, People } from '@microsoft/mgt-react';
import { Text } from '@fluentui/react-northstar';
import { KBListWrapper } from '../kb/KBListWrapper';
import { FontIcon } from '@fluentui/react';
import ArchiveIcon from '../../svg/archive-icon.svg';
import UnArchiveIcon from '../../svg/Un-archive.svg';
import { useHistory } from 'react-router-dom';
import { CheckLogin } from '../shared/components/CheckLogin';
import { Helmet } from 'react-helmet';
import { LoadingScreen } from '../shared/components/LoadingScreen';
import { Toolbar } from '../shared/components/Toolbar';
import ConfigCSS from '../config/config.module.css';
import { CheckLicense } from '../shared/components/License/CheckLicense';
import useCurrentPage from '../kb/useCurrentPage';
import { useTranslation } from 'react-i18next';
import { ITeam } from '../shared/interfaces/supportGroup.interface';
import { graphService } from '../shared/services/graph.service';

const getEmailList = (groupMembers: GroupMember[]): string[] =>
  groupMembers.map((groupMember: GroupMember) => groupMember['Email']);

export const GroupTable = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const api = new platformService();
  const graph = new graphService();
  const [data, setData] = useState<RowsData[]>([]);
  const [apiStatus, setApiStatus] = useState<APIStatus>(
    DEFAULT_API_STATUS as APIStatus
  );

  //Disable Mail section to not require Mail.ReadBasic in Tikit scopes
  MgtPersonCard.config.sections.mailMessages = false;

  const [archiveDialogOpen, setArchiveDialogOpen] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<Group>({} as Group);
  const {currentPage, setCurrentPage} = useCurrentPage()

  const fetchData = useCallback(async () => {
    setApiStatus({ ...apiStatus, msg: GETTING_ALL_GROUPS });

    const check = ENDPOINT_SECURITY_MAPPINGS.find(x => x.key == SETTINGS_PAGES.GROUPS);
    if (check && JSON.parse(sessionStorage.getItem('my_application_roles') || '[]').filter(x => x.EndpointPath == check.path && x.Permissions.includes(check.method)).length <= 0) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: 403
      });
      return;
    }
    try {
      const results = await Promise.all([
        api.getSupportGroups('?$expand=Members&$orderby=Name'),
        api.getTeams('?$select=Name,FriendlyName,TeamsAadObjectId,TeamsChannelName')
      ]);

      const groups: Group[] = results[0].data?.value ?? [];
      const teams: ITeam[] = results[1].data?.value ?? [];

      const channelInfo = await graph.getChannelData(groups.map(x => ({ teamId: x.TeamsAadObjectId, channelId: x.TeamsChannelId })));

      const rowData: RowsData[] = groups.map((group: Group) => {
        const team = teams.find(t => t.TeamsAadObjectId == group.TeamsAadObjectId);
        return {
          id: group['Id'],
          nameSearch: group.Name,
          [t('groups.name')]: (<Flex gap="gap.medium" vAlign="center">
            <Text content={group.Name} />
            {group.IsArchived && (
              <Text content={TREE_LISTING_CONSTANTS.archived} size="smaller" weight="light" disabled />
            )}
          </Flex>
          ),
          [t('teams.teams-columns.team')]: team?.FriendlyName || team?.Name || "",
          [t('teams.teams-columns.triage-channel')]: channelInfo.find(x => x.id === group.TeamsChannelId)?.displayName || "",
          [t('groups.description')]: group['Description'],
          [t('groups.members')]: (
            <ProviderConsumer render={(globalTheme) => (
            <>
              <Text
                content="Members"
                className="lg:hidden"
                size="small"
                weight="semibold"
              />
              <People className={globalTheme.siteVariables.theme === themeNames.Default ? '' : 'mgt-dark'} peopleQueries={getEmailList(group['Members'])} onClick={e => {
                e.stopPropagation();
              }} />
            </>
            )} />
          ),
          "": (
            <>
            <CheckLicense disable>
              <MenuButton
                trigger={
                
                    <Button icon={<MoreIcon />} text iconOnly title="Actions" onClick={(e) => {
                      e.stopPropagation();
                    }} />
                }
                menu={{
                  items: [
                    {
                      content: t('common.tree-listing-constants.edit'),
                      icon: <FontIcon iconName={"Edit"} className={`text-lg`} style={{ lineHeight: "unset" }} />,
                      onClick: (e) => {
                        e.stopPropagation();
                        history.push(`/settings/groups?id=${group.Id}`);
                      }
                    },
                    {
                      content: group.IsArchived ? TREE_LISTING_CONSTANTS.unArchive : TREE_LISTING_CONSTANTS.archive,
                      icon: group.IsArchived ? <UnArchiveIcon className={`${ConfigCSS.svgIcon}`} /> : <ArchiveIcon className={`${ConfigCSS.svgIcon}`} />,
                      onClick: (e) => {
                        e.stopPropagation();
                        setSelectedGroup(group);
                        setArchiveDialogOpen(true);
                      }
                    }
                  ],
                }}
              />
              </CheckLicense>
            </>
          )
        };
      });

      setData(rowData);
    } catch (err: any) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: err.response.status
      });
    } finally {
      setApiStatus({ ...apiStatus, status: DONE });
    }
  }, []);

  const archiveGroup = async (temp: Group) => {
    try {
      const apiData = {
        IsArchived: !temp.IsArchived
      };
      await api.updateSupportGroup('ServiceDesk.Core.Models.Support.SupportGroup', temp.Id, apiData);
      fetchData();
    } catch (err: any) {
      setApiStatus({
        ...apiStatus,
        status: ERROR,
        errCode: (err.response) ? err.response.status : ""
      });
    }
  };

  const loadPage = () => {
    fetchData();
  }

  const navs: Breadcrumbs = {
    breadcrumbs: [
      {
        title: toTitleCase(SETTINGS),
        link: `/${SETTINGS}`
      },
      {
        title: toTitleCase(GROUPS),
        link: ''
      }
    ]
  };

  const errConfig = {
    fields: {
      title:
        apiStatus.errCode === 401 || apiStatus.errCode === 403
          ? UNAUTHORIZED_ACCESS
          : SOMETHING_WENT_WRONG,
      desc: CONTACT_ADMIN
    }
  };

  return (
    <CheckLogin onSignedIn={loadPage}>
      <Helmet>
        <title>{toTitleCase(GROUPS)} - Tikit</title>
      </Helmet>
      {
        apiStatus.status === LOADING ? (
          <LoadingScreen message={apiStatus.msg} />
        ) : apiStatus.status === ERROR ? (
          <ProviderConsumer render={(globalTheme) => (
            <>
              <Toolbar globalTheme={globalTheme} title="Settings > Groups" hideSave={true} breadcrumbs={navs}/>
              <Flex className={ConfigCSS.configBody}>
                <Flex.Item grow>
                  <>
                    <Communication {...errConfig} />
                    {apiStatus.errCode === 401 && (<Button content= {REFRESH_SESSION} primary onClick={e => { Providers.globalProvider.login(); }} />)}
                  </>
                </Flex.Item>
              </Flex>
            </>)} />) : 
            (<>
            <Dialog
              style={{ maxWidth: '500px' }}
              open={archiveDialogOpen}
              onCancel={() => setArchiveDialogOpen(false)}
              cancelButton={toTitleCase(BUTTONS.CANCEL)}
              confirmButton={
                selectedGroup?.IsArchived
                  ? TREE_LISTING_CONSTANTS.unArchive
                  : TREE_LISTING_CONSTANTS.archive
              }
              onConfirm={() => { setArchiveDialogOpen(false); archiveGroup(selectedGroup) }}
              content={`${(!selectedGroup.IsArchived) ? ARCHIVE_CONFIRMATION_SUBTITLE : UNARCHIVE_CONFIRMATION_SUBTITLE} ‘${selectedGroup?.Name}’ Group?`}
              header={`${(!selectedGroup.IsArchived) ? ARCHIVE_MODAL_HEADER : UNARCHIVE_MODAL_HEADER} ‘${selectedGroup?.Name}’?`}
            />
            <KBListWrapper
              title={toTitleCase(GROUPS)}
              addNewTitle={toTitleCase(NEW_GROUP)}
              routeName={SETTINGS_PAGES.GROUPS}
              headers={GROUPS_HEADERS}
              rows={data}
              currentPage={currentPage} 
              setCurrentPage={setCurrentPage}
              breadcrumbs={navs}
              ifEmpty={{
                header: t('groups.header'),
                subHeader:
                t('groups.sub-header')
              }}
            />
          </>
        )
      }
    </CheckLogin>
  )
};
