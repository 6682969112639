import React, { useEffect, useState } from 'react';
import { Input, Flex, ProviderConsumer, Button, Text } from '@fluentui/react-northstar';
import { platformService } from '../shared/services/platform.service';
import { Communication, TToolbarInteraction } from '@fluentui/react-teams';
import { TeamsChannelPicker, PeoplePicker } from '@microsoft/mgt-react';
import { MgtTeamsChannelPicker } from '@microsoft/mgt-components';
import { Providers } from '@microsoft/mgt-element';
import { Toolbar } from '../shared/components/Toolbar';
import ConfigCSS from '../config/config.module.css';
import { PlatformUser } from '../shared/interfaces/platformuser.interface';
import { graphService } from '../shared/services/graph.service';
import '../AppGlobals';
import { Breadcrumbs } from "../shared/components/BreadcrumbNavigation"
import { useHistory } from "react-router-dom";
import MgtCss from './../mgt.module.css';
import { CheckLogin } from '../shared/components/CheckLogin';
import { Helmet } from 'react-helmet';
import { LoadingScreen } from '../shared/components/LoadingScreen';
import { ThemeColorScheme } from '../shared/common/TeamsTheme';
import { MobileSettingsView } from '../automation/AutomationTable';
import { ISupportGroup, ITeam } from '../shared/interfaces/supportGroup.interface';
import { useSetState, appState } from '../AppState';
import { getCachedFeature } from '../shared/cache/FeatureCache';
import { CONTACT_ADMIN, LICENSE_FEATURE, NEW_GROUP, REFRESH_SESSION, SAVE, SOMETHING_WENT_WRONG, UNAUTHORIZED_ACCESS } from '../shared/utils/constants';
import { useTranslation } from 'react-i18next';

class SupportGroup implements ISupportGroup {
    constructor(id, name, description, teamsChannelId, applicationRoleId, members, teamsAadObjectId) {
        this.Id = id;
        this.Name = name;
        this.Description = description;
        this.TeamsChannelId = teamsChannelId;
        this.ApplicationRoleId = applicationRoleId;
        this.Members = members;
        this.TeamsAadObjectId = teamsAadObjectId;
    }
    Id: 0;
    Name: "";
    Description: "";
    TeamsChannelId: "";
    ApplicationRoleId: 0;
    Members: PlatformUser[];
    TeamsAadObjectId: "";
}

export interface IGroupFormProperties {
    id: string,
}

export const Groups = (props: IGroupFormProperties) => {
    const { t } = useTranslation();
    const history = useHistory();
    const api = new platformService();
    const graphAPI = new graphService();
    MgtTeamsChannelPicker.config.useTeamsBasedScopes = true;

    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [errCode, setErrCode] = useState(0);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [groupTeamsId, setGroupTeamsId] = useState("");
    const [groupChannelId, setGroupChannelId] = useState("");
    const [supportGroup, setSupportGroup] = useState<ISupportGroup>(new SupportGroup(0, "", "", "", 0, [], ""));
    const [groupName, setGroupName] = useState("");
    const [groupDescription, setGroupDescription] = useState("");
    const [memberAadIds, setMemberAadIds] = useState([]);
    const [hasTikit, setHasTikit] = useState(true);
    const [enabledMultiDepartment, setEnabledMultiDepartment] = useState(false);
    const [noDefaultTeam, setNoDefaultTeam] = useState(false);
    const [allTeamsAadObjectIdState, setAllTeamsAadObjectIdState] = useState([]);

    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const currentState = appState();
    const setState = useSetState();

    let allTeamsAadObjectId = [];
    let setAllTeamsAadObjectId = false;

    const onToolbarInteraction = (interaction: TToolbarInteraction) => {
        if (interaction.action === "toggle-menu") {
            setMenuOpen(!menuOpen);
        }
    }

    const fetchTeams = async () => {
        const thisEnabledMultiDepartment = await getCachedFeature(LICENSE_FEATURE.MultiDepartment);
        setEnabledMultiDepartment(thisEnabledMultiDepartment);

        if(thisEnabledMultiDepartment){
            const result: any[] = await api.getTeams('?$select=TeamsAadObjectId').then((val) => {
                let value = val.data.value;
                setIsError(false);
                return value;
            })
            .catch((e: any) => {
                setIsError(true);
                setErrCode(e.response.status);
            })
            .finally(() => {
                setIsLoading(false);
            });

            allTeamsAadObjectId = result.map(a => a.TeamsAadObjectId);
        }else{
            const defaultTeam : ITeam = await api.getDefaultTeamUnrestricted().then((val) => {
                let value = val.data.value;
                setIsError(false);
                return JSON.parse(value);
            }).catch((e: any) => {
                setIsError(true);
                setErrCode(e.response.status);
            })
            .finally(() => {
                setIsLoading(false);
            });
            if(defaultTeam)
                allTeamsAadObjectId.push(defaultTeam.TeamsAadObjectId);
        }
        setAllTeamsAadObjectId = true;
        setNoDefaultTeam(allTeamsAadObjectId.length == 0);
        setAllTeamsAadObjectIdState(allTeamsAadObjectId);
    }

    const fetchData = async () => {
        setLoadingMessage(t('groups.loading-your-group'));
        if (props.id && parseInt(props.id) > 0) {
            const result = await api.getSupportGroups(`(${props.id})?$expand=Members`).then((val) => {
                let value = val.data;
                setIsError(false);
                return value;
            })
                .catch((e: any) => {
                    setIsError(true);
                    setErrCode(e.response.status);
                })
                .finally(() => {
                    setIsLoading(false);
                });

            let sg = new SupportGroup(result.Id, result.Name, result.Description, result.TeamsChannelId, result.ApplicationRoleId, result.Members, result.TeamsAadObjectId);
            initGroupData(sg);
        } else
            setIsLoading(false);
    }

    const loadPage = () => {
        fetchData();
    }

    const errConfig = {
        fields: {
            title: (errCode == 401) ? UNAUTHORIZED_ACCESS : SOMETHING_WENT_WRONG,
            desc: CONTACT_ADMIN
        }
    };

    const setEmptyData = () => {
        setSupportGroup(new SupportGroup(0, "", "", "", 0, [], ""));
        setGroupName("");
        setGroupDescription("");
        const picker: any = document.querySelector('mgt-people-picker');
        picker.clearState();
    }

    const updateSupportGroupData = (isNew: boolean) => {
        let memberIds = memberAadIds;
        api.updateSupportGroupMemmbers(supportGroup.Id, memberIds, groupName, groupDescription, groupChannelId, groupTeamsId).then((result) => {
            if (result.data.value !== null) {
                setIsError(false);
                let data = JSON.parse(result.data.value)
                let sg = new SupportGroup(data.Id, data.Name, data.Description, data.TeamsChannelId, data.ApplicationRoleId, data.Members, data.TeamsAadObjectId);
                initGroupData(sg);

                if (isNew) {
                    setState(state => {
                        const supportgroups = state.supportgroups;
                        supportgroups.push(sg);
                        return {
                            ...state,
                            supportgroups: supportgroups
                        };
                    });
                }
                else {
                    let indx = currentState.supportgroups.findIndex(x => x.Id == sg.Id);
                    if (indx != -1) {
                        currentState.supportgroups[indx] = sg;
                    }
                }
            }
        }).finally(() => {
            setIsLoading(false);
            history.push(`/settings/groups`)
        });
    }

    const saveData = () => {
        setLoadingMessage(t('groups.updating-your-groups'));
        setIsLoading(true);
        if (supportGroup.ApplicationRoleId === 0) {
            api.getRoleByName("?$filter=RoleName%20eq%20'" + globalThis.sdApp.RoleNameAgents + "'").then((result) => {
                if (result.data.value.length > 0) {
                    supportGroup.ApplicationRoleId = result.data.value[0].Id
                } else {
                    setIsError(true);
                    setErrCode(400);
                    return;
                }

                let isNew = false;
                if (supportGroup.Id == 0) {
                    supportGroup.Name = groupName;
                    supportGroup.Description = groupDescription;
                    supportGroup.TeamsChannelId = groupChannelId;
                    supportGroup.TeamsAadObjectId = groupTeamsId;
                    isNew = true;
                }

                api.setSupportGroup('ServiceDesk.Core.Models.Support.SupportGroup', supportGroup).then((response) => {
                    supportGroup.Id = response.data.Id;

                    updateSupportGroupData(isNew);
                });
            });
        } else
            updateSupportGroupData(false);
    };

    const setGroup = async (event) => {
        setDisabled(true);
        if (event.detail.length > 0 && event.detail[0].channel.id.length > 0) {
            setGroupTeamsId(event.detail[0].team.id);
            if(supportGroup.TeamsChannelId !== event.detail[0].channel.id)
                setGroupName(event.detail[0].channel.displayName);
            let tikitApp = await graphAPI.getTeamApps(event.detail[0].team.id).then((val) => {
                let apps = val.data.value || [];
                return apps.filter(x => x["teamsApp"]["externalId"] == window.__runtimeConfig.tikitClientId || x["teamsApp"]["id"] == window.__runtimeConfig.tikitClientId);
            });

            if (tikitApp.length > 0) {
                setHasTikit(true);
                if (supportGroup.TeamsChannelId != event.detail[0].channel.id) {
                    const picker: any = document.querySelector('mgt-people-picker');
                    picker.clearState();
                    setDisabled(false);
                    setMemberAadIds([]);
                } else {
                    setCurrentGroupUsers(event.detail[0].team.id, supportGroup);
                }
            } else
                setHasTikit(false);
            setGroupChannelId(event.detail[0].channel.id);
        } else {
            setGroupChannelId("");
            setGroupTeamsId("");
            setGroupName("");
            const picker: any = document.querySelector('mgt-people-picker');
            picker.clearState();
            setDisabled(true);
            setMemberAadIds([]);
        }
    };

    const setCurrentGroupUsers = async (teamId: string, supportGroup: ISupportGroup) => {
        if (!teamId) return;
        let users = await graphAPI.getMembersOfGroup(teamId).then((response) => {
            if (response.data !== null) {
                return response.data.value;
            }
        });

        const results = users.filter((user) => {
            return supportGroup.Members.some((elm) => {
                return elm.Email === user.mail;
            });
        });
        const picker: any = document.querySelector('mgt-people-picker');
        picker.selectUsersById(results.map((item) => { return item.id }));
        setDisabled(false);
    }

    const setPeople = async event => {
        if (event.detail.length > 0) {
            let aadIds = event.detail.map(u => u.id);
            setMemberAadIds(aadIds);
        } else
            setMemberAadIds([]);
    }

    const initGroupData = (sg: ISupportGroup) => {
        setSupportGroup(sg);
        setGroupName(sg.Name);
        setGroupDescription(sg.Description);
        setGroupChannelId(sg.TeamsChannelId);
    }

    const updateGroupData = (supportGroup: ISupportGroup) => {
        setSupportGroup(supportGroup);
        setGroupName(supportGroup.Name);
        setGroupDescription(supportGroup.Description);
        setCurrentGroupUsers(groupTeamsId, supportGroup);
    }

    useEffect(() => {
        if(!setAllTeamsAadObjectId)
            fetchTeams();
        if(allTeamsAadObjectIdState.length > 0)
            allTeamsAadObjectId = allTeamsAadObjectIdState;
        let count = 0;
        const setPicker = async () => {
            const picker: any = document.querySelector('mgt-teams-channel-picker')
            if (picker?.isLoadingState ?? true)
                setTimeout(setPicker, 500);

            if (groupChannelId)
                await picker.selectChannelById(groupChannelId);

            if(picker?.shadowRoot && picker?.items){
                const pickerSelector = picker.shadowRoot;
                const pickerList = pickerSelector.querySelectorAll('.list-team');
                const indexes = [];
                picker?.items.forEach((data, index) => {
                    if(allTeamsAadObjectId.indexOf(data.item.id) == -1)
                        pickerList[index].style.display = "none";
                });
            }

            count++;
        }

        setPicker();

    }, [groupChannelId]);

    const updateGroupDescription = event => setGroupDescription(event.target.value);
    const updateGroupName = event => setGroupName(event.target.value);

    const navs: Breadcrumbs = {
        breadcrumbs: [
            {
                title: t('common.breadcrumb.settings'),
                link: '/settings'
            },
            {
                title: t('common.breadcrumb.groups'),
                link: '/settings/groups'
            },
            {
                title: (supportGroup.Name.length > 0) ? supportGroup.Name : NEW_GROUP,
                link: ""
            }
        ]
    };

    const LabelElement = (props: any) => {
        return (
            <div>
                <Flex styles={{ paddingTop: "20px" }}>
                    <Text content={props.label} style={{ display: 'block' }} />
                    {props.required && <span style={{ color: 'red', marginLeft: 2 }}>*</span>}
                </Flex>
                <div>{props.children}</div>
            </div>
        )
    }

    return (
        <CheckLogin onSignedIn={loadPage}>
            <Helmet>
                <title>{((groupName?.length ?? 0) > 0) ? groupName : NEW_GROUP} - Tikit</title>
            </Helmet>
            <div className="hidden md:block">
                {
                    (isLoading) ? <LoadingScreen message={loadingMessage} /> :
                        (isError) ? (<>
                            <Communication {...errConfig} />
                            {errCode === 401 && (<Button content={REFRESH_SESSION} primary onClick={e => { Providers.globalProvider.login(); }} />)}
                        </>) :
                            (
                                <ProviderConsumer render={(globalTheme) => (
                                    <div style={{
                                        display: 'block',
                                        ...{ '--input-background-color': 'var(--mgt-theme-background)' },
                                        ...ThemeColorScheme(globalTheme.siteVariables)
                                    }}>
                                        <Toolbar onInteraction={onToolbarInteraction} globalTheme={globalTheme} title="Settings > Groups" saveText={SAVE} saveCommand={saveData} breadcrumbs={navs}
                                            disableSave={!groupName || !groupTeamsId || memberAadIds.length == 0 || disabled} />
                                        <Flex className={ConfigCSS.configBody}>
                                            <Flex.Item>
                                                <Flex column
                                                    className={`pr-3 lg:w-1/2 w-full`}
                                                    styles={{
                                                        overflow: "auto",
                                                        paddingLeft: "15px",
                                                    }}>
                                                    <LabelElement label={t('groups.input-fields.associated-channel')} required={true}></LabelElement>
                                                    <div>
                                                        <TeamsChannelPicker selectionChanged={setGroup} className={`${MgtCss.noBorder}`} />
                                                        {(!hasTikit) && (
                                                            <Text error content={t('groups.tikit-app-not-installed')}></Text>
                                                        )}
                                                        {(!enabledMultiDepartment && noDefaultTeam) && (
                                                            <div>{t('groups.install-tikit-to-team')}</div>
                                                        )}
                                                        {(enabledMultiDepartment && noDefaultTeam) && (
                                                            <div>{t('groups.you-cant-create-a-group')}<a href={`${window.location.origin}/settings/teams`} target="_blank" rel="noopener noreferrer" style={{ color: globalTheme.siteVariables.colorScheme.brand.foregroundActive }}>here</a></div>
                                                        )}
                                                        <div>
                                                        <Text disabled content={t('groups.channels-can-only-be-members')}></Text>
                                                        </div>
                                                    </div>
                                                    <LabelElement label={t('groups.input-fields.name')} required={true}></LabelElement>
                                                    <div className="teams-input-med">
                                                        <Input
                                                            clearable
                                                            inverted
                                                            placeholder={t('groups.input-fields.enter-group-name')}
                                                            value={groupName}
                                                            onChange={updateGroupName}
                                                            fluid />
                                                    </div>
                                                    <LabelElement label={t('groups.members')} required={true}></LabelElement>
                                                    <div>
                                                        <PeoplePicker className={`height-small ${MgtCss.groupsPicker}`} groupId={groupTeamsId} selectionChanged={setPeople} disabled={disabled}></PeoplePicker>
                                                        <Text disabled content={t('groups.group-members-can-only-be-members')}></Text>
                                                    </div>
                                                    <LabelElement label={t('groups.input-fields.description')}></LabelElement>
                                                    <div className="teams-input-med">
                                                        <Input
                                                            clearable
                                                            inverted
                                                            placeholder={t('groups.input-fields.enter-group-description')}
                                                            value={groupDescription}
                                                            onChange={updateGroupDescription}
                                                            fluid />
                                                    </div>
                                                </Flex>
                                            </Flex.Item>
                                        </Flex>
                                    </div>)} />
                            )
                }
            </div>
            <MobileSettingsView />
        </CheckLogin>
    )
}