import React, { useEffect, useState } from 'react';
import {
  ProviderConsumer,
  Flex,
  Input,
  SearchIcon,
  Card,
  Text,
  CardHeader,
  Checkbox,
  Alert,
  InfoIcon
} from '@fluentui/react-northstar';
import { TEMPLATE } from '../shared/utils/constants';
import { TicketTemplate, Ticket } from '../shared/interfaces/ticket.interface';
import TemplateFieldsCardBody from './TemplateFieldsCardBody';
import { TemplateFormStateProps } from './TemplateFieldsForm';
import { mappingTemplateProps } from './SetDefault_TemplateForm';
import { EmptyData } from '../shared/components/EmptyData';
import EmptySVG from '../../svg/empty.svg';
import { appState } from '../AppState';

export interface TicketTemplatesSelectionProperties {
  ticketData: Ticket;
  formData: TemplateFormStateProps;
  dispatchEvent?: any;
}

export const TicketTemplatesSelection = (
  props: TicketTemplatesSelectionProperties
) => {

  const currentState = appState();
  const { formData, ticketData, dispatchEvent } = props;

  const [templates, setTemplates] = useState(
    Object.assign([], formData.templates)
  );
  const [template, setTemplate] = useState<TicketTemplate>(
    ticketData['SelectedTemplate'] || ({} as TicketTemplate)
  );

  useEffect(() => {
    setTemplates(currentItems => {
      return currentItems.map((templateData, index) => {
        templateData['hidden'] = false;
        return mappingTemplateProps(templateData, formData);
      });
    });
  }, []);

  useEffect(() => {
    if(ticketData["TemplateHasError"]){
      const divElement = document.getElementById("templateSelectionContent");
      if(divElement)
        divElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
    if(ticketData['IsTemplateSelectionSaving'])
      dispatchEvent({type:"IsTemplateSelectionSaving", data:false});
  }, [ticketData["TemplateHasError"], ticketData['IsTemplateSelectionSaving']])

  const handleSearch = (event: any) => {
    const target = event.target.value.toString() || '';
    setTemplates(currentItems => {
      if (target.length > 0) {
        let re = new RegExp(target, 'i');
        const matches = templates.map(json => {
          const match = JSON.stringify(json).match(re);
          json['hidden'] = !(match && match.length > 0);
          return json;
        });
        return matches;
      } else
        return templates.map(json => {
          json['hidden'] = false;
          return json;
        });
    });
  };

  const onSelectedCard = (checked: boolean, item: TicketTemplate) => {
    if (!checked) {
      ticketData['SelectedTemplate'] = {};
      setTemplate({} as TicketTemplate);
      dispatchEvent({
        type: 'set-selected-template',
        data: {
          SelectedTemplate: {}
        }
      });
    } else {
      ticketData['SelectedTemplate'] = item;
      setTemplate(item);
      dispatchEvent({
        type: 'set-selected-template',
        data: {
          SelectedTemplate: item
        }
      });
    }
  };

  const renderItems = templates.map((item: TicketTemplate, index) => {
    return (
      <Card
        fluid
        className={`mt-4`}
        elevated
        {...(template.Id && template.Id == item.Id && { selected: true })}
        hidden={item['hidden']}
        onClick={() => {
          onSelectedCard(template.Id != item.Id, item);
        }}
      >
        <Card.TopControls style={{ zIndex: 1 }}>
          <Checkbox
            checked={template.Id == item.Id}
            onChange={(e, data) => {
              onSelectedCard(data.checked, item);
            }}
          />
        </Card.TopControls>
        <CardHeader>
          <Flex gap="gap.small">
            <Flex column styles={{ maxWidth: '90%' }}>
              <Text content={item.Name} weight="semibold" />
            </Flex>
          </Flex>
        </CardHeader>
        <TemplateFieldsCardBody templateData={item} formData={formData} />
      </Card>
    );
  });

  return (
    <ProviderConsumer
      render={globalTheme => (
        <>
          <Flex
            column
            style={{ flex: 1, maxHeight: '70vh', overflow: 'auto' }}
            gap="gap.large"
          >
            <Flex.Item>
              <Flex column>
                <div id="templateSelectionContent">
                  <Text
                    content={TEMPLATE.SELECT_TEMPLATE}
                    weight="bold"
                    size="large"
                  />
                </div>
                <Text content={TEMPLATE.SELECT_TEMPLATE_DESCRIPTION} />
                {ticketData["TemplateHasError"] && (
                  <Alert className="mt-2" danger icon={<InfoIcon />} content={
                      <>
                      <Text content={ticketData["TemplateErrorMessage"]} />
                    </>
                  } />
                )}
              </Flex>
              
            </Flex.Item>
            <Flex.Item>
              <Flex vAlign="center">
                <Text content={TEMPLATE.EXISTING_TEMPLATES} weight="bold" style={{margin: '1vh'}} />
                <Flex.Item push>
                  <Input
                    icon={<SearchIcon />}
                    placeholder={TEMPLATE.TEMPLATE_INPUT_PLACEHOLDER}
                    onChange={handleSearch}
                    style={{ color: globalTheme.siteVariables.colorScheme.default.foreground }}
                  />
                </Flex.Item>
              </Flex>
            </Flex.Item>
            <Flex.Item>
              <div className={`grid grid-cols-1 gap-4 lg:grid-cols-2`}>
                {renderItems?.length > 0 ? (
                  renderItems
                ) : (
                  <EmptyData
                    headerText={TEMPLATE.EMPTY_TEMPLATE_SELECTION}
                    subheaderText={''}
                    SVGIcon={<EmptySVG width={130} height={130} />}
                  />
                )}
              </div>
            </Flex.Item>
          </Flex>
        </>
      )}
    />
  );
};
