import React, { useEffect, useState } from 'react';
import {
  ChevronEndIcon,
  ChevronDownIcon,
  Flex,
  ProviderConsumer as FluentUIThemeConsumer,
  AddIcon,
  Button,
  Dialog,
  CloseIcon
} from '@fluentui/react-northstar';
import Style from './TicketTasks.module.css';
import { TicketTasksListSkeleton } from './Skeletons/TicketTasksSkeleton';
import { TicketTasksList } from './TicketTasksList/TicketTasksList';
import { TicketTasksDialog } from './TicketTasksDialog/TicketTasksDialog';
import { platformService } from '../../services/platform.service';
import { useSetState, appState } from '../../../AppState';
import { Toast } from '../toast/Toast';
import { LICENSE_FEATURE, MyWorkListViewType } from '../../utils/constants';
import { getCachedFeature } from '../../cache/FeatureCache';
import { PlatformUser } from '../../interfaces/platformuser.interface';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
  analysts: PlatformUser[];
  statuses: TaskStatus[];
  ticketId: number;
  ticketTitle: string;
  reloadTicketList: boolean;
  setReloadTaskList: (prevState:any) => void;
  isFormDisabled?: boolean;
  ticketTasks: TicketTask[];
  getTicketTasks: (ticketId: number) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

interface TaskIdAndTitle {
  taskId: number;
  taskTitle: string;
}

const initialTicketTaskDetails = {
  Title: null,
  StatusId: undefined,
  AssigneeId: undefined,
  TicketId: undefined,
  Assignee: undefined,
  Status: undefined,
  TeamId: undefined,
  SupportGroupId: undefined,
  TaskCollaborators: []
};

export const TicketTasks = ({
  analysts,
  statuses,
  ticketId,
  ticketTitle,
  reloadTicketList,
  setReloadTaskList,
  isFormDisabled,
  ticketTasks,
  getTicketTasks,
  isLoading,
  setIsLoading
}: Props) => {
  const api = new platformService();
  const [openTicketTasksDialog, setOpenTicketTasksDialog] = useState<boolean>(false);
  const [openTaskList, setOpenTaskList] = useState<boolean>(false);
  const [ticketTaskDetails, setTicketTaskDetails] = useState<TicketTask>(initialTicketTaskDetails);
  const [showUpdateBtn, setShowUpdateBtn] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [taskIdAndTitle, setTaskIdAndTitle] = useState<TaskIdAndTitle>({ taskId: null, taskTitle: null });
  const setAppState = useSetState();
  const currentState = appState();
  const history = useHistory();
  const { t } = useTranslation();
  
  const deleteTask = (taskId: number, taskTitle: string) => {
    setShowConfirmationModal(true);
    setTaskIdAndTitle({ taskId, taskTitle });
  };

  const onCloseConfirmTaskDeleteModal = () => {
    setShowConfirmationModal(false);
  };

  const onClickConfirmTaskDeleteModal = async (taskId: number) => {
    onCloseConfirmTaskDeleteModal();
    try {
      setIsLoading(true);
      const isTicketTaskEnabled = await getCachedFeature(LICENSE_FEATURE.TicketTasks);
      if(isTicketTaskEnabled) {
        await api.deleteTicketTask(`TicketTask/${taskId}`);
        await getTicketTasks(ticketId);
        Toast.success(t('ticket-details.edit-ticket-form.task.success.delete-success'));
      } else {
        Toast.error(t('ticket-details.edit-ticket-form.task.error.fail-to-delete'));
      }
    } catch (error) {
      console.error('Error: ', error);
      Toast.error(t('ticket-details.edit-ticket-form.task.error.fail-to-delete'));
    } finally {
      setIsLoading(false);
    }
  };

  const renderModalPage = (taskTitle: string) => (<span><b>{t('ticket-details.edit-ticket-form.task.delete-dialog.task-title')}: </b>{taskTitle}</span>);

  const openTaskDialog = (e: any) => {
    e.stopPropagation();
    setOpenTicketTasksDialog(true);
  };

  const onTaskClick = (taskDetails: TicketTask) => {
    setTicketTaskDetails({ ...taskDetails });
    setShowUpdateBtn(true);
    setOpenTicketTasksDialog(true);
  };

  const onModalClose = () => {
    setOpenTicketTasksDialog(false);
    setShowUpdateBtn(false);
    setTicketTaskDetails({ ...initialTicketTaskDetails });
  };

  useEffect(() => {
    if (ticketId > 0 && reloadTicketList){

      getTicketTasks(ticketId);
      setReloadTaskList(false);
    }
  }, [ticketId, reloadTicketList]);

  useEffect(() => {
    const { isRouteFromMyWork, selectedTaskOrApproval } = currentState;

    if(isRouteFromMyWork && selectedTaskOrApproval?.type === MyWorkListViewType.Tasks){
      setAppState((prev) => ({ ...prev, isRouteFromMyWork: false }));
      setOpenTaskList(true);
    }
  }, []);

  return (
    <FluentUIThemeConsumer
      render={globalTheme => (
        <>
          <Flex
            vAlign="center"
            space="between"
            className={`mt-7 pb-4 cursor-pointer`}
            style={{ borderBottom: openTaskList ? `1px solid ${globalTheme.siteVariables.colorScheme.brand.border1}` : 'none' }}
            onClick={() => setOpenTaskList(!openTaskList)}
          >
            <Flex gap="gap.small" vAlign="center">
              {openTaskList ? <ChevronEndIcon /> : <ChevronDownIcon />}
              <Flex style={{ fontWeight: 400, fontSize: '16px', lineHeight: '24px' }}>
              {t('ticket-details.edit-ticket-form.task.title')} ({ticketTasks ? ticketTasks.length : 0})
              </Flex>
            </Flex>
            {openTaskList && (
              <div
                className={`hidden md:flex items-center  ${(isLoading || isFormDisabled) ? Style['addBtnDisable'] : ''} `}
                style={{ color: `${globalTheme.siteVariables.colorScheme.brand.foreground}` }}
                onClick={e => openTaskDialog(e)}
              >
                <AddIcon size="small" className={`${(isLoading || isFormDisabled) ? Style['disableSvg'] : ''}`} />{' '}
                &nbsp; {t('ticket-details.edit-ticket-form.task.add-task')}
              </div>
            )}
          </Flex>
          {openTaskList &&
            (isLoading ? (
              <TicketTasksListSkeleton />
            ) : (
              <TicketTasksList
                onTaskClick={onTaskClick}
                tasksList={ticketTasks}
                deleteTask={deleteTask}
                isFormDisabled={isFormDisabled}
              />
            ))}

          {!isFormDisabled && <Dialog
              header={t('ticket-details.edit-ticket-form.task.delete-dialog.are-you-sure')}
              content={renderModalPage(taskIdAndTitle.taskTitle)}
              open={showConfirmationModal}
              headerAction={{
                icon: <CloseIcon />,
                title: t('common.buttons.close'),
                onClick: onCloseConfirmTaskDeleteModal
              }}
              footer={
                <Flex gap="gap.small">
                  <Flex.Item push>
                    <Button content={t('common.buttons.cancel')} onClick={onCloseConfirmTaskDeleteModal} />
                  </Flex.Item>
                  <Flex.Item>
                    <Button content={t('common.buttons.confirm')} primary onClick={() => { onClickConfirmTaskDeleteModal(taskIdAndTitle.taskId); }} />
                  </Flex.Item>
                </Flex>
              }
            />
          }

          {!isFormDisabled && 
            <TicketTasksDialog
              open={openTicketTasksDialog}
              close={() => {
                onModalClose();
              }}
              statuses={statuses}
              analysts={analysts}
              ticketId={ticketId}
              ticketTitle={ticketTitle}
              getAllTicketList={getTicketTasks}
              setIsLoading={setIsLoading}
              ticketTaskDetails={ticketTaskDetails}
              showUpdateBtn={showUpdateBtn}
              setShowUpdateBtn={(value: boolean) => setShowUpdateBtn(value)}
            />
          }
        </>
      )}
    />
  );
};
