import React, { useContext, useEffect, useState } from 'react';
import TaskBoard from './tabs/TaskBoard';
import { AppStateContext, FilterDropdownProps, useSetState, appState } from '../AppState';
import _ from 'lodash';
import { customizedFilter } from '../shared/components/Filter';
import { Ticket } from '../shared/interfaces/ticket.interface';
import {
  FILTERS,
  LICENSE_FEATURE,
  FILTERS_STRINGS,
  STATUS_VALUE,
  STATUS_GUID,
  TASK_STATUS_GUID
} from '../shared/utils/constants';
import { getCachedFeature } from '../shared/cache/FeatureCache';
import { platformService } from '../shared/services/platform.service';
import { RenderSkeleton } from './TicketBoardView/RenderSkeleton';
import { CustomViewContext } from './toolbar/CustomViewContextProvider';
import useCustomViewsTickets from './List/useCustomViewsTickets';
import { ListContext } from './List/ListContextProvider';
import { ListInputItemsEnum, getStatusIdByGuid, getTaskStatusIdByGuid } from './ticketHelper';
import { getChildFiltersCountFromStorage, getChildFiltersFromStorage, getChildFiltersInputValueFromStorage, getViewIdFromStorage } from './toolbar/CustomViewPopup/heplers/customPopupHelper';

const api = new platformService();

interface PropsI {
  defaultFilters: FilterPropsItems[];
}

export const Board = ({ defaultFilters }: PropsI) => {
  const state = useContext(AppStateContext);
  const setState = useSetState();
  const currentState = appState();
  const [isSlaEnable, setIsSlaEnable] = useState(false);
  const [isLifecycleEnabled, setIsLifecycleEnabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const { setListStates } = useContext(ListContext);
  const [isDeflectedSelected, setIsDeflectedSelected] = useState(false);

  const [allTickets, setAllTickets] = useState({
    active: [] as Ticket[],
    closed: [] as Ticket[],
    all: [] as Ticket[],
  });
  const [parentFilteredTickets, setParentFilteredTickets] = useState([] as Ticket[]);
  const [arrangedTickets, setArrangedTickets] = useState([] as Ticket[]);

  useCustomViewsTickets({ defaultFilters, isBoardView: true, setParentFilteredTickets, allTickets }); // Get tickets filtered by custom views

  const { customViewStates } = useContext(CustomViewContext);

  const checkSlaFeatureHasBeenEnabled = async () => {
    try {
      const enabled = await getCachedFeature(LICENSE_FEATURE.ServiceLevelAgreements);
      await getCachedFeature(LICENSE_FEATURE.MultiDepartment);
      setIsSlaEnable(enabled);
    } catch (error) {
      console.error(error);
    }
  };

  const getTicketsWithDetails = async () => {
    setIsLoading(true);
    setListStates(prev => ({
      ...prev,
      isTicketsFetching: true
    }));
    try {
      const [isRelateTicketEnabled, isTicketTaskEnabled, isEnabledLifecycle] = await Promise.all([
        getCachedFeature(LICENSE_FEATURE.RelatedTickets),
        getCachedFeature(LICENSE_FEATURE.TicketTasks),
        getCachedFeature(LICENSE_FEATURE.TicketLifecycle)
      ]);
      const [activeTickets, closedTickets, slas, ticketTask, mergedTickets, pendingApprovals] = await Promise.all([
        api.getTicketList(`?$filter=Closed eq false&$expand=AffectedUsers($select=PlatformUserId),TicketCollaborators($select=PlatformUserId),Tags,TicketLifecycle($expand=Status,Lifecycle($select=Title),Phases($filter=IsCurrent eq true))&$orderby=CreatedDate desc`),
        api.getTicketList(`?$filter=Closed eq true&$expand=AffectedUsers($select=PlatformUserId),TicketCollaborators($select=PlatformUserId),Tags,TicketLifecycle($expand=Status,Lifecycle($select=Title),Phases($filter=IsCurrent eq true))&$orderby=CreatedDate desc`),
        api.getTicketSla(FILTERS_STRINGS.TICKET_SLA_FILTER),
        isTicketTaskEnabled ? api.getTicketTasks(`?$filter=IsDeleted%20eq%20false`) : Promise.resolve([]),
        isRelateTicketEnabled ? api.getAllMergedTickets() : Promise.resolve([]),
        api.getAllPendingTicketApprovals()
      ]);

      const completedStatusId = getTaskStatusIdByGuid(currentState.taskStatus, TASK_STATUS_GUID.COMPLETED);

      const active = activeTickets.data.value?.map((ticket: any) => {
        const ticketTasks = ticketTask?.filter((task: any) => task.TicketId === ticket.Id);
        const ticketTaskWithStatus = ticketTasks.map((task: any) => {
          const itemStatus = currentState?.taskStatus?.find((statusItem: any) => statusItem.Id === task.StatusId);
          task.Status = itemStatus;
          return task;
        });
        const ticketHasActiveTasks: boolean = ticketTaskWithStatus.some((task: any) => task.StatusId !== completedStatusId);
        const ticketApprovals: TicketApprovals[] = pendingApprovals?.filter((approval: TicketApprovals) => approval.TicketId === ticket.Id);
        const ticketHasPendingApprovals: boolean = ticketApprovals?.length > 0 ? true : false;
        return {
          ...ticket, 
          TicketSlas: slas?.filter((sla: any) => sla.TicketId === ticket.Id) || [], 
          TicketTasks: ticketTaskWithStatus,
          HasActiveTasks: ticketHasActiveTasks,
          PendingTicketApprovals: ticketApprovals,
          HasPendingApprovals: ticketHasPendingApprovals
      }}) || [] as Ticket[];

      const closed = closedTickets.data.value?.map((ticket: any) => {
        const ticketSlas = slas?.filter((sla: any) => sla.TicketId === ticket.Id) || [];
        const mergedDetail = mergedTickets?.find(merge => merge.RelatedTicketId === ticket.Id);
        const ticketTasks = ticketTask?.filter((task: TicketTask) => ticket.Id === task.TicketId) || [];
        const hasActiveTasks: boolean = ticketTasks.some((task: any) => task.StatusId !== completedStatusId);
        const ticketApprovals = pendingApprovals?.filter((approval: any) => approval.TicketId === ticket.Id);
        const ticketHasPendingApprovals: boolean = ticketApprovals?.length > 0 ? true : false;
        return {
          ...ticket,
          TicketSlas: ticketSlas,
          SourceTicketId: mergedDetail?.SourceTicketId ?? null,
          HasActiveTasks: hasActiveTasks,
          TicketTasks: ticketTasks,
          PendingTicketApprovals: ticketApprovals,
          HasPendingApprovals: ticketHasPendingApprovals
        }
      }) || [] as Ticket[];
            
      setIsLifecycleEnabled(isEnabledLifecycle);
      setState(preState => ({
        ...preState,
        isLoadingSla: false,
        ticketsSla: slas,
        tickets: active,
        closedTickets: closed,
        reloadBoardOnAddTicket: false
      }));

      setAllTickets({ active, closed, all: [...active, ...closed] });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setState(preState => ({
        ...preState,
        isLoadingSla: false,
        ticketsSla: [],
        reloadBoardOnAddTicket: false
      }));
      console.log(`Error occurred when loading tickets with SLAs and Tasks:`, error);
    }

    setListStates(prev => ({
      ...prev,
      isTicketsFetching: false
    }));
  };

  useEffect(() => {
    checkSlaFeatureHasBeenEnabled();
    getTicketsWithDetails();
  }, []);

  useEffect(() => {
    if (state.reloadBoardOnAddTicket) {
      const active = state.tickets;
      const closed = state.closedTickets;
      setAllTickets({ active, closed, all: [...active, ...closed] });
      setState(preState => ({ ...preState, reloadBoardOnAddTicket: false }));
    }
  }, [state.reloadBoardOnAddTicket]);

  useEffect(() => {
    if (parentFilteredTickets && !isLoading) {
      const storageFilterDropdown: FilterDropdownProps = {
        ...state.filterDropdown,
        queryValue: getChildFiltersInputValueFromStorage(),
        queryValueApplied: getChildFiltersInputValueFromStorage(),
        selectedValues: getChildFiltersFromStorage(),
      }
      const filterDropdown = getChildFiltersCountFromStorage() ? storageFilterDropdown : state.filterDropdown;
      const filtersApplyCount = getChildFiltersCountFromStorage() ? Math.random() : state.filtersApplyCount;

      setArrangedTickets(customizedFilter(parentFilteredTickets, filterDropdown, filtersApplyCount));

      if ([state.listFilter, getViewIdFromStorage('default')].includes(ListInputItemsEnum.Deflected)) {
        setIsDeflectedSelected(true);
      } else {
        setIsDeflectedSelected(isDeflectedStatusSelected(state.filterDropdown.selectedValues, customViewStates.selectedCustomView?.FiltersDropdownValues));
      }
    }
  }, [state.filtersApplyCount, parentFilteredTickets]);

  const isDeflectedStatusSelected = (filterDropdownValues: FilterPropsItems[], customViewDropdownValues: FilterPropsItems[]) => {
    const isDeflectedStatus = (dropdownValues: FilterPropsItems[]) => {
      let id = getStatusIdByGuid(state.ticketStatus, STATUS_GUID.DEFLECTED);

      const statusDropdownValues = dropdownValues.find(value => value.title === FILTERS.STATUS.title);
      return statusDropdownValues.values.find(value => value.key === id).selected
    }
    if (customViewDropdownValues) {
      const isDeflected = isDeflectedStatus(customViewDropdownValues);
      if (isDeflected) {
        return isDeflected
      }
    }
    return isDeflectedStatus(filterDropdownValues);
  }

  return (
    <>
      {isLoading ? (
        <RenderSkeleton page="board" />
      ) : (
        <TaskBoard
          tickets={arrangedTickets}
          isDeflectedStatusSelected={isDeflectedSelected}
          boardFilter={state.boardFilter}
          listFilter={state.listFilter}
          platformusers={state.platformusers}
          ticketprops={{
            status: state.ticketStatus,
            categories: state.categories,
            priority: state.priority
          }}
          supportgroups={state.supportgroups}
          teams={state.teams}
          isSlaEnabled={isSlaEnable}
          isLifecycleEnabled={isLifecycleEnabled}
        />
      )}
    </>
  );
};