import React from 'react';
import { Button, Text, ProviderConsumer, Flex } from '@fluentui/react-northstar';
import { Providers } from "@microsoft/mgt-element";

export const LoginPrompt: React.FC<any> = () => {
  return (
    <ProviderConsumer
      render={globalTheme => (
        <Flex column gap="gap.small" vAlign="center" hAlign="center" fill style={{ marginTop: '2em' }}>
          <Text align="center" content="Additional permissions are needed to authenticate, please click the 'Grant Consent' button below to consent to them." />
          <Button content="Grant Consent" primary onClick={() => Providers.globalProvider.login()} />
        </Flex>
      )}
    />
  );
};
