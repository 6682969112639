import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ChevronDownIcon,
  ChevronEndIcon,
  Dialog,
  Flex,
  Input,
  MenuButton,
  MoreIcon,
  ProviderConsumer,
  SearchIcon,
  Table,
  Text,
  ProviderConsumer as FluentUIThemeConsumer,
} from "@fluentui/react-northstar";
import { Link, useHistory } from "react-router-dom";
import { cloneDeep, throttle } from "lodash";
import { ITEMS_PER_PAGE } from "../../shared/components/table/TableWrapper";
import { TablePagination } from "../../shared/components/table/TablePagination";
import { FontIcon } from "@fluentui/react";
import Styles from "../styles/LifeCycleTableView.module.css";
import LifecycleTaskIcon from "../../../svg/lifecycle-task-icon.svg";
import LifecycleApprovalIcon from "../../../svg/approvals-lifecycle.svg";
import LifecyclePowerAutomateTaskIcon from "../../../svg/lifecycle-powerautomate-icon.svg";
import UnArchiveIcon from "../../../svg/Un-archive.svg";
import ArchiveIcon from "../../../svg/archive-icon.svg";
import { ARCHIVE_CONFIRMATION_SUBTITLE, ARCHIVE_MODAL_HEADER, BUTTONS, TREE_LISTING_CONSTANTS, UNARCHIVE_CONFIRMATION_SUBTITLE, UNARCHIVE_MODAL_HEADER, LIFECYCLE } from "../../shared/utils/constants";
import { toTitleCase } from "../../shared/utils/helper";
import { Lifecycle, LifecycleTransition } from "../utils/typings";
import { ILifecycle } from "../../shared/interfaces/lifecycle.interface";
import { platformService } from "../../shared/services/platform.service";
import { useTranslation } from "react-i18next";

interface Props {
  rows: Lifecycle[];
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  fetchData: () => void;
}

export const LifecycleTableView = ({
  rows,
  currentPage,
  setCurrentPage,
  fetchData
}: Props) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [collection, setCollection] = useState<Lifecycle[]>([]); // could be searched result or original data passed as prop
  const [paginatedData, setPaginatedData] = useState<Lifecycle[]>([]);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState<boolean>(false);
  const [selectedLifecycleForArchive, setSelectedLifecycleForArchive] = useState<Lifecycle>({} as Lifecycle);
  const history = useHistory();
  const api = new platformService();
  const { t } = useTranslation();

  const searchedResult = useRef(
    throttle((val: string) => {
      const query: string = val.toLowerCase();

      const filteredData: Lifecycle[] = rows.filter((row: Lifecycle) =>
        Object.keys(row).some(
          (key: string) =>
            typeof row[key] !== "object" && // this check filters out the JSX elements
            row[key].toString().toLowerCase().includes(query)
        )
      );

      setCollection(filteredData);
      setPaginatedData(filteredData.slice(0, ITEMS_PER_PAGE));
      setTotalCount(filteredData.length);
    }, 400)
  );

  const handleCartClick = (id) => {
    if (id === expandedRow) {
      setExpandedRow(null);
    } else {
      setExpandedRow(id);
    }
  };

  const renderCaretIcon = (isExpanded) => {
    return isExpanded ? (
      <ChevronEndIcon />
    ) : (
      <ChevronDownIcon />
    );
  };

  const onSearch = (e: React.SyntheticEvent<HTMLElement>) => {
    const value = (e.target as HTMLInputElement).value;
    setSearchKey(value);
    setCurrentPage(1);
    searchedResult.current(value);
  };

  const showMenuButton = (lifecycleData: Lifecycle) => (
    <ProviderConsumer 
      render={(globalTheme) => (
        <MenuButton
        trigger={
          <Button
            icon={<MoreIcon />}
            text
            iconOnly
            title="Actions"
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        }
        menu={{
          items: [
            {
              content: t('common.edit'),
              icon: (
                <FontIcon
                  iconName={t('common.edit')}
                  className={`text-lg`}
                  style={{ lineHeight: "unset" }}
                />
              ),
              onClick: (e) => {
                e.stopPropagation();
                history.push(`/settings/lifecycle?id=${lifecycleData.Id}`);
              },
            },
            {
              content: lifecycleData.IsArchived
                ? TREE_LISTING_CONSTANTS.unArchive
                : TREE_LISTING_CONSTANTS.archive,
              icon: lifecycleData.IsArchived ? <UnArchiveIcon stroke={globalTheme.siteVariables.colorScheme.default.foreground2} /> :
                 <ArchiveIcon stroke={globalTheme.siteVariables.colorScheme.default.foreground2} />,
              onClick: (e) => {
                e.stopPropagation();
                setSelectedLifecycleForArchive(lifecycleData)
                setArchiveDialogOpen(true);
              },
            },
          ],
        }}
      />
    )}
    />
  );

  const showTransition = (transition: LifecycleTransition[]) => {
    return (
      <ProviderConsumer 
        render={(globalTheme) => (
          <Flex wrap>
          {transition.map((item) => (
            <div
              className={`ml-2 mt-2 inline-block capitalize pt-0.5 pb-1 px-3 rounded-full`}
              style={{backgroundColor: globalTheme.siteVariables.colorScheme.brand.background1, color: globalTheme.siteVariables.colorScheme.default.foreground1}}
              key={item.Id}
            >
              <Text size="smaller">
                {item.Title}
              </Text>
            </div>
          ))}
        </Flex>
        ) }
      />
      
    );
  };

  const showTaskAndApprovalsCounts = (
    taskCount: number,
    approvalsCount: number,
    powerAutomateTaskCount: number
  ) => {
    return (
      <ProviderConsumer 
        render={(_globalTheme) => (
          <Flex>
            {taskCount > 0 && (
              <Flex.Item>
                <div className={`ml-2`}>
                  <LifecycleTaskIcon
                    width={12}
                    height={12}
                    className="inline-block"
                  />
                  &nbsp;{taskCount} {(taskCount > 1) ? LIFECYCLE.TASKS : LIFECYCLE.TASK}
                </div>
              </Flex.Item>
            )}
            {approvalsCount > 0 && (
              <Flex.Item>
                <div className={`ml-2`}>
                  <LifecycleApprovalIcon
                    width={12}
                    height={12}
                    className={`inline-block`}
                  />
                  &nbsp;{approvalsCount} {(approvalsCount > 1) ? LIFECYCLE.APPORVALS : LIFECYCLE.APPROVAL}
                </div>
              </Flex.Item>
            )}
            {powerAutomateTaskCount > 0 && (
              <Flex.Item>
                <div className={`ml-2`}>
                  <LifecyclePowerAutomateTaskIcon
                    width={12}
                    height={12}
                    className={`inline-block`}
                  />
                  &nbsp;{powerAutomateTaskCount} {(powerAutomateTaskCount > 1) ? LIFECYCLE.POWER_AUTOMATE_TASKS : LIFECYCLE.POWER_AUTOMATE_TASK}
                </div>
              </Flex.Item>
            )}
          </Flex>
        )}
      />
    );
  };

  const showTitle = (title: string, id: number, isArchived) => {
    return (
      <ProviderConsumer
        render={(globalTheme) => (
          <>
            <span
              style={{ cursor: "pointer", color: globalTheme.siteVariables.colorScheme.brand.foregroundActive, textOverflow: 'ellipsis', width: '70%'}}
            >
              <Link to={`/settings/lifecycle?id=${id}`}>{title}</Link>
            </span>
            {
              isArchived && <Text className={`ml-1`} content={TREE_LISTING_CONSTANTS.archived} size="smaller" weight="light" disabled />
            }
          </>
        )}
      />
    );
  };

  const moveToPage = (type: string) => {
    if (type === "next") {
      const newData: Lifecycle[] = collection.slice(
        currentPage * ITEMS_PER_PAGE,
        (currentPage + 1) * ITEMS_PER_PAGE
      );
      setCurrentPage((prevPage) => prevPage + 1);
      setPaginatedData(newData);
    } else {
      const newData: Lifecycle[] = collection.slice(
        (currentPage - 1) * ITEMS_PER_PAGE - ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE
      );
      setCurrentPage((prevPage) => prevPage - 1);
      setPaginatedData(newData);
    }
  };

  const archiveLifecycle = async (temp: ILifecycle) => {
    try {
      const apiData = {
        IsArchived: !temp.IsArchived
      };
      await api.updateLifecycle(temp.Id, apiData);
      fetchData();
    } catch (err) {
      console.log("::::::::::: ", err)
    }
  };

  useEffect(() => {
    setCollection(cloneDeep(rows));
    setPaginatedData(
      rows.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        ITEMS_PER_PAGE * currentPage
      )
    );
    setTotalCount(rows.length);
  }, [rows]); // rows

  return (
    <FluentUIThemeConsumer
        render={(globalTheme) => (
    <>
      <Flex column gap="gap.medium" className={`m-2.5`}>
        <Flex style={{ justifyContent: "flex-end" }}>
          <Input
            icon={<SearchIcon />}
            inverted
            placeholder={t('lifecycle.search')}
            value={searchKey}
            onChange={onSearch}
          />
        </Flex>

        <Table className={`responsive-table`} >
          <Table.Row header 
            styles={{borderColor: globalTheme.siteVariables.colorScheme.default.border, borderBottomColor: globalTheme.siteVariables.colorScheme.default.border }} >
            <Table.Cell
              className={`${Styles["cheveronIconCellWidth"]}`}
            ></Table.Cell>
            <Table.Cell content={t('lifecycle.title')} className={`${Styles["titleCellWidth"]}`}/>
            <Table.Cell content={t('lifecycle.description')} className={`ml-2`} />
            <Table.Cell content={""} />
            <Table.Cell
              className={`${Styles["moreMenuBtnCellWidth"]}`}
            ></Table.Cell>
          </Table.Row>
          {paginatedData.map((row: Lifecycle) => (
            <React.Fragment key={row.Id}>
              <Table.Row className={`${Styles["bodyRows"]}`} 
                {...expandedRow !== row.Id && ({styles: { borderBottomColor: globalTheme.siteVariables.colorScheme.default.backgroundActive }})}
                style={{
                  backgroundColor: globalTheme.siteVariables.colorScheme.grey.background
                }}
                >
                <Table.Cell
                  className={`${Styles["cheveronIconCellWidth"]} cursor-pointer`}
                  onClick={() => handleCartClick(row.Id)}
                >
                  {renderCaretIcon(expandedRow === row.Id)}
                </Table.Cell>
                <Table.Cell content={showTitle(row.Title, row.Id, row.IsArchived)} className={`${Styles["titleCellWidth"]}`}/>
                <Table.Cell content={row.Description} className={`ml-2`}/>
                <Table.Cell content={""} />
                <Table.Cell
                  content={showMenuButton({Id: row.Id, Title: row.Title, IsArchived: row.IsArchived})}
                  className={`${Styles["moreMenuBtnCellWidth"]}`}
                />
              </Table.Row>
              {expandedRow === row.Id && (
                <Table.Row className={`${Styles["expandedTable"]}`}>
                  <Table className={`w-full`}>
                    {row?.Phases?.map((detail) => (
                      <Table.Row
                        key={detail.Id}
                        className={`${Styles["innerTableRowHeight"]}`}
                        styles={{ borderBottomColor: globalTheme.siteVariables.colorScheme.default.backgroundActive , backgroundColor: globalTheme.siteVariables.colorScheme.grey.background}}
                      >
                        <Table.Cell  className={`${Styles["cheveronIconCellWidth"]}`} />
                        <Table.Cell content={detail.Name} className={`${Styles["titleCellWidth"]}`}/>
                        <Table.Cell className="mb-1.5"
                          content={showTransition(detail?.Transitions)}
                        />
                        <Table.Cell
                          content={showTaskAndApprovalsCounts(
                            detail["Tasks@odata.count"],
                            detail["Approvals@odata.count"],
                            detail["PowerAutomateTasks@odata.count"]
                          )}
                        />
                        <Table.Cell className={`${Styles["moreMenuBtnCellWidth"]}`} />
                      </Table.Row>
                    ))}
                  </Table>
                </Table.Row>
              )}
            </React.Fragment>
          ))}
        </Table>
        <TablePagination
          currentPage={currentPage}
          totalCount={totalCount}
          moveToPage={moveToPage}
          itemPerPage={ITEMS_PER_PAGE}
        />
      </Flex>
      <Dialog
        style={{ maxWidth: '500px' }}
        open={archiveDialogOpen}
        onCancel={() => setArchiveDialogOpen(false)}
        cancelButton={toTitleCase(BUTTONS.CANCEL)}
        confirmButton={
          selectedLifecycleForArchive?.IsArchived
            ? TREE_LISTING_CONSTANTS.unArchive
            : TREE_LISTING_CONSTANTS.archive
        }
        onConfirm={() => { setArchiveDialogOpen(false); archiveLifecycle(selectedLifecycleForArchive) }}
        content={`${(!selectedLifecycleForArchive.IsArchived) ? ARCHIVE_CONFIRMATION_SUBTITLE : UNARCHIVE_CONFIRMATION_SUBTITLE} ‘${selectedLifecycleForArchive?.Title}’ Lifecycle?`}
        header={`${(!selectedLifecycleForArchive.IsArchived) ? ARCHIVE_MODAL_HEADER : UNARCHIVE_MODAL_HEADER} ‘${selectedLifecycleForArchive?.Title}’?`}
      />
    </>
    )} />
  );
};
