import { appState } from '../../AppState';
import { getStatusIdByGuid } from '../../tikit/ticketHelper';
import { PlatformUser } from '../interfaces/platformuser.interface';
import { TicketProps } from '../interfaces/ticketproperties.interface';
import { STATUS_GUID, STATUS_VALUE } from '../utils/constants';
import { getStatusIdByGuidHelper, getTicketsFilterQuery } from '../utils/helper';

type Filter = { [key: number]: FilterType };

export const useTicketsPQ = () => {
  const state = appState();
  const getCurrentUserFullName = () => state.platformusers.find((user: PlatformUser) => user.Id === state.currentUserId)?.FullName;

  const getStatusId = (statusValue: string) => {
    return state.ticketStatus.find((status: TicketProps) => {
      return status.Guid === statusValue;
    })?.Id;
  };

  const filter: Filter = {
    0: { Closed: [false], Resolved: [false] }, // 'Status/Value': ['Active', 'Pending']
    1: {
      Closed: [false], // 'Status/Value': ['Active', 'Pending']
      'Assignee/FullName': [getCurrentUserFullName()], 
      Resolved: [false]
    },
    2: {
      Closed: [false], // 'Status/Value': ['Active', 'Pending']
      'Assignee/FullName': [null],
      Resolved: [false]
    },
    3: { StatusId: [getStatusId(STATUS_GUID.CLOSED), getStatusId(STATUS_GUID.RESOLVED)] }, //  'Status/Value': ['Closed']
    4: { StatusId: [getStatusId(STATUS_GUID.DEFLECTED)] } // 'Status/Value': ['Deflected']
  };

  // converting object into query
  const _getFilterQuery = (filterObj: FilterType, _searchText: string) => {
    // initially filterObj contains all values of dropdown filters as empty array
    let filterQuery = getTicketsFilterQuery(filterObj, state.ticketStatus);

    filterQuery = _searchText
      ? `(contains(Title, '${_searchText}'))${
          filterQuery ? ` and ${filterQuery}` : ''
        }`
      : filterQuery;

    return filterQuery;
  };

  const getParentFiltersQuery = (_filterNumber: number, _text: string) => _getFilterQuery(filter[_filterNumber], _text)

  return { getParentFiltersQuery };
};
