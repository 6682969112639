import React from 'react';
import { getLifecycleClass } from "../../../tikit/ticketHelper";

interface Props {
status: LifecycleStatus, 
phaseName: string,
extraClassNames?: string
id?: string
isPastPhase?: boolean;
}

export const TicketLifecyclePhaseElement = ({status, phaseName, extraClassNames, id, isPastPhase}: Props) => {
    return (
    <div className={`phase-ribbon-container ${extraClassNames ? extraClassNames : ''}`} title={phaseName}>
      <div className={`${isPastPhase ? 'phase-ribbon phase-ribbon-pastphase' : getLifecycleClass(status)}`} {...id && ({id: id})}>
        <div className="truncate" dir="ltr" style={{position: "relative"}}>{phaseName}</div>
      </div>
    </div>
    );
  };
  