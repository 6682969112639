import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { fallbackLng,Languages } from "./contants";

const resources: {
  [key: string]: { translations: string };
} = Object.values(Languages()).reduce((acc, langValues) => {
  return {
    ...acc,
    [langValues.key]: {
      translations: langValues.filePath,
    },
  };
}, {});
i18n.use(initReactI18next).init({
  fallbackLng: fallbackLng(), // If no language found, run this one
  lng: fallbackLng(), // default language
  resources: resources,
  ns: ['translations'],
  defaultNS: 'translations',
  interpolation: {
    escapeValue: false // react already safes from xss
  }
});
i18n.languages = Object.values(Languages()).map((langValue) => langValue.key);

export default i18n;
