import React, { useEffect, useState } from "react";
import { Header, Grid, Flex, ProviderConsumer, Checkbox, ChevronEndIcon, ChevronDownIcon } from '@fluentui/react-northstar';
import { TToolbarInteraction } from '@fluentui/react-teams';
import { Toolbar } from '../shared/components/Toolbar';
import { Breadcrumbs } from "../shared/components/BreadcrumbNavigation";
import { Helmet } from 'react-helmet';
import { checkInTeams } from "../App";
import { LoadingScreen } from "../shared/components/LoadingScreen";
import AccordionOpen from '../../svg/accordion-open.svg';
import AccordionClose from '../../svg/accordion-close.svg';
import { hasConsentFeature } from "../shared/cache/FeatureCache";
import { Providers } from "@microsoft/mgt-element";
import { toastDefault } from "../shared/utils/constants";
import { toast } from "react-toastify";
import './config.css'
import { ThemeColorScheme } from "../shared/common/TeamsTheme";
import { CheckLicense } from "../shared/components/License/CheckLicense";
import { MobileSettingsView } from "../automation/AutomationTable";
import { platformService } from "../shared/services/platform.service";
import {  i18n } from '../../components/shared/utils/helper';
const { t } = i18n();


interface ConsentData {
  Scopes: string[];
  ShortDesc: { text: string, isOpen: boolean }[];
  LongDesc: string[];
};

export const ConsentFeatures = {
  /* BUG 29988: Temporarily removing this from list until we do release the feature
  TEAMS_MEETING: {
    FeatureName:  t('consent-config.teams-meetings'),
    Scopes: ['Calendars.ReadWrite'],
    ShortDesc: [
      { text: t('consent-config.have-full-access-to-user-calendars'), isOpen: false }
    ],
    LongDesc: [
      t('consent-config.allows-the-app')
    ]
  },
  */
  MY_WORK: {
    FeatureName: t('consent-config.my-work'),
    Scopes: ['Calendars.ReadWrite', 'Group.ReadWrite.All', 'Mail.Read'],
    ShortDesc: [
      { text: t('consent-config.have-full-access-to-user-calendars'), isOpen: false },
      { text: t('consent-config.read-and-write-all-groups'), isOpen: false },
      { text: t('consent-config.read-user-mail'), isOpen: false }
    ],
    LongDesc: [
      t('consent-config.allows-the-app-to-read-update-create-and-delete-events-in-your-calendars'),
      t('consent-config.allows-the-app-to-create-groups-and-read-all-group'),
      t('consent-config.allows-the-app-to-read-email-in-your-mailbox')
    ]
  },
  EMAIL_CONNECTOR: {
    FeatureName: t('consent-config.notification-center'),
    Scopes: ['Mail.Read', 'Mail.Send'],
    ShortDesc: [
      { text: t('consent-config.read-all-mail'), isOpen: false },
      { text: t('consent-config.send-mail-as-a-user'), isOpen: false }
    ],
    LongDesc: [
      t('consent-config.allows-the-app-to-read-email-in-all-mailbox'),
      t('consent-config.allows-the-app-to-send-mail-as-you')
    ],
    ConsentUrl: "CheckEmailConsent",
    AppId: window.__runtimeConfig.emailClientId
  },
  INTUNE_CONNECTOR: {
    FeatureName: t('consent-config.intune-connector'),
    Scopes: ['DeviceManagementManagedDevices.PrivilegedOperations.All', 'DeviceManagementManagedDevices.Read.All', 'Device.Read.All', 'DeviceManagementRBAC.Read.All'],
    ShortDesc: [
      { text: t('consent-config.perform-user-impacting-remote-actions-on-Microsoft-Intune-devices'), isOpen: false },
      { text: t('consent-config.read-devices-microsoft-intune-devices'), isOpen: false },
      { text: t('consent-config.read-all-devices'), isOpen: false },
      { text: t('consent-config.read-microsoft-intune-rbac-settings'), isOpen: false }
    ],
    LongDesc: [
      t('consent-config.allows-the-app-to-perform-remote-high-impact-actions'),
      t('consent-config.allows-the-app-to-read-the-properties-of-devices'),
      t('consent-config.allows-the-app-to-read-devices'),
      t('consent-config.allows-the-app-to-read-the-properties-relating-to-the-microsoft')
    ]
  }
};
export const ConsentRequired: ConsentData = {
  Scopes: [
    'Channel.ReadBasic.All',
    'Contacts.Read',
    'Directory.AccessAsUser.All',
    'Directory.Read.All',
    'email',
    'Files.ReadWrite.All',
    'Group.Read.All',
    'GroupMember.Read.All',
    'offline_access',
    'OnlineMeetings.ReadWrite',
    'openid',
    'People.Read',
    'People.Read.All',
    'Presence.Read.All',
    'profile',
    'Sites.Read.All',
    'Team.ReadBasic.All',
    'TeamsAppInstallation.ReadWriteForTeam',
    'User.Read',
    'User.Read.All',
    'User.ReadBasic.All'
  ],
  ShortDesc: [
    { text: t('consent-config.read-the-names-and-descriptions-of-channels'), isOpen: false },
    { text: t('consent-config.read-your-contacts'), isOpen: false },
    { text: t('consent-config.access-the-directory-as-you'), isOpen: false },
    { text: t('consent-config.read-directory-data'), isOpen: false },
    { text: t('consent-config.have-full-access-to-all-files-you-have-access-to'), isOpen: false },
    { text: t('consent-config.read-all-groups'), isOpen: false },
    { text: t('consent-config.read-group-memberships'), isOpen: false },
    { text: t('consent-config.maintain-access-to-data-you-have-given-it-access-to'), isOpen: false },
    /* BUG 29988: Temporarily removing this from list until we do release the feature
    { text: t('consent-config.read-and-create-your-online-meetings'), isOpen: false },
    */
    { text: t('consent-config.read-your-relevant-people-list'), isOpen: false },
    { text: t('consent-config.read-all-users-relevant-people-lists'), isOpen: false },
    { text: t('consent-config.read-presence-information-of-all-users-in-your-organization'), isOpen: false },
    { text: t('consent-config.read-items-in-all-site-collections'), isOpen: false },
    { text: t('consent-config.read-the-names-and-descriptions-of-teams'), isOpen: false },
    { text: t('consent-config.manage-installed-teams-apps-in-teams'), isOpen: false },
    { text: t('consent-config.sign-you-in-and-read-your-profile'), isOpen: false },
    { text: t('consent-config.read-all-users-full-profiles'), isOpen: false },
    { text: t('consent-config.read-all-users-basic-profiles'), isOpen: false }
  ],
  LongDesc: [
    t('consent-config.read-channel-names-and-channel-descriptions-on-your-behalf'),
    t('consent-config.allows-the-app-to-read-contacts-in-your-contact-folders'),
    t('consent-config.allows-the-app-to-have-the-same-access-to-information-in-your-work-or-school-directory-as-you-do'),
    t('consent-config.allows-the-app-to-read-data-in-your-organizations-directory'),
    t('consent-config.allows-the-app-to-read-create-update-and-delete-all-files-that-you-can-access'),
    t('consent-config.allows-the-app-to-list-groups-and-to-read-their-properties-and-all-group-memberships-on-your-behalf'),
    t('consent-config.allows-the-app-to-list-groups-read-basic-group-properties-and-read-membership-of-all-your-groups'),
    t('consent-config.allows-the-app-to-see-and-update-the-data-you-gave-it-access-to'),
    /* BUG 29988: Temporarily removing this from list until we do release the feature
    t('consent-config.allows-the-app-to-read-and-create-online-meetings-on-your-behalf'),
    */
    t('consent-config.allows-the-app-to-read-a-list-of-people-in-the-order-thats-most-relevant-to-you'),
    t('consent-config.allows-the-app-to-read-a-list-of-people-in-the-order-that-is-most-relevant-to-you'),
    t('consent-config.allows-the-app-to-read-presence-information-of-all-users-in-the-directory-on-your-behalf'),
    t('consent-config.allow-the-application-to-read-documents-and-list-items-in-all-site-collections-on-your-behalf'),
    t('consent-config.read-the-names-and-descriptions-of-teams-on-your-behalf'),
    t('consent-config.allows-the-app-to-read-install-upgrade-and-uninstall-teams-apps-in-teams-you-can-acces'),
    t('consent-config.allows-you-to-sign-in-to-the-app-with-your-organizational-account'),
    t('consent-config.allows-the-app-to-read-the-full-set-of-profile'),
    t('consent-config.allows-the-app-to-read-a-basic-set-of-profile-properties')
  ]
};

export function ConsentConfig() {
  const api = new platformService();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [features, setFeatures] = useState([]);
  const [required, setRequired] = useState<ConsentData>(ConsentRequired);
  const [consentUnchanged, setConsentUnchanged] = useState<boolean>(true);

  const onToolbarInteraction = (interaction: TToolbarInteraction) => {
    if (interaction.action === "toggle-menu") {
      setMenuOpen(!menuOpen);
    }
  }

  const fetchData = async () => {
    setLoadingMessage(t('consent-config.checking-consents'));

    const results = [];
    Object.keys(ConsentFeatures).forEach(key => {
      results.push(hasConsentFeature(key))
    });

    const settled = await Promise.all(results);
    setFeatures(Object.keys(ConsentFeatures).map((key, idx) => ({ ...ConsentFeatures[key], hasConsent: settled[idx], canUpdate: !settled[idx] })));
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const openScope = (featureIndex: number, scopeIndex: number) => {
    const dupe = features.slice();
    dupe[featureIndex].ShortDesc[scopeIndex].isOpen = !features[featureIndex].ShortDesc[scopeIndex].isOpen;
    setFeatures(dupe);
  };
  const openReqScope = (scopeIndex: number) => {
    const dupe: ConsentData = { ...required };
    dupe.ShortDesc[scopeIndex].isOpen = !required.ShortDesc[scopeIndex].isOpen;
    setRequired(dupe);
  };
  const toggleFeature = (featureIndex: number) => {
    const dupe = features.slice();
    if (features[featureIndex].canUpdate) {
      dupe[featureIndex].hasConsent = !features[featureIndex].hasConsent;
      setFeatures(dupe);
      setConsentUnchanged(false);
    }
  };

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  const promptConsents = async () => {
    let scopes = ConsentRequired.Scopes;
    let feature = undefined;

    features.forEach(f => {
      if (f.hasConsent && !f.AppId) scopes = scopes.concat(...f.Scopes)
      else if (f.hasConsent && f.AppId) feature = f;
    });
    const provider = Providers.globalProvider;
    try {
      await provider.getAccessTokenForScopes(...scopes);
      if (feature) {
        const res = await api.checkConsent(feature.ConsentUrl);
        if (!res.data.value) {
          const jwt = parseJwt(localStorage.getItem('accessToken'));
          window.location.href = `https://login.microsoftonline.com/${jwt['tid']}/adminconsent?client_id=${feature.AppId}&redirect_uri=${encodeURIComponent(`${window.__runtimeConfig.platformurl}email/consented`)}`
          return;
        }
      }
            
      toast.success(t('consent-config.consents-have-been-updated'), toastDefault);
      setConsentUnchanged(true);
    } catch {

    }
  };

  const navs: Breadcrumbs = {
    breadcrumbs: [
      {
        title: "Settings",
        link: '/settings'
      },
      {
        title: t('consent-config.consent-management'),
        link: ""
      }
    ]
  };

  return (
    <>
      <Helmet>
        <title>{t('consent-config.title-page')}</title>
      </Helmet>
      <div className="hidden md:block">
        {
          (isLoading) ? <LoadingScreen message={loadingMessage} /> :
            (<ProviderConsumer render={(globalTheme) => (
              <>
                <Toolbar onInteraction={onToolbarInteraction} globalTheme={globalTheme} title="Settings > Feature Configuration" saveText={t('consent-config.update-consent')} disableSave={consentUnchanged} saveCommand={promptConsents} breadcrumbs={navs} />
                <Flex styles={{ ...ThemeColorScheme(globalTheme.siteVariables)}}>
                  <Flex.Item grow>
                    <Grid columns="auto"
                      styles={{
                        padding: '20px',
                        paddingTop: 0,
                        justifyContent: 'flex-start',
                        rowGap: '10px',
                        overflow: 'scroll',
                        maxHeight: (checkInTeams()) ? "calc(100vh - 50px)" : "calc(100vh - 100px)"
                      }}>
                      <Header content={t('consent-config.microsoft-consent-configuration')} />
                      <div className="teams-input-med">
                        <em>{t('consent-config.many-of-the-features-in-tikit-are-enhanced')}  <strong>{t('consent-config.a-m365-adminsitrator-account-is-required-for-consent')}</strong></em>
                      </div>
                      <div className="teams-input-med">
                        <em>{t('consent-config.once-consent-has-been-granted')}&nbsp;
                        <a href="https://help.tikit.ai/faq/how-do-i-resolve-need-admin-approval-when-opening-the-tikit-web-app/" target="_blank" 
                           rel="noopener noreferrer" style={{ color: globalTheme.siteVariables.colorScheme.brand.foregroundActive }}>{t('consent-config.if-you-would-like-more-information')}</a></em>
                      </div>

                      <Header as="h2" content={t('consent-config.feature-based-consents')} className="defaultStyle" />
                      {features.map((feat, fi) => (<>
                        <CheckLicense disable>
                          <Checkbox label={<strong>{feat.FeatureName}</strong>} toggle onChange={() => toggleFeature(fi)} checked={feat.hasConsent} disabled={!feat.canUpdate} className={!feat.canUpdate ? 'ui-toggle-readonly' : ''} />
                        </CheckLicense>
                        {feat.Scopes.map((_s, idx) => (<>
                          <Flex vAlign="center" space="between" className="pl-7 cursor-pointer" onClick={() => openScope(fi, idx)}>
                            <Flex gap="gap.small" vAlign="center" style={{ position: 'relative', ...ThemeColorScheme(globalTheme.siteVariables) }}>
                              {feat.ShortDesc[idx].isOpen ? <AccordionOpen className="configAccordionFill" /> : <AccordionClose className="configAccordionFill" />}
                              <span>{feat.ShortDesc[idx].text}</span>
                            </Flex>
                          </Flex>
                          {feat.ShortDesc[idx].isOpen && <div className="pl-12 teams-input-med text-xs">{feat.LongDesc[idx]}</div>}
                        </>))}
                      </>))
                      }

                      <Header as="h2" content={t('consent-config.required-consent')} className="defaultStyle" />
                      {required.ShortDesc.map((d, idx) => (<>
                        <Flex vAlign="center" space="between" className="pl-7 cursor-pointer" onClick={() => openReqScope(idx)}>
                          <Flex gap="gap.small" vAlign="center">
                            {d.isOpen ? <ChevronEndIcon /> : <ChevronDownIcon />}
                            <span>{d.text}</span>
                          </Flex>
                        </Flex>
                        {d.isOpen && <div className="pl-12 teams-input-med text-xs">{required.LongDesc[idx]}</div>}
                      </>))
                      }
                    </Grid>
                  </Flex.Item>
                </Flex>
              </>)} />)
        }
      </div>
      <MobileSettingsView />
    </>
  )
}