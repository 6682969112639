import React, { useEffect, useState } from 'react';
import Styles from '../styles/Lifecycle.module.css';
import { Flex, Button, AddIcon, ThemePrepared, Alert } from '@fluentui/react-northstar';
import { PhaseTransition } from './PhaseTransition';
import { cloneDeep } from 'lodash';
import { KeyValuePair, LifecycleTransition, LifecyclePhase } from '../utils/typings';
import { INITIAL_LIFECYCLETRANSITION_DATA } from '../utils/constants';
import { LIFECYCLE } from '../../shared/utils/constants';
import { useTranslation } from 'react-i18next';

interface Props {
  data: LifecycleTransition[];
  updateData: (data: LifecycleTransition[]) => void;
  globalTheme: ThemePrepared<any>;
  phasesOptions: DropdownDataModel[];
  tasksOptions: DropdownDataModel[];
  approvalsOptions: DropdownDataModel[];
  phaseData: LifecyclePhase;
  taskStatusList: TaskStatus[];
  phaseIndex?: number;
  updateTransitionDataOrRemove: (editData: KeyValuePair[], index: number) => void;
  powerautomateOptions: DropdownDataModel[];

}

export const PhaseTransitionSection = ({
  data,
  updateData,
  globalTheme,
  phasesOptions,
  tasksOptions,
  approvalsOptions,
  taskStatusList,
  phaseData,
  updateTransitionDataOrRemove,
  powerautomateOptions
}: Props) => {
  
  const { t } = useTranslation();

  const addNewTransition = () => {
    const dataLength = data?.length ?? 0;
    let newTransition = cloneDeep({...INITIAL_LIFECYCLETRANSITION_DATA, Title: `${t('lifecycle.new-transition')} ${(dataLength + 1)}`});
    data.push(newTransition);
    updateData([...data]);
  }
  
  const clickOnExpand = (index: number) => {
    data[index].IsExpand = !data[index].IsExpand;
    updateData([...data]);
  }

  return (
    <>
     <div>
        <h2 className={Styles.SubHeading2}>{t('lifecycle.transitions')}</h2>
        <div className={`${Styles.TicketsContainer}`} >
          {data.map((item, index) => {
            return (
              <div key={item.Id} className={`mb-3`}>
                <PhaseTransition 
                  data={item}
                  dataIndex={index}
                  updateData={updateTransitionDataOrRemove}
                  globalTheme={globalTheme}
                  phasesOptions={phasesOptions} 
                  tasksOptions={tasksOptions}
                  approvalsOptions={approvalsOptions}
                  taskStatusList={taskStatusList}
                  clickOnExpand={clickOnExpand}
                  phaseData={phaseData}
                  powerautomateOptions={powerautomateOptions}
                />
              </div>
            )
          })}
        </div>
        <Flex className={`mb-10 -mt-1 mr-0 ml-0`}>
            <Button
            icon={<AddIcon 
                styles={{color: globalTheme.siteVariables.colorScheme.brand.foreground}} 
                />}
            text
            primary
            content={<span 
                style={{color: globalTheme.siteVariables.colorScheme.brand.foreground}}
                >{LIFECYCLE.ADD_TRANSITION}</span>}
            onClick={addNewTransition}
            />
        </Flex>
    </div>
    </>
  );

};
