import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { Flex } from '@fluentui/react-northstar';

export const BackendUpdating = () => {
  return (
    <div className="h-screen flex justify-center content-center">
      <Flex gap="gap.small" hAlign="center" vAlign="center">
        <Player src="https://assets8.lottiefiles.com/packages/lf20_egxfwkca.json" loop autoplay background='transparent' speed={1} />
        <Flex column={true} hAlign="center" vAlign="center">
          <img src="https://signup.tikit.ai/images/tikit-logo-stacked.png" width="300" />
          <h2 className="font-bold text-2xl mb-3 mt-12">You seem to have caught us while we are adding and updating features!</h2>
          <span>We are running around and getting tasks done as fast as possible. We don't expect this to take long, unless there is an impostor among us.</span>
          <br /><br />
          <a href="https://tikit.freshstatus.io/">Get latest service status & updates here</a>
        </Flex>
      </Flex>
    </div>
  );
};
