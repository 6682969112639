import React, { useContext, useCallback } from 'react';
import ToolbarCSS from '../../Toolbar.module.css';
import { ColumnTreeWrapper } from './ColumnTreeWrapper/ColumnTreeWrapper';
import { ColumnItem } from './ColumnItem/ColumnItem';
import { SELECTED_COLUMNS } from '../../../../shared/utils/constants';
import { CustomViewContext, DEFAULT_SORT_ORDER } from '../../CustomViewContextProvider';
import { Alert, Text, ThemePrepared } from '@fluentui/react-northstar';
import { themeNames } from '@fluentui/react-teams';
import { ListFeatureStates } from '../../../List/List';
import { useTranslation } from 'react-i18next';

type PropTypes = {
  globalTheme: ThemePrepared<any>;
  isIdOrRequestSelected: boolean;
  filtersByFeature: (item: any, field: string) => void;
};

export const ColumnsView = ({ globalTheme, isIdOrRequestSelected, filtersByFeature }: PropTypes) => {
  const { customViewStates, setCustomViewStates } = useContext(CustomViewContext);
  const { columns: { selectedColumns } } = customViewStates;
  const filterColumnsByFeature = (item: FEViewColumn) => {
    return filtersByFeature(item, "label");
  };
  const featureRestrictColumns: FEViewColumn[] = SELECTED_COLUMNS.filter(filterColumnsByFeature);
  const {t} = useTranslation();
  
  const isDarkMode = globalTheme.siteVariables.theme === themeNames.Dark;

  const clearSelected = useCallback(() => {
    setCustomViewStates((prev): CustomViewStatesType => ({
      ...prev,
      columns: {
        columnsKeyOrder: [],
        selectedColumns: [],
        sortOrder: DEFAULT_SORT_ORDER,
      },
    }));
  }, []);
  
  return (
    <div className={`p-3 mt-2 ${ToolbarCSS.secondaryToolbarBg}`}>
      <div className='flex justify-between items-center'>
        <h2 className='font-bold my-3'>{t('ticket.ticket-list.configure-column.columns')}</h2>
        <Text content={t('ticket.ticket-list.configure-column.clear')} color='brand' className={`cursor-pointer`} onClick={clearSelected} />
      </div>
      {!isIdOrRequestSelected && (<Alert fitted danger={true} content={t('ticket.ticket-list.configure-column.id-or-request-is-mandatory')} style={{ fontSize: 12, width: '100%', marginBottom: '.5rem' }} />)}
      <div className='grid grid-cols-2 gap-4'>
        <div>
          <div className={`h-80 overflow-auto pt-2.5 pl-2.5 pb-2.5 pr-2.5 rounded-sm ${ToolbarCSS.ColumnContainer}`}>
            <h2 className='mb-3 font-semibold'>{t('ticket.ticket-list.configure-column.columns-footnote')}</h2>
            {featureRestrictColumns.map((column) => {
              const _hasColumn = selectedColumns.some((col) => col.id === column.id);
              return (
                <ColumnItem
                  globalTheme={globalTheme}
                  key={column.id}
                  label={column.label}
                  checked={_hasColumn}
                  _onChange={() => {
                    if (!_hasColumn) {
                      return setCustomViewStates((prev): CustomViewStatesType => ({
                        ...prev,
                        columns: { ...prev.columns, selectedColumns: [...prev.columns.selectedColumns, column] }
                      }));
                    } else {
                      setCustomViewStates((prev): CustomViewStatesType => {
                        return {
                          ...prev,
                          columns: {
                            ...prev.columns,
                            selectedColumns:
                              prev.columns.selectedColumns.filter(
                                (col) => col.id !== column.id
                              ),
                            sortOrder:
                              prev.columns.sortOrder?.orderBy === column.id
                                ? DEFAULT_SORT_ORDER
                                : prev.columns.sortOrder,
                          },
                        };
                      });
                    }
                  }}
                />
              );
            })}
          </div>
        </div>
        <div>
          <ColumnTreeWrapper
            columns={selectedColumns}
            isDarkMode={isDarkMode}
            onDragEndHandler={(_columns) => {
              //
            }}
            filterColumnsByFeature={filterColumnsByFeature}
          />
        </div>
      </div>
    </div>
  );
};
