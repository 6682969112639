
import { Text, Ref } from '@fluentui/react-northstar'
import { MgtPersonCard, PersonCard } from '@microsoft/mgt-react'
import React from 'react'
import { Portal } from '../../Wysiwyg/SlateEditor'
import { getClaims } from '../../../../AuthConfig'
import "./PersonCard.css";

type PersonCardMentionProps = {
    mentionedEmail: string;
    element: any;
    href: string;
};

export const PersonCardMention: React.FC<PersonCardMentionProps> = ({
  mentionedEmail,
  element,
}: PersonCardMentionProps) => {
    const linkRef = React.useRef<HTMLElement>();
    const cardRef = React.useRef<HTMLDivElement | null>();
    const [showPersonCard, setShowPersonCard] = React.useState<boolean>(false);

    const isDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
    let theme = localStorage.getItem("TikitTheme") ?? "light";

    if (theme === "system") {
    theme = isDark ? "dark" : "light";
    }

    MgtPersonCard.config.sections.mailMessages = false;

    const cardInfo: any = {};
    if (/^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}?$/.test(mentionedEmail))
        cardInfo.userId = mentionedEmail;
    else
        cardInfo.personQuery = mentionedEmail;

    React.useEffect(() => {
        const el = cardRef.current
        const rect = linkRef.current.getBoundingClientRect()
        el.style.bottom = `calc(100vh - ${rect.bottom - rect.height}px)`
        el.style.left = `${rect.left + (rect.width / 2) - 170}px`
    }, [showPersonCard])

    return (
        <>
            <Ref innerRef={linkRef}>
                <Text color="brand" content={element} onMouseOver={() => setShowPersonCard(true)} onMouseOut={() => { setShowPersonCard(false) }} className='cursor-pointer'
                    atMention={getClaims('preferred_username', 'unique_name', 'oid').some(x => x.toLowerCase() == mentionedEmail.toLowerCase()) ? "me" : false} />
            </Ref>
            <Portal>
                <div className={showPersonCard ? '' : 'hidden'} style={{ position: 'fixed', zIndex: 1000 }} ref={cardRef} onMouseOver={() => setShowPersonCard(true)} onMouseOut={() => { setShowPersonCard(false) }}>
                    <PersonCard {...cardInfo}  className={theme} />
                </div>
            </Portal>
        </>
    )
}
