import React, { Fragment, useContext, useEffect } from 'react';
import { Input, ThemePrepared } from '@fluentui/react-northstar';
import Filters from './Filters';
import { CustomViewContext } from '../CustomViewContextProvider';
import { ColumnsView } from './ColumnsView/ColumnsView';
import ToolbarCSS from '../Toolbar.module.css';
import { ListFeatureStates } from '../../List/List';
import { useTranslation } from 'react-i18next';

interface Props {
  defaultFilters: FilterPropsItems[];
  selectedRequesters: PeoplePickerUserType[];
  setSelectedRequesters: React.Dispatch<React.SetStateAction<PeoplePickerUserType[]>>;
  globalTheme: ThemePrepared<any>;
  isIdOrRequestSelected: boolean;
  invalidRequesterMsg: string;
  setInvalidRequesterMsg: React.Dispatch<React.SetStateAction<string>>;
  selectedAffectedUsers: PeoplePickerUserType[];
  setSelectedAffectedUsers: React.Dispatch<React.SetStateAction<PeoplePickerUserType[]>>;
  invalidAffectedUserMsg: string;
  setInvalidAffectedUserMsg: React.Dispatch<React.SetStateAction<string>>;
  listFeatureStates: ListFeatureStates;
}

const Content = ({
  defaultFilters,
  selectedRequesters,
  setSelectedRequesters,
  globalTheme,
  isIdOrRequestSelected,
  invalidRequesterMsg,
  setInvalidRequesterMsg,
  selectedAffectedUsers,
  setSelectedAffectedUsers,
  invalidAffectedUserMsg,
  setInvalidAffectedUserMsg,
  listFeatureStates
}: Props) => {
  const { customViewStates, setCustomViewStates } = useContext(CustomViewContext);
  const { view, viewName } = customViewStates;
  const filtersByFeature = (item: any, field: string) => {
    if(!listFeatureStates.useTeam && item[field] == "Team") return false; 
    if(!listFeatureStates.useTicketType && (item[field] == "Type" || item[field] == "Ticket Type")) return false; 
    if(!listFeatureStates.useTicketLifecycle && (item[field] == "Lifecycle" || item[field] == "Phase")) return false; 
    if(!listFeatureStates.useSLA && (item[field] == "SLAs")) return false;

    return true;
  };

  const featureRestrictFilters = defaultFilters.filter(item => filtersByFeature(item, "title"));
  const {t} = useTranslation();

  useEffect(() => {
    setInvalidRequesterMsg('')
  }, [])

  return (
    <Fragment>
      {view !== 'columns' && (
        <>
          <div className='font-semibold mt-2'>{t('ticket.ticket-list.configure-column.name')}</div>
          <Input
            fluid
            onChange={(e, p) => { setCustomViewStates((prev): CustomViewStatesType => ({ ...prev, viewName: p.value })) }}
            value={viewName}
            inverted
            placeholder={t('ticket.ticket-list.configure-column.enter-view-name')}
            className={`mt-2 ${ToolbarCSS['custom-view-name']}`}
          />

          <Filters
            {...{
              defaultFilters: featureRestrictFilters,
              selectedRequesters,
              setSelectedRequesters,
              invalidRequesterMsg,
              setInvalidRequesterMsg,
              selectedAffectedUsers,
              setSelectedAffectedUsers,
              invalidAffectedUserMsg,
              setInvalidAffectedUserMsg,
              filtersByFeature
            }}
          />
        </>
      )}
      <ColumnsView
        globalTheme={globalTheme}
        isIdOrRequestSelected={isIdOrRequestSelected}
        filtersByFeature={filtersByFeature}
      />
    </Fragment>
  );
};

export default Content;
