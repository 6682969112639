import { Dialog, ThemePrepared } from '@fluentui/react-northstar';
import React, { useContext, useEffect, useState, useRef } from 'react';
import Content from './Content';
import ToolbarCSS from '../Toolbar.module.css';
import { CustomViewContext, DEFAULT_SORT_ORDER } from '../CustomViewContextProvider';
import { addNewView, isSaveView, editCustomViewFunc, getViewOnAddNewView, selectedColumnsFromConfigurableColumnsKeyOrder, setSortOrderInStorage, setConfiguredColumnsKeyOrderInStorage } from './heplers/customPopupHelper';
import { appState, useSetState } from '../../../AppState';
import FormLoading from './FormLoading';
import { ThemeColorScheme } from '../../../shared/common/TeamsTheme';
import ListFunctions, { DEFAULT_SORT_ORDER_FOR_QUERYING } from '../../List/ListFunctions';
import { ListContext } from '../../List/ListContextProvider';
import { useHistory, useLocation } from 'react-router-dom';
import { ListFeatureStates } from '../../List/List';
import { useTranslation } from 'react-i18next';

interface Props {
  defaultFilters: FilterPropsItems[];
  globalTheme: ThemePrepared<any>;
  isCustomViewEnabled: boolean;
  listFeatureStates: ListFeatureStates;
}

const CustomViewPopup = ({ defaultFilters, globalTheme, isCustomViewEnabled, listFeatureStates }: Props) => {
  const setAppState = useSetState();
  const state = appState();
  const history = useHistory();
  const location = useLocation();
  const { customViewStates, setCustomViewStates } = useContext(CustomViewContext);
  const { setListStates } = useContext(ListContext);
  const { showCustomViewModal, view, viewName } = customViewStates;
  const [submitClicked, setSubmitClicked] = useState(false);
  const [selectedRequesters, setSelectedRequesters] = useState([] as PeoplePickerUserType[]);
  const [selectedAffectedUsers, setSelectedAffectedUsers] = useState([] as PeoplePickerUserType[]);
  const [invalidRequesterMsg, setInvalidRequesterMsg] = useState('');
  const [invalidAffectedUserMsg, setInvalidAffectedUserMsg] = useState('');
  const { getpaginatedTickets, isDefaultViewId } = ListFunctions();
  const isMounted = useRef(false);
  const {t} = useTranslation();

  const header = () => {
    if (view === 'edit') {
      return [t('ticket.ticket-list.configure-column.edit-view'), t('ticket.ticket-list.configure-column.edit-view'), t('ticket.ticket-list.configure-column.update')];
    } else if (view === 'save') {
      return [t('ticket.ticket-list.configure-column.save-view'), t('ticket.ticket-list.configure-column.save-view'), t('ticket.ticket-list.configure-column.save')];
    } else if (view === 'columns') {
      return [
        t('ticket.ticket-list.configure-column.configure-columns'),
        t('ticket.ticket-list.configure-column.add-remove-or-re-arrange'),
        t('ticket.ticket-list.configure-column.apply'),
      ];
    } else {
      // add - view
      return [
        t('ticket.ticket-list.configure-column.add-new-view'),
        t('ticket.ticket-list.configure-column.assign-name-manage-filters'),
        t('ticket.ticket-list.configure-column.save'),
      ];
    }
  };
  const [title, subTitle, confirmBtnContent] = header();

  const isIdOrRequestSelected = () => {
    const { columnsKeyOrder } = customViewStates.columns;
    if (columnsKeyOrder.length <= 0) return true;
    return columnsKeyOrder.includes(1) || columnsKeyOrder.includes(2);
  };

  const onSubmit = async () => {

    if (!isCustomViewEnabled) return;

    if (!isIdOrRequestSelected()) return;
    setSubmitClicked(true);
    if (customViewStates.view === 'columns') {
      const prevSortOrder = customViewStates.configureColumnsApplied.sortOrder;

      setCustomViewStates((prev): CustomViewStatesType => {
        const sortOrder = prev.columns.sortOrder?.orderType ? {
          orderBy: prev.columns.sortOrder?.orderBy,
          orderType: prev.columns.sortOrder?.orderType
        } : DEFAULT_SORT_ORDER;

        setSortOrderInStorage(sortOrder);
        setConfiguredColumnsKeyOrderInStorage(prev.columns?.columnsKeyOrder);
        return {
          ...prev,
          showCustomViewModal: false,
          view: isSaveView(
            isDefaultViewId,
            prev.columns?.columnsKeyOrder,
            state.filterDropdownCount,
            state.filterDropdown.queryValue
          )
            ? 'save'
            : 'none',
          configurableColumnsKeyOrder: [...(prev.columns?.columnsKeyOrder ?? [])],
          columns: {
            ...prev.columns,
            sortOrder
          },
          configureColumnsApplied: {
            prevSortOrder,
            sortOrder
          }
        };
      });
    }
    if (customViewStates.view === 'edit') {
      await editCustomViewFunc({
        selectedRequesters,
        customViewStates,
        setCustomViewStates,
        setAppState,
        defaultFilters,
        setInvalidRequesterMsg,
        setInvalidAffectedUserMsg,
        selectedAffectedUsers
      });
    }
    if (customViewStates.view === 'save' || customViewStates.view === 'add') {
      await addNewView({
        selectedRequesters,
        customViewStates,
        setCustomViewStates,
        defaultFilters,
        setAppState,
        setInvalidRequesterMsg,
        setInvalidAffectedUserMsg,
        history,
        location,
        selectedAffectedUsers
      });
    }
    setSubmitClicked(false);
  };

  const onCancel = () => {
    if (customViewStates.view === 'columns') {
      setCustomViewStates((prev): CustomViewStatesType => ({
        ...prev,
        showCustomViewModal: false,
        view: isSaveView(
          isDefaultViewId,
          customViewStates.configurableColumnsKeyOrder,
          state.filterDropdownCount,
          state.filterDropdown.queryValue
        )
          ? 'save'
          : 'none',
        viewName: '',
        columns: {
          columnsKeyOrder: [...prev.configurableColumnsKeyOrder],
          selectedColumns: selectedColumnsFromConfigurableColumnsKeyOrder(prev.configurableColumnsKeyOrder),
          sortOrder: prev.configureColumnsApplied.sortOrder,
        },
      }));
    }
    if (customViewStates.view === 'edit') {
      setCustomViewStates((prev): CustomViewStatesType => ({
        ...prev,
        showCustomViewModal: false,
        view: 'edit',
        viewName: '',
        filters: JSON.parse(JSON.stringify(defaultFilters)),
        columns: {
          columnsKeyOrder: [],
          selectedColumns: [],
          sortOrder: prev.configureColumnsApplied.sortOrder,
        },
      }));
    }
    if (customViewStates.view === 'save') {
      setCustomViewStates((prev): CustomViewStatesType => ({
        ...prev,
        showCustomViewModal: false,
        viewName: '',
        columns: {
          columnsKeyOrder: [...prev.configurableColumnsKeyOrder],
          selectedColumns: selectedColumnsFromConfigurableColumnsKeyOrder(prev.configurableColumnsKeyOrder),
          sortOrder: prev.configureColumnsApplied.sortOrder
        },
      }));
    }
    if (customViewStates.view === 'add') {
      setCustomViewStates((prev): CustomViewStatesType => ({
        ...prev,
        showCustomViewModal: false,
        view: getViewOnAddNewView(
          customViewStates.selectedCustomView,
          isSaveView(
            isDefaultViewId,
            customViewStates.configurableColumnsKeyOrder,
            state.filterDropdownCount,
            state.filterDropdown.queryValue
          )
        ),
        viewName: '',
        filters: [],
        columns: {
          columnsKeyOrder: customViewStates.selectedCustomView ? [] : [...prev.configurableColumnsKeyOrder],
          selectedColumns: customViewStates.selectedCustomView ? [] : selectedColumnsFromConfigurableColumnsKeyOrder(prev.configurableColumnsKeyOrder),
          sortOrder: prev.configureColumnsApplied.sortOrder
        },
      }));
    }
  };

  // get paginated tickets if configure columns is applied after changing sort order
  useEffect(() => {
    if (isMounted.current) {
      const appliedSortOrder = customViewStates.configureColumnsApplied.sortOrder || DEFAULT_SORT_ORDER_FOR_QUERYING;
      const prevAppliedSortOrder = customViewStates.configureColumnsApplied.prevSortOrder || DEFAULT_SORT_ORDER_FOR_QUERYING;

      // previous and applied sort order will be same for edit/add/save custom_view
      // previous and applied sort order will only be different for configure_columns sort order selection and ticket list columns sort order selection
      if (
        prevAppliedSortOrder?.orderBy === appliedSortOrder?.orderBy &&
        prevAppliedSortOrder?.orderType === appliedSortOrder?.orderType
      ) { return };

      const page = 1;
      setListStates((prev) => ({
        ...prev,
        isTicketsFetching: true,
        currentPage: page,
      }));
      getpaginatedTickets({ page });
    }

    isMounted.current = true;
  }, [customViewStates.configureColumnsApplied.sortOrder]);

  return (
    <Dialog
      className={`responsive-dialog ${ToolbarCSS.customViewDialog} overflow-auto`}
      style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background, ...ThemeColorScheme(globalTheme.siteVariables) }}
      closeOnOutsideClick={false}
      cancelButton={{ content: t('ticket.ticket-list.configure-column.cancel'), disabled: submitClicked }}
      onCancel={onCancel}
      confirmButton={{
        content: confirmBtnContent,
        primary: true,
        disabled: (view !== 'columns' && !viewName) || submitClicked || !isIdOrRequestSelected() || !!invalidRequesterMsg,
      }}
      onConfirm={onSubmit}
      open={showCustomViewModal}
      // onOpen={onOpen}
      header={{
        content: (
          <div style={{ width: '100%' }}>
            <h3>{title}</h3>
            <div className='text-gray-400 text-sm font-medium'>{subTitle}</div>
          </div>
        ),
        style: { width: '100%' },
      }}
      content={
        <div className='relative'>
          {submitClicked && (
            <FormLoading message={view === 'edit' ? t('ticket.ticket-list.configure-column.updating') : t('ticket.ticket-list.configure-column.creating')} />
          )}
          <div className={submitClicked && 'pointer-events-none opacity-40'}>
            <Content
              {...{
                defaultFilters,
                selectedRequesters,
                setSelectedRequesters,
                globalTheme,
                isIdOrRequestSelected: isIdOrRequestSelected(),
                invalidRequesterMsg,
                setInvalidRequesterMsg,
                selectedAffectedUsers,
                setSelectedAffectedUsers,
                invalidAffectedUserMsg,
                setInvalidAffectedUserMsg,
                listFeatureStates
              }}
            />
          </div>
        </div>
      }
    ></Dialog>
  );
};

export default CustomViewPopup;
