import React, { useEffect, useState } from 'react';
import { LICENSE_FEATURE } from '../utils/constants';
import { getCachedFeature } from './../cache/FeatureCache';

interface Props {
  children: any;
  featureName: string;
}

export const CheckFeature: React.FC<Props> = ({ children, featureName }) => {
  const [enabledFeature, setEnabledFeature] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (featureName) {
        setEnabledFeature(featureName === LICENSE_FEATURE.ValidSubscription || (await getCachedFeature(featureName)));
      }
    })();
  }, [featureName]);

  return <>{enabledFeature && children}</>;
};
