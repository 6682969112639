import React, { useContext } from 'react';
import { Communication } from '@fluentui/react-teams';
import { RenderSkeleton } from './RenderSkeleton';
import { Board } from '../Board';
import { Report } from '../Report';
import { List } from '../List/List';
import { AppStateContext } from '../../AppState';
import { Ticket } from '../../shared/interfaces/ticket.interface';
import { toggleButtonMapper, ViewType } from '../ticketHelper';

interface Props {
  isTicketsFetching: boolean;
  page: string;
  onTicketsSelected: (tickets: Ticket[]) => void;
  defaultFilters: FilterPropsItems[]
}

export const TicketBoardView = ({
  isTicketsFetching,
  page,
  onTicketsSelected,
  defaultFilters
}: Props) => {
  const state = useContext(AppStateContext);

  return (
    <>
      {isTicketsFetching || state.isReload ? (
        <RenderSkeleton page={page} />
      ) : !isTicketsFetching && state.isError ? (
        <Communication {...state.errorConfig} />
      ) : page === toggleButtonMapper[ViewType.board] ? (
        <Board defaultFilters={defaultFilters} />
      ) : page === toggleButtonMapper[ViewType.chart] ? (
        <Report />
      ) : (
        <List onTicketsSelected={onTicketsSelected} defaultFilters={defaultFilters}/>
      )}
    </>
  );
};
