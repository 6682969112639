let skipBrowserLang = false;
const tryRequire = (x: string) => {

  try {
    require('./' + x + '.json');
    return true;
  }
  catch (e) {
    return false;
  }
}
const langs = () => {
  const engLangCode = "en-US";
  const languages = { [engLangCode]: [engLangCode, require(`./${engLangCode}.json`)] }
  const addedLangs = [engLangCode];
  navigator.languages.map((x, i) => {
    if (x === engLangCode && i == 0) {
      skipBrowserLang = true;
    }

    if (tryRequire(x) && !addedLangs.includes(x)) {
      languages[x] = [x, require('./' + x + '.json')]
      addedLangs.push(x);
    } else if (x.length > 2 && tryRequire(x.substring(0, 2)) && !addedLangs.includes(x.substring(0, 2))) {
      languages[x] = [x, require('./' + x.substring(0, 2) + '.json')]
      addedLangs.push(x.substring(0, 2));
    }
  });
  return languages;
}

export const fallbackLng = () => Object.keys(langs())[0];

export const Languages = (): Language[] => {
  const browserLangs = Object.entries(langs()).map(([langKey, langValue], i, arr) => {
    const [key, filePath] = langValue;
    const isDefault = (arr.length == 1 || (arr.length > 1 && i == 1 && !skipBrowserLang) || (i < 1 && skipBrowserLang)) ? true : false;
    const label = new Intl.DisplayNames(langKey, { type: 'language', languageDisplay: 'dialect' }).of(langKey.substring(0, 2));
    return {
      name: key,
      key,
      label: label.charAt(0).toUpperCase() + label.slice(1),
      value: langKey,
      filePath,
      isDefault
    }
  });
  browserLangs.sort((a, b) => {
    return a.isDefault < b.isDefault ? 1 : -1;
  });
  settingUpLanguagesLocalStorage(browserLangs);
  return browserLangs;
}

function settingUpLanguagesLocalStorage(browserLangs: { name: any; key: any; label: any; value: string; filePath: any; isDefault: boolean; }[]) {
  const storedLanguages = localStorage.getItem('TikitLanguages');
  if (storedLanguages) {
    const lsLangs = JSON.parse(storedLanguages) as Language[];
    // if any change occured in BrowserLang then we will update local storage
    if (lsLangs.length != browserLangs.length) {
      localStorage.setItem('TikitLanguages', JSON.stringify(browserLangs));
    }
    else {
      lsLangs.forEach((item, index) => {
        if (item.key != browserLangs[index].key) {
          localStorage.setItem('TikitLanguages', JSON.stringify(browserLangs));
        }
      });
    }
    let browserdefaultLanguage = '';
    let lsDefaultLanguage = '';
    lsDefaultLanguage = lsLangs[0]?.key;
    browserdefaultLanguage = browserLangs[0]?.key;
    if (browserdefaultLanguage !== lsDefaultLanguage) {
      localStorage.setItem('TikitLanguages', JSON.stringify(browserLangs));
    }
  } else {
    localStorage.setItem('TikitLanguages', JSON.stringify(browserLangs));
  }
}
