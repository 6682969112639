import React, { useEffect, useState } from "react";
import { TextArea, Text, ProviderConsumer as FluentUIThemeConsumer, Flex, Dropdown } from "@fluentui/react-northstar";
import { LifecycleTask } from "../utils/typings";
import { PlatformUser } from "../../shared/interfaces/platformuser.interface";
import { ThemeColorScheme } from "../../shared/common/TeamsTheme";
import '../styles/Styles.css';
import { LICENSE_FEATURE, LIFECYCLE } from '../../../components/shared/utils/constants'
import { CheckFeature } from "../../shared/components/CheckFeature";
import { appState } from "../../AppState";
import { useTranslation } from "react-i18next";
import { formatAssigneeItems, formatSupportGroupItems, formatTeamItems } from './../../shared/components/TicketTasks/TaskCommon/TaskCommon';

interface Props{
    taskData: LifecycleTask,
    setTaskData: (data: LifecycleTask) => void;
    userList: PlatformUser[];
}

export const LabelElement = (props: any) => {
  return (
    <div>
      <Flex>
        <Text content={props.label} style={{ display: 'block' }} />
        {props.required && (
          <span style={{ color: 'red', marginLeft: 2 }}>*</span>
        )}
      </Flex>
      <div>{props.children}</div>
    </div>
  );
};

export const PhaseTaskForm = ({taskData,setTaskData, userList}:Props) => {
    const [assigneeItems, setAssigneeItems] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [teamItems, setTeamItems] = useState([]);
    const [teamSearchText, setTeamSearchText] = useState('');
    const [supportGroupItems, setSupportGroupItems] = useState([]);
    const [supportGroupText, setSupportGroupText] = useState('');
    const [taskCollaboratorItems, settaskCollaboratorItem] = useState([]);
    
    const currentState = appState();
    const { t } = useTranslation();

    const populateTeamsDropDown = (teams: any[]) => {
      const items = teams.map((team: any) => {
        if (team.accessibilityitemprops.id === taskData?.TeamId) {
          team.selected = true;
          setTeamSearchText(taskData?.Team?.Name);
        } else {
          team.selected = false;
        }
        return team;
      });
      setTeamItems([...items]);
    }

    const populateAssigneeDropDown = ()=>{
      const supportGroup = currentState.supportgroups.filter(x=>x.Id == taskData?.SupportGroupId)[0];
      const users = supportGroup ? supportGroup.Members : userList;
      const items = users.map((data: any) => {
        if (data.Id === taskData?.Assignee?.Id) {
          data.selected = true;
          setSearchText(taskData.Assignee.FullName);
        } else {
          data.selected = false;
        }
        data.header = data.FullName;
        return data;
      });
      setAssigneeItems([...items]);
    }

    const populateSupportGroupDropDown = (groups: any[]) => {
      const groupItems = groups.map((group: any) => {
        if (group.accessibilityitemprops.id === taskData?.SupportGroupId) {
          group.selected = true;
          setSupportGroupText(taskData?.SupportGroup?.Name);
        } else {
          group.selected = false;
        }
        return group;
      });
      setSupportGroupItems([...groupItems]);
    }

    const onTeamsChange = (selectedTeam: any) => {
      const updatedTeamItems = teamItems.map((item: any) => {
        if (item.Id == selectedTeam?.Id) {
          item.selected = true;
        } else {
          item.selected = false;
        }
        return item;
      });
      setTeamItems(updatedTeamItems);
      setTeamSearchText(selectedTeam?.Name);

      const updatedSupportGroupItems = selectedTeam ? currentState.supportgroups.filter(t => t.TeamsAadObjectId == selectedTeam?.TeamsAadObjectId) : currentState.supportgroups;
      setSupportGroupItems(formatSupportGroupItems(updatedSupportGroupItems));
      setSupportGroupText('');

      const updatedAssigneeItems = (!selectedTeam) ? userList : currentState.teams.find(x => x.Id == selectedTeam?.Id)?.Users || [];
      setAssigneeItems(formatAssigneeItems(updatedAssigneeItems));
      setSearchText('');

      setTaskData({
        ...taskData,
        TeamId: selectedTeam?.Id,
        Team: selectedTeam,
        SupportGroupId: null,
        SupportGroup: null,
        AssigneeId: null,
        Assignee: null
      });
    }

    const onSupportGroupChange = (selectedGroup: any) => {
      const updatedGroupItems = supportGroupItems.map((item: any) => {
        if (item.Id == selectedGroup?.Id) {
          item.selected = true;
        } else {
          item.selected = false;
        }
        return item;
      });
      setSupportGroupItems(updatedGroupItems);
      setSupportGroupText(selectedGroup?.Name);

      const updatedAssigneeItems = (selectedGroup) ? selectedGroup?.Members : userList;
      setAssigneeItems(formatAssigneeItems(updatedAssigneeItems));
      setSearchText('');

      setTaskData({
        ...taskData,
        SupportGroupId: selectedGroup?.Id,
        SupportGroup: selectedGroup,
        AssigneeId: null,
        Assignee: null
      });
    }

    const onAssigneeChange = (selectedAssignee: any) => {
      const updatedAssigneeItems = assigneeItems.map((item: any) => {
        if (item.Id == selectedAssignee?.Id) {
          item.selected = true;
        } else {
          item.selected = false;
        }
        return item;
      });
      setAssigneeItems(updatedAssigneeItems);
      setSearchText(selectedAssignee?.FullName);
      setTaskData({
        ...taskData,
        AssigneeId: selectedAssignee?.Id,
        Assignee: selectedAssignee
      });
    }

    const onChangeTaskCollaborators = (_e, p) => {
      const stringifyTheValue = JSON.stringify(p.value);
      const parsedValue: any[] = JSON.parse(stringifyTheValue);
      let selectedTasktaskCollaboratorList = [];
      parsedValue.forEach(item => {
        const phasetaskCollaboratorId = item['accessibilityitemprops'];
        const phaseTaskCollaborator = currentState.platformusers.find(u=>u.Id == phasetaskCollaboratorId)
        selectedTasktaskCollaboratorList.push(phaseTaskCollaborator);
      });
 
      setTaskData({ ...taskData, TaskCollaborators: selectedTasktaskCollaboratorList });
    }

    useEffect(()=>{
      populateTeamsDropDown(formatTeamItems(currentState.teams));
    },[taskData?.TeamId]);
  
    useEffect(()=>{
      let  filteredGroups = currentState.supportgroups;
      if(taskData?.Team && taskData?.Team!== null){
        filteredGroups=currentState.supportgroups.filter(t => t.TeamsAadObjectId == taskData?.Team?.TeamsAadObjectId) ;
      }
      populateSupportGroupDropDown(formatSupportGroupItems(filteredGroups));
    },[taskData?.SupportGroupId]);
  
    useEffect(()=>{
      populateAssigneeDropDown();
    },[taskData?.AssigneeId]);
  
    useEffect(() => {
      const taskCollaboratorFormatedList: any[] = formatTaskCollaboratorList(
        currentState.usersInRole,
        taskData?.TaskCollaborators);
      settaskCollaboratorItem(taskCollaboratorFormatedList);
    }, [taskData?.TaskCollaborators, currentState.usersInRole]);

    const formatTaskCollaboratorList = (analystList: Assignee[], selectedtaskCollaborator: PlatformUser[] | null) => {
      let taskCollaboratorFormatedList = [];
      analystList.forEach((t: { FullName: any; Id: number }) => {
          let taskCollaboratorObj = {};
          
          if (selectedtaskCollaborator?.some(item => item.Id == t.Id)) {
            taskCollaboratorObj['selected'] = true;
          } else {
            taskCollaboratorObj['selected'] = false;
          }
          
          taskCollaboratorObj['header'] = t.FullName;
          taskCollaboratorObj['accessibilityitemprops'] = t.Id;

          taskCollaboratorFormatedList.push(taskCollaboratorObj);
      });
      return taskCollaboratorFormatedList;
    };
  
    return (
      <FluentUIThemeConsumer
      render={globalTheme => (
        
        <div
          className="min-h-32"
          style={{
            color: 'var(--mgt-theme-default-foreground)',
            ...ThemeColorScheme(globalTheme.siteVariables)
          }}
        >
          <div >
            <LabelElement label={LIFECYCLE.TITLE} required={true}>
                <TextArea
                    maxLength={500}
                    required
                    fluid
                    value={taskData.Name}
                    onChange={(_e, p) => {
                      setTaskData({
                        ...taskData,
                        Name: p.value
                      });
                  }}
                />
            </LabelElement>
          </div>

          <CheckFeature featureName={LICENSE_FEATURE.MultiDepartment}>
            <div className={`mt-2`}>
              <LabelElement label={t('ticket.add-ticket.team')} >
              <Dropdown
                  className={`cursor-pointer`}
                  items = {teamItems}
                  fluid
                  clearable
                  checkable
                  search
                  searchQuery={teamSearchText}
                  onSearchQueryChange={(_e, data) => {
                     setTeamSearchText(data.searchQuery);
                  }}
                  onChange={(_event, p: any) => {
                    onTeamsChange(p.value);
                  }}
                  value={teamItems.filter(t => t.selected)}
                  style={{backgroundColor: globalTheme.siteVariables.colorScheme.default.background2 }}
                  noResultsMessage={
                   <div style={{ textAlign: 'center' }}>
                      <Text content={t('ticket.add-ticket.team-not-found')} />
                    </div>
                  }
                 />
              </LabelElement>
            </div>    
          </CheckFeature>

          <div className={`mt-2`}>
            <Text content={t('ticket.add-ticket.group')} />
              <Dropdown
                  items={supportGroupItems}
                  fluid
                  checkable
                  clearable
                  search
                  searchQuery={supportGroupText}
                  onSearchQueryChange={(_e, data) => {
                     setSupportGroupText(data.searchQuery);
                  }}
                  onChange={(_event, p:any) => {
                    onSupportGroupChange(p.value);
                  }}
                  value={supportGroupItems.filter(t => t.selected)}
                  noResultsMessage={
                    <div style={{ textAlign: 'center' }}>
                      <Text content={t('ticket.add-ticket.group-not-found')} />
                    </div>
                  }
                  style={{backgroundColor: globalTheme.siteVariables.colorScheme.default.background2 }}
                />
            </div>

          <div className={`mt-2`}>
            <LabelElement label={LIFECYCLE.ASSIGNEE}>
              <Dropdown
                style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background2 }}
                items={assigneeItems}
                search
                fluid
                checkable
                clearable
                className={`assignee-drapdown-field`}
                value={assigneeItems.filter(t => t.selected)}
                searchQuery={searchText}
                onSearchQueryChange={(_e, data) => {
                  setSearchText(data.searchQuery);
                }}
                onChange={(_event, p: any) => {
                  onAssigneeChange(p.value);
                }}
                noResultsMessage={
                  <div style={{ textAlign: 'center' }}>
                    <Text content={LIFECYCLE.USER_NOT_FOUND} />
                  </div>
                }
              />
            </LabelElement>
          </div>

          <CheckFeature featureName={LICENSE_FEATURE.Collaborators}>
            <div className={`mt-2`}>
               <Text content={t('ticket.ticket-list.collaborators')} />
                  <Dropdown
                    multiple
                    search
                    fluid
                    items={taskCollaboratorItems.filter((t: any) => taskData.AssigneeId != t.accessibilityitemprops)}
                    onChange={onChangeTaskCollaborators}
                    defaultValue={taskCollaboratorItems.filter(t => t.selected)}
                    searchInput={{ inline: true,  styles: { paddingLeft: '8px', height: '32px' } }}
                    noResultsMessage={<div>No User found</div>}
                    style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background2 }}
                    className={'taskCollaborators--dropdown'}
                 />
              </div>
           </CheckFeature >
        </div>)} />
    ) 
};