import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  SyntheticEvent,
  useState,
} from "react";
import get from "lodash/get";
import clone from "lodash/clone";
import chunk from "lodash/chunk";
import update from 'immutability-helper';

import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  Dropdown,
  DropdownItemProps,
  Flex,
  FlexItem,
  Form as FluentUIForm,
  FormDropdown,
  FormRadioGroup,
  Input,
  ProviderConsumer as FluentUIThemeConsumer,
  RadioGroupItemProps,
  SiteVariablesPrepared,
  Text,
  TextArea,
  ComponentEventHandler,
} from "@fluentui/react-northstar";

import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  CloseIcon,
  AddIcon,
} from "@fluentui/react-icons-northstar";

import { getText, TTextObject, TTranslations } from "@fluentui/react-teams/lib/esm/translations";
import { Surface } from "@fluentui/react-teams/lib/esm/types/types";

import { FormTheme } from "@fluentui/react-teams/lib/esm/components/Form/FormTheme";
import { ICSSInJSStyle } from "@fluentui/styles";
import { kbDataService, KBStateService } from '../shared/services/kb.service';
import KBCSS from '../kb.module.css';
import { EditorComponent } from "../shared/components/Wysiwyg/Editor";
import { KBData, useSetState } from "../AppState";
import { useSetKBState } from "../AppKBState";

export interface IFormState {
  [inputId: string]: string | string[];
}

interface IEnumerableInputOption {
  title: TTextObject;
  value: string;
}

interface IEnumerableInputBase {
  title: TTextObject;
  options: IEnumerableInputOption[];
  inputId: string;
  optional?: boolean;
}

interface IEnumerableSingletonInputBase extends IEnumerableInputBase {
  initialValue?: string;
}

interface IEnumerableMultipleInputBase extends IEnumerableInputBase {
  initialValues?: string[];
}

interface ITextInputBase {
  title: TTextObject;
  inputId: string;
  optional?: boolean;
  placeholder?: TTextObject;
  initialValue?: string;
}

export type TInputWidth = "split" | "full";

interface IPreparedInput {
  formState: IFormState;
  setFormState: Dispatch<SetStateAction<IFormState>>;
  t: TTranslations;
  errors?: TFormErrors;
}

interface ITextField extends ITextInputBase {
  type: "text";
  width?: TInputWidth;
}

interface IMultilineTextInput extends ITextInputBase {
  type: "multiline-text";
}

interface IPreparedMultilineTextInput
  extends IMultilineTextInput,
  IPreparedInput { }

export type TField = IDropdownInput | IDropdownMultipleInput | ITextField | any;

interface ITextInputs {
  type: "text-inputs";
  fields: TField[];
}

interface IPreparedTextInputs extends ITextInputs, IPreparedInput { }

interface IDropdownInput extends IEnumerableSingletonInputBase {
  type: "dropdown";
  multiple?: false;
  width?: TInputWidth;
}

interface IPreparedDropdownInput extends IDropdownInput, IPreparedInput { }

interface IDropdownMultipleInput extends IEnumerableMultipleInputBase {
  type: "dropdown";
  multiple: true;
  width?: TInputWidth;
}

interface IPreparedDropdownMultipleInput
  extends IDropdownMultipleInput,
  IPreparedInput { }

interface IRadioButtonsInput extends IEnumerableSingletonInputBase {
  type: "radio-buttons";
}

interface IPreparedRadioButtonsInput
  extends IRadioButtonsInput,
  IPreparedInput { }

interface ICheckboxesInput extends IEnumerableMultipleInputBase {
  type: "checkboxes";
}

interface IPreparedCheckboxesInput extends ICheckboxesInput, IPreparedInput { }

type TInputGroup =
  | ITextInputs
  | IMultilineTextInput
  | IDropdownInput
  | IDropdownMultipleInput
  | IRadioButtonsInput
  | ICheckboxesInput;

type TPreparedInputGroup =
  | IPreparedTextInputs
  | IPreparedMultilineTextInput
  | IPreparedDropdownInput
  | IPreparedDropdownMultipleInput
  | IPreparedRadioButtonsInput
  | IPreparedCheckboxesInput
  | any;

interface ISection {
  title?: TTextObject;
  preface?: TTextObject;
  inputGroups?: TInputGroup[];
}

export type TFormErrors = { [inputId: string]: TTextObject };

export type TFormInteraction = {
  event: "submit";
  target: "form";
  formState: IFormState;
};

interface WithOptionalInternalCallbacks<P> {
  __internal_callbacks__?: {
    [callbackId: string]: ComponentEventHandler<P>;
  };
}

export interface IFormProps extends WithOptionalInternalCallbacks<IFormState> {
  headerSection?: ISection;
  sections: ISection[];
  errors?: TFormErrors;
  topError?: TTextObject;
  submit: TTextObject;
  cancel?: TTextObject;
  onInteraction?: (interaction: TFormInteraction) => void;
}

export interface IFormDialogProps extends IFormProps {
  trigger: JSX.Element;
}

interface IFormSectionProps extends IPreparedInput {
  section: ISection;
  header?: false;
  keyPrefix: string;
}

interface IFormHeaderSectionProps {
  section: ISection;
  header: true;
  errors?: TFormErrors;
  t: TTranslations;
}

const MaxWidth = ({ children, styles, flush }: PropsWithChildren<any>) => (
  <Box
    styles={{
      margin: "0 auto",
      maxWidth: flush ? "none" : "29.75rem",
      padding: flush ? 0 : "1.25rem",
      ...styles,
    }}
  >
    {children}
  </Box>
);

interface IErrorMessageProps {
  excludeIcon?: boolean;
  message: TTextObject;
  id?: string;
  t?: TTranslations;
  styles?: ICSSInJSStyle;
}

const errorId = (describesId: string) => `${describesId}__error`;
const labelId = (describesId: string) => `${describesId}__label`;
const fullInputId = (inputId: string) => `input_${inputId}`;

const ErrorMessage = ({
  excludeIcon,
  message,
  id,
  t,
  styles,
}: IErrorMessageProps) => (
  <Box
    variables={({ colorScheme }: SiteVariablesPrepared) => ({
      color: colorScheme.red.foreground,
    })}
    {...(id && { id })}
    styles={{ paddingLeft: ".375rem", ...styles }}
  >
    {!excludeIcon && (
      <ExclamationCircleIcon
        outline
        size="small"
        styles={{ marginRight: ".25rem" }}
      />
    )}
    <Text size="small" content={getText(t?.locale, message)} />
  </Box>
);

const DropdownField = (
  props: IPreparedDropdownInput | IPreparedDropdownMultipleInput
) => {
  const { options, t, inputId, title, errors, formState, setFormState } = props;
  const id = fullInputId(inputId);
  const error = get(errors, inputId, false);
  const selectedValues = Array.isArray(formState[inputId])
    ? formState[inputId]
    : formState[inputId]
      ? [formState[inputId]]
      : [];
  const items = options.map(({ title, value }) => ({
    key: `${inputId}__${value}`,
    selected: selectedValues.includes(value),
    header: getText(t?.locale, title),
    "data-value": value,
  }));
  return (
    <FormDropdown
      fluid
      id={id}
      label={getText(t?.locale, title)}
      styles={{ marginBottom: ".75rem" }}
      onChange={(_e, props) => {
        if (props.multiple) {
          const values = (get(
            props,
            "value",
            []
          ) as DropdownItemProps[]).map(
            (selectedItemProps: DropdownItemProps) =>
              get(selectedItemProps, "data-value")
          );
          values.length
            ? (formState[inputId] = values)
            : delete formState[inputId];
        } else {
          formState[inputId] = get(props, ["value", "data-value"]);
        }
        setFormState(formState);
      }}
      defaultValue={
        props.multiple
          ? items.filter(({ "data-value": value }) =>
            selectedValues.includes(value)
          )
          : items.find(({ "data-value": value }) =>
            selectedValues.includes(value)
          )
      }
      items={items}
      {...(props.multiple && { multiple: true })}
      {...(error && {
        error: true,
        errorMessage: <ErrorMessage message={error} t={t} />,
      })}
    />
  );
};

const splitQuery = (rowSize: number) =>
  `@media (min-width: ${16 * 8.25 * rowSize}px)`;

const textInputStyles = (rowSize: number, group: number) => ({
  flex: "1 0 auto",
  marginRight: ".75rem",
  marginBottom: group === 0 ? ".25rem" : ".75rem",
  width: "100%",
  [splitQuery(rowSize)]: {
    order: group,
    width: `calc(${(100 / rowSize).toFixed(1)}% - .75rem)`,
  },
  ...(group === 0 && { alignSelf: "flex-end" }),
});

const TextInputsGroup = ({
  fields,
  t,
  errors,
  formState,
  setFormState,
}: IPreparedTextInputs) => {

  const rows: TField[][] = [];
  let i = 0;
  while (i < fields.length) {
    switch (fields[i]?.width) {
      case "split":
        let j = i + 1;
        while (fields[j]?.width === "split") {
          j += 1;
        }
        Array.prototype.push.apply(rows, chunk(fields.slice(i, j + 1), 3));
        i = j;
        break;
      default:
        rows.push([fields[i]]);
        i += 1;
        break;
    }
  }

  const setState = useSetKBState();

  const kbAddToEdit = (answer: string, inputId: any) => {
    setState((state) => {
      const newEdittedKB = state.editedKB;
      const findEdittedKB = newEdittedKB.findIndex(kb => kb.id === inputId);
      if (findEdittedKB >= 0) {
        if (newEdittedKB[findEdittedKB].answer === answer) {
          return {
            ...state
          }
        } else {
          let updateValues = update(newEdittedKB[findEdittedKB], { answer: { $set: answer } })
          var newData = update(newEdittedKB, {
            $splice: [[findEdittedKB, 1, updateValues]]
          });

          return {
            ...state,
            editedKB: newData
          }
        }
      } else {
        return {
          ...state,
          editedKB: [
            ...state.editedKB,
            {
              id: inputId,
              answer: answer,
              type: "answer"
            }
          ]
        }

      }
    })
  }

  const kbAddQuestionToEdit = (question: string, inputId: any, dataIndex: number) => {
    setState((state) => {
      const newEdittedKB = state.editedKB;
      const findEdittedKB = newEdittedKB.findIndex(kb => { 
        if(kb.question) {
          return kb.id === inputId && kb.question.index === dataIndex
        }
      });
      if (findEdittedKB >= 0) {
        if (newEdittedKB[findEdittedKB].question) {
          if (newEdittedKB[findEdittedKB].question.index === dataIndex) {
            if (newEdittedKB[findEdittedKB].question.content === question) {
              return {
                ...state
              }
            } else {
              let updateValues = update(newEdittedKB[findEdittedKB], { question: { content: { $set: question } } })
              var newData = update(newEdittedKB, {
                $splice: [[findEdittedKB, 1, updateValues]]
              });

              return {
                ...state,
                editedKB: newData
              }
            }
          } else {
            return {
              ...state,
              editedKB: [
                ...state.editedKB,
                {
                  id: inputId,
                  question: {
                    index: dataIndex,
                    content: question,
                  },
                  type: "question"
                }
              ]
            }
          }
        } else {
          return {
            ...state,
            editedKB: [
              ...state.editedKB,
              {
                id: inputId,
                question: {
                  index: dataIndex,
                  content: question,
                },
                type: "question"
              }
            ]
          }
        }
      } else {
        return {
          ...state,
          editedKB: [
            ...state.editedKB,
            {
              id: inputId,
              question: {
                index: dataIndex,
                content: question,
              },
              type: "question"
            }
          ]
        }

      }
    })
  };

  const kbRemoveQuestion = (dataId: number, dataIndex: number) => {
    setState((state) => {
      let getEdittedKBs = state.editedKB;
      let checkDataIdIndex = getEdittedKBs.findIndex( toupdate => toupdate.id === dataId && toupdate.type === "remove_question");
      
      let kbdataQuestionsLength = state.knowledgeBase.find(kb => kb.id === dataId).questions.length;
      
      
      if(checkDataIdIndex < 0) {
        if(kbdataQuestionsLength > 1) {
          getEdittedKBs.push({
            id: dataId,
            type: "remove_question",
            removedQuestionsIndex: [dataIndex]
          });
        }
        else {
          console.log("error: exceeded")
        }
      } else {
        if(kbdataQuestionsLength > (getEdittedKBs[checkDataIdIndex].removedQuestionsIndex.length + 1)) {
          getEdittedKBs[checkDataIdIndex] = {
            ...getEdittedKBs[checkDataIdIndex],
            removedQuestionsIndex: [...getEdittedKBs[checkDataIdIndex].removedQuestionsIndex, dataIndex]
          };
        }
        else {
          console.log("error: exceeded")
        }
      }
      return {
        ...state,
        editedKB: getEdittedKBs
      }
    })
  }
  return (
    <>
      {rows.map((rowFields, r) => {
        // TODO: row should have a stable field to use as the key, since the key
        // will be incorrect if the rows are shuffled. I've used the index for
        // now since it's more (but not totally) correct than the previous
        // behavior of using a generated id that changed on each render.
        return (
          <FluentUIThemeConsumer
            render={(globalTheme) => (
              <Box
                key={r}
                styles={{
                  display: "flex",
                  flexFlow: "row wrap",
                  [splitQuery(rowFields.length)]: {
                    marginRight: "-.75rem",
                  },
                }}
              >
                {rowFields.map((field) => {
                  const { inputId, title, type, dataId, dataField, dataIndex, source } = field;
                  const id = fullInputId(inputId);
                  const error = get(errors, inputId, false);
                  const theme = globalTheme.siteVariables.theme;
                  var defaultInput = KBCSS.whiteBG;
                  var newInput = KBCSS.grayBG;
                  if (theme === "teamsDarkTheme") {
                    defaultInput = KBCSS.gray2BG
                    newInput = KBCSS.gray3BG;
                  }
                  return (
                    <React.Fragment key={inputId}>
                      <Input.Label
                        htmlFor={id}
                        id={labelId(id)}
                        styles={textInputStyles(rowFields.length, 0)}
                      >
                        {getText(t?.locale, title)}
                      </Input.Label>
                      {(() => {
                        const { placeholder } = field as ITextField;
                        const strValue = formState[inputId]! as string;
                        const kbInputOnChange = (e, props) => {
                          if (props && "value" in props) {
                            formState[inputId] = props.value.toString();
                            kbDataService.setOneData(dataId, dataField, dataIndex, props.value.toString());
                            setFormState(formState);
                          }
                        };

                        const kbEditorOnChange = (value: any) => {
                          formState[inputId] = value;
                          kbDataService.setOneData(dataId, dataField, dataIndex, value);
                          setFormState(formState);
                        }
                        
                        switch (type) {
                          case "dropdown":
                            const { options, multiple } = field as IDropdownInput;
                            const selectedValues = Array.isArray(formState[inputId])
                              ? formState[inputId]
                              : formState[inputId]
                                ? [formState[inputId]]
                                : [];
                            const items = options.map(({ title, value }) => ({
                              key: `${inputId}__${value}`,
                              selected: selectedValues.includes(value),
                              header: getText(t?.locale, title),
                              "data-value": value,
                            }));
                            return (
                              <Dropdown
                                fluid
                                id={id}
                                placeholder={getText(t?.locale, title)}
                                styles={{
                                  ...textInputStyles(rowFields.length, 1),
                                  ...(error && { marginBottom: 0 }),
                                }}
                                onChange={(_e, props) => {
                                  if (props.multiple) {
                                    const values = (get(
                                      props,
                                      "value",
                                      []
                                    ) as DropdownItemProps[]).map(
                                      (selectedItemProps: DropdownItemProps) =>
                                        get(selectedItemProps, "data-value")
                                    );
                                    values.length
                                      ? (formState[inputId] = values)
                                      : delete formState[inputId];
                                  } else {
                                    formState[inputId] = get(props, [
                                      "value",
                                      "data-value",
                                    ]);
                                  }
                                  setFormState(formState);
                                }}
                                defaultValue={
                                  multiple
                                    ? items.filter(({ "data-value": value }) =>
                                      selectedValues.includes(value)
                                    )
                                    : items.find(({ "data-value": value }) =>
                                      selectedValues.includes(value)
                                    )
                                }
                                items={items}
                                {...(multiple ? { multiple: true }: {})}
                                {...(error && { error: true })}
                              />
                            );
                          case "text":
                            return (
                              <Input
                                fluid
                                id={id}
                                {...(placeholder && {
                                  placeholder: getText(t.locale, placeholder),
                                })}
                                {...(error && { error: true })}
                                styles={{
                                  ...textInputStyles(rowFields.length, 1),
                                  ...(error && { marginBottom: 0 }),
                                }}
                                aria-labelledby={[labelId(id)]
                                  .concat(error ? errorId(id) : [])
                                  .join(" ")}
                                value={(strValue !== 'undefined') ? strValue : ''}
                                onChange={(e, props) => {
                                  if (props && "value" in props) {
                                    formState[inputId] = props.value.toString();
                                    setFormState(formState);
                                  }
                                }}
                              />
                            );
                          case "kbaddquestion":                            
                            return (
                              <>
                              <Button icon={<AddIcon />} text content="Add alternative phrasing"
                                style={{
                                  color: globalTheme.siteVariables.colorScheme.brand.foreground,
                                }}
                                {...{
                                  onClick: (e) => {
                                    // kbDataService.addOneQuestion(dataId);
                                    setState((state) => {
                                      const newState = KBStateService.addOneQuestion(dataId, state.knowledgeBase);
                                      return {
                                        ...state,
                                        knowledgeBase: newState,
                                        tab: 0
                                      }
                                    })
                                  },
                                }}
                              />
                              </>
                            );
                          case "kbanswer":
                            return (
                              <>
                                <EditorComponent
                                  markdownString={strValue}
                                  onChange={(data: any) => {
                                    kbAddToEdit(data, dataId);
                                  }}
                                  maxLength={25000}
                                  useBorder={true}
                                />
                              </>
                            );
                          case "kbquestion":
                            var fieldWidth = (strValue == undefined || strValue.length < 34) ? 290 : (strValue.length * 8.5);
                            return (
                              <Flex gap="gap.small"
                                style={
                                  {
                                    width: fieldWidth + "px",
                                    maxWidth: "90%",
                                    minWidth: "180px"
                                  }
                                }
                              >
                                <FlexItem>
                                  <>
                                    <Input
                                      fluid
                                      inverted
                                      id={id}
                                      {...(placeholder && {
                                        placeholder: getText(t.locale, placeholder),
                                      })}
                                      {...(error && { error: true })}
                                      aria-labelledby={[labelId(id)]
                                        .concat(error ? errorId(id) : [])
                                        .join(" ")}
                                      defaultValue={strValue}
                                      placeholder="Enter a question"
                                      onChange={(e, p) => {
                                        kbAddQuestionToEdit(p.value, dataId, dataIndex)
                                      }}
                                      onBlur={(e) => {
                                        if (strValue == undefined || strValue.length == 0) {
                                          // e!.currentTarget.parentElement.parentElement.parentElement.remove();
                                          // kbDataService.removeOneQuestion(rowKey, dataIndex);
                                          kbRemoveQuestion(dataId, dataIndex)
                                        }
                                      }}
                                      onKeyPress={(e: any) => {
                                        if (e.charCode === 13)
                                          e.currentTarget.blur();
                                      }}
                                      input={{
                                        styles: {
                                          backgroundColor: "var(--surface-background2)",
                                        },
                                      }}
                                      className={source == "Editorial" ? defaultInput : newInput}
                                    />
                                  </>
                                </FlexItem>
                                <FlexItem push>
                                  <Button icon={<CloseIcon />} text iconOnly title="Remove"
                                    onClick={
                                      (e: any) => {
                                        // 0 a return event occured, and some type of click event occured
                                        // https://github.com/facebook/react/issues/3907#issuecomment-363948471
                                        if (e.detail !== 0) {
                                          e!.currentTarget.parentElement.parentElement.remove();
                                          kbRemoveQuestion(dataId, dataIndex)
                                          // kbDataService.removeOneQuestion(dataId, dataIndex);
                                          // kbRemoveQuestion(dataId, dataIndex)
                                        }
                                      }
                                    }
                                    styles={
                                      {
                                        alignSelf: "center",
                                      }
                                    }
                                  />
                                </FlexItem>

                              </Flex>
                            );
                          default:
                            return null;
                        }
                      })()}
                      {error ? (
                        <ErrorMessage
                          message={error}
                          t={t}
                          id={errorId(id)}
                          styles={textInputStyles(rowFields.length, 2)}
                        />
                      ) : (
                        <Box
                          styles={{
                            ...textInputStyles(rowFields.length, 2),
                            marginBottom: 0,
                          }}
                        />
                      )}
                    </React.Fragment>
                  );
                })}
              </Box>
            )} />
        );
      })}
    </>
  );
};

const CheckboxesGroup = ({
  options,
  title,
  t,
  inputId,
  errors,
  formState,
  setFormState,
}: IPreparedCheckboxesInput) => {
  const id = fullInputId(inputId);
  const error = get(errors, inputId, false);
  return (
    <Box styles={{ marginBottom: ".75rem" }}>
      <Input.Label htmlFor={id} id={labelId(id)}>
        {getText(t?.locale, title)}
      </Input.Label>
      <Box
        id={id}
        aria-labelledby={[labelId(id)]
          .concat(error ? errorId(id) : [])
          .join(" ")}
        aria-multiselectable="true"
      >
        {options.map(({ title, value }) => {
          const selected = formState[inputId]?.includes(value);
          return (
            <Box key={`${id}__${value}`}>
              <Checkbox
                role="option"
                aria-selected={selected ? "true" : "false"}
                checked={selected}
                variables={{ layout: "radio-like" }}
                label={getText(t?.locale, title)}
                data-value={value}
                onChange={(e, props) => {
                  const value = get(props, "data-value");
                  if (props?.checked) {
                    Array.isArray(formState[inputId])
                      ? (formState[inputId] as string[]).push(value)
                      : (formState[inputId] = [value]);
                  } else {
                    const next_values = (formState[inputId] as string[]).filter(
                      (v) => v !== value
                    );
                    next_values.length > 0
                      ? (formState[inputId] = next_values)
                      : delete formState[inputId];
                  }
                  setFormState(formState);
                }}
              />
            </Box>
          );
        })}
      </Box>
      {error && <ErrorMessage message={error} t={t} id={errorId(id)} />}
    </Box>
  );
};

const MultilineTextGroup = ({
  title,
  placeholder,
  t,
  inputId,
  errors,
  formState,
  setFormState,
}: IPreparedMultilineTextInput) => {
  const id = fullInputId(inputId);
  const error = get(errors, inputId, false);
  const strValue = formState[inputId]! as string || "";
  var fieldWidth = (strValue == undefined || strValue.length == 0) ? 50 : strValue.length + 20;
  fieldWidth = (fieldWidth > 98) ? 98 : fieldWidth;
  return (
    <>
      <Flex gap="gap.small"
        style={
          {
            width: fieldWidth + "%",
          }
        }>
        <Flex>
          <Input.Label htmlFor={id} id={labelId(id)}>
            {getText(t?.locale, title)}
          </Input.Label>
          <TextArea
            fluid
            resize="both"
            id={id}
            value={(formState[inputId] as string) || ""}
            {...(placeholder && { placeholder: getText(t?.locale, placeholder) })}
            onChange={(e, props) => {
              props && props.value
                ? (formState[inputId] = props.value)
                : delete formState[inputId];
              setFormState(formState);
            }}
            styles={{
              backgroundColor: "var(--surface-background2)",
              height: "auto",
              paddingBottom: "0",
            }}
          />
          {error && <ErrorMessage message={error} t={t} />}
        </Flex>
        <Flex>
          <CloseIcon
            {...{
              onClick: (e) => {
                e!.currentTarget.parentElement.parentElement.parentElement.remove();
              },
            }}
            styles={
              {
                alignSelf: "center",
              }
            }
          />
        </Flex>
      </Flex>
    </>
  );
};

const RadioButtonsGroup = ({
  options,
  t,
  inputId,
  title,
  errors,
  formState,
  setFormState,
}: IPreparedRadioButtonsInput) => {
  const id = fullInputId(inputId);
  const error = get(errors, inputId, false);
  return (
    <FormRadioGroup
      id={id}
      vertical
      styles={{ marginBottom: ".75rem" }}
      label={getText(t?.locale, title)}
      {...(error && { errorMessage: <ErrorMessage message={error} t={t} /> })}
      items={options.map(({ title, value }) => {
        const label = getText(t?.locale, title);
        const key = `${inputId}__${value}`;
        const name = label;
        const checked = formState[inputId] === value;
        const onChange = (
          _e: SyntheticEvent<HTMLElement, Event>,
          props: RadioGroupItemProps | undefined
        ) => {
          if (props && props.checked && props.value)
            formState[inputId] = props.value.toString();
          setFormState(formState);
        };
        return { key, value, label, name, checked, onChange };
      })}
    />

  );
};

const FormInputGroup = (props: TPreparedInputGroup) => {
  switch (props.type) {
    case "text-inputs":
      return <TextInputsGroup {...props} />;
    case "multiline-text":
      return <MultilineTextGroup {...props} />;
    case "dropdown":
      return <DropdownField {...props} />;
    case "checkboxes":
      return <CheckboxesGroup {...props} />;
    case "radio-buttons":
      return <RadioButtonsGroup {...props} />;
    default:
      return null;
  }
};

const FormSection = (props: IFormSectionProps | IFormHeaderSectionProps) => {
  const { errors, header, section, t } = props;
  return (
    <>
      {section.title && (
        <Text
          as={header ? "h1" : "h2"}
          weight={header ? "bold" : "semibold"}
          size={header ? "large" : "medium"}
        >
          {getText(t.locale, section.title)}
        </Text>
      )}
      {section.preface && (
        <Text as="p">{getText(t.locale, section.preface)}</Text>
      )}
      {section.inputGroups?.length &&
        section.inputGroups.map((inputGroup, gi) => (
          <FormInputGroup
            {...inputGroup}
            {...{
              t,
              errors,
              formState: (props as IFormSectionProps).formState,
              setFormState: (props as IFormSectionProps).setFormState,
            }}
            key={`${(props as IFormSectionProps).keyPrefix}__Group-${gi}`}
          />
        ))}
    </>
  );
};

const setInitialValue = (
  acc: IFormState,
  field:
    | TField
    | IMultilineTextInput
    | IDropdownInput
    | IDropdownMultipleInput
    | IRadioButtonsInput
    | ICheckboxesInput
) => {
  if (
    field.hasOwnProperty("initialValue") &&
    (field as
      | ITextField
      | IMultilineTextInput
      | IDropdownInput
      | IRadioButtonsInput).initialValue
  )
    acc[field.inputId] = (field as
      | ITextField
      | IDropdownInput
      | IRadioButtonsInput).initialValue!;
  else if (field.hasOwnProperty("initialValues"))
    acc[field.inputId] =
      (field as IDropdownMultipleInput | ICheckboxesInput).initialValues || [];
  return acc;
};

const initialFormState = (sections: ISection[]) => {
  return sections.reduce(
    (acc_i: IFormState, { inputGroups }) =>
      inputGroups
        ? inputGroups.reduce((acc_j: IFormState, inputGroup) => {
          if (!inputGroup) return acc_j;
          switch (inputGroup.type) {
            case "text-inputs":
              return (inputGroup as ITextInputs).fields.reduce(
                setInitialValue,
                acc_j
              );
            default:
              return setInitialValue(acc_j, inputGroup);
          }
        }, acc_i)
        : acc_i,
    {}
  );
};

interface IFormContentProps extends Omit<IFormProps, "submit"> {
  formState: IFormState;
  setFormState: Dispatch<SetStateAction<IFormState>>;
  t: TTranslations;
  flush?: boolean;
}

const FormContent = React.memo(
  ({
    topError,
    flush,
    t,
    headerSection,
    sections,
    errors,
    formState,
    setFormState,
  }: IFormContentProps) => {
    return (
      <Box>
        {topError && (
          <Alert
            danger
            visible
            dismissible
            content={
              <Flex vAlign="center">
                <ExclamationTriangleIcon
                  outline
                  styles={{ marginRight: ".25rem" }}
                />
                <Text
                  styles={{ margin: ".25rem 0" }}
                  content={getText(t.locale, topError)}
                />
              </Flex>
            }
          />
        )}
        {headerSection && (
          <FormSection header section={headerSection} {...{ t, errors }} />
        )}
        {sections.map((section, si) => {
          const key = `Form__Section-${si}`;
          return (
            <FormSection
              {...{
                section,
                t,
                key,
                keyPrefix: key,
                errors,
                formState,
                setFormState,
              }}
            />
          );
        })}
      </Box>
    );
  }
);

export const KBForm = ({
  cancel,
  errors,
  headerSection,
  sections,
  submit,
  topError,
  onInteraction,
}: IFormProps) => {
  const [formState, setUnclonedFormState] = useState<IFormState>(() =>
    initialFormState(sections)
  );

  const setFormState = (formState: SetStateAction<IFormState>) =>
    setUnclonedFormState(clone(formState));

  return (
    <FluentUIThemeConsumer
      render={(globalTheme) => {
        const { t } = globalTheme.siteVariables;
        return (
          <FormTheme globalTheme={globalTheme} surface={Surface.base}>
            <FluentUIForm
              styles={{
                display: "block",
                "& > *:not(:last-child)": { marginBottom: 0 },
                "& > :last-child": { marginTop: 0 },
                backgroundColor: "var(--surface-background2)",
              }}
              {...(onInteraction && {
                onSubmit: () =>
                  onInteraction({
                    event: "submit",
                    target: "form",
                    formState,
                  }),
              })}
            >
              <FormContent
                {...{
                  headerSection,
                  sections,
                  topError,
                  errors,
                  t,
                  formState,
                  setFormState,
                }}
              />
              {cancel || submit && (
                <Box
                  styles={{
                    backgroundColor: "var(--surface-background)",
                    height: "1px",
                    position: "absolute",
                    left: 0,
                    right: 0,
                    zIndex: 1,
                  }}
                />
              )}
              {cancel || submit && (
                <Box
                  styles={{
                    backgroundColor: "var(--shadow-background)",
                    height: "1px",
                    position: "sticky",
                    bottom: "4.5rem",
                  }}
                />
              )}
              {cancel || submit && (
                <Box
                  styles={{
                    backgroundColor: "var(--surface-background)",
                    position: "sticky",
                    bottom: 0,
                    height: "4.5rem",
                    zIndex: 2,
                  }}
                >
                  <MaxWidth
                    styles={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {cancel && (
                      <Button
                        content={getText(t.locale, cancel)}
                        styles={{ marginRight: ".5rem" }}
                      />
                    )}
                    {submit && (
                      <Button
                        primary
                        type="submit"
                        content={getText(t.locale, submit)}
                      />
                    )}
                  </MaxWidth>
                </Box>
              )}
            </FluentUIForm>
          </FormTheme>
        );
      }}
    />
  );
};

export const KBFormDialog = ({
  cancel,
  errors,
  headerSection,
  sections,
  submit,
  topError,
  trigger,
  onInteraction,
}: IFormDialogProps) => {
  const [formState, setUnclonedFormState] = useState<IFormState>(
    initialFormState(sections)
  );

  const setFormState = (formState: SetStateAction<IFormState>) =>
    setUnclonedFormState(clone(formState));

  return (
    <Dialog
      trigger={trigger}
      trapFocus
      content={
        <FluentUIThemeConsumer
          render={(globalTheme) => {
            const { t } = globalTheme.siteVariables;
            return (
              <FormTheme globalTheme={globalTheme} surface={Surface.raised}>
                <FluentUIForm
                  styles={{
                    display: "block",
                    backgroundColor: "var(--surface-background)",
                  }}
                >
                  <FormContent
                    flush
                    {...{
                      headerSection,
                      sections,
                      topError,
                      errors,
                      t,
                      formState,
                      setFormState,
                    }}
                  />
                </FluentUIForm>
              </FormTheme>
            );
          }}
        />
      }
      confirmButton={{
        content: submit,
        ...(onInteraction && {
          onClick: () =>
            onInteraction({
              event: "submit",
              target: "form",
              formState,
            }),
        }),
      }}
      cancelButton={{
        content: cancel,
      }}
    />
  );
};
