import React, { useState, useEffect, useCallback } from 'react';
import { Dropdown, FormLabel, Flex, Text, ProviderConsumer } from '@fluentui/react-northstar';
import Styles from '../Automation.module.css';
import { platformService } from '../../shared/services/platform.service';
import {
  AUTOMATION_CREATE,
  AUTOMATION_TRIGGER_TYPES,
  LICENSE_FEATURE,
  AUTOMATION_LABELS,
  AUTOMATION_SCHEDULE_FREQUENCY,
  ENTITY_TYPE,
  DEFAULT_AUTOMATION_GROUP,
  DEFAULT_AUTOMATION_FILTER,
  AutomationToggleStyle
} from '../../shared/utils/constants';
import { toTitleCase } from '../../shared/utils/helper';
import { getCachedFeature } from '../../shared/cache/FeatureCache';
import { Toggle } from '@fluentui/react';
import { CheckFeature } from '../../shared/components/CheckFeature';
import { useTranslation } from 'react-i18next';

interface Props {
  whenData: AutomationWhenData;
  updateWhenData: (data: AutomationWhenData) => void;
  updateIfGroupData: (data: AutomationGroup) => void;
  updateIfChildGroupData: (data: AutomationChildrenGroup[]) => void;
  doData: AutomationDoTickets;
  updateDoData: (data: AutomationDoTickets) => void;
}

export const When = ({
  whenData,
  whenData: { entityName, triggerType, onEvent, onSchedule, scheduleFrequency },
  updateWhenData,
  updateIfGroupData,
  updateIfChildGroupData,
  doData,
  updateDoData
}: Props) => {
  const {t} = useTranslation();
  const api = new platformService();


  const [entityNamesList, setEntityNamesList] = useState<DropdownDataModel[]>(
    []
  );

  const fetchEntityNamesList = useCallback(async () => {
    try {
      const {
        data: { value }
      } = await api.getTriggerApplicableTypes();

      const entityNamesList: DropdownDataModel[] = value.map((val: string) => ({
        key: val,
        id: val,
        label: getEntityNameLabel(val),
        header: getEntityNameLabel(val)
      }));

      setEntityNamesList(entityNamesList);
    } catch (err) {
      console.error(err);
    }
  }, []);
  const loadPage = () => {
    fetchEntityNamesList();
  }

  const getEntityNameLabel = (entityName:string) => t(`automation.automation-triggers.${(entityName ?? ENTITY_TYPE.Ticket).split('.').pop()}`) ?? t(`automation.automation-triggers.Ticket`);

  const [triggerTypes, setTriggerTypes] = useState({});
  useEffect(() => {
    loadPage();

    (async () => {
      let enableFeature = false;
      try {
        enableFeature = await getCachedFeature(LICENSE_FEATURE.ScheduledAutomation);
      } catch { }
      if (!enableFeature && onSchedule)
        updateWhenData({
          ...whenData,
          onSchedule: false,
          onEvent: true
        });
      setTriggerTypes(AUTOMATION_TRIGGER_TYPES.map(o => {
        if (!enableFeature && o.key == "Scheduled") {
          o["disabled"] = true;
          o["content"] = AUTOMATION_LABELS.FEATURE_RESTRICTED;
          o["className"] = Styles.DisabledDropdownItem;
        }
      }
      ));
    })();
  }, []);

  useEffect(()=>{
    if ((entityName==ENTITY_TYPE.Approval || entityName==ENTITY_TYPE.Task) && triggerType=="Deleted") 
    {
      updateWhenData({
        ...whenData,
        triggerType: "Added"
      });
    }
  },[entityName]);

  const updateTriggerToggle = (type: string, checked: boolean) => {
    updateWhenData({
      ...whenData,
      onSchedule: (type == 'schedule') ? checked : !checked,
      onEvent: (type == 'event') ? checked : !checked
    });
  }

  const resetIfAndCardSelection=()=>{
    DEFAULT_AUTOMATION_GROUP.filters=[DEFAULT_AUTOMATION_FILTER];
     updateIfGroupData(DEFAULT_AUTOMATION_GROUP);
     updateIfChildGroupData([]);
    updateDoData({
      ...doData,
      actionNotification: {
        ...doData?.actionNotification,
        CardNameTVA: "AutomationNotificationCard",
        CardNameTikit: "AutomationNotificationCard"
      }
    });
  }

  return (
    <ProviderConsumer render={globalTheme =>
      <>
        <div className={Styles.WhenSection}>
          <h2 className={Styles.SubHeading2}>{t('automation.when-text')}</h2>

          <CheckFeature featureName={LICENSE_FEATURE.ScheduledAutomation}>
            <div className={Styles.marginY}>
              <div>
                <Flex>
                  {onSchedule && (
                    <Toggle
                      defaultChecked={true}
                      style={AutomationToggleStyle.Enabled}
                      onChange={(_ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                        updateTriggerToggle('schedule', checked);
                      }}
                    />
                  )}
                  {!onSchedule && (
                    <Toggle
                    style={AutomationToggleStyle.Disabled}
                      onChange={(_ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                        updateTriggerToggle('schedule', checked);
                      }}
                    />
                  )}
                  <Text content={AUTOMATION_CREATE.WHEN.SCHEDULE} className="mr-2" color="brand" />
                </Flex>
              </div>
              <div className={Styles.DropdownContainer}>
                <div>
                  <FormLabel
                    content={toTitleCase(AUTOMATION_CREATE.WHEN.TRIGGER)}
                    styles={{ fontSize: '12px' }}
                  />
                  <Dropdown
                    disabled={!onSchedule}
                    inverted
                    fluid
                    items={AUTOMATION_SCHEDULE_FREQUENCY}
                    placeholder={toTitleCase(
                      `select ${AUTOMATION_CREATE.WHEN.TRIGGER}`
                    )}
                    value={AUTOMATION_SCHEDULE_FREQUENCY.filter(x => x.value == scheduleFrequency)}
                    onChange={(e, p) => {
                      const data = p.value as DropdownDataModel;
                      updateWhenData({
                        ...whenData,
                        scheduleFrequency: data.value
                      });
                    }}

                    style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
                  />
                </div>
              </div>
            </div>
          </CheckFeature>
          <div className={Styles.marginY}>
            <CheckFeature featureName={LICENSE_FEATURE.ScheduledAutomation}>
              <div>
                <Flex>
                  {onEvent && (
                    <Toggle
                      defaultChecked={true}
                      style={AutomationToggleStyle.Enabled}
                      onChange={(_ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                        updateTriggerToggle('event', checked);
                      }}
                    />
                  )}
                  {!onEvent && (
                    <Toggle
                    style={AutomationToggleStyle.Disabled}
                      onChange={(_ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
                        updateTriggerToggle('event', checked);
                      }}
                    />
                  )}
                  <Text content={AUTOMATION_CREATE.WHEN.EVENT} className="mr-2" color="brand" />
                </Flex>
              </div>
            </CheckFeature>
            <div className={Styles.DropdownContainer}>
              <div>
                <FormLabel
                  content={toTitleCase(AUTOMATION_CREATE.WHEN.ENTITY_NAME)}
                  styles={{ fontSize: '12px' }}
                />
                <Dropdown
                  disabled={!onEvent}
                  inverted
                  fluid
                  items={entityNamesList}
                  placeholder={toTitleCase(
                    `select ${AUTOMATION_CREATE.WHEN.ENTITY_NAME}`
                  )}
                  value={getEntityNameLabel(entityName)}
                  onChange={(e, p) => {
                    const data = p.value as DropdownDataModel;
                    updateWhenData({
                      ...whenData,
                      entityName: data.key
                    });

                    resetIfAndCardSelection();
                  }}
                  style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
                />
              </div>

              <div>
                <FormLabel
                  content={toTitleCase(AUTOMATION_CREATE.WHEN.TRIGGER_TYPE)}
                  styles={{ fontSize: '12px' }}
                />
                <Dropdown
                  disabled={!onEvent}
                  inverted
                  fluid
                  items={AUTOMATION_TRIGGER_TYPES.filter(x=>!(x.key=='Deleted' && (entityName==ENTITY_TYPE.Approval || entityName==ENTITY_TYPE.SLA || entityName==ENTITY_TYPE.Lifecycle)))}
                  placeholder={toTitleCase(
                    `select ${AUTOMATION_CREATE.WHEN.TRIGGER_TYPE}`
                  )}
                  style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
                  value={AUTOMATION_TRIGGER_TYPES.filter(o => o.key === triggerType)[0].label }
                  onChange={(e, p) => {
                    const data = p.value as DropdownDataModel;
                    updateWhenData({
                      ...whenData,
                      triggerType: data.key
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    } />
  );
};
