import React, { useEffect, useState } from 'react';
import './HelpCardEditor.css';
import { DropdownItemProps, Flex, Dropdown, MenuButton, Button, Dialog, ProviderConsumer, Input, Text } from '@fluentui/react-northstar';
import { themeNames } from '@fluentui/react-teams';
import { clone } from 'lodash';
import { useSetState, appState, KBData } from '../../../AppState';
import { ThemeColorScheme } from '../../common/TeamsTheme';
import { kbDataService } from '../../services/kb.service';
import { platformService } from '../../services/platform.service';
import { CustomHelpCardConstants, FILTERS_STRINGS, BUTTONS, LICENSE_FEATURE } from '../../utils/constants';
import { toTitleCase } from '../../utils/helper';
import { LoadingScreen } from '../LoadingScreen';
import { LabelElement, getBackgroundColor } from '../TicketForm';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Editor } from 'react-draft-wysiwyg';
import AddButtonSVG from '../../../../svg/addButton.svg';
import ResetDefaultSVG from '../../../../svg/resetDefault.svg';
import {
    EditorState,
    convertToRaw,
    ContentState,
    Modifier,
    Entity,
    SelectionState
  } from 'draft-js';
import { HtmlProps } from './Editor';
import { getCachedFeature } from '../../cache/FeatureCache';

interface HelpCardEditorState {
    editorState: any;
    openDialog?: boolean;
    editedData?: any;
    editedBeforeElement?: HTMLAnchorElement[];
    editedAfterElement?: HTMLAnchorElement[];
}

interface CustomButtonData {
    Button: string;
    ButtonIcon?: string;
    ButtonId?: string;
    DisplayName: string;
    Data?: string;
    DataId?: string;
  }
  
  interface CustomButtonProps {
    globalTheme: any;
    editorState: any;
    handleCustomAction?: (editorState: any, key: string, embedData?: string) => void;
    openEditDialog?: boolean;
    editData?: CustomButtonData;
    handleCustomEditConfirm?: (editorState: any, editedData: CustomButtonData) => void;
    handleCustomEditCancel?: (editorState: any) => void;
  }
  
  export const HelpCardButtons = ({
    globalTheme,
    editorState,
    handleCustomAction,
    openEditDialog,
    editData,
    handleCustomEditConfirm,
    handleCustomEditCancel 
  }: CustomButtonProps) => {
  
    const menuItemsAll = [
      {
        key: CustomHelpCardConstants.Button_KnowledgeArticle,
        content: CustomHelpCardConstants.Button_KnowledgeArticle_Display,
        value: CustomHelpCardConstants.Button_KnowledgeArticle,
        description: CustomHelpCardConstants.Button_KnowledgeArticle_Description
      },
      {
        key: CustomHelpCardConstants.Button_NewTicket,
        content: CustomHelpCardConstants.Button_NewTicket_Display,
        value: CustomHelpCardConstants.Button_NewTicket,
        description: CustomHelpCardConstants.Button_NewTicket_Description
      },
      {
        key: CustomHelpCardConstants.Button_MyRequests,
        content: CustomHelpCardConstants.Button_MyRequests_Display,
        value: CustomHelpCardConstants.Button_MyRequests,
        description: CustomHelpCardConstants.Button_MyRequests_Description
      },
      {
        key: CustomHelpCardConstants.Button_Template,
        content: CustomHelpCardConstants.Button_Template_Display,
        value: CustomHelpCardConstants.Button_Template,
        description: CustomHelpCardConstants.Button_Template_Description
      },
      {
        key: CustomHelpCardConstants.Button_OpenURL,
        content: CustomHelpCardConstants.Button_OpenURL_Display,
        value: CustomHelpCardConstants.Button_OpenURL,
        description: CustomHelpCardConstants.Button_OpenURL_Description
      }
    ];
    
    const setState = useSetState();
    const currentState = appState();
    const api = new platformService();
    const [isOpen, setIsOpen] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisableSave, setIsDisableSave] = useState(true);
    const [showErrorUrl, setShowErrorUrl] = useState(false);
    const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [buttonName, setButtonName] = useState("");
    const [buttonData, setButtonData] = useState("");
    const [buttonDataId, setButtonDataId] = useState("");
    const [currSelect, setCurrSelect] = useState();
    const [templates, setTemplates] = useState<Array<DropdownItemProps>>([]);
    const [kbList, setKBList] = useState<Array<DropdownItemProps>>([]);
    const [kbListFullProps, setKBListFullProps] = useState<Array<KBData>>([]);
    const [teams, setTeams] = useState<Array<DropdownItemProps>>([]);
    const [defaultTeam, setDefaulTeam] = useState(currentState?.defaultTeam?.Id ?? null);
    const [enabledMultiDepartment, setEnabledMultiDepartment] = useState(false);
    const [menuItems, setMenuItems] = useState(menuItemsAll);
  
    const getDropdownItems = (itemId, itemValue) => {
      return {
        accessibilityItemProps: {
          id: itemId,
          answerValue: itemValue
        },
        header: itemValue,
        selected: false
      } as DropdownItemProps;
    }
  
    const fetchButtonData = async () => {
      try {
        if(!isDataFetched)
          setIsLoading(true);
        const getTeamsFromState = () => currentState.teams;
        const getTeamsFromAPI = async () => (await api.getTeamsSafe(FILTERS_STRINGS.TICKET_TEAMS));
        const results = await Promise.allSettled([
          (await api.getTicketTemplates(`${FILTERS_STRINGS.FILTERS_URL_PARAMS} and NoCustomForm eq false and CardResponseJson ne '' and CardResponseJson ne null`)),
          (currentState?.teams.length > 0) ? Promise.resolve(getTeamsFromState()) : getTeamsFromAPI(),
          (currentState?.defaultTeam?.Id) ? [currentState?.defaultTeam] : api.getTeamsSafe(FILTERS_STRINGS.IS_DEFAULT),
          (await kbDataService.fetchAPI()),
          getCachedFeature(LICENSE_FEATURE.MultiDepartment),
          getCachedFeature(LICENSE_FEATURE.TicketDeflection),
          getCachedFeature(LICENSE_FEATURE.TemplateDeflection)
        ]);

        const thisEnabledMultiDepartment = (results[4].status == "fulfilled") ? results[4].value : false;
        setEnabledMultiDepartment(thisEnabledMultiDepartment);
        const thisEnabledTicketDeflection = (results[5].status == "fulfilled") ? results[5].value : false;
        const thisEnabledTemplateDeflection = (results[6].status == "fulfilled") ? results[6].value : false;

        setMenuItems(prev => {
          let items = prev;
          if(!thisEnabledTemplateDeflection)
            items = items.filter(i => i.key != CustomHelpCardConstants.Button_Template);
          if(!thisEnabledTicketDeflection)
            items = items.filter(i => i.key != CustomHelpCardConstants.Button_KnowledgeArticle);
          return items;
        });
        
        if(results[3].status == "fulfilled"){
          const kbValues = JSON.parse(results[3].value.data.value);
          const kbDropdownValues = kbValues.map(kb => {
            return getDropdownItems(kb.id, kb.questions[0]);
          });
          setKBList(kbDropdownValues);
          setKBListFullProps(kbValues);
        }
  
        if(results[0].status == "fulfilled"){
          const templates = results[0].value.data.value.map(item => {
            return getDropdownItems(item.Id, item.Name);
          });
          setTemplates(templates);
        }

        if(results[1].status == "fulfilled" && results[2].status == "fulfilled"){
  
          const allTeams = results[1].value;
          const defaultTeam = results[2].value[0];
    
          setState(prevState => {
            prevState.teams = allTeams
            prevState.defaultTeam = defaultTeam
            return prevState;
          });
    
          const teams = allTeams.map(item => {
            return getDropdownItems(item.Id, item.FriendlyName);
          });
          setTeams(teams);
    
          setDefaulTeam(defaultTeam?.Id ?? null);
        }
        
        setIsDataFetched(true);
        setIsLoading(false);
        return true;
      } catch {
        console.log("Error fetching items...");
        setIsDataFetched(false);
        setIsLoading(false);
        return false;
      }
    }

    useEffect(() => {
      fetchButtonData();
    }, [])
  
    useEffect(() => {
      const fetchData = async () => {
        setIsDisableSave(true);
        const index = menuItems.findIndex(m => m.value == editData.Button);
        if(index == -1)
          handleCustomEditCancel(editorState);
        else {
          setSelectedMenuIndex(index);

          setButtonName(editData.DisplayName);
          setButtonData(editData.Data);
          setButtonDataId(editData.DataId);
    
          if(menuItems[index].key == CustomHelpCardConstants.Button_Template 
            || menuItems[index].key == CustomHelpCardConstants.Button_KnowledgeArticle 
            || menuItems[index].key == CustomHelpCardConstants.Button_NewTicket)
            setSearchText(editData.Data);
    
          setCurrSelect(editorState.getSelection());
          
          setIsOpen(openEditDialog);
          const done = isDataFetched || await fetchButtonData();
          if(!done)
            setIsOpen(false);
        }
      }
      if(openEditDialog)
        fetchData().catch(console.error);
    }, [openEditDialog])
  
    useEffect(() => {
      switch (menuItems[selectedMenuIndex].key){
        case CustomHelpCardConstants.Button_MyRequests:
          setIsDisableSave(buttonName.length == 0);
          break;
        case CustomHelpCardConstants.Button_OpenURL:
          const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
          '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
          const valid: boolean = !!urlPattern.test(buttonData);
          if(buttonData?.length > 0)
            setShowErrorUrl(!valid);
          setIsDisableSave(!valid || buttonName.length == 0);
          break;
        default:
          setIsDisableSave(buttonName.length == 0 || buttonData?.length == 0);
          break;
      }
    }, [buttonName, buttonData]);

    const setCustomButtonIcon = (data: CustomButtonData) => {
      if(data.Button == CustomHelpCardConstants.Button_KnowledgeArticle && data.DataId.length > 0){
        const kb = kbListFullProps.find(kb => kb.id == parseInt(data.DataId));
        if(kb.answer.match(/^ApplyTemplate\:\d+$/gi))
          data.ButtonIcon = CustomHelpCardConstants.Button_Template;
        else
          delete data.ButtonIcon;
      }

      return data;
    }
  
    const insertButtonToken = () => {
      let displayData = `{{Button: ${menuItems[selectedMenuIndex].value}, DisplayName: ${buttonName}`;
      let embedData: CustomButtonData = { Button: menuItems[selectedMenuIndex].value, ButtonId: Date.now().toString(), DisplayName: buttonName};
      if(buttonData?.length > 0){
        embedData.Data = buttonData;
        if(buttonDataId.length > 0)
          embedData.DataId = buttonDataId;
        displayData = `${displayData}, Data: ${buttonData}`;

        embedData = setCustomButtonIcon(embedData);
      }
      displayData = `${displayData}}}`;
      const embedDataStr = encodeURI(JSON.stringify(embedData));
  
      const currentContent = editorState.getCurrentContent(); 
      const selection = editorState.getSelection(); 
      const entityKey = Entity.create('MENTION', 'IMMUTABLE', {"text":`${displayData}`,"value":`${embedDataStr}`,"url":`${embedDataStr}`}); 
      const textWithEntity = Modifier.insertText(currentContent, selection, `${buttonName}`, null, entityKey); 
      let newState = EditorState.push(editorState, textWithEntity, 'insert-characters');
      const newContentState = Modifier.replaceText(
        newState.getCurrentContent(),
        newState.getSelection(),
        " "
      );
      handleCustomAction(EditorState.push(newState, newContentState, 'insert-characters'), displayData, embedDataStr);
    };
  
    const onMenuChange = async(e, data) =>{
      setSelectedMenuIndex(data.index);
      const selectedMenu = menuItems[data.index];
      setIsOpen(true);
      const done = isDataFetched || await fetchButtonData();
      if(done && selectedMenu.key == CustomHelpCardConstants.Button_NewTicket&& teams?.length > 0 && defaultTeam){
        const val = teams.find(team => team.accessibilityItemProps.id == defaultTeam)?.accessibilityItemProps.answerValue ?? "";
        setSearchText(val);
        setButtonData(val);
      }
      if(!done)
        setIsOpen(false);
    }
  
    const nameSection = <>
      <LabelElement label={CustomHelpCardConstants.Name_Label} required />
      <div className="teams-input-med">
        <Input
          onChange={e => {
            const val = (e.target as HTMLInputElement).value;
            setButtonName(val);
          }}
          fluid
          value={buttonName}
          id="editor-custom-focus-input"
        />
      </div>
    </>;
  
    const descriptionSection = <>
      <Flex className='mb-3'>
        <Text content={menuItems[selectedMenuIndex].description} /> 
      </Flex>
    </>;
  
    const getDropdownElement = (label, items) => {
      return <>
      <LabelElement label={label} required />
      <div className="teams-input-med">
        <Dropdown fluid search items={items} inverted clearable
        searchQuery={searchText}
        onSearchQueryChange={(_e, data) => {
          setSearchText(data.searchQuery);
        }}
        onChange={(e, p) => {
  
          if (!p.value && setSearchText)
            setSearchText("");
  
          const answer = p.value ? p.value['accessibilityItemProps'].answerValue : '';
          const answerId = p.value ? p.value['accessibilityItemProps'].id : 0;
  
          setButtonData(answer);
          setButtonDataId(`${answerId}`);
        }} 
        noResultsMessage = {<div>{CustomHelpCardConstants.No_Item}</div>}
        style={{ backgroundColor: getBackgroundColor(globalTheme) }}
        {...(buttonData?.length > 0 && { value: buttonData })}
        />
      </div>
      </>
    }
  
    const getDialogContent = () => {
      const selectedMenu = menuItems[selectedMenuIndex];
      let extras = <></>;
      switch(selectedMenu.key){
        case CustomHelpCardConstants.Button_Template:
          extras = getDropdownElement(CustomHelpCardConstants.Template, templates);
          break;
        case CustomHelpCardConstants.Button_NewTicket:
          if(enabledMultiDepartment)
            extras = getDropdownElement(CustomHelpCardConstants.Team, teams);
          break;
        case CustomHelpCardConstants.Button_KnowledgeArticle:
          extras = getDropdownElement(CustomHelpCardConstants.Knowledge, kbList);
          break;
        case CustomHelpCardConstants.Button_OpenURL:
          extras = <>
            <LabelElement label={'Link'} required />
            <div className="teams-input-med">
              <Input
                onChange={e => {
                  const val = (e.target as HTMLInputElement).value;
                  setButtonData(val);
                }}
                fluid
                value={buttonData}
              />
              {showErrorUrl && (
                <div style={{ color: '#c4314b' }}>
                  {CustomHelpCardConstants.Error_Link}
                </div>
              )}
            </div>
          </>;
          break;
        default:
          break;
      }
  
      return (isLoading? <LoadingScreen message={CustomHelpCardConstants.Loading} height='50%' /> : <>
        {descriptionSection}
        {nameSection}
        {extras}
      </>);
    }
  
    const onCloseDialog = () => {
      setIsOpen(false);
      setButtonName("");
      setButtonData("");
      setButtonDataId("");
      setSearchText("");
    }
  
    const onConfirmEdit = () => {
      let data = clone(editData)
      data.DisplayName = buttonName;
      if(buttonData)
        data.Data = buttonData;
      if(buttonDataId)
        data.DataId = buttonDataId;
      data = setCustomButtonIcon(data);
      let edState = (currSelect) ? EditorState.forceSelection(editorState, currSelect) : editorState;
      handleCustomEditConfirm(edState, data);
    }
  
    return (
      <>
      <MenuButton
        onMenuItemClick={onMenuChange}
        trigger={<Button content={CustomHelpCardConstants.Add_Button} icon={<div style={{width: '20px'}}><AddButtonSVG fill={globalTheme.siteVariables.colorScheme.default.foreground} /></div>} iconPosition="before" style={{padding: '0 0.5em'}} className='ml-2' />}
        menu={menuItems}
        className='rdw-inline-wrapper'
      />
      <Dialog
        closeOnOutsideClick={false}
        open={isOpen}
        footer={isLoading ? <></> : 
          <Flex gap="gap.small" className="mt-5">
              <Flex.Item push>
              <Button content={toTitleCase(BUTTONS.CANCEL)} 
                onClick={() => { 
                  if(openEditDialog) 
                    handleCustomEditCancel(editorState); 
                  onCloseDialog() 
                }}/>
              </Flex.Item>
              <Button content={toTitleCase(BUTTONS.OK)} primary disabled={isDisableSave}
                onClick={() => {
                  if(openEditDialog)
                    onConfirmEdit();
                  else
                    insertButtonToken();
                  onCloseDialog();
                }} />
          </Flex>
        }
        content={<Flex column gap={"gap.medium"}>{getDialogContent()}</Flex>}
        header={isLoading ? <></> : <>{`${openEditDialog ? CustomHelpCardConstants.Edit : CustomHelpCardConstants.Add} ${menuItems[selectedMenuIndex].content} ${CustomHelpCardConstants.Button}`}</>}
        style={{ maxWidth: '500px' }}
      />
      </>
    );
  }
  
  export const rearrangeCustomButtons = (editorData) => {
    const pattern = /<a\s+[^>]*class="wysiwyg-mention"[^>]*>.*?<\/a>/gi;
    let outputHTML = "";
  
    if(!pattern.test(editorData))
      outputHTML = editorData;
    else{
  
      let main = document.createElement('div');
      main.innerHTML = editorData;
  
      for(let child of main.children){
        let childHtml = child.outerHTML;
        let mentions = RegExp.prototype[Symbol.match].call(new RegExp(pattern), childHtml) || [];
  
        if(mentions?.length == 0)
          outputHTML += childHtml;
        else{
          let strippedChildHtml = childHtml.replaceAll(pattern, "");
          let tempChildDiv = document.createElement('div');
          tempChildDiv.innerHTML = strippedChildHtml;
          let innerText = tempChildDiv.innerText.trim();
          if(innerText.length > 0){
            outputHTML += strippedChildHtml;
            outputHTML += `<p>${mentions.join(" ")}</p>`;
          }else
            outputHTML += childHtml;
        }
      }
    }
    return outputHTML;
  }
  
  export class HelpCardEditorComponent extends React.Component<HtmlProps, HelpCardEditorState> {
    toolbarOptions: string[];
    toolbarInline: any;
    clickEvents: any[];
    isOpenDialog: boolean;
    clickedElement: HTMLAnchorElement;
    clickedData: any;
    editorContainerElement: HTMLElement;
    constructor(props: any) {
      super(props);
      const htmlString = this.props.htmlString;
      const blocksFromHtml = htmlToDraft(htmlString);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const newEditorState = EditorState.createWithContent(contentState);
      this.state = { editorState: newEditorState, openDialog: false, editedBeforeElement: [], editedAfterElement: [] };
      if (this.props.toolbarOptions)
        this.toolbarOptions = this.props.toolbarOptions;
      if (this.props.toolbarInline)
        this.toolbarInline = this.props.toolbarInline;
      this.clickEvents = [];
      this.isOpenDialog = false;
    }

    resetToDefault : Function =  (editorState: any) => {
      const blocksFromHtml = htmlToDraft(CustomHelpCardConstants.DefaultEndUserCardBody);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      let newEditorState = EditorState.push(editorState, contentState, 'insert-characters');
      newEditorState = EditorState.forceSelection(newEditorState, newEditorState.getSelection());

      this.onEditorStateChange(newEditorState);
    }
  
    onEditorStateChange: Function = (editorState: any) => {
      const { openDialog } = this.state;
      if(openDialog && (openDialog != this.isOpenDialog)){
        const focusInput: HTMLElement = document.querySelector("#editor-custom-focus-input");
        if(focusInput)
          focusInput.focus();
        return;
      }
  
      const rawdata = convertToRaw(editorState.getCurrentContent());
      let html = draftToHtml(rawdata, {}, false, ({ type, data }) => {
          if (type === 'IMAGE') {
              const alignment = data.alignment || 'none';
              return `<img src="${data.src}" alt="${data.alt}" style="height: ${data.height};width: ${data.width}; float: ${alignment}"/>`;
          }
      });
      
      if(this.isOpenDialog){
        this.state.editedAfterElement.forEach((elementAfter, index) => {
          let elementBefore = this.state.editedBeforeElement[index];
        
          let elementBeforeStr = elementBefore.outerHTML.replace("data-mention=\"\"", "data-mention");
          let elementAfterStr = elementAfter.outerHTML.replace("data-mention=\"\"", "data-mention");
    
          html = html.replace(elementBeforeStr, elementAfterStr);
        });
      }
  
      if (this.props.onChange) this.props.onChange(html);
  
      this.setState({ editorState });
  
      let elements = document.getElementsByClassName("rdw-mention-link");
      for (let el of elements) {
        if(!this.clickEvents.includes(el)){
          el.addEventListener('click', (e) => this.handleClickedMentions(e, editorState));
          this.clickEvents.push(el);
        }
        
      }
    };
  
    handleClickedMentions: Function = (e, editorState) => {
      const target = e.target as HTMLElement;
      if(target.classList.contains("deleteCustomButton")) return false;
      let anchorElement: HTMLAnchorElement;
  
      if(target.nodeName.toString() == "a")
        anchorElement = target as HTMLAnchorElement;
      else
      {
        const parentLink = target.closest(".rdw-mention-link");
        if(parentLink)
          anchorElement = parentLink as HTMLAnchorElement;
      }
  
      const dataValue = JSON.parse(decodeURI(anchorElement.getAttribute("href")));
      this.clickedElement = anchorElement;
      this.clickedData = dataValue;
      
      this.isOpenDialog = false;
      this.setState({ openDialog: true });
    }
  
    handleCustomAction : Function = (editorState: any, key: string, embedData?: string) => {
  
      //If we want to cursor to return without changing button arrangement
      const contentState = editorState.getCurrentContent();
      const selectionAfter = contentState.getSelectionAfter();
      let newEditorState = EditorState.forceSelection(editorState, selectionAfter);
      this.onEditorStateChange(newEditorState);
    }
  
    handleCustomEditConfirm : Function = (editorState: any, editedData: CustomButtonData) => {
      let elementBefore = document.createElement('a');
      elementBefore.href = this.clickedElement.getAttribute("href");
      elementBefore.className = "wysiwyg-mention";
      elementBefore.setAttribute("data-mention", "");
      elementBefore.setAttribute("data-value", this.clickedElement.getAttribute("href"));
      elementBefore.innerText = this.clickedElement.innerText;
  
      let href = encodeURI(JSON.stringify(editedData));
      this.clickedElement.setAttribute("href", href);
      this.clickedElement.firstElementChild.firstElementChild.outerHTML = `<span data-text="true">${editedData.DisplayName}</span>`;
  
      let elementAfter = document.createElement('a');
      elementAfter.href = href;
      elementAfter.className = "wysiwyg-mention";
      elementAfter.setAttribute("data-mention", "");
      elementAfter.setAttribute("data-value", href);
      elementAfter.innerText = editedData.DisplayName;
  
      let elementsBefore = this.state.editedBeforeElement || [];
      elementsBefore.push(elementBefore);
  
      let elementsAfter = this.state.editedAfterElement || [];
      elementsAfter.push(elementAfter);
  
      let newEditorState;
      let selection = editorState.getSelection();
      const block = editorState.getCurrentContent().getBlockBefore(selection.getAnchorKey());
      if(block){
        newEditorState = EditorState.forceSelection(editorState, 
          new SelectionState({
          anchorKey: block.getKey(),
          anchorOffset: block.getLength(),
          focusKey: block.getKey(),
          focusOffset: block.getLength()
        }));
      }
  
      this.isOpenDialog = true;
      this.setState({ openDialog: false, editedBeforeElement: elementsBefore, editedAfterElement: elementsAfter });
      if(newEditorState)
        this.onEditorStateChange(newEditorState);
    }
  
    handleCustomEditCancel : Function = (editorState: any) => {
      let newEditorState;
      let selection = editorState.getSelection();
      const block = editorState.getCurrentContent().getBlockBefore(selection.getAnchorKey());
      if(block){
        newEditorState = EditorState.forceSelection(editorState, 
          new SelectionState({
          anchorKey: block.getKey(),
          anchorOffset: block.getLength(),
          focusKey: block.getKey(),
          focusOffset: block.getLength()
        }));
      }
      this.setState({ editorState: newEditorState || editorState, openDialog: false });
    }

    onChange: Function = (editorState) => {
      this.createClose();
    }

    handleClickedClose: Function = (e, editorState) => {
      const target = e.target as HTMLElement;
      let parentElement = target.parentElement;
      if(!parentElement) return;

      e.preventDefault();
      e.stopPropagation();

      const dataValue: CustomButtonData = JSON.parse(decodeURI(parentElement.getAttribute("href")));

      let elementBefore = document.createElement('a');
      elementBefore.href = parentElement.getAttribute("href");
      elementBefore.className = "wysiwyg-mention";
      elementBefore.setAttribute("data-mention", "");
      elementBefore.setAttribute("data-value", parentElement.getAttribute("href"));
      elementBefore.innerText = dataValue.DisplayName;

      editorState = this.state.editorState;
      const rawdata = convertToRaw(editorState.getCurrentContent());
      let html = draftToHtml(rawdata);

      let elementBeforeStr = elementBefore.outerHTML.replace("data-mention=\"\"", "data-mention");
      html = html.replace(elementBeforeStr, "");

      const blocksFromHtml = htmlToDraft(html);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      let newEditorState = EditorState.push(editorState, contentState, 'insert-characters');
      newEditorState = EditorState.forceSelection(newEditorState, newEditorState.getSelection());

      this.onEditorStateChange(newEditorState);
    }

    createClose: Function = ()=> {
      let elements = document.getElementsByClassName("rdw-mention-link");
      for (let el of elements) {
        if(el.querySelectorAll(".deleteCustomButton").length == 0){
          let div = document.createElement('span');
          div.className = "deleteCustomButton";
          el.appendChild(div);
          el.addEventListener("mouseover", () => {
            el.querySelector(".deleteCustomButton").classList.add("hoverDeleteCustomButton");
          });
          el.addEventListener("mouseout", () => {
            el.querySelector(".deleteCustomButton").classList.remove("hoverDeleteCustomButton");
          });
          const { editorState } = this.state;
          el.querySelector(".deleteCustomButton").addEventListener('click', (e) => this.handleClickedClose(e, editorState));
        }
      }
    }

    resetEditorWidth: Function = () => {
      if(this.editorContainerElement.parentElement.offsetWidth != this.editorContainerElement.offsetWidth){
        const width = `${this.editorContainerElement.parentElement.offsetWidth}px`;
        this.editorContainerElement.style.width = width;
      }
    }

    setEditorReference: Function = ref => {
      if(ref?.state){
        this.createClose();
      }
      if(ref?.editorContainer){
        let editorElement: HTMLElement = ref?.editorContainer;
        editorElement.style.width = `${editorElement.clientWidth}px`;
        this.editorContainerElement = editorElement;

        window.addEventListener('resize', () => {
          this.resetEditorWidth();
        });

        window.addEventListener('mouseup', () => {
          this.resetEditorWidth();
        });
      }
    };
  
    render() {
      const { editorState, openDialog } = this.state;
      return (
        <ProviderConsumer
          render={globalTheme => (
            <>
            <div className={`${globalTheme.siteVariables.theme !== themeNames.Default && 'dark-mood-custom'} helpcard-editor-outer-wrapper`}
              style={{ position: 'relative', color: this.props.readOnly ? 'var(--mgt-theme-foreground-disabled)' : 'var(--mgt-theme-foreground)', backgroundColor: this.props.readOnly ? 'var(--mgt-theme-background-disabled)' : 'var(--mgt-theme-background)', ...ThemeColorScheme(globalTheme.siteVariables) }}
            >
              <Editor
                editorRef={this.setEditorReference}
                editorState={editorState}
                wrapperClassName={`${this.props.wrapperClassName ?? 'editor-wrapper'} ${this.props.readOnly ? ' editor-wrapper--disabled' : ''}`}
                editorClassName={this.props.editorClassName ?? 'editor-default'}
                toolbarClassName={`editor-toolbar`}
                onEditorStateChange={this.onEditorStateChange}
                onChange={this.onChange}
                blockStyleFn={() => 'defaultStyle'}
                mention={this.props.mention}
                handleReturn={() => {
                  return this.props.oneLine;
                }}
                toolbar={{
                  options: this.toolbarOptions ?? [
                    'inline',
                    'blockType',
                    'list',
                    'link',
                    'emoji',
                    'image',
                    'remove',
                    'history'
                  ],
                  inline: this.toolbarInline ?? {
                    inDropdown: false,
                    className: undefined,
                    component: undefined,
                    dropdownClassName: undefined,
                    options: ['bold', 'italic', 'strikethrough']
                  }
                }}
                readOnly={this.props.readOnly}
                spellCheck={this.props.spellCheck}
                toolbarCustomButtons={[
                  <HelpCardButtons key={"help-buttons"} globalTheme={globalTheme} editorState={editorState} handleCustomAction={(estate, key, embedData) => this.handleCustomAction(estate, key, embedData)} openEditDialog={openDialog} editData={this.clickedData} handleCustomEditConfirm={(eState, editedData) => this.handleCustomEditConfirm(eState, editedData)} handleCustomEditCancel={(eState) => this.handleCustomEditCancel(eState)} />,
                  <Dialog key={"set-default"} closeOnOutsideClick={false}
                    trigger={<div className="ui-menubutton rdw-inline-wrapper" style={{boxSizing: 'border-box', display: 'inline-block'}}><Button content={CustomHelpCardConstants.ResetDefault} icon={<div style={{width: '20px'}}><ResetDefaultSVG fill={globalTheme.siteVariables.colorScheme.default.foreground} /></div>} iconPosition="before" style={{padding: '0 0.5em'}} className='ml-2'/></div>}
                    onConfirm={() => this.resetToDefault(editorState)}
                    cancelButton={toTitleCase(CustomHelpCardConstants.ResetNo)}
                    confirmButton={toTitleCase(CustomHelpCardConstants.ResetYes)}
                    content={<>
                      <Flex className='my-2'><Text content={CustomHelpCardConstants.ResetDefaultMessage}  /></Flex>
                    </>}
                    header={CustomHelpCardConstants.ResetDefault} 
                    style={{ maxWidth: '500px' }}
                  />
                ]}
              />
            </div>
            </>
          )}
        />
      );
    }
  }
  