import React, { useEffect, useState } from "react";
import { platformService } from "../services/platform.service";
import { ConsentState, SignedInConsentState } from "../services/signInState.service";
import { LoginPrompt } from "./LoginPrompt";
import { useLocation } from 'react-router-dom'
import { ADMINISTRATORS, ANALYSTS, BILLING_PAGE, SUBSCRIPTION_EXPIRED_PAGE, AGENTS } from "../utils/constants";
import { appState } from "../../AppState";
import { TenantNotFound } from "../../browser-pages/errors/412";
import { BackendUpdating } from "../../browser-pages/errors/503";

interface Props {
  children: any;
  onSignedIn?: () => void;
  onBillingIssue?: () => void;
};


export const CheckLogin: React.FC<Props> = ({ children, onSignedIn, onBillingIssue }) => {
  const userRoles = appState().userRoles;
  const [isSignedIn] = SignedInConsentState.useIsSignedIn();
  const [upgradeInProg, setUpgradeInProg] = useState(false);
  const api = new platformService();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const validStatus = ['active', 'trialing', 'past_due', 'incomplete'];
        const result = await Promise.all([api.checkSubscription(), !userRoles.isLoading ? Promise.resolve<any>({ data: { Roles: userRoles.roles } }) : api.me()]);
        const subscriptionStatus = result[0].data;
        const roles = result[1].data.Roles;

        if (onBillingIssue && (subscriptionStatus === 'past_due' || subscriptionStatus === 'incomplete') && (roles.includes(ADMINISTRATORS) || roles.includes(ANALYSTS)))
          onBillingIssue();

        if (validStatus.includes(subscriptionStatus)) {
          if (location.pathname === SUBSCRIPTION_EXPIRED_PAGE)
            window.location.href = "/requests";
        }
        else if (!roles.includes(ADMINISTRATORS) && !roles.includes(ANALYSTS) && location.pathname !== SUBSCRIPTION_EXPIRED_PAGE)
          window.location.href = SUBSCRIPTION_EXPIRED_PAGE;
        else if ((roles.includes(ADMINISTRATORS) || roles.includes(ANALYSTS)) && location.pathname !== BILLING_PAGE)
          window.location.href = BILLING_PAGE;
      } catch (e) {
        setUpgradeInProg(e && e['response'] && e['response'].status === 503 && e['response'].data && e['response'].data.Message === "Upgrade in progress");
      }

      if (onSignedIn)
        onSignedIn();
    }

    if (isSignedIn === ConsentState.SignedIn) {
      fetchData().catch(err => console.error(err));
    }
  }, [isSignedIn]);

  return (
    <>
      {(isSignedIn === ConsentState.NeedsConsent) && <LoginPrompt />}
      {(isSignedIn === ConsentState.NeedsInstall) && <TenantNotFound />}
      {(isSignedIn !== ConsentState.NeedsConsent) && (
        <>
          {!upgradeInProg && children}
          {upgradeInProg && <BackendUpdating />}
        </>
      )}
    </>
  );
};