import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import Styles from './NestedDropdown.module.css';
import './NestedDropdown.css';
import _ from 'lodash';
import { ProviderConsumer } from '@fluentui/react-northstar';
import DownArrow from '../../../../svg/dropdown-downarrow.svg';
import CloseIcon from '../../../../svg/close.svg';
import { getBackgroundColor } from '../TicketForm';
import { ThemeColorScheme } from '../../common/TeamsTheme';
import { getDropdownBoxShadow } from '../../../tikit/ticketHelper';

interface Props {
  label?: string;
  placeholder?: string;
  items: Item[];
  dataItem?: Item;
  defaultValue: string;
  onChange: (item: Item) => void;
  disabled?: boolean;
  showClearBtn?: boolean;
  error?: boolean;
  tabIndex?: number;
}

interface Item {
  id: number;
  parentId: null | number;
  position: number;
  value: string;
}

interface TreeItemProps {
  id: string;
  value: string;
  onSelectCallback: () => void;
  activeValue: string;
  paddingLeft: string;
}

const TreeItem = ({ onSelectCallback, value, activeValue, paddingLeft }: TreeItemProps) => {
  return (
    <div style={{ paddingLeft: paddingLeft }} className={`${Styles.ListItem} flex items-center py-1.5 pr-4 cursor-pointer`} onClick={() => onSelectCallback()}>
      <div className={`${value === activeValue && `${Styles.ListItemActive} font-semibold selected-value`}`}>
        {value}
      </div>
    </div>
  );
};

const RecursiveTree = ({ data, onSelectCallback, activeValue }) => {
  const createTree = (item: any, padding: number) => {
    padding++;
    return item && <>
      <TreeItem
        id={item.id}
        key={item.id}
        onSelectCallback={() => onSelectCallback(item)}
        value={item.value}
        activeValue={activeValue}
        paddingLeft={`${padding > 1 ? (padding * 0.75) : padding}rem`}
      />
      {item.children && item.children.map((item: any) => <Fragment key={item.id}>{createTree(item, padding)}</Fragment>)}
    </>;
  }

  return (
    <div>
      {data.map((item: any, i: any) => (
        <Fragment key={i}>{createTree(item, 0)}</Fragment>
      ))}
    </div>
  );
};

export const NestedDropdown = ({
  label,
  items = [],
  dataItem,
  placeholder,
  onChange,
  defaultValue,
  disabled,
  showClearBtn,
  error,
  tabIndex
}: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [activeValue, setActiveValue] = useState('');
  const [activeValueArray, setActiveValueArray] = useState([]);
  const [show, setShow] = useState(false);

  function parentData(data: any) {
    if (!data?.ParentId) {
      setActiveValueArray([]);
      if (data?.Value) setActiveValueArray([data?.Value]);
      return;
    }
    const parentCategoryValue = _.find(items, o => o?.id == data.ParentId);
    parentData(parentCategoryValue);
    setActiveValueArray(items_1 => [...items_1, data?.Value]);
  }

  useEffect(() => {
    if (dataItem !== undefined) {
      setActiveValue(dataItem?.value)
      parentData(dataItem);
    } else {
      if (defaultValue) {
        setActiveValue(defaultValue)
        setActiveValueArray([defaultValue]);
        onChange(items.find(item => item.value === defaultValue));
      } else {
        setActiveValueArray([]);
      }
    }
  }, [dataItem]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && show) {
        setShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [wrapperRef, show]);

  const toTree = useCallback((data: Item[], pid = null) => {
    return data.sort((a, b) => (a?.position ?? 0) - (b?.position ?? 0)).reduce((acc, item) => {
      if (item.parentId === pid) {
        const obj = { ...item };
        const children = toTree(data, item.id);
        if ((children?.length ?? -1) >= 0) obj['children'] = children;
        acc.push(obj);
      }
      return acc;
    }, []);
  },
    [items]
  );

  const getIconClass = () => {
    return disabled ? 'nested-dropdown-icon-disabled' : 'nested-dropdown-icon';
  }

  const getForegroundColor = ({ siteVariables: { colorScheme } }: any) => {
    return disabled ? colorScheme.default.foregroundDisabled1 : colorScheme.default.foreground;
  }

  const onClickClear = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.stopPropagation();
    setActiveValueArray([]);
    onChange(null);
    setActiveValue('');
  }

  const onItemSelect = (item: any) => {
    setActiveValue(item?.Value);
    parentData(item);
    onChange(item);
    setShow(false);
  }

  const dropdownItems = useMemo(() => toTree(items), [items]);

  return (
    <ProviderConsumer
      render={globalTheme => {
        return (
          <div ref={wrapperRef} className={`relative rounded ${error && 'border border-red-700'}`} style={{ pointerEvents: disabled ? 'none' : 'auto', ...ThemeColorScheme(globalTheme.siteVariables) }}>
            {label && <div>{label}</div>}
            <div {...((tabIndex != null && tabIndex > -1) && { tabIndex })}
              className={`w-full flex items-center justify-between rounded cursor-pointer ${Styles.Container}`}
              style={{ backgroundColor: getBackgroundColor(globalTheme, disabled) }}
              onClick={() => setShow(!show)}
            >
              <span dir="rtl" className={`text-sm ${Styles.value} w-full truncate mr-4`} style={{ color: getForegroundColor(globalTheme) }}>
                <bdi>{activeValueArray?.length > 0 ? activeValueArray.join(' > ') : placeholder}</bdi>
              </span>
              {showClearBtn && !disabled && activeValueArray.length > 0 ?
                <CloseIcon className={`${getIconClass()}`} onClick={e => onClickClear(e)} /> :
                <DownArrow className={`${getIconClass()}`} />}
            </div>
            {show && (
              <div style={{ boxShadow: `${getDropdownBoxShadow(globalTheme)}` }}
                className={`${Styles.OptionContainer} rounded absolute overflow-auto left-0 right-0 z-10`}>
                {dropdownItems.length <= 0 ? <>
                  <div className="h-full flex flex-col items-center justify-center py-2">
                    <span>No data found</span>
                  </div>
                </> : <>
                  <RecursiveTree
                    data={dropdownItems}
                    activeValue={activeValue}
                    onSelectCallback={(item: any) => onItemSelect(item)}
                  />
                </>}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};
