import React, { useContext, useEffect, useState, useCallback } from 'react';
import { ProviderConsumer as FluentUIThemeConsumer, Alert } from '@fluentui/react-northstar';
import { CustomViewContext } from '../CustomViewContextProvider';
import { PeoplePicker, PersonType, UserType } from '@microsoft/mgt-react';
import { LabelElement } from '../../../shared/components/TicketForm';
import { appState } from '../../../AppState';
import { getChildFiltersCountFromStorage, inValidRequesters, inValidAffectedUsers, isChildFilterSelectedExceptQueryValue } from './heplers/customPopupHelper';
import { getSearchedValues } from '../../../shared/components/Filter';
import { CustomViewFilter } from './CustomViewFilter/CustomViewFilter';
import { FILTERS, LICENSE_FEATURE } from '../../../shared/utils/constants';
import { getCachedFeature } from '../../../shared/cache/FeatureCache';
import { useTranslation } from 'react-i18next';
import ToolbarCSS from '../Toolbar.module.css';

interface Props {
  defaultFilters: FilterPropsItems[];
  selectedRequesters: PeoplePickerUserType[];
  setSelectedRequesters: React.Dispatch<React.SetStateAction<PeoplePickerUserType[]>>;
  invalidRequesterMsg: string;
  setInvalidRequesterMsg: React.Dispatch<React.SetStateAction<string>>;
  selectedAffectedUsers: PeoplePickerUserType[];
  setSelectedAffectedUsers: React.Dispatch<React.SetStateAction<PeoplePickerUserType[]>>;
  invalidAffectedUserMsg: string;
  setInvalidAffectedUserMsg: React.Dispatch<React.SetStateAction<string>>;
  filtersByFeature: (item: any, field: string) => void;
}

const Filters = ({
  defaultFilters,
  selectedRequesters,
  setSelectedRequesters,
  invalidRequesterMsg,
  setInvalidRequesterMsg,
  selectedAffectedUsers,
  setSelectedAffectedUsers,
  invalidAffectedUserMsg,
  setInvalidAffectedUserMsg,
  filtersByFeature
}: Props) => {
  const state = appState();

  const { customViewStates, setCustomViewStates } = useContext(CustomViewContext);
  const { filters } = customViewStates;
  const [localFilters, setLocalFilters] = useState([] as FilterPropsItems[])
  const [assigneeValue, setAssigneeValue] = useState('');
  const featureRestrictFilters = filters.filter(item => filtersByFeature(item, "title"));
  const {t} = useTranslation();

  const onClickSelectedValues = (clickedParentId: number, clickedValue: FilterAccordionValues, date?: any) => {
    const isSearchDropdown = clickedParentId === FILTERS.ASSIGNEE.id;
    const selectedFilterIdx = filters.findIndex((filter) => filter.id === clickedParentId);
    const selectedFilter = filters[selectedFilterIdx];
    const selectedValue = selectedFilter.values.find((value) => value.key === clickedValue.key);
    if (selectedFilterIdx == FILTERS.CREATED_DATE.id || selectedFilterIdx == FILTERS.MODIFIED_DATE.id) {
      //checking if there is a modified/created date selected then limiting selection on modified/created date dropdown
      let temp = selectedFilter.values.map((value)=>{
        if (value.key === clickedValue.key){
          value.selected = !value.selected;
        }
        else {
          value.selected = false;
        }
        return value;
      });
      selectedFilter.values = temp;
    }
    else {
      selectedValue.selected = !selectedValue.selected;
    }
    setCustomViewStates((prev): CustomViewStatesType => ({ ...prev, filters: JSON.parse(JSON.stringify(filters)) }));

    let dropdownValues = [];
    if (isSearchDropdown) {
      dropdownValues = getSearchedValues([...selectedFilter.values], assigneeValue);
    } else {
      dropdownValues = selectedFilter.values;
    }

    const localSelectedFilter = localFilters[selectedFilterIdx];
    localSelectedFilter.values = dropdownValues;

    setLocalFilters([...localFilters]);
  };

  const onChangeDate = (clickedParentId: number, clickedValue: string)=>{
    const selectedFilterIdx = filters.findIndex((filter) => filter.id === clickedParentId);
    const selectedFilter = filters[selectedFilterIdx];
    const selectedValue = selectedFilter.values.find((value) => value.selected);
    selectedValue.date = clickedValue;
    setCustomViewStates((prev): CustomViewStatesType => ({ ...prev, filters: JSON.parse(JSON.stringify(filters)) }));
    setLocalFilters(filters);
  }
  interface IGetFilterCountReturn {
    [key: string]: number
  }
  const getFilterCount = useCallback((filtersArr: FilterPropsItems[] = JSON.parse(JSON.stringify(filters))): IGetFilterCountReturn => {
    let totalSelectedCount = 0;

    const deleteFilterIfExists = (id: number) => {
      // find index of filter from filters array
      const userFilterIdx = filtersArr.findIndex((filter: any) => filter.id === id);
      // delete filter from filters array, if exists
      if (userFilterIdx !== -1) {
        filtersArr.splice(userFilterIdx, 1);
      }
    }

    deleteFilterIfExists(FILTERS.REQUESTERS.id);
    deleteFilterIfExists(FILTERS.AffectedUsers.id);

    const selectedCount = filtersArr?.reduce((acc, filter) => {
      const selectedValueCount = filter?.values?.reduce((acc: any, value: any) => {
        acc = value.selected ? acc + 1 : acc;
        return acc;
      }, 0);

      totalSelectedCount += selectedValueCount;

      return { ...acc, [filter.id]: selectedValueCount };
    }, {});

    selectedCount['total'] = totalSelectedCount + selectedRequesters.length + selectedAffectedUsers.length;

    return selectedCount;
  }, [filters, selectedRequesters, selectedAffectedUsers]);

  useEffect(() => {
    (async () => {
      const enabledTicketTags = await getCachedFeature(LICENSE_FEATURE.TicketTags);
      if(!enabledTicketTags) defaultFilters = defaultFilters.filter(x => x.key !== 'Tags');
      if (defaultFilters && customViewStates.filters?.length === 0) {
        setCustomViewStates((prev): CustomViewStatesType => ({
          ...prev, filters: [...JSON.parse(JSON.stringify(defaultFilters))],
        }));
      }
      if (customViewStates.view !== 'edit' && defaultFilters && localFilters.length === 0) {
        setLocalFilters([...JSON.parse(JSON.stringify(defaultFilters))])
      }

      if (customViewStates.view === 'edit') {
        const tempLocalFilters = [...JSON.parse(JSON.stringify(filters))]
        const selectedFilterIdx = tempLocalFilters.findIndex((filter) => filter.id === FILTERS.ASSIGNEE.id);
        const localSelectedFilter = tempLocalFilters[selectedFilterIdx]
        const dropdownValues = getSearchedValues([...localSelectedFilter.values], '')
        localSelectedFilter.values = dropdownValues
        setLocalFilters([...tempLocalFilters])
      }

      if (
        isChildFilterSelectedExceptQueryValue(
          getChildFiltersCountFromStorage(),
          state.filterDropdown?.queryValueApplied
        ) && customViewStates.filters?.length > 0
      ) {
        setLocalFilters(JSON.parse(JSON.stringify(customViewStates.filters)))
      }
    })()

  }, [defaultFilters]);

  const defaultSelectedUsersFromViewFilters = (existingEmails: null | string[]) => {
    if(!existingEmails) return [];

    return existingEmails.map(
      (requesterEmail) => {
        const matchedUser = state.platformusers.find(
          (user) => user.Email === requesterEmail
        );
        return {
          displayName: matchedUser?.FullName,
          userPrincipalName: matchedUser?.Email,
          givenName: matchedUser?.FullName,
          surname: matchedUser?.FamilyName,
          mail: matchedUser?.Email,
          id: matchedUser?.AadObjectId
        };
      }
    );
  }

  const defaultSelectedUsersFromFilterValues = (filteredValues: null | FilterAccordionValues[]) => {
    if(!filteredValues) return [];

    return filteredValues.map(
      (item) => {
        const matchedUser = state.platformusers.find(
          (user) => user.Id === item.key
        );
        return {
          displayName: matchedUser?.FullName,
          userPrincipalName: matchedUser?.Email,
          givenName: matchedUser?.FullName,
          surname: matchedUser?.GivenName,
          mail: matchedUser?.Email,
          id: matchedUser?.AadObjectId
        };
      }
    );
  }

  // set initial selectedRequesters in case of custom_view editing or saving(when customViewStates.view is equal to 'edit' or 'save')
  useEffect(() => {
    if (customViewStates.view !== 'edit' && customViewStates.view !== 'save') {
      setSelectedRequesters([]);
      setSelectedAffectedUsers([])
      return;
    }
    if (customViewStates.view === 'edit' || customViewStates.view === 'save') {
      let requesterValues = customViewStates.filters.find((filteredItem) => filteredItem.id === FILTERS.REQUESTERS.id);
      if (requesterValues?.values && requesterValues?.values?.length !== 0) {
        let filteredRequesterValues = requesterValues.values.filter((item) => item.selected);
        const requesters = filteredRequesterValues.map(
          (item) => {
            const matchedUser = state.platformusers.find((user) => user.Id === item.key);
            return {
              displayName: matchedUser?.FullName,
              userPrincipalName: matchedUser?.UserName,
              givenName: matchedUser?.GivenName,
              surname: matchedUser?.FamilyName,
              mail: matchedUser?.Email,
              id: matchedUser?.AadObjectId
            };
          }
        );
        setSelectedRequesters(requesters);
      }

      let affectedUserValues = customViewStates.filters.find((filteredItem) => filteredItem.id === FILTERS.AffectedUsers.id);
      if (affectedUserValues?.values && affectedUserValues?.values?.length !== 0) {
        let filteredaffectedUserValues = affectedUserValues.values.filter((item) => item.selected);
        const selectedAffectedUser =  defaultSelectedUsersFromFilterValues(filteredaffectedUserValues);
        setSelectedAffectedUsers(selectedAffectedUser);
      return;
    }

    const existingEmails = (id: number): string[] => {
      return customViewStates.selectedCustomView?.ViewFilters?.find(
        (ViewFilter) => ViewFilter.FilterKey === id
      )?.FilterValues?.split(',');
    }

    const existingRequesterEmails =  existingEmails(FILTERS.REQUESTERS.id);
    const existingAffectedUserEmails =  existingEmails(FILTERS.AffectedUsers.id);

    // if custom_view contains no requester in filters
    if (!existingRequesterEmails) {
      setSelectedRequesters([]);
      return;
    }

    const defaultSelectedRequesters = existingRequesterEmails.map(
      (requesterEmail) => {
        const matchedUser = state.platformusers.find((user) => user.Id === parseInt(requesterEmail, 10));
        return {
          displayName: matchedUser?.FullName,
          userPrincipalName: matchedUser?.UserName,
          givenName: matchedUser?.GivenName,
          surname: matchedUser?.FamilyName,
          mail: matchedUser?.Email,
          id: matchedUser?.AadObjectId
        };
      }
    );

    const defaultSelectedAffectedUsers = defaultSelectedUsersFromViewFilters(existingAffectedUserEmails);

    setSelectedRequesters([...defaultSelectedRequesters]);
    setSelectedAffectedUsers([...defaultSelectedAffectedUsers]);
  }
}, []);

  const selectRequestersFunc = () => {
    return selectedRequesters;
  };

  const selectAffectedUsersFunc = () => {
    return selectedAffectedUsers;
  };

  const onChangeSearchDropdown = (e: any) => {
    const value: string = e.target.value;
    setAssigneeValue(value)

    const filterIdx = filters.findIndex(filter => filter.title === FILTERS.ASSIGNEE.title)
    const values = filters[filterIdx].values
    const dropdownValues = getSearchedValues([...values], value)

    localFilters[filterIdx].values = dropdownValues

    setLocalFilters((_prev) => ([...localFilters]))
  }

  const onChangeCollaboratorSearchDropdown = (e: any) => {
    const value: string = e.target.value;
    setAssigneeValue(value)

    const filterIdx = filters.findIndex(filter => filter.title === FILTERS.TicketCollaborators.title)
    const values = filters[filterIdx].values
    const dropdownValues = getSearchedValues([...values], value)

    localFilters[filterIdx].values = dropdownValues

    setLocalFilters((_prev) => ([...localFilters]))
  }

  return (
    <FluentUIThemeConsumer
    render={(globalTheme) => {
      return (
        <div style={{ height: '100%' }} >
          <div className='font-semibold mt-3'>
            Filters{' '}{getFilterCount()['total'] > 0 ? `(${getFilterCount()['total']})` : ``}
          </div>
          {invalidRequesterMsg && (
            <Alert
              fitted
              danger={true}
              content={invalidRequesterMsg}
              style={{
                fontSize: 12,
                width: '100%',
                marginBottom: '.5rem',
                marginTop: '1rem',
              }}
            />
          )}
          <div className='flex flex-col mt-2'>
            <div className='flex flex-wrap gap-2 mt-2'>
              <div className={`${ToolbarCSS.filterItem} relative`}>
                <div>
                <LabelElement label={`${t('ticket.ticket-list.configure-column.requesters')} ${selectedRequesters.length > 0 ? '(' + selectedRequesters.length + ')' : ''}`}>
                  <PeoplePicker
                    // selectionMode='single'
                    userType={UserType.user}
                    type={PersonType.person}
                    selectedPeople={selectRequestersFunc()}
                    selectionChanged={(e: any) => {
                      const detail: PeoplePickerUserType[] = e.detail.map(u => ({ displayName: u.displayName, mail: u.mail, givenName: u.givenName, surname: u.familyName, userPrincipalName: u.userPrincipalName, id: u.id }));
                      inValidRequesters(detail, setInvalidRequesterMsg) // check
                      setSelectedRequesters([...detail]);
                    }}
                  />
                  </LabelElement>
                </div>
              </div>
              <div className={`${ToolbarCSS.filterItem} relative`}>
                <div>
                  <LabelElement label={`${t('ticket.ticket-list.configure-column.affected-users')} ${selectedAffectedUsers.length > 0 ? '(' + selectedAffectedUsers.length + ')' : ''}`}></LabelElement>
                  <PeoplePicker userType={UserType.user} type={PersonType.person} selectedPeople={selectAffectedUsersFunc()} selectionChanged={(e: any) => { 
                      const detail: PeoplePickerUserType[] = e.detail; 
                      inValidAffectedUsers(detail, setInvalidAffectedUserMsg) // check
                      setSelectedAffectedUsers([...detail]);
                    }}
                  />
                  </div>
              </div>
            </div>
            <div>
              <div className='grid grid-cols-3 gap-2 mt-2'>
                {localFilters
                  .filter((filter) => filter.id !== FILTERS.REQUESTERS.id && filter.id !== FILTERS.AffectedUsers.id)
                  .map((filter: FilterPropsItems) => {
                    return <CustomViewFilter
                      key={filter.id}
                      filter={filter}
                      assigneeValue={assigneeValue}
                      globalTheme={globalTheme}
                      getFilterCount={getFilterCount}
                      onChangeSearchDropdown={onChangeSearchDropdown}
                      onClick={(value, date) => onClickSelectedValues(filter.id, value, date)}
                      onChangeCollaboratorSearchDropdown={onChangeCollaboratorSearchDropdown}
                      onChangeDate={(value) => onChangeDate(filter.id, value)} />
                  })}
              </div>
            </div>
          </div>
        </div>
      );
    }}
  />
  );
};

export default Filters;
