import React, { useEffect, useState } from 'react';
import Styles from './EventEvaluationType.module.css';

import { Dropdown, FormLabel, ProviderConsumer } from '@fluentui/react-northstar';
import { AUTOMATION_OPERATORS, ENTITY_TYPE } from '../../utils/constants';
import { useTranslation } from 'react-i18next';

interface Props {
  evaluationType: string;
  updateEvaluationType: (property: string) => void;
  property: string
  entityName: string
}

export const EventEvaluationType = ({
  evaluationType,
  updateEvaluationType,
  property,
  entityName
}: Props) => {
  const {t} = useTranslation();


  const [evaluationTypeList, setEvaluationTypeList] = useState<
      DropdownDataModel[]
    >([]);

    

  useEffect(() => {
    let actionsFilter = (entityName === ENTITY_TYPE.Approval && property === "title") ? "approvaltitle" : property.toLowerCase();
    let evaluationNone = ['tags', 'comments', 'servicelevelagreement', 'approvaltitle', 'relatedtickets',
                          "ticketlifecyclephase.name","approvers","phases.name","activephase","ticketcollaborators","affectedusers",
                          "affecteduserscount","collaboratorscount"];
    if (evaluationNone.includes(actionsFilter)) 
      setEvaluationTypeList(AUTOMATION_OPERATORS.noneEvaluationType);
    else if (property=="template")
      setEvaluationTypeList(AUTOMATION_OPERATORS.templateEvaluationType);
    else 
      setEvaluationTypeList(AUTOMATION_OPERATORS.evaluationType);

  }, [property, entityName]);

  const automationPropertyChange = (e, p) => {
    const data = p.value as DropdownDataModel;
    updateEvaluationType(data.value);
  };


  return (
    <ProviderConsumer render={globalTheme =>
      <div className={Styles.GroupInput}>
        <FormLabel content={t('automation.property-field.evaluation-type')} styles={{ fontSize: '12px' }} />
        <Dropdown
          inverted
          fluid
          items={evaluationTypeList}
          placeholder={t('automation.property-field.select-evaluation-type')}
          style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
          value={evaluationTypeList.filter(x => x.value == evaluationType)}
          onChange={automationPropertyChange}
        />
      </div>
    } />
  );
};
