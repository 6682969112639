import React, { useState } from 'react';
import { Text, Flex, ThemePrepared, ProviderConsumer, TrashCanIcon, Dialog, SvgIconSizeValue } from '@fluentui/react-northstar';
import { LifecyclePowerAutomateTask, KeyValuePair } from '../utils/typings';
import { toTitleCase } from '../../shared/utils/helper';
import { BUTTONS, DELETE, LIFECYCLE } from '../../shared/utils/constants';
import { ThemeColorScheme } from '../../shared/common/TeamsTheme';
import { themeNames } from '@fluentui/react-teams/lib/esm';
import { PhasePowerAutomateTaskDialog } from './PhasePowerAutomateTaskDialog';
import { PlatformUser } from '../../shared/interfaces/platformuser.interface';
import TaskStyle from '../styles/PhaseTask.module.css';
import LifecyclePowerAutomateIcon from "../../../svg/lifecycle-powerautomate-icon.svg";
import { useTranslation } from 'react-i18next';

const iconStyle = {
  size: 'small' as SvgIconSizeValue,
  style: { color: '#8f90a6' }
};
interface Props {
  data: LifecyclePowerAutomateTask;
  dataIndex: number;
  updateData: (editData: KeyValuePair[], index: number) => void;
  globalTheme: ThemePrepared<any>;
  userList: PlatformUser[];
}

export const PhasePowerAutomateTask = ({
  data,
  data: { Id, Name, Assignee },
  dataIndex,
  updateData,
  userList
}: Props) => {
 
  const { t } = useTranslation();
  
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const updateTask = (task: LifecyclePowerAutomateTask) => {
    let kv = [];
    Object.keys(task).forEach(key => {
      kv.push({
        key: key,
        value: task[key]
      } as KeyValuePair)
    });    
    updateData(kv, dataIndex);
  }

  const removeItem = () => {
    const pair: KeyValuePair = {
      key: "RemoveItem",
      value: dataIndex
    } 
    updateData([pair], dataIndex);
  }

  const removeItemMessageText = t('lifecycle.delete-powerautomate-task-message', { taskName: Name })

  return (
    <ProviderConsumer
      render={globalTheme => (
        <>
        <div
          onClick={() => {setOpenDialog(true);}}
          className={`min-h-32 cursor-pointer mx-2 ${TaskStyle['phaseTaskCard']}`}
          style={{color: 'var(--mgt-theme-default-foreground)', ...ThemeColorScheme(globalTheme.siteVariables)}}
        >
        <div className="mb-2 text-xs mt-1" style={{marginTop: '3px'}}>
            <Flex
              column
              gap="gap.small"
              style={{background: `${globalTheme.siteVariables.theme === themeNames.Dark ? 'rgb(51, 51, 51)' : '#fafafa'}`, padding: '0.75rem',
                boxShadow: 'var(--mgt-theme-border-color) 0px 1px 2px',
                borderRadius: '3px'
              }}
            >
            <div className="flex space-x-1 pb-2 ticketTaskTitle" >
              <div>
                <LifecyclePowerAutomateIcon width={14} height={14} className="inline-block" />
              </div>
              <div>
                <div>
                  <Text weight="semibold" className="break-normal" size="small">{Name}</Text>
                </div>
            </div>
          </div>
          <div className={`mt-2`}>
            <Flex vAlign={`center`} space={`between`}>
              <Flex.Item>
                <Flex vAlign='center'>
                  <Flex.Item>
                    <Flex vAlign='center'></Flex>
                  </Flex.Item>
                </Flex>
              </Flex.Item>
              <Flex.Item>
                <Flex vAlign='center' className={`${TaskStyle['phaseTaskCardDelete']}`}
                    onClick={e => {
                      e.stopPropagation();
                      setOpenDeleteDialog(true);
                    }}
                    title={`Remove Task`}
                  >
                    <Flex.Item>
                      <TrashCanIcon size={`small`} />
                    </Flex.Item>
                    <Flex.Item>
                      <span className='ml-1'>
                        {LIFECYCLE.DELETE_POWERAUTOMATE_TASK}
                      </span>
                    </Flex.Item>
                  </Flex>
              </Flex.Item>
            </Flex>
          </div>
        </Flex>
        </div>
      </div>
      <Dialog
        open={openDeleteDialog}
        onCancel={() => setOpenDeleteDialog(false)}
        cancelButton={toTitleCase(BUTTONS.CANCEL)}
        confirmButton={toTitleCase(DELETE)}
        onConfirm={() => {
          removeItem();
          setOpenDeleteDialog(false);
        }}
        content={removeItemMessageText}
        header={LIFECYCLE.DELETE_POWERAUTOMATE_TASK}
        style={{ maxWidth: '500px' }}
      />  
      <PhasePowerAutomateTaskDialog
          open={openDialog}
          onCancel={() => setOpenDialog(false)}
          saveTask={updateTask}
          initData={data}
          userList={userList}
      />  
      </>
    )}
    />
  );

};
