import { Flex, ProviderConsumer } from '@fluentui/react-northstar';
import React from 'react';
import {
  getTaskStatusIdByGuid
} from '../../../../tikit/ticketHelper';
import Style from './TaskCommon.module.css';
import { TicketTaskStatus } from '../../TicketCardTemplate';
import { STATUS_GUID } from '../../../utils/constants';
import { useTranslation } from 'react-i18next';

interface TaskListProps {
  taskList: TicketTask[];
}

interface ApprovalsListProps {
  approvalsList: TicketApprovals[];
}

export const listOfOpenTask = (
  taskStatus: TaskStatus[],
  ticketTasks: TicketTask[],
  completedGuid: string,
  faieldGuid: string,
) => {
  const completedStatusId = getTaskStatusIdByGuid(taskStatus, completedGuid);
  const faieldStatusId = getTaskStatusIdByGuid(taskStatus, faieldGuid);
  return ticketTasks.filter(item => {
    if (item.StatusId !== completedStatusId && item.StatusId !== faieldStatusId) {
      return item;
    }
  });
};

export const listOfPenddingApprovals = (state: number, approvalsList) => {
  return approvalsList.filter(item => {
    if (item.Approval.ApprovalState === state) {
      return item;
    }
  }); 
}

export const dialogHeader = (status: StatusDetail, ticketId: number, t: any) => {

  if(status?.Guid === STATUS_GUID.CLOSED) {
    return t('ticket-details.status-change-dialog.are-you-sure-change-status-close', { ticketId: ticketId });
  } else if(status?.Guid === STATUS_GUID.RESOLVED) {
    return t('ticket-details.status-change-dialog.are-you-sure-change-status-resolve', { ticketId: ticketId });
  }
  return t('ticket-details.status-change-dialog.are-you-sure-change-status', { ticketStatus: "close", ticketId: ticketId });
}

export const dialogContent = (taskList: TicketTask[], approvalsList: TicketApprovals[]) => {
  return {
    content: (
      <ProviderConsumer
        render={_globalTheme => (
          <div className={`${Style.dialogContent}`}>
            {
              taskList.length > 0 && <> 
                <ShowTasksList taskList={taskList} />
              </>
            }
            {
              approvalsList.length > 0 && <>
                <ShowApprovalsList approvalsList={approvalsList} />
              </>
            }
          </div>
        )}
      />
    )
  };
};

export const ShowTasksList = ({taskList}: TaskListProps) => {

  const { t } = useTranslation();

  return <ProviderConsumer
    render={_globalTheme => (
      <>
        <p className={`font-bold`}>
        {t('ticket-details.status-change-dialog.ticket-has-incomplete-tasks')}
        </p>
        <ul className={`${Style.listStyle} mt-2`}>
          {taskList.map(task => (
            <>
              <li className={`mt-2`}>
                <Flex>
                  <Flex.Item>
                    <span>{task.Title}</span>
                  </Flex.Item>
                  <Flex.Item className={`ml-1`}>
                    <span><TicketTaskStatus status={task?.Status} postion={'start'} /></span>
                  </Flex.Item>
                </Flex>
              </li>
            </>
          ))}
        </ul>
      </>
    )}
  />
}

export const ShowApprovalsList = ({approvalsList}: ApprovalsListProps) => {

  const { t } = useTranslation();

  return <ProviderConsumer
    render={_globalTheme => (
      <>
        <p className={`mt-4 font-bold`}>
          {t('ticket-details.status-change-dialog.ticket-has-pending-approvals')}
        </p>
        <ul className={`${Style.listStyle} mt-2`}>
          {approvalsList.map(approval => (
            <>
              <li>{approval.Approval.Title}</li>
            </>
          ))}
        </ul>
      </>
    )}
  />
}

export const formatTeamItems = (items: any[]) => {
  return items.map((item: any) => {
    return {
      Id: item['Id'],
      Name: item['FriendlyName'],
      TeamsAadObjectId: item['TeamsAadObjectId'],
      header: item['FriendlyName'],
      accessibilityitemprops: { id: item['Id'] },
      selected: false
    };
  }).filter(u => u.accessibilityitemprops.id != -1);
};

export const formatSupportGroupItems = (items: any[]) => {
  return items.map((item: any) => {
    return {
      Id: item['Id'],
      Name: item['Name'],
      TeamsAadObjectId: item['TeamsAadObjectId'],
      Members: item['Members'],
      header: item['Name'],
      accessibilityitemprops: { id: item['Id'] },
      selected: false
    };
  }).filter(u => u.accessibilityitemprops.id != -1);
};

export const formatAssigneeItems = (items: any[]) => {
  return items.map((item: any) => {
    return {
      Id: item['Id'],
      FullName: item["FullName"],
      header: item['FullName'],
      accessibilityitemprops: { id: item['Id'] },
      selected: false
    };
  }).filter(u => u.accessibilityitemprops.id != -1);
};
