import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';

import MgtCss from '../../../mgt.module.css';
import { Get, Agenda, Tasks } from '@microsoft/mgt-react';
import {
  Box,
  Flex,
  SiteVariablesPrepared,
  Dropdown,
} from '@fluentui/react-northstar';
import { separatorStyles } from '../../../../fluent-ui/components/Board/BoardLane';
import { ThemeColorScheme } from '../../../shared/common/TeamsTheme';
import { DateTime } from 'luxon';
import {
  OutlookTask,
  PlannerTask,
} from '@microsoft/microsoft-graph-types-beta';
import { TicketTemplate } from '../../WorkBoard/Templates/TicketTemplate';
import { EmailTemplate } from '../../WorkBoard/Templates/EmailTemplate';
import { AgendaTemplate } from '../../WorkBoard/Templates/AgendaTemplate';
import { themeNames } from '@fluentui/react-teams/lib/esm';
import { hasConsentFeature } from '../../../shared/cache/FeatureCache';
import { FeatureConsentNeeded } from '../../../shared/components/UpgradeNeeded';
import { TicketTasksTemplate } from '../../WorkBoard/Templates/TicketTasksTemplate/TicketTasksTemplate';
import ApprovalsTemplate from '../../WorkBoard/Templates/ApprovalTemplate/ApprovalsTemplate';
import { IMyWorkBoardView } from './MyWorkBoard';
import CustomScrollArea from 'react-perfect-scrollbar';
import {i18n} from '../../../shared/utils/helper';

const { t } = i18n();

interface Element {
  title: string;
  template: JSX.Element | JSX.Element[];
  hidden?: boolean;
}

const changeCardStyle = () => {
  let mgtTaskSelector = document.querySelector('mgt-tasks').shadowRoot;
  mgtTaskSelector
    .querySelectorAll('.Tasks')
    .forEach((x) => x.setAttribute('style', 'display: grid;'));
  mgtTaskSelector
    .querySelectorAll('.NewTask')
    .forEach((x) => x.setAttribute('style', 'justify-content:space-between'));
  mgtTaskSelector
    .querySelectorAll('.NewTask > .TaskContent > .TaskDetailsContainer')
    .forEach((x) => x.setAttribute('style', 'max-width:120px;white-space: unset;grid-template-columns: max-content;'));
};

const changeTitleStyle = () => {
  let mgtTaskSelector = document.querySelector('mgt-tasks').shadowRoot;
  mgtTaskSelector
    .querySelectorAll('.TaskTitle')
    .forEach((x) =>
      x.setAttribute(
        'style',
        'text-overflow: ellipsis;overflow: hidden;white-space: initial;'
      )
    );
};

const MY_WORK_TEMPLATES = {
  Tickets: (props: IMyWorkBoardView) => {
    return (<><TicketTemplate {...props } /></>)
  },
  Emails: () => {
    return (
      <Get
        scopes={['mail.read']}
        maxPages={2}
        version='beta'
        resource='/me/mailFolders/inbox/messages?$orderby=createdDateTime desc&$filter=isRead eq false and isDraft eq false'
      >
        <EmailTemplate template='value' />
      </Get>
    );
  },
  Agenda: () => {
    const startDate = DateTime.local()
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toUTC();
    const endDate = DateTime.local()
      .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
      .toUTC();

    return (
      <Agenda
        eventQuery={`/me/calendarview?startDateTime=${startDate}&endDateTime=${endDate} | Calendars.ReadWrite`}
      >
        <AgendaTemplate template='event' />
      </Agenda>
    );
  },
  Tasks: (props: IMyWorkBoardView) => {
    const [taskTitle, setTaskTitle] = useState<PlannerTask | OutlookTask>();
    const[deletedId,setDeletedId] = useState<string>('0');
    useEffect(() => {
      changeTitleStyle();
      changeCardStyle();
    }, [taskTitle]);

    return (
      <Tasks
        className={`${props.siteVariables.theme !== themeNames.Dark ? '' : 'mgt-dark'}`}
        taskFilter={(task: PlannerTask | OutlookTask) => {
          setTaskTitle(task);
          return task.completedDateTime == null && task.id != deletedId;
        }}
        taskRemoved={(task: any) => {
          setDeletedId(task.detail.id);
        }
        }
      />
    );
  },
  TicketTasks: () => <TicketTasksTemplate />,
  Approvals: () => <ApprovalsTemplate />
};

const dropdownDownItems = [
  t('my-work.lane-heading.my-tickets'),
  t('my-work.lane-heading.my-tasks'),
  t('my-work.lane-heading.my-approvals'),
  t('my-work.lane-heading.today-agenda'),
  t('my-work.lane-heading.my-unread-emails'),
  t('my-work.lane-heading.my-task-by-planner'),
];

const MyWorkBoardView: React.FC<IMyWorkBoardView> = (props) => {
  const headerStyles = { fontWeight: 700, fontSize: 'inherit', paddingLeft: 5 };
  const [elements, setElements] = useState<Element[]>([
    {
      title: t('my-work.lane-heading.my-tickets'),
      template: <MY_WORK_TEMPLATES.Tickets {...props} />,
      hidden: false,
    },
    {
      title: t('my-work.lane-heading.my-tasks'),
      template: <MY_WORK_TEMPLATES.TicketTasks />,
      hidden: false,
    },
    {
      title: t('my-work.lane-heading.my-approvals'),
      template: <MY_WORK_TEMPLATES.Approvals />,
      hidden: false,
    },
    {
      title: t('my-work.lane-heading.today-agenda'),
      template: <MY_WORK_TEMPLATES.Agenda />,
      hidden: true,
    },
    {
      title: t('my-work.lane-heading.my-unread-emails'),
      template: <MY_WORK_TEMPLATES.Emails />,
      hidden: true,
    },
    {
      title: t('my-work.lane-heading.my-task-by-planner'),
      template: <MY_WORK_TEMPLATES.Tasks {...props}/>,
      hidden: true,
    },
  ]);
  const [menu, setMenu] = useState(0);
  const [hasConsent, setHasConsent] = useState(true);

  useEffect(() => {
    (async () => {
      const results = await Promise.all([
        hasConsentFeature('EMAIL_CONNECTOR'),
        hasConsentFeature('MY_WORK')
      ]);

      setElements([
        {
          title: t('my-work.lane-heading.my-tickets'),
          template: <MY_WORK_TEMPLATES.Tickets {...props} />,
          hidden: false,
        },
        {
          title: t('my-work.lane-heading.my-tasks'),
          template: <MY_WORK_TEMPLATES.TicketTasks />,
          hidden: false,
        },
        {
          title: t('my-work.lane-heading.my-approvals'),
          template: <MY_WORK_TEMPLATES.Approvals />,
          hidden: false,
        },
        {
          title: t('my-work.lane-heading.today-agenda'),
          template: <MY_WORK_TEMPLATES.Agenda />,
          hidden: !results[1],
        },
        {
          title: t('my-work.lane-heading.my-unread-emails'),
          template: <MY_WORK_TEMPLATES.Emails />,
          hidden: !results[1] && !results[0],
        },
        {
          title: t('my-work.lane-heading.my-task-by-planner'),
          template: <MY_WORK_TEMPLATES.Tasks {...props} />,
          hidden: !results[1],
        },
      ]);
      setHasConsent(results[1]);
    })();
  }, []);

  return (
    <Container fluid={true} className={`h-full overflow-y-hidden my-work`}>
      {!hasConsent && <div className="mt-2"><FeatureConsentNeeded /></div>}
      <div className={`md:hidden py-3`}>
        <Flex hAlign="center">
          <Dropdown
            className='w-full'
            fluid
            items={dropdownDownItems}
            placeholder={t('my-work.lane-heading.today-agenda')}
            inverted
            onChange={(e, p) => {
              setMenu(p.highlightedIndex);
            }}
          />
        </Flex>
      </div>
      <Row
        className={`${MgtCss.rowMyWork} my-work__board my-work__board--show-${menu}`}
        style={{flexWrap: 'nowrap'}}
      >
        {elements.map((element: Element, index: number) => (
          <Col
            className={`${MgtCss.colMyWork} my-work__board__lane`}
            key={`data_${index}`}
            xs={3}
            sm={3}
          >
            <div
              className={`hidden-on-mobile--important`}
              style={{
                marginLeft: 10,
                marginTop: 12,
                marginBottom: 15,
                ...headerStyles,
              }}
            >
              {element.title}
            </div>

            <Box
              variables={({ colorScheme }: SiteVariablesPrepared) => ({
                backgroundColor: 'colorScheme.default.background2',
                separatorColor: colorScheme.default.border2,
              })}
              styles={{
                flex: '0 0 auto',
                padding: '0 0 .75rem 0',
                height: 'calc(100vh - 100px) !important',
                ...(index === elements.length - 1 ? {} : separatorStyles),
                ...ThemeColorScheme(props.siteVariables),
              }}
            >
              {!element.hidden && (
                <CustomScrollArea style={{ paddingRight: '17px', paddingLeft: '17px', height: 'calc(100% - 51px)', width: '100%', backgroundColor: 'transparent' }}>
                  {element.template}
                </CustomScrollArea>
              )}
            </Box>
          </Col>
        ))}
      </Row>
    </Container>
  );
};


export default MyWorkBoardView;
