import { CacheService, CacheConfig, CacheStore, CacheSchema, CacheItem } from "@microsoft/mgt-react";
const schema: CacheSchema = {
    name: 'keyvalue',
    stores: {
        users: ""
    },
    version: 1
};
  
interface CacheUserKV extends CacheItem {
    aadObjectId?: string;
}

let cache: CacheStore<CacheUserKV>
cache = CacheService.getCache<CacheUserKV>(schema, 'users');

export const setUserKeyValue = (email: string, aadObjectId: string) => {
    cache.putValue(email, { aadObjectId: aadObjectId });
}

export const getUserKeyValue = async(email: string) => {
    const kv = await cache.getValue(email);
    return (kv) ? kv.aadObjectId : "";
}