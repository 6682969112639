import {
  Table,
  Flex,
  SearchIcon,
  Input,
  ProviderConsumer as FluentUIThemeConsumer,
  SiteVariablesPrepared,
} from '@fluentui/react-northstar';
import { gridNestedBehavior } from '@fluentui/accessibility';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { toTitleCase } from '../../utils/helper';
import { TablePagination } from './TablePagination';
import Styles from './table.module.css';
import { useHistory, useLocation } from 'react-router-dom';
import { KBTableTheme } from '../../../kb/KBTableTheme';
import { TeamsTheme } from '@fluentui/react-teams/lib/esm/themes';
import { ThemeColorScheme } from '../../../../components/shared/common/TeamsTheme';
import { USER_LICENSE_HEADERS } from '../../utils/constants';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const getHeaders = (
  headers: string[],
  headerNotTitleCase: boolean
): HeaderList => {
  const data: HeaderList = {
    key: 'header',
    items: [],
  };
  headers.forEach((header: string) => {
    data.items.push({
      content: headerNotTitleCase ? header : toTitleCase(header),
      key: header.toLowerCase(),
    });
  });

  return { ...data, className: `${Styles.tableHeader}` };
};
interface Props {
  headers: string[];
  rows: RowsData[];
  routeName: string;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  onRowClickHandler?: (id: number) => void;
  headerNotTitleCase?: boolean;
  hideSearch?: boolean;
  searchText?: string;
  onSearchKeyChanged?: (data: string) => void;
}

export const ITEMS_PER_PAGE = 15
export const TableWrapper = ({
  headers,
  rows = [],
  routeName,
  onRowClickHandler,
  headerNotTitleCase,
  currentPage,
  setCurrentPage,
  hideSearch,
  searchText,
  onSearchKeyChanged
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [searchKey, setSearchKey] = useState(searchText ?? '');
  const [totalCount, setTotalCount] = useState(0);
  const [collection, setCollection] = useState<RowsData[]>([]); // could be searched result or original data passed as prop
  const [paginatedData, setPaginatedData] = useState<RowsData[]>([]);
  const $tableWrapper = useRef<HTMLDivElement | null>(null);

  const searchedResult = (val: string) => {
    const query: string = val.toLowerCase();
    
    const filteredData: RowsData[] = rows.filter((row: RowsData) =>
      Object.keys(row).some(
        (key: string) =>
          typeof row[key] !== 'object' && // this check filters out the JSX elements
          (row[key]?.toString().toLowerCase().includes(query) ?? false)
      )
    );
    setCollection(_.cloneDeep(filteredData));
    setPaginatedData(filteredData.slice((currentPage - 1) * ITEMS_PER_PAGE, ITEMS_PER_PAGE * currentPage));
    setTotalCount(filteredData.length);
  }

  useEffect(() => {
    if(onSearchKeyChanged)
      onSearchKeyChanged(searchKey);
    if (hideSearch && searchText)
      searchedResult(searchText);
    else
      searchedResult(searchKey);
  }, [searchKey, searchText, rows]);


  const moveToPage = (type: string) => {
    if (type === 'next') {
      const newData: RowsData[] = collection.slice(currentPage * ITEMS_PER_PAGE, (currentPage + 1) * ITEMS_PER_PAGE);
      setCurrentPage((prevPage) => prevPage + 1);
      setPaginatedData(newData);
    } else {
      const newData: RowsData[] = collection.slice(
        (currentPage - 1) * ITEMS_PER_PAGE - ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE
      );
      setCurrentPage((prevPage) => prevPage - 1);
      setPaginatedData(newData);
    }
  };

  const handleRowClick = (id: number) => {
    if (onRowClickHandler) {
      onRowClickHandler(id);
    } else {
      let path = `/settings/${routeName}?id=${id}`;
      if (location.pathname.indexOf('/settings/') == -1) path = `/${routeName}?id=${id}`;
      history.push(path);
    }
  };
  const getRows = useMemo(() => (): RowListObject[] => {
    const result: RowListObject[] = [];

    paginatedData.forEach((row: RowsData, rowIndex: number) => {
      const rowListObject: RowListObject = {
        key: rowIndex,
        items: [],
        onClick: () => headers != USER_LICENSE_HEADERS ? handleRowClick(row['id'] as number) : {},
        className: `${Styles.tableRow}`,
      };

      const items: RowItemsObject[] = [];
      headers.forEach((header: string, headerIndex: number) => {
        const rowItemsObject: RowItemsObject = { content: '', key: '' };

        rowItemsObject['content'] =
          items.length === 0 ? (
            <div className={Styles.rowFirstColumn}>{row[header]}</div>
          ) : (
            row[header] ?? ''
          );

        rowItemsObject['key'] = `cell-${headerIndex}`;
        rowItemsObject['styles'] = { alignSelf: 'center' };

        items.push(rowItemsObject);
      });
      rowListObject.items = [...items];
      result.push(rowListObject);
    });
    return result;
  }, [paginatedData]);

  const onSearch = (e: React.SyntheticEvent<HTMLElement>) => {
    const value = (e.target as HTMLInputElement).value
    setSearchKey(value);
    searchedResult(value);
    setCurrentPage(() => 1)
  }

  return (
    <Flex column gap='gap.medium'>
      {!hideSearch && (
        <Flex style={{ justifyContent: 'flex-end' }}>
          <Input
            icon={<SearchIcon />}
            inverted
            placeholder={t('common.search')}
            value={searchKey}
            onChange={onSearch}
          />
        </Flex>
      )}

      <FluentUIThemeConsumer
        render={(globalTheme) => (
          <>
            <KBTableTheme globalTheme={globalTheme}>
              <div
                ref={$tableWrapper}
                style={{
                  width: '100%',
                  height: 'inherit',
                  backgroundColor:
                    globalTheme.siteVariables.colorScheme.default.background2,
                  paddingBottom: '1rem',
                  ...ThemeColorScheme(globalTheme.siteVariables),
                }}
              >
                <Table
                  compact
                  header={getHeaders(headers, headerNotTitleCase)}
                  rows={getRows()}
                  aria-label='Nested navigation'
                  accessibility={gridNestedBehavior}
                  className={`responsive-table`}
                  variables={({
                    theme,
                    colorScheme,
                  }: SiteVariablesPrepared) => {
                    return {
                      rowBorderColor: colorScheme.default.backgroundActive,
                      // box model
                      compactRowHeight: '2.5rem',
                      defaultRowMinHeight: '3rem',
                      defaultRowVerticalPadding: '.8125rem',
                      ...{
                        defaultRowHeight: 'auto',
                        cellVerticalAlignment: 'flex-start',
                      },
                      // colors
                      backgroundColor:
                        theme === TeamsTheme.HighContrast
                          ? colorScheme.grey.background2
                          : colorScheme.grey.background,
                      ...(theme === TeamsTheme.HighContrast
                        ? {
                          rowBorderColor: colorScheme.grey.foreground,
                          rowBorderHoverColor: colorScheme.grey.foreground,
                        }
                        : {}),
                    };
                  }}
                  styles={{
                    width: 'auto',
                  }}
                />
              </div>
            </KBTableTheme>
            <TablePagination
              currentPage={currentPage}
              totalCount={totalCount}
              moveToPage={moveToPage}
              itemPerPage={ITEMS_PER_PAGE}
            />
          </>
        )}
      />
    </Flex>
  );
};
