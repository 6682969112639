import React from 'react';
import { Board } from '../../../fluent-ui/components/Board/Board';
import {
    IBoardItemCardLayout,
    TBoardItems
} from '../../../fluent-ui/components/Board/BoardItem';
import _ from 'lodash';
import {
    PlatformUser
} from '../../shared/interfaces/platformuser.interface';
import { Ticket } from '../../shared/interfaces/ticket.interface';
import { TicketProps } from '../../shared/interfaces/ticketproperties.interface';

import { platformService } from '../../shared/services/platform.service';
import { DateTime } from 'luxon';
import { ListInputItemsEnum, getStatusIdByGuid } from '../ticketHelper';
import { STATUS_GUID, FILTERS_STRINGS } from '../../shared/utils/constants';
import { ISupportGroup, ITeam } from '../../shared/interfaces/supportGroup.interface';
import { withTranslation } from 'react-i18next';

const api = new platformService();
interface itemProps {
    status: TicketProps[];
    categories: TicketProps[];
    urgency?: TicketProps[];
    impact?: TicketProps[];
    priority: TicketProps[];
}

interface Props {
    tickets: Ticket[];
    isDeflectedStatusSelected: boolean;
    // closedTickets: Ticket[];
    boardFilter: number | undefined;
    listFilter: number | undefined;
    platformusers: PlatformUser[];
    ticketprops: itemProps;
    supportgroups: ISupportGroup[];
    teams: ITeam[];
    isSlaEnabled: boolean;
    isLifecycleEnabled: boolean;
    t: any;
}

interface State {
    k: string;
    tickets: Ticket[];
    // closedTickets: Ticket[];
    boardFilter: number | undefined;
    listFilter: number | undefined;
    platformusers: PlatformUser[];
    ticketprops: itemProps;
    boardItems: TBoardItems;
    isEmpty: boolean;
    headerText: string;
    subheaderText: string;
    supportgroups: ISupportGroup[];
    teams: ITeam[];
}

interface LooseObject {
    [key: string]: any;
}

export const getSlaByTicketId = async (ticketId: number) => {
    return await api.getTicketSla(
        `${FILTERS_STRINGS.TICKET_SLA_FILTER}&$filter=TicketId eq ${ticketId}`
    );
}
class TaskBoard extends React.Component<Props, State> {
    t = this.props.t;

    constructor(props: Props) {
        super(props);
        this.state = {
            k: Math.random().toString(),
            tickets: props.tickets,
            // closedTickets: props.closedTickets,
            boardFilter: props.boardFilter,
            listFilter: props.listFilter,
            platformusers: props.platformusers,
            ticketprops: props.ticketprops,
            boardItems: this.generateItems(this.props.tickets),
            isEmpty: this.props.tickets.length === 0,
            headerText: this.t('ticket.ticket-board.no-tickets-header-text'),
            subheaderText: this.t('ticket.ticket-board.start-subheader-text'),
            supportgroups: props.supportgroups,
            teams: props.teams
        };
    }
    componentDidMount() {
        this.setState({
            k: Math.random().toString(),
            tickets: this.props.tickets,
            // closedTickets: this.props.closedTickets,
            boardFilter: this.props.boardFilter,
            listFilter: this.props.listFilter,
            platformusers: this.props.platformusers,
            ticketprops: this.props.ticketprops,
            boardItems: this.generateItems(this.props.tickets),
            supportgroups: this.props.supportgroups,
            teams: this.props.teams
        });
    }
    componentWillUnmount() {
        this.setState({
            k: '',
            tickets: [],
            platformusers: [],
            boardItems: {},
            ticketprops: this.state.ticketprops
        });
    }
    componentDidUpdate(prevProps: Props, prevState: State) {
        if (!_.isEqual(this.props, prevProps)) {
            const isClosed = this.props.listFilter == ListInputItemsEnum.Closed;
            
            this.setState({
                k: Math.random().toString(),
                tickets: this.props.tickets,
                // closedTickets: this.props.closedTickets,
                platformusers: this.props.platformusers,
                ticketprops: this.props.ticketprops,
                boardItems: this.generateItems(this.props.tickets),
                isEmpty: this.props.tickets.length === 0,
                headerText: (isClosed && this.props.tickets.length === 0) ? 'There are no Closed Tickets.' : '',
                subheaderText: '',
                supportgroups: this.props.supportgroups,
                teams: this.props.teams
            });
        }

        if (!_.isEqual(this.state, prevState)) {
        }
    }

    transformItem(ti: Ticket, count: LooseObject, order: LooseObject, lane_name: string, transformeditems: LooseObject, enumTypeFilter:string) {

            if (typeof count[lane_name] == 'undefined') {
                count[lane_name] = 0;
            }
            if (typeof order[lane_name] == 'undefined') {
                // check if the variable for the order of the item in that lane is set, set if not
                order[lane_name] = [];
            }
            
            if (!localStorage.getItem(enumTypeFilter)) {
                order[lane_name].push({
                    Id: ti.Id,
                    order: count[lane_name],
                    title: ti.Title
                }); // push the values of the item to the order lane. preparing it to save on the localstorage
            }
            let item_order = 0; // count +1 for the item count in the lane
            if (localStorage.getItem(enumTypeFilter)) {
                // if localstorage is not set then, it's the first time to load it on the particular browser. the orders will be the default order from item_order variable
                let saved_order = localStorage.getItem(
                    enumTypeFilter
                ); // get the value from localstorage
                let saved_order_json: LooseObject = {}; // set the variable when it will be parsed
                if (typeof saved_order_json[lane_name] == 'undefined') {
                    saved_order_json[lane_name] = [];
                }
                saved_order_json = JSON.parse(
                    saved_order == null ? '{}' : saved_order
                );
                let currentitem;
                if (typeof saved_order_json[lane_name] !== 'undefined') {
                    currentitem = saved_order_json[lane_name].find((x: any) => {
                        return x.Id === ti.Id;
                    }); // set the current item
                }
                item_order =
                    typeof currentitem == 'undefined'
                        ? count[lane_name]
                        : currentitem.order; // change the item_order
                order[lane_name].push({
                    Id: ti.Id,
                    order: item_order,
                    title: ti.Title
                });
            }
            
            let RequesterId = ti.RequesterId == null ? [] : [ti.RequesterId];
            if (ti.AffectedUsers && ti.AffectedUsers.length > 0)
                RequesterId = [...RequesterId, ...ti.AffectedUsers.map(x => x.Id)];

            let AssigneeId = ti.AssigneeId == null ? [] : [ti.AssigneeId];
            if (ti.TicketCollaborators && ti.TicketCollaborators.length > 0)
                AssigneeId = [...AssigneeId, ...ti.TicketCollaborators.map(x => x.Id)];
                
            const CreatedById = ti.CreatedById == null ? [] : [ti.CreatedById];

            transformeditems[ti.Id] = {
                Id: ti.Id,
                lane: lane_name,
                order: count[lane_name],
                title: ti.Title,
                body: ti.Description,
                users: RequesterId,
                AssigneeId: AssigneeId,
                Assignee: { ...ti['Assignee'] },
                Requester: { ...ti['Requester'] },
                Status: { ...ti['Status'] },
                ResolutionDate: ti.ResolutionDate,
                CategoryId: ti.CategoryId,
                TicketTypeId: ti.TicketTypeId,
                StatusId: ti.StatusId,
                PriorityId: ti.PriorityId,
                SupportGroupId: ti.SupportGroupId,
                CreatedDate: ti.CreatedDate,
                DueDate: ti.DueDate,
                ModifiedDate: ti.ModifiedDate,
                CreatedById: CreatedById,
                Comments: ti.Comments,
                TicketSlas: ti.TicketSlas,
                IsSlasFetching: ti.IsSlasFetching,
                IsMerged: ti.IsMerged,
                Closed: ti.Closed,
                CollaboratorsCount: ti?.TicketCollaborators,
                AffectedUsersCount: ti?.AffectedUsers,
                SourceTicketId: ti?.SourceTicketId ?? null,
                TicketTasks: ti.TicketTasks,
                HasActiveTasks: ti.HasActiveTasks,
                TicketLifecycle: ti.TicketLifecycle,
                PendingTicketApprovals: ti.PendingTicketApprovals,
                HasPendingApprovals: ti.HasPendingApprovals
            };
            count[lane_name]++;
    }

    api = new platformService();

    generateItems = (tickets: Ticket[], newLaneOrder?: any) => {
        let transformeditems: LooseObject = {};
        let count: LooseObject = {};
        let i = 0;
        let order: LooseObject = {};

        if(!this.props.isDeflectedStatusSelected) {
            tickets = tickets.filter(
                (ticket: Ticket) =>
                    ticket.StatusId !=
                    getStatusIdByGuid(this.props.ticketprops.status, STATUS_GUID.DEFLECTED)
            );
        }

        if (tickets.length > 0) {
            if (this.props.boardFilter === 0) {
                // if board filter is status
                if (typeof newLaneOrder != 'undefined') {
                    // checking if newLaneOrder is defined, if it is then save it to localstorage
                    localStorage.setItem(
                        `status_${this.props.listFilter}`,
                        JSON.stringify(newLaneOrder)
                    );
                }

                let defaultStatus = this.props.ticketprops.status.find(status => {
                    return status.IsDefault;
                });
                let defaultid = this.props.ticketprops.status[0]?.Id;
                if (typeof defaultStatus != 'undefined') {
                    defaultid = defaultStatus.Id;
                }

                

                tickets.forEach((ti: Ticket) => {
                    let lane_name =
                        ti.StatusId == null
                            ? `status_${defaultid}`
                            : `status_${ti.StatusId}`;
                    this.transformItem(ti, count, order, lane_name, transformeditems, `status_${this.props.listFilter}`);
                });

                if (typeof newLaneOrder == 'undefined') {
                    // lastly, if the newlaneorder is not defined, then save the order variable to the localstorage
                    localStorage.setItem(
                        `status_${this.props.listFilter}`,
                        JSON.stringify(order)
                    );
                }
            } else if (this.props.boardFilter === 1) {
                // if board filter is category
                if (typeof newLaneOrder != 'undefined') {
                    // checking if newLaneOrder is defined, if it is then save it to localstorage
                    localStorage.setItem(
                        `category_${this.props.listFilter}`,
                        JSON.stringify(newLaneOrder)
                    );
                }

                tickets.forEach((ti: Ticket) => {
                    let lane_name =
                        ti.CategoryId == null ? 'no_category' : `category_${ti.CategoryId}`; // identifying what lane is the ticket. if it is null, it will set as 'no_category'
                    this.transformItem(ti, count, order, lane_name, transformeditems, `category_${this.props.listFilter}`);
                });

                if (typeof newLaneOrder == 'undefined') {
                    // lastly, if the newlaneorder is not defined, then save the order variable to the localstorage
                    localStorage.setItem(
                        `category_${this.props.listFilter}`,
                        JSON.stringify(order)
                    );
                }
            } else if (this.props.boardFilter === 2) {
                // filter is priority
                // checking if newLaneOrder is defined (drag and drop function), if it is then save it to localstorage
                if (typeof newLaneOrder != 'undefined') {
                    localStorage.setItem(
                        `priority_${this.props.listFilter}`,
                        JSON.stringify(newLaneOrder)
                    );
                }

                // check priority lists if there is a default value in case ticket priorityId property is null
                let defaultPriority = this.props.ticketprops.priority.find(priority => {
                    return priority.IsDefault;
                });
                let defaultid = this.props.ticketprops.priority[0].Id;
                if (typeof defaultPriority != 'undefined') {
                    defaultid = defaultPriority.Id;
                }

                tickets.forEach((ti: Ticket) => {
                    let lane_name =
                        ti.PriorityId == null
                            ? `priority_${defaultid}`
                            : `priority_${ti.PriorityId}`;
                    this.transformItem(ti, count, order, lane_name, transformeditems, `priority_${this.props.listFilter}`);
                });

                if (typeof newLaneOrder == 'undefined') {
                    // lastly, if the newlaneorder is not defined, then save the order variable to the localstorage
                    localStorage.setItem(
                        `priority_${this.props.listFilter}`,
                        JSON.stringify(order)
                    );
                }
            } else if (this.props.boardFilter === 3) {
                // filter is group
                if (typeof newLaneOrder != 'undefined') {
                    // checking if newLaneOrder is defined, if it is then save it to localstorage
                    localStorage.setItem(
                        `group_${this.props.listFilter}`,
                        JSON.stringify(newLaneOrder)
                    );
                }

                tickets.forEach((ti: Ticket) => {
                    let lane_name =
                        ti.SupportGroupId == null
                            ? 'no_group'
                            : `group_${ti.SupportGroupId}`; // identifying what lane is the ticket. if it is null, it will set as 'no_group'
                    this.transformItem(ti, count, order, lane_name, transformeditems, `group_${this.props.listFilter}`);
                });

                if (typeof newLaneOrder == 'undefined') {
                    // lastly, if the newlaneorder is not defined, then save the order variable to the localstorage
                    localStorage.setItem(
                        `group_${this.props.listFilter}`,
                        JSON.stringify(order)
                    );
                }
            } else if (this.props.boardFilter === 4) {
                // filter is team
                if (typeof newLaneOrder != 'undefined') {
                    // checking if newLaneOrder is defined, if it is then save it to localstorage
                    localStorage.setItem(
                        `team_${this.props.listFilter}`,
                        JSON.stringify(newLaneOrder)
                    );
                }

                tickets.forEach((ti: Ticket) => {
                    let lane_name =
                        ti.TeamId == null
                            ? 'no_team'
                            : `team_${ti.TeamId}`; // identifying what lane is the ticket. if it is null, it will set as 'no_team'
                    this.transformItem(ti, count, order, lane_name, transformeditems, `team_${this.props.listFilter}`);
                });

                if (typeof newLaneOrder == 'undefined') {
                    // lastly, if the newlaneorder is not defined, then save the order variable to the localstorage
                    localStorage.setItem(
                        `team_${this.props.listFilter}`,
                        JSON.stringify(order)
                    );
                }
            }
        }
        return transformeditems;
    };

    updatedBoardItemsFromFormattedLane = (
        currentformattedlane: LooseObject,
        laneThatChanged: string,
        movedItemId: number,
        updatedValues: any
      ) => {
        if(updatedValues.AssigneeId || updatedValues.AssigneeId === null){
            // for Group By Group only 
            updatedValues.AssigneeId = updatedValues.AssigneeId === null ? [] : [updatedValues.AssigneeId]
        }

        const changedFormattedLane = currentformattedlane[laneThatChanged]
        let changedItemIdx = changedFormattedLane.findIndex(item => item.Id === movedItemId)
        changedFormattedLane[changedItemIdx] = {
            ...changedFormattedLane[changedItemIdx], 
            lane: laneThatChanged, 
            ...updatedValues
        }

        return currentformattedlane;
      };

    generateUsers = (platformusers: any[]) => {
        let transformedusers: LooseObject = {};

        platformusers.forEach((u: any) => {
            transformedusers[u.Id] = {
                name: u.FullName
            };
        });
        return transformedusers;
    };
    generateLanes = () => {
        let transformedlane: LooseObject = {};
        if (this.props.boardFilter === 0) {
            const deflectedId = getStatusIdByGuid(
                this.state.ticketprops.status,
                STATUS_GUID.DEFLECTED
            ); // get the deflected id, will return 0 if there's no deflected column

            let sortableStatus = []; //set sortable variable
            this.state.ticketprops.status.forEach((status: TicketProps) => {
                sortableStatus.push([status, status.Position]); // push ticket to the sortable container
            });

            sortableStatus.sort((a, b) => {
                // sort ticket by Position [1]
                return a[1] - b[1];
            });

            sortableStatus.forEach((sortedStatus: [TicketProps, number]) => {
                // iterate each sorted item

                if(sortedStatus[0].Id === deflectedId && !this.props.isDeflectedStatusSelected){
                    // skip if deflected status is not selected in child filter and in custom_view
                    return
                }
                transformedlane[`status_${sortedStatus[0].Id}`] = {
                    // generate lane
                    title: sortedStatus[0].Value
                };
            });
        } else if (this.props.boardFilter === 1) {
            transformedlane['no_category'] = {
                title: 'No Category'
            };
            let sortableCategory = []; //set sortable variable
            this.state.ticketprops.categories.forEach((category: TicketProps) => {
                sortableCategory.push([category, category.Position]); // push ticket to the sortable container
            });

            sortableCategory.sort((a, b) => {
                // sort ticket by Position [1]
                return a[1] - b[1];
            });

            sortableCategory.forEach((sortedCategories: [TicketProps, number]) => {
                // iterate each sorted item
                transformedlane[`category_${sortedCategories[0].Id}`] = {
                    // generate lane
                    title: sortedCategories[0].Value
                };
            });
        } else if (this.props.boardFilter === 2) {
            let sortablePrio = []; //set sortable variable
            this.state.ticketprops.priority.forEach((priority: TicketProps) => {
                sortablePrio.push([priority, priority.Position]); // push ticket to the sortable container
            });

            sortablePrio.sort((a, b) => {
                // sort ticket by Position [1]
                return a[1] - b[1];
            });

            sortablePrio.forEach((sortedPriorities: [TicketProps, number]) => {
                // iterate each sorted item
                transformedlane[`priority_${sortedPriorities[0].Id}`] = {
                    // generate lane
                    title: sortedPriorities[0].Value
                };
            });
        } else if (this.props.boardFilter === 3) {
            transformedlane['no_group'] = {
                title: 'No Group'
            };
            let sortableGroup = []; //set sortable variable
            this.state.supportgroups.forEach((group: ISupportGroup) => {
                sortableGroup.push([group, group.Id]); // push ticket to the sortable container
            });

            sortableGroup.sort((a, b) => {
                // sort ticket by Position [1]
                return a[1] - b[1];
            });

            sortableGroup.forEach((sortedGroups: [ISupportGroup, number]) => {
                // iterate each sorted item
                transformedlane[`group_${sortedGroups[0].Id}`] = {
                    // generate lane
                    title: sortedGroups[0].Name
                };
            });
        } else if (this.props.boardFilter === 4) {
            transformedlane['no_team'] = {
                title: 'No Team'
            };
            let sortableTeam = []; //set sortable variable
            this.state.teams.forEach((team: ITeam) => {
                sortableTeam.push([team, team.Id]); // push ticket to the sortable container
            });

            sortableTeam.sort((a, b) => {
                // sort ticket by Position [1]
                return a[1] - b[1];
            });

            sortableTeam.forEach((sortedTeams: [ITeam, number]) => {
                // iterate each sorted item
                transformedlane[`team_${sortedTeams[0].Id}`] = {
                    // generate lane
                    title: sortedTeams[0].FriendlyName
                };
            });
        }
        return transformedlane;
    };
    board_action = (e: any) => {
        let currentformattedlane: LooseObject = e.items;
        let previtems = this.generateItems(this.state.tickets)

        let prevlanes = this.generateLanes();

        let prevItemEntries = Object.entries(previtems);
        let prevLaneEntries = Object.entries(prevlanes);

        let prevformattedLane: LooseObject = {};

        prevLaneEntries.forEach(lane => {
            if (typeof prevformattedLane[lane[0]] == 'undefined') {
                prevformattedLane[lane[0]] = [];
            }
        });
        
        prevItemEntries.forEach(ci => {
            if(!prevformattedLane[ci[1].lane]){
                return // if assigned ticket status is archived
            }
            prevformattedLane[ci[1].lane].push(ci[1]);
        });

        let laneThatChanged = ''; //lane transferred to
        Object.keys(currentformattedlane).forEach((key) => {
            if(currentformattedlane[key].length > prevformattedLane[key].length){
                laneThatChanged = key
            }
        })

        let movedItemId: number | null = null;
        if (laneThatChanged != '') {
            let originalitems: any[] = [];
            let currentitems: any[] = [];
            currentformattedlane[laneThatChanged].map((item: any) => {
                currentitems.push(item.Id);
                prevformattedLane[laneThatChanged].find((i: any) => {
                    if (i.Id == item.Id) {
                        originalitems.push(i.Id);
                        return;
                    }
                });
            });

            currentitems.forEach(i => {
                if (!originalitems.includes(i)) {
                    movedItemId = i;
                }
            });
            if (movedItemId != null) {
                let newticketstate = this.state.tickets;

                let newticketstateIdx = newticketstate.findIndex((ticket) => ticket.Id == movedItemId);

                const getUpdatedSLAs = async (ticketId: number,newticketstate: any, ticketKey: "closedTickets" | "tickets") => {
                    const slaList = await this.api.getTicketSla(
                        `?$filter=TicketId eq ${ticketId} &$orderby=CreatedDate desc&$select=CreatedDate,ModifiedDate,TicketId,SlaStateId&$expand=SlaState,ServiceLevelAgreement($select=Name,IsActive,CreatedDate;$expand=SlaCriterias($select=Id,TimeDigitPart,TimeUnitPart,SlaActionTypeId,CreatedDate))`
                    );

                    let updatedValues = {
                        TicketSlas : slaList,
                        IsSlasFetching : false
                    }
                    // update changed_ticket values - it will update this.state.tickets and this.props.tickets also
                    newticketstate[newticketstateIdx] = {
                        ...newticketstate[newticketstateIdx],
                        ...updatedValues
                    }
                    
                    // update ticket values that are showing in board
                    const newboarditems: TBoardItems = this.updatedBoardItemsFromFormattedLane(
                        currentformattedlane,
                        laneThatChanged,
                        movedItemId,
                        updatedValues
                    );
                    
                    this.setState((prev) => ({
                        ...prev,
                        // k: Math.random().toString(),
                        [ticketKey]: newticketstate,
                        boardItems: newboarditems
                    }));
                }

                const checkClosed = (laneName: string, laneId: number, isTicketClosed: boolean): boolean => {
                    if (laneId > 0 && laneName === "StatusId" && (laneId === getStatusIdByGuid(this.props.ticketprops.status, STATUS_GUID.CLOSED) || laneId === getStatusIdByGuid(this.props.ticketprops.status, STATUS_GUID.DEFLECTED))) {
                        return true;
                    } else if (laneId > 0 && laneName === "StatusId" && laneId !== getStatusIdByGuid(this.props.ticketprops.status, STATUS_GUID.CLOSED)) {
                        return false;
                    } else {
                        return isTicketClosed;
                    }
                }

                type IUpdatedId = 
                    { StatusId : number, AssigneeId?: number } | 
                    { CategoryId: number | null, AssigneeId?: number } | 
                    { PriorityId: number, AssigneeId?: number } | 
                    { SupportGroupId: number | null, AssigneeId: number | null} |
                    { TeamId: number | null, SupportGroupId: number | null, AssigneeId: number | null}
                const updateBoardItems = async (updatedId: IUpdatedId) => { 
                    const updatedValues = {
                        ModifiedDate: DateTime.now().toString(),
                        IsSlasFetching : true,
                        Closed: checkClosed(Object.keys(updatedId)[0], (updatedId['StatusId'] ?? 0), newticketstate[newticketstateIdx].Closed),
                        ...updatedId,
                    }
                    
                    // update changed_ticket values - it will update this.state.tickets and this.props.tickets also
                    newticketstate[newticketstateIdx] = {
                        ...newticketstate[newticketstateIdx],
                        ...updatedValues
                    }
                    
                    // update ticket values that are showing in board
                    const newboarditems: TBoardItems = this.updatedBoardItemsFromFormattedLane(
                        currentformattedlane,
                        laneThatChanged,
                        movedItemId,
                        updatedValues // AssigneeId will be in array
                    );
                    
                    const ticketKey = 'tickets';
                    this.setState((prev) => ({
                        ...prev,
                        // k: Math.random().toString(),
                        [ticketKey]: newticketstate,
                        boardItems: newboarditems
                    }));
                    (async () => {
                        await this.api.updateTicket(movedItemId, {
                            ...updatedId
                        });
                        await getUpdatedSLAs(movedItemId, newticketstate, ticketKey);
                    })();
                }


                switch (this.props.boardFilter) {
                    case 0: {
                        const status = parseInt(laneThatChanged.split('status_')[1]);
                        updateBoardItems({ StatusId: status })
                        break;
                    }
                    case 1: {
                        const category = laneThatChanged == 'no_category' ? null : parseInt(laneThatChanged.split('category_')[1]);
                        updateBoardItems({ CategoryId: category })
                        break;
                    }
                    case 2: {
                        const priority = parseInt(laneThatChanged.split('priority_')[1]);
                        updateBoardItems({ PriorityId: priority })
                        break;
                    }
                    case 3: {
                        const group = laneThatChanged == 'no_group' ? null : parseInt(laneThatChanged.split('group_')[1]);
                        // check if assignee exist in changed_lane
                        const prevAssigneeId = newticketstate[newticketstateIdx].AssigneeId;
                        let isAssigneeExist = false;
                        if (group) {
                            const groupSpecifiedAssignees = this.state.supportgroups.find(supportGroup => supportGroup.Id === group).Members
                            isAssigneeExist = groupSpecifiedAssignees.some(assignee => assignee.Id === prevAssigneeId)
                        } else {
                            isAssigneeExist = true
                        }
                        const AssigneeId = isAssigneeExist ? prevAssigneeId : null;
                        updateBoardItems({ SupportGroupId: group, AssigneeId: AssigneeId })
                        break;
                    }
                    case 4: {
                        const teamId = laneThatChanged == 'no_team' ? null : parseInt(laneThatChanged.split('team_')[1]);
                        const prevGroupId = newticketstate[newticketstateIdx].SupportGroupId;
                        const prevAssigneeId = newticketstate[newticketstateIdx].AssigneeId;
                        let groupId = null;
                        let assigneeId = null;
                        if (teamId) {
                            const team = this.state.teams?.find(o => o.Id === teamId);
                            const teamGroups = this.state.supportgroups?.filter(x => x.TeamsAadObjectId == team?.TeamsAadObjectId) || [];
                            const isGroupExist = teamGroups.some(g => g.Id === prevGroupId);
                            groupId = isGroupExist ? prevGroupId : null;
                        } else {
                            groupId = prevGroupId;
                        }
                        if (groupId) {
                            const groupMembers = this.state.supportgroups?.find(supportGroup => supportGroup.Id === prevGroupId)?.Members || [];
                            const isAssigneeExist = groupMembers.some((assignee: any) => assignee.Id === prevAssigneeId);
                            assigneeId = isAssigneeExist ? prevAssigneeId : null;
                        }
                        else if (teamId) {
                            const teamMemebers = this.state.teams?.find(x => x.Id === teamId)?.Users || [];
                            const isAssigneeExist = teamMemebers.some(assignee => assignee.Id === prevAssigneeId);
                            assigneeId = isAssigneeExist ? prevAssigneeId : null;
                        }
                        else {
                            assigneeId = prevAssigneeId;
                        }
                        updateBoardItems({ TeamId: teamId, SupportGroupId: groupId, AssigneeId: assigneeId })
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
        }
    };
    boardItemCardLayout: IBoardItemCardLayout = {
        previewPosition: 'afterHeader',
        overflowPosition: 'header'
    };
    render() {
        return (
            <div className='h-full'>
                <Board
                    onInteraction={this.board_action}
                    key={this.state.k}
                    items={this.state.boardItems}
                    lanes={this.generateLanes()}
                    users={this.generateUsers(this.state.platformusers)}
                    boardItemCardLayout={this.boardItemCardLayout}
                    isSlaEnabled={this.props.isSlaEnabled}
                    isLifecycleEnabled={this.props.isLifecycleEnabled}
                />
            </div>
        );
    }
}

export default withTranslation()(TaskBoard);
