import React from "react";
import { CheckFeature } from "../../CheckFeature";
import { LICENSE_FEATURE, LIFECYCLE_STATUS_GUID } from "../../../utils/constants";
import { Flex, Text } from "@fluentui/react-northstar";
import { DisplayLifecyclePhases } from "./DisplayLifecyclePhases";
import { PhaseCard } from "./card";
import { TicketLifecyclePhaseElement } from "../../TicketLifecycleTab/TicketLifecyclePhaseRibbon";
import LifecyleIcon from '../../../../../svg/lifecycle.svg';
import AppCSS from '../../../../../../src/components/App.module.css';
import { appState, useSetState } from "../../../../AppState";

export function DisplayLifecycleWithPhases({ ticketData, isAnalyst }: { ticketData: any, isAnalyst: boolean }) {
  const currentstate = appState();
  const setAppState = useSetState();
  const isPastPhase = (phase: TicketLifecyclePhase) => {
    if (phase.IsCurrent) {
      return false;
    }
    else {
      const phases = ticketData["TicketLifecycle"]["Phases"]
      .sort(function(a: TicketLifecyclePhase, b: TicketLifecyclePhase){return a.Order - b.Order})
      .map(function(phase: TicketLifecyclePhase){
        return phase});
      const currentPhaseOrder = phases.find(x=>x.IsCurrent)?.Order;
      if (phase.Order < currentPhaseOrder) return true;
    }
    return false;
  }
    return (
        <>
        <CheckFeature featureName={LICENSE_FEATURE.TicketLifecycle}>
            <Flex vAlign="center" gap="gap.small" className={`${AppCSS['grid-item']}`} >
                <Flex vAlign="center" gap="gap.smaller" styles={{maxWidth: '35%'}}>
                  <LifecyleIcon
                    style={{ marginRight: '5px', fill: '#8f90a6' }}
                    width={16}
                    height={16}
                    stroke="#8f90a6"
                  />
                  <Text size="small" style={{ marginLeft: '4px', ...(isAnalyst ? {textDecoration: 'underline'} : {}) }} 
                    {...(isAnalyst ? {color: "brand"} : {})} weight="semibold" className={`truncate ${isAnalyst ? 'cursor-pointer' : ''}`}
                    onClick={(e) => {
                        if(isAnalyst)
                            setAppState(prevState => ({ ...prevState, lifecycleMenuOpen: true }));
                        e.stopPropagation();
                    }}>
                    {ticketData?.TicketLifecycle?.Lifecycle?.Title ?? ''}
                  </Text>
                </Flex>
                <div className={`w-full truncate`}>
                  {!ticketData["TicketLifecycle"] && (
                    <bdi>None</bdi>
                  )}
                  {ticketData["TicketLifecycle"] && (
                    <>
                    <Flex column className={'cui-pt-4 cui-pv-4'} styles={{justifyContent: 'end'}}>
                    <DisplayLifecyclePhases isForm={false} children={
                      ticketData["TicketLifecycle"]["Phases"]
                        .sort(function(a: TicketLifecyclePhase, b: TicketLifecyclePhase){return a.Order - b.Order})
                        .map(function(phase: TicketLifecyclePhase, index){
                        return (
                          <PhaseCard itemId={phase.IsCurrent ? "phase-card-current" : `phase-card-${index}`} children={
                            <>
                            <div className={`${isAnalyst ? 'cursor-pointer' : ''}`} 
                            title={phase?.Name ?? ''}
                            onClick={(e) => {
                              if(currentstate.lifecyclePhaseMenu != phase.Order && isAnalyst)
                                setAppState(prevState => ({ ...prevState, lifecycleMenuOpen: true, lifecyclePhaseMenu: phase.Order }));
                              e.stopPropagation();
                            }}>
                            <TicketLifecyclePhaseElement 
                              id={`phase-ribbon-${phase.Order}`}
                              extraClassNames={"ml-3.5"}
                              status={phase.IsCurrent ? ticketData?.TicketLifecycle?.Status : {Guid: LIFECYCLE_STATUS_GUID.NOT_STARTED}} 
                              phaseName={phase?.Name ?? ''}
                              isPastPhase={isPastPhase(phase)}/>
                            </div>
                            </>
                          } />
                        )
                      })
                    } />
                    </Flex>
                    </>
                  )}
                </div>
            </Flex>
            </CheckFeature>
        </>
    );
}