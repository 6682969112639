import { useState } from 'react';
import { ITEMS_PER_PAGE } from '../shared/components/table/TableWrapper';

const useCurrentPage = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const onDeleteRowSetCurrentPage = (rowsLength: number) => {
    const rowsLengthAfterDeletion = rowsLength - 1;
    const pageNo =
      rowsLengthAfterDeletion % ITEMS_PER_PAGE == 0
        ? currentPage - 1 || 1
        : currentPage;
    setCurrentPage(pageNo);
  };

  return { currentPage, setCurrentPage, onDeleteRowSetCurrentPage };
};

export default useCurrentPage;
