import { DateTime } from 'luxon';
import { AppStateValue } from '../AppState';
import { getUser } from '../AuthConfig';

import { PlatformUser } from '../shared/interfaces/platformuser.interface';
import { ISupportGroup, ITeam } from '../shared/interfaces/supportGroup.interface';
import { Ticket } from '../shared/interfaces/ticket.interface';
import { TicketProps } from '../shared/interfaces/ticketproperties.interface';
import { FILTERS_STRINGS, FILTERS, USER_TYPE, LIFECYCLE_STATUS_GUID, CSS_CLASS, TicketApprovalState } from '../shared/utils/constants';
import { graphService } from '../shared/services/graph.service';
import { platformService } from '../shared/services/platform.service';
import { ConvertDateToLocale } from '../shared/common/ConvertDate';
import { themeNames } from '@fluentui/react-teams';
import i18next from 'i18next';

const t = i18next.t;

export enum TableFilterOptions {
  AllActive = 0,
  MyWork = 1,
  Unassigned = 2,
  Closed = 3,
  Deflected = 4
}

export enum EditTicketEvent {
  SaveSuccess = 0,
  DeleteSuccess = 1,
  TicketUnassigned = 2,
  TicketTaken = 3,
  TemplateApplied = 4,
  TicketsRelated = 5
}

export const formatFilter = (list: any[]) =>
  list.map(p => ({
    key: p.Id,
    title: p.Value || p.Name,
    selected: false,
    parentId: p.ParentId,
    position: p.Position
  }));

export const customDateFormatter = (date: any) => ConvertDateToLocale(date, DateTime.DATE_SHORT);

export const formatGroup = (list: any[]) =>
  [
    {
      Id: 0,
      Name: t('ticket.ticket-list.bulk-edit.no-group'),
      selected: false
    },
    ...list
  ].map(p => ({
    key: p.Id,
    title: p.Name,
    selected: false
  }));

export const formatTeam = (list: any[]) =>
  [
    {
      Id: 0,
      Name: t('ticket.ticket-list.bulk-edit.no-team'),
      FriendlyName: t('ticket.ticket-list.bulk-edit.no-team'),
      selected: false
    },
    ...list
  ].map(p => ({
    key: p.Id,
    title: p.FriendlyName,
    selected: false
  }));

  export const formatLifecycle = (list: any[]) =>
  [
    {
      Id: 0,
      Title: t('ticket.child-filter.no-lifecycle'),
      selected: false
    },
    ...list
  ].map(p => ({
    key: p.Id,
    title: p.Title,
    selected: false
  }));

export const formatPlatformUser = (users: any[], isCollaborator: boolean = false) =>
  [
    {
      Id: 0,
      FullName: isCollaborator ? t('ticket.ticket-list.bulk-edit.no-collaborators') : t('ticket.ticket-list.bulk-edit.no-assignee'),
      selected: false
    },
    ...users
  ].map(u => ({
    key: u.Id,
    title: u.FullName,
    aadObjectId: u.AadObjectId,
    upn: u.UserName,
    selected: false
  }));

export const threedigitized = (num: number) =>
  num < 10 ? `00${num}` : num < 100 ? `0${num}` : num.toString();

export const getDateWithDay = (date: string) => date ? DateTime.fromISO(date).setLocale(i18next.language).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY) : t('ticket.ticket-board.ticket-card.none');

export const inputItems = {
  status: t('ticket.ticket-board.group-by-filter.status'), 
  category: t('ticket.ticket-board.group-by-filter.category'), 
  priority: t('ticket.ticket-board.group-by-filter.priority'), 
  group: t('ticket.ticket-board.group-by-filter.group'), 
  team: t('ticket.ticket-board.group-by-filter.team'),
};
export const getShortDate = (date: string) => date ? DateTime.fromISO(date).setLocale(i18next.language).toLocaleString(DateTime.DATE_SHORT) : t('ticket.ticket-board.ticket-card.none');


export const laneItemFilter2 = [t('ticket.parent-filter.all-active'), t('ticket.parent-filter.all-closed')];

export enum ListInputItemsEnum {
  'All_Active' = 0,
  'My_Work' = 1,
  'Unassigned' = 2,
  'Closed' = 3,
  'Deflected' = 4,
}

export enum ViewType {
  'list' = 0,
  'board' = 1,
  'chart' = 2,
}

export const listInputItems = {
  [ListInputItemsEnum.All_Active]: t('ticket.parent-filter.all-active'),
  [ListInputItemsEnum.My_Work]: t('ticket.parent-filter.my-work'),
  [ListInputItemsEnum.Unassigned]: t('ticket.parent-filter.unassigned'),
  [ListInputItemsEnum.Closed]: t('ticket.parent-filter.closed'),
  [ListInputItemsEnum.Deflected]: t('ticket.parent-filter.deflected')
};

export const listFilterDate = [
  t('ticket.chart-filter.last-30-days'),
  t('ticket.chart-filter.last-month'),
  t('ticket.chart-filter.last-7-days'),
  t('ticket.chart-filter.this-year'),
  t('ticket.chart-filter.last-year')
];

export const defaultToggleButtonList = [false, false, false];

export const toggleButtonMapper = {
  [ViewType.list]: 'list',
  [ViewType.board]: 'board',
  [ViewType.chart]: 'chart'
};

const dateFilters = [
  {
    key: 0,
    title: t('ticket.child-filter.due-childs.late'),
    selected: false
  },
  {
    key: 1,
    title: t('ticket.child-filter.due-childs.today'),
    selected: false
  },
  {
    key: 2,
    title: t('ticket.child-filter.due-childs.tomorrow'),
    selected: false
  },
  {
    key: 3,
    title: t('ticket.child-filter.due-childs.this-week'),
    selected: false
  },
  {
    key: 4,
    title: t('ticket.child-filter.due-childs.next-week'),
    selected: false
  },
  {
    key: 5,
    title: t('ticket.child-filter.due-childs.future'),
    selected: false
  },
  {
    key: 6,
    title: t('ticket.child-filter.due-childs.no-date'),
    selected: false
  }
];
const valueDateFilters = [
  {
    key: 0,
    title: t('ticket.child-filter.date-childs.earlier-than'),
    selected: false
  },
  {
    key: 1,
    title: t('ticket.child-filter.date-childs.earlier-or-equal'),
    selected: false
  },
  {
    key: 2,
    title: t('ticket.child-filter.date-childs.later-than'),
    selected: false
  },
  {
    key: 3,
    title: t('ticket.child-filter.date-childs.later-or-equal'),
    selected: false
  }
];

export const filterOptions: FilterPropsItems[] = [
  {
    id: FILTERS.DUE.id,
    title: FILTERS.DUE.title,
    key: 'Due',
    values: [...dateFilters]
  },
  {
    id: FILTERS.STATUS.id,
    title: FILTERS.STATUS.title,
    key: 'Status',
    values: []
  },
  {
    id: FILTERS.PRIORITY.id,
    title: FILTERS.PRIORITY.title,
    key: 'Priority',
    values: []
  },
  {
    id: FILTERS.CATEGORY.id,
    title: FILTERS.CATEGORY.title,
    key: 'Category',
    values: []
  },
  {
    id: FILTERS.ASSIGNEE.id,
    title: FILTERS.ASSIGNEE.title,
    key: 'Assignee',
    values: []
  },
  {
    id: FILTERS.TEAM.id,
    title: FILTERS.TEAM.title,
    key: 'Team',
    values: []
  },
  {
    id: FILTERS.GROUP.id,
    title: FILTERS.GROUP.title,
    key: 'Group',
    values: []
  },
  {
    id: FILTERS.TICKET_TYPE.id,
    title: FILTERS.TICKET_TYPE.title,
    key: 'Ticket Type',
    values: []
  },
  {
    id: FILTERS.TAGS.id,
    title: FILTERS.TAGS.title,
    key: 'Tags',
    values: []
  },
  {
    id: FILTERS.RESOLUTION.id,
    title: FILTERS.RESOLUTION.title,
    key: 'Resolution',
    values: [...dateFilters]
  },
  {
    id: FILTERS.REQUESTERS.id,
    title: FILTERS.REQUESTERS.title,
    key: 'Requester',
    values: []
  },
  {
    id: FILTERS.LIFECYCLE.id,
    title: FILTERS.LIFECYCLE.title,
    key: 'Lifecycle',
    values: []
  },
  {
    id: FILTERS.CREATED_DATE.id,
    title: FILTERS.CREATED_DATE.title,
    key: 'CreatedDate',
    values: [...valueDateFilters]
  },
  {
    id: FILTERS.MODIFIED_DATE.id,
    title: FILTERS.MODIFIED_DATE.title,
    key: 'ModifiedDate',
    values: [...valueDateFilters]
  },
  {
    id: FILTERS.AffectedUsers.id,
    title: FILTERS.AffectedUsers.title,
    key: 'Affected Users',
    values: []
  },
  {
    id: FILTERS.TicketCollaborators.id,
    title: FILTERS.TicketCollaborators.title,
    key: 'Collaborators',
    values: []
  }
];

export const getActiveStatusId = (statusList: TicketProps[]) => {
  let activeStatus = statusList.find((status: TicketProps) => {
    return status.Value.toLowerCase() == 'active';
  });
  if (typeof activeStatus === 'undefined') {
    activeStatus = statusList[0];
  }
  return activeStatus.Id;
};

export const getMyWork = (items: Ticket[], users: PlatformUser[]): Ticket[] => {
  let currentUserName = getUser().toLowerCase();

  let currentUser = users.find(user => user.UserName.toLowerCase() == currentUserName);
  return items.filter(item => item.AssigneeId == currentUser.Id);
};

export const getStatusIdByValue = (
  statusList: TicketProps[],
  stValue: string
) => {
  let status = statusList.find((s: TicketProps) => {
    return s.Value.toLowerCase().includes(stValue.toLowerCase());
  });
  if (!status) {
    status = statusList[statusList.length - 1];
  }

  return status ? status.Id : 0;
};
export const getStatusIdByGuid = (statusList: TicketProps[], guidValue: string) => {
  let status = statusList.find((s: TicketProps) => s.Guid == guidValue);
  return status ? status.Id : 0;
};

export const getTaskStatusIdByGuid = (statusList: TaskStatus[], guidValue: string) => {
  let status = statusList.find((s: TaskStatus) => s.Guid == guidValue);
  if (!status) status = statusList[statusList.length - 1];
  return status ? status.Id : 0;
};

export const getTaskStatusValueByGuid = (statusList: TaskStatus[], guidValue: string) => {
  let status = statusList.find((s: TaskStatus) => s.Guid == guidValue);
  if (!status) status = statusList[statusList.length - 1];
  return status ? status.Value : '';
};

export const getCategoryValue = (
  categoryId: number,
  categoryList: TicketProps[]
): string => {
  let category = categoryList.find(c => {
    return c.Id == categoryId;
  })?.Value;

  if (typeof category == 'undefined') {
    category = 'No Category';
  }

  return category;
};

export const getTicketTypeValue = (typeId: number, ticketTypeList: TicketProps[]): string => {
  let ticketType = ticketTypeList.find(t => t.Id == typeId)?.Value;
  if (typeof ticketType == 'undefined') ticketType = 'None';
  return ticketType;
};

export const getStatusValue = (
  statusId: number,
  statusList: TicketProps[]
): string => {
  let status = statusList.find(s => {
    return s.Id == statusId;
  })?.Value;

  return status;
};

export const getPriorityValue = (
  priorityId: number,
  priorityList: TicketProps[]
): string => {
  let priority = priorityList.find(p => {
    return p.Id == priorityId;
  })?.Value;

  return priority;
};

export const getSupportGroupName = (
  groupId: number,
  groupList: ISupportGroup[]
): string => {
  let group = groupList.find(s => {
    return s.Id == groupId;
  })?.Name;

  return group;
};

export const getTeamName = (
  teamId: number,
  teamList: ITeam[]
): string => {
  return teamList.find(s => {
    return s.Id == teamId;
  })?.FriendlyName;
};

export const getTicketTypeName = (
  ticketTypeId: number,
  ticketTypeList: TicketProps[]
): string => {
  let ticketType = ticketTypeList.find(s => {
    return s.Id == ticketTypeId;
  })?.Value;

  return ticketType;
};

export const getUserData = (user_id: MaybeNull<number>, state: AppStateValue) =>
  state.platformusers.find(user => user.Id == user_id);

export const getStatus = (statusId: number, ticketStatus: TicketProps[]) => 
  ticketStatus.find(c => c.Id == statusId);

export const isCloseFilter = (selectedOption: number) =>
  selectedOption === TableFilterOptions.Closed || selectedOption === TableFilterOptions.Deflected;

export const getCategory = (
  category_id: MaybeNull<number>,
  categories: TicketProps[]
) => categories.find(c => c.Id == category_id);

export const getTicketType = (
  ticketType_id: MaybeNull<number>,
  ticketType: TicketProps[]
) => ticketType.find(c => c.Id == ticketType_id);

export const getPriority = (
  priority_id: MaybeNull<number>,
  priority: TicketProps[]
) => priority.find(c => c.Id == priority_id);

export const getGroup = (group_id: MaybeNull<number>, state: AppStateValue) =>
  state.supportgroups.find(c => c.Id == group_id);

export const filterPlatformUsersInRole = (platformUsers: PlatformUser[], usersInRole: any[]) => {
  let usersInRoleEmails = usersInRole.map(user => user.Email ?? user.UserName);
  return platformUsers?.filter((m) => usersInRoleEmails.includes((m.Email ?? m.UserName)));
}
export const getTeam = (team_id: MaybeNull<number>, state: AppStateValue) =>
  state.teams.find(c => c.Id == team_id);

export const getLifecycle = (lifecycle_id: MaybeNull<number>, state: AppStateValue) =>
  state.lifecycles.find(c => c.Id == lifecycle_id);

export const getCommentsCount = (list: any) => {
  let count = 0;
  list.forEach((ticket: any) => count = count + (ticket.Comments ? ticket.Comments.length : 0));
  return count;
}

export const getDropdownBoxShadow = ({ siteVariables }: any) => {
  const color = siteVariables.theme == themeNames.Dark ?
    siteVariables.colorScheme.default.border1 : siteVariables.colorScheme.default.border2;
  return `${color} 0px 0.8rem 1rem -0.2rem`;
}

export const toolbarButtonColor = (isUserLicensed: boolean, colorScheme: any) => {
  return isUserLicensed ? colorScheme.brand.foreground : colorScheme.default.foregroundDisabled
}

export const getTemplateCardBgColor = (theme: any) => theme === themeNames.Dark ? 'rgb(51, 51, 51)' : '#fafafa';

export const checkIfApprovalNeedsUpdation = async (api: platformService, approvalToBeUpdated: Approval) => {
  if (approvalToBeUpdated.IsRequiredByAll) {
    return true;
  }
  else {
    const approval = await api.getApproval(approvalToBeUpdated.Id);
    if (approval[0].ApprovalState === TicketApprovalState.Pending) {
      return true;
    }
  }
  return false;
}

export const getApprovalStatus = (approval: any) => {
  if (approval.IsRequiredByAll) {
    const isApprovedByAll = approval?.Approvers.every((approver: any) => approver.HasApproved === TicketApprovalState.Approved);
    const isRejectedByAnyOne = approval?.Approvers.some((approver: any) => approver.HasApproved === TicketApprovalState.Rejected);
    if (isApprovedByAll) return TicketApprovalState.Approved;
    else return isRejectedByAnyOne ? TicketApprovalState.Rejected : TicketApprovalState.Pending;
  }
  else return approval.ApprovalState;
}

export const getUpdatedApprovalStatus = (approval: any) => {
  const hasAnyoneRejected = approval?.Approvers.some((approver: any) => approver.HasApproved === TicketApprovalState.Rejected);
  if (hasAnyoneRejected) return TicketApprovalState.Rejected;
  else {
    if (approval.IsRequiredByAll) {
      let checkIfApproved = approval?.Approvers.every((approver: any) => approver.HasApproved === TicketApprovalState.Approved);
      if (checkIfApproved) return TicketApprovalState.Approved;
    }
    else {
      let checkAllNotRequired = approval.Approvers.some((approver: any) => (approver.HasApproved === TicketApprovalState.Approved));
      if (checkAllNotRequired) return TicketApprovalState.Approved;
    }
    return TicketApprovalState.Pending;
  }
}

export const updateApproval = async (api: platformService, approval: Approval, userId: number, status: TicketApprovalState, t: any) => {

  const approverId = approval.Approvers.find(approver => approver.ApproverId === userId)?.Id;
  const approver = await api.getApprover(approverId);
 
  approval.Approvers.forEach(o => {
    if (o.ApproverId == userId) o.HasApproved = status;
  })

  const updatedApprovalState = getUpdatedApprovalStatus(approval);

  if (approver.HasApproved === TicketApprovalState.Pending) {
    const apis = [api.updateTicketApprover({ HasApproved: status }, approver.Id)];
    
    if (updatedApprovalState !== TicketApprovalState.Pending) {
      let isUpdateRequired = await checkIfApprovalNeedsUpdation(api, approval);
      if (isUpdateRequired) apis.push(api.updateTicketApproval({ ApprovalState: updatedApprovalState }, approval.Id));
    }

    await Promise.all(apis);

    return {
      IsError: false,
      UpdatedStatus: updatedApprovalState,
      Message: `${status === TicketApprovalState.Approved ? t('common.approval-confirm-message') : t('common.rejection-confirm-message')}`
    };
    
  }
  else {
    return {
      IsError: true,
      UpdatedStatus: updatedApprovalState,
      Message: t('ticket-details.edit-ticket-form.approval.error.already-responded')
    };
  }
}

export const updateApprovalList = (
  approvals: TicketApprovals[],
  approverId: number,
  approvalId: number,
  state: TicketApprovalState,
  updatedStatus: TicketApprovalState
) => {
  let updatedApprovals = [...approvals];
  updatedApprovals.forEach(approval => {
    if (approval.Approval.Id === approvalId) {
      approval.Approval.ApprovalState = updatedStatus;
      approval.Approval.Approvers.forEach((p: any) => {
        if (p.ApproverId == approverId) {
          const currentDate = new Date();
          p.ModifiedDate = currentDate.toISOString();
          p.HasApproved = state;
        }
      })
    }
  });
  return updatedApprovals;
}

export const loadFilters = async (api: platformService, graphAPI: graphService, state: AppStateValue, setState: any) => {
  
  const results = await Promise.all([
    api.getStatus(FILTERS_STRINGS.FILTERS_URL_PARAMS),
    api.getPriority(FILTERS_STRINGS.FILTERS_URL_PARAMS),
    api.getCategories(FILTERS_STRINGS.FILTERS_URL_PARAMS),
    api.getTicketTypesSafe(FILTERS_STRINGS.FILTERS_URL_PARAMS),
    api.getTeamsSafe(FILTERS_STRINGS.TICKET_TEAMS),
    api.getSupportGroups(FILTERS_STRINGS.TICKET_GROUPS),
    api.getUserList(),
    api.getUsersInRole(),
    api.getTags(FILTERS_STRINGS.DELETE_FALSE),
    api.getTaskStatus(`${FILTERS_STRINGS.FILTERS_URL_PARAMS} and IsPowerAutomateStatus eq false`),
    api.getLifecyclesSafe(`${FILTERS_STRINGS.FILTERS_URL_PARAMS}&$select=Id,Title`),
    api.getDefaultTeamUnrestricted(),
    api.getLifecycleStatus(FILTERS_STRINGS.ARCHIVE_FALSE),
    api.getSource(FILTERS_STRINGS.FILTERS_URL_PARAMS)
  ]);
  const defaultTeam: ITeam = JSON.parse(results[11].data.value);
  const defaultSG: ISupportGroup[] = (defaultTeam) ? results[5].data.value.filter(groups => groups.TeamsAadObjectId === defaultTeam.TeamsAadObjectId && groups.TeamsChannelId === defaultTeam.TeamsChannelId) : results[5].data.value;
  
  const newState = {
    ticketStatus: results[0].data.value,
    priority: results[1].data.value,
    categories: results[2].data.value,
    ticketTypes: results[3],
    teams: results[4],
    supportgroups: results[5].data.value,
    platformusers: results[6],
    usersInRole: JSON.parse(results[7].data.value),
    tags: results[8],
    taskStatus: results[9],
    lifecycles: results[10],
    lifecycleStatus: results[12].data.value,
    defaultTeam: defaultTeam,
    defaultSupportGroup: defaultSG?.[0],
    source: results[13].data.value
  };
  loadTeamMembers(graphAPI, setState, newState.teams, newState.platformusers);
  setState((prevState: AppStateValue) => ({ ...prevState, ...newState }));

  return newState as AppStateValue;
}

export const getPortalSource = async()=>{
  const api = new platformService();
  let sourceList = (await api.getSource(FILTERS_STRINGS.ARCHIVE_FALSE)).data.value;
  return sourceList.find(x=>x.Guid=='6c844bb5-ee9f-41d8-940b-2d4eac39112c');
}
export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
export const getUserFromToken = async () => {
  const jwt = parseJwt(localStorage.getItem('accessToken'));
  const idjwt = parseJwt(localStorage.getItem('token'));

  const payload = {
    FullName: jwt['name'],
    GivenName: jwt['given_name'],
    FamilyName: jwt['family_name'],
    Email: idjwt['email'],
    UserName: jwt['upn'],
    AadObjectId: jwt['oid']
  };

  const api = new platformService();
  const requester = await api.getOrCreatePlatformUser(payload);
  delete requester['@odata.context'];

  return requester;
}

export const loadTeamMembers = async (graphAPI: graphService, setState: any, teams: ITeam[], platformUsers: PlatformUser[]) => {
  try {
    const expandedTeams = [];
    
    for (const team of teams) {
      team.Users = [];
      if (team.TeamsAadObjectId && team.TeamsAadObjectId !== "") {
        const graphGroup: any[] = (await graphAPI.getTeamMembers(team.TeamsAadObjectId)).data.value;
        if (graphGroup.length > 0) 
          team.Users = getTeamMembers(graphGroup, platformUsers);
      }
      expandedTeams.push(team);
    }

    const newState = { teams: expandedTeams };
    setState((prevState: AppStateValue) => ({ ...prevState, ...newState }));
  } catch { }
};

const getTeamMembers = (graphGroup: any[], platformUsers: PlatformUser[]) => {
  let users = graphGroup.map(member => 
    platformUsers.find(u => (u.AadObjectId?.toLocaleLowerCase() ?? "") == member["id"]?.toLowerCase() || u.Email?.toLowerCase() == member["mail"]?.toLowerCase() || u.UserName?.toLowerCase() == member["userPrincipalName"]?.toLowerCase()));

  return users.filter(Boolean).sort((a, b) => (sortTeamMembers(a.FullName, b.FullName)));
}

const sortTeamMembers = (userNameA: string, userNameB: string) => {
  if (userNameA > userNameB) return 1;
  else if (userNameB > userNameA) return -1;
  else return 0;
};

export const formatAffectedUserLikeCollaborators = (usersList: PlatormUserEntity[]) => {
  return usersList?.map(user => {
    return {
      Id: user.Id,
      FullName: user.FullName,
      Email: user.Email,
      Guid: user.Guid,
      ...user
    };
  });
};

export const foramtRequesterLikeAssignee = (user: Requester) => {
  return {
    Id: user.Id,
    FullName: user.FullName,
    Email: user.Email,
    Guid: user.Guid,
    ...user
  };
};

export const mergedCollaboratorAndAffectedUsers = (
  affectedUsers: PlatormUserEntity[],
  requester: Requester
) => {
  let mergedArray = [];
  if(affectedUsers?.length > 0) {
    mergedArray = [...mergedArray, ...affectedUsers]
  }
  if(requester) {
    mergedArray = [...mergedArray, requester]
  }
  return mergedArray;
};

export const mergedCollaboratorOrAffectedUsers = (usersList: PlatormUserEntity[], user: PlatormUserEntity, userType: string) => {
  let mergedList = [];

  if (user) {
    user['userType'] = userType === USER_TYPE.COLLABORATOR ? USER_TYPE.ASSIGNEE : USER_TYPE.REQUESTER;
    mergedList = [user, ...mergedList];
  }

  if (usersList?.length > 0) {
    usersList.forEach(u => { u['userType'] = userType; });
    mergedList = [...mergedList, ...usersList];
  }

  return mergedList;
}


export const getLifecycleClass = (status: LifecycleStatus) => {
  if(status == null)
    return "";

  switch (status.Guid){
    case LIFECYCLE_STATUS_GUID.IN_PROGRESS:
      return CSS_CLASS.LIFECYCLE_INPROGRESS;
    case LIFECYCLE_STATUS_GUID.COMPLETED:
      return CSS_CLASS.LIFECYCLE_COMPLETED;
    case LIFECYCLE_STATUS_GUID.FAILED:
      return CSS_CLASS.LIFECYCLE_FAILED;
    default:
      return CSS_CLASS.LIFECYCLE_NOTSTARTED;
  }
}
export const getStatusNameByGuid = (
  statusList: TicketProps[],
  guidValue: string
) => {
  let status = statusList.find((s: TicketProps) => s.Guid == guidValue);
  if (!status) status = statusList[statusList.length - 1];
  return status ? status.Value : '';
};

export const refershPlatformUserState = async (newItem: any, state: AppStateValue, setState: any) => {

  // Check if the element already exists based on 'id'
  const updatedList = state.platformusers.some(item => item.Id === newItem.Id)
  ? state.platformusers.map(item => item.Id === newItem.Id ? newItem : item) // Replace if exists
  : [...state.platformusers, newItem]; // Add if doesn't exist

  const newState = {
    platformusers: updatedList
  };

  setState((prevState: AppStateValue) => ({ ...prevState, ...newState }));
};