import {
    Datepicker,
    pxToRem,
    Flex,
    Text,
    ComponentEventHandler,
    DatepickerProps,
    ShorthandValue,
    InputProps
  } from '@fluentui/react-northstar';
  import React from 'react';
import { customDateFormatter } from '../../../tikit/ticketHelper';
import { DatepickerLocalizations } from '../../../App';

  

export const TicketDatePicker = (isDisabled:boolean, selectedDate?: Date, 
    input?: ShorthandValue<InputProps>,
    onDateChange?: ComponentEventHandler<DatepickerProps & {
        value: Date;
      }> ) =>{


    const calendarChildren = (ComponentType, props) => {
      return (
        <Flex space="between" hAlign="center">
          <Datepicker.CalendarHeaderAction
            onClick={props.onPreviousClick}
            direction="previous"
            title={props.prevMonthAriaLabel}
            disabled={props.disabledPreviousButton}
          />
          <Text
            content={props.label}
            styles={{
              paddingTop: pxToRem(5)
            }}
          />
          <Datepicker.CalendarHeaderAction
            onClick={props.onNextClick}
            direction="next"
            title={props.nextMonthAriaLabel}
            disabled={props.disabledNextButton}
          />
        </Flex>
      );
    }

    return (
      <Datepicker
        {...DatepickerLocalizations}
        firstDayOfWeek={0}
        disabled={isDisabled}
        className={`fluent-date`}
        formatMonthDayYear={customDateFormatter}
        selectedDate={ selectedDate }
        onDateChange={onDateChange}
        input={input==null ? <></> : input}
        calendar={{
          header: {
            children: calendarChildren
          }
        }}
      />
    );
  }