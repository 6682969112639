import React, { useEffect, useState } from 'react';
import Styles from './FilterFields.module.css';

import { Button, ProviderConsumer as FluentUIThemeConsumer, ThemePrepared } from '@fluentui/react-northstar';
import DeleteIcon from '../../../../../svg/delete.svg';
import { AutomationOperators } from '../../../../shared/utils/constants';
import { ValueField } from '../../../../shared/components/ValueField/ValueField';
import { OperatorField } from './_/OperatorField/OperatorField';
import { PropertyField } from '../../../../shared/components/PropertyField/PropertyField';
import { DatePicker } from '../../../../shared/components/DatePicker/DatePicker';
import { DatepickerLocalizations } from '../../../../App';
import { useTranslation } from 'react-i18next';

interface Props {
  propertiesList: DropdownDataModel[];
  filterData?: CriteriaMetaData;
  filterIndex: number;
  updateFilterData?: (filterIndex: number, data: CriteriaMetaData) => void;
  updatePropertyData?: (
    filterIndex: number,
    data: AutomationDoTicketInfo
  ) => void;
  removeFilter?: (filterIndex: number) => void;
  globalTheme: ThemePrepared
}

export const FilterFields = ({
  propertiesList,
  filterData,
  filterIndex,
  updateFilterData,
  removeFilter,
  globalTheme
}: Props) => {
  const [property, setProperty] = useState('');
  const [operator, setOperator] = useState('');
  const [value, setValue] = useState('');

  const [showTextField, setShowTextField] = useState(false);
  const [showDateField, setShowDateField] = useState(false);
  const [disableValueField, setDisableValueField] = useState(true);

  const { t } = useTranslation();

  React.useEffect(() => {
    setProperty(filterData?.property ?? '');
    setOperator(filterData?.operator ?? '');
    // @ts-ignore
    setValue(filterData?.value ?? '');
  }, [filterData]);

  // to set showTextField value
  useEffect(() => {
    const showTextFieldRule =
      (property === 'comments' && [(AutomationOperators.Contains.toLowerCase(), AutomationOperators.NotContains.toLowerCase())].includes(operator.toLowerCase())) ||
      (property === 'title' && 
        [
          AutomationOperators.Equal.toLowerCase(),
          AutomationOperators.NotEqual.toLowerCase(),
          AutomationOperators.Contains.toLowerCase(),
          AutomationOperators.NotContains.toLowerCase(),
          AutomationOperators.EndsWith.toLowerCase(),
          AutomationOperators.NotEndsWith.toLowerCase(),
          AutomationOperators.StartsWith.toLowerCase(),
          AutomationOperators.NotStartsWith.toLowerCase()
        ].includes(operator.toLowerCase())) ||
      property.toLowerCase().includes('comment');
    setShowTextField(showTextFieldRule);
  }, [property, operator]);

  // to disable value field
  useEffect(() => {
    const disableValueFieldRule = [
      AutomationOperators.IsNull.toLowerCase(),
      AutomationOperators.IsNotNull.toLowerCase(),
      AutomationOperators.IsEmpty.toLowerCase(),
      AutomationOperators.IsNotEmpty.toLowerCase(),
      AutomationOperators.AddedByRequester.toLowerCase(),
      AutomationOperators.AddedByAssignee.toLowerCase(),
      AutomationOperators.AddedbyAgent.toLowerCase()
    ].includes(operator.toLowerCase());

    setDisableValueField(disableValueFieldRule);
  }, [operator]);

  // to show data field
  useEffect(() => {
    if (property.toLowerCase().includes('date')) {
      setShowDateField(true);
      setDisableValueField(false);
    } else {
      setShowDateField(false);
    }
  }, [property]);

  const resetOperatorAndValueFields = () => {
    setOperator('');
    setValue('');
    setDisableValueField(true);
  };

  const onPropertyChange = (_property: string) => {
    setProperty(_property);

    updateFilterData(filterIndex, {
      ...filterData,
      property: _property,
      operator: '',
      value: ''
    });

    resetOperatorAndValueFields();
  };

  const onOperatorChange = (_operator: string) => {
    setOperator(_operator);
    updateFilterData(filterIndex, { ...filterData, operator: _operator });
    setValue('');
  };

  const onValueChange = (_value: string) => {
    setValue(_value);

    updateFilterData(filterIndex, { ...filterData, value: _value });
  };

  return (
    <FluentUIThemeConsumer
      render={globalTheme => (
        <>
          <div className={`${Styles.GroupInputsContainer} ${Styles.Line}`}>
            {/* Property */}
            <PropertyField
              propertiesList={propertiesList}
              property={property}
              updateProperty={onPropertyChange}
            />

            {/* Operator */}

            <OperatorField
              propertyValue={property}
              operatorValue={operator}
              updateOperator={onOperatorChange}
            />

            {/* Value */}
            {showDateField ? (
              <DatePicker
                {...DatepickerLocalizations}
                dateValue={value}
                disableValueField={disableValueField}
                onChangeHandler={_dateValue => {
                  onValueChange(_dateValue);
                }}
              />
            ) : (
              <ValueField
                fieldOperatorShow
                showTextField={showTextField}
                propertyValue={property}
                automationValue={value}
                operatorValue={operator}
                disableValueField={disableValueField}
                updateValue={onValueChange}
              />
            )}
          </div>
          <div className={Styles.CloseIcon}>
            <Button
              icon={<DeleteIcon height={14} width={14} stroke={`${globalTheme.siteVariables.colorScheme.brand.foreground}`} />}
              text
              primary
              content={
                <span style={{ color: globalTheme.siteVariables.colorScheme.brand.foreground, fontSize: '12px' }}>{t('automation.automation-filter.remove')}</span>
              }
              onClick={() => {
                removeFilter(filterIndex);
              }}
              color={`${globalTheme.siteVariables.colorScheme.brand.foreground}`}
            />
          </div>
        </>
      )}
    />
  );
};
