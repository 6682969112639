import React, { useEffect, useState } from "react";
import { ProviderConsumer, Flex, Input, SearchIcon, Button, AddIcon, Card, CardHeader, CardBody, Text, EditIcon, Checkbox, RadioGroup, Alert, InfoIcon, FlexItem } from "@fluentui/react-northstar";
import { ITemplateFormProperties } from "./TicketTemplates";
import { AdaptiveCardElement } from "./AdaptiveCardElement";
import defaultCards from "../../data/defaultCards.json";
import MgtCss from '../mgt.module.css';
import { TEMPLATE } from "../shared/utils/constants";
import { mergeStyleSets } from "@fluentui/react";
import { useTranslation } from "react-i18next";

interface Props{
  templateProps: ITemplateFormProperties, 
  isUpdate?: boolean | true 
}

const classNames = mergeStyleSets({
  customForms: {
    '.ac-textBlock:not(.ac-textBlock ~ .ac-textBlock)': {
      maxWidth: '90%'
    }
  }
})

export const SetDefault_CustomForm = (props: Props) => {
  const {t} = useTranslation();
  const { templateProps, isUpdate } = props;
  const { templateData } = templateProps;
  const [ selectedCardIndex, setSelectedCardIndex ] = useState(null);
  const [ selectedOption, setSelectedOption ] = useState("scratch");
  const [ cards, setCards ] = useState(defaultCards);

  useEffect(() => {
    templateData.CardResponseJson = "";
    setCards((currentItems) => {
      return currentItems.map((cardData) => {
        cardData["hidden"] = false;
        return cardData;
      });
    });
  }, []);

  useEffect(() => {
    if(selectedOption == "default" && selectedCardIndex == null)
      templateData.errorFormsArray?.push("NoSelectedCardIndex");
    else{
      templateData.errorFormsArray = templateData.errorFormsArray?.filter(x => x != "NoSelectedCardIndex");
      templateData.validateForms = false;
    }
  }, [selectedOption, selectedCardIndex]);
  
  const cardItems = [
    {
      name: 'cardItems',
      key: 'CardItem',
      value: 'cardItem',
    }
  ];

  const handleSearch = (event: any) => {
    const target = event.target.value.toString() || "";
    setCards((currentItems) => {
      if(target.length > 0){
      let re = new RegExp(target, 'i');
      const matches = defaultCards.map((json) => {
        const match = JSON.stringify(json).match(re)
        json["hidden"] = !(match && match.length > 0)
        return json;
      });
      return  matches;
      }else
        return cards.map((json) => {json["hidden"]=false; return json;});
    });
  };

  const onSelectCard = (checked: boolean, index: number, cardJson: any) => {
    if(!checked){
      setSelectedCardIndex(null);
      templateData.CardResponseJson = "";
    }else{
      templateData.validateForms = false;
      setSelectedCardIndex(index);
      delete cardJson["hidden"];
      templateData.CardResponseJson = JSON.stringify(cardJson);
    }
  }

  const renderItems = cards.map((cardJson, index) => (
    <>
    <Card compact ghost styles={{width: '46%', marginTop: '1rem', "&:hover": {background: 'transparent'}, height: 'fit-content'}} 
      hidden={cardJson["hidden"]} onClick={() => {
        onSelectCard(selectedCardIndex != index, index, cardJson);
      }}>
      <Card.TopControls style={{zIndex: 1}}>
      <Checkbox 
        checked={selectedCardIndex == index}
        onChange= {(e, data) => {
          onSelectCard(data.checked, index, cardJson);
        }} />
      </Card.TopControls>
      <Card.Body fitted>
        <AdaptiveCardElement jsonString={JSON.stringify(cardJson)} cardProps={{styles: {height: '350px', overflow: 'hidden', pointerEvents: 'none'}, selected: selectedCardIndex == index}} containerClassName={classNames.customForms} clear={true}/>
      </Card.Body>
    </Card>
  </>
  ));

  const chooseItems = [
    {
      name: 'templateType',
      key: 'Scratch',
      label: (isUpdate) ? TEMPLATE.START_FROM_SCRATCH : TEMPLATE.CREATE_FROM_SCRATCH,
      value: 'scratch',
    },
    {
      name: 'templateType',
      key: 'Default',
      label: (isUpdate) ? TEMPLATE.START_FROM_DEFAULT : TEMPLATE.CREATE_FROM_DEFAULT,
      value: 'default'
    },
    {
      name: 'templateType',
      key: 'NoCustomForm',
      label: "No Custom Form",
      value: 'noCustomForm'
    }
  ];

  return (
    <ProviderConsumer render={(globalTheme) => (
        <>
          <Flex column style={{flex: 1, maxHeight: '70vh', overflow: 'auto'}} gap="gap.large">
            <Flex.Item>
              <Flex column>
              <Text content={(isUpdate) ? TEMPLATE.UPDATE_CUSTOM_FORM :TEMPLATE.CREATE_CUSTOM_FORM} weight="bold" size="large" />
              <Text content={(isUpdate) ? TEMPLATE.UPDATE_CUSTOM_FORM_DESCRIPTION: TEMPLATE.CREATE_CUSTOM_FORM_DESCRIPTION}  />
              </Flex>
            </Flex.Item>
            <Flex.Item>
              <Flex column gap="gap.small">
                <RadioGroup
                  vertical
                  items={chooseItems}
                  defaultCheckedValue={selectedOption}
                  onCheckedValueChange={(e, data) => {
                      setSelectedOption(data["value"].toString())
                      templateData.NoCustomForm = false;
                      if(data["value"].toString() == "scratch"){
                        setSelectedCardIndex(null);
                        templateData.CardResponseJson = "";
                      } 
                      else if (data["value"].toString() == "noCustomForm"){
                        setSelectedCardIndex(null);
                        templateData.NoCustomForm = true;
                        templateData.CardResponseJson = "";
                      }
                    }
                  }
                />
              </Flex>
            </Flex.Item>
            <Flex.Item>
              <div>
              <Flex vAlign="center" {...(selectedOption != "default") && ({className: MgtCss.disabledContainer})}>
                <Text content={TEMPLATE.DEFAULT_FORMS} weight="bold" />
                <Flex.Item push>
                  <Input icon={<SearchIcon />} placeholder={t('template.search')} onChange={handleSearch}/>
                </Flex.Item>
              </Flex>
              {templateData.validateForms && (
                <Alert className="mt-2" danger icon={<InfoIcon />} content={
                    <>
                    <Text content={TEMPLATE.ERROR_NO_CARD_SELECTED} />
                  </>
                } />
              )}
              </div>
            </Flex.Item>
            
            <Flex.Item>
              <Flex gap="gap.medium" className={MgtCss.cardSelectionContainer}
                {...(selectedOption != "default") && ({className: `${MgtCss.cardSelectionContainer} ${MgtCss.disabledContainer}`})}>
                {renderItems}
              </Flex>
            </Flex.Item>
            </Flex>
        
        </>
    )} />
  )
}