import React from "react";
import { FormLeftArrow, FormRightArrow, LeftArrow, RightArrow } from "./arrows";
import usePreventBodyScroll from "./usePreventBodyScroll";
import "./hideScrollbar.css";
import { VisibilityContext, ScrollMenu } from 'react-horizontal-scrolling-menu';

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

interface DisplayLifecycleProps {
  isForm?: boolean;
  children: React.ReactElement[];
}

function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

export function DisplayLifecyclePhases({ children, isForm = false }: DisplayLifecycleProps) {
  const { disableScroll, enableScroll } = usePreventBodyScroll();

  // NOTE: apiRef
  const apiRef = React.useRef({} as scrollVisibilityApiType);

  const scrollToCurrentPhase = () => {
    apiRef.current.scrollToItem(
      apiRef.current.getItemById("phase-card-current"),
      "auto",
      "center"
    );
  };
  
  return children && children.length > 0 ? (
    <div onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
      {isForm ? <ScrollMenu
          apiRef={apiRef}
          LeftArrow={FormLeftArrow}
          RightArrow={FormRightArrow}
          options={{
              ratio: 0.9,
              rootMargin: "5px",
              threshold: [0.01, 0.05, 0.5, 0.75, 0.95, 1]
          }}
          onInit={scrollToCurrentPhase}
      >
          {children}
      </ScrollMenu> : <ScrollMenu
          apiRef={apiRef}
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          options={{
              ratio: 0.9,
              rootMargin: "5px",
              threshold: [0.01, 0.05, 0.5, 0.75, 0.95, 1]
          }}
          onInit={scrollToCurrentPhase}
      >
          {children}
      </ScrollMenu>}
    </div>
  ) : (<></>);
}
