import React from 'react';
import { RenderItemParams } from '@atlaskit/tree';
import { ProviderConsumer as FluentUIThemeConsumer } from '@fluentui/react-northstar';
import ToolbarCSS from '../../../../Toolbar.module.css';
import { ColumnDragSvg, TextSortAscending, TextSortDescending } from '../../../../../../../svg';
import { EnumSortOrderType } from '../../../../CustomViewContextProvider';
import { NON_SORTABLE_COLUMNS } from '../../../../../../shared/utils/constants';

interface IProps {
  customViewStates: CustomViewStatesType;
  setCustomViewStates: React.Dispatch<React.SetStateAction<CustomViewStatesType>>;
}

interface ISortIconProps {
  colorScheme: any;
  itemId: any;
  customViewStates: CustomViewStatesType;
  chooseSortOrder: (type: EnumSortOrderType) => () => void;
}

export const ColumnDragItem = ({ customViewStates, setCustomViewStates }: IProps) =>
({ item, provided, snapshot }: RenderItemParams) => {
  
  const chooseSortOrder = (type: EnumSortOrderType) => () => {
    setCustomViewStates(
      (prev): CustomViewStatesType => ({
        ...prev,
        columns: {
          ...prev.columns,
          sortOrder: type ? {
            orderBy: item.data.id,
            orderType: type,
          } : null,
        },
      })
    );
  };

  return (
    <FluentUIThemeConsumer
      render={({ siteVariables: { colorScheme } }) => {
        return (
          <div
            ref={provided.innerRef}
            className={`py-2 flex items-center justify-between mb-2.5 rounded-sm ${ToolbarCSS['ColumnItem']}`}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style, height: '40px',
              ...({ backgroundColor: snapshot?.isDragging ? colorScheme.default.background5 : colorScheme.default.background2 })
            }}
          >
            <div className='flex items-center'>
              <span className="mx-2.5">
                <ColumnDragSvg />
              </span>
              <span className="font-semibold text-sm">
                {item.data ? item.data?.label : ''}
              </span>
            </div>
            {(item.data && !NON_SORTABLE_COLUMNS.includes(item.data.label)) && (
              <div className='flex cursor-pointer align-center'>
                <SortIcon 
                  colorScheme={colorScheme}
                  customViewStates={customViewStates}
                  itemId={item.data.id}
                  chooseSortOrder={chooseSortOrder}
                />
              </div>
            )}
          </div>
        );
      }}
    />
  )
};

const SortIcon = ({ colorScheme, customViewStates, itemId, chooseSortOrder }: ISortIconProps) => {
  const isSelected = customViewStates.columns.sortOrder?.orderBy == itemId;
  return (isSelected && customViewStates.columns.sortOrder?.orderType === EnumSortOrderType.asc) ? <>
    <div className={isSelected && `${ToolbarCSS['SortBtnSelected']}`}
      onClick={chooseSortOrder(EnumSortOrderType.none)}>
      <TextSortAscending fill={colorScheme.brand.foreground} />
    </div>
  </> : <>
    <div className={`${ToolbarCSS[isSelected ? 'SortBtnSelected' : 'SortBtn']}`}
      onClick={chooseSortOrder(isSelected ? EnumSortOrderType.asc : EnumSortOrderType.desc)}>
      <TextSortDescending fill={isSelected ? colorScheme.brand.foreground : colorScheme.default.borderDisabled} />
    </div>
  </>
}