import React from 'react';
import { RelationCard } from '../RelationCard/RelationCard';
import { IRelationType, RelationType } from '../RelateTicketHelper';
import RelatedIcon from '../../../../../svg/related-icon.svg';
import MergedIcon from '../../../../../svg/merged.svg';
import {  i18n } from '../../../../../components/shared/utils/helper';
const { t } = i18n();

interface Props {
  onRelationTypeSelection?: (relationType: number) => void;
}

const getStringValue = (key: number) => RelationType[key].addWhiteSpaces();

const relationsList : IRelationType[] = [
  { key: RelationType.MergeTickets, name: t('ticket-details.edit-ticket-form.relate-ticket.merge-tickets'), icon: MergedIcon },
  { key: RelationType.RelateTickets, name: t('ticket-details.edit-ticket-form.relate-ticket.relate-tickets'), icon: RelatedIcon }
];

export const RelationTypeSelection = ({ onRelationTypeSelection }: Props) => {
  return (
    <>
      <div className='dialog-header font-bold' >
        {t('ticket-details.edit-ticket-form.relate-ticket.relation-type-selection')}
      </div>
      <div className='dialog-body overflow-auto'>
        {relationsList.map((relation: IRelationType) => (
          <RelationCard
            relation = {relation}
            onClick = {() => onRelationTypeSelection(relation.key)}
          />
        ))}
      </div>
    </>
  );
};
