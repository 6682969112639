import React, { Dispatch, SetStateAction, useState } from "react";

import cloneDeep from "lodash/cloneDeep";
import pick from "lodash/pick";
import omit from "lodash/omit";
import uniqueId from "lodash/uniqueId";


import {
    DragDropContext,
    DropResult,
    DragStart,
    DragUpdate,
    ResponderProvided,
} from "react-beautiful-dnd";

import {
    Accessibility,
    Box,
    Button,
    CloseIcon,
    Dialog,
    Flex,
    FocusZoneTabbableElements,
    MenuItem,
    ProviderConsumer as FluentUIThemeConsumer,
    SiteVariablesPrepared,
} from "@fluentui/react-northstar";

import {
    EditIcon,
    TrashCanIcon,
} from "@fluentui/react-icons-northstar";

import { getCode, keyboardKey } from "@fluentui/keyboard-key";

import { BoardTheme } from "@fluentui/react-teams/lib/esm/components/Board/BoardTheme";

import { TUsers } from "@fluentui/react-teams/lib/esm/types/types";

import { getText, interpolate, TTranslations } from "@fluentui/react-teams/lib/esm/translations";

import {
    BoardLane,
    TPlaceholderPosition,
    TBoardLanes,
    TBoardLane,
} from "./BoardLane";

import {
    TBoardItems,
    IBoardItem,
    IPreparedBoardItems,
    IPreparedBoardItem,
    IBoardItemCardLayout,
} from "./BoardItem";

import { BoardItemDialog, BoardItemDialogAction } from "./BoardItemDialog";
import { Toast } from "../../../components/shared/components/toast/Toast";
import { appState } from "../../../components/AppState";
import { getStatusIdByGuid, inputItems, getStatus } from "../../../components/tikit/ticketHelper";
import { STATUS_GUID, TASK_STATUS_GUID, LIFECYCLE_STATUS_GUID } from "../../../components/shared/utils/constants";
import { dialogContent, dialogHeader, listOfOpenTask } from "../../../components/shared/components/TicketTasks/TaskCommon/TaskCommon";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { getInputItemValues } from "../../../components/tikit/BoardMenu";

const boardBehavior: Accessibility = (props: any) => ({
    attributes: {
        root: {
            role: "region",
            "aria-label": "Board lanes",
        },
    },
    focusZone: {
        props: {
            shouldEnterInnerZone: (event) => getCode(event) === keyboardKey.Enter,
            handleTabKey: FocusZoneTabbableElements.all,
        },
    },
});

const defaultBoardItemCardLayout: IBoardItemCardLayout = {
    previewPosition: "top",
    overflowPosition: "footer",
};

interface IBoardInteractionUpdateLanes {
    event: "update";
    target: "lanes";
    lanes: TBoardLanes;
}

interface IBoardInteractionUpdateItems {
    event: "update";
    target: "items";
    items: IPreparedBoardItems;
}

export type TBoardInteraction =
    | IBoardInteractionUpdateLanes
    | IBoardInteractionUpdateItems;

export interface IBoardProps {
    users: TUsers;
    lanes: TBoardLanes;
    items: TBoardItems;
    boardItemCardLayout?: IBoardItemCardLayout;
    onInteraction?: (interaction: TBoardInteraction) => void;
    onCheck?: () => void;
    isSlaEnabled?: boolean;
    isLifecycleEnabled?: boolean;
}

interface IBoardStandaloneProps {
    users: TUsers;
    arrangedLanes: TBoardLanes;
    setArrangedLanes: Dispatch<SetStateAction<TBoardLanes>>;
    arrangedItems: IPreparedBoardItems;
    setArrangedItems: Dispatch<SetStateAction<IPreparedBoardItems>>;
    boardItemCardLayout?: IBoardItemCardLayout;
    addingLane: boolean;
    setAddingLane: Dispatch<SetStateAction<boolean>>;
    t: TFunction<"translation", undefined>;
    rtl: boolean;
    isSlaEnabled: boolean;
    isLifecycleEnabled: boolean;
}

type IItems = {
    [itemKey: string]: IBoardItem;
} | IPreparedBoardItems

const prepareBoardItems = (items: IItems, lanes: { [laneKey: string]: TBoardLane }): IPreparedBoardItems => {

    const groups = getInputItemValues(inputItems)?.map(o => o.toLowerCase());
    for (const group of groups) {
        if (Object.keys(items)[0]?.includes(group)) {
            return items as IPreparedBoardItems
        }
    }
    const unsortedPreparedBoardItems = Object.keys(items).reduce(
        (acc: IPreparedBoardItems, itemKey) => {
            const item = items[itemKey] as IPreparedBoardItem;
            item.itemKey = itemKey;
            if (acc.hasOwnProperty(item.lane)) acc[item.lane].push(item);
            else acc[item.lane] = [item];
            return acc;
        },
        {}
    );

    return Object.keys(lanes).reduce((acc: IPreparedBoardItems, laneKey) => {
        acc[laneKey] = unsortedPreparedBoardItems.hasOwnProperty(laneKey)
            ? unsortedPreparedBoardItems[laneKey].sort((a, b) => a.order - b.order)
            : [];
        return acc;
    }, {});
};

const resetOrder = (item: IPreparedBoardItem, newOrder: number) => {
    item.order = newOrder;
    return item;
};

const getDraggable = (draggableId: string) =>
    document.querySelector(
        `[data-rbd-drag-handle-draggable-id='${draggableId}']`
    );
const getDroppable = (droppableId: string) =>
    document.querySelector(`[data-rbd-droppable-id='${droppableId}']`);

const getClientYChildren = (
    $parent: Element,
    draggableId: string,
    endIndex: number
) =>
    Array.from($parent.children)
        .filter(($child) => {
            const childDraggableId = $child.getAttribute("data-rbd-draggable-id");
            return (
                typeof childDraggableId === "string" && childDraggableId !== draggableId
            );
        })
        .slice(0, endIndex);

const getPlaceholderPosition = (
    $draggable: Element,
    clientYChildren: Element[]
): TPlaceholderPosition => {
    if (!$draggable || !$draggable.parentNode) return null;

    const { clientHeight, clientWidth } = $draggable;

    const clientY = clientYChildren.reduce((acc, $child) => {
        return acc + $child.clientHeight + 8;
    }, 2);

    const clientX = 20;

    return [clientX, clientY, clientWidth, clientHeight];
};

const BoardStandalone = (props: IBoardStandaloneProps) => {
    const {
        users,
        arrangedLanes,
        setArrangedLanes,
        arrangedItems,
        setArrangedItems,
        addingLane,
        setAddingLane,
        t,
        rtl,
        isSlaEnabled,
        isLifecycleEnabled
    } = props;

    const [placeholderPosition, setPlaceholderPosition] = useState<
        TPlaceholderPosition
    >(null);
    const [showTaskWarningDialog, setShowTaskWarningDialog] = useState<boolean>(false);
    const [ticketId, setTicketId] = useState<number>(0);
    const [dragItemObj, setDragItemObj] = useState({
        source: null,
        destination: null,
        provided: null,
        boardItem: null
    });
    const [openTaskList, setOpenTaskList] = useState<TicketTask[]>([]);
    const [pendingApprovals, setPendingApprovals] = useState<TicketApprovals[]>([]);
    const [dropLaneName, setDropLaneName] = useState<StatusDetail>();
    const currentState = appState();

    const onDragStart = (event: DragStart, provided: ResponderProvided) => {
        const laneKey = event.source.droppableId;
        const itemKey = event.draggableId;
        const boardLane = arrangedLanes[laneKey];
        const boardItem = arrangedItems[laneKey].find(
            (boardItem) => boardItem.itemKey === itemKey
        );

        const announcement = t('ticket.ticket-board.on-drag-start-board-item', {
            itemTitle: boardItem ? boardItem.title: '',
            itemPosition: arrangedItems[laneKey].indexOf(boardItem!) + 1,
            laneLength: arrangedItems[laneKey].length,
            laneTitle: boardLane.title,
        });

        provided.announce(announcement);

        const $draggable = getDraggable(event.draggableId);
        if (!$draggable || !$draggable.parentNode) return;
        setPlaceholderPosition(
            getPlaceholderPosition(
                $draggable,
                getClientYChildren(
                    $draggable.parentNode as Element,
                    event.draggableId,
                    event.source.index
                )
            )
        );
    };

    const onDragUpdate = (event: DragUpdate, provided: ResponderProvided) => {
        if (!event.destination) return;
        const $draggable = getDraggable(event.draggableId);
        const $droppable = getDroppable(event.destination.droppableId);
        if (!$draggable || !$droppable) return;

        const destinationLaneKey = event.destination!.droppableId;
        const itemKey = event.draggableId;
        const boardLane = arrangedLanes[destinationLaneKey];
        const boardItem = arrangedItems[event.source.droppableId].find(
            (boardItem) => boardItem.itemKey === itemKey
        );

        const announcement = t(
            destinationLaneKey === event.source.droppableId
                ? "ticket.ticket-board.on-drag-update-board-item-same-lane"
                : "ticket.ticket-board.on-drag-update-board-item-different-lane"
            , {
                    itemTitle: boardItem ? boardItem.title : '',
                    itemPosition: event.destination.index + 1,
                    laneLength: arrangedItems[destinationLaneKey].length,
                    laneTitle: boardLane ? boardLane.title : '',
            },
        );

        provided.announce(announcement);

        setPlaceholderPosition(
            getPlaceholderPosition(
                $draggable,
                getClientYChildren(
                    $droppable,
                    event.draggableId,
                    event.destination.index
                )
            )
        );
    };

    const onDragEnd = (
        { draggableId, reason, source, destination }: DropResult,
        provided: ResponderProvided
    ) => {
        const sourceType = source?.droppableId.split("_");
        const boardItem: any = arrangedItems[source.droppableId].find((boardItem) => boardItem.itemKey === draggableId);

        if (destination) {
            const closeStatusId = getStatusIdByGuid(currentState.ticketStatus, STATUS_GUID.CLOSED);
            const resolveStatusId = getStatusIdByGuid(currentState.ticketStatus, STATUS_GUID.RESOLVED);
            const lifecycleIsActive = (boardItem.TicketLifecycle?.Status ?? false) && [LIFECYCLE_STATUS_GUID.NOT_STARTED, LIFECYCLE_STATUS_GUID.IN_PROGRESS].indexOf(boardItem.TicketLifecycle.Status.Guid) >= 0;
            if(props.isLifecycleEnabled && lifecycleIsActive && (destination.droppableId == `status_${closeStatusId}` || destination.droppableId == `status_${resolveStatusId}`)){
                Toast.error(`Ticket with an active lifecycle cannot be moved to resolved or closed.`);
            }
            else if (boardItem.Closed && sourceType[0].toLowerCase() !== "status") {
                Toast.error(t('ticket.ticket-board.closed-ticket-cannot-update'));
            } else if (boardItem.IsMerged) {
                Toast.error(t('ticket.ticket-board.merged-ticket-status-cannot-change'));
            } else if ((boardItem.HasActiveTasks || boardItem.HasPendingApprovals) && (destination.droppableId == `status_${closeStatusId}` || destination.droppableId == `status_${resolveStatusId}`)) {
                if(destination.droppableId == `status_${closeStatusId}`) {
                    const status: any = getStatus(closeStatusId, currentState.ticketStatus);
                    setDropLaneName(status);
                } else if(destination.droppableId == `status_${resolveStatusId}`) {
                    const status: any = getStatus(resolveStatusId, currentState.ticketStatus);
                    setDropLaneName(status);
                }
                setShowTaskWarningDialog(true);
                setTicketId(boardItem.Id);
                const taskList = listOfOpenTask(
                  currentState.taskStatus,
                  boardItem.TicketTasks,
                  TASK_STATUS_GUID.COMPLETED,
                  TASK_STATUS_GUID.FAILED
                );
                setOpenTaskList(taskList);
                setPendingApprovals(boardItem.PendingTicketApprovals)
                setDragItemObj({ source, destination, provided, boardItem });
            } else {
                onDragEndLogic( source, destination, provided, boardItem);
            }
        } else {
            onDragEndLogic(source, destination, provided, boardItem);
        }
    };

    const onDragEndLogic = (source, destination, provided: ResponderProvided, boardItem: any) => {
        let announcement;

        if (destination) {
            const laneKey = destination.droppableId;
            const boardLane = laneKey && arrangedLanes[laneKey];
            announcement = t("ticket.ticket-board.on-drag-end-board-item", {
                itemTitle: (boardItem as IPreparedBoardItem).title,
                itemPosition: Math.max(1, destination.index + 1),
                laneLength: arrangedItems[laneKey].length,
                laneTitle: boardLane ? boardLane.title : '',
            });
        } else {
            announcement = t("ticket.ticket-board.on-drag-cancel-board-item", {
                itemTitle: (boardItem as IPreparedBoardItem).title,
            });
        }

        provided.announce(announcement);

        if (destination) {
            const sourceLaneKey = source.droppableId;
            const destinationLaneKey = destination.droppableId;

            const movingItems = arrangedItems[sourceLaneKey].splice(source.index, 1);
            arrangedItems[sourceLaneKey].forEach(resetOrder);

            arrangedItems[destinationLaneKey].splice(
                destination.index,
                0,
                movingItems[0]
            );
            arrangedItems[destinationLaneKey].forEach(resetOrder);

            setPlaceholderPosition(null);
            return setArrangedItems(cloneDeep(arrangedItems));
        }
    }

    const moveLane = (laneKey: string, delta: 1 | -1) => {
        const laneKeys = Object.keys(arrangedLanes);
        const from = laneKeys.indexOf(laneKey);
        laneKeys.splice(from + delta, 0, laneKeys.splice(from, 1)[0]);
        setArrangedLanes(
            laneKeys.reduce(
                (nextArrangedLanes: TBoardLanes, currentLaneKey: string) => {
                    nextArrangedLanes[currentLaneKey] = arrangedLanes[currentLaneKey];
                    return nextArrangedLanes;
                },
                {}
            )
        );
    };

    const deleteLane = (laneKey: string) => {
        setArrangedLanes(omit(arrangedLanes, [laneKey]));
    };

    const onCloseConfirmModal = () => {
      setShowTaskWarningDialog(false);
    };

    const onConfirmModal = () => {
      onCloseConfirmModal();
      onDragEndLogic(dragItemObj.source, dragItemObj.destination, dragItemObj.provided, dragItemObj.boardItem)
    };

    return (
        <DragDropContext {...{ onDragStart, onDragUpdate, onDragEnd }}>
            <Box styles={{ overflowX: "auto", flex: "1 0 0" }}>
                <Box
                    styles={{ height: "100%", display: "flex" }}
                >
                    {Object.keys(arrangedLanes).map((laneKey, laneIndex, laneKeys) => {
                        const last = laneIndex === laneKeys.length - 1;
                        return (
                            <BoardLane
                                isSlaEnabled={isSlaEnabled}
                                first={laneIndex === 0}
                                last={addingLane ? false : last}
                                laneKey={laneKey}
                                lane={arrangedLanes[laneKey]}
                                preparedItems={arrangedItems[laneKey]}
                                editItemDialog={editItemDialog(props)}
                                key={`BoardLane__${laneKey}`}
                                users={users}
                                t={t}
                                rtl={rtl}
                                boardItemCardLayout={
                                    props.boardItemCardLayout || defaultBoardItemCardLayout
                                }
                                placeholderPosition={placeholderPosition}
                                moveLane={moveLane}
                                deleteLane={deleteLane}
                            />
                        );
                    })}
                    {addingLane && (
                        <BoardLane
                            isSlaEnabled={isSlaEnabled}
                            last
                            pending
                            laneKey={uniqueId("pl")}
                            preparedItems={[]}
                            key="BoardLane__pending_lane"
                            users={users}
                            t={t}
                            rtl={rtl}
                            boardItemCardLayout={
                                props.boardItemCardLayout || defaultBoardItemCardLayout
                            }
                            placeholderPosition={null}
                            exitPendingLane={(value) => {
                                if (value.length > 0) {
                                    const newLaneKey = uniqueId("sl");
                                    setArrangedLanes(
                                        Object.assign(arrangedLanes, {
                                            [newLaneKey]: { title: value },
                                        })
                                    );
                                    setArrangedItems(
                                        Object.assign(arrangedItems, { [newLaneKey]: [] })
                                    );
                                }
                                setAddingLane(false);
                            }}
                        />
                    )}
                </Box>
            </Box>
            <Dialog
                content={dialogContent(openTaskList, pendingApprovals)}
                header={dialogHeader(dropLaneName, ticketId, t)}
                open={showTaskWarningDialog}
                headerAction={{
                    icon: <CloseIcon />,
                    title: 'Close',
                    onClick: onCloseConfirmModal
                }}
                footer={
                <Flex gap="gap.small">
                    <Flex.Item push>
                    <Button
                        content={t('common.buttons.cancel')}
                        onClick={onCloseConfirmModal}
                    />
                    </Flex.Item>
                    <Flex.Item>
                    <Button
                        content={t('common.buttons.confirm')}
                        primary
                        onClick={onConfirmModal}
                    />
                    </Flex.Item>
                </Flex>
                }
            />
        </DragDropContext>
    );
};

export const Board = (props: IBoardProps) => {
    const { t } = useTranslation();
    const [arrangedLanes, setStateArrangedLanes] = useState<TBoardLanes>(
        props.lanes
    );

    const [arrangedItems, setStateArrangedItems] = useState<IPreparedBoardItems>(
        prepareBoardItems(props.items, props.lanes)
    );

    const [addingLane, setAddingLane] = useState<boolean>(false);

    const setArrangedLanes = (lanes: TBoardLanes) => {
        if (props.onInteraction)
            props.onInteraction({ event: "update", target: "lanes", lanes });
        return setStateArrangedLanes(lanes as SetStateAction<TBoardLanes>);
    };

    const setArrangedItems = (items: IPreparedBoardItems) => {
        if (props.onInteraction)
            props.onInteraction({ event: "update", target: "items", items });
        return setStateArrangedItems(items as SetStateAction<IPreparedBoardItems>);
    };

    return (
        <FluentUIThemeConsumer
            render={(globalTheme) => {
                const { rtl } = globalTheme.siteVariables;
                return (
                  <BoardTheme globalTheme={globalTheme} style={{ height: '100%' }}>
                    <Flex
                      hAlign="start"
                      column
                      variables={({ colorScheme }: SiteVariablesPrepared) => ({
                        backgroundColor: colorScheme.default.background2
                      })}
                      styles={{ height: '100%' }}
                    >
                      <BoardStandalone
                        {...{
                          t,
                          rtl,
                          arrangedLanes,
                          arrangedItems,
                          setArrangedItems: setArrangedItems as Dispatch<
                            SetStateAction<IPreparedBoardItems>
                          >,
                          addingLane,
                          setAddingLane,
                          setArrangedLanes: setArrangedLanes as Dispatch<
                            SetStateAction<TBoardLanes>
                          >
                        }}
                        isSlaEnabled={props.isSlaEnabled}
                        isLifecycleEnabled={props.isLifecycleEnabled}
                        {...pick(props, ['users', 'boardItemCardLayout'])}
                      />
                    </Flex>
                  </BoardTheme>
                );
            }}
        />
    );
};

const editItemDialog = ( item: IBoardStandaloneProps ) => (boardItem: IBoardItem) => {
    return (
        <>
            <BoardItemDialog
                action={BoardItemDialogAction.Edit}
                trigger={
                    <MenuItem
                        vertical
                        icon={<EditIcon outline size="small" />}
                        content={item.t("ticket.ticket-board.edit-board-item")}
                    />
                }
                initialState={boardItem}
                {...item}
            />
            <Dialog
                trigger={
                    <MenuItem
                        vertical
                        icon={<TrashCanIcon outline size="small" />}
                        content={item.t("ticket.ticket-board.delete")}
                    />
                }
                content={item.t("ticket.ticket-board.confirm-delete", {
                    title: boardItem.title
                })}
                confirmButton={{ content: item.t("ticket.ticket-board.delete") }}
                cancelButton={{ content: item.t("ticket.ticket-board.cancel") }}
                onConfirm={() => {
                    const pos = item.arrangedItems[boardItem.lane].findIndex(
                        (laneItem) =>
                            laneItem.itemKey ===
                            (boardItem as IPreparedBoardItem).itemKey
                    );
                    item.arrangedItems[boardItem.lane].splice(pos, 1);
                    item.setArrangedItems(cloneDeep(item.arrangedItems));
                }}
            />
        </>
    )
};