import React from "react";

import { VisibilityContext } from "react-horizontal-scrolling-menu";

export function PhaseCard({ itemId, children }: { itemId: string, children: React.ReactElement }) {
  const visibility = React.useContext(VisibilityContext);

  const visible = visibility.isItemVisible(itemId);

  return (
    <>
    {children}
    </>
  );
}
