import React, { useState } from 'react';
import TicketIcon from '../../../../../svg/ticket.svg';
import StatusIcon from '../../../../../svg/status-dot.svg';
import { getDateWithDay, threedigitized } from '../../../../tikit/ticketHelper';
import { getTicketStatusClass } from '../RelateTicketHelper';
import { appState } from '../../../../AppState';
import CSS from './TicketListCard.module.css';
import { Checkbox, Flex, ProviderConsumer as FluentUIThemeConsumer } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';

interface Props {
  ticket: Ticket,
  isSelected: boolean,
  onClick: () => void;
}

export const TicketListCard = ({ ticket, isSelected, onClick }: Props) => {
  const {t} = useTranslation();
  const currentState = appState();
  const [hoverState, setHoverState] = useState<boolean>(false);

  const getStatusClass = () => getTicketStatusClass(ticket.StatusId, currentState);

  return (
    <FluentUIThemeConsumer
      render={({ siteVariables: { colorScheme } }) => (
        <>
          <div key={ticket.Id} className={`p-3 bg-white cursor-pointer ticket-list-card relate-card-container `}
            style={{
              borderColor: colorScheme.default.border,
              backgroundColor: `${hoverState ? colorScheme.default.background2 : colorScheme.default.background}`
            }}
            onMouseEnter={() => setHoverState(true)} onMouseLeave={() => setHoverState(false)} onClick={() => onClick()}>
            <div className={`flex items-center justify-between`} >
              <Flex vAlign="center">
                <Flex vAlign="center">
                  <Checkbox checked={isSelected} className={`related-ticket-checkbox ${CSS.TicketCheckbox}`} />
                  <TicketIcon width={24} className={`mr-3`} />
                </Flex>
                <div className={CSS.TicketDiv}>
                  <div className='mb-2'>
                    <span className={`ticket-id text-gray-400`}>#{threedigitized(ticket.Id)} </span>
                    <span>{ticket.Title}</span>
                  </div>
                  <div className="text-xs">
                    <Flex vAlign="center">
                      <StatusIcon className={`mr-2 ${getStatusClass()}`} />
                      <span className={`text-gray-400`}>{t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.created-on')}&nbsp;</span>
                      {getDateWithDay(ticket.CreatedDate)}
                    </Flex>
                  </div>
                </div>
              </Flex>
            </div>
          </div>
        </>
      )}
    />
  );
};
