import React, { createContext, useState } from 'react';
import { MyWorkListDataI } from './MyWork';
import { MyWorkListViewType } from '../../shared/utils/constants';

interface PropTypes {
  children: React.ReactNode;
}

export interface MyWorkContextType {
  myWorkStates: MyWorkStatesType;
  setMyWorkStates: React.Dispatch<React.SetStateAction<MyWorkStatesType>>;
}

export const DEFAULT_SORT_ORDER_MY_WORK: ISortOrder = null;

export const MyWorkContext = createContext({} as MyWorkContextType);
const MyWorkProvider = MyWorkContext.Provider;

export const DEFAULT_MY_WORK_VALUES = {
  showClosed: false,
  sortOrder: {
      [MyWorkListViewType.All]: DEFAULT_SORT_ORDER_MY_WORK,
      [MyWorkListViewType.Tickets]: DEFAULT_SORT_ORDER_MY_WORK,
      [MyWorkListViewType.Tasks]: DEFAULT_SORT_ORDER_MY_WORK,
      [MyWorkListViewType.Approvals]: DEFAULT_SORT_ORDER_MY_WORK,
  },
  myWorkList: {
      isFetching: true,
      allData: [] as MyWorkListDataI[],
      currentPage: 1,
      dataCount: {
          [MyWorkListViewType.All]: 0,
          [MyWorkListViewType.Tickets]: 0,
          [MyWorkListViewType.Tasks]: 0,
          [MyWorkListViewType.Approvals]: 0,
      },
      selectedTabId: MyWorkListViewType.All,
  }
};

const MyWorkContextProvider = ({ children }: PropTypes) => {
  const [myWorkStates, setMyWorkStates] = useState<MyWorkStatesType>(DEFAULT_MY_WORK_VALUES);

  return (
    <>
      <MyWorkProvider value={{ myWorkStates, setMyWorkStates }}>
        <>{children}</>
      </MyWorkProvider>
    </>
  );
};

export default MyWorkContextProvider;
