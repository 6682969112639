import React, { useEffect, useState } from "react";
import {
    IChartWidget,
    Widget,
    WidgetTitle,
    WidgetBody,
    ChartWidgetSize,
    WidgetFooter
} from "./ChartWidgets";
import {
    ProviderConsumer as FluentUIThemeConsumer,
    Box,
    Loader,
    Button
} from "@fluentui/react-northstar";

import { ChartService } from '../../../shared/services/chart.service';
import { ChartTypes } from '../Charts/ChartTypes';
import { Communication } from '@fluentui/react-teams';
import { ConvertDateToLocale } from "../../../shared/common/ConvertDate";
import { Providers } from "@microsoft/mgt-element/dist/es6/providers/Providers";
import { CheckLogin } from "../../../shared/components/CheckLogin";
import {useTranslation} from 'react-i18next';
import { CONTACT_ADMIN, REFRESH_SESSION, SOMETHING_WENT_WRONG, UNAUTHORIZED_ACCESS } from "../../../shared/utils/constants";
import { DateTime } from "luxon";


export type TChartBoardInteraction = IChartBoardInteractionWidgetAction;
export interface IChartBoardInteractionWidgetAction {
    event: "click";
    target: "action";
    widget: string;
    action: string;
}

export interface IChartBoard {
    FilterDate: number | undefined;
}

let dataReport = [];
const linkExample = { href: "#" };

const assigneeListMap = (t) => (x: any) => {
    return {
        labels: x["Assignee"],
        datasets: [
            {
                label: t('ticket.chart.active'),
                data: x['Active']
            },
            {
                label: t('ticket.chart.pending'),
                data: x['Pending']
            },
            {
                label: t('ticket.chart.resolved'),
                data: x['Resolved']
            },
            {
                label: t('ticket.chart.closed'),
                data: x['Closed']
            }
        ]

    };
}

const ChartBoard: React.FC<IChartBoard> = ({ FilterDate }) => {
    const {t} = useTranslation();
    const apiChart = new ChartService();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [errCode, setErrCode] = useState(0);
    const [loadingMessage, setLoadingMessage] = useState("");
    const [dataConfig, setDataConfig] = useState({ widgets: [] });
    const [isCheckedIn, setIsCheckedIn] = useState<boolean>(false);

    const fetchData = async (filter) => {
        setLoadingMessage(t('ticket.loading.chart'));
        setIsLoading(true);
        let ConvertToArray = (array) => {
            return Array.from(array);
        };

        let _sortByDate = (arr, field, sort) => {
            return arr.sort((a, b) => {
                let convertA = new Date(a[field]).valueOf();
                let convertB = new Date(b[field]).valueOf();
                if (sort == "asc")
                    return convertA - convertB;
                else
                    return convertB - convertA;

            });
        }
        await apiChart.getReport(filter).then((repos) => {
            dataReport = repos.data;
            let arr = [];
            arr = dataReport["TicketVolumeTrends"].reduce((r, a) => {
                var formattedDate = a["FormattedDate"];
                r[formattedDate] = [...(r[formattedDate] || []), a];
                return r;
            }, []);

            var newArrayLabels = Object.keys(arr).map((key, index) => {
                return {
                    labels: key
                };
            }, []);

            var labelMapping = newArrayLabels.map((x) => { return x["labels"] });
            const ticketsCreated = Object.keys(arr).map((key, index) => {
                return arr[key].map((x, y) => {
                    if (x["Created"] > 0)
                        return x["Created"];

                    return 0;
                }, []).reduce((r, a) => { return r; });

            });

            const TicketsModified = Object.keys(arr).map((key, index) => {
                return arr[key].map((x, y) => {
                    if (x["TicketsModified"] > 0)
                        return x["TicketsModified"];

                    return 0
                }, []).reduce((r, a) => { return a });

            });

            const ticketsCompleted = Object.keys(arr).map((key, index) => {
                return arr[key].map((x, y) => {
                    if (x["Completed"] > 0)
                        return x["Completed"];

                    return 0;
                }, []).reduce((r, a) => { return a });

            });
            const categoryList = ConvertToArray(dataReport["TicketByCategory"]).map((x, index) => {
                return { label: x["Category"], data: x["Percentage"] }
            });

            const statusList = ConvertToArray(dataReport["TicketsByStatus"]).map((x, index) => {
                return { label: x["Category"], data: x["Percentage"] }

            });

            const surveyList = ConvertToArray(dataReport["TicketsBySurvey"]).map((x, index) => {
                return { label: x["Category"], data: x["Percentage"] }
            });

            let statusCreatedDflectedList = [];
            var getTicketByStatus = ConvertToArray(dataReport["TicketsByStatus"]).map((x, index) => {
                if (x["Category"] == "Active" || x["Category"] == "Pending"
                    || x["Category"] == "Resolved" || x["Category"] == "Closed" || x["Category"] == "No Status") {
                    return { label: t('ticket.chart.created'), data: x["Percentage"] }
                } else {
                    return { label: t('ticket.chart.deflected'), data: x["Percentage"] }
                }

            }).filter(o => { return o != undefined });

            if (getTicketByStatus.length > 0) {

                var getCreated = getTicketByStatus.filter(o => { return o.label == "Created" }).map((x) => {
                    return x["data"];
                });

                var getDefelcted = getTicketByStatus.filter(o => { return o.label == "Deflected" }).map((x) => {
                    return x["data"];
                });

                var getCreatedTotal = getCreated.reduce((a, b) => {
                    return a + b;
                }, 0);

                var getDeflectedTotal = getDefelcted.reduce((a, b) => {
                    return a + b;
                }, 0);

                let objData = [];
                if (getCreatedTotal > 0)
                    objData.push({
                        label: t('ticket.chart.created'), data: getCreatedTotal
                    })
                if (getDeflectedTotal > 0)
                    objData.push({
                        label: t('ticket.chart.deflected'), data: getDeflectedTotal
                    })

                statusCreatedDflectedList = objData;

            }

            const assigneeList = ConvertToArray(dataReport["TicketsByAssignee"]).map(assigneeListMap(t));

            const analystLabelMapping = ConvertToArray(dataReport["TicketsByAnalyst"]).map((x) => {
                return x["Assignee"];
            }).filter((x) => { return x != null; });

            const analystAverageResolutionTimeMapping = ConvertToArray(dataReport["TicketsByAnalyst"]).map((x) => {
                return x['TotalAverageResolutionTime'];
            });

            const statusLabelMapping = statusList.map((x) => {
                return x["label"]
            });

            const statusPercentageMapping = statusList.map((x) => {
                return x["data"];
            });

            const surveyLabelMapping = surveyList.map((x) => {
                return x["label"]
            });

            const surveyPercentageMapping = surveyList.map((x) => {
                return x["data"];
            });

            //Tickets Created Deflected - Label and Percentage Mapping
            const statusCreatedDeflectedLabelMapping = statusCreatedDflectedList.map((x) => {
                return x["label"]
            });

            const statusCreatedDeflectedPercentageMapping = statusCreatedDflectedList.map((x) => {
                return x["data"];
            });

            const categoryLabelMapping = categoryList.map((x) => {
                return x["label"];
            });

            const catPercentageMapping = categoryList.map((x) => {

                return x["data"];
            });

            const assigneeLabelMapping = assigneeList.map((x) => {
                return x["labels"];
            });

            const assigneeDataMappingActive = ConvertToArray(dataReport["TicketsByAssignee"]).map((x) => {
                if (x["Active"] > 0)
                    return x["Active"];
                else
                    return 0;
            });
            const assigneeDataMappingPending = ConvertToArray(dataReport["TicketsByAssignee"]).map((x) => {
                if (x["Pending"] > 0)
                    return x["Pending"];
                else
                    return 0;
            });

            const assigneeDataMappingClosed = ConvertToArray(dataReport["TicketsByAssignee"]).map((x) => {
                if (x["Closed"] > 0)
                    return x["Closed"];
                else
                    return 0;
            });
            const assigneeDataMappingResolved = ConvertToArray(dataReport["TicketsByAssignee"]).map((x) => {
                if (x["Resolved"] > 0)
                    return x["Resolved"];
                else
                    return 0;
            });

            const getCreateDataSets = [
                {
                    label: t('ticket.chart.created'), data: ticketsCreated
                },
                {
                    label: t('ticket.chart.modified'), data: TicketsModified
                },
                {
                    label: t('ticket.chart.completed'), data: ticketsCompleted
                }
            ]
            const createDataSetForCategory = [
                {
                    label: t('ticket.chart.category'), data: catPercentageMapping
                }
            ];
            const createDataSetForStatus = [
                {
                    label: t('ticket.chart.status'), data: statusPercentageMapping
                }
            ];

            const createDataSetForSurvey = [
                {
                    label: t('ticket.chart.survey'), data: surveyPercentageMapping
                }
            ];

            //Tickets Created and Deflected DataSets
            let createDataSetForStatusCreatedDeflected = [];

            if (statusCreatedDeflectedPercentageMapping.length > 0) {
                createDataSetForStatusCreatedDeflected.push(
                    {
                        label: t('ticket.chart.status'), data: statusCreatedDeflectedPercentageMapping
                    }
                );
            }

            const createDataSetForAssignee = [
                {
                    label: t('ticket.chart.active'), data: assigneeDataMappingActive.filter(a => { return a != undefined })
                },
                {
                    label: t('ticket.chart.pending'), data: assigneeDataMappingPending.filter(a => { return a != undefined })
                },
                {
                    label: t('ticket.chart.closed'), data: assigneeDataMappingClosed.filter(a => { return a != undefined })
                },
                {
                    label: t('ticket.chart.resolved'), data: assigneeDataMappingResolved.filter(a => { return a != undefined })
                }
            ]

            const createDataSetForAverageResolutionTimePerAnalyst = [
                {
                    label: t('ticket.chart.average-resolution-in-hours'),
                    data: analystAverageResolutionTimeMapping
                }
            ]

            var checkVolumeSet = getCreateDataSets.filter(function (o) { return o.data.length > 0; });
            var checkCategorySet = createDataSetForCategory.filter(function (o) { return o.data.length > 0; });
            var checkAssigneeSet = createDataSetForAssignee.filter(function (o) { return o.data.length > 0; });
            var checkStatusSet = createDataSetForStatus.filter(function (o) { return o.data.length > 0; });
            let checkSurveySet = createDataSetForSurvey.filter(function (o) { return o.data.length > 0; });
            var checkAverageResolutionPerAnalyst = createDataSetForAverageResolutionTimePerAnalyst.filter(function (o) { return o.data.length > 0 });

            const finalSet = { labels: labelMapping, datasets: (checkVolumeSet.length == 0) ? [] : getCreateDataSets };

            const finalCatSet = { labels: categoryLabelMapping, datasets: (checkCategorySet.length == 0) ? [] : createDataSetForCategory };

            const finalAssigneeSet = { labels: assigneeLabelMapping, datasets: (checkAssigneeSet.length == 0) ? [] : createDataSetForAssignee }

            const finalStatSet = { labels: statusLabelMapping, datasets: (checkStatusSet.length == 0) ? [] : createDataSetForStatus };
            const finalSurveySet = { labels: surveyLabelMapping, datasets: (checkSurveySet.length == 0) ? [] : createDataSetForSurvey};

            const finalCreatedDeflectedSet = { labels: statusCreatedDeflectedLabelMapping, datasets: createDataSetForStatusCreatedDeflected };
            const finalAverageResolutionTimePerAnalystSet = {
                labels: (checkAverageResolutionPerAnalyst.length == 0) ? [] : analystLabelMapping,
                datasets: (checkAverageResolutionPerAnalyst.length == 0) ? [] : createDataSetForAverageResolutionTimePerAnalyst
            }

            var lastUpdate = _sortByDate(ConvertToArray(dataReport["TicketVolumeTrends"]), "ModifiedDate", "desc");
            var lastUpdateString = (lastUpdate.length == 0) ? "" : `${ConvertDateToLocale(lastUpdate[0]["ModifiedDate"], DateTime.DATETIME_MED_WITH_WEEKDAY)}`;
            var lastUpdateTicketAssignee = _sortByDate(ConvertToArray(dataReport["TicketsByAssignee"]), "Date", "desc");
            var lastUpdateTicketAssigneeString = (lastUpdateTicketAssignee.length == 0) ? "" : `${ConvertDateToLocale(lastUpdateTicketAssignee[0]["Date"], DateTime.DATETIME_MED_WITH_WEEKDAY)}`;

            //Get Average Resolution Time in Hours
            //var postLabel = dataReport["TotalAverageResolutionTime"] > 1 ? " Hours" : " Hour";
            var getAverageResolutionTime = dataReport["TotalAverageResolutionTime"];

            apiChart.initData(finalSet);
            apiChart.initCategoryData(finalCatSet);
            apiChart.initAssigneeData(finalAssigneeSet);
            apiChart.initStatusData(finalStatSet);
            apiChart.initStatusCreatedDeflectedData(finalCreatedDeflectedSet);
            apiChart.initTicketSurvey(finalSurveySet);
            apiChart.initLastUpdate(lastUpdateString);
            apiChart.initLastUpdateTicketAssignee(lastUpdateTicketAssigneeString);
            apiChart.initAverageResolutionTime(getAverageResolutionTime);
            apiChart.initAverageResolutionTimePerAnalyst(finalAverageResolutionTimePerAnalystSet);

            const dataWidgets = [
                {
                    id: "AverageResolutionTime",
                    title: t('ticket.chart.average-resolution-time'),
                    size: ChartWidgetSize.Single,
                    body: [
                        {
                            id: "AverageResolutionTime",
                            title: t('ticket.chart.average-resolution-time'),
                            content: {
                                type: "placeholder",
                                message: apiChart.getAverageResolutionTime()
                            },
                        }
                    ],
                    link: linkExample,
                } as unknown as IChartWidget
                ,
                {
                    id: "AverageResolutionTimePerAnalyst",
                    title: t('ticket.chart.average-resolution-per-analyst'),
                    size: ChartWidgetSize.Triple,
                    body: [
                        {
                            id: "AverageResolutionTimePerAnalyst",
                            title: t('ticket.chart.average-resolution-per-analyst'),
                            content: {
                                type: "chart",
                                chart: {
                                    title: t('ticket.chart.average-resolution-per-analyst'),
                                    type: ChartTypes.Bar,
                                    data: apiChart.getAverageResolutionTimePerAnalyst()
                                },
                            },
                        },
                    ]
                } as unknown as IChartWidget
                ,

                {
                    title: t('ticket.chart.tickets-deflected-vs-created'),
                    size: ChartWidgetSize.Single,
                    link: linkExample,
                    body: [
                        {
                            id: "TicketDeflectedVsCreated",
                            title: t('ticket.chart.tickets-deflected-vs-created'),
                            content: {
                                type: "chart",
                                chart: {
                                    title: t('ticket.chart.tickets-deflected-vs-created'),
                                    type: ChartTypes.Pie,
                                    data: apiChart.getStatCreatedDeflectedData()
                                },
                            },
                        },
                    ],
                } as unknown as IChartWidget
                ,
                {
                    title: t('ticket.chart.tickets-survey'),
                    size: ChartWidgetSize.Single,
                    link: linkExample,
                    body: [
                        {
                            id: "TicketsSurvey",
                            title: t('ticket.chart.tickets-survey'),
                            content: {
                                type: "chart",
                                chart: {
                                    title: t('ticket.chart.tickets-survey'),
                                    type: ChartTypes.Pie,
                                    data: apiChart.getTicketSurveyData()
                                },
                            },
                        },
                    ],
                } as unknown as IChartWidget
                ,
                {
                    id: "ChartTicketVolume",
                    title: t('ticket.chart.tickets-volume'),
                    desc: t('ticket.chart.last-updated') + apiChart.getLastUpdate(),
                    size: ChartWidgetSize.Triple,
                    body: [
                        {
                            id: "t1",
                            title: t('ticket.chart.7-days'),
                            content: {
                                type: "chart",
                                chart: {
                                    title: t('ticket.chart.tickets-volume'),
                                    type: ChartTypes.LineStacked,
                                    data: apiChart.getData()
                                },
                            },
                        }
                    ],
                    link: linkExample,
                } as unknown as IChartWidget
                ,
                {
                    title: t('ticket.chart.tickets-created-by-category'),
                    size: ChartWidgetSize.Double,
                    link: linkExample,
                    body: [
                        {
                            id: "",
                            title: "",
                            content: {
                                type: "chart",
                                chart: {
                                    title: t('ticket.chart.tickets-created-by-category'),
                                    type: ChartTypes.Doughnut,
                                    data: apiChart.getCatData()
                                },
                            },
                        },
                    ],
                } as unknown as IChartWidget
                ,
                {
                    id: "ChartTicketsByAssignee",
                    title: t('ticket.chart.tickets-by-assignee'),
                    desc: t('ticket.chart.last-updated') + apiChart.getLastUpdateTicketAssignee(),
                    size: ChartWidgetSize.Triple,
                    body: [
                        {
                            id: t('ticket.chart.tickets-by-assignee'),
                            title: "",
                            content: {
                                type: "chart",
                                chart: {
                                    title: t('ticket.chart.tickets-by-assignee'),
                                    type: ChartTypes.BarStacked,
                                    data: apiChart.getAssigneeData()
                                },
                            },
                        },
                    ],
                    link: linkExample,
                } as unknown as IChartWidget,
                {
                    title: t('ticket.chart.ticket-created-by-status'),
                    size: ChartWidgetSize.Double,
                    link: linkExample,
                    body: [
                        {
                            id: "2",
                            title: t('ticket.chart.ticket-created-by-status'),
                            content: {
                                type: "chart",
                                chart: {
                                    title: t('ticket.chart.ticket-created-by-status'),
                                    type: ChartTypes.Doughnut,
                                    data: apiChart.getStatData()
                                },
                            },
                        },
                    ],
                } as unknown as IChartWidget
            ]

            const dataConfigs = { widgets: dataWidgets };
            setDataConfig(dataConfigs);
            setIsError(false);
            return dataConfigs;
        })
            .catch((e) => {
                setIsError(true);
                setErrCode(e.response.status);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const loadPage = () => {
        setIsCheckedIn(true);
    }
    useEffect(() => {

        let filterStr = null;
        if (FilterDate == 0)
            filterStr = "Last 30 days";
        else if (FilterDate == 1)
            filterStr = "Last Month";
        else if (FilterDate == 2)
            filterStr = "Last 7 days";
        else if (FilterDate == 3)
            filterStr = "This Year";
        else
            filterStr = "Last Year";

        if (isCheckedIn)
            fetchData(filterStr);

    }, [FilterDate, isCheckedIn]);

    var chartList = {
        widgets: []
    }

    dataConfig.widgets.forEach(item => {

        chartList.widgets.push(item);

    });

    const errConfig = {
        fields: {
            title: (errCode == 401 || errCode == 403) ? UNAUTHORIZED_ACCESS : SOMETHING_WENT_WRONG,
            desc: CONTACT_ADMIN
        }
    }

    const CommunicationContainer = () => {
        return (
            <>
                <Communication {...errConfig} />
                {errCode === 401 && (<Button content={REFRESH_SESSION} primary onClick={e => { Providers.globalProvider.login(); }} />)}
            </>
        )
    }

    const ChartBoardComponent = () => {
        if (isLoading)
            return <Loader label={loadingMessage} style={{ height: "100%" }} />
        else
            return (isError) ? <CommunicationContainer /> : <FluentThemeConsumerContainer />
    }

    const FluentThemeConsumerContainer = () => {
        return (
            <FluentUIThemeConsumer
                render={(globalTheme) => (
                    <Box
                        styles={{
                            display: "grid",
                            gridGap: "1.2rem",
                            gridTemplate:
                                "repeat(auto-fill, 25rem) / repeat(auto-fill, minmax(18.75rem, 1fr))",
                            gridAutoFlow: "dense",
                            gridAutoRows: "25rem",
                            padding: "1rem 1rem 1.25rem",
                            minWidth: "20rem",
                            "@media (max-width: 986px)": {
                                gridTemplate:
                                    "repeat(auto-fill, 25rem) / repeat(auto-fill, minmax(15.75rem, 1fr))",
                            },
                        }}
                    >
                        {chartList.widgets &&
                            chartList.widgets.map(
                                (
                                    {
                                        id,
                                        title,
                                        desc,
                                        size,
                                        body,
                                        link,
                                    }: IChartWidget,
                                    key: number
                                ) => (
                                    <Widget key={key} size={size}>
                                        <WidgetTitle
                                            {...{
                                                widgetId: id,
                                                title,
                                                desc,
                                                globalTheme
                                            }}
                                        />
                                        <WidgetBody
                                            body={body}
                                            siteVariables={globalTheme.siteVariables}
                                        />
                                        {link && (
                                            <WidgetFooter
                                                siteVariables={globalTheme.siteVariables}
                                                link={link}
                                            />
                                        )}
                                    </Widget>
                                )
                            )}
                    </Box>
                )}
            />
        )
    }

    return (
        <CheckLogin onSignedIn={loadPage}>
            <ChartBoardComponent />
        </CheckLogin>
    )
}

export default ChartBoard;