import { CacheService, CacheConfig, CacheStore, CacheSchema, CacheItem } from "@microsoft/mgt-react";
import { graphService } from '../services/graph.service';

const cacheSchema: CacheSchema = {
    name: 'photos',
    stores: {
        users: "",
        contacts: ""
    },
    version: 1
};
  
interface CachePhoto extends CacheItem {
    photo?: string;
}

// retrieves invalidation time from cache config
const getPhotoInvalidationTime = (): number =>
CacheService.config.photos.invalidationPeriod || CacheService.config.defaultInvalidationPeriod;

// checks for if cache is enabled
const photosCacheEnabled = (): boolean => CacheService.config.photos.isEnabled && CacheService.config.isEnabled;

// declare the desired cache store
let cache: CacheStore<CachePhoto>
if (photosCacheEnabled())
    cache = CacheService.getCache<CachePhoto>(cacheSchema, 'users');

const graphAPI = new graphService();

const getNewPhoto = async (idOrUpn: string) => {
    // store graph result into the cache if cache is enabled
    if (photosCacheEnabled()) {
        const src = await graphAPI.getUserPhoto(idOrUpn);
        cache.putValue(idOrUpn, { photo: src });
        return src;
    }
}

export const setCachedPhoto = (idOrUpn: string, data: string) => {
    if (photosCacheEnabled())
        cache.putValue(idOrUpn, { photo: data });
}

export const getCachedPhoto = async(idOrUpn: string) => {
    // check if the cache is enabled
    if (photosCacheEnabled()) {
        const photoObj = await cache.getValue(idOrUpn);
        // check if an item is retrieved, and if it's not expired
        if (photoObj && getPhotoInvalidationTime() > Date.now() - photoObj.timeCached)
            return photoObj.photo;
        else
            return getNewPhoto(idOrUpn);
    }
}