import React, { useCallback, useEffect, useState } from 'react';
import { platformService } from '../shared/services/platform.service';
import { SignedInState } from '../shared/services/signInState.service';
import '../AppGlobals';
import {
  DEFAULT_API_STATUS,
  DONE,
  ERROR,
  LOADING,
  TICKET_TYPE
} from '../shared/utils/constants';
import { Modal } from '../shared/components/Modal/Modal';
import { CheckLogin } from '../shared/components/CheckLogin';

interface Props {
  id: string;
  open: boolean;
  onCancel: () => void;
  onSuccessFullCall: () => void;
}

export const TicketType: React.FC<Props> = ({
  id,
  open,
  onCancel,
  onSuccessFullCall
}) => {
  const api = new platformService();
  const queryId = parseInt(id);

  const [ticketTypeDetails, setTicketTypeDetails] = useState<TicketTypeDetail>();
  const [ticketTypeName, setTicketTypeName] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [apiStatus, setAPIStatus] = useState<APIStatus>(
    DEFAULT_API_STATUS as APIStatus
  );

  const updateAPIStatus = (newState: Partial<APIStatus>) => {
    setAPIStatus({ ...apiStatus, ...newState });
  };

  const fetchTicketTypeDetails = useCallback(async () => {
    updateAPIStatus({
      msg: TICKET_TYPE.FETCHING_TICKET_TYPE_DETAILS,
      status: LOADING
    });

    try {
      const { data } = await api.getTicketTypes(`(${queryId})`);
      setTicketTypeDetails(data);
      setTicketTypeName(data.Value);
      setIsDefault(data.IsDefault);
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    } finally {
      updateAPIStatus({ status: DONE });
    }
  }, [queryId]);

  const fetchTicketTypeDefaultInstance = useCallback(async () => {
    updateAPIStatus({
      msg: TICKET_TYPE.FETCHING_TICKET_TYPE_DEFAULT_INSTANCE,
      status: LOADING
    });

    try {
      const {
        data: { value }
      } = await api.getDefaultInstance(`?v=${new Date().getTime()}`);
      const parsedValue = JSON.parse(value);
      const requiredProps: TicketTypeDetail = (({
        Value,
        Position,
        IsArchived,
        IsDefault,
        IsSystem,
        ParentId,
        Id,
        ModifiedDate,
        ModifiedById,
        CreatedDate,
        CreatedById,
        Guid,
        IsDeleted
      }) => ({
        Value,
        Position,
        IsArchived,
        IsDefault,
        IsSystem,
        ParentId,
        Id,
        ModifiedDate,
        ModifiedById,
        CreatedDate,
        CreatedById,
        Guid,
        IsDeleted
      }))(parsedValue);

      setTicketTypeDetails(requiredProps);
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    } finally {
      updateAPIStatus({ status: DONE });
    }
  }, []);

  const [isCheckIn, setIsCheckIn] = useState<boolean>(false);

  const loadPage = () => {
    setIsCheckIn(true);
  }

  useEffect(() => {
    if (isCheckIn) {
      if (queryId > 0 && open) fetchTicketTypeDetails();
      else fetchTicketTypeDefaultInstance();
    }
  }, [isCheckIn, queryId, open]);

  const checkAndUndefaultOther = async (currentDefaultId: number) => {
    try {
      const {
        data: { value }
      } = await api.getTicketTypes(`?v=${new Date().getTime()}`);

      const defaultIdList: number[] = value
        .filter(
          (val: TicketTypeDetail) =>
            val.IsDefault && val.IsDefault && val.Id !== currentDefaultId
        )
        .map((value: TicketTypeDetail) => value.Id);

      if (defaultIdList.length > 0) {
        for (const id of defaultIdList) {
          const payload: UpdateTicketTypePayload = {
            Id: id.toString(),
            IsDefault: false
          };

          await api.updateTicketType(payload);
        }
      }
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    }
    // no finally block to avoid the flicker effect because finally sets the status to done
  };

  const createTicketType = async (value: string, defaultCheck: boolean) => {
    const payload: TicketTypeDetail = {
      ...ticketTypeDetails,
      Value: value,
      IsDefault: defaultCheck
    };
    updateAPIStatus({
      msg: TICKET_TYPE.CREATING_NEW_TICKET_TYPE,
      status: LOADING
    });

    try {
      const {
        data: { Id }
      } = await api.createTicketType(payload);

      if (defaultCheck) {
        await checkAndUndefaultOther(Id);
      }

      onCancel();
      onSuccessFullCall();
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    }
    // no finally block to avoid the flicker effect because finally sets the ticket type to done
  };

  const updateTicketType = async (value: string, defaultCheck: boolean) => {
    const payload: UpdateTicketTypePayload = {
      Value: value,
      Id: ticketTypeDetails.Id.toString(),
      IsDefault: defaultCheck
    };
    updateAPIStatus({
      msg: TICKET_TYPE.UPDATING_TICKET_TYPE,
      status: LOADING
    });

    try {
      await api.updateTicketType(payload);

      if (defaultCheck) {
        await checkAndUndefaultOther(ticketTypeDetails.Id);
      }

      onCancel();
      onSuccessFullCall();
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    }
    // no finally block to avoid the flicker effect because finally sets the ticket type to done
  };

  const saveData = async (value: string, defaultCheck: boolean) => {
    if (queryId > 0) updateTicketType(value, defaultCheck);
    else createTicketType(value, defaultCheck);
  };

  const closeModal = () => {
    setTicketTypeDetails(null);
    setTicketTypeName('');
    setIsDefault(false);

    onCancel();
  };

  return (
    <CheckLogin onSignedIn={loadPage}>
      <Modal
        apiStatus={apiStatus}
        name={TICKET_TYPE.TICKET_TYPE}
        open={open}
        isUpdate={queryId > 0}
        inputValue={ticketTypeName}
        checkBoxValue={isDefault}
        onCancelHandler={closeModal}
        onConfirmationHandler={(inputValue, checkBoxValue) => {
          saveData(inputValue, checkBoxValue);
        }}
      />
    </CheckLogin>
  );
};
