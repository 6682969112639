import React, { useEffect, useReducer, useState } from 'react';
import {
  Skeleton,
  Flex,
  Text,
  Box,
  ProviderConsumer as FluentUIThemeConsumer,
  Tooltip,
  SpeakerPersonIcon
} from '@fluentui/react-northstar';
import { ChatIcon, MailIcon } from '@fluentui/react-icons-mdl2';
import { MgtPersonCard, Person, PersonCardInteraction } from '@microsoft/mgt-react';
import { Link, Panel, PanelType } from '@fluentui/react';
import { UserForm } from '../../user/UserForm';
import { PlatformUser } from '../interfaces/platformuser.interface';
import { themeNames } from '@fluentui/react-teams';
import { useTranslation } from 'react-i18next';

interface RequesterCardProps {
  Requester: PlatformUser;
  ticketId: number;
  ticketSubject: string;
  ccAddress: string;
}

const ACTIONS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  SET_REQUESTER: 'call-api'
};

const requesterReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.LOADING: {
      return {
        ...state,
        LoadingRequester: true
      };
    }
    case ACTIONS.SUCCESS: {
      return {
        ...state,
        LoadingRequester: false
      };
    }
    case ACTIONS.SET_REQUESTER: {
      return {
        ...state,
        Requester: action.data
      };
    }

    default:
      throw new Error('Unexpected action');
  }
};

const initialState = {
  LoadingRequester: true,
  Requester: {}
};

export const RequesterCard = (Props: RequesterCardProps) => {
  const {t} = useTranslation();
  const [state, dispatch] = useReducer(requesterReducer, initialState);
  const [openUserInfo, setOpenUserInfo] = useState(false);
  
  //Disable Mail section to not require Mail.ReadBasic in Tikit scopes
  MgtPersonCard.config.sections.mailMessages = false;

  useEffect(() => {
    dispatch({ type: ACTIONS.SET_REQUESTER, data: Props.Requester });
    dispatch({ type: ACTIONS.SUCCESS });
  }, [Props.Requester]);

  const personDetails = {
    displayName: state?.Requester?.FullName,
    mail: (state?.Requester?.Email ?? state?.Requester?.UserName)
  };

  return (
    <FluentUIThemeConsumer
      render={globalTheme => (
        <div
          className={`p-2.5 h-28`}
          style={{
            backgroundColor:
              globalTheme.siteVariables.colorScheme.default.background5
          }}
        >
          <Text content={t('ticket-details.edit-ticket-form.requester-card.title')} size="medium" weight="semibold" />
          {!state.LoadingRequester && (
            <Flex column className={`mt-2`} gap="gap.medium">
              {personDetails.displayName && personDetails.mail ? (
                <Flex vAlign="center">
                  <Flex.Item>
                    <Box className={`mr-4`}>
                      <Box>
                        <Person
                          personQuery={personDetails.mail}
                          fetchImage
                          avatarSize="auto"
                          personCardInteraction={PersonCardInteraction.hover}
                        />
                      </Box>
                    </Box>
                  </Flex.Item>
                  <Flex.Item>
                    <Box className={`break-words w-1/2`}>
                      <Box>
                        <Flex>
                          <Link
                            style={{
                              color:
                                globalTheme.siteVariables.colorScheme.brand
                                  .foreground1
                            }}
                            onClick={() => setOpenUserInfo(true)}
                          >
                            {`${state.Requester.GivenName} ${state.Requester.FamilyName}`}
                          </Link>
                          <Panel
                            isLightDismiss
                            isOpen={openUserInfo}
                            onDismiss={() => setOpenUserInfo(false)}
                            onOuterClick={() => setOpenUserInfo(true)}
                            closeButtonAriaLabel="Close"
                            type={PanelType.large}
                            className={globalTheme.siteVariables.theme === themeNames.Default ? "" : "panel--darkmode"}
                          >
                            <UserForm
                              {...{
                                id: state.Requester.Id?.toString(),
                                isUserInfoOnly: true
                              }}
                            />
                          </Panel>
                        </Flex>
                      </Box>
                      <Box>
                        <Flex>
                          <Box>
                            <Tooltip
                              content={t('ticket-details.edit-ticket-form.requester-card.send-email')}
                              trigger={
                                <a target="_blank" href={`mailto:${personDetails.mail}?subject=%23${Props.ticketId} - ${Props.ticketSubject}&cc=${Props.ccAddress}`}>
                                  <MailIcon style={{color: globalTheme.siteVariables.colorScheme.brand.foreground}} />
                                </a>
                              }
                            />
                          </Box>
                          <Box>
                            <Tooltip
                              content={t('ticket-details.edit-ticket-form.requester-card.message-in-team')}
                              trigger={
                                <a target="_blank" className={`ml-2`} href={`https://teams.microsoft.com/l/chat/0/0?users=${personDetails.mail}`}>
                                  <ChatIcon style={{color: globalTheme.siteVariables.colorScheme.brand.foreground}} />
                                </a>
                              }
                            />
                          </Box>
                        </Flex>
                      </Box>
                    </Box>
                  </Flex.Item>
                </Flex>
              ) : (
                <Flex vAlign="center">
                  <Flex.Item>
                    <Box className={`mr-4`}>
                      <SpeakerPersonIcon
                        size="large"
                        className={`text-gray-500`}
                        variables={{ largeSize: '24px' }}
                      />
                      <span className="mx-1 font-semibold">{t('ticket-details.edit-ticket-form.requester-card.no-requester')}</span>
                    </Box>
                  </Flex.Item>
                </Flex>
              )}
            </Flex>
          )}
          {state.LoadingRequester && (
            <div className={`mt-2 h-28`}>
              <Skeleton animation="wave">
                <Flex gap="gap.medium" column>
                  <Flex gap="gap.medium" vAlign="center">
                    <Skeleton.Avatar size="smaller" />
                    <Skeleton.Text size="smaller" />
                  </Flex>
                  <Flex gap="gap.medium">
                    <Skeleton.Text size="smaller" />
                  </Flex>
                </Flex>
              </Skeleton>
            </div>
          )}
        </div>
      )}
    />
  );
};

export const MemoizedRequesterCard = React.memo(RequesterCard);
