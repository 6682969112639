import React from 'react';
import { Flex, Skeleton } from '@fluentui/react-northstar';

export const PropertiesSkeletonLoader = () => (
  <Skeleton animation="wave" className={`pt-5`}>
    <Flex gap="gap.medium" column>
      <Flex gap="gap.medium">
        <Skeleton.Line width="30%" />
      </Flex>
      <Flex.Item>
        <Skeleton.Input fluid />
      </Flex.Item>
    </Flex>
    <Flex gap="gap.medium" column className={`pt-5`}>
      <Flex gap="gap.medium">
        <Skeleton.Line width="30%" />
      </Flex>
      <Flex.Item>
        <Skeleton.Input fluid />
      </Flex.Item>
    </Flex>
    <Flex gap="gap.medium" column className={`pt-5`}>
      <Flex gap="gap.medium">
        <Skeleton.Line width="30%" />
      </Flex>
      <Flex.Item>
        <Skeleton.Input fluid />
      </Flex.Item>
    </Flex>
  </Skeleton>
);
