import React from 'react';
import './TicketDetailPopup.css';
import { TicketDetailInfo } from '../TicketDetailInfo/TicketDetailInfo';
import { CloseIcon } from '@fluentui/react-northstar';

interface Props {
  attachmentsCount?: number;
  commentsCount?: number;
  isBoardMobileView?: boolean;
  ticketData: any;
  open: boolean;
  closePopup?: (value: boolean) => void;
  detailCardApprovalsList?: TicketApprovals[];
  detailCardTaskList?: TicketTask[];
}

export const TicketDetailPopup = ({ ticketData, open, attachmentsCount, commentsCount, isBoardMobileView, closePopup, detailCardApprovalsList, detailCardTaskList }: Props) => {
  return (
    <div
      className={`ticketDetailPopup block md:hidden`}
      style={{ height: open ? '100vh' : '0vh' }}
      onClick={() => {
        closePopup(false);
      }}
    >
      <div
        className={`ticketDetailPopupCard`}
        style={{ height: open ? '68vh' : '0' }}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <div className="pt-2.5 px-3 mb-1 flex items-center justify-end">
          <CloseIcon
            onClick={() => {
              closePopup(false);
            }}
          />
        </div>
        <TicketDetailInfo 
          ticketData={ticketData} 
          attachmentsCount={attachmentsCount} 
          commentsCount={commentsCount}
          isBoardMobileView={isBoardMobileView}
          detailCardApprovalsList={detailCardApprovalsList} 
          detailCardTaskList={detailCardTaskList} />
      </div>
    </div>
  );
};
