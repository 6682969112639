import {
  Alert,
  Dropdown,
  Flex,
  ProviderConsumer,
  Text,
  TextArea
} from '@fluentui/react-northstar';
import React, { useEffect, useState } from 'react';
import { threedigitized } from '../../../../tikit/ticketHelper';
import { NestedDropdown } from '../../NestedDropdown/NestedDropdown';
import TicketSVG from '../../../../../svg/ticket.svg';
import './TicketTasksDialog.css';
import { ThemeColorScheme } from '../../../common/TeamsTheme';
import { LabelElement } from '../../TicketForm';
import { PlatformUser } from '../../../interfaces/platformuser.interface';
import { useTranslation } from 'react-i18next';
import { CheckFeature } from '../../CheckFeature';
import { LICENSE_FEATURE } from '../../../utils/constants';
import { appState } from '../../../../AppState';
import { formatAssigneeItems, formatSupportGroupItems, formatTeamItems } from './../TaskCommon/TaskCommon';

interface Props {
  statuses: TaskStatus[];
  analysts: PlatformUser[];
  ticketId: number;
  ticketTitle: string;
  setFormObj: any;
  formObj: any;
  alert: any;
  isCurrentPhase: boolean;
}

export const TicketTasksForm = ({
  statuses,
  analysts,
  ticketId,
  ticketTitle,
  setFormObj,
  formObj,
  alert,
  isCurrentPhase
}: Props) => {
  
  const { t } = useTranslation();
  const currentState = appState();

  const [analystsItems, setAnalystsItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [supportGroupDropDownItems, setSupportGroupDropDownItems] = useState([]);
  const [supportGroupQuery, setSupportGroupQuery] = useState('');
  const [teamDropDownItems, setTeamDropDownItems] = useState([]);
  const [teamSearchQuery, setTeamSearchQuery] = useState('');

  const [taskCollaboratorList, settaskCollaboratorList] = useState([]);

  const findDefaultValue = (itemsArray: any[]) => {
    return itemsArray.find(item => item.IsDefault);
  };

  const populateTeamsDropDown = (teams: any[]) => {
    const items = teams.map((team: any) => {
      if (team.accessibilityitemprops.id === formObj?.TeamId) {
        team.selected = true;
        setTeamSearchQuery(formObj?.Team?.Name);
      } else {
        team.selected = false;
      }
      return team;
    });
    setTeamDropDownItems([...items]);
  }

  const populateSupportGroupDropDown = (groups: any[]) => {
    const groupItems = groups.map((group: any) => {
      if (group.accessibilityitemprops.id === formObj?.SupportGroupId) {
        group.selected = true;
        setSupportGroupQuery(formObj?.SupportGroup?.Name);
      } else {
        group.selected = false;
      }
      return group;
    });
    setSupportGroupDropDownItems([...groupItems]);
  }

  const populateAssigneeDropDown = ()=>{
    const supportGroup = currentState.supportgroups.filter(x=>x.Id == formObj?.SupportGroupId)[0];
    const users = supportGroup ? supportGroup.Members : analysts;
    const items = users.map((data: any) => {
      if (data.Id === formObj?.Assignee?.Id) {
        data.selected = true;
        setSearchQuery(formObj.Assignee.FullName);
      } else {
        data.selected = false;
      }
      data.header = data.FullName;
      return data;
    });
    setAnalystsItems([...items]);
  }

  const onTeamChange = (selectedTeam: any) => {
    const updatedTeamItems = teamDropDownItems.map((item: any) => {
      if (item.Id == selectedTeam?.Id) {
        item.selected = true;
      } else {
        item.selected = false;
      }
      return item;
    });
    setTeamDropDownItems(updatedTeamItems);
    setTeamSearchQuery(selectedTeam?.Name);

    const updatedSupportGroupItems = selectedTeam ? currentState.supportgroups.filter(t => t.TeamsAadObjectId == selectedTeam?.TeamsAadObjectId) : currentState.supportgroups;
    setSupportGroupDropDownItems(formatSupportGroupItems(updatedSupportGroupItems));
    setSupportGroupQuery('');

    const updatedAssigneeItems = (!selectedTeam) ? analysts : currentState.teams.find(x => x.Id == selectedTeam?.Id)?.Users || [];
    setAnalystsItems(formatAssigneeItems(updatedAssigneeItems));
    setSearchQuery('');

    setFormObj({
      ...formObj,
      TeamId: selectedTeam?.Id,
      Team: selectedTeam,
      SupportGroupId: null,
      SupportGroup: null,
      AssigneeId: null,
      Assignee: null
    });
  }

  const onGroupChange = (selectedGroup: any) => {
    const updatedGroupItems = supportGroupDropDownItems.map((item: any) => {
      if (item.Id == selectedGroup?.Id) {
        item.selected = true;
      } else {
        item.selected = false;
      }
      return item;
    });
    setSupportGroupDropDownItems(updatedGroupItems);
    setSupportGroupQuery(selectedGroup?.Name);

    const updatedAssigneeItems = (selectedGroup) ? selectedGroup?.Members : analysts;
    setAnalystsItems(formatAssigneeItems(updatedAssigneeItems));
    setSearchQuery('');

    setFormObj({
      ...formObj,
      SupportGroupId: selectedGroup?.Id,
      SupportGroup: selectedGroup,
      AssigneeId: null,
      Assignee: null
    });
  }

  const onAssigneeChange = (selectedAssignee: any) => {
    const updatedAssigneeItems = analystsItems.map((item: any) => {
      if (item.Id == selectedAssignee?.Id) {
        item.selected = true;
      } else {
        item.selected = false;
      }
      return item;
    });
    setAnalystsItems(updatedAssigneeItems);
    setSearchQuery(selectedAssignee?.FullName);
    setFormObj({
      ...formObj,
      AssigneeId: selectedAssignee?.Id,
      Assignee: selectedAssignee
    });
  }

  useEffect(()=>{
    populateTeamsDropDown(formatTeamItems(currentState.teams));
  },[formObj?.TeamId]);

  useEffect(()=>{
    let  filteredGroups = currentState.supportgroups;
    if(formObj?.Team && formObj?.Team!== null){
      filteredGroups=currentState.supportgroups.filter(t => t.TeamsAadObjectId == formObj?.Team?.TeamsAadObjectId) ;
    }
    populateSupportGroupDropDown(formatSupportGroupItems(filteredGroups));
  },[formObj?.SupportGroupId]);

  useEffect(()=>{
    populateAssigneeDropDown();
  },[formObj?.AssigneeId]);

  useEffect(() => {
    const taskCollaboratorFormatedList: any[] = taskCollaboratorsFormat(
      currentState.usersInRole,
      formObj?.TaskCollaborators.map(o => currentState.platformusers.find(i => i.Id == o.PlatformUserId))
    );
    settaskCollaboratorList(taskCollaboratorFormatedList);
  }, [formObj?.TaskCollaborators, currentState.usersInRole]);

  const taskCollaboratorsFormat = (analystList: Assignee[], selectedtaskCollaborator: PlatformUser[] | null) => {
    let taskCollaboratorFormatedList = [];
    analystList.forEach((t: { FullName: any; Id: number }) => {
        let taskCollaboratorObj = {};
        taskCollaboratorObj['header'] = t.FullName;
        taskCollaboratorObj['accessibilityitemprops'] = t.Id;
        if (selectedtaskCollaborator?.some(item => item.Id == t.Id)) {
          taskCollaboratorObj['selected'] = true;
        } else {
          taskCollaboratorObj['selected'] = false;
        }
        taskCollaboratorFormatedList.push(taskCollaboratorObj);
    });
    return taskCollaboratorFormatedList;
  };

  const onChangetaskCollaborators = (_e, p) => {
    const stringifyTheValue = JSON.stringify(p.value);
    const parsedValue: any[] = JSON.parse(stringifyTheValue);
    let selectedTasktaskCollaboratorList = [];
    parsedValue.forEach(item => {
      const taskCollaboratorId = item['accessibilityitemprops'];
      selectedTasktaskCollaboratorList.push(taskCollaboratorId);
    });

    //update the list of Task taskCollaborator
    const updatedTasktaskCollaboratorList = [];
    currentState.usersInRole.forEach(obj => {
      if (selectedTasktaskCollaboratorList.includes(obj.Id)) 
        updatedTasktaskCollaboratorList.push({ PlatformUserId: obj.Id });
    });
    setFormObj({ ...formObj, TaskCollaborators: updatedTasktaskCollaboratorList });
  }

  return (
    <ProviderConsumer
      render={globalTheme => (
        <>
          <div className="dialog-header font-bold" style={{ marginTop: '-30px' }}>
            <Flex vAlign="center">
              <TicketSVG width={24} className={`mr-1`} />
              <Text style={{ color: globalTheme.siteVariables.colorScheme.brand.foreground }}>
                #{threedigitized(ticketId)}
              </Text>
            </Flex>
            <div className={`py-2 ticketTitle`} style={{ fontSize: '12px', ...ThemeColorScheme(globalTheme.siteVariables) }}>
              {ticketTitle}
            </div>
          </div>
          <div>
            <div className={`my-2 font-bold`}>{`${(formObj.Id > 0) ? t('ticket-details.edit-ticket-form.task.dialog.update-task') : t('ticket-details.edit-ticket-form.task.dialog.add-task')}`}</div>
            <div className={`my-2`}>
              {alert.show && (
                <Alert
                  fitted
                  {...alert.attributes}
                  content={alert.title}
                  style={{ fontSize: 12, width: '100%' }}
                />
              )}
            </div>
            <div>
              <div className={`mt-2`} style={{ marginBottom: '-0.25rem' }}>
                <LabelElement label={t('ticket-details.edit-ticket-form.task.fields.title')} required={true}>
                  <TextArea
                    fluid
                    maxLength={500}
                    required
                    style={{ height: 60 }}
                    onChange={(_e, data) => {
                      setFormObj({ ...formObj, Title: data.value });
                    }}
                    value={formObj.Title}
                  />
                </LabelElement>
              </div>

              {(isCurrentPhase) && (
                <div className={`mt-2`}>
                  <LabelElement label={t('ticket-details.edit-ticket-form.task.fields.status')}>
                    <NestedDropdown
                      items={statuses.map((item: TaskStatus) => ({
                        id: item.Id,
                        parentId: item.ParentId,
                        position: item.Position,
                        value: item.Value,
                        ...item
                      }))}
                      dataItem={formObj?.Status}
                      defaultValue={findDefaultValue(statuses)?.Value}
                      onChange={(value: any) => {
                        setFormObj({
                          ...formObj,
                          Status: value,
                          StatusId: value.Id
                        });
                      }}
                    />
                  </LabelElement>
                </div>
              )}

              <CheckFeature featureName={LICENSE_FEATURE.MultiDepartment}>
                  <div className={`mt-2`}>
                    <LabelElement label={t('ticket.add-ticket.team')} >
                    <Dropdown
                      className={`cursor-pointer`}
                      items = {teamDropDownItems}
                      fluid
                      checkable
                      clearable
                      search
                      searchQuery={teamSearchQuery}
                      onSearchQueryChange={(_e, data) => {
                        setTeamSearchQuery(data.searchQuery);
                      }}
                      onChange={(_event, p) => {
                        onTeamChange(p?.value);
                      }}
                      value={teamDropDownItems.filter(t => t.selected)}
                      style={{backgroundColor: globalTheme.siteVariables.colorScheme.default.background2 }}
                      noResultsMessage={
                        <div style={{ textAlign: 'center' }}>
                          <Text content={t('ticket.add-ticket.team-not-found')} />
                        </div>
                      }
                    />
                    </LabelElement>
                  </div>
              </CheckFeature>

              <div className={`mt-2`}>
                  <Text content={t('ticket.add-ticket.group')} />
                  <Dropdown
                    items={supportGroupDropDownItems}
                    fluid
                    checkable
                    clearable
                    search
                    searchQuery={supportGroupQuery}
                    onSearchQueryChange={(_e, data) => {
                       setSupportGroupQuery(data.searchQuery);
                    }}
                    onChange={(_event, p) => {
                      onGroupChange(p?.value);
                    }}
                    value={supportGroupDropDownItems.filter(t => t.selected)}
                    noResultsMessage={
                      <div style={{ textAlign: 'center' }}>
                        <Text content={t('ticket.add-ticket.group-not-found')} />
                      </div>
                    }
                    style={{backgroundColor: globalTheme.siteVariables.colorScheme.default.background2 }}
                  />
               </div>

              <div className={`mt-2`}>
                <LabelElement label={t('ticket-details.edit-ticket-form.task.fields.assignee')}>
                  <Dropdown
                    style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background2 }}
                    items={analystsItems}
                    search
                    fluid
                    checkable
                    clearable
                    value={analystsItems.filter(t => t.selected)}
                    searchQuery={searchQuery}
                    onSearchQueryChange={(_e, data) => {
                      setSearchQuery(data.searchQuery);
                    }}
                    onChange={(_event, p: any) => {
                      onAssigneeChange(p.value);
                    }}
                    noResultsMessage={
                      <div style={{ textAlign: 'center' }}>
                        <Text content={t('ticket.add-ticket.user-not-found')} />
                      </div>
                    }
                  />
                </LabelElement>
              </div>

              <CheckFeature featureName={LICENSE_FEATURE.Collaborators}>
                <div className={`mt-2`}>
                  <Text content={t('ticket.ticket-list.collaborators')} />
                  <Dropdown
                    multiple
                    search
                    fluid
                    items={taskCollaboratorList.filter((t: any) => formObj.AssigneeId != t.accessibilityitemprops)}
                    onChange={onChangetaskCollaborators}
                    defaultValue={taskCollaboratorList.filter(t => t.selected)}
                    searchInput={{ inline: true,  styles: { paddingLeft: '8px', height: '32px' } }}
                    noResultsMessage={<div>No User found</div>}
                    style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background2 }}
                    className={'taskCollaborators--dropdown'}
                  />
                </div>
                </CheckFeature >
            </div>
          </div>
        </>
      )}
    />
  );
};
