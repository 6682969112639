import React, { useState, useEffect, useRef } from 'react';
import { Flex, Text, CalendarIcon, ProviderConsumer as FluentUIThemeConsumer, Alert, InfoIcon, Divider } from '@fluentui/react-northstar';
import AppCSS from '../../../../components/App.module.css';
import ApprovalIcon from '../../../../svg/ticket-approval-icon.svg';
import { ThemeColorScheme } from '../../common/TeamsTheme';
import { UserAvatar } from '../UserAvatar';
import TickIcon from '../../../../svg/tick-icon.svg';
import CrossIcon from '../../../../svg/cross-icon.svg';
import AdditionalInfoIcon from '../../../../svg/additional-info-icon.svg';
import { getShortDate, getDateWithDay, getApprovalStatus, getTemplateCardBgColor } from '../../../tikit/ticketHelper';
import { CSS_CLASS, MyWorkListViewType, TicketApprovalState } from '../../utils/constants';
import CSS from '../../../tikit/WorkBoard/Templates/TemplateCard.module.css';
import '../../../tikit/Request/TicketRequestCard/TicketRequestCard.css'
import { scrollToCardIfSelected } from '../TicketCard/TicketCard';
import { appState, useSetState } from '../../../AppState';
import { useTranslation } from 'react-i18next';

interface Props {
  approval: Approval;
  currentUserId: number;
  updateApprovalStatus?: (ticketApprovalData: Approval, approverId: number, status: TicketApprovalState) => void;
  isTicketClosed?: boolean;
  isLifecycleEnabled: boolean;
  disableButonOnUpdate?: boolean;
  activePhaseIndex?: number;
  phaseIndex?: number;
  enableApprovalButton?: boolean;
}

export const TicketApprovalCard = ({ approval, currentUserId, updateApprovalStatus, isTicketClosed, isLifecycleEnabled, disableButonOnUpdate = false, activePhaseIndex, phaseIndex, enableApprovalButton}: Props) => {
  const {t} = useTranslation();
  const [approvalStatus, setApprovalStatus] = useState<TicketApprovalState>(approval.ApprovalState);
  const [isUpdatingState, setUpdatingState] = useState<boolean>(false);
  const activeApprovalRef = useRef(null);
  const { selectedTaskOrApproval } = appState();
  const setAppState = useSetState();

  const isApprovalEnabled = (isLifecycleEnabled && approval?.TicketLifecyclePhase != undefined) ? (approval?.TicketLifecyclePhase?.IsCurrent && enableApprovalButton) : true;
  
  const isDisabled = isTicketClosed || !isApprovalEnabled || isUpdatingState;

  useEffect(() => checkApprovalStatus(), [approval.ApprovalState]);

  const checkApprovalStatus = () => {
    const approvalStatus = getApprovalStatus(approval);
    setApprovalStatus(approvalStatus);
  }

  const index = approval.Approvers.findIndex(e =>  e.Approver.Id === currentUserId);
  if (index > -1) {
    approval.Approvers.unshift(approval.Approvers[index]);
    approval.Approvers.splice(index + 1, 1);
  }

  const changeApprovedStatus = (event: any, status: TicketApprovalState, approverId: number) => {
    if (!isDisabled) {
      event.stopPropagation();
      if (disableButonOnUpdate) setUpdatingState(true);
      updateApprovalStatus(approval, approverId, status);
    }
  }

  useEffect(() => {
    if(!selectedTaskOrApproval || selectedTaskOrApproval.type !== MyWorkListViewType.Approvals) return;

    const { id } = selectedTaskOrApproval;
    scrollToCardIfSelected({ cardId: approval.Id, selectedCardId: id, activeCardRef: activeApprovalRef });

    setAppState((prev) => ({ ...prev, isRouteFromMyWork: false, selectedTaskOrApproval: undefined }));
  }, [approval]);

  return (
    <FluentUIThemeConsumer
      render={globalTheme => (
        <div
          className="min-h-32"
          style={{
            color: 'var(--mgt-theme-default-foreground)',
            ...ThemeColorScheme(globalTheme.siteVariables)
          }}
          ref={activeApprovalRef}
        >
          <div className="mt-3">
            <Flex
              className={`lifecycleItem ${CSS.ticketTaskCardDetail}`}
              column
              gap="gap.small"
              style={{
                background: getTemplateCardBgColor(globalTheme.siteVariables.theme),
                padding: '0.75rem',
                
              }}
            >
              <div style={{marginBottom: 0}}>
                <Flex space="between" className="mb-2">
                  <Flex.Item>
                  <div>
                    <Flex>
                    <Flex.Item>
                      <span className={`mr-2`}>
                        <ApprovalIcon />
                      </span>
                      </Flex.Item>
                      <Flex.Item>
                      <span>
                        <Text weight='semibold' size='small'>
                          {approval?.Title}
                        </Text>
                      </span>
                      </Flex.Item>
                    </Flex>
                    </div>
                  </Flex.Item>
                  <Flex.Item>
                    <span className={`ml-2`}>
                      {approvalStatus === TicketApprovalState.Approved && (
                         <Flex hAlign={"end"} className={'cui-pt-4 cui-pv-4'}>
                         <div
                           className={`${CSS_CLASS.STATUS_PILL_ACTIVE}`}
                         >
                           <Text weight="bold" size="smaller">
                           {t('ticket-details.edit-ticket-form.approval.approved')}
                           </Text>
                         </div>
                       </Flex>
                      )}
                      {approvalStatus === TicketApprovalState.Rejected && (
                        <Flex hAlign={"end"} className={'cui-pt-4 cui-pv-4'}>
                        <div
                          className={`${CSS_CLASS.STATUS_PILL_RESOLVED}`}
                        >
                          <Text weight="bold" size="smaller">
                          {t('ticket-details.edit-ticket-form.approval.rejected')}
                          </Text>
                        </div>
                      </Flex>
                      )}
                      {approvalStatus === TicketApprovalState.Pending && (
                        <Flex hAlign={"end"} className={'cui-pt-4 cui-pv-4'}>
                        <div
                          className={`${CSS_CLASS.STATUS_PILL_OTHERS}`}
                        >
                          <Text weight="bold" size="smaller">
                          {t('ticket-details.edit-ticket-form.approval.pending')}
                          </Text>
                        </div>
                      </Flex>
                      )}
                    </span>
                  </Flex.Item>
                </Flex>

                {approval.Approvers.map((approver) => (

                  <div key={approver.ApprovalId} className="text-xs">
                    <Flex space="between" className="mb-2.5">

                      <Flex.Item>
                        <div className="flex items-center mb-2.5 space-x-1">
                          <UserAvatar
                            {...{
                              idOrUpn: approver.Approver.AadObjectId ?? approver.Approver.UserName,
                              avatarProps: {
                                name: approver.Approver.FullName,
                                size: 'small',
                                className: 'cui-ml-2'
                              }
                            }}
                          />
                          <div className='pl-2'>
                            <Text size="small">
                              { currentUserId && approver.Approver.Id == currentUserId ? t('ticket-details.edit-ticket-form.approval.you') : approver.Approver.FullName}
                            </Text>
                            <Flex vAlign="center" gap="gap.smaller">
                                {approver.HasApproved === TicketApprovalState.Approved && (<Text className={AppCSS.ColorGray} size="small">{t('ticket-details.edit-ticket-form.approval.approved-on')} {getDateWithDay(approver.ModifiedDate)}</Text>)}
                                {approver.HasApproved === TicketApprovalState.Rejected && (<Text className={AppCSS.ColorGray} size="small">{t('ticket-details.edit-ticket-form.approval.rejected-on')} {getDateWithDay(approver.ModifiedDate)}</Text>)}
                                {approver.HasApproved === TicketApprovalState.Pending && approval.ApprovalState === TicketApprovalState.Pending && (<Text className={AppCSS.ColorGray} size="small">{t('ticket-details.edit-ticket-form.approval.pending-approval')}</Text>)}
                            </Flex>
                          </div>
                        </div>
                      </Flex.Item>
                      <Flex vAlign="center" gap="gap.smaller">
                        {currentUserId && approver.Approver.Id == currentUserId && approver.HasApproved === TicketApprovalState.Pending && approvalStatus === TicketApprovalState.Pending ? (
                          <>
                            <div className={`approval-button ${!isDisabled && 'approval-button-hover cursor-pointer'}`} onClick={(e) => { changeApprovedStatus(e, TicketApprovalState.Approved, approver.Approver.Id) }}>
                              <TickIcon width='20' height='16' className={`${isDisabled ? 'approvals-disabled-icon' : ''}`} />
                            </div>
                            <div className={`approval-button ${!isDisabled && 'approval-button-hover cursor-pointer'}`} onClick={(e) => { changeApprovedStatus(e, TicketApprovalState.Rejected, approver.Approver.Id) }}>
                              <CrossIcon width='16' height='16' className={`${isDisabled ? 'approvals-disabled-icon' : ''}`} />
                            </div>
                          </>
                        ) : (<></>)}
                      </Flex>
                    </Flex>
                  </div>
                ))}
              </div>
              {approval.IsRequiredByAll && <div className="text-xs">
                <Alert warning
                  content={
                    <>
                        <Text weight="semibold" content={t('ticket-details.edit-ticket-form.approval.required-note')} />
                        <Text className="ml-1" size='small' content={t('ticket-details.edit-ticket-form.approval.required-approvers-response')} />
                    </>
                }
                />
              </div>}
              <Divider size={0} className={`mb-2.5`} />
              <div className="text-xs">
                <Flex vAlign="center" gap="gap.smaller">
                  <div className="flex space-x-1">
                    <div className={`mt-1`}>
                      <AdditionalInfoIcon />
                    </div>
                    <div className={`mt-0.5 ${AppCSS.ColorGray}`}>{t('ticket-details.edit-ticket-form.approval.additional-info')}</div>
                    <span className="mt-0.5 ml-px">{approval.AdditionalDetails}</span>
                  </div>
                </Flex>
              </div>
              <div className="text-xs">
                <Flex vAlign="center" gap="gap.smaller">
                  <div className="flex space-x-1">
                    <div>
                      <CalendarIcon style={{ color: '#8f90a6' }} />
                    </div>
                    <div className={`mt-0.5 ${AppCSS.ColorGray}`}>{t('ticket-details.edit-ticket-form.approval.requested-on')}</div>
                    <span className="mt-0.5 ml-px">{getShortDate(approval.CreatedDate)}</span>
                  </div>
                </Flex>
              </div>
            </Flex>
          </div>
        </div>
      )} />
  );
};