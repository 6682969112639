import React from 'react';
import { Flex, Skeleton, ProviderConsumer as FluentUIThemeConsumer } from '@fluentui/react-northstar';
import { ThemeColorScheme } from '../../../common/TeamsTheme';

export const TicketTasksListSkeleton = () => {
  const TaskCardSkeleton = () => {
    return (
      <FluentUIThemeConsumer render={globalTheme => (
        <div style={{ backgroundColor: 'var(--mgt-theme-background)', ...ThemeColorScheme(globalTheme.siteVariables) }}>
          <Flex gap="gap.small" column className={`p-4`}>
            <Flex.Item>
              <Skeleton.Line width="15%" />
            </Flex.Item>
            <Flex.Item>
              <Skeleton.Line />
            </Flex.Item>
            <Flex.Item>
              <Skeleton.Line width="85%" />
            </Flex.Item>
          </Flex>
        </div>
      )} />
    );
  }
  return (
    <Skeleton animation="wave">
      <Flex gap="gap.small" column>
        <TaskCardSkeleton />
        <TaskCardSkeleton />
        <TaskCardSkeleton />
      </Flex>
    </Skeleton>
  )
}