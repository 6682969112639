import React, { useState } from 'react';
import { Text, Flex, ThemePrepared, ProviderConsumer as FluentUIThemeConsumer, Button, TrashCanIcon, Dialog, SvgIconSizeValue, List, Alert, Divider } from '@fluentui/react-northstar';
import { ThemeColorScheme } from '../../shared/common/TeamsTheme';
import { themeNames } from '@fluentui/react-teams/lib/esm';
import ApprovalIcon from '../../../svg/lifecycle-filled-approvals.svg';
import AdditionalInfoIcon from '../../../svg/additional-info-icon.svg';
import AppCSS from '../../../components/App.module.css';
import ApprovalsStyles from '../styles/PhaseApprovals.module.css';
import { UserAvatar } from '../../shared/components/UserAvatar';
import { LifecycleApproval, KeyValuePair } from '../utils/typings';
import { PhaseApprovalDialog } from './PhaseApprovalDialog';
import { toTitleCase } from '../../shared/utils/helper';
import { BUTTONS, DELETE, TicketApprovalState } from '../../shared/utils/constants';
import { transformModalContent } from '../../automation/AutomationTable';
import { getDateWithDay } from '../../tikit/ticketHelper';
import { appState } from '../../AppState';
import { useTranslation } from 'react-i18next';
import { LIFECYCLE } from '../../../components/shared/utils/constants';
import { platformService } from '../../shared/services/platform.service';

const iconStyle = {
  size: 'small' as SvgIconSizeValue,
  style: { color: '#8f90a6' }
};
interface Props {
  data: LifecycleApproval;
  dataIndex: number;
  updateData: (editData: KeyValuePair[], index: number) => void;
  globalTheme: ThemePrepared<any>;
}

export const PhaseApproval = ({
  data,
  data: { Id, Name, AdditionalDetails, Approvers, ApprovalState, IsRequiredByAll },
  dataIndex,
  updateData
}: Props) => {

  const currentState = appState();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openWarningDeleteDialog, setOpenWarningDeleteDialog] = useState(false);
  const [transitions, setTransitions] = useState([]);
  const api = new platformService();

  const updateApproval = (approval: LifecycleApproval) => {
    let kv = [];
    Object.keys(approval).forEach(key => {
      kv.push({
        key: key,
        value: approval[key]
      } as KeyValuePair)
    });    
    updateData(kv, dataIndex);
  }

  const removeItem = () => {
    const pair: KeyValuePair = {
      key: "RemoveItem",
      value: dataIndex
    } 
    updateData([pair], dataIndex);
  }

  const peformDeleteCheck = async()=>{
    const transitionList = await api.getTransitionsByCriteria(data.Id.toString());
    setTransitions(transitionList);
    (transitionList.length > 0)?setOpenWarningDeleteDialog(true):setOpenDeleteDialog(true);
  }

  const { t } = useTranslation();
  const removeApprovalMessageText = t('lifecycle.delete-appoval-message', { approvalName: Name })
  const removeApprovalUsedMessageText = t('lifecycle.delete-appoval-used-message', { approvalName: Name })

  return (
    <FluentUIThemeConsumer
      render={globalTheme => (
        <>
          <div
            onClick={() => setOpenDialog(true)}
            className={`min-h-32 ml-2 cursor-pointer ${ApprovalsStyles['phaseApprovalCard']}`}
            style={{
              color: 'var(--mgt-theme-default-foreground)',
              ...ThemeColorScheme(globalTheme.siteVariables)
            }}
          >
            <div className="mb-2 text-xs mt-1 mr-2">
              <Flex
                column
                gap="gap.small"
                style={{
                  background: `${globalTheme.siteVariables.theme === themeNames.Dark ? 'rgb(51, 51, 51)' : '#fafafa'}`,
                  padding: '0.75rem',
                  boxShadow: 'var(--mgt-theme-border-color) 0px 1px 2px',
                  borderRadius: '3px'
                }}
              >
                <div style={{marginBottom: 0}}>
                  <Flex space="between" className="mb-2.5">
                    <Flex.Item>
                      <div className="flex space-x-1">
                        <div>
                          <ApprovalIcon />
                        </div>
                        <div>
                          <Text weight="semibold" size="small">
                            {Name}
                          </Text>
                        </div>
                      </div>
                    </Flex.Item>
                  </Flex>

                  {Approvers?.map(approver => (

                    <div>
                      <Flex space="between" className="mb-2.5">

                        <Flex.Item>
                          <div className="flex items-center mb-2.5 space-x-1">
                            <UserAvatar
                              {...{
                                idOrUpn: (approver.Email ?? approver.UserName),
                                avatarProps: {
                                  name: approver.FullName,
                                  size: 'small',
                                  className: 'cui-ml-2'
                                }
                              }}
                            />
                            <div>
                              <Text size="small">
                                {approver.Email === currentState.userRoles.roles[0] ? LIFECYCLE.YOU : approver.FullName}
                              </Text>
                              <Flex vAlign="center" gap="gap.smaller">
                                  {approver.HasApproved === TicketApprovalState.Approved && (
                                    <>
                                      <Text className={AppCSS.ColorGray} size="small">{LIFECYCLE.APPROVED_ON}</Text>
                                      <span className={AppCSS.ColorGray}>{getDateWithDay(approver.ModifiedDate)}</span>
                                    </>
                                  )}
                                  {approver.HasApproved === TicketApprovalState.Rejected && (
                                    <>
                                      <Text className={AppCSS.ColorGray} size="small">{LIFECYCLE.REJECTED_ON}</Text>
                                      <span className={AppCSS.ColorGray}>{getDateWithDay(approver.ModifiedDate)}</span>
                                    </>
                                  )}
                                  {approver.HasApproved === TicketApprovalState.Pending && data.ApprovalState === TicketApprovalState.Pending && 
                                  (<Text className={AppCSS.ColorGray} size="small">{LIFECYCLE.PENDING_APPROVAL}</Text>)}
                              </Flex>
                            </div>
                          </div>
                        </Flex.Item>
                      </Flex>
                    </div>
                  ))}
                </div>
                { IsRequiredByAll && <div className="text-xs">
                  <Alert warning
                    content={
                      <>
                          <Text weight="semibold" content={`${LIFECYCLE.NOTE}:`} />
                          <Text className="ml-1" size='small' content={LIFECYCLE.REQUIRED_APPROVER_RESPONSE} />
                      </>
                  }
                  />
                </div> }
                <Divider size={0} className={`mb-2.5`} />
                <div className="text-xs">
                  <Flex vAlign={`center`} space={`between`}>
                    <Flex.Item>
                      <Flex>
                        <Flex.Item>
                          <span className={`pt-1`}>
                            <AdditionalInfoIcon {...iconStyle} />
                          </span>
                        </Flex.Item>
                        <Flex.Item>
                          <>
                            <span style={{ color: '#8f90a6', marginLeft: '8px' }}>{LIFECYCLE.ADDITIONAL_INFO}: </span>
                            <span className={`ml-1`}>{AdditionalDetails}</span>
                          </>
                        </Flex.Item>
                      </Flex>
                    </Flex.Item>
                    <Flex.Item>
                      <Flex vAlign='center' className={`${ApprovalsStyles['phaseApprovalCardDelete']}`}
                        onClick={async e => {
                          e.stopPropagation();
                          await peformDeleteCheck();
                        }}
                        title={LIFECYCLE.DELETE_APPROVAL}
                      >
                        <Flex.Item>
                          <TrashCanIcon size={`small`} />
                        </Flex.Item>
                        <Flex.Item>
                          <span className='ml-1'>
                            {LIFECYCLE.DELETE_APPROVAL}
                          </span>
                        </Flex.Item>
                      </Flex>
                    </Flex.Item>
                  </Flex>
                </div>
                <div className="text-xs">
              </div>
              </Flex>
            </div> 
          </div>
          <PhaseApprovalDialog
            open={openDialog}
            onCancel={() => setOpenDialog(false)}
            saveApproval={updateApproval}
            initData={data}
          />
          <Dialog
            open={openDeleteDialog}
            onCancel={() => setOpenDeleteDialog(false)}
            cancelButton={toTitleCase(BUTTONS.CANCEL)}
            confirmButton={toTitleCase(DELETE)}
            onConfirm={() => {
              removeItem();
              setOpenDeleteDialog(false);
            }}
            content={removeApprovalMessageText}
            header={LIFECYCLE.DELETE_APPROVAL}
            style={{ maxWidth: '500px' }}
          />
          <Dialog
            open={openWarningDeleteDialog}
            onConfirm={() => setOpenWarningDeleteDialog(false)}
            confirmButton={toTitleCase(BUTTONS.OK)}
            content={<>
              <div>{removeApprovalUsedMessageText}</div>
              <br/>
              {transitions && transitions.map(transition =>
                <p>{transition.Title}</p>
              )}
            </>}
            header={LIFECYCLE.DELETE_APPROVAL}
            style={{ maxWidth: '500px' }}
          />
        </>
      )} />
  )
};
