import React, { useState } from "react";
import { Loader, ProviderConsumer, RadioGroup, Text } from '@fluentui/react-northstar';
import { Toolbar } from '../shared/components/Toolbar';
import { Helmet } from 'react-helmet';
import { AppStateContext } from "../AppState";
import { toTitleCase } from "../shared/utils/helper";
import { Breadcrumbs } from "../shared/components/BreadcrumbNavigation";
import { SETTINGS } from "../shared/utils/constants";
import { useTranslation } from 'react-i18next';
import { Languages } from '../../i18n/contants';


export const Preferences: React.FC = () => {
    const { i18n, t } = useTranslation();
    const navs: Breadcrumbs = {
        breadcrumbs: [
            {
                title: toTitleCase(SETTINGS),
                link: '/settings'
            },
            {
                title: toTitleCase(t('preferences.preferences')),
                link: ''
            }
        ]
    };

    const items = [
        { name: 'light', key: 'Light', label: t('preferences.light'), value: 'light' },
        { name: 'dark', key: 'Dark', label: t('preferences.dark'), value: 'dark' },
        { name: 'system', key: 'System', label: t('preferences.system'), value: 'system' },
    ];

    const requestItems = [
        { name: 'abbreviated', key: 'Abbreviated', label: t('preferences.abbreviated'), value: 'abbreviated' },
        { name: 'full', key: 'Full', label: t('preferences.default'), value: 'full' }
    ];

    const storedTheme = localStorage.getItem('TikitTheme')
    const [theme, setTheme] = useState(storedTheme ? storedTheme : items[0].value);
    const [language, setLanguage] = useState(i18n.language);
    const [languageObj, setLanguageObj] = useState(Languages().filter(x => x.isDefault)?.[0]);
    const [isSaving, setIsSaving] = useState(false);

    const requestLength = localStorage.getItem('TikitRequestLength');
    const [reqLength, setReqLength] = useState(requestLength || requestItems[0].value)

    const handleChange = (e, p) => setTheme(p.value);
    const handleReqChange = (e, p) => setReqLength(p.value);
    const handleLanguageChange = (e, p) => {
        setLanguage(p.value);
        setLanguageObj(p);
    }

    const saveData = () => {
        setIsSaving(true);
        localStorage.setItem('TikitTheme', theme);
        localStorage.setItem('TikitRequestLength', reqLength);;
        let lsLangs = JSON.parse(localStorage.getItem('TikitLanguages')) as Language[];
        lsLangs.forEach(x => x.isDefault = false);
        lsLangs.filter(x => x.value === languageObj.value)[0].isDefault = true;
        localStorage.setItem('TikitLanguages', JSON.stringify(lsLangs));
        i18n.changeLanguage(languageObj.value);
        location.reload();
    }

    return <AppStateContext.Consumer>
        {(state) => {
            return (
                <ProviderConsumer
                    render={globalTheme => (
                        <>
                            <Helmet>
                                <title>{t('preferences.title-page')}</title>
                            </Helmet>

                            <Toolbar
                                globalTheme={globalTheme}
                                title={`Settings > Preferences`}
                                breadcrumbs={navs}
                                saveText={t('preferences.save')}
                                saveCommand={saveData}
                                overrideLicenseCheck={true}
                            />
                            <div className="p-5 h-full">
                                {(isSaving) && (
                                    <div className={`text-center h-4/5 flex w-full justify-center`}>
                                        <Loader label={t('preferences.loading-tikit-theme')} />
                                    </div>
                                )}
                                {(!isSaving) && (
                                    <>
                                        <Text content={t('preferences.theme')} size="larger"  />
                                        <RadioGroup
                                            className="pt-5 pb-10"
                                            vertical
                                            defaultCheckedValue={theme}
                                            items={items}
                                            onCheckedValueChange={handleChange}
                                        />

                                        <Text content={t('preferences.request-length')} size="larger" />
                                        <RadioGroup className="pt-5" vertical defaultCheckedValue={reqLength} items={requestItems} onCheckedValueChange={handleReqChange} />
                                        <div className="pt-5">
                                            <Text content={t('preferences.language')} size="larger" />
                                            <RadioGroup
                                                className="pt-5"
                                                vertical
                                                defaultCheckedValue={language}
                                                items={Languages()}
                                                onCheckedValueChange={handleLanguageChange}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    )} />
            )
        }}
    </AppStateContext.Consumer>
}