import React from 'react';
import { Loader } from '@fluentui/react-northstar';

import { SkeletonBoard, SkeletonTable } from '../../shared/components/Skeleton';
import { LoadingScreen } from '../../shared/components/LoadingScreen';
import {useTranslation} from 'react-i18next';

interface Props {
  page: string;
}

export const RenderSkeleton = ({ page }: Props) => {
  const {t}= useTranslation();
  return page === 'chart' ? (    
    <LoadingScreen message={t('ticket.loading.chart')} />
  ) : page === 'board' ? (
    <SkeletonBoard />
  ) : (
    <SkeletonTable />
  );
}
