import { Providers, ProviderState } from "@microsoft/mgt-element";
import { useEffect, useState } from "react";
import { TeamsSSOProvider } from "../providers/TeamsSSOProvider";

export class SignedInState {
  static useIsSignedIn(): [boolean] {
    const [isSignedIn, setIsSignedIn] = useState(false);

    useEffect(() => {
      const updateState = () => {
        const provider = Providers.globalProvider;
        setIsSignedIn(provider && provider.state === ProviderState.SignedIn);
      };

      Providers.onProviderUpdated(updateState);
      updateState();

      return () => {
        Providers.removeProviderUpdatedListener(updateState);
      }
    }, []);

    return [isSignedIn];
  }
}

export class SignedInConsentState {
  static useIsSignedIn(): [ConsentState] {
    const [isSignedIn, setIsSignedIn] = useState(ConsentState.Loading);

    useEffect(() => {
      const updateState = () => {
        const provider = Providers.globalProvider;
        const teamsProvider = provider as TeamsSSOProvider;
        if (teamsProvider && teamsProvider.needsConsent)
          setIsSignedIn(ConsentState.NeedsConsent);
        else if (teamsProvider && teamsProvider.needsInstall)
          setIsSignedIn(ConsentState.NeedsInstall);
        else
          setIsSignedIn(SignedInConsentState.convert(provider.state));
      };

      Providers.onProviderUpdated(updateState);
      updateState();

      return () => {
        Providers.removeProviderUpdatedListener(updateState);
      }
    }, []);

    return [isSignedIn];
  }

  private static convert(providerState: ProviderState): ConsentState {
    return ConsentState[ProviderState[providerState] as keyof typeof ConsentState];
  }
}

export enum ConsentState {
  /**
   * Loading = 0
   */
  Loading = 0,
  /**
   * SignedOut = 1
   */
  SignedOut = 1,
  /**
   * SignedIn = 2
   */
  SignedIn = 2,
  /**
   * NeedsConsent = 3
   */
  NeedsConsent = 3,
  /**
   * NeedsInstall = 4
   */
  NeedsInstall = 4
}