import React, { CSSProperties } from 'react';

import _ from 'lodash';
import { Box, Card } from '@fluentui/react-northstar';
import MgtCss from '../../../mgt.module.css';

interface CardTemplateProps {
  children: JSX.Element[] | JSX.Element;
  styles?: CSSProperties;
  boxStyle?: any;
}

export const CardTemplate = (props: CardTemplateProps): JSX.Element => {
  return (
    <Card
      {...{
        style: {
          ...props.styles,
          position: 'relative',
          margin: `0 1.25rem .5rem 1.25rem`,
          width: 'auto',
          height: 'auto',
          marginLeft: '0px',
          marginRight: '0px'
        },
        className: MgtCss.mgtCardTemplate
      }}
    >
      <Box
        styles={{
          borderRadius: '4px',
          overflow: 'hidden'
        }}
        {...(_.isNil(props.boxStyle) && { style: props.boxStyle })}
      >
        {props.children}
      </Box>
    </Card>
  );
};
