import React from 'react';
import ChartJS from 'chart.js';
import {
  ProviderConsumer as FluentUIThemeConsumer,
  Loader
} from '@fluentui/react-northstar';
import { IChartData, ChartTypes } from './ChartTypes';
import {
  LineChart,
  LineStackedChart,
  ChartEmptyState,
  ChartErrorState,
  PieChart,
  BarChart,
  BarStackedChart,
  DoughnutChart
} from './ChartTypes/';

import { ChartTheme } from './ChartTheme';
import { TTextObject, getText } from './translations/';

(ChartJS as any).defaults.global.legend.display = false;
(
  ChartJS as any
).defaults.global.defaultFontFamily = `Segoe UI, system-ui, sans-serif`;
const CHARTS = {
  [ChartTypes.Line]: LineChart,
  [ChartTypes.LineStacked]: LineStackedChart,
  [ChartTypes.Pie]: PieChart,
  [ChartTypes.Doughnut]: DoughnutChart,
  [ChartTypes.Bar]: BarChart,
  [ChartTypes.BarStacked]: BarStackedChart
};

export interface IChartProps {
  title: TTextObject;

  type: ChartTypes;

  data?: IChartData;
}

export function Chart({ title, type, data }: IChartProps) {
  if (data && data.datasets && data.datasets.length > 6) {
    data.datasets = data.datasets.slice(0, 6);
  }

  const ChartContainer = CHARTS[type];
  const ChartComponent = props => {
    const { globalTheme, t } = props;
    if (data) {
      if (data?.datasets.length || data?.labels?.length) {
        return (
          <ChartContainer
            title={getText(t.locale, title)}
            data={data}
            siteVariables={globalTheme.siteVariables}
          />
        );
      }
      return <ChartEmptyState siteVariables={globalTheme.siteVariables} />;
    }
    return <ChartErrorState siteVariables={globalTheme.siteVariables} />;
  };

  return (
    <FluentUIThemeConsumer
      render={globalTheme => {
        const { t } = globalTheme.siteVariables;
        return (
          <ChartTheme globalTheme={globalTheme}>
            <React.Suspense fallback={<Loader />}>
              <ChartComponent t={t} globalTheme={globalTheme} />
            </React.Suspense>
          </ChartTheme>
        );
      }}
    />
  );
}
