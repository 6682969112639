import React from 'react';
import {
  Flex,
  Skeleton
} from '@fluentui/react-northstar';
export const RelatedTicketsListSkeleton = () => {
  return (
    <Skeleton animation="wave" className={`pt-5`}>
      <Flex gap="gap.large" column>

        <Flex gap="gap.small" column>
          <Flex.Item>
            <Skeleton.Line width="15%" />
          </Flex.Item>
          <Flex.Item>
            <Skeleton.Line />
          </Flex.Item>
          <Flex.Item>
            <Skeleton.Line width="85%" />
          </Flex.Item>
        </Flex>

        <Flex gap="gap.small" column>
          <Flex.Item>
            <Skeleton.Line width="15%" />
          </Flex.Item>
          <Flex.Item>
            <Skeleton.Line />
          </Flex.Item>
          <Flex.Item>
            <Skeleton.Line width="85%" />
          </Flex.Item>
        </Flex>

        <Flex gap="gap.small" column>
          <Flex.Item>
            <Skeleton.Line width="15%" />
          </Flex.Item>
          <Flex.Item>
            <Skeleton.Line />
          </Flex.Item>
          <Flex.Item>
            <Skeleton.Line width="85%" />
          </Flex.Item>
        </Flex>

      </Flex>
    </Skeleton>
  );
};