import React, { useState } from 'react';
import { getTicketStatusClass, IRelatedTicketsCard, RelationType } from '../RelateTicketHelper';
import { getUserData, getDateWithDay, threedigitized } from '../../../../tikit/ticketHelper';
import { Flex, Text } from '@fluentui/react-northstar';
import MergedIcon from '../../../../../svg/merged.svg';
import RelatedIcon from '../../../../../svg/related-icon.svg';
import ParentChildIcon from '../../../../../svg/child.svg';
import BlockedByIcon from '../../../../../svg/block-by.svg';
import StatusIcon from '../../../../../svg/status-dot.svg';
import PopupIcon from '../../../../../svg/popup.svg';
import { appState } from '../../../../AppState';
import CSS from './RelatedTicketCard.module.css';
import { Link, mergeStyleSets } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

const classNames = mergeStyleSets({
  userLink: {
    color: 'var(--mgt-theme-brand-foreground1)',
    ':hover, :active, :focus': {
      color: 'var(--mgt-theme-brand-foreground1) !important'
    }
  },
});

interface Props {
  ticket: IRelatedTicketsCard;
}

export const RelatedTicketCard = ({ ticket }: Props) => {
  const {t} = useTranslation();
  const currentState = appState();
  const [hoverState, setHoverState] = useState<boolean>(false);

  const getUserName = (createdById: number) => getUserData(createdById, currentState)?.FullName;
  const getStatusClass = (ticketStatusId: number) => getTicketStatusClass(ticketStatusId, currentState);
  const onCardClick = () => window.open(`${window.location.origin}/tickets/${ticket.Id}`, '_blank');
  const getRelateAction = () => ticket.RelationshipTypeId === RelationType.MergeTickets ? t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.merged-by'): t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.related-by');
  
  const getRelationIcon = () => {
    switch (ticket.RelationshipTypeId) {
      case RelationType.MergeTickets:
        return <MergedIcon width='22' height='17' className='mr-2 mb-1' />;
      case RelationType.RelateTickets:
        return <RelatedIcon width='22' height='17' className='mr-2 mb-1' />;
      case RelationType.ParentChild:
        return <ParentChildIcon width='22' height='17' className='mr-2 mb-1' />;
      case RelationType.BlockedBy:
        return <BlockedByIcon width='22' height='17' className='mr-2 mb-1' />;
      default:
        return <></>;
    }
  }

  return (
    <div key={`${ticket.Id}-${ticket.RelationshipTypeId}`} className={`relate-card-container ${CSS.MainDiv}`}>
      <Flex column gap="gap.small" className={`pt-3 pb-3`} onMouseEnter={()=>setHoverState(true)} onMouseLeave={()=>setHoverState(false)}>
        <Flex className={CSS.MarginBotton} vAlign="center">
          {getRelationIcon()} 
          <Link className={`${classNames.userLink} font-bold`} onClick={onCardClick}>#{threedigitized(ticket.Id)}</Link>
        </Flex>
        <div className={`${CSS.TitleDiv} break-all`}>
          <Flex className={CSS.MarginBotton}>
            <Link className={`${classNames.userLink} font-bold`} onClick={onCardClick}>
              <Text weight="semibold" size="medium">{ticket.Title}</Text>
            </Link>
            <div className={`${CSS.RedirectIcon} ${!hoverState && 'invisible'}`}>
              <PopupIcon className={`cursor-pointer`} onClick={onCardClick} />
            </div>
          </Flex>
        </div>
        <div className="text-xs">
          <Flex vAlign="center">
            <StatusIcon className={`mr-2 ${getStatusClass(ticket.StatusId)}`} />
            <span className={`text-gray-400`}>{getRelateAction()}&nbsp;</span>
            <span>{getUserName(ticket.CreatedById)}&nbsp;</span>
            <span className={`text-gray-400`}>{t('ticket-details.edit-ticket-form.relate-ticket.ticket-selection.on')}&nbsp;</span>
            <span>{getDateWithDay(ticket.CreatedDate)}</span>
          </Flex>
        </div>
      </Flex>
    </div>
  );
};
