import { useContext } from 'react';
import ListFunctions from '../../List/ListFunctions';
import { listInputItems } from '../../ticketHelper';
import { CustomViewContext } from '../CustomViewContextProvider';

const useSelectedViewName = () => {
  const { defaultViewId, isDefaultViewId } = ListFunctions();
  const { customViewStates } = useContext(CustomViewContext);

  if(isDefaultViewId){
    return listInputItems[defaultViewId] // viewName
  } else {
    return customViewStates.selectedCustomView?.Name || 'loading...' // viewName
  }
};

export default useSelectedViewName;
