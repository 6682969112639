import { AddIcon, Alert, Button, Card, Checkbox, CloseIcon, Dropdown, DropdownItemProps, Flex, Input, Loader, ProviderConsumer } from '@fluentui/react-northstar';
import * as microsoftTeams from "@microsoft/teams-js";
import React, { useState, useEffect } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { AppStateContext, useSetState } from '../AppState';
import { EditorComponent } from '../shared/components/Wysiwyg/Editor';
import KBCSS from '../kb.module.css';
import { ConsentState, SignedInConsentState } from '../shared/services/signInState.service';
import { LoginPrompt } from '../shared/components/LoginPrompt';
import { kbDataService } from '../shared/services/kb.service';
import { platformService } from '../shared/services/platform.service';
import { useTranslation } from 'react-i18next';
import { FILTERS_STRINGS } from '../shared/utils/constants';
import { getCachedFeature } from "../shared/cache/FeatureCache";
import { LICENSE_FEATURE, KNOWLEDGE_BASE } from "../shared/utils/constants";
import { UpgradeNeeded } from "../shared/components/UpgradeNeeded";
import { toTitleCase } from '../shared/utils/helper';

const AddKnowledge: React.FC = () => {
  const {t} = useTranslation();
  const api = new platformService();
  const params = new URLSearchParams(window.location.search);

  const [toBeCreatedKB, setToBeCreatedKB] = useState({ questions: [params.get('q') || ''], answer: params.get('a') || '', source: params.get('s') || 'Editorial', status: params.get('st') || 'Draft' , agentOnly: params.get('ao') || "false"});
  const setState = useSetState();
  const [isSignedIn] = SignedInConsentState.useIsSignedIn();
  const [useTemplate, { toggle: toggleUseTemplate }] = useBoolean(false);
  const [templates, setTemplates] = useState<Array<DropdownItemProps>>([]);
  const [stringAnswer, setStringAnswer] = useState(params.get('a') || '');
  const [templateAnswer, setTemplateAnswer] = useState('');
  const [hasError, setHasError] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState(t('knowledge.setting-up'));
  const [isTemplateDeflectionEnabled, setIsTemplateDeflectionEnabled] = useState(false);
  const [isTicketDeflectionEnabled, setIsTicketDeflectionEnabled] = useState(true);

  const fetchTicketTemplate = async () => {
    try {
      const result = await api.getTicketTemplates(`${FILTERS_STRINGS.FILTERS_URL_PARAMS} and NoCustomForm eq false and CardResponseJson ne '' and CardResponseJson ne null`);

      const templates = result.data.value.map(item => {
        return {
          accessibilityItemProps: {
            id: item.Id,
            answerValue: `ApplyTemplate:${item.Id}`
          },
          header: item.Name,
          selected: false
        } as DropdownItemProps;
      });

      setTemplates(templates);
    } catch {
      setTemplates([]);
    }

    let newstate = {
      tickets: [],
      closedTickets: [],
      platformusers: [],
      supportgroups: null,
      teams: null,
      menu: null,
      boardFilter: null,
      activeFilter: null,
      listFilter: null,
      ticketStatus: null,
      categories: null,
      priority: null,
      comments: [],
      isLoading: false,
      isError: false,
      listChartFilterDate: null,
      usersInRole: [],
    }

    setState((state) => {
      return { ...state, ...newstate };
    });
  }

  const loadPage = () => {
    fetchTicketTemplate();
  }

  useEffect(() => {
    if (isSignedIn === ConsentState.SignedIn) {
      fetchTicketTemplate();
      (async () => setIsTemplateDeflectionEnabled((await getCachedFeature(LICENSE_FEATURE.TemplateDeflection))))();
      (async () => setIsTicketDeflectionEnabled((await getCachedFeature(LICENSE_FEATURE.TicketDeflection))))();
    }
  }, [isSignedIn]);

  const CloseTaskModule = () => {
    microsoftTeams.dialog.url.submit();
  };
  const addKnowledgeItem = async () => {
    setHasError(false);
    if (toBeCreatedKB.questions.filter(x => x && x.trim() != "").length <= 0 || toBeCreatedKB.answer.trim() === "") {
      setHasError(true);
      return;
    }

    try {
      setLoadingMsg(t('knowledge.loading-knowledge-message'));
      setState(state => {
        return { ...state, isLoading: true };
      });
      await kbDataService.addAPI([toBeCreatedKB]);
      await api.sendToConversation({ Error: t('knowledge.knowledgebase-updated-successfully'), IsShowTyping: true });
    } catch {
      await api.sendToConversation({ Error: t('knowledge.an-error-occurred-updating-the-knowledgebase'), IsShowTyping: true });
    }

    setState(state => {
      return { ...state, isLoading: false };
    });
    CloseTaskModule();
  };
  
  let keyCounter = 0;

  return (
    <AppStateContext.Consumer>
      {(state) => {
        return (<>
          {(isSignedIn === ConsentState.NeedsConsent) && <LoginPrompt />}
          {(isSignedIn !== ConsentState.NeedsConsent) &&
          (!isTicketDeflectionEnabled) ? 
          <UpgradeNeeded 
            toolbarText={`${toTitleCase(KNOWLEDGE_BASE)}`} 
            headerText={t('knowledge.ticket-deflection.no-access')} 
            subheaderText={t('knowledge.ticket-deflection.upgrade-plan')} /> :
            (<ProviderConsumer render={(globalTheme) =>
              
            (state.isLoading ? <Loader label={loadingMsg} className="h-screen" /> : (<div className={`h-screen p-5 overflow-y-auto`}>
              {hasError && (<Alert content={t('knowledge.add-knowledge-modal.error.no-data')} danger visible />)}
              
              <div>
                <label className={`mb-2`}>Phrases</label>
                {(toBeCreatedKB.questions.map((q, index) => {
                  const key = keyCounter++
                  return (
                    <Flex  key={key}>
                      <Flex.Item>
                        <Input
                          inverted
                          fluid
                          showSuccessIndicator={false}
                          placeholder={t('knowledge.enter-a-phrase')}
                          maxLength={1000}
                          style={{
                            backgroundColor: globalTheme.siteVariables.colorScheme.default.background2
                          }}
                          variables={{
                            background: globalTheme.siteVariables.colorScheme.default.background2
                          }}
                          className={`${KBCSS.TransparentInput} mb-2`}
                          value={toBeCreatedKB.questions[index]}
                          onChange={(e, p) => {
                            let questions = toBeCreatedKB.questions
                            questions[index] = p.value
                            setToBeCreatedKB({ ...toBeCreatedKB, questions: questions });
                          }} />
                      </Flex.Item>
                      <Flex.Item>
                        <Button icon={<CloseIcon />} text iconOnly title={t('knowledge.remove')}
                          onClick={
                            () => {
                              if (toBeCreatedKB.questions.length > 1) {
                                let questions = toBeCreatedKB.questions.slice(0, index).concat(toBeCreatedKB.questions.slice(index + 1));
                                setToBeCreatedKB({ ...toBeCreatedKB, questions: questions });
                              } else {
                                setToBeCreatedKB({ ...toBeCreatedKB, questions: [''] });
                              }
                            }
                          }
                          styles={
                            {
                              alignSelf: "center",
                            }
                          }
                        />
                      </Flex.Item>
                    </Flex>
                  )
                }))}
                <Button icon={<AddIcon />} text content={t('knowledge.add-alternative-phrasing')} className={"mb-2"}
                  style={{
                    color: globalTheme.siteVariables.colorScheme.brand.foreground,
                  }}
                  {...{
                    onClick: (e) => {
                      setToBeCreatedKB({ ...toBeCreatedKB, questions: [...toBeCreatedKB.questions, ''] })
                    },
                  }}
                />
              </div>
              <div className={`dialog-editor`}>
                <Flex column>
                  <Flex.Item>
                    <label className={`mb-2`}>{t('knowledge.respond-with')}</label>
                  </Flex.Item>
                  {isTemplateDeflectionEnabled &&
                    <Flex.Item>
                      <Checkbox label={t('knowledge.use-template')} toggle onChange={() => {
                        toggleUseTemplate();
                        setToBeCreatedKB({ ...toBeCreatedKB, answer: (useTemplate) ? templateAnswer : stringAnswer });
                      }} checked={useTemplate} />
                    </Flex.Item>
                  }
                </Flex>
                {!useTemplate && (<EditorComponent markdownString={stringAnswer} onChange={(data: any) => {
                  setToBeCreatedKB({ ...toBeCreatedKB, answer: data });
                  setStringAnswer(data);
                }} maxLength={25000} useBorder={true} />)}
                {useTemplate && (<Dropdown fluid search items={templates} onChange={(e, p) => {
                  setToBeCreatedKB({ ...toBeCreatedKB, answer: (p.value ? p.value['accessibilityItemProps'].answerValue : '') });
                  setTemplateAnswer(p.value ? p.value['accessibilityItemProps'].answerValue : '');
                }} />)}
              </div>
              <Flex gap="gap.small" className={`pt-2`}>
                <Flex.Item push>
                  <Button content={t('knowledge.cancel')} onClick={CloseTaskModule}></Button>
                </Flex.Item>
                <Button primary content={t('knowledge.add-draft-knowledge')} onClick={addKnowledgeItem}></Button>
              </Flex>
            </div>))} />)}
        </>)
      }}
    </AppStateContext.Consumer>
  );
}

export default AddKnowledge;