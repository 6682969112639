import React from 'react';
import Styles from '../../Sla.module.css';

import { SLA } from '../../../shared/utils/constants';
import { SlaHeader } from '../SlaHeader';
import { TextInputField } from '../../../shared/common/TextInputField';
import { FormGroup } from '../../../shared/components/FormGroup/FormGroup';
import { Dropdown, FormCheckbox, FormLabel, ProviderConsumer } from '@fluentui/react-northstar';
import { toTitleCase } from '../../../shared/utils/helper';
import { useTranslation } from 'react-i18next';

interface Props {
  slaInfo: SlaInfo;
  updateSlaInfo: (slaInfo: SlaInfo) => void;
  statuses: DropdownDataModel[];
}

export const Info = ({ slaInfo, updateSlaInfo, statuses }: Props) => {
  const {t} = useTranslation();
  return (
    <ProviderConsumer render={globalTheme =>
      <>
        <SlaHeader />
        <h2 className={`text-base mb-2 font-bold`}>{SLA.GENERAL_SLA_INFORMATION}</h2>
        <FormGroup>
          <TextInputField
            label={SLA.NAME}
            value={slaInfo.name}
            className={Styles.RequiredCustomColor}
            isRequired
            updateValue={(value: string) => {
              updateSlaInfo({ ...slaInfo, name: value });
            }}
          />
        </FormGroup>
        <FormGroup>
          <FormCheckbox
            label={t('sla.enabled')}
            checked={slaInfo.isActive}
            onChange={(_, p) => {
              updateSlaInfo({
                ...slaInfo,
                isActive: p.checked
              });
            }}
          />
        </FormGroup>
        <FormGroup>
          <div className={`flex items-center text-base mb-2 font-bold`}>
            <h2 >{SLA.TICKET_STATUS}</h2>
            <span className="text-red-500 ml-1">*</span>
          </div>
          <FormLabel
            content={SLA.SLA_INFO_DESCRIPTION}
            styles={{ fontSize: '12px' }}
          />
        </FormGroup>
        <Dropdown
          inverted
          multiple
          fluid
          placeholder={toTitleCase(`select ${SLA.TICKET_STATUS}`)}
          items={statuses}
          style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
          value={statuses.filter(({ Id }) => slaInfo.statusList.includes(Id))}
          onChange={(_e, p) => {
            const data = p.value as DropdownDataModel[];
            updateSlaInfo({
              ...slaInfo,
              statusList: data.map(({ Id }) => Id)
            });
          }}
        />
      </>
    } />
  );
};
