/** List node */
type Node<T> = {
  p: () => T
  res: (value: T) => void
  rej: (reason: any) => void
  next?: Node<T>
}

/** Queue interface */
export interface Queue {
  add<T>(promiseFunction: () => PromiseLike<T>): Promise<T>
  done(): Promise<void>
  clear(): void
}

/**
 * Creates a new queue with the specified concurrency level.
 *
 * @param {number} concurrency - The maximum number of concurrent operations.
 * @return {Queue} - The newly created queue.
 */
export const AsyncQueue = (concurrency: number): Queue => {
  let active = 0
  let size = 0
  let head: Node<PromiseLike<any>> | undefined | null
  let tail: Node<PromiseLike<any>> | undefined | null
  let resolveDonePromise: (value: void | PromiseLike<void>) => void
  let donePromise: Promise<void> | void

  const afterRun = () => {
    active--;
    if (--size) 
      run();
    else
      donePromise = resolveDonePromise?.();
  };

  const run = () => {
    if (head && active < concurrency) {
      active++;
      let curHead = head;
      head = head.next;
      curHead.p().then(
        (v) => (curHead.res(v), afterRun()),
        (e) => (curHead.rej(e), afterRun())
      )
    }
  }

  return {
    add<T>(p: () => PromiseLike<T>) {
      let node = { p } as Node<PromiseLike<T>>;
      let promise = new Promise((res, rej) => { node.res = res; node.rej = rej; });
      tail = (head) ? tail!.next = node : head = node;
      
      size++;
      run();
      return promise as Promise<T>;
    },
    done: () => {
      if (!size)
        return Promise.resolve();
      
      if (donePromise)
        return donePromise
      
      return (donePromise = new Promise((resolve) => (resolveDonePromise = resolve)))
    },
    clear() {
      head = tail = null
      size = active
    }
  }
}