import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { CloseIcon, Dialog, ProviderConsumer as FluentUIThemeConsumer, Menu, Text, tabListBehavior } from '@fluentui/react-northstar';
import _ from 'lodash';
import { RequestToolbar } from './RequestToolbar';
import { appState, useSetState } from '../../AppState';
import { platformService } from '../../shared/services/platform.service';
import { TicketRequestCard } from './TicketRequestCard/TicketRequestCard';
import { RequestPageSkeleton } from './Skeletons/RequestPageSkeleton';
import { Nav } from '../../browser-pages/browser-components/Nav';
import { TablePagination } from '../../shared/components/table/TablePagination';
import { EmptyData } from '../../shared/components/EmptyData';
import FlashlightSVG from './../../../svg/flashlight.svg';
import { CONTACT_ADMIN, LICENSE_FEATURE, UNAUTHORIZED_ACCESS, SOMETHING_WENT_WRONG, FILTERS_STRINGS, APPROVAL_PAGINATION, TicketApprovalState, TICKET_PAGINATION } from '../../shared/utils/constants';
import { checkInTeams } from '../../App';
import { Helmet } from 'react-helmet';
import { NewRequest } from './NewRequest';
import { ApprovalColumn } from './TicketRequestCard/ApprovalColumn';
import { getCachedFeature } from '../../shared/cache/FeatureCache';
import { useTranslation } from 'react-i18next';
import { UpgradeNeeded } from '../../shared/components/UpgradeNeeded';
import RequestContextProvider, { RequestListContext } from './RequestList/RequestContextProvider';
import RequestFunctions from './RequestList/RequestFunctions';
import { ConsentState, SignedInConsentState } from '../../shared/services/signInState.service';

export const Requests = () => {
  const { t } = useTranslation();

  return (<>
    <Helmet>
      <title>{t('ticket.toolbar.tickets')} - Tikit</title>
    </Helmet>
    <RequestContextProvider>
      <RequestsPage />
    </RequestContextProvider>
  </>);
};

const RequestsPage = () => {
  const { t } = useTranslation();
  const api = new platformService();
  const setState = useSetState();
  const currentState = appState();

  const { getPaginatedRequests, moveToRequestPage, getPaginatedApprovals, moveToApprovalPage } = RequestFunctions();
  const { requestStates, setRequestStates, approvalStates, setApprovalStates, showClosed, setShowClosed, searchFilter, setSearchFilter } = useContext(RequestListContext);

  const [isSignedIn] = SignedInConsentState.useIsSignedIn();
  const isReloadClickedRef = useRef(false);

  const [dialog, setDialog] = useState(false);
  const [dialogClose, setDialogClose] = useState(true);
  const [column, setColumn] = useState<number>(0);
  const [teamsConfig, setTeamsConfig] = useState({});

  const setError = (e: any) => {
    const errCode = e.response ? e.response.status : 500;
    let errorstate = {
      ...currentState,
      isLoading: false,
      isError: true,
      errorConfig: { fields: { title: errCode == 401 || errCode == 403 ? UNAUTHORIZED_ACCESS : SOMETHING_WENT_WRONG, desc: CONTACT_ADMIN } }
    };
    if (!_.isEqual(currentState, errorstate)) {
      setState(errorstate);
    }
  };

  const getStatusFromAPI = async () => (await api.getStatus()).data.value;
  const getStatusFromState = () => currentState.ticketStatus;

  const getUsersFromState = () => currentState.platformusers;
  const getUsersFromAPI = async () => (await api.getUsers()).data.value;

  const getTeamsFromState = () => currentState.teams;
  const getTeamsFromAPI = async () => (await api.getTeamsSafe(FILTERS_STRINGS.TICKET_TEAMS));

  const loadPage = async () => {
    try {
      await getCachedFeature(LICENSE_FEATURE.TicketLifecycle);

      const results = await Promise.all([
        (currentState?.ticketStatus?.length > 0) ? Promise.resolve(getStatusFromState()) : getStatusFromAPI(),
        (currentState?.platformusers.length > 0) ? Promise.resolve(getUsersFromState()) : getUsersFromAPI(),
        (currentState?.teams.length > 0) ? Promise.resolve(getTeamsFromState()) : getTeamsFromAPI(),
        (currentState?.defaultTeam?.Id) ? [currentState?.defaultTeam] : api.getTeamsSafe(FILTERS_STRINGS.IS_DEFAULT),
        (currentState?.teamsConfig?.TenantId) ? currentState?.teamsConfig : (await api.getTeamsBotConfiguration()).data
      ]);

      const allStatus = results[0];
      const allUsers = results[1];
      const allTeams = results[2];
      const defaultTeam = results[3][0];
      const botconfigResult = results[4];

      setState(prevState => {
        prevState.platformusers = allUsers
        prevState.ticketStatus = allStatus
        prevState.teams = allTeams
        prevState.defaultTeam = defaultTeam
        prevState.teamsConfig = botconfigResult
        return prevState;
      });

      setTeamsConfig(botconfigResult || {});

    } catch (err) {
      console.error(err);
      setError(err);
    }
  };

  const loadItems = async () => {
    await loadPage();
    getPaginatedRequests(requestStates.currentPage);
    getPaginatedApprovals(approvalStates.currentPage);
  };

  const refreshLists = (checked: boolean, search: string) => {
    setRequestStates((prev) => ({ ...prev, isTicketsFetching: true, currentPage: 1 }));
    setApprovalStates((prev) => ({ ...prev, isApprovalsFetching: true, currentPage: 1 }));
    getPaginatedRequests(1, checked, search);
    getPaginatedApprovals(1, checked, search);
  };

  const toggleClosed = (checked: boolean) => {
    setShowClosed(checked);
    refreshLists(checked, undefined);
  };

  const searchItems = useCallback(_.debounce((searchText: string) => {
    setSearchFilter(searchText);
    refreshLists(undefined, searchText);
  }, 2000), [showClosed]);

  const refreshPage = useCallback(() => {
    setState(state => ({ ...state, isReload: true }));
    setRequestStates(state => ({ ...state, isTicketsFetching: true }));
    setApprovalStates(state => ({ ...state, isApprovalsFetching: true }));
    refreshLists(undefined, undefined);
  }, [currentState, requestStates, approvalStates, showClosed, searchFilter]);

  const onApprovalUpdate = (approvalId: number, approverId: number, status: TicketApprovalState, approvalState: TicketApprovalState) => {};


  const items = [
    {
      key: 'tickets',
      content: 'Tickets',
    },
    {
      key: 'approvals',
      content: 'Approvals',
    },
  ]

  useEffect(() => {
    if (isSignedIn == ConsentState.SignedIn && !isReloadClickedRef.current && !currentState.isReload)
      loadItems();
    if (isSignedIn == ConsentState.SignedIn && currentState.isReload)
      loadItems();

    if (!currentState.isReload)
      isReloadClickedRef.current = false;
  }, [isSignedIn, currentState.isReload]);

  useEffect(() => {
    if (!approvalStates.isApprovalsFetching) 
      setColumn(approvalStates.countApprovals === 0 ? 2 : 0);
  }, [approvalStates.isApprovalsFetching])

  const [isFeatureEnabled, setIsFeatureEnabled] = useState(true);
  (async () => setIsFeatureEnabled((await getCachedFeature(LICENSE_FEATURE.TicketPortal))))();

  return ((!isFeatureEnabled) ? <UpgradeNeeded toolbarText={t('ticket.toolbar.tickets')} headerText={t('ticket-portal.no-access-current-plan')} subheaderText={t('ticket-portal.no-access-upgrade-plan')} /> :
    <FluentUIThemeConsumer render={(globalTheme) => (
      <>
        {!checkInTeams() && <Nav />}
        <RequestToolbar
          globalTheme={globalTheme}
          initialFilterValue={showClosed}
          changeFilter={toggleClosed}
          searchTickets={searchItems}
          onClickRefresh={refreshPage}
          onClickRequest={() => { setDialog(true); }}
          onChangeColumn={(e) => { setColumn(e) }}
          column={column}
          hasApprovals={approvalStates.approvals.length > 0}
        />
        <div className={`h-screen`}>
          <div style={{ height: 'calc(100vh - 90px - 3.25rem)', paddingBottom: '20px' }}>
            <div className='block md:hidden'>
              <Menu
                defaultActiveIndex={0}
                items={items}
                underlined
                primary
                accessibility={tabListBehavior}
                aria-label="Today's events"
                onActiveIndexChange={(_e, p) => {
                  if(p.activeIndex === 0) {
                    setColumn(2)
                  } else {
                    setColumn(1)
                  }
                }}
              />
            </div>
            <div style={{ maxWidth: column === 0 ? '1920px' : '800px', margin: 'auto' }} className={`h-full px-5 md:p-0`}>
              <div className={`flex w-full`}>
                <div className={` ${column !== 0 ? 'w-full' : 'w-full md:w-1/2'} px-0 py-5 md:px-5 ${column === 1 ? 'hidden' : ''}`}>
                  <div>
                    <Text content={t('requests.tickets')} weight={'semibold'} size='large' />
                  </div>
                  {requestStates.isTicketsFetching && (<RequestPageSkeleton />)}
                  {!requestStates.isTicketsFetching && requestStates.tickets.length === 0 && (<EmptyData SVGIcon={<FlashlightSVG width={200} height={200} className={'pb-2'} />} headerText={t('requests.no-matches')} subheaderText={t('requests.try-different-search')} />)}
                  {!requestStates.isTicketsFetching && requestStates.tickets.length > 0 && (
                    <div className="overflow-auto" style={{ height:'calc(100vh - 165px - 3.25rem)' }}>
                      {requestStates.tickets.map((ticket: Ticket) => (<TicketRequestCard ticket={ticket} view='list' userId={currentState.currentUserId} />))}
                    </div>
                  )}
                  {!requestStates.isTicketsFetching && requestStates.tickets.length > 0 && (
                    <TablePagination currentPage={requestStates.currentPage} totalCount={requestStates.countTickets} moveToPage={moveToRequestPage} itemPerPage={TICKET_PAGINATION.ITEM_PER_PAGE} />
                  )}
                </div>
                <div className={`${column !== 0 ? 'w-full' : 'w-full md:w-1/2 border-l hidden md:block'} px-0 py-5 md:px-5 ${column === 2 ? 'hidden' : ''}`}>
                  <div>
                    <Text content={t('requests.approvals-columns.approvals')} weight={'semibold'} size='large' />
                  </div>
                  {approvalStates.isApprovalsFetching && (<RequestPageSkeleton />)}
                  {!approvalStates.isApprovalsFetching && approvalStates.approvals.length === 0 && (<EmptyData SVGIcon={<FlashlightSVG width={200} height={200} className={'pb-2'} />} headerText={t('requests.no-matches')} subheaderText={t('requests.try-different-search')} />)}
                  {!approvalStates.isApprovalsFetching && approvalStates.approvals.length > 0 && (
                    <ApprovalColumn 
                        pagination={{
                          currentPage: approvalStates.currentPage,
                          totalCount: approvalStates.countApprovals,
                          moveToPage: moveToApprovalPage,
                          itemPerPage: APPROVAL_PAGINATION.ITEM_PER_PAGE
                        }}
                        paginatedApprovals={approvalStates.approvals}
                        userId={currentState.currentUserId}
                        users={currentState.platformusers}
                        column={column}
                        onUpdate={onApprovalUpdate}
                      />
                    )}
                  </div>
              </div>
            </div>
          </div>
        </div>

        <Dialog
          open={dialog}
          content={{
            content: (<NewRequest globalTheme={globalTheme} onCreateTicket={() => setDialogClose(false)} onCreateComplete={() => setDialogClose(true)} teamsConfig={teamsConfig} />),
            styles: { marginBottom: 0 },
            className: `new-request-dialog`,
            id: 'new-request'
          }}
          header={t('requests.new-request')}
          headerAction={(dialogClose ? {
            icon: <CloseIcon />,
            title: 'Close',
            onClick: () => setDialog(false)
          } : <></>)}
          className='w-full-important md:max-w-flyout'
        />
      </>
    )} />
  );
};
