import React, { useEffect, useRef, useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import { AppStateContext, FilterDropdownProps, useSetState } from '../../AppState';
import { customizedFilter } from '../../shared/components/Filter';
import { CustomViewContext } from '../toolbar/CustomViewContextProvider';
import { getCustomViewDropdownFilters, getSortOrderFromStorage, setSelectedCustomView } from '../toolbar/CustomViewPopup/heplers/customPopupHelper';
import { ListContext } from './ListContextProvider';
import ListFunctions from './ListFunctions';
import { FILTERS, STATUS_GUID } from '../../shared/utils/constants';
import { TableFilterOptions } from '../ticketHelper';

interface Props {
  defaultFilters: FilterPropsItems[];
  isBoardView?: boolean;
  setParentFilteredTickets?: React.Dispatch<React.SetStateAction<any>>;
  allTickets?: {
    active: any[];
    closed: any[];
    all: any[];
  };
}

const useCustomViewsTickets = ({ defaultFilters, isBoardView, setParentFilteredTickets, allTickets }: Props) => {
  const state = useContext(AppStateContext);
  const setState = useSetState();
  const { setListStates } = useContext(ListContext);
  const { getpaginatedTickets, getCurrentUser, getStatusIdByGuid, customViewId, defaultViewId, isDefaultViewId, setTicketInterval } = ListFunctions();
  const { customViewStates, setCustomViewStates } = useContext(CustomViewContext);

  const history = useHistory();
  const location = useLocation();

  const firstCall = useRef(false);
  const isMounted = useRef(false);

  function setIdOfRequestersFromPlatformUsers(FiltersDropdownValues: FilterPropsItems[] | undefined) {
    if (!FiltersDropdownValues) return;
    let requesterFilterValues = FiltersDropdownValues.find(dropdownValue => dropdownValue.title === FILTERS.REQUESTERS.title);
    if (!requesterFilterValues) return;
    requesterFilterValues.values = requesterFilterValues?.values?.map(value => {
      const platformuser = state.platformusers.find(user => user.Id === value.key)
      value.aadObjectId = platformuser?.AadObjectId;
      value.title = platformuser?.UserName;
      return value;
    })
  }

  const ticketsObj = {
    [TableFilterOptions.AllActive]: () => allTickets.active.filter((ticket: Ticket)=> ticket.StatusId !== getStatusIdByGuid(STATUS_GUID.RESOLVED)),
    [TableFilterOptions.MyWork]: () => allTickets.active.filter((ticket: Ticket) => (getCurrentUser()?.Id === ticket.AssigneeId && ticket.StatusId !== getStatusIdByGuid(STATUS_GUID.RESOLVED))),
    [TableFilterOptions.Unassigned]: () => allTickets.active.filter((ticket: Ticket) => (!ticket.AssigneeId  && ticket.StatusId !== getStatusIdByGuid(STATUS_GUID.RESOLVED))),
    [TableFilterOptions.Closed]: () => allTickets.closed.filter((ticket: Ticket) => (ticket.StatusId === getStatusIdByGuid(STATUS_GUID.CLOSED) || ticket.StatusId === getStatusIdByGuid(STATUS_GUID.RESOLVED))),
    [TableFilterOptions.Deflected]: () => allTickets.closed.filter((ticket: Ticket) => ticket.StatusId === getStatusIdByGuid(STATUS_GUID.DEFLECTED)),
  }

  useEffect(() => {
    if (isBoardView && (!allTickets || allTickets.all.length <= 0)) return;

    const currentPage = 1;

    // state.listFilter === undefined // it means that user refreshed page, or page is loading first time
    if (state.listFilter !== undefined) return;
    if (firstCall.current) return;

    if (!customViewId || customViewStates.customViews.length <= 0) return;
    const customView = customViewStates.customViews.find((customView) => customView.Id === Number(customViewId));

    let FiltersDropdownValues: FilterPropsItems[] | undefined;

    const sortOrder: ISortOrder = customView?.OrderBy
      ? { orderBy: customView.OrderBy, orderType: customView.OrderType }
      : null;
    const updatedSortOrder = getSortOrderFromStorage() || sortOrder;

    if (customView) {
      FiltersDropdownValues = getCustomViewDropdownFilters(JSON.parse(JSON.stringify(defaultFilters)), customView.ViewFilters);
      setSelectedCustomView({
        customView,
        defaultFilters,
        setCustomViewStates,
        history,
        location,
        sortOrderUponRefresh: updatedSortOrder
      });
    } else {
      setCustomViewStates((prev): CustomViewStatesType => ({
        ...prev,
        selectedCustomView: undefined,
        configureColumnsApplied: {
          prevSortOrder: updatedSortOrder,
          sortOrder: updatedSortOrder
        }
      }));
    }

    if (isBoardView) {
      setIdOfRequestersFromPlatformUsers(FiltersDropdownValues);
      const filterDropdownProps = {
        collapseList: [],
        isOpen: false,
        isQueryEmpty: true,
        queryValue: '',
        selectedValues: FiltersDropdownValues,
      } as FilterDropdownProps;
      const tickets = customView ? allTickets.all : ticketsObj[defaultViewId]();
      setParentFilteredTickets(customizedFilter(tickets, filterDropdownProps, Math.random()));
    } else {
      getpaginatedTickets({ page: currentPage, FiltersDropdownValues, sortOrder: updatedSortOrder });
    }
    firstCall.current = true;
    isMounted.current = true;

  }, [customViewStates?.customViews, allTickets?.all]);

  useEffect(() => {
    // This useEffect will run on click refresh button also
    const currentPage = 1;
    let timer = null;

    if (!isBoardView) {
      setListStates((prev) => ({
        ...prev,
        isTicketsFetching: true,
        currentPage,
      }));
    }

    // on page loading, if search_params or localstorage contain customview id then some data needs to be fetched from backend before calling getpaginatedTickets. this functionality is handled in above useEffect
    if (customViewId && state.listFilter === undefined && !state.filtersApplyCount) return;

    if (isBoardView && (allTickets?.all?.length ?? -1) >= 0) {
      setIdOfRequestersFromPlatformUsers(customViewStates.selectedCustomView?.FiltersDropdownValues);
      const filterDropdownProps = {
        collapseList: [],
        isOpen: false,
        isQueryEmpty: true,
        queryValue: '',
        queryValueApplied: '',
        selectedValues: customViewStates.selectedCustomView?.FiltersDropdownValues || [],
      } as FilterDropdownProps;

      const tickets = isDefaultViewId && defaultViewId >= 0 ? ticketsObj[defaultViewId]() : allTickets.all;
      setParentFilteredTickets(customizedFilter(tickets, filterDropdownProps, Math.random()));
    }
    if (!isBoardView) {
      if (!isMounted.current) {
        setCustomViewStates(prev => ({
          ...prev,
          configureColumnsApplied: {
            prevSortOrder: getSortOrderFromStorage(),
            sortOrder: getSortOrderFromStorage(),
          }
        }));
      }

      getpaginatedTickets({
        page: currentPage,
        ...(!isMounted.current && {
          defaultFilters,
        }),
      });
      isMounted.current = true
      if (timer) clearInterval(timer);
      timer = setTicketInterval();
    }

    return () => { if (timer) clearInterval(timer); }
  }, [
    state.listFilter, // on refresh this state gets new address in memory
    state.filtersApplyCount,
    allTickets?.all
  ]);

  const isWholeNumber = (num: number | undefined) => {
    if (!num) return false;
    return num % 1 === 0;
  }

  useEffect(() => {
    return () => {
      setState(prev => ({
        ...prev,
        listFilter: isWholeNumber(prev.listFilter) ? prev.listFilter : undefined,
      }));
    };
  }, []);

  return <></>;
};

export default useCustomViewsTickets;