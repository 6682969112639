import React from 'react';
import {
    Flex,
    ProviderConsumer as FluentUIThemeConsumer, Tooltip,
} from '@fluentui/react-northstar';
export interface TButtonProps {
    icon?: string;
    name: string;
    isActive: boolean;
    onClick?: (interaction: any) => void;
    iconComponent: React.ComponentType<React.SVGAttributes<SVGElement>>;
}

export interface TButtonData {
    buttonActive: number
}

interface Props {
    className?: string;
    onChange?: (interaction: any) => void;
    buttonList: TButtonProps[];
}


const TButton: React.FC<TButtonProps> = (props: TButtonProps) => {

    return (
        <FluentUIThemeConsumer
            render={(globalTheme) => (
                <Tooltip content={props.name} trigger={<button aria-labl style={{backgroundColor: globalTheme.siteVariables.colorScheme.default.background}} onClick={props.onClick}>
                    <props.iconComponent style={{
                        fill: props.isActive ? globalTheme.siteVariables.colorScheme.brand.foreground : globalTheme.siteVariables.colorScheme.default.foreground,
                    }} />
                </button>} />
            )} />
    )
}

export const ToggleButton: React.FC<Props> = (props: Props) => {
    const clickHandler = (index: number) => {
        if (props.onChange) {
            props.onChange({ buttonActive: index });
        }
    }
    return (
        <FluentUIThemeConsumer
            render={(globalTheme) => (
                <Flex className='space-x-4'
                styles={{
                  marginRight: '16px',
                  marginLeft: '4px',
                  alignItems: 'center',
                  display: 'inline-flex',
                 
                }}>
                 {props.buttonList.map((b, i) => {
                        return (
                            <TButton {...b} onClick={() => { clickHandler(i) }} />
                        )
                    })}
                </Flex>
            )} />
    )
}