import React from 'react';
import { Box, Flex, Skeleton } from '@fluentui/react-northstar';
import { Col, Row } from 'react-grid-system';

export const RequestDetailCardSkeleton = ({ globalTheme }) => (
  <Flex column className="relative">
    <div
      className={`px-5 pt-5 mb-5`}
      style={{
        backgroundColor: globalTheme.siteVariables.colorScheme.default.background,
        minHeight: '50px'
      }}
    >
      <Box>
        <Row>
          <Col style={{ marginBottom: 15 }}>
            <Flex gap="gap.small" style={{ marginBottom: 15 }}>
              <Skeleton animation="wave">
                <Skeleton.Shape width="100px" height="20px" />
              </Skeleton>

              <div style={{ float: 'right' }}>
                <Skeleton animation="wave">
                  <Skeleton.Line width="50px" height="20px" />
                </Skeleton>
              </div>
            </Flex>

            <Flex gap="gap.small" className={`mb-4`}>
              <Skeleton animation="wave">
                <Skeleton.Shape width="100%" height="20px" />
                <Skeleton.Shape width="30%" height="20px" />
              </Skeleton>
            </Flex>

            <Flex gap="gap.small">
              <Skeleton animation="wave">
                <Skeleton.Shape width="20%" height="20px" />
              </Skeleton>

              <div style={{ float: 'right' }}>
                <Skeleton animation="wave">
                  <Skeleton.Line width="10%" height="20px" />
                </Skeleton>
              </div>
            </Flex>
          </Col>
        </Row>
      </Box>
    </div>
  </Flex>
);
