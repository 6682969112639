import React, { useState, useEffect, useCallback } from 'react';
import Styles from './styles/Lifecycle.module.css';
import { ProviderConsumer, Button, CloseIcon, Text } from '@fluentui/react-northstar';
import { Providers } from '@microsoft/mgt-element';
import { Toolbar } from '../shared/components/Toolbar';
import { Communication, TToolbarInteraction } from '@fluentui/react-teams';
import {
  DONE,
  ERROR,
  LOADING
} from '../shared/utils/constants';
import { Breadcrumbs } from '../shared/components/BreadcrumbNavigation';
import { platformService } from '../shared/services/platform.service';
import { Information } from './views/Information';
import { CheckLogin } from '../shared/components/CheckLogin';

import { Helmet } from 'react-helmet';
import { LoadingScreen } from '../shared/components/LoadingScreen';
import { DEFAULT_LIFECYCLE_INFO_DATA, INITIAL_LIFECYCLEPHASE_DATA, TransitionActionTypeString, INITIAL_LIFECYCLETRANSITION_DATA } from './utils/constants';
import { PhaseSection } from './views/PhaseSection';
import { Lifecycle, LifecycleData, LifecyclePhase } from './utils/typings';
import { PlatformUser } from '../shared/interfaces/platformuser.interface';
import { getNewGuid } from './utils/helper';
import { cloneDeep } from 'lodash';
import { useHistory } from 'react-router-dom';
import useUnsavedChangesWarning from '../shared/hooks/useUnsavedChangesWarning';
import { LIFECYCLE, CONTACT_ADMIN, UNAUTHORIZED_ACCESS, SOMETHING_WENT_WRONG } from '../shared/utils/constants';
import { useTranslation } from 'react-i18next';
import { loadFilters } from '../tikit/ticketHelper';
import { graphService } from '../shared/services/graph.service';
import { appState, useSetState } from '../AppState';

interface Props {
  id: string;
}

export const FormGroup = ({
  children,
  marginClass
}: {
  children: React.ReactElement | React.ReactElement[];
  marginClass: string
}) => <div className={marginClass}>{children}</div>;

export const LifecycleForm = ({ id }: Props) => {
  const { t } = useTranslation();
  const api = new platformService();
  const graphAPI = new graphService();
  const currentState = appState();
  const setState = useSetState();
  const queryId = parseInt(id);
  const history = useHistory();
  const [intialInfoData, setIntialInfoData] = useState<LifecycleData>(
    DEFAULT_LIFECYCLE_INFO_DATA
  );
  const clonedPhaseData = cloneDeep({...INITIAL_LIFECYCLEPHASE_DATA, Guid: getNewGuid(), 
    Name: `${t('lifecycle.new-phase')} 1`, Transitions: [cloneDeep({...INITIAL_LIFECYCLETRANSITION_DATA, Title: `${t('lifecycle.new-transition')} 1` })]});
  const [intialPhasesData, setIntialPhasesData] = useState<
    LifecyclePhase[]
  >((queryId == 0) ? [clonedPhaseData] : []);

  const [isFormUpdated, setIsFormUpdated] = useState<boolean>(false);
  const [Prompt, setDirty] = useUnsavedChangesWarning();
  const setFormDirty: (boolVlaue: boolean) => void = setDirty as (boolVlaue: boolean) => void;

  const [apiStatus, setAPIStatus] = useState<APIStatus>({
    status: LOADING,
    msg: `${t('common.loading')}`,
    errCode: 0
  });
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLifecycleValid, setIsLifecycleValid] = useState(false);
  const [platformUserList, setPlatformUserList] = useState<PlatformUser[]>([]);
  const [taskStatusList, setTaskStatusList] = useState<TaskStatus[]>([]);
  const [infoData, setInfoData] = useState<LifecycleData>(
    DEFAULT_LIFECYCLE_INFO_DATA
  );

  const [phasesData, setPhasesData] = useState<
    LifecyclePhase[]
  >((queryId == 0) ? [clonedPhaseData] : []);

  const setError = err => {
    setAPIStatus({
      ...apiStatus,
      status: ERROR,
      errCode: err
    });
  };

  const saveIntialData = (data: Lifecycle) => {

    setIntialInfoData({
      ...intialInfoData,
      Id: data.Id,
      Title: data.Title,
      Description: data.Description,
      SetStatusResolved: data.SetStatusResolved
    });

    const stringifyData = JSON.stringify(data.Phases);
    const parseData = JSON.parse(stringifyData);

    setIntialPhasesData(parseData);

  }

  const setInitData = (data: Lifecycle) => {
    setAPIStatus({
      status: LOADING,
      msg: LIFECYCLE.FETCHING_LIFECYCLE,
      errCode: 0
    });

    setInfoData({
      ...infoData,
      Id: data.Id,
      Title: data.Title,
      Description: data.Description,
      SetStatusResolved: data.SetStatusResolved
    });

    setPhasesData(data.Phases);
    saveIntialData(data);

    setAPIStatus({ ...apiStatus, status: DONE });
  }
  
  const fetchExtraData = useCallback(async () => {
    setAPIStatus({
      status: LOADING,
      msg: LIFECYCLE.FETCHING_LIFECYCLE,
      errCode: 0
    });

    try {
      const results = await Promise.all([
        api.getUsersInRole(),
        api.getTaskStatus(`?$filter=IsDeleted eq false and IsArchived eq false`)
      ]);
      setPlatformUserList(JSON.parse(results[0].data.value));
      setTaskStatusList(results[1]);
      setAPIStatus({ ...apiStatus, status: DONE });
    } catch (err) {
      setError(err);
    }
  }, []);

  const fetchLifecycleDataForEdit = useCallback(async () => {
    setAPIStatus({
      status: LOADING,
      msg: LIFECYCLE.FETCHING_LIFECYCLE,
      errCode: 0
    });

    try {

      const expandTasksQuery = "Tasks($expand=Assignee,Team,SupportGroup,TaskCollaborators)";
      const expandApprovalsQuery = "Approvals($expand=Approvers)";
      const expandPowerAutomateTasksQuery = "PowerAutomateTasks($expand=Assignee)";

      const expandTransitionActionQuery = "TransitionAction($expand=TransitionItem)";
      const expandTransitionsQuery = `Transitions($expand=${expandTransitionActionQuery})`;

      const { data } = await api.getLifecycles(`(${queryId})?$expand=Phases($expand=${expandTasksQuery},${expandApprovalsQuery},${expandPowerAutomateTasksQuery},${expandTransitionsQuery})`);
      
      const ids = data.Phases.map((item: LifecyclePhase) => 
        {
          return item.Transitions.map(x => x.TransitionRuleId);
        }
      );
      const ruleIds = ids.flat(1).filter(Number).toString();
      const expandTransitionRuleCriteriaQuery = "TransitionRuleCriterias($expand=TransitionItem)";
      const expandTransitionRuleGroupsQuery = `TransitionRuleGroups($expand=${expandTransitionRuleCriteriaQuery})`;
      const result = await api.getTransitionRule(`?$filter=Id in (${ruleIds})&$expand=${expandTransitionRuleGroupsQuery}`);
      const rules = result.data.value;
      data.Phases.map((item: LifecyclePhase) => 
        {
          item.Transitions = item.Transitions.map(x => {
            x.TransitionRule = rules.filter(o => o.Id == x.TransitionRuleId)[0];
            x.IsExpand = false;
            return x;
          });
          item.IsExpand = false;
          return item;
        }
      );

      setInitData(data);

      setAPIStatus({ ...apiStatus, status: DONE });
    } catch (err) {
      setError(err);
    }
  }, [queryId]);

  const saveLifecycle = useCallback(async () => {
    setAPIStatus({
      status: LOADING,
      msg: LIFECYCLE.SAVING_LIFECYCLE,
      errCode: 0
    });

    try {

      const lifecycleData: Lifecycle = {
        Id: infoData.Id,
        Title: infoData.Title,
        Description: infoData.Description,
        SetStatusResolved: infoData.SetStatusResolved,
        Phases: cloneDeep(phasesData)
      };

      const { data: {data, error} } = await api.saveLifecycle(lifecycleData);
      setFormDirty(false);
      if(data == "Success"){
        setAPIStatus({ ...apiStatus, status: DONE });
        history.push('/settings/lifecycle');
      }else
        setError(error);
      
    } catch (err) {
      setError(err);
    }

  }, [infoData, phasesData]);

  const cancelLifeCycle = useCallback(() => {
    history.push('/settings/lifecycle');
  }, []);

  const [isCheckedIn, setIsCheckedIn] = useState<boolean>(false);

  const loadPage = async () => {
    setIsCheckedIn(true);
    await loadFilters(api, graphAPI, currentState, setState);
  }

  useEffect(() => {
    if (isCheckedIn) {
      if (queryId > 0) {
        fetchLifecycleDataForEdit();
      }
      fetchExtraData();
    }
  }, [queryId, isCheckedIn]);

  useEffect(() => {
    setFormDirty(isFormUpdated);
  }, [isFormUpdated]);

  useEffect(() => {
    setIsFormUpdated(checkIfFormUpdated());
  }, [infoData, phasesData, intialInfoData, intialPhasesData]);

  const checkIfFormUpdated = (): boolean => {
    let isUpdated = false;
    isUpdated = compareObjectProperties(infoData, intialInfoData);
    if (!isUpdated) {
      isUpdated = compareObjectList(phasesData, intialPhasesData);
    }
    return isUpdated;
  }

  const compareObjectList = (updatedList: any[], initialList: any[]) => {
    if ((updatedList?.length ?? 0) !== (initialList?.length ?? 0)) return true;
    let isObjectUpdated = false;
    for (let i = 0; i < updatedList.length; i++) {
      isObjectUpdated = compareObjectProperties(updatedList[i], initialList[i]);
      if (isObjectUpdated) break;
    }
    return isObjectUpdated;
  }

  const compareObjectProperties = (updatedObject: any, initialObject: any): boolean => {
    
    let isUpdated = false;
    let propertiesList = Object.keys(updatedObject);
    const listTypes = ["Transitions", "TransitionRuleGroups", "TransitionRuleCriterias", "Tasks", "Approvals", "Approvers", "PowerAutomateTasks" ];
    for (let property of propertiesList) {
      if (listTypes.includes(property)) {
        isUpdated = compareObjectList(updatedObject[property], initialObject[property]);
        if (isUpdated) break;
      }
      else if (property == 'TransitionAction' || property == 'TransitionRule') {
        isUpdated = compareObjectProperties(updatedObject[property], initialObject[property]);
        if (isUpdated) break;
      }
      else if (property !== 'Guid' && property !== 'IsExpand') {
        if (updatedObject[property]?.toString() !== initialObject[property]?.toString()) {
          isUpdated = true;
          break;
        }
      }
    }
    return isUpdated;
  }

  const updatePhaseData = (item: LifecyclePhase[]) => {
    setPhasesData([...item]);
  }

  useEffect(() => {
    const isValid = validateLifecycle(infoData, phasesData);
    setIsLifecycleValid(isValid);
  }, [infoData, phasesData]);

  const validateLifecycle = (iData: LifecycleData, pData: LifecyclePhase[]) => {
    if(!iData.Title)
      return false;
    let validFlag = true;
    pData.forEach(phase => {
      if(!phase.Name)
        validFlag = false;
      phase.Transitions.forEach(transition => {
        if(!transition.Title)
          validFlag = false;
        if(!transition.UseDefaultLogic){
          transition.TransitionRule.TransitionRuleGroups.forEach(group => {
            group.TransitionRuleCriterias.forEach(rule => {
              if(!rule.PropertyType) validFlag = false;
              if(!rule.TransitionItem) validFlag = false;
              if(!rule.StatusId) validFlag = false;
            })
          });
          if (!transition.TransitionAction.TransitionItem && 
            (transition.TransitionAction.ActionType == TransitionActionTypeString.MoveToPhaseX)
          ) validFlag = false;
        }
      });
    });
    return validFlag;
  }

  const onToolbarInteraction = (interaction: TToolbarInteraction) => {
    if (interaction.action === 'toggle-menu') {
      setMenuOpen(!menuOpen);
    }
  };

  const errConfig = {
    fields: {
      title:
        apiStatus.errCode === 401 
          ? UNAUTHORIZED_ACCESS
          : SOMETHING_WENT_WRONG,
      desc:  CONTACT_ADMIN
    }
  };

  const navs: Breadcrumbs = {
    breadcrumbs: [
      {
        title: LIFECYCLE.BREADCRUMB_SETTINGS,
        link: '/settings'
      },
      {
        title: LIFECYCLE.NAME,
        link: '/settings/lifecycle'
      },
      {
        title: infoData.Title.length > 0 ? infoData.Title : LIFECYCLE.NAME,
        link: ''
      }
    ]
  };

  return (
    <CheckLogin onSignedIn={loadPage}>
      <Helmet>
        <title>{t('lifecycle.lifecycle-tikit')}</title>
      </Helmet>
      <div className="hidden md:block">
        {
          apiStatus.status === LOADING ? (
            <LoadingScreen message={apiStatus.msg} />
          ) : apiStatus.status === ERROR ? (
            <div style={{ height: '100vh' }}>
              <Communication {...errConfig} />
              {apiStatus.errCode === 401 && (
                <Button
                  content={t('common.refresh-session')}
                  primary
                  onClick={e => {
                    Providers.globalProvider.login();
                  }}
                />
              )}
            </div>
          ) : (
            <ProviderConsumer
              render={globalTheme => {
                return (
                  <div className={Styles.AutomationWrapper}>
                    <Toolbar
                      onInteraction={onToolbarInteraction}
                      globalTheme={globalTheme}
                      title={`${LIFECYCLE.BREADCRUMB_SETTINGS} > ${LIFECYCLE.NAME}`}
                      saveText={LIFECYCLE.SAVE}
                      saveCommand={saveLifecycle}
                      breadcrumbs={navs}
                      isLifecycleForm={true}
                      cancelLifecycleCommand={cancelLifeCycle}
                      disableSave={!isFormUpdated || !isLifecycleValid}
                    />
                    <div className={Styles.AutomationContainer}>
                      <div className={Styles.Automation}>
                        <Helmet>
                          <title>
                            {t('lifecycle.page-title', {infoDataTitle: infoData.Title})}
                          </title>
                        </Helmet>
                        <Information
                          infoData={infoData}
                          updateInfoData={setInfoData}
                        />

                        <PhaseSection data={phasesData} updateData={updatePhaseData} 
                          globalTheme={globalTheme} lifecycleData={infoData} 
                          userList={platformUserList}
                          taskStatusList={taskStatusList}
                        />
                      </div>
                    </div>
                  </div>
                );
              }}
            />
          )}
      </div>
      {Prompt}
    </CheckLogin>
  );
};
