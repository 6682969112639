import { ThemePrepared } from '@fluentui/react-northstar';
import React, { useState } from 'react';

import Switch from 'react-switch';
import { AND, OR } from '../../utils/constants';

interface Props {
  type: GroupType;
  changeGroupType: (type: GroupType) => void;
  globalTheme: ThemePrepared<any>
}

const FLEX_CENTER = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

export const SwitchButton = ({ type, changeGroupType, globalTheme }: Props) => {
  const [checked, setChecked] = useState(type === AND || type=="And");
  return (
    <Switch
      checked={checked}
      onChange={() => {
        changeGroupType(checked ? "Or" : "And");
        setChecked(prevState => !prevState);
      }}
      handleDiameter={30}
      offColor={globalTheme.siteVariables.colorScheme.brand.foreground}
      onColor={globalTheme.siteVariables.colorScheme.brand.foreground}
      offHandleColor="#fff"
      onHandleColor="#fff"
      height={34}
      width={72}
      borderRadius={50}
      activeBoxShadow={`0px 0px 1px 2px ${globalTheme.siteVariables.colorScheme.brand.foreground}`}
      uncheckedIcon={
        <div
          style={{
            ...FLEX_CENTER,
            height: '100%',
            fontSize: '12px',
            color: '#fff',
            paddingRight: 2,
            opacity: 0.4
          }}
        >
          {AND.toUpperCase()}
        </div>
      }
      checkedIcon={
        <div
          style={{
            ...FLEX_CENTER,
            height: '100%',
            fontSize: '12px',
            color: '#fff',
            paddingRight: 2,
            opacity: 0.4
          }}
        >
          {OR.toUpperCase()}
        </div>
      }
      uncheckedHandleIcon={
        <div
          style={{
            ...FLEX_CENTER,
            height: '100%',
            color: '#242424',
            fontSize: '12px',
            fontWeight: 'bold'
          }}
        >
          {OR.toUpperCase()}
        </div>
      }
      checkedHandleIcon={
        <div
          style={{
            ...FLEX_CENTER,
            height: '100%',
            color: '#242424',
            fontSize: '10px',
            fontWeight: 'bold'
          }}
        >
          {AND.toUpperCase()}
        </div>
      }
      className="react-switch"
      id="small-radius-switch"
    />
  );
};
