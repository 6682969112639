import React from 'react';
import { Button, Header, Text } from '@fluentui/react-northstar';

export const TenantNotFound = () => {
  return (
    <div className="h-screen flex items-center justify-center flex-col px-8">
      <img src="https://signup.tikit.ai/images/tikit-logo-stacked.png" width="300" />
      <Header as="h1" content="Thanks for your interest in Tikit Virtual Agent" />
      <Text content={<>
          <div>Hi there! I'm the Tikit Virtual Agent here to elevate the end user support experience by providing real-time access to all your service desk needs. I empower employees to help themselves through AI-powered ticket deflection,
          predefined templates to expedite IT requests and easy-to-use functionality enabling them to create, engage with and view all service desk tickets created in Tikit.</div>
          <br />
          <div>Tikit Virtual Agent works with an existing Tikit Subscription. Please make sure you have setup Tikit first by following the Tikit Quick Start Guide <a href="https://help.tikit.ai/articles/quick-start/" target="_blank">here</a>.</div>
        </>} />
      <br/>
      <a href="https://help.tikit.ai/contact-support/" className={`cursor-pointer px-4 py-2 block`} target='_blank'>
        <Button primary content="Contact Support" />
      </a>
    </div>
  );
};