import { STORAGE_CONSTANTS } from '../../../shared/utils/constants';
import { getLocalStorageItem, setlocalStorageItem } from '../../toolbar/CustomViewPopup/heplers/customPopupHelper';

const getStorageKey = (isMyGroupWork?: boolean) => {
    return isMyGroupWork ? 'MY_GROUP_WORK' : 'MY_WORK_LIST';
}
export const setShowClosedInStorage = (isMyGroupWork: boolean, isClosed?: boolean) => {
    setlocalStorageItem(STORAGE_CONSTANTS[getStorageKey(isMyGroupWork)].SHOW_CLOSED, isClosed);
};
export const getShowClosedFromStorage = (isMyGroupWork: boolean): boolean | undefined => {
    return getLocalStorageItem(STORAGE_CONSTANTS[getStorageKey(isMyGroupWork)].SHOW_CLOSED) || undefined;
}

export const setSelectedTabInStorage = (isMyGroupWork: boolean, tabId?: MyWorkListDataType) => {
    setlocalStorageItem(STORAGE_CONSTANTS[getStorageKey(isMyGroupWork)].TAB, tabId);
};
export const getSelectedTabFromStorage = (isMyGroupWork: boolean): MyWorkListDataType | undefined => {
    return getLocalStorageItem(STORAGE_CONSTANTS[getStorageKey(isMyGroupWork)].TAB) || undefined;
}

export const setMyWorkSortOrderInStorage = (isMyGroupWork: boolean, tabId: MyWorkListDataType, sortOrder?: ISortOrder) => {
    setlocalStorageItem(STORAGE_CONSTANTS[getStorageKey(isMyGroupWork)].SORT_ORDER[tabId], sortOrder);
};
export const getMyWorkSortOrderFromStorage = (isMyGroupWork: boolean, tabId: MyWorkListDataType): ISortOrder | null => {
    return getLocalStorageItem(STORAGE_CONSTANTS[getStorageKey(isMyGroupWork)].SORT_ORDER[tabId]);
}

export const setMyWorkLastPagePathInStorage = (isMyGroupWork: boolean, path: string) => {
    setlocalStorageItem(STORAGE_CONSTANTS[getStorageKey(isMyGroupWork)].LAST_PAGE_PATH, path);
};
export const getMyWorkLastPagePathFromStorage = (isMyGroupWork: boolean): string | null => {
    return getLocalStorageItem(STORAGE_CONSTANTS[getStorageKey(isMyGroupWork)].LAST_PAGE_PATH);
}
