import { FormInput } from '@fluentui/react-northstar';
import React from 'react';
import { LabelElement } from '../components/Modal/Modal';
import { toTitleCase } from '../utils/helper';
import i18next from 'i18next';
import MgtCss from './../../mgt.module.css';
interface Props {
  label: string;
  isLabelToTitleCase?: boolean;
  isRequired?: boolean;
  value: string;
  updateValue: (value: string) => void;
  placeholder?: string;
  className?: string;
  labelClassName?: string;
  errorMessage?: string;
  disabled?: boolean;
}


export const TextInputField = ({
  label,
  isLabelToTitleCase,
  isRequired,
  value,
  updateValue,
  placeholder,
  className,
  errorMessage,
  disabled,
  labelClassName,
}: Props) => (
    <>
        <div className={disabled ? MgtCss.disabledContainer : ''}>
          <LabelElement className={labelClassName} label={(isLabelToTitleCase == undefined || isLabelToTitleCase) ? toTitleCase(label) : label} required={isRequired}></LabelElement>
        </div>
        <FormInput
            inverted
            fluid
            className={className}
            required={isRequired ?? false}
            placeholder={(placeholder) ? placeholder : `${i18next.t('common.enter-the')} ${label}`}
            showSuccessIndicator={false}
            errorMessage={errorMessage==undefined?'':errorMessage}
            value={value}
            disabled={disabled}
            onChange={e => {
                updateValue((e.target as HTMLInputElement).value);
            }}
        />
    </>
);
