import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';  // IMPORTANT: DO NOT USE WITHOUT DOM-PURIFY. This could lead to script injection.
import DOMPurify from 'dompurify';

import { Button, Card, Flex, Loader } from '@fluentui/react-northstar';
import { Input } from 'adaptivecards';
import { AdaptiveCardElement } from '../../templates/AdaptiveCardElement';
import { platformService } from '../../shared/services/platform.service';
import { markdownComponents } from '../../shared/utils/constants';
import { useTranslation } from 'react-i18next';
import { getPortalSource, getUserFromToken } from '../ticketHelper';

const CreateRequest: React.FC = () => {
  const { type, itemId } = useParams<any>();
  const { t } = useTranslation();
  const [state, setState] = useState(0);
  const [request, setRequest] = useState("");
  const [answer, setAnswer] = useState("");
  const [templateResponse, setTemplateResponse] = useState({});
  const history = useHistory();

  const api = new platformService();

  const submitTicket = async () => {
    try {
      setState(5);

      const requester = await getUserFromToken();
      let source = await getPortalSource();

      const ticket: any = { 
        Title: request,
        RequesterId: requester.Id,
        Requester: { EmailAddress: (requester.Email ?? requester.UserName), AadObjectId: requester.AadObjectId, UserPrincipalName: requester.UserName },
        TemplateId: parseFloat(itemId),
        SourceId: source.Id
      };
      if (itemId) 
        ticket.CardAnswerJson = JSON.stringify(templateResponse)
      
      const r = await api.addRequest(ticket);
      history.push(`/requests/${r.data.Id}`);
    }
    catch {
      setState(4);
    }
  };

  const getTemplate = async (templateId) => {
    try {
      const result = await api.getTicketTemplates(`(${templateId})`);
      if (result?.data?.IsArchived ?? true) 
        setState(6);
      else if (result?.data?.CardResponseJson) {
        setAnswer(result.data.CardResponseJson);
        setRequest(result.data.Name);
        setState(1);
      } else 
        setState(4);
    } catch { 
      setState(4);
    }
  };
  const getKnowledge = async (answerId) => {
    try {
      const result = await api.getKnowledgeArticle(answerId);
      setAnswer(result.data.Answer);
      setState(3);
    } catch { 
      setState(4);
    }
  };

  useEffect(() => {
    if (type === 'template') 
      getTemplate(itemId)
    else
      getKnowledge(itemId);
  }, []);

  return (<div className={`h-screen`}>
      <Card style={{ width: '100%', overflowY: 'scroll' }}>
        <>
          {state === 0 && (<Loader className='m-4' label={t('requests.dialog.loading')} />)}
          {state === 1 && (
            <div className='email-request-card'>
              <Card.Body>
                <AdaptiveCardElement jsonString={answer} cardProps={{ styles: { height: 'fit-content' } }} onInputValueChanged={(input: Input) => { setTemplateResponse(cardResponse => { cardResponse[input.id] = input.value; return cardResponse; }); }} />
              </Card.Body>
              <Card.Footer>
                <Flex gap='gap.small' className={'mt-4'} hAlign='end'><Button primary content={t('common.buttons.submit')} onClick={submitTicket} style={{ marginRight: 0 }} /></Flex>
              </Card.Footer>
            </div>
          )}
          {state === 2 && (<div className='text-center'>{t('requests.dialog.received')}  {t('requests.dialog.close-window')}</div>)}
          {state === 3 && (<div className='max-w-3xl mx-auto'><Markdown remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]} components={markdownComponents}>{DOMPurify.sanitize(answer)}</Markdown></div> )}
          {state === 4 && (<div className='text-center'>{t('requests.dialog.oops')}</div>)}
          {state === 5 && (<Loader className='m-4' label={t('requests.dialog.creating')} />)}
          {state === 6 && (<div className='text-center'>{t('requests.dialog.archived-article')}</div>)}
        </>
      </Card>
    </div>);
};

export default CreateRequest;
