import React, { useCallback, useEffect, useState } from 'react';

import { platformService } from '../shared/services/platform.service';
import { SignedInState } from '../shared/services/signInState.service';
import '../AppGlobals';
import {
  DEFAULT_API_STATUS,
  DONE,
  ERROR,
  LOADING,
  ACCESS_TOKEN
} from '../shared/utils/constants';
import { TokenModal } from './TokenModal';
import { Button, CloseIcon, Dialog, Divider, Flex } from '@fluentui/react-northstar';
import { ConvertDateToLocale } from '../shared/common/ConvertDate';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

export interface TokenFormProperties {
  id: string;
  open: boolean;
  isView: boolean;
  onCancel: () => void;
  onSuccessFullCall: () => void;
  myRoles: any[];
}

export const Tokens = (props: TokenFormProperties) => {
  const {t} = useTranslation();
  const { id, open, isView, onCancel, onSuccessFullCall, myRoles } = props;
  const queryId: number = parseInt(id);
  const api = new platformService();

  const [apiStatus, setAPIStatus] = useState<APIStatus>(
    DEFAULT_API_STATUS as APIStatus
  );
  const [isSignedIn] = SignedInState.useIsSignedIn();
  const [tokenName, setTokenName] = useState('');
  const [expiration, setExpiration] = useState('');
  const [create, setCreate] = useState(false);
  const [secret, setSecret] = useState('');

  const updateAPIStatus = (newState: Partial<APIStatus>) => {
    setAPIStatus({ ...apiStatus, ...newState });
  };

  const fetchAccessTokenDefaultInstance = async () => {
    updateAPIStatus({
      status: DONE
    });
    setTokenName('');
  };

  const fetchAccessToken = useCallback(async () => {
    updateAPIStatus({
      msg: ACCESS_TOKEN.FETCHING_ACCESS_TOKEN_DETAILS,
      status: LOADING
    });

    try {
      const { data } = await api.getAccessToken(id);
      setTokenName(data.Name);
      setExpiration(data.ExpirationDate);
    } catch (error: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: error.response.status
      });
    } finally {
      updateAPIStatus({ status: DONE });
    }
  }, [queryId]);

  useEffect(() => {
    if (isSignedIn) {
      if (queryId > 0) fetchAccessToken();
      else fetchAccessTokenDefaultInstance();
    }
  }, [isSignedIn, queryId]);

  const createToken = async (payload: any) => {
    updateAPIStatus({
      msg: ACCESS_TOKEN.CREATING_NEW_ACCESS_TOKEN,
      status: LOADING
    });

    try {
      let token = await api.createToken(payload);
      updateAPIStatus({
        msg: 'good',
        status: DONE
      });
      setCreate(true);
      setSecret(token.data.value as string);
    } catch (err: any) {
      updateAPIStatus({
        status: ERROR,
        errCode: err.response.status
      });
    }
  };

  const copyToClipboard = () => {
    let range = document.createRange();
    range.selectNode(document.getElementById("secret_value"));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy"); 
  }

  return (
    (create ? <Dialog
      open={open}
      content={
        <div>
          <div style={{ position: 'absolute', top: '1.5rem', left: '1rem', right: '4rem' }}>
          <Flex space="between">
            <Flex.Item push grow>
              <div style={{ fontSize: '1.5rem', fontWeight: 500 }}>{t('access-token.authentication-token')}</div>
            </Flex.Item>
            <Flex.Item><Button primary content={t('access-token.copy-to-clipboard')} onClick={copyToClipboard}/>
            </Flex.Item>
          </Flex>
        </div>
        <div style={{ position: 'absolute', top: '3.5rem', left: '1rem', right: '1rem' }}>
          <Divider style={{ marginTop: 20, marginBottom: 5 }} />
          <div style={{ fontSize: 12, color: '#8f90a6' }}>
            {t('access-token.authentication-msg')}
          </div>
          <div id="secret_value" style={{ fontSize: 15, marginTop: 15 }}>
            {secret}
          </div>
        </div>
        </div>
      }
      style={{ height: '200px', maxWidth: '600px' }}
      headerAction={{
        icon: <CloseIcon />,
        title: 'Close',
        onClick: () => {
          onCancel();
          onSuccessFullCall();
        },
      }}
    /> :
      <TokenModal
        applicationRoles={myRoles}
        apiStatus={apiStatus}
        name={ACCESS_TOKEN.TOKEN}
        open={open}
        isView={isView}
        inputValue={tokenName}
        expiration={ConvertDateToLocale(expiration, DateTime.DATETIME_MED)}
        showCheckBox={false}
        onCancelHandler={onCancel}
        onConfirmationHandler={(data) => {
          setTokenName(data);
          createToken(data);
        }}
      />
    ))
};
