import React, { Dispatch, SetStateAction } from "react";
import uniqueId from "lodash/uniqueId";
import cloneDeep from "lodash/cloneDeep";

import { FormDialog } from "../Form/Form";
import { IPreparedBoardItem, IPreparedBoardItems } from "./BoardItem";
import { TBoardLanes } from "./BoardLane";
import { TUsers } from "@fluentui/react-teams/lib/esm/types/types";
import { TFunction } from "i18next";

export enum BoardItemDialogAction {
  Create,
  Edit,
}

export interface BoardItemDialogProps {
  action: BoardItemDialogAction;
  trigger: JSX.Element;
  initialState: Partial<IPreparedBoardItem>;
  arrangedLanes: TBoardLanes;
  setArrangedItems: Dispatch<SetStateAction<IPreparedBoardItems>>;
  arrangedItems: IPreparedBoardItems;
  users: TUsers;
  t: TFunction<"translation", undefined>
}

export const BoardItemDialog = ({
  action,
  trigger,
  initialState,
  arrangedLanes,
  setArrangedItems,
  arrangedItems,
  users,
  t,
}: BoardItemDialogProps) => {
  return (
    <FormDialog
      trigger={trigger}
      headerSection={{
        title: (function () {
          switch (action) {
            case BoardItemDialogAction.Create:
              return t("ticket.ticket-board.add-board-item");
            case BoardItemDialogAction.Edit:
              return t("ticket.ticket-board.edit-board-item");
          }
        })(),
      }}
      sections={[
        {
          inputGroups: [
            {
              type: "text-inputs",
              fields: [
                {
                  type: "text",
                  title: t("ticket.ticket-board.title"),
                  inputId: "board-item__title",
                  initialValue: initialState["title"] as string,
                },
              ],
            },
            {
              type: "text-inputs",
              fields: [
                {
                  type: "text",
                  title: t("ticket.ticket-board.subtitle"),
                  inputId: "board-item__subtitle",
                  optional: true,
                  initialValue: initialState["subtitle"] as string,
                },
              ],
            },
            {
              type: "multiline-text",
              title: t("ticket.ticket-board.board-item-body"),
              inputId: "board-item__body",
              optional: true,
              initialValue: (initialState.body
                  ? Array.isArray(initialState.body)
                    ? initialState.body[0]
                    : initialState.body
                  : "") as string
            },
            {
              type: "dropdown",
              title: t("ticket.ticket-board.board-lane"),
              inputId: "board-item__lane",
              multiple: false,
              options: Object.keys(arrangedLanes).map((laneKey) => ({
                title: arrangedLanes[laneKey].title,
                value: laneKey,
              })),
              initialValue: initialState.lane,
            },
            {
              type: "dropdown",
              title: t("ticket.ticket-board.board-item-users"),
              inputId: "board-item__users",
              optional: true,
              multiple: true,
              options: Object.keys(users).map((userKey) => ({
                title: users[userKey].name,
                value: userKey,
              })),
              initialValues: initialState.users.map(u => u.toString()),
            },
          ],
        },
      ]}
      submit={t("ticket.ticket-board.save")}
      cancel={(function () {
        switch (action) {
          case BoardItemDialogAction.Create:
            return t("ticket.ticket-board.discard");
          case BoardItemDialogAction.Edit:
            return t("ticket.ticket-board.cancel");
        }
      })()}
      onInteraction={({ formState }) => {
        if (!formState) return;
        const boardItem = Object.keys(formState).reduce(
          (
            boardItem: {
              [boardItemPropKey: string]: any;
            },
            inputId
          ) => {
            const [_prefix, boardItemProperty] = inputId.split("__");
            const value = formState[inputId];
            if (value) boardItem[boardItemProperty] = value;
            return boardItem;
          },
          (function () {
            switch (action) {
              case BoardItemDialogAction.Create:
                return {
                  order: -1,
                  itemKey: uniqueId("nbi"),
                };
              case BoardItemDialogAction.Edit:
                return cloneDeep(initialState);
            }
          })()
        );
        switch (action) {
          case BoardItemDialogAction.Create:
            arrangedItems[boardItem.lane as string].push(
              (boardItem as unknown) as IPreparedBoardItem
            );
            break;
          case BoardItemDialogAction.Edit:
            const fromPos = arrangedItems[
              initialState.lane as string
            ].findIndex(
              (laneItem) => laneItem.itemKey === initialState.itemKey!
            );
            if (boardItem.lane !== initialState.lane) {
              arrangedItems[initialState.lane as string].splice(fromPos, 1);
              arrangedItems[boardItem.lane as string].push(
                boardItem as IPreparedBoardItem
              );
            } else {
              arrangedItems[boardItem.lane as string][
                fromPos
              ] = boardItem as IPreparedBoardItem;
            }
        }
        setArrangedItems(cloneDeep(arrangedItems));
      }}
    />
  );
};
