import { Button, ProviderConsumer, TrashCanIcon } from '@fluentui/react-northstar';
import { themeNames } from '@fluentui/react-teams';
import React from 'react';
import { ThemeColorScheme } from '../../shared/common/TeamsTheme';
import { CheckLicense } from '../../shared/components/License/CheckLicense';
import Styles from './MappedRolesItem.module.css';

interface Props {
  title: string;
  deleteHandler: () => void;
  inModal?: boolean;
}

export const MappedRolesItem = ({ title, inModal, deleteHandler }: Props) => {
  return (
    <ProviderConsumer
      render={globalTheme => {
      const isDarkMode = globalTheme.siteVariables.theme === themeNames.Dark;

      return (
        <ul style={{ position: 'relative', ...ThemeColorScheme(globalTheme.siteVariables)}}
          className={`flex justify-between items-center ${inModal ? Styles.ModalMappedRoleItem : Styles.MappedRoleItem}
          ${isDarkMode && Styles.RoleItemBorderDark || ''}`}
        >
          <li>{title}</li>
          <li>
            <CheckLicense disable>
              <Button
                icon={<TrashCanIcon />}
                text
                iconOnly
                onClick={e => {
                  e.stopPropagation();
                  deleteHandler();
                }}
              />
            </CheckLicense>
          </li>
        </ul>
      )}}/>
  );
};
