import React, { useEffect, useState } from 'react';
import Styles from './OperatorField.module.css';

import { Dropdown, DropdownProps, FormLabel, ProviderConsumer } from '@fluentui/react-northstar';
import { AUTOMATION_OPERATORS } from '../../../../../../shared/utils/constants';
import { useTranslation } from 'react-i18next';

interface Props {
  propertyValue: string;
  operatorValue: string;
  updateOperator: (operator: string) => void;
}

export const OperatorField = ({
  propertyValue,
  operatorValue,
  updateOperator
}: Props) => {
  const [automationOperatorList, setAutomationOperatorList] = useState<
    DropdownDataModel[]
  >([]);
  const { t } = useTranslation();

  const setAutomationOperatorData = (_propertyValue: string) => {
    if (_propertyValue === 'title') {
      setAutomationOperatorList(AUTOMATION_OPERATORS.request);
    } else if (_propertyValue.toLowerCase().includes('comment')) {
      setAutomationOperatorList(AUTOMATION_OPERATORS.comment);
    } else if (_propertyValue.toLowerCase().includes('tags') || _propertyValue.toLowerCase().includes('affectedusers') || _propertyValue.toLowerCase().includes('ticketcollaborators')) {
      setAutomationOperatorList(AUTOMATION_OPERATORS.tag);
    } else if (_propertyValue.toLowerCase().includes('date')) {
      setAutomationOperatorList(AUTOMATION_OPERATORS.date);
    } else {
      setAutomationOperatorList(AUTOMATION_OPERATORS.basic);
    }
  };

  useEffect(() => {
    setAutomationOperatorData(propertyValue);
  }, [propertyValue]);

  const automationOperatorChange = (_e, p: DropdownProps) => {
    const data = p.value as DropdownDataModel;
    updateOperator(data.value);
  };

  return (
    <ProviderConsumer render={globalTheme =>
      <div className={Styles.GroupInput}>
        <FormLabel content={t('automation.automation-operators.title')} styles={{ fontSize: '12px' }} />
        <Dropdown
          inverted
          fluid
          items={automationOperatorList}
          style={{ backgroundColor: globalTheme.siteVariables.colorScheme.default.background }}
          placeholder={t('automation.automation-operators.placeholder')}
          value={automationOperatorList.filter(x => x.value == operatorValue)}
          onChange={automationOperatorChange}
        />
      </div>} />
  );
};
