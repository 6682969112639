import React, { useEffect, useState } from 'react';
import Styles from './Tree.module.css';
import { ProviderConsumer as FluentUIThemeConsumer } from '@fluentui/react-northstar';

import Tree, {
  moveItemOnTree,
  ItemId,
  mutateTree,
  RenderItemParams,
  TreeSourcePosition,
  TreeDestinationPosition,
  TreeItem
} from '@atlaskit/tree';
import {
  find as _find,
  forEach as _each,
  flattenDeep as _flattenDeep,
  isEmpty as _isEmpty
} from 'lodash-es';
import { DraggableItem } from './DraggableItem/DraggableItem';
import { ThemeColorScheme } from '../../common/TeamsTheme';

type CSRenderItemParams = Omit<RenderItemParams, 'item'>;
type NewRenderItemParams = CSRenderItemParams & {
  item: CTreeItem;
};

export type CTreeItem = TreeItem & {
  container?: boolean;
};

export interface TreeData {
  rootId: ItemId;
  items: Record<ItemId, CTreeItem>;
}

export interface TreeWrapperProps {
  depth: number;
  data: TreeData;
  isEditEnabled?: boolean;
  onEditHandler?: (item: CTreeItem) => void;
  onArchiveHandler?: (item: CTreeItem) => void;
  onDragEndHandler?: (
    source: ItemId,
    destination: TreeDestinationPosition,
    rootChildren?: ItemId[]
  ) => void;
  isDragEnabled?: boolean;
  isLicensedUser?: boolean;
}

export const TreeWrapper: React.FC<TreeWrapperProps> = ({
  depth: ALLOWED_NESTING_DEPTH,
  data,
  isEditEnabled,
  onEditHandler,
  onArchiveHandler,
  onDragEndHandler,
  isDragEnabled,
  isLicensedUser
}) => {
  const [tree, setTree] = useState<TreeData>(data);
  const [currentDraggingItem, setCurrentDraggingItem] = useState<
    ItemId | null | undefined
  >(null);

  useEffect(() => {
    setTree(data);
  }, [data]);

  function getParent(itemId: ItemId) {
    let parent = null;
    _each(tree.items, (item: CTreeItem) => {
      if (item.hasChildren && item.children.includes(itemId)) parent = item.id;
    });
    return parent;
  }

  // Recursively find parents
  function getNumberOfParents(parent_id: ItemId, childNesting = 0): any {
    const parent = getParent(parent_id);

    if (!parent) return childNesting;

    childNesting++;
    return getNumberOfParents(parent, childNesting);
  }

  // Recursively find children
  function getNumberOfChildren(childrenIdList: any[], parentsNesting = 0): any {
    parentsNesting++;

    if (_isEmpty(childrenIdList)) return parentsNesting;

    const children = childrenIdList.map(child =>
      _find(tree.items, { id: child })
    );
    let allChildren: any = [];
    if (!_isEmpty(children)) {
      allChildren = _flattenDeep(children.map(child => child?.children));
    }

    return getNumberOfChildren(allChildren, parentsNesting);
  }

  // This function recursively chechs how many new parents and how many children the dragged item has.
  // If their total sum (the depth) exceeds the defined depth, the drag & drop will be canceled.
  function nestingAllowed(parentId: ItemId) {
    const dragged = _find(tree.items, { id: currentDraggingItem || undefined });
    let numberOfChildren = 1; // if the item is at the end of the chain it must be counted as a child
    let numberOfParents = 0;

    if (dragged?.hasChildren) {
      numberOfChildren = getNumberOfChildren(dragged.children);
    }

    if (parentId !== tree.rootId) {
      numberOfParents = getNumberOfParents(parentId);
    }

    setCurrentDraggingItem(null);
    return numberOfChildren + numberOfParents <= ALLOWED_NESTING_DEPTH;
  }

  function droppingAllowed(parentId: ItemId) {
    const dropped = _find(tree.items, { id: parentId });

    return dropped?.container;
  }

  const renderItem = (props: NewRenderItemParams) => (
    <DraggableItem
      {...props}
      isEditEnabled={isEditEnabled}
      onEditHandler={item => onEditHandler && onEditHandler(item)}
      onArchiveHandler={item => onArchiveHandler && onArchiveHandler(item)}
      isDragEnabled={isDragEnabled}
      isLicensedUser={isLicensedUser}
    />
  );

  const onExpand = (itemId: ItemId) => {
    setTree(mutateTree(tree, itemId, { isExpanded: true }));
  };

  const onCollapse = (itemId: ItemId) => {
    setTree(mutateTree(tree, itemId, { isExpanded: false }));
  };

  const onDragStart = (itemId: ItemId) => {
    setCurrentDraggingItem(itemId);
  };

  const onDragEnd = (
    source: TreeSourcePosition,
    destination?: TreeDestinationPosition
  ) => {
    if (!destination) return;
    if (!nestingAllowed(destination.parentId)) {
      // nesting limit exceeded.
      return;
    }

    if (!droppingAllowed(destination.parentId)) {
      // Dropping into this element is not allowed.
      return;
    }

    const newTree: TreeData = moveItemOnTree(tree, source, destination);
    setTree(newTree);
    onDragEndHandler &&
      onDragEndHandler(
        currentDraggingItem,
        destination,
        newTree.items[destination.parentId].children
      );
  };

  return (
    <FluentUIThemeConsumer
      render={globalTheme => (
        <div className={Styles.TreeContainer}
        style={{
          width: '100%',
          ...ThemeColorScheme(globalTheme.siteVariables)
        }}>
          <Tree
            tree={tree}
            renderItem={renderItem}
            onExpand={onExpand}
            onCollapse={onCollapse}
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
            offsetPerLevel={8 * 2}
            isDragEnabled={isDragEnabled}
            isNestingEnabled
          />
        </div>
      )}
    />
  );
};
