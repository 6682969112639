import React, { useEffect, useState, useCallback } from 'react';
import { KBMain } from '../kb/KBMain';
import { UserTable } from '../user/UserTable';
import { UserForm } from '../user/UserForm';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { MsTeamsConfig } from '../config/TeamsConfig';
import SettingSVG from '../../svg/control-pannel.svg';
import { Flex, Text, Button, MenuIcon } from '@fluentui/react-northstar';
import { Groups } from '../groups/Groups';
import { GroupTable } from '../groups/GroupTable';
import { TagsTable } from '../tags/TagsTable';
import { useSetState } from '../AppState';
import { BrowserAppTemplate } from '../browser-pages/BrowserAppTemplate';
import {
  LICENSE_FEATURE,
  LOADING_MSG,
  SETTINGS_PAGES
} from '../shared/utils/constants';
import { Billing } from '../billing/Billing';
import { CategoryList } from '../category/CategoryList';
import { TicketTypeList } from '../ticket-type/TicketTypeList';
import { PriorityList } from '../priority/PriorityList';
import { StatusList } from '../status/StatusList';
import { NotificationCenter } from '../notification-center/NotificationCenter';
import { TicketTemplatesTable } from '../templates/TicketTemplatesTable';
import { TicketTemplates } from '../templates/TicketTemplates';
import { Automation } from '../automation/Automation';
import {
  AutomationTable,
  MobileSettingsView
} from '../automation/AutomationTable';
import { SecurityPage } from '../security/SecurityPage';
import AppKBStateProvider from '../AppKBState';
import { TokensTable } from '../token/TokenList';
import { platformService } from '../shared/services/platform.service';
import { CheckLogin } from '../shared/components/CheckLogin';
import { LoadingScreen } from '../shared/components/LoadingScreen';
import { ServiceLevelAgreement } from '../sla/Sla';
import { SlaTable } from '../sla/SlaTable';
import { ConsentConfig } from '../config/ConsentConfig';
import { TeamsTable } from '../team/TeamsTable';
import { Teams } from '../team/Teams';
import { SurveyConfig } from '../config/SurveyConfig';
import { Preferences } from '../config/Preferences';
import { LifecycleTable } from '../lifecycle/LifecycleTable';
import { LifecycleForm } from '../lifecycle/LifecycleForm';
import { useTranslation } from 'react-i18next';
import { AiConfig } from '../config/AiConfig';
import { getCachedFeature } from '../shared/cache/FeatureCache';


interface Props {
  settingsKey: string;
}

export const Settings: React.FC<Props> = ({ settingsKey }: Props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const query = new URLSearchParams(useLocation().search);

  const [loadingMessage] = useState(LOADING_MSG);
  const [isOpen, setIsOpen] = useState(false);
  const [roles, setRoles] = useState(
    sessionStorage.getItem('my_application_roles')
  );
  const api = new platformService();

  const [isCheckedIn, setIsCheckedIn] = useState<boolean>(false);
  const [isNotificationCenter, setIsNotificationCenter] = useState(false);

  const loadPage = () => {
    setIsCheckedIn(true);
  };

  useEffect(() => {
    const myRoles = async () => {
      try {
        const gettingRolesResponse = await api.GetMyApplicationRoles();
        const roles = gettingRolesResponse.data.value;
        return roles;
      } catch (err) {
        return err;
      }
    };

    if ((!roles || roles === '{}' || roles === '[]') && isCheckedIn) {
      myRoles().then(r => {
        sessionStorage.setItem('my_application_roles', JSON.stringify(r));
        setRoles(JSON.stringify(r));
      });
    }
  }, [isCheckedIn]);

  (async () => {
     const nc = await getCachedFeature(LICENSE_FEATURE.NotificationCenter);
    setIsNotificationCenter(nc);
  })();

  if (isCheckedIn && roles) {
    
    switch (settingsKey) {
      case SETTINGS_PAGES.KNOWLEDGE:
        return (
          <AppKBStateProvider>
            <KBMain />
          </AppKBStateProvider>
        );
      case SETTINGS_PAGES.TAGS:
        return <TagsTable />;
      case SETTINGS_PAGES.ACCESS_TOKEN:
        return <TokensTable />;
      case SETTINGS_PAGES.TEAMS:
        return query.get('id') ? (
          <Teams {...{ id: query.get('id') }}></Teams>
        ) : (
          <TeamsTable />
        );
      case SETTINGS_PAGES.GROUPS:
        return query.get('id') ? (
          <Groups {...{ id: query.get('id') }}></Groups>
        ) : (
          <GroupTable />
        );
      case SETTINGS_PAGES.USERS:
        return query.get('id') ? (
          <UserForm {...{ id: query.get('id') }} />
        ) : (
          <UserTable />
        );
      case SETTINGS_PAGES.AUTOMATION:
        return query.get('id') ? (
          <Automation {...{ id: query.get('id') }} />
        ) : (
          <AutomationTable />
        );
      case SETTINGS_PAGES.STATUS:
        return <StatusList />;
      case SETTINGS_PAGES.PRIORITY:
        return <PriorityList />;
      case SETTINGS_PAGES.CATEGORY:
        return <CategoryList />;
      case SETTINGS_PAGES.TICKET_TYPES:
        return <TicketTypeList />;
      case SETTINGS_PAGES.LIFECYCLE:
        return query.get('id') ? (
            <LifecycleForm {...{ id: query.get('id') }} />
          ) : (
            <LifecycleTable />
          );
      case SETTINGS_PAGES.BILLING:
        return <Billing />;
      case SETTINGS_PAGES.NOTIFICATION_CENTER:
        if (!isNotificationCenter){
          history.push("/settings/email-connector");
          return <></>;
        }
        return <NotificationCenter />;
      case SETTINGS_PAGES.EMAIL_CONNECTOR:
        if (isNotificationCenter){
          history.push("/settings/notification-center");
          return <></>;
        }
        return <NotificationCenter />;
      case SETTINGS_PAGES.FEATURE_CONFIGURATION: 
          return <ConsentConfig />;
      case SETTINGS_PAGES.BOT_CONFIGURATION:
      case SETTINGS_PAGES.MS_TEAMS:
        return <MsTeamsConfig />;
      case SETTINGS_PAGES.AI_CONFIG:
        return <AiConfig />;
      case SETTINGS_PAGES.SECURITY:
        return <SecurityPage />;
      case SETTINGS_PAGES.SURVEYS:
        return <SurveyConfig />;
      case SETTINGS_PAGES.TEMPLATES:
        const id = query.get('id');
        const menu = query.get('menu');
        return id && id.length > 0 && parseInt(id) > 0 ? (
          <TicketTemplates {...{ id: id, menu: menu }}></TicketTemplates>
        ) : (
          <TicketTemplatesTable />
        );
      case SETTINGS_PAGES.SLA:
        return query.get('id') ? (
          <ServiceLevelAgreement {...{ id: +query.get('id') }} />
        ) : (
          <SlaTable />
        );
      case SETTINGS_PAGES.PREFERENCES:
        return <Preferences />;     
      default:
        return (
          <>
            <div className="hidden md:block">
              <Flex styles={{ height: '100vh' }}>
                <Flex.Item grow align="center">
                  <div style={{ height: '100%' }}>
                    <div className={`md:hidden settings-menu__button`}>
                      <Button
                        onClick={() => {
                          setIsOpen(!isOpen);
                        }}
                        content={<MenuIcon size="large" />}
                        className={`m-3`}
                        iconOnly
                        title="menu"
                      />
                    </div>
                    <Flex
                      column
                      hAlign="center"
                      vAlign="center"
                      style={{ height: '100%' }}
                    >
                      <SettingSVG
                        width={150}
                        height={150}
                        style={{ margin: '0 auto' }}
                      />
                      <Text
                        content={t('common.settings-page.default-title')}
                        weight="bold"
                        size="larger"
                      />
                      <Text content={t('common.settings-page.default-description')} />
                    </Flex>
                  </div>
                </Flex.Item>
              </Flex>
            </div>
            <MobileSettingsView />
          </>
        );
    }
  }
  return (
    <CheckLogin onSignedIn={loadPage}>
      <LoadingScreen message={loadingMessage} />
    </CheckLogin>
  );
};

export const SettingsPage: React.FC = () => {
  const setState = useSetState();
  let { settingsKey } = useParams<any>();
  useEffect(() => {
    setState(state => {
      var newstate = {
        ...state,
        isLoading: false
      };
      if(state.isSidenavChanged) {
        newstate = {
          ...newstate
        }
      } else {
        newstate = {
          ...newstate,
          isSidenavCollapsed: true,
          isSidenavChanged: true,
        }
      }
      return newstate;
    });
  }, []);

  return (
      <BrowserAppTemplate
        page={<Settings settingsKey={settingsKey} />}
        active={settingsKey}
      />
  );
};
