import React, { useState, useEffect } from 'react';
import Styles from './Billing.module.css';
import AppCSS from '../App.module.css';
import { SettingsPageWrapper } from '../shared/components/SettingsPageWrapper';
import { Breadcrumbs } from '../shared/components/BreadcrumbNavigation';
import { BILLING, COUNTRIES, SETTINGS, toastDefault, PRODUCT_LICENSE, ADMINISTRATORS, UNAUTHORIZED_ACCESS, CONTACT_ADMIN } from '../shared/utils/constants';
import { toTitleCase } from '../shared/utils/helper';
import { AddIcon, Alert, Button, Dialog, Dropdown, Flex, Header, Input, Loader, ProviderConsumer, Table, TableCell, TableRow, Text, WindowMinimizeIcon } from '@fluentui/react-northstar';
import { AcceptIcon, CloseIcon, ExclamationTriangleIcon, InfoIcon } from '@fluentui/react-icons-northstar';
import { CompoundButton, Panel, PanelType, Stack } from '@fluentui/react';
import { platformService } from '../shared/services/platform.service';
import { toast } from 'react-toastify';
import { LabelElement } from '../shared/components/Modal/Modal';
import { CheckLogin } from '../shared/components/CheckLogin';
import { Helmet } from 'react-helmet';
import { loadStripe, StripeCardElement } from '@stripe/stripe-js';
import { Elements, CardElement } from '@stripe/react-stripe-js';
import { MobileSettingsView } from '../automation/AutomationTable';
import isEmail from 'validator/lib/isEmail';
import { LicenseDistribution } from '../user/LicenseDistribution';
import '../user/PanelFlyout.css';
import { getCachedFeature } from '../shared/cache/FeatureCache';
import { ThemeColorScheme } from '../shared/common/TeamsTheme';
import { useTranslation } from 'react-i18next';
import { Route } from '../../route-constants';
import i18n from 'i18next';
import { Communication } from '@fluentui/react-teams';
import { appState  } from '../AppState';

const stripePromise = loadStripe(window.__runtimeConfig.stripeKey);

interface FormInputs {
  accountEmail: string;
  count: number;
  oldCount: number;
  billingPlan: string;
  billingPrice: any;
  licenseEndDate: string;
  subscriptionStatus: string;
  last4: string;
  stripeKey: string;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  postal: string;
  country: string;
  cardIsValid: boolean;
  selectedPriceId: string;
  usedCount: number;
  minCount: number;
  prorationAmount: number;
  nextPaymentAmount: number;
  billingChanged: boolean;
  saveDisabled: boolean;
}

interface SubscriptionScheduleUpdate {
  id: string;
  priceId: string;
  customerId: string;
  scheduledUpdateStartDate: Date;
  scheduledUpdateEndDate: Date;
  endBehavior: string;
  status: string;
  seatCount: number;
}

export const Billing = props => {
  const appContextState = appState();
  const api = new platformService();
  const [formValues, setFormValues] = useState<FormInputs>({
    accountEmail: '',
    count: 0,
    billingPlan: '',
    billingPrice: {},
    licenseEndDate: '',
    subscriptionStatus: '',
    last4: '',
    stripeKey: '',
    streetAddress1: '',
    streetAddress2: '',
    city: '',
    state: '',
    postal: '',
    country: '',
    cardIsValid: false,
    selectedPriceId: '',
    oldCount: 0,
    usedCount: 0,
    minCount: 1,
    prorationAmount: 0,
    nextPaymentAmount: 0,
    billingChanged: false,
    saveDisabled: false
  });
  const [subscriptionSchedule, setSubscriptionSchedule] = useState<SubscriptionScheduleUpdate>({
    id: '',
    priceId: '',
    customerId: '',
    scheduledUpdateStartDate: new Date(),
    scheduledUpdateEndDate: new Date(),
    endBehavior: '',
    status: '',
    seatCount: 0
  });
  const [subValues, setSubValues] = useState<any>({});
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isDisabled, setDisabled] = useState<boolean>(false);
  const [_options, setOptions] = useState<any>({});
  const [cardElement, setCardElement] = useState<StripeCardElement | undefined>(undefined);
  const [openUserInfo, setOpenUserInfo] = useState(false);
  const [isErrorQuantity, setIsErrorQuantity] = useState("");
  const [isLicensedUser, setIsLicensedUser] = useState(false);
  const [formInitialState, setFormInitialState] = useState<FormInputs>(null);
  const [isFormUpdated, setIsFormUpdated] = useState<boolean>(false);
  const [isSeatCountUpdated, setIsSeatCountUpdated] = useState<boolean>(false);
  const [agentLinkDisabled, setIsAgentLinkDisabled] = useState<boolean>(false);
  const [subscriptionAmoutText, setSubscriptionAmountText] = useState<string>("");
  const [billingErrorText, setBillingErrorText] = useState<string>("");
  const isAdmin = appContextState.userRoles.roles.includes(ADMINISTRATORS);
  const [billingMessage, setBillingMessage] = useState<string>("");
  const [addingPayment, setAddingPayment] = useState<boolean>(false);

  const { t } = useTranslation();

  const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  const navs: Breadcrumbs = {
    breadcrumbs: [
      {
        title: toTitleCase(SETTINGS),
        link: Route.settings.pagePath
      },
      {
        title: toTitleCase(BILLING.BILLING),
        link: ''
      }
    ]
  };

  const cleanDate = (dateString: string) => {
    if (!dateString || dateString.length < 1) return '';
    const date = new Date(dateString);

    return new Intl.DateTimeFormat(i18n.language, { dateStyle: 'short' }).format(date)
  };

  const getTenantLicensesData = (licensesData: any) => licensesData ?? { UsedCount: 0, ProductId: "" };
  const getMinCount = (usedCount: number) => usedCount > 1 ? usedCount : 1;

  const updateLicensesCount = async () => {
    const result = await api.getTenantLicenses();
    const tenantLicensesData = getTenantLicensesData(result.data.value[0]);
    setFormValues({
      ...formValues,
      usedCount: tenantLicensesData.UsedCount,
      minCount: getMinCount(tenantLicensesData.UsedCount)
    });
  }

  const setFetchedData = (result: any[]) => {
    const tenantLicensesData = getTenantLicensesData(result[2].data.value[0]);
    const billingData = {
      accountEmail: result[1].data.AccountEmail,
      count: result[1].data.SeatCount,
      billingPlan: result[0].data.items && result[0].data.items.length > 0 && result[0].data.items[0].price ? result[0].data.items[0].price.nickname : '',
      billingPrice: result[1].data.CurrentPrice,
      licenseEndDate: cleanDate(result[0].data.currentPeriodEnd),
      subscriptionStatus: result[0].data.status,
      last4: result[1].data.Last4 || '',
      stripeKey: result[1].data.StripeKey || '',
      streetAddress1: result[1].data.StreetAddress1 || '',
      streetAddress2: result[1].data.StreetAddress2 || '',
      city: result[1].data.City || '',
      state: result[1].data.State || '',
      postal: result[1].data.PostalCode || '',
      country: result[1].data.Country || '',
      cardIsValid: false,
      selectedPriceId: result[1].data.PriceId || result[0].data.items && result[0].data.items.length > 0 && result[0].data.items[0].price ? result[0].data.items[0].price.id : '',
      oldCount: result[1].data.SeatCount,
      usedCount: tenantLicensesData.UsedCount,
      minCount: getMinCount(tenantLicensesData.UsedCount),
      prorationAmount: 0,
      nextPaymentAmount: 0,
      billingChanged: false,
      saveDisabled: false
    }
    setFormValues(billingData);
    setFormInitialState(billingData);
  }

   const errConfig = {
    fields: { title: UNAUTHORIZED_ACCESS, desc: CONTACT_ADMIN }
  };

  const fetchData = async () => {
    try {
      const result = await Promise.all([
        api.getSubscription(),
        api.getLicenseSettings(),
        api.getTenantLicenses(),
        getSubscriptionSchedule()
      ]);
      const cachedIsLicensed = await getCachedFeature(PRODUCT_LICENSE.AnalystAccess);
      setFetchedData(result);
      setOptions({ clientSecret: result[1].data.StripeSecret || '' });
      setSubValues({
        customerId: result[1].data.CustomerId,
        productId: (result[2].data.value[0]) ? result[2].data.value[0].ProductId : "",
        priceId: result[1].data.PriceId,
        prices: result[1].data.Prices
      });
      setIsLicensedUser(cachedIsLicensed);
      setBillingMessage(result[1].data.BillingMessage);

      const sub = result[0].data;
      if ((sub.status === "past_due" || sub.status === "incomplete") && (sub.latestInvoice && sub.latestInvoice.paymentIntent && sub.latestInvoice.paymentIntent.status === "requires_action")) {
        try {
          const stripe = await stripePromise;
          const result = await stripe.confirmCardPayment(sub.latestInvoice.paymentIntent.clientSecret, { payment_method: sub.latestInvoice.paymentIntent.paymentMethodId });
          if (result.error) 
            setBillingErrorText(result.error.message);
        } catch { }
        
        // Regardless of what happens, reload the form
        await fetchData();
      }
      setLoading(false);
    } catch { }
  };

  const loadPage = () => {
    fetchData();
  };

  const beginCheckout = async () => {
    const origin = window.location.origin;
    try {
      const result = await api.getCheckoutLink(`${origin}/settings/billing`, `${origin}/settings/billing`);
      window.location.assign(result.data.value);
    } catch (err) {
      toast.error(BILLING.CHECKOUT_ERRROR, toastDefault);
    }
  };

  const sendToPortal = async () => {
    const origin = window.location.origin;
    try {
      const result = await api.getPortalLink(`${origin}${Route.settings.billing.pagePath}`);
      window.location.assign(result.data.value);
    } catch (err) {
      toast.error(BILLING.CHECKOUT_ERRROR, toastDefault);
    }
  };
  const retryPayment = async () => {
    try {
      setLoading(true);
      setBillingErrorText("");
      const result = await api.retryPayment();
      if (result.data.value !== "success") {
        const vals = result.data.value.split('|~|');
        if (!vals[0].includes('requires_action'))
          setBillingErrorText(vals[1]);
      }
    } catch (err) {
      toast.error(BILLING.CHECKOUT_ERRROR, toastDefault);
    }
    await fetchData();
  };

  const updateSubscription = async () => {
    setAddingPayment(true);
    try {
      const stripe = await stripePromise;
      const result = await stripe.createPaymentMethod({ type: 'card', card: cardElement });
      if (result.error) {
        toast.error(t('billing.error-creating-payment-method'));
      } else {
        const address = {
          streetAddress1: formValues.streetAddress1,
          streetAddress2: formValues.streetAddress2,
          city: formValues.city,
          state: formValues.state,
          postalCode: formValues.postal,
          country: formValues.country
        };

        await api.updateSubscription(
          subValues.customerId,
          result.paymentMethod.id,
          formValues.selectedPriceId,
          (formValues.count != formValues.oldCount) ? formValues.count : 0,
          address,
          subValues.accountEmail
        );
        toast.success(t('billing.subscription-updation-success'), toastDefault);
        setLoading(true);
        fetchData();
      }
    } catch (e) {
      toast.error(t('billing.subscription-updation-error'));
      setLoading(true);
      fetchData();
    }
    setAddingPayment(false);
  };

  const saveCustomerData = async () => {
    try {
      if (formValues.oldCount === formValues.count) {
        await saveSubscriptionUpdate();
      } else if (formValues.subscriptionStatus !== 'trialing') {
        let result = await api.getInvoicePreview(formValues.count);
        formValues.prorationAmount = result.data.prorationAmount;
        formValues.nextPaymentAmount = result.data.updatedMonthlyAmount;
         
        const price = formValues.billingPrice;
        if (formValues.nextPaymentAmount <= 0)
          setSubscriptionAmountText(t('billing.please-see-manage-billing'));
        else if (formValues.count > formValues.oldCount)
          setSubscriptionAmountText(t('billing.next-charge', { nextPayment: formatter.format(formValues.nextPaymentAmount), numAgents: formValues.count, costPerAgent: formatter.format(formValues.nextPaymentAmount / formValues.count), licenseEndDate: formValues.licenseEndDate }));
        else
          setSubscriptionAmountText(t(`billing.pending-charge.${price.Recurring}`, { nextPayment: formatter.format(formValues.nextPaymentAmount), numAgents: formValues.count, costPerAgent: formatter.format(formValues.nextPaymentAmount / formValues.count), licenseEndDate: formValues.licenseEndDate }));

        setIsSeatCountUpdated(true);
      } else {
        await saveSubscriptionUpdate();
      }
    } catch (e) { }
  };

  const saveSubscriptionUpdate = async () => {
    try {
      setIsSeatCountUpdated(false);
      await api.updateSubscription(subValues.customerId, '', '', (formValues.count != formValues.oldCount) ? formValues.count : 0, null, formValues.accountEmail);
      formValues.oldCount = formValues.count;
      toast.success(t('billing.save-success'), toastDefault);
      setIsAgentLinkDisabled(false);
      fetchData();
    } catch (e) { }
    setFormInitialState(formValues);
  };

  const updateFormValue = (ev: any) => {
    const { name, value } = ev.target;
    
    let billingChanged = formValues.billingChanged;
    if (["streetAddress1", "streetAddress2", "city", "state", "postal", "country"].includes(name)) {
      billingChanged = formInitialState[name] !== value;
      if (!billingChanged) {
        for (let p of ["streetAddress1", "streetAddress2", "city", "state", "postal", "country"]) {
          if (name !== p && formInitialState[p] !== formValues[p]) {
            billingChanged = true;
            break;
          }
        }
      }
    }

    setFormValues({ ...formValues, [name]: value, billingChanged: billingChanged, saveDisabled: billingChanged || (cardElement !== undefined && !cardElement['_empty']) });
  };

  const updateCount = (isAdd: boolean) => {
    let count = formValues.count || 0;

    if (isAdd) count++;
    else count--;

    if (count < 1) count = 1
    setFormValues({ ...formValues, count: count });
    setIsAgentLinkDisabled(count !== formValues.oldCount);
  };

  const updateCountValue = (ev: any) => {
    const { name, value } = ev.target;
    let count = value || 0;

    setFormValues({ ...formValues, [name]: count });
  };

  const checkInvalidQuantity = () => {
    let invalidQuantity = true;
    if (formValues.count < 1)
      setIsErrorQuantity(t('billing.min-license-error'));
    else if (formValues.count < formValues.minCount && formValues.minCount > 1)
      setIsErrorQuantity(t('billing.quantity-error'));
    else {
      setIsErrorQuantity("");
      invalidQuantity = false;
    }
    return invalidQuantity;
  }

  const subscriptionPriceUpdate = () => {
    const price = subValues.prices.find(x => x.PriceId === subscriptionSchedule.priceId);
    if (price) {
      let pricePer = pricePerAgent(price);
      return t('billing.price-per-agent.seats', { price: formatter.format(pricePer), numAgents: subscriptionSchedule.seatCount, costPerAgent: formatter.format(pricePer / subscriptionSchedule.seatCount) });
    }
    return 0;
  };

  const pricePerAgent = (price: any) => {
    if (price.TiersMode !== "volume") {
      let count = formValues.count, total = 0, startFrom = 0;
      for (let tier of price.Tiers) {
        if (tier.FlatAmount)
          total += tier.FlatAmount;

        if (tier.UnitAmount)
          total += tier.UnitAmount * (tier.UpTo && tier.UpTo > 0 ? Math.min(count, tier.UpTo - startFrom) : count);

        startFrom = (tier.UpTo || 0) + 1;
        count -= tier.UpTo || 0;

        if (count <= 0) break;
      }
      return total / 100.00;
    } else {
      let count = formValues.count;
      let tiers = price.Tiers.filter(t => count <= t.UpTo);
      let tier = (tiers.length == 0) ? price.Tiers[price.Tiers.length - 1] : tiers[0];

      return (tier.FlatAmount ? tier.FlatAmount : (count * tier.UnitAmount)) / 100.00;
    }
  }
  const disableDecrement = () => {
    let validDateToDecrement = new Date(new Date().setDate(new Date().getDate() + 30));
    return validDateToDecrement < new Date(formValues.licenseEndDate);
  }

  const getSubscriptionSchedule = async () => {
    let result = await api.getSubscriptionSchedule();
    let subSchedule = {
      id: result.data.id,
      priceId: result.data.priceId,
      customerId: result.data.customerId,
      seatCount: result.data.seatCount,
      scheduledUpdateStartDate: result.data.scheduledUpdateStartDate,
      scheduledUpdateEndDate: result.data.scheduledUpdateEndDate,
      status: result.data.status,
      endBehavior: result.data.endBehavior
    };
    setSubscriptionSchedule(subSchedule);
  }

  useEffect(() => {
    const invalidQuantity = checkInvalidQuantity();
    const isInvalidInput = (input: any) => input === null || input === undefined || input === '';
    setDisabled(
      !formValues.cardIsValid ||
      isInvalidInput(formValues.accountEmail) ||
      isInvalidInput(formValues.streetAddress1) ||
      isInvalidInput(formValues.city) ||
      isInvalidInput(formValues.state) ||
      isInvalidInput(formValues.postal) ||
      isInvalidInput(formValues.country) ||
      subValues.prices.filter((p: any) => p.PriceId === formValues.selectedPriceId).length < 1 ||
      invalidQuantity
    );
  }, [formValues]);

  useEffect(() => {
    if (formInitialState) {
      setIsFormUpdated(checkIfFormUpdated());
    }
  }, [formValues, formInitialState]);

  const checkIfFormUpdated = () => {
    let isDirty = false;
    let propertiesList = Object.keys(formInitialState);
    for (let property of propertiesList) {
      let updatedPropertyValue: any = formValues[property];
      let initialPropetyValue: any = formInitialState[property];
      if (property.includes('Date')) {
        updatedPropertyValue = parseDate(updatedPropertyValue);
        initialPropetyValue = parseDate(initialPropetyValue);
      }
      if (updatedPropertyValue?.toString() != initialPropetyValue?.toString()) {
        isDirty = true;
        break;
      }
    }
    return isDirty;
  };

  const parseDate = (date: string) => date === undefined ? date : Date.parse(date);

  const renderSummaryComponent = () => {
    return (
      <>
        <LabelElement label={toTitleCase(BILLING.BILLING_SUMMARY)} />
        {isErrorQuantity && isErrorQuantity.length > 0 && (
          <Text error content={isErrorQuantity} />
        )}
        <div className="teams-input-med">
          <Table aria-label="Compact view static table" >
            <TableRow header>
              <TableCell content={t('billing.product')} styles={{ justifyContent: "center" }} />
              <TableCell content={t('billing.num-licensed-agents')} styles={{ justifyContent: "center", minWidth: "50%" }} />
            </TableRow >
            
                <TableRow styles={{ height: "90px", verticalAlign: "top" }}>
                  <TableCell content=
                    {
                      <div style={{ paddingBottom: '15px' }}>
                        <div className={`font-semibold`}>{formValues.billingPrice.PriceName || formValues.billingPrice.ProductName}</div>
                        <div>
                          <Text size="small" content={t(`billing.price-per-agent.${formValues.billingPrice.Recurring}`, { price: formatter.format(pricePerAgent(formValues.billingPrice) / formValues.count) })} />
                        </div>
                        <div>
                          <Text size="small" content={t(`billing.price-per.${formValues.billingPrice.Recurring}`, { price: formatter.format(pricePerAgent(formValues.billingPrice)) })} />
                        </div>
                        <div>
                          <Text size="small" content={t(`billing.billing-occurs.${formValues.billingPrice.Recurring}`)} />
                        </div>
                      </div>
                    } styles={{ justifyContent: "center" }} />

                  <TableCell content={
                    <>
                      <Flex styles={{ alignItems: "center" }} column>
                        {renderSeatChangeComponent()}
                        <Text content={t('billing.count-agents-consumed', { count: formValues.usedCount})} onClick={() => { if (!agentLinkDisabled) setOpenUserInfo(true)}} disabled={agentLinkDisabled} className={`cursor-pointer`} color="brand" style={{ margin: "10px" }} />
                      </Flex>
                    </>
                  } styles={{ justifyContent: "center", minWidth: "50%" }} />
                </TableRow>
                <TableRow header>
                  <TableCell content={t('billing.min-license-note')} styles={{ justifyContent: "center" }} />
                </TableRow>
                <Dialog
                  className={AppCSS.confirmDialog}
                  cancelButton={t('common.buttons.cancel')}
                  confirmButton={t('common.buttons.ok')}
                  onConfirm={() => {
                    saveSubscriptionUpdate();
                  }}
                  onCancel={() => {
                    setIsSeatCountUpdated(false);
                  }}
                  content={
                    <div>
                      {(() => {
                        if (formValues.count > formValues.oldCount) {
                          return (
                            <div>
                              {t('billing.prorate-charge', { count: formValues.count - formValues.oldCount, proratedAmount: formatter.format(formValues.prorationAmount) })} {subscriptionAmoutText}
                              <div style={{ paddingTop: '15px', fontSize: '.9em' }}>
                                {t('billing.confirm-increase')}
                              </div>
                            </div>
                          )
                        } else {
                          return (
                            <div>
                              {subscriptionAmoutText} {t('billing.confirm-decrease', { oldCount: formValues.oldCount, licenseEndDate: formValues.licenseEndDate })}
                              <div style={{ paddingTop: '15px', fontSize: '.9em' }}>
                                {t('billing.confirm-ok')}
                              </div>
                            </div>
                          )
                        }
                      })()}
                    </div>
                  }
                  header={
                    <div>
                      {(() => {
                        if (formValues.count > formValues.oldCount) {
                          return t('billing.quantity-change-increase');
                        } else {
                          return t('billing.quantity-change-decrease');
                        }
                      })()}
                    </div>
                  }
                  open={isSeatCountUpdated}
                />
                {renderPanelComponent(formValues.billingPrice.PriceName || formValues.billingPrice.ProductName, false)}
          </Table>
          <div style={{ display: `${subscriptionSchedule.status === 'canceled' || subscriptionSchedule.seatCount <= 0 ? 'none' : 'block'}` }}>
            <Text
              content={t('billing.overallocation-error', { price: subscriptionPriceUpdate(), updateStartDate: subscriptionSchedule.scheduledUpdateStartDate })}
              size="small"
              color="red"
              temporary
            />
          </div>
        </div>
      </>
    )
  }

  const renderPanelComponent = (productName: string, resetOnDismiss: boolean) => {
    return <ProviderConsumer render={(globalTheme) => (
      <Panel
        isLightDismiss
        isOpen={openUserInfo}
        onDismiss={() => {
          if (resetOnDismiss) {
            setLoading(true);
            loadPage();
          }
          setOpenUserInfo(false);
        }}
        onOuterClick={() => setOpenUserInfo(true)}
        closeButtonAriaLabel={t('common.buttons.close')}
        hasCloseButton={true}
        type={PanelType.medium}
        className={`panelFlyout`}
        style={{ width: '100%', ...ThemeColorScheme(globalTheme.siteVariables) }}
      >
        <LicenseDistribution routeName={BILLING.BILLING} licenseName={productName} updateLicensesDetail={updateLicensesCount} />
      </Panel>
    )} />
  }

  const renderSeatChangeComponent = () => {
    return (
      <Flex gap="gap.small" styles={{ width: '200px' }}>
      <Flex.Item size="size.quarter">
        <Button icon={<WindowMinimizeIcon />} primary title={t('billing.decrement')} styles={{ minWidth: "10px", marginRight: "0 !important", borderRadius: 0 }} 
          disabled={formValues.subscriptionStatus == 'past_due' || formValues.subscriptionStatus == 'incomplete' || formValues.count <= formValues.minCount || disableDecrement()}
          onClick={() => { updateCount(false); }} />
      </Flex.Item>
      <Flex.Item grow size="size.half">
        <input type="number" className={`${Styles.minmaxInput} ${(isErrorQuantity.length > 0) ? Styles.inputError : ''}`} name="count" min={formValues.minCount} value={formValues.count} onChange={updateCountValue} 
          disabled={formValues.subscriptionStatus == 'past_due' || formValues.subscriptionStatus == 'incomplete' || disableDecrement()} />
      </Flex.Item>
      <Flex.Item size="size.quarter">
        <Button icon={<AddIcon />} primary title={t('billing.increment')} styles={{ minWidth: "10px", borderRadius: 0 }} disabled={formValues.subscriptionStatus == 'past_due' || formValues.subscriptionStatus == 'incomplete'}
          onClick={() => { updateCount(true); }} />
      </Flex.Item>
    </Flex>
    );
  };

  return (
    <CheckLogin onSignedIn={loadPage}>
     
      <ProviderConsumer render={(globalTheme) => (
        <div className="hidden md:block" style={{ width: '100%', ...ThemeColorScheme(globalTheme.siteVariables) }}>
          
          <SettingsPageWrapper
            title={toTitleCase(BILLING.BILLING)}
            routeName={BILLING.BILLING}
            breadcrumbs={navs}
            queryId={0}
            mode="edit"
            saveData={saveCustomerData}
            isDisabled={isEmail(formValues.accountEmail) && isLicensedUser && isErrorQuantity.length == 0 && isFormUpdated && !formValues.saveDisabled}
          >
            <Helmet>
              <title>{BILLING.BILLING} - Tikit</title>
            </Helmet>

            {(!isAdmin) && (<Communication {...errConfig} />)}

            <div className={`xl:w-1/2 lg:w-3/4 p-4`}>
              <Header as="h2" content={t('billing.page-title-1')} className={`font-semibold`} />
              <div className="py-4 teams-input-med">
                {isLoading && (<Alert content={t('billing.page-loading-message')} />)}
                {!isLoading && formValues.subscriptionStatus !== 'trialing' && formValues.subscriptionStatus !== 'active' && formValues.subscriptionStatus !== 'past_due' && formValues.subscriptionStatus !== 'incomplete' && (
                  <Alert danger icon={<ExclamationTriangleIcon />} content={t('billing.subscription-not-active')} />)}
                {!isLoading && (formValues.subscriptionStatus === 'past_due' || formValues.subscriptionStatus === 'incomplete') && (
                  <Alert warning icon={<ExclamationTriangleIcon />} content={`Your Tikit subscription is currently active, but we need some additional information to complete a recently attempted payment.  Please click on 'Retry Payment' to try your payment method again or select "Manage Billing" to update your billing details.`} />
                )}
                {!isLoading && formValues.subscriptionStatus === 'active' && (
                  <Alert
                    success
                    icon={<AcceptIcon />}
                    content={`${t('billing.active-subscription-message', {cardEnds: formValues.last4 !== ''
                    ? t('billing.card-on-file-ends', {last4: formValues.last4})
                    : ''}
                    )}`}
                  />
                )}
                {!isLoading && formValues.subscriptionStatus === 'trialing' && (
                  <Alert
                    warning
                    icon={<AcceptIcon />}
                    content={t('billing.free-trial.message', { trialEndDate: formValues.licenseEndDate,  billingInfo: formValues.last4 !== ''
                    ? t('billing.free-trial.billing-info', { last4: formValues.last4 })
                    : t('billing.free-trial.service-disruption-message')
                    })}
                  />
                )}
              </div>
              {!isLoading && billingErrorText && billingErrorText.length > 0 && (
                <div className="py-4 teams-input-med">
                  <Alert danger icon={<ExclamationTriangleIcon />} content={billingErrorText} />
                </div>
              )}
              {!isLoading && (
                <>
                  <LabelElement label={toTitleCase(BILLING.ACCOUNT_EMAIL)} required />
                  <div className="teams-input-med">
                    <Input
                      icon={
                        formValues.accountEmail?.length > 0 ? (
                          <CloseIcon
                            onClick={() => {
                              setFormValues({ ...formValues, ['accountEmail']: '' });
                            }}
                          />
                        ) : null
                      }
                      inverted
                      fluid
                      error={!isEmail(formValues.accountEmail)}
                      type="email"
                      name="accountEmail"
                      value={formValues.accountEmail}
                      onChange={updateFormValue}
                    />
                  </div>
                  <LabelElement label={toTitleCase(BILLING.Next_BILLING_DATE)} />
                  <div className="teams-input-med">
                    <Input
                      type="text"
                      inverted
                      fluid
                      name="licenseEndDate"
                      value={formValues.licenseEndDate}
                      disabled
                    />
                  </div>
                </>
              )}
              <>
                {!isLoading &&
                  (formValues.subscriptionStatus === 'active' ||
                    formValues.subscriptionStatus === 'trialing' ||
                    formValues.subscriptionStatus === 'past_due' ||
                    formValues.subscriptionStatus === 'incomplete') &&
                  formValues.last4 !== '' && (
                    renderSummaryComponent()
                  )}
              </>
            </div>
            <div className={`xl:w-1/2 md:w-1/2 lg:w-3/4 p-4`}>
              {!isLoading && (<Header as="h2" content={t('billing.page-title-2')} className={`font-semibold`} />)}
              <div className={Styles.ButtonContainer}>
                {!isLoading &&
                  formValues.subscriptionStatus !== 'active' &&
                  formValues.subscriptionStatus !== 'trialing' &&
                  formValues.subscriptionStatus !== 'past_due' &&
                  formValues.subscriptionStatus !== 'incomplete' && (
                    <CompoundButton
                      secondaryText={t('billing.tikit-payment-info')}
                      text={t('billing.reactivate-subscription')}
                      color="brand"
                      onClick={beginCheckout}
                    />
                  )}
                {!isLoading &&
                  (formValues.subscriptionStatus === 'active' ||
                    formValues.subscriptionStatus === 'trialing' ||
                    formValues.subscriptionStatus === 'past_due' ||
                    formValues.subscriptionStatus === 'incomplete') &&
                  formValues.last4 !== '' && (
                    <CompoundButton
                      secondaryText={t('billing.tikit-payment-info')}
                      text={t('billing.manage-billing')}
                      onClick={sendToPortal}
                      disabled={!isLicensedUser}
                    />
                  )}
                {!isLoading && 
                  (formValues.subscriptionStatus === 'past_due' || 
                   formValues.subscriptionStatus === 'incomplete') && 
                  formValues.last4 !== '' && (
                    <CompoundButton
                      secondaryText={t('billing.tikit-payment-info')}
                      text="Retry Payment"
                      disabled={!isLicensedUser}
                      onClick={retryPayment}
                      style={{ marginLeft: '5px' }}
                    />
                   )}
                {!isLoading &&
                  (formValues.subscriptionStatus === 'active' ||
                    formValues.subscriptionStatus === 'trialing' ||
                    formValues.subscriptionStatus === 'past_due' ||
                    formValues.subscriptionStatus === 'incomplete') &&
                  formValues.last4 === '' && (
                    <Elements stripe={stripePromise}>
                      <Flex styles={{ alignItems: "left", width: '300px', paddingBottom: '5px'}} column>
                        <LabelElement label={BILLING.NUMBER_OF_AGENTS}  />
                        {renderSeatChangeComponent()}
                        <Text content={t('billing.count-agents-consumed', { count: formValues.usedCount })} onClick={() => { if(!agentLinkDisabled) setOpenUserInfo(true); }} disabled={agentLinkDisabled} className={`cursor-pointer`} color="brand" style={{ margin: "10px" }} />
                        {renderPanelComponent(formValues.billingPrice.PriceName || formValues.billingPrice.ProductName, false)}
                      </Flex>
                      <Stack horizontal tokens={{ childrenGap: 10 }} className="pb-2">
                        <LabelElement label={toTitleCase(BILLING.BILLING_PLAN)} required />
                        <a href="https://tikit.ai/pricing" target="_blank"><InfoIcon outline /></a>
                      </Stack>
                      {billingMessage && <div className="teams-input-med pb-2"><Alert content={<div>{billingMessage} If you have any questions please email <a href="mailto:team@tikit.ai" style={{fontWeight: 'bold', textDecoration: 'underline'}}>team@tikit.ai</a>.</div>} variables={{ oof: true }}/></div>}
                      <div className="teams-input-med">
                        <Stack tokens={{ childrenGap: 20 }}>
                          {subValues.prices.map(price => 
                          (<CompoundButton primary={price.PriceId === formValues.selectedPriceId}
                            className={`${Styles['billingPlanBtn']} ${subValues.prices.length <= 1 ? Styles['billingPlanSelected'] : price.PriceId === formValues.selectedPriceId ? Styles['billingPlanActiveBtn'] : ''}`}
                            onClick={() => setFormValues({ ...formValues, selectedPriceId: price.PriceId })} >
                            {
                              <Stack>
                                <Text content={price.PriceName || price.ProductName} weight="semibold" />
                                <Text size="small" content={t(`billing.price-per-agent.${price.Recurring}`, { price: formatter.format((price.BillingScheme === "per_unit" ? price.Cost : pricePerAgent(price)) / formValues.count) })} />
                                <Text size="small" content={t(`billing.price-per.${price.Recurring}`, { price: formatter.format(price.BillingScheme === "per_unit" ? price.Cost : pricePerAgent(price)) })} />
                                <Text size="small" content={t(`billing.billing-occurs.${price.Recurring}`)} />
                              </Stack>
                            }
                          </CompoundButton>)
                          )}
                        </Stack>
                      </div>
                      <LabelElement label={toTitleCase(BILLING.STREET_ADDRESS)} required className="pt-2" />
                      <div className="teams-input-med">
                        <Input clearable inverted fluid type="text" name="streetAddress1" value={formValues.streetAddress1} onChange={updateFormValue} />
                      </div>
                      <LabelElement label={toTitleCase(BILLING.STREET_ADDRESS_2)} />
                      <div className="teams-input-med">
                        <Input clearable inverted fluid type="text" name="streetAddress2" value={formValues.streetAddress2} onChange={updateFormValue} />
                      </div>
                      <LabelElement label={toTitleCase(BILLING.CITY)} required />
                      <div className="teams-input-med">
                        <Input clearable inverted fluid type="text" name="city" value={formValues.city} onChange={updateFormValue} />
                      </div>
                      <LabelElement label={BILLING.STATE_PROVINCE} required />
                      <div className="teams-input-med">
                        <Input clearable inverted fluid type="text" name="state" value={formValues.state} onChange={updateFormValue} />
                      </div>
                      <LabelElement label={BILLING.POSTAL_ZIPCODE} required />
                      <div className="teams-input-med">
                        <Input clearable inverted fluid type="text" name="postal" value={formValues.postal} onChange={updateFormValue} />
                      </div>
                      <LabelElement label={BILLING.COUNTRY} required />
                      <div className="teams-input-med pb-4">
                        <Dropdown fluid search inverted
                          style={{ background: globalTheme.siteVariables.colorScheme.default.background }}
                          placeholder={COUNTRIES.find(c => c.accessibilityItemProps.value === formValues.country)?.header?.toString()}
                          items={COUNTRIES}
                          onChange={(_e, p) => { setFormValues({ ...formValues, country: p.value ? p.value['accessibilityItemProps'].value : '' }); }} />
                      </div>
                      <LabelElement label={toTitleCase(BILLING.CARD_INFORMATION)} required />
                      <div className={`${Styles.cardElement} teams-input-med`}>
                        <CardElement options={{
                          hidePostalCode: true,
                          style: {
                            base: {
                              color: globalTheme.siteVariables.colorScheme.default.foreground
                            }
                          }
                        }}
                          onReady={e => { setCardElement(e); }}
                          onChange={e => { setFormValues({ ...formValues, cardIsValid: e.complete && !e.error, saveDisabled: formValues.billingChanged || !e.empty }); }} />
                      </div>
                      {!addingPayment && <CompoundButton className={`${isDisabled || !isLicensedUser ? Styles['addPaymentDisabled'] : Styles['addPaymentActive']}`}
                        onClick={updateSubscription} disabled={isDisabled || !isLicensedUser} >
                        <Stack style={{ textAlign: "start" }}>
                          <Text content={t('billing.add-payment-method')} weight="semibold" />
                          <Text size="small" content={t('billing.tikit-payment-info')} />
                        </Stack>
                      </CompoundButton>}
                      {addingPayment && (<CompoundButton className={Styles['billingPlanBtn']}>
                        <Stack style={{ textAlign: "start" }}>
                          <Loader className='pb-2' />
                          <Text content={t('billing.adding-payment')} weight="semibold" />
                          <Text size="small" content={t('billing.tikit-payment-info')} />
                        </Stack>
                      </CompoundButton>)}
                    </Elements>
                  )}
              </div>

            </div>
          </SettingsPageWrapper>
        </div>
      )} />
      <MobileSettingsView />
    </CheckLogin>
  );
};
