import React, { useState } from 'react';
import Styles from './RelationCard.module.css';
import { IRelationType } from '../RelateTicketHelper';
import { Flex, ProviderConsumer as FluentUIThemeConsumer } from '@fluentui/react-northstar';


interface Props {
  relation: IRelationType,
  onClick: () => void;
}

export const RelationCard = ({ relation, onClick }: Props) => {
  const [hoverState, setHoverState] = useState<boolean>(false);
  const Icon = relation.icon;

  return (
    <FluentUIThemeConsumer
      render={({ siteVariables: { colorScheme } }) => (
        <>
          <div
            key={relation.key} onClick={onClick}
            onMouseEnter={() => setHoverState(true)} onMouseLeave={() => setHoverState(false)}
            className={`p-4 relation-card bg-white cursor-pointer mb-3 ${Styles.RelationCard}`}
            style={{
              color: hoverState ? colorScheme.default.foreground : colorScheme.default.foreground2,
              borderColor: hoverState ? colorScheme.brand.foreground1 : colorScheme.default.border,
              backgroundColor: colorScheme.default.background,
            }}
          >
            <Flex vAlign="center">
              <Icon width={40} height={25} className={`mr-4`} />
              {relation.name}
            </Flex>
          </div>
        </>
      )}
      />
  );
};
