import React from 'react';
import { Flex, ChevronEndIcon, ChevronStartIcon } from '@fluentui/react-northstar';
import Styles from './table.module.css';

import { useTranslation } from 'react-i18next';
interface Props {
  currentPage: number;
  totalCount: number;
  moveToPage: (type: string) => void;
  itemPerPage?: number;
  isLoading?: boolean;
}

export const TablePagination = ({
  currentPage,
  totalCount,
  moveToPage,
  itemPerPage,
  isLoading = false
}: Props) => {

  let itemsLength = itemPerPage ? itemPerPage : 10;
  const isNextDisabled = Math.ceil(totalCount / itemsLength) === currentPage || totalCount === 0 || isLoading;
  const isBackDisabled = currentPage === 1 || isLoading;
  const { t } = useTranslation();

  return (
    <Flex space='between' padding='padding.medium' vAlign='center'>
      <div className={Styles.paginationText}>
        {`${totalCount === 0 ? 0 : currentPage * itemsLength - (itemsLength - 1)} 
        to ${(currentPage * itemsLength > totalCount) ? totalCount : currentPage * itemsLength} 
        of ${totalCount}`}
      </div>
      <Flex vAlign='center' gap='gap.medium'>
        <ChevronStartIcon
          className={Styles.paginationText}
          outline
          size='small'
          disabled={isBackDisabled}
          onClick={() => { moveToPage('previous') }}
          styles={{ pointerEvents: isBackDisabled ? 'none' : 'auto', '&:hover': { cursor: 'pointer' } }}
        />
        <div className={Styles.paginationText}>{t('common.page')} {currentPage}</div>
        <ChevronEndIcon
          className={Styles.paginationText}
          outline
          size='small'
          disabled={isNextDisabled}
          onClick={() => { moveToPage('next') }}
          styles={{ pointerEvents: isNextDisabled ? 'none' : 'auto', '&:hover': { cursor: 'pointer' } }}
        />
      </Flex>
    </Flex>
  );
};
