import React from 'react';
import {
  Flex,
  InfoIcon,
  SiteVariablesPrepared
} from '@fluentui/react-northstar';
import { TeamsTheme } from '@fluentui/react-teams/lib/esm/themes';
import i18next from 'i18next';

export function ChartEmptyState({
  siteVariables
}: {
  siteVariables: SiteVariablesPrepared;
}) {
  let textColor;
  switch (siteVariables.theme) {
    case TeamsTheme.Dark:
      textColor = siteVariables.colors.grey['400'];
      break;
    case TeamsTheme.HighContrast:
      textColor = siteVariables.colors.white;
      break;
    case TeamsTheme.Default:
    default:
      textColor = siteVariables.colors.grey['400'];
      break;
  }
  return (
    <Flex
      styles={{
        height: '100%',
        minHeight: '14rem',
        backgroundColor: siteVariables.colorScheme.grey.background,
        color: textColor
      }}
      vAlign="center"
      hAlign="center"
    >
      <InfoIcon outline styles={{ marginRight: '.5rem' }} /> {i18next.t('common.no-data-found')}
    </Flex>
  );
}
