import React, { useState, useEffect} from 'react';
import Styles from '../styles/Lifecycle.module.css';
import { Flex, ThemePrepared, Button, AddIcon, ProviderConsumer } from '@fluentui/react-northstar';
import { Phase } from './Phase';
import { cloneDeep } from 'lodash';
import { KeyValuePair, LifecyclePhase, LifecycleData } from '../utils/typings';
import { createDropdownDataModel, INITIAL_LIFECYCLEPHASE_DATA, TransitionActionTypeString, INITIAL_LIFECYCLETRANSITION_DATA } from '../utils/constants';
import { getNewGuid } from '../utils/helper';
import { PlatformUser } from '../../shared/interfaces/platformuser.interface';
import { DisplayLifecyclePhases } from '../../shared/components/TicketDetailInfo/displayLifecyclePhases/DisplayLifecyclePhases';
import { PhaseCard } from '../../shared/components/TicketDetailInfo/displayLifecyclePhases/card';
import { LIFECYCLE } from './../../shared/utils/constants';
import { useTranslation } from 'react-i18next';

interface Props {
  data: LifecyclePhase[];
  updateData: (data: LifecyclePhase[]) => void;
  globalTheme: ThemePrepared<any>;
  lifecycleData: LifecycleData;
  userList: PlatformUser[];
  taskStatusList: TaskStatus[];
}

export const PhaseSection = ({
  data,
  updateData,
  globalTheme,
  lifecycleData,
  userList,
  taskStatusList
}: Props) => {
  const [phasesOptions, setPhasesOptions] = useState<DropdownDataModel[]>([]);
  const [reloadOptions, setReloadOptions] = useState(true);
  const [dataLength, setDataLength] = useState(0);

  const { t } = useTranslation();
  
  useEffect(() => {
    if (reloadOptions || dataLength !== data.length) {
      const phases: DropdownDataModel[] = createDropdownDataModel(data);
      setPhasesOptions(phases);
      setReloadOptions(false);
      setDataLength(data.length);
    }
  }, [reloadOptions, data.length]);

  const updatePhaseData = (editData: KeyValuePair[], index: number) => {
    editData.forEach(pair => {

      if (pair.key == "Name")
        setReloadOptions(true);

      if (pair.key == "RemoveItem") {
        data.splice(index, 1);
        setReloadOptions(true);
      } else
        data[index][pair.key] = pair.value;
    });    
    updateData([...data]);
  }

  const addNewPhase = () => {
    const dataLength = data?.length ?? 0;
    const newPhase = cloneDeep({...INITIAL_LIFECYCLEPHASE_DATA, Guid: getNewGuid(), LifecycleId: lifecycleData.Id, 
      Name: `${t('lifecycle.new-phase')} ${(dataLength + 1)}`, Transitions: [cloneDeep({...INITIAL_LIFECYCLETRANSITION_DATA, Title: `${t('lifecycle.new-transition')} 1` })]});
    data.push(newPhase);
    updateData([...data]);
    setReloadOptions(true);
  }

  const PhaseTitleSection = (lifecyclePhase: LifecyclePhase[]) => {

    return(
      <ProviderConsumer 
        render={globalTheme => (
          <div className={`w-full truncate mb-6`}>
            <Flex column className={'cui-pt-4 cui-pv-4'} styles={{justifyContent: 'end'}}>
              <DisplayLifecyclePhases isForm={true} children={
                lifecyclePhase
                .map(function(phase: LifecyclePhase, index){
                  return (
                    <PhaseCard itemId={false ? "phase-card-current" : `phase-card-${index}`} key={index} children={
                      <>
                        <div title={phase?.Name ?? ''} className='py-2' >
                          {
                            phase.Name && <Flex.Item className={`p-2 mt-2 ml-0.5`} styles={{
                              backgroundColor: globalTheme.siteVariables.colorScheme.default.background,
                              border: `1px solid ${globalTheme.siteVariables.colorScheme.default.border}` 
                            }}>
                              <span>{phase.Name}</span>
                            </Flex.Item>
                          }
                        </div>
                      </>
                    } />
                  )
                })
              } />
            </Flex>
          </div>
        )}
      />
    )
  }

  const clickOnExpand = (index: number, isExpand: boolean) => {
    data[index].IsExpand = isExpand;
    updateData([...data]);
  }

  function handleScroll(movePhaseGuid) {
    document.getElementById(movePhaseGuid).scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
  }

  return (
    <>
      <div>
        <div>
          {PhaseTitleSection(data)}
        </div>
        <h2 className={Styles.SubHeading2}>{LIFECYCLE.PHASES}</h2>
        {data.map((phaseData, index) => {
          return <div id={phaseData.Guid} className={`mt-3`}>
            <Phase 
              phaseData={phaseData}
              phaseIndex={index}
              updatePhaseData={updatePhaseData}
              globalTheme={globalTheme}
              phasesOptions={phasesOptions}
              userList={userList}
              taskStatusList={taskStatusList}
              clickOnExpand={clickOnExpand}
              allPhasesData={data}
              handleScroll={handleScroll}
            /></div>
        })}
        <Flex className='m-0 mb-6 mt-4'>
            <Button
            icon={<AddIcon 
                styles={{color: globalTheme.siteVariables.colorScheme.brand.foreground}} 
                />}
            text
            primary
            content={<span 
                style={{color: globalTheme.siteVariables.colorScheme.brand.foreground}}
                >{LIFECYCLE.ADD_PHASE}</span>}
            onClick={addNewPhase}
            />
        </Flex>
      </div>
    </>
  );

};
