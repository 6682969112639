import { Flex } from '@fluentui/react-northstar';
import React from 'react';
import { RelatedTicketCard } from './RelatedTicketCard/RelatedTicketCard';
import { IRelatedTicketsCard } from './RelateTicketHelper';
import { EmptyData } from '../EmptyData';
import EmptySVG from '../../../../svg/empty.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  relatedTickets: IRelatedTicketsCard[];
}

export const RelatedTicketsList = ({ relatedTickets }: Props) => {
  const {t} = useTranslation();
  return (
    <div className='related-ticket-list'>
        {relatedTickets.length > 0 ? 
        (relatedTickets.map((ticket: IRelatedTicketsCard) => (<RelatedTicketCard ticket={ticket} />))) : 
        <Flex className='mt-5' >
          <Flex.Item grow styles={{ paddingTop: '0.25rem', overflow: 'auto' }} ></Flex.Item>
          <EmptyData
            headerText={t('ticket-details.edit-ticket-form.relate-ticket.empty-realte-ticket')}
            SVGIcon={<EmptySVG width={75} height={75} />}
          />
        </Flex>}
    </div>
  );
};
